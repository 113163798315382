/*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2021] - [2022] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any. The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Searching
///  Description: Search in the workspace
///  Owner      : Sumeet Goel
///  Author     : Akansha Aggarwal
///  Created On : 23-05-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///----------------------------------------------------------------- -->

import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef, Optional } from '@angular/core';
import { DeleteDocsComponent } from '../../../shared/delete-docs/delete-docs.component';
import { DetailAndActivityComponent } from '../../../shared/detail-and-activity/detail-and-activity.component';
import { DownloadComponent } from '../../../shared/download/download.component';
import { GuestUserModalComponent } from '../../../shared/guest-user-modal/guest-user-modal.component';
import { SharedAccessComponent } from '../../../shared/shared-access/shared-access.component';
import { OwnedDocsService } from '../../../@core/utils/owned-docs.service';
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import { VerifyDocumentService } from '../../../@core/verify-document.service';
import { BulkTagComponent } from '../../../../app/shared/bulk-tag/bulk-tag.component';
import { SharedService } from '../../../@core/utils/shared.service';
import { ThreeDotComponent } from '../three-dot/three-dot.component';
import { FormatTooltipComponent } from './format-tooltip/format-tooltip.component';
import { Router } from '@angular/router';
import { PrintService } from '../../../@core/utils/print.service';
import { SharedServiceService } from '../../../shared-service.service';
import { DatePipe } from '@angular/common';
import { DpagestooltipComponent } from './dpagestooltip/dpagestooltip.component';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { NormalSearchService } from '../../../@core/utils/normal-search.service';
import { Title } from '@angular/platform-browser';
import { PrintDocumentComponent } from '../../../shared/print-document/print-document.component';
import { SharedDocsService } from '../../../@core/utils/shared-docs.service';
import { DateTransformPipe } from '../../date-transform.pipe';
import { DocPreviewComponent } from '../../../../app/shared/doc-preview/doc-preview.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ShareGuetsRenderedComponent } from '../../owned-by-me/own-by-me/share-guets-rendered/share-guets-rendered.component';
/**
 * search component  onInit
 */
@Component({
  selector: 'ngx-searching',
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.scss']
})
/**
 * SearchingComponent  on init
 */
export class SearchingComponent implements OnInit {
  @ViewChild(DetailAndActivityComponent) rightnavchild: DetailAndActivityComponent;
  @ViewChild(ThreeDotComponent, { static: false }) threeDot: ThreeDotComponent;
  /**
   * header Search Subscription key
   */
  headerSearchSubscription: any;
  /**
   * Access var
   */
  notAccess = "Not Access"
  /**
  * searchName key
  */
  searchName: any;
  /**
  * loaderSub variable
  */
  loaderSub: any;
  /**
  * loading variable
  */
  loading = false;
  /**
 * checkDataAvailable variable
 */
  checkDataAvailable: any;
  /**
 * notAvailable variable
 */
  notAvailable: any;
  /**
  * hideCards variable
  */
  hideCards = false;
  /**
  * subscriptionSearch variable
  */
  subscriptionSearch: any;
  /**
  * searchCount variable
  */
  searchCount: any;
  /**
* subscriptionPage variable
*/
  subscriptionPage;
  /**
 * pageNum variable
 */
  pageNum;
  /**
  * pageValue variable
  */
  pageValue;
  /**
  * pager variable
  */
  pager: any = {};
  /**
* pageValueCurrent variable
*/
  pageValueCurrent;
  /**
* totalPages variable
*/
  totalPages;
  /**
  * subsToggleData variable
  */
  subsToggleData;
  /**
* workSpaceId variable
*/
  workSpaceId;
  /**
  * createdBy variable
  */
  createdBy;
  /**
   * getGuid variable
   */
  getGuid;
  /**
 * wsName variable
 */
  wsName;
  /**
  * timeFormat variable
  */
  timeFormat;
  /**
  * DateFormat variable
  */
  DateFormat;
  /**
  * guestAccess variable
  */
  guestAccess;
  /**
 * chkDownloadPrint variable
 */
  chkDownloadPrint;
  /**
  * chkEditRights variable
  */
  chkEditRights;
  /**
  * downloadPrint variable
  */
  downloadPrint;
  /**
  * parseFlag variable
  */
  parseFlag;
  /**
   * moment variable
   */
  moment: any;
  /** 
* Array with external Solution key to pass to delete component
*/
  selectedDocsCheck: any;

  middot = '|';

  public previousRoutePath = new BehaviorSubject<string>('');
  @ViewChild(PrintDocumentComponent) printChild: PrintDocumentComponent;
  searchValueChange: unknown;
  multiDoc: boolean;
  tagPanelOpen: boolean = true;
  docOwnerEmailIdNew: any;
  sharedDocStatus: string;
  editrights: any;
  previewPanelOpen: boolean = true;
  previewFlag: boolean = false;
  multiSeletedDocs: any;
  cellSelected: any;
  GuestFlag:string;
  getguid:any;
  relevanceByContent:any
  isDownloadDisabled = false;
  searchSpinnerNew = false;
  docShared: number;
  docSelected: number;
  docSelectedByUser: boolean = false;
  docSharedTrue: boolean = false;
  /**
   * is Guest access available in role
   */
  isGuestAccessInRole: boolean=false;
  /**
   * Limiting API Calls while opening Guest Share modal
   */
  runOneTime: boolean = true;

  /**
   * Constructor Public
   */
  constructor(
    private datePipe: DateTransformPipe, private ownedservice: OwnedDocsService, private data1: VerifyDocumentService, public sharedService: SharedService, public cd: ChangeDetectorRef, public toastrService: NbToastrService, private location: Location,
    public router: Router, public printservice: PrintService, private Print: PrintService, public sharedservice: SharedServiceService,
    public dialogService: NbDialogService, private overlayContainer: OverlayContainer, public searchService: NormalSearchService, private titleService: Title, private datanew: SharedDocsService,
    @Optional() public dialogRef: NbDialogRef<any>,
    public data: OwnedDocsService,) {
    this.getguid = localStorage.getItem('loginguid')
    this.sharedDocStatus = localStorage.getItem('sharedDoc')
    this.GuestFlag = localStorage.getItem('GuestFlag');
    this.deleteRights = localStorage.getItem('deleteRights');
    this.isGuestAccessInRole = localStorage.getItem('isGuestAccess').toLowerCase() =='true'?true:false;
    /**
   * localwsId getItem
   */
    this.workSpaceId = localStorage.getItem('localwsId')

    /**
   * localloginId getItem
   */
    this.createdBy = localStorage.getItem('localloginId')

    /**
   * loginguid getItem
   */
    this.getGuid = localStorage.getItem('loginguid')

    /**
   * WorkSpaceName getItem
   */
    this.wsName = localStorage.getItem('WorkSpaceName')

    /**
   * TimeFormat getItem
   */
    this.timeFormat = localStorage.getItem('TimeFormat')

    /**
   * wsCreationDate getItem
   */
    this.wsCreationDate = localStorage.getItem('wsCreated');

    /**
* parseFlag getItem
*/
    this.parseFlag = localStorage.getItem('parserflag');
    /**
     * DateFormat getItem
     */
    this.DateFormat = localStorage.getItem('DateFormat')
    /**
     * guest Access getItem
     */
    this.guestAccess = localStorage.getItem('isGuestAccess')
    /**
     * chkDownloadPrint getItem
     */
    this.chkDownloadPrint = localStorage.getItem('downloadandprint')
    /**
* downloadPrint getItem
*/
    this.downloadPrint = JSON.parse(this.chkDownloadPrint);
    /**
* relevanceByContent getItem
*/
    this.relevanceByContent = localStorage.getItem('RelevanceByContent')

    /**
     * chkEditRights getItem
     */
    this.chkEditRights = localStorage.getItem('editaccess');
    this.editrights = JSON.parse(this.chkEditRights);
  }
  /**
    * subscriptionOwnedByDetail variable
    */
  subscriptionOwnedByDetail;
  /**
  * dateformat variable
  */
  dateformat;
  /**
  * timeFormatDate variable
  */
  timeFormatDate
  /**
  * timeZone variable
  */
  timeZone;
  /**
    * subscriptionSearchThree variable
    */

  subscriptionSearchThree;
  /**
   * currentPageValChange variable
   */
  currentPageValChange;
  /**
   * paginationThrough variable
   */
  paginationThrough = '';
  HideViewDetailresponse: boolean
  getPreviousURL: any;
  WorkSpaceName;
  WsdisplayName;
  newDocumentOwner = '';
  closePreview() {
    // this.tagPanelOpen= true;
    this.isDetailOpen = false;
    this.isTrue = false
    this.previewPanelOpen = true;
    this.hidePreview()
    this.gridColumnApi.setColumnVisible('pageCount', false);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', false);
    this.gridColumnApi.setColumnVisible('verifiedByDisplayName', false);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  /**
   * ngOnInit Component
   */
  ngOnInit(): void {
    this.sharedService.transferData.subscribe((res) => {
      if(res=='reloadData')
      {
        //this.ngOnInit()
        this.searchingAPI('');
      }
    });
    
    this.searchSpinnerNew = true;
    this.sharedService.searchloader.subscribe((response) => {
      this.searchSpinnerNew = true;
      setTimeout(() => {
        this.searchSpinnerNew = false;
      }, 800);
    })
    this.sharedservice.hideSideBar.next(false);
    
    this.sharedservice.headerToggle.subscribe((res:boolean) =>{
      this.closeTagPanel();
      this.crossdata();
      this.showData();
      this.closePreview();
      })

    this.sharedService.isDeleted.subscribe(data => {
      this.ngOnInit();
      this.previousRoutePath.next(this.location.path());
    })
    this.sharedService.cellClickedData.subscribe((data) => {
      localStorage.setItem("docId", this.cellData.docId)
      this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
    })
    localStorage.setItem('saved', 'false')
    this.sharedService.closeDetailBar1.subscribe(() => {
      this.closeTagPanel();
      this.showData();
      this.crossdata();
    });
    this.sharedService.isClose.subscribe((data) => {
      this.closePreview();
      this.showData();
    })
    this.dateformat = localStorage.getItem('DateFormat');
    this.timeFormatDate = localStorage.getItem('TimeFormat');
    this.timeZone = localStorage.getItem('TimeZone');
    this.WorkSpaceName = localStorage.getItem('WorkSpaceName')
    this.WsdisplayName = localStorage.getItem('WsdisplayName') ? localStorage.getItem('WsdisplayName') : '';
    this.titleService.setTitle("Search Results" + this.middot + this.WsdisplayName + this.middot + this.WorkSpaceName + this.middot + 'dox2U');

    this.subscriptionSearch = this.sharedservice.searchData.subscribe((data) => {
      this.searchValueChange = data;
      this.searchTableData(this.searchValueChange, '');
    });
    this.subscriptionSearch = this.sharedService.searchDataPagingChange.subscribe((data) => {
      this.searchSpinnerNew = false;
      this.paginationThrough = 'usingPagination';
      this.currentPageValChange = data;
    });

    this.subscriptionSearchThree = this.sharedService.viewClickThreeDot.subscribe((data) => {
      this.onViewClickThree(data);
    });
    this.subscriptionSearchThree = this.sharedservice.totalCountValue.subscribe((data) => {
      this.documentCount = data;
      this.docCountTotal = data;

      if (this.searchValueChange == '') {
        this.searchingAPI('ngOninit')
      }
      else {
        this.updateColumnDef(this.searchValueChange);
      }
      this.cd.detectChanges();
    });
    /**
     * subject used for refreshing the elastic after the deletion of document by searching component not a good solution
     */
    this.sharedservice.refreshSearch.subscribe((res) => {
      this.searchSpinnerNew = true;
      setTimeout(() => {
        this.searchingAPI('');
      }, 4500);
    })
    this.sharedService.printService.subscribe((e) => {
      this.isSelectedOnceDetailsPrint = false;
    });

    this.subscriptionSearchThree = this.sharedservice.forSearchTableSpinnerstop.subscribe((data) => {
      if (this.firsthit) {
        this.searchSpinner = true;
        this.firsthit = '';
      }
      else {
        this.searchSpinner = false;
        this.firsthit = '';
      }

      this.searchValueChange = data;
      this.searchTableData(this.searchValueChange, '');

    });
    this.sharedService.ownedByMeTag.subscribe((res: any) => {
      if (this.HideViewDetailresponse == res) {
        this.tagVerify()
        this.HideViewDetailresponse = false;
      }
    });
    this.subscriptionOwnedByDetail = this.sharedService.closeDetailBar.subscribe((data) => {
      //this.crossData();
      this.crossdata();
      this.showdata();
    });
    // setTimeout(() => {
    //   this.searchingAPI('ngOninit')
    // }, 3000);
    this.previousRoutePath.next(this.location.path());


    if (localStorage.getItem('preview') == 'True') {
      this.previewFlag = true
    }
    /**
     * UploadRightsUserList calling
     */
    this.UploadRightsUserList();

  }
  crossdata() {

    this.hideDetail = true;

    this.HideViewDetailresponse = true
    this.sharedservice.hideSideBar.next(!this.hideDetail);
  }
  getPageReload() {
    this.searchSpinner = true;
    this.ngOnInit();
  }
  shareAccessTags() {
    if (this.selectedDocs.length > 0 && this.selectedDocs.length === 1) {
      this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      if (this.selectedDocs.length > 0 && ((this.editrights && this.selectedDocs[0].docShareStatus == "Access") || this.docOwnerEmailId === this.createdBy)) {
        flag = true;
      }
      this.datanew.showDelete = flag;

      return flag;
    }
    /*
   *for multiple docs select delete option show
   */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let bulkTags = localStorage.getItem('bulkTags');
      let tempdata = this.selectedDocs.findIndex(item => item.docShareStatus === this.notAccess)
      let tempdata1 = this.selectedDocs.findIndex(item => item.docOwnerEmailId !== this.createdBy)
      if (tempdata !== -1) {
        flag = false;
        return flag;
      } else {
        if (bulkTags === 'True') {
          if (localStorage.getItem('editaccess') === 'true') {
            flag = true;
            return flag;
          } else if (tempdata1 !== -1) {
            flag = false;
            return flag;
          }
          else {
            flag = true;
            return flag;
          }

        }
      }





    }
  }
  shareAccessValidate() {
    if (this.selectedDocs.length > 0 && this.selectedDocs.length === 1) {
      this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      if (this.selectedDocs.length > 0 && ((this.editrights && this.selectedDocs[0].docShareStatus === "Access") || this.docOwnerEmailId === this.createdBy)) {
        flag = true;
      }
      this.datanew.showDelete = flag;

      return flag;
    }
    /*
   *for multiple docs select delete option show
   */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let bulkShareRight = localStorage.getItem('bulkShareing');
      let tempdata = this.selectedDocs.findIndex(item => item.docShareStatus === this.notAccess)
      let tempdata1 = this.selectedDocs.findIndex(item => item.docOwnerEmailId !== this.createdBy)
      if (tempdata !== -1) {
        flag = false;
        return flag;
      } else {
        if (bulkShareRight === 'True') {
          if (localStorage.getItem('editaccess') === 'true') {
            flag = true;
            return flag;
          } else if (tempdata1 !== -1) {
            flag = false;
            return flag;
          }
          else {
            flag = true;
            return flag;
          }

        }
      }




    }
  }
  /**
    * selectedSortItem variable
    */
  selectedSortItem = 'Relevance, prioritized by Name'
  /**
  * rowData variable
  */
  rowData = [];
  /**
  * documentCount variable
  */
  documentCount;
  /**
  * toBeSortData variable
  */
  toBeSortData;
  /**
  * removePlusGetTmp variable
  */
  removePlusGetTmp;
  /**
  * getDifference variable
  */
  getDifference;
  /**
  * mainDifChk variable
  */
  mainDifChk;
  /**
  * valueAdmin variable
  */
  valueAdmin;
  /**
  * dateFullYear variable
  */
  dateFullYear;
  /**
  * dateFullToday variable
  */
  dateFullToday;
  /**
  * docCountTotal variable
  */
  docCountTotal;
  /**
* searchTableData function
*/

  searchTableData(data, val) {
    if (data !== '') {
      this.rowData = data;
      for (let i = 0; i <= this.rowData.length - 1; i++) {
        this.rowData[i].documentFormat = this.rowData[i].format;

      }
      this.rowData.forEach(element => {
        if (element.documentowner == this.createdBy) {
          if (element.docownername) {
            if (!(element.docownername.includes(' (you)'))) {
              element['docownername'] = element.docownername + ' (you)';
            }

          }

        }

      });

      this.dateFullYear = new Date().getFullYear();
      this.dateFullToday = new Date().getDate();
      this.dateFullToday < 10 ? this.dateFullToday = 0 + '' + this.dateFullToday : this.dateFullToday = this.dateFullToday;
      if (this.timeZone) {
        let timeZoneVal = this.timeZone.includes('UTC+') ? this.timeZone.includes('UTC+') : this.timeZone.includes('UTC-');

        if (timeZoneVal) {
          let getTmp = this.timeZone?.split('UTC');


          if (getTmp[1].includes('+')) {
            this.valueAdmin = "+";
            this.removePlusGetTmp = getTmp[1]?.split('+')
          }
          else {
            this.valueAdmin = "-";
            this.removePlusGetTmp = getTmp[1]?.split('-')
          }

        }

        this.getDifference = this.removePlusGetTmp[1];
      }
      this.rowData = this.datePipe.transformSearch(data);
      for (let i = 0; i <= this.rowData.length - 1; i++) {
        this.rowData[i].docGuid = this.rowData[i].docguid;
        this.rowData[i].docId = JSON.parse(this.rowData[i].docid);
        this.rowData[i].docOwnerEmailId = this.rowData[i].documentowner;
        this.rowData[i].docRename = this.rowData[i].docrename;
        this.rowData[i].originalFileName = this.rowData[i].originalfilename;
      }
      this.toBeSortData = this.rowData;
      this.sendDetailsData = this.rowData[0];
      this.sendDetailValue(this.sendDetailsData);
      this.updateColumnDef(this.rowData);

      if (val == 'ngOnInit') {
        this.searchSpinner = false
        this.firsthit = '';
      }
    }
    else {
      this.searchingAPI('');
    }

  }

  firsthit = '';
  searchSpinner = true;
  searchingAPI(val?) {
    this.searchSpinnerNew = true;
    let formatTypeVal = localStorage.getItem('formatTypeSearch');
    let documentOwnerSearch = localStorage.getItem('documentOwnerSearch');
    let docrenameSearch = localStorage.getItem('docrenameSearch');
    let FromDateSearch = localStorage.getItem('FromDateSearch');
    let ToDateSearch = localStorage.getItem('ToDateSearch');
    let EXTRACTED_TEXTSearch = localStorage.getItem('EXTRACTED_TEXTSearch');
    let searchingstringSearch = localStorage.getItem('searchingstringSearch');
    let deepSearch = localStorage.getItem('Deep Search').toLowerCase();
    let NotesSearch = localStorage.getItem('NotesSearch');
    let sharedBySearch = localStorage.getItem('sharedBySearch');
    let strBoostFieldSearch = localStorage.getItem('strBoostFieldSearch');
    let CountPerPageSearch = localStorage.getItem('CountPerPageSearch');
    let PageNumberSearch = localStorage.getItem('PageNumberSearch');
    let FlagSearch = localStorage.getItem('FlagSearch');
    let TagsSearch
    if (localStorage.getItem('TagsSearch') !== '') {
      TagsSearch = localStorage.getItem('TagsSearch');
    }
    else if (localStorage.getItem('TagsSearchDate') !== '') {
      let TagsSearchValue = localStorage.getItem('TagsSearchDate');
      let tempDate = TagsSearchValue;
      TagsSearch = tempDate;
    }
    else {
      TagsSearch = "";
    }
    let parserValue;
    if (this.parseFlag == 'True') {
      parserValue = 1;
    }
    else {
      parserValue = 0;
    }
    let postDataTarget = {
      CountPerPage: JSON.parse(CountPerPageSearch),
      EXTRACTED_TEXT: EXTRACTED_TEXTSearch,
      Flag: FlagSearch,
      FromDate: FromDateSearch,
      LoginUserEmailID: this.createdBy,
      Notes: NotesSearch,
      PageNumber: JSON.parse(PageNumberSearch),
      Tags: TagsSearch,
      ToDate: ToDateSearch,
      WSOID: this.workSpaceId,
      docownername: documentOwnerSearch,
      docrename: docrenameSearch,
      format: formatTypeVal,
      groupid: "",
      isAllUserShare: "",
      searchingstring: searchingstringSearch,
      sharedBy: sharedBySearch,
      strBoostField: strBoostFieldSearch,
      IsParserEnable: deepSearch === "true" ? 1 : 0,
      "sortingstr": this.sortingString
    }
    this.firsthit = 'value';

    this.searchService.SearchNormalFilter(postDataTarget).subscribe((response: any) => {
      if (response.isSuccess) {
        this.searchSpinnerNew = false;
        this.searchTableData(response.data, 'ngOnInit');

      }
      else {
        this.rowData = [];
        this.updateColumnDef(this.rowData);
        this.searchSpinner = false;
        this.searchSpinnerNew = false;
        this.noRowsTemplate = '<h4><img src="../../../assets/images/Empty-box.png" /><br>No documents<br><p>There are no documents in this listing that match your selected criteria.</p></h4>'
        this.cd.detectChanges();
      }
    })
  }

  /**
     * contentBase function
     */
  contentBase() {
    this.sharedService.contentBaseData.next()
  }
  /**
   * contentBase function
   */
  nameBase() {
    this.sharedService.nameBaseData.next()
  }
  /**
   * alphaAsc function
   */
  alphaAsc(data) {
    const sortType = data;
    const sortName = this.toBeSortData.sort((a, b) => {
      if (sortType === "asc") return a.docrename.toLowerCase() > b.docrename.toLowerCase() ? 1 : -1;
      else return b.docrename.toLowerCase() > a.docrename.toLowerCase() ? 1 : -1;
    });
    /**
     * uniqueNameExist const
     */
    const uniqueNameExist = [];
    /**
 * sameNameFilter const
 */
    const sameNameFilter = [];
    for (let i in sortName) {
      const uniName = uniqueNameExist.indexOf(sortName[i]["docrename"]);
      if (uniName === -1) uniqueNameExist.push(sortName[i]["docrename"]);
    }
    let mainSortedArray = [];
    if (uniqueNameExist.length !== sortName.length) {
      for (let i = 0; i <= sortName.length - 1; i++) {
        let arrayRow = [];
        arrayRow.push(sortName[i]);
        if (i !== sortName.length - 1) {
          for (let j = i + 1; j <= sortName.length - 1; j++) {

            const iExtName = sortName[i]["format"]
            const checkExistInOuter = sortName[j]["format"].includes(iExtName)
            if (sortName[i]["docrename"] === sortName[j]["docrename"] && checkExistInOuter) {
              arrayRow.push(sortName[j]);
            }
          }
        }
        const filterOutValue = arrayRow.sort((a, b) => {

          const checkDateSame = a.createddtm?.split("T")[0];
          const checkDateSame_ = b.createddtm?.split("T")[0];
          if (checkDateSame === checkDateSame_) {

            if (new Date(b.createddtm) > new Date(a.createddtm)) {
              return 1
            } else {
              return -1
            }
          } else {
            return 0;
          }
        });
        const checkIfAlreadyPushed = mainSortedArray.findIndex((el) => el.docId === sortName[i]["docId"]);
        if (checkIfAlreadyPushed === -1) {
          mainSortedArray = [...mainSortedArray, ...filterOutValue];
        }
      }
    } else {
      mainSortedArray = sortName
    }

    this.rowData = [];


    this.rowData = mainSortedArray;
    this.sendDetailsData = this.rowData[0];
    this.sendDetailValue(this.sendDetailsData);

    this.gridApi.setRowData(this.rowData);
    this.updateColumnDef(this.rowData);

    this.cd.detectChanges();

  }

  /**
    * dateAsc function
    */
  dateAsc(data) {
    let dateTimeSort = data
    let sortDate = []
    let dateA;
    let dateB;
    sortDate = this.toBeSortData.sort(function (a, b) {
      dateA = new Date(a.changedate),
        dateB = new Date(b.changedate)
      if (dateTimeSort === 'asc') {
        return dateA - dateB
      } else {
        return dateB - dateA
      }
    });
    const uniqueDateTimeExist = [];
    const sameNameFilter = [];
    for (let i in sortDate) {
      const timeStamp = new Date(sortDate[i]['changedate']).getTime()
      const uniDateTime = uniqueDateTimeExist.indexOf(timeStamp);
      if (uniDateTime === -1) uniqueDateTimeExist.push(timeStamp);
    }
    let mainSortedArray = [];
    if (uniqueDateTimeExist.length !== sortDate.length) {
      for (let i = 0; i <= sortDate.length - 1; i++) {
        let arrayRow = [];
        arrayRow.push(sortDate[i]);
        if (i !== sortDate.length - 1) {
          for (let j = i + 1; j <= sortDate.length - 1; j++) {
            const timeStampOuterArray = new Date(sortDate[i]['changedate']).getTime()
            const timeStampInnerArray = new Date(sortDate[j]['changedate']).getTime()
            if (timeStampOuterArray === timeStampInnerArray) {
              arrayRow.push(sortDate[j]);
            } else {
              break;
            }
          }
        }
        const filterOutValue = arrayRow.sort((a, b) => {
          if (dateTimeSort === 'asc') {
            return a.docrename > b.docrename ? 1 : -1
          } else {
            return b.docrename - a.docrename ? 1 : -1
          }
        });
        const checkIfAlreadyPushed = mainSortedArray.findIndex((el) => {
          const timeStampOuterArray_ = new Date(el['changedate']).getTime()
          const timeStampOuterArray = new Date(sortDate[i]['changedate']).getTime()
          if (timeStampOuterArray_ === timeStampOuterArray) {
            return el
          }
        });
        if (checkIfAlreadyPushed === -1) {
          mainSortedArray = [...mainSortedArray, ...filterOutValue];
        }
      }
    }

    this.rowData = [];
    if (mainSortedArray.length > 0) {
      this.rowData = mainSortedArray;
      this.sendDetailsData = this.rowData[0];
      this.sendDetailValue(this.sendDetailsData);
    }
    else {
      this.rowData = sortDate;
      this.sendDetailsData = this.rowData[0];
      this.sendDetailValue(this.sendDetailsData);
    }

    this.gridApi.setRowData(this.rowData);
  }
  /**
     * columnDefs variable
     */
  columnDefs = null;
  /**
  * updateColumnDef function
  */
  public updateColumnDef(rowData) {
    /*
    Added tooltip component for the format type
    */
    this.searchSpinnerNew = false
    this.columnDefs = [
      {
        headerName: '', field: 'format',
        "cellRendererFramework": FormatTooltipComponent,
        maxWidth: 60,
        minWidth: 60,
        resizable: false, suppressMovable: true, suppressCellSelection: true, cellClass: 'suppress-movable-col',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        showDisabledCheckboxes: true,

      },

      {
        headerName: 'Name', field: 'docrename', resizable: true, suppressCellSelection: true, suppressMovable: true,
        cellClass: 'suppress-movable-col', cellRenderer: this.createHyperLink.bind(this) ,  minWidth: 230,
        cellStyle: params => {

          if (params?.data.docShareStatus === this.notAccess) {

            return { color: '#8F9BB3' };
          }
          else {
            return { color: '#2c7db4' };
          }

        }

      },
      { headerName: '', field: '', "cellRendererFramework": ShareGuetsRenderedComponent, resizable: false, suppressCellSelection: false, suppressMovable: false, cellClass: 'suppress-movable-col', maxWidth: 200, minWidth: 80, }, 
      {
        headerName: 'Size', field: 'pageconversion', resizable: false, suppressMovable: true, suppressCellSelection: true,
        cellClass: 'suppress-movable-col', maxWidth: 230, minWidth: 100, "cellRendererFramework": DpagestooltipComponent,
      },

      {
        headerName: 'Added Date', field: 'createddtm', resizable: false, suppressCellSelection: true, suppressMovable: true,
        cellClass: 'suppress-movable-col', maxWidth: 230, minWidth: 100,

      },
      {
        headerName: 'Owned by', field: 'docownername', resizable: false, suppressCellSelection: true, suppressMovable: true,
        cellClass: 'suppress-movable-col', maxWidth: 230, minWidth: 100,

      },

      {
        headerName: '', field: ' ',
        "cellRendererFramework": ThreeDotComponent, onSelectionChanged: (event) => this.getThreeDotValidation(event),
        maxWidth: 220, minWidth: 80,
        cellRendererParams: {
          label: 'transfer click',
          onPreviewClick: this.openPreviewPanel.bind(this),
          onTransferClick: this.openModelOwnerShipTransferThreeDot.bind(this),
          onViewClick: this.onViewClickThreeDot.bind(this),
          onDeleteClick: this.openDeleteModalThree.bind(this),
          onViewClickPrint: this.openModelPrintThree.bind(this),
          onShareClick: this.openShareModal.bind(this),
          onViewClickDownload: this.openModelDownloadThree.bind(this),
          onViewClickDocDetails: this.openModelDocDetails.bind(this),
          onGuestClick: this.GuestModalShare.bind(this),
          onTagVerify: this.tagVerify.bind(this),
          rowData: rowData
        }
      }
    ];

  }
  /**
  * openModelDocDetails function
  */
  openModelDocDetails(params) {
    if (this.documentIdValue == params?.rowData.docId) {

      const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'details');
      this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
        this.sendDetailsData = params?.rowData;
        this.sendDetailValue(this.sendDetailsData);
        this.hideData();
        this.hideDetail = false;
      });
    }
    this.hideDetail = false;
    this.sharedservice.hideSideBar.next(!this.hideDetail);
  }
  /**
    * isSelectedGuest var
    */
  isSelectedGuest = false;
  /**
    * openModalGuest function
    */
  previewValidate() {
    let flag = false;

    if (this.selectedDocs.length === 1) {
      if ((localStorage.getItem('sharedDoc') == "Access" || this.createdBy === localStorage.getItem('docOwnerEmailId')) && (localStorage.getItem('preview') == 'True')) {
        flag = true;
      } else {
        flag = false;
      }
    }
    return flag;
  }
  openModalGuest(event) {

    let selectedRowData = this.getSelectedRowData(event.rowData);
    if (selectedRowData[0].originalFileName == event.rowData.originalFileName) {
      if (this.flagClick) {
        // this.isSelectedGuest = true;
        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(event.rowData.docId),
          "role": "View",
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getGuid,
          "DocGuid": event.rowData.docGuid
        }
        this.flagClick = '';
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

          if (response.isSuccess) {
            if (this.guestAccess === 'true') {
              this.guestChild.open(event);
            } else {
              this.guestChild.openNonEdit(event);
            }
          }

        }, error => {

        })
      }

    }
  }
  getThreeDotValidation(event?) {
    let shareRight = this.shareAccessValidate();
    localStorage.setItem("shareRightValidation", String(shareRight))
    let tagsRight = this.shareAccessTags();
    localStorage.setItem("sharetagsRightValidation", String(tagsRight))
    // console.log(localStorage.getItem('shareRightValidation'));
  }
  /**
* isSelectedOnceDetailsView variable
*/
  isSelectedOnceDetailsView = false;
  /**
* isDetailOpen variable
*/
  isDetailOpen = false;
  /**
* hideData function
*/
  hideData() {

    this.isSelectedOnceDetailsView = false;
    this.isDetailOpen = true;
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    // setInterval(()=> {this.isDetailOpen ?  this.sizeToFit():'' }, 1000);

  }
  /**
  * sizeToFit function
  */
  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }
  /**
     * hideDetail variable
     */
  hideDetail: boolean = true;
  /**
* crossData function
*/
  crossData() {
    this.hideDetail = true;

    this.HideViewDetailResponse = true

  }
  isTrue: boolean;
  closeTagPanel() {
    this.tagPanelOpen = true;
    this.isDetailOpen = false;
    this.isTrue = false;
    this.previewPanelOpen = true;
  }
  tagVerify(params?) {
    let isPreviewOpen = localStorage.getItem('Invokepreview');
    this.isTrue = false;
    if (this.oneTime == 1) {
      this.tagPanelOpen = !this.tagPanelOpen;
    }
    this.oneTime++
    setTimeout(() => {
      this.oneTime = 1
    }, 1000);
    this.sharedservice.hideSideBar.next(!this.tagPanelOpen);

    this.hideDetail = true;
    this.previewPanelOpen = true;
    if (isPreviewOpen != 'true') {
      this.getIds();
      // localStorage.setItem('saved', 'true');
    }
    localStorage.setItem('Invokepreview', String(false))
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  @ViewChild(BulkTagComponent) rightnavchild1: BulkTagComponent;
  getIds() {
    const ids = []
    const docVerifierEmailId = [];
    const docOwnerEmailId = [];
    const selectedGuid = [];
    const uploadedByDisplayName = [];
    const extension = [];
    const docRenameList = [];
    let loggedInUser = localStorage.getItem('WsdisplayName');
    this.selectedDocs.forEach(obj => {
      ids.push(obj.docId);
      docVerifierEmailId.push(obj.verifiedByEmailId);
      docOwnerEmailId.push(obj.docOwnerEmailId);
      selectedGuid.push(obj.docGuid);
      uploadedByDisplayName.push(loggedInUser);
      extension.push(obj.formatExtension ?? obj.format);
      docRenameList.push(obj.neweDocRename ?? obj.docrename ?? obj.originalFileName.split('.').slice(0, -1).join('.'));
    })
    localStorage.setItem('docId', ids.at(-1));
    this.sharedService.documentIds = [...ids];
    this.rightnavchild1.getmethodValue(ids, docVerifierEmailId, docOwnerEmailId, selectedGuid, uploadedByDisplayName, "", extension, docRenameList)
  }
  /**
     * HideViewDetailResponse variable
     */
  HideViewDetailResponse = true;
  /**
* detailSection function
*/
  detailSection() {
    this.tagPanelOpen = true;
    this.previewPanelOpen = true

    this.hideDetail = !this.hideDetail;
    this.HideViewDetailResponse = !this.HideViewDetailResponse;
    localStorage.setItem('saved', 'false')

    this.hideData();
    if (!this.hideDetail) {
      if (this.rowData) {

        if (this.selectedDocs.length > 0) {
          this.sendDetailValue(this.selectedDocs[this.selectedDocs.length - 1]);
        }
        else {

          this.sendDetailValue(this.sendDetailsData);
        }
      }
    }
    this.hideDetail ? this.crossData() : '';
    this.hideDetail ? this.showdata() : '';

    this.sharedservice.hideSideBar.next(!this.hideDetail);
    this.hideData();
  }
  showData() {
    this.isDetailOpen = false;
    this.gridColumnApi.setColumnVisible('pageCount', true);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', true);
    this.gridColumnApi.setColumnVisible('verifiedByDisplayName', true);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  /**
    * showData function
    */
  showdata() {
    this.isDetailOpen = false;
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  /**
     * gridApi variable
     */
  gridApi;
  /**
     * gridColumnApi variable
     */
  gridColumnApi: any;
  /**
     * columnApi variable
     */
  columnApi: any;
  /**
     * rowSelection variable
     */
  rowSelection = 'multiple';
  /**
     * listBg variable
     */
  listBg = true;
  /**
     * onGridReady function
     */
  onGridReady(params) {
    this.gridApi = params?.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params?.columnApi;
    params?.api.paginationGoToPage(4);
    this.columnApi = params?.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    }
    // this.onPageSizeChanged(this.selectedItem);
    setTimeout(() => { params?.api.sizeColumnsToFit() }, 1000);
  }
  /**
     * isSelectedOnceDetails variable
     */
  isSelectedOnceDetails = false;
  /**
     * isTransferModalAllowed variable
     */
  isTransferModalAllowed = true;
  /**
     * openModelOwnerShipTransferThreeDot function
     */
  openModelOwnerShipTransferThreeDot(params) {
    // setTimeout(() => {
    //   this.isTransferModalAllowed = true;
    // }, 1000);
    if (!this.isSelectedOnceDetails && this.isTransferModalAllowed) {
      this.isTransferModalAllowed = false;
      this.isSelectedOnceDetails = true;
      if (this.selectedDocs.length === 1) {
        const body = this.getRoleAccessBody(
          params?.rowData.docId,
          params?.rowData.docGuid,
          'OwnerShipTransfer'
        );
        this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
          this.openTemplate(this.transferOwnership);
          this.isTransferModalAllowed = true;
        })

      }
      else {
        this.isSelectedOnceDetails = true;
        this.openTemplate(this.transferOwnership);
        this.isTransferModalAllowed = true;
      }
    }
  }
  /**
     * isSelectedOnceDetailsPrint variable
     */
  isSelectedOnceDetailsPrint = false;
  selectedDocumentName: any;
  /**
     * openModelPrintThree function
     */
  selectDocumentName: any;
  openModelPrintThree(params) {
    let selectedrowData = this.getSelectedRowData(params?.rowData);
    if (!this.isSelectedOnceDetailsPrint && selectedrowData[0].docId == params?.rowData.docId) {
      if (this.flagClick) {
        this.isSelectedOnceDetailsPrint = true;
        const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'Print')
        this.flagClick = '';
        this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
          if (response.data && response.data[0].docShareStatus === this.notAccess) {
            this.openModal(this.wsOutSync, false);
          } else {
            this.selectedDocs = [];
            this.selectDocumentName = params?.rowData.docrename
            let pribtObj = { fileName: this.selectDocumentName, loader: true, docId: params?.rowData?.docId }

            this.selectedDocs.push(params?.rowData);
            this.printservice.setSelectedDocs(this.selectedDocs);
            this.printChild.checking(pribtObj);
            this.printservice.validateAndprintDoc();
            this.sharedService.printService.next(true);
          }
        })
      }

    }
  }
  /**
     * openTemplate function
     */

  public openTemplate(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }
  /*
   *For List View show
   */
  onGridClick() {
    this.listBg = false;
  }

  /**
   *For Grid View show
   */
  onListClick() {
    this.listBg = true;
  }
  /**
     * selectedItem variable
     */
  selectedItem = '50';
  /**
   * changeValue variable
   */
  changeValue;
  /**
     * onPageSize function
     */
  onPageSizeChanged(value: string) {

    var pagesize_value = value;
    this.changeValue = value;

    this.gridApi.paginationSetPageSize(Number(pagesize_value));
    this.sharedservice.searchPaginationChanged.next(true);
    this.currentPageVal = 0;
    this.setText(
      "#lbCurrentPage",
      this.currentPageVal
    );
    // setTimeout(() => {
    //   localStorage.setItem('PageNumberSearch','0')
    // }, 100);
  }
  /**
     * noRowsTemplate variable
     */
  noRowsTemplate: any;
  /**
     * selectedFileNames variable
     */
  selectedFileNames = '';
  /**
     * numberofDocsSelected variable
     */
  numberofDocsSelected: number;
  /**
     * docGuidList variable
     */
  docGuidList = '';
  /**
     * tooltipShowDelay variable
     */
  tooltipShowDelay = 0;
  /**
     * docList variable
     */
  doclist: string = '';
  sortingString: string = '';
  diffrenceValidate: any = [];
  name: any;
  date: any;
  /**
     * docListDelete variable
     */
  doclistDelete: string = '';
  @ViewChild(DocPreviewComponent) previewchild: DocPreviewComponent
  /**
   * User Role Access Point
   */

  UserRoleAccess(data?) {
    let status;
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": data[0]?.docId,
      "role": "View",
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid": this.getGuid,
      "DocGuid": data[0]?.docGuid
    }
    this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((res: any) => {
      status = res.data[0].docShareStatus;
      localStorage.setItem("docShareStatus", status)
      this.selectedDocs.forEach((element) => {
        if (data[0]?.docId === element?.docId) {
          element['docShareStatus'] = status;
        }
      })
      console.log(this.selectedDocs)
    })
    return status;
  }

  isDownloadModalOperationInProgress = false;
  disabledownload = () => {
    this.isDownloadModalOperationInProgress = true;
    setTimeout(() => {
      this.isDownloadModalOperationInProgress = false;
    }, 700);
  }

  /**
   * function to resolve multiple guest modal opening requests
   */
  isGuestModalOperationInProgress = false;
  disableGuest = () => {
    this.isGuestModalOperationInProgress = true;
    setTimeout(() => {
      this.isGuestModalOperationInProgress = false;
    }, 700);
  }

  /**
   * function to resolve multiple download modal opening requests
   */
  isDeleteModalOperationInProgress = false;
  disabledelete = () => {
    this.isDeleteModalOperationInProgress = true;
    setTimeout(() => {
      this.isDeleteModalOperationInProgress = false;
    }, 700);
  }

  /**
     * onSelectionChanged function
     */
  onSelectionChanged(params) {
  this.isDownloadDisabled = false;
  console.log(params.columnApi.getColumnState())
  let multiDocsWithStatus = this.selectedDocs.filter((obj) => (obj.parserstatuscode == 'doc_21' || obj.parserstatuscode == 'doc_23' || obj.parserstatuscode == 'doc_15'));
  if (multiDocsWithStatus.length > 0) {
    this.isDownloadDisabled = true;
  } else {
    this.isDownloadDisabled = false;
  }
  let array=[]
  let Sorting =false
  for (let i of params.columnApi.getColumnState()){
      array.push(i.sort)
    }
    for (let i = 0; i < this.diffrenceValidate.length; i++) {
      if ((array[i] !== this.diffrenceValidate[i])) {
        Sorting = true
      }
    }
    if (Sorting || this.diffrenceValidate.length === 0) {
      this.diffrenceValidate = array
      let name = params.columnApi.getColumnState().filter(s => s.sort !== null && s.colId === "neweDocRename")
      let date = params.columnApi.getColumnState().filter(s => s.sort !== null && s.colId === "addedDateTime")
      name = name[0]?.sort
      date = date[0]?.sort
      if (name && name !== this.name) {
        this.date = date
        if (name == 'asc') {
          this.sortingString = 'DocRename asc'
        }
        else {
          this.sortingString = 'DocRename DESC'
        }
      }
      if (date && date !== this.date) {
        this.name = name
        if (date == 'asc') {
          this.sortingString = 'DocVerificationDtm  asc'
        }
        else {
          this.sortingString = 'DocVerificationDtm  DESC'
        }

      }
      localStorage.setItem('PageNumberSearch', '0')
      this.currentPageVal = 1
      this.setText(
        "#lbCurrentPage",
        this.currentPageVal
      );
      this.searchingAPI()
    }
    this.previewchild.updateDocAuditLog()

    let val: any = this.selectedDocs.length;
    let tempdata1 = this.selectedDocs.findIndex(item => item.docShareStatus === this.notAccess)
    // if (tempdata1 != -1) {
    //   this.sharedService.closeDetailBar1.next();
    // }
    localStorage.setItem("selectedDocs", val)
    localStorage.setItem("selectedDocsNumber", String(this.selectedDocs.length));
    this.getThreeDotValidation();
    if (val > 0) {
      this.previewchild.share(this.selectedDocs);
      this.sharedService.docPreview.next(this.selectedDocs);
    }
    var selectedRows = this.gridApi.getSelectedRows();
    let flag = this.showDeleteUser()
    this.previewValidate();
    let flags = this.previewValidate()
    let isAllOwned = this.selectedDocs?.every((e) => {
      return e.docOwnerEmailId == this.createdBy;
    })
    localStorage.setItem('isAllOwned', JSON.stringify(isAllOwned))
    localStorage.setItem('accessRights', String(flags))
    localStorage.setItem('DocOwner', selectedRows[0]?.docOwnerEmailId)
    this.getIds();
    this.getSelectedRowData(this.selectedDocs[0])
    localStorage.setItem('sharedDoc', selectedRows[0]?.docShareStatus);
    localStorage.setItem("docId", this.selectedDocs[0]?.docId)
    var selectedRowsString = '';
    var maxToShow = 5;
    let docListNew = '';
    let docGuidNew = '';
    selectedRows.forEach(function (selectedRow, index) {
      if (index > 0) {
        selectedRowsString += '|';
        docListNew += '|'
        docGuidNew += '|'
      }
      localStorage.setItem('selectedDocOwnedBy', selectedRows[0]?.docOwnerEmailId);
      localStorage.setItem('docOwnerEmailId', selectedRows[0]?.docOwnerEmailId)

      selectedRowsString += selectedRow.originalFileName;
      docListNew += selectedRow.docId;
      docGuidNew += selectedRow.docGuid;
    });

    this.selectedFileNames = selectedRowsString;
    this.doclist = docListNew;
    this.docGuidList = docGuidNew;
    this.doclistDelete = docListNew;
    this.numberofDocsSelected = selectedRows.length;
    this.showDeleteUser();
    localStorage.setItem("deleteRights", String(this.showDeleteUser()))
    localStorage.setItem("downloadRight", String(this.showDownload()))
    localStorage.setItem('TransferOwnerShipValidation', String(this.showTransferOwnerShip()))
    for (let i = 0; i <= this.selectedDocs.length; i++) {

    }
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    this.cellData = selectedData[0]
    localStorage.setItem("docId", this.cellData?.docId)
    this.rightnavchild.userName = '';
    this.rightnavchild.userAndGroupName = '';
    this.onVerifyDocDetails(this.cellData)
    setTimeout(() => {
      this.rightnavchild.getSharedDetails();
    }, 300);
    this.sharedService.cellClickedData.subscribe((data) => {
      this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
    })
  }
  /**
    * resetSelected function
    */
  resetSelected(event) {

    if (event) {
      this.selectedDocs = [];
    }
    this.isSelectedOnceDetailsDelete = false;
  }
  /**
     * docOwnerEmailId variable
     */
  docOwnerEmailId: any;
  /**
   * deleteRights variable
   */
  deleteRights: any;
  /**
   * showDeleteUser function
   */
  showDeleteUser() {
    if (this.selectedDocs.length > 0 && this.selectedDocs.length === 1) {
      this.docOwnerEmailId = this?.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
        *Return if user is owner of selected document
       */
      let flag = false;
      let isSharedRights = localStorage.getItem('isSharedRights');

      // if((this.selectedDocs[0].docOwnerEmailId == this.createdBy) || (isSharedRights == 'true' && this.deleteRights)){
      //   flag= true;
      //   let isAllOwned = true;
      //   localStorage.setItem('isAllOwned', JSON.stringify(isAllOwned))
      // }
      // this.data.showDelete = flag;

      if (((this.selectedDocs[0].docOwnerEmailId === this.createdBy) || (localStorage.getItem('deleteaccess') === 'true') && this.selectedDocs[0].docShareStatus === 'Access')) {
        flag = true;
      }
      // else if(((this.selectedDocs[0].docOwnerEmailId !== this.createdBy) && (this.deleteRights)) && this.selectedDocs[0].docShareStatus == 'Access'){
      //   flag= true;
      // }
      this.datanew.showDelete = flag;

      return flag;

    }

    /*
      *for multiple docs select delete option show
    */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
         *Return if user is owner of selected document
       */
      let flag = false;
      let isSharedRights = localStorage.getItem('isSharedRights');
      let allSharedData = JSON.parse(localStorage.getItem('allSharedData'));

      let tempdata = this.selectedDocs.findIndex(item => item.docOwnerEmailId !== this.createdBy)
      let tempdata1 = this.selectedDocs.findIndex(item => item.docShareStatus !== 'Access' && item.docShareStatus !== "Shared");
      if (tempdata1 !== -1) {
        flag = false;
      } else {
        if (localStorage.getItem('deleteaccess') === 'true') {
          flag = true;
        }
        else {
          if (tempdata == -1) {
            flag = true;
          } else {
            flag = false;
          }
        }
      }

      return flag;
      //   let sharedArray = [];
      //   let none = (arr, callback) => !arr.some(callback)

      //   this.selectedDocs.forEach((e)=>{
      //     let data = allSharedData.filter((e1)=>{
      //      return e1.documentDetailsList[0].docId == e.docId;
      //     });
      //     if(!data[0]){
      //       return;
      //     }
      //     let newData = data[0].documentShareUserList;
      //     let isSharedIndex =  newData.findIndex((e)=>{
      //      return e.userEmailID == this.createdBy;
      //     })
      //     let json:any;
      //     if(isSharedIndex>-1){
      //        json = {'docId':e.docId,'isShared':true}
      //     } else{
      //       json = {'docId':e.docId,'isShared':false}
      //     }
      //     sharedArray.push(json);
      //   })
      //   let someSharedCount = 0;
      //    sharedArray.forEach((e)=>{
      //     if(e.isShared == true){
      //       someSharedCount++;
      //     }
      //   });
      //   let isSomeShared = false;
      //   if(someSharedCount>0){
      //     isSomeShared = true;
      //   }

      //   let isAllShared = sharedArray.every((e)=>{
      //     return e.isShared == true
      //   })
      //   let isAllOwned = this.selectedDocs.every((e)=>{
      //     return e.docOwnerEmailId == this.createdBy;
      //   })
      //   let someOwnedCount = 0;
      //   let isSomeOwned = false;
      //    this.selectedDocs.forEach((e)=>{
      //      let index = sharedArray.findIndex((e1)=>{
      //        return e1.docId == e.docId;
      //      })
      //      if((index >-1  && !sharedArray[index].isShared) || index== -1){
      //       if(e.docOwnerEmailId == this.createdBy){
      //         someOwnedCount++;
      //         return true;
      //       }
      //      }
      //   })
      //   if(someOwnedCount>0){
      //     isSomeOwned = true;
      //   }
      // let isNoneOwned = none(this.selectedDocs, el => el.docOwnerEmailId === this.createdBy)
      // let isNoneShared = none(sharedArray, el => el.isShared === true)
      // let cummilativeCount = someSharedCount + someOwnedCount;


      // if(this.deleteRights){
      //   if((isSomeOwned || isSomeShared) && (cummilativeCount == this.selectedDocs.length)){
      //     flag = true;
      //   }else{
      //     flag = false;
      //   }


      // } else if(!this.deleteRights){
      //   flag = false;
      // }

      //     localStorage.setItem('isAllOwned', JSON.stringify(isAllOwned))

      // if(isAllOwned){
      //   flag = true;
      // }



      //   this.data.showDelete = flag;
      //   return flag;
    }

  }
  /**
  * onFirstDataRendered function
  */
  onFirstDataRendered(params) {
    params?.api.sizeColumnsToFit();
  }
  /**
    * clickedData variable
    */
  clickedData;
  /**
     * documentIdValue variable
     */
  documentIdValue
  /**
    * onCellClicked function
    */
  isAllStatus: boolean
  onVerifyDocDetails(params) {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postdata = {
      "DocGuid": params?.docGuid,
      "DocID": params?.docId,
      "WsLink": arr[2],
      "Wsoid": Number(localStorage.getItem('localwsId')),
      "currentUser": localStorage.getItem('localloginId'),
    }
    this.data1.verifierDocDetailsService(postdata).subscribe((customTags: any) => {
      if (customTags.isSuccess) {
        this.sharedService.documentId = customTags?.data.docverificationdetailslists[0].docId;
        this.isAllStatus = customTags?.data.docverificationdetailslists[0].isAllUserShare;
        this.sharedService.isShare.next(this.isAllStatus);
      }
    })
  }

  onCellClicked(cell) {
    //   if (cell.column.colId !== 'name') { // cell is from non-select column
    //     cell.node.setSelected(true);
    // }
    this.cellData = cell.data;
    this.clickedData = cell;
    let tempdata1 = this.selectedDocs.findIndex(item => item.docShareStatus === this.notAccess)
    // if (tempdata1 != -1) {
    //   this.sharedService.closeDetailBar1.next();
    // }
    this.getIds();
    localStorage.setItem("docId", this.cellData.docId)
    this.rightnavchild.userName = '';
    this.rightnavchild.userAndGroupName = '';
    this.rightnavchild.getSharedDetails();
    this.onVerifyDocDetails(cell.data);
    localStorage.setItem('selectedDocOwnedBy', cell.data.docOwnerEmailId);

    this.previewValidate();
    this.getThreeDotValidation();
    this.docOwnerEmailIdNew = cell.data.docOwnerEmailId
    this.sharedService.ownerShipRight.next(this.showTransferOwnerShip())
    this.clickedData = cell;
    this.flagClick = 'data1';
    this.documentIdValue = cell.data.docId;
    this.getSelectedRowData(this.clickedData.data)
    localStorage.setItem('sharedDoc', this.selectedDocs[0].docShareStatus);
    localStorage.setItem("deleteRights", String(this.showDeleteUser()))
    localStorage.setItem('TransferOwnerShipValidation', String(this.showTransferOwnerShip()))
    this.accessCheck(this.clickedData.data);
  }
  /**
   * sharedAccess variable
   */
  sharedAccess = '';
  /**
   * accessCheck function
   */
  accessCheck(params?) {
    let selectedRowData = this.getSelectedRowData(params?.rowData);
    if ((selectedRowData[0]?.docId == params?.rowData?.docId || selectedRowData[0]?.docId == params?.docId) && ((this.shiftKey !== 'Shift' && this.shiftKeyCode !== 16))) {

      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(params?.rowData?.docId || params?.docId),
        "role": "View",
        "database": "get2doxMaster",
        "createdby": this.createdBy,
        "Isdeleteby": false,
        "guid": this.getGuid,
        "DocGuid": params?.docGuid
      }
      this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (response.data[0].docShareStatus === this.notAccess) {
            params.docShareStatus = response.data[0].docShareStatus;
            this.sharedService.threeDotValue.next(params);
            this.sharedAccess = this.notAccess;
          } else {
            params.docShareStatus = response.data[0].docShareStatus;
            this.sharedService.threeDotValue.next(params);
            this.sharedAccess = 'Access';
          }
        } else {
          this.openModal(this.wsOutSync, false);
        }

      }, error => {

      });
    }
  }
  /**
    * everyRowValue variable
    */
  everyRowValue = [];
  /**
     * currentPageData function
     */

  currentPageData;
  /**
     * maxIndexVal function
     */

  maxIndexVal;
  /**
    * getValueMyData function
    */
  getValueMyData;
  /**
   * getVeryVal variable
   */
  getVeryVal;
  /**
   * allPartData variable
   */
  allPartData;
  /**
  * makeCheck variable
  */
  makeCheck = [];
  /**
  * getRowClass variable
  */
  getRowClass = params => {

    this.everyRowValue = [];
    this.getValueMyData = params?.node.rowModel.rowsToDisplay;
    for (let i = 0; i <= this.getValueMyData.length - 1; i++) {
      this.getVeryVal = this.getValueMyData[i]
      this.allPartData = this.getVeryVal.data;
      this.everyRowValue.push(this.allPartData)
    }

    this.currentPageData = params?.api.paginationProxy.currentPage + 1;


    if (!this.regardingSearch) {
      if (this.currentPageData == 1) {
        this.sendDetailsData = this.everyRowValue[0];
        const check = this.makeCheck.indexOf(this.currentPageData);
        if (check === -1) {
          this.makeCheck.length = 0;
          this.sendDetailValue(this.sendDetailsData);
          this.makeCheck.push(this.currentPageData);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentPageData) {
            this.makeCheck.length = 0;
            this.sendDetailValue(this.sendDetailsData);
            this.makeCheck.push(check);
          }
        }
      }
      else if (this.currentPageData == 2) {
        this.maxIndexVal = params?.api.paginationProxy.pageSize;
        this.sendDetailsData = this.everyRowValue[this.maxIndexVal];

        const check = this.makeCheck.indexOf(this.currentPageData);
        if (check === -1) {
          this.sendDetailValue(this.sendDetailsData);
          this.makeCheck.length = 0;
          this.makeCheck.push(this.currentPageData);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentPageData) {
            this.makeCheck.length = 0;
            this.sendDetailValue(this.sendDetailsData);
            this.makeCheck.push(check);
          }
        }
      }
      else {
        this.maxIndexVal = params?.api.paginationProxy.pageSize * (this.currentPageData - 1);
        this.sendDetailsData = this.everyRowValue[this.maxIndexVal];

        const check = this.makeCheck.indexOf(this.currentPageData);
        if (check === -1) {
          this.sendDetailValue(this.sendDetailsData);
          this.makeCheck.length = 0;
          this.makeCheck.push(this.currentPageData);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentPageData) {
            this.makeCheck.length = 0;
            this.sendDetailValue(this.sendDetailsData);
            this.makeCheck.push(check);
          }
        }
      }
    }
  };
  /**
   * paginationChangeValCurrent variable
   */
  paginationChangeValCurrent;
  /**
  * paginationChangeValPagesSize variable
  */
  paginationChangeValPagesSize;

  /**
   * totalPagesChk variable
   */
  totalPagesChk;
  /**
   * pageSizeTotal variable
   */
  pageSizeTotal;
  /**
   * totalPagesCount variable
   */
  totalPagesCount;
  /**
   * nextBtnDisable variable
   */
  nextBtnDisable = false;
  /**
   * totalData variable
   */
  totalData;
  /**
   * totalPages_pre variable
   */
  totalPages_pre;
  /**
   * setText function
   */
  currentDataValue = '';
  setText(selector, text) {

    document.querySelector(selector).innerHTML = text;
    if (selector == '#lbTotalPages') {
      this.totalPagesChk = document.querySelector(selector).innerHTML;
    }
    if (selector == '#lbPageSize') {
      this.pageSizeTotal = document.querySelector(selector).innerHTML;
    }
    if (selector == '#lbCurrentPage') {
      this.currentDataValue = document.querySelector(selector).innerHTML;
    }
    if (this.totalPagesChk && this.pageSizeTotal) {
      this.totalData = this.totalPagesChk / this.pageSizeTotal;
      if (this.totalData > 0) {
        let parseInitTotal = parseInt(this.totalData);
        if (parseInitTotal > 0) {

          this.totalPages_pre = this.totalPagesChk / this.pageSizeTotal

          let totalPages = (this.totalPagesChk % this.pageSizeTotal) == 0 ? this.totalPages_pre : parseInt(this.totalPages_pre) + 1
          this.totalPagesCount = totalPages;
        }
        else {
          this.totalPagesCount = 1;
        }
      }
      else {
        this.totalPagesCount = 1;
      }

    }
  }
  /**
   * gridOptions variable
   */
  gridOptions;
  /**
   * currentPageVal variable
   */
  currentPageVal;
  /**
   * getItemPerPage variable
   */
  getItemPerPage;
  /**
   * onPaginationChanged function
   */
  onPaginationChanged(event) {
    if ((this.getItemPerPage !== undefined)) {
      if (this.getItemPerPage !== this.selectedItem) {
        this.gridOptions = event;
        if (event.api) {
          this.setText(
            "#lbLastPageFound",
            event.api.paginationIsLastPageFound()
          );
          this.setText("#lbPageSize", this.selectedItem);
          this.setText(
            "#lbCurrentPage",
            event.api.paginationGetCurrentPage() + 1
          );
          if (this.paginationThrough == 'usingPagination') {

            this.setText(
              "#lbCurrentPage",
              JSON.parse(this.currentPageValChange.currentpage) + 1
            );

          }
          else {
            this.currentPageVal = event.api.paginationProxy.currentPage;
            this.showingVal = this.currentPageVal
          }
          this.setText("#lbTotalPages", this.docCountTotal);

          this.pageChangeData(this.currentPageVal, this.selectedItem)
          this?.gridApi?.deselectAll();

        }
        this.getItemPerPage = this.selectedItem;
      }
      else {
        this.getItemPerPage = this.selectedItem;
        this.gridOptions = event;
        if (event.api) {
          this.setText(
            "#lbLastPageFound",
            event.api.paginationIsLastPageFound()
          );
          this.setText("#lbPageSize", this.selectedItem);
          this.setText(
            "#lbCurrentPage",
            event.api.paginationGetCurrentPage() + 1
          );
          if (this.paginationThrough == 'usingPagination') {

            this.setText(
              "#lbCurrentPage",
              JSON.parse(this.currentPageValChange.currentpage) + 1
            );

          }
          else {
            this.currentPageVal = event.api.paginationProxy.currentPage;
            this.showingVal = this.currentPageVal
          }
          this.setText("#lbTotalPages", this.docCountTotal);


          this?.gridApi?.deselectAll();

        }
      }


    }
    else {

      this.getItemPerPage = this.selectedItem;
      this.gridOptions = event;
      if (event.api) {
        this.setText(
          "#lbLastPageFound",
          event.api.paginationIsLastPageFound()
        );
        this.setText("#lbPageSize", this.selectedItem);
        this.setText(
          "#lbCurrentPage",
          event.api.paginationGetCurrentPage() + 1
        );
        if (this.paginationThrough == 'usingPagination') {

          this.setText(
            "#lbCurrentPage",
            JSON.parse(this.currentPageValChange.currentpage) + 1
          );

        }
        else {
          this.currentPageVal = event.api.paginationProxy.currentPage;
          this.showingVal = this.currentPageVal
        }
        this.setText("#lbTotalPages", this.docCountTotal);
        this?.gridApi?.deselectAll();

      }
    }
  }

  condition1 = 0

  condition = 0
  /**
   * onButtontFirst function
   */
  onBtFirst() {
    this.searchSpinnerNew = true
    if (this.condition == 0) {
      // setTimeout(() => {
      //   this.searchSpinnerNew = true
      // }, 100)
      this.condition++
      this.currentPageVal = 0;
      this.showingVal = 0;
      let lbCurrentPage = document.getElementById('lbCurrentPage').innerHTML;
      if (lbCurrentPage && Number(lbCurrentPage) !== 0 && Number(lbCurrentPage) !== 1) {
        this.setText(
          "#lbCurrentPage",
          1
        );
        let temp = {
          currentpage: this.currentPageVal,
          pageSize: this.selectedItem
        }
        this.pageChangeData(this.currentPageVal, this.selectedItem);
        //this.searchingAPI();
      }
      setTimeout(() => {
        this.condition = 0
        this.searchSpinnerNew = false
      }, 2500);
    }


  }

    /**
   * Method to hide threedot component on scroll
   */
    hideThreeDotComp() {
      let element = document.getElementsByClassName('context-menu');
      element[0].classList.add('displayChange')
      this.threeDot?.hide();
      this.clearOverlayContainer();
      
  }

  /**
   * method to empty the overlay container on scroll which shows threedot component
   */
  clearOverlayContainer() {
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    overlayContainerElement.innerHTML = ''; 
  }

  /**
   * onBtLast function
   */
  onBtLast() {
    // setTimeout(() => {
    //   this.searchSpinnerNew = true
    // }, 100)
    this.searchSpinnerNew = true
    if (this.condition == 0) {


      this.condition++
      this.currentPageVal = this.totalPagesCount - 1;
      this.showingVal = this.totalPagesCount - 1;
      let lbCurrentPage = document.getElementById('lbCurrentPage').innerHTML;
      if (lbCurrentPage && Number(lbCurrentPage) !== this.totalPagesCount) {
        this.setText(
          "#lbCurrentPage",
          this.totalPagesCount
        );
        /**
         * temp variable
         */
        let temp = {
          /**
           * current page variable
           */
          currentpage: this.currentPageVal,
          /**
           * pageSize variable
           */
          pageSize: this.selectedItem
        }
        this.pageChangeData(this.currentPageVal, this.selectedItem);
      }
      setTimeout(() => {
        this.condition = 0
        this.searchSpinnerNew = false
      }, 2500);
    }

  }


  /**
   * showingVal variable
   */
  showingVal;
  /**
   * onButtontNext function
   */
  onBtNext1 = 0
  onBtNext() {
    this.searchSpinnerNew = true
    if (this.condition == 0) {


      //this.searchSpinnerNew = false
      this.condition++
      this.nextBtnDisable = false;
      //this.searchSpinnerNew=true
      let lbCurrentPage = document.getElementById('lbCurrentPage').innerHTML;

      if (this.totalPagesCount <= (this.currentPageVal + 1)) {
        this.nextBtnDisable = true;
      }

      else {
        this.nextBtnDisable = false;
      }
      if (!this.nextBtnDisable) {
        this.currentPageVal = this.currentPageVal + 1;

        this.showingVal = this.currentPageVal;
        this.setText(
          "#lbCurrentPage",
          this.currentPageVal
        );
        let temp = {
          currentpage: this.currentPageVal,
          pageSize: this.selectedItem
        }

        this.pageChangeData(this.currentPageVal, this.selectedItem)

      }
      setTimeout(() => {
        this.condition = 0;
        this.searchSpinnerNew = false
      }, 2500);

    }

  }
  /**
   * pageChangeData function
   */
  pageChangeData(currentpage, pagesize) {
    let joinData = currentpage + ',' + pagesize
    this.sharedservice.paginationChanging.next(joinData);
    // setTimeout(() => {
    //   this.searchSpinnerNew = true
    // }, 100)
  }
  /**
   * previousVal variable
   */
  previousVal;
  /**
   * changesCount variable
   */
  changesCount;
  /**
   * onBtPrevious function
   */
  onBtFirst2 = 0
  onBtPrevious() {
    // this.searchSpinnerNew=true;
    // setTimeout(() => {
    //   this.searchSpinnerNew = true
    // }, 100)
    this.searchSpinnerNew = true
    if (this.condition == 0) {

      let lbCurrentPage = document.getElementById('lbCurrentPage').innerHTML;
      this.condition++
      if (this.currentPageVal !== 0) {
        if (this.changesCount) {
          this.currentPageVal = this.changesCount;
          this.showingVal = this.currentPageVal
        }
        else {
          if (this.currentPageVal == 1) {
            this.showingVal = 1;
            this.currentPageVal = 0
          }
          else {
            this.showingVal = this.currentPageVal
          }
        }
        this.setText(
          "#lbCurrentPage",
          this.showingVal
        );
        if (lbCurrentPage && Number(lbCurrentPage) >= 2) {
          this.currentPageVal = Number(lbCurrentPage) - 2;
        }
        let temp = {
          currentpage: this.showingVal - 1,
          pageSize: this.selectedItem
        }
        this.changesCount = this.showingVal - 1
        this.pageChangeData(this.changesCount, this.selectedItem)
      }
      setTimeout(() => {
        this.condition = 0
        this.searchSpinnerNew = false
      }, 2500);
    }


  }
  /**
     * selectedDocs variable
     */
  selectedDocs = [];
  /**
     * valueChangeData variable
     */
  valueChangeData;
  /**
     * getSelectedRowData fun
     */
  getSelectedRowData(params) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    if (selectedNodes.length >= 1) {
      this.docSelected = selectedNodes.length;
      this.docSelectedByUser = true;
      let tempdata1 = this.selectedDocs.filter(item => item.docShareStatus == "Not Access")
      console.log("tempdata1", tempdata1.length);
      if(tempdata1.length == 0) {
        this.docSharedTrue = false;
      }
      else {
        this.docSharedTrue = true;
        this.docShared = tempdata1.length;
      }
      
    }

    else {
        this.docSelectedByUser = false;
    }
    let threeDotElements = document.querySelectorAll("#threedoticon");
    if (selectedNodes.length > 1) {
      console.log('Multiple rows are selected:');
      
      threeDotElements.forEach(threeDotElement => {
        threeDotElement.classList.add("disabled-button");
      });
    } else {
      threeDotElements.forEach(threeDotElement => {
        threeDotElement.classList.remove("disabled-button");
      });
    }

    let selectedData = selectedNodes.map(node => node.data);
    this.selectedDocsCheck = selectedData;
    this.selectedDocsCheck.forEach((document) => {
      let postdata = {
        "DocGuid": document.docGuid,
        "DocID": document.docId,
        // "WsLink": arr[2],
        "Wsoid": Number(localStorage.getItem('localwsId')),
        "currentUser": localStorage.getItem('localloginId'),
      }
      this.data1.verifierDocDetailsService(postdata).subscribe((res: any) => {
        if (res.isSuccess) {
          if (document.docId == res?.data.docverificationdetailslists[0].docId) {
            if (res?.data.docverificationdetailslists[0].hasOwnProperty('externalSol')) {
              document.externalSol = res?.data.docverificationdetailslists[0].externalSol;
            }
          }
        }
      })
    });
    this.selectedDocs = this.selectedDocsCheck;
    this.sharedService.selectedDocsData.next(this.selectedDocs);
    for (let i = 0; i <= this.selectedDocs.length - 1; i++) {
      if (this.valueChangeData != 'View') {
        if (params) {
          if (params?.docId == this.selectedDocs[i].docId) {
            this.onVerifyDocDetails(this.selectedDocs[0])
            this.rightnavchild.getSharedDetails();
            this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
            this.sendDetailValue(this.selectedDocs[this.selectedDocs.length - 1]);
          }
        }
      }
      else {
        this.valueChangeData = '';
      }

    }

    if (this.selectedDocs.length > 1) {
      this.multiDoc = true;
    }
    if (this.selectedDocs.length == 0 || this.selectedDocs.length == 1) {
      let val = 'single'
      this.sharedService.threeDotValue.next(val);
      localStorage.setItem("deleteRights", String(this.showDeleteUser()))
      localStorage.setItem("downloadRight", String(this.showDownload()))
      localStorage.setItem('TransferOwnerShipValidation', String(this.showTransferOwnerShip()))
    } else {
      let val = 'multi'
      this.sharedService.threeDotValue.next(val);
      localStorage.setItem("deleteRights", String(this.showDeleteUser()))
      localStorage.setItem("downloadRight", String(this.showDownload()))
      localStorage.setItem('TransferOwnerShipValidation', String(this.showTransferOwnerShip()))
    }
    this.printservice.setSelectedDocs(this.selectedDocs);
    this.sizeConvert();
    return selectedData;
  }
  count = 0;
  openShareCommonModal(params?) {
    this.onVerifyDocDetails(this.selectedDocs[0])
    this.count = Number(localStorage.getItem('uploadTime'))
    if (this.count == 0) {
      setTimeout(() => {
        if (this.selectedDocs.length > 1) {
          const ids = []
          const docVerifierEmailId = [];
          const docOwnerEmailId = [];
          const selectedGuid = [];
          this.selectedDocs.forEach(obj => {
            ids.push(obj.docId)
            docVerifierEmailId.push(obj.verifiedByEmailId);
            docOwnerEmailId.push(obj.docOwnerEmailId)
            selectedGuid.push(obj.docGuid)
          })
          let tempdata = {
            owner: docOwnerEmailId[0],
            verifier: docVerifierEmailId[0],
            docId: ids[0],
            bydefault: ''
          }
          this.sharedService.isShare.next(this.isAllStatus);
          this.sharedService.threeDotValue.next('multi')
          this.shareuse.shareWithMutipleUsers(ids, selectedGuid, tempdata);
        }
        else {
          this.cellData = this.selectedDocs[0];
          let selectedrowData = this.getSelectedRowData(this.cellData);
          if (selectedrowData[0].docId == this.cellData.docId) {
            this.verifierEmailID = this.cellData.verifiedByEmailId;
            this.ownerEmailID = this.cellData.docOwnerEmailId;
            // this.selectedDocs.push(params?.rowData);
            let tempdata = {
              owner: this.ownerEmailID,
              verifier: this.verifierEmailID,
              docId: this.cellData.docId,
              bydefault: ''
            }
            this.sharedService.isShare.next(this.isAllStatus);
            this.shareuse.shareUsersMqueue(tempdata);
          }
        }
      }, 400);
    }
    this.count++;
    localStorage.setItem('uploadTime', '1');

  }
  /**
     * selectedDocSize var
     */
  selectedDocSize = 0;
  /**
     * sizeConvert function
     */
  sizeConvert() {
    this.selectedDocSize = 0;
    this.selectedDocs.forEach(element => {
      if (element.size.includes('KB')) {
        this.selectedDocSize += Number(element.size?.split(" ")[0]);
      }
      else if (element.size.includes('MB')) {
        this.selectedDocSize += Number(element.size?.split(" ")[0]) * 1024;
      }
      else if (element.size.includes('GB')) {
        this.selectedDocSize += Number(element.size?.split(" ")[0]) * 1024 * 1024;
      }
    });
  }
  /**
     * pendingRequest var
     */
  pendingRequest;
  /**
     * rightNavDataDetail var
     */
  rightNavDataDetail;
  /**
     * sendDetailValue function
     */
  sendDetailValue(data, isGuest?) {
    if (data) {
      localStorage.setItem('isSharedRights', data);
      this.rightNavDataDetail = data;
      this.rightNavChild.actdata(this.rightNavDataDetail, isGuest);
    }
    else {
      this.rightNavDataDetail = '';
      this.rightNavChild.actdata(this.rightNavDataDetail, isGuest);
    }

  }
  /**
     * deleted function
     */
  deleted = "Trash";
  /**
     * DetailAndActivityComponent Component
     */
  @ViewChild(DetailAndActivityComponent) rightNavChild: DetailAndActivityComponent;
  /**
     * mini profile Component
     */
  @ViewChild('miniprofile', { read: TemplateRef }) miniprofile: TemplateRef<any>;
  /**
     * transferOwnership Component
     */
  @ViewChild('transferOwnership', { read: TemplateRef }) transferOwnership: TemplateRef<any>;
  /**
     * DeleteDocsComponent Component
     */
  @ViewChild(DeleteDocsComponent) deleteChild: DeleteDocsComponent;
  /**
     * mini profile View Component
     */
  @ViewChild('miniprofileview', { read: TemplateRef }) miniprofileview: TemplateRef<any>;
  /**
     * wsOutSync Component
     */
  @ViewChild('wsOutSync', { read: TemplateRef }) wsOutSync: TemplateRef<any>;
  /**
     * DownloadComponent Component
     */
  @ViewChild(DownloadComponent) downloadChild: DownloadComponent;
  /**
     * GuestUserModalComponent Component
     */
  @ViewChild(GuestUserModalComponent) guestChild: GuestUserModalComponent;
  /**
     * shareChild Component
     */
  @ViewChild('shareChild') shareuse: SharedAccessComponent;
  /**
     * shift key Code var
     */
  shiftKeyCode: number;
  /**
     * shift key var
     */
  shiftKey: string;
  /**
     * onViewClickThree function
     */
  flagClick = '';
  valuerowdata: any;
  onViewClickThree(params?) {
    let selectedData = params?.rowData;

    let selectvaluedoc = selectedData.docId;
    if (params?.rowData.docId) {
      this.valuerowdata = params?.rowData.docId;
    }
    else {
      this.valuerowdata = params?.docId;
    }
    if ((selectvaluedoc == this.valuerowdata) && ((this.shiftKey !== 'Shift' && this.shiftKeyCode !== 16))) {
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(params?.rowData?.docId || params?.docId),
        "role": "View",
        "database": "get2doxMaster",
        "createdby": this.createdBy,
        "Isdeleteby": false,
        "guid": this.getGuid,
        "DocGuid": params?.docGuid
      }
      this.flagClick = ''
      this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (response.data[0].docShareStatus === this.notAccess) {
            //this.openModal(this.wsOutSync, false);
          } else {
            localStorage.setItem('redirectedFrom', 'shared-with-me')

            const url = this.router.serializeUrl(
              this.router.createUrlTree(['view/view-document'], { queryParams: { docId: params?.rowData?.docId || params?.docId, docGuid: params?.rowData?.docGuid || params?.docGuid } })
            );

            window.open(url, '_blank');
          }

        } else {
          //this.openModal(this.wsOutSync, false);

        }
      }, error => {

      })

    }
  }

  onViewClickThreeDot(params?) {
    let selectedrowData = this.getSelectedRowData(params?.rowData);
    let selectedData = params?.rowData;

    let selectvaluedoc = selectedData.docId;
    if (params?.rowData.docId) {
      this.valuerowdata = params?.rowData.docId;
    }
    else {
      this.valuerowdata = params?.docId;
    }
    if ((selectedrowData[0].docId == this.valuerowdata) && ((this.shiftKey !== 'Shift' && this.shiftKeyCode !== 16))) {

      if (this.flagClick) {
        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(params?.rowData?.docId || params?.docId),
          "role": "View",
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getGuid,
          "DocGuid": params?.docGuid
        }
        this.flagClick = ''
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === this.notAccess) {
              this.openModal(this.wsOutSync, false);
            } else {
              localStorage.setItem('redirectedFrom', 'shared-with-me')

              const url = this.router.serializeUrl(
                this.router.createUrlTree(['view/view-document'], { queryParams: { docId: params?.rowData?.docId || params?.docId, docGuid: params?.rowData?.docGuid || params?.docGuid } })
              );

              window.open(url, '_blank');
            }

          } else {
            this.openModal(this.wsOutSync, false);

          }
        }, error => {

        })
      }
    }
  }

  /**
     * openModal function
     */

openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick:boolean) {
  this.dialogRef = this.dialogService.open(dialogModal, {closeOnBackdropClick:false,
  closeOnEsc: false});

}


  /**
   * 
   * @param params This method will open the doc in Viewer Editor on double clicking on the row anywhere.
   */
  onRowDoubleClicked(params) {
    let data = params?.data
    params = { rowData: data }
    this.onViewClickThree(params);
   }

   
/**
   * createHyperLink function
   */
createHyperLink(params): any {

    if (!params?.data) { return; }
    const spanElement = document.createElement('span');
    if (params?.data.docShareStatus == 'Access') {
    spanElement.innerHTML = ` ${params?.value} `;
  }
  if (params?.data.docShareStatus == 'Not Access') {
    spanElement.innerHTML = `${params?.value} <img  src="../../../../../assets/images/SVG/eye-off.svg" style="vertical-align: middle;">`
    spanElement.classList.add('hideImage');
  }

    spanElement.style.cursor = "pointer";
    spanElement.style.overflow = "hidden";
    spanElement.style.textOverflow = "ellipsis";
    spanElement.addEventListener('click', ($event) => {
      if (params?.data.docShareStatus?.toLowerCase() !== 'not access') {
        let data = params?.data
        if (params?.column.colId == "docrename") {
          this.flagClick = 'data1'
          let params = { rowData: data }

          //this.onViewClickThree(params);
        }


        $event.preventDefault();
      }
    });
    return spanElement;

  }
  /**
     * isSelectedOnceDetailsEdit variable
     */
  isSelectedOnceDetailsEdit;
  /**
     * onEditClick function
     */

  onEditClick(params) {
    let selectedrowData = this.getSelectedRowData(params?.rowData);
    if (selectedrowData[0].docId == params?.rowData.docId) {
      if (this.flagClick) {
        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(params?.rowData.docId),
          "role": "Edit",
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getGuid,
          "DocGuid": params?.docGuid
        }
        this.flagClick = '';
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === this.notAccess) {
              this.openModal(this.wsOutSync, false);
            } else {
              localStorage.setItem('isSave', 'false')
              localStorage.setItem('redirectedFrom', 'shared-with-me')
              const url = this.router.serializeUrl(
                this.router.createUrlTree(['edit/edit-document'], { queryParams: { docId: params?.rowData.docId, docGuid: params?.rowData.docGuid } })
              );

              window.open(url, '_blank');
            }

          }
        }, error => {

        })
      }

    }
  }

  /**
     * isUserGrp var
     */
  isUsersGroup: any;
  /**
   * isUserGrp function
   */

  isUserGrp($event) {
    this.isUsersGroup = $event;
    this.cd.detectChanges();
  }
  /**
     * showVerifier var
     */
  showVerifier: boolean;
  /**
     * isCheckedAllUsers var
     */
  isCheckedAllUsers: any;
  /**
     * verifierName var
     */

  verifierName: any;
  /**
   * sharedGroup function
   */
  sharedGroup($event) {
    this.setUserGroupArr = $event;
    if (this.setUserGroupArr.length > 0 && !this.isCheckedAllUsers) {
      this.isAllUserShare = false;
    }
    if (this.setUserGroupArr.includes(this.verifierName)) {
      this.showVerifier = true;
    } else {
      this.showVerifier = false;
    }

  }

  /**
   * isCheckedAllUser function
   */
  isCheckedAllUser($event) {
    this.isCheckedAllUsers = $event;
    this.isAllUserShare = $event;
  }
  /**
    * isSelectedAllUsers var
    */
  isSelectedAllUsers: any;
  /**
    * isSelectedAllUser function
    */

  isSelectedAllUser($event) {
    this.isSelectedAllUsers = $event;
  }
  /**
    * shareUserCount variable
    */
  shareUserCount: any;
  /**
    * sharedGroupCount function
    */
  sharedGroupCount($event) {
    this.shareUserCount = $event;
  }
  /**
     * rightUserList var
     */
  rightUserList: any;
  /**
     * isSelectedOnceDetailsDownload var
     */
  isSelectedOnceDetailsDownload = false;
  /**
     * openModelDownloadThree function
     */
  openModelDownloadThree(params) {

    let selectedRowData = this.getSelectedRowData(params?.rowData);

    if (selectedRowData[0].docId == params?.rowData.docId) {
      if (this.flagClick) {
        this.selectedDocs = [];
        this.selectedDocs.push(params?.rowData);
        const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'download');
        this.isSelectedOnceDetailsDownload = true;
        this.flagClick = '';
        this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
          if (response.data[0] && response.data[0].docShareStatus === this.notAccess) {
            this.openModal(this.wsOutSync, false);
          } else {
            this.sharedservice.downlaodButtonThreeDotOwnedBy.next();
          }
        });
      }

    }
  }
  /**
     * reloadScreen function
     */
  reloadScreen() {
    window.location.reload();
  }
  /**
    * getRoleAccessBody function
    */
  public getRoleAccessBody(docId, docGuid, role) {
    return {
      wsoid: Number(this.workSpaceId),
      docID: Number(docId),
      role: role,
      database: 'get2doxmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      DocGuid: docGuid
    };
  }
  /**
     * regardingSearch var
     */
  regardingSearch = false;
  /**
   * allUserLength var
   */
  allUserLength: any;
  /**
   * ownerTransferUsers var
   */
  ownerTransferUsers: any;
  /**
   * uploadRightUserList var
   */
  uploadRightUserList = [];
  /**
  * UploadRightsUserList func
  */
  UploadRightsUserList() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }

    this.pendingRequest = this.data.searchAllUsersService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.rightUserList = response.data;
        let actualUsers = [];
        response.data.forEach(user=>{
        if(!user.roleName.includes("Tally") && user.emailId !== this.createdBy ){
        actualUsers.push(user.roleName);
      }
     })
        this.allUserLength = actualUsers.length;
        this.ownerTransferUsers = response.data.filter(
          loc => (loc.emailId != this.createdBy && !loc.roleName.includes("Tally")));

        for (let user of this.ownerTransferUsers) {

          user['custom'] = user.displayName;
        }


        this.uploadRightUserList = response.data;

        for (let user of this.uploadRightUserList) {

          user['custom'] = user.fullName;
        }
      }
    }, error => {
      setTimeout(() => {

      }, 1000)
    })
  }
  /**
    * documentOwnerName var
    */
  documentOwnerName: any;
  /**
     * documentOwnerEmail var
     */
  documentOwnerEmail: any;
  /**
     * onDocumentOwner function
     */
  onDocumentOwner(event) {

    this.documentOwnerName = event.displayName;
    this.documentOwnerEmail = event.emailId;
  }
  /**
   * selectedDocumentOwner var
   */
  selectedDocumentOwner = null;
  /**
   * closeModal func
   */
  closeModal() {
    this.selectedDocumentOwner = null;
    this.dialogRef.close();
    this.isSelectedOnceDetails = false;

  }
  /**
    * sendDetailsData var
    */
  sendDetailsData: string;
  /**
  * confirmOwnerShipTransfer func
  */
  confirmOwnerShipTransfer() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;
    postData = {
      "wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "docIdList": this.doclist,
      "docCount": this.numberofDocsSelected,
      "newOwnerEmailId": this.documentOwnerEmail,
      "originalFileNameList": this.selectedFileNames,
      "modifiedBy": this.createdBy,
      "separator": "|",
      "guid": this.getGuid,
      "DocGuid": this.docGuidList
    }
    this.sendDetailsData = '';
    this.isSelectedOnceDetails = false;
    this.data.transferOwnershipService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.dialogRef.close();
        this.selectedDocumentOwner = null;
        this.toastrService.primary("Document ownership transferred to " + this.documentOwnerName);
        this.newDocumentOwner = this.documentOwnerName;
        this.sharedservice.globalSearchEmitter.next('');
        this.searchSpinnerNew = true;
        setTimeout(() => {
          this.searchingAPI('');
        }, 4000);
      }
    });
    this.dialogRef.close();
  }
  /**
    * isSelectedOnceDetailsDelete var
    */
  isSelectedOnceDetailsDelete = false;
  /**
* openDeleteModalThree function
*/
  openDeleteModalThree(params) {
    // if(!this.isSelectedOnceDetailsDelete){

    let selectedrowData = this.getSelectedRowData(params?.rowData);
    if (this.selectedDocs.length === 1) {
      if (!this.isSelectedOnceDetailsDelete && selectedrowData[0].docId == params?.rowData.docId) {
        this.isSelectedOnceDetailsDelete = true;
        const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'Delete')

        this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
          if (response.data && response.data[0].docShareStatus === this.notAccess) {
            this.openModal(this.wsOutSync, false);
          } else {
            this.selectedDocs = [];

            this.selectedDocs.push(params?.rowData);
            this.selectedFileNames = this.selectedDocs[0].originalFileName;
            this.doclistDelete = this.selectedDocs[0].docId;
            this.numberofDocsSelected = this.selectedDocs.length;
            this.deleteChild.callAuthCheck();
          }
        })

      }
    }
    else {
      if (!this.isSelectedOnceDetailsDelete) {
        this.isSelectedOnceDetailsDelete = true;
        this.openDeleteModalGlobal()
      }


    }

    // }
  }
  cellData: any
  GuestModalShare() {
    if(this.runOneTime){
    // console.log(this.cellData)
    this.cellData = this.selectedDocs[0]
    let guestShareEventData= this.selectedDocs
    let selectedrowData = this.getSelectedRowData(this.cellData);
    if (selectedrowData[0].originalFileName == this.cellData.originalFileName) {
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(this.cellData.docId),
        "role": "View",
        "database": "get2doxMaster",
        "createdby": this.createdBy,
        "Isdeleteby": false,
        "guid": this.getGuid,
        "DocGuid": this.cellData.docGuid
      }
      this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (this.guestAccess === 'true') {
            this.guestChild.open(guestShareEventData);
          } else {
            this.guestChild.openNonEdit(guestShareEventData);
          }
        }
      }, error => {

      })
    }
    this.runOneTime = false;
    setTimeout(() => {
      this.runOneTime = true;
    },2000);
    setTimeout(() => {
      this.isSelectedGuest = false;
    },100);
  }
  }
  /**
   * trees var
   */
  trees = [];
  /**
     * showErrorMessage var
     */
  showErrorMessage = false;
  /**
     * setDate var
     */
  setDate = true;
  /**
     * wsCreationDate var
     */
  wsCreationDate: string;
  /**
     * minDate var
     */
  minDate: any = new Date();
  /**
     * rangeDate var
     */
  rangeDate;
  /**
     * selectUploadedBy var
     */
  selectUploadedBy = '';
  /**
     * selectDocOwner var
     */
  selectDocOwner = '';
  /**
     * checkIfDocExists function
     */
  checkIfDocExists(val, params, isGlobal) {
    this.trees = [];
    /**
     * flag boolean
     */
    let flag = false;

    this.showErrorMessage = false;
    if (this.setDate === false) {
      var startDate = new DatePipe('en-US').transform(val[0].startDate || this.wsCreationDate, 'dd-MMM-yyyy');
      if (startDate == null || startDate == undefined) {
        startDate = "";


      }
      var endDate = new DatePipe('en-US').transform(val[0].endDate
        || this.minDate, 'dd-MMM-yyyy');
      if (endDate == null || endDate == undefined) {
        endDate = "";
      }
    }
    else {
      var startDate = new DatePipe('en-US').transform(val[0].startDate, 'dd-MMM-yyyy');
      if (startDate == null || startDate == undefined) {
        startDate = "";

      }
      var endDate = new DatePipe('en-US').transform(val[0].endDate || this.minDate, 'dd-MMM-yyyy');
      if (endDate == null || endDate == undefined) {
        endDate = "";
      }


    }

    let postData: any;
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "OriginalFileName": '',
      "startDate": new DatePipe('en-US').transform(this.rangeDate?.start, 'dd-MMM-yyyy') || "",
      "endDate": new DatePipe('en-US').transform(this.rangeDate?.end || !!this.rangeDate?.start ? this.minDate : "", 'dd-MMM-yyyy') || "",
      "documentFormat": '',
      "uploadedBy": this.selectUploadedBy,
      "verifiedBy": this.selectDocOwner,
      "createdBy": this.createdBy,

    }
    this.sendDetailsData = '';
    this.data.getOwnedByMeDocListData(postData).subscribe((response: any) => {


      if (response.isSuccess && response.data) {

        let docId = isGlobal ? this.selectedDocs[0].docId : params?.rowData.docId;
        let resp = [];
        if (isGlobal) {
          resp = response.data.filter((e, i) => {
            for (var key in this.selectedDocs) {
              if (this.selectedDocs[key].docId === e.docId)
                return true;
            }
            return false;

          })
        }
        else {
          resp = response.data.filter((e) => {

            return e.docId == params?.rowData.docId;
          })
        }


        if (resp.length > 0) {
          if (isGlobal && this.selectedDocs.length == resp.length) {
            this.deleteChild.callAuthCheck()
          }
          else {
            flag = true;
            this.selectedDocs = [];
            this.selectedDocs.push(params?.rowData);
            this.selectedFileNames = this.selectedDocs[0].originalFileName;
            this.doclistDelete = this.selectedDocs[0].docId;
            this.numberofDocsSelected = this.selectedDocs.length;
            this.sizeConvert();
            this.sizeApi();
            this.deleteChild.callAuthCheck();
          }

        }
        else {

          this.openModal(this.wsOutSync, false);
        }

      }
      else {
        this.sendDetailsData = '';
      }
      if (!response.isSuccess) {
        this.showErrorMessage = true;
        this.sendDetailsData = '';
        this.sendDetailValue(this.sendDetailsData);
      }
    })
  }


  showDownload() {

    if (this.selectedDocs.length > 0 && this.selectedDocs.length === 1) {
      this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      if (((localStorage.getItem('downloadandprint') === 'true' && this.selectedDocs[0].docShareStatus === 'Access'))) {
        flag = true;
      }
      this.datanew.showDelete = flag;

      return flag;
    }

    /*
     *for multiple docs select delete option show
     */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let tempdata = this.selectedDocs.findIndex(item => item.docShareStatus === "Not Access")
      // let tempdata1 = this.selectedDocs.findIndex(item=>item.docOwnerEmailId === this.createdBy)
      if (tempdata !== -1) {
        flag = false;
        return flag;
      }
      else {
        if (localStorage.getItem('downloadandprint') === 'true') {
          flag = true;
          return flag;
        }
        else {
          flag = false;
          return flag;
        }
      }

    }


  }
  /**
     * returnQuote var
     */
  returnQuote: any = [];
  /**
     * myValue variable
     */
  myValue: any;
  /**
     * fileCount var
     */
  fileCount: any;
  /**
     * totalWSCount var
     */
  totalWSCount: any;
  /**
     * percentageValue var
     */
  percentageValue: number;
  /**
     * availableTrashSize var
     */
  availableTrashSize: number;
  /**
     * occupiedTrashSize var
     */
  occupiedTrashSize: number;
  /**
     * sizeApi function
     */
  sizeApi() {
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "docStatus": this.deleted,
      "modifiedBy": this.createdBy,
      "guid": localStorage.getItem('loginguid')
    }
    this.data.SizeTrashStatusService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.returnQuote = response.request;
        this.myValue = this.returnQuote.trashFileSize;
        this.fileCount = this.returnQuote.trashCount;
        this.totalWSCount = this.returnQuote.binSizeValue;
        this.percentageValue = this.returnQuote.wsSizePercentage;
        this.availableTrashSize = 0;
        if (this.totalWSCount && this.totalWSCount.includes('KB')) {
          this.availableTrashSize = Number(this.totalWSCount?.split("K")[0]);
        }
        else if (this.totalWSCount && this.totalWSCount.includes('MB')) {
          this.availableTrashSize = Number(this.totalWSCount?.split("M")[0]) * 1024;
        }
        else if (this.totalWSCount && this.totalWSCount.includes('GB')) {
          this.availableTrashSize = Number(this.totalWSCount?.split("G")[0]) * 1024 * 1024;
        }
        if (this.myValue) {
          if (this.myValue.includes('KB')) {
            this.occupiedTrashSize = Number(this.myValue?.split(" ")[0]);
          }
          else if (this.myValue.includes('MB')) {
            this.occupiedTrashSize = Number(this.myValue?.split(" ")[0]) * 1024;
          }
          else if (this.myValue.includes('GB')) {
            this.occupiedTrashSize = Number(this.myValue?.split(" ")[0]) * 1024 * 1024;
          }
          else {
            this.occupiedTrashSize = Number(this.myValue);
          }
        }

      }
    })

  }


  showTransferOwnerShip() {

    if (this.selectedDocs.length > 0 && this.selectedDocs.length == 1) {
      this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let isSharedRights = localStorage.getItem('isSharedRights');


      if (((this.selectedDocs[0].docOwnerEmailId === this.createdBy)) && this.selectedDocs[0].docShareStatus == 'Access') {
        flag = true;
      }
      this.datanew.showDelete = flag;

      return flag;
    }

    /*
     *for multiple docs select delete option show
     */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let isSharedRights = localStorage.getItem('isSharedRights');
      let allSharedData = JSON.parse(localStorage.getItem('allSharedData'));

      let tempdata = this.selectedDocs.findIndex(item => item.docOwnerEmailId !== this.createdBy)
      let tempdata1 = this.selectedDocs.findIndex(item => item.docShareStatus !== "Access")
      if (tempdata1 != -1) {
        flag = false;
        return flag;
      } else {
        if (tempdata != -1) {
          flag = false;
          return flag;
        }
        else {
          flag = true;
          return flag;
        }
      }


      //   let sharedArray = [];
      //   let none = (arr, callback) => !arr.some(callback)

      //   this.selectedDocs.forEach((e)=>{
      //     let data = allSharedData.filter((e1)=>{
      //      return e1.documentDetailsList[0].docId == e.docId;
      //     });
      //     if(!data[0]){
      //       return;
      //     }
      //     let newData = data[0].documentShareUserList;
      //     let isSharedIndex =  newData.findIndex((e)=>{
      //      return e.userEmailID == this.createdBy;
      //     })
      //     let json:any;
      //     if(isSharedIndex>-1){
      //        json = {'docId':e.docId,'isShared':true}
      //     } else{
      //       json = {'docId':e.docId,'isShared':false}
      //     }
      //     sharedArray.push(json);
      //   })

      //   let someSharedCount = 0;
      //    sharedArray.forEach((e)=>{
      //     if(e.isShared == true){
      //       someSharedCount++;
      //     }
      //   });
      //   let isSomeShared = false;
      //   if(someSharedCount>0){
      //     isSomeShared = true;
      //   }


      //   let isAllShared = sharedArray.every((e)=>{
      //     return e.isShared == true
      //   })
      //   let isAllOwned = this.selectedDocs.every((e)=>{
      //     return e.docOwnerEmailId == this.createdBy;
      //   })
      //   let someOwnedCount = 0;
      //   let isSomeOwned = false;
      //    this.selectedDocs.forEach((e)=>{
      //      let index = sharedArray.findIndex((e1)=>{
      //        return e1.docId == e.docId;
      //      })
      //      if((index >-1  && !sharedArray[index].isShared) || index== -1){
      //       if(e.docOwnerEmailId == this.createdBy){
      //         someOwnedCount++;
      //         return true;
      //       }
      //      }
      //   })
      //   if(someOwnedCount>0){
      //     isSomeOwned = true;
      //   }

      // let isNoneOwned = none(this.selectedDocs, el => el.docOwnerEmailId === this.createdBy)
      // let isNoneShared = none(sharedArray, el => el.isShared === true)

      // let cummilativeCount = someSharedCount + someOwnedCount;


      // if(this.deleteRights){
      //     flag = true;
      //   }


      // else if(!this.deleteRights){
      //   flag = false;
      // }
      //     localStorage.setItem('isAllOwned', JSON.stringify(isAllOwned))

      // if(isAllOwned){
      //   flag = true;
      // }
      //   this.datanew.showDelete = flag;
      //   return flag;
    }
  }



  /**
   * verifierEmailID var
   */
  verifierEmailID: any;
  /**
   * ownerEmailID var
   */
  ownerEmailID: any;
  /**
    * isAllUserShare var
    */
  isAllUserShare: boolean;
  /**
    * isSelectedOnceDetailsShare var
    */
  isSelectedOnceDetailsShare = false;
  /**
    * openShareModal function
    */
  openShareModal(params) {
    /**
     * selectedRowData variable
     */
    if (this.selectedDocs.length === 1) {
      let selectedRowData = this.getSelectedRowData(params?.rowData);

      if (selectedRowData[0].docId == params?.rowData.docId) {
        if (this.flagClick) {
          this.getSharedWithData(params?.rowData.docId)
          this.verifierEmailID = params?.rowData.docVerifierEmailId;
          this.ownerEmailID = params?.rowData.docOwnerEmailId;
          this.isAllUserShare = false
          this.selectedDocs = [];
          this.selectedDocs.push(params?.rowData);
          const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'share');
          this.isSelectedOnceDetailsShare = true;
          this.flagClick = '';
          this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
            if (response.data[0] && response.data[0].docShareStatus === this.notAccess) {
              this.openModal(this.wsOutSync, false);
            } else {
              this.sharedService.isShare.next(this.isAllStatus);
              this.shareuse.shareUsers(params?.rowData.docId);
            }
          });
        }

      }
    }
    else {
      if (this.flagClick) {
        const ids = []
        const docVerifierEmailId = [];
        const docOwnerEmailId = [];
        const selectedGuid = [];
        this.flagClick = '';
        this.selectedDocs.forEach(obj => {
          ids.push(obj.docId)
          docVerifierEmailId.push(obj.verifiedByEmailId);
          docOwnerEmailId.push(obj.docOwnerEmailId)
          selectedGuid.push(obj.docGuid)
        })
        let tempdata = {
          owner: docOwnerEmailId[0],
          verifier: docVerifierEmailId[0],
          docId: ids[0],
          bydefault: ''
        }
        this.sharedService.isShare.next(this.isAllStatus);
        this.sharedService.threeDotValue.next('multi');
        this.shareuse.shareWithMutipleUsers(ids, selectedGuid, tempdata);
      }

    }



  }
  /**
    * sharedWithUsers var
    */
  sharedWithUsers: any;
  /**
    * setUserGroupArr var
    */

  isSelectNoUsers
  isSelectNoUser($event) {
    this.isSelectNoUsers = $event;
  }
  setUserGroupArr: any;
  /**
    * getSharedWithData function
    */
  getSharedWithData(docId) {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(docId),
      "database": "get2doxMaster",
    }
    this.pendingRequest = this.data.getSharedWithModalDataForView(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sharedWithUsers = response.data;
        this.setUserGroupArr = this.sharedWithUsers;
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(true);
        this.sharedService.selectedAccessEditor.next(response.data);
        this.cd.detectChanges();
      }

      else {
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(false);
      }
    })
  }
  /**
    * downloadModalOpen var
    */
  downloadModalOpen = false;
  /**
     * downChecking var
     */
  downChecking = '';
  /**
     * downloadIcon var
     */
  downloadIcon = false;
  /**
     * downloadAccessMultiDocs function
     */
  downloadAccessMultiDocs(data) {
    /**
     * ids array
     */
    const ids = []
    this.downChecking = data;
    this.selectedDocs.forEach(obj => {
      ids.push(obj.docId)
    })
    const body = {
      wsoid: Number(this.workSpaceId),
      docID: ids.toString(),
      role: 'Download',
      database: 'dox2udevmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      guid: this.getGuid,
    };
    this.data.deleteAccessMultipleService(body).subscribe((Response: any) => {
      if (Response.data) {
        let containdata = Response.data.filter(a => a.docShareStatus === this.notAccess)
        if (containdata.length > 0) {
          this.openModal(this.wsOutSync, false);
        }
        else {
          this.downloadChild.validateDownloadDailyLimit();
        }
      }

    });
  }
  /**
     * openDeleteModalGlobal function
     */
  deleteCell = true
  openDeleteModalGlobal() {
    const ids = []
    this.selectedDocs.forEach(obj => {
      ids.push(obj.docId)
    })
    const body = {
      wsoid: Number(this.workSpaceId),
      docID: ids.toString(),
      role: 'Delete',
      database: 'get2doxmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      guid: this.getguid,
    };
    this.data.deleteAccessMultipleService(body).subscribe((Response: any) => {
      if (Response.data.filter(a => a.docShareStatus === this.notAccess).lenght > 0) {
        this.openModal(this.wsOutSync, false);
      } else {
        this.deleteChild.callAuthCheck();
      }
    })
  }

  oneTime: any = 1
  openPreviewPanel() {
    localStorage.setItem('Invokepreview', 'true');
    if (this.oneTime == 1) {
      this.previewPanelOpen = !this.previewPanelOpen
    }
    if (this.previewPanelOpen == false && this.oneTime == 1) {
      localStorage.setItem('Invokepreview', String(true))
      this.previewchild.updateDocAuditLog()
    }
    else {
      localStorage.setItem('Invokepreview', String(false))
    }
    this.oneTime++
    setTimeout(() => {
      this.oneTime = 1
    }, 1000);
    this.tagPanelOpen = true
    this.hideDetail = true

    this.sharedservice.hideSideBar.next(!this.previewPanelOpen);
    this.hidePreview();
  }
  hidePreview() {
    this.sharedservice.hideSideBar.next(!this.previewPanelOpen);
    this.gridColumnApi.setColumnVisible('pageCount', true);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', true);
    this.gridColumnApi.setColumnVisible('docOwnerDisplayName', true);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  GetValue() {
    this.relevanceByContent = localStorage.getItem('RelevanceByContent')

  }
  ngOnDestroy() {
    this.isTransferModalAllowed = false;
  }
}

