/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Upgrade Component
///  Description: inside shared module and contains the upgrade functionality for the upgrade
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is of alignment of feature flags on basic of unqiueIDs
///
///------------------------------------------------------------------->
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { RolesService } from '../../@core/utils/roles.service';
import { environment } from '../../../environments/environment';
import { SignInService } from '../../@core/sign-in.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'ngx-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss']
})
export class UpgradeModalComponent implements OnInit {
  @ViewChild("upgradeModalOwner") upgradeModalOwner: TemplateRef<any>;
  @ViewChild("upgradeModalOwnerInvite") upgradeModalOwnerInvite: TemplateRef<any>;
  @ViewChild("upgradeModalUser") upgradeModalUser: TemplateRef<any>;
  @ViewChild("upgradeModalUserInvite") upgradeModalUserInvite: TemplateRef<any>;
  @ViewChild("upgradeModalOwnerUpload") upgradeModalOwnerUpload: TemplateRef<any>;
  @ViewChild("upgradeModalUserUpload") upgradeModalUserUpload: TemplateRef<any>;
  category: any;
  workSpaceId;
  createdBy;
  owneremailid;
  noteuserfullname;
  feature: any;
  wsName: any;
  emailFlag: any;
  totalDpages: string;
  maxEmail: number;
  subscriptionId: string;
  baseApp:any;
  emailId : any;
  wsId : any;
  addOnUsers: any;
  totalUsers: any;
  getallparseflag: string;
  constructor(
    private SignInService: SignInService ,private dialogRef: NbDialogRef<any>,private data: RolesService,private router : Router, private dialogService: NbDialogService, private cookieService: CookieService) {
    this.category = localStorage.getItem('logincategory')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.owneremailid = localStorage.getItem('ownerEmailId')
    this.subscriptionId =localStorage.getItem('subscriptionSpId')
    this.noteuserfullname = localStorage.getItem('noteuserfullname')
    this.wsName = localStorage.getItem('WorkSpaceName');
    // this.maxEmail = Number(localStorage.getItem('maxFreeUsers'));

    this.baseApp = environment.baseapp;
    this.emailId = localStorage.getItem('localloginId');
    this.wsId = localStorage.getItem('localwsId');

    if( localStorage.getItem('totalDpages')){

      this.totalDpages = localStorage.getItem('totalDpages')?.split('|')[0];
    }


   }

  ngOnInit(): void {
    this.getMaxUserCount();
  }
  /**
  * close modal
  */
  public close() {
    this.dialogRef.close();
    if(localStorage.getItem('refreshKey') === 'true'){
      localStorage.removeItem('refreshKey')
     window.location.reload()
    }

  }
  /**
   * goto Billing for updation
   */
  gotobilling() {

    this.hrefData = this.router.url;
    var getDomainName = ".";
    var splitDot = new Array();
    var urlData = window.location.toString();
    var splitBackslash = urlData?.split("/")[2];
    if (splitBackslash == "localhost:4200") {
      this.workspaceDomain = ".localhost:4200";
    }
    else {
      splitDot = splitBackslash?.split(".");
      for (let i = 1; i < splitDot.length; i++) {

        if (i == 1)
          getDomainName = getDomainName + splitDot[i];
        if (i == 2)
          getDomainName = getDomainName + "." + splitDot[i];
      }
      this.workspaceDomain = getDomainName;
      this.wsSubDomain = urlData?.split("/")[2];
    }
    this.cookieService.set('emailId', this.emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('wsURL', this.wsSubDomain, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });

    const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + this.workspaceDomain + '/manage-billing/dashboard/'], {
      queryParams: {
        wsId: this.wsId,
        loginId: this.emailId,
        token:localStorage.getItem('token')
      }
    }))
    // this.router.navigate(['/onboarding/dashboard']);
    window.open(environment.httpUrl + url.substring(1), '_blank');
    if(localStorage.getItem('refreshKey') === 'true'){
      localStorage.removeItem('refreshKey')
     window.location.reload()
    }
  }
  /**
*upgrade modal
*/
  openUpgrade(feature?,mail?) {
    this.feature = feature;
    this.emailFlag = mail;
    if (this.category === 'Owner') {
      this.openTemplate(this.upgradeModalOwner);
    } else {
      this.openTemplate(this.upgradeModalUser);

    }
  }

  openUpgradeExtraction(feature?,mail?) {
    this.feature = feature;
    this.emailFlag = mail;
    if (this.category === 'Owner') {
      this.openTemplate(this.upgradeModalOwner);
    }
  }
  /**
*upgrade modal
*/
  openUpgradeInvite(feature?,mail?) {
    this.feature = feature;
    this.emailFlag = mail;
    if (this.category === 'Owner') {
      this.openTemplate(this.upgradeModalOwnerInvite);
    } else {
      this.openTemplate(this.upgradeModalUserInvite);

    }
  }
  /**
*upgrade modal
*/
  openUpgradeUpload(feature?,email?) {
    localStorage.removeItem('uploadTime')
    this.feature = feature;
    this.emailFlag=email
    if (this.category === 'Owner') {
      this.openTemplate(this.upgradeModalOwnerUpload);
    } else{
      this.openTemplate(this.upgradeModalUserUpload);
    }
  }

  public openTemplate(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  reuesttoowner(){
    this.wsUpgrade();
    this.roledelivary();
  }

  sendmessage;
  messagebody;
  insertmessgae;
  workspaceDomain: string;
  wsSubDomain;
  hrefData;
  roledelivary(){
    this.noteuserfullname = localStorage.getItem('noteuserfullname')
    let domainValue = environment.domain;
    this.hrefData = this.router.url;
    var getDomainName = ".";
    var splitDot = new Array();
    var urlData = window.location.toString();
    var splitBackslash = urlData?.split("/")[2];
    if (splitBackslash == "localhost:4200") {
      this.workspaceDomain = ".localhost:4200";
    }
    else {
      splitDot = splitBackslash?.split(".");
      for (let i = 1; i < splitDot.length; i++) {

        if (i == 1)
          getDomainName = getDomainName + splitDot[i];
        if (i == 2)
          getDomainName = getDomainName + "." + splitDot[i];
      }
      this.workspaceDomain = getDomainName;
      this.wsSubDomain = urlData?.split("/")[2];
    }
    this.cookieService.set('emailId', this.emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('wsURL', this.wsSubDomain, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });

    const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + this.workspaceDomain + '/manage-billing/dashboard/'], {
      queryParams: {
        wsId: this.wsId,
        loginId: this.emailId,
        token:localStorage.getItem('token')
      }
    }))

     this.messagebody = '<b>' + this.noteuserfullname +'</b>' + " (" + '<b>' + this.createdBy + '</b>' + ') '+"  " + 'wants you to upgrade workspace subscription plan so that they can add more users to your workspace.' + ' ' +this.feature + '.'  + ' ' +'Go to' + ' '
      + `<a style="color:#3366FF;" href=${url}>Billing.</a>`
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: 'Upgrade Workspace Subscription',
      message: this.messagebody,
      notificationTo:this.owneremailid,
      createdBy: this.createdBy,
    };
    this.insertmessgae  = '';
    this.data.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.upgradowner()
      } else {
        this.insertmessgae = '';
      }
    });
  }

  upgradowner(formatedMsg?:string){
    let body ={
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID:this.owneremailid,
      role : null,
      groupName : null,
      message: 'Upgrade Workspace Subscription',
      databaseName: "",
      createdBy: null,
      Flag: "Toster",
      WsLink : "",
    }
    this.sendmessage  = '';
    this.data.notifysend(body).subscribe((response:any)=>{
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else{
        this.sendmessage = '';
      }
    })
  }
  wsUpgrade(){
    this.noteuserfullname = localStorage.getItem('noteuserfullname');

    var st = window.location.toString();
    var arr = st?.split("/");
    let body ={
      "wsLink": arr[2],
      "wsName": this.wsName,
      "memName": this.noteuserfullname,
      "memEmailId": this.createdBy,
      "ownerEmailID": this.owneremailid,
      "featureRequest": this.feature,
      "flag": this.emailFlag
    }
    this.sendmessage  = '';
    this.data.wsUpgrade(body).subscribe((response:any)=>{
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else{
        this.sendmessage = '';
      }
    })
  }
  getMaxUserCount() {
    if (this.workSpaceId){
      let postdata = {
        wsoid: Number(this.workSpaceId)
  
        
      };
      this.getallparseflag = '';
      this.SignInService.getWSDetails(postdata).subscribe((response: any) => {
        if (response.isSuccess) {
          this.maxEmail = response.data[0].freeUser;
          this.addOnUsers = response.data[0].addOnUser;
          this.totalUsers = this.maxEmail + this.addOnUsers;
        }
      })
    }
    // if (this.workSpaceId) {
    //   let parserdata = {
    //     wsoid: Number(this.workSpaceId),
    //     createdBy: ""
    //   }
    //   this.getallparseflag = '';
    //   this.SignInService.getparserflag(parserdata).subscribe((response: any) => {
    //     if (response.isSuccess) {
    //       this.getallparseflag = response.data;
    //       for (let i = 0; i <= this.getallparseflag.length - 1; i++) {
    //         if (response.data[i].uniqueID == 'FID-001') {
    //           this.maxEmail = response.data[i].totalMemberAllow;
    //         }
    //       }
    //     }
    //   });
    // }
  }

}
