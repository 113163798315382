import { Observable } from 'rxjs';
/**
 *  User 
 */
export interface User {
   /**
      *  variable for name
   */
  name: string;
   /**
      *  variable for picture
   */
  picture: string;
}
/**
 *  Contacts 
 */
export interface Contacts {
  /**
      *  variable for User
   */
  user: User;
    /**
      *  variable for type
   */
  type: string;
}
/**
 *  RecentUsers 
 */
export interface RecentUsers extends Contacts {
    /**
      *  variable for time
   */
  time: number;
}
/**
 *  UserData 
 */
export abstract class UserData {
    /**
 *  abstract method for getUsers
 */
  abstract getUsers(): Observable<User[]>;
    /**
 *  abstract method for getContacts
 */
  abstract getContacts(): Observable<Contacts[]>;
    /**
 *  abstract method for getRecentUsers
 */
  abstract getRecentUsers(): Observable<RecentUsers[]>;
}
