import { ChangeDetectorRef, Component,OnInit, Output,EventEmitter, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

import { HttpClient} from '@angular/common/http';
import { Router} from '@angular/router';
import { UserSettingService } from '../../../../@core/utils/user-setting.service';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
;
import { Optional } from 'ag-grid-community';
import { SharedService } from '../../../../@core/utils/shared.service';



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password-header.component.html',
  styleUrls: ['./change-password-header.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  usChangePasswordForm: FormGroup;
  show_password: Boolean = false;
  show_NewPassword: boolean = false;
  submitted = false;
  usPasswod: any;
  confirmpass: any;
  errors: any;
  wpname: any;
  wpweb: any;
  usOldPass: any;
  wpprofile: any;
  usOldPassKey: any;
  usPasswodKey: any;
  workSpaceId: any;
  createdBy: any;
  showPassword=false;
  EmailKey:string;
  CategoryKey:string
  cannotblanksubmit:boolean=false
  passwordLength: any;
  containLowerCase: any;
  containSpecialCharacter: any;
  containNumber: any;
  containUpperCase: any;
  passwordNotMatch: boolean;
  incorrectPassword: boolean;
  @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>; // Note: TemplateRef
  isSubmitted: boolean;
  sideBardVal: unknown;
  passwordClose: boolean;
  subscrip: any;
  resetval: boolean;
  resetVal: boolean;

  constructor(private sharedService :SharedService ,@Optional() private dialogRef: NbDialogRef<any>, private dialogService: NbDialogService,private cookieService: CookieService, private formBuilder: FormBuilder,  private http: HttpClient, private router: Router, private data: UserSettingService,  private cd: ChangeDetectorRef, private toastrService: NbToastrService,) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')

    if (!this.createdBy) {
      this.router.navigateByUrl('/sign-in');
    }
    /**
    * use for the password signup form validation
    */
    this.passwordForm = this.formBuilder.group({
      repassword: ['', [Validators.required,
      Validators.maxLength(20),
      Validators.minLength(6)]]
    });
   }

  /**
  * password form confirm
  */
  passwordConfirming(c: any): any {
    if (c.get('password').value !== c.get('repassword').value) {
      return {
        invalid: true
      };
    }
  }
ngOnInit(): void {
  this.passwordClose = false;
  var st = window.location.toString();
  var arr = st?.split("/");
 this.subscrip =  this.sharedService.IsSidebarClicked.subscribe((data) => {
    this.sideBardVal = data;
    if ((this?.f1?.password?.value?.length >0 || this?.f1?.repassword?.value?.length >0) && !this.isSubmitted) {
      this.openModal(this.unsavedChanges, false);
    }
    if(this.resetVal){
      this.sharedService.IsSidebarClicked.next('profile');
    }
  });
}
ngOnDestroy(){
  this?.subscrip?.unsubscribe();
}



//function for show/hide new password start here
userSetNewPassword() {
  this.show_NewPassword = !this.show_NewPassword;
}


//function use for check password strength start here
changeButtonState(e) {
  this.submitted = false;
  this.incorrectPassword = false;
  this.passwordNotMatch = false;
  if ((this?.f1?.password?.value?.length >0 || this?.f1?.repassword?.value?.length >0) && !this.isSubmitted) {
   this.isUnsaved.emit('stay');
  }  if (e.key === 'Delete' || e.key === 'Backspace') {
    this.isUnsaved.emit('discard' + "|" + 'reset');

  }

}
//function use for check password strength end here


spaceNotAllow(e) {

  if (e.which == 32)
    return false;
}


  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });

  }
  hidePasswordError(event){
    if(event.length === 0){
      this.fieldError= true;
    }
    else{
      this.fieldError = false;
    }
    this.passwordNotMatch1 = false;
  }

  oldPasswordField="";
  fieldError= false;
  passwordNotMatch1=false;

//for change password***************
PwdNotMatch:boolean=false;
errorMessage:any;
incorrectPwd:boolean
BackendResponse:boolean=false
resetPasswordConfirm(){
  var st = window.location.toString();
  var arr = st?.split("/");
  this.submitted = true;
  if(this.oldPasswordField.length === 0){
    this.fieldError= true;
  }
  if (!this.passwordLength || !this.containLowerCase || !this.containSpecialCharacter || !this.containNumber || !this.containUpperCase || this.passwordForm.invalid || this.fieldError) {
    return
  } else {
    let postData: any;
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "EmailID": this.createdBy,
      "OldPassword": this.oldPasswordField,
      "Password": this.passwordForm.value.repassword,
      "guid" : localStorage.getItem('loginguid')
    }
    this.data.changePasswordService(postData).subscribe((response: any) => {
      if (response.isSuccess == true) {
        if (response.statusCode == 'C200') {
          this.isSubmitted = true;
          this.submitted = false
          this.passwordForm.reset();
          this.containLowerCase = false;
          this.containNumber = false;
          this.containSpecialCharacter = false;
          this.containUpperCase = false;
          this.passwordLength = false
          this.isUnsaved.emit('discard' + "|" + 'reset');
          this.toastrService.primary('Password changed successfully');
          this.oldPasswordField ="";
        }
      }
      else if (response.statusCode == 'C201') {
        this.errorMessage=response.returnMessage
        this.passwordNotMatch1 = true;
      }
      else if (response.statusCode == 'C415') {
        this.errorMessage=response.returnMessage ;

        this.incorrectPassword = true
      }
      else {

      }
    })
  }
}









passwordForm: FormGroup;




  get f3() {
    return this.passwordForm.controls;
  }

  onKeydownMain(event){
    this.incorrectPwd=false;
    if (event.code === 'Space') {
      event.preventDefault();
  }
  this.PwdNotMatch=false
  this.BackendResponse=false
  }

  ExshowPassword:boolean=false
  ExtoggleShowPassword() {
    this.ExshowPassword = !this.ExshowPassword;
  }

  ExgetInputType() {
    if (this.ExshowPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  /**
 * remove non english Characters
 */
  removeLanguage(event) {
    var regPattern = /[\u0000-\u007A]+/g;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  get f1() {
    return this.passwordForm.controls;
  }
  /**
 * password strength function
 * @param event
 */
  checkPasswordStrength(event) {
    this.submitted = false;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    /[A-Z]/.test(this.f1.repassword.value) ? this.containUpperCase = true : this.containUpperCase = false;
    /[a-z]/.test(this.f1.repassword.value) ? this.containLowerCase = true : this.containLowerCase = false;
    /\d/.test(this.f1.repassword.value) ? this.containNumber = true : this.containNumber = false;
    format.test(this.f1.repassword.value) ? this.containSpecialCharacter = true : this.containSpecialCharacter = false;
    this.f1.repassword.valid ? this.passwordLength = true : this.passwordLength = false;
  }
  /**
* onEnter event
*/
  onEnterPassword(event) {
    if (this.passwordLength && this.containLowerCase && this.containSpecialCharacter && this.containNumber && this.containUpperCase) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    } else {
      this.submitted = true;
      this.cd.detectChanges();
    }
  }
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }



 passwordValid(event: any) {
  this.passwordIsValid = event;
}
passwordIsValid = false;


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if ((this?.f1?.password?.value?.length >0 || this?.f1?.repassword?.value?.length >0) && !this.isSubmitted) {
      $event.preventDefault();

      return false;
    }
  }




@Output('close') close= new EventEmitter()
@Output('isUnsaved') isUnsaved= new EventEmitter()
passwordclose(){
  this.passwordClose = true;

}
resetPassword(){
    this.resetVal =true;
    this.oldPasswordField = '';
    this.passwordForm.reset();
    if(this.resetVal){
      this.isUnsaved.emit('discard' + "|" + 'reset');
    }
  }


  discardChanges(ref:any){
    if (this.passwordClose){
      this.passwordForm.reset();
      this.close.emit('');
    }
    this.dialogRef.close();
    ref.close();
    this.isUnsaved.emit('discard' + "|" + this.sideBardVal);
  }
}
function ref(ref: any) {
  throw new Error('Function not implemented.');
}

