import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-dpagestooltip',
  templateUrl: './dpagestooltip.component.html',
  styleUrls: ['./dpagestooltip.component.scss']
})
export class DpagestooltipComponent implements OnInit {

  constructor() { }
 /**
 * tooltipData var
 */
  tooltipData: any;
   /**
 * formatData var
 */
  formatData: any;
   /**
 * tooltipDataValue var
 */
  tooltipDataValue:any;
   /**
 * tooltipDataSplit var
 */
  tooltipDataSplit:any;
   /**
 * tooltipDataAccess var
 */
  tooltipDataAccess= true;
     /**
 * dpages tooltip ONinit
 */
  ngOnInit(): void {
  }

   /**
 * data var
 */
  data: any;
   /**
 * agInit for the dpages
 */
   agInit(params:any){
     this.data=params?.data;
     this.tooltipDataValue = this.data.pageconversion;
     this.tooltipData= this.tooltipDataValue;
     this.formatData = this.data.size + ' ' + this.data.sizeunit;
     this.tooltipDataAccess = true;

   }

}
