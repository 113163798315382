<!-------------------- Adding document format type in the lists -------------------->
<!-------------------------- For format type that is Images --------------------------->
<span *ngIf="href == '/pages/documents/my-queue'">
    <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='png' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-PNG"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='jpg' || tooltipData == 'jpeg') && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-JPG"></i> 
        </button>
         
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='tiff' || tooltipData== 'tif') && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-TIF"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='gif' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-GIF"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xls' && data.pageCount >= 0"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                 <i class="sprite sprite-XLS"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='fods' && data.pageCount >= 0"
                        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                         <i class="sprite sprite-FODS"></i>
                        
                        </button>
                        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xlsm' && data.pageCount >= 0"
                                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                 <i class="sprite sprite-XLSM"></i>
                                
                                </button>
                                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xlsx' && data.pageCount >= 0"
                                        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                         <i class="sprite sprite-XLSX"></i>
                                        
                                        </button>
                                        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='ods' && data.pageCount >= 0"
                                                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                                 <i class="sprite sprite-ODS"></i>
                                                
                                                </button>
                                                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='slk' && data.pageCount >= 0"
                                                        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                                         <i class="sprite sprite-SLK"></i>
                                                        
                                                        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='pjpeg' || tooltipData === 'pjpg') && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-PJPG"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='pjp' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-PJP"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='dib' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-DIB"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='jfif' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-JFIF"></i>
        
        </button>
        <!----------------------- For format type other than Images --------------------->
         <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='pdf' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-PDF"></i>
        
        </button>
        
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='Document' || tooltipData== 'docx' || tooltipData == 'doc') && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-DOC"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='txt' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-TXT"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='rtf' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-RTF"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='odt' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-ODT"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='bmp' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-BMP"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='dot' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-DOT"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='text' && data.pageCount >= 0"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-TEXT"></i>
        
        </button>
        <!-- <button style="pointer-events: none !important;" disabled nbButton class="bg-transparent border-0 ml-3 pl-1" *ngIf="formatData === 'This document is being processed.'"
        nbTooltip= {{formatData}} nbTooltipPlacement="top"  nbTooltipStatus="control" status="control">
           <div style="    background: #c8d3df;
           z-index: 1;
           position: absolute;
           right: 1px;"><nb-icon icon="loader-outline" style="    z-index: 1;
           background: #cce7fff5;
           color: black;"></nb-icon></div>
        </button> -->

       
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='png' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-PNG"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='jpg' || tooltipData == 'jpeg') && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-JPG"></i> 
                </button>
                 
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='tiff' || tooltipData== 'tif') && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-TIF"></i>
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='gif' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-GIF"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xls' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-XLS"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='fods' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-FODS"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xlsm' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-XLSM"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='xlsx' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-XLSX"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='ods' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-ODS"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='slk' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                 <i class="sprite sprite-SLK"></i>
                
                </button>
                <!------------------------------- For format type other than Images --------------------------------->
                 <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='pdf' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-PDF"></i>
                
                </button>
                
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='Document' || tooltipData== 'docx' || tooltipData == 'doc') && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-DOC"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='txt' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-TXT"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='rtf' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-RTF"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='odt' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-ODT"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='bmp' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-BMP"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='pjp' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-PJP"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='dib' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-DIB"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='dot' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-DOT"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='text' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-TEXT"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='jfif' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-JFIF"></i>
                
                </button>
                <button nbButton class="bg-transparent border-0  btn1" *ngIf="tooltipData =='pjpg' && formatData === 'This document can’t be processed by dox2U.'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="control">
                <i class="sprite sprite-PJPG"></i>
                
                </button>
             
         
</span>

<span *ngIf="href == '/pages/documents/workspace-queue'">
    <button nbButton class="bg-transparent border-0 " *ngIf="tooltipData=='png'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
         <i class="sprite sprite-PNG"></i>
        
        </button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='jpg' || tooltipData == 'jpeg'"
nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
         <i class="sprite sprite-JPG"></i> 
        </button>
 
<button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='tiff' || tooltipData== 'tif'"
nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
         <i class="sprite sprite-TIF"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='xls'"
nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
         <i class="sprite sprite-XLS"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='fods'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
                 <i class="sprite sprite-FODS"></i>
                </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='xlsm'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
        <i class="sprite sprite-XLSM"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='xlsx'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
        <i class="sprite sprite-XLSX"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='ods'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
        <i class="sprite sprite-ODS"></i>
        </button>
        <button nbButton class="bg-transparent border-0 btn1 " *ngIf="tooltipData=='slk'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
        <i class="sprite sprite-SLK"></i>
        </button>                
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData=='gif'"
nbTooltip= {{formatData}} nbTooltipPlacement="right"  nbTooltipStatus="info">
        
        </button>
        <!--------------------------- For format type other than Images ------------------------------->
 <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='pdf'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-PDF"></i>
        
        </button>

<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='Document' || tooltipData== 'docx' || tooltipData == 'doc'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-DOC"></i>
        
        </button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='txt'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info" >
        <i class="sprite sprite-TXT"></i>
        
        </button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='rtf'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-RTF"></i>
        
        </button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='odt'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-ODT"></i>
        
        </button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='bmp'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-BMP"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='pjp'"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
        <i class="sprite sprite-PJP"></i>
        
        </button>
        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='dib'"
        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                <i class="sprite sprite-DIB"></i>
                
                </button>

                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='dot'"
                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                        <i class="sprite sprite-DOT"></i>
                        
                        </button>

                        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='text'"
                        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                <i class="sprite sprite-TEXT"></i>
                                
                                </button>
                                <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='jfif'"
                                nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                        <i class="sprite sprite-JFIF"></i>
                                        
                                        </button>
                                        <button nbButton class="bg-transparent border-0 btn1" *ngIf="tooltipData =='pjpg'"
                                        nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
                                                <i class="sprite sprite-PJPG"></i>
                                                
                                                </button>
      
         
</span>
