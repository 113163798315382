<!-- /*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any. The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret  
* trade or copyright law. 
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Delete documents
///  Description: Delete docs generic module
///  Author     : Trisha Pathak                          
///  Created On : 09-08-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description 
///
///----------------------------------------------------------------- -->


<!-----------------Delete doc confirm modal start----------------->

<ng-template #confirmDelete let-data let-ref="dialogRef">
  <nb-card class="dltmodalheight">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Delete document(s)
        </h6>

      </div>
    </nb-card-header>

    <nb-card-body>
      <p class="paragraph text-basic mb-0"
        *ngIf="!externalAssociationFound && defaultDelText && !externalAssociationFoundMul">Are you sure you want to
        delete the selected document(s)? The selected
        document(s) will be moved to trash and other users will no longer have access.</p>
      <div *ngIf="externalAssociationFound && !externalAssociationFoundMul">
        <p class="paragraph text-basic mb-0">This
          document is associated with external solutions like Tally.</p>
        <p class="paragraph text-basic mb-0" style="margin-top: 8px;">If you
          proceed, the document will be trashed and the external associations will be removed. Are you sure you want to
          continue?</p>
      </div>

      <div *ngIf="externalAssociationFoundMul && !externalAssociationFound">
        <p class="paragraph text-basic mb-0">Some of the selected document(s) are associated with external solutions
          like Tally.</p>
        <p class="paragraph text-basic mb-0" style="margin-top: 8px;">If you proceed, the document(s) will be trashed and the external
          associations will be removed. Are you sure you want to continue?</p>
        <div>
          <p class="paragraph text-basic mb-0" style="margin-top: 12px;"><b>The following document(s) are associated with external solutions like
              Tally:</b> </p>
        </div>
        <div class="chips-container">

          <ng-container >
            <ul *ngFor="let document of associatedDocumentsName; let i = index">
              <li class="">{{document}}</li>
            </ul>
          </ng-container>

        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton  [nbSpinner]="showSpinnerDelete"
      nbSpinnerStatus="primary"
      nbSpinnerSize="tiny" status="primary"  size="small" class="mr-3 delt2" (click)="deleteOwnedDoc(confirmDelete)">DELETE</button>
      <button   size="small" (click)="dialogRef.close(); isDeleted.emit(false);" outline status = "basic" class="dltCancel">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>


<!---------------------Delete doc confirm modal end--------------->


<!-----------------workspace out of sync modal start------------------->

<ng-template #wsOutOfSync let-data let-ref="wsOutOfSyncRef">
  <nb-card class="workdpspacemodaldelt">
    <nb-card-header> <h4 class="subtitle text-basic"> Workspace out of sync</h4> </nb-card-header>
    <nb-card-body>
      <p class="paragraph-2 text-basic">Your workspace went out of sync. There are some changes made which are</p>
      <p class="paragraph-2 text-basic" style="margin-top: -17px;">not synchronized.</p>
      <p class="paragraph-2 text-basic mb-0">Please reload this page before you continue.</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary"  size="small" class="mr-3"  (click)="reloadScreen();wsOutOfSyncRef.close()">Reload</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!--------------Work space out of sync modal end------------------>


