import { Injectable } from '@angular/core';
import { PeriodsService } from './periods.service';
import { SharedChart, SharedChartData } from '../data/shared-chart';

@Injectable()
export class SharedChartService extends SharedChartData {

  
  private data = { };

  constructor(private period: PeriodsService) {
    super();
    this.data = {
    
    };
  }

  getSharedChartData(period: string): SharedChart {
    return this.data[period];
  }
}
