<!--------------------------- Adding document format type in the lists ------------------------->
<!--------------------- For format type that is Images ---------------------------->
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='png' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-PNG"></i>
 
  
</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData=='jpg' || tooltipData == 'jpeg') && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-JPG"></i> 

</button>
 
<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData=='tiff' || tooltipData== 'tif') && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-TIF"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='gif' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-GIF"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData=='pjpeg' || tooltipData === 'pjpg') && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-PJPG"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='pjp' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-PJP"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='dib' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-DIB"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='jfif' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-JFIF"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xls' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='fods' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-FODS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xlsm' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLSM"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xlsx' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLSX"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='ods' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-ODS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='slk' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-SLK"></i>

</button>
<!--------------------- For format type other than Images ------------------>
 <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='pdf' && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-PDF"></i>

</button>

<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData =='Document' || tooltipData== 'docx' || tooltipData == 'doc') && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-DOC"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='txt' && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-TXT"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='rtf' && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-RTF"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='odt' && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-ODT"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='bmp' && shareddata) "
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-BMP"></i>

</button>
<button nbButton class="bg-transparent border-0  btn1" *ngIf="(tooltipData =='dot' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-DOT"></i>

</button>
<button nbButton class="bg-transparent border-0  btn1" *ngIf="(tooltipData =='text' && shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-TEXT"></i>

</button>






<!-------------------- for unshared user -------------------------------->
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='png' && !shareddata)"
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-PNGCopy"></i>
 
  
</button>

<button nbButton class="bg-transparent border-0 btn1" *ngIf="!shareddata"
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<img src="../../../../../assets/images/SVG/eye-off.svg">
 
  
</button>


<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData=='jpg' || tooltipData == 'jpeg') && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-JPGCopy"></i> 

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData=='tiff' || tooltipData== 'tif') && !shareddata ) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-TIFCopy"></i>

</button>

<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='gif' && !shareddata)"
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-GIFCopy"></i>

</button>
<!---------------------------- For format type other than Images --------------------------------->
 <button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='pdf' && !shareddata) "
 nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-PDFCopy"></i>

</button>

<button nbButton class="bg-transparent border-0 btn1" *ngIf="((tooltipData =='Document' || tooltipData== 'docx' || tooltipData == 'doc') && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-DOCCopy"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='txt' && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-TXTCopy"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='rtf' && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-RTFCopy"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='odt' && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-ODTCopy"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData =='bmp' && !shareddata) "
nbTooltip= "This document is not shared with you" nbTooltipPlacement="right" nbTooltipStatus="info">
<i class="sprite sprite-BMPCopy"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xls' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='fods' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-FODS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xlsm' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLSM"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='xlsx' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-XLSX"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='ods' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-ODS"></i>

</button>
<button nbButton class="bg-transparent border-0 btn1" *ngIf="(tooltipData=='slk' && !shareddata)"
nbTooltip= {{formatData}} nbTooltipPlacement="right" nbTooltipStatus="info">
 <i class="sprite sprite-SLK"></i>

</button>




