import { Component, ChangeDetectorRef, ChangeDetectionStrategy,HostListener, ElementRef, ViewChild, TemplateRef} from '@angular/core';
import { Router } from "@angular/router";
import { DetailAndActivityService } from '../../@core/utils/detail-and-activity.service';
import { ApiServiceService } from '../../@core/api-service.service';
import { MiniProfileService } from '../../@core/utils/mini-profile.service';
import { SharedService } from '../../@core/utils/shared.service';
import { VerifyDocumentService } from '../../@core/verify-document.service';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import * as mdl from "@aspnet/signalr";
import { GuestUserModalComponent } from '../../shared/guest-user-modal/guest-user-modal.component';
import { SignInService } from "../../@core/sign-in.service";
import { CookieService } from 'ngx-cookie-service';
import { FeatureFlagsService } from '../../@core/utils/feature-flag.service';
import { environment } from '../../../environments/environment';
import {BulkTagComponent} from '../../../app/shared/bulk-tag/bulk-tag.component';
import { DateTransformPipe } from '../../pages/date-transform.pipe';
import { SharedAccessService } from '../../@core/utils/shared-access.service';
import { SharedAccessComponent } from '../../shared/shared-access/shared-access.component';
import { OwnedDocsService } from '../../@core/utils/owned-docs.service';
import { NbDialogService } from '@nebular/theme';
@Component({
  selector: 'ngx-detail-and-activity',
  templateUrl: './detail-and-activity.component.html',
  styleUrls: ['./detail-and-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailAndActivityComponent{

  workSpaceId;
  getguid;
  pendingRequest: any;
  sanitizedUrl: any;
  pagesCount: string;
  timeFormat;
  subscriptionPlan: string;
  parserflag;
  subreparsedocguid;
  detailreparse;
  allUserLength:any
  openFromViewer = false;

  public _hubConnectonthird?: mdl.HubConnection;
  checkiddata: any;
  GuestFlag: string;
  category: any;
  activityfeature;
  urlcheck;
  notExtractedStatus: any;
  extensionValue: any;
  iseditableDocument: any;
  getGropuName: any[] = [];
  categoryList: any;
  userAndGroupName: string = '';
  userList: any;
  groupData: boolean;
  userName: string = '';
  @ViewChild('shareChild') shareuse: SharedAccessComponent;
  guestAccessSection: boolean;
  sharedAccessSection: boolean;
  hideEdit: boolean;
  guestAccess:any
  chkeditrights:any
  editrights:any
  @ViewChild('miniprofile', { read: TemplateRef }) miniprofile:TemplateRef<any>;
  @ViewChild('miniprofileview', { read: TemplateRef }) miniprofileview:TemplateRef<any>;
  @ViewChild(GuestUserModalComponent) guestChild: GuestUserModalComponent;
  sharedEmailShow: boolean;
  sharedCountEmail: any[]=[];
  guestemailid: string;
  docIsDisplayed: boolean = true;
  docUnderProcessing: boolean = false;
  docFormatNotSupported: boolean = false;
  docIsLockedOrCorrupt: boolean = false;
  docParkingQueue: boolean= false;
  docState = '';
  currentTab: string;
  externalAssociations: string = '';
  isGuestAccessInRole: boolean = false;
  constructor(
    private datePipe: DateTransformPipe,private owneddocsservice:OwnedDocsService, private miniProfile:MiniProfileService, private dialogService: NbDialogService,
      private featureFlagService: FeatureFlagsService,private _eref: ElementRef,
    private cookieService: CookieService,public sanitizer: DomSanitizer,
    private signInService: SignInService,private thumbnailService:VerifyDocumentService,
    private router: Router, private cd: ChangeDetectorRef, private sharedService : SharedService,
    private detailsactivity : DetailAndActivityService,private apiServiceService: ApiServiceService) {
    this.guestAccess = localStorage.getItem('isGuestAccess')
    this.isGuestAccessInRole = localStorage.getItem('isGuestAccess')?.toString().toLowerCase() =='true'?true:false;
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.getguid = localStorage.getItem('loginguid')
    this.category = localStorage.getItem('logincategory')
    this.chkeditrights = localStorage.getItem('editaccess')
    this.editrights = JSON.parse(this.chkeditrights);
      this.timeFormat = localStorage.getItem('TimeFormat')
      this.subscriptionPlan = localStorage.getItem('wsSubscriptionPlan')
    this.GuestFlag = localStorage.getItem('GuestFlag').toLowerCase();
     this.activityfeature = localStorage.getItem('activityfeature');
      this.parserflag = localStorage.getItem('parserflag');
      this.detailreparse = this.sharedService.detailscheck.subscribe((data) => {
        let docguidrealtime = data;
        if(this.detailval.docGuid == docguidrealtime){
          this.getdetailsdataparser(this.detailval)
          this.getActivitydataparser(this.parseract);
        }


      });

      this.detailreparse = this.sharedService.detailsparserapi.subscribe((data) => {
        this.checkiddata = data;
        let onlyid = this.checkiddata?.split('|')
        if(this.detailval.docGuid == onlyid[0]){
          this.getdetailsdataparser(this.detailval)
          this.getActivitydataparser(this.parseract);
        }


      });


      this.urlcheck = this.router.url;

       }

  url = './assets/images/Document-Details-No-Document-Selected.svg';
  detailArrayVar: boolean = true;
  // shereTemplateListService(data){

  //  this.sharedaccessservice.shereTemplateListService.subscribe(data)=>{

  //   }
  // }
  getUserName = [];
  getGroupName = [];
  selectedDocs = [];
  showdata:any;
  dialogRef:any
  onCellClicked(data){

    if(data == 'uploaded_by'){
      let minidata = {
        "wsoid": Number(this.workSpaceId),
        "emailId":  this.selectedDocs[0].uploadedByEmailId,
        "originalFileName" :'',
       }
       this.showdata = minidata;

       this.dialogRef = this.dialogService.open(this.miniprofile);
    }else if(data == 'owner'){
      let minidata = {
        "wsoid": Number(this.workSpaceId),
        "emailId":  this.selectedDocs[0].docOwnerEmailId,
        "originalFileName" :'',
       }
       this.showdata = minidata;

       this.dialogRef = this.dialogService.open(this.miniprofile);
    }
    else if(data == 'verified_by'){
      let minidata = {
        "wsoid": Number(this.workSpaceId),
        "emailId":  this.verifierEmail,
        "originalFileName" :'',
       }
       this.showdata = minidata;

       this.dialogRef = this.dialogService.open(this.miniprofile);
    }
    else{
      let minidata = {
        "wsoid": Number(this.workSpaceId),
        "emailId":  data,
        "originalFileName" :'',
       }
       this.showdata = minidata;

       this.dialogRef = this.dialogService.open(this.miniprofile);
    }



  }
ngOnInit(){
  this.currentTab = window.location.href.toString();

  this.sharedService.docPreview.subscribe((response) => {
    if(this.currentTab?.includes("my-queue")){
      this.docState = response[0]?.parseStatus;
    }
    else{
      this.docState = response[0].parserstatuscode;
    }
    if (this.docState == 'doc_21') {
      this.docUnderProcessing = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    } else if (this.docState == 'doc_15'){
      this.docParkingQueue = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docUnderProcessing = false;

    } else if (this.docState == 'doc_22') {
      this.docIsLockedOrCorrupt = true;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    } else if (this.docState == 'doc_23') {
      this.docFormatNotSupported = true;
      this.docUnderProcessing = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    }else{
      this.docIsLockedOrCorrupt = false;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = true;
      this.docParkingQueue = false;
    }
  })
  this.sharedService.cellClickedData.subscribe((data)=>{
  this.onVerifyDocDetails(this.selectedDocs[0]);
  this.getSharedWithData(Number(localStorage.getItem('docId')));
  this.getSharedDetails();
  })
  this.sharedService.isShare.subscribe((flag:any)=>{
    this.isAllStatus = flag;
  })
  let getstdata = window.location.toString();
  let getarrdata = getstdata?.split('/');
  if( getarrdata[5] == 'trash' ){
    this.guestAccessSection=false
    this.sharedAccessSection=false

  }
  else if(getarrdata[5] == 'my-queue'){
    this.guestAccessSection=false
    this.sharedAccessSection=true
   }else{
    this.guestAccessSection=true
    this.sharedAccessSection=true
   }
   if(getarrdata[5] == 'workspace-queue')
   {
    this.hideEdit=true;
    if(this.selectedDocs[0]?.docStatus == 'Pending'){
      this.guestAccessSection=false;
    }
   }


  this.sharedService.selectedDocsData.subscribe((data:any)=>{
    this.selectedDocs = data;
  })
  this.getSharedWithData(Number(localStorage.getItem('docId')));
  if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
    this.openFromViewer = true;
  }
  if (this.router.url.includes('/verification/verify-document')){
    this.guestAccessSection = false;
  }
}

docOwnerEmailId
shareAccessValidate(){
   if(this.router.url.includes('/verification/verify-document')){
    return true;
   }
  else if(this.router.url.includes('/view/view-document')){
    this.docOwnerEmailId = this.detailval?.docOwnerEmailId;
    if((this.editrights)|| (this.docOwnerEmailId === this.createdBy)){
      return true;
    }
    return false;
  }
  if(this.selectedDocs.length> 0 && this.selectedDocs.length == 1){
    this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
    localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
    /*
     *Return if user is owner of selected document
     */
    let flag = false;

    let getstdata = window.location.toString();
    let getarrdata = getstdata?.split('/');
    if( getarrdata[5] == 'owned-by-me' ){
      flag = true;
      }
      else if(getarrdata[5] == 'my-queue'){
      flag = true;

      }
      else if(getarrdata[5] == 'shared-with-me'){
        if(this.isGuestAccessInRole){
          flag = true;
        }
        else{
          flag = false;
        }

      } else if(getarrdata[4] == 'cabinet'){
        if(this.selectedDocs.length > 0 && ((this.isGuestAccessInRole && this.selectedDocs[0].docShareStatus == "Shared" )|| this.docOwnerEmailId === this.createdBy)){
          flag= true;
        }
      }
      else if(getarrdata[4] == 'search?q='){
        if(this.selectedDocs.length > 0 && ((this.isGuestAccessInRole && this.selectedDocs[0].docShareStatus == "Access" )|| this.docOwnerEmailId === this.createdBy)){
          flag= true;
        }
      }



    return flag;
  }

}
guestRole
guestAcessMethod(){
  if ((this.router.url.includes('/view/view-document'))){
    this.docOwnerEmailId=this.detailval?.docOwnerEmailId
    if((this.guestAccess == 'true')){
      return true;
    }
    else{
      return false;
    }
  }
  if(this.router.url.includes('/verification/verify-document'))
  {
    return false;
  }
  let flag = false;
  let getstdata = window.location.toString();
  let getarrdata = getstdata?.split('/');
  if(this.guestAccess =='true'){
    flag = true;
    if(getarrdata[5] == 'workspace-queue'){
      flag = false;
    }
  }
  return flag
}
rightUserList:any
UploadRightsUserList() {

  var st = window.location.toString();
  var arr = st?.split("/");
  let postData: any
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "WsLink": arr[2],
  }
  this.owneddocsservice.searchAllUsersService(postData).subscribe((response : any)=>{
    let actualUserLength = [];
    response.data.forEach(user=>{
      if(user.roleName !== "Tally Viewer With Rights" && user.roleName !== "Tally Viewer" && user.emailId !== this.createdBy ){
      actualUserLength.push(user.roleName);
      }
    })
    this.allUserLength = actualUserLength.length;
  })
  this.pendingRequest = this.owneddocsservice.UploadRightsUserListService(postData).subscribe((response: any) => {
    if (response.isSuccess) {
      this.rightUserList = response?.data;





    }
  }
  )
}
isSelectedAllUsers
isCheckedAllUsers:boolean;
isAllUserShare:boolean;
shareuserCount
showVerifier:boolean
verifierName
isUsersGroup:boolean
isSelectedAllUser($event) {
  this.isSelectedAllUsers = $event;
}
  sharedGroupCount($event) {
  this.shareuserCount = $event;
}
isCheckedAllUser($event) {
  this.isCheckedAllUsers = $event;
  this.isAllUserShare = $event;
}
sharedGroup($event) {
  this.setUserGroupArr = $event;
  if (this.setUserGroupArr?.length > 0 && !this.isCheckedAllUsers) {
    this.isAllUserShare = false;
  }
  if (this.setUserGroupArr.includes(this.verifierName)) {
    this.showVerifier = true;
  } else {
    this.showVerifier = false;
  }

}
isSelectNoUsers
isSelectNoUser($event){
  this.isSelectNoUsers = $event;
}
isUserGrp($event) {
  this.isUsersGroup = $event;
  this.cd.detectChanges();
}
  getSharedDetails(value?:number){
    if(Number(localStorage.getItem('docId')) || value){
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(localStorage.getItem('docId')) || value,
        "database": "get2doxMaster",
      }
      this.getUserName = [];
      this.getGroupName = [];
      this.pendingRequest = this.owneddocsservice.getSharedWithModalDataForView(postData).subscribe((response: any) => {
  if(response){
    this.getGroupName = [];

    this.userList = response.data;
    response.data.filter((obj)=>{
      if (obj.categroy == 'GROUP'){
        let groupname =' '+obj.name;
        this.getGroupName.push(groupname)
      }
      

   })
   
   this.getUserName = response?.data?.filter((obj)=>{
    return obj.categroy == 'USER'
     
  })

  if(this.getUserName.length>0){
    let temp_var_arr=[]
    for(var i=0; i<this.getUserName.length; i++){
      temp_var_arr.push(" "+this.getUserName[i].name)
    }
    this.getUserName=temp_var_arr
    
  }

 this.userAndGroupName = this.getGroupName.toString();
  }


      })
    }


  }
  getName(name){
    // console.log(name)
  }
  hidedetail;
  crossdetails(){
    this.sharedService.closeDetailBar.next();
  }
  @ViewChild(BulkTagComponent) rightnavchild1: BulkTagComponent;
  @HostListener('document:click', ['$event'])
  documentClicks(event: MouseEvent) {

  }
  isAllStatus:any
  ownerEmailID:any
  verifierEmailID:any;

  openShareCommonModal(params){
    if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
      this.selectedDocs[0]=this.detailval;
    }
    this.UploadRightsUserList();
    this.onVerifyDocDetails(this.selectedDocs[0]);

   setTimeout(() => {
    const ids=[]
    const docVerifierEmailId = [];
    const docOwnerEmailId = [];
    const selectedGuid =[];
    this.selectedDocs.forEach(obj=>{
      ids.push(obj.docId)

      docVerifierEmailId.push(obj.docVerifierEmailId);
      docOwnerEmailId.push(obj.docOwnerEmailId)
      selectedGuid.push(obj.docGuid)
    })
    this.getSharedWithData(ids[0]);
     this.sharedService.isShare.next(this.isAllStatus);
    this.shareuse.shareWithMutipleUsers(ids,selectedGuid);
   }, 500);



    }
    sharedWithUsers:any;
    setUserGroupArr:any
    getSharedWithData(docid) {
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(docid),
        "database": "get2doxMaster",
      }
      if(!isNaN(docid)){
        this.pendingRequest = this.owneddocsservice.getSharedWithModalDataForView(postData).subscribe((response: any) => {
          if (response) {
            this.sharedWithUsers = response.data;
            this.setUserGroupArr = this.sharedWithUsers;
            /*
              *radio button selection value checking
           */
            this.sharedService.getuserradio.next(true);
            this.sharedService.selectedAccessEditor.next(response.data);
            this.cd.detectChanges();
          }

          else {
            /*
              *radio button selection value checking
           */
            this.sharedService.getuserradio.next(false);
          }
        });
      }
    }
    onVerifyDocDetails(params){
      var st = window.location.toString();
      var arr = st?.split("/");
      let postdata={
        "DocGuid" :params?.docGuid,
        "DocID": params?.docId,
        // "WsLink": arr[2],
        "Wsoid": Number(localStorage.getItem('localwsId')),
        "currentUser":localStorage.getItem('localloginId'),
        }
           this.thumbnailService.verifierDocDetailsService(postdata).subscribe((customTags:any)=>{
            if (customTags.isSuccess) {
              this.isAllStatus = customTags?.data.docverificationdetailslists[0].isAllUserShare;
              this.sharedService.isShare.next(this.isAllStatus);
            }
           })
    }
  titletabdata;
  tabActive(data){
    this.titletabdata = data.tabTitle;
    if(this.titletabdata == 'DETAILS'){
      this.sharedService.tabclickdetailact.next();
    }
    else if(this.titletabdata == 'ACTIVITY'){
      this.sharedService.tabclickdetailact.next();
    }
  }
  cellData
  GuestModalShare(){
    /*
    * This condition is applied here for giving the data to guest share inside verification componenet
    */
    if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
      this.cellData =  this.detailval;
    }
    else{
      this.cellData = this.selectedDocs[0];
    }

    let postData={
      "wsoid": Number(this.workSpaceId),
      "docID": Number(this.cellData.docId),
      "role": "View",
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid" : this.getguid,
      "DocGuid" : this.cellData.docGuid
    }

    this.pendingRequest = this.owneddocsservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        if(response.data[0].docShareStatus === 'Not Access' || this.cellData.docOwnerEmailId === this.createdBy) {
          if(this.guestAccess === 'true'){
            this.guestChild.open(this.selectedDocs);
          } else{
            this.guestChild.openNonEdit(this.cellData);
          }
        } else{
          if(this.guestAccess === 'true'){
            this.guestChild.open(this.selectedDocs);
          } else{
            this.guestChild.openNonEdit(this.cellData);
          }

        }
      }

    }, error => {

    })
  // }
  // }
  }


  detaildatabody(data){
    if(data == 'DETAILS'){
      this.sharedService.tabclickdetailact.next();
    }

  }
  activitydatabody(data){
    if(data == 'ACTIVITY'){
      this.sharedService.tabclickdetailact.next();
    }
  }

  urldetails = window.location.href;
  searchurl = '';
  CabinetUrl = '';
  activitymsg = '';
  activitytabdis = false;
  actdata(data, isGuest?){

    if(data){
      let urlval = this.urldetails;

      if(urlval.search('pages/cabinet') !=-1){
        this.CabinetUrl = 'cabinet';
      }
      else if(urlval.search('pages/search')){
        this.searchurl = 'search';
      }
      else{
        this.CabinetUrl = '';
        this.searchurl = '';
      }
      if(this.urlcheck !='/pages/documents/my-queue'){
        // alert('kya ye chl rha h ')
        this.getdetailsdata(data, isGuest);

        // console.log(this.getdetailsdata)

      }
      else if((urlval.search('verification/verify-document')) || (urlval.search('view/view-document'))){
        this.getdetailsdata(data,isGuest);
      }
      else{
           if(data.isReadable){
        this.getdetailsdata(data, isGuest);
      }

      else{
        if(!data.isReadable){
          this.sharedService.closeDetailBar.next();
        }

      }
      }


      if(this.CabinetUrl){

          this.activitymsg = '';
          if(this.urlcheck !='/pages/documents/my-queue'){

            if(data.documentowner !== this.createdBy){
              if(data.docShareStatus == 'Not Shared'){
                this.activitytabdis = true;
              }
              else{
                this.activitytabdis = false;
                this.getActivitydata(data, isGuest);
              }
           }
           else{
            this.activitytabdis = false;
            this.getActivitydata(data, isGuest);
           }



          }
          else{
            if(data.isReadable){
              if(data.documentowner !== this.createdBy){
                if(data.docShareStatus == 'Not Shared'){
                  this.activitytabdis = true;
                }
                else{
                  this.activitytabdis = false;
                  this.getActivitydata(data, isGuest);
                }
             }
             else{
              this.activitytabdis = false;
              this.getActivitydata(data, isGuest);
             }
            }

            else{
              if(data){
                this.sharedService.closeDetailBar.next();
              }

            }
          }


      }
      else if(this.searchurl){

          this.activitymsg = '';
          if(this.urlcheck !='/pages/documents/my-queue'){

            if(data.documentowner !== this.createdBy){
              if(data.docShareStatus == 'Not Access'){
                this.activitytabdis = true;
              }
              else{
                this.activitytabdis = false;
                this.getActivitydata(data, isGuest);
              }
           }
           else{
            this.activitytabdis = false;
            this.getActivitydata(data, isGuest);
           }



          }
          else{
            if(data.isReadable){
              if(data.documentowner !== this.createdBy){
                if(data.docShareStatus == 'Not Access'){
                  this.activitytabdis = true;
                }
                else{
                  this.activitytabdis = false;
                  this.getActivitydata(data, isGuest);
                }
             }
             else{
              this.activitytabdis = false;
              this.getActivitydata(data, isGuest);
             }
            }

            else{
              if(data){
                this.sharedService.closeDetailBar.next();
              }

            }
          }

      }
      else{
        this.activitymsg = '';
        this.getActivitydata(data, isGuest);

      }
    }
    else{
      this.sanitizedUrl = 'No Data';
      this.url = './assets/images/Document-Details-No-Document-Selected.svg';
      this.detailArrayVar = true;
      this.docRename = '';
      this.imageval = '';
      this.cd.detectChanges();
    }
  }

  originalname;
  size;
  shareDocCount;
  formate;
  pages;
  docType;
  docOwnerName;
  fileCreatedDate;
  modifieddatetime;
  uploadedby;
  uploadeddatetime;
  verifieddatetime;
  documentformate;
  dimension;
  pageConversion;
  currentdocdisplayname;
  fileExtensionview;
  notesview;
  previousOwnernameview;
  docRename;
  createdBy;
  showblankimage;
  filevalue;
  modifiedfilevalue;
  uploadval;
  verifierDisplayName;
  verifierEmail;
  statusdoc;
  statusvalue;
  sharedDocumentsData = [];
  arrayId = [];
  lastindexval;
  detailArray = [];
  lanscapevalue = false
  pagecount;
  fileextension;
  parserstatus;
  parserautodetect;
  language;
  manuallanguage;
  detailval;
  mergelanguage;
  detailsshowdata = false;
  getdetailsdata(valuetarget, isGuest?){
    this.detailval = valuetarget;
    this.onVerifyDocDetails(valuetarget);
    this.getSharedDetails(valuetarget.docId);
    if(!this.arrayId){
      if(valuetarget){
        this.createdBy = localStorage.getItem('localloginId');
        let detail = {
          "wsoid": valuetarget.wsoid || parseInt(localStorage.getItem('localwsId')),
          "docId": valuetarget.docId || JSON.parse(valuetarget.docid),
          "CreatedBy": this.createdBy,
          "guid" : this.getguid,
          "DocGuid" : valuetarget.docGuid || valuetarget.docguid
      }
      this.originalname = '';
      this.showblankimage = '';
      this.size = '';
      this.formate = '';
      this.pages = '';
      this.docType = '';
      this.docOwnerName = '';
      this.fileCreatedDate = '';
      this.modifieddatetime = '';
      this.uploadedby = '';
      this.uploadeddatetime = '';
      this.externalAssociations = '';
      this.verifieddatetime = '';
      this.url = '';
      this.documentformate = '';
      this.pagesCount = '';
      this.dimension = '';
      this.pageConversion = '';
      this.currentdocdisplayname = '';
      this.fileExtensionview = '';
      this.notesview = '';
      this.previousOwnernameview = '';
      this.docRename = '';
      this.detailArray = [];
      this.filevalue = '';
      this.modifiedfilevalue = '';
      this.uploadval = '';
      this.verifierDisplayName = '';
      this.verifierEmail= '';
      this.statusdoc = '';
      this.statusvalue = '';
      this.pagecount = '';
      this. parserstatus = '';
      this.parserautodetect = '';
      this.language = '';
      this.manuallanguage = '';
      this.lanscapevalue = false;
      this.detailsshowdata = true;
      this.detailsactivity.getdetailsdata(detail).subscribe((response: any)=> {

        if (response.isSuccess) {

          this.rightnavchild1.getNoteData(response);
          this.arrayId.push(valuetarget.docId);
          this.sharedService.notes.next(response);
        /*
          hide image and text on empty table
        */
          if(this.detailArray?.length > 0){
            this.detailArrayVar = false;
          }else{
            this.detailArrayVar = true;
          }

           this.detailArray = response.data.documentDetailsList;
           let sharedArray = response.data.documentShareUserList;
           this.iseditableDocument = this.detailArray[0].iseditable;
           /*
           * when user not sign-in and hitting some url
           */
           let getstdata = window.location.toString();
            let getarrdata = getstdata?.split('/');
            if(getarrdata[5] == 'my-queue'){
              this.originalname = '';
                this.docRename = this.detailArray[0].originalFileName;

            }else{
              this.originalname = this.detailArray[0].originalFileName;
              this.docRename = this.detailArray[0].docRename;

            }
          this.shareDocCount = this.detailArray[0].shareDocCount;
           this.size = this.detailArray[0].size;
           this.formate = this.detailArray[0].documentFormat;
           this.pages = this.detailArray[0].pageCount;
           this.docType = this.detailArray[0].docType;
           this.docOwnerName = this.detailArray[0].docOwnerName;
           this.statusdoc = this.detailArray[0].docStatus;
           this.pagecount = this.detailArray[0].pageCount
           this.fileextension =  this.detailArray[0].fileExtension;
           this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();
           this.verifierDisplayName = this.detailArray[0].verifiedByDisplayName;
           this.verifierEmail = this.detailArray[0].verifiedByEmailId;
           this.externalAssociations = this.detailArray[0].externalSol != ''? this.detailArray[0].externalSol: '';
           /*
                *parser language
          */
           if(this.parserflag == 'True'){
              //  this.parserstatus = this.detailArray[0].parseStatus;
               this.language = this.detailArray[0].language;
               if(this.language != 'Autodetect'){
                   /*
                       *In the edittable document format always show language detected
                       *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
                       *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
                  */
                 if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
                  || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
                  || (this.fileExtensionview.toLowerCase() == '.odt')
                 ){

                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                  }
                  else{
                    this.parserautodetect = 'Processing';
                    this.manuallanguage = '';
                  }

                 }
                else{
                  this.manuallanguage = this.detailArray[0].language;
                  this.parserautodetect = '';
                }

               }
               else{
                 /*
                    *parser status related changes
                */
               if(this.detailArray[0].parserlanguage){
                let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                if(getparserlanguage[0]){
                  this.mergelanguage = getparserlanguage[0];

                }
                if(getparserlanguage[0] && getparserlanguage[1]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                }
                if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                }

                this.parserautodetect = this.mergelanguage
                this.manuallanguage = '';
               }
               else{
                this.parserautodetect = 'Processing';
                this.manuallanguage = '';
               }

               }

               if(this.detailArray[0].parseStatus == 'doc_08'){
                this.parserstatus = 'Success';
              }
              else if(this.detailArray[0].parseStatus == 'doc_05'){
                this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == 'doc_01'){
                this.parserstatus = 'Extracting'
              }
              else if((this.detailArray[0].parseStatus == 'doc_07')
              || (this.detailArray[0].parseStatus == 'doc_15')){
                if(this.detailArray[0].running_module_code == 'm_13'){
                  this.parserstatus = 'Failure1';
                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Extraction Failed';
                    this.manuallanguage = '';
                   }
                }
                else{
                  this.notExtractedStatus = this.detailArray[0].parserapirequest;
                  if(this.notExtractedStatus == '1'){
                    this.parserstatus = 'Extracting'
                  }
                  else{
                    this.parserstatus = 'Not Extracted';
                  }

                }

              }
              else if((this.detailArray[0].parseStatus == 'doc_00') ||
              (this.detailArray[0].parseStatus == 'doc_03') ||
              (this.detailArray[0].parseStatus == 'doc_04') ||
              (this.detailArray[0].parseStatus == 'doc_18') ||
              (this.detailArray[0].parseStatus == 'doc_24') ||
              (this.detailArray[0].parseStatus == 'doc_25') ||
              (this.detailArray[0].parseStatus == 'doc_27')){
                this.parserstatus = 'Failure2'
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
              }
              else if(this.detailArray[0].parseStatus == 'doc_23'){
               this.parserstatus = 'Failure3'
              }
              else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
               this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == ''){

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'In queue'
                }
                else{
                  this.parserstatus = 'Not Extracted';
                }

              }
           }
           else{


            if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
            || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
            || (this.fileExtensionview.toLowerCase() == '.odt' || this.iseditableDocument == 1)
           ){
            if(this.detailArray[0].parseStatus == 'doc_08'){
              this.parserstatus = 'Success';
            }
            else if(this.detailArray[0].parseStatus == 'doc_05'){
              this.parserstatus = 'Extracting'
            }
            else if(this.detailArray[0].parseStatus == 'doc_01'){
              this.parserstatus = 'Extracting'
            }
            else if((this.detailArray[0].parseStatus == 'doc_07')
            || (this.detailArray[0].parseStatus == 'doc_15')){
              if(this.detailArray[0].running_module_code == 'm_13'){
                this.parserstatus = 'Failure4';
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
              }
              else{

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'Extracting'
                }
                else{
                  this.parserstatus = 'Not Extracted';
                }

              }

            }
            else if((this.detailArray[0].parseStatus == 'doc_00') ||
            (this.detailArray[0].parseStatus == 'doc_03') ||
            (this.detailArray[0].parseStatus == 'doc_04') ||
            (this.detailArray[0].parseStatus == 'doc_18') ||
            (this.detailArray[0].parseStatus == 'doc_24') ||
            (this.detailArray[0].parseStatus == 'doc_25') ||
            (this.detailArray[0].parseStatus == 'doc_27')){
              this.parserstatus = 'Failure5'
              if(this.detailArray[0].parserlanguage){
                let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                if(getparserlanguage[0]){
                  this.mergelanguage = getparserlanguage[0];

                }
                if(getparserlanguage[0] && getparserlanguage[1]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                }
                if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                }

                this.parserautodetect = this.mergelanguage
                this.manuallanguage = '';
               }
               else{
                this.parserautodetect = 'Extraction Failed';
                this.manuallanguage = '';
               }
            }
            else if(this.detailArray[0].parseStatus == 'doc_23'){
             this.parserstatus = 'Failure6'
            }
            else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
             this.parserstatus = 'Extracting'
            }
            else if(this.detailArray[0].parseStatus == ''){

              this.notExtractedStatus = this.detailArray[0].parserapirequest;
              if(this.notExtractedStatus == '1'){
                this.parserstatus = 'In queue'
              }
              else{
                this.parserstatus = 'Not Extracted';
              }

            }
           }
           else{
            this.parserstatus = '';
           }

            this.parserautodetect = '';
            this.manuallanguage = '';
           }
           if(this.docRename){
            this.sendrenamedname(this.docRename);
           }
           else{
            this.sendrenamedname(this.originalname);

           }
           let index = sharedArray.findIndex((e)=>{
             return e.userEmailID === this.createdBy;
           });
           if(index>-1){
            this.sendRightsInfo(true)
           }

           else{
            this.sendRightsInfo(false)
           }
           this.sharedDocumentsData.push(response.data);
           localStorage.setItem('allSharedData',JSON.stringify(this.sharedDocumentsData));
           if(this.CabinetUrl){
            if((valuetarget.documentowner != this.createdBy ) && (!valuetarget.isallusershare)){
              this.url = '';
              if(this.CabinetUrl){
                 if(valuetarget.documentowner !== this.createdBy){
                    if(valuetarget.docShareStatus == 'Not Shared'){
                      this.sanitizedUrl = 'search data not shared '
                    }
                    else{
                       if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                    }
                 }
                 else{
                   if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                 }
              }
              else{

                 if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
              }
            }
            else{
              if(!valuetarget.readableReason){
                if(this.CabinetUrl){
                  if(valuetarget.documentowner !== this.createdBy){
                     if(valuetarget.docShareStatus == 'Not Shared'){
                       this.sanitizedUrl = 'search data not shared '
                     }
                     else{
                        if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                     }
                  }
                  else{
                    if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{

                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               }
               else{
                this.url = '';
                if(this.CabinetUrl){
                  if(valuetarget.documentowner !== this.createdBy){
                     if(valuetarget.docShareStatus == 'Not Shared'){
                       this.sanitizedUrl = 'search data not shared '
                     }
                     else{
                        if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                     }
                  }
                  else{
                    if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{

                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               }
            }
         }
         else if(this.searchurl){
          if((valuetarget.documentowner != this.createdBy ) && (!valuetarget.isallusershare)){
            this.url = '';
            if(this.searchurl){
               if(valuetarget.documentowner !== this.createdBy){
                  if(valuetarget.docShareStatus == 'Not Access'){
                    this.sanitizedUrl = 'search data not shared '
                  }
                  else{
                     if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{
                 if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
               }
            }
            else{

               if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
            }
          }
          else{
            if(!valuetarget.readableReason){
              if(this.searchurl){
                if(valuetarget.documentowner !== this.createdBy){
                   if(valuetarget.docShareStatus == 'Not Access'){
                     this.sanitizedUrl = 'search data not shared '
                   }
                   else{
                      if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                   }
                }
                else{
                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                }
             }
             else{

                if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             }
             else{
              this.url = '';
              if(this.searchurl){
                if(valuetarget.documentowner !== this.createdBy){
                   if(valuetarget.docShareStatus == 'Not Access'){
                     this.sanitizedUrl = 'search data not shared '
                   }
                   else{
                      if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                   }
                }
                else{
                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                }
             }
             else{

                if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             }
          }
       }
           else{
            if(!valuetarget.readableReason){
              if(this.CabinetUrl){
                if(valuetarget.documentowner !== this.createdBy){
                   if(valuetarget.docShareStatus == 'Not Shared'){
                     this.sanitizedUrl = 'search data not shared '
                   }
                   else{
                      if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                   }
                }
                else{
                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                }
             }
             else{

                if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             }
             else{
              this.url = '';
             }
           }


           this.currentdocdisplayname = this.detailArray[0].docOwnerDisplayName;
           this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();

           this.notesview = this.detailArray[0].notes;
           this.previousOwnernameview = this.detailArray[0].previousOwnerDisplayName;

           if((this.statusdoc == 'Trash') || (this.statusdoc == 'Delete') || (this.statusdoc == 'DELETE') || (this.statusdoc == 'TRASH')){
               this.statusvalue = this.statusdoc;
           }
           else{
            this.statusvalue = '';
           }

           this.detailArray= this.datePipe.transformView(this.detailArray);

           this.fileCreatedDate = this.detailArray[0].fileCreatedDate;
           this.modifieddatetime = this.detailArray[0].fileModifiedDate;
           this.uploadedby = this.detailArray[0].uploadedByDisplayName;
           this.uploadeddatetime = this.detailArray[0].uploadedDateTime;
           this.externalAssociations = this.detailArray[0].externalSol != ''? this.detailArray[0].externalSol: '';
           this.verifieddatetime = this.detailArray[0].verifiedDateTime;


           if(this.formate == 'Document' || this.formate == 'DOCUMENT'){
             this.documentformate = this.formate;
           }
           else if(this.formate == 'Pdf' || this.formate == 'PDF'){
            this.documentformate = this.formate;
           }
           else{
            this.documentformate = 'Image';
           }

           if(this.documentformate == 'Image'){
             this.dimension = (this.detailArray[0].width) + '' + ' (w) x' + this.detailArray[0].height + ' (h)';
             if(this.detailArray[0].width > this.detailArray[0].height){
                this.lanscapevalue = true;
             }
             else{
              this.lanscapevalue = false;
             }
           }
           else{
            this.dimension = '';
           }
           this.detailsshowdata = false;
           this.cd.detectChanges();
        }
      })
      }
      else{
        this.originalname = '';
      this.size = '';
      this.formate = '';
      this.pages = '';
      this.docType = '';
      this.docOwnerName = '';
      this.fileCreatedDate = '';
      this.modifieddatetime = '';
      this.uploadedby = '';
      this.uploadeddatetime = '';
      this.externalAssociations = '';
      this.verifieddatetime = '';
      this.url = '';
      this.documentformate = '';
      this.dimension = '';
      /**
       For dPages count sections
       */
      this.pagecount = '';
      this.currentdocdisplayname = '';
      this.fileExtensionview = '';
      this.pageConversion = '';
      this.notesview = '';
      this.previousOwnernameview = '';
      this.docRename = '';
      this.detailArray = [];
      this.showblankimage = 'No Details';
      this.sendimagename(this.showblankimage);
      this.detailsshowdata = false;
      }
    }
    else{
        this.lastindexval = this.arrayId[this.arrayId.length - 1];

        if(valuetarget){

          this.detailval = valuetarget;
          this.createdBy = localStorage.getItem('localloginId')

          let detail = {
            "wsoid": valuetarget.wsoid || parseInt(localStorage.getItem('localwsId')),
            "docId": valuetarget.docId || JSON.parse(valuetarget.docid),
            "CreatedBy": this.createdBy,
            "guid" : this.getguid,
            "DocGuid" : valuetarget.docGuid || valuetarget.docguid
        }
        this.originalname = '';
        this.showblankimage = '';
        this.size = '';
        this.pageConversion = '';
        this.formate = '';
        this.pages = '';
        this.docType = '';
        this.docOwnerName = '';
        this.fileCreatedDate = '';
        this.modifieddatetime = '';
        this.uploadedby = '';
        this.uploadeddatetime = '';
        this.externalAssociations = '';
        this.verifieddatetime = '';
        this.url = '';
        this.documentformate = '';
        this.dimension = '';
        this.currentdocdisplayname = '';
        this.fileExtensionview = '';
        this.notesview = '';
        this.previousOwnernameview = '';
        this.docRename = '';
        this.detailArray = [];
        this.filevalue = '';
        this.modifiedfilevalue = '';
        this.uploadval = '';
        this.verifierDisplayName = '';
        this.verifierEmail= '';
        this.statusdoc = '';
        this.statusvalue = '';
        this.lanscapevalue = false;
        this.detailsshowdata = true;
        this.parserstatus = '';

        //GetGuestList
        let getuserlistsPayload={
          "wsoid": Number(this.workSpaceId),
          "docGuid": valuetarget.docGuid || valuetarget.docguid,
        }

        this.detailsactivity.getListofSharedUsersOfDoc(getuserlistsPayload).subscribe((response:any)=>{
          if(response.isSuccess){
              if(response.data && response.data.length>0)
              {
                let uniqueEmailPhone=[]
                let multiListArr=[]
                /**
                 * create unique emailPhone list
                 */
                for(let i=0; i<response.data.length; i++)
                {
                  if(!uniqueEmailPhone.includes(response.data[i].guestWhatsAppEmail))
                  {
                    uniqueEmailPhone.push(response.data[i].guestWhatsAppEmail);
                  }
                }
    
                for(let i=0; i<uniqueEmailPhone.length; i++)
                {
                  if(!this.sharedCountEmail.includes(uniqueEmailPhone[i]))
                  {
                    this.sharedCountEmail.push(uniqueEmailPhone[i])
                  }
              
    
                }
                console.log("this.sharedCountEmail",this.sharedCountEmail)
                //this.MultipleListEmailWhatsappWise=multiListArr
                this.guestemailid=this.sharedCountEmail.toString()
                
              }
              
          }
        })



        this.detailsactivity.getdetailsdata(detail).subscribe((response: any)=> {
          this.sharedCountEmail=[]
          if (response.isSuccess) {

      
        let GuestEmaillist=  response.data.documentGuestShareList
            for(let i=0 ; i<=GuestEmaillist.length-1 ; i++){
              if(GuestEmaillist.length==0){
                this.sharedEmailShow=true
              }
              else{
                this.sharedEmailShow=false
                /*
                need to change from backend
                */
                //this.sharedCountEmail.push(" "+GuestEmaillist[i]?.userEmailID)

                //this.guestemailid=this.sharedCountEmail.toString()
              }
            }
 


            this.arrayId.push(valuetarget.docId);
             this.detailArray = response.data.documentDetailsList;
             if(this.detailArray?.length > 0){
              this.detailArrayVar = false;
            }else{
              this.detailArrayVar = true;
            }
             let sharedArray = response.data.documentShareUserList
             let getstdata = window.location.toString();
             this.docRename = this.detailArray[0].originalFileName
             let getarrdata = getstdata?.split('/');
             /*
             Hide Original Name if it is equal to modified name in my and ws queau
             */
             if(getarrdata[5] == 'my-queue'){
              this.originalname = this.detailArray[0].originalFileName;
                this.docRename = this.detailArray[0].originalFileName;

            }else{
              this.originalname = this.detailArray[0].originalFileName;
              this.docRename = this.detailArray[0].docRename;

            }
             this.verifierDisplayName = this.detailArray[0].verifiedByDisplayName
             this.verifierEmail = this.detailArray[0].verifiedByEmailId
             this.shareDocCount = this.detailArray[0].shareDocCount;
             this.iseditableDocument = this.detailArray[0].iseditable;
             this.size = this.detailArray[0].size;
             this.formate = this.detailArray[0].documentFormat;
             this.pages = this.detailArray[0].pageCount;
             this.pageConversion = this.detailArray[0].pageConversion;
             this.docType = this.detailArray[0].docType;
             this.docOwnerName = this.detailArray[0].docOwnerName;
             this.statusdoc = this.detailArray[0].docStatus;
             this.pagecount = this.detailArray[0].pageCount;
             this.fileextension =  this.detailArray[0].fileExtension;
             this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();

                /*
                *parser language
          */
           if(this.parserflag == 'True'){

            this.language = this.detailArray[0].language;
            if(this.language != 'Autodetect'){
                /*
                    *In the edittable document format always show language detected
                    *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
                    *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
               */
              if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
               || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
               || (this.fileExtensionview.toLowerCase() == '.odt')
              ){
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                 this.manuallanguage = '';
                }
                else{
                  this.parserautodetect = 'Processing'
                  this.manuallanguage = '';
                }

              }
             else{
               this.manuallanguage = this.detailArray[0].language;
               this.parserautodetect = '';
             }

            }
            else{
              /*
                 *parser status related changes
             */
                 if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Processing';
                  this.manuallanguage = '';
                 }
              }



              if(this.detailArray[0].parseStatus == 'doc_08'){
                this.parserstatus = 'Success';
              }
              else if(this.detailArray[0].parseStatus == 'doc_05' || this.detailArray[0].parseStatus == 'doc_03' ||this.detailArray[0].parseStatus == 'doc_04'){
                this.parserstatus = 'in process'
              }
              else if(this.detailArray[0].parseStatus == 'doc_01'|| this.detailArray[0].parseStatus == 'doc_02' || this.detailArray[0].parseStatus == ''){
                this.parserstatus = 'in process'
              }
              else if((this.detailArray[0].parseStatus == 'doc_07')
              || (this.detailArray[0].parseStatus == 'doc_15')){
                if(this.detailArray[0].running_module_code == 'm_13'){
                  this.parserstatus = 'Failure7'
                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Extraction Failed';
                    this.manuallanguage = '';
                   }
                }
                else{

                  this.notExtractedStatus = this.detailArray[0].parserapirequest;
                  if(this.notExtractedStatus == '1'){
                    this.parserstatus = 'Extracting'
                  }
                  else{
                    this.parserstatus = 'Not Extracted';
                  }

                }

              }
              else if((this.detailArray[0].parseStatus == 'doc_00') ||
              (this.detailArray[0].parseStatus == 'doc_10') ||
              (this.detailArray[0].parseStatus == 'doc_11') ||
              (this.detailArray[0].parseStatus == 'doc_12') ||
              (this.detailArray[0].parseStatus == 'doc_13') ||
              (this.detailArray[0].parseStatus == 'doc_14') ||
              (this.detailArray[0].parseStatus == 'doc_15')){
                this.parserstatus = 'Not Extarcted'
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
              }
              else if(this.detailArray[0].parseStatus == 'doc_01' ||this.detailArray[0].parseStatus == '' ||this.detailArray[0].parseStatus == 'doc_02' ||this.detailArray[0].parseStatus == 'doc_03'){
                this.parserstatus = 'In process'
              }
              else if(this.detailArray[0].parseStatus == 'doc_23'){
               this.parserstatus = 'In process'
              }
              else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
               this.parserstatus = 'In process'
              }
              else if(this.detailArray[0].parseStatus == ''){

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'In process'
                }
                else{
                  this.parserstatus = 'In process';
                }

              }
          }
            else{

              if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
              || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
              || (this.fileExtensionview.toLowerCase() == '.odt' || this.iseditableDocument == 1)
             ){

              if(this.detailArray[0].parseStatus == 'doc_08'){
                this.parserstatus = 'Success';
              }
              else if(this.detailArray[0].parseStatus == 'doc_05'){
                this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == 'doc_01'){
                this.parserstatus = 'Extracting'
              }
              else if((this.detailArray[0].parseStatus == 'doc_07')
              || (this.detailArray[0].parseStatus == 'doc_15')){
                if(this.detailArray[0].running_module_code == 'm_13'){
                  this.parserstatus = 'Failure'
                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Extraction Failed';
                    this.manuallanguage = '';
                   }
                }
                else{

                  this.notExtractedStatus = this.detailArray[0].parserapirequest;
                  if(this.notExtractedStatus == '1'){
                    this.parserstatus = 'Extracting'
                  }
                  else{
                    this.parserstatus = 'Not Extracted6';
                  }

                }

              }
              else if((this.detailArray[0].parseStatus == 'doc_00') ||
              (this.detailArray[0].parseStatus == 'doc_03') ||
              (this.detailArray[0].parseStatus == 'doc_04') ||
              (this.detailArray[0].parseStatus == 'doc_18') ||
              (this.detailArray[0].parseStatus == 'doc_24') ||
              (this.detailArray[0].parseStatus == 'doc_25') ||
              (this.detailArray[0].parseStatus == 'doc_27')){
                this.parserstatus = 'Failure'
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
              }
              else if(this.detailArray[0].parseStatus == 'doc_23'){
               this.parserstatus = 'Failure'
              }
              else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
               this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == ''){

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'In queue'
                }
                else{
                  this.parserstatus = 'Not Extracted';
                }

              }


             }
             else{
              this.parserstatus = '';
             }

            this.parserautodetect = '';
            this.manuallanguage = '';
            }


             if(this.docRename){
              this.sendrenamedname( this.docRename);
             }
             else{
              this.sendrenamedname( this.originalname);
             }
             let index = sharedArray.findIndex((e)=>{
               return e.userEmailID === this.createdBy;
             });
             if(index>-1){
              this.sendRightsInfo(true)
             }

             else{
              this.sendRightsInfo(false)
             }
             this.sharedDocumentsData.push(response.data);
             localStorage.setItem('allSharedData',JSON.stringify(this.sharedDocumentsData));
             if(this.CabinetUrl){
              if((valuetarget.documentowner != this.createdBy ) && (!valuetarget.isallusershare)){
                this.url = '';
                if(this.CabinetUrl){
                  if(valuetarget.documentowner !== this.createdBy){
                     if(valuetarget.docShareStatus == 'Not Shared'){
                       this.sanitizedUrl = 'search data not shared '
                     }
                     else{
                       if(valuetarget.formatExtension){
                         this.extensionValue = valuetarget.formatExtension.toLowerCase();
                       }
                       else if(valuetarget.format){
                        this.extensionValue = valuetarget.format.toLowerCase();
                      }
                       this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                     }
                  }
                  else{
                    if(valuetarget.formatExtension){
                      this.extensionValue = valuetarget.formatExtension.toLowerCase();
                    }
                    else if(valuetarget.format){
                      this.extensionValue = valuetarget.format.toLowerCase();
                    }
                   this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{
                if(valuetarget.formatExtension){
                  this.extensionValue = valuetarget.formatExtension.toLowerCase();
                }
                else if(valuetarget.format){
                  this.extensionValue = valuetarget.format.toLowerCase();
                }
                 this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
               }
              }
              else{
                if(!valuetarget.readableReason){
                  if(this.CabinetUrl){
                    if(valuetarget.documentowner !== this.createdBy){
                       if(valuetarget.docShareStatus == 'Not Shared'){
                         this.sanitizedUrl = 'search data not shared '
                       }
                       else{
                        if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                       }
                    }
                    else{
                     if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                    }
                 }
                 else{

                   if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                 }
                 }
                 else{
                  this.url = '';
                  if(this.CabinetUrl){
                    if(valuetarget.documentowner !== this.createdBy){
                       if(valuetarget.docShareStatus == 'Not Shared'){
                         this.sanitizedUrl = 'search data not shared '
                       }
                       else{
                         if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                       }
                    }
                    else{
                     if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                    }
                 }
                 else{

                   if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                 }
                 }
              }
           }
           else if(this.searchurl){
            if((valuetarget.documentowner != this.createdBy ) && (!valuetarget.isallusershare)){
              this.url = '';
              if(this.searchurl){
                if(valuetarget.documentowner !== this.createdBy){
                   if(valuetarget.docShareStatus == 'Not Access'){
                     this.sanitizedUrl = 'search data not shared '
                   }
                   else{
                     if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                   }
                }
                else{
                 if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                }
             }
             else{

               if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
             }
            }
            else{
              if(!valuetarget.readableReason){
                if(this.searchurl){
                  if(valuetarget.documentowner !== this.createdBy){
                     if(valuetarget.docShareStatus == 'Not Access'){
                       this.sanitizedUrl = 'search data not shared '
                     }
                     else{
                       if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                     }
                  }
                  else{
                   if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{

                 if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               }
               else{
                this.url = '';
                if(this.searchurl){
                  if(valuetarget.documentowner !== this.createdBy){
                     if(valuetarget.docShareStatus == 'Not Access'){
                       this.sanitizedUrl = 'search data not shared '
                     }
                     else{
                       if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                     }
                  }
                  else{
                   if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                  }
               }
               else{

                 if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               }
            }
         }
           else{
            if(!valuetarget?.readableReason){
              if(this.CabinetUrl){
                if(valuetarget.documentowner !== this.createdBy){
                   if(valuetarget.docShareStatus == 'Not Shared'){
                     this.sanitizedUrl = 'search data not shared '
                   }
                   else{
                     if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                   }
                }
                else{
                 if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
                }
             }
             else{

               if(valuetarget.formatExtension){
                          this.extensionValue = valuetarget.formatExtension.toLowerCase();
                        }
                        else if(valuetarget.format){
                          this.extensionValue = valuetarget.format.toLowerCase();
                        }
                         this.pdfFileConversion(this?.detailArray[0]?.thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             }
             else{
              this.url = '';
             }
           }

             this.currentdocdisplayname = this.detailArray[0].docOwnerDisplayName;
             this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();
             this.notesview = this.detailArray[0].notes;
             this.previousOwnernameview = this.detailArray[0].previousOwnerDisplayName;

             if((this.statusdoc == 'Trash') || (this.statusdoc == 'Delete') || (this.statusdoc == 'DELETE') || (this.statusdoc == 'TRASH')){
                 this.statusvalue = this.statusdoc;
             }
             else{
              this.statusvalue = '';
             }

             this.detailArray= this.datePipe.transformView(this.detailArray);

             this.fileCreatedDate = this.detailArray[0].fileCreatedDate;
             this.modifieddatetime = this.detailArray[0].fileModifiedDate;
             this.uploadedby = this.detailArray[0].uploadedByDisplayName;
             this.uploadeddatetime = this.detailArray[0].uploadedDateTime;
             this.externalAssociations = this.detailArray[0].externalSol != ''? this.detailArray[0].externalSol: '';
             this.verifieddatetime = this.detailArray[0].verifiedDateTime;

             if(this.formate == 'Document' || this.formate == 'DOCUMENT'){
               this.documentformate = this.formate;
             }
             else if(this.formate == 'Pdf' || this.formate == 'PDF'){
              this.documentformate = this.formate;
             }
             else{
              this.documentformate = 'Image';
             }

             if(this.documentformate == 'Image'){
              this.dimension = (this.detailArray[0].width) + '' + ' (w) x' + this.detailArray[0].height + ' (h)';

               if(this.detailArray[0].width > this.detailArray[0].height){
                this.lanscapevalue = true;
                }
                else{
                  this.lanscapevalue = false;
                }
             }
             else{
              this.dimension = '';
             }
             this.detailsshowdata = false;
             this.cd.detectChanges();
          }
        })
        }
        else{
          this.originalname = '';
        this.size = '';
        this.pageConversion = '';
        this.formate = '';
        this.pages = '';
        this.docType = '';
        this.docOwnerName = '';
        this.fileCreatedDate = '';
        this.modifieddatetime = '';
        this.uploadedby = '';
        this.uploadeddatetime = '';
        this.externalAssociations = '';
        this.verifieddatetime = '';
        this.url = '';
        this.documentformate = '';
        this.dimension = '';
        this.currentdocdisplayname = '';
        this.fileExtensionview = '';
        this.notesview = '';
        this.previousOwnernameview = '';
        this.docRename = '';
        this.detailArray = [];
        this.showblankimage = 'No Details';
        this.sendimagename(this.showblankimage);
        this.detailsshowdata = false;
        }

    }
  }

  datechange;
  getactdata:any;
  getnameval:any;
  uniquedata:any;
  arrydata:any;
  definedata:any;
  onlyFormat = ""
  pdfFileConversion(fileName,format){
    let postData = {
      "wsoid": Number(this.workSpaceId)  ,
      "fileName": fileName,
      "folderName": "thumbnail"
    }
    this.onlyFormat = '';
    // this.pendingRequest = this.thumbnailService.pdfViewerFileConversion(postData).subscribe((response: any) => {
    //   if(response.body.type == "image/svg+xml"){
    //     this.createImageFromBlob(response.body);
    //     this.onlyFormat = '';
    //     this.cd.detectChanges();
    //   }
    //   else{
    //      this.onlyFormat = format;
    //      this.sanitizedUrl = '';
    //      this.cd.detectChanges();
    //   }

    // })
  }
imageToShow: any;
createImageFromBlob(image: Blob) {
   let reader = new FileReader();
   reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
      /*
      * for image conversion blob to base 64 sanitizer
      */
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.imageToShow);

      this.cd.detectChanges();

   }, false);
   if (image) {
      reader.readAsDataURL(image);
   }
}

activitydata = [];
  getActivitydata(valueactivity , isGuest?){
    this.parseract = valueactivity;
 if(!this.arrayId){
  if(valueactivity){
    this.createdBy = localStorage.getItem('localloginId')

    let act = {
      "wsoid": valueactivity.wsoid || parseInt(localStorage.getItem('localwsId')),
      "docId": valueactivity.docId || JSON.parse(valueactivity.docid),
      "CreatedBy": this.createdBy,
      "guid" : this.getguid,
      "DocGUID" : valueactivity.docGuid || valueactivity.docguid
    }
     this.getactdata = [];
     this.uniquedata =[];
     this.arrydata = [];
     this.datechange = '';
    this.detailsactivity.getActivitydata(act).subscribe((response: any)=> {
      if (response.isSuccess) {
        this.arrayId.push(valueactivity.docId);
        this.getactdata = [];
        this.uniquedata =[];
        this.arrydata = [];
        this.datechange = '';




        this.getactdata = (response.data);



        /*
        *datetime modulate
        */

      for(let i=0;i<=this.getactdata?.length-1;i++){

          let removetime = moment(this.getactdata[i].logDateTime).format(this.timeFormat);

          if(removetime == "Invalid date"){
            let timeonly = this.getactdata[i].logDateTime?.split(" ")
            this.getactdata[i].logDateTime =timeonly[2] ? timeonly[1] + ' ' + timeonly[2] : timeonly[1];;
          }
          else{
            this.getactdata[i].logDateTime = removetime;
          }
      }




        this.definedata = this.getactdata.map((item,index)=>{
          let findpreviousdateval = this.uniquedata.findIndex((item2=>item2.currentactivityDate === item.activityDate))
          this.arrydata = [];
          if(findpreviousdateval === -1){

             let actvalarray = {
              docId: 64,
              logDateTime: item.logDateTime,
              logMessage: item.logMessage,
             }
             this.arrydata.push(actvalarray);

             let actval = {
              activityDate: item.activityDate ,
              currentactivityDate : item.activityDate,
              InnerList :  this.arrydata,
              createdDtm : item.createdDtm
             }
             this.uniquedata.push(actval);
          }
          else{

            let actvalarray = {
              docId: 64,
              logDateTime: item.logDateTime,
              logMessage: item.logMessage,
             }

             this.uniquedata[findpreviousdateval]['InnerList'].push(actvalarray);
          }
        })


          const makeUniqueArray=[]
          this.onlytodayyesterday = [];
          for(let i=0;i<=this.uniquedata?.length-1;i++){
            if(this.uniquedata[i]['activityDate']==="Yesterday" || this.uniquedata[i]['activityDate']==="Today"){
              this.onlytodayyesterday.push(this.uniquedata[i]);
            }

              if(this.uniquedata[i]['activityDate']!=="Yesterday" && this.uniquedata[i]['activityDate']!=="Today"){
              const parseDate=this.uniquedata[i].createdDtm.toString()?.split("T")[0]
              const checkUniqueArray=makeUniqueArray.findIndex(al=>al['samedate']===parseDate)

              if(checkUniqueArray===-1){
                const ary=[]
                ary.push(this.uniquedata[i]['InnerList'][0])
                const obj={samedate:parseDate,activityDate:this.uniquedata[i]['activityDate'],currentactivityDate:this.uniquedata[i]['currentactivityDate'],InnerList:ary}
                makeUniqueArray.push(obj)
              }else{
                const copyInnerList=[...this.uniquedata[checkUniqueArray]['InnerList']]
                copyInnerList.push(this.uniquedata[i]['InnerList'][0])
                makeUniqueArray[checkUniqueArray]['InnerList']=copyInnerList
              }
            }
          }
          // }
          this.activitydata=[...this.onlytodayyesterday,...makeUniqueArray]
          this.uniquedata = this.activitydata;

          this.cd.detectChanges();

      }

    });

  }

 }
 else{

    if(valueactivity){
      this.parseract = valueactivity;
      this.createdBy = localStorage.getItem('localloginId')

      let act = {
        "wsoid": valueactivity.wsoid || parseInt(localStorage.getItem('localwsId')),
        "docId": valueactivity.docId || JSON.parse(valueactivity.docid),
        "CreatedBy": this.createdBy,
        "guid" : this.getguid,
        "DocGUID" : valueactivity.docGuid || valueactivity.docguid
      }
       this.getactdata = [];
       this.uniquedata =[];
       this.arrydata = [];
       this.datechange = '';
      this.detailsactivity.getActivitydata(act).subscribe((response: any)=> {
        if (response.isSuccess) {
          this.arrayId.push(valueactivity.docId);
          this.getactdata = [];
          this.uniquedata =[];
          this.arrydata = [];
          this.datechange = '';

          this.getactdata = (response.data);

          /*
          * date time mainpulation
          */
          for(let i=0;i<=this.getactdata?.length-1;i++){
            let removetime = moment(this.getactdata[i].logDateTime).format(this.timeFormat);
            if(removetime == "Invalid date"){
              let timeonly = this.getactdata[i].logDateTime?.split(" ")
              this.getactdata[i].logDateTime = timeonly[2] ? timeonly[1] + ' ' + timeonly[2] : timeonly[1];;
            }
            else{
              this.getactdata[i].logDateTime = removetime;
            }

        }

          let allDatesArray = [];
          this.definedata = this.getactdata.map((item,index)=>{
            let findpreviousdateval = this.uniquedata.findIndex((item2=>item2.currentactivityDate === item.activityDate))
            this.arrydata = [];
            if(findpreviousdateval === -1){

               let actvalarray = {
                docId: 64,
                logDateTime: item.logDateTime,
                logMessage: item.logMessage,
               }
               this.arrydata.push(actvalarray);

               let actval = {
                activityDate: item.activityDate ,
                currentactivityDate : item.activityDate,
                InnerList :  this.arrydata,
                createdDtm : item.createdDtm
               }
               this.uniquedata.push(actval);              
               allDatesArray = this.uniquedata.map((item2,index)=> item2.activityDate);
            }
            else{

              let actvalarray = {
                docId: 64,
                logDateTime: item.logDateTime,

                logMessage: item.logMessage,

               }
               this.uniquedata[findpreviousdateval]['InnerList'].push(actvalarray);
               allDatesArray = this.uniquedata.map((item2,index)=> item2.activityDate.split(' ')[0]);
            }
          })
          const uniqueDates = new Set();
          allDatesArray.forEach((data)=>{
            uniqueDates.add(data.split(" ")[0]);
          })
          const finalArray = [];

          this.uniquedata.forEach(obj => {
              const date = obj.activityDate.split(' ')[0]; // Extracting date part
              if (uniqueDates.has(date)) {
                  const existingEntryIndex = finalArray.findIndex(entry => entry.date === date);
                  if (existingEntryIndex === -1) {
                      finalArray.push({ date: date, InnerList: obj.InnerList, ...obj });
                  } else {
                      finalArray[existingEntryIndex].InnerList = finalArray[existingEntryIndex].InnerList.concat(obj.InnerList);
                  }
              }
          });
          this.uniquedata = finalArray;
          const makeUniqueArray=[]
          this.onlytodayyesterday = [];
          for(let i=0;i<=this.uniquedata?.length-1;i++){
            if(this.uniquedata[i]['activityDate']==="Yesterday" || this.uniquedata[i]['activityDate']==="Today"){
              this.onlytodayyesterday.push(this.uniquedata[i]);
            }

              if(this.uniquedata[i]['activityDate']!=="Yesterday" && this.uniquedata[i]['activityDate']!=="Today"){
              const parseDate=this.uniquedata[i].createdDtm.toString()?.split("T")[0]
              const checkUniqueArray=makeUniqueArray.findIndex(al=>al['samedate']===parseDate)

              if(checkUniqueArray===-1){
                const ary=[]
                ary.push(this.uniquedata[i]['InnerList'][0])
                const obj={samedate:parseDate,activityDate:this.uniquedata[i]['activityDate'],currentactivityDate:this.uniquedata[i]['currentactivityDate'],InnerList:ary}
                makeUniqueArray.push(obj)
              }else{
                const copyInnerList=[...this.uniquedata[checkUniqueArray]['InnerList']]
                copyInnerList.push(this.uniquedata[i]['InnerList'][0])
                makeUniqueArray[i]['InnerList']=copyInnerList
              }
            }
          }

          this.activitydata=[...this.onlytodayyesterday,...makeUniqueArray]
          this.uniquedata = finalArray;

          this.cd.detectChanges();

        }


      });

    }

 }



  }

  docrename;
  imageval;
  sendrenamedname(data){
    console.log("impoer jfrejuhueg");
    if(data){
      this.docrename = data;
      this.imageval = '';
      console.log("impoer jfrejuhueg 12");
    }
    else{
      this.docrename = '';
      console.log("impoer jfrejuhueg 12345");

    }

  }
  sendRightsInfo(data){

    localStorage.setItem('isSharedRights',data);
  }

  sendimagename(data){
    if(data){
      this.imageval = './assets/images/Document-Details-No-Document-Selected.svg'
    }
  }
  gotobilling(){
    let domainValue = environment.domain;
    window.location.href = 'https://payments.' + domainValue + '/manage-subscription/sign-in';

  }
  /**
   * feature flagging
   * @param feature present or not
   */
  isFeatureEnable(feature) {
    const isEnabled =
      this.featureFlagService.isFeatureEnabled(feature) || false;
    return isEnabled;
  }
  /*
    *for real time updation
  */
    msg;

  parseract;
  rolename;
  groupname;



 /*
    *for real time updation
  */


  getdetailsdataparser(valuetarget){
    this.detailval = valuetarget;
    if(!this.arrayId){
      if(valuetarget){
        this.createdBy = localStorage.getItem('localloginId');
        let detail = {
          "wsoid": valuetarget.wsoid || parseInt(localStorage.getItem('localwsId')),
          "docId": valuetarget.docId,
          "CreatedBy": this.createdBy,
          "guid" : this.getguid,
          "DocGuid" : valuetarget.docGuid
      }

      this.parserautodetect = '';
      this.language = '';
      this.manuallanguage = '';
      this.mergelanguage = '';
      this.lanscapevalue = false;
      this.detailsactivity.getdetailsdata(detail).subscribe((response: any)=> {

        if (response.isSuccess) {
          this.arrayId.push(valuetarget.docId);
        /*
          hide image and text on empty table
        */
          if(this.detailArray?.length > 0){
            this.detailArrayVar = false;
          }else{
            this.detailArrayVar = true;
          }

           this.detailArray = response.data.documentDetailsList;

           let sharedArray = response.data.documentShareUserList;
           this.iseditableDocument = this.detailArray[0].iseditable;
           this.verifierDisplayName = this.detailArray[0].verifiedByDisplayName
           this.verifierEmail = this.detailArray[0].verifiedByEmailId
           /*
           * when user not sign-in and hitting some url
           */
           let getstdata = window.location.toString();
            let getarrdata = getstdata?.split('/');
            if(getarrdata[5] == 'my-queue'){
              this.docRename = this.detailArray[0].originalFileName
              this.originalname = '';

            }else{
              this.originalname = this.detailArray[0].originalFileName;
              this.docRename = this.detailArray[0].docRename;

            }

           this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();
           /*
                *parser language
          */
           if(this.parserflag == 'True'){

               this.language = this.detailArray[0].language;
               if(this.language != 'Autodetect'){
                   /*
                       *In the edittable document format always show language detected
                       *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
                       *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
                  */
                 if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
                  || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
                  || (this.fileExtensionview.toLowerCase() == '.odt')
                 ){
                   if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Processing'
                    this.manuallanguage = '';
                   }

                 }
                else{
                  this.manuallanguage = this.detailArray[0].language;
                  this.parserautodetect = '';
                }

               }
               else{
                 /*
                    *parser status related changes
                */
               if(this.detailArray[0].parserlanguage){
                let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                if(getparserlanguage[0]){
                  this.mergelanguage = getparserlanguage[0];

                }
                if(getparserlanguage[0] && getparserlanguage[1]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                }
                if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                  this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                }

                this.parserautodetect = this.mergelanguage
                this.manuallanguage = '';
               }
               else{
                this.parserautodetect = 'Processing';
                this.manuallanguage = '';
               }

               }

               if(this.detailArray[0].parseStatus == 'doc_08'){
                this.parserstatus = 'Success';
              }
              else if((this.detailArray[0].parseStatus == 'doc_07')
              || (this.detailArray[0].parseStatus == 'doc_15')){
                if(this.detailArray[0].running_module_code == 'm_13'){
                  this.parserstatus = 'Failure4';
                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Extraction Failed';
                    this.manuallanguage = '';
                   }
                }
                else{

                  this.notExtractedStatus = this.detailArray[0].parserapirequest;
                  if(this.notExtractedStatus == '1'){
                    this.parserstatus = 'Extracting'
                  }
                  else{
                    this.parserstatus = 'Not Extracted';
                  }

                }

              }
              else if((this.detailArray[0].parseStatus == 'doc_00') ||
              (this.detailArray[0].parseStatus == 'doc_03') ||
              (this.detailArray[0].parseStatus == 'doc_04') ||
              (this.detailArray[0].parseStatus == 'doc_18') ||
              (this.detailArray[0].parseStatus == 'doc_24') ||
              (this.detailArray[0].parseStatus == 'doc_25') ||
              (this.detailArray[0].parseStatus == 'doc_27')){
                this.parserstatus = 'Failure5'
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
                  || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
                  || (this.fileExtensionview.toLowerCase() == '.odt')
                 ){
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = '';
                  this.manuallanguage = 'Extraction Failed';
                 }

                 }
              }
              else if(this.detailArray[0].parseStatus == 'doc_23'){
               this.parserstatus = 'Failure6'
              }
              else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
               this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == ''){

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'In queue'
                }
                else{
                  this.parserstatus = 'Not Extracted';
                }

              }
           }
           else{
            this.parserstatus = '';
            this.parserautodetect = '';
            this.manuallanguage = '';
           }
           if(this.docRename){
            this.sendrenamedname(this.docRename);
           }
           else{
            this.sendrenamedname(this.originalname);

           }
           let index = sharedArray.findIndex((e)=>{
             return e.userEmailID === this.createdBy;
           });
           if(index>-1){
            this.sendRightsInfo(true)
           }

           else{
            this.sendRightsInfo(false)
           }
           this.sharedDocumentsData.push(response.data);
           localStorage.setItem('allSharedData',JSON.stringify(this.sharedDocumentsData));
           if(this.CabinetUrl){
              if((valuetarget.docOwnerEmailId != this.createdBy ) && (valuetarget.docShareStatus != 'Shared')){
                this.url = '';
              }
              else{
                if(!valuetarget.readableReason){
                  if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                 }
                 else{
                  this.url = '';
                 }
              }
           }
           else if(this.searchurl){
            if((valuetarget.docOwnerEmailId != this.createdBy ) && (valuetarget.docShareStatus != 'Shared')){
              this.url = '';
            }
            else{
              if(!valuetarget.readableReason){
                 if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               else{
                this.url = '';
               }
            }
         }
           else{
            if(!valuetarget.readableReason){
               if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             else{
              this.url = '';
             }
           }


           this.currentdocdisplayname = this.detailArray[0].docOwnerDisplayName;

           this.notesview = this.detailArray[0].notes;
           this.previousOwnernameview = this.detailArray[0].previousOwnerDisplayName;

           if((this.statusdoc == 'Trash') || (this.statusdoc == 'Delete') || (this.statusdoc == 'DELETE') || (this.statusdoc == 'TRASH')){
               this.statusvalue = this.statusdoc;
           }
           else{
            this.statusvalue = '';
           }

           this.detailArray= this.datePipe.transformView(this.detailArray);

           this.fileCreatedDate = this.detailArray[0].fileCreatedDate;
           this.modifieddatetime = this.detailArray[0].fileModifiedDate;
           this.uploadedby = this.detailArray[0].uploadedByDisplayName;
           this.uploadeddatetime = this.detailArray[0].uploadedDateTime;
           this.externalAssociations = this.detailArray[0].externalSol != ''? this.detailArray[0].externalSol: '';
           this.verifieddatetime = this.detailArray[0].verifiedDateTime;


           if(this.formate == 'Document' || this.formate == 'DOCUMENT'){
             this.documentformate = this.formate;
           }
           else if(this.formate == 'Pdf' || this.formate == 'PDF'){
            this.documentformate = this.formate;
           }
           else{
            this.documentformate = 'Image';
           }

           if(this.documentformate == 'Image'){
            this.dimension = (this.detailArray[0].width) + '' + ' (w) x' + this.detailArray[0].height + ' (h)';

             if(this.detailArray[0].width > this.detailArray[0].height){
                this.lanscapevalue = true;
             }
             else{
              this.lanscapevalue = false;
             }
           }
           else{
            this.dimension = '';
           }
           this.cd.detectChanges();
        }
      })
      }
      else{
        this.originalname = '';
      this.size = '';
      this.formate = '';
      this.pages = '';
      this.docType = '';
      this.docOwnerName = '';
      this.fileCreatedDate = '';
      this.modifieddatetime = '';
      this.uploadedby = '';
      this.uploadeddatetime = '';
      this.externalAssociations = '';
      this.verifieddatetime = '';
      this.url = '';
      this.documentformate = '';
      this.dimension = '';
      /**
       For dPages count sections
       */
      this.pagecount = '';
      this.currentdocdisplayname = '';
      this.fileExtensionview = '';
      this.pageConversion = '';
      this.notesview = '';
      this.previousOwnernameview = '';
      this.docRename = '';
      this.detailArray = [];
      this.showblankimage = 'No Details';
      this.sendimagename(this.showblankimage);

      }
    }
    else{
        this.lastindexval = this.arrayId[this.arrayId?.length - 1];

        if(valuetarget){
          this.detailval = valuetarget;
          this.createdBy = localStorage.getItem('localloginId');
          let detail = {
            "wsoid": valuetarget.wsoid || parseInt(localStorage.getItem('localwsId')),
            "docId": valuetarget.docId,
            "CreatedBy": this.createdBy,
            "guid" : this.getguid,
            "DocGUID" : valuetarget.docGuid
        }

      this.parserautodetect = '';
      this.language = '';
      this.manuallanguage = '';
      this.mergelanguage = '';
      this.lanscapevalue = false;
        this.detailsactivity.getdetailsdata(detail).subscribe((response: any)=> {

          if (response.isSuccess) {
            this.arrayId.push(valuetarget.docId);
             this.detailArray = response.data.documentDetailsList;
             if(this.detailArray?.length > 0){
              this.detailArrayVar = false;
            }else{
              this.detailArrayVar = true;
            }
             let sharedArray = response.data.documentShareUserList
             let getstdata = window.location.toString();
             this.docRename = this.detailArray[0].originalFileName
             let getarrdata = getstdata?.split('/');
             this.iseditableDocument = this.detailArray[0].iseditable;
             this.verifierDisplayName = this.detailArray[0].verifiedByDisplayName
             this.verifierEmail = this.detailArray[0].verifiedByEmailId
             /*
             Hide Original Name if it is equal to modified name in my and ws queau
             */
             if(getarrdata[5] == 'my-queue'){
               this.originalname = '';
               this.docRename = this.detailArray[0].originalFileName;

             }else{
               this.originalname = this.detailArray[0].originalFileName;
               this.docRename = this.detailArray[0].docRename;

             }

             this.fileExtensionview = this.detailArray[0].fileExtension.toLowerCase();

                /*
                *parser language
          */
           if(this.parserflag == 'True'){

            this.language = this.detailArray[0].language;
            if(this.language != 'Autodetect'){
                /*
                    *In the edittable document format always show language detected
                    *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
                    *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
               */
              if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
               || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
               || (this.fileExtensionview.toLowerCase() == '.odt')
              ){
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                 this.manuallanguage = '';
                }
                else{
                  this.parserautodetect = 'Processing'
                  this.manuallanguage = '';
                }

              }
             else{
               this.manuallanguage = this.detailArray[0].language;
               this.parserautodetect = '';
             }

            }
            else{
              /*
                 *parser status related changes
             */
                 if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = 'Processing';
                  this.manuallanguage = '';
                 }
              }



              if(this.detailArray[0].parseStatus == 'doc_08'){
                this.parserstatus = 'Success';
              }
              else if((this.detailArray[0].parseStatus == 'doc_07')
              || (this.detailArray[0].parseStatus == 'doc_15')){
                if(this.detailArray[0].running_module_code == 'm_13'){
                  this.parserstatus = 'Failure7'
                  if(this.detailArray[0].parserlanguage){
                    let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                    if(getparserlanguage[0]){
                      this.mergelanguage = getparserlanguage[0];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                    }
                    if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                      this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                    }

                    this.parserautodetect = this.mergelanguage
                    this.manuallanguage = '';
                   }
                   else{
                    this.parserautodetect = 'Extraction Failed';
                    this.manuallanguage = '';
                   }
                }
                else{

                  this.notExtractedStatus = this.detailArray[0].parserapirequest;
                  if(this.notExtractedStatus == '1'){
                    this.parserstatus = 'Extracting'
                  }
                  else{
                    this.parserstatus = 'Not Extracted';
                  }

                }
              }
              else if((this.detailArray[0].parseStatus == 'doc_00') ||
              (this.detailArray[0].parseStatus == 'doc_03') ||
              (this.detailArray[0].parseStatus == 'doc_04') ||
              (this.detailArray[0].parseStatus == 'doc_18') ||
              (this.detailArray[0].parseStatus == 'doc_24') ||
              (this.detailArray[0].parseStatus == 'doc_25') ||
              (this.detailArray[0].parseStatus == 'doc_27')){
                this.parserstatus = 'Failure88'
                if(this.detailArray[0].parserlanguage){
                  let getparserlanguage = this.detailArray[0].parserlanguage?.split(',');
                  if(getparserlanguage[0]){
                    this.mergelanguage = getparserlanguage[0];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];

                  }
                  if(getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]){
                    this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];

                  }

                  this.parserautodetect = this.mergelanguage
                  this.manuallanguage = '';
                 }
                 else{
                  if((this.fileExtensionview.toLowerCase() == '.txt') || (this.fileExtensionview.toLowerCase() == '.doc')
                  || (this.fileExtensionview.toLowerCase() == '.docx') || (this.fileExtensionview.toLowerCase() == '.rtf')
                  || (this.fileExtensionview.toLowerCase() == '.odt')
                 ){
                  this.parserautodetect = 'Extraction Failed';
                  this.manuallanguage = '';
                 }
                 else{
                  this.parserautodetect = '';
                  this.manuallanguage = 'Extraction Failed';
                 }

                 }
              }
              else if(this.detailArray[0].parseStatus == 'doc_23'){
               this.parserstatus = 'Failure9'
              }
              else if(this.detailArray[0].parseStatus == 'Partially Parsed'){
               this.parserstatus = 'Extracting'
              }
              else if(this.detailArray[0].parseStatus == ''){

                this.notExtractedStatus = this.detailArray[0].parserapirequest;
                if(this.notExtractedStatus == '1'){
                  this.parserstatus = 'In queue'
                }
                else{
                  this.parserstatus = 'Not Extracted';
                }

              }
          }
            else{
            this.parserstatus = '';
            this.parserautodetect = '';
            this.manuallanguage = '';
            }


             if(this.docRename){
              this.sendrenamedname( this.docRename);
             }
             else{
              this.sendrenamedname( this.originalname);
             }
             let index = sharedArray.findIndex((e)=>{
               return e.userEmailID === this.createdBy;
             });
             if(index>-1){
              this.sendRightsInfo(true)
             }

             else{
              this.sendRightsInfo(false)
             }
             this.sharedDocumentsData.push(response.data);
             localStorage.setItem('allSharedData',JSON.stringify(this.sharedDocumentsData));
             if(this.CabinetUrl){
              if((valuetarget.docOwnerEmailId != this.createdBy ) && (valuetarget.docShareStatus != 'Shared')){
                this.url = '';
              }
              else{
                if(!valuetarget.readableReason){
                   if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
                 }
                 else{
                  this.url = '';
                 }
              }
           }
           else if(this.searchurl){
            if((valuetarget.docOwnerEmailId != this.createdBy ) && (valuetarget.docShareStatus != 'Shared')){
              this.url = '';
            }
            else{
              if(!valuetarget.readableReason){
                 if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
               }
               else{
                this.url = '';
               }
            }
         }
           else{
            if(!valuetarget.readableReason){
               if(valuetarget.formatExtension){
                    this.extensionValue = valuetarget.formatExtension.toLowerCase();
                  }
                  else if(valuetarget.format){
                    this.extensionValue = valuetarget.format.toLowerCase();
                  }
                  this.pdfFileConversion(this.detailArray[0].thumbnailPath?.split('\\')[3],this.extensionValue);
             }
             else{
              this.url = '';
             }
           }

             this.currentdocdisplayname = this.detailArray[0].docOwnerDisplayName;

             this.notesview = this.detailArray[0].notes;
             this.previousOwnernameview = this.detailArray[0].previousOwnerDisplayName;

             if((this.statusdoc == 'Trash') || (this.statusdoc == 'Delete') || (this.statusdoc == 'DELETE') || (this.statusdoc == 'TRASH')){
                 this.statusvalue = this.statusdoc;
             }
             else{
              this.statusvalue = '';
             }

             this.detailArray= this.datePipe.transformView(this.detailArray);

             this.fileCreatedDate = this.detailArray[0].fileCreatedDate;
             this.modifieddatetime = this.detailArray[0].fileModifiedDate;
             this.uploadedby = this.detailArray[0].uploadedByDisplayName;
             this.uploadeddatetime = this.detailArray[0].uploadedDateTime;
             this.externalAssociations = this.detailArray[0].externalSol != ''? this.detailArray[0].externalSol: '';
             this.verifieddatetime = this.detailArray[0].verifiedDateTime;


             if(this.formate == 'Document' || this.formate == 'DOCUMENT'){
               this.documentformate = this.formate;
             }
             else if(this.formate == 'Pdf' || this.formate == 'PDF'){
              this.documentformate = this.formate;
             }
             else{
              this.documentformate = 'Image';
             }

             if(this.documentformate == 'Image'){
              this.dimension = (this.detailArray[0].width) + '' + ' (w) x' + this.detailArray[0].height + ' (h)';

               if(this.detailArray[0].width > this.detailArray[0].height){
                this.lanscapevalue = true;
                }
                else{
                  this.lanscapevalue = false;
                }
             }
             else{
              this.dimension = '';
             }
             this.cd.detectChanges();
          }
        })
        }
        else{
          this.originalname = '';
        this.size = '';
        this.pageConversion = '';
        this.formate = '';
        this.pages = '';
        this.docType = '';
        this.docOwnerName = '';
        this.fileCreatedDate = '';
        this.modifieddatetime = '';
        this.uploadedby = '';
        this.uploadeddatetime = '';
        this.externalAssociations = '';
        this.verifieddatetime = '';
        this.url = '';
        this.documentformate = '';
        this.dimension = '';
        this.currentdocdisplayname = '';
        this.fileExtensionview = '';
        this.notesview = '';
        this.previousOwnernameview = '';
        this.docRename = '';
        this.detailArray = [];
        this.showblankimage = 'No Details';
        this.sendimagename(this.showblankimage);

        }

    }
  }
  /**
   * method to prevent multiple modal opening while click on share access edit button
   */
  isModalOperationInProgress=false;
disableModal=()=>{
  this.isModalOperationInProgress=true;
  setTimeout(() => {
    this.isModalOperationInProgress=false;
  }, 500);
}

  onlytodayyesterday = [];
  getActivitydataparser(valueactivity){
    this.parseract = valueactivity;
    if(!this.arrayId){
     if(valueactivity){
       this.createdBy = localStorage.getItem('localloginId');
       let act = {
         "wsoid": valueactivity.wsoid || parseInt(localStorage.getItem('localwsId')),
         "docId": valueactivity.docId,
         "CreatedBy": this.createdBy,
         "guid" : this.getguid,
         "DocGUID" : valueactivity.docGuid
       }
        this.getactdata = [];
        this.uniquedata =[];
        this.arrydata = [];
        this.datechange = '';
       this.detailsactivity.getActivitydata(act).subscribe((response: any)=> {
         if (response.isSuccess) {
           this.arrayId.push(valueactivity.docId);
           this.getactdata = [];
           this.uniquedata =[];
           this.arrydata = [];
           this.datechange = '';




           this.getactdata = (response.data);



           /*
           *datetime modulate
           */

         for(let i=0;i<=this.getactdata?.length-1;i++){

             let removetime = moment(this.getactdata[i].logDateTime).format(this.timeFormat);
             if(removetime == "Invalid date"){
              let timeonly = this.getactdata[i].logDateTime?.split(" ")
              this.getactdata[i].logDateTime = timeonly[2] ? timeonly[1] + ' ' + timeonly[2] : timeonly[1];;
            }
            else{
              this.getactdata[i].logDateTime = removetime;
            }

         }




           this.definedata = this.getactdata.map((item,index)=>{
             let findpreviousdateval = this.uniquedata.findIndex((item2=>item2.currentactivityDate === item.activityDate))
             this.arrydata = [];
             if(findpreviousdateval === -1){

                let actvalarray = {
                 docId: 64,
                 logDateTime: item.logDateTime,
                 logMessage: item.logMessage,
                }
                this.arrydata.push(actvalarray);

                let actval = {
                 activityDate: item.activityDate ,
                 currentactivityDate : item.activityDate,
                 InnerList :  this.arrydata,
                 createdDtm : item.createdDtm
                }
                this.uniquedata.push(actval);
             }
             else{

               let actvalarray = {
                 docId: 64,
                 logDateTime: item.logDateTime,
                 logMessage: item.logMessage,
                }

                this.uniquedata[findpreviousdateval]['InnerList'].push(actvalarray);
             }
           })

            const makeUniqueArray=[]
            this.onlytodayyesterday = [];
            for(let i=0;i<=this.uniquedata?.length-1;i++){
              if(this.uniquedata[i]['activityDate']==="Yesterday" || this.uniquedata[i]['activityDate']==="Today"){
                this.onlytodayyesterday.push(this.uniquedata[i]);
              }

                if(this.uniquedata[i]['activityDate']!=="Yesterday" && this.uniquedata[i]['activityDate']!=="Today"){
                const parseDate=this.uniquedata[i].createdDtm.toString()?.split("T")[0]
                const checkUniqueArray=makeUniqueArray.findIndex(al=>al['samedate']===parseDate)

                if(checkUniqueArray===-1){
                  const ary=[]
                  ary.push(this.uniquedata[i]['InnerList'][0])
                  const obj={samedate:parseDate,activityDate:this.uniquedata[i]['activityDate'],currentactivityDate:this.uniquedata[i]['currentactivityDate'],InnerList:ary}
                  makeUniqueArray.push(obj)
                }else{
                  const copyInnerList=[...this.uniquedata[checkUniqueArray]['InnerList']]
                  copyInnerList.push(this.uniquedata[i]['InnerList'][0])
                  makeUniqueArray[checkUniqueArray]['InnerList']=copyInnerList
                }
              }
            }

            this.activitydata=[...this.onlytodayyesterday,...makeUniqueArray]
            this.uniquedata = this.activitydata;

            this.cd.detectChanges();

         }

       });

     }

    }
    else{
     this.lastindexval = this.arrayId[this.arrayId?.length - 1];

       if(valueactivity){
        this.parseract = valueactivity;
         this.createdBy = localStorage.getItem('localloginId');
         let act = {
           "wsoid": valueactivity.wsoid || parseInt(localStorage.getItem('localwsId')),
           "docId": valueactivity.docId,
           "CreatedBy": this.createdBy,
           "guid" : this.getguid,
           "DocGUID" : valueactivity.docGuid
         }
          this.getactdata = [];
          this.uniquedata =[];
          this.arrydata = [];
          this.datechange = '';
         this.detailsactivity.getActivitydata(act).subscribe((response: any)=> {
           if (response.isSuccess) {
             this.arrayId.push(valueactivity.docId);
             this.getactdata = [];
             this.uniquedata =[];
             this.arrydata = [];
             this.datechange = '';

             this.getactdata = (response.data);

             /*
             * date time mainpulation
             */
             for(let i=0;i<=this.getactdata?.length-1;i++){
               let removetime = moment(this.getactdata[i].logDateTime).format(this.timeFormat);
               if(removetime == "Invalid date"){
                let timeonly = this.getactdata[i].logDateTime?.split(" ")
                this.getactdata[i].logDateTime = timeonly[2] ? timeonly[1] + ' ' + timeonly[2] : timeonly[1];;
              }
              else{
                this.getactdata[i].logDateTime = removetime;
              }
           }


             this.definedata = this.getactdata.map((item,index)=>{
               let findpreviousdateval = this.uniquedata.findIndex((item2=>item2.currentactivityDate === item.activityDate))
               this.arrydata = [];
               if(findpreviousdateval === -1){

                  let actvalarray = {
                   docId: 64,
                   logDateTime: item.logDateTime,
                   logMessage: item.logMessage,
                  }
                  this.arrydata.push(actvalarray);

                  let actval = {
                   activityDate: item.activityDate ,
                   currentactivityDate : item.activityDate,
                   InnerList :  this.arrydata,
                   createdDtm : item.createdDtm
                  }
                  this.uniquedata.push(actval);
               }
               else{

                 let actvalarray = {
                   docId: 64,
                   logDateTime: item.logDateTime,

                   logMessage: item.logMessage,
                  }
                  this.uniquedata[findpreviousdateval]['InnerList'].push(actvalarray);
               }
             })


          const makeUniqueArray=[]
          this.onlytodayyesterday = [];
          for(let i=0;i<=this.uniquedata?.length-1;i++){
            if(this.uniquedata[i]['activityDate']==="Yesterday" || this.uniquedata[i]['activityDate']==="Today"){
              this.onlytodayyesterday.push(this.uniquedata[i]);
            }

              if(this.uniquedata[i]['activityDate']!=="Yesterday" && this.uniquedata[i]['activityDate']!=="Today"){
              const parseDate=this.uniquedata[i].createdDtm.toString()?.split("T")[0]
              const checkUniqueArray=makeUniqueArray.findIndex(al=>al['samedate']===parseDate)

              if(checkUniqueArray===-1){
                const ary=[]
                ary.push(this.uniquedata[i]['InnerList'][0])
                const obj={samedate:parseDate,activityDate:this.uniquedata[i]['activityDate'],currentactivityDate:this.uniquedata[i]['currentactivityDate'],InnerList:ary}
                makeUniqueArray.push(obj)
              }else{
                const copyInnerList=[...this.uniquedata[checkUniqueArray]['InnerList']]
                copyInnerList.push(this.uniquedata[i]['InnerList'][0])
                makeUniqueArray[checkUniqueArray]['InnerList']=copyInnerList
              }
            }
          }

          this.activitydata=[...this.onlytodayyesterday,...makeUniqueArray]
          this.uniquedata = this.activitydata;

          this.cd.detectChanges();


           }


         });

       }

    }



     }


}
