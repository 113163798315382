import { Observable } from 'rxjs';
/**
 *  TrafficBar
 */
export interface TrafficBar {
    /**
      *  variable for data
   */
  data: number[];
    /**
      *  variable for   labels
   */
  labels: string[];
    /**
      *  variable for formatter
   */
  formatter: string;
}
/**
 *  TrafficBar
 */
export abstract class TrafficBarData {
  /**
 *  abstract method for getTrafficBarData
 */
  abstract getTrafficBarData(period: string): Observable<TrafficBar>;
}
