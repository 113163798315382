/*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : GuestUserModalComponent
///  Description: Guest Share for the project
///  Owner      : Sumeet Goel
///  Author     : Sushma Shukla
///  Created On : 17-05-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///  Aryan Sharma        15 Jan 2024      New Guest Share Features Development
///  Prabhat Ranjan      15 Jan 2024      New Guest Share Features Development
 
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, Output, TemplateRef, ViewChild, EventEmitter, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogService, NbTagComponent, NbToastrService,NbCalendarRange, NbDateService } from '@nebular/theme';
import { InviteUsersService } from '../../@core/utils/invite-users.service';

import { SharedService } from '../../@core/utils/shared.service';
import { HttpClient } from '@angular/common/http';
import { SignInService } from '../../@core/sign-in.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { SharedServiceService } from '../../shared-service.service';
import { DetailAndActivityComponent } from '../../shared/detail-and-activity/detail-and-activity.component';
import { Router } from '@angular/router';
import { PhoneNumberUtil } from "google-libphonenumber";


 
/**
 * @ignore
 */
@Component({
  selector: 'ngx-guest-user-modal',
  templateUrl: './guest-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./guest-user-modal.component.scss']
})
export class GuestUserModalComponent implements OnInit, AfterViewInit {
  existingMail = []
  validemail: any[] = [];
  wrongemail: boolean = false
  wrongemailonOutsideClick = false;
  confrmdissabledbtn = true
  textareavalue: [] = [];
  workSpaceId: any
  showSpinner = false;
  wsName: any;
  createdBy: string;
  options: string[];
  defaultselected: any;
  alreadyexistanceId = false;
  alreadyexistanceIdConf = false;
  doxEvent: any;
  ipAddress: any; 
  selectedCountry: any;

  daysList = [ '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39 ', '40', '41', '42' , '43', '44', '45', '46', '47', '48', '49',
  '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72' , '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'];
  hoursList = [ '00', '01', '02', '03 ', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

  selectedDays = '01';
  selectedHours = '00';
  guestAccess: any;
  userMail: any;
  validEmailDuration = [];
  deletedExistingEmails = [];
  wsOwnerName: string;
  checkdata: string
  durationError = false;
  disableAdd = true;
  checkdataShared: string;
  checkdataDelete: string;
  subscriptionmini: any;
  dataguid: any;
  href: any;
  noteuserfullname: string;
  notShared: boolean=true;
  /**
   * Long TimeZone used as a key to be sent to API
   */
  timeZone: string;
  /**
   * For checking if whatsqpp feature is enabled in the plan or not
   */
  whatsappInPlan: boolean = false;
  /**
   * For checking if Guest Share feature is enabled in the plan or not
   */
  guestInPlan: boolean = false;
  /**
   * For checking if whatsapp feature is enabled in the role or not
   */
  isWhatsappAccessInRole: boolean = false;
  /**
   * For checking if Guest Share feature is enabled in the role or not
   */
  isGuestAccessInRole: boolean = false;
  /**
   * For checking if OTP feature is enabled in the general Settings Globally or not
   */
  generalOtp: boolean = false;
  /**
   * For checking if Download & Print feature is enabled in the general Settings Globally or not
   */
  generalDownloadPrint: boolean = false;
  /**
   * For checking if Dcoins feature is enabled in the Plan or not
   */
  isDcoinEnableInPlan: boolean = false;
  /**
   * For checking if How many Dcoins are left in our plan & workspace.
   */
  dcoinsAvailable: number = 0;
  /**
   * For checking How many Dcoins are used per whatsapp message
   */
  whatsappMessages: number= 0;
    /**
   * For checking How many Dcoins are used per whatsapp message
   */
  dCoins: number= 0;
      /**
   * For checking How many Dcoins are required for sending current listed numbers
   */
  dcoinsRequired: number= 0;
        /**
   * For checking How many whatsapp messages can be sent;
   */
  blueChips: number= 0;
  /**
   * For Differentiating states of advanced otp settings
   */
  tempadvancedSettingsOtpMandatoryNew: boolean= false;
    /**
   * For Differentiating states of advanced Download and print settings
   */
  tempadvancedSettingsDownloadMandatoryNew: boolean= false;
    /**
   * For Differentiating modal opening advanced settings
   */
  threeDotTrigger: boolean = false;
    /**
   * For Differentiating modal opening expiration Date
   */
  threeDotTriggerExp: boolean = false;
      /**
   * For Differentiating states of Calender Date
   */
  tempexpirationModalCalendardateNew: Date = new Date(new Date().getTime()+24*60*60*1000);
        /**
   * For Differentiating states of Calender infinite duration
   */
  tempexpirationDateInfiniteDurationNew: boolean = false;
         /**
   * For checking if the purchase dcoin modal is open or not
   */
  dCoinsInvalid: boolean = false;
           /**
   * Expiry Date Text 
   */
  expirationDate: any = 'Expiration Date';
  /**
   * for setting flag of is expiry on new share or previously shared document
   */
  isExpiryEdit: boolean = false;
    /**
   * for checking if close button is clicked or not
   */
  closeBtnClick: boolean = false;
  constructor(public router :  Router,public sharedservice : SharedServiceService, public cookieService: CookieService, public signInService: SignInService, public http: HttpClient, public dialogService: NbDialogService, public cd: ChangeDetectorRef,
     public data: InviteUsersService, public toastrService: NbToastrService, public sharedService: SharedService, public el: ElementRef) {
    
    this.selectedDays = '01';
    this.selectedHours = '00';
  }

  /**
   * Event Related Variables 
   */
  singleSkeleton=false;
  docGuidArr=[]
  docClickedModifiedNames=[]
  docsSelectedData=[]
  isSingleSharing=false
  shareBtnValid=false
  guestShareDirty=false
  /**
   * Email Input Section Related Variables
   */
  showEmailSection=true;
  emailInputArr=[]
  emailInputString=''
  emailChipsAllValid=true;
  emailBtnSpinnner=false;
  emailErrorMsg=''

  /**
   * Modification Related Variables
   */
  modifyType=''
  emailInputArrModifyList=''
  DocGuidEmailWiseModifyList=[]
  DocGuidPhoneWiseModifyList=[]

  /**
   *Resend Document Related Variables
   */
  resendType='';
  emailInputResendList=''
  DocGuidEmailWiseResendList=[]
  DocGuidPhoneWiseResendList=[]
  /**
   *Remove Guest Access Related Variables
   */
  removeGuestModalText=''
  removeType='';
  emailInputRemoveList='';
  DocGuidRemoveList='';
  


  /**
   * Whatsapp Number Variables
   */
  whatsappTooltipMsg=""
  toggleOptionRadioEmailWhatsapp=''
  showWhatsappSection=false;
  whatsappNumberInputString=''
  whatsappNumberInputArr=[]
  whatsappNumberExistingList=[]
  whatsappChipsAllValid=true;
  whatsappBtnSpinnner=false;
  whatsappErrorMsg='';
  countryDialCode='+91';
  countryNameInitial='in';
  countryFlagClass='iti__flag iti__in';
  showCountryDropdown=false;
  countrySearchString='';

  countryDataArray=[
    {
      class:'iti__flag iti__in',
      name:'India',
      countryCode:'in',
      dialCode:'+91'
    },
    {
      class:'iti__flag iti__us',
      name:'United States',
      countryCode:'us',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__gb',
      name:'United Kingdom',
      countryCode:'gb',
      dialCode:'+44'
    },
    {
      class:'iti__flag iti__af',
      name:'Afghanistan',
      countryCode:'af',
      dialCode:'+93'
    },
    {
      class:'iti__flag iti__al',
      name:'Albania',
      countryCode:'al',
      dialCode:'+355'
    },
    {
      class:'iti__flag iti__dz',
      name:'Algeria',
      countryCode:'dz',
      dialCode:'+213'
    },
    {
      class:'iti__flag iti__as',
      name:'American Samoa',
      countryCode:'as',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ad',
      countryCode:'as',
      name:'Andorra',
      dialCode:'+376'
    },
    {
      class:'iti__flag iti__ao',
      countryCode:'ao',
      name:'Angola',
      dialCode:'+244'
    },
    {
      class:'iti__flag iti__ai',
      countryCode:'ai',
      name:'Anguilla',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ag',
      countryCode:'ag',
      name:'Antigua and Barbuda',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ar',
      countryCode:'ar',
      name:'Argentina',
      dialCode:'+54'
    },
    {
      class:'iti__flag iti__am',
      countryCode:'am',
      name:'Armenia',
      dialCode:'+374'
    },
    {
      class:'iti__flag iti__aw',
      countryCode:'aw',
      name:'Aruba',
      dialCode:'+297'
    },
    {
      class:'iti__flag iti__ac',
      countryCode:'ac',
      name:'Ascension Island',
      dialCode:'+247'
    },
    {
      class:'iti__flag iti__au',
      countryCode:'au',
      name:'Australia',
      dialCode:'+61'
    },
    {
      class:'iti__flag iti__at',
      countryCode:'at',
      name:'Austria',
      dialCode:'+43'
    },
    {
      class:'iti__flag iti__az',
      countryCode:'az',
      name:'Azerbaijan',
      dialCode:'+994'
    },
    {
      class:'iti__flag iti__bs',
      countryCode:'bs',
      name:'Bahamas',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__bh',
      countryCode:'bh',
      name:'Bahrain',
      dialCode:'+973'
    },
    {
      class:'iti__flag iti__bd',
      countryCode:'bd',
      name:'Bangladesh',
      dialCode:'+880'
    },
    {
      class:'iti__flag iti__bb',
      countryCode:'bb',
      name:'Barbados',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__by',
      countryCode:'by',
      name:'Belarus',
      dialCode:'+375'
    },
    {
      class:'iti__flag iti__be',
      countryCode:'be',
      name:'Belgium',
      dialCode:'+32'
    },
    {
      class:'iti__flag iti__bz',
      countryCode:'bz',
      name:'Belize',
      dialCode:'+501'
    },
    {
      class:'iti__flag iti__bj',
      countryCode:'bj',
      name:'Benin',
      dialCode:'+229'
    },
    {
      class:'iti__flag iti__bm',
      countryCode:'bm',
      name:'Bermuda',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__bt',
      countryCode:'bt',
      name:'Bhutan',
      dialCode:'+975'
    },
    {
      class:'iti__flag iti__bo',
      countryCode:'bo',
      name:'Bolivia',
      dialCode:'+591'
    },
    {
      class:'iti__flag iti__ba',
      countryCode:'ba',
      name:'Bosnia and Herzegovina',
      dialCode:'+387'
    },
    {
      class:'iti__flag iti__bw',
      countryCode:'bw',
      name:'Botswana',
      dialCode:'+267'
    },
    {
      class:'iti__flag iti__br',
      countryCode:'br',
      name:'Brazil',
      dialCode:'+55'
    },
    {
      class:'iti__flag iti__io',
      countryCode:'io',
      name:'British Indian Ocean Territory',
      dialCode:'+246'
    },
    {
      class:'iti__flag iti__vg',
      countryCode:'vg',
      name:'British Virgin Islands',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__bn',
      countryCode:'bn',
      name:'Brunei',
      dialCode:'+673'
    },
    {
      class:'iti__flag iti__bg',
      countryCode:'bg',
      name:'Bulgaria',
      dialCode:'+359'
    },
    {
      class:'iti__flag iti__bf',
      countryCode:'bf',
      name:'Burkina Faso',
      dialCode:'+226'
    },
    {
      class:'iti__flag iti__bi',
      countryCode:'bi',
      name:'Burundi (Uburundi)',
      dialCode:'+257'
    },
    {
      class:'iti__flag iti__kh',
      countryCode:'kh',
      name:'Cambodia',
      dialCode:'+855'
    },
    {
      class:'iti__flag iti__cm',
      countryCode:'cm',
      name:'Cameroon (Cameroun)',
      dialCode:'+237'
    },
    {
      class:'iti__flag iti__ca',
      countryCode:'ca',
      name:'Canada',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__cv',
      countryCode:'cv',
      name:'Cape Verde',
      dialCode:'+238'
    },
    {
      class:'iti__flag iti__bq',
      countryCode:'bq',
      name:'Caribbean Netherlands',
      dialCode:'+599'
    },
    {
      class:'iti__flag iti__ky',
      countryCode:'ky',
      name:'Cayman Islands',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__cf',
      countryCode:'cf',
      name:'Central African Republic',
      dialCode:'+236'
    },
    {
      class:'iti__flag iti__td',
      countryCode:'td',
      name:'Chad',
      dialCode:'+235'
    },
    {
      class:'iti__flag iti__cl',
      countryCode:'cl',
      name:'Chile',
      dialCode:'+56'
    },
    {
      class:'iti__flag iti__cn',
      countryCode:'cn',
      name:'China',
      dialCode:'+86'
    },
    {
      class:'iti__flag iti__cx',
      countryCode:'cx',
      name:'Christmas Island',
      dialCode:'+61'
    },
    {
      class:'iti__flag iti__cc',
      countryCode:'cc',
      name:'Cocos (Keeling) Islands',
      dialCode:'+61'
    },
    {
      class:'iti__flag iti__co',
      countryCode:'co',
      name:'Colombia',
      dialCode:'+57'
    },
    {
      class:'iti__flag iti__km',
      countryCode:'km',
      name:'Comoros',
      dialCode:'+269'
    },
    {
      class:'iti__flag iti__cd',
      countryCode:'cd',
      name:'Congo (DRC)',
      dialCode:'+243'
    },
    {
      class:'iti__flag iti__cg',
      countryCode:'cg',
      name:'Congo (Republic)',
      dialCode:'+242'
    },
    {
      class:'iti__flag iti__ck',
      countryCode:'ck',
      name:'Cook Islands',
      dialCode:'+682'
    },
    {
      class:'iti__flag iti__cr',
      countryCode:'cr',
      name:'Costa Rica',
      dialCode:'+506'
    },
    {
      class:'iti__flag iti__ci',
      countryCode:'ci',
      name:'Côte d’Ivoire',
      dialCode:'+225'
    },
    {
      class:'iti__flag iti__hr',
      countryCode:'hr',
      name:'Croatia (Hrvatska)',
      dialCode:'+385'
    },
    {
      class:'iti__flag iti__cu',
      countryCode:'cu',
      name:'Cuba',
      dialCode:'+53'
    },
    {
      class:'iti__flag iti__cw',
      countryCode:'cw',
      name:'Curaçao',
      dialCode:'+599'
    },
    {
      class:'iti__flag iti__cy',
      countryCode:'cy',
      name:'Cyprus',
      dialCode:'+357'
    },
    {
      class:'iti__flag iti__cz',
      countryCode:'cz',
      name:'Czech Republic',
      dialCode:'+420'
    },
    {
      class:'iti__flag iti__dk',
      countryCode:'dk',
      name:'Denmark (Danmark)',
      dialCode:'+45'
    },
    {
      class:'iti__flag iti__dj',
      countryCode:'dj',
      name:'Djibouti',
      dialCode:'+253'
    },
    {
      class:'iti__flag iti__dm',
      countryCode:'dm',
      name:'Dominica',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__do',
      countryCode:'do',
      name:'Dominican Republic',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ec',
      countryCode:'ec',
      name:'Ecuador',
      dialCode:'+593'
    },
    {
      class:'iti__flag iti__eg',
      countryCode:'eg',
      name:'Egypt',
      dialCode:'+20'
    },
    {
      class:'iti__flag iti__sv',
      countryCode:'sv',
      name:'El Salvador',
      dialCode:'+503'
    },
    {
      class:'iti__flag iti__gq',
      countryCode:'gq',
      name:'Equatorial Guinea',
      dialCode:'+240'
    },
    {
      class:'iti__flag iti__er',
      countryCode:'er',
      name:'Eritrea',
      dialCode:'+291'
    },
    {
      class:'iti__flag iti__ee',
      countryCode:'ee',
      name:'Estonia',
      dialCode:'+372'
    },
    {
      class:'iti__flag iti__sz',
      countryCode:'sz',
      name:'Eswatini',
      dialCode:'+268'
    },
    {
      class:'iti__flag iti__et',
      countryCode:'et',
      name:'Ethiopia',
      dialCode:'+251'
    },
    {
      class:'iti__flag iti__fk',
      countryCode:'fk',
      name:'Falkland Islands',
      dialCode:'+500'
    },
    {
      class:'iti__flag iti__fo',
      countryCode:'fo',
      name:'Faroe Islands',
      dialCode:'+298'
    },
    {
      class:'iti__flag iti__fj',
      countryCode:'fj',
      name:'Fiji',
      dialCode:'+679'
    },
    {
      class:'iti__flag iti__fi',
      countryCode:'fi',
      name:'Finland (Suomi)',
      dialCode:'+358'
    },
    {
      class:'iti__flag iti__fr',
      countryCode:'fr',
      name:'France',
      dialCode:'+33'
    },
    {
      class:'iti__flag iti__gf',
      countryCode:'gf',
      name:'French Guiana',
      dialCode:'+594'
    },
    {
      class:'iti__flag iti__pf',
      countryCode:'pf',
      name:'French Polynesia',
      dialCode:'+689'
    },
    {
      class:'iti__flag iti__ga',
      countryCode:'ga',
      name:'Gabon',
      dialCode:'+241'
    },
    {
      class:'iti__flag iti__gm',
      countryCode:'gm',
      name:'Gambia',
      dialCode:'+220'
    },
    {
      class:'iti__flag iti__ge',
      countryCode:'ge',
      name:'Georgia',
      dialCode:'+995'
    },
    {
      class:'iti__flag iti__de',
      countryCode:'de',
      name:'Germany (Deutschland)',
      dialCode:'+49'
    },
    {
      class:'iti__flag iti__gh',
      countryCode:'gh',
      name:'Ghana (Gaana)',
      dialCode:'+233'
    },
    {
      class:'iti__flag iti__gi',
      countryCode:'gi',
      name:'Gibraltar',
      dialCode:'+350'
    },
    {
      class:'iti__flag iti__gr',
      countryCode:'gr',
      name:'Greece',
      dialCode:'+30'
    },
    {
      class:'iti__flag iti__gl',
      countryCode:'gl',
      name:'Greenland',
      dialCode:'+299'
    },
    {
      class:'iti__flag iti__gd',
      countryCode:'gd',
      name:'Grenada',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__gp',
      countryCode:'gp',
      name:'Guadeloupe',
      dialCode:'+590'
    },
    {
      class:'iti__flag iti__gu',
      countryCode:'gu',
      name:'Guam',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__gt',
      countryCode:'gt',
      name:'Guatemala',
      dialCode:'+502'
    },
    {
      class:'iti__flag iti__gg',
      countryCode:'gg',
      name:'Guernsey',
      dialCode:'+44'
    },
    {
      class:'iti__flag iti__gn',
      countryCode:'gn',
      name:'Guinea',
      dialCode:'+224'
    },
    {
      class:'iti__flag iti__gw',
      countryCode:'gw',
      name:'Guinea-Bissau',
      dialCode:'+245'
    },
    {
      class:'iti__flag iti__gy',
      countryCode:'gy',
      name:'Guyana',
      dialCode:'+592'
    },
    {
      class:'iti__flag iti__ht',
      countryCode:'ht',
      name:'Haiti',
      dialCode:'+509'
    },
    {
      class:'iti__flag iti__hn',
      countryCode:'hn',
      name:'Honduras',
      dialCode:'+504'
    },
    {
      class:'iti__flag iti__hk',
      countryCode:'hk',
      name:'Hong Kong',
      dialCode:'+852'
    },
    {
      class:'iti__flag iti__hu',
      countryCode:'hu',
      name:'Hungary',
      dialCode:'+36'
    },
    {
      class:'iti__flag iti__is',
      countryCode:'is',
      name:'Iceland',
      dialCode:'+354'
    },
    {
      class:'iti__flag iti__id',
      countryCode:'id',
      name:'Indonesia',
      dialCode:'+62'
    },
    {
      class:'iti__flag iti__ir',
      countryCode:'ir',
      name:'Iran',
      dialCode:'+98'
    },
    {
      class:'iti__flag iti__iq',
      countryCode:'iq',
      name:'Iraq',
      dialCode:'+964'
    },
    {
      class:'iti__flag iti__ie',
      countryCode:'ie',
      name:'Ireland',
      dialCode:'+353'
    },
    {
      class:'iti__flag iti__im',
      countryCode:'im',
      name:'Isle of Man',
      dialCode:'+44'
    },
    {
      class:'iti__flag iti__il',
      countryCode:'il',
      name:'Israel',
      dialCode:'+972'
    },
    {
      class:'iti__flag iti__it',
      countryCode:'it',
      name:'Italy',
      dialCode:'+39'
    },
    {
      class:'iti__flag iti__jm',
      countryCode:'jm',
      name:'Jamaica',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__jp',
      countryCode:'jp',
      name:'Japan',
      dialCode:'+81'
    },
    {
      class:'iti__flag iti__je',
      countryCode:'je',
      name:'Jersey',
      dialCode:'+44'
    },
    {
      class:'iti__flag iti__jo',
      countryCode:'jo',
      name:'Jordan',
      dialCode:'+962'
    },
    {
      class:'iti__flag iti__kz',
      countryCode:'kz',
      name:'Kazakhstan',
      dialCode:'+7'
    },
    {
      class:'iti__flag iti__ke',
      countryCode:'ke',
      name:'Kenya',
      dialCode:'+254'
    },
    {
      class:'iti__flag iti__ki',
      countryCode:'ki',
      name:'Kiribati',
      dialCode:'+686'
    },
    {
      class:'iti__flag iti__xk',
      countryCode:'xk',
      name:'Kosovo',
      dialCode:'+383'
    },
    {
      class:'iti__flag iti__kw',
      countryCode:'kw',
      name:'Kuwait',
      dialCode:'+965'
    },
    {
      class:'iti__flag iti__kg',
      countryCode:'kg',
      name:'Kyrgyzstan',
      dialCode:'+996'
    },
    {
      class:'iti__flag iti__la',
      countryCode:'la',
      name:'Laos',
      dialCode:'+856'
    },
    {
      class:'iti__flag iti__lv',
      countryCode:'lv',
      name:'Latvia',
      dialCode:'+371'
    },
    {
      class:'iti__flag iti__lb',
      countryCode:'lb',
      name:'Lebanon',
      dialCode:'+961'
    },
    {
      class:'iti__flag iti__ls',
      countryCode:'ls',
      name:'Lesotho',
      dialCode:'+266'
    },
    {
      class:'iti__flag iti__lr',
      countryCode:'lr',
      name:'Liberia',
      dialCode:'+231'
    },
    {
      class:'iti__flag iti__ly',
      countryCode:'ly',
      name:'Libya',
      dialCode:'+218'
    },
    {
      class:'iti__flag iti__li',
      countryCode:'li',
      name:'Liechtenstein',
      dialCode:'+423'
    },
    {
      class:'iti__flag iti__lt',
      countryCode:'lt',
      name:'Lithuania',
      dialCode:'+370'
    },
    {
      class:'iti__flag iti__lu',
      countryCode:'lu',
      name:'Luxembourg',
      dialCode:'+352'
    },
    {
      class:'iti__flag iti__mo',
      countryCode:'mo',
      name:'Macau',
      dialCode:'+853'
    },
    {
      class:'iti__flag iti__mg',
      countryCode:'mg',
      name:'Madagascar',
      dialCode:'+261'
    },
    {
      class:'iti__flag iti__mw',
      countryCode:'mw',
      name:'Malawi',
      dialCode:'+265'
    },
    {
      class:'iti__flag iti__my',
      countryCode:'my',
      name:'Malaysia',
      dialCode:'+60'
    },
    {
      class:'iti__flag iti__mv',
      countryCode:'mv',
      name:'Maldives',
      dialCode:'+960'
    },
    {
      class:'iti__flag iti__ml',
      countryCode:'ml',
      name:'Mali',
      dialCode:'+223'
    },
    {
      class:'iti__flag iti__mt',
      countryCode:'mt',
      name:'Malta',
      dialCode:'+356'
    },
    {
      class:'iti__flag iti__mh',
      countryCode:'mh',
      name:'Marshall Islands',
      dialCode:'+692'
    },
    {
      class:'iti__flag iti__mq',
      countryCode:'mq',
      name:'Martinique',
      dialCode:'+596'
    },
    {
      class:'iti__flag iti__mr',
      countryCode:'mr',
      name:'Mauritania',
      dialCode:'+222'
    },
    {
      class:'iti__flag iti__mu',
      countryCode:'mu',
      name:'Mauritius',
      dialCode:'+230'
    },
    {
      class:'iti__flag iti__yt',
      countryCode:'yt',
      name:'Mayotte',
      dialCode:'+262'
    },
    {
      class:'iti__flag iti__mx',
      countryCode:'mx',
      name:'Mexico',
      dialCode:'+52'
    },
    {
      class:'iti__flag iti__fm',
      countryCode:'fm',
      name:'Micronesia',
      dialCode:'+691'
    },
    {
      class:'iti__flag iti__md',
      countryCode:'md',
      name:'Moldova',
      dialCode:'+373'
    },
    {
      class:'iti__flag iti__mc',
      countryCode:'mc',
      name:'Monaco',
      dialCode:'+377'
    },
    {
      class:'iti__flag iti__mn',
      countryCode:'mn',
      name:'Mongolia',
      dialCode:'+976'
    },
    {
      class:'iti__flag iti__me',
      countryCode:'me',
      name:'Montenegro',
      dialCode:'+382'
    },
    {
      class:'iti__flag iti__ms',
      countryCode:'ms',
      name:'Montserrat',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ma',
      countryCode:'ma',
      name:'Morocco',
      dialCode:'+212'
    },
    {
      class:'iti__flag iti__mz',
      countryCode:'mz',
      name:'Mozambique',
      dialCode:'+258'
    },
    {
      class:'iti__flag iti__mm',
      countryCode:'mm',
      name:'Myanmar (Burma)',
      dialCode:'+95'
    },
    {
      class:'iti__flag iti__na',
      countryCode:'na',
      name:'Namibia',
      dialCode:'+264'
    },
    {
      class:'iti__flag iti__nr',
      countryCode:'nr',
      name:'Nauru',
      dialCode:'+674'
    },
    {
      class:'iti__flag iti__np',
      countryCode:'np',
      name:'Nepal',
      dialCode:'+977'
    },
    {
      class:'iti__flag iti__nl',
      countryCode:'nl',
      name:'Netherlands',
      dialCode:'+31'
    },
    {
      class:'iti__flag iti__nc',
      countryCode:'nc',
      name:'New Caledonia',
      dialCode:'+687'
    },
    {
      class:'iti__flag iti__nz',
      countryCode:'nz',
      name:'New Zealand',
      dialCode:'+64'
    },
    {
      class:'iti__flag iti__ni',
      countryCode:'ni',
      name:'Nicaragua',
      dialCode:'+505'
    },
    {
      class:'iti__flag iti__ne',
      countryCode:'ne',
      name:'Niger',
      dialCode:'+227'
    },
    {
      class:'iti__flag iti__ng',
      countryCode:'ng',
      name:'Nigeria',
      dialCode:'+234'
    },
    {
      class:'iti__flag iti__nu',
      countryCode:'nu',
      name:'Niue',
      dialCode:'+683'
    },
    {
      class:'iti__flag iti__nf',
      countryCode:'nf',
      name:'Norfolk Island',
      dialCode:'+672'
    },
    {
      class:'iti__flag iti__kp',
      countryCode:'kp',
      name:'North Korea',
      dialCode:'+850'
    },
    {
      class:'iti__flag iti__mk',
      countryCode:'mk',
      name:'North Macedonia',
      dialCode:'+389'
    },
    {
      class:'iti__flag iti__mp',
      countryCode:'mp',
      name:'Northern Mariana Islands',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__no',
      countryCode:'no',
      name:'Norway',
      dialCode:'+47'
    },
    {
      class:'iti__flag iti__om',
      countryCode:'om',
      name:'Oman',
      dialCode:'+968'
    },
    {
      class:'iti__flag iti__pk',
      countryCode:'pk',
      name:'Pakistan',
      dialCode:'+92'
    },
    {
      class:'iti__flag iti__pw',
      countryCode:'pw',
      name:'Palau',
      dialCode:'+680'
    },
    {
      class:'iti__flag iti__ps',
      countryCode:'ps',
      name:'Palestine',
      dialCode:'+970'
    },
    {
      class:'iti__flag iti__pa',
      countryCode:'pa',
      name:'Panama',
      dialCode:'+507'
    },
    {
      class:'iti__flag iti__pg',
      countryCode:'pg',
      name:'Papua New Guinea',
      dialCode:'+675'
    },
    {
      class:'iti__flag iti__py',
      countryCode:'py',
      name:'Paraguay',
      dialCode:'+595'
    },
    {
      class:'iti__flag iti__pe',
      countryCode:'pe',
      name:'Peru',
      dialCode:'+51'
    },
    {
      class:'iti__flag iti__ph',
      countryCode:'ph',
      name:'Philippines',
      dialCode:'+63'
    },
    {
      class:'iti__flag iti__pl',
      countryCode:'pl',
      name:'Poland',
      dialCode:'+48'
    },
    {
      class:'iti__flag iti__pt',
      countryCode:'pt',
      name:'Portugal',
      dialCode:'+351'
    },
    {
      class:'iti__flag iti__pr',
      countryCode:'pr',
      name:'Puerto Rico',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__qa',
      countryCode:'qa',
      name:'Qatar',
      dialCode:'+974'
    },
    {
      class:'iti__flag iti__re',
      countryCode:'re',
      name:'Réunion',
      dialCode:'+262'
    },
    {
      class:'iti__flag iti__ro',
      countryCode:'ro',
      name:'Romania',
      dialCode:'+40'
    },
    {
      class:'iti__flag iti__ru',
      countryCode:'ru',
      name:'Russia',
      dialCode:'+7'
    },
    {
      class:'iti__flag iti__rw',
      countryCode:'rw',
      name:'Rwanda',
      dialCode:'+250'
    },
    {
      class:'iti__flag iti__bl',
      countryCode:'bl',
      name:'Saint Barthélemy',
      dialCode:'+590'
    },
    {
      class:'iti__flag iti__sh',
      countryCode:'sh',
      name:'Saint Helena',
      dialCode:'+290'
    },
    {
      class:'iti__flag iti__kn',
      countryCode:'kn',
      name:'Saint Kitts and Nevis',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__lc',
      countryCode:'lc',
      name:'Saint Lucia',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__mf',
      countryCode:'mf',
      name:'Saint Martin',
      dialCode:'+590'
    },
    {
      class:'iti__flag iti__pm',
      countryCode:'pm',
      name:'Saint Pierre and Miquelon',
      dialCode:'+508'
    },
    {
      class:'iti__flag iti__vc',
      countryCode:'vc',
      name:'Saint Vincent and the Grenadines',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ws',
      countryCode:'ws',
      name:'Samoa',
      dialCode:'+685'
    },
    {
      class:'iti__flag iti__sm',
      countryCode:'sm',
      name:'San Marino',
      dialCode:'+378'
    },
    {
      class:'iti__flag iti__st',
      countryCode:'st',
      name:'São Tomé and Príncipe',
      dialCode:'+239'
    },
    {
      class:'iti__flag iti__sa',
      countryCode:'sa',
      name:'Saudi Arabia',
      dialCode:'+966'
    },
    {
      class:'iti__flag iti__sn',
      countryCode:'sn',
      name:'Senegal',
      dialCode:'+221'
    },
    {
      class:'iti__flag iti__sc',
      countryCode:'sc',
      name:'Seychelles',
      dialCode:'+248'
    },
    {
      class:'iti__flag iti__sl',
      countryCode:'sl',
      name:'Sierra Leone',
      dialCode:'+232'
    },
    {
      class:'iti__flag iti__sg',
      countryCode:'sg',
      name:'Singapore',
      dialCode:'+65'
    },
    {
      class:'iti__flag iti__sx',
      countryCode:'sx',
      name:'Sint Maarten',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__sk',
      countryCode:'sk',
      name:'Slovakia',
      dialCode:'+421'
    },
    {
      class:'iti__flag iti__si',
      countryCode:'si',
      name:'Slovenia',
      dialCode:'+386'
    },
    {
      class:'iti__flag iti__sb',
      countryCode:'sb',
      name:'Solomon Islands',
      dialCode:'+677'
    },
    {
      class:'iti__flag iti__so',
      countryCode:'so',
      name:'Somalia',
      dialCode:'+252'
    },
    {
      class:'iti__flag iti__za',
      countryCode:'za',
      name:'South Africa',
      dialCode:'+27'
    },
    {
      class:'iti__flag iti__kr',
      countryCode:'kr',
      name:'South Korea',
      dialCode:'+82'
    },
    {
      class:'iti__flag iti__ss',
      countryCode:'ss',
      name:'South Sudan',
      dialCode:'+211'
    },
    {
      class:'iti__flag iti__es',
      countryCode:'es',
      name:'Spain',
      dialCode:'+34'
    },
    {
      class:'iti__flag iti__lk',
      countryCode:'lk',
      name:'Sri Lanka',
      dialCode:'+94'
    },
    {
      class:'iti__flag iti__sd',
      countryCode:'sd',
      name:'Sudan',
      dialCode:'+249'
    },
    {
      class:'iti__flag iti__sr',
      countryCode:'sr',
      name:'Suriname',
      dialCode:'+597'
    },
    {
      class:'iti__flag iti__sj',
      countryCode:'sj',
      name:'Svalbard and Jan Mayen',
      dialCode:'+47'
    },
    {
      class:'iti__flag iti__se',
      countryCode:'se',
      name:'Sweden',
      dialCode:'+46'
    },
    {
      class:'iti__flag iti__ch',
      countryCode:'ch',
      name:'Switzerland',
      dialCode:'+41'
    },
    {
      class:'iti__flag iti__sy',
      countryCode:'sy',
      name:'Syria',
      dialCode:'+963'
    },
    {
      class:'iti__flag iti__tw',
      countryCode:'tw',
      name:'Taiwan',
      dialCode:'+886'
    },
    {
      class:'iti__flag iti__tj',
      countryCode:'tj',
      name:'Tajikistan',
      dialCode:'+992'
    },
    {
      class:'iti__flag iti__tz',
      countryCode:'tz',
      name:'Tanzania',
      dialCode:'+255'
    },
    {
      class:'iti__flag iti__th',
      countryCode:'th',
      name:'Thailand',
      dialCode:'+66'
    },
    {
      class:'iti__flag iti__tl',
      countryCode:'tl',
      name:'Timor-Leste',
      dialCode:'+670'
    },
    {
      class:'iti__flag iti__tg',
      countryCode:'tg',
      name:'Togo',
      dialCode:'+228'
    },
    {
      class:'iti__flag iti__tk',
      countryCode:'tk',
      name:'Tokelau',
      dialCode:'+690'
    },
    {
      class:'iti__flag iti__to',
      countryCode:'to',
      name:'Tonga',
      dialCode:'+676'
    },
    {
      class:'iti__flag iti__tt',
      countryCode:'tt',
      name:'Trinidad and Tobago',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__tn',
      countryCode:'tn',
      name:'Tunisia',
      dialCode:'+216'
    },
    {
      class:'iti__flag iti__tr',
      countryCode:'tr',
      name:'Turkey',
      dialCode:'+90'
    },
    {
      class:'iti__flag iti__tm',
      countryCode:'tm',
      name:'Turkmenistan',
      dialCode:'+993'
    },
    {
      class:'iti__flag iti__tc',
      countryCode:'tc',
      name:'Turks and Caicos Islands',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__tv',
      countryCode:'tv',
      name:'Tuvalu',
      dialCode:'+688'
    },
    {
      class:'iti__flag iti__vi',
      countryCode:'vi',
      name:'U.S. Virgin Islands',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__ug',
      countryCode:'ug',
      name:'Uganda',
      dialCode:'+256'
    },
    {
      class:'iti__flag iti__ua',
      countryCode:'ua',
      name:'Ukraine',
      dialCode:'+380'
    },
    {
      class:'iti__flag iti__ae',
      countryCode:'ae',
      name:'United Arab Emirates',
      dialCode:'+971'
    },
    {
      class:'iti__flag iti__gb',
      countryCode:'gb',
      name:'United Kingdom',
      dialCode:'+44'
    },
    {
      class:'iti__flag iti__us',
      countryCode:'us',
      name:'United States',
      dialCode:'+1'
    },
    {
      class:'iti__flag iti__uy',
      countryCode:'uy',
      name:'Uruguay',
      dialCode:'+598'
    },
    {
      class:'iti__flag iti__uz',
      countryCode:'uz',
      name:'Uzbekistan',
      dialCode:'+998'
    },
    {
      class:'iti__flag iti__vu',
      countryCode:'vu',
      name:'Vanuatu',
      dialCode:'+678'
    },
    {
      class:'iti__flag iti__va',
      countryCode:'va',
      name:'Vatican City',
      dialCode:'+39'
    },
    {
      class:'iti__flag iti__ve',
      countryCode:'ve',
      name:'Venezuela',
      dialCode:'+58'
    },
    {
      class:'iti__flag iti__vn',
      countryCode:'vn',
      name:'Vietnam',
      dialCode:'+84'
    },
    {
      class:'iti__flag iti__wf',
      countryCode:'wf',
      name:'Wallis and Futuna',
      dialCode:'+681'
    },
    {
      class:'iti__flag iti__eh',
      countryCode:'eh',
      name:'Western Sahara',
      dialCode:'+212'
    },
    {
      class:'iti__flag iti__ye',
      countryCode:'ye',
      name:'Yemen',
      dialCode:'+967'
    },
    {
      class:'iti__flag iti__zm',
      countryCode:'zm',
      name:'Zambia',
      dialCode:'+260'
    },
    {
      class:'iti__flag iti__zw',
      countryCode:'zw',
      name:'Zimbabwe',
      dialCode:'+263'
    },
    {
      class:'iti__flag iti__ax',
      countryCode:'ax',
      name:'Åland Islands',
      dialCode:'+358'
    }

   
  ];


  /**
   * Multi Document List
   */
  showAccordion=true;
  MultiDocList=[]
  MultipleListEmailWhatsappWise=[]
  deletedMultiListData=[]
  doNotHideThreeDot=false;

  /**
   * Free D coins related Variables
   */
   availableDcoins=0;
  
  /**
  * Expiration Date Modal Related Variables
  */
  tempexpirationDateModalHour=10
  tempexpirationDateModalMin: any =50
  tempexpirationDateModalAMPM='AM'
  tempexpirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
  tempminexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
  tempmaxexpirationModalCalendardate=new Date(new Date().getTime()+50*24*60*60*1000)
  tempexpirationModalCaledardateFormatted='';
  tempexpirationDateInfiniteDuration: boolean=false;
  tempexpirationError=''

  expirationDateModalHour=10
  expirationDateModalMin:any =50
  expirationDateModalAMPM='AM'
  expirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
  minexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
  maxexpirationModalCalendardate=new Date(new Date().getTime()+50*12*30*24*60*60*1000)
  expirationModalCaledardateFormatted='';
  expirationDateInfiniteDuration=false;
  expirationError=''

  sharedByUserName: string = '';
  otpStatus: string = '';
  userRightsCheck: string = '';
  /**
   *Advanced Settings Related Variables 
   */
  advancedSettingsOtpMandatory=false;
  advancedSettingsDownloadMandatory=false
  tempadvancedSettingsOtpMandatory=false;
  tempadvancedSettingsDownloadMandatory=false
  originalAdvancedSettingsDownloadMandatory: boolean;
  /**
   * UserRights 
   */
  userRights:any
  userWhatsappEnabled:any;
  userDownloadPrintEnabled:any;
  isOtpMandatory:any;
  isDownloadPrintMandatory:any
  @Output() invitemodal = new EventEmitter<any>();
  @ViewChild(DetailAndActivityComponent) rightnavchild: DetailAndActivityComponent;
  @ViewChild('chipemail') mailid !: ElementRef


  ngOnInit(): void { 
    

    this.whatsappInPlan = localStorage.getItem('WhatsappFlag').toLowerCase() =='true'?true:false;
    this.guestInPlan = localStorage.getItem('GuestFlag').toLowerCase() =='true'?true:false;
    this.isWhatsappAccessInRole = localStorage.getItem('isGuestWhatsappAccess').toLowerCase() =='true'?true:false;
    this.isGuestAccessInRole = localStorage.getItem('isGuestAccess').toString().toLowerCase() =='true'?true:false;
    this.generalOtp = localStorage.getItem('GeneralOTP').toLowerCase() =='true'?true:false;
    this.generalDownloadPrint = localStorage.getItem('GeneralDownloadPrint').toLowerCase() =='true'?true:false;
    this.isDcoinEnableInPlan = localStorage.getItem('dcoins').toLowerCase() =='true'?true:false;
    this.dcoinsAvailable = Number(localStorage.getItem('TotalDCoins'));

    
    if(!this.whatsappInPlan){
      this.whatsappTooltipMsg="This feature is not available in your plan. Please upgrade your subscription and try again."
    }
    if(this.whatsappInPlan && !this.isWhatsappAccessInRole){
      this.whatsappTooltipMsg="The rights for this feature are not enabled in your role. Please contact your Workspace Admin to assign the necessary rights."
    }

    if(!this.whatsappInPlan) {
      this.isWhatsappAccessInRole = false;
    }

    this.tempadvancedSettingsOtpMandatory=false;
    this.tempadvancedSettingsDownloadMandatory=false

    if(this.generalOtp) {
      this.advancedSettingsOtpMandatory = true; 
      this.advancedSettingOtpInputChange(this.advancedSettingsOtpMandatory); 
    }
    else {
      this.advancedSettingsOtpMandatory = false;  
    }

    
    if(this.generalDownloadPrint) {
      this.advancedSettingsDownloadMandatory = false;  
      this.advancedSettingDownloadPrintChange(this.advancedSettingsDownloadMandatory);
    }
    else {
      this.advancedSettingsDownloadMandatory = false; 
    }
    this.cd.detectChanges();
    this.createdBy = localStorage.getItem('localloginId')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.wsOwnerName = localStorage.getItem('WsdisplayName')?  localStorage.getItem('WsdisplayName'):'';
    this.noteuserfullname = localStorage.getItem('noteuserfullname') ? localStorage.getItem('noteuserfullname'):'';

    this.userDownloadPrintEnabled=localStorage.getItem('isDownloadPrintDocuments')?localStorage.getItem('isDownloadPrintDocuments'):false
    this.userWhatsappEnabled=localStorage.getItem('isWhatsappAccess')?localStorage.getItem('isWhatsappAccess'):false
    this.isOtpMandatory=localStorage.getItem('isOtpMandatory')?localStorage.getItem('isOtpMandatory'):false
    this.isDownloadPrintMandatory=localStorage.getItem('isDownloadPrintMandatory')?localStorage.getItem('isDownloadPrintMandatory'):false

    this.confrmdissabledbtn = true;
    this.getIPAddress();
    this.href = this.router.url;

    /** 
     * Logic to show current time on Expiration Modal calendar
    */
    this.tempexpirationDateInfiniteDuration=false
    this.tempexpirationError=''

    this.expirationDateInfiniteDuration=false;
    this.expirationError=''

    this.expirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
    this.tempexpirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
    this.tempexpirationModalCalendardateNew =  this.tempexpirationModalCalendardate
    this.minexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
    this.tempminexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
    
    this.expirationDateModalHour=new Date().getHours()
    this.tempexpirationDateModalHour=new Date().getHours()

    this.expirationDateModalMin=new Date().getMinutes()
    this.tempexpirationDateModalMin=new Date().getMinutes()

    if((this.expirationDateModalMin<10) &&(this.expirationDateModalMin!=0)) {
      this.expirationDateModalMin = "0"+this.expirationDateModalMin;
    }
    else if(this.expirationDateModalMin==0) {
      this.expirationDateModalMin = "00"
    }
    else {
      this.expirationDateModalMin
    }

    if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
      this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
    }
    else if(this.tempexpirationDateModalMin==0) {
      this.tempexpirationDateModalMin = "00"
    }
    else {
      this.tempexpirationDateModalMin
    }


    if(this.expirationDateModalHour>11){
      this.expirationDateModalAMPM='PM'
    }
    if(this.tempexpirationDateModalHour>11){
      this.tempexpirationDateModalAMPM='PM'
    }
    
    if(this.expirationDateModalHour>12){
      this.expirationDateModalHour=this.expirationDateModalHour-12;
    }
    if(this.tempexpirationDateModalHour>12){
      this.tempexpirationDateModalHour=this.tempexpirationDateModalHour-12;
    }

    let st = window.location.toString();
    let arr = st?.split("/");
    this.guestShareDirty=false;
    this.cd.detectChanges();
  }
 
  @ViewChild('RemoveGuestAccessWarnModal') RemoveGuestAccessWarnModal:TemplateRef<any>
  RemoveGuestAccessWarnModalRef:any

  @ViewChild('unsavedChangesWarnModal') unsavedChangesWarnModal:TemplateRef<any>
  unsavedChangesWarnModalRef:any

  @ViewChild('unsavedChangesWarnModalEmailWhatsappSwitch') unsavedChangesWarnModalEmailWhatsappSwitch:TemplateRef<any>
  unsavedChangesWarnModalEmailWhatsappSwitchRef:any
  
  @ViewChild('advancedSettingOtpDownloadModal') advancedSettingOtpDownloadModal: TemplateRef<any>
  dialogRefAdvancedSettingModel:any

  @ViewChild('dialogRefExpirationModel') dialogRefExpiration: TemplateRef<any>
  dialogRefExpirationModelRef:any

  @ViewChild('InsufficientDcoinsModal') InsufficientDcoinsModal: TemplateRef<any>
  InsufficientDcoinsModalRef:any

  ngAfterViewInit(): void {
    
    this.userDownloadPrintEnabled=localStorage.getItem('isDownloadPrintDocuments')?localStorage.getItem('isDownloadPrintDocuments'):false
    this.userWhatsappEnabled=localStorage.getItem('isWhatsappAccess')?localStorage.getItem('isWhatsappAccess'):false
    this.isOtpMandatory=localStorage.getItem('isOtpMandatory')?localStorage.getItem('isOtpMandatory'):false
    this.isDownloadPrintMandatory=localStorage.getItem('isDownloadPrintMandatory')?localStorage.getItem('isDownloadPrintMandatory'):false
  }

  
  @ViewChild('countryListDropDown',{static:false, read: ElementRef}) countryListDropDown:ElementRef<HTMLElement>;
  @HostListener('document:click', ['$event']) onClick(e) {

    
      let rect
      if(document.getElementById('countryListDropDownMainDiv')){
         rect=document.getElementById('countryListDropDownMainDiv').getBoundingClientRect();
         console.log("rect",rect)
      }
     
      console.log("rect",rect)
  
      // /if(typeof(rect)!='undefined'){
        
        setTimeout(() => {
          if(!((e?.clientX>=rect?.left && e?.clientX<=rect.right) && (e?.clientY>=(rect.top) && e?.clientY<=rect.bottom)) && this.showCountryDropdown )
          {
      
            let rectFlag
            if(document?.getElementById('countryListDropDownFlagContainer')){
              rectFlag =document?.getElementById('countryListDropDownFlagContainer').getBoundingClientRect();
            }
      
            if(!((e?.clientX>=rectFlag?.left && e?.clientX<=rectFlag?.right) && (e?.clientY>=rectFlag?.top && e?.clientY<=rectFlag?.bottom)))
            {
              this.showCountryDropdown=false;
            }
          }
        }, 500);
  
      //}

    
    
  
  
}

  /**
   *Update Dcoins Display
   */
   UpdateDeductedDcoinsDisplay()
   {
       let st = window.location.toString();
       let arr = st?.split("/");
 
       let subsDetailpostData = {
         "WSLink": arr[2],
         "Wsoid": Number(this.workSpaceId)
       }

       setTimeout(() => {

        this.data.subscriptionDetailsApiCall(subsDetailpostData).subscribe((response:any)=>{
          this.availableDcoins=response.data[0].totalDcoins
          localStorage.setItem('TotalDCoins',String(this.availableDcoins))
          this.cd.detectChanges()
          // this.sharedService.transferData.next('refreshDcoinsData');
          })

       }, 5000);
 
       
 
   }
 

  /**
   *Close Insufficient dcoins Modal 
   */
   CloseInsufficientDcoinsModal(){
    this.InsufficientDcoinsModalRef.close()
   }

  /** 
   *Function to get updated list of shares document wise 
   */
  getUpdatedListOfSharesForDoc()
  {
    this.singleSkeleton=true;
    this.MultipleListEmailWhatsappWise=[]
    let getuserlistsPayload={
      "wsoid": Number(this.workSpaceId),
      "docGuid": this.docGuidArr.join('|'),
    }
   
    this.data.getListofSharedUsersOfDoc(getuserlistsPayload).subscribe((response:any)=>{
      if(response.isSuccess){
          if(response.data && response.data.length>0)
          {
            let uniqueEmailPhone=[]
            let multiListArr=[]
            /**
             * create unique emailPhone list
             */
            for(let i=0; i<response.data.length; i++)
            {
              if(!uniqueEmailPhone.includes(response.data[i].guestWhatsAppEmail))
              {
                uniqueEmailPhone.push(response.data[i].guestWhatsAppEmail);
              }
            }

            for(let i=0; i<uniqueEmailPhone.length; i++)
            {
              let documentArr=[]
              for(let j=0; j<response.data.length; j++)
              {
                if(response.data[j].guestWhatsAppEmail==uniqueEmailPhone[i])
                {
                  let infiniteDate=false;
                  console.log(response.data[j].expiryDtm)
                  if(response.data[j].expiryDtm && response.data[j].expiryDtm.includes('/'))
                  {
                    let splitdata=(response.data[j].expiryDtm).split(" ")
                    let year=splitdata[0].split('/')[2]
                    if(Number(year)>2100)
                    {
                      infiniteDate=true
                    }

                    
                  }

                  console.log("infiniteDate",infiniteDate)



                  documentArr.push({
                    docGuid:response.data[j].docGuid,
                    isInfinite:infiniteDate,
                    expiryDtm:response.data[j].expiryDtm,
                    expiryDuration:response.data[j].expiryDuration,
                    documentName:response.data[j].documentName,
                    isotp:response.data[j].isotp,
                    downloadPrintRights:response.data[j].downloadPrintRights,
                    shareBy:response.data[j].shareBy,
                    shareName:response.data[j].shareName?response.data[j].shareName:'',
                    messageStatus:response.data[j].messageStatus != "0"?response.data[j].messageStatus:'SENT',
                    isMobileNumberValid:response.data[j].isMobileNumberValid?response.data[j].isMobileNumberValid:'',
                    showIconTooltip:false,
                    showThreeDotTooltip:false,
                    checked:false,
                  })
                }
              }

              multiListArr.push({
                guestWhatsAppEmail:uniqueEmailPhone[i],
                show:false,
                checked:false,
                showModifyOptions:false,
                detailsArr:documentArr
              })



            }
            this.MultipleListEmailWhatsappWise=multiListArr

            console.log("this.MultipleListEmailWhatsappWise",this.MultipleListEmailWhatsappWise)
            
          }
          this.singleSkeleton=false;
      }
    })

  }
  /**
   * Function to handle checked changes in multiple docs list
   */
  handleMultiDocsCheckedChange(event,emailPhone,docName)
  {
        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        {

              if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
              {
                for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
                {

                  if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].documentName==docName){
                    if(event)
                    {
                      this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked=true;
                    }else{
                      this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked=false;
                    }

                  
                  }else{
                    
                  }
                  
                }
              }else{
                
              }

        }
        
        //Logic to set modify options true or false based inputs checked
        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        {      
              if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
              {
                this.MultipleListEmailWhatsappWise[i].showModifyOptions=false;
                for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
                {

                  if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked){
                    this.MultipleListEmailWhatsappWise[i].showModifyOptions=true;
                    
                    break;
                  }

                  
                }
              }else{
                
              }

        }

        let allRowsSelected = true; // Assuming initial state is all selected

        for (let i = 0; i < this.MultipleListEmailWhatsappWise.length; i++) {
          allRowsSelected = true; // Reset for each outer loop iteration
        
          for (let j = 0; j < this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++) {
            if (!this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked) {
              allRowsSelected = false;
              break; // Exit inner loop if any row is not checked
            }
          }
        
          this.MultipleListEmailWhatsappWise[i].checked = allRowsSelected;
        }
        


        let checkedRowArray = [];
        for (let i = 0; i < this.MultipleListEmailWhatsappWise.length; i++) {
          for (let j = 0; j < this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++) {
            if (this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked) {
              checkedRowArray.push(this.MultipleListEmailWhatsappWise[i].detailsArr[j]);
              if(checkedRowArray.length==1){
                this.MultipleListEmailWhatsappWise[i].showModifyOptions=false;
              }
              else{
                this.MultipleListEmailWhatsappWise[i].showModifyOptions=true;
              }
              console.log("check kar",checkedRowArray);
            }
          }
        }
  }
   
  /**
   * Function to check all items in multi docs
   */
  checkAllSubListsMultiDocs(event,emailPhone){
    
    for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++){

      if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
      {
        if(event){
          this.MultipleListEmailWhatsappWise[i].checked=true;
          this.MultipleListEmailWhatsappWise[i].showModifyOptions=true;

          for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
          {
            this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked=true
          }


        }else{
          this.MultipleListEmailWhatsappWise[i].checked=false;
          this.MultipleListEmailWhatsappWise[i].showModifyOptions=false;

          for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
          {
            this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked=false
          }
        }

      }
      
    }

  }

   
  /**
   *check if emailPhone Already exists 
   */
  checkEmailPhoneAlreadyShared(emailPhone)
  {
     for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
     {
       if(this.isSingleSharing)
       {
          if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail.toLowerCase()==emailPhone.toLowerCase())
          {
          console.log("checkEmailPhoneFound")
          return true;
          }
       }else{

          if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail.toLowerCase()==emailPhone.toLowerCase())
          {
            let chekPresentInAllDocs=true
            if(this.docGuidArr.length==this.MultipleListEmailWhatsappWise[i].detailsArr.length)
            {
              return true;
              
            }
           
          }
          

       }
       
     }
     return false;
  }

  inviteUsersForm = new FormGroup({
    'chipEmail': new FormControl('', [Validators.maxLength(200)]),
    'chipDuration': new FormControl('', [Validators.required])
  })
  /**
   * Function to handle behaviour when user toggles between Email and Whatsapp Share Sections
   */
  changeToggleRadioInput(event){
    // this.toggleOptionRadioEmailWhatsapp=event.target.value;
    if(event.target.value=='Email')
    {
      if(this.whatsappNumberInputArr.length>0 || this.whatsappNumberInputString!='')
      {
        this.unsavedChangesWarnModalEmailWhatsappSwitchRef=this.dialogService.open(this.unsavedChangesWarnModalEmailWhatsappSwitch, { context: 'this is some additional data passed to dialog' });
      }else{
        if(!this.closeBtnClick){
        this.expirationDate = 'Expiration Date';
        }
        this.ngOnInit()
        this.showEmailSection=true;
        this.showWhatsappSection=false;
        this.toggleOptionRadioEmailWhatsapp=event.target.value;
      }
      
    }else if(event.target.value=='Whatsapp'){
      if(this.emailInputArr.length>0 || this.emailInputString!='')
      {
        this.unsavedChangesWarnModalEmailWhatsappSwitchRef=this.dialogService.open(this.unsavedChangesWarnModalEmailWhatsappSwitch, { context: 'this is some additional data passed to dialog' });
      }else{
        if(!this.closeBtnClick){
        this.expirationDate = 'Expiration Date';
        }
        this.ngOnInit()
        this.showEmailSection=false;
        this.showWhatsappSection=true;
        this.toggleOptionRadioEmailWhatsapp=event.target.value;
      }
      
    }
   this.closeBtnClick = false;
  }

  changeToggleRadioInputText(event){
    
    if(event=='Email')
    {
      if(this.whatsappNumberInputArr.length>0 || this.whatsappNumberInputString!='')
      {
        this.unsavedChangesWarnModalEmailWhatsappSwitchRef=this.dialogService.open(this.unsavedChangesWarnModalEmailWhatsappSwitch, { context: 'this is some additional data passed to dialog' });
      }else{
        this.expirationDate = 'Expiration Date';
        this.ngOnInit()
        this.showEmailSection=true;
        this.showWhatsappSection=false;
        this.toggleOptionRadioEmailWhatsapp=event;
      }
      
    }else if(event=='Whatsapp'){
      if(this.emailInputArr.length>0 || this.emailInputString!='')
      {
        this.unsavedChangesWarnModalEmailWhatsappSwitchRef=this.dialogService.open(this.unsavedChangesWarnModalEmailWhatsappSwitch, { context: 'this is some additional data passed to dialog' });
      }else{
        this.expirationDate = 'Expiration Date';
        this.ngOnInit()
        this.showEmailSection=false;
        this.showWhatsappSection=true;
        this.toggleOptionRadioEmailWhatsapp=event;
      }
      
    }
   
  }

  /**
   * ToggleWhatsappCountryDropDown section
   */
  ToggleWhatsappCountryDropDown()
  {
    this.showCountryDropdown=!this.showCountryDropdown
  }
 
  /**
   *When Country is changed from dropdown 
   */
  CountryChange(dialCode,countryCode,flagClass){
    console.log("dialCode,countryCode,flagClass",dialCode,countryCode,flagClass)
    this.countryDialCode=dialCode
    this.countryNameInitial=countryCode
    this.countryFlagClass=flagClass
    
    this.whatsappNumberInputString=''
    this.whatsappNumberInputArr=[]
    this.whatsappChipsAllValid=true;
    this.cd.detectChanges();
  }
  /**
   * Search Country Dropdown List
   */
  SearchCountryList()
  { 
      let tempArr=[]
      let countryPushed=[]
      for(let i=0; i<this.countryDataArray.length; i++)
      {
          if(this.countryDataArray[i].name.toLowerCase().includes(this.countrySearchString.toLowerCase()))
          {
            tempArr.push(this.countryDataArray[i])
            countryPushed.push(this.countryDataArray[i].name)
          }
      }

      for(let i=0; i<this.countryDataArray.length; i++)
      {
          if(!countryPushed.includes(this.countryDataArray[i].name))
          {
            tempArr.push(this.countryDataArray[i])
          }
      }
      this.countryDataArray=tempArr
  }
  /**
   *Whatsapp Input Tab Event for Whatsapp Section
   */
   whatsappInputTabPress(event){
      // this.guestShareDirty=true

      let globalLength=0;
      for(let i=0; i<this.whatsappNumberInputArr.length; i++)
      {
        globalLength+=this.whatsappNumberInputArr[i].phone.length;
      }
      globalLength+=this.whatsappNumberInputString.length;
      
      if(globalLength<=1000)
      {
        this.whatsappNumberInputCheck()
      }
   }

  /**
  *Whatsapp Input KeyPress Event 
  */
  whatsappInputKeyPress(event)
  {
    // this.guestShareDirty=true

      let globalLength=0;
      for(let i=0; i<this.whatsappNumberInputArr.length; i++)
      {
        globalLength+=this.whatsappNumberInputArr[i].phone.length;
      }
      globalLength+=this.whatsappNumberInputString.length;

      let pattern=/[0-9]/g;
      let checkInputNumber=(pattern.test(event.key) || event.key==',' || event.keyCode==9 || event.keyCode==13)
      let lengthValidityCheck=!(globalLength>=1000 || this.whatsappNumberInputString.length>=20)

      if(!checkInputNumber || !lengthValidityCheck)
      {
        event.preventDefault()
      }else if(event.keyCode==13 || event.key=="," || event.keyCode==9){
        event.preventDefault()
        this.whatsappNumberInputCheck()
      }

  }

  /**
   *Function to Handle Backspace Event Whatsapp Number 
   */
  whatsappInputBackspace(event){
    let validCheck=true;
    let invalidPhoneError=false
    let duplicatePhoneError=false

    if(event.keyCode==8){
      if(this.whatsappNumberInputString=='' && this.whatsappNumberInputArr.length>0)
      {
        this.whatsappNumberInputArr.splice(this.whatsappNumberInputArr.length-1,1)
      }
    }

    this.whatsappErrorMsg=''
    const phoneUtil = PhoneNumberUtil.getInstance();
    //Check Valid Invalid
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      if(!this.whatsappNumberInputArr[i].isValid){
        validCheck=false;
        let phoneNumberValid=false
        if(this.whatsappNumberInputArr[i].phone.length<=20)
        {
          phoneNumberValid=phoneUtil.isValidNumber(phoneUtil.parse(this.whatsappNumberInputArr[i].phone,this.countryNameInitial));
        }
        

        if(!(this.whatsappNumberInputArr[i].phone.length<=20) || !phoneNumberValid){
          invalidPhoneError=true
          this.whatsappErrorMsg="This phone number is invalid"
        }

        if(this.checkEmailPhoneAlreadyShared(this.whatsappNumberInputArr[i].phone))
        {
          duplicatePhoneError=true
          this.whatsappErrorMsg="The doc(s) have already been shared with this guest"
        }

      }
    }

    if(invalidPhoneError && duplicatePhoneError)
    {
      this.whatsappErrorMsg="The entered number(s) are either invalid or already have the doc shared with them"
    }

  }

  /**
   *Function to Handle Paste Whatsapp Number 
   */
  whatsappNumberPaste(pastedData)
  {
    // this.guestShareDirty=true

    document.getElementById('whatsappSectionInputContainerDivPhoneNumbersInput').style.display='none';
    this.whatsappNumberInputString=pastedData
    let checkNumAlreadyExist=false;
    // for(let i=0; i<this.whatsappNumberExistingList.length; i++){
    //   if(this.whatsappNumberExistingList.includes(this.whatsappNumberInputString)){
    //     checkNumAlreadyExist=true;
    //   }
    // }

    const phoneUtil = PhoneNumberUtil.getInstance();

    let globalLength=0;
    let chipsTextLength=0;
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      chipsTextLength+=this.whatsappNumberInputArr[i].phone.length;
    }
    globalLength=this.whatsappNumberInputString.length+chipsTextLength;

    if(globalLength>1000){
      let charactersLeft=1000-chipsTextLength
      this.whatsappNumberInputString=this.whatsappNumberInputString.substring(0,charactersLeft)
    }
    globalLength=this.whatsappNumberInputString.length+chipsTextLength;
    
    let splitList=pastedData.trim().replace(/(\r\n|\n|\r)/gm, " ")?.split(/[, " "]+/);
    if(globalLength<=1000){
      let invalidPhoneError=false
      let duplicatePhoneError=false
      for(let i=0; i<splitList.length; i++)
      { 
            let checkNumAlreadyExist=false;

            for(let j=0; j<this.whatsappNumberInputArr.length; j++){
              if(this.whatsappNumberInputArr[j].phone==splitList[i]){
                checkNumAlreadyExist=true;
                
              }
            }

        let obj={}
        let phoneNumberValid=false
        if(splitList[i].length<=20)
        {
          phoneNumberValid=phoneUtil.isValidNumber(phoneUtil.parse(splitList[i],this.countryNameInitial));
        }

        if(splitList[i].length<=20 && !checkNumAlreadyExist && phoneNumberValid && !this.checkEmailPhoneAlreadyShared(splitList[i]))
        {
          obj={
            'isValid':true,
            'phone':splitList[i]
          }
          this.shareBtnValid=true
        }else{
          obj={
            'isValid':false,
            'phone':splitList[i]
          }
          this.whatsappChipsAllValid=false;
          this.shareBtnValid=false

          if(!(splitList[i].length<=20) || !phoneNumberValid){
            invalidPhoneError=true
            this.whatsappErrorMsg="This phone number is invalid"
          }

          if(checkNumAlreadyExist || this.checkEmailPhoneAlreadyShared(splitList[i]))
          {
            duplicatePhoneError=true
            this.whatsappErrorMsg="The doc(s) have already been shared with this guest"
          }
          

        }
        
        this.whatsappNumberInputArr.push(obj)
      }
      
      if(invalidPhoneError && duplicatePhoneError)
      {
        this.whatsappErrorMsg="The entered number(s) are either invalid or already have the doc shared with them"
       }
    }

    this.whatsappNumberInputString='';
    

    let myDiv = document.getElementById("whatsappSectionInputContainerDivPhoneNumbersChipsOuter");
    myDiv.scrollLeft = myDiv.scrollWidth;
    
    setTimeout(()=>{
      myDiv.scrollLeft = myDiv.scrollWidth;
      this.whatsappNumberInputString=''
      document.getElementById('whatsappSectionInputContainerDivPhoneNumbersInput').style.display='block';
      this.cd.detectChanges()
    }, 400); 

  }

  /**
   * Add Whatsapp Number Input
   */
  whatsappNumberInputCheck(){
    
        let pattern=/[0-9]/g;
    
        const phoneUtil = PhoneNumberUtil.getInstance();
        
        let splitList=this.whatsappNumberInputString.trim().split(",")
       
          for(let i=0; i<splitList.length; i++)
          { 
            let checkNumAlreadyExist=false;

            for(let i=0; i<this.whatsappNumberInputArr.length; i++){
              if(this.whatsappNumberInputArr[i].phone.toString()==splitList[i]){
                checkNumAlreadyExist=true;
              }
            }

            let obj={}
            
            let phoneNumberValid=false
            if(splitList[i].length<=20)
            {
              phoneNumberValid=phoneUtil.isValidNumber(phoneUtil.parse(splitList[i],this.countryNameInitial));
            }

            if(splitList[i].length<20 && !checkNumAlreadyExist && phoneNumberValid && !this.checkEmailPhoneAlreadyShared(splitList[i]))
            {
              obj={
                'isValid':true,
                'phone':splitList[i]
              }
              this.shareBtnValid=true
            }else{
              obj={
                'isValid':false,
                'phone':splitList[i]
              }
              this.whatsappChipsAllValid=false;
              this.shareBtnValid=false
              if(checkNumAlreadyExist || this.checkEmailPhoneAlreadyShared(splitList[i]))
              {
                this.whatsappErrorMsg="The doc(s) have already been shared with this guest"
              }else{
                this.whatsappErrorMsg="This phone number is invalid"
              }
              
            }
            
            this.whatsappNumberInputArr.push(obj)
            this.whatsappNumberInputString='';
          }
        
        

        let myDiv = document.getElementById("whatsappSectionInputContainerDivPhoneNumbersChipsOuter");
        myDiv.scrollLeft = myDiv.scrollWidth;
        setTimeout(()=> {
          myDiv.scrollLeft = myDiv.scrollWidth;
          let validCheck=true;
          for(let i=0; i<this.whatsappNumberInputArr.length; i++)
          {
            if(!this.whatsappNumberInputArr[i].isValid){
              validCheck=false;
            }
          }

          this.shareBtnValid=validCheck

        }, 1000); 
    
    
  }
  /**
   * Function to Remove whatsapp Number From Chips List of Email
   */
  RemoveWhatsappNumberFromChips(phone,idx){
    let validCheck=true;
    let invalidPhoneError=false
    let duplicatePhoneError=false
    
    //Remove Email
    this.whatsappNumberInputArr.splice(idx,1)
    //Remove Email
    // for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    // {
    //   if(this.whatsappNumberInputArr[i].phone==phone)
    //   {
    //     this.whatsappNumberInputArr.splice(i,1)
    //   }
      
    // }

    this.whatsappErrorMsg=''
    const phoneUtil = PhoneNumberUtil.getInstance();
    //Check Valid Invalid
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      if(!this.whatsappNumberInputArr[i].isValid){
        validCheck=false;
        let phoneNumberValid=false
        if(this.whatsappNumberInputArr[i].phone.length<=20)
        {
          phoneNumberValid=phoneUtil.isValidNumber(phoneUtil.parse(this.whatsappNumberInputArr[i].phone,this.countryNameInitial));
        }

        if(!(this.whatsappNumberInputArr[i].phone.length<=20) || !phoneNumberValid){
          invalidPhoneError=true
          this.whatsappErrorMsg="This phone number is invalid"
        }

        if(this.checkEmailPhoneAlreadyShared(this.whatsappNumberInputArr[i].phone))
        {
          duplicatePhoneError=true
          this.whatsappErrorMsg="The doc(s) have already been shared with this guest"
        }

      }
    }

    if(invalidPhoneError && duplicatePhoneError)
    {
      this.whatsappErrorMsg="The entered number(s) are either invalid or already have the doc shared with them"
    }

    this.whatsappChipsAllValid=validCheck;
    this.shareBtnValid=validCheck;
    if(this.whatsappChipsAllValid){
      this.whatsappErrorMsg=''
      this.DcoinsInsufficientError()
    }
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      if(this.whatsappNumberInputArr[i].isValid ==false&& !invalidPhoneError && !this.dCoinsInvalid ){
        duplicatePhoneError=true
        this.whatsappErrorMsg="The doc(s) have already been shared with this guest"
      }
    }
  }

  /**
   * Whatsapp Dcoins insufficient error 
   */
  DcoinsInsufficientError()
  {
    let st = window.location.toString();
    let arr = st?.split("/");
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    let phonelist=[]
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      if(this.whatsappNumberInputArr[i].isValid){
        phonelist.push(this.whatsappNumberInputArr[i].phone)
      }
    }

    let subsDetailpostData = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }

    let dcoinsReqPerMsg
    this.data.DcoinsRelatedSettings().subscribe((response: any)=> {
      if (response.isSuccess) {
        response.data.forEach((element)=> {
          if(element.itemName.toLowerCase() =='whatsapp') {
            this.whatsappMessages= Number(element.itemCount);
            this.dCoins = Number(element.dCoins);
            dcoinsReqPerMsg=Number(element.dCoins)/Number(element.itemCount);
            // console.log("this.dCoins",this.dCoins)
            // console.log("dcoinsReqPerMsg",dcoinsReqPerMsg)

            this.data.subscriptionDetailsApiCall(subsDetailpostData).subscribe((response:any)=>{
              this.availableDcoins=response.data[0].totalDcoins
              let dcoinsRequired=phonelist.length*Number(dcoinsReqPerMsg)
              localStorage.setItem('TotalDCoins',String(this.availableDcoins))
              
 
              
                               if(this.availableDcoins>=dcoinsRequired)
                               {
                                     
                          
                               }else{
                                 this.shareBtnValid=false
                                 for(let i=0; i<this.whatsappNumberInputArr.length; i++)
                                 {
                                   if((i+1)*dcoinsReqPerMsg>this.availableDcoins) 
                                   {
                                       this.whatsappNumberInputArr[i].isValid=false
                                   }
                                 }
                                
                               }
 
 
         })

          }
        })

     
  

    }

    })



  }

 /**
   *Function to call API on whatsapp share
   */
  WhatsappShareButtonClickFunction(){
    this.isExpiryEdit = false;
    this.whatsappBtnSpinnner=true
    this.shareBtnValid=false
    this.guestShareDirty=true;

    let st = window.location.toString();
    let arr = st?.split("/"); 
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    let phonelist=[]
    for(let i=0; i<this.whatsappNumberInputArr.length; i++)
    {
      if(this.whatsappNumberInputArr[i].isValid){
        phonelist.push(this.whatsappNumberInputArr[i].phone)
      }
    }
    
    let Date=''
    let Month=''
    if((this.expirationModalCalendardate.getMonth()+1)<10)
    {
      Month="0"+(this.expirationModalCalendardate.getMonth()+1)
    }else{
      Month=""+(this.expirationModalCalendardate.getMonth()+1)+""
    }

    if(this.expirationModalCalendardate.getDate()<10)
    {
      Date="0"+this.expirationModalCalendardate.getDate()
    }else{
      Date=""+this.expirationModalCalendardate.getDate()+""
    }


    let formatedDate=this.expirationModalCalendardate.getFullYear()+"-"+Month+"-"+Date+"T"
      
      if(this.expirationDateModalAMPM=='PM' && this.expirationDateModalHour!=12){
        let hour=this.expirationDateModalHour+12
        let min=''
        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }
        formatedDate+=hour+":"+min+":"+"00"
      }else{
        let hour=''
        let min=''
        
        if(this.expirationDateModalHour<10)
        {
          hour="0"+this.expirationDateModalHour
        }else{
          hour=""+this.expirationDateModalHour+""
        }

        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }

        formatedDate+=hour+":"+min+":"+'00'
      }
      
      this.expirationModalCaledardateFormatted=formatedDate
    
      let expirationInfiniteCheck=0;
      if(this.expirationDateInfiniteDuration){
        expirationInfiniteCheck=1
        this.expirationModalCaledardateFormatted=this.createInfiniteDate()
      }

      let otpMandatoryCheck=0
      if(this.advancedSettingsOtpMandatory){
        otpMandatoryCheck=1
      }

      let DownloadMandatoryCheck=0
      if(this.advancedSettingsDownloadMandatory){
        DownloadMandatoryCheck=1
      }

    let postData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.docGuidArr.join('|'),
      "guestWhatsAppNumberList": phonelist.join('|'),
      "expiryDurationList": this.expirationModalCaledardateFormatted,
      "isDeleteGuestWhatsAppNumberList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "messageID": 0,
      "messageStatus": 0,
      "isMobileNumberValid": 1,
      "countryCode": this.countryDialCode,
      "infiniteStatus": expirationInfiniteCheck,
      "isotp":otpMandatoryCheck,
      "downloadPrintRights":DownloadMandatoryCheck,
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    
    let dcoinsPayload={
      "wsoid": Number(this.workSpaceId),
      "itemCount": phonelist.length
    }

    let subsDetailpostData = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }

    let dcoinsReqPerMsg
    this.data.DcoinsRelatedSettings().subscribe((response: any)=> {
      if (response.isSuccess) {
        response.data.forEach((element)=> {
          if(element.itemName.toLowerCase()=='whatsapp') {
            this.whatsappMessages= Number(element.itemCount);
            this.dCoins = Number(element.dCoins);
            dcoinsReqPerMsg=Number(element.dCoins)/Number(element.itemCount);
            // console.log("this.dCoins",this.dCoins)
            // console.log("dcoinsReqPerMsg",dcoinsReqPerMsg)

            this.data.subscriptionDetailsApiCall(subsDetailpostData).subscribe((response:any)=>{
              this.availableDcoins=response.data[0].totalDcoins
              let dcoinsRequired=phonelist.length*Number(dcoinsReqPerMsg)
              localStorage.setItem('TotalDCoins',String(this.availableDcoins))
              // console.log("dcoinsRequired",dcoinsRequired)
              // console.log("this.availableDcoins",this.availableDcoins)
 
              
                               if(this.availableDcoins>=dcoinsRequired)
                               {
                                     this.data.phoneNumberWiseGuestShare(postData).subscribe((response: any) => {
                           
                                       if (response.isSuccess) {
                                         
                                         
                                         this.toastrService.primary("Document(s) shared with guest(s)")
                                         this.whatsappNumberInputArr=[]
                                         this.shareBtnValid=false
                                         
                                         let notifyEmailSent=[]
                                         const map = new Map();
                                         //Logic to Send Real Time Notify
                                         if(this.docsSelectedData.length == 1){
                                          let msgBody = '';
                                        //  for(let i=0; i<this.docsSelectedData.length; i++)
                                        //  {
                           
                                        //      if(!notifyEmailSent.includes(this.docsSelectedData[i].ownerEmail))
                                        //      {
                                        //            notifyEmailSent.push(this.docsSelectedData[i].ownerEmail)
                                                   
                                        //            let msgBody=localStorage.getItem('noteuserfullname')+" ("+localStorage.getItem('localloginId')+") shared "+this.docsSelectedData[i].modifiedName+" with "+phonelist.length+" guests. SendingDocSharedRealTimeNotification"
                                                   
                                        //            let notifysend = {
                                        //              wsoid: Number(this.workSpaceId),
                                        //              key: null,
                                        //              userEmailID:this.docsSelectedData[i].ownerEmail,
                                        //              role: null,
                                        //              groupName: null,
                                        //              message: msgBody,
                                        //              databaseName: "",
                                        //              createdBy: this.createdBy,
                                        //              Flag: "Modal",
                                        //              WsLink: ""
                                        //            }
                           
                                        //            if(localStorage.getItem('localloginId')!=this.docsSelectedData[i].ownerEmail)
                                        //            {
                                        //              this.signInService.notifysend(notifysend).subscribe((response: any) => {
                                        //                if (response.isSuccess) {
                                        //                  let respons = ["","",""]
                                        //                  respons[1]=msgBody
                                        //                  this.sharedService.transferData.next(respons);
                                        //                }
                                        //              })
                                        //            }
                                                   
                                        //      }
                                        //  }
                                         for(let i=0; i<this.docsSelectedData.length; i++)
                                         {
                                           if(!map.has(this.docsSelectedData[i].ownerEmail)){
                                                 map.set(this.docsSelectedData[i].ownerEmail,1);
                                           }
                                           else{
                                             map.set(this.docsSelectedData[i].ownerEmail,map.get(this.docsSelectedData[i].ownerEmail)+1);
                                           }
                                          
                                           
                                             if(!notifyEmailSent.includes(this.docsSelectedData[i].ownerEmail))
                                             {
                                                   notifyEmailSent.push(this.docsSelectedData[i].ownerEmail);
                                                   // let msgBody = '';
                                                 
                                                    msgBody=localStorage.getItem('noteuserfullname')+" ("+localStorage.getItem('localloginId')+") shared "+this.docsSelectedData[i].modifiedName+" with "+phonelist.length+" guests. SendingDocSharedRealTimeNotification"
                                 
                                                 }
                                                 let notifysend = {
                                                   wsoid: Number(this.workSpaceId),
                                                   key: null,
                                                   userEmailID:this.docsSelectedData[i].ownerEmail,
                                                   role: null,
                                                   groupName: null,
                                                   message: msgBody,
                                                   databaseName: "",
                                                   createdBy: this.createdBy,
                                                   Flag: "Modal",
                                                   WsLink: ""
                                                 }
                                 
                                                 if(localStorage.getItem('localloginId').toString().toLowerCase()!=this.docsSelectedData[i].ownerEmail.toLowerCase())
                                                 {
                                                   this.signInService.notifysend(notifysend).subscribe((response: any) => {
                                                     if (response.isSuccess) {
                                                       let respons = ["","",""]
                                                       respons[1]=msgBody
                                                       this.sharedService.transferData.next(respons);
                                                     }
                                                   })
                                                 }
                                               }
                                         }
                                         else {
                                          let msgBody = '';
                                          for(let i=0; i<this.docsSelectedData.length; i++)
                                          {
                                            if(!map.has(this.docsSelectedData[i].ownerEmail)){
                                                  map.set(this.docsSelectedData[i].ownerEmail,1);
                                            }
                                            else{
                                              map.set(this.docsSelectedData[i].ownerEmail,map.get(this.docsSelectedData[i].ownerEmail)+1);
                                            }
                                           
                            
                                            
                                              if(!notifyEmailSent.includes(this.docsSelectedData[i].ownerEmail))
                                              {
                                                    notifyEmailSent.push(this.docsSelectedData[i].ownerEmail);
                                                    // let msgBody = '';
                                                   
                                                      
                                                    
                                  
                                                  }
                                                }
                                          for(const [key,value] of map.entries()){
                                            msgBody=localStorage.getItem('noteuserfullname')+" ("+localStorage.getItem('localloginId')+") shared "+value+" documents with "+phonelist.length+" guests. SendingDocSharedRealTimeNotification"
                                          let notifysend = {
                                                wsoid: Number(this.workSpaceId),
                                                key: null,
                                                userEmailID:key,
                                                role: null,
                                                groupName: null,
                                                message: msgBody,
                                                databaseName: "",
                                                createdBy: this.createdBy,
                                                Flag: "Modal",
                                                WsLink: ""
                                              }
                            
                                              if(localStorage.getItem('localloginId').toString().toLowerCase()!=key.toLowerCase())
                                              {
                                                this.signInService.notifysend(notifysend).subscribe((response: any) => {
                                                  if (response.isSuccess) {
                                                    let respons = ["","",""]
                                                    respons[1]=msgBody
                                                    this.sharedService.transferData.next(respons);
                                                  }
                                                })
                                              }
                                          }
                                        }
                                         this.singleSkeleton=true;
                                          this.MultipleListEmailWhatsappWise=[]
                                         setTimeout(() => {
                                          this.getUpdatedListOfSharesForDoc()
                                         }, 4000);
                                        
                                         this.whatsappBtnSpinnner=false
                                         
                                     }else{
                                       this.toastrService.danger("Some Error Occured");
                                       this.whatsappBtnSpinnner=false
                                       }
                                       this.UpdateDeductedDcoinsDisplay()
                                     });
                           
                               }else{
                                this.whatsappBtnSpinnner=false
                                  this.shareBtnValid=false
                                  this.dCoinsInvalid = true;
                                  this.InsufficientDcoinsModalRef=this.dialogService.open(this.InsufficientDcoinsModal, { context: 'this is some additional data passed to dialog' });

                                 for(let i=0; i<this.whatsappNumberInputArr.length; i++)
                                 {
                                   if((i+1)*dcoinsReqPerMsg>this.availableDcoins) 
                                   {
                                       this.whatsappNumberInputArr[i].isValid=false
                                   }
                                 }

                                 

                               }
                              
                               
         })

          }
        })

     
  

    }

    })


    //Update D coins balance
    setTimeout(() => {

      this.data.subscriptionDetailsApiCall(subsDetailpostData).subscribe((response:any)=>{
       this.availableDcoins=response.data[0].totalDcoins
       localStorage.setItem('TotalDCoins',String(this.availableDcoins))
       this.cd.detectChanges()
       this.sharedService.transferData.next('refreshDcoinsData');
       //this.sharedService.transferData.next('reloadData');
       })

    }, 5000);


  }

  /**
   * Function to Handle Input Backspace Event
   */
  emailInputBackspace(event){
    let pattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validCheck=true;
    let invalidEmailError=false
    let duplicateEmailError=false
    this.emailErrorMsg=''

    //Remove Email
    if(event.keyCode==8){
      if(this.emailInputString=='' && this.emailInputArr.length>0 )
      {
        this.emailInputArr.splice(this.emailInputArr.length-1,1)
      }
    }

   

    //Check Valid Invalid Error
    for(let i=0; i<this.emailInputArr.length; i++)
    {
      if(!this.emailInputArr[i].isValid)
      {
            validCheck=false;
            if(!(this.emailInputArr[i].email.length<340) || !pattern.test(this.emailInputArr[i].email))
            {
              invalidEmailError=true;
              this.emailErrorMsg="That's an invalid email"
            }

            if(this.checkEmailPhoneAlreadyShared(this.emailInputArr[i].email))
            {
              duplicateEmailError=true
              this.emailErrorMsg="The doc(s) have already been shared with this guest"
            }

      }
      
    }

    if(invalidEmailError && duplicateEmailError)
    {
      this.emailErrorMsg="The entered email(s) are either invalid or already have the doc shared with them"
    }

    this.emailChipsAllValid=validCheck
    this.shareBtnValid=validCheck
    if(validCheck){
      this.emailErrorMsg=''
    }

  }

  /**
   * Function to Handle Input Tab Press for email section
   */
  emailInputKeyTab(event)
  {       
        // this.guestShareDirty=true

        let globalLength=0;
        for(let i=0; i<this.emailInputArr.length; i++)
        {
          globalLength+=this.emailInputArr[i].email.length;
        }
        globalLength+=this.emailInputString.length;

        if(globalLength>=3400 || this.emailInputString.length>=340)
        {
        
        }else{
          this.emailInputCheck()
        }
  }

 /**
  *Email Input KeyPress Event 
  */
  emailInputKeyPress(event)
  {   
      // this.guestShareDirty=true

      let globalLength=0;
      for(let i=0; i<this.emailInputArr.length; i++)
      {
        globalLength+=this.emailInputArr[i].email.length;
      }
      globalLength+=this.emailInputString.length;
      
      console.log("kypress event",event.keyCode)

      if(globalLength>=3400 || this.emailInputString.length>=340)
      {
        event.preventDefault()
      }else if(event.keyCode==13 || event.key=="," || event.keyCode==9 ||event.keyCode==32){
        event.preventDefault()
        this.emailInputCheck()
      }
  }

  /**
   *Paste Function For Email Input  
   */
  emailInputPaste(pastedData)
  {   
    // this.guestShareDirty=true
    
      document.getElementById('emailSectionInputContainerDivPhoneNumbers').style.display='none';
      this.emailInputString=pastedData
      let pattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailErrorMsg=''

      let globalLength=0;
      let chipsTextLength=0;
      for(let i=0; i<this.emailInputArr.length; i++)
      {
        chipsTextLength+=this.emailInputArr[i].email.length;
      }
      globalLength=this.emailInputString.length+chipsTextLength;
      

      if(globalLength>3400){
        let charactersLeft=3400-chipsTextLength
        this.emailInputString=this.emailInputString.substring(0,charactersLeft)
      }

      globalLength=this.emailInputString.length+chipsTextLength;
      
      let splitList=[]

      if(this.emailInputString.includes(","))
      {
        splitList=pastedData.toLowerCase().trim().replace(/(\r\n|\n|\r)/gm, " ")?.split(/[, " "]+/);
      }else{
        splitList=pastedData.toLowerCase().trim().replace(/(\r\n|\n|\r)/gm, " ")?.split(/[, " "]+/);
      }

      
      if(globalLength<3400 && splitList.length>0){
        let invalidEmailError=false
        let duplicateEmailError=false
        for(let i=0; i<splitList.length; i++)
        {
            let checkInAddedList=false
            //check in existing array list of multiple docs
            for(let j=0; j<this.emailInputArr.length; j++)
            {
                if(splitList[i].toLowerCase()==this.emailInputArr[j].email)
                {
                  checkInAddedList=true
                }
            }

          let obj={}
          if(splitList[i].length<=340 && pattern.test(splitList[i]) && !this.checkEmailPhoneAlreadyShared(splitList[i]) && !checkInAddedList )
          {
            obj={
              'isValid':true,
              'email':splitList[i]
            }
            this.shareBtnValid=true
          }else{
            obj={
              'isValid':false,
              'email':splitList[i]
            }
            this.emailChipsAllValid=false;
            this.shareBtnValid=false
            if(!(splitList[i].length<340) || !pattern.test(splitList[i]))
            {
              invalidEmailError=true;
              this.emailErrorMsg="That's an invalid email"
            }

            if(this.checkEmailPhoneAlreadyShared(splitList[i]) || checkInAddedList)
            {
              duplicateEmailError=true
              this.emailErrorMsg="The doc(s) have already been shared with this guest"
            }

          }
          this.emailInputArr.push(obj)
        }
            if(invalidEmailError && duplicateEmailError)
            {
              this.emailErrorMsg="The entered email(s) are either invalid or already have the doc shared with them"
            }
      }else{

      }
       
      this.emailInputString='';

       let myDiv = document.getElementById("emailSectionInputContainerDiv");
        myDiv.scrollLeft = myDiv.scrollWidth;
        setTimeout(()=>{
          myDiv.scrollLeft = myDiv.scrollWidth;
          this.emailInputString=''
          document.getElementById('emailSectionInputContainerDivPhoneNumbers').style.display='block';
        }, 400); 

        this.emailInputString='';
        this.cd.detectChanges();
        setTimeout(() => {
          this.emailInputString='';
          this.cd.detectChanges();
          document.getElementById('emailSectionInputContainerDivPhoneNumbers').style.display='block';
        }, 100);
        
  }
  /**
   * Function to Add Email Input Keyup Event
   */
  emailInputCheck(){
     let pattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     this.emailErrorMsg=''

      let splitList=this.emailInputString.split(",")
      let invalidEmailError=false
      let duplicateEmailError=false
        for(let i=0; i<splitList.length; i++)
        { 
          let checkInAddedList=false
          
          //check in existing array list of multiple docs
          for(let j=0; j<this.emailInputArr.length; j++)
          {
              if(splitList[i].toLowerCase()==this.emailInputArr[j].email)
              {
                checkInAddedList=true
              }
          }

          let obj={}
          if(splitList[i].length<340 && pattern.test(splitList[i]) && !this.checkEmailPhoneAlreadyShared(splitList[i]) && !checkInAddedList)
          {
            obj={
              'isValid':true,
              'email':splitList[i]
            }
            this.shareBtnValid=true
          }else{
            obj={
              'isValid':false,
              'email':splitList[i]
            }
            this.emailChipsAllValid=false;
            this.shareBtnValid=false;
            

            if(!(splitList[i].length<340) || !pattern.test(splitList[i]))
            {
              invalidEmailError=true;
              this.emailErrorMsg="That's an invalid email"
            }

            if(this.checkEmailPhoneAlreadyShared(splitList[i]) || checkInAddedList)
            {
              duplicateEmailError=true
              this.emailErrorMsg="The doc(s) have already been shared with this guest"
            }

          }
          this.emailInputArr.push(obj)

           if(invalidEmailError && duplicateEmailError)
            {
              this.emailErrorMsg="The entered email(s) are either invalid or already have the doc shared with them"
            }

        }
       
       this.emailInputString=''

       let myDiv = document.getElementById("emailSectionInputContainerDiv");
        myDiv.scrollLeft = myDiv.scrollWidth;
        setTimeout(()=> {

          myDiv.scrollLeft = myDiv.scrollWidth;
          let validCheck=true;
          for(let i=0; i<this.emailInputArr.length; i++)
          {
            if(!this.emailInputArr[i].isValid)
            {
              validCheck=false;
            }
          }
          this.emailChipsAllValid=validCheck
          this.shareBtnValid=validCheck

        }, 400); 
 
  }
  /*Function to Remove Email From Chips List of Email*/
  RemoveEmailFromChips(email,idx){
    let pattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validCheck=true;
    let invalidEmailError=false
    let duplicateEmailError=false
    this.emailErrorMsg=''

    this.emailInputArr.splice(idx,1)
    //Remove Email
    // for(let i=0; i<this.emailInputArr.length; i++)
    // {
    //   if(this.emailInputArr[i].email.toLowerCase()==email.toLowerCase())
    //   {
    //     this.emailInputArr.splice(i,1)
    //   }
    // }

    this.emailErrorMsg=''
    //Check Valid Invalid
    for(let i=0; i<this.emailInputArr.length; i++)
    {
      if(!this.emailInputArr[i].isValid)
      {
            validCheck=false;
            if(!(this.emailInputArr[i].email.length<=340) || !pattern.test(this.emailInputArr[i].email))
            {
              invalidEmailError=true;
              this.emailErrorMsg="That's an invalid email"
            }

            if(this.checkEmailPhoneAlreadyShared(this.emailInputArr[i].email))
            {
              duplicateEmailError=true
              this.emailErrorMsg="The doc(s) have already been shared with this guest"
            }

      }
      
    }

    if(invalidEmailError && duplicateEmailError)
    {
     this.emailErrorMsg="The entered email(s) are either invalid or already have the doc shared with them"
    }

    
    this.emailChipsAllValid=validCheck
    this.shareBtnValid=validCheck
    if(validCheck){
      this.emailErrorMsg=''
    }
    for(let i=0; i<this.emailInputArr.length; i++)
    {
      if((this.emailInputArr[i].isValid == false)) {
          invalidEmailError=true;
          this.emailErrorMsg="The entered email(s) are either invalid or already have the doc shared with them"
      }
      else {
        invalidEmailError=false;
      }
    }
  }

  /**
   * Add Zero Before Number
   */
  AddZero(val)
  {
      if(val<10)
      {
        return ("0"+val)
      }else{
        return val
      }
  }
  
  /**
   * Create Infinite Date
   */
  createInfiniteDate()
  {
    let dt=new Date(new Date().getTime()+99999*24*60*60*1000)
    let dtStr=''
    dtStr=""+dt.getFullYear()+"-"+this.AddZero(dt.getMonth()+1)+"-"+this.AddZero(dt.getDate())+"T"+this.AddZero(dt.getHours())+":"+this.AddZero(dt.getMinutes())+":00";
    return dtStr
  }

  /**
   * Function to call API on Email Share Click Functionality
   */
  EmailShareButtonClickFunction(){
    this.isExpiryEdit = false;
    this.emailBtnSpinnner=true
    this.shareBtnValid=false
    this.guestShareDirty = true;
    
    let st = window.location.toString();
    let arr = st?.split("/");
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    let emaillist=[]
    for(let i=0; i<this.emailInputArr.length; i++)
    {
      if(this.emailInputArr[i].isValid){
        emaillist.push(this.emailInputArr[i].email)
      }
    }

    let Date=''
    let Month=''
    if((this.expirationModalCalendardate.getMonth()+1)<10)
    {
      Month="0"+(this.expirationModalCalendardate.getMonth()+1)
    }else{
      Month=""+(this.expirationModalCalendardate.getMonth()+1)+""
    }

    if(this.expirationModalCalendardate.getDate()<10)
    {
      Date="0"+this.expirationModalCalendardate.getDate()
    }else{
      Date=""+this.expirationModalCalendardate.getDate()+""
    }


    let formatedDate=this.expirationModalCalendardate.getFullYear()+"-"+Month+"-"+Date+"T"
      
      if(this.expirationDateModalAMPM=='PM' && this.expirationDateModalHour!=12){
        let hour=this.expirationDateModalHour+12
        let min=''
        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }
        formatedDate+=hour+":"+min+":"+"00"
      }else{
        let hour=''
        let min=''
        
        if(this.expirationDateModalHour<10)
        {
          hour="0"+this.expirationDateModalHour
        }else{
          hour=""+this.expirationDateModalHour+""
        }

        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }

        formatedDate+=hour+":"+min+":"+'00'
      }
      
      this.expirationModalCaledardateFormatted=formatedDate
    
      let expirationInfiniteCheck=0;
      if(this.expirationDateInfiniteDuration){
        expirationInfiniteCheck=1
        this.expirationModalCaledardateFormatted=this.createInfiniteDate()
      }

      let otpMandatoryCheck=0
      if(this.advancedSettingsOtpMandatory){
        otpMandatoryCheck=1
      }

      let DownloadMandatoryCheck=0
      if(this.advancedSettingsDownloadMandatory){
        DownloadMandatoryCheck=1
      }

    let postData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.docGuidArr.join('|'),
      "guestEmailidList": emaillist.join('|'),
      "expiryDurationList":this.expirationModalCaledardateFormatted,
      "isDeleteGuestEmailIDList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "infiniteStatus": ""+expirationInfiniteCheck+"",
      "isotp":""+otpMandatoryCheck+"",
      "downloadPrintRights":""+DownloadMandatoryCheck+"",
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    this.data.emailWiseGuestShare(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        this.emailBtnSpinnner=false
        this.getUpdatedListOfSharesForDoc()
        this.toastrService.primary("Document(s) shared with guest(s)");
        this.emailInputArr=[]
        this.shareBtnValid=false
        
        let notifyEmailSent=[]
        const map = new Map();
        //Logic to Send Real Time Notify
        if(this.docsSelectedData.length == 1){
          let msgBody = '';
        for(let i=0; i<this.docsSelectedData.length; i++)
        {
          if(!map.has(this.docsSelectedData[i].ownerEmail)){
                map.set(this.docsSelectedData[i].ownerEmail,1);
          }
          else{
            map.set(this.docsSelectedData[i].ownerEmail,map.get(this.docsSelectedData[i].ownerEmail)+1);
          }
         
          
            if(!notifyEmailSent.includes(this.docsSelectedData[i].ownerEmail))
            {
                  notifyEmailSent.push(this.docsSelectedData[i].ownerEmail);
                  // let msgBody = '';
                
                   msgBody=localStorage.getItem('noteuserfullname')+" ("+localStorage.getItem('localloginId')+") shared "+this.docsSelectedData[i].modifiedName+" with "+emaillist.length+" guests. SendingDocSharedRealTimeNotification";

                }
                let notifysend = {
                  wsoid: Number(this.workSpaceId),
                  key: null,
                  userEmailID:this.docsSelectedData[i].ownerEmail,
                  role: null,
                  groupName: null,
                  message: msgBody,
                  databaseName: "",
                  createdBy: this.createdBy,
                  Flag: "Modal",
                  WsLink: ""
                }

                if(localStorage.getItem('localloginId').toString().toLowerCase()!=this.docsSelectedData[i].ownerEmail.toLowerCase())
                {
                  this.signInService.notifysend(notifysend).subscribe((response: any) => {
                    if (response.isSuccess) {
                      let respons = ["","",""]
                      respons[1]=msgBody
                      this.sharedService.transferData.next(respons);
                    }
                  })
                }
              }
            }
            else {
              let msgBody = '';
              for(let i=0; i<this.docsSelectedData.length; i++)
              {
                if(!map.has(this.docsSelectedData[i].ownerEmail)){
                      map.set(this.docsSelectedData[i].ownerEmail,1);
                }
                else{
                  map.set(this.docsSelectedData[i].ownerEmail,map.get(this.docsSelectedData[i].ownerEmail)+1);
                }
               

                
                  if(!notifyEmailSent.includes(this.docsSelectedData[i].ownerEmail))
                  {
                        notifyEmailSent.push(this.docsSelectedData[i].ownerEmail);
                        // let msgBody = '';
                       
                          
                        
      
                      }
                    }
              for(const [key,value] of map.entries()){
                msgBody=localStorage.getItem('noteuserfullname')+" ("+localStorage.getItem('localloginId')+") shared "+value+" documents with "+emaillist.length+" guests. SendingDocSharedRealTimeNotification"
              let notifysend = {
                    wsoid: Number(this.workSpaceId),
                    key: null,
                    userEmailID:key,
                    role: null,
                    groupName: null,
                    message: msgBody,
                    databaseName: "",
                    createdBy: this.createdBy,
                    Flag: "Modal",
                    WsLink: ""
                  }

                  if(localStorage.getItem('localloginId').toString().toLowerCase()!=key.toLowerCase())
                  {
                    this.signInService.notifysend(notifysend).subscribe((response: any) => {
                      if (response.isSuccess) {
                        let respons = ["","",""]
                        respons[1]=msgBody
                        this.sharedService.transferData.next(respons);
                      }
                    })
                  }
              }
            }


        // this.sharedService.transferData.next('refreshDcoinsData');

        
      }else{
        this.toastrService.danger("Some Error Occured");
        this.emailBtnSpinnner=false
      }
      this.UpdateDeductedDcoinsDisplay()
      
      
    });

  }

  /**
   *Notify Send For Sending Real Time Notification
   */
  notifysendShare() {
  
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID:this.docsSelectedData[0].ownerEmail,
      role: null,
      groupName: null,
      message: "Document is shared with Guest",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Modal",
      WsLink: ""
    }

    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        
      }
    })
  }

   /**
   *Set Advanced Settings as per Document Date 
   */
   setAdvancedSettingToModify(type,otpRight,downloadRight){
    this.threeDotTrigger = true;
    if(type=='single' && otpRight!='' && downloadRight!='')
    {
        if(otpRight=='1')
        {
          this.tempadvancedSettingsOtpMandatory=true
        }else if(otpRight=='0'){
          this.tempadvancedSettingsOtpMandatory=false
        }

        if(downloadRight=='True')
        {
          this.tempadvancedSettingsDownloadMandatory=true
        }else if(downloadRight=='False'){
          this.tempadvancedSettingsDownloadMandatory=false
        }
      

    }else if(type=='multiple'){

      if(this.generalOtp) {
        this.tempadvancedSettingsOtpMandatory = true;  
      }
      
      if(this.generalDownloadPrint) {
        this.tempadvancedSettingsDownloadMandatory = true;  
      }
            
    }

 }

 /**
  * Infinite Box Unchecked 
  */
 InfiniteBoxUnchecked(event)
 {
     if(!this.threeDotTriggerExp) {
      this.tempexpirationDateInfiniteDurationNew = event;
     }
      this.tempexpirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
                  
      this.tempexpirationDateModalHour=new Date().getHours()

      this.tempexpirationDateModalMin=new Date().getMinutes()

      if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
        this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
      }
      else if(this.tempexpirationDateModalMin==0) {
        this.tempexpirationDateModalMin = "00"
      }
      else {
        this.tempexpirationDateModalMin
      }


      if(this.tempexpirationDateModalHour>11){
        this.tempexpirationDateModalAMPM='PM'
      }

      if(this.tempexpirationDateModalHour>12){
        this.tempexpirationDateModalHour=this.tempexpirationDateModalHour-12;
      }
 }

  /**
   *Set Expiry Date as per Document Date 
   */
   setExpiryDateToModify(type,expiryDtm){
      this.threeDotTriggerExp = true;
      if(type=='single' && expiryDtm!='' && expiryDtm.includes('/'))
      {
          this.tempexpirationModalCalendardate=new Date(expiryDtm)
          
          this.tempexpirationDateModalHour=this.tempexpirationModalCalendardate.getHours()
    
          this.tempexpirationDateModalMin= this.tempexpirationModalCalendardate.getMinutes()
      
          if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
            this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
          }
          else if(this.tempexpirationDateModalMin==0) {
            this.tempexpirationDateModalMin = "00"
          }
          else {
            this.tempexpirationDateModalMin
          }

          if(this.tempexpirationDateModalHour>11){
            this.tempexpirationDateModalAMPM='PM'
          }
          
          if(this.tempexpirationDateModalHour>12){
            this.tempexpirationDateModalHour=this.tempexpirationDateModalHour-12;
          }

          let splitData=expiryDtm.split(" ")
          let year=splitData[0].split('/')[2]
          console.log('year',year)

          if(Number(year)>2100)
          {
            this.tempexpirationDateInfiniteDuration=true
            console.log('year infinite')
          }


          

      }else if(type=='multiple'){

              this.tempexpirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
              
              this.tempexpirationDateModalHour=new Date().getHours()

              this.tempexpirationDateModalMin=new Date().getMinutes()

              if(this.tempexpirationDateModalHour>11){
                this.tempexpirationDateModalAMPM='PM'
              }
          
              if(this.tempexpirationDateModalHour>12){
                this.tempexpirationDateModalHour=this.tempexpirationDateModalHour-12;
              }
              
              if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
                this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
              }
              else if(this.tempexpirationDateModalMin==0) {
                this.tempexpirationDateModalMin = "00"
              }
              else {
                this.tempexpirationDateModalMin
              }
              
      }

   }

  /**
   *Set Modification Options 
  */
  setModificationOptions(type,emailPhone,guid)
  {  
    this.modifyType=type
    this.emailInputArrModifyList=emailPhone

    //Logic For Email/Phone List
    this.emailInputArrModifyList=emailPhone
    this.DocGuidEmailWiseModifyList=[]
    this.DocGuidPhoneWiseModifyList=[]

      if(type=='single'){
        if(emailPhone.includes('@'))
        {
          this.DocGuidEmailWiseModifyList.push(guid)
        }else{
          this.DocGuidPhoneWiseModifyList.push(guid)
        }
      }else if(type=='multiple'){

          for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
          { 
              if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
              {

                for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
                {
                  if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked)
                  {
                    if(emailPhone.includes('@'))
                    {
                      this.DocGuidEmailWiseModifyList.push(this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid)
                    }else{
                      this.DocGuidPhoneWiseModifyList.push(this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid)
                    }
                    
                  }
                  
                }
                
              }
            
          }

      }

  }

  /**
   *Email ExpiryDate Modification Functionality 
   */
  EmailModificationExpiryDateFunctionality(){
   
    let st = window.location.toString();
    let arr = st?.split("/");
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    
    //Logic For Date Format Handling
    let Date=''
    let Month=''
    if((this.expirationModalCalendardate.getMonth()+1)<10)
    {
      Month="0"+(this.expirationModalCalendardate.getMonth()+1)
    }else{
      Month=""+(this.expirationModalCalendardate.getMonth()+1)+""
    }

    if(this.expirationModalCalendardate.getDate()<10)
    {
      Date="0"+this.expirationModalCalendardate.getDate()
    }else{
      Date=""+this.expirationModalCalendardate.getDate()+""
    }

    console.log("Date:"+Date+"Month:"+Month)

    let formatedDate=this.expirationModalCalendardate.getFullYear()+"-"+Month+"-"+Date+"T"
      
      if(this.expirationDateModalAMPM=='PM' && this.expirationDateModalHour!=12){
        let hour=this.expirationDateModalHour+12
        formatedDate+=hour+":"+this.expirationDateModalMin+":"+"00"
      }else{
        let hour=''
        let min=''
        
        if(this.expirationDateModalHour<10)
        {
          hour="0"+this.expirationDateModalHour
        }else{
          hour=""+this.expirationDateModalHour+""
        }

        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }

        formatedDate+=hour+":"+min+":"+'00'
      }

      
      this.expirationModalCaledardateFormatted=formatedDate
    
      let expirationInfiniteCheck=0;
      if(this.expirationDateInfiniteDuration){
        expirationInfiniteCheck=1
        this.expirationModalCaledardateFormatted=this.createInfiniteDate()
      }

      let otpMandatoryCheck=0
      if(this.advancedSettingsOtpMandatory){
        otpMandatoryCheck=1
      }

      let DownloadMandatoryCheck=0
      if(this.advancedSettingsDownloadMandatory){
        DownloadMandatoryCheck=1
      }

    let emailPostData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.DocGuidEmailWiseModifyList.join('|'),
      "guestEmailidList": this.emailInputArrModifyList,
      "expiryDurationList":this.expirationModalCaledardateFormatted,
      "isDeleteGuestEmailIDList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "infiniteStatus": ""+expirationInfiniteCheck+"",
      "isotp":"",
      "downloadPrintRights":"",
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    let phonePostData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.DocGuidPhoneWiseModifyList.join('|'),
      "guestWhatsAppNumberList": this.emailInputArrModifyList,
      "expiryDurationList": this.expirationModalCaledardateFormatted,
      "isDeleteGuestWhatsAppNumberList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "messageID": 0,
      "messageStatus": 0,
      "isMobileNumberValid": 1,
      "countryCode": this.countryDialCode,
      "infiniteStatus": expirationInfiniteCheck,
      "isotp":0,
      "downloadPrintRights":0,
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    console.log("this.emailInputArrModifyList",this.emailInputArrModifyList)
    console.log("phonePostData",phonePostData)

    if(this.emailInputArrModifyList.includes('@'))
    {
        this.data.emailWiseGuestShare(emailPostData).subscribe((response: any) => {
          this.UpdateDeductedDcoinsDisplay()
          if (response.isSuccess) {
            this.getUpdatedListOfSharesForDoc()
            this.toastrService.primary("Document(s) shared with guest(s)");
            this.emailInputArr=[]
            this.shareBtnValid=false
          }
          this.emailInputArrModifyList=''
          this.DocGuidEmailWiseModifyList=[]
          this.DocGuidPhoneWiseModifyList=[]
          
        });
    }else{
        this.data.phoneNumberWiseGuestShare(phonePostData).subscribe((response: any) => {
          this.UpdateDeductedDcoinsDisplay()
          if (response.isSuccess) {
            console.log('inside phone expiry update')
            this.getUpdatedListOfSharesForDoc()
            this.toastrService.primary("Document(s) shared with guest(s)");
            this.emailInputArr=[]
            this.shareBtnValid=false
          }
          this.emailInputArrModifyList=''
          this.DocGuidEmailWiseModifyList=[]
          this.DocGuidPhoneWiseModifyList=[]
          
        });
    }
    

  }


  /**
   *Email Advanced Settings Functionality 
   */
   EmailModificationAdvancedSettingFunctionality(){
    let st = window.location.toString();
    let arr = st?.split("/");
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    
    //Logic For Date Format Handling
    let Date=''
    let Month=''
    if((this.expirationModalCalendardate.getMonth()+1)<10)
    {
      Month="0"+(this.expirationModalCalendardate.getMonth()+1)
    }else{
      Month=""+(this.expirationModalCalendardate.getMonth()+1)+""
    }

    if(this.expirationModalCalendardate.getDate()<10)
    {
      Date="0"+this.expirationModalCalendardate.getDate()
    }else{
      Date=""+this.expirationModalCalendardate.getDate()+""
    }

    let formatedDate=this.expirationModalCalendardate.getFullYear()+"-"+Month+"-"+Date+"T"
      
      if(this.expirationDateModalAMPM=='PM' && this.expirationDateModalHour!=12){
        let hour=this.expirationDateModalHour+12
        formatedDate+=hour+":"+this.expirationDateModalMin+":"+"00"
      }else{
        let hour=''
        let min=''
        
        if(this.expirationDateModalHour<10)
        {
          hour="0"+this.expirationDateModalHour
        }else{
          hour=""+this.expirationDateModalHour+""
        }

        if(this.expirationDateModalMin<10)
        {
          min=""+this.expirationDateModalMin
        }else{
          min=""+this.expirationDateModalMin+""
        }

        formatedDate+=hour+":"+min+":"+'00'
      }
      
      this.expirationModalCaledardateFormatted=formatedDate
    
      let expirationInfiniteCheck=0;
      if(this.expirationDateInfiniteDuration){
        expirationInfiniteCheck=1
      }

      let otpMandatoryCheck=0
      if(this.advancedSettingsOtpMandatory){
        otpMandatoryCheck=1
      }

      let DownloadMandatoryCheck=0
      if(this.advancedSettingsDownloadMandatory){
        DownloadMandatoryCheck=1
      }

    let emailPostData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.DocGuidEmailWiseModifyList.join('|'),
      "guestEmailidList": this.emailInputArrModifyList,
      "expiryDurationList":"",
      "isDeleteGuestEmailIDList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "infiniteStatus": ""+expirationInfiniteCheck+"",
      "isotp":""+otpMandatoryCheck+"",
      "downloadPrintRights":""+DownloadMandatoryCheck+"",
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    let phonePostData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.DocGuidPhoneWiseModifyList.join('|'),
      "guestWhatsAppNumberList": this.emailInputArrModifyList,
      "expiryDurationList": "",
      "isDeleteGuestWhatsAppNumberList": this.deletedMultiListData.join('|'),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      "timeZone": this.timeZone,
      "messageID": 0,
      "messageStatus": 0,
      "isMobileNumberValid": 1,
      "countryCode": this.countryDialCode,
      "infiniteStatus": Number(expirationInfiniteCheck),
      "isotp":Number(otpMandatoryCheck),
      "downloadPrintRights":Number(DownloadMandatoryCheck),
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }

    if(this.emailInputArrModifyList.includes('@'))
    {
        this.data.emailWiseGuestShare(emailPostData).subscribe((response: any) => {
          this.UpdateDeductedDcoinsDisplay()
          if (response.isSuccess) {
            this.getUpdatedListOfSharesForDoc()
            this.toastrService.primary("Guest Access Settings Modified");
            this.emailInputArr=[]
            this.shareBtnValid=false
          }
          this.emailInputArrModifyList=''
          this.DocGuidEmailWiseModifyList=[]
          this.DocGuidPhoneWiseModifyList=[]
          
        });
    }else{
        this.data.phoneNumberWiseGuestShare(phonePostData).subscribe((response: any) => {
          this.UpdateDeductedDcoinsDisplay()
          if (response.isSuccess) {
            this.getUpdatedListOfSharesForDoc()
            this.toastrService.primary("Guest Access Settings Modified");
            this.emailInputArr=[]
            this.shareBtnValid=false
          }
          this.emailInputArrModifyList=''
          this.DocGuidEmailWiseModifyList=[]
          this.DocGuidPhoneWiseModifyList=[]
          
        });
    }
    

  }

  /**
   * Delete Guest Access Single Doc
   */
  RemoveGuestAccessSingleDoc(type,guid,emailPhone)
  {
    this.guestShareDirty=true;
    
    let guidEmail=''
    if(type=='RemoveAll'){
        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        { 
          for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
          {
            if(guidEmail=='')
            {
              guidEmail+=this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
            }else{
              guidEmail+=","+this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
            }
            
          }
          
        }
    }else if(type=='single'){
      guidEmail=guid+"|"+emailPhone
    }else if(type=='multiple'){

        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        { 
            if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
            {

              for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
              {
                if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked)
                {
                  if(guidEmail=='')
                  {
                    guidEmail+=this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                  }else{
                    guidEmail+=","+this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                  }
                }
                
              }
              
            }
          
        }

    }else if(type=='multipleAll'){

      for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
      { 
          if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
          {

            for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
            {
              
                if(guidEmail=='')
                {
                  guidEmail+=this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                }else{
                  guidEmail+=","+this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                }
              
              
            }
            
          }
        
      }

  }

    let postData={
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "docGuidsWhatsAppEmails": guidEmail,
      "flag": 1
    }

    this.data.RemoveGuestShareAccess(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        this.getUpdatedListOfSharesForDoc()
        this.toastrService.primary("Guest Access updated");
        this.UpdateDeductedDcoinsDisplay()
        
      }
      
    });

  }

  /**
   *Remove Button Click on Remove Modal 
   */
  RemoveButtonClickInRemoveModal(){
    this.RemoveGuestAccessSingleDoc(this.removeType,this.DocGuidRemoveList,this.emailInputRemoveList)
    this.RemoveGuestAccessWarnModalRef.close()
  }
  /**
   *Cancel Button Click on Remove Modal 
   */
   CancelButtonClickInRemoveModal(){
    this.RemoveGuestAccessWarnModalRef.close()
   }
  /**
  *Function to Remove Guest Access Parameters in variables
  */
  RemoveGuestAccess(showhide,type,guid,emailPhone){
    this.guestShareDirty=true;

    this.removeGuestModalText=''
    this.removeType='';
    this.emailInputRemoveList='';
    this.DocGuidRemoveList='';
    if(type!='')
    {
    this.removeType=type;
    this.emailInputRemoveList=emailPhone;
    this.DocGuidRemoveList=guid;
    }

    if(type=="RemoveAll"){
      this.removeGuestModalText="Are you sure you want to revoke the access for all guests invited to this document?"
    }else{
      this.removeGuestModalText="Are you sure you want to revoke "+emailPhone+"'s guest access for the selected document(s)?"
    }

    if(showhide=='show'){
      this.RemoveGuestAccessWarnModalRef=this.dialogService.open(this.RemoveGuestAccessWarnModal, { context: 'this is some additional data passed to dialog' });
    }else if(showhide=='hide'){
      this.RemoveGuestAccessWarnModalRef.close()
    }

  }

  /**
   *Function to Delete Documents From List of Users
   */
  deleteDocFromMultiList(email){
    this.deletedMultiListData.push(email)
  }
  
  /**
   * Function logic to resend documents for multple list docs selection
   */
  resendDocumentsFunction(type,emailPhone,guid)
  {  
    let guidEmail=''
    if(type=='single'){
      guidEmail=guid+"|"+emailPhone
    }else if(type=='multiple'){

        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        { 
            if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
            {

              for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
              {
                if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].checked)
                {
                  if(guidEmail=='')
                  {
                    guidEmail+=this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                  }else{
                    guidEmail+=","+this.MultipleListEmailWhatsappWise[i].detailsArr[j].docGuid+"|"+this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail
                  }
                }
                
              }
              
            }
          
        }

    }

    let st = window.location.toString();
    let arr = st?.split("/");

    
    let postData={
      "wsoid":Number(this.workSpaceId),
      "docGuidsWhatsAppEmails": guidEmail,
      "createWS": "",
      "wsLink": arr[2],
      "expirydtm": "",
      "shareGUID": "",
      "wsName":localStorage.getItem('WorkSpaceName'),
      "ownerName": "",
      "shareBy": "",
      "timeZone": "",
      "ownerEmail": "",
      "countryCode": "",
      "docGuid": "",
      "isotp": ""

    }
    
    //Check If Dcoins balance is enough to send message
    if(!emailPhone.includes('@') && emailPhone!=''){

          let dcoinsPayload={
            "wsoid": Number(this.workSpaceId),
            "itemCount": 1
          }        
              this.data.checkIfDcoinsAvailable(dcoinsPayload).subscribe((response:any)=>{
                if(response.isSuccess){
                  
                  this.data.ResendDocumentGuestShare(postData).subscribe((response: any) => {
                                this.UpdateDeductedDcoinsDisplay()
                                if (response.isSuccess) {
                                  this.toastrService.primary("Document(s) shared with guest(s)");
                              }else{
                                this.toastrService.danger("Some Error Occured");
                                }
                                
                              });
                }else{
                  this.InsufficientDcoinsModalRef=this.dialogService.open(this.InsufficientDcoinsModal, { context: 'this is some additional data passed to dialog' });
                  this.UpdateDeductedDcoinsDisplay()
                }
          })

    }else{
         if(emailPhone!='')
         {
            this.data.ResendDocumentGuestShare(postData).subscribe((response: any) => {
              this.UpdateDeductedDcoinsDisplay()
              if (response.isSuccess) {
                this.toastrService.primary("Document(s) shared with guest(s)");
            }else{
              this.toastrService.danger("Some Error Occured");
              this.UpdateDeductedDcoinsDisplay()
              }
              
            });
         }
          
    }


  }

  
  /**
   * Resend Button Click Function
   */
  ResendButtonClickFunction(type){
    if(type=='show'){
      document.getElementById('InsufficientDcoinsModal').style.display='block';
    }else{
    document.getElementById('InsufficientDcoinsModal').style.display='none';
    }

    let wsLinkst = window.location.toString();
    let arr = wsLinkst?.split("/");

    let postData={
      "WSLink":arr[2],
      "Wsoid": Number(this.workSpaceId),
    }

    this.data.guestUserRights(postData).subscribe((response:any)=>{
      if(response.isSuccess){
        if(response.data.totalDcoins){
          this.availableDcoins=response.data[0].totalDcoins
        }
      }
    })


  }

  /**
   * Purchase Dcoins Functions
  */
  PurchaseDcoins(){
    document.getElementById('InsufficientDcoinsModal').style.display='none';
  }

  /**
   * Function to make Api Call For Free D coins
   */
  GetFreeDcoins(){
    let st = window.location.toString();
    let arr = st?.split("/");
    let postData={
      "WSLink":arr[2],
      "Wsoid": Number(this.workSpaceId),
    }

    this.data.guestUserRights(postData).subscribe((response:any)=>{
      if(response.isSuccess){
        if(response.data[0].totalDcoins){
          this.availableDcoins=response.data[0].totalDcoins
        }
      }
    })

  }
  /**
   * Function logic to change advanced settings for multple list docs selection
   */
   advancedSettingChangeForMultiDocs(){
    document.getElementById('advancedSettingsOtpAndDownloadPrint').style.display='block';
   }

   /**
   * Function logic to change Expiration Date for multple list docs selection
   */
   expirationDateChangeForMultiDocs(){
    document.getElementById('ExpirationDateModal').style.display='block';
   }

   /*Open Expiration Date Modal*/
   openExpirationDateModel(type){
      if(type=='show')
      {
        document.getElementById('ExpirationDateModal').style.display='block';
      }else if(type=='hide'){
        document.getElementById('ExpirationDateModal').style.display='none';
      }
    
   }

   /**
   * Advanced Setting Otp change Event
   */
   advancedSettingOtpInputChange(event,type?){
   
    this.tempadvancedSettingsOtpMandatory=event
    if(type == "static" && !this.threeDotTrigger){
      this.tempadvancedSettingsOtpMandatoryNew = this.tempadvancedSettingsOtpMandatory;
    }
   }

   /**
   * Advanced Setting Download Print change Event
   */
   advancedSettingDownloadPrintChange(event,type?){
    
    this.tempadvancedSettingsDownloadMandatory=event;
    if(type == "static" && !this.threeDotTrigger){
      this.tempadvancedSettingsDownloadMandatoryNew = this.tempadvancedSettingsDownloadMandatory;
    }
   }
   

  /**
   * Expiration Date Date Time Change Function
   */
  ExpirationDateTimeChange(name,type){

    if(this.tempexpirationError!='')
    {
      this.tempexpirationError=''
    }

    if(!this.tempexpirationDateInfiniteDuration)
    {

          if(name=='Hour')
          { 
              if(type=='Increment')
              {
                if(!(this.tempexpirationDateModalHour>=12)){
                  this.tempexpirationDateModalHour++
                }
                
              }else{
                if(!(this.tempexpirationDateModalHour<=0)){
                  this.tempexpirationDateModalHour--
                }
                
              }
            
              
          }else if(name=='Min')
          {
              if(type=='Increment')
              {
                if(!(this.tempexpirationDateModalMin>=59)){
                  this.tempexpirationDateModalMin++
                }
              }else{
                if(!(this.tempexpirationDateModalMin<=0)){
                  this.tempexpirationDateModalMin--
                }
                
              }
            if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
              this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
            }
            else if(this.tempexpirationDateModalMin==0) {
              this.tempexpirationDateModalMin = "00"
            }
            else {
              this.tempexpirationDateModalMin
            }
          }else if(name=='AMPM'){
            if(this.tempexpirationDateModalAMPM=='AM')
            {
              this.tempexpirationDateModalAMPM='PM'
            }else{
              this.tempexpirationDateModalAMPM='AM'
            }
          }

    }
    

  }
  /**
   * Expiration Date Changed from Calendar event
   */
   ExpirationDateChange(event){
    if(this.tempexpirationError!='')
    {
      this.tempexpirationError=''
    }

      if(new Date().getTime()<new Date(event).getTime())
      {
        this.tempexpirationModalCalendardate=new Date(event)
      }
      if(!this.threeDotTriggerExp) {
        this.tempexpirationModalCalendardateNew = this.tempexpirationModalCalendardate;
        this.tempexpirationDateInfiniteDuration = this.tempexpirationDateInfiniteDurationNew;
      }
   }
   /**
    * Expiration date hour Input
    */
   expirationDateHourInput(event)
   { 
        if(this.tempexpirationError!='')
        {
          this.tempexpirationError=''
        }
        
        if(!(event.key==0 || event.key==1 || event.key==2 || event.key==3 || event.key==4 || event.key==5 || event.key==6 ||event.key==7 || event.key==8 || event.key==9) || event.key==''){
          event.preventDefault()
        }

        if(this.tempexpirationDateInfiniteDuration)
        {
          event.preventDefault()
        }
    
   }

   expirationDateHourInputKeyup()
   {
        if(this.tempexpirationError!='')
        {
          this.tempexpirationError=''
        }

        if(!this.tempexpirationDateInfiniteDuration){
            if(this.tempexpirationDateModalHour>12)
            {
              this.tempexpirationDateModalHour=12
            }else if(this.tempexpirationDateModalHour<0)
            {
              this.tempexpirationDateModalHour=0
            }
      }
   }

   /**
    * Expiration date minute Input
    */
   expirationDateMinInput(event)
   {
      if(this.tempexpirationError!='')
      {
        this.tempexpirationError=''
      }

      if(!(event.key==0 || event.key==1 || event.key==2 || event.key==3 || event.key==4 || event.key==5 || event.key==6 ||event.key==7 || event.key==8 || event.key==9) || event.key==''){
        event.preventDefault()
      }

      if(this.tempexpirationDateInfiniteDuration)
      {
         event.preventDefault()
      }
   }

   expirationDateMinInputKeyup()
   {
      if(this.tempexpirationError!='')
      {
        this.tempexpirationError=''
      }

      if(!this.tempexpirationDateInfiniteDuration){
          if(this.tempexpirationDateModalMin>59)
          {
            this.tempexpirationDateModalMin=59
          }else if(this.expirationDateModalMin<0)
          {
            this.tempexpirationDateModalMin=0
          }
      }
   }

   /**
    * Show Tooltip Text
    */

   showTooltipText(type) {
    if(type=='show'){
      document.getElementById('tooltiptext4').style.display='block';
      let postData={
        "docGuid": this.doxEvent?.rowData?.docGuid || this.doxEvent?.docGuid,
      }
      this.data.DocDetailsEmailandnumberList(postData).subscribe((response: any) => {
  
        if (response.isSuccess) {
       
          let responseData = response.data;
          console.log("responseData",responseData)
          this.sharedByUserName = response.data[0].sharedBy;
          this.otpStatus = response.data[0].otpStatus;
          this.userRightsCheck = response.data[0].printDownloadrights
          
        }
      });   
    }else if(type=='hide'){
      setTimeout(() => {
        document.getElementById('tooltiptext4').style.display='none';
      }, 5000);
    } 

   }

   /**
    * Show three dot modal
    */

   openThreeDotModal(type) {
    if(type=='show'){
      document.getElementById('threeDotTooltipText').style.display='block';
    }

    else if(type=='hide'){
      setTimeout(() => {
        document.getElementById('threeDotTooltipText').style.display='none';
      }, 8000);
   }
  }

  /**
   * Toggle Accordion Muliple Docs Shared List
    */
  ToggleAccordionMultiList(id){

    for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++){
      if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail!=id){
        this.MultipleListEmailWhatsappWise[i].show=false
      }
    }
    
    for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++){
      if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==id){
        this.MultipleListEmailWhatsappWise[i].show=!this.MultipleListEmailWhatsappWise[i].show
      }
    }
  
  }
  /**
   * Function to show or hide status of guest list tooltip menu dropdown
    */
  ToggleTooltipTextStatus(emailPhone,docName,type){
   
    for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
    {

      if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
      {
        for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++){

          if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].documentName==docName){
            if(type=='show')
            {
              this.MultipleListEmailWhatsappWise[i].detailsArr[j].showIconTooltip=true;
            }else if(type=='hide'){
              this.MultipleListEmailWhatsappWise[i].detailsArr[j].showIconTooltip=false;
            }
            
          }else{
            this.MultipleListEmailWhatsappWise[i].detailsArr[j].showIconTooltip=false;
          }
          
        }
      }else{
        for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++)
        {
          this.MultipleListEmailWhatsappWise[i].detailsArr[j].showIconTooltip=false;
        }

      }

    }

  }
  /**
   *Set Do Not Hide Three Dot Variable true 
   */
  setDoNotHideVariable()
  {
    this.doNotHideThreeDot=true;
  }

   /**
   * Function to show or hide three dot tooltip of guest list menu dropdown
    */
   ToggleTooltipTextThreeDot(emailPhone,docName,type){
   
        for(let i=0; i<this.MultipleListEmailWhatsappWise.length; i++)
        {

          if(this.MultipleListEmailWhatsappWise[i].guestWhatsAppEmail==emailPhone)
          {
            for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++){
              if(this.MultipleListEmailWhatsappWise[i].detailsArr[j].documentName==docName){
                if(type=='show')
                {
                    this.MultipleListEmailWhatsappWise[i].detailsArr[j].showThreeDotTooltip=!this.MultipleListEmailWhatsappWise[i].detailsArr[j].showThreeDotTooltip;
                
                }else if(type=='hide'){
                  
                  setTimeout(() => {
                    if(!this.doNotHideThreeDot){
                      this.MultipleListEmailWhatsappWise[i].detailsArr[j].showThreeDotTooltip=false;
                    }
                  }, 1000);
                  
                }
                
              }else{
                this.MultipleListEmailWhatsappWise[i].detailsArr[j].showThreeDotTooltip=false;
              }
              
            }
          }else{

            for(let j=0; j<this.MultipleListEmailWhatsappWise[i].detailsArr.length; j++){
                this.MultipleListEmailWhatsappWise[i].detailsArr[j].showThreeDotTooltip=false;
            }

          }

        }

  }
  ToggleTooltipText2(type){
    if(type=='show'){
      document.getElementById('tooltiptext2').style.display='block';
    }else if(type=='hide'){
      setTimeout(() => {
        document.getElementById('tooltiptext2').style.display='none';
      }, 5000);
    } 

     
  }
  ToggleTooltipText3(type){
    if(type=='show'){
      document.getElementById('tooltiptext3').style.display='block';
    }else if(type=='hide'){
      setTimeout(() => {
        document.getElementById('tooltiptext3').style.display='none';
      }, 5000);
    } 
  }
  ToggleTooltipText4(type){
    if(type=='show'){
      document.getElementById('tooltiptext4').style.display='block';
    }else if(type=='hide'){
      setTimeout(() => {
        document.getElementById('tooltiptext4').style.display='none';
      }, 5000);
    } 
  }
  /**
   * Function to show or hide three dot menu dropdown
  */
  ToggleThreeDotMenu(type){
    if(type=='show'){
      document.getElementById('threeDotTooltipText').style.display='block';
    }else if(type=='hide'){
      document.getElementById('threeDotTooltipText').style.display='none';
    }
  }
  
  /**
   * Close Unsaved Changes Modal
  */
  closeUnsavedChangesModal(){
    this.unsavedChangesWarnModalEmailWhatsappSwitchRef.close();
    this.closeBtnClick = true;
    //this.ngOnInit()
    if(this.showEmailSection)
    {
      this.showEmailSection=true;
      this.showWhatsappSection=false;
      this.toggleOptionRadioEmailWhatsapp='Email';
      document.getElementById('emailRadioInput').click()
    }else{
      this.showEmailSection=false;
      this.showWhatsappSection=true;
      this.toggleOptionRadioEmailWhatsapp='Whatsapp';
      document.getElementById('whatsappRadioInput').click()
    }
    
  }
  /**
   * Proceed Unsaved Changes Modal
    */
  proceedUnsavedChangesModal(type?){
    
    this.unsavedChangesWarnModalEmailWhatsappSwitchRef.close()
    this.emailInputArr=[]
    this.emailInputString=''
    this.whatsappNumberInputArr=[]
    this.whatsappNumberInputString=''

    this.ngOnInit()
    if(this.showEmailSection)
    {
      this.showEmailSection=false;
      this.showWhatsappSection=true;
      document.getElementById('whatsappRadioInput').click()
      //this.toggleOptionRadioEmailWhatsapp='Email';
    }else{
      this.showEmailSection=true;
      this.showWhatsappSection=false;
      document.getElementById('emailRadioInput').click()
      //this.toggleOptionRadioEmailWhatsapp='Whatsapp';
    }
    this.expirationDate = 'Expiration Date';
  }

   /**
   * Proceed Unsaved Changes Warm Modal
    */

  ProceedMainModalWarn(type){
    
    if(type=='show' && (this.emailInputArr.length>0 || this.whatsappNumberInputArr.length>0)){
      this.unsavedChangesWarnModalRef=this.dialogService.open(this.unsavedChangesWarnModal, { context: 'this is some additional data passed to dialog' });
    }else if(type=='hide'){
      this.unsavedChangesWarnModalRef.close()
      this.dialogRef.close()
      if(this.guestShareDirty)
      {
        this.sharedService.transferData.next('reloadData');
      }

      this.showEmailSection=true;
      this.emailInputArr=[]
      this.emailInputString=''
      this.emailChipsAllValid=true;
      this.shareBtnValid=false
      this.emailErrorMsg=''

      this.toggleOptionRadioEmailWhatsapp=''
      this.showWhatsappSection=false;
      this.whatsappNumberInputString=''
      this.whatsappNumberInputArr=[]
      this.whatsappNumberExistingList=[]
      this.whatsappChipsAllValid=true;
      this.whatsappErrorMsg='';
    }
    else{
      this.dialogRef.close()
      if(this.guestShareDirty)
      {
        this.sharedService.transferData.next('reloadData');
      }
      this.showEmailSection=true;
      this.emailInputArr=[]
      this.emailInputString=''
      this.emailChipsAllValid=true;
      this.shareBtnValid=false
      this.emailErrorMsg=''

      this.toggleOptionRadioEmailWhatsapp=''
      this.showWhatsappSection=false;
      this.whatsappNumberInputString=''
      this.whatsappNumberInputArr=[]
      this.whatsappNumberExistingList=[]
      this.whatsappChipsAllValid=true;
      this.whatsappErrorMsg='';
      
    }
    
  }
   /**
   * close Button click Unsaved Changes Modal
    */
  closeMainModalWarn(){
    this.unsavedChangesWarnModalRef.close()
  }

  /**
   *Function to  toggle advanced setting
   */

  onToggleChange() {
    this.originalAdvancedSettingsDownloadMandatory = this.advancedSettingsDownloadMandatory;
  }

  /**
   *Function to Save changes for adavnced setting 
   */
  saveChanges(dialog) {
    if (this.advancedSettingsDownloadMandatory !== this.originalAdvancedSettingsDownloadMandatory) {
      
      this.originalAdvancedSettingsDownloadMandatory = this.advancedSettingsDownloadMandatory;
      this.cd.detectChanges();
    }
    this.ToggleAdvancedSettingsModal('hide',dialog);
  }
  /**
   *Function to cancel changes for adavnced setting 
   */
  cancelChanges(dialog) {
    this.advancedSettingsDownloadMandatory = this.originalAdvancedSettingsDownloadMandatory;
    this.cd.detectChanges();
    this.ToggleAdvancedSettingsModal('hide',dialog);
  }

  
  /**
   * Advanced Settings Modal Open and hide
   */
  ToggleAdvancedSettingsModal(type,dialog: TemplateRef<any>){
    if(type=='show')
    {
      this.dialogRefAdvancedSettingModel=this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }else if(type=='hide'){
      this.dialogRefAdvancedSettingModel.close()
    }
    
  }
  /**
   *Update Advanced Setting Changes 
   */
   UpdateAdvancedSettingChanges()
   { 
    this.isExpiryEdit = false;
    if(this.threeDotTrigger){
        this.isExpiryEdit = true;
     }
    this.advancedSettingsOtpMandatory = this.tempadvancedSettingsOtpMandatory
    this.advancedSettingsDownloadMandatory = this.tempadvancedSettingsDownloadMandatory
     
     if(this.modifyType!='' && this.emailInputArrModifyList!='')
     {
       this.EmailModificationAdvancedSettingFunctionality()
     }
     this.dialogRefAdvancedSettingModel.close()

     this.modifyType=''
     this.emailInputArrModifyList=''
     this.tempexpirationDateInfiniteDuration=false;
     if(this.threeDotTrigger){
     this.tempadvancedSettingsOtpMandatory = this.tempadvancedSettingsOtpMandatoryNew;
     this.tempadvancedSettingsDownloadMandatory = this.tempadvancedSettingsDownloadMandatoryNew;
     this.advancedSettingsOtpMandatory = this.tempadvancedSettingsOtpMandatoryNew;
     this.advancedSettingsDownloadMandatory = this.tempadvancedSettingsDownloadMandatoryNew;
    }
     this.cd.detectChanges();    
     this.threeDotTrigger = false
   }

   /**
   *Cancel Advanced Setting Changes 
   */
   CancelAdvancedSettingChanges()
   { 
    this.tempadvancedSettingsOtpMandatory = this.advancedSettingsOtpMandatory
    this.tempadvancedSettingsDownloadMandatory = this.advancedSettingsDownloadMandatory
    
     this.cd.detectChanges()
     this.dialogRefAdvancedSettingModel.close()
     this.modifyType=''
     this.emailInputArrModifyList=''
     this.tempexpirationDateInfiniteDuration=false;
     if(this.threeDotTrigger){
      this.tempadvancedSettingsOtpMandatory = this.tempadvancedSettingsOtpMandatoryNew;
      this.tempadvancedSettingsDownloadMandatory = this.tempadvancedSettingsDownloadMandatoryNew;
      this.advancedSettingsOtpMandatory = this.tempadvancedSettingsOtpMandatoryNew;
      this.advancedSettingsDownloadMandatory = this.tempadvancedSettingsDownloadMandatoryNew;
     }
     this.cd.detectChanges();
   }


  /*Toggle Advanced Settings Modal*/
  ToggleExpirationSettingsModal(type,dialog:TemplateRef<any>){
    if(type=='show')
    {
      this.dialogRefExpirationModelRef=this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }else if(type=='hide'){
      this.dialogRefExpirationModelRef.close()
      
    }
    
  }
  /**
   *Update Expiration Date Changes 
   */
  UpdateExpirationDateChanges()
  {
    this.isExpiryEdit = false;
    if(this.threeDotTriggerExp && this.tempexpirationModalCalendardateNew != undefined) {
      this.isExpiryEdit = true;
     }
    let year=this.tempexpirationModalCalendardate.getFullYear()
    let month=this.tempexpirationModalCalendardate.getMonth()+1
    let date=this.tempexpirationModalCalendardate.getDate()

    let hour=''
    let min=''

    if(this.tempexpirationDateModalAMPM=='PM' && this.tempexpirationDateModalHour!=12){
       hour=""+(this.tempexpirationDateModalHour+12)+""
    }else{
    
      if(this.tempexpirationDateModalHour<10)
      {
        hour="0"+this.tempexpirationDateModalHour
      }else{
        hour=""+this.tempexpirationDateModalHour+""
      }

    }

    if(this.tempexpirationDateModalMin<10)
    {
      min=""+this.tempexpirationDateModalMin
    }else{
      min=""+this.tempexpirationDateModalMin+""
    }

    
    if(new Date(month+'/'+date+'/'+year+' '+hour+':'+min+':00').getTime()<(new Date(new Date().getTime()+1*60*60*1000)).getTime())
    {
     this.tempexpirationError="Minimum duration must be 1 hour"
    }else{
      this.tempexpirationError=''
    }

    if(new Date(month+'/'+date+'/'+year+' '+hour+':'+min+':00').getTime()<new Date().getTime())
    {
     this.tempexpirationError="The expiration date cannot be before the current time"
    }

  if(this.tempexpirationError==''){
              
            this.expirationModalCalendardate=this.tempexpirationModalCalendardate
            
            this.expirationDateModalHour=this.tempexpirationDateModalHour
            this.expirationDateModalMin=this.tempexpirationDateModalMin
            this.expirationDateModalAMPM=this.tempexpirationDateModalAMPM

            this.expirationDateInfiniteDuration=this.tempexpirationDateInfiniteDuration
            this.expirationError=this.tempexpirationError


              if(this.modifyType!='' && this.emailInputArrModifyList!='')
              {
                this.EmailModificationExpiryDateFunctionality()
              }
              this.dialogRefExpirationModelRef.close()

              this.modifyType='' 
              this.emailInputArrModifyList=''
              // this.tempexpirationDateInfiniteDuration=false;
              this.expirationDate = this.tempexpirationDateInfiniteDuration == true? 'Unlimited' : this.formatDate(this.tempexpirationModalCalendardate,this.tempexpirationDateModalHour,this.tempexpirationDateModalMin,this.tempexpirationDateModalAMPM);
  }
   if(this.threeDotTriggerExp && this.tempexpirationModalCalendardateNew != undefined) {
    this.tempexpirationModalCalendardate = this.tempexpirationModalCalendardateNew;
    this.tempexpirationDateInfiniteDuration = this.tempexpirationDateInfiniteDurationNew;
    this.isExpiryEdit = true;
   }
   this.threeDotTriggerExp = false;
  }
  /**
   *Cancel Expiration Date Changes 
   */
  CancelExpirationDateChanges()
  {
    this.tempexpirationModalCalendardate=this.expirationModalCalendardate

   this.tempexpirationDateModalHour=this.expirationDateModalHour
   this.tempexpirationDateModalMin=this.expirationDateModalMin
   this.tempexpirationDateModalAMPM=this.expirationDateModalAMPM
  
   this.tempexpirationDateInfiniteDuration=this.expirationDateInfiniteDuration
   this.tempexpirationError=this.expirationError

  this.dialogRefExpirationModelRef.close()

  this.modifyType='' 
  this.emailInputArrModifyList=''
  // this.tempexpirationDateInfiniteDuration=false;
  if(this.threeDotTriggerExp && this.tempexpirationModalCalendardateNew != undefined) {
  this.tempexpirationModalCalendardate = this.tempexpirationModalCalendardateNew;
  this.tempexpirationDateInfiniteDuration = this.tempexpirationDateInfiniteDurationNew
  }
  this.isExpiryEdit = false;
}
  enableConfirmBtn() {
    if ((this.alreadyexistanceIdConf === false && this.inviteUsersForm.get('chipEmail').value !== '' && this.validemail.length !== 0) || this.deletedExistingEmails.length !== 0) {
      this.confrmdissabledbtn = false
    }
    else { this.confrmdissabledbtn = true }
  }
  /**
   * @param event validation on modal duration and email
   */

  addemail(email: any, isConfirm) {
    this.notShared=false
    if (this.selectedDays === '00' && this.selectedHours === '00') {
      this.durationError = true;
      return;
    }
    this.alreadyexistanceId = false;
    this.alreadyexistanceIdConf = false;
    if (email.value.includes(',') || email.value.includes(' ')) {
      this.wrongemail = true;

      return;
    }
    this.textareavalue = email.value.trim().replace(/(\r\n|\n|\r)/gm, " ")?.split(/[, " "]+/);//replace using for remove line break in textarea and split using for convert it into array
   
    let correctemail = [...new Set(this.textareavalue)]

    for (let i in correctemail) {
     
      var pattern = /^[a-z0-9]{1}[a-z0-9._]{0,}\@[a-z0-9]{1}[a-z0-9-]{1,}\.[a-z]{2}[a-zA-Z.]{0,}$/i;
      if (pattern.test(correctemail[i])) {
        this.alreadyexistanceId = false;
        this.alreadyexistanceIdConf = false;

        if (this.existingMail.indexOf(correctemail[i]) == -1 && this.validemail.indexOf(correctemail[i]) == -1) {
          this.validemail.unshift(correctemail[i]);
          this.validEmailDuration.unshift(parseInt(this.selectedDays) * 24 + parseInt(this.selectedHours));
          this.inviteUsersForm.reset();

        } else {
          this.alreadyexistanceId = true;
          this.alreadyexistanceIdConf = true;

          this.wrongemail = false;
        }
      }
      else if (this.inviteUsersForm.get('chipEmail').value !== null) {
        this.wrongemail = true
        this.alreadyexistanceId = false;
        this.alreadyexistanceIdConf = false;

      }
    }

    this.enableConfirmBtn();
  }
  /**
    * @param event validation on modal duration and email
    */
  addemailValid(email: any, isConfirm?) {
    if (this.selectedDays === '00' && this.selectedHours === '00') {
      this.durationError = true;
    }
    else {
      this.durationError = false;
    }

    this.alreadyexistanceId = false;
    if ((email.value.includes(',') || email.value.includes(' ') && this.inviteUsersForm.get('chipEmail').value !== '')) {

    
      if (isConfirm === 'true') {
        this.wrongemailonOutsideClick = true;
      }
      this.wrongemail = true;
      return;
    }
    this.textareavalue = email.value.trim().replace(/(\r\n|\n|\r)/gm, " ")?.split(/[, " "]+/);//replace using for remove line break in textarea and split using for convert it into array
 
    let correctemail = [...new Set(this.textareavalue)]

    for (let i in correctemail) {
    
      var pattern = /^[a-z0-9]{1}[a-z0-9._]{0,}\@[a-z0-9]{1}[a-z0-9-]{1,}\.[a-z]{2}[a-zA-Z.]{0,}$/i;
      if (pattern.test(correctemail[i])) {
        this.alreadyexistanceId = false;

        if (this.existingMail.indexOf(correctemail[i]) == -1 && this.validemail.indexOf(correctemail[i]) == -1) {
         
        }
        else {
          this.alreadyexistanceId = true;
          if (isConfirm === 'true') {
            this.wrongemailonOutsideClick = false;
          }
          this.wrongemail = false;
        }
      }
      else if (this.inviteUsersForm.get('chipEmail').value !== null && this.inviteUsersForm.get('chipEmail').value !== '') {
        if (isConfirm === 'true') {
          this.wrongemailonOutsideClick = true;
        }
        this.wrongemail = true;
        this.alreadyexistanceId = false;

      }
    }

  }
  /**
    * @param  Get ip Address 
    */
  getIPAddress() {
    if (localStorage.getItem('selectedCountry') === undefined || localStorage.getItem('selectedCountry') === null || localStorage.getItem('countryNameInitial') === null || localStorage.getItem('countryDialCode') === null) {
      this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
        this.http.get('https://ipapi.co/' + res.ip + '/json/').subscribe((resp: any) => {
          this.ipAddress = resp.ip;
          this.selectedCountry = resp.country_name;
          this.countryNameInitial=resp.country_code
          this.countryDialCode=resp.country_calling_code
          localStorage.setItem('ipAddress', this.ipAddress);
          localStorage.setItem('selectedCountry', this.selectedCountry);
          localStorage.setItem('countryNameInitial', this.countryNameInitial);
          localStorage.setItem('countryDialCode',this.countryDialCode);
        }, error => {

        });
      }, error => {

      });
    }
    else {
      this.ipAddress = localStorage.getItem(this.ipAddress);
      this.selectedCountry = localStorage.getItem(this.selectedCountry);
      this.countryNameInitial=localStorage.getItem('countryNameInitial');
      this.countryDialCode=localStorage.getItem('countryDialCode');
      
    }

    for(let i=0; i<this.countryDataArray.length; i++)
    {
      if(this.countryDataArray[i].countryCode.toLowerCase()==this.countryNameInitial.toLowerCase())
      {
        this.countryDialCode=this.countryDataArray[i].dialCode
        this.countryFlagClass=this.countryDataArray[i].class
      }
    }

  }
  /**
    * @param  Confirm Guest Api 
    */

  confirmGuest() {
    this.sharedService.cellClickedData1.next(true);
    this.sharedService.onGuestClick.next();
    if (this.validemail.length === 0 && this.deletedExistingEmails.length !== 0) {
      this.validEmailDuration = ['0'];
    }

    if (this.validemail.length !== 0 && this.validEmailDuration.includes('0')) {
      this.validEmailDuration.splice(0, 1)
    }
    var st = window.location.toString();
    var arr = st?.split("/");
    this.timeZone = 
      (localStorage.getItem('WSTimeZone')) 
      ? localStorage.getItem('WSTimeZone')
      : localStorage.getItem('User_time_zone');
    let postData: any
    postData =
    {
      "wsoid": Number(this.workSpaceId),
      "ownerName": this.noteuserfullname,
      "wsLink": arr[2],
      "docGuid": this.doxEvent?.rowData?.docGuid || this.doxEvent?.docGuid,
      "guestEmailidList": this.validemail.join('|'),
      "expiryDurationList": this.validEmailDuration.join('|'),
      "isDeleteGuestEmailIDList": this.deletedExistingEmails.join(','),
      "shareBy": this.createdBy,
      "systemIP": localStorage.getItem('ipAddress'),
      "separator": "|",
      "createWS": environment?.baseapp,
      'timeZone': this.timeZone,
      "IsExpiryEdit": this.isExpiryEdit?1:0
    }
     this.showSpinner = true;
    this.data.guestConfirm(postData).subscribe((response: any) => {


      if (response.isSuccess) {
     
        let userList = response.data;
        this.toastrService.primary("Guest Access Updated");
        setTimeout(() => {
          this.sharedService.cellClickedData.next(true);
        }, 500);
        setTimeout(() => {
          if(this.href === '/pages/documents/owned-by-me'){
            // this.sharedservice.ownedByMeEmitterGuest.next(this.doxEvent.rowData);
          } else if(this.href === '/pages/documents/shared-with-me'){
            // this.sharedservice.sharedWithMeEmitterGuest.next(this.doxEvent.rowData); 
          } else if(this.href === '/pages/cabinet'){
            // this.sharedservice.commonCabinetEmitterGuest.next(this.doxEvent); 
          } else if(this.href === '/pages/search'){
            // this.sharedservice.globalSearchEmitterGuest.next(this.doxEvent);
          }  
        }, 1000);
       
        if (response.request.isDeleteGuestEmailIDList.length !== 0 && response.request.guestEmailidList.length !== 0) {
      
          this.checkdataDelete = 'deleted';
          this.checkdataShared = 'shared';
          this.notifyinsertShared();
          this.notifyinsertDelete();
          this.notifyDeleteUSer();
         
        }
        else if (response.request.guestEmailidList.length !== 0) {
          this.checkdataShared = 'shared';
          this.notifyinsertShared();
        


        } else if (response.request.isDeleteGuestEmailIDList.length !== 0) {
          this.checkdataDelete = 'deleted';
          this.notifyinsertDelete();
          this.notifyDeleteUSer();



        }
        this.showSpinner = false;
      }
      
      
    });
  }
  /**
    * @param event All user Api for finding registerd mail id
    */

  registerdMailId: any[] = [];
  userMailId: any[] = [];

  getAllUsersList(event) {
    this.userMailId = [];
    this.existingMail = [];
   
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "wsoid": Number(this.workSpaceId),
      "docGuid": event?.rowData?.docGuid || event?.docGuid
    }
      ;
    this.data.guestListService(postData).subscribe((response: any) => {

      if (response.isSuccess) {

        this.userMail = response.data.forEach(element => {
          this.existingMail.push(element.guestEmailid);


        });
      }

    });

  }

  @ViewChild('dialog') dialog: TemplateRef<any>
  
  @ViewChild('dialogNotAcess') dialogNotAcess: TemplateRef<any>

  dialogRef: any
  dialogRefAccess: any
  
  /**
    * @param event to open Guest Share Modal
    */ 
   extdata;
  open(event?,type?) {
    // this.tempexpirationModalCalendardateNew = undefined;
    // this.expirationDateInfiniteDuration = false;
    this.expirationDate = 'Expiration Date';
    this.guestShareDirty=false;
    if(type == "dashboard"){
    this.ngOnInit();
  }
    this.doxEvent = event;
    this.docClickedModifiedNames=[]
    this.docsSelectedData=[]
    if(this.doxEvent.rowData)
    {
      this.isSingleSharing=true;
      this.docGuidArr=[]
      this.docClickedModifiedNames=[]
      this.docGuidArr.push(this.doxEvent.rowData.docGuid)
      this.docClickedModifiedNames.push(this.doxEvent.rowData.docRename)
      this.docsSelectedData.push({
        'guid':this.doxEvent.rowData.docGuid,
        'modifiedName':this.doxEvent.rowData.docRename,
        'ownerEmail':this.doxEvent.rowData.docOwnerEmailId
      })
    }else{
      if(this.doxEvent.length>0 && this.doxEvent.length<=1)
      {
        this.isSingleSharing=true;
      }else{
        this.isSingleSharing=false;
      }
      this.docGuidArr=[]
      for(let i=0; i<this.doxEvent.length; i++)
      {
        this.docGuidArr.push(this.doxEvent[i].docGuid)
        this.docClickedModifiedNames.push(this.doxEvent[i].docRename)
        this.docsSelectedData.push({
          'guid':this.doxEvent[i].docGuid,
          'modifiedName':this.doxEvent[i].docRename,
          'ownerEmail':this.doxEvent[i].docOwnerEmailId != undefined? this.doxEvent[i].docOwnerEmailId:this.doxEvent[i].createdBy
        })
      }
    }

    
    //this.getUpdatedListOfSharesForDoc()
    this.singleSkeleton=false;
   this.confrmdissabledbtn = true;
   this.disableAdd= true;
   this.selectedDays = '01';
   this.selectedHours = '00';
   if(event.extension){
     this.extdata = event.extension;
   }
   else if(event.rowData){
    this.extdata = event.rowData.formatExtension;
   }
    // this.dialogRef = this.dialogService.open(this.dialog, {
    //   closeOnBackdropClick: false,
    //   closeOnEsc: false });


    //Get Updated Lists
    this.MultipleListEmailWhatsappWise=[];
    this.workSpaceId = localStorage.getItem('localwsId');
    let getuserlistsPayload={
      "wsoid": Number(this.workSpaceId),
      "docGuid": this.docGuidArr.join('|'),
    }
   
    this.data.getListofSharedUsersOfDoc(getuserlistsPayload).subscribe((response:any)=>{
      if(response.isSuccess){
          if(response.data && response.data.length>0)
          {
            let uniqueEmailPhone=[]
            let multiListArr=[]
            /**
             * create unique emailPhone list
             */
            for(let i=0; i<response.data.length; i++)
            {
              if(!uniqueEmailPhone.includes(response.data[i].guestWhatsAppEmail))
              {
                uniqueEmailPhone.push(response.data[i].guestWhatsAppEmail);
              }
            }

            for(let i=0; i<uniqueEmailPhone.length; i++)
            {
              let documentArr=[]
              for(let j=0; j<response.data.length; j++)
              {
                if(response.data[j].guestWhatsAppEmail==uniqueEmailPhone[i])
                {

                  let infiniteDate=false;
                  console.log(response.data[j].expiryDtm)
                  if(response.data[j].expiryDtm && response.data[j].expiryDtm.includes('/'))
                  {
                    let splitdata=(response.data[j].expiryDtm).split(" ")
                    let year=splitdata[0].split('/')[2]
                    if(Number(year)>2100)
                    {
                      infiniteDate=true
                    }

                  }

                  console.log("infiniteDate",infiniteDate)

                  documentArr.push({
                    docGuid:response.data[j].docGuid,
                    isInfinite:infiniteDate,
                    expiryDtm:response.data[j].expiryDtm,
                    expiryDuration:response.data[j].expiryDuration,
                    documentName:response.data[j].documentName,
                    isotp:response.data[j].isotp,
                    downloadPrintRights:response.data[j].downloadPrintRights,
                    shareBy:response.data[j].shareBy,
                    shareName:response.data[j].shareName?response.data[j].shareName:'',
                    messageStatus:response.data[j].messageStatus != "0"?response.data[j].messageStatus:'SENT',
                    isMobileNumberValid:response.data[j].isMobileNumberValid?response.data[j].isMobileNumberValid:'',
                    showIconTooltip:false,
                    showThreeDotTooltip:false,
                    checked:false,
                  })
                }
              }

              multiListArr.push({
                guestWhatsAppEmail:uniqueEmailPhone[i],
                show:false,
                checked:false,
                showModifyOptions:false,
                detailsArr:documentArr
              })

            }
            this.MultipleListEmailWhatsappWise=multiListArr

            console.log("this.MultipleListEmailWhatsappWise",this.MultipleListEmailWhatsappWise)
            
          }
          
          this.dialogRef = this.dialogService.open(this.dialog, {
            closeOnBackdropClick: false,
            closeOnEsc: false });

      }
    })


  }
  /**
   *Function to open non editable Modal Guest Share  
   */
  openNonEdit(event?) {
    this.doxEvent = event;
    console.log("this.doxEvent",this.doxEvent)
    this.docClickedModifiedNames=[]
    this.docsSelectedData=[]
    if(this.doxEvent)
    { 
      this.docGuidArr=[]
      this.docClickedModifiedNames=[]
      
      if(this.doxEvent?.rowData?.docGuid)
      {
        this.docGuidArr.push(this.doxEvent.rowData.docGuid)
      }else{
        this.docGuidArr.push(this.doxEvent.docGuid)
      }
      this.isSingleSharing=true;
      
      
    }else{
      if(this.doxEvent.length>0 && this.doxEvent.length<=1)
      {
        this.isSingleSharing=true;
      }else{
        this.isSingleSharing=false;
      }
      this.docGuidArr=[]
      for(let i=0; i<this.doxEvent.length; i++)
      {
        this.docGuidArr.push(this.doxEvent[i].docGuid)
        this.docClickedModifiedNames.push(this.doxEvent[i].docRename)
        this.docsSelectedData.push({
          'guid':this.doxEvent[i].docGuid,
          'modifiedName':this.doxEvent[i].docRename,
          'ownerEmail':this.doxEvent[i].docOwnerEmailId
        })
      }
    }

    this.getUpdatedListOfSharesForDoc()
    this.dialogRefAccess = this.dialogService.open(this.dialogNotAcess, {
      closeOnBackdropClick: false,
      closeOnEsc: false });
  }


  @ViewChild('autoInput') input;
  /**
    * @param NbTagComponent Remove Tag list
    */


  onTagRemove(tagToRemove: NbTagComponent): void {
    if(this.validemail.length==1){  
        this.notShared=true
    }
    let index = this.validemail.indexOf(tagToRemove.text);
    this.validEmailDuration.splice(index, 1);
    this.validemail = this.validemail.filter(t => t !== tagToRemove.text);

    this.enableConfirmBtn();
  }
  /**
    * @param NbTagComponent Remove Tag list
    */

  onTagRemoveExisting(tagToRemove: NbTagComponent): void {
    this.deletedExistingEmails.push(tagToRemove.text);
    this.existingMail = this.existingMail.filter(t => t !== tagToRemove.text);

    this.enableConfirmBtn();
  }
  /**
    * @param close confirm cancell form should be reset
    */
  guestUserModalReset() {
    this.tempexpirationModalCalendardateNew = new Date(new Date().getTime()+24*60*60*1000);
    this.tempexpirationDateInfiniteDuration = false;
    this.expirationDateInfiniteDuration = false;
    this.ProceedMainModalWarn('show')
    this.wrongemail = false;
    this.wrongemailonOutsideClick = false;
    //this.inviteUsersForm.reset();
    this.confrmdissabledbtn = true;

    this.alreadyexistanceId = false;
    this.alreadyexistanceIdConf = false;
    this.validemail.splice(0, this.validemail.length);
    //this.sharedService.onGuestClick.next();\
        /** 
     * Logic to show current time on Expiration Modal calendar
    */
        this.expirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
        this.tempexpirationModalCalendardate=new Date(new Date().getTime()+24*60*60*1000)
        this.minexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
        this.tempminexpirationModalCalendardate=new Date(new Date().getTime()-24*60*60*1000)
        
        this.expirationDateModalHour=new Date().getHours()
        this.tempexpirationDateModalHour=new Date().getHours()
    
        this.expirationDateModalMin=new Date().getMinutes()
        this.tempexpirationDateModalMin=new Date().getMinutes()

        if((this.expirationDateModalMin<10) &&(this.expirationDateModalMin!=0)) {
          this.expirationDateModalMin = "0"+this.expirationDateModalMin;
        }
        else if(this.expirationDateModalMin==0) {
          this.expirationDateModalMin = "00"
        }
        else {
          this.expirationDateModalMin
        }

        if((this.tempexpirationDateModalMin<10) &&(this.tempexpirationDateModalMin!=0)) {
          this.tempexpirationDateModalMin = "0"+this.tempexpirationDateModalMin;
        }
        else if(this.tempexpirationDateModalMin==0) {
          this.tempexpirationDateModalMin = "00"
        }
        else {
          this.tempexpirationDateModalMin
        }



        if(this.expirationDateModalHour>11){
          this.expirationDateModalAMPM='PM'
        }
        if(this.tempexpirationDateModalHour>11){
          this.tempexpirationDateModalAMPM='PM'
        }
        
        if(this.expirationDateModalHour>12){
          this.expirationDateModalHour=this.expirationDateModalHour-12;
        }
        if(this.tempexpirationDateModalHour>12){
          this.tempexpirationDateModalHour=this.tempexpirationDateModalHour-12;
        }
        this.tempexpirationDateInfiniteDuration = false;
        this.tempadvancedSettingsOtpMandatory=false;
        this.tempadvancedSettingsDownloadMandatory=false;
        this.advancedSettingsOtpMandatory=false;
        this.advancedSettingsDownloadMandatory=false;
        this.tempadvancedSettingsOtpMandatoryNew= false;
        this.tempadvancedSettingsDownloadMandatoryNew= false;
        this.tempexpirationDateInfiniteDurationNew = false;
        this.threeDotTrigger = false;
        this.threeDotTriggerExp = false;
        this.dCoinsInvalid = false;
        this.isExpiryEdit = false;
  }
/**
   * @param event reset value
   */
   resetValue(){
    this.sharedService.onGuestClick.next();
   }
  /**
   * @param event Button Behaviour
   */
  changeButtonState(event) {
    this.disableAdd = false;
    this.alreadyexistanceId = false;
    this.alreadyexistanceIdConf = false;
    this.wrongemailonOutsideClick = false;

    this.wrongemail = false;
  }

  /** 
   * @param event Dropdown event Access Days
   */

  changeAccessState(event) {
    if (this.selectedDays === '00' && this.selectedHours === '00') {
      this.durationError = true;
    }
    else {
      this.durationError = false;
    }
 
    this.selectedDays = event ;
   
  }

  /**
   * @param event Dropdown event Access Hour
   */

  changeAccessHours(event) {

    if (this.selectedDays === '00' && this.selectedHours === '00') {
      this.durationError = true;
    }
    else {
      this.durationError = false;
    }
   
    this.selectedHours = event;

  }

  /** 
    * receive function from parent comp for send invite
    */


  hasIcon: boolean = false
  public index: number = 0;
  @HostBinding('class')
  classes = 'example-items-rows';

  showToast() {
    this.toastrService.primary('Invite Sent', '',);
  }

  insertmessgae = '';
  messageheading;
  messageheadingdeleted;
  messagebody;
  /**
   * Send Delete Notification 
   */
  notifyinsertDelete() {

    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange3 = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'
    this.messageheadingdeleted = "Guest(s) are removed from Document";
    this.messagebody = this.noteuserfullname + ' (' + this.createdBy + ')' +' ' + "removed " + this.deletedExistingEmails.length + " guest(s) for " + (this.doxEvent?.rowData?.docRename || this.doxEvent?.docRename) + '.' + this.extdata + ' ' + 'View.' + `<a href=${urlchange3}>Owned by me</a>`

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: this.messageheadingdeleted,
      message: this.messagebody,
      notificationTo: this.doxEvent?.rowData?.docOwnerEmailId || this.doxEvent?.docOwnerEmailId,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysenddelete();
      }
      else {
        this.insertmessgae = '';
      }

    })
    

  }
  /**
   * send key on deletion
   */
  notifyDeleteUSer(){
    var st = window.location.toString();
      var arr = st?.split("/");
  
      let uploadnotify = {
        "wsoid": Number(this.workSpaceId),
        "wsLink": this.deletedExistingEmails.join(','),
        "createdby": this.createdBy,
        "category": "Guest",
        "value": "",
        "database": ""
      }
      this.signInService.roleinsert(uploadnotify).subscribe((response: any) => {
        if (response.isSuccess) {
          this.insertmessgae = response.data;
          this.deletedExistingEmails = [];
         }
         else{
           this.insertmessgae = '';
         }
      })
  }
  /**
   * Send Shared Notification 
   */
  notifyinsertShared() {

    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange3 = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'

    this.messageheading = "Document is shared with Guest";
    this.messagebody = this.noteuserfullname + ' (' + this.createdBy + ')' +' '+ "shared " + (this.doxEvent?.rowData?.docRename || this.doxEvent?.docRename) + '.' + this.extdata + " with " + this.validemail?.length + " guests. View" + `<a href=${urlchange3}>Owned by me</a>`
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: this.messageheading,
      message: this.messagebody,
      notificationTo: this.doxEvent?.rowData?.docOwnerEmailId || this.doxEvent?.docOwnerEmailId,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysend();
      }
      else {
        this.insertmessgae = '';
      }

    })
    this.validemail = [];
    this.validEmailDuration = [];
  }
    /** 
       * Send Shared Toastr 
       */
  sendmessage;
  notifysend() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.doxEvent?.rowData?.docOwnerEmailId || this.doxEvent?.docOwnerEmailId,
      role: null,
      groupName: null,
      message: this.messageheading,
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
  /** 
   * Send Delete Toastr 
   */
  notifysenddelete() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.doxEvent?.rowData?.docOwnerEmailId || this.doxEvent?.docOwnerEmailId,
      role: null,
      groupName: null,
      message: this.messageheadingdeleted,
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
    this.validemail = [];
    this.validEmailDuration = [];
  }
  formatDate(date,hr,min,period) {
    const options = { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleString('en-US', options);
    
    // Split the formatted date into its components
    const [month, day, year, time] = formattedDate.split(', ');
    
    // Construct the final formatted date string
    const formattedDateString = `${month}, ${day} ${hr}:${min} ${period}`;
  
    return formattedDateString;
  }
}


