import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { DetailAndActivityComponent } from './detail-and-activity/detail-and-activity.component';
import { NbAccordionModule, NbAutocompleteModule, NbButtonGroupModule, NbButtonModule, NbCardModule, NbContextMenuModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbOptionModule, NbPopoverModule, NbRadioModule, NbSelectModule, NbSidebarModule, NbTabsetModule, NbTagModule, NbThemeModule, NbToastrModule, NbToggleModule, NbTooltipModule,NbSpinnerModule,NbWindowModule, NbCheckboxModule,NbCalendarModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { InviteUserModalComponent } from './invite-user-modal/invite-user-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { MiniProfileComponent } from './mini-profile/mini-profile.component';
import { SharedAccessComponent } from './shared-access/shared-access.component';
import { DownloadComponent } from './download/download.component';
import { DeleteDocsComponent } from './delete-docs/delete-docs.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { GuestUserModalComponent } from './guest-user-modal/guest-user-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { FeatureFlagDirectiveModule } from '../@theme/directives/feature-flag-directive.module';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import { SecondLoaderComponent } from './second-loader/second-loader.component';
import { SmartFilterComponent } from './smart-filter/smart-filter.component';
import { PrintDocumentComponent } from './print-document/print-document.component';
import {AutosizeModule} from 'ngx-autosize';
import { BulkTagComponent } from './bulk-tag/bulk-tag.component';
import { DocPreviewComponent } from './doc-preview/doc-preview.component';
import { SafePipe } from '../safe.pipe';
import {Ng2TelInputModule} from 'ng2-tel-input';



@NgModule({
  declarations: [
    SharedComponent,
    SafePipe,
    DetailAndActivityComponent,
    InviteUserModalComponent,
    GuestUserModalComponent,
    TemplateEditorComponent,
    MiniProfileComponent,
    SharedAccessComponent,
    DownloadComponent,
    DeleteDocsComponent,
    UpgradeModalComponent,
    TableLoaderComponent,
    SmartFilterComponent,
    SecondLoaderComponent,
    PrintDocumentComponent,
    BulkTagComponent,
    DocPreviewComponent,
         
  ],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    NbTagModule,
    NbCalendarModule,
    NbCheckboxModule,
    FormsModule,
    AutosizeModule,
    ReactiveFormsModule,
    FeatureFlagDirectiveModule,
    NbTabsetModule,
    NbListModule,
    SharedRoutingModule,
    NbContextMenuModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    NbMenuModule,
    NbDialogModule.forRoot(),
    NbInputModule,
    NbCardModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbSidebarModule,
    NbFormFieldModule,
    NbAccordionModule,
    NbSelectModule,
    NbOptionModule,
    NbIconModule,
    NbButtonModule,
    NbButtonGroupModule,
    NbToastrModule.forRoot(),
    NbAutocompleteModule,
    NbPopoverModule,
    NbToggleModule,
    NbTagModule,
    NbEvaIconsModule,
    NbTooltipModule,
    NbRadioModule,
    NbDatepickerModule.forRoot(),
    NbIconModule,
    BsDropdownModule.forRoot(),
    NgSelectModule,
    NbSpinnerModule,
    NbWindowModule.forRoot(),
    Ng2TelInputModule
  ],
  // providers:[{provide: NbDialogRef,}],
  exports: [UpgradeModalComponent, DetailAndActivityComponent, InviteUserModalComponent,TemplateEditorComponent,MiniProfileComponent, DownloadComponent,SharedAccessComponent,DeleteDocsComponent,TableLoaderComponent,SecondLoaderComponent, GuestUserModalComponent,PrintDocumentComponent,SmartFilterComponent , BulkTagComponent,DocPreviewComponent,SafePipe]
})
export class SharedModule { }
