<!--/*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : My Docs in upload
///  Description: Docs in upload section
///  Owner      : Sumeet Goel
///  Author     : suraj kumar
///  Created On : 23-07-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///  Jyoti Singh
///------------------------------------------------------------------->


<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">

<!-- Breadcrumbs and Activity Details invoke -->




<!-------------------------------------- Heading Card Starts------------------------------------>
<div class="row" style="margin: 0px;">
<div class="globalparent col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
<nb-card class="mb-3 pt-3 pb-3 pr-3 pl-4 HeadCard">
  <nb-card-body class="p-0 HeadCardBody">
    <div class="row"  (click)="gridRow('clickinsideTable')">
      <div class="col-8 searchheightfield">
        <div *ngIf="!showSearch" style="display: flex ; flex-direction: column; padding: 0;" class="col-8">
          <h5 class="headline-5  mb-0 d-flex align-items-center docshead"><span nbTooltipTrigger="hover"

              nbTooltipPlacement="right" nbTooltipStatus="info">My Queue</span>
              <nb-icon icon="done" class="ml-5"></nb-icon>
          </h5>
          <p class="caption text-hint mb-0 captext"> {{totalDocCount}} document(s)</p>
        </div>

        <div class="searchFilter-section background-basic-color-2 mb-0 col-8 " *ngIf="showSearch">
          <div class="row m-0 myqueuediv">
            <!-------------------------  SEARCH & Filter section   ---------------------------------->
            <div class="searchFilter">
              <form [formGroup]="myDocsSearchForm" class="searchFilter2">
                <div class="row">
                  <div class="col-12 fieldinput">
                    <nb-form-field>
                      <nb-icon nbPrefix icon="search-outline" pack="eva" class="paddingleft"></nb-icon>
                      <input type="text" nbInput fullWidth fieldSize="small" class=" w-100 paragraph text-hint radius20 padding39"
                        placeholder="Search in My Queue..." autocomplete="off" formControlName="docsName" name="docsName"
                        id="docsSearchName" (keyup)="alphacheck($event);removeLanguage($event)"(keydown.enter)="searchUserForm($event.target.value)" (keypress)="alphacheck($event)"
                        (paste)="onPasteName($event)" maxlength="30" #box>
                      <button type="button" class="bg-transparent border-0 search-clear text-hint" *ngIf="box.value"
                        (click)="clearSearchInput();searchUserForm('')">
                        <nb-icon icon="close-outline"></nb-icon>
                      </button>

                    </nb-form-field>
                     <nb-icon id="funnel" class="cursor-pointer paddingRight text-hint" nbSuffix icon="funnel-outline" pack="eva"
                        (click)="searchBox($event);" id="filterclickicon"  [ngClass]="{'iconblue':!filterBox}">
                      </nb-icon>
                  </div>
                </div>
                <!----------------------------------------- Search bar ends ------------------------------------->

                <!------------------------------ Filter Section Starts --------------------------------->
                <div #filter class="row filterboxdiv" [hidden]="filterBox" (click)="filterdata('clickinsidefilter')">
                  <div class="col-12 filterBox color-basic-100 pl-0 pr-0">
                    <nb-card>
                      <nb-card-header class="pt-3 pb-3 pl-4">
                        <div class="row form-group mb-0">
                          <div class="col-12">
                            <h6 class="btn-rest-filter   Headline  text-basic">Filter by</h6>
                          </div>
                        </div>
                      </nb-card-header>

                      <nb-card-body>
                        <div #filter class="form-group row">
                          <label class="col-4 col-form-label subtitle text-basic">Added date</label>
                          <div class="col-8">
                            <input readOnly nbInput placeholder="Pick Date Range" fullWidth [min]="min"
                              formControlName="uploadDate" [nbDatepicker]="formpicker" fieldSize="medium" size="medium"
                              class="inputSize">
                            <nb-rangepicker #formpicker></nb-rangepicker>
                          </div>

                        </div>

                        <div #filter class="form-group mt-3 row">
                          <label class="col-4 col-form-label subtitle text-basic">Format</label>
                          <div class="col-8">

                            <nb-select placeholder="Select format" formControlName="documentFormat" fieldSize="medium"
                              fullWidth size="medium" class="height40">
                              <nb-option value={{a.displayName}} *ngFor="let a of docFormatList ">{{a.displayName}}
                              </nb-option>
                            </nb-select>
                          </div>
                        </div>

                        <div #filter class="form-group mt-3 row">
                          <label class="col-4 col-form-label subtitle text-basic">Uploaded by</label>
                          <div class="col-8">
                            <ng-select [items]="uploadedBy" placeholder="Uploaded By" class="cursor-pointer" appendTo="body" bindValue="verifier" bindLabel="verifierName"
                              formControlName="uploadedBy" class="text-hint">
                            </ng-select>
                          </div>

                        </div>

                        <div #filter class="form-group mt-3 row mb-0">
                          <label class="col-4 col-form-label subtitle text-basic"> Owner</label>

                          <div class="col-8">
                            <ng-select [items]="DocsOwnerList" placeholder="Document owner" class="cursor-pointer" appendTo="body" bindValue="documentOwner" bindLabel="documentOwnerName"
                              formControlName="documentOwner" class="text-hint">
                            </ng-select>
                          </div>


                        </div>
                         <!--------------------- Filter CHIPS starts  start--------------------------------->
                      <div  *ngIf="myqueuechips?.length > 0" class="">
                        <div class="mr-2 mt-3 row position-relative filterChipssection">
                          <div class="">
                            <div class="filter-chips ">
                              <nb-tag-list (tagRemove)="onTagRemove($event)" class="pt-2">
                                <nb-tag  *ngFor="let chip of myqueuechips" [text]="chip" status="basic" class="textTypo fontsize15"
                                  [size]="small" [removable]="myqueuechips.length > 0"> {{chip}}</nb-tag>
                              </nb-tag-list>
                            </div>
                          </div>
                        </div>
                      </div>

                      </nb-card-body>

                      <!-------------------- Filter CHIPS starts finish------------------>
                      <nb-card-footer>
                        <button type="button" class="mr-2" nbButton status="primary" size="small"
                          (click)="filterMyDoc()">Apply</button>
                        <button type="button" size="small" class="filterbtnsearch text-basic"
                          (click)="clickReset()">Reset</button>
                      </nb-card-footer>
                    </nb-card>
                  </div>

                </div>
                <!-------------- Filter Section ends ------------------->
              </form>
            </div>



          </div>
        </div>



        <!-- --------------------------------------------------for search----------------------- -->

      </div>

      <div class="col-4 d-flex justify-content-end align-items-center">
        <div class=" click-menu d-flex justify-content-end multiDocsMenu">

          <button  nbButton ghost class="cursor-pointer changecolor viewclass " [ngClass]="previewPanelOpen? 'notConvertblue':'Convertblue'" *ngIf="selectedDocs?.length == 1 && previewFlag"
          nbTooltip="Preview" nbTooltipStatus="info" nbTooltipPlacement="bottom" (click)="openPreviewPanel(previewChild)">
          <nb-icon icon="eye-outline" class="changecolor"  ></nb-icon>
        </button>
          <button nbButton ghost class="cursor-pointer changecolor viewclass " *ngIf="selectedDocs?.length && showDeleteUser() && shareAccessValidate()"
              nbTooltip="Share" nbTooltipStatus="info" nbTooltipPlacement="bottom" (click)="openShareCommonModal(shareChild)">
              <nb-icon icon="share-outline" [ngClass]="getClassIfDisabled() "
                class="changecolor"></nb-icon>
            </button>
          <div class="trashbtn" >
            <button nbButton ghost class="cursor-pointer changecolor viewclass " *ngIf="selectedDocs?.length && showDeleteUser()"
              nbTooltip="Change Verifier" nbTooltipStatus="info" nbTooltipPlacement="bottom" [disabled]="isDownloadModalOperationInProgress" (click)="disabledownload();chngVerifiermodal(chngVerifier)">
              <nb-icon icon="person-outline" [ngClass]="getClassIfDisabled()"
                class="changecolor"></nb-icon>
            </button>
          </div>

            <button nbButton ghost class="cursor-pointer changecolor viewclass mr-2" *ngIf="selectedDocs?.length && showDeleteUser()" nbTooltip="Delete" nbTooltipStatus="info"
            nbTooltipPlacement="bottom" (click)="opendelete(deleteDocsmyqueue)">
            <nb-icon icon="trash-2-outline"  [ngClass]="getClassIfDisabled()" class="changecolor"> </nb-icon>
            </button>


          <div *ngIf="selectedDocs.length > 0 && showDeleteUser()" class="line"></div>
        </div>
        <div class="activity-section ml-1 d-flex">
          <button nbButton ghost class="filterfunnel viewclass  " (click)="onFunnelClick()" nbTooltip="Search & filter"
            nbTooltipStatus="info" nbTooltipPlacement="bottom">
            <nb-icon [ngClass]="{'icon-active' : showSearch}" class=" cursor-pointer ml-0" icon="search-outline">
            </nb-icon>
          </button>
          <button nbButton ghost class="detailsection viewclass ml-1" (click)="detailsection()" nbTooltip="View details"
            nbTooltipStatus="info" nbTooltipPlacement="bottom" [ngClass]="{'disableCursorEventForProcessing' : selectedDocs.length>1}">
            <nb-icon [ngClass]="{'icon-active' : !hidedetail}" class="cursor-pointer ml-0" icon="file-text-outline">
            </nb-icon>
          </button>


        </div>
        <button nbButton ghost class="filterfunnel viewclass " (click)="openModalTemplate()" nbTooltip="Template Editor" [ngClass] ="templateColor ? 'icon-active': ''"
        nbTooltipStatus="info" nbTooltipPlacement="bottom" style="font-size: 23px;font-weight: 600;color: #585858; width: 20px;">#

      </button>
      <button nbButton *ngIf="shareAccessTags()" (click)="tagVerify()"  class= "Font AA/ Small  ml-2 "
     [status]="isTrue ? 'outline' : 'primary'" nbTooltipPlacement="bottom" >Tag & Verify

    </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<!------------------------------ Search and Filter Bar starts -------------------------->
<div class="row m-0 searchBar">
  <div class="searchBarfirstchild pl-0" [ngClass]="hidedetail && tagPanelOpen && previewPanelOpen ? 'col-12 pr-0' : 'col-xxxl-8 col-xxl-7 col-xl-7 col-l-7 col-md-7 col-sm-7 pr-0' ">
  <nb-card
    class="mb-0 allwsCard pl-0  searchBarfirstchildcard" >
    <nb-card-body class="p-0 overflow-hidden nbCardBody" [ngClass]="isDetailOpen ? '' : ''">
      <div class="ownedClass" *ngIf="showSpinner">
        <ngx-second-loader></ngx-second-loader>
       </div>
      <div *ngIf="listBg">

        <div class="row m-0" [ngClass]="showSpinner ? 'parentgrid' : ''">

          <div class="col-md-10 d-flex itemsperPage pt-3 pl-0 pr-0 mb-3" *ngIf="!docSelectedByUser">
            <p class="mb-0 pr-2  paragraph-1 text-basic ">Items per page :</p>
            <nb-select size="tiny" class="pagesize" (selectedChange)="onPageSizeChanged($event)"
              [(selected)]="selectedItem" id="pagesize" #drpSelectedValue class="pagesize">

              <nb-option value="50">50</nb-option>
              <nb-option value="100">100</nb-option>
              <nb-option value="200">200</nb-option>
              <nb-option value="500">500</nb-option>
            </nb-select>


          </div>

          <div class="col-md-10 d-flex itemsperPage docselected pt-3 pl-0 pr-0" *ngIf="docSelectedByUser">
            <p class="mb-0 pr-2  paragraph-1 text-basic ">{{docSelected}} documents selected</p>
            
          </div>


        </div>
        <div style="margin-top: 19px;display: flex;justify-content: flex-end;padding-right: 30px;">

          <nb-icon class="cursor-pointer searchiconarrow" (click)="onBtFirst()" icon="arrowhead-left-outline"></nb-icon>
          <nb-icon class="cursor-pointer searchiconarrow"  (click)="onBtPrevious()" icon="arrow-ios-back-outline"></nb-icon>
          <span class="value pr-1" id="lbCurrentPage">{{currentPage}}</span>
           of
          <span class="pl-1">
          {{totalPages}}
          </span>
            <nb-icon class="cursor-pointer searchiconarrow" (click)="onBtNext()" icon="arrow-ios-forward-outline"></nb-icon>
          <nb-icon class="cursor-pointer searchiconarrow"   (click)="onBtLast()" icon="arrowhead-right-outline"></nb-icon>

      </div>

        <div #myDiv class="ag-theme-balham" (click)="gridRow('clickinsideTable')" [ngClass]="showSpinner ? 'parentgrid' : ''">
          <div class="row">
            <div class="pt-3" [ngClass]="hidedetail ? 'col-12' : 'col-12'" #saveTags>
              <ag-grid-angular  [gridAutoHeight]="true" class="ag-theme-balham "  [rowClassRules]="rowClassRules" [rowClassRulesNoThumbnail]="rowClassRulesNoThumbnail"
                 [overlayNoRowsTemplate]="noRowsTemplate" [rowSelection]= "'multiple'" [rowMultiSelectWithClick]="false"
               (gridReady)="onGridReady($event)" [rowData]="rowData"  [sortingOrder]="sortingOrder"
                (cellClicked)="onCellClicked($event)" [enableBrowserTooltips]="false" [getRowNodeId]="getRowNodeId"
                [tooltipShowDelay]="tooltipShowDelay" headerHeight="48" [paginationPageSize]="50" [suppressRowDeselection]="true"
                [tooltipShowDelay]="tooltipShowDelay" (selectionChanged)="onSelectionChanged($event)"  (rowClicked)="onRowClick($event)"
                [enableBrowserTooltips]="true" (firstDataRendered)="onFirstDataRendered($event)" [rowHeight]=48
                (click)="getSelectedRowData()" domLayout="autoHeight" [getRowClass]="getRowClass"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"  [isRowSelectable]="isRowSelectable"
              (sortChange)="sortChange($event)"  (paginationChanged)="onPaginationChanged($event)"
              (rowDoubleClicked)="onRowDoubleClicked($event)" (bodyScroll)="hideThreeDotComp()"
               >

              </ag-grid-angular>


            </div>



          </div>


        </div>

      </div>

    </nb-card-body>
  </nb-card>
</div>

  <div class="col-xxxl-4  col-xxl-5 col-xl-5 col-l-5 col-md-5 col-sm-5 pr-0" >
    <div [hidden]="hidedetail">
      <ngx-detail-and-activity></ngx-detail-and-activity>
    </div>
    <div [hidden]="tagPanelOpen">
      <ngx-bulk-tag></ngx-bulk-tag>
    </div>
    <div [hidden]="previewPanelOpen">
      <ngx-doc-preview></ngx-doc-preview>
  </div>
</div>
</div>
</div>
</div>









<div>
  <ng-template #miniprofile let-data let-ref="miniprofile">
    <ngx-mini-profile [showdata]="showdata"></ngx-mini-profile>
  </ng-template>
</div>

<!---------------------- Delete Document Modal Start----------------------------->
<ng-template #deleteDocsmyqueue let-data let-ref="dialogRef">
  <nb-card class="modalnbdelete">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Delete document(s)
        </h6>


      </div>
    </nb-card-header>
    <nb-card-body class="padding-25">
      <p class="paragraph text-basic">Choose a reason for deleting selected document(s) to keep other users in your
        workspace updated.</p>
      <p class="text-primary paragraph">{{selectedDocs.length}} document(s) selected</p>
      <nb-radio-group [(ngModel)]="selectedOption "  >
        <nb-radio value="Poor scan quality" (click)="valueChange('Duplicate document')"  ><p class="deleteradiobtn P1 paragraph mb-0">Poor scan quality</p></nb-radio>
        <nb-radio value="Incorrect document" (click)="valueChange('Duplicate document')"><p class="deleteradiobtn P1 paragraph mb-0">Incorrect document</p></nb-radio>
        <nb-radio value="Duplicate document" (click)="valueChange('Duplicate document')"><p class="deleteradiobtn P1 paragraph mb-0" >Duplicate document</p></nb-radio>
        <nb-radio value="Other" (click)="valueChange('Other')" ><p class="deleteradiobtn P1 paragraph mb-0">Other</p></nb-radio>

      </nb-radio-group>
      <input nbInput id="OtherReason" #OtherReason maxlength="75" placeholder="Reason for deleting document (optional)"
        class="d-block" style="width: 60%;" *ngIf="Otherreason">
    </nb-card-body>
    <nb-card-footer>

      <button id="deleteModal" class="mr-3" status="primary" nbButton
        (click)="DeleteDocsModalResion(); ref.close();isdeleteSelectedOnce=false;HideViewDetailresponseForDelete=true; showSpinner = true;"
        size="small">DELETE</button>
      <button  (click)="ref.close();HideViewDetailresponseForDelete=true" status="control" nbButton
        class="controlbtndelt text-basic"><b>CANCEL</b></button>
    </nb-card-footer>

  </nb-card>
</ng-template>


<!-------------------------------Change Docs verifier------------------------------------>

<ng-template #chngVerifier chng-verify let-data let-ref="dialogRef">
  <nb-card class="chng-verify">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Change verifier
        </h6>


      </div>
    </nb-card-header>

    <nb-card-body>
      <p class="paragraph text-basic">Please select a user to make them the verifier of selected document(s).</p>

      <div class="row m-0">
        <div class="col-8 pl-0">
          <ng-select class="selectchangeverifier" [items]="filteredRightUserList" placeholder="Select user..." bindLabel="displayName"
            [closeOnSelect]="true" appendTo="body" bindValue="displayName" (change)="onChangeVerifier($event)"
            [ngModelOptions]="{standalone: true}" [ngModel]="selectedOwner?.displayName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p class="subtitle text-basic mb-0" title={{item.emailId}}>{{item.displayName}}</p>
            </ng-template>
          </ng-select>
        </div>
      </div>


    </nb-card-body>
    <nb-card-footer>
      <button class="mr-3" status="primary" nbButton
        (click)="onDocumentVerifierUpdate(); ref.close();isSelectedOnce = false; showSpinner=true;" size="small">CHANGE VERIFIER</button>
      <button  (click)="ref.close();isSelectedOnce = false" size="small" class="change-verfier-btn"
        size="small"><b class="text-basic">CANCEL</b></button>
    </nb-card-footer>
  </nb-card>

</ng-template>
<ng-template #wsOutSync let-data let-ref="dialogRef">
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Workspace out of sync </h6>
      </div>
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic">Your workspace went out of sync. There are some changes made which are not
        synchronized.
        Please reload this page before you continue.</p>
      <p class="mt-2 paragraph text-basic mb-0">This action cannot be completed as the document has been
        Deleted.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="textbutton" size="small"
        (click)="reloadScreen();dialogRef.close()">RELOAD</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ngx-template-editor [tagTempList]="tagTempList" [templateInput]="templateInput" [selectedTemplate]="selectedTemplate"
  [selectedTemplateVerify]="selectedTemplate" (tagTemplate)="tagTemplateEdit($event)"></ngx-template-editor>


  <!-- share modal start -->

  <ngx-shared-access #shareChild [ownerEmailID]="ownerEmailID" [allUserLength]="allUserLength"
  [verifierEmailID]="verifierEmailID" [sharedWithUsers]="sharedWithUsers" [isAllUserShare]="isAllUserShare"  (isSelectNoUser)="isSelectNoUser($event)"
  (isSelectedAllUser)="isSelectedAllUser($event)" (sharedGroupCount)="sharedGroupCount($event)"
  (isCheckedAllUser)="isCheckedAllUser($event)" (sharedGroup)="sharedGroup($event)"
  (isUserGrp)="isUserGrp($event)">
</ngx-shared-access>

  <!-- share modal end -->

  <!-- preview component -->
  <ngx-doc-preview #previewChild>

  </ngx-doc-preview>
  <!-- preview component end -->
