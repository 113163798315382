
 <nb-card [nbSpinner]="showSpinner" nbSpinnerStatus="primary" nbSpinnerSize="giant"  class="miniprofilemodal">
     <div class="miniprofilemodal">

     
      <nb-card-header class="pl-0 pr-0 pt-0">
       
        
          <div class="row showminiProfile">
            <div class="col-12">
                <img id="showIcon" [src]="urlshow" alt="image"  class="image imageminiprofile" >
               
                <p class="namedata color-basic leftside mb-0 profileHead" *ngIf="name">
                    {{name}}
                </p>
                <p class="namedata subtitle-2 mb-0" *ngIf="rolename">
                  <span class="color-hint"> {{rolename}}</span>
                </p>
                <p class="statusbudges  text-basic mb-0" *ngIf="status">
                    {{status}}
                </p>
                <div class="dcoins-div">
                    <div class="row padding-8">
                      <div class="col-md-6 left-side-rect">
                         <p class="dcoins-subtitle">My dCoins</p>
                         <a *ngIf="dcoinsOn" class="purchase-dcoins-subtitle" href="mailto:billing@dox2u.com">+ Add dCoins</a>
                         <a *ngIf="!dcoinsOn" class="purchase-dcoins-subtitle" (click)="openModal(upgradeModalDcoins)">+ Add dCoins</a>
                      </div>
                      <div class="col-md-6">
                        <h1 class="dcoins-title">{{totalDCoins}}</h1>
                      </div>
                    </div>
                    
                  </div>
            </div>
         </div>
     
        </nb-card-header>
        <hr>
      <nb-card-body class="pl-0 pr-0 miniscrollvalue">
             <div id="adaptiveheight" class="miniscrollvalue">
                <div class="row ml-3 mr-3" *ngIf="DisplayName">
                    
                    <div class="col-5 leftside">
                        <p class="text-basic leftside mb-0">Full name</p> 
                    </div>
                    <div class="col-7 pr-0 rightside paragraph-2 text-basic breakTheWord">
                        {{DisplayName}}
                    </div>
                 </div>
    
                 
                 <div class="row ml-3 mr-3" *ngIf="EmailId">
                   
                    <div class="col-5 leftside">
                        <p class="color-basic leftside mb-0">Email</p> 
                    </div>
                    <div class="col-7 pr-0 rightside paragraph-2 text-basic d-flex">
                        <div class="emailBox">
                        {{EmailId}}</div>
                        <span> <a class="text-primary cursor-pointer ml-2" (click)="copySignUp($event)">Copy</a></span>
                    </div>
                 </div>
    
             
                 <div class="row ml-3 mr-3" *ngIf="contactno">
                    
                    <div class="col-5 leftside">
                        <p class="color-basic leftside mb-0"> Contact number</p>
                    </div>
                    <div class="col-7 pr-0 rightside paragraph-2 text-basic breakTheWord">
                        {{contactno}}
                    </div>
                 </div>
    
                 
               
    
                
                 <div class="row ml-3 mr-3" *ngIf="groupname">
                    
                    <div class="col-5 leftside">
                        <p class="color-basic leftside mb-0">
                            Groups
                        </p> 
                    </div>
                    <div class="col-7 pr-0 rightside paragraph-2 text-basic groupval breakTheWord">
                        {{groupname}}
                    </div>
                 </div>
    
           
                 <div class="row ml-3 mr-3" *ngIf="whatIDo">
                   
                    <div class="col-5 leftside">
                        <p class="color-basic leftside mb-0 breakTheWord">
                            What I do
                        </p>
                        
                    </div>
                    <div class="col-7 pr-0 rightside paragraph-2 text-basic breakTheWord">
                        {{whatIDo}}
                    </div>
                 </div>
    
    
                 <div class="row ml-3 mr-3" *ngIf="whereiam">
                   
                    <div class="col-5 leftside">
                        <p class="color-basic leftside mb-0">
                            Where I am
                        </p>
                       
                    </div>
                    <div class="col-7  pr-0 rightside paragraph-2 text-basic breakTheWord">
                        {{whereiam}}
                    </div>
                 </div>
    
                
                 <div class="row ml-3 mr-3" *ngIf="localtime">
                   
                    <div class="col-5 leftside">
                         <p class="color-basic leftside mb-0">
                            Local time
                         </p>
                        
                    </div>
                    <div class="col-7 pr-0  rightside paragraph-2 text-basic breakTheWord">
                        <span class="mr-3">{{localtime1}}</span>
                         <span>{{localtime}}</span>  
                    </div>
                 </div>
             </div>
        
       
       </nb-card-body>
    </div>
    </nb-card>

    <!-- modal for the upgrade dcoins Start  -->
<ng-template #upgradeModalDcoins>
    <nb-card class="invitemodal">
      <nb-card-header>
        <div class="justify-content-between d-flex">
          <h6 class="subtitle text-basic mb-0"> Add dCoins </h6>
         </div> 
      </nb-card-header>
      <nb-card-body>
        <p class="paragraph text-basic mb-0">This functionality is not supported in your current subscription plan.<br> Please upgrade to an eligible plan and try again</p>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">UPGRADE NOW</button>
        <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <!-- modal for the upgrade dcoins End  -->
