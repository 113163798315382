<div [ngClass]="openFromViewer? 'verify': 'detaildata'" style="height: calc(100vh - 232px); width: -webkit-fill-available;">
    <nb-card [ngClass]="openFromViewer? 'verifyDiv': 'heightdetails'" (click)="detaildatabody('DETAILS')"  >
        <nb-card-body *ngIf="docIsDisplayed" class="pr-0 pl-0 detailactivity">
  
           <div class="row m-0 details1" >
               <div class="col-10 details2 pl-0" >
               <div class=" pr-0 col2" [hidden]="sanitizedUrl == 'No Data'">
                <div *ngIf="docrename" [hidden]="imageval" class="d-flex">
  
  
                    <button nbButton class="bg-transparent border-0 pt-0 shadow-none image16" >
                        <i *ngIf="fileextension == '.png'" class="sprite sprite-PNG" ></i>
                        <i *ngIf="fileextension=='.jpg' || fileextension == '.jpeg'" class="sprite sprite-JPG"></i>
                        <i *ngIf="fileextension=='.tiff' || fileextension== '.tif'" class="sprite sprite-TIF"></i>
                        <i *ngIf="fileextension=='.gif'" class="sprite sprite-GIF"></i>
                        <i *ngIf="fileextension =='.pdf'" class="sprite sprite-PDF"></i>
                        <i *ngIf="fileextension =='.Document' || fileextension== '.docx' || fileextension == '.doc'" class="sprite sprite-DOC"></i>
                        <i *ngIf="fileextension =='.txt'" class="sprite sprite-TXT"></i>
                        <i *ngIf="fileextension =='.rtf'" class="sprite sprite-RTF"></i>
                        <i *ngIf="fileextension =='.odt'" class="sprite sprite-ODT"></i>
                        <i *ngIf="fileextension =='.bmp'" class="sprite sprite-BMP"></i>
                        <i *ngIf="fileextension =='.pjpg'" class="sprite sprite-PJPG"></i>
                        <i *ngIf="fileextension =='.pjp'" class="sprite sprite-PJP"></i>
                        <i *ngIf="fileextension =='.dib'" class="sprite sprite-DIB"></i>
                        <i *ngIf="fileextension =='.dot'" class="sprite sprite-DOT"></i>
                        <i *ngIf="fileextension =='.text'" class="sprite sprite-TEXT"></i>
                        <i *ngIf="fileextension =='.jfif'" class="sprite sprite-JFIF"></i>
                        <i *ngIf="fileextension =='.xls'" class="sprite sprite-XLS"></i>
                        <i *ngIf="fileextension =='.fods'" class="sprite sprite-FODS"></i>
                        <i *ngIf="fileextension =='.xlsm'" class="sprite sprite-XLSM"></i>
                        <i *ngIf="fileextension =='.xlsx'" class="sprite sprite-XLSX"></i>
                        <i *ngIf="fileextension =='.ods'" class="sprite sprite-ODS"></i>
                        <i *ngIf="fileextension =='.slk'" class="sprite sprite-SLK"></i>
  
                    </button>
                   </div>
               </div>
            <div class="col8 pl-3" [hidden]="sanitizedUrl == 'No Data'">
                <div *ngIf="docrename" [hidden]="imageval" class="d-flex col8line">
                    <p class=" m-0 subtitle subtitle-s1 text-basic d-flex align-items-center col8linep">
                        {{docrename}}
                    </p>
                </div>
            </div>
        </div>
            <div class="pr-3 cursor-pointer col-2 crossplace">
                <nb-icon icon="close-outline" (click)="crossdetails()" class="cursor-pointer"></nb-icon>
            </div>
  
           </div>
  
           <!------------------------ View Details activity change ---------------------->
           <div [hidden]="sanitizedUrl == 'No Data'">
            <nb-tabset [hidden]="imageval"  (changeTab)="tabActive($event)">
                <nb-tab tabTitle="DETAILS" [ngClass]="openFromViewer? 'verifyList p-0 pr-2': 'listheight p-0 pr-2' " (click)="detaildatabody('DETAILS')" [nbSpinner]="detailsshowdata"  nbSpinnerStatus="primary"  nbSpinnerSize="tiny">
  
                        <!-- <div class="row pb-3 mt-2 m-0" *ngIf="(sanitizedUrl !== 'search data not shared ') && (sanitizedUrl !== '') && onlyFormat == ''" >
                            <div class="col-md-12">
                                    <img [src]="sanitizedUrl" class="ml-auto mr-auto d-block"  [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
                                <p *ngIf="detailArrayVar" class="pl-1 subtitle text-basic text-center">Select a file or folder to view its details</p>
                           </div>
                        </div>
                        <div class="row pb-3 mt-2 m-0" [hidden]="(sanitizedUrl !== 'search data not shared ') && (sanitizedUrl !== '') && onlyFormat == ''" >
                            <div class="col-md-12" >
                                  <span *ngIf="onlyFormat == 'tiff' || onlyFormat == 'tif'">
                                    <img src="../../../assets/images/SVG/TIF.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'txt'">
                                    <img src="../../../assets/images/SVG/TXT.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'bmp'">
                                    <img src="../../../assets/images/SVG/BMP.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'doc' || onlyFormat == 'docx'">
                                    <img src="../../../assets/images/SVG/DOC.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'gif'">
                                    <img src="../../../assets/images/SVG/GIF.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'jpg' || onlyFormat == 'jpeg'">
                                    <img src="../../../assets/images/SVG/JPG.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
                                  <span *ngIf="onlyFormat == 'odt'">
                                    <img src="../../../assets/images/SVG/ODT.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
                                  <span *ngIf="onlyFormat == 'pdf'">
                                    <img src="../../../assets/images/SVG/PDF.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'png'">
                                    <img src="../../../assets/images/SVG/PNG.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
                                  <span *ngIf="onlyFormat == 'rtf'">
                                    <img src="../../../assets/images/SVG/RTF.svg" class="ml-auto mr-auto d-block" [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
  
                                  </span>
  
  
                           </div>
                        </div>  -->
  
        <!-- shared access div -->
        <div *ngIf="sharedAccessSection">
          <p class="Text Font/S1 subtitle mb-0">Shared Access<span class="Text Font/P1 paragraph text-primary cursor-pointer" [class.disabled]="isModalOperationInProgress" (click)="disableModal();openShareCommonModal(shareChild)" *ngIf="!hideEdit && shareAccessValidate()" > (Edit)</span></p>
          <div class="row bottommargin d-flex align-items-center  m-0 pb-2">
            <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
            Users
         </div>
           <div class="col-7 colortext paragraph-2 d-flex" *ngIf="getUserName.length !== 0">
            <div class="d-block" style="width: 100%;">
              <!-- <p  *ngFor="let user of getUserName"  class=" colortext1 paragraph-2 text-primary  mb-0 cursor-pointer" style="display:inline-block !important; width: fit-content;"  (click)="getName(user.emailID); onCellClicked(user.emailID);" style="cursor: pointer;" > 
                {{user.name}}<span class="mr-1" *ngIf="getUserName.length !== 1 && getUserName.length !== 0"> ,</span>
              </p> -->
              <p class=" colortext1 paragraph-2 text-primary  mb-0 cursor-pointer" style="display:inline-block !important; width: fit-content;"  (click)="getName(user.emailID); onCellClicked(user.emailID);" style="cursor: pointer;" > 
                {{getUserName}}
              </p>
              </div>
           </div>
            <div class="col-7 colortext paragraph-2 text-basic" *ngIf="getUserName.length === 0  && !isAllStatus">
              Not Shared
  
            </div>
            <div class="col-7 colortext paragraph-2 text-basic" *ngIf="isAllStatus && getUserName.length === 0 ">
              Shared with everyone
  
            </div>
  
        </div>
        <div class="row bottommargin d-flex align-items-center  m-0 ">
          <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
          Groups
       </div>
  
          <div class="col-7 colortext paragraph-2 text-basic"  *ngIf="userAndGroupName">
         {{userAndGroupName}}
          </div>
          <div class="col-7 colortext paragraph-2 text-basic" *ngIf="getGroupName.length === 0 && !isAllStatus" >
            Not Shared
  
          </div>
          <div class="col-7 colortext paragraph-2 text-basic" *ngIf="isAllStatus && getGroupName.length === 0" >
            Shared with all groups
  
          </div>
  
      </div>
  
      <hr class="m-0" style="background-color: #E4E9F2; border-bottom: 1px solid  #E4E9F2; border-top: 0px; margin: 12px 0px !important">
        </div>
        <div *ngIf="guestAccessSection && GuestFlag === 'true' ">
          <p class="Text Font/S1 subtitle mb-0">Guest Access<span class="Text Font/P1 paragraph text-primary cursor-pointer" *ngIf="!hideEdit && shareAccessValidate() && guestAcessMethod()" [class.disabled]="isModalOperationInProgress" (click)="disableModal();GuestModalShare()"> (Edit)</span></p>
  
          <div class="row bottommargin d-flex align-items-center  m-0 " >
            <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
            Guest
         </div>
            <div class="col-7 colortext2 paragraph-2 text-basic" *ngIf="shareDocCount !== '0'" >
  {{guestemailid}}
       </div>
            <div class="col-7 colortext paragraph-2 text-basic" *ngIf="shareDocCount === '0'" >
               Not Shared
                </div>
        </div>
        <hr class="m-0" style="background-color: #E4E9F2; border-bottom: 1px solid  #E4E9F2; border-top: 0px; margin: 12px 0px !important;">
        </div>
        <div>
          <p class="Text Font/S1 subtitle mb-0">Doc Details</p>
  
  
  
  
  
  
  
          <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="originalname">
            <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0" >
               Original Name
            </div>
            <div class="col-7 colortext paragraph-2 text-basic text-truncate-max-width mx-width">
                 {{originalname}}
  
            </div>
   </div>
       <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="size">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
              Size
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
             {{size}}
           </div>
      </div>
  
       <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="dimension">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Dimensions
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
            {{dimension}}
           </div>
      </div>
           <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="statusvalue">
              <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
              Status
              </div>
              <div class="col-7 colortext paragraph-2 text-basic">
                 {{statusvalue}}
              </div>
        </div>
  
  
  
       <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="documentformate">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Format
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{documentformate}}
           </div>
       </div>
       <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="pages">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
            Pages in Doc
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{pages}}
           </div>
       </div>
  
  
    <!------------------------- parser related text extraction and language selected/detected ------------------>
    <div class="row bottommargin  d-flex align-items-center m-0 pb-2" *ngIf="(parserflag == 'True' && parserstatus) || (parserflag == 'False' && parserstatus && ((fileExtensionview === '.txt' || fileExtensionview === '.doc' || fileExtensionview === '.docx' || fileExtensionview === '.rtf' || fileExtensionview === '.odt') || (iseditableDocument == 1)))">
       <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Text Extraction
       </div>
       <div class="col-7 colortext paragraph-2 text-basic">
           {{parserstatus}}
       </div>
   </div>
  
  
  
  
  
  
       <div class="row bottommargin  m-0 pb-2 d-flex align-items-center" *ngIf="currentdocdisplayname">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Owner
           </div>
           <div class="col-7 colortext paragraph-2 text-primary cursor-pointer" style="cursor: pointer;" (click)="onCellClicked('owner')">
           {{currentdocdisplayname}}
           </div>
       </div>
  
       <div class="row bottommarginowner d-flex align-items-center  m-0 pb-2" *ngIf="uploadedby">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Uploader
           </div>
           <div class="col-7 colortext paragraph-2 text-primary" style="cursor: pointer;"  (click)="onCellClicked('uploaded_by')">
       {{uploadedby}}
           </div>
       </div>
       <div class="row bottommarginowner d-flex align-items-center  m-0 pb-2" >
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Verifier
           </div>
           <div class="col-7 colortext paragraph-2 text-primary" style="cursor: pointer;"  (click)="onCellClicked('verified_by')">
       {{verifierDisplayName}}
           </div>
       </div>
       <div class="row bottommargin  d-flex align-items-center m-0 pb-2" *ngIf="fileCreatedDate">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Created Date
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{fileCreatedDate}}
           </div>
       </div>
       <div class="row bottommargin  d-flex align-items-center m-0 pb-2" *ngIf="modifieddatetime">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
           Modified Date
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{modifieddatetime}}
           </div>
       </div>
  
       <div class="row bottommargin d-flex align-items-center m-0 pb-2" *ngIf="uploadeddatetime">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
             Uploaded On
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{uploadeddatetime}}
           </div>
       </div>
       <div class="row bottommargin  d-flex align-items-center m-0 pb-2" *ngIf="guestAccessSection">
           <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
               Added Date
           </div>
           <div class="col-7 colortext paragraph-2 text-basic">
           {{verifieddatetime}}
           </div>
       </div>
       <div class="row bottommargin  d-flex align-items-center m-0 pb-2" *ngIf="externalAssociations !== ''">
        <div class="col-5 colorfile Text Font/S2 subtitle-2 text-basic p-0">
          External Associations
        </div>
        <div class="col-7 colortext paragraph-2 text-basic">
         This file is attached to {{externalAssociations}}
        </div>
    </div>
        </div>
  
  
        <!-- new code end here -->
  
  
  
  
                </nb-tab>
                <nb-tab tabTitle="ACTIVITY"  class="listheight p-0 pr-2" (click)="activitydatabody('ACTIVITY')" >
                    <div class="row pb-3  activitycenter1" *ngIf="activityfeature == 'False'">
                        <div class="col-md-12     activitycenter2">
                            <img src="../../../assets/images/detail_plan.svg" class="ml-auto mr-auto d-block"  [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']">
                            <p class="detail-plan-upgrade-content">This feature is not available in your plan</p>
                               <button *ngIf="category === 'Owner'" nbButton status="primary" class="textbutton  mt-1" size="small" (click)="gotobilling()">UPGRADE NOW</button>
  
                       </div>
                    </div>
                    <div class="row pb-3  activitycenter1" *ngIf="activitytabdis">
                        <div class="col-md-12     activitycenter2">
                            <img src="../../../assets/images/Folder_img.svg" class="ml-auto mr-auto d-block"  [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']">
                               <p  class="  mt-3"  >This document is not shared with you</p>
  
                       </div>
                    </div>
                 <div *ngIf="activityfeature == 'True' && !activitytabdis">
                    <div *ngIf="uniquedata" class="mt-2">
  
                        <div *ngFor="let acthead Of uniquedata;let i=index">
  
                            <div class="row activitydescription pl-3">
                                <div class="col-12 ml-1">
                                  <div class="subtitle text-basic mainvalue" >
                                    {{acthead.activityDate.split(' ')[0]}}
                                  </div>
                                </div>
                            </div>
  
                            <div *ngFor="let actinnerlist Of uniquedata[i]['InnerList']">
                              <div class="row activitydescription pl-3">
                                <div class="col-12 ml-1">
                                    <div class="paragraph-2 text-basic">
                                        <div [innerHtml]="actinnerlist.logMessage"></div>
                                    </div>
  
                                </div>
  
  
                              </div>
                              <div class="row activitydescription pl-3">
                                <div class="col-12 ml-1">
                                    <div class="caption text-basic pb-3">
                                        {{actinnerlist.logDateTime}}
                                    </div>
                                </div>
                              </div>
                            </div>
                            <hr class="mt-0">
                         </div>
                    </div>
                 </div>
  
                </nb-tab>
  
              </nb-tabset>
           </div>
  
          <div class="row pb-3 imgediv" *ngIf="sanitizedUrl == 'No Data'" [nbSpinner]="detailsshowdata"  nbSpinnerStatus="primary"  nbSpinnerSize="tiny">
            <div class="col-md-12 imgecenterdiv"    >
                <img [src]="url" class="ml-auto mr-auto d-block"  [ngClass]="[lanscapevalue ? 'centervallandscape': 'centerval']" alt="">
                <p *ngIf="detailArrayVar" class=" pl-1 subtitle text-basic text-center">Select a file or folder to view its details</p>
           </div>
        </div>
  <div *featureFlag="'9'">
          <div *ngIf="imageval" class="listheight pl-3">
            <p class="imageinner">Select a file or folder to view its details</p>
          </div>
  </div>
        </nb-card-body>
        <nb-card-body *ngIf="docUnderProcessing || docFormatNotSupported || docIsLockedOrCorrupt  || docParkingQueue" class="previewActivity">
          <div class="alternative-content" *ngIf="docUnderProcessing">
            <div class="copyScreenLoader" [nbSpinner]="true" nbSpinnerStatus="primary" nbSpinnerSize="giant"></div>
            <div class="copyLoadingScreenText">We are still preparing your document for viewing. Please check again in some time</div>
          </div>
          <div class="alternative-content" *ngIf="docParkingQueue">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/SVG/hourclock.svg"></div>
            <div class="copyLoadingScreenText">Sorry, this is taking us longer than expected. We will get back to you soon.</div>
          </div>
          <div class="alternative-content" *ngIf="docFormatNotSupported">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/document_not_supported.svg"></div>
            <div class="copyLoadingScreenText">We’re sorry! The format for this document is not supported for viewing on dox2U as yet</div>
          </div>
          <div class="alternative-content" *ngIf="docIsLockedOrCorrupt">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/Padlock.svg"></div>
            <div class="copyLoadingScreenText">This document is either locked or corrupt and cannot be viewed on dox2U</div>
          </div>
        </nb-card-body>
      </nb-card>
  </div>
  
  
  
  <ngx-shared-access #shareChild [ownerEmailID]="ownerEmailID" [allUserLength]="allUserLength"
  [verifierEmailID]="verifierEmailID" [sharedWithUsers]="sharedWithUsers" [isAllUserShare]="isAllUserShare"
  (isSelectedAllUser)="isSelectedAllUser($event)" (sharedGroupCount)="sharedGroupCount($event)" [selectedMultiDocument]="selectedDocs"
  (isCheckedAllUser)="isCheckedAllUser($event)" (sharedGroup)="sharedGroup($event)"  (isSelectNoUser)="isSelectNoUser($event)"
  (isUserGrp)="isUserGrp($event)">
  </ngx-shared-access>
  
  <ngx-guest-user-modal></ngx-guest-user-modal>
  
  <ng-template #miniprofile let-data let-ref="miniprofile">
  <div class="d-flex justify-content-between float-right cursor-pointer headProfile"
    *ngIf="ownerEmailID === createdBy">
    <p class="headline-6 text-primary mb-0 text-right cursor-pointer" (click)="cancleEdit()" style="z-index: 7;">
      Edit Profile</p>
    <!-- <p class="headline-6 text-primary mb-0 text-right cursor-pointer " (click)="cancleEdit()"  *ngIf="!changeText">Edit Profile</p> -->
  </div>
  <ngx-mini-profile [showdata]="showdata"></ngx-mini-profile>
  </ng-template>
  