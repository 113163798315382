import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { environment } from '../.../../../../../src/environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { retry } from 'rxjs/operators';

import 'rxjs/add/observable/of';
import 'rxjs/Rx';
@Injectable({
  providedIn: 'root'
})
export class UserSettingService {

  constructor(private apiService:ApiServiceService,private http: HttpClient) { }
  public baseURL=environment.baseUrl;
  public baseOrigin = environment.baseOrigin;
   /**
 * method  for elastic base url
 */
   public elasticdbaseurl = environment.elasticdbaseurl;

// ****************** Get User Profile Details start here *******************

getUserProfileDetailsService(data) {
  return this.apiService.post( '/api/UserSettings/GetUserProfileDetails', data)
}

searchAllUsersService(data) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Access-Control-Allow-Origin', this.baseOrigin);
  headers.append('Access-Control-Allow-Credentials', 'true');
  headers.append('GET', 'POST');
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }) };
  return this.http.post(this.baseURL + '/api/AllUsers/Search', data, httpOptions).pipe(
    retry(3)
  );
}
// Change password starts
changePasswordService(data){
  return this.apiService.post( '/api/UserSettings/ChangePassword', data);
}

  //---- Get User Notification Details
  getNotificationService(data){
    return this.apiService.post('/api/UserSettings/GetUserNotificationSettings', data);
  }

 //---Update User Notification Details
 updateNotificationService(data) {
  return this.apiService.post('/api/UserSettings/UpdateNotification', data);
 }
   /**
 * method  for getting user image in profile
 */
   pdfViewerFileConversion(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFileStreamFromBlob', data, {responseType:'blob',observe: 'response'}).pipe(
      retry(0)
    );
  }

  // ****************** Email Validate start here *******************
  EmailValidate(data) {
  

  
    return this.apiService.post('/api/Account/Validate', data, )
   
  }

  forgotPassword(data) {
 

  
    return this.apiService.post('/api/Account/Forgot', data, )
     
  
  }
  // ****************** Forgot Password end here *******************

  // ****************** Reset Password start here *******************

  resetPassword(data) {
  

  
    return this.apiService.post('/api/Account/Reset', data, )
     
  
  }
  // ****************** Reset Password end here *******************


  // For support services

  supportService(formData) {
     return this.apiService.post('/api/UserSettings/InsertUserSupport', formData, )
   }


   elasticUpdation(formData) {
    return this.apiService.post('/api/DocumentVerification/UpdateProfileElastic', formData, )
  }

}
