import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private apiService:ApiServiceService) { }

  updateRight(data) {
   return this.apiService.post('/api/Role/UpdateRights', data);
  }

  roleAdd(data) {
   return this.apiService.post('/api/Role/Add', data);
  }

   //************************ API for add notify insert API Get End ************************

   notifyinsert(data) {
     return this.apiService.post('/api/GetNotificationUser/NotificationInsert', data);
  }
  notifyEmail(data) {
    return this.apiService.post('/api/Workspace/RoleWiseEmailID', data);
 }
   wsUpgrade(data) {
     return this.apiService.post('/api/OwnedByMe/WSUpgradePlan', data);
  }
  notifysend(data) {
    return this.apiService.post( '/api/Notification/UserSendMessage', data);
  }
  roleDefault(data) {
    return this.apiService.post('/api/Role/Default', data);
  }

  roleCopy(data) {
    return this.apiService.post('/api/Role/Copy', data);
  }
  roleRename(data) {
    return this.apiService.post('/api/Role/Rename', data);
  }
  roleDelete(data) {
     return this.apiService.post('/api/Role/Delete', data);
  }


}
