/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Feature flag Service
///  Description: used to set features in the localstorage
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is of alignment of feature flags on basic of unqiueIDs
///
///------------------------------------------------------------------->
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FeatureConfig } from "../../@core/interface/feature-flag.interface";
import { DashboardService } from "./dashboard.service";
import confData from "../../@core/data/feature-falg.config.json"
import { SignInService } from "../sign-in.service";

/**
 * Injectable for feature flagging
 */
@Injectable({
  providedIn: "root"
})
/**
 * export for feature flagging
 */
export class FeatureFlagsService {
  /**
 * variable for feature flagging
 */
  config: FeatureConfig = null;
 /**
 * variable for feature flagging url
 */
  configUrl = confData;
   /**
 * variable for workSpaceId
 */
  workSpaceId: any;
     /**
 * variable for createdBy
 */
  createdBy: any;
  /**
 * variable for feature
 */
  feature: any;
  /**
      * git ignore on onInit
      */
  constructor(private http: HttpClient, private data: DashboardService, private signInService: SignInService) {
    if (localStorage.getItem('localwsId')) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    }
    this.loadConfig();
    this.workspaceDetails();
   }

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   * from JSON
   */

  /**
  * feature flagging data from API
  * @param key
  * @returns
  */
      /**
 * variable for getallparseflag
 */
   getallparseflag;
    /**
 * method for loadConfig
 */

    workspaceDetails() {
      let wsoid = localStorage.getItem('localwsId');
      let postData = {
        wsoid: Number(this.workSpaceId)
      }
      this.signInService.getWSDetails(postData).subscribe((response: any) => {
        if(response.isSuccess) {
          localStorage.setItem('maxFreeUsers', response.data[0].freeUser);
          localStorage.setItem('addOnUsers', response.data[0].addOnUser);
        }
      })
    }
  loadConfig() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;
    postData = {
      wsoid: Number(this.workSpaceId),
      createdBy: this.createdBy
    };
    this.data.getWsFeatureflagging(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.config = response.data;
        this.getallparseflag = response.data;
        for (let i = 0; i <= this.getallparseflag.length - 1; i++) {
          if (this.getallparseflag[i].uniqueID == 'FID-0013') {
            localStorage.setItem('parserflag', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-0018') {
            localStorage.setItem('GuestFlag', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-0063') {
            localStorage.setItem('dcoins', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-0064') {
            localStorage.setItem('WhatsappFlag', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-007') {
            localStorage.setItem('auditLogDuration', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-004') {
            localStorage.setItem('addDomainGS', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID === 'FID-0062') {
            localStorage.setItem('downloadExtractedText', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-002') {
           localStorage.setItem('freeDpage', this.getallparseflag[i].isActive);
         }
          if (this.getallparseflag[i].uniqueID == 'FID-003') {
           localStorage.setItem('trialFree', this.getallparseflag[i].isActive);
          }
         if (this.getallparseflag[i].uniqueID == 'FID-0061') {
           localStorage.setItem('ViewExtractedText', this.getallparseflag[i].isActive);
         }
        //  if (this.getallparseflag[i].uniqueID == 'FID-001') {
        //    localStorage.setItem('maxFreeUsers', this.getallparseflag[i].totalMemberAllow);
        //  }
          if (this.getallparseflag[i].uniqueID == 'FID-006') {
            localStorage.setItem('groupManagement', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-008') {
            localStorage.setItem('activityfeature', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-005') {
            localStorage.setItem('roleManagement', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID == 'FID-0031') {
            localStorage.setItem('bulkTagging', this.getallparseflag[i].isActive);
          }
          if (this.getallparseflag[i].uniqueID === 'FID-0051') {
            localStorage.setItem('dList', this.getallparseflag[i].isActive);
            localStorage.setItem('Response', this.getallparseflag[i].isActive);

          }
          if(this.getallparseflag[i].uniqueID === 'FID-0061'){
            localStorage.setItem('Viewextractedtext',this.getallparseflag[i].isActive);
          }
        }
      }
    });
  }

    /**
 * method for isFeatureEnabled
 */
  isFeatureEnabled(key) {

    this.feature = this?.config?.find(o => o?.subscriptionType === key);
    if (this?.feature?.isActive === 'True'){
      return true
    } else{
      return false
    }
  }
}
