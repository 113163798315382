/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Header component
///  Description: Contains logic for header and also used to set features flags in the localstorage
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is of alignment of feature flags on basic of unqiueIDs
///
///------------------------------------------------------------------->
import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef,DoCheck, OnChanges, TemplateRef, ViewChild, HostListener, ElementRef, Optional, SimpleChanges } from '@angular/core';
import { NbContextMenuDirective, NbDialogRef, NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService,NbToastrService } from '@nebular/theme';
import { MiniProfileService } from '../../../@core/utils/mini-profile.service';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil,filter } from 'rxjs/operators';
import {  Observable, of, Subject, Subscription } from 'rxjs';
import { MENU_ITEMS } from "../../../../app/pages/pages-menu";
import { SignUpService } from '../../../@core/sign-up.service';
import { Router,ActivatedRoute,RoutesRecognized } from '@angular/router';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NbDateService} from '@nebular/theme';
import {WorkspaceAdminService} from '../../../@core/workspace-admin.service'
import { WorkspaceManagementService } from '../../../@core/utils/workspace-management.service' ;
import { UserSettingService } from '../../../@core/utils/user-setting.service';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { SharedService } from '../../../@core/utils/shared.service'
import { NormalSearchService } from '../../../@core/utils/normal-search.service';
import { MyQueueService } from '../../../@core/utils/my-queue.service';
import { DatePipe, Location } from '@angular/common';
import { SharedServiceService } from '../../../shared-service.service';
import { CookieService } from 'ngx-cookie-service';
import { SupportComponent } from './support/support.component';
import moment, { invalid } from 'moment';
import { DashboardComponent } from '../../../pages/dashboard-comp/dashboard/dashboard.component';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DashboardService } from '../../../@core/utils/dashboard.service';
import { SignInService } from '../../../@core/sign-in.service';
import { NgSelectComponent } from '@ng-select/ng-select';

 /**
  * Component header
  */
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
 /**
  * HeaderComponent component
  */

export class HeaderComponent implements OnInit, OnDestroy {
   /**
  * codec Variable
  */
  codec = new HttpUrlEncodingCodec;
   /**
  * workSpaceId Variable
  */
  workSpaceId: any;
   /**
  * deep search feature flag Variable
  */
   deepSearch = false;
   /**
  * wsSubscriptionRemainDaysCount Variable
  */
  wsSubscriptionRemainDaysCount;
    /**
  * createdBy Variable
  */
  createdBy: string;
    /**
  * isLoadingUserList Variable
  */
  isLoadingUserList = true;
   /**
  * wsName Variable
  */
  wsName: any;
   /**
  * showSpinner Variable
  */


  showSpinner= true;
   /**
  * checkstatus Variable
  */
  checkstatus:boolean=true;
   /**
  * searchText Variable
  */
  searchText;
   /**
  * imageUrl Variable
  */
  imageUrl:any;
   /**
  * changePassword Variable
  */
  changePassword:boolean;
   /**
  * notification Variable
  */
  notification:boolean=false;
     /**
  * NormalSearchValue Variable
  */
  NormalSearchValue = '';
   /**
  * AdvSearchValue Variable
  */
  AdvSearchValue:any;
     /**
  * AdvDocContains Variable
  */
  AdvDocContains:any;
      /**
  * destroy Variable
  */
  public destroy$: Subject<void> = new Subject<void>();
       /**
  * userPictureOnly Variable
  */
  userPictureOnly: boolean = false;
     /**
  * user Variable
  */
  user: any;
    /**
  * checked1 Variable
  */
  checked1 = true;
      /**
  * rangeDate Variable
  */
  // rangeDate={
  //   /**
  //    * start
  //    */
  //   start : '' ,
  //   /**
  //    * end */ end : ''};
  rangeDate;
       /**
  * listArray Variable
  */
  listArray = [];
       /**
  * sum Variable
  */
  sum = 50;
    /**
  * direction Variable
  */
  direction = "";
     /**
  * themes Variable
  */
  themes = [
    /**
     * default
     */
    {
      value: 'default',
      name: 'Light',
    },
      /**
     * dark
     */
    {
      value: 'dark',
      name: 'Dark',
    },
     /**
     * cosmic
     */
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
      /**
     * corporate
     */
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
   /**
  * currentTheme Variable
  */
  currentTheme = 'default';
    /**
  * SupportComponent Component
  */
  @ViewChild(SupportComponent) supportChild: SupportComponent;
  /**
  * Dashboard Component
  */
   @ViewChild(DashboardComponent) DashboardData: DashboardComponent;
   /**
  * mini profile Component
  */
  @ViewChild('miniprofile', { read: TemplateRef }) miniprofile:TemplateRef<any>;
    /**
  * mini profile view Component
  */
  @ViewChild('miniprofileview', { read: TemplateRef }) miniprofileview:TemplateRef<any>;
 /**
  * userMenu variable
  */
  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
   /**
  * hide notify variable
  */
  hidenotify=true;
    /**
  * docTypeList variable
  */
  docTypeList=[
    /**
     * Any
     */
    { name: 'Any', format: '' },
    /**
     * Png
     */
    { name: 'PNG', format: 'Image' },
     /**
     * JPG/JPEG
     */
    { name: 'JPG/JPEG', format: 'Image' },
     /**
     * BMP
     */
    { name: 'BMP', format: 'Image' },
    /**
     * GIF
     */
    { name: 'GIF', format: 'Image' },
     /**
     * TIF/TIFF
     */
    { name: 'TIF/TIFF', format: 'Image' },
     /**
     * TXT
     */
    { name: 'TXT', format: 'Document' },
    /**
     * RTF
     */
    { name: 'RTF', format: 'Document' },
     /**
     * DOC/DOCX
     */
    { name: 'DOC/DOCX', format: 'Document' },
     /**
     *ODT
     */
    { name: 'ODT', format: 'Document' },
     /**
     *PDF
     */
    { name: 'PDF',format: 'PDF' },
  ];
    /**
  * dateSelectList variable
  */
  dateSelectList=['Anytime','Today','Yesterday','Last 7 days','Last 30 days','Last 90 days','Custom..']
   /**
  * advFilterDropdown variable
  */
  advFilterDropdown : boolean;
   /**
  * searchUserData variable
  */
  searchUserData: any;
     /**
  * anyoneClick variable
  */
  anyoneClick=false;
    /**
  * anyoneClickShare variable
  */
  anyoneClickShare=false;
   /**
  * docTagsValueEnd variable
  */
  docTagsValueEnd=''
   /**
  * selectOwner variable
  */
  selectOwner;
    /**
  * selectShare variable
  */
  selectShare;
    /**
  * lengthOwner variable
  */
  lengthOwner: any;
    /**
  * customDropDownn variable
  */
  customDropDownn=false;
    /**
  * fromDate variable
  */
  fromDate: any;
     /**
  * toDate variable
  */
  toDate: any;
    /**
  * searchLength variable
  */
  searchLength: any;
     /**
  * validSearchLength variable
  */
  validSearchLength=false;
  /**
  * fromDates variable
  */
  fromDates: any;
   /**
  * toDates variable
  */
  toDates: any;
   /**
  * isTypeSelected variable
  */
  isTypeSelected;
  /**
  * loading variable
  */
  loading = false;
    /**
  * AdvSearchValueContains variable
  */
  AdvSearchValueContains;
    /**
  * DateInput variable
  */
  DateInput: any;
  /**
  * searchresultdata variable
  */
  searchresultdata: any;
   /**
  * advSearchClickValue variable
  */
  advSearchClickValue=false;
  /**
  * subscriptionOwnedBy variable
  */
  subscriptionOwnedBy: any;
   /**
  * firstLevel variable
  */
  firstLevel= 'Dashboard';
    /**
  * breadCrumnSubs variable
  */
  breadCrumnSubs: any;
    /**
  * secondLevel variable
  */
  secondLevel: string;
    /**
  * thirdLevel variable
  */
  thirdLevel: string;
   /**
  * href variable
  */
  href;
    /**
  * subscription variable
  */
  subscription: any;
  /**
  * dataOwn variable
  */
  dataOwn;
  /**
  * subscriptions variable
  */
  subscriptions: any;
   /**
  * subscriptionsDoc variable
  */
  subscriptionsDoc: any;
    /**
  * subscribeVisible variable
  */
  subscribeVisible :any;
  /**
  * dataShare variable
  */
  dataShare;
    /**
  * dataFromat variable
  */
  dataFromat;
    /**
  * iconUrl variable
  */
  iconUrl: any;
   /**
  * subscriptionsDate variable
  */
  subscriptionsDate: any;
    /**
  * dataDate variable
  */
  dataDate;
   /**
  * subscriptionMonth variable
  */
  subscriptionMonth: any;
   /**
  * cabinetBreadcrumbMonth variable
  */
  cabinetBreadcrumbMonth: unknown;
    /**
  * subscriptionWeek variable
  */
  subscriptionWeek: any;
    /**
  * cabinetBreadcrumbWeek variable
  */
  cabinetBreadcrumbWeek: unknown;
  /**
  * subscriptionYear variable
  */
  subscriptionYear: any;
    /**
  * cabinetBreadcrumbYear variable
  */
  cabinetBreadcrumbYear: unknown;
   /**
  * subscriptionOwned variable
  */
  subscriptionOwned: any;
    /**
  * subscriptionown variable
  */
  subscriptionown: any;
   /**
  * cabinetBreadcrumbOwn variable
  */
  cabinetBreadcrumbOwn: unknown;
   /**
  * subscriptionsAdded variable
  */
  subscriptionsAdded: any;
   /**
  * addeddatebreadcrumbValue variable
  */
  addeddatebreadcrumbValue: unknown;
     /**
  * crumbVisible variable
  */
  crumbVisible;
     /**
  * subscriptionsVisible variable
  */
  subscriptionsVisible: any;
   /**
  * thirdLevelBread variable
  */
  thirdLevelBread: string;
     /**
  * subscriptionSearch variable
  */
  subscriptionSearch: any;
    /**
  * nb-context-menu component
  */
  @ViewChild("nb-context-menu") threeDotTrash!:any
    /**
  * NbContextMenuDirective component
  */
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
    /**
  * unsavedEditchanges component
  */
  @ViewChild('unsavedEditchanges', { read: TemplateRef }) unsavedEditchanges: TemplateRef<any>;
     /**
  * unsavedChangesOnCancle component
  */
  @ViewChild('unsavedChangesOnCancle', { read: TemplateRef }) unsavedChangesOnCancle: TemplateRef<any>;
     /**
  * unsavedChanges component
  */
  @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>; // Note: TemplateRef
     /**
  * cropperModal component
  */
  @ViewChild('cropperModal') cropperModal: TemplateRef<any>;
    /**
  * subscriptionHeader var
  */
subscriptionHeader = new Subscription;

public previousRoutePath = new BehaviorSubject<string>('');
  /**
  * items var
  */
  items = [
    {
      title: 'Mark as read',
      icon: { icon: 'done-all-outline', pack: 'eva' },
    },
     {
      title: 'Delete',
      icon: { icon: 'trash-2-outline', pack: 'eva' },
    },
  ]
   /**
  * wsSubscriptionPlan var
  */
  wsSubscriptionPlan: any;
   /**
  * loginProfile var
  */
  loginProfile = true;
    /**
  * isUnsavedChangePW var
  */
  isUnsavedChangePW: boolean;
    /**
  * isUnsavedChangeNotify var
  */
  isUnsavedChangeNotify: boolean;
   /**
  * croppedImage var
  */
  croppedImage: string;
     /**
  * showdata var
  */
  showdata: { wsoid: number; emailId: any; originalFileName: any; };
     /**
  * subscriptionmini var
  */
  subscriptionmini: Subscription;
     /**
  * removePressed var
  */
  removePressed: boolean;
    /**
  * formateError var
  */
  formateError: boolean=false;
     /**
  * enableSave var
  */
  enableSave: boolean=false;
    /**
  * timeZone var
  */
  timeZone: any;
    /**
  * contactNo var
  */
  contactNo: any;
   /**
  * profileImagePath var
  */
  profileImagePath: any;
   /**
  * subscriptionSidebar var
  */
  subscriptionSidebar: Subscription;
    /**
  * toggleRight var
  */
  toggleRight = true;
  isNotificationDeleted: boolean =true;
  NormalSearchValueInput: boolean;



   /**
  * isSearchFocused var
  */
  isSearchFocused = false;
   /**
  * selectedOwnerEmailid var
  */
  selectedOwnerEmailid: any;
  /**
  * selectedOwnerDisplayName var
  */
  selectedOwnerDisplayName;
   /**
  * searchValue var
  */
  searchValue: string[];
     /**
  * selectedSharedEmailid var
  */
  selectedSharedEmailid: any;
     /**
  * selectedSharedDisplayName var
  */
  selectedSharedDisplayName;
    /**
  * searchValueShared var
  */
  searchValueShared: string[];
   /**
  * NormalSearchValueArray var
  */
  NormalSearchValueArray=[];
    /**
  * sharedSplit var
  */
  sharedSplit: string;
   /**
  * sharedValue var
  */
  sharedValue: string;
    /**
  * ownedSplit var
  */
  ownedSplit: string;
   /**
  * ownerValue var
  */
  ownerValue: string;
   /**
  * email Shared Validate var
  */
  emailSharedValidate: any;
   /**
  * email Owned Validate var
  */
  emailOwnedValidate: any;
   /**
  * typeSplit var
  */
  typeSplit: string;
   /**
  * type Value var
  */
  typeValue: string;
  /**
  * type Validate var
  */
  typeValidate: any;
   /**
  * parse flag var
  */
  parseFlag;
   /**
  * doc Name Value var
  */
  docNameValue: any;
    /**
  * doc Tags Value var
  */
  docTagsValue: any;
   /**
  * name Split var
  */
  nameSplit: string;
   /**
  * name Split Contain var
  */
  nameSplitContain: string;
   /**
  * checked2 var
  */
  checked2: boolean;
    /**
  * rangeDateTags var
  */
  rangeDateTags;
   /**
  * docTagsNotes var
  */
  docTagsNotes: any;
  /**
  * doc Tags Value Start var
  */
  docTagsValueStart=undefined;
   /**
  * Include the Value var
  */
  IncludetheValue= undefined;
     /**
  * Normal Search Value Array includes var
  */
  NormalSearchValueArrayincludes=[];
  /**
   * url Query Params Var
   */
  urlQueryParams;
   /**
   * already name Var
   */
  alreadyname= false;
     /**
   * valid Search Length Tags Var
   */
  validSearchLengthTags: boolean;
   /**
   * Normal Search Value Prev Var
   */
  NormalSearchValuePrev: string;
    /**
   * search Length Tags Var
   */
  searchLengthTags: any;
   /**
   * double Inverted Commas Var
   */
  doubleInvertedCommas: any[];
  /**
   * without double Inverted Commas Var
   */
  withoutdoubleInvertedCommas: any[];
   /**
   * Normal Search Value Array Owner Var
   */
  NormalSearchValueArrayOwner=[];
    /**
   * Normal Search Value Array Contain Var
   */
  NormalSearchValueArrayContains=[];
    /**
   * Normal Search Value Array Tags Notes Var
   */
  NormalSearchValueArrayTagsNotes=[];
    /**
   *Normal Search Value Array Shared Var
   */
  NormalSearchValueArrayShared=[];
    /**
   *Normal Search Value Array Date Var
   */
  NormalSearchValueArrayDate=[];
    /**
   *Normal Search Value Array Type Var
   */
  NormalSearchValueArrayType=[];
    /**
   *Normal Search Value Array Name Var
   */
  NormalSearchValueArrayName=[];
  targetdata: any;
  suggestedGuideline: any;
  nameTagValue: any;
  workSpacePlanName: string;
  searchIds: string;
  withContainCount: any;
  withoutContainCount: any;
  normalSearchCount: any;
  advanceSearchCount: any;
  correctvalue: boolean =false;
  correctvalue1: boolean = false;
  isblue: boolean;
  menuRights: any;
  show_getstarted: boolean=true;
  getcountdata: any;
  checkListCompleted: boolean = true;
  subTempValue: any;
  oncardclick12: string;
  advFilterDropdownSelect: boolean=false;
  dateId: any;
  typeValueData: any;
  selectedItem: string;
  sidebarMenuClicked: boolean;
  selectedEvent: any;
  dateBoxClicked: boolean;
  getCalenderId: any;
  onSearchBtnClicked: boolean = false;selectedFormat: any;
  rowDatatarget: any;
  public menu = MENU_ITEMS;
  scrollingPreviousValue: any;
  @ViewChild("hideMenu", { read: ElementRef, static: false })
  hideMenu: ElementRef;
  scrollingCurrentValue: any;
  totalDCoins: number = 0;
  dcoinsOn: boolean = false;
  workspaceImg: any;
  
;
 /**
   *Constructor to define Public
   */

    constructor(@Optional() public userSettingsRef: NbDialogRef<any>,public sharedservice : SharedServiceService,public sidebarService: NbSidebarService,public sharedService : SharedService,
              public menuService: NbMenuService,
              public themeService: NbThemeService,
              private signInService: SignInService,
              private myqueueservice: MyQueueService,
              public route :ActivatedRoute,
              private changingValue: DashboardService,
              public _eref: ElementRef,
              public location:Location, public cookieService: CookieService,
              public searchService : NormalSearchService,
              public miniProfile:MiniProfileService,
              public subdata:WorkspaceAdminService,
              public toastrService: NbToastrService,
              protected dateService: NbDateService<Date>,
              public userService: UserData,public router: Router,
              public layoutService: LayoutService,public signUpService:SignUpService,
              public breakpointService: NbMediaBreakpointsService, public cd: ChangeDetectorRef,public eRef: ElementRef,
              public http:HttpClient, public data: WorkspaceManagementService, public dialogService: NbDialogService, public datanew:UserSettingService, public sanitizer: DomSanitizer) {
              this.workSpaceId = localStorage.getItem('localwsId')
              this.wsName = localStorage.getItem('WorkSpaceName')
              this.createdBy = localStorage.getItem('localloginId');
              this.parseFlag = localStorage.getItem('parserflag');
              this.wsSubscriptionPlan = localStorage.getItem('wsSubscriptionPlan')
              this.ediimageUrl = '../../../assets/images/Default_Profile_Photo.svg';

                if (!this.createdBy) {
                  this.router.navigateByUrl('/onboarding/sign-in');
                }
      let self = this;
      window.addEventListener("keydown", function (event) {

        switch (event.key) {
          case "/":
            if (!self.isSearchFocused){
              if ((self.NormalSearchValue === '' || self.NormalSearchValue === null) && !self.isSearchFocused) {
                self.headerSearch.nativeElement.focus();
                setTimeout(() => {
                  self.NormalSearchValue = null;
                  self.cd.detectChanges();
                }, 1);

                self.cd.detectChanges();

              } else {
                self.headerSearch.nativeElement.focus();
                self.NormalSearchValuePrev = self.NormalSearchValue;
                setTimeout(() => {
                  self.NormalSearchValue = self.NormalSearchValuePrev;
                  self.cd.detectChanges();
                }, 1);
              }
            }
        }


      });


  }
 /**
   *show new notify Var
   */
  shownewnotify;
   /**
   *header Search Component
   */
  @ViewChild("headerSearch") headerSearch: ElementRef;
   /**
   *mouse move Component
   */
  @HostListener('document:mousemove', ['$event'])
     /**
   *key down Component
   */
  @HostListener('document:keydown', ['$event'])
    /** 
   * mouse enter Function
   */
  mouseenter() {
      let getmouseenter = JSON.parse(localStorage.getItem('mintues'));
      if(localStorage.getItem('newnotify')){
        this.getallbudgescount();
        this.shownewnotify = localStorage.getItem('newnotify');
        localStorage.setItem('shownotifynew' , this.shownewnotify);
        localStorage.removeItem('newnotify');
        this.newnotification();
      }

}
 /**
   * text var
   */
public text: String;



 /**
   * change Text var
   */

  changeText: boolean;
   /**
   * image Url Static var
   */
  imageUrlStatic:any;
   /**
   * image New Url var
   */
  imageNewUrl:any;
    /**
   * edit image Url var
   */
  ediimageUrl:any = '../../../assets/images/Default_Profile_Photo.svg';
    /**
   * login Profil Image var
   */
  loginProfilImage = '../../../assets/images/Default_Profile_Photo.svg';
   /**
   *  Full name var
   */
  Full_name:any
    /**
   *  display name var
   */
  display_name:any
   /**
   *  cabinet Breadcrumb tAG var
   */
  cabinetBreadcrumbTags;
  /**
   *  subscription tags var
   */
  subscriptiontags;
  /**
   *  subscription Label var
   */
  subscriptionlabel;
    /**
   *  cabinet Breadcrumb label var
   */
  cabinetBreadcrumblabel;
     /**
   *  cabinet Breadcrumb owned var
   */
  cabinedBreadcrumbOwned;
    /**
   *  show Cropper var
   */
  showCropper = false;
     /**
   *  subscription value var
   */
  subscriptionvalue;
      /**
   *  cabinet Breadcrumb value var
   */
  cabinetBreadcrumbvalue;
   /**
   *  show Icon Crad var
   */
  showIconCrad=true;
   /**
   * gettag2card var
   */
  gettag2card;
    /**
   * gettag3card var
   */
  gettag3card;
    /**
   * gettag1card var
   */
  gettag1card;
  /**
   * subhelpcenter var
   */
  subhelpcenter;
  /**
   * verify url var
   */
  verifyurl = false;
    /**
   * show Spinner notification url var
   */
  showSpinnernotification = false;

  imageUrlExists: boolean = false;
    /**
   * subscription content var
   */
  subscriptioncontent;
   /**
   * ngOnInit function
   */
   innerwidth =  window.innerWidth;
   hideDiv= false;
   @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  ngOnInit() {

    this.sharedService.transferData.subscribe((res) => {
      if(res=='refreshDcoinsData')
      {
       this.ngOnInit()
      }
    });
    
    this.getDCoins();
    this.sharedService.searchString.subscribe(()=>{
      this.NormalSearchValue=''
    })
    if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
      this.hideDiv = true;
    }
    this.MyDocListcount();
    this.getSelectedItem();
    this.showSpinner = false;


    this.dashboardcards();
    this.sharedService.miniProfileClicked.subscribe(()=>{
      this.cancleEdit();
    })
    this.changeText = false;
    //this.imageUrl = localStorage.getItem('imageUrl') ? localStorage.getItem('imageUrl') : '';
    this.workspaceStatus()
    // this.wsIconBlobGenerator();
       this.sharedservice.userProfileEmitter.subscribe((data) => {
      this.GetTimeZone(this.UserSetingModal);
      this.userSettingsRef = this.dialogService.open(this.UserSetingModal,{  closeOnEsc: false,
        closeOnBackdropClick: false });

 });
    this.ediimageUrl = localStorage.getItem('ediimageUrl') ? localStorage.getItem('ediimageUrl') : '../../../assets/images/Default_Profile_Photo.svg';
    this.getUserImage();

 this.sharedservice.userSettingEmitter.subscribe((data) => {
  this.dialogRef = this.dialogService.open(this.Password, { closeOnBackdropClick: false, closeOnEsc: false });

});
    this.sharedService.subscriptionPlan.subscribe((data) => {
      this.wsSubscriptionPlan = data;
      this.cd.detectChanges();
    });
  this.sharedService.menuRights.subscribe((data) => {
  this.workspaceMenuRight(data)
  this.MyDocListcount();


});


    this.thirdLevelBread="General Settings"


    this.href = this.router.url;
    this.crumbVisible= localStorage.getItem('crumVisibles');
    if(this.href.includes('verification/verify-document') || this.href.includes('my-trash/my-trash-document') || this.href.includes('view/view-document') || this.href.includes('edit/edit-document')){
      this.verifyurl = true;
    }
    if (this.href === '/pages/cabinet')
    {
      this.firstLevel = 'Common Cabinet'
    }
    if(this.imageUrl == 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D'){
      this.showIconCrad = false;
      this.cd.detectChanges();
    }


    this.menuService.onItemClick().pipe(
      filter(({ tag }) => tag === "my-context-menu")
    )
    .subscribe((option) => {
      this.notifyActions(option.item["id"]);
    });
     this.subscriptionOwnedBy = this.sharedservice.globalSearchEmitter.subscribe((data) => {
       this.headerAdvSearch('ngOnInit','','searchclickoninit');
     });
     this.subscriptioncontent = this.sharedService.contentBaseData.subscribe((data) => {
      this.headerAdvSearch('ngOnInit','content','searchclickoninit');
    });
    this.subscriptioncontent = this.sharedService.nameBaseData.subscribe((data) => {
      this.headerAdvSearch('ngOnInit','name','searchclickoninit');
    });
    this.subscriptionSidebar = this.sharedservice.paginationChanging.subscribe((data) => {
      this.targetdata = data;
      let splitcurrent = this.targetdata?.split(',');
      let temp = {
        currentpage : splitcurrent[0],
        pageSize : splitcurrent[1]
      }
      this.headerAdvSearch('pagechange','',temp);

     });
     this.subscriptionSearch = this.sharedService.OnLeftItemClick.subscribe((data) => {
       this.NormalSearchValue = '';
     });
     this.subscription = this.sharedService.searchRemoveChipOwn.subscribe((data) => {
       this.dataOwn = data;
       let isSharedIndex
       this.dataOwn?.split(',').forEach(element => {
        isSharedIndex  =  this.selectOwner.findIndex((e)=>{
        return e === element;
      });
    });
     this.selectOwner.splice(isSharedIndex, 1);
    });
     this.subscriptions = this.sharedService.searchRemoveChipShared.subscribe((data) => {
       this.dataShare = data;
       let isSharedIndex
       this.dataShare?.split(',').forEach(element => {
        isSharedIndex  =  this.selectShare.findIndex((e)=>{
        return e === element;
      });
    });
     this.selectShare.splice(isSharedIndex, 1);
    });
    this.subscriptionsAdded = this.sharedService.addeddatebreadcrumb.subscribe((data) => {
      this.addeddatebreadcrumbValue = data;

   });
   this.subscriptionsVisible = this.sharedService.crumbvisible.subscribe((data) => {
    this.crumbVisible = data;

 });

     this.subscriptionsDoc = this.sharedService.searchRemoveDocFromart.subscribe((data) => {
       this.dataFromat = data;
       this.isTypeSelected = null;
    });
     this.subscriptionsDate = this.sharedService.searchRemoveDocDate.subscribe((data) => {
       this.dataDate = data;
       this.DateInput = null;
    });
    this.subscriptionMonth = this.sharedService.commonCabinetBreadcum.subscribe((data) => {
      this.cabinetBreadcrumbMonth = data;

    });
    this.subscriptionWeek= this.sharedService.commonCabinetBreadcumWeek.subscribe((data) => {
      this.cabinetBreadcrumbWeek = data;

    });
    this.subscriptionYear= this.sharedService.commonCabinetBreadcumYear.subscribe((data) => {
      this.cabinetBreadcrumbYear = data;

    });



    this.subscriptiontags= this.sharedService.tagbreadcrumb.subscribe((data) => {
      this.cabinetBreadcrumbTags = data;
  });
    this.subscriptionlabel= this.sharedService.labelbreadcrumb.subscribe((data) => {
      this.cabinetBreadcrumblabel = data;
      if(this.cabinetBreadcrumblabel){

        this.cookieService.set('duplicatcabinetlabel',JSON.stringify(this.cabinetBreadcrumblabel));
      }
      if(!this.cabinetBreadcrumblabel){


        if((this.gettag2card == "true") || (this.gettag3card == "true")){
          if(this.cookieService.get('duplicatcabinetlabel')){

            this.cookieService.set('duplicatcabinetlabel',this.cookieService.get('duplicatcabinetlabel'));

          }
        }
      }

      this.subscriptionmini = this.sharedService.minicrossval.subscribe((data) => {
        this.crossminidata();
      });
    });



      this.subscriptionvalue = this.sharedService.valuebreadcrumb.subscribe((data) => {
        this.cabinetBreadcrumbvalue = data;
        if(this.cabinetBreadcrumbvalue){

          this.cookieService.set('duplicatcabinetavl',JSON.stringify(this.cabinetBreadcrumbvalue));
          this.cookieService.set("valuetag",this.cabinetBreadcrumbvalue)
        }
        if(!this.cabinetBreadcrumbvalue){


          this.gettag3card = this.cookieService.get('tags3card');
          if(this.gettag3card == "true"){
            if(this.cookieService.get('duplicatcabinetavl')){

              this.cookieService.set('duplicatcabinetavl',this.cookieService.get('duplicatcabinetavl'));

            }
          }
        }



    });


     this.breadCrumnSubs = this.sharedService.braedCrumbFirstLevel.subscribe((data) => {
      this.href = this.router.url;
      this.firstLevel = localStorage.getItem('breadCrumbFirstLvl');
      this.crumbVisible= localStorage.getItem('crumVisibles');
      this.thirdLevelBread = localStorage.getItem('breadCrumbThreeLvl');
      if(this.firstLevel != 'Admin'){

      localStorage.setItem('breadCrumbThreeLvl','');

      }
      this.secondLevel = localStorage.getItem('breadCrumbsecondLvl');
      this.thirdLevel = localStorage.getItem('breadCrumbThreeLvl');
      this.crumbVisible= localStorage.getItem('crumVisibles');
      this.cd.detectChanges();

     });
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      if(this.imageUrl == "" || this.imageUrl == null || this.imageUrl == undefined){
        this.imageUrlStatic = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
      }


      this.isLoadingUserList = true;
      setTimeout(() => {
        this.isLoadingUserList = false;
      }, 2000);




      this.subhelpcenter = this.sharedService.closehelpcenter.subscribe((data) => {
        this.closecenter(data);
      });

      this.subTempValue = this.sharedservice.productFruitDataTemp.subscribe((data)=>{
        let datatemp= localStorage.getItem('checklist')
        if(datatemp == 'done'){
        this.checkListCompleted = false

      }
      })
  }


  /**
   * Getting Dcoins Function
   */
   getDCoins() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let  postDatasp = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }
    this.subdata.wsSubscriptionDetailsService(postDatasp).subscribe((response:any)=>{
      if(response.data){
        localStorage.setItem('TotalDCoins',String(response.data?.[0]?.totalDcoins));
        this.totalDCoins = Number(localStorage.getItem('TotalDCoins'));
        this.cd.detectChanges();
      }
    })
   }


  ngAfterViewInit() {
    let data= localStorage.getItem('checklist')
    if(data == 'done'){
     this.checkListCompleted = false
   }
   this.bindMenuRights();

   setTimeout(() => {
     localStorage.removeItem('signUpInEmail');
   }, 10000);
  }


  @HostListener('window:load',['$event'])
  onLoad(){

      if( localStorage.getItem('checklist') =='done' ||  (window.productFruits?.api?.checklists.getChecklists().length == 0)){
        this.checkListCompleted=false
      }


  }
  myFunction(event:any) {
    //this.onScroll(event);
    this.scrollingCurrentValue = this.hideMenu.nativeElement.getBoundingClientRect().bottom;
     if (this.scrollingCurrentValue != this.scrollingPreviousValue) {
      this.hideMenu.nativeElement.click();
      
    }
  }

  onScroll(event) {
    if (this.ngSelect.isOpen) {
      this.ngSelect.close();
    }
  }
  multiselect() {
    this.scrollingPreviousValue = this.hideMenu.nativeElement.getBoundingClientRect().bottom;
  }


  bindMenuRights() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;
    postData = {
      Wsoid: parseInt(localStorage.getItem('localwsId')),
      WsLink: arr[2],
      EmailId: localStorage.getItem('localloginId'),
    };
    this.data.menuRightsService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.menuRights = response.data.category;
        this.sharedService.menuValueRights.next(response.data)
        this.getparserflag()
        this.workspaceDetails();
      }

    });
  }
  /**
   * timeZoneChanged function
   */
     timeZoneChanged() {
      this.data.getTimezone({}).subscribe((response: any) => {
        if (response.isSuccess == true) {
          this.zoneTime = response.data;
          for (let i=0; i<this.zoneTime.length;i++) {
            if (this.zoneTime[i].timeZone.includes("(UTC)")) {
              let timeParsed = this.zoneTime[i].timeZone.split(")");
              this.zoneTime[i].timeZone = timeParsed[0] + "+00:00)" + timeParsed[1];
            }
          }
          this.allTimeZone =  response.data;
          this.timeZoneID = response.data.timeZone;
        }
      })
    }
       /**
   * openSupport function
   */
        oncardclick3='';
        itemVAlueIdVariable='';
  isMenu :boolean = false;
  itemValue(){
  this.isMenu = !this.isMenu;
  this.itemVAlueIdVariable= document.getElementById("itemValueId").id

  }
 
  openSupport(){
    this.supportChild.openSupport();
    this.isMenu = !this.isMenu;
  }
  openHelp(){
    window.open('https://help.dox2u.com/', "_blank");
    this.isblue=true;
    this.isMenu = !this.isMenu;
  }
    /**
   * onScrollDown function
   */
  onScrollDown(ev: any) {


    this.sum += 50;
    this.appendItems();

    this.direction = "scroll down";
  }
  /**
   * appendItems function
   */


  appendItems() {
    this.addItems("push");
  }
 /**
   * prependItems function
   */
  prependItems() {
    this.addItems("unshift");
  }
 /**
   * add Items function
   */
  addItems(_method: string) {
    let pushValue=this.getallArray.slice(0,this.listArray.length+this.sum)
    this.listArray=pushValue
  }


/**
   * workspace Menu Right function
   */


 workspaceMenuRight(response?) {
    localStorage.setItem('isGuestAccess', response.isGuestAccess.toString())
    localStorage.setItem('isWhatsappAccess', response.isWhatsappAccess.toString())
    localStorage.setItem('logincategory', response.category)
    localStorage.setItem('isDownloadPrintDocuments', response.isDownloadPrintDocuments)
    localStorage.setItem('isWhatsappAccess', response.isWhatsappAccess)
    localStorage.setItem('isOtpMandatory', response.isOtpMandatory)
    localStorage.setItem('isDownloadPrintMandatory', response.isDownloadPrintMandatory)
}
/**
   * close center function
   */

closecenter(data){
  this.showSupport = true;
}
/**
   *all Search Api function
   */

  allSearchApi(){
    var st = window.location.toString();
    var arr = st?.split("/");

    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "FullName": '',
      "GroupId": 0,
      "RoleId": 0,
      "Status": status,
      "FromDate": '',
      "ToDate": '',
      "AuthorizeEmailId": this.createdBy
    }
    this.datanew.searchAllUsersService(postData).subscribe((response: any) => {
      if (response.isSuccess){
        this.searchUserData = response.data;
        this.removeItemOnce();
        let anyOne = {displayName : "Anyone"};

        this.searchUserData.splice(0,0, anyOne);
        const replaceWith = ':';
        let encodedName = this.route.snapshot.queryParamMap.get('q')
        if(encodedName?.length == 1){
          if(encodedName == "="){
            encodedName = '';
          }
        }
        else{

              const change= encodedName ;
              if(change != null){
                let ary=change?.split("")
                if(ary[ary.length-1]==="="){
                ary[ary.length-1]=""
                }else{
                const lastIn=ary.lastIndexOf("=");
                if(lastIn!==-1){
                  if(ary[lastIn+1]===" "){
                  ary[lastIn]=''
                  }
                }
                }
                encodedName=ary.join("")
              }


        }
        const decodedName = decodeURIComponent(encodedName);
        if (encodedName !== null) {
          const urlQueryParams = decodedName?.split('&').join(' ');
          this.urlQueryParams = urlQueryParams?.split('=').join(replaceWith)

          this.NormalSearchValue = this.urlQueryParams;
          this.searchInputChange('URL');
        }
      }
    })
  }
  HandleFlags(){
    localStorage.setItem('EnterActive' , "activeEnter");
  }
  /**
 * onOwnerName function
 */
  onOwnerName(event){
      this.onSearchBtnClicked = true;
    if (event?.displayName === 'Anyone' || event === undefined){
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayOwner.filter(a => a?.split(':')[0] === 'owner');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayOwner.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayOwner.indexOf(element)
              this.NormalSearchValueArrayOwner.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }

      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ') +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;

        this.selectedOwnerEmailid = event?.displayName;
        this.cd.detectChanges();

      }  else{
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayOwner.filter(a => a?.split(':')[0] === 'owner');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayOwner.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayOwner.indexOf(element)
              this.NormalSearchValueArrayOwner.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
        this.selectedOwnerDisplayName = event.displayName;
        this.selectedOwnerEmailid = event.emailId;
        this.anyoneClick=false;
        this.searchValue = this.NormalSearchValue?.split(' ');
        if (this.selectedOwnerEmailid !== null && this.selectedOwnerDisplayName !== 'Anyone' && this.selectedOwnerEmailid !== '' && this.selectedOwnerEmailid !== undefined) {
            let value = '';
            value = 'owner:' + this.selectedOwnerEmailid + ' ';
            this.NormalSearchValueArrayOwner.push(value);
            let includeValueAdd = '';
            if(this.IncludetheValue !== undefined){
              includeValueAdd = this.IncludetheValue
            }
          this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
            this.NormalSearchValueArrayDate.join(' ') +
            this.NormalSearchValueArrayTagsNotes.join(' ')  +
            this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
            this.NormalSearchValueArrayOwner.join(' ') +  this.NormalSearchValueArrayType.join(' ') +
            this.NormalSearchValueArrayincludes.join(' ') ;
        }
    }

    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
  /**
   *  doc name blur event
   */
  onDocName(event){
    let tempValueDuplicate = [];
    let tempValue = [];
  var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
  if(event.target.value !== ''){
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')

    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }

    const chars = event.target.value;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)

           }
        }

      }

      event.target.value = tempValue.join('')
      this.AdvSearchValue = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
      for(let i=0;i<=tempValue.length-1;i++){
        let temp = {
          word : tempValue[i],
          ValueTarget : true
        }
        tempValueDuplicate.push(temp)
      }
      if (!regex.test(char)) {

        // alert("Please Enter English Only");
        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

        event.target.value = tempValue.join('')
        this.AdvSearchValue = event.target.value;

        event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }

  }
  else{
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
    const chars = event.key;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)
           }
        }

      }

      event.target.value = tempValue.join('')
      this.AdvSearchValue = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
      if (!regex.test(char)) {

        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

          event.target.value = tempValue.join('')
          this.AdvSearchValue = event.target.value;

         event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }
  }
  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  if(event.target.value !== ''){

    if(/^([a-z0-9])$/.test(event.target.value)){
      // event.target.value = ''

    }
    else{

    }
  }
  else{
    if(/^([a-z0-9])$/.test(event.target.value)){

    }
    else{

    }
  }

  // setTimeout(() => {
    if (this.AdvSearchValue !== null || this.AdvSearchValue !== undefined || this.AdvSearchValue !== ''){
      this.docNameValue = event.target.value;
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayName.filter(a => a?.split(':')[0] === 'name');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayName.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayName.indexOf(element)
              this.NormalSearchValueArrayName.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
       let value = '';
      value = this.docNameValue.includes(' ') ? 'name:' + JSON.stringify(this.docNameValue)+ ' ' : 'name:' + this.docNameValue+ ' ';
       this.docNameValue !== '' ? this.NormalSearchValueArrayName.push(value) + ' ' : '';
       let includeValueAdd = '';
       if(this.IncludetheValue !== undefined){
         includeValueAdd = this.IncludetheValue
       }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')   +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')   + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
     }
     if(this.IncludetheValue){
       this.searchType = 'Advance';
       this.searchTypeModify = 'change'
     }
     else{
       this.searchType = 'Single';
       this.searchTypeModify = 'change'
     }
  // }, 2000);

  }
 /**
   * doc name blur variable
   */
  doccontainsValue : any;
   /**
   * onDocContains function
   */
  onDocContains(event){
    let tempValueDuplicate = [];
    let tempValue = [];
  var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
  if(event.target.value !== ''){
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')

    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }

    const chars = event.target.value;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)

           }
        }

      }

      event.target.value = tempValue.join('')
      this.AdvDocContains = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
      for(let i=0;i<=tempValue.length-1;i++){
        let temp = {
          word : tempValue[i],
          ValueTarget : true
        }
        tempValueDuplicate.push(temp)
      }
      if (!regex.test(char)) {

        // alert("Please Enter English Only");
        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

        event.target.value = tempValue.join('')
        this.AdvDocContains = event.target.value;

        event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }

  }
  else{
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
    const chars = event.key;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)
           }
        }

      }

      event.target.value = tempValue.join('')
      this.AdvDocContains = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
      if (!regex.test(char)) {

        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

          event.target.value = tempValue.join('')
          this.AdvDocContains = event.target.value;

         event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }
  }



    if (this.AdvDocContains !== null || this.AdvDocContains !== undefined || this.AdvDocContains !== ''){
      this.doccontainsValue = event.target.value;
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayContains.filter(a => a?.split(':')[0] === 'contains');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayContains.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayContains.indexOf(element)
              this.NormalSearchValueArrayContains.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
      /**
       * value variable
       */
      let value = '';
       value = this.doccontainsValue.includes(' ') ? 'contains:' + JSON.stringify(this.doccontainsValue)+ ' ' : 'contains:' + this.doccontainsValue + ' ';
      this.doccontainsValue !== '' ? this.NormalSearchValueArrayContains.push(value) + ' ' : ' ';
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')   +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;

    }
    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
  uploadImageCancle(){
    this.ediimageUrl = this.loginProfilImage;
    this.enableSave = false;
  }
  /**
   onDocTagsNotes event
   */
  onDocTagsNotes(event){
    let tempValueDuplicate = [];
    let tempValue = [];
  var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
  if(event.target.value !== ''){
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')

    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }

    const chars = event.target.value;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)

           }
        }

      }

      event.target.value = tempValue.join('')
      this.docTagsNotes = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
      for(let i=0;i<=tempValue.length-1;i++){
        let temp = {
          word : tempValue[i],
          ValueTarget : true
        }
        tempValueDuplicate.push(temp)
      }
      if (!regex.test(char)) {

        // alert("Please Enter English Only");
        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

        event.target.value = tempValue.join('')
        this.docTagsNotes = event.target.value;

        event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }

  }
  else{
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
    const chars = event.key;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)
           }
        }

      }

      event.target.value = tempValue.join('')
      this.docTagsNotes = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
      if (!regex.test(char)) {

        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

          event.target.value = tempValue.join('')
          this.docTagsNotes = event.target.value;

         event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }
  }


    if (this.docTagsNotes !== null || this.docTagsNotes !== undefined || this.docTagsNotes !== ''){
      this.docTagsValue = event.target.value;
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayTagsNotes.filter(a => a?.split(':')[0] === 'tags');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayTagsNotes.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayTagsNotes.indexOf(element)
              this.NormalSearchValueArrayTagsNotes.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
      let value = '';
      value = this.docTagsValue.includes(' ') ? 'tags:' + JSON.stringify(this.docTagsValue)+ ' ' : 'tags:' + this.docTagsValue + ' ';
      this.docTagsValue !== '' ? this.NormalSearchValueArrayTagsNotes.push(value) + ' ' : '';
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;

    }
    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
  /**
   * remove Item Once from data function
   */
  removeItemOnce() {
    this.searchUserData.forEach(element => {
      if(element.emailId === this.createdBy){
        let index = this.searchUserData.indexOf(element);

        this.searchUserData.splice(index,1)
        this.searchUserData.unshift(element);

      }
    });
  }
/**
   * email pattern
   */
   emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
   /**
   * onShareName function
   */
  onShareName(event){
      this.onSearchBtnClicked = true;
    if (event?.displayName === 'Anyone' || event === undefined){
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayShared.filter(a => a?.split(':')[0] === 'shared');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayShared.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayShared.indexOf(element)
              this.NormalSearchValueArrayShared.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ') +
        this.NormalSearchValueArrayincludes.join(' ') ;
        this.selectedSharedEmailid = event?.displayName;
      }  else{
        this.anyoneClickShare=false;
      let filteredOwner;
      filteredOwner = this.NormalSearchValueArrayShared.filter(a => a?.split(':')[0] === 'shared');
      if (filteredOwner.length > 0) {
        this.NormalSearchValueArrayShared.forEach(element => {
          filteredOwner.forEach(value => {
            if (element === value) {
              let index = this.NormalSearchValueArrayShared.indexOf(element)
              this.NormalSearchValueArrayShared.splice(index, 1);
              this.cd.detectChanges();
            }
          });
        });
      }
        this.selectedSharedEmailid  = event.emailId;
        this.selectedSharedDisplayName  = event.displayName;
        this.searchValueShared = this.NormalSearchValue?.split(' ');
        if (this.selectedSharedEmailid !== null && this.selectedSharedDisplayName !== 'Anyone' && this.selectedSharedEmailid !== '' && this.selectedSharedEmailid !== undefined) {

            let value = '';
            value =  'shared:' + this.selectedSharedEmailid + ' ';
          this.NormalSearchValueArrayShared.push(value);
          let includeValueAdd = '';
              if(this.IncludetheValue !== undefined){
                includeValueAdd = this.IncludetheValue
              }
          this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
            this.NormalSearchValueArrayDate.join(' ')  +
            this.NormalSearchValueArrayTagsNotes.join(' ')  +
            this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
            this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
            this.NormalSearchValueArrayincludes.join(' ') ;
        }
    }
    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
  /**
   * search input change event
   */
   searchstringvalue = '';
    /**
   * chk value index var
   */
   chkvalueindex = '';
     /**
   * type include var
   */
   typeinclude = ['PNG','JPG/JPEG','BMP','GIF','TIF/TIFF','TXT','RTF','DOC/DOCX','ODT','PDF'];
      /**
   * valid Regex var
   */
   validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   /**
   * search Input Change function
   */
  enterCount = 0
   searchInputChange(isurl?){
    this.sharedService.searchloader.next(true)
        if (this.NormalSearchValue.includes('type:') || this.NormalSearchValue.includes('tags:')) {
      this.headerAdvSearch('ngOnInit','','searchclickoninit')
    }else{
    this.searchType = 'Advance';
    this.searchTypeModify = 'change'
    let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regex = /\s(?=(?:[^'"`]*(['"`]).*?\1)*[^'"`]*$)/g;
    const str = this.NormalSearchValue;
    const subst = `\n`;
    let result = str.replace(regex, subst);
    this.nameSplit = null
      this.NormalSearchValueArray = [...this.NormalSearchValue?.split(' ')]
    let result2 = result.replace(/\n\s*\n/g, '\n');
    // this.NormalSearchValue = result2.replace(/\n/g, " ").toLowerCase();
    if (this.NormalSearchValue.includes('shared:')){
        this.sharedSplit = this.NormalSearchValue?.split('shared:')[1];
      this.sharedValue = this.sharedSplit;
        this.emailSharedValidate = this.sharedValue.includes(' ') ? this.searchUserData.filter(a => a.emailId === this.sharedValue?.split(' ')[0]) : this.searchUserData.filter(a => a.emailId === this.sharedValue)
      this.selectedSharedDisplayName = this?.emailSharedValidate[0]?.displayName;

      if(this?.emailSharedValidate[0]?.emailId == undefined){
        this.selectedSharedEmailid = this.sharedSplit;
      }
      else{
        this.selectedSharedEmailid = this?.emailSharedValidate[0]?.emailId;
      }
      if (this.selectedSharedEmailid === undefined){
        emailPattern.test(this.sharedValue) ? this.sharedValue : '';
      }
      this.NormalSearchValueArrayShared = [];
      this.NormalSearchValueArrayShared.push('shared:' + this.selectedSharedEmailid + ' ');
    }
    else if (!this.NormalSearchValue.includes('shared:')) {
      this.NormalSearchValueArrayShared = [];
      this.selectedSharedDisplayName = null;
      this.selectedSharedEmailid = null;
    }
    if (this.NormalSearchValue.includes('owner:')) {
      this.ownedSplit = this.NormalSearchValue?.split('owner:')[1];
    this.ownerValue = this.ownedSplit;
        this.emailOwnedValidate = this.ownerValue.includes(' ') ? this.searchUserData.filter(a => a.emailId === this.ownerValue?.split(' ')[0]) : this.searchUserData.filter(a => a.emailId === this.ownerValue)
    this.selectedOwnerDisplayName = this?.emailOwnedValidate[0]?.displayName;
    if(this?.emailOwnedValidate[0]?.emailId == undefined){
      this.selectedOwnerEmailid = this.ownedSplit;
    }
    else{
      this.selectedOwnerEmailid = this?.emailOwnedValidate[0]?.emailId;
    }
      if (this.selectedOwnerEmailid === undefined) {
        emailPattern.test(this.ownerValue) ? this.ownerValue : '';
      }
      this.NormalSearchValueArrayOwner = [];
      this.NormalSearchValueArrayOwner.push('owner:'+this.selectedOwnerEmailid+' ');

    }
    else if (!this.NormalSearchValue.includes('owner:')) {
      this.NormalSearchValueArrayOwner = [];
      this.selectedOwnerDisplayName = null;
      this.selectedOwnerEmailid = null;
    }
    if (this.NormalSearchValue.includes('type:')) {
      this.typeSplit = this.NormalSearchValue?.split('type:')[1];
    this.typeValue = this.typeSplit.toUpperCase();
    if(this.typeValue === 'JPG'){
      this.typeValue = 'JPG/JPEG';
    }
    if(this.typeValue === 'JPEG'){
      this.typeValue = 'JPG/JPEG';
    }
    if(this.typeValue === 'TIF'){
      this.typeValue = 'TIF/TIFF';
    }
    if(this.typeValue === 'TIFF'){
      this.typeValue = 'TIF/TIFF';
    }
    if(this.typeValue === 'DOC'){
      this.typeValue = 'DOC/DOCX';
    }
    if(this.typeValue === 'DOCX'){
      this.typeValue = 'DOC/DOCX';
    }
      this.typeValidate = this.typeValue.includes(' ') ? this.docTypeList.filter(a => a.name === this.typeValue?.split(' ')[0]) : this.docTypeList.filter(a => a.name === this.typeValue)
    this.isTypeSelected = this?.typeValidate[0]?.name;
      this.NormalSearchValueArrayType = [];
      this.NormalSearchValueArrayType.push('type:' + this.isTypeSelected.toLowerCase() + ' ');
    } else{
      this.NormalSearchValueArrayType = [];
      this.isTypeSelected = null;
        this.IncludetheValue = '';
    }
    if(this.NormalSearchValue.includes('name:')){
        this.nameSplit =   this.NormalSearchValue?.split('name:')[1];
        this.AdvSearchValue = this.nameSplit?.split('"')[1]


      if(this.nameSplit.length <= 50){
        this.AdvSearchValue = this?.nameSplit.indexOf('"') >= 0 ? this?.nameSplit?.includes(' ') ? this.nameSplit?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplit.replace(/"|'/g, '')) : this?.nameSplit?.includes(' ') ? this.nameSplit?.split(' ')[0] : this.nameSplit;
      }
      if (this.nameSplit.length > 50){
        this.nameSplit = this.nameSplit.slice(0,49);
        this.AdvSearchValue = this?.nameSplit.indexOf('"') >= 0 ? this?.nameSplit?.includes(' ') ? this.nameSplit?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplit.replace(/"|'/g, '')) : this?.nameSplit?.includes(' ') ? this.nameSplit?.split(' ')[0] : this.nameSplit;
      }
      this.NormalSearchValueArrayName = [];
      this.NormalSearchValueArrayName.push('name:' + this.AdvSearchValue + ' ');
    } else{
      this.NormalSearchValueArrayName = [];
      this.AdvSearchValue = null;
    }
    if(this.NormalSearchValue.includes('contains:')){
        this.nameSplitContain = this.NormalSearchValue?.split('contains:')[1];
        this.AdvDocContains =  this.nameSplitContain?.split('"')[1]

      if (this.nameSplitContain.length <= 50) {
        this.nameSplitContain = this.nameSplitContain.slice(0, 49);
        this.AdvDocContains = this?.nameSplitContain.indexOf('"') >= 0 ? this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplitContain.replace(/"|'/g, '')) : this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.split(' ')[0] : this.nameSplitContain;
      }
      if (this.nameSplitContain.length > 50) {
        this.nameSplitContain = this.nameSplitContain.slice(0, 49);
        this.AdvDocContains = this?.nameSplitContain.indexOf('"') >= 0 ? this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplitContain.replace(/"|'/g, '')) : this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.split(' ')[0] : this.nameSplitContain;
      }
      this.NormalSearchValueArrayContains = [];
      this.NormalSearchValueArrayContains.push('contains:' + this.AdvDocContains + ' ');
    } else{
      this.NormalSearchValueArrayContains = [];
      this.AdvDocContains = null
    }
    if (this.NormalSearchValue.includes('date:')) {

      this.DateInput = '';
        this.sharedSplit = this.NormalSearchValue?.split('date:')[1]
        this.sharedSplit = this.sharedSplit?.split(' ')[0];

        var date = moment(this.sharedSplit?.split(',')[0]);
        var dateend = moment(this.sharedSplit?.split(',')[1]);
      if (date.isValid() && dateend.isValid()){

            if (this.sharedSplit?.split(',')[0]) {

            this.NormalSearchValueArrayDate = [];
              this.NormalSearchValueArrayDate.push('date:' + this.sharedSplit?.split(',')[0] + ' ');
          }
            if (this.sharedSplit?.split(',')[1]) {

            this.NormalSearchValueArrayDate = [];
              this.NormalSearchValueArrayDate.push('date:' + this.sharedSplit?.split(',')[0] + ',' + this.sharedSplit?.split(',')[1]+' ');
          }

            if(this.sharedSplit?.split(',')[0] && this.sharedSplit?.split(',')[1]){

            // this is for today when todate and fromdate both coming

            let currentDateVal =  moment().format('YYYY-MM-DD');
              if(currentDateVal == this.sharedSplit?.split(',')[0] && currentDateVal == this.sharedSplit?.split(',')[1]){
                this.customDropDownn = false;
                this.DateInput = 'Today';
                sessionStorage.setItem('searchdate','Today');
            }

            // this is for yesterday when todate and fromdate both are coming

            let checkDropdownToDate = new Date();
            let checkDropdownFromDate = new Date()
            checkDropdownFromDate.setDate(checkDropdownFromDate.getDate() - 1);


            let changeValueToDate = moment(checkDropdownToDate).format('YYYY-MM-DD');
            let changeValueFromDate = moment(checkDropdownFromDate).format('YYYY-MM-DD');
              if(changeValueFromDate == this.sharedSplit?.split(',')[0] && changeValueToDate == this.sharedSplit?.split(',')[1]){
                this.customDropDownn = false;
                this.DateInput = 'Yesterday';
                sessionStorage.setItem('searchdate','Yesterday');
             }


            // this is for last 7 days
            let checkDropdownToDate7 = new Date();
            let checkDropdownFromDate7 = new Date();
            checkDropdownToDate7.setDate(checkDropdownToDate7.getDate() - 7);
            let changeValueToDate7 = moment(checkDropdownToDate7).format('YYYY-MM-DD');
            let changeValueFromDate7 = moment(checkDropdownFromDate7).format('YYYY-MM-DD');
              if(changeValueToDate7 == this.sharedSplit?.split(',')[0] && changeValueFromDate7 == this.sharedSplit?.split(',')[1]){
             this.customDropDownn = false;
             this.DateInput = 'Last 7 days';
             sessionStorage.setItem('searchdate','Last 7 days');
            }

            // this is for last 30 days
            let checkDropdownToDate30 = new Date();
            let checkDropdownFromDate30 = new Date();
            checkDropdownToDate30.setDate(checkDropdownToDate30.getDate() - 30);
            let changeValueToDate30 = moment(checkDropdownToDate30).format('YYYY-MM-DD');
            let changeValueFromDate30 = moment(checkDropdownFromDate30).format('YYYY-MM-DD');
              if(changeValueToDate30 == this.sharedSplit?.split(',')[0] && changeValueFromDate30 == this.sharedSplit?.split(',')[1]){
              this.customDropDownn = false;
              this.DateInput = 'Last 30 days';
              sessionStorage.setItem('searchdate','Last 30 days');
             }

            //  this is for last 90 days
            let checkDropdownToDate90 = new Date();
            let checkDropdownFromDate90 = new Date();
            checkDropdownToDate90.setDate(checkDropdownToDate90.getDate() - 90);
            let changeValueToDate90 = moment(checkDropdownToDate90).format('YYYY-MM-DD');
            let changeValueFromDate90 = moment(checkDropdownFromDate90).format('YYYY-MM-DD');
              if(changeValueToDate90 == this.sharedSplit?.split(',')[0] && changeValueFromDate90 == this.sharedSplit?.split(',')[1]){
              this.customDropDownn = false;
              this.DateInput = 'Last 90 days';
              sessionStorage.setItem('searchdate','Last 90 days');
             }
          }
            else if(this.sharedSplit?.split(',')[0]){
            let currentDateVal =  moment().format('YYYY-MM-DD');
              if(currentDateVal == this.sharedSplit?.split(',')[0]){
                this.customDropDownn = false;
                this.DateInput = 'Today';
                sessionStorage.setItem('searchdate','Today');
            }

            let checkDropdownToDate = new Date();
            let checkDropdownFromDate = new Date()
            checkDropdownFromDate.setDate(checkDropdownFromDate.getDate() - 1);
            checkDropdownToDate.setDate(checkDropdownToDate.getDate() - 1);

            let changeValueToDate = moment(checkDropdownToDate).format('YYYY-MM-DD');
            let changeValueFromDate = moment(checkDropdownFromDate).format('YYYY-MM-DD');
              if(changeValueToDate == this.sharedSplit?.split(',')[0]){
                this.customDropDownn = false;
                this.DateInput = 'Yesterday';
                sessionStorage.setItem('searchdate','Yesterday');
             }

             if(this.DateInput == ''){
              this.DateInput = 'Custom..';
                let changeFirstValue = this.sharedSplit?.split(',')[0];
               this.rangeDate={
              start : '' ,
              end : ''
               };
                this.rangeDate.start = new Date(this.sharedSplit?.split(',')[0]);
                if(this.sharedSplit?.split(',')[1]){
                  this.rangeDate.end = new Date(this.sharedSplit?.split(',')[1])
              }
              else{
                this.rangeDate.end = "";
              }


              this.customDropDownn = true;
              sessionStorage.setItem('searchdate','Custom');
              this.cd.detectChanges();
             }


          }
          if(this.DateInput == '' || this.DateInput == null || this.DateInput == 'Custom..'){
            this.DateInput = 'Custom..';
              let changeFirstValue = this.sharedSplit?.split(',')[0];
             this.rangeDate={
            start : '' ,
            end : ''
             };
              this.rangeDate.start = new Date(this.sharedSplit?.split(',')[0]);
              this.rangeDate.end = new Date(this.sharedSplit?.split(',')[1])

            this.customDropDownn = true;
            sessionStorage.setItem('searchdate','Custom');
            this.cd.detectChanges();
          }
        // }
      } else{
        this.DateInput = 'Anytime';
        this.customDropDownn = false;
        this.toDate = "";
        this.fromDate = "";
        this.rangeDate = '';

        this.NormalSearchValueArrayDate = [];
      }

      this.advFilterDropdown = false;
    } else {
      this.DateInput = null;
      this.customDropDownn = false;
      this.toDate = "";
      this.fromDate = "";
      this.rangeDate = "";

      this.NormalSearchValueArrayDate = [];
      this.advFilterDropdown = false;
    }
    if (this.NormalSearchValue.includes('tags:')) {
        this.sharedSplit = this.NormalSearchValue?.split('tags:')[1];
        let sharedSplitCheck = this.sharedSplit?.split(' ')[0];
        this.docTagsNotes =  sharedSplitCheck?.split('"')[1]

      if(Date.parse(sharedSplitCheck)){
        this.checked2 = true;
        this.checked1 = false
      } else{
        this.checked1 = true;
        this.checked2 = false
      }
      if(this.checked1){
        if (sharedSplitCheck.length <= 50) {
          sharedSplitCheck = sharedSplitCheck.slice(0, 49);
          this.docTagsNotes = this?.sharedSplit?.includes('""') || this?.sharedSplit?.includes('"') ? JSON.parse(sharedSplitCheck) : this?.sharedSplit?.includes(' ') ? sharedSplitCheck?.split(' ')[0] : sharedSplitCheck;
        }
        if (sharedSplitCheck.length > 50) {
          sharedSplitCheck = sharedSplitCheck.slice(0, 49);
          this.docTagsNotes = this?.sharedSplit?.includes('""') || this?.sharedSplit?.includes('"') ? JSON.parse(sharedSplitCheck) : this?.sharedSplit?.includes(' ') ? sharedSplitCheck?.split(' ')[0] : this.sharedSplit;
        }
        this.NormalSearchValueArrayTagsNotes = [];
        this.NormalSearchValueArrayTagsNotes.push('tags:' + this.docTagsNotes + ' ');
      } else {
        this.rangeDateTags = sharedSplitCheck;
        this.NormalSearchValueArrayTagsNotes = [];
        this.NormalSearchValueArrayTagsNotes.push('tags:' + this.rangeDateTags + ' ');
      }

    } else {
      this.NormalSearchValueArrayTagsNotes = [];
       this.docTagsNotes = null
    }

    this.includetheword();


    if (isurl === 'URL'){
        this.headerAdvSearch('url','','searchclickoninit');
      } else{
        if(this.enterCount === 0){
          this.headerAdvSearch('ngOnInit','','searchclickoninit')
          this.enterCount++;
      }
        setTimeout(() => {
          this.enterCount = 0;
        }, 3000);
    }
    }

  }

  searchInputChangeAddNormal(isurl?){

    this.searchType = 'Advance';
    this.searchTypeModify = 'change'
    let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regex = /\s(?=(?:[^'"`]*(['"`]).*?\1)*[^'"`]*$)/g;
    const str = this.NormalSearchValue;
    const subst = `\n`;
    let result = str.replace(regex, subst);
    this.nameSplit = null
    this.NormalSearchValueArray = [...this.NormalSearchValue?.split(' ')]
    let result2 = result.replace(/\n\s*\n/g, '\n');
    // this.NormalSearchValue = result2.replace(/\n/g, " ").toLowerCase();
    if (this.NormalSearchValue.includes('shared:')){

      this.sharedSplit = this.NormalSearchValue?.split('shared:')[1];
      this.sharedValue = this.sharedSplit;
      this.emailSharedValidate = this.sharedValue.includes(' ') ? this.searchUserData.filter(a => a.emailId === this.sharedValue?.split(' ')[0]) : this.searchUserData.filter(a => a.emailId === this.sharedValue)
      this.selectedSharedDisplayName = this?.emailSharedValidate[0]?.displayName;
      if(this?.emailSharedValidate[0]?.emailId == undefined){
        this.selectedSharedEmailid = this.sharedSplit;
      }
      else{
        this.selectedSharedEmailid = this?.emailSharedValidate[0]?.emailId;
      }

      if (this.selectedSharedEmailid === undefined){
        emailPattern.test(this.sharedValue) ? this.sharedValue : '';
      }
      this.NormalSearchValueArrayShared = [];
      this.NormalSearchValueArrayShared.push('shared:' + this.selectedSharedEmailid + ' ');
    }
    else if (!this.NormalSearchValue.includes('shared:')) {
      this.NormalSearchValueArrayShared = [];
      this.selectedSharedDisplayName = null;
      this.selectedSharedEmailid = null;
    }
    if (this.NormalSearchValue.includes('owner:')) {
    this.ownedSplit = this.NormalSearchValue?.split('owner:')[1];
    this.ownerValue = this.ownedSplit;
      this.emailOwnedValidate = this.ownerValue.includes(' ') ? this.searchUserData.filter(a => a.emailId === this.ownerValue?.split(' ')[0]) : this.searchUserData.filter(a => a.emailId === this.ownerValue)
    this.selectedOwnerDisplayName = this?.emailOwnedValidate[0]?.displayName;
    if(this?.emailOwnedValidate[0]?.emailId == undefined){
      this.selectedOwnerEmailid = this.ownedSplit;
    }
    else{
      this.selectedOwnerEmailid = this?.emailOwnedValidate[0]?.emailId;
    }
      if (this.selectedOwnerEmailid === undefined) {
        emailPattern.test(this.ownerValue) ? this.ownerValue : '';
      }
      this.NormalSearchValueArrayOwner = [];
      this.NormalSearchValueArrayOwner.push('owner:'+this.selectedOwnerEmailid+' ');

    }
    else if (!this.NormalSearchValue.includes('owner:')) {
      this.NormalSearchValueArrayOwner = [];
      this.selectedOwnerDisplayName = null;
      this.selectedOwnerEmailid = null;
    }
    if (this.NormalSearchValue.includes('type:')) {
    this.typeSplit = this.NormalSearchValue?.split('type:')[1];
    this.typeValue = this.typeSplit.toUpperCase();
    if(this.typeValue === 'JPG'){
      this.typeValue = 'JPG/JPEG';
    }
    if(this.typeValue === 'JPEG'){
      this.typeValue = 'JPG/JPEG';
    }
    if(this.typeValue === 'TIF'){
      this.typeValue = 'TIF/TIFF';
    }
    if(this.typeValue === 'TIFF'){
      this.typeValue = 'TIF/TIFF';
    }
    if(this.typeValue === 'DOC'){
      this.typeValue = 'DOC/DOCX';
    }
    if(this.typeValue === 'DOCX'){
      this.typeValue = 'DOC/DOCX';
    }
    this.typeValidate = this.typeValue.includes(' ') ? this.docTypeList.filter(a => a.name === this.typeValue?.split(' ')[0]) : this.docTypeList.filter(a => a.name === this.typeValue)
    this.isTypeSelected = this?.typeValidate[0]?.name;
      this.NormalSearchValueArrayType = [];
      this.NormalSearchValueArrayType.push('type:' + this.isTypeSelected.toLowerCase() + ' ');
    } else{
      this.NormalSearchValueArrayType = [];
      this.isTypeSelected = null;
    }
    if(this.NormalSearchValue.includes('name:')){
      this.nameSplit =   this.NormalSearchValue?.split('name:')[1];
      this.AdvSearchValue = this.nameSplit?.split('"')[1]


      if(this.nameSplit.length <= 50){
        this.AdvSearchValue = this?.nameSplit.indexOf('"') >= 0 ? this?.nameSplit?.includes(' ') ? this.nameSplit?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplit.replace(/"|'/g, '')) : this?.nameSplit?.includes(' ') ? this.nameSplit?.split(' ')[0] : this.nameSplit;
      }
      if (this.nameSplit.length > 50){
        this.nameSplit = this.nameSplit.slice(0,49);
        this.AdvSearchValue = this?.nameSplit.indexOf('"') >= 0 ? this?.nameSplit?.includes(' ') ? this.nameSplit?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplit.replace(/"|'/g, '')) : this?.nameSplit?.includes(' ') ? this.nameSplit?.split(' ')[0] : this.nameSplit;
      }
      this.NormalSearchValueArrayName = [];
      this.NormalSearchValueArrayName.push('name:' + this.AdvSearchValue + ' ');
    } else{
      this.NormalSearchValueArrayName = [];
      this.AdvSearchValue = null;
    }
    if(this.NormalSearchValue.includes('contains:')){
      this.nameSplitContain = this.NormalSearchValue?.split('contains:')[1];
      this.AdvDocContains =  this.nameSplitContain?.split('"')[1]

      if (this.nameSplitContain.length <= 50) {
        this.nameSplitContain = this.nameSplitContain.slice(0, 49);
        this.AdvDocContains = this?.nameSplitContain.indexOf('"') >= 0 ? this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplitContain.replace(/"|'/g, '')) : this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.split(' ')[0] : this.nameSplitContain;
      }
      if (this.nameSplitContain.length > 50) {
        this.nameSplitContain = this.nameSplitContain.slice(0, 49);
        this.AdvDocContains = this?.nameSplitContain.indexOf('"') >= 0 ? this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"|'/g, '')
   : (this.nameSplitContain.replace(/"|'/g, '')) : this?.nameSplitContain?.includes(' ') ? this.nameSplitContain?.split(' ')[0] : this.nameSplitContain;
      }
      this.NormalSearchValueArrayContains = [];
      this.NormalSearchValueArrayContains.push('contains:' + this.AdvDocContains + ' ');
    } else{
      this.NormalSearchValueArrayContains = [];
      this.AdvDocContains = null
    }
    if (this.NormalSearchValue.includes('date:')) {

      this.DateInput = '';
      this.sharedSplit = this.NormalSearchValue?.split('date:')[1]
      this.sharedSplit = this.sharedSplit?.split(' ')[0];
      var date = moment(this.sharedSplit?.split(',')[0]);
      var dateend = moment(this.sharedSplit?.split(',')[1]);
      if (date.isValid() && dateend.isValid()){

          if (this.sharedSplit?.split(',')[0]) {

            this.NormalSearchValueArrayDate = [];
            this.NormalSearchValueArrayDate.push('date:' + this.sharedSplit?.split(',')[0] + ' ');
          }
          if (this.sharedSplit?.split(',')[1]) {

            this.NormalSearchValueArrayDate = [];
            this.NormalSearchValueArrayDate.push('date:' + this.sharedSplit?.split(',')[0] + ',' + this.sharedSplit?.split(',')[1]+' ');
          }

          if(this.sharedSplit?.split(',')[0] && this.sharedSplit?.split(',')[1]){

            // this is for today when todate and fromdate both coming

            let currentDateVal =  moment().format('YYYY-MM-DD');
            if(currentDateVal == this.sharedSplit?.split(',')[0] && currentDateVal == this.sharedSplit?.split(',')[1]){
                this.customDropDownn = false;
                this.DateInput = 'Today';
                sessionStorage.setItem('searchdate','Today');
            }

            // this is for yesterday when todate and fromdate both are coming

            let checkDropdownToDate = new Date();
            let checkDropdownFromDate = new Date()
            checkDropdownFromDate.setDate(checkDropdownFromDate.getDate() - 1);


            let changeValueToDate = moment(checkDropdownToDate).format('YYYY-MM-DD');
            let changeValueFromDate = moment(checkDropdownFromDate).format('YYYY-MM-DD');
            if(changeValueFromDate == this.sharedSplit?.split(',')[0] && changeValueToDate == this.sharedSplit?.split(',')[1]){
                this.customDropDownn = false;
                this.DateInput = 'Yesterday';
                sessionStorage.setItem('searchdate','Yesterday');
             }


            // this is for last 7 days
            let checkDropdownToDate7 = new Date();
            let checkDropdownFromDate7 = new Date();
            checkDropdownToDate7.setDate(checkDropdownToDate7.getDate() - 7);
            let changeValueToDate7 = moment(checkDropdownToDate7).format('YYYY-MM-DD');
            let changeValueFromDate7 = moment(checkDropdownFromDate7).format('YYYY-MM-DD');
            if(changeValueToDate7 == this.sharedSplit?.split(',')[0] && changeValueFromDate7 == this.sharedSplit?.split(',')[1]){
             this.customDropDownn = false;
             this.DateInput = 'Last 7 days';
             sessionStorage.setItem('searchdate','Last 7 days');
            }

            // this is for last 30 days
            let checkDropdownToDate30 = new Date();
            let checkDropdownFromDate30 = new Date();
            checkDropdownToDate30.setDate(checkDropdownToDate30.getDate() - 30);
            let changeValueToDate30 = moment(checkDropdownToDate30).format('YYYY-MM-DD');
            let changeValueFromDate30 = moment(checkDropdownFromDate30).format('YYYY-MM-DD');
            if(changeValueToDate30 == this.sharedSplit?.split(',')[0] && changeValueFromDate30 == this.sharedSplit?.split(',')[1]){
              this.customDropDownn = false;
              this.DateInput = 'Last 30 days';
              sessionStorage.setItem('searchdate','Last 30 days');
             }

            //  this is for last 90 days
            let checkDropdownToDate90 = new Date();
            let checkDropdownFromDate90 = new Date();
            checkDropdownToDate90.setDate(checkDropdownToDate90.getDate() - 90);
            let changeValueToDate90 = moment(checkDropdownToDate90).format('YYYY-MM-DD');
            let changeValueFromDate90 = moment(checkDropdownFromDate90).format('YYYY-MM-DD');
            if(changeValueToDate90 == this.sharedSplit?.split(',')[0] && changeValueFromDate90 == this.sharedSplit?.split(',')[1]){
              this.customDropDownn = false;
              this.DateInput = 'Last 90 days';
              sessionStorage.setItem('searchdate','Last 90 days');
             }
          }
          else if(this.sharedSplit?.split(',')[0]){
            let currentDateVal =  moment().format('YYYY-MM-DD');
            if(currentDateVal == this.sharedSplit?.split(',')[0]){
                this.customDropDownn = false;
                this.DateInput = 'Today';
                sessionStorage.setItem('searchdate','Today');
            }

            let checkDropdownToDate = new Date();
            let checkDropdownFromDate = new Date()
            checkDropdownFromDate.setDate(checkDropdownFromDate.getDate() - 1);
            checkDropdownToDate.setDate(checkDropdownToDate.getDate() - 1);

            let changeValueToDate = moment(checkDropdownToDate).format('YYYY-MM-DD');
            let changeValueFromDate = moment(checkDropdownFromDate).format('YYYY-MM-DD');
            if(changeValueToDate == this.sharedSplit?.split(',')[0]){
                this.customDropDownn = false;
                this.DateInput = 'Yesterday';
                sessionStorage.setItem('searchdate','Yesterday');
             }

             if(this.DateInput == ''){
              this.DateInput = 'Custom..';
              let changeFirstValue = this.sharedSplit?.split(',')[0];
               this.rangeDate={
              start : '' ,
              end : ''
               };
              this.rangeDate.start = new Date(this.sharedSplit?.split(',')[0]);
              if(this.sharedSplit?.split(',')[1]){
                this.rangeDate.end = new Date(this.sharedSplit?.split(',')[1])
              }
              else{
                this.rangeDate.end = "";
              }


              this.customDropDownn = true;
              sessionStorage.setItem('searchdate','Custom');
              this.cd.detectChanges();
             }


          }
          if(this.DateInput == '' || this.DateInput == null || this.DateInput == 'Custom..'){
            this.DateInput = 'Custom..';
            let changeFirstValue = this.sharedSplit?.split(',')[0];
             this.rangeDate={
            start : '' ,
            end : ''
             };
            this.rangeDate.start = new Date(this.sharedSplit?.split(',')[0]);
            this.rangeDate.end = new Date(this.sharedSplit?.split(',')[1])

            this.customDropDownn = true;
            sessionStorage.setItem('searchdate','Custom');
            this.cd.detectChanges();
          }

      } else{
        this.DateInput = 'Anytime';
        this.customDropDownn = false;
        this.toDate = "";
        this.fromDate = "";
        this.rangeDate = '';

        this.NormalSearchValueArrayDate = [];
      }

      this.advFilterDropdown = false;
    } else {
      this.DateInput = null;
      this.customDropDownn = false;
      this.toDate = "";
      this.fromDate = "";
      this.rangeDate = "";

      this.NormalSearchValueArrayDate = [];
      this.advFilterDropdown = false;
    }
    if (this.NormalSearchValue.includes('tags:')) {
      this.sharedSplit = this.NormalSearchValue?.split('tags:')[1];
      let sharedSplitCheck = this.sharedSplit?.split(' ')[0];
      this.docTagsNotes =  sharedSplitCheck?.split('"')[1]

      if(Date.parse(sharedSplitCheck)){
        this.checked2 = true;
        this.checked1 = false
      } else{
        this.checked1 = true;
        this.checked2 = false
      }
      if(this.checked1){
        if (sharedSplitCheck.length <= 50) {
          sharedSplitCheck = sharedSplitCheck.slice(0, 49);
          this.docTagsNotes = this?.sharedSplit?.includes('""') || this?.sharedSplit?.includes('"') ? JSON.parse(sharedSplitCheck) : this?.sharedSplit?.includes(' ') ? sharedSplitCheck?.split(' ')[0] : sharedSplitCheck;
        }
        if (sharedSplitCheck.length > 50) {
          sharedSplitCheck = sharedSplitCheck.slice(0, 49);
          this.docTagsNotes = this?.sharedSplit?.includes('""') || this?.sharedSplit?.includes('"') ? JSON.parse(sharedSplitCheck) : this?.sharedSplit?.includes(' ') ? sharedSplitCheck?.split(' ')[0] : this.sharedSplit;
        }
        this.NormalSearchValueArrayTagsNotes = [];
        this.NormalSearchValueArrayTagsNotes.push('tags:' + this.docTagsNotes + ' ');
      } else {
        this.rangeDateTags = sharedSplitCheck;
        this.NormalSearchValueArrayTagsNotes = [];
        this.NormalSearchValueArrayTagsNotes.push('tags:' + this.rangeDateTags + ' ');
      }

    } else {
      this.NormalSearchValueArrayTagsNotes = [];
       this.docTagsNotes = null
    }

    this.includetheword();

  }
 /**
   * advance Search keys var
   */
  advanceSearchkeys = ['type:','owner:','shared:','date:','name:','contains:','tags:']
   /**
   * check in include var
   */
  checkininclude = '';
    /**
   * normal search type var
   */
  normalsearchtype = [];
   /**
   *taking left porting  var
   */
  takingleftportingtr = '';
   /**
   *include the word function
   */
  includetheword(){
    this.normalsearchtype = [];
    this.takingleftportingtr = this.NormalSearchValue;
    let normalvalue1 = this.NormalSearchValueArray;
    for(let i=0;i<=this.NormalSearchValueArray.length-1;i++){
      if(this.NormalSearchValueArray[i].includes('type')){
        if((this.NormalSearchValueArray[i].indexOf("type")) != -1){
          let arraytype = this.NormalSearchValueArray[i];
          let arraytargettype = arraytype?.split(":")
          const checkFormatSm = ["png", "jpg", "bmp",'jpg/jpeg','tif/tiff','doc/docx',"gif", "tif", "txt", "rtf", "doc", "docx", "odt", "pdf", "jpeg", "tiff"];

          let chkdata1 = checkFormatSm.filter(i=>{
            if(i == arraytargettype[1].toLowerCase()){
              return i
            }
          })
          if(chkdata1.length > 0){
            let typevalid = 'vaild';
            if(arraytargettype[0].includes('type')){
              let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1].toLowerCase())
              this.takingleftportingtr = getsearch.join(' ')
             }
             else{
              let getsearch = this.takingleftportingtr;
              this.takingleftportingtr = getsearch
             }
            // let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1].toLowerCase())
            // this.takingleftportingtr = getsearch.join(' ')
          }
          else{
            if(arraytargettype[1] !== ''){
              if(arraytargettype[0].includes('type')){
                let typevalid = arraytargettype[0] + ":" + arraytargettype[1];
              let getsearch = this.takingleftportingtr?.split(typevalid)
              this.takingleftportingtr = getsearch.join(' ')
              this.normalsearchtype.push(typevalid);
               }
               else{
                let getsearch = this.takingleftportingtr;
                this.takingleftportingtr = getsearch
               }

            }
            else{
              let staticKey = this.NormalSearchValue;
              if(staticKey.includes("type")){

                let flagValidation = "valid";
                  let newArray = [];
                  const convertSt = staticKey.trimStart().trimEnd().toLowerCase();
                  const checkExist = convertSt.includes("type:");
                  if (checkExist) {
                    const foundStartFrom = convertSt.indexOf("type:");
                    if (foundStartFrom > 0) {
                      if (convertSt[foundStartFrom - 1].indexOf(" ") === -1) {
                        flagValidation = staticKey;
                      }
                    }
                    const getInitalValue = convertSt
                      .slice(foundStartFrom, foundStartFrom + 9)
                      .trim()
                      ?.split(":");
                    if (getInitalValue.length === 2) {
                      const checkFirstEl = getInitalValue[0].toLowerCase() === "type" ? true : false;
                      if (!checkFirstEl) {
                        flagValidation = staticKey;
                      }
                      const checkSecondEl = checkFormatSm.includes(getInitalValue[1].toLowerCase());
                      if (!checkSecondEl) {

                        flagValidation = getInitalValue[0] + ":" + getInitalValue[1];
                        if(getInitalValue[0].includes('type')){
                          let getsearch = this.takingleftportingtr?.split(flagValidation)
                          this.takingleftportingtr = getsearch.join(' ')
                         }
                         else{
                          let getsearch = this.takingleftportingtr;
                          this.takingleftportingtr = getsearch
                         }
                        // let getsearch = this.takingleftportingtr?.split(flagValidation)
                        // this.takingleftportingtr = getsearch.join(' ')
                        this.normalsearchtype.push(flagValidation);
                      }
                      newArray.push(foundStartFrom);

                    } else {
                      flagValidation = staticKey;
                    }
                  } else {
                    flagValidation = staticKey;
                  }

              }
            }
          }

          }
   }

        if(this.NormalSearchValueArray[i].includes('owner')){
          if((this.NormalSearchValueArray[i].indexOf("owner")) != -1){
            let arrayowner = this.NormalSearchValueArray[i];
            let arraytargetowner = arrayowner?.split(":")
            if(arraytargetowner[1].toLowerCase().match(this.validRegex)){
              let validationowner = 'valid';
              // let getsearch = this.takingleftportingtr?.split(arraytargetowner[0] + ':' + arraytargetowner[1].toLowerCase())
              // this.takingleftportingtr = getsearch.join(' ')
              if(arraytargetowner[0].includes('owner')){
                let getsearch = this.takingleftportingtr?.split(arraytargetowner[0] + ':' + arraytargetowner[1].toLowerCase())
                this.takingleftportingtr = getsearch.join(' ')
               }
               else{
                let getsearch = this.takingleftportingtr;
                this.takingleftportingtr = getsearch
               }
            }
            else{
              if(arraytargetowner[1] !== ''){
                let validationowner= arraytargetowner[0] + ':' +arraytargetowner[1];
                if(arraytargetowner[0].includes('owner')){
                let getsearch = this.takingleftportingtr?.split(validationowner)
                this.takingleftportingtr = getsearch.join(' ')
                this.normalsearchtype.push(validationowner);
                 }
                 else{
                  let getsearch = this.takingleftportingtr;
                  this.takingleftportingtr = getsearch
                 }
                // let getsearch = this.takingleftportingtr?.split(validationowner)
                // this.takingleftportingtr = getsearch.join(' ')
                // this.normalsearchtype.push(validationowner);
              }
              else{
                const staticKeyowner=this.NormalSearchValue
                let validationowner="valid"
                if(staticKeyowner.includes("owner")){
                  const convertTextTypeowner=staticKeyowner.trimStart().trimEnd().toLowerCase();
                  ///check name:exist
                  if(convertTextTypeowner.includes('owner:')){
                      const indexTypeNameowner=convertTextTypeowner.indexOf('owner')
                      /////check before name what is exist
                      if(indexTypeNameowner>0){
                      //previously element
                      const checkPrevNameowner=convertTextTypeowner[indexTypeNameowner-1]===" "?true:false
                      if(!checkPrevNameowner) validationowner=staticKeyowner
                      }
                      const sliceStTypeowner=convertTextTypeowner.slice(indexTypeNameowner,convertTextTypeowner.length)
                      const splitTypeowner=sliceStTypeowner?.split(":")
                      if(splitTypeowner.length===2 && validationowner==='valid'){

                        if(splitTypeowner[1].match(this.validRegex)){
                          validationowner = 'valid';
                          // let getsearch = this.takingleftportingtr?.split(arraytargetowner[0] + ':' + arraytargetowner[1])
                          // this.takingleftportingtr = getsearch.join(' ')
                          if(arraytargetowner[0].includes('owner')){
                            let getsearch = this.takingleftportingtr?.split(arraytargetowner[0] + ':' + arraytargetowner[1].toLowerCase())
                            this.takingleftportingtr = getsearch.join(' ')
                           }
                           else{
                            let getsearch = this.takingleftportingtr;
                            this.takingleftportingtr = getsearch
                           }
                        }
                        else{
                          validationowner=splitTypeowner[0] + ":" + splitTypeowner[1];
                          if(splitTypeowner[0].includes('owner')){
                            let getsearch = this.takingleftportingtr?.split(validationowner)
                          this.takingleftportingtr = getsearch.join(' ')
                          this.normalsearchtype.push(validationowner);
                           }
                           else{
                            let getsearch = this.takingleftportingtr;
                            this.takingleftportingtr = getsearch
                           }

                        }



                      }else{
                        validationowner=staticKeyowner
                      }
                  }else{
                    validationowner=staticKeyowner
                  }


                }
              }
            }
            }
      }

      if(this.NormalSearchValueArray[i].includes('shared')){
        if((this.NormalSearchValueArray[i].indexOf("shared")) != -1){
          let arrayshared = this.NormalSearchValueArray[i];
          let arraytargetshared = arrayshared?.split(":")
          if(arraytargetshared[1].toLowerCase().match(this.validRegex)){
            let validationshared = 'valid';
            // let getsearch = this.takingleftportingtr?.split(arraytargetshared[0] + ':' + arraytargetshared[1].toLowerCase())
            // this.takingleftportingtr = getsearch.join(' ')
            if(arraytargetshared[0].includes('shared')){
              let getsearch = this.takingleftportingtr?.split(arraytargetshared[0] + ':' + arraytargetshared[1].toLowerCase())
              this.takingleftportingtr = getsearch.join(' ')
             }
             else{
              let getsearch = this.takingleftportingtr;
              this.takingleftportingtr = getsearch
             }
          }
          else{
            if(arraytargetshared[1] !== ''){
              let validationshared= arraytargetshared[0] + ':' + arraytargetshared[1];
              if(arraytargetshared[0].includes('shared')){
                let getsearch = this.takingleftportingtr?.split(validationshared)
                this.takingleftportingtr = getsearch.join(' ')
                this.normalsearchtype.push(validationshared);
               }
               else{
                let getsearch = this.takingleftportingtr;
                this.takingleftportingtr = getsearch
               }

            }
            else{
              const staticKeyshared=this.NormalSearchValue
              let validationshared="valid"
              if(staticKeyshared.includes("shared")){
                const convertTextTypeshared=staticKeyshared.trimStart().trimEnd().toLowerCase();
                ///check name:exist
                if(convertTextTypeshared.includes('shared:')){
                    const indexTypeNameshared=convertTextTypeshared.indexOf('shared')
                    /////check before name what is exist
                    if(indexTypeNameshared>0){
                    //previously element
                    const checkPrevNameshared=convertTextTypeshared[indexTypeNameshared-1]===" "?true:false
                    if(!checkPrevNameshared) validationshared=staticKeyshared
                    }
                    const sliceStTypeshared=convertTextTypeshared.slice(indexTypeNameshared,convertTextTypeshared.length)
                    const splitTypeshared=sliceStTypeshared?.split(":")
                    if(splitTypeshared.length===2 && validationshared==='valid'){

                      if(splitTypeshared[1].match(this.validRegex)){
                        validationshared = 'valid';
                        // let getsearch = this.takingleftportingtr?.split(splitTypeshared[0] + ':' + splitTypeshared[1])
                        // this.takingleftportingtr = getsearch.join(' ')
                        if(arraytargetshared[0].includes('shared')){
                          let getsearch = this.takingleftportingtr?.split(arraytargetshared[0] + ':' + arraytargetshared[1].toLowerCase())
                          this.takingleftportingtr = getsearch.join(' ')
                         }
                         else{
                          let getsearch = this.takingleftportingtr;
                          this.takingleftportingtr = getsearch
                         }
                      }
                      else{
                        validationshared=splitTypeshared[0] + ":" + splitTypeshared[1]
                        if(splitTypeshared[0].includes('shared')){
                          let getsearch = this.takingleftportingtr?.split(validationshared)
                          this.takingleftportingtr = getsearch.join(' ')
                          this.normalsearchtype.push(validationshared);
                         }
                         else{
                          let getsearch = this.takingleftportingtr;
                          this.takingleftportingtr = getsearch
                         }

                    }
                }else{
                  validationshared=splitTypeshared[0] + ":" + splitTypeshared[1];
                  if(splitTypeshared[0].includes('shared')){
                    let getsearch = this.takingleftportingtr?.split(validationshared)
                    this.takingleftportingtr = getsearch.join(' ')
                    this.normalsearchtype.push(validationshared);
                   }
                   else{
                    let getsearch = this.takingleftportingtr;
                    this.takingleftportingtr = getsearch
                   }

                }

              }
            }

          }
          }
    }


      }


      if(this.NormalSearchValueArray[i].includes('name')){
        if((this.NormalSearchValueArray[i].indexOf("name")) != -1){
          let arraytype = this.NormalSearchValueArray[i];
          let arraytargettype = arraytype?.split(":")
          if(arraytargettype[1] !== ''){
             if(arraytargettype[1].includes('\"')){
              const staticKeyType=this.NormalSearchValue
              let validationType="valid"
              if(staticKeyType.includes("name")){
                const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
                ///check name:exist
                if(convertTextType.includes('name:')){
                    const indexTypeName=convertTextType.indexOf('name')
                    /////check before name what is exist
                    if(indexTypeName>0){
                    //previously element
                    const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
                    if(!checkPrevName) {
                      validationType=staticKeyType
                    }
                    }
                    const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
                    const splitType=sliceStType?.split(":")
                    let dddd = splitType[1].lastIndexOf('\"')
                    let changevalue = splitType[1].slice(0,dddd)
                    splitType[1] = changevalue + '\"'
                    if(splitType.length >=2 && validationType==='valid'){

                    if(validationType==='valid'){
                    const checkFirstName=splitType[0].includes("name")
                    if(!checkFirstName)
                    {
                      validationType=staticKeyType
                    }

                    }
                    if(validationType==='valid'){
                    //check no space at 0 index
                    const checkSpaceName=splitType[1][0]===" "?false:true
                    if(!checkSpaceName)
                    {
                      validationType=splitType[0] + ":" + splitType[1];
                      if(splitType[0].includes('name')){
                        let getsearch = this.takingleftportingtr?.split(validationType)
                      this.takingleftportingtr = getsearch.join(' ')
                      this.normalsearchtype.push(validationType);
                       }
                       else{
                        let getsearch = this.takingleftportingtr;
                        this.takingleftportingtr = getsearch
                       }

                    }
                    }
                    if(validationType==='valid'){
                      ///check if start and last index is "
                      const checkQuoteStart=splitType[1][0]==='"' ? true:false
                      if(checkQuoteStart){
                      if(splitType[1].length>1){
                        const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                        const findQuExist=splitRemainingSt.indexOf('"')
                        if(findQuExist===-1){
                          validationType='valid';
                        //   let getsearch = this.takingleftportingtr?.split(splitType[0] + ':' + splitType[1])
                        // this.takingleftportingtr = getsearch.join(' ')
                        if(splitType[0].includes('name')){
                          let getsearch = this.takingleftportingtr?.split(splitType[0] + ':' + splitType[1].toLowerCase())
                          this.takingleftportingtr = getsearch.join(' ')
                         }
                         else{
                          let getsearch = this.takingleftportingtr;
                          this.takingleftportingtr = getsearch
                         }
                        }
                      }
                      }
                      }
                    ////
                    }else{
                        validationType=staticKeyType
                    }
                }else{
                    validationType=staticKeyType
                }

              }
             }
             else{

               if(arraytargettype[1]){
                 let namechk = 'valid'
                 if(arraytargettype[0].includes('name')){
                  let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1].toLowerCase())
                  this.takingleftportingtr = getsearch.join(' ')
                 }
                 else{
                  let getsearch = this.takingleftportingtr;
                  this.takingleftportingtr = getsearch
                 }

               }
             }
         }
         else{
          const staticKeyType=this.NormalSearchValue
          let validationType="valid"
          if(staticKeyType.includes("name")){
            const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
            ///check name:exist
            if(convertTextType.includes('name:')){
                const indexTypeName=convertTextType.indexOf('name')
                /////check before name what is exist
                if(indexTypeName>0){
                //previously element
                const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
                if(!checkPrevName) {
                  validationType=staticKeyType
                }
                }
                const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
                const splitType=sliceStType?.split(":")
                if(splitType.length===2 && validationType==='valid'){

                if(validationType==='valid'){
                const checkFirstName=splitType[0].includes("name")
                if(!checkFirstName)
                {
                  validationType=staticKeyType
                }

                }
                if(validationType==='valid'){
                //check no space at 0 index
                const checkSpaceName=splitType[1][0]===" "?false:true
                if(!checkSpaceName)
                {
                  validationType=splitType[0] + ":" + splitType[1];
                  if(splitType[0].includes('name')){
                    let getsearch = this.takingleftportingtr?.split(validationType)
                    this.takingleftportingtr = getsearch.join(' ')
                    this.normalsearchtype.push(validationType);
                   }
                   else{
                    let getsearch = this.takingleftportingtr;
                    this.takingleftportingtr = getsearch
                   }

                }
                }
                if(validationType==='valid'){
                  ///check if start and last index is "
                  const checkQuoteStart=splitType[1][0]==='"' ? true:false
                  if(checkQuoteStart){
                  if(splitType[1].length>1){
                    const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                    const findQuExist=splitRemainingSt.indexOf('"')
                    if(findQuExist===-1){
                      validationType=staticKeyType
                    }
                  }
                  }
                  }
                ////
                }else{
                    validationType=splitType[0]+":" + splitType[1];
                    if(splitType[0].includes('name')){
                      let getsearch = this.takingleftportingtr?.split(validationType)
                      this.takingleftportingtr = getsearch.join(' ')
                      this.normalsearchtype.push(validationType);

                     }
                     else{
                      let getsearch = this.takingleftportingtr;
                      this.takingleftportingtr = getsearch
                     }

                }
            }else{
                validationType=staticKeyType
            }

          }
         }
          }
   }

   if(this.parseFlag == 'True'){

     if(this.NormalSearchValueArray[i].includes('contains')){
      if((this.NormalSearchValueArray[i].indexOf("contains")) != -1){
        let arraytype = this.NormalSearchValueArray[i];
        let arraytargettype = arraytype?.split(":")
        if(arraytargettype[1] !== ''){
           if(arraytargettype[1].includes('\"')){
            const staticKeyType=this.NormalSearchValue
            let validationType="valid"
            if(staticKeyType.includes("contains")){
              const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
              ///check name:exist
              if(convertTextType.includes('contains:')){
                  const indexTypeName=convertTextType.indexOf('contains')
                  /////check before name what is exist
                  if(indexTypeName>0){
                  //previously element
                  const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
                  if(!checkPrevName) {
                    validationType=staticKeyType
                  }
                  }
                  const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
                  const splitType=sliceStType?.split(":")
                  let dddd = splitType[1].lastIndexOf('\"')
                  let changevalue = splitType[1].slice(0,dddd)
                  splitType[1] = changevalue + '\"'
                  if(splitType.length===2 && validationType==='valid'){

                  if(validationType==='valid'){
                  const checkFirstName=splitType[0].includes("contains")
                  if(!checkFirstName)
                  {
                    validationType=staticKeyType
                  }

                  }
                  if(validationType==='valid'){
                  //check no space at 0 index
                  const checkSpaceName=splitType[1][0]===" "?false:true
                  if(!checkSpaceName)
                  {
                    if(splitType[1].length > 200){
                      let gotoword = splitType[1].slice(0,200);
                      let getaboveword = splitType[1].slice(201,splitType[1].length)
                      splitType[1] = gotoword;
                    }
                    validationType=splitType[0] + ":" + splitType[1];
                    if(splitType[0].includes('contains')){
                      let getsearch = this.takingleftportingtr?.split(validationType)
                      this.takingleftportingtr = getsearch.join(' ')
                      this.normalsearchtype.push(validationType);
                     }
                     else{
                      let getsearch = this.takingleftportingtr;
                      this.takingleftportingtr = getsearch
                     }

                  }
                  }
                  if(validationType==='valid'){
                    ///check if start and last index is "
                    const checkQuoteStart=splitType[1][0]==='"' ? true:false
                    if(checkQuoteStart){
                    if(splitType[1].length>1){
                      const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                      const findQuExist=splitRemainingSt.indexOf('"')
                      if(findQuExist===-1){
                        validationType='valid';
                        let lengthofdata = splitType[1].length;
                        if(splitType[1].length > 200){
                          let gotoword = splitType[1].slice(0,200);
                          let getaboveword = splitType[1].slice(201,splitType[1].length)
                          splitType[1] = gotoword;
                        }
                        if(splitType[0].includes('contains')){
                          let getsearch = this.takingleftportingtr?.split(splitType[0] + ':' + splitType[1])
                          this.takingleftportingtr = getsearch.join(' ')
                         }
                         else{
                          let getsearch = this.takingleftportingtr;
                          this.takingleftportingtr = getsearch
                         }

                      }
                    }
                    }
                    }

                  }else{
                      validationType=staticKeyType
                  }
              }else{
                  validationType=staticKeyType
              }

            }
           }
           else{

             if(arraytargettype[1]){
               let namechk = 'valid'
               if(arraytargettype[1].length > 200){
                let gotoword = arraytargettype[1].slice(0,200);
                let getaboveword = arraytargettype[1].slice(201,arraytargettype[1].length)
                arraytargettype[1] = gotoword;
              }

              if(arraytargettype[0].includes('contains')){
                let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1])
                this.takingleftportingtr = getsearch.join(' ')
               }
               else{
                let getsearch = this.takingleftportingtr;
                this.takingleftportingtr = getsearch
               }

             }
           }
       }
       else{
        const staticKeyType=this.NormalSearchValue
        let validationType="valid"
        if(staticKeyType.includes("contains")){
          const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
          ///check name:exist
          if(convertTextType.includes('contains:')){
              const indexTypeName=convertTextType.indexOf('contains')
              /////check before name what is exist
              if(indexTypeName>0){
              //previously element
              const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
              if(!checkPrevName) {
                validationType=staticKeyType
              }
              }
              const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
              const splitType=sliceStType?.split(":")
              if(splitType.length===2 && validationType==='valid'){

              if(validationType==='valid'){
              const checkFirstName=splitType[0].includes("contains")
              if(!checkFirstName)
              {
                validationType=staticKeyType
              }

              }
              if(validationType==='valid'){
              //check no space at 0 index
              const checkSpaceName=splitType[1][0]===" "?false:true
              if(!checkSpaceName)
              {
                if(splitType[1].length > 200){
                  let gotoword = splitType[1].slice(0,200);
                  let getaboveword = splitType[1].slice(201,splitType[1].length)
                  splitType[1] = gotoword;
                }
                validationType=splitType[0] + ":" + splitType[1];
                if(splitType[0].includes('contains')){
                  let getsearch = this.takingleftportingtr?.split(validationType)
                  this.takingleftportingtr = getsearch.join(' ')
                  this.normalsearchtype.push(validationType);
                 }
                 else{
                  let getsearch = this.takingleftportingtr;
                  this.takingleftportingtr = getsearch
                 }

              }
              }
              if(validationType==='valid'){
                ///check if start and last index is "
                const checkQuoteStart=splitType[1][0]==='"' ? true:false
                if(checkQuoteStart){
                if(splitType[1].length>1){
                  const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                  const findQuExist=splitRemainingSt.indexOf('"')
                  if(findQuExist===-1){
                    validationType=staticKeyType
                  }
                }
                }
                }

              }else{
                if(splitType[1].length > 200){
                  let gotoword = splitType[1].slice(0,200);
                  let getaboveword = splitType[1].slice(201,splitType[1].length)
                  splitType[1] = gotoword;
                }
                  validationType=splitType[0] + ":" +splitType[1];
                  if(splitType[0].includes('contains')){
                    let getsearch = this.takingleftportingtr?.split(validationType)
                  this.takingleftportingtr = getsearch.join(' ')
                  this.normalsearchtype.push(validationType);
                   }
                   else{
                    let getsearch = this.takingleftportingtr;
                    this.takingleftportingtr = getsearch
                   }


              }
          }else{
              validationType=staticKeyType
          }

        }
       }
        }
  }
   }



if(this.NormalSearchValueArray[i].includes('tags')){
  if((this.NormalSearchValueArray[i].indexOf("tags")) != -1){
    let arraytype = this.NormalSearchValueArray[i];
    let arraytargettype = arraytype?.split(":")
    if(arraytargettype[1] !== ''){
       if(arraytargettype[1].includes('\"')){
        const staticKeyType=this.NormalSearchValue
        let validationType="valid"
        if(staticKeyType.includes("tags")){
          const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
          ///check name:exist
          if(convertTextType.includes('tags:')){
              const indexTypeName=convertTextType.indexOf('tags')
              /////check before name what is exist
              if(indexTypeName>0){
              //previously element
              const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
              if(!checkPrevName) {
                validationType=staticKeyType
              }
              }
              const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
              const splitType=sliceStType?.split(":")
              let dddd = splitType[1].lastIndexOf('\"')
              let changevalue = splitType[1].slice(0,dddd)
              splitType[1] = changevalue + '\"'
              if(splitType.length===2 && validationType==='valid'){

              if(validationType==='valid'){
              const checkFirstName=splitType[0].includes("tags")
              if(!checkFirstName)
              {
                validationType=staticKeyType
              }

              }
              if(validationType==='valid'){
              //check no space at 0 index
              const checkSpaceName=splitType[1][0]===" "?false:true
              if(!checkSpaceName)
              {
                validationType=splitType[0] + ":" + splitType[1];
                if(splitType[0].includes('tags')){
                  let getsearch = this.takingleftportingtr?.split(validationType)
                  this.takingleftportingtr = getsearch.join(' ')
                  this.normalsearchtype.push(validationType);
                 }
                 else{
                  let getsearch = this.takingleftportingtr;
                  this.takingleftportingtr = getsearch
                 }

              }
              }
              if(validationType==='valid'){
                ///check if start and last index is "
                const checkQuoteStart=splitType[1][0]==='"' ? true:false
                if(checkQuoteStart){
                if(splitType[1].length>1){
                  const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                  const findQuExist=splitRemainingSt.indexOf('"')
                  if(findQuExist===-1){
                    validationType='valid';
                    if(splitType[0].includes('tags')){
                      let getsearch = this.takingleftportingtr?.split(splitType[0] + ':' + splitType[1])
                      this.takingleftportingtr = getsearch.join(' ')
                     }
                     else{
                      let getsearch = this.takingleftportingtr;
                      this.takingleftportingtr = getsearch
                     }

                  }
                }
                }
                }

              }else{
                  validationType=staticKeyType
              }
          }else{
              validationType=staticKeyType
          }

        }
       }
       else{

         if(arraytargettype[1]){
           let namechk = 'valid'
           if(arraytargettype[0].includes('tags')){
            let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1].toLowerCase())
            this.takingleftportingtr = getsearch.join(' ')
           }
           else{
            let getsearch = this.takingleftportingtr;
            this.takingleftportingtr = getsearch
           }

         }

       }
   }
   else{
    const staticKeyType=this.NormalSearchValue
    let validationType="valid"
    if(staticKeyType.includes("tags")){
      const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
      ///check name:exist
      if(convertTextType.includes('tags:')){
          const indexTypeName=convertTextType.indexOf('tags')
          /////check before name what is exist
          if(indexTypeName>0){
          //previously element
          const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
          if(!checkPrevName) {
            validationType=staticKeyType
          }
          }
          const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
          const splitType=sliceStType?.split(":")
          if(splitType.length===2 && validationType==='valid'){

          if(validationType==='valid'){
          const checkFirstName=splitType[0].includes("tags")
          if(!checkFirstName)
          {
            validationType=staticKeyType
          }

          }
          if(validationType==='valid'){
          //check no space at 0 index
          const checkSpaceName=splitType[1][0]===" "?false:true
          if(!checkSpaceName)
          {
            validationType=splitType[0] + ":" + splitType[1];
            if(splitType[0].includes('tags')){
              let getsearch = this.takingleftportingtr?.split(validationType)
              this.takingleftportingtr = getsearch.join(' ')
              this.normalsearchtype.push(validationType);
             }
             else{
              let getsearch = this.takingleftportingtr;
              this.takingleftportingtr = getsearch
             }

          }
          }
          if(validationType==='valid'){
            ///check if start and last index is "
            const checkQuoteStart=splitType[1][0]==='"' ? true:false
            if(checkQuoteStart){
            if(splitType[1].length>1){
              const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
              const findQuExist=splitRemainingSt.indexOf('"')
              if(findQuExist===-1){
                validationType=staticKeyType
              }
            }
            }
            }

          }else{
              validationType=splitType[0]+":" + splitType[1];
              if(splitType[0].includes('tags')){
                let getsearch = this.takingleftportingtr?.split(validationType)
                  this.takingleftportingtr = getsearch.join(' ')
                  this.normalsearchtype.push(validationType);
               }
               else{
                let getsearch = this.takingleftportingtr;
                this.takingleftportingtr = getsearch
               }


          }
      }else{
          validationType=staticKeyType
      }

    }
   }
    }
}

if(this.NormalSearchValueArray[i].includes('date')){
  if((this.NormalSearchValueArray[i].indexOf("date")) != -1){
    let arraytype = this.NormalSearchValueArray[i];
    let arraytargettype = arraytype?.split(":")
    if(arraytargettype[1]){
      if(arraytargettype[1] !== ''){
        if(arraytargettype[1].includes('\"')){
         const staticKeyType=this.NormalSearchValue
         let validationType="valid"
         if(staticKeyType.includes("date")){
           const convertTextType=staticKeyType.trimStart().trimEnd().toLowerCase();
           ///check name:exist
           if(convertTextType.includes('date:')){
               const indexTypeName=convertTextType.indexOf('date')
               /////check before name what is exist
               if(indexTypeName>0){
               //previously element
               const checkPrevName=convertTextType[indexTypeName-1]===" "?true:false
               if(!checkPrevName) {
                 validationType=staticKeyType
               }
               }
               const sliceStType=convertTextType.slice(indexTypeName,convertTextType.length)
               const splitType=sliceStType?.split(":")
               if(splitType.length===2 && validationType==='valid'){

               if(validationType==='valid'){
               const checkFirstName=splitType[0].includes("date")
               if(!checkFirstName)
               {
                 validationType=staticKeyType
               }

               }
               if(validationType==='valid'){
               //check no space at 0 index
               const checkSpaceName=splitType[1][0]===" "?false:true
               if(!checkSpaceName)
               {
                 validationType=splitType[0] + ":" + splitType[1];
                 if(splitType[0].includes('date')){
                   let getsearch = this.takingleftportingtr?.split(validationType)
                   this.takingleftportingtr = getsearch.join(' ')
                   this.normalsearchtype.push(validationType);
                  }
                  else{
                   let getsearch = this.takingleftportingtr;
                   this.takingleftportingtr = getsearch
                  }

               }
               }
               if(validationType==='valid'){
                 ///check if start and last index is "
                 const checkQuoteStart=splitType[1][0]==='"' ? true:false
                 if(checkQuoteStart){
                 if(splitType[1].length>1){
                   const splitRemainingSt=splitType[1].slice(2,splitType[1].length-1)
                   const findQuExist=splitRemainingSt.indexOf('"')
                   if(findQuExist===-1){
                     validationType='valid';
                     if(splitType[0].includes('date')){
                       let getsearch = this.takingleftportingtr?.split(splitType[0] + ':' + splitType[1])
                       this.takingleftportingtr = getsearch.join(' ')
                      }
                      else{
                       let getsearch = this.takingleftportingtr;
                       this.takingleftportingtr = getsearch
                      }

                   }
                 }
                 }
                 }

               }else{
                   validationType=staticKeyType
               }
           }else{
               validationType=staticKeyType
           }

         }
        }
        else{

          if(arraytargettype[1]){
            let namechk = 'valid'
            if(arraytargettype[0].includes('date')){
             let getsearch = this.takingleftportingtr?.split(arraytargettype[0] + ':' + arraytargettype[1])
            this.takingleftportingtr = getsearch.join(' ')
            }
            else{
             let getsearch = this.takingleftportingtr;
             this.takingleftportingtr = getsearch
            }

          }
        }
    }
    }


    }
}

  }

  if(this.takingleftportingtr){
    this.normalsearchtype = [];
      this.normalsearchtype.push(this.takingleftportingtr)
  }
  this.IncludetheValue = this.normalsearchtype.join(' ');

  if(this.IncludetheValue.length >0){
    let str = this.IncludetheValue
    if (!str.trim().length) {
     this.IncludetheValue = '';
   }

  }
}
     /**
   *breadcrumb Second Lvl function
   */
  breadcrumbSecondLvl(event) {
    if (event.target.outerText === 'Users') {
      this.router.navigate(['/pages/workspace-admin/users']);
    }


  };
    /**
   *count Occurences function
   */
   countOccurences(string, word) {
    return string?.split(word).length - 1;
 }
  /**
   *cabinet Month Click function
   */
  cabinetMonthClick(event){
      this.sharedService.monthClick.next(event);
  }
   /**
   *cabinet Year Click function
   */
  cabinetYearClick(event){
    this.sharedService.monthClick.next(event);
  }
 /**
   *cabinet label Click function
   */
  cabinetlabel(event){
    this.sharedService.labelClick.next(event);
  }
  /**
   *cabinet owned Click function
   */
  cabinetowned(event){
  this.sharedService.ownedClick.next(event);
  this.cabinedBreadcrumbOwned = '';
  }
  /**
   *cabinet owned value function
   */
  cabinetvalue(event){
    this.sharedService.labelClick.next(event);
  }
   /**
   *Include word  function
   */
  includeWord(event){

    let tempValueDuplicate = [];
    let tempValue = [];
  var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
  if(event.target.value !== ''){
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')

    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }

    const chars = event.target.value;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)

           }
        }

      }

      event.target.value = tempValue.join('')
      this.IncludetheValue = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
      for(let i=0;i<=tempValue.length-1;i++){
        let temp = {
          word : tempValue[i],
          ValueTarget : true
        }
        tempValueDuplicate.push(temp)
      }
      if (!regex.test(char)) {

        // alert("Please Enter English Only");
        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

        event.target.value = tempValue.join('')
        this.IncludetheValue = event.target.value;

        event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }

  }
  else{
    tempValueDuplicate = [];
    tempValue = [];
    tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
    const chars = event.key;
    const char = chars;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if(key == "å"){
      // alert("Please Enter English Only");
      if(tempValue.length > 0){
        for(let i=0;i<=tempValue.length-1;i++){

         if(!regex.test(tempValue[i])){
          tempValueDuplicate[i].ValueTarget = false
         }
        }
        tempValue = [];
        for(let i=0;i<=tempValueDuplicate.length-1;i++){
           if(tempValueDuplicate[i].ValueTarget){
            tempValue.push(tempValueDuplicate[i].word)
           }
        }

      }

      event.target.value = tempValue.join('')
      this.IncludetheValue = event.target.value;

      event.preventDefault()

      // return false;
    }
    else{
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
    for(let i=0;i<=tempValue.length-1;i++){
      let temp = {
        word : tempValue[i],
        ValueTarget : true
      }
      tempValueDuplicate.push(temp)
    }
      if (!regex.test(char)) {

        if(tempValue.length > 0){
          for(let i=0;i<=tempValue.length-1;i++){

           if(!regex.test(tempValue[i])){
            tempValueDuplicate[i].ValueTarget = false
           }
          }
          tempValue = [];
          for(let i=0;i<=tempValueDuplicate.length-1;i++){
             if(tempValueDuplicate[i].ValueTarget){
              tempValue.push(tempValueDuplicate[i].word)
             }
          }

        }

          event.target.value = tempValue.join('')
          this.IncludetheValue = event.target.value;

         event.preventDefault()

        // return false;

      }
      // else {
      //   return true;
      // }
    }
  }



    this.NormalSearchValueArrayincludes = [];
    const str = event.target.value;
   let string = str.replace(/  +/g, ' ');
    this.IncludetheValue = string;
    let value = '';
    value = this.IncludetheValue.includes(' ') ? this.IncludetheValue : this.IncludetheValue;
    this.NormalSearchValueArrayincludes.push(value);
    let includeValueAdd = '';
              if(this.IncludetheValue !== undefined){
                includeValueAdd = this.IncludetheValue
              }
    this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
      this.NormalSearchValueArrayDate.join(' ') +
      this.NormalSearchValueArrayTagsNotes.join(' ') +
      this.NormalSearchValueArrayContains.join(' ') + this.NormalSearchValueArrayShared.join(' ') +
      this.NormalSearchValueArrayOwner.join(' ') + this.NormalSearchValueArrayType.join(' ') +
       includeValueAdd;
      if(this.IncludetheValue){
        this.searchType = 'Advance';
        this.searchTypeModify = 'change'
      }
      else{
        this.searchType = 'Single';
        this.searchTypeModify = 'change'
      }
  }
   /**
   *on Date Select function
   */
  onDateSelect(event){
      this.onSearchBtnClicked = true;
    if (event === 'Anytime' || event === undefined){
      sessionStorage.setItem('searchdate','Anytime');
      this.multiSearchInputHandler('date');
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ') +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ') + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ') + this.NormalSearchValueArrayType.join(' ') +
        this.NormalSearchValueArrayincludes.join(' ') ;
      this.customDropDownn=false;
       this.toDate="";
       this.fromDate="";
       this.rangeDate = '';

    }
    if (event=== 'Today'){
      this.customDropDownn=false;
      sessionStorage.setItem('searchdate','Today');
      this.fromDate =new Date ();
      this.toDate =new Date ();

      let DateTareget = this.fromDate.toISOString();
      let tempCheck1 = DateTareget?.split('Z')
      this.fromDate = tempCheck1[0]

      let DateTaregetTo = this.toDate.toISOString();
      let tempCheck2 = DateTaregetTo?.split('Z')
      let ToFrom = tempCheck2[0];

      this.toDate = ToFrom
      this.docTagsValueEnd = undefined;
      this.multiSearchInputHandler('date');
      let value = '';
      value = "date:" + (new DatePipe('en-US').transform(this.fromDate, 'yyyy-MM-dd')) + ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
        this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
        this.rangeDate = '';

    }
    if(event === 'Yesterday'){
      this.customDropDownn=false;

      sessionStorage.setItem('searchdate','Yesterday');
      this.toDate = new Date();
      this.docTagsValueEnd = undefined;
      this.fromDate = new Date()
      this.fromDate.setDate(this.fromDate.getDate() - 1)
      this.toDate.setDate(this.toDate.getDate() - 1)

      let DateTareget = new Date(this.fromDate).toISOString();
      let tempCheck1 = DateTareget?.split('Z')
      this.fromDate = tempCheck1[0]

      let DateTaregetTo = new Date(this.toDate).toISOString();
      let tempCheck2 = DateTaregetTo?.split('Z')
      this.toDate = tempCheck2[0]


      this.multiSearchInputHandler('date');
      let value = '';
      value = "date:" + (new DatePipe('en-US').transform((this.fromDate), 'yyyy-MM-dd')) + ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
          this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ') + ' ' +
        this.NormalSearchValueArrayincludes.join(' ') ;

      this.rangeDate = '';

    }
    if(event === 'Last 7 days'){
      this.customDropDownn=false;
      sessionStorage.setItem('searchdate','Last 7 days');
      this.toDate = new Date();
      this.fromDate = new Date(this.toDate)
      this.fromDate.setDate(this.fromDate.getDate() - 7);

      let DateTareget = new Date(this.fromDate).toISOString();
      let tempCheck1 = DateTareget?.split('Z')
      this.fromDate = tempCheck1[0]

      let DateTaregetTo = new Date(this.toDate).toISOString();
      let tempCheck2 = DateTaregetTo?.split('Z')
      this.toDate = tempCheck2[0]
      this.multiSearchInputHandler('date');
      let value = '';
      value = "date:" + (new DatePipe('en-US').transform(this.fromDate, 'yyyy-MM-dd')) + ',' + (new DatePipe('en-US').transform(this.toDate, 'yyyy-MM-dd')) + ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
        this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
        this.rangeDate = '';

    }
    if(event === 'Last 30 days'){
      this.customDropDownn=false;
      sessionStorage.setItem('searchdate','Last 30 days');
      this.toDate = new Date();
      this.fromDate = new Date(this.toDate)
      this.fromDate.setDate(this.fromDate.getDate() - 30);

      let DateTareget = new Date(this.fromDate).toISOString();
      let tempCheck1 = DateTareget?.split('Z')
      this.fromDate = tempCheck1[0]

      let DateTaregetTo = new Date(this.toDate).toISOString();
      let tempCheck2 = DateTaregetTo?.split('Z')
      this.toDate = tempCheck2[0]

      this.multiSearchInputHandler('date');
      let value = '';
      value = "date:" + (new DatePipe('en-US').transform(this.fromDate, 'yyyy-MM-dd')) + ',' + (new DatePipe('en-US').transform(this.toDate, 'yyyy-MM-dd')) + ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
        this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
        this.rangeDate = '';

    }
    if(event === 'Last 90 days'){
      this.customDropDownn=false;
      sessionStorage.setItem('searchdate','Last 90 days');
      this.toDate = new Date();
      this.fromDate = new Date(this.toDate)
      this.fromDate.setDate(this.fromDate.getDate() - 90);

      let DateTareget = new Date(this.fromDate).toISOString();
      let tempCheck1 = DateTareget?.split('Z')
      this.fromDate = tempCheck1[0]

      let DateTaregetTo = new Date(this.toDate).toISOString();
      let tempCheck2 = DateTaregetTo?.split('Z')
      this.toDate = tempCheck2[0]

      this.multiSearchInputHandler('date');
      let value = '';
      value = "date:" + (new DatePipe('en-US').transform(this.fromDate, 'yyyy-MM-dd')) + ',' + (new DatePipe('en-US').transform(this.toDate, 'yyyy-MM-dd')) + ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
        this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ') +  this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
        this.rangeDate = '';

     }
    if(event === 'Custom..'){
       this.customDropDownn =true;

       sessionStorage.setItem('searchdate','Custom');
    }

    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
  clickMehere(data){
this.oncardclick3=data;
  }
     /**
   *open search var
   */
  opensearch = '';

     /**
   *filter Toggle function
   */
  filterToggle(data){
    this.advFilterDropdown = !this.advFilterDropdown;
    this.opensearch = 'Advancesearch';
    this.advancesearchtoggleid = document.getElementById("searchDeep").id;
    this.hidenotify=true;
        this.isMenu=false;
        this.showProfile=false;
  }
  fetchId1(id){
   this.selectedFormat = id;
  }
     /**
   *oncardclick var
   */
  oncardclick = '';
  oncardclick1=' ';
   /**
   *documnet click component
   */
  @HostListener('document:click',['$event'])
     /**
   *documentClick function
   */
documentClick(event) {
  if (!this._eref.nativeElement.contains(event.target)) {
    if (this.oncardclick != 'cardclickadv' && !this.onSearchBtnClicked  && this.getCalenderId !='cdk-overlay-0' && this.selectedFormat != 'a069e2438ecd'){
      this.advFilterDropdown = false;
      this.oncardclick = '';
      this.getCalenderId = '';
      this.onSearchBtnClicked = false;
      this.selectedFormat = '';
    }else {

      this.oncardclick = '';
    }
    if (!this._eref.nativeElement.contains(event.target)) {

      if(!this.profiletogglefield){
        if (this.oncardclick1 != 'profileclk'){
          this.showProfile = false;
        }
        else{
         this.oncardclick1 = ''
        }
      }

      if(!this.itemVAlueIdVariable){
        if(this.oncardclick3 != 'closeMe'){
         this.isMenu=false;
        }
        else{
          this.oncardclick3=''
        }
      }
      if(!this.notificstionItemId){
        if(this.oncardclick4 != 'notifyClose'){
          this.hidenotify=true;
        }
        else{this.oncardclick4=''}
      }
      }
}
if (this._eref.nativeElement.contains(event.target)) {
    if(!this.advancesearchtoggleid){
      if (this.oncardclick != 'cardclickadv' && !this.onSearchBtnClicked   && this.getCalenderId !='cdk-overlay-0' && this.selectedFormat != 'a069e2438ecd'){
        this.advFilterDropdown = false;
        this.onSearchBtnClicked = false;
        this.selectedFormat = '';
      }
      else{
       this.oncardclick = '';
      }
      }
      else{
      this.advancesearchtoggleid = '';
    }

    if (this._eref.nativeElement.contains(event.target)) {

    if(!this.profiletogglefield){
      if (this.oncardclick1 != 'profileclk'){
        this.showProfile = false;
      }
      else{
       this.oncardclick1 = ''
      }
    }
    else{
      this.profiletogglefield = '';
    }

    if(!this.itemVAlueIdVariable){
      if(this.oncardclick3 != 'closeMe'){
       this.isMenu=false;
      }
      else{
        this.oncardclick3=''
      }
    }
    else{
      this.itemVAlueIdVariable='';
    }
    if(!this.notificstionItemId){
      if(this.oncardclick4 != 'notifyClose'){
        this.hidenotify=true;
      }
      else{this.oncardclick4=''}
    }

      else{this.notificstionItemId='';}


    }


  }else{
    // if(this.onSearchBtnClicked){
    //   this.advFilterDropdown = true;
    //   this.onSearchBtnClicked = false
    // }
  }
}



   /**
   *advclick function
   */

advclick(data){
  this.oncardclick = data;
  // this.hidenotify=true;
}
profilediv(data){
  this.oncardclick1 = data;
  // this.hidenotify=true;
}
 /**
   *advancesearchtoggleid var
   */

advancesearchtoggleid = '';
 /**
   *clickonadvsearch var
   */
  clickonadvsearch;
   /**
   *advancesearch function
   */
  advancesearch(data){
    this.clickonadvsearch = data;
  }
    /**
   *notifyActions function
   */
   notifyActions(id) {
    if (id == "MarkASREAD") {
      this.allreadmessage();
    } else if (id == "DELETE") {
      if( !(this.msgnotification === 'No notifications')){
        this.deleteallnotification();
      }

    }
  }
   /**
   *ngOnDestroy function
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this?.subscriptionOwnedBy?.unsubscribe();
    this?.breadCrumnSubs?.unsubscribe();
    this?.subscriptionSidebar?.unsubscribe();
  }
   /**
   *onTypeSelect function
   */
  searchType:any
  searchTypeModify = '';
  onTypeSelect(event){
    if (this.selectedFormat == 'a069e2438ecd' ){
      this.onSearchBtnClicked = true;
    }
    this.selectedFormat = '';
    if (event?.name === 'Any' || event === undefined ) {
      this.isTypeSelected = event?.name;
      let filteredOwner;
    filteredOwner = this.NormalSearchValueArrayType.filter(a => a?.split(':')[0] === 'type');
    if (filteredOwner.length > 0) {
      this.NormalSearchValueArrayType.forEach(element => {
        filteredOwner.forEach(value => {
          if (element === value) {
            let index = this.NormalSearchValueArrayType.indexOf(element)
            this.NormalSearchValueArrayType.splice(index, 1);
            this.cd.detectChanges();
          }
        });
      });
    }
    let includeValueAdd = '';
    if(this.IncludetheValue !== undefined){
      includeValueAdd = this.IncludetheValue
    }
      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ')  +
        this.NormalSearchValueArrayDate.join(' ')  +
        this.NormalSearchValueArrayTagsNotes.join(' ')  +
        this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
        this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ')  +
        this.NormalSearchValueArrayincludes.join(' ') ;
    } else {
      let filteredOwner;
    filteredOwner = this.NormalSearchValueArrayType.filter(a => a?.split(':')[0] === 'type');
    if (filteredOwner.length > 0) {
      this.NormalSearchValueArrayType.forEach(element => {
        filteredOwner.forEach(value => {
          if (element === value) {
            let index = this.NormalSearchValueArrayType.indexOf(element)
            this.NormalSearchValueArrayType.splice(index, 1);
            this.cd.detectChanges();
          }
        });
      });
    }
      this.isTypeSelected = event.name;
      if (this.isTypeSelected !== null && this.isTypeSelected !== 'Any' && this.isTypeSelected !== '' && this.isTypeSelected !== undefined) {
        let value = '';
        value = 'type:' + this.isTypeSelected + ' ';
        this.NormalSearchValueArrayType.push(value);
        let includeValueAdd = '';
        if(this.IncludetheValue !== undefined){
          includeValueAdd = this.IncludetheValue
        }
        this.NormalSearchValue =
        this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ') +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ') + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ') + this.NormalSearchValueArrayType.join(' ') +
        this.NormalSearchValueArrayincludes.join(' ') ;
      }
    }
    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }

  }
  /**
   * multiSearchInputHandler function
   */
  multiSearchInputHandler(type){
    let filteredOwner;
    filteredOwner = this.NormalSearchValueArrayDate.filter(a => a?.split(':')[0] === type);
    if (filteredOwner.length > 0) {
      this.NormalSearchValueArrayDate.forEach(element => {
        filteredOwner.forEach(value => {
          if (element === value) {
            let index = this.NormalSearchValueArrayDate.indexOf(element)
            this.NormalSearchValueArrayDate.splice(index, 1);
            this.cd.detectChanges();
          }
        });
      });
    }
  }
    /**
   * changeTheme function
   */
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
    /**
   * workspacename var
   */
  workspacename;
/**
   * icon Path name var
   */
  iconPathname;
  /**
   *icon Path var
   */
  iconPath;
    /**
   *log out function
   */
  logout() {
    let logoinIdCookie = 'localloginId' + localStorage.getItem('localwsId')
    let wsIdCookie = 'localwsId' + localStorage.getItem('localwsId')
    let postData: any
    postData = {
      "EmailId": this.createdBy,
      "Wsoid": Number(this.workSpaceId)
    }
    this.signUpService.signOutService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.statusCode == 'C200') {
          this.router.navigateByUrl('/onboarding/sign-in');
          this.notifylogout();
          if (localStorage.getItem('WorkSpaceName')) {
            this.workspacename = localStorage.getItem('WorkSpaceName')
            this.cookieService.set('wsname', this.workspacename);
          }
          if (localStorage.getItem('iconPath')) {
            this.iconPathname = localStorage.getItem('iconPath')
            this.cookieService.set('iconPathname', this.iconPathname);
            if (this.iconPathname == 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D') {
              let datanot = "hide"
              localStorage.setItem('iconPath', 'null')

            }
            else {
              this.iconPath = this.iconPathname;
              localStorage.setItem('iconPath', this.iconPath)

            }

          }
          this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          localStorage.clear();
          sessionStorage.clear();

          this.cookieService.delete('loginguid')
        }
      } else {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigateByUrl('/onboarding/sign-in');
      }
    })
  }

     /**
   *loctiondirect function
   */
  loctiondirect(){
    this.router.navigate(['/pages/dashboard']);
      this.sharedService.dashboardReload.next();
   // this.cookieService.set('breadCrumbFirstLvl' , 'WS_Localhost')
    localStorage.setItem('breadCrumbFirstLvl' , 'WS_Localhost');
    this.sharedService.dashboardskelton.next();
    this.sharedService.braedCrumbFirstLevel.next();

  }
   /**
   *notifyl ogout function
   */
  notifylogout(){
    let notify = {
      "wsoid": Number(this.workSpaceId),
      "key": "",
      "userEmailID": this.createdBy
    }
    this.signUpService.notifylogout(notify).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.statusCode == 'C200') {


        }
      }
    })
  }
  /**
   * date change for tags
   */
   fetchId(data:any){
    this.getCalenderId = data;
   }
  dateChange(event,b){

    this.dateId = b
    // if (this.oncardclick != 'cardclickadv'){
    //   this.advFilterDropdown = true;
    // }
    this.getCalenderId = '';
    this.docTagsValue = event;
    let filteredOwner;
    filteredOwner = this.NormalSearchValueArrayTagsNotes.filter(a => a?.split(':')[0] === 'tags');
    if (filteredOwner.length > 0) {
      this.NormalSearchValueArrayTagsNotes.forEach(element => {
        filteredOwner.forEach(value => {
          if (element === value) {
            let index = this.NormalSearchValueArrayTagsNotes.indexOf(element)
            this.NormalSearchValueArrayTagsNotes.splice(index, 1);
            this.cd.detectChanges();
          }
        });
      });
    }
      let value = '';
      value = 'tags:' + new DatePipe('en-US').transform(this.docTagsValue, 'yyyy-MM-dd')+ ' ';
      this.NormalSearchValueArrayTagsNotes.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }
    this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
      this.NormalSearchValueArrayDate.join(' ')  +
      this.NormalSearchValueArrayTagsNotes.join(' ')  +
      this.NormalSearchValueArrayContains.join(' ')  + this.NormalSearchValueArrayShared.join(' ')  +
      this.NormalSearchValueArrayOwner.join(' ')  + this.NormalSearchValueArrayType.join(' ') +
      this.NormalSearchValueArrayincludes.join(' ') ;
      if(this.IncludetheValue){
        this.searchType = 'Advance';
        this.searchTypeModify = 'change'

      }
      else{
        this.searchType = 'Single';
        this.searchTypeModify = 'change'

      }
      this.cd.detectChanges();
  }
  /**
   * dateChangeCustom
   */
  dateChangeCustom(event?,text?){
    if(text=='text'){
    let date=  event.target.value?.split('-')
    this.docTagsValueStart = date[0]
    this.docTagsValueEnd =date[1]

    }
    else{
      this.docTagsValueStart = event?.start;
      this.docTagsValueEnd = event?.end;
    }

    let fromDate11 = moment(this.docTagsValueStart).format('YYYY-MM-DD');
    let mergeDate = fromDate11 + 'T00:51:39.498'

    this.docTagsValueStart = mergeDate
    let toDate11 = moment(this.docTagsValueEnd).format('YYYY-MM-DD');
    let mergeDate2 = toDate11 + 'T00:51:39.498'

    this.docTagsValueEnd = mergeDate2

    let filteredOwner;
    filteredOwner = this.NormalSearchValueArrayDate.filter(a => a?.split(':')[0] === 'date');
    if (filteredOwner.length > 0) {
      this.NormalSearchValueArrayDate.forEach(element => {
        filteredOwner.forEach(value => {
          if (element === value) {
            let index = this.NormalSearchValueArrayDate.indexOf(element)
            this.NormalSearchValueArrayDate.splice(index, 1);
            this.cd.detectChanges();
          }
        });
      });
    }
    if (this.docTagsValueEnd !== null && this.docTagsValueEnd !== undefined && this.docTagsValueEnd !== '') {
      let value = '';
      value = 'date:' + new DatePipe('en-US').transform(this.docTagsValueStart, 'yyyy-MM-dd') + "," + new DatePipe('en-US').transform(this.docTagsValueEnd, 'yyyy-MM-dd')+ ' ';
      this.NormalSearchValueArrayDate.push(value);
      let includeValueAdd = '';
      if(this.IncludetheValue !== undefined){
        includeValueAdd = this.IncludetheValue
      }

      this.NormalSearchValue = this.NormalSearchValueArrayName.join(' ') +
        this.NormalSearchValueArrayDate.join(' ') +
        this.NormalSearchValueArrayTagsNotes.join(' ') +
        this.NormalSearchValueArrayContains.join(' ') + this.NormalSearchValueArrayShared.join(' ') +
        this.NormalSearchValueArrayOwner.join(' ') + this.NormalSearchValueArrayType.join(' ') +
        this.NormalSearchValueArrayincludes.join(' ') ;
      this.cd.detectChanges();
    }
    if(this.IncludetheValue){
      this.searchType = 'Advance';
      this.searchTypeModify = 'change'
    }
    else{
      this.searchType = 'Single';
      this.searchTypeModify = 'change'
    }
  }
/**
 * toggle Sidebar return function
 */
toggleSidebar(): boolean {
  this.sharedservice.headerToggle.next(true);
  this.toggleRight = !this.toggleRight;
  this.sidebarService.toggle(true, 'menu-sidebar');
  this.layoutService.changeLayoutSize();
  this.toggleRight ? this.sharedService.changeLeftToggle.next(false) : this.sharedService.changeLeftToggle.next(false);
  return false;
}


  /**
 * search result var
 */

  searchresult:any;
  /**
 * Reset Click Adv function
 */
  ResetClickAdv(){
this.NormalSearchValue ="";
this.fromDates="";
this.toDates="";
this.fromDate="";
this.toDate="";
this.docTagsNotes = null;
this.docTagsDate = null
this.dateTagsChange = null
this.docTagsValue = null
this.isTypeSelected=null;
this.selectOwner=null;
this.selectedOwnerDisplayName = null;
this.selectedSharedDisplayName = null;
this.selectedOwnerEmailid = null;
this.selectedSharedEmailid = null;
this.IncludetheValue  = '';
this.selectShare=null;
this.validSearchLength = false;
this.validSearchLengthTags = false;
this.AdvSearchValue=null;
this.AdvDocContains = null;
this.DateInput=null;
this.customDropDownn=false;
this.NormalSearchValueArray = [];
this.NormalSearchValueArrayName = [];
this.NormalSearchValueArrayDate=[];
this.NormalSearchValueArrayTagsNotes=[];
this.NormalSearchValueArrayContains=[];
this.NormalSearchValueArrayShared=[];
this.NormalSearchValueArrayOwner=[];
this.NormalSearchValueArrayType=[];
this.NormalSearchValueArrayincludes.join(' ');
this.NormalSearchValueArrayincludes = [];
this.rangeDateTags = '';
this.AdvSearchValueContains = null;
this.rangeDate = '';
this.checked1 = true;
this.checked2 = false
  }
  /**
 * yes from Date variable
 */
  yesfromDate;
  /**
 * today to Date variable
 */
  todaytoDate;
  /**
 * last seven to Date variable
 */
  lastseventoDate;
    /**
 * last seven from Date variable
 */
  lastsevenfromDate;
      /**
 * last thirty to Date variable
 */
  lastthirtytoDate;
    /**
 * last thirty from Date variable
 */
  lastthirtyfromDate;
    /**
 * last ninty to Date variable
 */
  lastnintytoDate;
    /**
 * last ninty from Date variable
 */
  lastnintyfromDate;
  /**
   * selected values should come at top search
   */
  selectedAdvanceSearchValues(){

  }
 /**
 * search Length contains variable
 */
  searchLengthcontains :any;
   /**
 *  valid Search Length Contains variable
 */
  validSearchLengthContains = false;
     /**
 *  sort type val variable
 */
  sortypeval = '';
  pageNumberval ;
  itemperpage ;
  pagingchange = '';
  docTagsDate;
  dateTagsChange;
  TodayTempData;
  nameDataTag:any;
  nameContainsValue:any;
  headerAdvSearch(event? ,sorttype?,oninitpage?){
    this.pagingchange = '';
    this.getSelectedItem();
    this.searchLength=this.AdvSearchValue?.length || 0
    this.searchLengthcontains=this.AdvDocContains?.length || 0
    this.searchLengthTags = this.docTagsNotes?.length || 0

    if (this.searchLength < 3 && this.searchLength != 0 && event !== 'ngOnInit'){
      this.validSearchLength =true;

      return;
    }
    if (this.searchLengthTags < 3 && this.searchLengthTags != 0 && event !== 'ngOnInit'){
      this.validSearchLengthTags =true;

      return;
    }
    if(this.searchLengthcontains < 3 && this.searchLengthcontains != 0){
      this.validSearchLengthContains =true;
      return;
    }
     if(event?.charCode === 13 && this?.NormalSearchValue.trim() ) {
      let resultStr = this.NormalSearchValue
        .trim()
        ?.split(' ')
        .filter(item => item.length > 0)
        .join(' ');


      this.NormalSearchValue = resultStr;
    }
    if((event?.charCode === 13 && this?.NormalSearchValue.trim() && this?.NormalSearchValue !=="") || this.advSearchClickValue || event === "ngOnInit" || event === 'url'  || event === 'pagechange'){
      this.validSearchLength =false;
      this.validSearchLengthTags =false;

      this.fromDates=this.rangeDate?.start;
      this.toDates=this.rangeDate?.end;
      this.advFilterDropdown =false;
      if(sorttype == 'content'){
        this.sortypeval = 'text';
      }
      else{
        this.sortypeval = 'name';
      }
      if(oninitpage == 'searchclickoninit'){
        this.pageNumberval = 0 * 50;
        this.itemperpage = 50;
      }
      else{
        this.pageNumberval = oninitpage.currentpage * oninitpage.pageSize;
        this.itemperpage = oninitpage.pageSize;
        this.pagingchange = 'pagesdata';
      }
      let parserValue
      if(this.parseFlag == 'True'){
        parserValue = 1;
      }
      else{
        parserValue = 0;
      }
      if(!this.docTagsNotes){
        if(this.docTagsValue){
          let fromDate12 = moment(this.docTagsValue).format('YYYY-MM-DD');
          let mergeDate23 = fromDate12
          if(this.NormalSearchValueArrayTagsNotes.length > 0){
            let tempTags = this.NormalSearchValueArrayTagsNotes[0]?.split(':');
            let fromDate123 = tempTags[1];
            if(fromDate123 !== fromDate12){
              let mergeDate231 = fromDate123
              this.docTagsDate = mergeDate231
            }
            else{
              this.docTagsDate = mergeDate23;
            }

          }

          this.dateTagsChange = this.docTagsValue
        }
        else{
          if(this.NormalSearchValueArrayTagsNotes.length > 0){
            let tempTags = this.NormalSearchValueArrayTagsNotes[0]?.split(':');
            let fromDate12 = tempTags[1];
            let mergeDate23 = fromDate12
            this.docTagsDate = mergeDate23
          }
          else{
            this.docTagsDate = '';
          }
        }
      }
      let onlyTag;
      let onlyNotes
      if(this.docTagsNotes){
        onlyTag = this.docTagsNotes;
        onlyNotes = this.docTagsNotes
      }
      else{
        onlyTag = '';
        onlyNotes = ''
      }
      if(this.isTypeSelected == 'Any'){
        this.isTypeSelected = '';
      }  if(this.selectedOwnerEmailid == "Anyone"){
        this.selectedOwnerEmailid = '';
      } if(this.selectedSharedEmailid == "Anyone"){
        this.selectedSharedEmailid = '';
      }

      if(this.NormalSearchValueArrayDate.length>0){
        if(!this.fromDate && !this.docTagsValueStart){
          let tempDate = this.NormalSearchValueArrayDate[0]?.split(':')

          let SearchDate = tempDate[1].search(',');
          if(SearchDate !== -1){
            let splitSearchFromdate = tempDate[1]?.split(',');
            let fromDate1 = moment(splitSearchFromdate[0]).format('YYYY-MM-DD');
            let mergeDate = fromDate1 + 'T00:51:39.498'
            this.fromDate = mergeDate
            let toDate1 = moment(splitSearchFromdate[1]).format('YYYY-MM-DD');
            let mergeDate2 = toDate1 + 'T00:51:39.498'
            this.toDate = mergeDate2

            this.customDropDownn = false;
          }
          else{
            let DateValue = tempDate[1];
            this.customDropDownn = false;
            let fromDate1 = moment(DateValue).format('YYYY-MM-DD');
            let mergeDate = fromDate1 + 'T00:51:39.498'

            this.fromDate = mergeDate
            let toDate1 = moment(DateValue).format('YYYY-MM-DD');
            let mergeDate2 = toDate1 + 'T00:51:39.498'

            this.toDate = mergeDate2

          }
        }
        else{
          if(this.fromDate && !this.docTagsValueStart){
            this.customDropDownn = false;
            let tempDate = this.NormalSearchValueArrayDate[0]?.split(':')

            let SearchDate = tempDate[1].search(',');
            if(SearchDate !== -1){
              let splitSearchFromdate = tempDate[1]?.split(',');

              let fromDate1 = moment(splitSearchFromdate[0]).format('YYYY-MM-DD');
              let mergeDate = fromDate1 + 'T00:51:39.498'

              this.fromDate = mergeDate
              let toDate1 = moment(splitSearchFromdate[1]).format('YYYY-MM-DD');
              let mergeDate2 = toDate1 + 'T00:51:39.498'

              this.toDate = mergeDate2

              this.customDropDownn = false;
            }
            else{
              let DateValue = tempDate[1];
              this.customDropDownn = false;
              let fromDate1 = moment(DateValue).format('YYYY-MM-DD');
              let mergeDate = fromDate1 + 'T00:51:39.498'

              this.fromDate = mergeDate
              let toDate1 = moment(DateValue).format('YYYY-MM-DD');
              let mergeDate2 = toDate1 + 'T00:51:39.498'

              this.toDate = mergeDate2

            }
          }
          else{
            this.customDropDownn = false;
            let tempDate = this.NormalSearchValueArrayDate[0]?.split(':')

            let SearchDate = tempDate[1].search(',');
            if(SearchDate !== -1){
              let splitSearchFromdate = tempDate[1]?.split(',');

              let fromDate1 = moment(splitSearchFromdate[0]).format('YYYY-MM-DD');
              let mergeDate = fromDate1 + 'T00:51:39.498'

              this.fromDate = mergeDate
              let toDate1 = moment(splitSearchFromdate[1]).format('YYYY-MM-DD');
              let mergeDate2 = toDate1 + 'T00:51:39.498'

              this.toDate = mergeDate2

              this.customDropDownn = false;
            }
            else{
              let DateValue = tempDate[1];
              this.customDropDownn = false;
              let fromDate1 = moment(DateValue).format('YYYY-MM-DD');
              let mergeDate = fromDate1 + 'T00:51:39.498'

              this.fromDate = mergeDate
              let toDate1 = moment(DateValue).format('YYYY-MM-DD');
              let mergeDate2 = toDate1 + 'T00:51:39.498'

              this.toDate = mergeDate2

            }
          }
        }
      }





      if(!this.IncludetheValue){
        this.IncludetheValue = ''
      }
      let DuplicatforFlagPostData = {
        "WSOID": this.workSpaceId,
        "format": this.isTypeSelected || '',
        "docownername": this.selectedOwnerEmailid || '',
        "docrename":this.AdvSearchValue || '',
        "FromDate": this.customDropDownn ? this.docTagsValueStart || '' :  this.fromDate || '',
        "ToDate": this.customDropDownn ? this.docTagsValueEnd || '' :this.toDate || '',
        "EXTRACTED_TEXT": this.AdvDocContains || '',
        "searchingstring": this.IncludetheValue?.includes('tags:') || this.IncludetheValue?.includes('type:') || this.IncludetheValue?.includes('shared:') || this.IncludetheValue?.includes('owner:') ? '' : this.IncludetheValue,
        "Tags": onlyTag || '' || this.docTagsDate,
        "Notes": onlyNotes || '',
        "sharedBy": this.selectedSharedEmailid || '',
        "groupid": "",
        "isAllUserShare": "",
        "LoginUserEmailID":this.createdBy,
        "Flag": '',
        "strBoostField":this.sortypeval,
        "CountPerPage" : JSON.parse(this.itemperpage),
        "PageNumber" : this.pageNumberval,
        "IsParserEnable" : this.deepSearch? 1:0,

      }

      let searchChangeValue = '';

      if(this.IncludetheValue != undefined){
        if(this.IncludetheValue.length >0){
          let str = this.IncludetheValue
          if (!str.trim().length) {

         }
         else{
          this.searchType = 'Normal'
         }
        }
      }


      if(DuplicatforFlagPostData.EXTRACTED_TEXT == '' && DuplicatforFlagPostData.FromDate == '' && DuplicatforFlagPostData.Notes == '' &&
      DuplicatforFlagPostData.Tags == ''  && DuplicatforFlagPostData.ToDate == ''  && DuplicatforFlagPostData.docownername == ''
       && DuplicatforFlagPostData.docrename == '' && DuplicatforFlagPostData.format == '' && DuplicatforFlagPostData.groupid == ''
       && DuplicatforFlagPostData.isAllUserShare == '' && DuplicatforFlagPostData.searchingstring == '' && DuplicatforFlagPostData.sharedBy == ''){
        this.searchType = 'Single';

        searchChangeValue = this.searchType;
        localStorage.setItem('SearchFlag',searchChangeValue);
      }
      else if((DuplicatforFlagPostData.EXTRACTED_TEXT !== '' || DuplicatforFlagPostData.FromDate !== '' || DuplicatforFlagPostData.Notes !== '' ||
      DuplicatforFlagPostData.Tags !== ''  || DuplicatforFlagPostData.ToDate !== ''  || DuplicatforFlagPostData.docownername !== ''
       || DuplicatforFlagPostData.docrename !== '' || DuplicatforFlagPostData.format !== '' || DuplicatforFlagPostData.groupid !== ''
       || DuplicatforFlagPostData.isAllUserShare !== ''  || DuplicatforFlagPostData.sharedBy !== '') && (DuplicatforFlagPostData.searchingstring !== '')){
        this.searchType = 'Advance';

        searchChangeValue = this.searchType;
        localStorage.setItem('SearchFlag',searchChangeValue);
      }
      else{
        if(this.searchTypeModify == 'change'){
          if(event == 'url'){
            searchChangeValue =  localStorage.getItem('SearchFlag')
            localStorage.setItem('SearchFlag',searchChangeValue);
          }
          else{
             if((DuplicatforFlagPostData.EXTRACTED_TEXT !== '' || DuplicatforFlagPostData.FromDate !== '' || DuplicatforFlagPostData.Notes !== '' ||
            DuplicatforFlagPostData.Tags !== ''  || DuplicatforFlagPostData.ToDate !== ''  || DuplicatforFlagPostData.docownername !== ''
             || DuplicatforFlagPostData.docrename !== '' || DuplicatforFlagPostData.format !== '' || DuplicatforFlagPostData.groupid !== ''
             || DuplicatforFlagPostData.isAllUserShare !== ''  || DuplicatforFlagPostData.sharedBy !== '') && (DuplicatforFlagPostData.searchingstring !== '')){
              this.searchType = 'Advance';
              searchChangeValue = this.searchType;
              localStorage.setItem('SearchFlag',searchChangeValue);
             }
             else if((DuplicatforFlagPostData.EXTRACTED_TEXT == '' || DuplicatforFlagPostData.FromDate == '' || DuplicatforFlagPostData.Notes == '' ||
             DuplicatforFlagPostData.Tags == ''  || DuplicatforFlagPostData.ToDate == ''  || DuplicatforFlagPostData.docownername == ''
              || DuplicatforFlagPostData.docrename == '' || DuplicatforFlagPostData.format == '' || DuplicatforFlagPostData.groupid == ''
              || DuplicatforFlagPostData.isAllUserShare == ''  || DuplicatforFlagPostData.sharedBy == '') && (DuplicatforFlagPostData.searchingstring !== '')){
                this.searchType = 'Normal';
                searchChangeValue = this.searchType;
                localStorage.setItem('SearchFlag',searchChangeValue);
             }
             else if((DuplicatforFlagPostData.EXTRACTED_TEXT !== '' || DuplicatforFlagPostData.FromDate !== '' || DuplicatforFlagPostData.Notes !== '' ||
             DuplicatforFlagPostData.Tags !== ''  || DuplicatforFlagPostData.ToDate !== ''  || DuplicatforFlagPostData.docownername !== ''
              || DuplicatforFlagPostData.docrename !== '' || DuplicatforFlagPostData.format !== '' || DuplicatforFlagPostData.groupid !== ''
              || DuplicatforFlagPostData.isAllUserShare !== ''  || DuplicatforFlagPostData.sharedBy !== '') && (DuplicatforFlagPostData.searchingstring == '')){
                this.searchType = 'Single';
                searchChangeValue = this.searchType;
                localStorage.setItem('SearchFlag',searchChangeValue);
             }

          }

        }
        else{
          searchChangeValue =  localStorage.getItem('SearchFlag')
          localStorage.setItem('SearchFlag',searchChangeValue);
        }

      }
      let GetFlagData = localStorage.getItem('SearchFlag')
      localStorage.setItem('FlagSearch',GetFlagData);
  if(!this.IncludetheValue){
    this.IncludetheValue = ''
  }
    let postData = {
      "WSOID": this.workSpaceId,
      "format": this.isTypeSelected || '',
      "docownername": this.selectedOwnerEmailid || '',
      "docrename":this.AdvSearchValue || '',
      "FromDate": this.customDropDownn ? this.docTagsValueStart || '' :  this.fromDate || '',
      "ToDate": this.customDropDownn ? this.docTagsValueEnd || '' :this.toDate || '',
      "EXTRACTED_TEXT": this.AdvDocContains || '',
      "searchingstring": this.IncludetheValue?.includes('tags:') || this.IncludetheValue?.includes('type:') || this.IncludetheValue?.includes('shared:') || this.IncludetheValue?.includes('owner:') ? '' : this.IncludetheValue,
      "Tags": onlyTag || '' || this.docTagsDate,
      "Notes": onlyNotes || '',
      "sharedBy": this.selectedSharedEmailid || '',
      "groupid": "",
      "isAllUserShare": "",
      "LoginUserEmailID":this.createdBy,
      "Flag": localStorage.getItem('SearchFlag'),
      "strBoostField":this.sortypeval,
      "CountPerPage" : JSON.parse(this.itemperpage),
      "PageNumber" : this.pageNumberval,
      "IsParserEnable" : this.deepSearch? 1:0,

    }
    this.sharedservice.searchPaginationChanged.subscribe((response: any) => {
      if(response === true){
        setTimeout(() => {
          this.pageNumberval = 0;
          localStorage.setItem('PageNumberSearch',this.pageNumberval);
        }, 500);
      }
    });
    localStorage.setItem('formatTypeSearch',postData.format);
    localStorage.setItem('documentOwnerSearch',postData.docownername);
    localStorage.setItem('docrenameSearch',postData.docrename);
    localStorage.setItem('FromDateSearch',postData.FromDate);
    localStorage.setItem('ToDateSearch',postData.ToDate);
    localStorage.setItem('EXTRACTED_TEXTSearch',postData.EXTRACTED_TEXT);
    localStorage.setItem('searchingstringSearch',postData.searchingstring);
    localStorage.setItem('TagsSearch',onlyTag);
    localStorage.setItem('TagsSearchDate',this.docTagsDate);
    localStorage.setItem('NotesSearch',postData.Notes);
    localStorage.setItem('sharedBySearch',postData.sharedBy);
    localStorage.setItem('strBoostFieldSearch',postData.strBoostField);
    localStorage.setItem('CountPerPageSearch',postData.CountPerPage);
    localStorage.setItem('PageNumberSearch',postData.PageNumber);
    this.searchresult = [];
    if(this.checkIfSearchChange() && event != 'ngOnInit'){
      return
    }
    this.TotalCount = '0'
    this.sharedservice.forSearchTableSpinner.next();
    setTimeout(() => {
      postData.PageNumber = this.pageNumberval;
      this.searchService.SearchNormalFilter(postData).subscribe((response: any) => {
        if (response.isSuccess) {


          if(localStorage.getItem('SearchFlag') == 'Normal'){
             this.normalSearchData(postData)
          }
          else if(localStorage.getItem('SearchFlag') == 'Advance'){
            this.advanceSearchData(postData)
          }
          else if(localStorage.getItem('SearchFlag') == 'Single'){
            if(this.AdvDocContains != undefined){
              if(this.AdvDocContains){
                this.withContains(postData);
              }
              else{
                this.withoutContains(postData)
              }
            }
            else{
              this.withoutContains(postData)
            }
          }


          this.searchresult = response.data;
            if(oninitpage.currentpage){

              let paging = {
                currentpage : oninitpage.currentpage,
                pagingchange : this.pagingchange
              }
              this.sharedService.searchDataPagingChange.next(paging);
            }
          this.advSearchClickValue = false;

        } else{
          if(localStorage.getItem('SearchFlag') == 'Normal'){
            this.normalSearchData(postData)
         }
         else if(localStorage.getItem('SearchFlag') == 'Advance'){
           this.advanceSearchData(postData)
         }
         else if(localStorage.getItem('SearchFlag') == 'Single'){
           if(this.AdvDocContains != undefined){
             if(this.AdvDocContains){
               this.withContains(postData);
             }
             else{
               this.withoutContains(postData)
             }
           }
           else{
             this.withoutContains(postData)
           }

         }
         this.searchresult = '';
        //  this.sharedservice.searchData.next(this.searchresult);
          if(oninitpage.currentpage){

            let paging = {
              currentpage : oninitpage.currentpage,
              pagingchange : this.pagingchange
            }
            this.sharedService.searchDataPagingChange.next(paging);
          }
          // this.sharedservice.forSearchTableSpinnerstop.next(this.searchresult);
        }
        if(this.NormalSearchValue === ''){
          this.ResetClickAdv();
          this.router.navigateByUrl('/pages/search?q=');
          this.sharedservice.searchData.next(this.searchresult);
        }
        if (event !== 'url') {
          let url;
          let tagvalue = null;
          if(this.docTagsNotes){
            tagvalue = this.docTagsNotes;

          }
          else{
            if(!this.docTagsNotes){
              if(this.docTagsValue){
                this.docTagsDate = this.docTagsValue;
                let tempVal = moment(this.docTagsDate).format('YYYY-MM-DD');
                tagvalue = tempVal
              }

            }
          }

          let FromDatetarget = null;
          if(!this.customDropDownn){
            if(this.fromDate){

              FromDatetarget = moment(this.fromDate).format('YYYY-MM-DD');
            }
            else{
              FromDatetarget = null
            }
          }
          else{
            FromDatetarget = moment(this.docTagsValueStart).format('YYYY-MM-DD');
          }

          let ToDatetarget = '';
          if(!this.customDropDownn){
            if(this.toDate){
              this.TodayTempData = sessionStorage.getItem('searchdate');
              if((this.TodayTempData !== 'Yesterday' ) && (this.TodayTempData !== 'Today' )){
                ToDatetarget = moment(this.toDate).format('YYYY-MM-DD');
              }
              else{
                ToDatetarget = '';
              }

            }
            else{
              ToDatetarget = ''
            }

          }
          else{
            this.TodayTempData = sessionStorage.getItem('searchdate');
            if((this.TodayTempData !== 'Yesterday' ) || (this.TodayTempData !== 'Today' )){
              ToDatetarget = moment(this.docTagsValueEnd).format('YYYY-MM-DD');
              if(ToDatetarget == 'Invalid date'){
                ToDatetarget = '';
              }
            }
            else{
              ToDatetarget = '';
            }
          }
          let docOwner
          if(this.selectedOwnerEmailid){
            docOwner = this.selectedOwnerEmailid + '';
          }
          if ((this.docTagsValueStart !== undefined && this.docTagsValueStart !== null && this.docTagsValueStart !== '' && this.docTagsValueEnd !== undefined && this.docTagsValueEnd !== null && this.docTagsValueEnd !== '') || (this.fromDate !== undefined && this.fromDate !== '' && this.fromDate !== null)) {
            let dateURL  = '';
            if(FromDatetarget && ToDatetarget){
              dateURL = FromDatetarget +  ',' + ToDatetarget;
            }
            else{
              dateURL = FromDatetarget
            }
            this.nameDataTag = null;
            if(this.AdvSearchValue){

              if(this.AdvSearchValue == this.nameSplit){
                this.nameDataTag = this.nameSplit;
              }
              else if(JSON.stringify(this.AdvSearchValue) == this.nameSplit){
                this.nameDataTag = this.nameSplit;
              }
              else{
                this.nameDataTag = JSON.stringify(this.AdvSearchValue);
              }

            }
            else{
              this.nameDataTag = null;
            }

            this.nameContainsValue = null;
            if(this.AdvDocContains){

              if(this.AdvDocContains == this.nameSplitContain){
                this.nameContainsValue = this.nameSplitContain;
              }
              else if(JSON.stringify(this.AdvDocContains) == this.nameSplitContain){
                this.nameContainsValue = this.nameSplitContain;
              }
              else{
                this.nameContainsValue = JSON.stringify(this.AdvDocContains);
              }

            }
            else{
              this.nameContainsValue = null;
            }

            url = this.router.serializeUrl(
              this.router.createUrlTree([''], {
                queryParams: {
                  name: this.nameDataTag, tags: tagvalue,
                  date: dateURL,
                  owner: docOwner, shared: this.selectedSharedEmailid, type: this.isTypeSelected, contains: this.nameContainsValue, '' : this.IncludetheValue
                }
              })
            );
          } else {

            this.nameDataTag = null;

            if(this.AdvSearchValue){

              if(this.AdvSearchValue == this.nameSplit){
                this.nameDataTag = this.nameSplit;
              }
              else if(JSON.stringify(this.AdvSearchValue) == this.nameSplit){
                this.nameDataTag = this.nameSplit;
              }
              else{
                this.nameDataTag = JSON.stringify(this.AdvSearchValue);
              }

            }
            else{
              this.nameDataTag = null;
            }

            this.nameContainsValue = null;
            if(this.AdvDocContains){

              if(this.AdvDocContains == this.nameSplitContain){
                this.nameContainsValue = this.nameSplitContain;
              }
              else if(JSON.stringify(this.AdvDocContains) == this.nameSplitContain){
                this.nameContainsValue = this.nameSplitContain;
              }
              else{
                this.nameContainsValue = JSON.stringify(this.AdvDocContains);
              }

            }
            else{
              this.nameContainsValue = null;
            }

            this.nameTagValue = null;
            if(onlyTag){

              if(onlyTag == this.sharedSplit){
                this.nameTagValue = this.sharedSplit;
              }
              else if(JSON.stringify(onlyTag) == this.sharedSplit){
                this.nameTagValue = this.sharedSplit;
              }
              else{
                this.nameTagValue = JSON.stringify(onlyTag);
              }

            }
            else if(this.docTagsDate){
              this.nameTagValue = tagvalue;
            }
            else{
              this.nameTagValue = null;
            }



            url = this.router.serializeUrl(
              this.router.createUrlTree([''], {
                queryParams: {
                  name:  this.nameDataTag  , tags: this.nameTagValue,
                  owner: docOwner, shared: this.selectedSharedEmailid, type: this.isTypeSelected, contains: this.nameContainsValue,'': this.IncludetheValue
                }
              })
            );
          }
          this.cd.detectChanges();

          let decodeurl = decodeURI(url.substring(2))
          if(decodeurl.length == 1){
            if(decodeurl == '='){
              decodeurl = '';
            }
          }
          else{
                const change= decodeurl ;
                let ary=change?.split("")
                if(ary[ary.length-1]==="="){
                ary[ary.length-1]=""
                }else{
                const lastIn=ary.lastIndexOf("=");
                if(lastIn!==-1){
                  if(ary[lastIn+1]===" "){
                  ary[lastIn]=''
                  }
                }
                }
                decodeurl=ary.join("")

          }
          if(decodeurl){
            let changesData = decodeurl
            let arydata=changesData?.split("");
            if(arydata[0] === '='){
              arydata[0] = ''
            }
            decodeurl=arydata.join("")
          }
          // this.sharedservice.searchData.next(this.searchresult);
          this.router.navigateByUrl('/pages/search?q=' + this.codec.encodeValue(decodeurl));

        }

        this.sharedservice.forSearchTableSpinnerstop.next(this.searchresult);
      });
    }, 500);
  }
  }

  TotalCount = '0';
  withContains(data){

    this.searchService.withContains(data).subscribe((response: any) => {
       if(response.isSuccess){
         this.withContainCount = response.returnMessage;
         if(response.returnMessage){
            // this.TotalCount = JSON.parse(this.TotalCount) + JSON.parse(response.returnMessage); // To solve Double count value in Search table
            this.TotalCount = JSON.parse(response.returnMessage);
            this.sharedservice.totalCountValue.next(this.TotalCount);
            this.isTypeSelected = this.isTypeSelected
            if(this.NormalSearchValue.includes('type:') || this.NormalSearchValue.includes('tags:')){
              this.IncludetheValue = ''
            }
         }
       }
    })
  }

  withoutContains(data){

    this.searchService.withoutContains(data).subscribe((response: any) => {
      if(response.isSuccess){
        this.withoutContainCount = response.returnMessage;
        if(response.returnMessage){
           // this.TotalCount = JSON.parse(this.TotalCount) + JSON.parse(response.returnMessage); // To solve Double count value in Search table
           this.TotalCount = JSON.parse(response.returnMessage);
          if(Number(this.TotalCount) > 150){
            console.log(this.TotalCount);
          }
          this.sharedservice.totalCountValue.next(this.TotalCount);
          this.isTypeSelected =  this.isTypeSelected;
          if(this.NormalSearchValue.includes('type:') || this.NormalSearchValue.includes('tags:')){
            this.IncludetheValue = ''
          }
       }
      }
   })
  }

  normalSearchData(data){

    data.PageNumber = 0;

    this.searchService.normalSearchData(data).subscribe((response: any) => {
      if(response.isSuccess){
        this.normalSearchCount = response.returnMessage;
        if(response.returnMessage){
          // this.TotalCount = JSON.parse(this.TotalCount) + JSON.parse(response.returnMessage); // To solve Double count value in Search table
          this.TotalCount = JSON.parse(response.returnMessage);
          this.sharedservice.totalCountValue.next(this.TotalCount);
          this.isTypeSelected = this.isTypeSelected
          if(this.NormalSearchValue.includes('type:') || this.NormalSearchValue.includes('tags:')){
            this.IncludetheValue = ''
          }
       }
      }
   })
  }

  advanceSearchData(data){

    this.searchService.advanceSearchData(data).subscribe((response: any) => {
      if(response.isSuccess){
        this.advanceSearchCount = response.returnMessage;
        if(response.returnMessage){
           // this.TotalCount = JSON.parse(this.TotalCount) + JSON.parse(response.returnMessage); // To solve Double count value in Search table
           this.TotalCount = JSON.parse(response.returnMessage);
          this.sharedservice.totalCountValue.next(this.TotalCount);
          this.isTypeSelected = this.isTypeSelected
          if(this.NormalSearchValue.includes('type:') || this.NormalSearchValue.includes('tags:')){
            this.IncludetheValue = ''
          }
       }
      }
   })
  }

  /**
 *  checkIfSearchChange function
 */
  checkIfSearchChange(){
    if(this.toDates == 'Invalid Date'){
      if((this?.selectOwner?.toString() === sessionStorage?.getItem('ownByHeader')|| this?.selectOwner === undefined || sessionStorage.getItem('ownByHeader')=== 'Anyone' ) &&
      (this?.selectShare?.toString() === sessionStorage?.getItem('sharedByHeader')|| this?.selectShare === undefined || sessionStorage.getItem('sharedByHeader')=== 'Anyone' ) &&
      (((new DatePipe('en-US').transform(this?.toDate, 'dd-MMM-yyyy')))  === (sessionStorage.getItem('endDateHeader')|| this?.toDate === undefined))&&
      (((new DatePipe('en-US').transform(this?.fromDates, 'dd-MMM-yyyy'))||  (new DatePipe('en-US').transform(this?.fromDate, 'dd-MMM-yyyy')))===  (sessionStorage.getItem('startDateHeader') || this?.fromDate === undefined))&&
      ((this?.AdvSearchValue || "")===sessionStorage.getItem('dbNameHeader'))&&
      (this?.isTypeSelected === sessionStorage.getItem('doFormatHeader')|| sessionStorage.getItem('doFormatHeader')=== 'Any' )&&
      (this?.NormalSearchValue || "" )=== sessionStorage.getItem('fileNameHeader'))
      {    return true}
    }
    else{
      if((this?.selectOwner?.toString() === sessionStorage?.getItem('ownByHeader')|| this?.selectOwner === undefined || sessionStorage.getItem('ownByHeader')=== 'Anyone' ) &&
    (this?.selectShare?.toString() === sessionStorage?.getItem('sharedByHeader')|| this?.selectShare === undefined || sessionStorage.getItem('sharedByHeader')=== 'Anyone' ) &&
    (((new DatePipe('en-US').transform(this?.toDates, 'dd-MMM-yyyy')) || (new DatePipe('en-US').transform(this?.toDate, 'dd-MMM-yyyy')))  === (sessionStorage.getItem('endDateHeader')|| this?.toDate === undefined))&&
    (((new DatePipe('en-US').transform(this?.fromDates, 'dd-MMM-yyyy'))||  (new DatePipe('en-US').transform(this?.fromDate, 'dd-MMM-yyyy')))===  (sessionStorage.getItem('startDateHeader') || this?.fromDate === undefined))&&
    ((this?.AdvSearchValue || "")===sessionStorage.getItem('dbNameHeader'))&&
    (this?.isTypeSelected === sessionStorage.getItem('doFormatHeader')|| sessionStorage.getItem('doFormatHeader')=== 'Any' )&&
    (this?.NormalSearchValue || "" )=== sessionStorage.getItem('fileNameHeader'))
    {    return true}
    }

  }
    /**
 *  subcript msg var
 */
  subcriptmsg;
    /**
 *  AdvSearchClick function
 */
  AdvSearchClick(){
    this.sharedService.searchloader.next(true)
    this.advSearchClickValue = true;

  }
  /**
   *
   * variable for outside
   */
   oncardclick4='';
   notificstionItemId='';
  /**
 *  notify out side var
 */
  notifyoutside = '';
   /**
 *  opennotification function
 */
    opennotification(){
      this.isMenu=false;
      this.notificstionItemId=document.getElementById('notificationid').id
      this.showProfile = false;
          localStorage.removeItem('newnotify');
      this.newnotifybtn = true;
      this.showSpinnernotification = true;
      this.notifyoutside = 'clickonnotification';
      this.advFilterDropdown=false;
    if(this.hidenotify){
      if (localStorage.getItem('deleteddbnid')){
        this.undobtnshow = localStorage.getItem('deleteddbnid')
      }
      else{
        this.undobtnshow = '';
      }
      this.getallnotification();
    }
    else{
      this.hidenotify = true;
    }
  }
    /**
 *  div notification var
 */
  divnotification = '';
  /**
 *  noteclick function
 */
  noteclick(data){
    this.oncardclick4 = data;


  }

  /**
 *  show Support var
 */
  showSupport : boolean = true;
   /**
 *   Support  help function
 */
  supportHelp(){
    this.showSupport = !this.showSupport;
    this.hidenotify=true;
    this.showProfile=false;
  }

 /**
 *   new notify btn  var
 */
  newnotifybtn = true;
   /**
 *   new notification function
 */
  newnotification(){
    this.newnotifybtn = false;
  }
     /**
 *   budgecount var
 */
  budgecount :any;
    /**
 *   budgecountval var
 */
  budgecountval :any;
   /**
 *   get all budges count function
 */
  getallbudgescount(){

    let notification = {
      wsoid : Number(this.workSpaceId),
      notificationFrom : "",
      notificationTo : this.createdBy,
      databaseName : "",
      createdBy : this.createdBy
    }
    this.budgecount = '';
    this.budgecountval = [];
    this.signUpService.getallnotification(notification).subscribe((response: any) => {

       if(response.isSuccess){

        this.budgecount = '';
        this.budgecountval = [];
          for(let i=0;i<=response.data.length-1;i++){
            if(!(response.data[i].isRead)){
              this.budgecountval.push(response.data[i]);
            }
          }
         if (localStorage.getItem('deleteddbnid')){
           this.undobtnshow = localStorage.getItem('deleteddbnid')
          }
          else{
            this.undobtnshow = '';
          }
          if(this.budgecountval.length){
            if(this.budgecountval.length > 99){
              this.budgecount = "99+"
            }
            else{
              this.budgecount = this.budgecountval.length;
            }

          }
           this.cd.detectChanges();
       }
    })
  }
 /**
 *  getallArray var
 */
  getallArray :any;
   /**
 *  totalnotification var
 */
  totalnotification : any;
   /**
 *  msgnotification var
 */
  msgnotification = '';
   /**
 *  onlyunreadmsg var
 */
  onlyunreadmsg = [];
    /**
 *  getallnotification function
 */
  getallnotification(){
    let notification = {
      wsoid : Number(this.workSpaceId),
      notificationFrom : "",
      notificationTo : this.createdBy,
      databaseName : "",
      createdBy : this.createdBy
    }
    this.getallArray = [];
    this.totalnotification = '0';
   this.showSpinnernotification = true;
    this.signUpService.getallnotification(notification).subscribe((response: any) => {
       if(response.isSuccess){
           for(let i=0;i<=response.data.length-1;i++){
            response.data[i].datetime = new Date(response.data[i].date)
           }
           this.getallArray = response.data.reverse();
           this.msgnotification = '';
           this.newnotifybtn = true;
           this.onlyunreadmsg = [];
           for(let i=0;i<=this.getallArray.length-1;i++){
             if(!this.getallArray[i].isRead){
                this.onlyunreadmsg.push(this.getallArray[i])
             }
           }
           this.totalnotification = this.onlyunreadmsg.length;
           this.getallbudgescount();
           this.addItems("push");
           this.hidenotify = false;

            this.showSpinnernotification = false;

           this.cd.detectChanges();
       }
       else{
         this.getallArray = [];
         this.msgnotification = 'No notifications';
         this.hidenotify = false;

          this.showSpinnernotification = false;

         this.cd.detectChanges();
       }
    })
  }
 /**
 *  readmessage function
 */
  readmessage(data){

    let readmsg =  {
      dbnid : JSON.stringify(data.dbnid),
      wsoid : Number(this.workSpaceId),
      modifiedBy : this.createdBy
    }
  this.signUpService.readmessage(readmsg).subscribe((response: any) => {
      if(response.isSuccess){
         this.getallbudgescount();
         this.getallafterread();
      }
   })
  }
  /**
 *  getallafterread function
 */
  getallafterread(){
    let notification = {
      wsoid : Number(this.workSpaceId),
      notificationFrom : "",
      notificationTo : this.createdBy,
      databaseName : "",
      createdBy : this.createdBy
    }
    this.totalnotification = '0';
    this.signUpService.getallnotification(notification).subscribe((response: any) => {
       if(response.isSuccess){

           for(let i=0;i<=response.data.length-1;i++){
            response.data[i].datetime = new Date(response.data[i].date)
           }
           this.getallArray = response.data.reverse();
          this.addItems("push");
           this.newnotifybtn = true;
           this.onlyunreadmsg = [];
           for(let i=0;i<=this.getallArray.length-1;i++){
            if(!this.getallArray[i].isRead){
               this.onlyunreadmsg.push(this.getallArray[i])
            }
          }
          this.totalnotification = this.onlyunreadmsg.length;

           this.cd.detectChanges();
       }
    })
  }
/**
 *  dbnidread var
 */
  dbnidread = [];
  /**
 *  allreadmessage function
 */
  allreadmessage(){

    this.dbnidread = [];

    for(let i=0;i<=this.getallArray.length-1;i++){
      if(!this.getallArray[i].isRead){
        this.dbnidread.push(this.getallArray[i].dbnid)
      }
    }

 let readmsg =  {
      dbnid : this.dbnidread.join(),
      wsoid : Number(this.workSpaceId),
      modifiedBy : this.createdBy
    }

    this.signUpService.readmessage(readmsg).subscribe((response: any) => {
      if(response.isSuccess){
         this.getallbudgescount();
         this.getallnotification();
      }
   })
  }
/**
 *  select all function
 */
  selectall(){

    for(let i=0;i<=this.getallArray.length-1;i++){
      let selectbodydata = i + 'selectbody';
      document.getElementById(`${selectbodydata}`).style.backgroundColor = "#D5E5F0";
    }
  }
/**
 * undobtnshow var
 */
  undobtnshow :any;
/**
 * myTimeout var
 */
  myTimeout = 0;
  /**
 * onebyonedelete function
 */
  onebyonedelete(data){

    let onedelete = {
      dbnid : JSON.stringify(data.dbnid),
      wsoid : Number(this.workSpaceId),
      modifiedBy : this.createdBy,
      flag : "delete"
    }
    localStorage.setItem('deleteddbnid', JSON.stringify(data.dbnid))
    this.checkundobtn = true;

    this.signUpService.onebyonedelete(onedelete).subscribe((response: any) => {
       if(response.isSuccess){
         this.undobtnshow = localStorage.getItem('deleteddbnid')
        this.getallbudgescount();
        this.getallnotification();

        clearTimeout(this.myTimeout);
        const id =  setTimeout(() => {
          this.checkundobtn = false;
          this.cd.detectChanges()
        }, 15000);
        this.myTimeout = Number(id);
        this.cd.detectChanges()

       }
    })
  }
  /**
 * dbniddelete var
 */
  dbniddelete = [];
   /**
 * checkundobtn var
 */

  checkundobtn = false;
  undoPressed = false;
     /**
 * deleteallnotification function
 */
  deleteallnotification(){

    this.dbniddelete = [];

    for(let i=0;i<=this.getallArray.length-1;i++){
        this.dbniddelete.push(this.getallArray[i].dbnid)
    }

    let onedelete = {
      dbnid : this.dbniddelete.join(),
      wsoid : Number(this.workSpaceId),
      modifiedBy : this.createdBy,
      flag : "delete"
    }
    localStorage.setItem('deleteddbnid', this.dbniddelete.join())
    this.checkundobtn = true;
    this.signUpService.onebyonedelete(onedelete).subscribe((response: any) => {
       if(response.isSuccess){
         this.undobtnshow = localStorage.getItem('deleteddbnid')
        this.getallbudgescount();
        this.getallnotification();
          setTimeout(() => {
            this.checkundobtn = false;
            this.cd.detectChanges();
          }, 15000);


       }
    })
  }
    /**
 * undodata function
 */
  undodata(){

    let onedelete = {
      dbnid : this.undobtnshow,
      wsoid : Number(this.workSpaceId),
      modifiedBy : this.createdBy,
      flag : "undo"
    }
    this.signUpService.onebyonedelete(onedelete).subscribe((response: any) => {
       if(response.isSuccess){
        this.undoPressed = true;
        this.checkundobtn = false;
        localStorage.removeItem('deleteddbnid');
        this.getallbudgescount();
        this.getallnotification();
       }
    })
  }
   /**
 * emptyNormalSearch function
 */
  emptyNormalSearch(){
    this.NormalSearchValue="";
    this.NormalSearchValueArray = [];
    this.NormalSearchValueArrayName = [];
    this.NormalSearchValueArrayDate = [];
    this.NormalSearchValueArrayTagsNotes = [];
    this.NormalSearchValueArrayContains = [];
    this.NormalSearchValueArrayShared = [];
    this.NormalSearchValueArrayOwner = [];
    this.NormalSearchValueArrayType = [];
    this.NormalSearchValueArrayincludes=[];
    this.cd.detectChanges();
  }
     /**
 * navigateHome function
 */
  navigateHome() {
     this.router.navigate(["/pages/dashboard"]);
      this.sharedService.dashboardReload.next();
      this.NormalSearchValue=''
  }
  /**
 * showProfile var
 */
  public showProfile:boolean = false;
    /**
 * buttonName var
 */
  public buttonName:any = 'Show';
 /**
 * showoutprofile var
 */
  showoutprofile = '';
  profiletogglefield='';
   /**
 * toggle function
 */
  toggle() {
    this.showProfile = !this.showProfile;
    this.hidenotify = true;
    this.isMenu=false;
    this.showoutprofile = 'showoutdataprofile';
    this.profiletogglefield =  document.getElementById('toggleid').id
  }


  /**
 * profileclick var
 */
  profileclick = '';
   /**
 * profilediv function
 */
  // profilediv(data){
  //   this.profileclick1 = data;
  // }

   /**
 * UserSetingModal component
 */
@ViewChild('UserSetingModal') UserSetingModal!:TemplateRef<any>
/**
 * userSettings function
 */
  userSettings() {


    this.GetTimeZone(this.UserSetingModal);
    this.userSettingsRef = this.dialogService.open(this.UserSetingModal,{ closeOnBackdropClick: false, closeOnEsc:false });
    this.ediimageUrl = localStorage.getItem('ediimageUrl') ? localStorage.getItem('ediimageUrl') : '../../../assets/images/Default_Profile_Photo.svg';
    this.getUserImage();

  }
  /**
 * closedialog function
 */
  closedialog(ref){
    ref.close();
    this.userSettingsRef.close();
  }
  /**
   *  EditProfileForm form
   */
  EditProfileForm = new FormGroup({
    zoneTime: new FormControl('')

  });

  /**
   *  display Name Guidline var
   */
displayNameGuidline: any;
 /**
   *  whatIDo var
   */
whatIDo:any;
 /**
   *  whereIam var
   */
whereIam:any
/**
   *  editproEmail var
   */
editproEmail:any
/**
   *  userRole var
   */
userRole:any;
/**
   *  userGroup var
   */
userGroup:any;
/**
   *  groupnames var
   */
groupnames: any;
/**
   *  full Name Pattern var
   */
 fullNamePattern = "^[a-zA-Z0-9. \s-]*$";
/**
   *  number Pattern var
   */
 numberPattern = "^[0-9 ()+-].*$";
 /**
   *  input Pattern var
   */
  inputPattern = "^[()a-zA-Z0-9 .,&]*$";
   /**
   * display Name GuidlineStatic var
   */
  displayNameGuidlineStatic:any;
   /**
   * WorkSpaceName Placeholder var
   */
  WorkSpaceNamePlaceholder:any

stopDot($event){
  if($event.keyCode == 46){
    $event.preventDefault();
  }
}
@ViewChild('fullName') fullName: ElementRef;
@ViewChild('disName') disName: ElementRef;
@ViewChild('val', { static: true }) divHello: ElementRef<HTMLElement>;

 /**
   * GetProfileDetails func
   */
  GetProfileDetails(dialog) {
  var st = window.location.toString();
  var arr = st?.split("/");
this.showSpinner= false;
  let postData: any
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "Wslink": arr[2],
    "EmailId": this.createdBy
  }

this.datanew.getUserProfileDetailsService(postData).subscribe((response:any)=>{
 if(response.isSuccess){
  localStorage.setItem('User_time_zone',response.data[0].timeZone);
  this.timeZoneChanged();
  this.allSearchApi();

this.displayNameGuidline=response?.data[0]?.displayNameGuidline;
  this.suggestedGuideline = response?.data[0]?.suggestedGuideline;

  localStorage.setItem('WsdisplayName', response?.data[0]?.displayName)

this.WorkSpaceNamePlaceholder=response?.data[0]?.wsName;
   if(response?.data[0]?.profileImagePath=="" || response?.data[0]?.profileImagePath==null || response?.data[0]?.profileImagePath == undefined ){
     this.profileImagePath = response.data[0].profileImagePath;

    this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
    this.loginProfilImage = this.ediimageUrl;
    console.log("episode1", this.loginProfilImage);
     localStorage.setItem('ediimageUrl', this.ediimageUrl)
    this.cd.detectChanges();
   }
   else{
    // this.ediimageUrl = environment.baseUrl + "/" + response.data[0].profileImagePath;
     this.profileImagePath = response.data[0].profileImagePath;

     console.log("profileimage", this.profileImagePath);
     this.getUserImage();
     //this.loginProfilImage = this.ediimageUrl;
     //localStorage.setItem('ediimageUrl', this.ediimageUrl)
     this.cd.detectChanges();

   }
   this.userProfileForm.patchValue({
    fullName:response.data[0].fullName,
    displayName:response.data[0].displayName,
    whatIDo:response.data[0].whatIdo,
    whereIAm:response.data[0].whereIam,
    timeZone:response.data[0].timeZone,
    contactNumber:response.data[0].contactNo,

   })



  this.displayNameGuidlineStatic=response.data[0].displayNameGuidline
   this.Full_name=response.data[0].fullName;
   this.display_name=response.data[0].displayName;
   this.whatIDo=response.data[0].whatIdo;
   this.whereIam=response.data[0].whereIam;
   this.editproEmail=response.data[0].emailID;
   this.userRole=response.data[0].roleName;
   this.timeZone = response.data[0].timeZone;
   localStorage.setItem('longTimeZone', response.data[0].timeZone);
   this.contactNo = response.data[0].contactNo;
   this.userRole=response.data[0].roleName;
   this.userRole=response.data[0].roleName;
   this.userGroup=response.data.map(obj => obj.groupName);
   if(this.userGroup.length > 0){
    this.groupnames = this.userGroup.join(',  ');
   } else{
    this.groupnames = response.data[0].groupName;
   }
   localStorage.setItem('WsdisplayName', response.data[0].displayName)
   this.sharedService.wsDisplayName.next(response.data[0].displayName);
  }
   if(dialog){
    this.dialogService.open(dialog,{ closeOnBackdropClick: false , closeOnEsc: false });

 }
})
}


getUserImage() {
  if (this.profileImagePath != null || this.profileImagePath != undefined) {
    let postData: any
    postData = {
      "wsoid": Number(this.workSpaceId),
      "fileName": this.profileImagePath,
      "folderName": "profileimage"
    }
    this.datanew.pdfViewerFileConversion(postData).subscribe((response: any) => {
   
      if (response.body instanceof Blob && response.body.size > 0) {
        const blob = new Blob([response.body], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        this.ediimageUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.loginProfilImage = this.ediimageUrl;
        console.log("episode2", this.loginProfilImage);
        this.cd.detectChanges();
        this.imageUrlExists = false;
      }
      else {
      
        this.ediimageUrl = "../../../assets/images/Default_Profile_Photo.svg";
        this.loginProfilImage = this.ediimageUrl;
        console.log("episode3", this.loginProfilImage);
        this.imageUrlExists = true;
        this.cd.detectChanges();
        localStorage.setItem('ediimageUrl', this.ediimageUrl)
      }
    })
  } else {
    this.ediimageUrl = "../../../assets/images/Default_Profile_Photo.svg";
    this.loginProfilImage = this.ediimageUrl;
    console.log("episode8", this.loginProfilImage);
    this.imageUrlExists = true;
    this.cd.detectChanges();
    localStorage.setItem('ediimageUrl', this.ediimageUrl)
  }

}

workspaceStatus() {
  var st = window.location.toString();
  var arr = st?.split("/");
  let postData: any
  postData = {
      "Wslink": arr[2],
      "emailId": localStorage.getItem('localloginId'),
  }
  this.signInService.wsStatus(postData).subscribe((response: any) => {
    if (response) {
      this.workspaceImg = response.data[0].iconPath;
      this.wsIconBlobGenerator();
    }
  });
}

wsIconBlobGenerator() {
  console.log("this.worksapce", this.workspaceImg);
  if (!this.workspaceImg.includes('https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D')) {
    let postDataForIcon = {
      "wsoid": Number(this.workSpaceId),
      "fileName": Number(this.workSpaceId) + '.png',
      "folderName": "profileimage"
    }
    this.signInService.updateWSIcon(postDataForIcon).subscribe((response: any) => {
  
  
      if (response.body instanceof Blob && response.body.size > 0) {
        const blob = new Blob([response.body], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        this.iconPath = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.imageUrl = this.iconPath
       
      } else {
    
        this.iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
        this.imageUrl = this.iconPath
      }
      this.cd.detectChanges();
  
      localStorage.setItem('iconPath', this.iconPath)
    })
  } else {
    this.iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
    this.imageUrl = this.iconPath;
    this.cd.detectChanges();
  
      localStorage.setItem('iconPath', this.iconPath)
  }
 
}
 /**
   * selectTime var
   */
    selectTime: any;
   /**
   * zoneTime var
   */
  zoneTime: any;
   /**
   * timeZoneID var
   */
  timeZoneID: any;
   /**
   * GetTimeZone function
   */
  GetTimeZone(dialog) {

    this.data.getTimezone({}).subscribe((response: any) => {
      if (response.isSuccess == true) {

        this.zoneTime = response.data;
        for (let user of this.zoneTime) {
          user.timeZone;
          user['custom'] = user.timeZone;
        }
        this.GetProfileDetails(dialog);

      }
    }
    )}
 /**
   * filter function
   */
  public filter(value: any): string[] {
    if(value){
      let filterValue = '';
      if(typeof value == 'object'){
        filterValue = value.timeZone.toLowerCase();
      }
      else{
        filterValue = value.toLowerCase();
      }
      return this.allTimeZone.filter(optionValue => {
        if(optionValue && optionValue.timeZone)
         return optionValue.timeZone.toLowerCase().includes(filterValue)
      });
    }

  }

   /**
   * remove Special Characters
   */
   removeSpecialCharacters(event) {
    var regex = new RegExp("^[a-zA-Z0-9. \s-]*$");
    var regPattern = /[\u0000-\u007A]+/g;
    var inputElement = event.target;
    if (event.key === ' ' && inputElement.value.trim() === '') {
      event.preventDefault();
      return false;
  }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key) || !regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
  }


  inviteTour(){
    window.productFruits?.api?.checklists.listen('dismissed', (checklistId) => {
      this.checkListCompleted = false
      localStorage.setItem('checklist','done')
    });
    let data = window.productFruits.api.tours.getTours()
    this.show_getstarted = !this.show_getstarted

    window.productFruits?.api?.checklists.injectToElement(98, this.divHello.nativeElement);
    window.productFruits?.api?.checklists.listen('item-launched', (id, internalId) => {
      this.show_getstarted = true;

      id = 4716
      if (internalId == 'invite_user')
        window.productFruits?.api?.tours.tryStartTour(id) ;
      else if (internalId == 'upload')
        window.productFruits?.api?.tours.tryStartTour(4788);
      else if (internalId == 'ws_management')
        window.productFruits?.api?.tours.tryStartTour(4783);
      // else if (internalId == 'upload')
      //   window.productFruits.api.tours.tryStartTour(2315);
    });
  }
 /**
   * getFilteredOptions function
   */
  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }
   /**
   * onChange ViewChild
   */
  onChange() {
    this.zoneTime = this.filter(this.input.nativeElement.value);
  }
   /**
   * autoInput ViewChild
   */
  @ViewChild('autoInput') input;
   /**
   * allTimeZone var
   */
  allTimeZone  = [];
  /**
   * selectedZone var
   */
  selectedZone:any;
/**
   * disableTimezone var
   */
  disableTimezone = true;

/**
   * openModal function
   */
    openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
      this.dialogRef = this.dialogService.open(dialogModal, {
        closeOnBackdropClick: false,
        closeOnEsc: false
      });

    }
/**
   * userProfileForm var
   */
  userProfileForm = new FormGroup({
    iconImage: new FormControl(),
    fullName: new FormControl('', [Validators.required,Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.fullNamePattern)]),
    displayName: new FormControl('', [Validators.maxLength(100),Validators.minLength(3), Validators.pattern(this.fullNamePattern)]),
    whatIDo: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.inputPattern)]),
    whereIAm: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.inputPattern)]),
    timeZone: new FormControl(),
    contactNumber: new FormControl('', [Validators.maxLength(15), Validators.pattern(this.numberPattern)]),

  });
 /**
   * fileSize var
   */

  fileSize:any
  /**
   * uploadfilesize var
   */
  uploadfilesize:boolean=false;
/**
   * replaceimg fun
   */

  replaceimg(event: any) {
    if (event === undefined) {
      return
    }
    this.removePressed = false;
    if (event.target.files.length > 0) {
      var extension = event.target.files[0].type?.split('/')[1];
      this.imageChangedEvent = event;
      this.fileSize = event.target.files[0].size;
      this.fileSize = Math.round((this.fileSize / 1024));
      if (this.fileSize > 500) {
        this.uploadfilesize = true;
        this.userProfileForm.patchValue({ iconImage: "" });
        return false;
      }
      else if (extension.toLowerCase() != "png" && extension.toLowerCase() != "jpg" && extension.toLowerCase() != "jpeg") {
        this.formateError = true;
      }
      else {
        this.openModal(this.cropperModal, true);
        this.uploadfilesize = false;
        this.formateError = false;

      }
      var src = URL.createObjectURL(event.target.files[0])


    }

  }
   /**
   * closeEditProfile fun
   */
  closeEditProfile(){

    this.uploadfilesize=false;
  }
  clearData(event){
    event.target.value = '';

   }
   /**
   * reset fun
   */
  reset(element){
     this.userProfileForm.patchValue({
      iconImage:""

   })
  }
   /**
   * discardChanges fun
   */
  discardChanges(ref){
    this.dialogRef.close();
    if(!this.userProfileForm.pristine){

      this.userProfileForm.reset();
      var st = window.location.toString();
    var arr = st?.split("/");
  this.showSpinner= false;
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "Wslink": arr[2],
      "EmailId": this.createdBy
    }

  this.datanew.getUserProfileDetailsService(postData).subscribe((response:any)=>{
   if(response.isSuccess){
    localStorage.setItem('User_time_zone',response.data[0].timeZone);

  this.displayNameGuidline=response?.data[0]?.displayNameGuidline;
     this.suggestedGuideline = response?.data[0]?.suggestedGuideline;
    localStorage.setItem('WsdisplayName', response?.data[0]?.displayName)

  this.WorkSpaceNamePlaceholder=response?.data[0]?.wsName;
     if(response?.data[0]?.profileImagePath=="" || response?.data[0]?.profileImagePath==null || response?.data[0]?.profileImagePath == undefined ){
       this.profileImagePath = response.data[0].profileImagePath;

      this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
      this.loginProfilImage = this.ediimageUrl;
      console.log("episode4", this.loginProfilImage);
       localStorage.setItem('ediimageUrl', this.ediimageUrl)
      this.cd.detectChanges();
     }
     else{
   
       this.profileImagePath = response.data[0].profileImagePath;
       this.getUserImage();
 
       this.cd.detectChanges();

     }
     this.userProfileForm.patchValue({
      fullName:response.data[0].fullName,
      displayName:response.data[0].displayName,
      whatIDo:response.data[0].whatIdo,
      whereIAm:response.data[0].whereIam,
      timeZone:response.data[0].timeZone,
      contactNumber:response.data[0].contactNo,

     })
    }
  })
    }
    ref.close();
    this.notification = false;
    this.changePassword = true;
    this.loginProfile = false;
    this.enableSave= false;
  }
   /**
   * resetimg var
   */
  resetimg;
  /**
   * Remove func
   */
  Remove(profileimg:any){
    this.removePressed = true;
    this.uploadfilesize=false;
    this.formateError=false;
    this.enableSave = true;
    // profileimg.src="../../../assets/images/Default_Profile_Photo.svg"
    this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
    this.userProfileForm.markAsDirty();

  }
/**
 *  for the browser functionality start here
 */
  @HostListener('window:beforeunload', ['$event'])
  /**
 * unloadNotification func
 */
  unloadNotification($event: any) {
    if (!this.userProfileForm.pristine) {
      $event.preventDefault();
      return false;
    }
  }
/**
 * selectedItemNgModel
 */

    selectedItemNgModel:any;
     /**
  * isSavedPressedEditor var
  */
    isSavedPressedEditor:boolean=false;
      /**
  * getGuid var
  */
    getGuid;
      /**
  * displayNameUser var
  */
    displayNameUser;

     /**
  * updateUserProfile func
  */
    updateUserProfile(files,ref) {
      localStorage.setItem('User_time_zone',this.userProfileForm.value.timeZone)
      this.getGuid = localStorage.getItem('loginguid')
      var st = window.location.toString();
      var arr = st?.split("/");
      this.showSpinner = true;

      let formData = new FormData();
      if((this.userProfileForm.value.fullName==undefined)||(this.userProfileForm.value.fullName==null)){
        this.userProfileForm.value.fullName = this.Full_name
      }

      if((this.userProfileForm.value.displayName==undefined) || (this.userProfileForm.value.displayName==null)){
        this.userProfileForm.value.displayName = this.display_name
      }

      if((this.userProfileForm.value.selectDisplayName==undefined)|| (this.userProfileForm.value.selectDisplayName==null)){
        this.userProfileForm.value.selectDisplayName = this.display_name
      }

      if((this.userProfileForm.value.whatIDo== undefined) || (this.userProfileForm.value.whatIDo==null)){
        this.userProfileForm.value.whatIDo = ''
      }

      if((this.userProfileForm.value.whereIAm==undefined) || (this.userProfileForm.value.whereIAm==null)){
        this.userProfileForm.value.whereIAm = ''
      }


      if((this.userProfileForm.value.contactNumber==undefined) || (this.userProfileForm.value.contactNumber==null)){
        this.userProfileForm.value.contactNumber=''
      }

        if((this.userProfileForm.value.timeZone==undefined) || (this.userProfileForm.value.timeZone==null)){
          this.userProfileForm.value.timeZone=this.timeZone
        }

        this.displayNameUser = this.userProfileForm.value.displayName
      localStorage.setItem('noteuserfullname', this.userProfileForm.value.fullName)

      formData.append("Wsoid", (this.workSpaceId));
      formData.append("WsLink", arr[2]);
        formData.append("ProfileImagePath", this.userProfileForm.value.iconImage);

      formData.append("FullName", this.userProfileForm.value.fullName.trim());
      formData.append("DisplayName", this.userProfileForm.value.displayName.trim());
      formData.append("WhatIdo", this.userProfileForm.value.whatIDo);
      formData.append("WhereIam", this.userProfileForm.value.whereIAm);
      formData.append("TimeZone", this.userProfileForm.value.timeZone);
      formData.append("ContactNo", this.userProfileForm.value.contactNumber);
      formData.append("ModifiedBy", this.createdBy);
      formData.append("guid", this.getGuid);
      formData.append("fileIcon", this.imageStrem)
      formData.append("reset", !this.removePressed ? '1' : 'Reset');
      let headers = new HttpHeaders({

      });

      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      for (let file of files)
        formData.append(file.name, file);



      const uploadReq = new HttpRequest('POST', environment.baseUrl + `/api/UserSettings/UpdateUserProfileDetails`, formData, {
        reportProgress: true,headers: headers

      });
      this.http.request(uploadReq).subscribe(event => {
        if (event.type === HttpEventType.Response) {

          this.toastrService.primary('Profile updated','',);
            this.showSpinner = false;

        this.userProfileForm.reset();
        this.loginProfilImage=this.ediimageUrl;
        console.log("episode5", this.loginProfilImage);
        this.GetProfileDetails(null);
        this.elasticUpdation();
        this.isSavedPressedEditor=true;
        setTimeout(() => {
          this.isSavedPressedEditor=false;
          // window.location.reload()
        }, 2000);
        }

      });
    }
            /**
  * elasticData var
  */
    elasticData;
            /**
  * elasticUpdation var
  */
    elasticUpdation(){

      let elasticData={
        docOwnerEmailID : this.createdBy,
        name : this.displayNameUser,
        wsoid : this.workSpaceId
      }

      this.datanew.elasticUpdation(elasticData).subscribe((response:any)=>{
        if(response.isSuccess){
          this.elasticData = response.data;
        }
      })
    }

  /**
  * dialogRef var
  */
     dialogRef:any
        /**
  * ViewChild var
  */
    @ViewChild('Password') Password!:TemplateRef<any>
         /**
  * Setting fun
  */
    Setting() {
      this.dialogRef = this.dialogService.open(this.Password, { closeOnBackdropClick: false ,closeOnEsc:false });
      this.ediimageUrl = localStorage.getItem('ediimageUrl')  ? localStorage.getItem('ediimageUrl') : '../../../assets/images/Default_Profile_Photo.svg';
      this.getUserImage();
      this.showProfile = false;
      this.GetProfileDetails(null);
    }
         /**
  * close fun
  */
    close(){
      this.isUnsavedChangePW = false;
      this.dialogRef.close();
    }
     /**
  * isUnsaved fun
  */
  isUnsaved(event){
    if(event === 'stay'){
      this.isUnsavedChangePW = true;
    }
    if (event === 'discard|notify' || event === 'discard|profile') {
      this.isUnsavedChangePW = false;
      event?.split('|')[1] === 'notify' ? this.userNotification('val') : this.userEditProfile('val');
    }
    if (event === 'discard|reset') {
      this.isUnsavedChangePW = false;
    }
  }
  onFocus(){
    this.NormalSearchValueInput  = false;
    this.sharedService.isSearchFocused.next(this.NormalSearchValueInput)
  }
  onBlur(){
    this.NormalSearchValueInput = true;
    this.sharedService.isSearchFocused.next(this.NormalSearchValueInput)
    }
   /**
     * isUnsavedNotify fun
     */
  isUnsavedNotify(event){
    if(event === 'stay'){
      this.isUnsavedChangeNotify = true;
    }
    if (event === 'discard|password' || event === 'discard|profile') {
      this.isUnsavedChangeNotify = false;
      event?.split('|')[1] === 'password' ? this.userchangePassword('val') : this.userEditProfile('val');
    }
  }
 /**
     * userchangePassword fun
     */
    userchangePassword(val?){
      if(!this.userProfileForm.pristine){
        this.openModal(this.unsavedChanges, false);
      }
      if (!this.isUnsavedChangeNotify && this.userProfileForm.pristine){
        this.notification = false;
        this.changePassword = true;
        this.loginProfile = false;
      }
      val === undefined ? this.sharedService.IsSidebarClickedNotify.next('password') : '';
    }
 /**
     * userNotification fun
     */
    userNotification(val?){
      if (!this.isUnsavedChangePW){
        this.changePassword = false;
        this.notification = true;
        this.loginProfile = false;
      }
      val === undefined ? this.sharedService.IsSidebarClicked.next('notify') : '';
    }
    /**
     * userEditProfile
     */
    userEditProfile(val?){
      if (!this.isUnsavedChangePW && !this.isUnsavedChangeNotify){
        this.notification = false;
        this.showSpinner = false;
        this.changePassword = false;
        this.loginProfile = true;
        this.ediimageUrl = localStorage.getItem('ediimageUrl') ? localStorage.getItem('ediimageUrl') : '../../../assets/images/Default_Profile_Photo.svg';
        this.getUserImage();

      }
    val === undefined ? this.sharedService.IsSidebarClicked.next('profile') : '';
    val === undefined ? this.sharedService.IsSidebarClickedNotify.next('profile') : '';
    }



    /**
     * paste non english
     */
     onPasteName(event: ClipboardEvent) {
      let clipboardData = event.clipboardData;
       let pastedText = clipboardData.getData('text').replace(/ /g, "").replace(/ /g, "");
      var regPattern = /[\u0000-\u007A]+/g;
      if (!regPattern.test(pastedText.toString())) {
       event.preventDefault();
       return false;
      }
     }
    /**
   * remove non english Characters
   */

     removeLanguageModal(event) {
      var regex = new RegExp("^[a-zA-Z0-9. \s-]*$");
      var regPattern = /[\u0000-\u007A]+/g;
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key) || !regPattern.test(key)) {
        event.preventDefault();
        return false;
      }

    }
    removeLanguageModal1(event) {
      var regex = new RegExp("^[0-9.+()-]*$");
      var regPattern = /[\u0000-\u007A]+/g;
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key) || !regPattern.test(key)) {
        event.preventDefault();
        return false;
      }

    }
     removeLanguage(event) {
      var regular1 = /{/
      var regular2 = /}/
      var regPattern = /[\u0000-\u007A]+/g;

      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
       if(regular1.test(key) || regular2.test(key) ){

        return true;

      }
      if (/|/.test(key)) {
        return true;
    }
      if (!regPattern.test(key)) {
        event.preventDefault();
        return false;
      }

    }



    alphacheck(event) {
       let tempValueDuplicate = [];
        let tempValue = [];
      var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
      if(event.target.value !== ''){
        tempValueDuplicate = [];
        tempValue = [];
        tempValue = event.target.value?.split('')

        for(let i=0;i<=tempValue.length-1;i++){
          let temp = {
            word : tempValue[i],
            ValueTarget : true
          }
          tempValueDuplicate.push(temp)
        }

        const chars = event.key;
        const char = chars;
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if(key == "å"){
          // alert("Please Enter English Only");
          if(tempValue.length > 0){
            for(let i=0;i<=tempValue.length-1;i++){

             if(!regex.test(tempValue[i])){
              tempValueDuplicate[i].ValueTarget = false
             }
            }
            tempValue = [];
            for(let i=0;i<=tempValueDuplicate.length-1;i++){
               if(tempValueDuplicate[i].ValueTarget){
                tempValue.push(tempValueDuplicate[i].word)

               }
            }
          }

          event.target.value = tempValue.join('')
          this.NormalSearchValue = event.target.value;

          event.preventDefault()

          return false;
        }
        else{
          tempValueDuplicate = [];
          tempValue = [];
          tempValue = event.target.value?.split('')
          for(let i=0;i<=tempValue.length-1;i++){
            let temp = {
              word : tempValue[i],
              ValueTarget : true
            }
            tempValueDuplicate.push(temp)
          }
          if (!regex.test(char)) {

            // alert("Please Enter English Only");
            if(tempValue.length > 0){
              for(let i=0;i<=tempValue.length-1;i++){

               if(!regex.test(tempValue[i])){
                tempValueDuplicate[i].ValueTarget = false
               }
              }
              tempValue = [];
              for(let i=0;i<=tempValueDuplicate.length-1;i++){
                 if(tempValueDuplicate[i].ValueTarget){
                  tempValue.push(tempValueDuplicate[i].word)
                 }
              }

            }

            event.target.value = tempValue.join('')
            this.NormalSearchValue = event.target.value;

            event.preventDefault()

            return false;

          }
          else {
            return true;
          }
        }

      }
      else{
        tempValueDuplicate = [];
        tempValue = [];
        tempValue = event.target.value?.split('')
        for(let i=0;i<=tempValue.length-1;i++){
          let temp = {
            word : tempValue[i],
            ValueTarget : true
          }
          tempValueDuplicate.push(temp)
        }
        const chars = event.key;
        const char = chars;
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if(key == "å"){
          // alert("Please Enter English Only");
          if(tempValue.length > 0){
            for(let i=0;i<=tempValue.length-1;i++){

             if(!regex.test(tempValue[i])){
              tempValueDuplicate[i].ValueTarget = false
             }
            }
            tempValue = [];
            for(let i=0;i<=tempValueDuplicate.length-1;i++){
               if(tempValueDuplicate[i].ValueTarget){
                tempValue.push(tempValueDuplicate[i].word)
               }
            }

          }

          event.target.value = tempValue.join('')
          this.NormalSearchValue = event.target.value;

          event.preventDefault()

          return false;
        }
        else{
          tempValueDuplicate = [];
          tempValue = [];
          tempValue = event.target.value?.split('')
        for(let i=0;i<=tempValue.length-1;i++){
          let temp = {
            word : tempValue[i],
            ValueTarget : true
          }
          tempValueDuplicate.push(temp)
        }
          if (!regex.test(char)) {

            if(tempValue.length > 0){
              for(let i=0;i<=tempValue.length-1;i++){

               if(!regex.test(tempValue[i])){
                tempValueDuplicate[i].ValueTarget = false
               }
              }
              tempValue = [];
              for(let i=0;i<=tempValueDuplicate.length-1;i++){
                 if(tempValueDuplicate[i].ValueTarget){
                  tempValue.push(tempValueDuplicate[i].word)
                 }
              }

            }

              event.target.value = tempValue.join('')
              this.NormalSearchValue = event.target.value;

             event.preventDefault()

            return false;

          }
          else {
            return true;
          }
        }
      }

    }

    alphacheckDocName(event) {
      let tempValueDuplicate = [];
       let tempValue = [];
     var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
     if(event.target.value !== ''){
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')

       for(let i=0;i<=tempValue.length-1;i++){
         let temp = {
           word : tempValue[i],
           ValueTarget : true
         }
         tempValueDuplicate.push(temp)
       }

       const chars = event.key;
       const char = chars;
       var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
       if(key == "å"){
         // alert("Please Enter English Only");
         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)

              }
           }

         }

         event.target.value = tempValue.join('')
         this.AdvSearchValue = event.target.value;

         event.preventDefault()

         return false;
       }
       else{
         tempValueDuplicate = [];
         tempValue = [];
         tempValue = event.target.value?.split('')
         for(let i=0;i<=tempValue.length-1;i++){
           let temp = {
             word : tempValue[i],
             ValueTarget : true
           }
           tempValueDuplicate.push(temp)
         }
         if (!regex.test(char)) {

           // alert("Please Enter English Only");
           if(tempValue.length > 0){
             for(let i=0;i<=tempValue.length-1;i++){

              if(!regex.test(tempValue[i])){
               tempValueDuplicate[i].ValueTarget = false
              }
             }
             tempValue = [];
             for(let i=0;i<=tempValueDuplicate.length-1;i++){
                if(tempValueDuplicate[i].ValueTarget){
                 tempValue.push(tempValueDuplicate[i].word)
                }
             }

           }

           event.target.value = tempValue.join('')
           this.AdvSearchValue = event.target.value;

           event.preventDefault()

           return false;

         }
         else {
           return true;
         }
       }

     }
     else{
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')
       for(let i=0;i<=tempValue.length-1;i++){
         let temp = {
           word : tempValue[i],
           ValueTarget : true
         }
         tempValueDuplicate.push(temp)
       }
       const chars = event.key;
       const char = chars;
       var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
       if(key == "å"){
         // alert("Please Enter English Only");
         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)
              }
           }

         }

         event.target.value = tempValue.join('')
         this.AdvSearchValue = event.target.value;

         event.preventDefault()

         return false;
       }
       else{
         tempValueDuplicate = [];
         tempValue = [];
         tempValue = event.target.value?.split('')
       for(let i=0;i<=tempValue.length-1;i++){
         let temp = {
           word : tempValue[i],
           ValueTarget : true
         }
         tempValueDuplicate.push(temp)
       }
         if (!regex.test(char)) {

           if(tempValue.length > 0){
             for(let i=0;i<=tempValue.length-1;i++){

              if(!regex.test(tempValue[i])){
               tempValueDuplicate[i].ValueTarget = false
              }
             }
             tempValue = [];
             for(let i=0;i<=tempValueDuplicate.length-1;i++){
                if(tempValueDuplicate[i].ValueTarget){
                 tempValue.push(tempValueDuplicate[i].word)
                }
             }

           }

             event.target.value = tempValue.join('')
             this.AdvSearchValue = event.target.value;

            event.preventDefault()

           return false;

         }
         else {
           return true;
         }
       }
     }

   }

   alphacheckAddedDate(event) {
    let tempValueDuplicate = [];
     let tempValue = [];
   var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
   if(event.target.value !== ''){
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')

     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }

     const chars = event.key;
     const char = chars;
     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
     if(key == "å"){
       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)

            }
         }

       }

       event.target.value = tempValue.join('')
       this.DateInput = event.target.value;

       event.preventDefault()

       return false;
     }
     else{
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')
       for(let i=0;i<=tempValue.length-1;i++){
         let temp = {
           word : tempValue[i],
           ValueTarget : true
         }
         tempValueDuplicate.push(temp)
       }
       if (!regex.test(char)) {

         // alert("Please Enter English Only");
         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)
              }
           }

         }

         event.target.value = tempValue.join('')
         this.DateInput = event.target.value;

         event.preventDefault()

         return false;

       }
       else {
         return true;
       }
     }

   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     const chars = event.key;
     const char = chars;
     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
     if(key == "å"){
       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.DateInput = event.target.value;

       event.preventDefault()

       return false;
     }
     else{
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
       if (!regex.test(char)) {

         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)
              }
           }

         }

           event.target.value = tempValue.join('')
           this.DateInput = event.target.value;

          event.preventDefault()

         return false;

       }
       else {
         return true;
       }
     }
   }

 }



   alphacheckDocContains(event) {
    let tempValueDuplicate = [];
     let tempValue = [];
   var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
   if(event.target.value !== ''){
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')

     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }

     const chars = event.key;
     const char = chars;
     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
     if(key == "å"){
       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)

            }
         }

       }

       event.target.value = tempValue.join('')
       this.AdvDocContains = event.target.value;

       event.preventDefault()

       return false;
     }
     else{
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')
       for(let i=0;i<=tempValue.length-1;i++){
         let temp = {
           word : tempValue[i],
           ValueTarget : true
         }
         tempValueDuplicate.push(temp)
       }
       if (!regex.test(char)) {

         // alert("Please Enter English Only");
         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)
              }
           }

         }

         event.target.value = tempValue.join('')
         this.AdvDocContains = event.target.value;

         event.preventDefault()

         return false;

       }
       else {
         return true;
       }
     }

   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     const chars = event.key;
     const char = chars;
     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
     if(key == "å"){
       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.AdvDocContains = event.target.value;

       event.preventDefault()

       return false;
     }
     else{
       tempValueDuplicate = [];
       tempValue = [];
       tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
       if (!regex.test(char)) {

         if(tempValue.length > 0){
           for(let i=0;i<=tempValue.length-1;i++){

            if(!regex.test(tempValue[i])){
             tempValueDuplicate[i].ValueTarget = false
            }
           }
           tempValue = [];
           for(let i=0;i<=tempValueDuplicate.length-1;i++){
              if(tempValueDuplicate[i].ValueTarget){
               tempValue.push(tempValueDuplicate[i].word)
              }
           }

         }

           event.target.value = tempValue.join('')
           this.AdvDocContains = event.target.value;

          event.preventDefault()

         return false;

       }
       else {
         return true;
       }
     }
   }

 }


 alphacheckDocType(event) {
  let tempValueDuplicate = [];
   let tempValue = [];
 var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
 if(event.target.value !== ''){
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')

   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }

   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)

          }
       }

     }

     event.target.value = tempValue.join('')
     this.isTypeSelected = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     if (!regex.test(char)) {

       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.isTypeSelected = event.target.value;

       event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }

 }
 else{
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)
          }
       }

     }

     event.target.value = tempValue.join('')
     this.isTypeSelected = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
     if (!regex.test(char)) {

       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

         event.target.value = tempValue.join('')
         this.isTypeSelected = event.target.value;

        event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }
 }

}

alphacheckDocOwner(event) {
  let tempValueDuplicate = [];
   let tempValue = [];
 var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
 if(event.target.value !== ''){
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')

   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }

   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)

          }
       }

     }

     event.target.value = tempValue.join('')
     this.selectedOwnerDisplayName = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     if (!regex.test(char)) {

       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.selectedOwnerDisplayName = event.target.value;

       event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }

 }
 else{
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)
          }
       }

     }

     event.target.value = tempValue.join('')
     this.selectedOwnerDisplayName = event.target.value;
     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
     if (!regex.test(char)) {

       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

         event.target.value = tempValue.join('')
         this.selectedOwnerDisplayName = event.target.value;

        event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }
 }

}

alphacheckDocShared(event) {
  let tempValueDuplicate = [];
   let tempValue = [];
 var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
 if(event.target.value !== ''){
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')

   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }

   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)

          }
       }

     }

     event.target.value = tempValue.join('')
     this.selectedSharedDisplayName = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     if (!regex.test(char)) {

       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.selectedSharedDisplayName = event.target.value;

       event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }

 }
 else{
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)
          }
       }

     }

     event.target.value = tempValue.join('')
     this.selectedSharedDisplayName = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
     if (!regex.test(char)) {

       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

         event.target.value = tempValue.join('')
         this.selectedSharedDisplayName = event.target.value;

        event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }
 }

}

alphacheckDocTags(event) {
  let tempValueDuplicate = [];
   let tempValue = [];
 var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
 if(event.target.value !== ''){
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')

   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }

   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)

          }
       }

     }

     event.target.value = tempValue.join('')
     this.docTagsNotes = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     if (!regex.test(char)) {

       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.docTagsNotes = event.target.value;

       event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }

 }
 else{
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)
          }
       }

     }

     event.target.value = tempValue.join('')
     this.docTagsNotes = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
     if (!regex.test(char)) {

       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

         event.target.value = tempValue.join('')
         this.docTagsNotes = event.target.value;

        event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }
 }

}

alphacheckDocIncludeWord(event) {
  let tempValueDuplicate = [];
   let tempValue = [];
 var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
 if(event.target.value !== ''){
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')

   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }

   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)

          }
       }

     }

     event.target.value = tempValue.join('')
     this.IncludetheValue = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
     for(let i=0;i<=tempValue.length-1;i++){
       let temp = {
         word : tempValue[i],
         ValueTarget : true
       }
       tempValueDuplicate.push(temp)
     }
     if (!regex.test(char)) {

       // alert("Please Enter English Only");
       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

       event.target.value = tempValue.join('')
       this.IncludetheValue = event.target.value;

       event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }

 }
 else{
   tempValueDuplicate = [];
   tempValue = [];
   tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
   const chars = event.key;
   const char = chars;
   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
   if(key == "å"){
     // alert("Please Enter English Only");
     if(tempValue.length > 0){
       for(let i=0;i<=tempValue.length-1;i++){

        if(!regex.test(tempValue[i])){
         tempValueDuplicate[i].ValueTarget = false
        }
       }
       tempValue = [];
       for(let i=0;i<=tempValueDuplicate.length-1;i++){
          if(tempValueDuplicate[i].ValueTarget){
           tempValue.push(tempValueDuplicate[i].word)
          }
       }

     }

     event.target.value = tempValue.join('')
     this.IncludetheValue = event.target.value;

     event.preventDefault()

     return false;
   }
   else{
     tempValueDuplicate = [];
     tempValue = [];
     tempValue = event.target.value?.split('')
   for(let i=0;i<=tempValue.length-1;i++){
     let temp = {
       word : tempValue[i],
       ValueTarget : true
     }
     tempValueDuplicate.push(temp)
   }
     if (!regex.test(char)) {

       if(tempValue.length > 0){
         for(let i=0;i<=tempValue.length-1;i++){

          if(!regex.test(tempValue[i])){
           tempValueDuplicate[i].ValueTarget = false
          }
         }
         tempValue = [];
         for(let i=0;i<=tempValueDuplicate.length-1;i++){
            if(tempValueDuplicate[i].ValueTarget){
             tempValue.push(tempValueDuplicate[i].word)
            }
         }

       }

         event.target.value = tempValue.join('')
         this.IncludetheValue = event.target.value;

        event.preventDefault()

       return false;

     }
     else {
       return true;
     }
   }
 }

}
    /**
     * image cropper code
     */
   imageStrem: any;
     /**
 * fileErr var
 */
   fileErr = false;
     /**
 * NoFile var
 */
   NoFile = false;
     /**
 * fileExtError var
 */
   fileExtError = false;
     /**
 * showIcon var
 */
   showIcon = false;
     /**
 * iconImagesDisabled var
 */
   iconImagesDisabled = true;
   /**
 * imageCropped fun
 */
   imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    let response = base64ToFile(event.base64);
    let file = new Blob([response], { type: 'application/octet-stream'});
    this.imageStrem = file;


}
/**
 * myqueue dot function
 */
MyDocListcount() {

  let postData = {
    "DocumentFormat": "",
    "DocumentOwner": "",
    "EndDate": "",
    "OriginalFileName": "",
    "StartDate": "",
    "UploadedBy": "",
    "Pagecount":1,
    "NumberofRow":50,
    "Verifier": this.createdBy,
    "Wsoid": Number(this.workSpaceId),

  }
  this.myqueueservice.myQueueListService(postData).subscribe((response: any) => {
    if (response.isSuccess) {
      this.rowDatatarget = (response.data);
      if(response?.data[0]?.totalcount){
        localStorage.setItem('redalert', response?.data[0]?.totalcount)
      }else{
        const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
        if (docinupload && docinupload.children) {
          docinupload.badge.dotMode = false;
          docinupload.badge.status = '';
          const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
          docinuploadchild.badge.text = 'qwerty';
          docinuploadchild.badge.status = '';


        }      }
      let redalertval = JSON.parse(localStorage.getItem('redalert'));
      if(!redalertval){
        redalertval=0

      }
          if(redalertval != 0){
        let docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");

        docinupload.badge.dotMode = true;
        docinupload.badge.status = 'danger';
        if (docinupload && docinupload.children) {

          const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
          if (redalertval > 99) {
            docinuploadchild.badge.text = "99+";
            docinuploadchild.badge.status = 'danger';
          }
          else {
            docinuploadchild.badge.text = redalertval;
            docinuploadchild.badge.status = 'danger';
          }
      }


      }
         else{
        const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
        if (docinupload && docinupload.children) {
          docinupload.badge.dotMode = false;
          docinupload.badge.status = '';
          const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
          docinuploadchild.badge.text = '';
          docinuploadchild.badge.status = '';

        }
    }
  }
  })
}
/**
 * uploadImage fun
 */
uploadImage(){
  // if(this.ediimageUrl.includes('Default_Profile_Photo')){
  //   this.ediimageUrl = '';
  // }
  this.ediimageUrl = this.croppedImage;
  this.enableSave = true;

}
/**
 * imageLoaded fun
 */
  imageLoaded() {
 this.showCropper = true;
}
/**
 * cropperReady fun
 */
cropperReady() {

}
/**
 * loadImageFailed fun
 */
loadImageFailed() {
}
/**
 * iconImage var
 */
iconImage: any;
/**
 * fileToUpload var
 */
fileToUpload: any;
/**
 * iconImagesEnable var
 */
iconImagesEnable: boolean = true;
/**
 * imageChangedEvent var
 */
imageChangedEvent: any = '';
/**
 * removeIcon var
 */
removeIcon = true;
/**
 * imageNewURL var
 */
imageNewURL: any;
/**
 * handleFileInput func
 */
handleFileInput(files) {
    if(files.currentTarget.files.length === 0){
      return
     }
    this.imageChangedEvent = files;
     this.fileErr = false;
     this.iconImagesEnable =false;
     this.fileExtError = false
     this.removeIcon = false;
     if (files != null && files != "") {
       this.fileSize = files.target.files[0].size;
       this.fileSize = Math.round((this.fileSize / 1024));
       this.NoFile = false;
       var extension = files.target.files[0].name?.split('.')[1];
       if (this.fileSize > 100) {

         document.getElementById("fileExtError").style.display = 'none';
         document.getElementById("fileErr").style.display = 'block';
          this.NoFile = true;
         this.fileErr = true;
         this.showIcon  = true;
         this.imageUrl = this.imageNewURL;
         return;
       }
       else if (extension.toLowerCase() != "png" && extension.toLowerCase() != "jpg" && extension.toLowerCase() != "jpeg") {

         document.getElementById("fileErr").style.display = 'none';
         this.NoFile = false;
         this.fileErr = false;


         document.getElementById("fileExtError").style.display = 'block';

         this.fileExtError = true;


         this.imageUrl = this.imageNewURL;
         return;
       }
       else {

      document.getElementById("fileErr").style.display = 'none';

      this.fileErr = false;
       this.NoFile = false;
      document.getElementById("fileExtError").style.display = 'none';

      this.iconImage = files.target.files[0].name;
      this.iconImagesEnable = false;
      this.fileToUpload = files.target.files.item(0);

      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
      this.showIcon  = true;
      this.iconImagesDisabled = false;

      const file = (files.target as HTMLInputElement).files[0];

      let image = files.target.files[0];
    }

     }
   }
/**
 * onCellClicked func
 */
   onCellClicked(data){
    let minidata = {
      "wsoid": Number(this.workSpaceId),
      "emailId": data.createdBy || data.status?.split('|')[2] || localStorage.getItem('localloginId'),
      "originalFileName": this.profileImagePath
     }

    this.showdata = minidata;
    this.showProfile = false;

    this.dialogRef = this.dialogService.open(this.miniprofile);
  }
/**
 * crossminidata
 */
  crossminidata(){
    this.dialogRef.close();
}
/**
 * cancleEdit func
 */
cancleEdit(){
  this.dialogRef.close();
  this.dialogRef = this.dialogService.open(this.Password, { closeOnBackdropClick: false, closeOnEsc: false });
  this.GetProfileDetails(null);
}
/**
 * onCancle func
 */
onCancle(val){

  if(!this.userProfileForm.pristine){
    this.openModal(this.unsavedChangesOnCancle, false);
  }
  else{
    this.dialogRef.close();
  }
}
/**
 * cancleUserProfile func
 */
cancleUserProfile(ref){
//   this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
//     this.loginProfilImage = this.ediimageUrl;  this.dialogRef.close();
  this.dialogRef.close();

//  this.userProfileForm.reset();
  ref.close();
  if(!this.userProfileForm.pristine){

    this.userProfileForm.reset();
    var st = window.location.toString();
  var arr = st?.split("/");
this.showSpinner= false;
  let postData: any
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "Wslink": arr[2],
    "EmailId": this.createdBy
  }

this.datanew.getUserProfileDetailsService(postData).subscribe((response:any)=>{
 if(response.isSuccess){
  localStorage.setItem('User_time_zone',response.data[0].timeZone);
this.displayNameGuidline=response?.data[0]?.displayNameGuidline;
   this.suggestedGuideline = response?.data[0]?.suggestedGuideline;
  localStorage.setItem('WsdisplayName', response?.data[0]?.displayName)

this.WorkSpaceNamePlaceholder=response?.data[0]?.wsName;
   if(response?.data[0]?.profileImagePath=="" || response?.data[0]?.profileImagePath==null || response?.data[0]?.profileImagePath == undefined ){
     this.profileImagePath = response.data[0].profileImagePath;

    this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
    this.loginProfilImage = this.ediimageUrl;
    console.log("episode6", this.loginProfilImage);
     localStorage.setItem('ediimageUrl', this.ediimageUrl)
    this.cd.detectChanges();
   }
   else{
    
     this.profileImagePath = response.data[0].profileImagePath;
     this.getUserImage();
    
     this.cd.detectChanges();

   }
   this.userProfileForm.patchValue({
    fullName:response.data[0].fullName,
    displayName:response.data[0].displayName,
    whatIDo:response.data[0].whatIdo,
    whereIAm:response.data[0].whereIam,
    timeZone:response.data[0].timeZone,
    contactNumber:response.data[0].contactNo,

   })
  }
})
  }
  this.uploadfilesize = false;
  this.fileExtError= false;
  this.notification = false;
  this.changePassword =false;
  this.formateError = false;
  this.loginProfile = true;
  this.enableSave= false;
}
cancleUserProfile1(){
  //   this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
  //     this.loginProfilImage = this.ediimageUrl;  this.dialogRef.close();
  //   this.dialogRef.close();

  // //  this.userProfileForm.reset();
  //   ref.close();
    if(!this.userProfileForm.pristine){

      this.userProfileForm.reset();
      var st = window.location.toString();
    var arr = st?.split("/");
  this.showSpinner= false;
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "Wslink": arr[2],
      "EmailId": this.createdBy
    }

  this.datanew.getUserProfileDetailsService(postData).subscribe((response:any)=>{
   if(response.isSuccess){
    localStorage.setItem('User_time_zone',response.data[0].timeZone);

  this.displayNameGuidline=response?.data[0]?.displayNameGuidline;
     this.suggestedGuideline = response?.data[0]?.suggestedGuideline;
    localStorage.setItem('WsdisplayName', response?.data[0]?.displayName)

  this.WorkSpaceNamePlaceholder=response?.data[0]?.wsName;
     if(response?.data[0]?.profileImagePath=="" || response?.data[0]?.profileImagePath==null || response?.data[0]?.profileImagePath == undefined ){
       this.profileImagePath = response.data[0].profileImagePath;

      this.ediimageUrl="../../../assets/images/Default_Profile_Photo.svg"
      this.loginProfilImage = this.ediimageUrl;
      console.log("episode7", this.loginProfilImage);
       localStorage.setItem('ediimageUrl', this.ediimageUrl)
      this.cd.detectChanges();
     }
     else{
       
       this.profileImagePath = response.data[0].profileImagePath;
       this.getUserImage();

       this.cd.detectChanges();

     }
     this.userProfileForm.patchValue({
      fullName:response.data[0].fullName,
      displayName:response.data[0].displayName,
      whatIDo:response.data[0].whatIdo,
      whereIAm:response.data[0].whereIam,
      timeZone:response.data[0].timeZone,
      contactNumber:response.data[0].contactNo,

     })
    }
  })
    }
    this.uploadfilesize = false;
    this.fileExtError= false;
    this.notification = false;
    this.changePassword =false;
    this.formateError = false;
    this.loginProfile = true;
    this.enableSave= false;
  }
/**
 * onEnter func
 */
onEnter(event){
   return false;
}
/**
 * crossdataclk var
 */
crossdataclk = '';
/**
 * advcross fun
 */
advcross(data){
  this.crossdataclk = data;
  this.advFilterDropdown = false;

}
/**
* element focus state
*/


  onCustomUserGroupChange(event: any) {

    var elementID = event;

    if (elementID == "1") {
      this.checked1 = true;
      this.checked2 = false;
      this.cd.detectChanges();
    }

    if (elementID == "2") {
      this.checked1 = false;
      this.checked2 = true;
      this.cd.detectChanges();
    }
  }
  displayValidateValue(val){
    this.enableSave = true;
    var letters = /\d/;
    let valueOfString = val.value;
    let threeCharacter = valueOfString.substring(0,3);
    if(threeCharacter.match(letters)){
        this.correctvalue1 = true;
        this.enableSave = false;
    }
    else{
        this.correctvalue1 = false;
    }
  }
 validateValue(val){
  this.enableSave = true;
  var letters = /\d/;
  let valueOfString = val.value;
  let threeCharacter = valueOfString.substring(0,3);
  if(threeCharacter.match(letters)){
      this.correctvalue = true;
      this.enableSave = false;
  }
  else{
      this.correctvalue = false;
  }
 }
 changeValue(){
  this.enableSave = true;
 }



 guid;
 dashboardcards(){
  this.guid = localStorage.getItem('loginguid')
  let usercard = {
    wsoid : Number(this.workSpaceId),
    documentOwner : this.createdBy,
    status: "",
    guid: this.guid,
    date: "",
    flag: "",
    isDismiss: 0,
    OwnerEmailID : localStorage.getItem('ownerEmailId')

  }
  this.changingValue.dashboardcards(usercard).subscribe((response: any) => {

    if (response.isSuccess) {

      this.getcountdata = response?.data;
      this.sharedService.dashboardValueTarget.next(this.getcountdata)
      if(this.getcountdata[0].invitationMembercount == 1){
        if(window.productFruits?.api != undefined){
          window.productFruits?.api?.checklists.markItemAsDone('invite_user', true)
        }
      }


      if( this.getcountdata[0].invitationMembercount >= 1 && this.getcountdata[0].ownerEmailID > 3 &&  this.getcountdata[0].generalSetting >= 1) localStorage.setItem('checklist','done')

      this.sharedservice.productFruitDataTemp.next()


    }
  })
}

getSelectedItem() {
  localStorage.setItem('saved', 'false')
  this.menuService.onItemClick().subscribe((event) => {
if (event.item.title) {
  this.selectedItem = event.item.title;
  this.selectedEvent = event.item;
  sessionStorage.setItem('lastActiveItem',JSON.stringify(event.item))
}
if (event.item.title == 'Roles') {
 this.sharedService.roleClicked.next();
}
});
this.menuService.getSelectedItem('menu')
.pipe(takeUntil(this.destroy$))
.subscribe((menuBag) => {
let value = menuBag;
  if (menuBag?.item) {
    menuBag.item.selected = false;
    this.selectedItem = menuBag.item.title;
  }
  if(this.selectedItem){
    this.selectedEvent.selected = false;
    this.selectedItem = this.selectedEvent.title;
  }
  this.cd.detectChanges();
});
}

workspaceDetails() {
  let wsoid = localStorage.getItem('localwsId');
  let postData = {
    wsoid: Number(this.workSpaceId)
  }
  this.signInService.getWSDetails(postData).subscribe((response: any) => {
    if(response.isSuccess) {
      localStorage.setItem('maxFreeUsers', response.data[0].freeUser);
      localStorage.setItem('addOnUsers', response.data[0].addOnUser);
    }
  })
}
getallparseflag;
getparserflag() {
  this.workSpaceId = localStorage.getItem('localwsId')
  let parserdata = {
    wsoid: Number(this.workSpaceId),
    createdBy: ""
  }
  this.getallparseflag = '';
  this.signInService.getparserflag(parserdata).subscribe((response: any) => {
    if (response.isSuccess) {
      this.getallparseflag = response.data;
      for (let i = 0; i <= this.getallparseflag.length - 1; i++) {
        if (this.getallparseflag[i].uniqueID === 'FID-0013') {
          localStorage.setItem('parserflag', this.getallparseflag[i].isActive);
          this.parseFlag = localStorage.getItem('parserflag');
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0018') {
          localStorage.setItem('GuestFlag', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0064') {
          localStorage.setItem('WhatsappFlag', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0063') {
          localStorage.setItem('dcoins', this.getallparseflag[i].isActive);
          this.dcoinsOn = localStorage.getItem('dcoins').toString().toLowerCase() == 'true'? true: false;
        }
        if (this.getallparseflag[i].uniqueID === 'FID-007') {
          localStorage.setItem('auditLogDuration', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-004') {
          localStorage.setItem('addDomainGS', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0062') {
          localStorage.setItem('downloadExtractedText', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-002') {
          localStorage.setItem('freeDpage', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-008') {
          localStorage.setItem('activityfeature', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0061') {
          localStorage.setItem('ViewExtractedText', this.getallparseflag[i].isActive);
        }
        // if (this.getallparseflag[i].uniqueID === 'FID-001') {
        //   localStorage.setItem('maxFreeUsers', this.getallparseflag[i].totalMemberAllow);
        // }
        if (this.getallparseflag[i].uniqueID === 'FID-006') {
          localStorage.setItem('groupManagement', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-005') {
          localStorage.setItem('roleManagement', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-003') {
          localStorage.setItem('trialFree', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0049') {
          localStorage.setItem('wsAnalytics', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0048') {
          localStorage.setItem('bulkShareing', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0050') {
          localStorage.setItem('bulkGuestSharing', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0031') {
          localStorage.setItem('bulkTags', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0052') {
          localStorage.setItem('preview', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0010') {
          localStorage.setItem('smartCabinet', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0012') {
          localStorage.setItem('Trash', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0053') {
          localStorage.setItem('Integration', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0056') {
          localStorage.setItem('RelevanceByContent', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0051') {
            localStorage.setItem('dList', this.getallparseflag[i].isActive);
            localStorage.setItem('Response', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0055') {
            localStorage.setItem('IP Capture', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === 'FID-0058') {
            localStorage.setItem('IP Address Whitelisting', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID ==="FID-0054") {
            localStorage.setItem('TallyIntegration', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID === "FID-0014") {
          localStorage.setItem('Deep Search', this.getallparseflag[i].isActive);
          if(this.getallparseflag[i].isActive.toLowerCase()=== "true"){
            this.deepSearch = true;
          }
        }
        if (this.getallparseflag[i].uniqueID === "FID-0012") {
          localStorage.setItem('customTrashDuration', this.getallparseflag[i].isActive.toLowerCase());
        }

      }
      // this.bindMenuRights();
      this.GetProfileDetails(null);
      this.getallbudgescount();

      this.cd.detectChanges();
    }
  })
}
gotobilling() {

  let hrefData = this.router.url;
  var getDomainName = ".";
  var splitDot = new Array();
  var urlData = window.location.toString();
  var splitBackslash = urlData?.split("/")[2];
  let workspaceDomain = '';
  let wsSubDomain = '';
  if (splitBackslash == "localhost:4200") {
     workspaceDomain = ".localhost:4200";
  }
  else {
    splitDot = splitBackslash?.split(".");
    for (let i = 1; i < splitDot.length; i++) {

      if (i == 1)
        getDomainName = getDomainName + splitDot[i];
      if (i == 2)
        getDomainName = getDomainName + "." + splitDot[i];
    }
    workspaceDomain = getDomainName;
    wsSubDomain = urlData?.split("/")[2];
  }
  let emailId = localStorage.getItem('localloginId');
  let wsId = localStorage.getItem('localwsId');
  this.cookieService.set('emailId', emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
  this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
  this.cookieService.set('wsURL', wsSubDomain, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });

  const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + workspaceDomain + '/manage-billing/dashboard/'], {
    queryParams: {
      wsId: wsId,
      loginId: emailId,
      token:localStorage.getItem('token')
    }
  }))
  // this.router.navigate(['/onboarding/dashboard']);
  window.open(environment.httpUrl + url.substring(1), '_blank');
  if(localStorage.getItem('refreshKey') === 'true'){
    localStorage.removeItem('refreshKey')
   window.location.reload()
  }
}

}

function e(e: any, arg1: number) {
  throw new Error('Function not implemented.');
}

