import { DatePipe, PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy,AfterViewInit, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDateService, NbDialogService, NbIconConfig, NbTagComponent, NbToastrService, NbDialogRef } from '@nebular/theme';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedAccessComponent } from '../../../shared/shared-access/shared-access.component';
import { GridOptions } from 'ag-grid-community';
import { VerifyDocumentService } from '../../../@core/verify-document.service';
import { MENU_ITEMS } from "../../pages-menu";
import { MyQueueService } from '../../../@core/utils/my-queue.service';
import { ThreeDotComponent } from '../three-dot/three-dot.component';
import { Router } from '@angular/router';
import { SharedServiceService } from '../../../shared-service.service';
import { DetailAndActivityComponent } from '../../../shared/detail-and-activity/detail-and-activity.component';
import {DocPreviewComponent } from '../../../shared/doc-preview/doc-preview.component'
import { BulkTagComponent } from '../../../shared/bulk-tag/bulk-tag.component';
import { SharedService } from '../../../@core/utils/shared.service'
import { Title } from '@angular/platform-browser';
import { SignInService } from "../../../@core/sign-in.service";
import { FormatTooltipsComponent } from './format-tooltips/format-tooltips.component';
import { TemplateRendererComponent } from './template-renderer/template-renderer.component';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { MyqueuetooltipComponent } from "../my-queau/myqueuetooltip/myqueuetooltip.component"
import { TextStatusComponent } from '../text-status/text-status.component';
import { SharedDocsService } from '../../../@core/utils/shared-docs.service';
import { OwnedDocsService } from '../../../@core/utils/owned-docs.service';
import { truncate } from 'fs';
import { DateTransformPipe } from '../../date-transform.pipe';
import { TemplateEditorComponent } from '../../../../app/shared/template-editor/template-editor.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../../../environments/environment';
import { timeStamp } from 'console';
import { OverlayContainer } from '@angular/cdk/overlay';
@Component({
  selector: 'ngx-my-queau',
  templateUrl: './my-queau.component.html',
  styleUrls: ['./my-queau.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class MyQueauComponent implements OnInit, OnDestroy,AfterViewInit {
  workSpaceId: any;
  createdBy: any;
  small: string = "small";
  isDetailOpen = false
  wsName: any;
  rowData = [];
  rowHeight: 48;
  public sortingOrder: ('asc' | 'desc')[] = ['desc', 'asc'];
  wsCreationDate: string = "1jan2020";
  filterBox: boolean = true;
  searchValue = '';
  searchData: any;
  regardingsearch = false;
  max: Date;
  min: Date;
  docFormatList: any[];
  pendingRequest: any;
  uploadedBy: any[] = [];
  DocsOwnerList: any[] = [];
  myqueuechips: any[] = [];
  validRightUser = [];
  defaultColDef;
  docIdList = [];
  selectedDocs = [];
  subscriptionChngVerify: any;
  selectedOwnername: string;
  subscriptionDlt: any;
  orignalFileNameList = [];
  selectedItem:any = '50';
  private gridApi;
  MyDocsDocumentCount: number
  noRowsTemplate = '<h4><img src="../../../assets/images/Empty-box.png" /><br>No documents<br><p>There are no documents in this listing that match your selected criteria.</p></h4>'
  WorkSpaceName: string;
  WsdisplayName: string;
  middot = ' | ';
  showSpinner: boolean;
  getguid;
  timeFormat: string;
  DateFormat: string;
  public menu = MENU_ITEMS;
  shiftkeyCode;
  shiftkey;
  element: HTMLElement;
  elementss;
  isMenuOpen: boolean;
  subscrip: Subscription;
  sub2: Subscription;
  columnApi: any;
  myqueueparser: Subscription;
  myQueueEnableRow: Subscription;
  subcribetabactive: Subscription;
  sumdpages: any;
  valuechangedata: unknown;
  filterApplied: any[];
  searchDataNew: any[];
  rowClassRules;
  filterData = [];
  templateColor: boolean = false;
  isSubmitted: boolean;
  defaultTemplate: any;
  docTemplateId: any;
  tagTemplateId: any;
  TemplateEditor: any;
  docsVerification: any;
  editTemplateInput: any;
  arrayUniqueByKey: unknown[];
  showOverlaySpinner: boolean;
  showText: boolean;
  saveeditedvalue: boolean;
  isEdit: any;
  docId: any;
  docModifiedEditedName: any;
  documentOwnerEmail: any;
  isCheckedAllUsers: boolean;
  isAllUserShare: boolean;
  documentOwnerName: any;
  ownerEmailID: any;
  docDetails: any;
  customform: any;
  exitButtonDisable: boolean;
  showSpinnerVerify: boolean;
  isDocEdited: boolean;
  multiDoc: boolean;
  getSelectedDocStatus: boolean;
  isSelectedOnceDetailsShare:boolean = false;
  verifierEmailID: any;
  sharedWithUsers: any;
  setUserGroupArr: any;
  isSelectedAllUsers: any;
  shareuserCount: any;
  verifierName: any;
  showVerifier: boolean;
  isUsersGroup: any;
  allUserLength: any;
  subscriptionShare: Subscription;
  selectedval: any;
  isRowSelectable: any;
  rowSelection: any;
  tagPanelOpen: boolean=true;
  isTrue: boolean=false;
  getTempId: any;
  isAllStatus: any;
  cellData: any;
  baseOrigin: string;
  baseURL: string;
  getverifierStatus: any;
  totalDocCount: any = 0;
  flag1: boolean;
  currentCell: any;
  previewPanelOpen: boolean=true;
  previewFlag: boolean =false;
  searchloader: unknown;
  searchString: any='';
  name: any;
  date: any;
  sortingString: any='';
  diffrenceValidate:any=[];
  countSpinner = 0;
  docSelected: number;
  docSelectedByUser: boolean = false;
  /**
   * FOR setting value in the filters of start date
   */
  searchEndDateValue: string;
    /**
   * FOR setting value in the filters of end date
   */
  searchStartDateValue: string;
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.shiftkey = event.key
    this.shiftkeyCode = event.keyCode;

  }
  @HostListener('window:keyup', ['$event'])
  keyEventup(event: KeyboardEvent) { 
    this.shiftkey = ""
    this.shiftkeyCode = 0;

  }
  @ViewChild('shareChild') shareuse: SharedAccessComponent;
  @ViewChild(ThreeDotComponent, { static: false }) threeDotComp: ThreeDotComponent;
  @ViewChild(TemplateEditorComponent) templateEditorChild: TemplateEditorComponent;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  // @ViewChild('shareChild')
  parseflag;
  rowClassRulesNoThumbnail;
  previousDocumentFormatValue : string = '';
  constructor(private datePipeTransform: DateTransformPipe, private http: HttpClient,
    private overlayContainer: OverlayContainer,
    private locations: PlatformLocation, private data1: VerifyDocumentService,
     private datanew: SharedDocsService, private dataCheck: OwnedDocsService, private renderer: Renderer2, private _eref: ElementRef, private httpClient: HttpClient, private signInService: SignInService, private sharedService: SharedService, private data: MyQueueService, private cd: ChangeDetectorRef, protected dateService: NbDateService<Date>,
    private titleService: Title, private cookieService: CookieService,
    private dialogService: NbDialogService, public dialogRef: NbDialogRef<MyQueauComponent>, private router: Router, private toastrService: NbToastrService, private sharedservice: SharedServiceService,) {
      this.baseOrigin = environment.baseOrigin;
      this.baseURL= environment.baseUrl;
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.getguid = localStorage.getItem('loginguid')
    this.min = this.dateService.addMonth(this.dateService.today(), -1);
    this.timeFormat = localStorage.getItem('TimeFormat')
    this.DateFormat = localStorage.getItem('DateFormat')
    this.parseflag = localStorage.getItem('parserflag');
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
      tooltipComponent2: 'customTooltip',
    };
    // this.rowClassRules = {
    //   'my-shaded-effect': function (params) {
    //     var pageCount = params?.data.pageCount;
    //     return pageCount === 0;

    //   },
    //   'sick-days-breach': 'data.sickDays >= 8',
    // };
    // setTimeout(() => {
    //   this.isRowSelectable = (params) => {
    //     var pageCount = params?.data.pageCount;
    //     return pageCount !== 0
    //    }
    // }, 2000);

    // this.rowClassRulesNoThumbnail = {
    //   'my-shaded-effect': function (params) {
    //     var iserror = params?.data.iserror;
    //     return iserror === 1;
    //   },
    //   'sick-days-breach': 'data.sickDays >= 8',
    // };


    this.sharedService.modalClose.subscribe((res:boolean)=>{
      this.templateColor = res;
    })
  }
  HideViewDetailresponse: boolean = false;
  option;
  optionsd: any[];
  tagTempList = [];
  templateInput;
  selectedTemplate:any;
  subscriptionmini;
  subscriptionOwnedByDetail;
  userfullname;
  tabactdata = '';
  @ViewChild('chngVerifier') chngVerifier!: TemplateRef<any>;
  @ViewChild('deleteDocsmyqueue') deleteDocsmyqueue!: TemplateRef<any>
  isSelectNoUsers
  isSelectNoUser($event){
    this.isSelectNoUsers = $event;
  }
  TagVerifyHeadGo(){
    localStorage.setItem('TagValue', 'TagData')
  }
  closePreview(){
    // this.tagPanelOpen= true;
    this.isDetailOpen = false;
    this.isTrue =false
    this.previewPanelOpen = true;
    this.hidePreview()
    this.gridColumnApi.setColumnVisible('pageCount', false);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', false);
    this.gridColumnApi.setColumnVisible('verifiedByDisplayName', false);
    setTimeout(() => { this.gridApi.sizeColumnsToFit();}, 100);
    
  }
  ngOnInit(): void {
    this.sharedService.searchloader.subscribe(()=>{
      this.showSpinner = true
    })
    this.sharedService.searchString.next(true)
    this.sharedService.cellClickedData.subscribe((data)=>{
      localStorage.setItem("docId", this.cellData.docId)
      this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
    })
    localStorage.setItem('saved', 'false')
    this.sharedservice.headerToggle.subscribe((res:boolean) =>{
      this.closeTagPanel();
      this.crossdata();
      this.showData();
      this.closePreview();

      })
    this.sharedService.closeDetailBar1.subscribe(() =>{
      this.closeTagPanel();
      this.crossdata();
      this.showData();
    });
    this.sharedService.isClose.subscribe((data)=>{
      this.closePreview();
      this.showData();
    })
    this.sharedService.getTagId.subscribe((event:any) =>{
      this.getTempId = event;
    });
    this.onGetTemplateDetails();
    this.tagTemplateList(false);
    // console.log(this.rowData)
    this.sharedService.threeDotValue.next(this.selectedDocs.length);
    this.WsdisplayName = localStorage.getItem('WsdisplayName') ? localStorage.getItem('WsdisplayName') : '';
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.WorkSpaceName = localStorage.getItem('WorkSpaceName')
    this.titleService.setTitle("My Queue" + this.middot + this.WsdisplayName + this.middot + this.WorkSpaceName + this.middot + "dox2U");
    this.MyDocList('ngOnInit', '');
    this.documentFormatValue();
    this.UploadRightsUserList()
    this.subscriptionOwnedByDetail = this.sharedService.closeDetailBar.subscribe((data) => {
      this.crossdata();
      this.showData();
    });
    this.subscriptionmini = this.sharedService.minicrossval.subscribe((data) => {
      this.crossminidata();
    });
    this.subscriptionmini = this.sharedService.ownedview.subscribe((data) => {
      this.valuechangedata = data;
    });
    this.subcribetabactive = this.sharedService.tabclickdetailact.subscribe((data) => {
      this.tabactdata = 'activestate';
    });
    this.myqueueparser = this.sharedService.myqueueparse.subscribe((data) => {

      this.MyDocListparserreal(data);
    });

    // this.myQueueEnableRow = this.sharedService.myQueueEnableRow.subscribe((data) => {

    //   this.MyDocListEnableRow(data);
    // });

    this.subscriptionChngVerify = this.sharedservice.MyqueueChangeVerifier.subscribe((data: any) => {
      if (this.selectedDocs[0].originalFileName == data.originalFileName) {
        this.selectedDocs = []
        this.selectedDocs.push(data);
        this.chngVerifiermodal(this.chngVerifier)
      }

    })
    this.subscriptionShare = this.sharedService.myqueueShare.subscribe((data: any) => {
      if (this.selectedDocs[0].originalFileName == data.originalFileName) {
        this.selectedDocs = []
        this.selectedDocs.push(data);
        this.openShareModal(data);
      }

    })

    this.sub2 = this.sharedservice.myqueueDeleteThreeDot.subscribe((data: any) => {
      let selectedData = this.getSelectedRowData(data.rowData);
      if (selectedData && selectedData[0].originalFileName == data.originalFileName && this.HideViewDetailresponseForDelete == data.res) {
        this.selectedDocs = [];
        this.selectedDocs.push(data);
        this.opendelete(this.deleteDocsmyqueue);
        this.HideViewDetailresponseForDelete = false;
      }
    })
    this.sharedService.myqueueShare.subscribe((res:any) =>{
      let selectedData = this.getSelectedRowData(res.rowData);
      if (selectedData && selectedData[0].originalFileName == res.originalFileName && this.HideViewDetailresponseForDelete == res.res) {
        this.selectedDocs = [];
        this.selectedDocs.push(res);
        this.opendelete(this.deleteDocsmyqueue);
        this.HideViewDetailresponseForDelete = false;
      }
    })
    this.sharedservice.myqueueViewDetails.subscribe(res => {
      this.HideViewDetailresponse = true;
      if (this.HideViewDetailresponse == res) {
        this.detailsection()
        
      }
    })
    this.sharedService.myqueueTagVerify.subscribe(res => {
      this.HideViewDetailresponse = true;
      if (this.HideViewDetailresponse == res) {
        this.tagVerify();
        
      }
    })
    this.sharedService.myqueuePreview.subscribe(res => {
      this.HideViewDetailresponse = true;
      if (this.HideViewDetailresponse == res) {
        this.openPreviewPanel();
       
      }
    })

    this.subscrip = this.sharedservice.myqueueDelete.subscribe((data: any) => {

      let selectedData = this.getSelectedRowData(data.rowData);
      if (selectedData && selectedData[0].originalFileName == data.originalFileName && this.HideViewDetailresponseForDelete == data.res) {
        this.isdeleteSelectedOnce = true;
        this.opendelete(this.deleteDocsmyqueue);
        this.HideViewDetailresponseForDelete = false;
      }
    })
    if(localStorage.getItem('preview') == 'True') {
      this.previewFlag=true
     }
    this.triggerFalseClick();
  }
  ngAfterViewInit(): void{
     this.rowData.length
  }
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
}
  getPageReload(){
    this.showSpinner = true;
    this.onVerifyDocDetails(this.cellData);
    this.MyDocList('ngOnInit', '');
  }
  onVerifyDocDetails(params?){
    var st = window.location.toString();
    var arr = st?.split("/");
    let postdata={
      "DocGuid" :params?.docGuid,
      "DocID": params?.docId,
      // "WsLink": arr[2],
      "Wsoid": Number(localStorage.getItem('localwsId')),
      "currentUser":localStorage.getItem('localloginId'),
      }
         this.data1.verifierDocDetailsService(postdata).subscribe((customTags:any)=>{
          if (customTags.isSuccess) {
            this.sharedService.documentId = customTags?.data.docverificationdetailslists[0].docId;
            localStorage.setItem("converted path", customTags?.data.docverificationdetailslists[0].convertedPath);
            this.isAllStatus = customTags?.data.docverificationdetailslists[0].isAllUserShare;
            this.sharedService.isShare.next(this.isAllStatus);
          }
         })
  }
  closeTagPanel() {
    this.tagPanelOpen= true;
    this.isDetailOpen = false;
    this.isTrue =false
    this.previewPanelOpen = true;
  }
  isSelectedOnce = false;
  isdeleteSelectedOnce = false;
  HideViewDetailresponseForDelete = true;
  onDeleteDocs(data) {
    let selectedData = this.getSelectedRowData(data.rowData);

    if (selectedData && selectedData[0].originalFileName == data.rowData.originalFileName && this.HideViewDetailresponseForDelete == data.res) {

      this.isdeleteSelectedOnce = true;
      this.selectedDocs = [];
      this.selectedDocs.push(data.rowData);
      this.opendelete(this.deleteDocsmyqueue);
      this.HideViewDetailresponseForDelete = false;

    }
  }
  onDeleteDocsdata(data) {
    let selectedData = this.getSelectedRowData(data.rowData);
    if (selectedData && selectedData[0].originalFileName == data.originalFileName) {
      this.isdeleteSelectedOnce = true;
      this.opendelete(this.deleteDocsmyqueue);
      this.HideViewDetailresponseForDelete = false;
    }
  }

  onClickedOutside(e) {
    this.filterBox = true;
  }
  onChangeVerifierClicked(data) {

    let selectedData = this.getSelectedRowData(data.rowData);
    if (selectedData && selectedData[0].originalFileName == data.rowData.originalFileName) {
      if (!this.isSelectedOnce) {
        this.selectedDocs = []
        this.selectedDocs.push(data.rowData);
        this.chngVerifiermodal(this.chngVerifier)
      }


    }

  }
 getVerifierStatus(){

 let url = this.baseURL + '/api/Workspace/WSCheckVerifierDoc?LoginUserEmailID='+ this.createdBy + '&Wsoid=' + this.workSpaceId + '&DocId=' + this.cellData.docId
 alert(url)
 let b = this.http.get(url);
 }
  ngOnDestroy() {
    this.subscriptionChngVerify?.unsubscribe();
    this.subscriptionOwnedByDetail?.unsubscribe();
    this.subscriptionmini?.unsubscribe();
    this.sub2?.unsubscribe();
    this.subscrip?.unsubscribe();
    this.sharedService.documentIds = [];
    this.isDetailOpen = false;
    this.HideViewDetailresponse=false
  }

  crossdata() {
    this.hidedetail = false;
    this.HideViewDetailresponse = true
    this.sharedservice.hideSideBar.next(this.hidedetail);
  }

  crossClickedOnAction(event) {

    if (event) {
      this.isSelectedOnceDetails = false;
    }
  }
  crossminidata() {
    this.dialogRef.close();
  }
  hidedetail = true;
  getIds(){
    const ids=[]
    const docVerifierEmailId = [];
    const docOwnerEmailId = [];
    const selectedGuid =[];
    const uploadedByDisplayName =[];
    const extension = [];
    const docRenameList = [];
    let loggedInUser = localStorage.getItem('WsdisplayName');
    this.selectedDocs.forEach(obj=>{
      ids.push(obj.docId);
      docVerifierEmailId.push(obj.verifiedByEmailId);
      docOwnerEmailId.push(obj.docOwnerEmailId);
      selectedGuid.push(obj.docGuid);
      uploadedByDisplayName.push(loggedInUser);
      extension.push(obj.formatExtension ?? obj.format);
      docRenameList.push(obj.neweDocRename ?? obj.docrename ?? obj.originalFileName?.split('.').slice(0, -1).join('.'));
  })
  localStorage.setItem('docId', ids.at(-1));
  this.sharedService.documentIds = [...ids];
  this.rightnavchild1.getmethodValue(ids,docVerifierEmailId,docOwnerEmailId,selectedGuid , uploadedByDisplayName,"myQueue",extension, docRenameList)
  }

  tagVerify(params?){
    let isPreviewOpen = localStorage.getItem('Invokepreview');
    this.isTrue = false;
    if (this.oneTime == 1) {
      this.tagPanelOpen = !this.tagPanelOpen;
    }
    this.oneTime++
    setTimeout(() => {
      this.oneTime = 1
    }, 1000);
    
    //let isPreviewOpen = localStorage.getItem('Invokepreview');
    this.sharedservice.hideSideBar.next(!this.tagPanelOpen);
    this.isTrue = true;
    //this.tagPanelOpen = false;
    this.hidedetail = true;
    this.previewPanelOpen=true;
    if(isPreviewOpen != 'true'){
      this.getIds();
      // localStorage.setItem('saved', 'true');
    }
    localStorage.setItem('Invokepreview',String(false))
    setTimeout(() => { this.gridApi.sizeColumnsToFit();}, 100);
  }

  // tagVerify(params?) {
  //   let isPreviewOpen = localStorage.getItem('Invokepreview');
  //   this.isTrue = true;

  //   if (this.oneTime == 1) {
  //     this.tagPanelOpen = !this.tagPanelOpen;
  //   }
  //   this.oneTime++
  //   setTimeout(() => {
  //     this.oneTime = 1
  //   }, 1000);
  //  this.sharedservice.hideSideBar.next(!this.tagPanelOpen);

  //   //this.tagPanelOpen = false;
  //   this.hidedetail = true;
  //   this.previewPanelOpen = true;
  //   if (isPreviewOpen != 'true') {
  //     this.getIds();
  //     // localStorage.setItem('saved', 'true');
  //   }
  //   localStorage.setItem('Invokepreview', String(false))
  //   setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  // }

  detailsection() {
    localStorage.setItem('Invokepreview',String(false))

    this.isTrue=false
    this.tagPanelOpen= true
    this.previewPanelOpen=true
    this.hidedetail = !this.hidedetail;
    
    this.HideViewDetailresponse = !this.HideViewDetailresponse;
    localStorage.setItem('saved', 'false')
    

    if (!this.hidedetail) {
      if (this.rowData) {
        if (this.selectedDocs.length > 0) {
          this.sendetailvalue(this.selectedDocs[this.selectedDocs.length - 1]);
        }
        else {

          this.sendetailvalue(this.senddetailsdata);
        }
      }
    }
    
    this.sharedservice.hideSideBar.next(!this.hidedetail);
    this.hideData();

  }
  oneTime:any=1
  openPreviewPanel(){
    console.log('callingRo')
    if(this.oneTime == 1){
      this.previewPanelOpen= ! this.previewPanelOpen
    }
    if(this.previewPanelOpen == false && this.oneTime == 1){
      localStorage.setItem('Invokepreview',String(true))
      this.previewchild.updateDocAuditLog()
      this.cd.detectChanges()
    }
    else{
      localStorage.setItem('Invokepreview',String(false))
    }
    this.oneTime++
    setTimeout(() => {
      this.oneTime= 1
    }, 1000);
    this.tagPanelOpen= true
    this.hidedetail=true
    this.sharedservice.hideSideBar.next(!this.previewPanelOpen);
    this.hidePreview();
  }
  hidePreview(){
    this.sharedservice.hideSideBar.next(!this.previewPanelOpen);
    this.gridColumnApi.setColumnVisible('pageCount', true);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', true);
    this.gridColumnApi.setColumnVisible('docOwnerDisplayName', true);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }
  // openModeldocdetails(params) {
  //   const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'details');
  //   this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
  //     if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {

  //     } else {

  //       this.senddetailsdata = params?.rowData;
  //       this.sendetailvalue(this.senddetailsdata);
  //       this.hideData();



  //     }
  //   });
  // }
  isSelectedOnceDetails = false;
  isSelectedOnceDetailsView1:boolean = false;
  openModeldocdetails(params){
    this.tagPanelOpen = true;
    let selectedrowData=this.getSelectedRowData(params?.rowData);
   if(!this.isSelectedOnceDetailsView1 && selectedrowData[0].originalFileName == params?.rowData.originalFileName){
    const body = this.getRoleAccessBody(params?.rowData.docId,params?.rowData.docGuid, 'details');
    this.isSelectedOnceDetailsView1 = true;
    this.senddetailsdata = params?.rowData;
    this.sendetailvalue(this.senddetailsdata);
    this.hideData();
    this.hidedetail = false;
    // this.data.viewUserWiseRoleAccess(body).subscribe((response:any) => {
    //   if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {
  
    //   } else {
    //     this.senddetailsdata = params?.rowData;
    //     this.sendetailvalue(this.senddetailsdata);
    //     this.hideData();
    //     this.hidedetail = false;
    //   }
    // });
    }

    this.sharedservice.hideSideBar.next(!this.hidedetail);
  }
  openModeldocdetailsClicked(params) {
    if (!this.isSelectedOnceDetails) {
      const body = this.getRoleAccessBody(params?.rowData.docId, params?.rowData.docGuid, 'details');
      this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
        if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {
          
        } else {

          // if (this.selectedDocs[0].originalFileName == params?.rowData.originalFileName) {

            this.isSelectedOnceDetails = true;
            this.senddetailsdata = params?.rowData;
            this.sendetailvalue(this.senddetailsdata);
            this.hideData();
            this.hidedetail = false;
          // }

        }
      });
    }
  }

  myDocsSearchForm = new FormGroup({
    docsName: new FormControl(''),
    uploadDate: new FormControl(''),

    documentFormat: new FormControl(''),
    uploadedBy: new FormControl(null),
    documentOwner: new FormControl(null)
  });

  testFormGroup = new FormGroup({
    itemsperPage: new FormControl('')
  })



  searchUserForm(value: string) {

    this.searchValue = value;
    if (value == '') {
      this.searchString=''
      this.MyDocList('ngOnInit', '');
    }
    if (!value && this.myqueuechips.length === 0) {
      this.rowData = this.searchData;
      localStorage.setItem('totalFileCount', this.rowData.length.toString())
      this.regardingsearch = false;
      this.searchDataNew = this.rowData;

    }
    else if (!value && this.myqueuechips.length !== 0) {
      this.rowData = this.filterApplied
      localStorage.setItem('totalFileCount', this.rowData.length.toString())
      this.regardingsearch = false;
      this.searchDataNew = this.rowData
      this.filterMyDoc();
    }
    else if (this.myqueuechips.length !== 0) {
      this.rowData = this.filterApplied.filter(x =>
        x.originalFileName.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
      this.regardingsearch = false;
      this.searchDataNew = this.rowData

    }
    else {
      this.currentPage = 1
      this.MyDocList('ngOnInit', '',value.trim());

      // this.rowData = this.searchData.filter(x =>
      //   x.originalFileName.trim().toLowerCase().includes(value.trim().toLowerCase())
      // );
      this.searchDataNew = this.rowData

      this.regardingsearch = true;
      if (this.rowData.length > 0) {
        this.senddetailsdata = this.rowData[0];
        this.sendetailvalue(this.senddetailsdata);
      }
      else {
        this.senddetailsdata = "";
        this.sendetailvalue(this.senddetailsdata);
      }

    }
  }

  clickReset() {
    this.myDocsSearchForm.patchValue({

      uploadDate: "",
      documentFormat: "",
      uploadedBy: null,
      documentOwner: null,
    })
    this.myqueuechips.length = 0;
    this.filterMyDoc();
  }
  selectedFileNames = '';
  onSelectionChanged(params) {
    let array=[]
    let Sorting =false
    for (let i of params.columnApi.getColumnState()){
      array.push(i.sort)
    }
    for(let i = 0 ; i <this.diffrenceValidate.length;i++){
       if((array[i] !== this.diffrenceValidate[i]) ){
        Sorting =true
       }
    }
  if(Sorting || this.diffrenceValidate.length === 0 ){
    this.diffrenceValidate = array
    let name = params.columnApi.getColumnState().filter(s => s.sort !== null && s.colId === "neweDocRename")
    let date = params.columnApi.getColumnState().filter(s => s.sort !== null && s.colId === "updatedDateTime")
    name = name[0]?.sort
    date = date[0]?.sort
if(name && name !== this.name){
  this.date=date
  if(name == 'asc'){
this.sortingString= 'OriginalFileName asc'
  }
  else{
    this.sortingString= 'OriginalFileName DESC'
  }
}
if(date && date !== this.date){
  this.name=name
  if(date == 'asc'){
    this.sortingString= 'CreatedDtm asc'
  }
  else{
    this.sortingString= 'CreatedDtm DESC'
  }

}
if(this.diffrenceValidate.length === 0 || Sorting){
  this.currentPage=1
  this.MyDocList('ngOnInit', '');
}
  }
    this.previewchild.updateDocAuditLog()

    this.cellData = this.selectedDocs[0];
        // localStorage.setItem("docId", selectedRows[0].docId)

    let val:any= this.selectedDocs.length

    this.getIds();
    this.clickeddata = this.cellData;
    localStorage.setItem("selectedDocsNumber", String(this.selectedDocs.length));
    localStorage.setItem("selectedDocs",val)
    localStorage.setItem('accessRights','true')
    if(val > 0){
    this.sharedService.docPreview.next(this.selectedDocs);
  }

    // this.onCellClicked(params);
    this.gridApi = params?.api;

    if (!this.gridApi) {
      return;
    }
    var selectedRows = this.gridApi.getSelectedRows();
    this.getSelectedRowData(this.selectedDocs[0])
    localStorage.setItem('DocOwner',selectedRows[0]?.docOwnerEmailId)
    this.getThreeDotValidation();
    localStorage.setItem("docId", selectedRows[0]?.docId)
    localStorage.setItem("selectedDocsNumber", String(this.selectedDocs.length));
    localStorage.setItem("selectedDocs",val)
    this.onVerifyDocDetails(this.cellData)
    setTimeout(() => {
      this.rightnavchild.getSharedDetails();
    }, 300);
    this.sharedService.cellClickedData.subscribe((data)=>{
      this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
    })

    var selectedRowsString = '';
    // var maxToShow = 5;
    let doclistnew = '';
    selectedRows.forEach(function (selectedRow, index) {
      // if (index >= maxToShow) {
      //   return;
      // }
      if (index > 0) {
        selectedRowsString += '|';
        doclistnew += '|'
      }
      selectedRowsString += selectedRow.originalFileName;
      doclistnew += selectedRow.docId;
    });
    localStorage.setItem('docOwnerEmailId', selectedRows[0]?.docOwnerEmailId)
  }
  numberofDocsSelected: number;
  clearSearchInput() {
    this.searchValue = '';
    let searchInput: any = document.getElementById('docsSearchName');
    searchInput.value = '';
    this.myDocsSearchForm.patchValue({
      docsName: ''
    })
    this.rowData = this.searchData;
    this.myDocsSearchForm.value.docsName = '';
    this.filterMyDoc('search');
  }
  isOpned: any;
  onPageSizeChanged(value: string) {
    var pagesize_value = value;
    this.gridApi?.paginationSetPageSize(Number(pagesize_value));
    setTimeout(() => {
      this.totalPages = (this.totalDocCount / this.selectedItem)
      if (this.totalDocCount % this.selectedItem != 0) {
        this.totalPages = Math.trunc(this.totalPages) + 1
      }
      setTimeout(() => {
        if (this.flag == true || this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages
          this.flag = false
          this.MyDocList('ngOnInit', '');
        }
        else{
          this.MyDocList('ngOnInit', '');
        }
      }, 100);
    }, 150);


  }

  gridOptions: GridOptions = {
    overlayLoadingTemplate: this.noRowsTemplate,
    paginationPageSize: 5,
    scrollbarWidth: 0
  }
  everyrowvalue = [];
  currentpagedata;
  maxindexval;
  getvaluemydata;
  geteveryval;
  allpartdata;
  makeCheck = [];
  senddetailsdata: any;
  getRowClass = params => {

    this.everyrowvalue = [];
    this.getvaluemydata = params?.node.rowModel.rowsToDisplay;



    for (let i = 0; i <= this.getvaluemydata.length - 1; i++) {

      this.geteveryval = this.getvaluemydata[i]
      this.allpartdata = this.geteveryval.data;
      this.everyrowvalue.push(this.allpartdata)
    }

    this.currentpagedata = params?.api.paginationProxy.currentPage + 1;


    if (!this.regardingsearch) {

      if (this.currentpagedata == 1) {
        this.senddetailsdata = this.everyrowvalue[0];
        const check = this.makeCheck.indexOf(this.currentpagedata);
        if (check === -1) {
          this.makeCheck.length = 0;
          this.sendetailvalue(this.senddetailsdata);
          this.makeCheck.push(this.currentpagedata);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentpagedata) {
            this.makeCheck.length = 0;
            this.sendetailvalue(this.senddetailsdata);
            this.makeCheck.push(check);
          }
        }
      }
      else if (this.currentpagedata == 2) {
        this.maxindexval = params?.api.paginationProxy.pageSize;
        this.senddetailsdata = this.everyrowvalue[this.maxindexval];

        const check = this.makeCheck.indexOf(this.currentpagedata);
        if (check === -1) {
          this.sendetailvalue(this.senddetailsdata);
          this.makeCheck.length = 0;
          this.makeCheck.push(this.currentpagedata);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentpagedata) {
            this.makeCheck.length = 0;
            this.sendetailvalue(this.senddetailsdata);
            this.makeCheck.push(check);
          }
        }
      }
      else {
        this.maxindexval = params?.api.paginationProxy.pageSize * (this.currentpagedata - 1);
        this.senddetailsdata = this.everyrowvalue[this.maxindexval];

        const check = this.makeCheck.indexOf(this.currentpagedata);
        if (check === -1) {
          this.sendetailvalue(this.senddetailsdata);
          this.makeCheck.length = 0;
          this.makeCheck.push(this.currentpagedata);
        } else if (this.makeCheck.length > 0) {
          if (this.makeCheck[0] !== this.currentpagedata) {
            this.makeCheck.length = 0;
            this.sendetailvalue(this.senddetailsdata);
            this.makeCheck.push(check);
          }
        }
      }
    }
    /*
    * documents to disable in process
    */

  };

  @ViewChild(DetailAndActivityComponent) rightnavchild: DetailAndActivityComponent;
  @ViewChild(BulkTagComponent) rightnavchild1: BulkTagComponent;
  @ViewChild(DocPreviewComponent)previewchild:DocPreviewComponent

  rightnavdatadetail;
  sendetailvalue(data) {
    if (data) {
      this.rightnavdatadetail = data;
      this.rightnavchild.actdata(this.rightnavdatadetail);
    }
    else {
      this.rightnavdatadetail = '';
      this.rightnavchild.actdata(this.rightnavdatadetail);
    }

  }
  /**
   * method open template editor
   */
  openModalTemplate() {
    this.templateColor = !this.templateColor;
    this.templateEditorChild.openTemplateModal();
  }
  columnDefs = null;
  /*
  * For disbaled/ in process Documents after upload
  */

  private updateColumnDef(rowData) {
    if (this.parseflag == 'True') {
      this.columnDefs = [
        {
          headerName: '', field: 'documentFormat',
          "cellRendererFramework": FormatTooltipsComponent,
          maxWidth: 60, onSelectionChanged: (event) => this.onSelectionCell(event),
          minWidth: 60,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          showDisabledCheckboxes: true,

          resizable: false, suppressMovable: true, suppressCellSelection: true, cellClass: 'suppress-movable-col'
        },
        {
          /*
         * For the name and tooltip of in process Documents after upload
         */

          headerName: 'Name', field: 'neweDocRename', autoEsc: false, tooltipField: 'neweDocRename'
          , tooltipComponent: 'tooltip', resizable: true, suppressMovable: true,
          cellClass: 'suppress-movable-col ; cellStyleAg ; MycolumnColor', cellStyle: {}, sortable: true, minWidth: 230,
          
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            var upperValueA = valueA.toUpperCase();
            var upperValueB = valueB.toUpperCase();
            return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
          }
        },
        {
          headerName: 'Extracted', field: 'parseStatus', maxWidth: 230, minWidth: 80, cellStyle: { 'justify-content': "left" },
          "cellRendererFramework": TextStatusComponent,
          resizable: false, suppressMovable: true, suppressCellSelection: true, cellClass: 'suppress-movable-col'
        },
        {

          /*
         * For the size color change of in process Documents after upload
         */


          headerName: 'dPages', "headerComponentFramework": TemplateRendererComponent, "cellRendererFramework": MyqueuetooltipComponent, field: 'pageConversionNew', sortable: false, resizable: false, maxWidth: 230, minWidth: 100, suppressMovable: true, cellClass: 'suppress-movable-col',
        },
        {
          /*
         * For the uploaded cell color change of in process Documents after upload
         */

          headerName: 'Uploaded on', field: 'updatedDateTime', resizable: false, maxWidth: 230, minWidth: 100, suppressMovable: true,
          cellClass: 'suppress-movable-col',  sortable: true,
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (new Date(nodeA.data.changedate) == new Date(nodeB.data.changedate)) return 0;
            return (new Date(nodeA.data.changedate) > new Date(nodeB.data.changedate)) ? 1 : -1
          }

        },


        {
          headerName: '', field: '', maxWidth: 220, minWidth: 80,
          suppressCellSelection : true, resizable: false,
          "cellRendererFramework": ThreeDotComponent, onSelectionChanged: (event) => this.getSelectedRowData(event),
          supperessMovable:false,
          cellRendererParams: {
            onVerifyClick: this.onVerifyClick.bind(this),
            onVerifyClickthree: this.onVerifyClickthree.bind(this),
            onViewClickdocdetails: this.openModeldocdetails.bind(this),
            onShareClick: this.openShareModal.bind(this),
            ondeleteClicked: this.onDeleteDocs.bind(this),
            onChangeVerifierClicked: this.onChangeVerifierClicked.bind(this),
            onTagVerify: this.tagVerify.bind(this),
            onPreview : this.openPreviewPanel.bind(this),
            rowData: rowData
          }
        }

      ];
    }
    else {
      this.columnDefs = [
        {
          headerName: '', field: 'documentFormat',
          "cellRendererFramework": FormatTooltipsComponent, onSelectionChanged: (event) => this.onSelectionCell(event),
          maxWidth: 60,
          minWidth: 60,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          showDisabledCheckboxes: true,
          resizable: false, suppressMovable: true, suppressCellSelection: true, cellClass: 'suppress-movable-col'
        },
        {
          /*
         * For the name and tooltip of in process Documents after upload
         */

          headerName: 'Name', field: 'neweDocRename', autoEsc: false, tooltipComponent: 'tooltip', resizable: true, suppressMovable: false,
          cellClass: 'suppress-movable-col ; cellStyleAg ; MycolumnColor', cellStyle: {}, sortable: true, minWidth: 230,
          
        },

        {

          /*
         * For the size color change of in process Documents after upload
         */


          headerName: 'dPages', "headerComponentFramework": TemplateRendererComponent, "cellRendererFramework": MyqueuetooltipComponent, field: 'pageConversionNew', sortable: false, resizable: false, maxWidth: 230, minWidth: 80, suppressMovable: true, cellClass: 'suppress-movable-col',
        },


        {
          /*
         * For the uploaded cell color change of in process Documents after upload
         */

          headerName: 'Uploaded on', field: 'updatedDateTime', resizable: false, maxWidth: 230, minWidth: 100, suppressMovable: true,
          cellClass: 'suppress-movable-col', sort: 'desc', sortable: true, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (new Date(nodeA.data.changedate) == new Date(nodeB.data.changedate)) return 0;
            return (new Date(nodeA.data.changedate) > new Date(nodeB.data.changedate)) ? 1 : -1;
          }

        },


        {
          headerName: '', field: '', maxWidth: 230, minWidth: 80,
          suppressCellSelection :true,
          "cellRendererFramework": ThreeDotComponent, onSelectionChanged: (event) => this.getSelectedRowData(event),
          cellRendererParams: {
            onVerifyClick: this.onVerifyClick.bind(this),
            onVerifyClickthree: this.onVerifyClickthree.bind(this),
            onViewClickdocdetails: this.openModeldocdetails.bind(this),
            onShareClick: this.openShareModal.bind(this),
            ondeleteClicked: this.onDeleteDocs.bind(this),
            onChangeVerifierClicked: this.onChangeVerifierClicked.bind(this),
            onTagVerify: this.tagVerify.bind(this),
            onPreview : this.openPreviewPanel.bind(this),
            rowData: rowData
          }
        }

      ];
    }

  }

   /**
   * 
   * @param params This method will open the doc in Viewer Editor on double clicking on the row anywhere.
   */
   onRowDoubleClicked(params) {
    let data = params?.data
    params = { rowData: data }
    this.onVerifyClick(params);
   }

  selectedatalist =[];
  onSelectionCell(event){
    this.selectedval = event;
    this.selectedatalist.push(event)

  }
  openShareModal(params){
    if(this.selectedDocs.length === 1){
      let selectedrowData=this.getSelectedRowData(params?.rowData);

      if(selectedrowData[0].docId == params?.rowData.docId){
        this.verifierEmailID = params?.rowData.verifiedByEmailId;
        this.ownerEmailID = params?.rowData.docOwnerEmailId;
        this.selectedDocs = [];
        // this.selectedDocs.push(params?.rowData);
        let tempdata = {
          owner: this.ownerEmailID,
          verifier: this.verifierEmailID,
          docId: params?.rowData.docId,
          bydefault: ''
        }
        this.onVerifyDocDetails(this.cellData)
        this.sharedService.isShare.next(this.isAllStatus);
        this.shareuse.shareUsersMqueue(tempdata);
     }
    }else{
      if(!this.isSelectedOnceDetailsShare)
      this.isSelectedOnceDetailsShare = true;
      this.openShareCommonModal();
    }

  }
  count = 0;
  openShareCommonModal(params?){
    this.onVerifyDocDetails( this.selectedDocs[0])
    this.count=Number(localStorage.getItem('uploadTime'))
    if(this.count==0){
   setTimeout(() => {
    if(this.selectedDocs.length > 1){
      const ids=[]
      const docVerifierEmailId = [];
      const docOwnerEmailId = [];
      const selectedGuid =[];
      this.selectedDocs.forEach(obj=>{
        ids.push(obj.docId)
        docVerifierEmailId.push(obj.verifiedByEmailId);
        docOwnerEmailId.push(obj.docOwnerEmailId)
        selectedGuid.push(obj.docGuid)
      })
      let tempdata = {

        owner: docOwnerEmailId[0],
        verifier: docVerifierEmailId[0],
        docId: ids[0],
        bydefault: ''
      }
      this.sharedService.isShare.next(this.isAllStatus);
      this.shareuse.shareWithMutipleUsers(ids,selectedGuid ,tempdata);
    }
   else{
    this.cellData = this.selectedDocs[0];
    let selectedrowData=this.getSelectedRowData(this.cellData);
    if(selectedrowData[0].docId == this.cellData.docId){
      this.verifierEmailID = this.cellData.verifiedByEmailId;
      this.ownerEmailID = this.cellData.docOwnerEmailId;
      // this.selectedDocs.push(params?.rowData);
      let tempdata = {
        owner: this.ownerEmailID,
        verifier: this.verifierEmailID,
        docId: this.cellData.docId,
        bydefault: ''
      }
      this.sharedService.isShare.next(this.isAllStatus);
      this.shareuse.shareUsersMqueue(tempdata);
   }
   }
   }, 400);
  }
  this.count++;
  localStorage.setItem('uploadTime','1');

    }
  getSharedWithData(docid) {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(docid),
      "database": "get2doxMaster",
    }
    this.pendingRequest = this.dataCheck.getSharedWithModalDataForView(postData).subscribe((response: any) => {
      if (response) {
        this.sharedWithUsers = response.data;
        this.setUserGroupArr = this.sharedWithUsers;
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(true);
        this.sharedService.selectedAccessEditor.next(response.data);
        this.cd.detectChanges();
      }

      else {
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(false);
      }
    })
  }
  createHyperLink(params): any {
    if (!params?.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a class="cursor-pointer' (click)="onVerifyClick(params?.data)" > ${params?.value} </a> `;
    spanElement.style.cursor = "pointer";
    spanElement.style.overflow = "hidden";
    spanElement.style.textOverflow = "ellipsis";
    spanElement.classList.add('qsdcvgynhntyth');
    spanElement.addEventListener('click', ($event) => {
      // if (params?.data.isReadable) {
        let data = params?.data
        if (params?.column.colId == "neweDocRename") {
          let params = { rowData: data }

          this.onVerifyClick(params)
        // }
      }
      $event.preventDefault();
    });
    return spanElement;
  }
  isPopup() {
    return true;
  }

  onFirstDataRendered(params) {
    params?.api.sizeColumnsToFit();
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }
  hideData() {
    this.isSelectedOnceDetailsView1=false

    this.HideViewDetailresponse = false;
    this.isDetailOpen = true;
    this.previewPanelOpen=true
    this.gridColumnApi.setColumnVisible('pageCount', false);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', false);
    this.gridColumnApi.setColumnVisible('docOwnerDisplayName', false);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    // setInterval(()=> {this.isDetailOpen ?  this.sizeToFit():'' }, 1000);

  }
  showData() {

    this.isDetailOpen = false;
    if(!this.isDetailOpen){
      this.hidedetail=true
    }
    this.gridColumnApi.setColumnVisible('pageCount', true);
    this.gridColumnApi.setColumnVisible('uploadedByDisplayName', true);
    this.gridColumnApi.setColumnVisible('docOwnerDisplayName', true);
    setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
  }

  private gridColumnApi;

  onGridReady(params) {
    this.gridApi = params?.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params?.columnApi;
    params?.api.paginationGoToPage(4);
    this.columnApi = params?.columnApi;
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    }
    // this.onPageSizeChanged(this.selectedItem);
  }
  @ViewChild('miniprofile', { read: TemplateRef }) miniprofile: TemplateRef<any>;

  showdata: any;
  clickeddata;
  multiSelect =[]
  onCellClicked(cell: any) {
  //   if (cell.column.colId !== 'name') { // cell is from non-select column
  //     cell.node.setSelected(true);
  // }
  // else{
  //   cell.node.deselectAll();
  // }

    this.currentCell=cell
   this.multiSelect.push(cell)
   this.tagTemplateList(false);
   this.getIds();
   this.rightnavchild.getSharedDetails();
  //  this.rightnavchild.getSharedWithData(cell.data.docId);
   this.rightnavchild.userName = '';
   this.rightnavchild.userAndGroupName = '';
   this.cellData = cell.data;

   this.onVerifyDocDetails(cell.data)
   localStorage.setItem("Parse Status",cell.data.parseStatus);
    this.clickeddata = cell;
    localStorage.setItem("docId", this.cellData.docId)
    this.getSelectedRowData(this.clickeddata.data)

    // this.rightnavchild1.getNoteData(cell.data);

    if (cell.column.colId == "0") {

      if (cell.data.iserror === 1) {
        this.onDeleteDocsdata(cell.data)

      }
    }

    if (cell.column.colId == "uploadedByDisplayName") {
      let minidata = {
        "wsoid": cell.data.wsoid,
        "emailId": cell.data.uploadedByEmailId,
        "originalFileName": cell.data.thumbnailPath,
      }

      this.showdata = minidata;

      this.dialogRef = this.dialogService.open(this.miniprofile);
    }
    if (cell.column.colId == "docOwnerDisplayName") {
      let minidata = {
        "wsoid": cell.data.wsoid,
        "emailId": cell.data.docOwnerEmailId,
        "originalFileName": cell.data.thumbnailPath
      }

      this.showdata = minidata;

      this.dialogRef = this.dialogService.open(this.miniprofile);
    }
  }
  oldverifier;
  docowner;
  uploaderemail;
  verifiernamedelete;

  getSelectedRowData(params) {

    let selectedNodes = this.gridApi.getSelectedNodes();
    if (selectedNodes.length >= 1) {
      this.docSelected = selectedNodes.length;
      this.docSelectedByUser = true;
      }
      else {
        this.docSelectedByUser = false;
      }
     
    let selectedData = selectedNodes?.map(node => node?.data);

    this.selectedDocs = selectedData;
    this.sharedService.selectedDocsData.next(this.selectedDocs);
    for (let i = 0; i <= this.selectedDocs.length - 1; i++) {
      // if (this.valuechangedata != 'View') {
        if (params) {
          if (params?.docId == this.selectedDocs[i].docId) {
            this.onVerifyDocDetails(this.selectedDocs[0])
            this.rightnavchild.getSharedDetails();
            this.rightnavchild.getdetailsdata(this.selectedDocs[0], '');
            this.sendetailvalue(this.selectedDocs[this.selectedDocs.length - 1]);
          }
        }
      // }
      // else {
      //   this.valuechangedata = '';
      // }

    }

    this.selectedDocs = selectedData;
    if(this.selectedDocs.length>1){
      this.multiDoc = true;
    }
    if(this.selectedDocs.length == 0 || this.selectedDocs.length == 1){
      let val = 'single'
      this.sharedService.threeDotValue.next(val);
    }else{
      let val = 'multi'
      this.sharedService.threeDotValue.next(val);
    }
    this.oldverifier = this?.selectedDocs[0]?.verifiedByEmailId;
    this.docowner = this?.selectedDocs[0]?.docOwnerEmailId;
    this.uploaderemail = this?.selectedDocs[0]?.uploadedByEmailId;
    this.verifiernamedelete = this?.selectedDocs[0]?.verifiedByName;

    return selectedData;

  }
  @ViewChild('wsOutSync', { read: TemplateRef }) wsOutSync: TemplateRef<any>;
  verifydocguid;
  valuerowdata;
  onVerifyClick(params) {

    let selectedData = params?.rowData;

    let selectvaluedoc = selectedData.docId;
    if (params?.rowData.docId) {
      this.valuerowdata = params?.rowData.docId;
    }
    else {
      this.valuerowdata = params?.docId;
    }
    if ((selectvaluedoc == this.valuerowdata) && ((this.shiftkey !== 'Shift' && this.shiftkeyCode !== 16))) {
      if (!this.isSelectedOnce) {
        this.selectedDocs = []
        this.selectedDocs.push(params?.rowData);

        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(params?.rowData.docId),
          "role": "PendingView",
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getguid,
          "DocGuid": params?.rowData.docGuid
        }
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === 'Not Access') {
              this.openModal(this.wsOutSync, false);
            } else {

              localStorage.setItem('redirectedFrom', 'my-docs')

              const url = this.router.serializeUrl(
                this.router.createUrlTree(['verification/verify-document'],
                  { queryParams: { docId: params?.rowData.docId, docGuid: params?.rowData.docGuid } }
                )
              );

              window.open(url, '_blank');

            }
          } else {
            this.openModal(this.wsOutSync, false);
          }

        }, error => {

        })
      }
    }
  }

  onVerifyClickthree(params) {
    let selectedData = this.getSelectedRowData(params?.rowData);
    let selectvaluedoc = selectedData[0].docId;
    if (params?.rowData.docId) {
      this.valuerowdata = params?.rowData.docId;
    }
    else {
      this.valuerowdata = params?.docId;
    }
    if ((selectvaluedoc == this.valuerowdata) && ((this.shiftkey !== 'Shift' && this.shiftkeyCode !== 16))) {
      if (!this.isSelectedOnce) {
        this.selectedDocs = []
        this.selectedDocs.push(params?.rowData);

        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(params?.rowData.docId),
          "role": "PendingView",
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getguid,
          "DocGuid": params?.rowData.docGuid
        }
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === 'Not Access') {
              this.openModal(this.wsOutSync, false);
            } else {

              localStorage.setItem('redirectedFrom', 'my-docs')

              const url = this.router.serializeUrl(
                this.router.createUrlTree(['verification/verify-document'],
                  { queryParams: { docId: params?.rowData.docId, docGuid: params?.rowData.docGuid } }
                )
              );

              window.open(url, '_blank');

            }
          } else {
            this.openModal(this.wsOutSync, false);
          }

        }, error => {

        })
      }
    }
  }

  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      autoFocus: true,
      closeOnEsc: false
    });

  }
  autoowner;
  uploadedbyMail: any
  DocownerMail: any
  docName: string;
  docNameArray = [];
  rowDataTemp =[];
  currentPage=1
  totalPages=1;
  redAlert;
  MyDocList(val: any, deletedata,search?) {
    let searchString=''
    if(search){
      this.searchString = search
    }
    var promise = new Promise((resolve, reject) => {
      let postData: any;
      let uploadedbyarr: any[] = [];
      let DocsOwnerarr: any[] = [];
      this.myqueuechips = [];
      this.filterBox = true;
      // this.cd.detectChanges();
      this.rowData = [];
      if(this.countSpinner === 0){
        this.countSpinner++;
        this.showSpinner = true;
        setTimeout(() => {
          this.countSpinner = 0;
        }, 2000);
      } else {
        this.showSpinner = false;
      }
      let numberofRow = 0
      if(this.currentPage == this.totalPages){
        numberofRow =  Number(this.totalDocCount)
      }
      else if(this.currentPage!=0 && this.currentPage != undefined ){
     numberofRow =  Number(this.selectedItem) * this.currentPage
      }
      else{
        numberofRow =  Number(this.selectedItem ) * 1
      }
      postData = {
        "Wsoid": Number(this.workSpaceId),
        "OriginalFileName": this.searchString,
        "Verifier": this.createdBy,
        "StartDate": this.searchStartDateValue?this.searchStartDateValue:"",
        "EndDate": this.searchEndDateValue?this.searchEndDateValue:"",
        "DocumentFormat": this.myDocsSearchForm.value.documentFormat || "",
        "UploadedBy":this.myDocsSearchForm.value.uploadedBy||"",
        "DocumentOwner": this.myDocsSearchForm.value.documentOwner ||"",
        "NumberofRow" :Number(this.selectedItem),
        "Pagecount":this.currentPage,
        "sortingstr": this.sortingString
         }
      this.data.myQueueListService(postData).subscribe((response: any) => {
        if(response?.data[0]?.totalcount){
          this.totalDocCount =response.data[0].totalcount
          this.redAlert = response.data[0].totalcount;
          this.totalPages = (this.totalDocCount / this.selectedItem)
          if(this.totalDocCount % this.selectedItem != 0){
            this.totalPages= Math.trunc(this.totalPages)+1
          }
        }
      else{
        this.totalPages=1
        this.currentPage=1
        this.totalDocCount = 0;
        this.redAlert=0
        const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
        if (docinupload && docinupload.children) {
          docinupload.badge.dotMode = false;
          docinupload.badge.status = '';
          const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
          docinuploadchild.badge.text = '';
          docinuploadchild.badge.status = '';

        }      }
        if (val != "ngOnInit") {
          if ((postData.StartDate == undefined || postData.StartDate == "") && (postData.EndDate == undefined || postData.EndDate == "") && this.DocownerMail == "" && this.myDocsSearchForm.value.documentFormat == ""
            && this.myDocsSearchForm.value.documentOwner == "" && this.myDocsSearchForm.value.uploadedBy == "") {
            this.toastrService.primary("No filter applied");
          }
        }
        resolve('resolved');
        /*
       * For Time date manipulation
       */
        this.rowData = this.datePipeTransform.transform(response);
        this.updateColumnDef(response.data);
        if (response.isSuccess) {
          this.showSpinner = false;
          this.autoowner = [];
          this.filteredOptions$ = [];

          response.data.forEach(element => {
            if (element.uploadedByEmailId == this.createdBy) {
              element['uploadedByDisplayName'] = element.uploadedByDisplayName + ' (you)';
            }
            if (element.docOwnerEmailId == this.createdBy) {
              element['docOwnerDisplayName'] = element.docOwnerDisplayName + ' (you)';
            }
            element.neweDocRename = element.neweDocRename?.replace(/</g, "&lt;").replace(/>/g, "&gt;");
            element.pageConversionNew = element.pageConversion?.split(':')[1];
          });
          this.rowData = (response.data);



          if (this.rowData.length == 0) {
            this.sendetailvalue('')
            this.showSpinner = false;
          }
          if (deletedata == 'delete') {
            this.afterdelete();
            deletedata = '';
          }
          this.rowData.forEach(element => {
            element.neweDocRename = element.originalFileName?.split('.').slice(0, -1).join('.');
          });
          localStorage.setItem('redalert', String(this.redAlert))
          this.updateColumnDef(response.data)

          this.searchData = (response.data);
          let redalertval = JSON.parse(localStorage.getItem('redalert'));
          if (redalertval != 0) {
            const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");

            docinupload.badge.dotMode = true;
            docinupload.badge.status = 'danger';
            if (docinupload && docinupload.children) {

              const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");

              if (this.totalDocCount > 99) {
                docinuploadchild.badge.text = "99+";
                docinuploadchild.badge.status = 'danger';
              }
              else {
                docinuploadchild.badge.text = String(this.totalDocCount);
                docinuploadchild.badge.status = 'danger';
              }

              this.cd.detectChanges();
            }
          }
          else {
            const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
            docinupload.badge.dotMode = false;
            docinupload.badge.status = '';
            if (docinupload && docinupload.children) {
              const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");

              docinuploadchild.badge.text = '';
              docinuploadchild.badge.status = '';
              this.cd.detectChanges();
            }
          }

          this.cd.detectChanges();

          this.MyDocsDocumentCount = this.searchData.length;
          for (var i = 0; i < this.searchData.length; i++) {

            this.searchData[i].uploadedByDisplayName != null ? uploadedbyarr.push(this.searchData[i].uploadedByDisplayName) : '';
            this.searchData[i].docOwnerDisplayName != null ? DocsOwnerarr.push(this.searchData[i].docOwnerDisplayName) : '';
          }

          this.filteredOptions$ = of(this.options);

          this.cd.detectChanges();


        }
        else {
          this.showSpinner = false;
          this.senddetailsdata = '';
          this.sendetailvalue(this.senddetailsdata);
        }
      })
    })
    promise.then((success) => {
      this.getFilterList();

    })
    this.previousDocumentFormatValue = this.myDocsSearchForm.value.documentFormat;
  }

  afterdelete() {
    if (this.rowData) {
      this.senddetailsdata = this.rowData[0];
      this.sendetailvalue(this.senddetailsdata);
    }
  }
  /**
   * filter row data code
   */


  filterMyDoc(data?) {
    this.myqueuechips = [];
    this.filterBox = true;
    this.rowData = this.searchValue === '' ? this.searchData : this.searchDataNew;
    if (this.myDocsSearchForm.value.uploadDate == null || this.myDocsSearchForm.value.uploadDate == undefined || this.myDocsSearchForm.value.uploadDate == "") {
      this.myDocsSearchForm.value.uploadDate = ""
    }
    if (this.myDocsSearchForm.value.uploadDate !== "") {
      var startDate: any = moment(this.myDocsSearchForm.value.uploadDate.start).format('MM-DD-YYYY');
      var endDate: any = moment(this.myDocsSearchForm.value.uploadDate.end).format('MM-DD-YYYY');
      this.searchStartDateValue = startDate;
      this.searchEndDateValue = endDate;
    } else {
      var startDate: any = null;
      var endDate: any = null;
      this.searchStartDateValue = startDate;
      this.searchEndDateValue = endDate;
    }

    if ((startDate == null || startDate == undefined || startDate == '') && (endDate == null || endDate == undefined || endDate == '')) {
      startDate = "";
      endDate = "";
      this.searchStartDateValue = startDate;
      this.searchEndDateValue = endDate;
    }
    else {
      if (endDate == null || endDate == undefined || endDate == '') {
        endDate = startDate;
        this.searchStartDateValue = startDate;
        this.searchEndDateValue = startDate;
      }
      this.myqueuechips.push('Date: ' + new DatePipe('en-US').transform(startDate, this.DateFormat)?.split('-').join(' ') + '-' + new DatePipe('en-US').transform(endDate, this.DateFormat)?.split('-').join(' '))
      this.rowData = this.rowData.filter(element => {

        return (moment(element.changedate).format('MM-DD-YYYY') >= startDate && moment(element.changedate).format('MM-DD-YYYY') <= endDate);

      });
      this.cd.detectChanges();

    }

    if (this.myDocsSearchForm.value.documentFormat == null || this.myDocsSearchForm.value.documentFormat == undefined || this.myDocsSearchForm.value.documentFormat == "") { this.myDocsSearchForm.value.documentFormat = "" }
    else {
      this.rowData = this.rowData.filter(element => {

        return element.documentFormat === this.myDocsSearchForm.value.documentFormat

      });
      this.myqueuechips.push('Document format: ' + this.myDocsSearchForm.value.documentFormat)
      this.cd.detectChanges();

    }

    if (this.myDocsSearchForm.value.uploadedBy == null || this.myDocsSearchForm.value.uploadedBy == undefined || this.myDocsSearchForm.value.uploadedBy == "") { this.myDocsSearchForm.value.uploadedBy = ""; this.uploadedbyMail = "" }
    else {
      this.rowData = this.rowData.filter(element => {

        return element.uploadedByEmailId === this.myDocsSearchForm.value.uploadedBy
      });

      this.myqueuechips.push('Uploaded by: ' + (this.myDocsSearchForm.value.uploadedBy))
      this.cd.detectChanges();
    }

    if (this.myDocsSearchForm.value.documentOwner == null || this.myDocsSearchForm.value.documentOwner == undefined || this.myDocsSearchForm.value.documentOwner == "") { this.myDocsSearchForm.value.documentOwner = ""; this.DocownerMail = "" }
    else {
      this.rowData = this.rowData.filter(element => {

        return element.docOwnerEmailId === this.myDocsSearchForm.value.documentOwner
      });
      this.myqueuechips.push('Owned by: ' + (this.myDocsSearchForm.value.documentOwner));
      this.cd.detectChanges();
    }
    this.filterApplied = this.rowData

    if ((startDate == undefined || startDate == "") && (endDate == undefined || endDate == "") && this.DocownerMail == "" && this.myDocsSearchForm.value.documentFormat == ""
      && this.myDocsSearchForm.value.documentOwner == "" && this.myDocsSearchForm.value.uploadedBy == "" && data === undefined) {
      this.toastrService.primary("No filter applied");
    }
    if (this.rowData.length == 0) {
      this.sendetailvalue('')
    }
    else {
      this.senddetailsdata = this.rowData[0];
      this.sendetailvalue(this.senddetailsdata);
    }
    if(this.previousDocumentFormatValue !==this.myDocsSearchForm.value.documentFormat) {
      this.currentPage = 1;
    }
    this.MyDocList('ngoninit','');
  }
  /**
   *
   * @param event outside click close event
   */
  searchBox(event) {
    this.elementss = event.currentTarget.id;
    this.filterBox = !this.filterBox;
    this.getfilterid = document.getElementById("filterclickicon").id;
  }

  filtercardclick = ""
  filterdata(data) {
    this.filtercardclick = data;
  }
  /*
  bind value in filter input
  */
  documentFormatValue() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any

    this.pendingRequest = this.data.documentFormatService(postData).subscribe((response: any) => {

      if (response.isSuccess == true) {
        this.docFormatList = response.data;

      }


    }, error => {

    })
  }


  /*
   searchable dropdow code start
   */

  options: string[];
  filteredOptions$;

  @ViewChild('autoInput') input;

  private filteruploaded(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptionsuploaded(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filteruploaded(filterString)),
    );
  }



  onChangeuploadedby() {
    this.filteredOptions$ = this.getFilteredOptionsuploaded(this.input.nativeElement.value);

  }

  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptionsuploaded($event);
  }

  /*

   bind value for owner
   */
  @ViewChild('autoInputowner') inputt;

  private filterowner(value: string): string[] {
    const filterValueowner = value.toLowerCase();
    return this.DocsOwnerList.filter(optionValue => optionValue.toLowerCase().includes(filterValueowner));
  }

  getFilteredOptionsowner(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filterowner(filterString)),
    );
  }

  onChangeowner() {
    this.autoowner = this.getFilteredOptionsowner(this.inputt.nativeElement.value);

  }
  selectnewverifier;
  onChangeVerifier(event) {
    this.selectedOwnerEmail = event.emailId;
    this.selectedOwnername = event.displayName;
    this.selectnewverifier = event.fullName;
  }
  onSelectionChangeowner($event) {
    this.autoowner = this.getFilteredOptionsowner($event);
  }

  /*
   searchable dropdow code end
   */

  //for remove chips
  sliceTagKey: any;
  statusKey: any;
  onTagRemove(tagToRemove: NbTagComponent): void {
    this.myqueuechips = this.myqueuechips.filter(t => t !== tagToRemove.text);
    this.sliceTagKey = tagToRemove.text;
    this.statusKey = this.sliceTagKey?.split(':')[0]

    if (this.statusKey === 'Date') {
      this.myDocsSearchForm.patchValue({
        uploadDate: ""
      })
      // this.filterMyDoc();
    }

    if (this.statusKey === 'Document format') {
      this.myDocsSearchForm.patchValue({
        documentFormat: ""
      })
      // this.filterMyDoc();
    }

    if (this.statusKey === 'Uploaded by') {
      this.myDocsSearchForm.patchValue({
        uploadedBy: null
      })
      // this.filterMyDoc();
    }
    if (this.statusKey === 'Owned by') {
      this.myDocsSearchForm.patchValue({
        documentOwner: null
      })
      // this.filterMyDoc();
    }

  }

  /*
   Delete docs modal method
   */

  deleteDocsModalForm = new FormGroup({
    delDocument: new FormControl(),

  })
  open(dialog: TemplateRef<any>) {
    /*
    *  remove this line to enable disable docs function
    */

    this.selectedOption = 'Poor scan quality';
    this.Otherreason = false;
    this.dialogService.open(dialog, { closeOnBackdropClick: false, autoFocus: false })
    this.element = document.getElementById('deleteModal');
    this.element.focus();
  }
  opendelete(dialog: TemplateRef<any>) {
    /*
    *  remove this line to enable disable docs function
    */

    if (this.selectedDocs.filter(a => a.pageCount == 0).length > 0) {

      this.openModal(this.wsOutSync, false);
    } else {
      this.selectedOption = 'Poor scan quality';
      this.Otherreason = false;
      this.dialogService.open(dialog, { closeOnBackdropClick: false, autoFocus: false })
      this.element = document.getElementById('deleteModal');
      this.element.focus();
    }

  }


  valueChange(reason: any) {
    if (reason == 'Other') { this.Otherreason = true; }
    else { this.Otherreason = false; }
  }

  getSum(total, num) {
    return total + Math.round(num);
  }
  Otherreason: boolean = false;

  @ViewChild('OtherReason') OtherReason!: ElementRef
  deletedoccount;
  docguidlist = [];
  dpagescount = [];
  enableflag;
  DeleteDocsModalResion() {
    this.docIdList = [];
    this.docguidlist = [];
    this.dpagescount = [];
    this.selectedDocs.forEach(element => {
      this.docIdList.push(element.docId);
      this.dpagescount.push(JSON.parse(element.pageConversionNew))
      this.docguidlist.push(element.docGuid);
      this.orignalFileNameList.push(element.originalFileName);
    })

    if (this.dpagescount) {
      this.sumdpages = this.dpagescount.reduce(this.getSum, 0)
    }
    if (this.selectedDocs.length >= 1) {
      this.oldverifier = this.selectedDocs[0].verifiedByEmailId;
      this.docowner = this.selectedDocs[0].docOwnerEmailId;
      this.uploaderemail = this.selectedDocs[0].uploadedByEmailId;
      this.verifiernamedelete = this.selectedDocs[0].verifiedByName;

      var st = window.location.toString();
      var arr = st?.split("/");

      let postData;
      if (this.selectedOption == 'Other') {
        this.selectedOption = this.OtherReason.nativeElement.value
      }
      if (this.parseflag == 'True') {
        this.enableflag = 1
      }
      else {
        this.enableflag = 0
      }
      postData = {
        "Wsoid": Number(this.workSpaceId),
        "WsLink": arr[2],
        "DocIdList": this.docIdList.join('|'),
        "OriginalFileNameList": this.orignalFileNameList.join('|'),
        "ModifiedBy": this.createdBy,
        "DeleteReason": this.selectedOption,
        "Separator": "|",
        "guid": this.getguid,
        "DocGuid": this.docguidlist.join('|'),
        "IsParserEnable": 1
      }
      this.deletedoccount = this.docIdList.length;
      this.pendingRequest = this.data.deleteMyDocService(postData).subscribe((response: any) => {

        if (response.isSuccess) {

          const iconConfig: NbIconConfig = { icon: '' };
          this.toastrService.primary(this.selectedDocs.length + ' Document(s) deleted.', iconConfig);
          this.sharedService.myqueuedpages.next(this.sumdpages);
          this.MyDocList('ngOnInit', 'delete');
          if ((this.docowner != this.oldverifier) && (this.uploaderemail != this.oldverifier) && (this.docowner != this.uploaderemail)) {
            this.notifyinsertthird();
            this.notifyinsertfourth();
          }
          if ((this.docowner != this.oldverifier) && (this.uploaderemail != this.oldverifier) && (this.docowner == this.uploaderemail)) {
            this.notifyinsertfivth();
          }

          setTimeout(() => {
            this.sizeToFit();
          }, 500);
        }
        else {

          const iconConfig: NbIconConfig = { icon: '' };
          this.toastrService.primary(response.returnMessage, iconConfig);
          this.MyDocList('ngOnInit', '');
        }
      },);

    }


  }

  selectedOption: any = 'Poor scan quality';

  uploadRightUserList = [];
  docOwnerName = [];
  docUploaderName = [];
  filteredNameData = [];
  uniqueFilterData = [];
  filteredRightUserList = [];
  rightUserList = []
  fullName: any
  UploadRightsUserList() {
    this.filteredRightUserList = [];
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }

    this.dataCheck.searchAllUsersService(postData).subscribe((response : any)=>{
      let actualUsers = [];
      response.data.forEach(user=>{
        if(!user.roleName.includes("Tally") && user.emailId !== this.createdBy ){
          actualUsers.push(user.roleName);
        }
      })
      this.allUserLength = actualUsers.length;
    })

    this.pendingRequest = this.data.UploadRightsUserListService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.rightUserList = response.data;
        this.uploadRightUserList = response.data;
        for (let user of this.uploadRightUserList) {

          if (user.emailId !== this.createdBy) {
            this.filteredRightUserList.push(user)
          }
          else {
            this.fullName = user.fullName
          }

        }

      }
    },)
  }
  /**
  * get Filter list
  */
  getFilterList() {
    let postData;
    postData = {
      "wsoid": Number(this.workSpaceId),
      "userEmailID": this.createdBy,
      "flag": "MyDocList"
    }
    this.doccountval = this.docIdList.length;
    this.newverifier = this.selectedOwnerEmail;

    this.data.getFilterList(postData).subscribe((response: any) => {
      if (response.isSuccess) {

        this.filterData = response.data;
        this.uploadedBy = response.data.filter(o1 => o1.verifier);
        this.uploadedBy = [...new Map(this.uploadedBy.map((item, key) => [item['verifier'], item])).values()];
        this.uploadedBy.forEach(element => {
          if (element.verifier == this.createdBy) {
            element['verifierName'] = element.verifierName + ' (you)';
          }
        });
        this.DocsOwnerList = response.data.filter(o1 => o1.documentOwner);
        this.DocsOwnerList = [...new Map(this.DocsOwnerList.map((item, key) => [item['documentOwner'], item])).values()]
        this.DocsOwnerList.forEach(element => {
          if (element.documentOwner == this.createdBy) {
            element['documentOwnerName'] = element.documentOwnerName + ' (you)';
          }
        });
      }
    });
  }
  chngVerifiermodal(dialog: TemplateRef<any>) {
    /*
   *  remove this line to enable disable docs function
   */
    this.data1.getVerifierStatus().subscribe((data:any)=>{
      this.getverifierStatus = data.returnMessage;
    })
  setTimeout(()=>{
    if (this.selectedDocs.filter(a => a.pageCount == 0).length > 0) {

      this.openModal(this.wsOutSync, false);
      this.getverifierStatus = '';
    } else {
      if( this.getverifierStatus == "0"){
        this.openModal(this.wsOutSync, false);
        this.getverifierStatus = '';
      }else{
        this.dialogService.open(dialog, { closeOnBackdropClick: false });
        this.isSelectedOnce = true;
      }
      }
  },1000)



  }

  /*
   show search section
   */


  showSearch: boolean = false;
  onFunnelClick() {
    if (this.showSearch) {
      if (this.myDocsSearchForm.value.docsName !== '' || this.myqueuechips.length > 0) {
        return
      }
    }
    this.showSearch = !this.showSearch;
  }
  /*

   docs verifier modal method
   */
  listBg = true;

  /*
   For List View show
   */
  onGridClick() {
    this.listBg = false;
  }
  reloadScreen() {
    window.location.reload();
  }
  /*

   For Grid View show
   */
  onListClick() {
    this.listBg = true;
  }

  selectedOwner: any;


  selectedOwnerEmail: any;
  doccountval;
  newverifier;
  docguidlistdata = [];
  onDocumentVerifierUpdate() {

    this.docIdList = [];
    this.docguidlistdata = [];

    this.selectedDocs.forEach(element => {
      this.docIdList.push(element.docId);
      this.docguidlistdata.push(element.docGuid);
    })

    if (this.selectedDocs.length == 1) {
      this.oldverifier = this.selectedDocs[0].verifiedByEmailId;
      this.docowner = this.selectedDocs[0].docOwnerEmailId;
    }

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData;

    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "DocIdList": this.docIdList.join('|'),
      "DocCount": this.docIdList.length.toString(),
      "OldVerifierEmailId": this.createdBy,
      "OldVerifierName": this.fullName,
      "VerifierEmailId": this.selectedOwnerEmail || '',
      "OriginalFileNameList": this.orignalFileNameList.join('|'),
      "ModifiedBy": this.createdBy,
      "Separator": "|",
      "guid": this.getguid,
      "DocGuid": this.docguidlistdata.join('|')
    }
    this.doccountval = this.docIdList.length;
    this.newverifier = this.selectedOwnerEmail;

    this.data.UpdateDocumentVerifier(postData).subscribe((response: any) => {
      if (response.isSuccess) {

        this.toastrService.primary('Verifier of the selected document(s) changed');
        this.MyDocList('ngOnInit', '');

        if (this.oldverifier != this.newverifier) {
          this.notifyinsertfirst();
        }

        if (this.oldverifier != this.docowner) {
          this.notifyinsertsecond();
        }

        this.selectedOwnerEmail = '';
      }
      else {
        const iconConfig: NbIconConfig = { icon: '' };
        this.toastrService.primary(response.returnMessage, iconConfig);

        this.MyDocList('ngOnInit', '');
        this.selectedOwnerEmail = '';
      }
    });

  }


  insertmessgae = '';
  messageheading;
  messagebody;
  notifyinsertfirst() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents to be verified",
      message: "<b>" + this.userfullname + " (" + this.createdBy + ")" + "</b>" + " " + "has assigned you" + " " + this.doccountval + " " + "documents for verification. Go to " + `<a href=${urlchange}>Workspace Queue</a>`,
      notificationTo: this.newverifier,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendfirst();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  sendmessage;
  notifysendfirst() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.newverifier,
      role: null,
      groupName: null,
      message: "Documents to be verified",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }


  notifyinsertsecond() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchangeall = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Verifier reassigned",
      message: "<b>" + this.selectnewverifier + " (" + this.newverifier + ")" + "</b>" + " " + "has been assigned as the new verifier for " + this.doccountval + ' ' + `of your documents. Go to ` + `<a href=${urlchangeall}>Workspace Queue</a>`,
      notificationTo: this.docowner,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendsecond();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  docProcessingCompleted() {
    let pricessingCompleted = true;
    this.selectedDocs.forEach(element => {
      if (element.pageCount === 0) {
        pricessingCompleted = false;
      }
    });
    return pricessingCompleted;
  }

  notifysendsecond() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.docowner,
      role: null,
      groupName: null,
      message: "Verifier reassigned",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }



  notifyinsertthird() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchangeall2 = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents deleted",
      message: "<b>" + this.verifiernamedelete + " (" + this.oldverifier + ")" + "</b>" + " " + "has deleted " + this.deletedoccount + " of your documents. Go to " + `<a href=${urlchangeall2}>Workspace Queue</a>`,
      notificationTo: this.docowner,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendthird();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  isCheckedAllUser($event) {
    this.isCheckedAllUsers = $event;
    this.isAllUserShare = $event;
  }
  isSelectedAllUser($event) {
    this.isSelectedAllUsers = $event;
  }
    sharedGroupCount($event) {
    this.shareuserCount = $event;
  }

  sharedGroup($event) {
    this.setUserGroupArr = $event;
    if (this.setUserGroupArr.length > 0 && !this.isCheckedAllUsers) {
      this.isAllUserShare = false;
    }
    if (this.setUserGroupArr.includes(this.verifierName)) {
      this.showVerifier = true;
    } else {
      this.showVerifier = false;
    }

  }
  isUserGrp($event) {
    this.isUsersGroup = $event;
    this.cd.detectChanges();
  }
  notifysendthird() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.docowner,
      role: null,
      groupName: null,
      message: "Documents deleted",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }

  notifyinsertfourth() {

    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchangeall = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents deleted",
      message: "<b>" + this.verifiernamedelete + " (" + this.oldverifier + ")" + "</b>" + " " + "has deleted " + this.deletedoccount + " of your documents. Go to " + `<a href=${urlchangeall}>Workspace Queue</a>`,
      notificationTo: this.uploaderemail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendfourth();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };


  notifysendfourth() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.uploaderemail,
      role: null,
      groupName: null,
      message: "Documents deleted",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }

  notifyinsertfivth() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchangeall = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents deleted",
      message: "<b>" + this.verifiernamedelete + " (" + this.oldverifier + ")" + "</b>" + " " + "has deleted " + this.deletedoccount + " of your documents. Go to " + `<a href=${urlchangeall}>Workspace Queue</a>`,
      notificationTo: this.uploaderemail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendfivth();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };


  notifysendfivth() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.uploaderemail,
      role: null,
      groupName: null,
      message: "Documents deleted",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }

  notifyinsertsixth() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchangeall = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents deleted",
      message: "<b>" + this.verifiernamedelete + " (" + this.oldverifier + ")" + "</b>" + " " + "has deleted " + this.deletedoccount + " of your documents. Go to " + `<a href=${urlchangeall}>Workspace Queue</a>`,
      notificationTo: this.uploaderemail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendsixth();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  hideThreeDotComp() {
    let element = document.getElementsByClassName('context-menu');
    element[0].classList.add('displayChange')
    this.threeDotComp?.hide();
    this.clearOverlayContainer();
    
}

clearOverlayContainer() {
  const overlayContainerElement = this.overlayContainer.getContainerElement();
  overlayContainerElement.innerHTML = ''; 
}


  notifysendsixth() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.uploaderemail,
      role: null,
      groupName: null,
      message: "Documents deleted",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }


  /**
       * paste non english
       */
  onPasteName(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text').replace(/ /g, "");
    var regPattern = /[\u0000-\u007A]+/g;
    if (!regPattern.test(pastedText.toString())) {
      event.preventDefault();
      return false;
    }
  }
  /**
  * remove non english Characters
  */
  removeLanguage(event) {
    var regPattern = /[\u0000-\u007A]+/g;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  alphacheck(event) {
    let tempValueDuplicate = [];
    let tempValue = [];
    var regex = /^[a-zA-Z0-9. _()\}\&@$`^,;\[\\\]\\_\{\`='"'":<>\?!|*#+\s-]*$/g
    if (event.target.value !== '') {
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')

      for (let i = 0; i <= tempValue.length - 1; i++) {
        let temp = {
          word: tempValue[i],
          ValueTarget: true
        }
        tempValueDuplicate.push(temp)
      }

      const chars = event.key;
      const char = chars;
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (key == "å") {
        // alert("Please Enter English Only");
        if (tempValue.length > 0) {
          for (let i = 0; i <= tempValue.length - 1; i++) {

            if (!regex.test(tempValue[i])) {
              tempValueDuplicate[i].ValueTarget = false
            }
          }
          tempValue = [];
          for (let i = 0; i <= tempValueDuplicate.length - 1; i++) {
            if (tempValueDuplicate[i].ValueTarget) {
              tempValue.push(tempValueDuplicate[i].word)

            }
          }
        }

        event.target.value = tempValue.join('')
        this.myDocsSearchForm.patchValue({
          docsName: event.target.value
        })
        event.preventDefault()

        return false;
      }
      else {
        tempValueDuplicate = [];
        tempValue = [];
        tempValue = event.target.value?.split('')
        for (let i = 0; i <= tempValue.length - 1; i++) {
          let temp = {
            word: tempValue[i],
            ValueTarget: true
          }
          tempValueDuplicate.push(temp)
        }
        if (!regex.test(char)) {

          // alert("Please Enter English Only");
          if (tempValue.length > 0) {
            for (let i = 0; i <= tempValue.length - 1; i++) {

              if (!regex.test(tempValue[i])) {
                tempValueDuplicate[i].ValueTarget = false
              }
            }
            tempValue = [];
            for (let i = 0; i <= tempValueDuplicate.length - 1; i++) {
              if (tempValueDuplicate[i].ValueTarget) {
                tempValue.push(tempValueDuplicate[i].word)
              }
            }

          }

          event.target.value = tempValue.join('')
          this.myDocsSearchForm.patchValue({
            docsName: event.target.value
          })
          event.preventDefault()

          return false;

        }
        else {
          return true;
        }
      }

    }
    else {
      tempValueDuplicate = [];
      tempValue = [];
      tempValue = event.target.value?.split('')
      for (let i = 0; i <= tempValue.length - 1; i++) {
        let temp = {
          word: tempValue[i],
          ValueTarget: true
        }
        tempValueDuplicate.push(temp)
      }
      const chars = event.key;
      const char = chars;
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (key == "å") {
        // alert("Please Enter English Only");
        if (tempValue.length > 0) {
          for (let i = 0; i <= tempValue.length - 1; i++) {

            if (!regex.test(tempValue[i])) {
              tempValueDuplicate[i].ValueTarget = false
            }
          }
          tempValue = [];
          for (let i = 0; i <= tempValueDuplicate.length - 1; i++) {
            if (tempValueDuplicate[i].ValueTarget) {
              tempValue.push(tempValueDuplicate[i].word)
            }
          }

        }

        event.target.value = tempValue.join('')
        this.myDocsSearchForm.patchValue({
          docsName: event.target.value
        })
        event.preventDefault()

        return false;
      }
      else {
        tempValueDuplicate = [];
        tempValue = [];
        tempValue = event.target.value?.split('')
        for (let i = 0; i <= tempValue.length - 1; i++) {
          let temp = {
            word: tempValue[i],
            ValueTarget: true
          }
          tempValueDuplicate.push(temp)
        }
        if (!regex.test(char)) {

          if (tempValue.length > 0) {
            for (let i = 0; i <= tempValue.length - 1; i++) {

              if (!regex.test(tempValue[i])) {
                tempValueDuplicate[i].ValueTarget = false
              }
            }
            tempValue = [];
            for (let i = 0; i <= tempValueDuplicate.length - 1; i++) {
              if (tempValueDuplicate[i].ValueTarget) {
                tempValue.push(tempValueDuplicate[i].word)
              }
            }

          }

          event.target.value = tempValue.join('')
          this.myDocsSearchForm.patchValue({
            docsName: event.target.value
          })

          event.preventDefault()

          return false;

        }
        else {
          return true;
        }
      }
    }

  }

  private getRoleAccessBody(docId, docguid, role) {
    return {
      wsoid: Number(this.workSpaceId),
      docID: Number(docId),
      role: role,
      database: 'get2doxmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      DocGuid: docguid
    };
  }
  flag = false;
  getClassIfDisabled() {
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = '';
    var maxToShow = 5;
    let doclistnew = '';
    this.flag = false;
    let flag = false;
    selectedRows.forEach(function (selectedRow, index) {
      if (selectedRow.documentFormat == "PDF") {
        flag = true;
      }
    });

    this.flag = flag;

    if (flag) {
      return 'colorIcon';
    }
  }
  getfilterid = "";

  outsideclick;
  tableCard = ''
  getfilterids = ''
  gridRow(data) {

    this.tableCard = data;
  }
  onRowClick(event:any){


    let indexRow = this.rowDataTemp.map((obj , index)=>{
      if(event.rowIndex !== index){
        return {
          ...obj ,
          isChecked:false,
        }

      }
      if (event.rowIndex === index) {
        let us = {
          ...obj,
          isChecked: !obj.isChecked
        };
        return us;


    }

  return obj

  })

    // this.rowData = indexRow;
  }
  @HostListener('document:click', ['$event'])
  documentClicks(event: MouseEvent) {

    if (this._eref.nativeElement.contains(event.target)) {

      if (!this.getfilterids) {
        if (this.tableCard != "clickinsideTable" && this.getTempId != 'clickedTag') {
          this?.gridApi?.deselectAll();
          this.selectedDocs = [];
          if (this.tabactdata != 'activestate') {
            this.sendetailvalue('');
          }
          else {
            this.tabactdata = '';
          }
        }
        else {
          this.tableCard = '';
        }
      }
      else {
        this.getfilterids = '';
      }
      if (!this.getfilterid) {
        if (this.filtercardclick != "clickinsidefilter") {
          this.isSelectedOnceDetails = false;
          this.isdeleteSelectedOnce = false;
          this.isSelectedOnce = false;
          this.HideViewDetailresponseForDelete = true
          this.filterBox = true;
        }
        else {
          this.filtercardclick = '';
        }
      }
      else {
        this.getfilterid = '';
      }
    }
  }
  sortChange() {
    this?.gridApi?.deselectAll();
  }
  onPaginationChanged() {
    this?.gridApi?.deselectAll();
  }
  parserguid = [];
  lastindexval;
  MyDocListparserreal(data) {
    // console.log("signha ", data);
    let getrealdocdata = data?.split('|');
    for (let i = 0; i <= this.rowData.length - 1; i++) {
      if (this.rowData[i].docGuid == getrealdocdata[0]) {
        this.rowData[i].parseStatus = getrealdocdata[2];
        this.rowData[i].running_module_code = getrealdocdata[3];
        this.cd.detectChanges();
        this.sharedService.parserreal.next(this.rowData[i]);
        this.cd.detectChanges();
      }
    }

  }

  getRowNodeId(data) {
    return data.docGuid;
  };
  MyDocListEnableRow(data) {
    console.log("data1", data);
    let getrealdocdata = data?.split('|');

    for (let i = 0; i <= this.rowData.length - 1; i++) {
      if (this.rowData[i].docGuid == getrealdocdata[0]) {
        this.rowData[i].pageCount = getrealdocdata[1];
        this.rowData[i].isReadable = getrealdocdata[2];
        var rowNode = this.gridApi.getRowNode(data?.split('|')[0]);
        rowNode.setDataValue('documentFormat', this.rowData[i].formatExtension)
        const row = rowNode.rowIndex;
        this.gridApi.redrawRows({ rowNodes: [row] });
        this.gridApi.refreshCells({
          force: true,
          rowNodes: [row]
        })
        this.cd.detectChanges();

      }
    }

  }



  /*
*delete option show in dropdown
*/
  docOwnerEmailId: any
  deleteRights: any;


  showDeleteUser() {

    if (this.selectedDocs.length > 0 && this.selectedDocs.length == 1) {
      this.docOwnerEmailId = this.selectedDocs[0]?.docOwnerEmailId;
      localStorage.setItem('docOwnerEmailId', this?.selectedDocs[0]?.docOwnerEmailId)
      /*
       *Return if user is owner of selected document
       */
      let flag = false;
      let isSharedRights = localStorage.getItem('isSharedRights');

      if ((this.selectedDocs[0].pageCount == 0) && (this.selectedDocs[0].iserror == 1)) {
        flag = false;
      }
      else if ((this.selectedDocs[0].pageCount > 0) && (this.selectedDocs[0].iserror == 0)) {
        flag = true;
      }
      else if ((this.selectedDocs[0].pageCount == 0) && (this.selectedDocs[0].iserror == 0)) {
        flag = false;
      }
      else if ((this.selectedDocs[0].pageCount > 0) && (this.selectedDocs[0].iserror == 1)) {
        flag = true;
      }
      this.datanew.showDelete = flag;

      return flag;
    }

    /*
     *for multiple docs select delete option show
     */

    if (this.selectedDocs.length > 0 && this.selectedDocs.length > 1) {
      /*
       *Return if user is owner of selected document
       */
      let flag = false;

      for (let i = 0; i <= this.selectedDocs.length - 1; i++) {
        if ((this.selectedDocs[i].pageCount == 0) && (this.selectedDocs[i].iserror == 1)) {
          flag = false;
          break;
        }
        else if ((this.selectedDocs[i].pageCount > 0) && (this.selectedDocs[i].iserror == 0)) {
          flag = true;

        }
        else if ((this.selectedDocs[i].pageCount == 0) && (this.selectedDocs[i].iserror == 0)) {
          flag = false;
          break;
        }
        else if ((this.selectedDocs[i].pageCount > 0) && (this.selectedDocs[i].iserror == 1)) {
          flag = true;

        }
      }

      return flag;

    }
  }

/**
 * Template Editor Code started from here
 */
 tagTemplate:any = [];
 templateArray = [];
  /**
 *
 * @param data
 * @param $event method  for on tag Template Edit
 */
 tagTemplateEdit($event:any) {
  this.isSubmitted = false;
  this.tagTemplateList($event.flag, $event);
}
 /**
 *
 * @param data
 * @param $event method  for tag Template List
 */
tagTemplateList(isRefresh, value?) {

  var st = window.location.toString();
  var arr = st?.split("/");
   let  postData = {

    "wsoid": Number(this.workSpaceId),
    "guid": this.createdBy
  }
  this.pendingRequest = this.data1.tagTemplateService(postData).subscribe((response: any) => {
    if (response.isSuccess == true) {
      this.tagTemplate = response.data;
      const filterOutTemp = this.tagTemplate.filter(al => al['tempName'] === 'No Template')
      const filterOutNoTemp = this.tagTemplate.filter(al => al['tempName'] !== 'No Template')
      filterOutNoTemp.sort((a, b) => a.tempName.localeCompare(b.tempName))
      this.tagTemplate = [...filterOutNoTemp, ...filterOutTemp]

      this.templateArray = response.data;
      this.defaultTemplate = response.data.find(obj => obj.isdefault);
      this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];

      if (value) {
        for (let i = 0; i <= response.data.length - 1; i++) {
          if (value.tdId == response.data[i].tdId) {
            this.defaultTemplate = response.data[i];
            if (this.selectedTemplate.tdId !== value.tdId) {
              this.selectedTemplate = response.data[i];
            }
          }
        }
      }
      else {
        this.defaultTemplate = response.data.find(obj => obj.isdefault);

        this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];

      }




      this.tagTempList = this.templateArray.slice();
      if (!isRefresh) {
        this.tagTempList.sort((a, b) => a.tempName.localeCompare(b.tempName))

        let inx = 0;
        let inxGeneral = 0;
        let inxNoTemplate = 0;
        for (let user of this.tagTemplate) {
          user.tempName;
          user['tagTemplateDropdown'] = user.tempName;
          if (user.tdId == this.docTemplateId) {
            if (!isRefresh && !this.defaultTemplate) {
              this.selectedTemplate = user;

              this.onTagTemplateId(this.selectedTemplate);
            }
          }
          if (user.tempName == 'General') {
            inxGeneral = inx;
          }
          if (user.tempName == 'No Template') {
            inxNoTemplate = inx;
          }
          inx = inx + 1;
        }
        if (this.defaultTemplate) {
          this.onTagTemplateId(this.selectedTemplate);
        }

        else {

          this.onTagTemplateId(this.selectedTemplate);
        }
      }
      else {
        this.selectedTemplate = value;

        this.onTagTemplateId(this.selectedTemplate);
      }
    }


  }, error => {
    this.errormsg();
  })
}
@BlockUI() blockUI: NgBlockUI;
 /**
 *
 * @param data
 * @param $event method  for error msg
 */
private errormsg(){
  setTimeout(() => {
    this.blockUI.stop();
  }, 1000)
  }
  /**
 *
 * @param data
 * @param $event method  for on Tag TemplateId
 */
onTagTemplateId(event) {

  this.tagTemplateId = event.tdId;

  let data = { "title": "", "description": "" };


  data['title'] = event.tempName;
  data['description'] = event.tempDescription;

  this.TemplateEditor?.patchValue(data);
  this.onGetTemplateDetails();

  this.docsVerification?.markAsDirty();
}
/**
 *
 * @param data
 * @param $event method  for getting Template Details
 */
onGetTemplateDetails() {
  if (this.tagTemplateId) {
    this.templateInput = [];
    var st = window.location.toString();
    var arr = st?.split("/");
   let postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "TempId": this.tagTemplateId
    }
    this.pendingRequest = this.data1.templateDetailsService(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        let id = 0;
        response.data.forEach(e => {
          let template = {
            'tagTempLabel': ''
          }

          if (e.tagControlId == 1) {
            template['inputType'] = 'text'
          }
          else if (e.tagControlId == 2) {
            template['inputType'] = 'date';
          }
          else if (e.tagControlId == 3) {
            template['inputType'] = 'dropdown';
          }
          template.tagTempLabel = e.tagLabel;
          if (typeof e.tagValue == undefined || e.tagValue == null || e.tagValue.trim() == "") {
            template['tagTempValue'] = '';
          }
          else if (e.tagControlId == 3) {
            let tagValue = e.tagValue?.split('||');
            tagValue.unshift('Select');
            template['tagTempValue'] = tagValue;
            template['selectedValue'] = null;
          }
          else {
            template['tagTempValue'] = e.tagValue;
          }
          template['id'] = id;
          template['required'] = e.isRequired;
          this.templateInput.push(template);
          id++;

        });
        this.templateInput.forEach((e) => {
          if (e.required) {

            return;
          }
        })


        this.editTemplateInput = this.templateInput.slice()
        for (let i = 0; i <= this.templateInput.length - 1; i++) {
          this.templateInput[i].checktagdrop = false;
          if (this.templateInput[i].inputType == 'date') {
            if (this.templateInput[i].tagTempValue === "Invalid date NaN,NaN") {
              this.templateInput[i].tagTempValue = ""
            }
            if (this.templateInput[i].tagTempValue && this.templateInput[i].tagTempValue !== "Invalid date NaN,NaN") {
              this.templateInput[i].tagTempValue = new Date(this.templateInput[i].tagTempValue);
            }

          }
          if (this.templateInput[i].inputType == 'text') {
            this.templateInput[i].errormsg = "";
            this.templatechange(this.templateInput[i]);
          }
        }

      }

    }, error => {
      this.errormsg();

    })
  }

}
/**
 *
 * @param data
 * @param $event method  for saving template change
 */
templatechange(data) {

  let templatedata = {
    wsoid: JSON.parse(this.workSpaceId),
    tagLabel: data.tagTempLabel
  }
  this.pendingRequest = this.data1.templatechange(templatedata).subscribe((response: any) => {

    if (response.isSuccess) {
      if (response.data.length > 0) {

        let temdata = {
          createdDtm: null,
          tagValue: data.tagTempValue
        }
        response.data.push(temdata);


        const key = 'tagValue';

        this.arrayUniqueByKey = [...new Map(response.data.map(item =>
          [item[key], item])).values()];

        for (let i = 0; i <= this.templateInput.length - 1; i++) {
          if (this.templateInput[i].id == data.id) {
            this.templateInput[i].tagvaldata = temdata.tagValue;
            this.templateInput[i].existingtagvalue = this.arrayUniqueByKey;
            this.templateInput[i].checktagdrop = true;
            this.templateInput[i]['errormsg'] = "";
          }
        }
      }
    }
  })
}

/**
 *
 * @param data
 * @param $event method  for saving template save
 */
saveDocDetails(flagValue) {
  this.isSubmitted = true;
  if (!this.docsVerification.valid) {
    this.saveeditedvalue = false;
    return false;
  }

  if (this.isEdit) {
    let filteredArrayEdit = this.docTagList.filter((e) => {
      return e.tagValue == ''
    })
    if (filteredArrayEdit.length > 0) {
      this.saveeditedvalue = false;
      return;
    }
  } else {
    let filteredArray = this.templateInput.filter((e) => {
      return e.required && e.tagTempValue == ''
    })
    if (filteredArray.length > 0) {

      return;
    }
  }
  var st = window.location.toString();
  var arr = st?.split("/");
  let postData: any;

  let doctagArray = [];
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "WsLink": arr[2],
    "DocID": Number(this.docId),
    "TempID": Number(this.tagTemplateId),
    "DocRename": this.docModifiedEditedName,
    "DocOwner": this.documentOwnerEmail,
    "IsAllUserShare": this.isCheckedAllUsers === false && this.isAllUserShare === false ? false : true,
    "Flag": flagValue,
    "ModifiedBy": this.createdBy,
    "docTags": doctagArray,
    "ownerEmailId": this.documentOwnerEmail,
    "ownerName": this.documentOwnerName,
    "oldOwnerEmailId": this.ownerEmailID,

    /*
    *adding modified by display name in the key of oldOwnerName
    */
    "oldOwnerName": this.WsdisplayName,
    "Notes": this.docsVerification.value.textNote,
    "WsName": this.wsName,
    "guid": this.getguid,
    "DocGuid": this.docDetails[0].docGuid
  }
  if (flagValue === 'verify') {

    let filteredData = this.templateInput.filter((e) => {
      return e.tagTempValue != '';
    });

    filteredData.forEach((e) => {
      let template = {
        "tempId": this.tagTemplateId,
        "tagLabel": "",
        "tagValue": "",
        "createdBy": this.createdBy,
        "tagcontrolid": "",
        "optionvalue": ""
      }
      if (e.inputType === "text") {
        template.tagLabel = e.tagTempLabel;
        template.tagValue = e.tagTempValue;
        template.tagcontrolid = '1';
        doctagArray.push(template);
      } else if (e.inputType === "dropdown") {
        if (e.selectedValue != '' || e.selectedValue != null) {
          template.tagLabel = e.tagTempLabel;
          template.tagValue = e.selectedValue;
          template.tagcontrolid = '3';
          if (e.tagTempLabel && e.selectedValue) {
            if (e.selectedValue != 'Select') {
              template.optionvalue = e.tagTempValue.join('||');
              doctagArray.push(template);
            }
          }
        }
      } else if (e.inputType === "date") {
        if (e.tagTempValue != "Invalid date NaN,NaN") {
          template.tagLabel = e.tagTempLabel;
          let changedateformat = moment(e.tagTempValue).format('YYYY-MM-DD');
          template.tagValue = changedateformat;
          template.tagcontrolid = '2';
          doctagArray.push(template);
        }

      }
    })
    for (let e of this.customform.controls) {
      let a = e as FormGroup;
      let template = {
        "tagLabel": "",
        "tagValue": "",
        "tagcontrolid": "",
        "createdBy": this.createdBy,

      }
      template.tagLabel = a.value.label;
      if (a.value.tagcontrolid == 2) {
        let changedateformat = moment(a.value.value).format('YYYY-MM-DD');
        template.tagValue = changedateformat;
      }
      else {
        template.tagValue = a.value.value;
      }

      template.tagcontrolid = a.value.tagcontrolid;

      doctagArray.push(template);
    }
  }
  if (flagValue === 'save') {
    this.saveeditedvalue = true;
    this.exitButtonDisable = true;
    let filteredData = this.docTagList.filter((e) => {
      return e.tagTempValue != '';
    });

    filteredData.forEach((e) => {
      let template = {
        "tempId": e.tempID,
        "tagLabel": "",
        "tagValue": "",
        "createdBy": this.createdBy,
        "tagcontrolid": "",
        "optionvalue": ""
      }
      if (e.tagControlId === "1") {
        template.tagLabel = e.tagLabel;
        template.tagValue = e.tagValue;
        template.tagcontrolid = '1';
        doctagArray.push(template);
      } else if (e.tagControlId === "3") {
        template.tagLabel = e.tagLabel;
        template.tagValue = e.tagValue;
        template.tagcontrolid = '3';
        template.optionvalue = e.optionValue;
        doctagArray.push(template);
      } else if (e.tagControlId === "2") {
        template.tagLabel = e.tagLabel;

        let changedateformat = moment(e.tagValue).format('YYYY-MM-DD');
        template.tagValue = changedateformat;

        template.tagcontrolid = '2';
        doctagArray.push(template);
      }
    })

    for (let e of this.customform.controls) {
      let a = e as FormGroup;
      let template = {
        "tagLabel": "",
        "tagValue": "",
        "tagcontrolid": "",
        "createdBy": this.createdBy,

      }
      template.tagLabel = a.value.label;
      if (a.value.tagcontrolid == 2) {
        let changedateformat = moment(a.value.value).format('YYYY-MM-DD');
        template.tagValue = changedateformat;
      }
      else {
        template.tagValue = a.value.value;
      }

      template.tagcontrolid = a.value.tagcontrolid;

      doctagArray.push(template);
    }
  }
  this.showSpinnerVerify = true;
  this.saveeditedvalue = true;

}
/**
 * variable for verify select option
 */
verifyselectoption = false;
/**
 *
 * @param data
 * @param $event method  for tag editing
 */
isTagsEdited($event, data) {
  this.isDocEdited = true;
  if ($event) {
    if (this.templateInput.length > 0) {
      for (let i in this.templateInput) {
        if (this.templateInput[i].inputType == "date") {
          if (data) {
            if (this.templateInput[i].id == data.id) {
              this.templateInput[i].datetypetag = "datechange";

            }
          }

        }

        if (this.templateInput[i].inputType == "text") {
          if (this.templateInput[i].id == data.id) {
            if (this.templateInput[i].tagTempValue == '') {
              this.templateInput[i].textcheck = "";
              this.cd.detectChanges();
            }
            else {
              this.cd.detectChanges();
              this.templateInput[i].textcheck = "dropdownchange";
              this.cd.detectChanges();
            }

          }
        }

      }
    }

  }
}
/**
 *
 * @param data
 * @param $event method  for custom dropdown tag
 */
customedropdowntag(data, id) {
  for (let i = 0; i <= this.templateInput.length - 1; i++) {
    if (this.templateInput[i].id == id) {
      this.templateInput[i].tagTempValue = data;
    }
  }
  for (let i in this.templateInput) {
    if (this.templateInput[i]['tagvaldata'] == "") {
      this.templateInput[i]['errormsg'] = "Error";
    }
    else {
      this.templateInput[i]['errormsg'] = "";
    }
  }

}
/**
 *
 * @param data
 * @param $event method  for select dropdown
 */
selectdropdown(data, $event, value) {
  if ($event) {
    if (this.templateInput.length > 0) {
      for (let i in this.templateInput) {
        if (this.templateInput[i].inputType == "dropdown") {
          if (this.templateInput[i].id == data.id) {
            if (this.templateInput[i].selectedValue == 'Select') {
              this.templateInput[i].dropdowncheck = "dropdownchange";
              this.cd.detectChanges();
            }
            else {
              this.templateInput[i].dropdowncheck = "dropdownchange";
              this.cd.detectChanges();
            }

          }
        }
      }
    }

  }
}
/**
 * array for template list
 */
docTagList = []
/**
 *
 * @param data
 * @param $event method  for changing template editor
 */
templatechangeedit(data) {

  let templatedata = {
    wsoid: JSON.parse(this.workSpaceId),
    tagLabel: data.tagLabel
  }

  this.pendingRequest = this.data1.templatechange(templatedata).subscribe((response: any) => {

    if (response.isSuccess) {
      if (response.data.length > 0) {

        let temdata = {
          createdDtm: null,
          tagValue: data.tagValue
        }
        response.data.push(temdata);
        const key = 'tagValue';

        this.arrayUniqueByKey = [...new Map(response.data.map(item =>
          [item[key], item])).values()];

        for (let i = 0; i <= this.docTagList.length - 1; i++) {
          if (this.docTagList[i].id == data.id) {
            this.docTagList[i].tagvaldata = temdata.tagValue;
            this.docTagList[i].existingtagvalue = this.arrayUniqueByKey;
            this.docTagList[i].checktagdrop = true;
          }
        }
      }
    }
  })
}
/**
 *
 * @param data
 * @param $event method to select text for template editor
 */
selecttext(data, $event) {
  if ($event) {

    if (this.templateInput.length > 0) {
      for (let i in this.templateInput) {
        if (this.templateInput[i].inputType == "text") {
          if (this.templateInput[i].id == data.id) {
            if (this.templateInput[i].tagTempValue == '') {
              this.templateInput[i].textcheck = "";
              this.cd.detectChanges();
            }
            else {
              this.cd.detectChanges();
              this.templateInput[i].textcheck = "dropdownchange";
              this.cd.detectChanges();
            }

          }
        }
      }
    }

  }
}
onBtPrevious(){
  if(this.currentPage != 1){
    this.currentPage=this.currentPage-1
    this.MyDocList('ngOnInit', '');
  }

}
onBtNext(){
  if(this.currentPage != this.totalPages){
    this.currentPage=this.currentPage+1
    this.MyDocList('ngOnInit', '');
  }

  }
  onBtFirst(){
    this.currentPage=1
    this.MyDocList('ngOnInit', '');
  }
  onBtLast(){
this.currentPage=this.totalPages
this.flag1=true
this.MyDocList('ngOnInit', '');
}
  currentPages:Number=1
  getThreeDotValidation(event?){
    let shareRight = this.shareAccessValidate();
    localStorage.setItem("shareRightValidation" , String(shareRight))
    let tagsRight = this.shareAccessTags();
    localStorage.setItem("sharetagsRightValidation" , String(tagsRight))
    console.log(localStorage.getItem('shareRightValidation'));
  }

  shareAccessValidate(){
    let flag=false
       /*
     *for single docs select delete option show
     */
    if(this.selectedDocs.length> 0 && this.selectedDocs.length == 1){
       flag = true
    }
        /*
     *for multiple docs select delete option show
     */
    else if(this.selectedDocs.length > 1){
    let bulkShareRight = localStorage.getItem('bulkShareing');
    bulkShareRight == 'True' ? flag=true:flag=false
    }
    return flag
      /*
     *for multiple docs select delete option show
     */
  }
  shareAccessTags(){
    let flag=false
             /*
     *for Single docs select delete option show
     */
    if(this.selectedDocs.length> 0 && this.selectedDocs.length == 1){
       flag = true
    }
          /*
     *for multiple docs select delete option show
     */
    else if(this.selectedDocs.length > 1){
      let bulkTags = localStorage.getItem('bulkTags');
      bulkTags == 'True' ? flag=true:flag=false
    }
    return flag

  }
  isDownloadModalOperationInProgress=false;
disabledownload=()=>{
  this.isDownloadModalOperationInProgress=true;
  setTimeout(() => {
    this.isDownloadModalOperationInProgress=false;
  }, 700);
}
}
