import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './users.service';
import { OrdersChartService } from './orders-chart.service';
import { ProfitChartService } from './profit-chart.service';
import { DocChartService } from './doc-chart.service';
import {SharedChartService} from './shared-chart.service';
import { PeriodsService } from './periods.service';
import { OrdersProfitChartService } from './orders-profit-chart.service';
import { ProfitBarAnimationChartService } from './profit-bar-animation-chart.service';

const SERVICES = [
  UserService,
  OrdersChartService,
  ProfitChartService,
  DocChartService,
  SharedChartService,
  PeriodsService,
  OrdersProfitChartService,
  ProfitBarAnimationChartService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders<MockDataModule> {
    return {
      ngModule: MockDataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
