 /**
      *  shared chart
      */
export interface SharedChart {
  /**
      * chartLabel for the shared chart
      */
  chartLabel: string[];
  /**
      * SharedData for the shared chart
      */
  SharedData: number[][];
}
 /**
      * sharedChartData
      */
export abstract class SharedChartData {
   /**
      * method for getSharedChartData
      */
  abstract getSharedChartData(period: string): SharedChart;
}
