import { ModuleWithProviders, NgModule,  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbSpinnerModule,
  NbIconModule,
  NbRadioModule,
  NbThemeModule, NbCardModule, NbListModule, NbTabsetModule, NbInputModule, NbFormFieldModule, NbTooltipModule, NbToggleModule, NbBadgeModule, NbDatepickerModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { FormsModule } from '@angular/forms';

import {
  FooterComponent,
  HeaderComponent,

} from './components';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './components/header/change-password/change-password-header.component';
import { NotificationsComponent } from './components/header/notifications/notifications.component';
import { PassStrengthComponent } from './components/header/pass-strength/pass-strength.component';
import {SharedModule } from '../shared/shared.module'
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AppAutofocusDirective } from './directives/app-autofocus.directive';
import { AppBlockCopyPasteDirective } from '../app-block-copy-paste.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SupportComponent } from './components/header/support/support.component';
import { FeatureFlagDirectiveModule } from './directives/feature-flag-directive.module';
import { ClickOutsideDirectiveModule } from './directives/click-outside-directive.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SortDirectiveModule } from './directives/sort-directive.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EllipsifyMeDirectiveModule } from './directives/ellipsify-directive.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';
import { DoubleClickDirectiveModule } from './directives/double-click-directive.module';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbToggleModule,
  NbDatepickerModule,
  NbSpinnerModule,
 NgxSkeletonLoaderModule,
  BsDropdownModule.forRoot(),
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,

];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, 
    NbCardModule,
    NbListModule,
    FormsModule,
    NbEvaIconsModule,
    NbIconModule,
    NbTabsetModule,
    NbInputModule,
    NbFormFieldModule,
    NbTooltipModule,
    ReactiveFormsModule,
    NbBadgeModule,
    SharedModule,
    NgSelectModule,
    FeatureFlagDirectiveModule,
    DoubleClickDirectiveModule,
    ClickOutsideDirectiveModule,
    EllipsifyMeDirectiveModule,
    SortDirectiveModule,
    InfiniteScrollModule,
    ImageCropperModule,
    NbRadioModule,
    NbDatepickerModule.forRoot(),
    RouterModule


  ],
  exports: [CommonModule, ...COMPONENTS,SupportComponent,ChangePasswordComponent,HeaderComponent],
  declarations: [...COMPONENTS, ChangePasswordComponent, NotificationsComponent, PassStrengthComponent, AppAutofocusDirective, AppBlockCopyPasteDirective, SupportComponent],
}) 
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME],
        ).providers,
      ],
    };
  }
}
