import { VerifyDocumentService } from './../../@core/verify-document.service';
import { Component, OnInit,ViewChild,TemplateRef,Output,EventEmitter,Input,ElementRef, ChangeDetectorRef, HostListener} from '@angular/core';
import {NbDialogRef,NbDialogService,} from "@nebular/theme";
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import {SharedService} from '../../../app/@core/utils/shared.service'
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';

import moment from 'moment';

@Component({
  selector: 'ngx-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit {
  @ViewChild('templateEditor') temeditir!: ElementRef<any>;
  @ViewChild('template') templateModal: TemplateRef<any>;
  @ViewChild('unhandleChanges') unhandleChanges: TemplateRef<any>;
  @Output() tagTemplate = new EventEmitter<any>();
  @Input() selectedTemplate;
  @Input() selectedTemplateVerify;
  @Input() templateInput;
  _tagTempList=[];
  selectedTemplates: any;
  isCreateNew = false;
  saveBtnClicked: boolean;
  saveBtnClicked1: boolean;
  getValue: boolean;
  checkEscValue: boolean;
  firstEntered: any;
  getIconColor: boolean =false;
  template:any;
  @Input()
  set tagTempList(tagTempList: any) {
    this._tagTempList = tagTempList;
  }
  public items = [
    { title: "delete", icon: "trash-2-outline" }]

  public tags = [
      { title: "text boxer", icon: "file-outline" },
      { title: "date picker", icon: "calendar-outline" },
      { title: "drop-down", icon: "download-outline" }
    ]
  isSavedPressed=false;
  get tagTempList() { return this._tagTempList; }
  loading = false;
  workSpaceId: string;
  createdBy: string;
  showText = false;
  docId = '';
  editTempId: number;
  AddOptionDisabled: boolean = false
  editTemplateInput = [];
  showErrorMessage = false;
  isSubmitted = null;
  DocGuid = '';
  showTempEditor: boolean = false;
  isSubmitPressed = false;
  docGuid = '';
  VisibUnvisib: string = "visible";
  DeleteRow: string = "hidden"
  DeleteDropDown: string = "hidden"
  showOverlaySpinner = true;
  @BlockUI() blockUI: NgBlockUI;
  myDocs = false;
  id: number;
  pendingRequest: any;
  errorMessage = '';
  private dialogRef: NbDialogRef<any>;

  constructor(
     private dialogService: NbDialogService,
     private activatedRoute: ActivatedRoute,
     private data: VerifyDocumentService,
     private httpClient: HttpClient,
     private router: Router,
     private titleService: Title,
     private fb: FormBuilder,
     private http: HttpClient,
     private cd: ChangeDetectorRef,
     private sharedService:SharedService
  ) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')

    if (!this.createdBy) {
      this.router.navigateByUrl('/sign-in'); }
      this.activatedRoute.queryParams.subscribe((params) => {
        this.docId = params['docId'];
        this.docGuid = params['docGuid'];
        let component = localStorage.getItem('redirectedFrom')
        if (component == 'my-docs') {
          this.myDocs = true;
        }

      })
    }

  ngOnInit(): void {

    this.sharedService.Template.subscribe((response)=>{
      this.template=response;
      if(!this._tagTempList){
        this._tagTempList =this.template
      }
    })

    let template = { tagTempLabel: "", inputType: "text", tagTempValue: "", id: undefined }
    this.editTemplateInput.push(template);

  }

  public openTemplateModal()
  {
    this.handleUnsavedChanges(this._tagTempList[0],this.unhandleChanges)
    this.dialogRef = this.dialogService.open(this.templateModal, {
            closeOnBackdropClick: false,
            closeOnEsc: false,

    });

  }
  public openTemplateModal1()
  {
    this.handleUnsavedChanges(this.template[0],this.unhandleChanges, this.template)
    this.dialogRef = this.dialogService.open(this.templateModal, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
    });
  }

  verifyDeleteTemplate(template: TemplateRef<any>) {
    this.showOverlaySpinner = false;

    this.showText = false;
    var st = window.location.toString();
    var arr = st?.split("/");
    this.loading = true;
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "TempId": Number(this.deleteTempId),
      "ModifiedBy": this.createdBy
    }
    this.blockUI.start('Loading...');
    setTimeout(() => {
      this.loading = false;
      this.showText = true;
    }, 1000);
    this.pendingRequest = this.data.deleteTemplateService(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        let selected;
        let hasDeletedAll: boolean = false;
        const length = this.tagTempList.length;

        this.tagTempList.forEach(function (item, i) {
          if (item.tdId === postData.TempId) {
            if (i > 0) {
              selected = i - 1;
            } else if (i == 0 && length === 1) {
              hasDeletedAll = true;
            } else {

              selected = i + 1;
            }
          }
        });
        this.tagTemplate.emit(true);
        if (hasDeletedAll) {
          this.createNewTemplate();
          this.TemplateEditor.markAsPristine();
        }
        else if (this.selectedTemplateVerify.tagTemplateDropdown === 'No Template'){
          this.selectedTemplates = 'No Template';
          this.onGetEditTemplateDetails(this.selectedTemplateVerify);
          this.cd.detectChanges();

        }
        else {
          this.selectedTemplates = this.tagTempList[selected];
          this.onGetEditTemplateDetails(this.tagTempList[selected]);
          this.cd.detectChanges();
        }
      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000)
    })
  }
  modalDeleteRef: any;

  deleteTempId = '';
  openDeleteModal(dialog: TemplateRef<any>, tagTemplate) {

    this.deleteTempId = tagTemplate.tdId;
    this.isSubmitted = true;
    this.modalDeleteRef = this.dialogService.open(dialog,
      {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      }
    );
  }


  public saveTemplate(closeFlag,openNewTemplate:boolean) {
    if (!this.TemplateEditor.valid || this.isNotValid()) {
      return
    }
    let flag = false;
    /**
     * create new template
     */
    if(this.isCreateNew){
      this.newTemplateCreated(openNewTemplate,closeFlag);
      this.firstEntered = true
    } else{
      if(this.editTempId === 0){
        this._tagTempList.forEach(element => {
          if (element.tempName === this.selectedTemplates.tempName){
            this.editTempId = element.tdId;
          }
        });
      }
        this.newTemplateCreated(openNewTemplate,closeFlag);
    }
  if(this.firstEntered)
    this.firstEntered = false;
    this.showErrorMessage = false;
    flag = false;
  }

  newTemplateCreated(openNewTemplate:any,closeFlag:any){
    /**
     * create new template
     */
    let doctagArray = [];
    let postData: any;

    if(this.clickoncreate !== 'createNew'){
      this.isSubmitPressed = true;
    }
    let flag = false;
    this.saveTemplatecondition(doctagArray);
    postData = {
      "wsoid": Number(this.workSpaceId),
      "tempId": this.editTempId,
      "tempName": this.TemplateEditor.get('title').value,
      "tempDescription": this.TemplateEditor.get('description').value,
      "createdBy": this.createdBy,
      "tags": doctagArray
    }
    this.blockUI.start('Loading...');
    if (flag || !this.TemplateEditor.valid) {
      return;
    }
    this.pendingRequest = this.data.saveTemplateEditorService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.showErrorMessage = false;
        if (response.statusCode == 'C200') {
          this.isSavedPressed = true;
          setTimeout(() => {
          this.isSavedPressed = false;
          }, 5000);
          this.isCreateNew = false;
          this.selectedTemplates = response.request;
          this.cd.detectChanges();
          let res = {flag: false, tdId:this.editTempId, tempName: this.TemplateEditor.get('title').value, tempDescription: this.TemplateEditor.get('description').value};
          this.tagTemplate.emit(res);
          this.TemplateEditor.markAsPristine();
        }
         this.saveTemplateifCondtion(openNewTemplate,closeFlag);
      }
      else if (!response.isSuccess) {
        if (response.statusCode == "C409") {
          this.showErrorMessage = true
          this.errorMessage = 'That template title is taken, choose another one';
        }
      }
    })

  }
     private saveTemplateifCondtion(openNewTemplate:any,closeFlag:any){
      if (openNewTemplate) {
        this.TemplateEditor.markAsPristine();

      } else if (
        closeFlag &&
        this.modalunhandleRef.content &&
        this.modalunhandleRef.content.closeAfterDiscard
      ) {
        this.TemplateEditor.reset();
        this.dialogRef.close();
      } else if (this.modalunhandleRef.content) {
        if (this.modalunhandleRef.content.selectedTemp) {
          this.selectedTemplate = this.modalunhandleRef.content.selectedTemp;
          this.onGetEditTemplateDetails(
            this.modalunhandleRef.content.selectedTemp
          );
        } else {
          this.createNewTemplate();

            this.TemplateEditor.markAsPristine();

        }
      }
    }
    private saveTemplatecondition(doctagArray:any){
      this.editTemplateInput.forEach((e) => {
        let template = {
          "tempId": this.editTempId,
          "tagLabel": "",
          "tagValue": "",
          "createdBy": this.createdBy,
          "isRequired": e.isRequired,
          "tagControlID": e.tagControlID
        }

        if (e.inputType != "dropdown") {
          template.tagLabel = e.tagTempLabel;
          template.tagValue = e.tagTempValue;
          if(e.inputType != 'date'){
            if (e.tagTempValue.trim() == '') {
            }
          }

          if (!template.tagControlID) {
            if (e.inputType == 'date') {
              template['tagControlId'] = 2;
              let changevalueformat = new Date(template['tagValue']);

              let newdatechange = this.getFormattedDate(changevalueformat);
              template['tagValue'] = newdatechange;
            }
            if (e.inputType == 'text') {
              template['tagControlId'] = 1;
            }
          }

          doctagArray.push(template);
        } else {
          template.tagLabel = e.tagTempLabel;
          template.tagValue = e.options.join('||');
          template.tagControlID = 3;
          doctagArray.push(template);
        }

      })
    }
  modalunhandleRef: any;
  clickoncreate = '';
  openUnhandledModal(dialog: TemplateRef<any>) {
    if (this.TemplateEditor.pristine ) {
      this.isSubmitPressed = false;
      this.createNewTemplate('createNew');
      this.TemplateEditor.markAsPristine();
    }
    else {

      const initialState = {
        closeAfterDiscard: false,
        openNewTemplate:true,
        selectedTemp:null
      };

      this.modalunhandleRef = this.dialogService.open(dialog,
        {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        }
      );
      this.clickoncreate = 'createNew'
      this.modalunhandleRef.content = initialState;
  }

  }
  clickValue(){
    this.saveBtnClicked1 = true;
  }
  createUnhandledModal(dialog: TemplateRef<any>) {
    this.getIconColor = false;
    this.sharedService.modalClose.next(this.getIconColor);
    if (this.TemplateEditor.pristine) {
      this.TemplateEditor.reset();
      this.dialogRef.close();

    } else {
      const initialState = {
        closeAfterDiscard: true,
      };

      this.modalunhandleRef = this.dialogService.open(dialog, {
        closeOnBackdropClick: false,
        closeOnEsc: false,

      });
      this.modalunhandleRef.content = initialState;
    }
  }
  discardTempChanges() {

    this.TemplateEditor.markAsPristine();
    this.modalunhandleRef.close();
    if (
      this.modalunhandleRef.content &&
      this.modalunhandleRef.content.closeAfterDiscard
    ) {
      this.TemplateEditor.reset();
      this.dialogRef.close();
    }else if (this.modalunhandleRef.content) {
      if (this.modalunhandleRef.content.selectedTemp) {
        this.selectedTemplate = this.modalunhandleRef.content.selectedTemp;
        this.onGetEditTemplateDetails(
          this.modalunhandleRef.content.selectedTemp
        );
      } else {
        this.createNewTemplate();
        this.TemplateEditor.markAsPristine();
      }
    } else {
      this.onGetEditTemplateDetails(this.selectedTemplate);
    }
  }



  TemplateEditor = this.fb.group({
    title: ["", Validators.required],
    description: [""],
  })

  createNewTemplate(value?) {
    if(value === 'createNew'){
      this.isCreateNew = true;
    }
    this.editTempId = 0;
    this.id = 0;
    this.TemplateEditor.reset();
    this.editTemplateInput = [];
    let template = { tagTempLabel: "", inputType: "text", tagTempValue: "", id: undefined }
      this.editTemplateInput.push(template)
  }

  isNotValid() {
    let flag = false;
    if(this.editTemplateInput.length === 0){
      flag = true;
    }
    this.editTemplateInput.forEach((e) => {
      if (e.inputType != 'dropdown') {
        if (e.tagTempLabel.trim() == '') {
          flag = true;
        }
      } else {
        if (e.options) {
          let tagValue = e.options.join('||');
          if (e.tagTempLabel.trim() == '' || tagValue.trim() == '') {
            flag = true;
          }
          for(let i=0; i<=e.options.length-1;i++){
            if(e.options[i] == null || e.options[i] == ""){
              flag = true;
            }
          }

        }
      }
    });
    return flag;
  }

  removeEditTemplateInput(id) {

    if (document.querySelector("#TemplateEditorCustomForm").children.length < 3) {
      this.DeleteRow = "hidden";
      this.saveBtnClicked = true;
    }

    this.editTemplateInput.splice(id, 1);
    this.TemplateEditor.markAsDirty()
  }

  AddOption(inputBox) {
    this.getValue = true;

    this.editTemplateInput[inputBox].options.push('');

    if (this.editTemplateInput[inputBox].options.length > 19) {
      this.AddOptionDisabled = true
    }

    setTimeout(() => {
      var x = document.getElementById("TemplateEditorCustomForm").children[inputBox].children[0].children[0].children[2].children.length - 2
      if (document.getElementById("TemplateEditorCustomForm").children[inputBox].children[0].children[0].children[2].children.length > 1) {
        document.getElementById("TemplateEditorCustomForm").children[inputBox].children[0].children[0].children[2].children[x].children[2].classList.remove("d-none")
        document.getElementById("TemplateEditorCustomForm").children[inputBox].children[0].children[0].children[2].children[x - 1].children[2].classList.remove("d-none")
      }
    }, 15)
  }
  deleteOption(temp, i) {
    if (this.editTemplateInput[temp].options.length < 21) {
      this.AddOptionDisabled = false;
    }

    var x = document.getElementById("TemplateEditorCustomForm").children[temp].children[0].children[0].children[2].children.length - 2
    if (x < 2) {
      document.getElementById("TemplateEditorCustomForm").children[temp].children[0].children[0].children[2].children[x].children[2].classList.add("d-none")
      document.getElementById("TemplateEditorCustomForm").children[temp].children[0].children[0].children[2].children[x - 1].children[2].classList.add("d-none")

    }
    setTimeout(() => {
      this.editTemplateInput[temp].options.splice(i, 1);
    }, 15)
  }
  trackByIdx(index: number, obj: any): any {
    return index;
  }
  addEditTemplateControl(e) {
   this.isSubmitPressed = false;
   this.TemplateEditor.markAsDirty()

    if (document.querySelector("#TemplateEditorCustomForm").children.length > 0) {
      this.DeleteRow = "visible";
    }

    if (!this.id) {
      this.id = 0;
    }
    let template = {
      'tagTempLabel': ''
    }

    if (e == 1) {
      template['inputType'] = 'text'
    }
    else if (e == 2) {
      template['inputType'] = 'date';
    }
    else if (e == 3) {

      template['inputType'] = 'dropdown';
      template['options'] = [''];
    }
    if (typeof e.tagValue == undefined || e.tagValue == null || e.tagValue.trim() == "") {
      template['tagTempValue'] =''

    }
    else if (e == 3) {
      template['selectedValue'] = '';
      template['options'] = [''];
    }

    template['id'] = this.editTempId;
    this.id++;
    this.editTemplateInput.push(template);
  }

  getFormattedDate(date) {
    var year = date.getFullYear().toString();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    let monthchange = moment(month).format("MMM");
    return monthchange + ' ' + day + ',' + year;
  }
  onInputChange() {
    this.showErrorMessage = false;
  }

  openModal(dialog: TemplateRef<any>) {

    this.showErrorMessage = false;
    this.isSavedPressed = false;
    this.isSubmitPressed = false;

    if (
      this.selectedTemplateVerify && this.selectedTemplateVerify.tempName === 'No Template' &&
      this.tagTempList.length > 0
    ) {
      if(this.tagTempList.length == 1){
        this.selectedTemplate=this.tagTempList[0]
        this.onGetEditTemplateDetails(this.tagTempList[0]);
      }
      else{
        const filterdata1 = this.tagTempList.filter(i => i.tempName != 'No Template')
        let valuedata3 = '';
        if(filterdata1.length > 0){
          valuedata3 = filterdata1[0]
        }
        this.selectedTemplate= valuedata3;
        this.onGetEditTemplateDetails(this.selectedTemplate);
      }

    } else {
      this.onGetEditTemplateDetails(this.selectedTemplate);
    }


    this.TemplateEditor.markAsPristine();

    this.dialogRef = this.dialogService.open(dialog, {
      closeOnBackdropClick: false,
            closeOnEsc: false,
            dialogClass: 'myclass'
    });

    this.showOverlaySpinner = false;
    this.showTempEditor = false;
    this.loading = true;
    this.VisibUnvisib = 'hidden';
    this.blockUI.start('Loading...');
    setTimeout(() => {
      this.VisibUnvisib = 'visible';
      this.loading = false;
      this.showTempEditor = true;
    }, 1000);
  }

  public handleUnsavedChanges(template,modifiedTemplate: TemplateRef<any> , temp?:any): void {
    if (this.TemplateEditor.pristine) {
      this.selectedTemplate=template
      this.onGetEditTemplateDetails(template, temp);
    } else {
      const initialState = {
        selectedTemp: template,
      };

      this.modalunhandleRef = this.dialogService.open(modifiedTemplate, {
        closeOnBackdropClick: false,
        closeOnEsc: false,

      });

      this.modalunhandleRef.content = initialState;
    }

  }

  onGetEditTemplateDetails(template,temps?:any) {
    this.showErrorMessage = false;
if(template?.tdId){
    this.editTemplateInput = [];
    var st = window.location.toString();
    var arr = st?.split("/");

    let data = { "title": "", "description": "" };


    data['title'] = template.tempName;
    data['description'] = template.tempDescription;


    this.TemplateEditor.patchValue(data);
    this.TemplateEditor.markAsPristine();
    let postData: any;
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "TempId": template.tdId
    }
    this.editTempId = template.tdId;
    this.blockUI.start('Loading...');
    let temp =temps
    this.pendingRequest = this.data.templateDetailsService(postData).subscribe((response: any) => {
      if(temp==undefined){
        temp=this._tagTempList
       }
    this._tagTempList = temp;
      if (response.isSuccess ) {
        this.id = 0;
        if(response.data.length == 1){
          if(response.data[0].tempName == "No Template"){
            this.openUnhandledModal(this.unhandleChanges);
          }
          else{
            response.data.forEach(e => {
              let template = {
                'tagTempLabel': '',
                'tagControlId': e.tagControlId,
                'isRequired': true
              }

              if (e.tagControlId == 1) {
                template['inputType'] = 'text'
              }
              else if (e.tagControlId == 2) {
                template['inputType'] = 'date';
              }
              else if (e.tagControlId == 3) {
                template['inputType'] = 'dropdown';
                let tagValue = [];
                if (e.tagValue) {
                  tagValue = e.tagValue?.split(',');
                }

                template['options'] = tagValue;
              }
              template.tagTempLabel = e.tagLabel;
              if (typeof e.tagValue == undefined || e.tagValue == null || e.tagValue.trim() == "") {
                template['tagTempValue'] = ''
              }
              else if (e.tagControlId == 3) {

                let tagValue = [];
                if (e.tagValue) {
                  tagValue = e.tagValue?.split(',');
                }

                template['tagTempValue'] = tagValue;
                template['selectedValue'] = '';
                template['options'] = tagValue;
              }
              else {
                template['tagTempValue'] = e.tagValue;
              }
              template['id'] = this.id;
              template['isRequired'] = e.isRequired;
              this.editTemplateInput.push(template);
              this.id++;

            });
          }
        }
        else if(response.data.length == 0){

            this.openUnhandledModal(this.unhandleChanges);

        }
        else{
          response.data.forEach(e => {
            let template = {
              'tagTempLabel': '',
              'tagControlId': e.tagControlId,
              'isRequired': true
            }

            if (e.tagControlId == 1) {
              template['inputType'] = 'text'
            }
            else if (e.tagControlId == 2) {
              template['inputType'] = 'date';
            }
            else if (e.tagControlId == 3) {
              template['inputType'] = 'dropdown';
              let tagValue = [];
              if (e.tagValue) {
                tagValue = e.tagValue?.split(',');
              }

              template['options'] = tagValue;
            }
            template.tagTempLabel = e.tagLabel;
            if (typeof e.tagValue == undefined || e.tagValue == null || e.tagValue.trim() == "") {
              template['tagTempValue'] =''
            }
            else if (e.tagControlId == 3) {

              let tagValue = [];
              if (e.tagValue) {
                tagValue = e.tagValue?.split('||');
              }

              template['tagTempValue'] = tagValue;
              template['selectedValue'] = '';
              template['options'] = tagValue;
            }
            else {
              template['tagTempValue'] = e.tagValue;
            }
            template['id'] = this.id;
            template['isRequired'] = e.isRequired;
            this.editTemplateInput.push(template);
            this.id++;

          });
        }

        var dropdownRowIndex = [];


        if (this.editTemplateInput.length > 1) {
          for (var i = 0; i < this.editTemplateInput.length; i++) {
            if (this.editTemplateInput[i].tagControlId == 3) {


              dropdownRowIndex.push(i)

            }


          }

          setTimeout(() => {
            for (var j = 0; j < dropdownRowIndex.length; j++) {

              if (document.getElementById("TemplateEditorCustomForm").children[dropdownRowIndex[j]].children[0].children[0].children[2].children.length > 2) {
                var optdrop = document.getElementById("TemplateEditorCustomForm").children[dropdownRowIndex[j]].children[0].children[0].children[2].children
                var DropOptLength = document.getElementById("TemplateEditorCustomForm").children[dropdownRowIndex[j]].children[0].children[0].children[2].children.length - 1


                for (var k = 0; k < DropOptLength; k++) { optdrop[k].children[2].classList.remove("d-none"); }


              }
            }
          }, 1000)


          this.DeleteRow = "visible";

        }
        else {
          this.DeleteRow = "hidden";
        }
      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000)
    })}
  }

  clickInputData(event){
    let tagData =event.target.value;
    if(event.target.selectionStart === 0 && event.keyCode == 32 ){
      event.preventDefault();
     }
    if(tagData == null ){
          this.getValue = true;
    }
    else{
      this.getValue = false;
    }
    this.TemplateEditor.markAsDirty();
  }
  editTemplateInputChange(event){
    this.TemplateEditor.markAsDirty();
  }
  public updateRequiredflag(inputBox){
    this.TemplateEditor.markAsDirty();
    inputBox.isRequired = !inputBox.isRequired
  }
 closeOtherMenus(tempId) {
    if (this._tagTempList && this._tagTempList.length) {
      this._tagTempList.forEach(x => {
        if (x.tdId !== tempId.tdId) {
          x['showMenu'] = false
          this.cd.detectChanges();

        }
        if (x.tdId === tempId.tdId) {
          x['showMenu'] = true
          this.cd.detectChanges();
        }
      })
    }
  }

}
