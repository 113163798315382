<div  class="PreviewData"  style="height: calc(100vh - 232px); ">
    <nb-card class="docItTPreviewOpen">
      <nb-card-header class="d-flex justify-content-between align-items-center p-0  px-3 py-3">
        <p class="subtitle mb-0">Preview</p>
        <nb-icon icon="close-outline" (click)="close()" class="cursor-pointer"></nb-icon>
      </nb-card-header>
      <nb-card-header class="filename">
        <div class="zoom-btn-container">
           
          
          <div class="d-flex align-items-center">
             <button nbButton class="bg-transparent border-0 pt-0 shadow-none image16 pb-0" >
                  <i *ngIf="extension == 'png'" class="sprite sprite-PNG" ></i>
                  <i *ngIf="extension=='jpg' || extension == 'jpeg'" class="sprite sprite-JPG"></i>
                  <i *ngIf="extension=='tiff' || extension== 'tif'" class="sprite sprite-TIF"></i>
                  <i *ngIf="extension=='gif'" class="sprite sprite-GIF"></i>
                  <i *ngIf="extension =='pdf'" class="sprite sprite-PDF"></i>
                  <i *ngIf="extension =='Document' || extension== 'docx' || extension == 'doc'" class="sprite sprite-DOC"></i>
                  <i *ngIf="extension =='txt'" class="sprite sprite-TXT"></i>
                  <i *ngIf="extension =='rtf'" class="sprite sprite-RTF"></i>
                  <i *ngIf="extension =='odt'" class="sprite sprite-ODT"></i>
                  <i *ngIf="extension =='bmp'" class="sprite sprite-BMP"></i>
                  <i *ngIf="extension =='pjpg'" class="sprite sprite-PJPG"></i>
                  <i *ngIf="extension =='pjp'" class="sprite sprite-PJP"></i>
                  <i *ngIf="extension =='dib'" class="sprite sprite-DIB"></i>
                  <i *ngIf="extension =='dot'" class="sprite sprite-DOT"></i>
                  <i *ngIf="extension =='text'" class="sprite sprite-TEXT"></i>
                  <i *ngIf="extension =='jfif'" class="sprite sprite-JFIF"></i>
                  <i *ngIf="extension =='xls'" class="sprite sprite-XLS"></i>
                  <i *ngIf="extension =='fods'" class="sprite sprite-FODS"></i>
                  <i *ngIf="extension =='xlsm'" class="sprite sprite-XLSM"></i>
                  <i *ngIf="extension =='xlsx'" class="sprite sprite-XLSX"></i>
                  <i *ngIf="extension =='ods'" class="sprite sprite-ODS"></i>
                  <i *ngIf="extension =='slk'" class="sprite sprite-SLK"></i>

              </button>
               <span class="d-flex col8line">
                   <p class=" m-0 subtitle subtitle-s1 text-basic d-flex align-items-center col8linep">
                       {{docrename}}
                   </p>
                 </span>        
             </div>
     </div>
      </nb-card-header>
        <nb-card-body *ngIf="docIsDisplayed" class="previewActivity p-0"
        >
         
          
        <div class="pdf-viewer-container" *ngIf="docIsDisplayed"
        style="width: 100%; position:relative; height: 53vh;  border-left: 1px solid #E4E9F2;border-bottom: 1px solid black;">
           <ngx-extended-pdf-viewer #pdfViewer [src]="sourceFile" [useBrowserLocale]="true" [(zoom)]="riser"
             [showHandToolButton]="false" [sidebarVisible]="false" [textLayer]="true" [height]="'80vh'"
             [handTool]="false" [enableDragAndDrop]="false" [enablePinchOnMobile]="true" [minZoom]="0.1" [maxZoom]="10"
              [ignoreKeyboard]="false" [showSecondaryToolbarButton]="false" [showPagingButtons] ="false"
             [acceptKeys]="['Ctrl+WHEEL', 'Ctrl+P','Ctrl++','Ctrl+-','+','-']" [ignoreKeys]="false" 
             [showPrintButton]="false" [showDownloadButton]="false" [showOpenFileButton]="false" [showSidebarButton]="false"
             [showFindButton]="false" [showPresentationModeButton]="false" [showRotateButton]="false"
             [showSidebarButton]="false"
             >
           </ngx-extended-pdf-viewer>
         </div>
        
          
        </nb-card-body>
        <nb-card-body *ngIf="docUnderProcessing || docFormatNotSupported || docIsLockedOrCorrupt  || docParkingQueue" class="previewActivity">
          <div class="alternative-content" *ngIf="docUnderProcessing">
            <div class="copyScreenLoader" [nbSpinner]="true" nbSpinnerStatus="primary" nbSpinnerSize="giant"></div>
            <div *ngIf="showProcessing" class="copyLoadingScreenText">We are still preparing your document for viewing. Please check again in some time</div>
          </div>
          <div class="alternative-content" *ngIf="docParkingQueue">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/SVG/hourclock.svg"></div>
            <div class="copyLoadingScreenText">Sorry, this is taking us longer than expected. We will get back to you soon.</div>
          </div>
          <div class="alternative-content" *ngIf="docFormatNotSupported">
            <div>
              <img class="cursor-pointer doc-not-loading" width="75.64px" height="60px"
              src="../../../assets/images/SVG/notsuporrted.svg"></div>
            <div class="copyLoadingScreenText">We’re sorry! The format for this document is not supported for viewing on dox2U as yet</div>
          </div>
          <div class="alternative-content" *ngIf="docIsLockedOrCorrupt">
            <div>
              <img class="cursor-pointer doc-not-loading" width="75.64px" height="60px"
              src="../../../assets/images/SVG/lockandcurpt.svg"></div>
            <div class="copyLoadingScreenText">This document is either locked or corrupt and cannot be viewed on dox2U</div>
          </div>
        </nb-card-body>
    </nb-card>
</div>
