import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-myqueuetooltip',
  templateUrl: './myqueuetooltip.component.html',
  styleUrls: ['./myqueuetooltip.component.scss']
})
export class MyqueuetooltipComponent implements OnInit {

  tooltipData: any;
  formatData: any;
  tooltipDataValue:any;
  tooltipDataSplit:any;
  tooltipDataAccess= true;
  tooltipSizeFormat=""

  constructor() { }

  ngOnInit(): void {
  }
  data: any;
  /*
    *Adding document format type in the my docs lists
   */
   agInit(params:any){
     this.data=params?.data;
     this.tooltipDataValue = this?.data?.pageConversion?.split(':')[1];
     this.tooltipData= this.tooltipDataValue
     this.formatData = this?.data?.size;
     this.tooltipSizeFormat = this.data.sizeUnit;
     this.tooltipDataAccess = true;

   }


}
