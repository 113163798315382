import { Injectable } from '@angular/core';
import { ApiServiceService } from '../@core/api-service.service';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs/operators';
import { HttpClient,HttpHeaders } from '@angular/common/http';

/**
 *  Injectable for verify service
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for verify service method
 */
export class VerifyDocumentService {
/**
 * variable  for data
 */
  private data: any;
/**
 * variable  for error
 */
  private error: any;
  /**
 * variable  for createdBy
 */
  createdBy: any;
  /**
 * variable  for workSpaceId
 */
  workSpaceId: any;
  docId: any;

/**
 *  git ignore onInit
 */
  constructor(private apiService:ApiServiceService, private http: HttpClient) {
    this.docId = localStorage.getItem('docId');
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
  }
   /**
 * method  for elastic base url
 */
  public elasticdbaseurl = environment.elasticdbaseurl;
   /**
 * method  for base Origin
 */
  public baseOrigin = environment.baseOrigin;
  /**
 * method  for templateDetailsService
 */
  templateDetailsService(data) {
   return this.apiService.post('/api/DocumentVerification/TemplateDetailsById', data);
  }
  /**
   * method for deleteAcessMultipleService
   */
  deleteAccessMultipleService(data) {
    return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
  }
  /**
 * method  for Upload Rights UserList Service
 */
  UploadRightsUserListService(data) {
   return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }
   /**
 * method  for get access rights
 */
  getaccessrights(data) {
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }
   /**
 * method  for tag Template Service
 */
  tagTemplateService(data) {
    return this.apiService.post('/api/DocumentVerification/TemplateList', data);
  }
  /**
  * method  for Get Tag Value by ID
  */
  GetTagValuebyID(data) {
     return this.apiService.post('/api/CabinateGetMonth/GetTagValuebyID', data);
   }
     /**
   /**
 * method  for verifier DocDetails Service
 */
  verifierDocDetailsService(data) {
    return this.apiService.post( `/api/DocumentVerification/DocVerifyById`, data);
  }
     /**
   /**
 * method  for verifier DocDetails Service
 */
    getVerifierStatus() {

      return this.apiService.post('/api/Workspace/WSCheckVerifierDoc?LoginUserEmailID='+ this.createdBy + '&Wsoid=' + this.workSpaceId + '&DocId=' + this.docId , "");
    }
   /**
 * method  for get Shared With Modal Data For View
 */
  getSharedWithModalDataForView(data) {
    return this.apiService.post('/api/GetUserDetailWithGroup/GetUserDetailWithGroup', data);
  }
 /**
 * method  for view User Wise Role Access
 */
  viewUserWiseRoleAccess(data) {
   return this.apiService.post('/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }
  /**
 * method  for view Docs AuditLog
 */
  viewDocsAuditLog(data) {
    return this.apiService.post('/api/Auditlog/InsertAuditLog', data);
  }
   /**
 * method  for update Trash Service
 */
  updateTrashService(data) {
   return this.apiService.post('/api/Trash/UpdateDocTrashStatus', data);
  }
    /**
 * method  for pdf Viewer File Conversion
 */
  pdfViewerFileConversion(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFileStreamFromBlob', data, {responseType:'blob',observe: 'response'}).pipe(
      retry(0)
    );
  }
  /**
 * method  for verification pdf Viewer File Conversion
 */
  verificationpdfViewerFileConversion(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFileStreamFromBlob', data, {responseType:'blob'}).pipe(
      retry(0)
    );
  }
    /**
 * method  for checkName Already Exists In WS
 */
  checkNameAlreadyExistsInWS(data) {
    return this.apiService.post('/api/CheckDuplicateDocumentName/CheckDuplicationDocName', data);
  }
    /**
 * method  for save Docs Details Service
 */
  saveDocsDetailsService(data) {
   return this.apiService.post('/api/DocumentVerification/UpdateDocDetails', data);
  }
      /**
 * method  for save DocIt Details Service
 */
      DocItTemplateInsert(data) {
        return this.apiService.post('/api/docItTypeMaster/DocItTemplateInsert', data);
       }
          /**
 * method  for save Docs Preview Service
 */
      GenerateBlobSASUrl(data) {
        return this.apiService.post('/api/OwnedByMe/GenerateBlobSASUrl', data);
       }
     /**
 * method  for elastic Update
 */
  elasticUpdate(data) {
    return this.apiService.post('/api/DocumentVerification/AfterVerificationElasticUpdateDocDetails', data);
   }
  /**
 * method  for docs Status List Service
 */
  docsStatusListService(data) {
   return this.apiService.get('/api/DocumentVerification/DocStatusList');
  }
   /**
 * method  for docs Status Check Service
 */
  docsStatusCheckService(data) {
    return this.apiService.post( '/api/DocumentVerification/GetDocStatusByDocId', data);
  }
/**
 * method  for ws Subscription Details Service
 */
  wsSubscriptionDetailsService(data) {
    return this.apiService.post('/api/Workspace/SubscriptionDetails', data);
   }


   /**
 *  template editor api start
 */
/**
 * method  for delete Template Service
 */
  deleteTemplateService(data) {
   return this.apiService.post('/api/DocumentVerification/DeleteTemplate', data);
  }
  /**
 * method  for get Doc Guid From Share Guid Guest
 */
  getDocGuidFromShareGuidGuest(data) {
   return this.apiService.post('/api/GuestAccess/CheckGuestShareGUIDDetails', data);
  }
    /**
 * method  for get Update Guest Access View
 */
  getUpdateGuestAccessView(data) {
    return this.apiService.post('/api/GuestAccess/UpdateGuestAccessView', data);
   }
/**
 * method  for get Update Session Guest Access
 */
   getUpdateSessionGuestAccess(data) {
    return this.apiService.post('/api/GuestAccess/UpdateSessionGuestAccess', data);
   }
/**
 * method  for save Template Editor Service
 */
  public saveTemplateEditorService(data) {
  return this.apiService.post('/api/DocumentVerification/InsertTemplate', data);
  }

/**
          *reparsing for document
*/
confirmreparse(data) {
    return this.apiService.post( `/api/RandomDocumentUpload/ReParsingEnableMethod`, data);
  }

/**
          *reparsing for document
*/
/**
 * variable   for httpOptions
 */
httpOptions
/**
 * method  for view change text
 */
viewchangetext(data) {

  let headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
  });

  headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')

  return this.http.post(this.elasticdbaseurl + "/api/Elastic/ExtractedText", data,{headers: headers});
}
/**
 * method  for get elastic
 */
getelastic(data) {

  let headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
  });

  headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')

  return this.http.post("https://dox2udev.com:96/api/Elastic/UpdateDocDetails", data,{headers: headers});
}

/**
          *template change value
*/
templatechange(data) {
  return this.apiService.post( `/api/DocumentVerification/GetTagValueList`, data);
}

/**
          *template change label
*/
labeldropdown(data) {
  return this.apiService.post( `/api/DocumentVerification/GetTagLabelList`, data);
}
 getTitleList(data) {
  return this.apiService.post('/api/docItTypeMaster/GetdocItdocumentTypeMaster', data);
 }
 getsubList(data){
  return this.apiService.post('/api/docItTypeMaster/GetdocItdocumentType', data);

 }
 GetResponseList(data){
  return this.apiService.post('/api/docItTypeMaster/GetResponseList', data);

 }
 linkDocument(data){
  return this.apiService.post('/api/docItTypeMaster/docItlinkDocument', data);
 }
 GetDocItTagtemplateList(data){
  return this.apiService.post('/api/docItTypeMaster/GetDocItTagtemplateList', data);

 }
 saveDocsitTags(data) {
  return this.apiService.post('/api/docItTypeMaster/DocItTemplateInsert', data);
 }
 CreateResponse(data) {
  return this.apiService.post('/api/docItTypeMaster/CreateResponse', data);
 }
  GetdocItTypeWithUniqueId(data) {
  return this.apiService.post('/api/docItTypeMaster/GetdocItTypeWithUniqueId', data);
 }
 /**
 *  method for upload available storage
 */
 ValidateAvailableStorage(data){
  return this.apiService.post('/api/RandomDocumentUpload/ValidateAvailableStorage', data);
}
/**
 *  API for delete my document  API Get Start here
 */
  deleteMyDocService(data) {
    return this.apiService.post('/api/DocumentVerification/DeleteDocVerifier', data);
   }
    /**
 *  method for making the copy of docket
 */
 copydocket(data){
  return this.apiService.post('/api/docItTypeMaster/copydocket', data);
}

    /**
 *  method for getting guest shared list data
 */
getListofSharedUsersOfDoc(data){
  return this.apiService.post('/api/WhatsAppGuestAccess/GetGuestList',data)
}
   /**
 *  method for getting all user list data
 */

searchAllUsersService(data) {
  return this.apiService.post('/api/AllUsers/Search', data);
 }

  getDocketDetails(data) {
    return this.apiService.post("/api/Dockettype/GetDocketViewarDetails", data);
  }

  getUseGroupDocketsDetails(data) {
    return this.apiService.post("/api/DocketDetails/DocketDetailUserWithGroup", data);
  }

  getDocketVarDetails(data) {
    return this.apiService.post("/api/DocketDetails/GetDocketDetails", data);
  }

  getDocketVarActivity(data) {
    return this.apiService.post("/api/DocketDetails/GetActivityDetails", data);
  }
}
