/**
 *  profit chart
 */
export interface ProfitChart {
    /**
      *  variable for chartLabel
   */
  chartLabel: string[];
    /**
      *  variable for Profit data
   */
  Profitdata: number[][];
}
/**
 *  ProfitChartData
 */

export abstract class ProfitChartData {
  /**
 *  abstract method for getProfitChartData
 */
  abstract getProfitChartData(period: string): ProfitChart;
}
