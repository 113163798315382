/**
 *  OrdersChart 
 */
export interface OrdersChart {
   /**
      *  variable for chartLabel
   */
  chartLabel: string[];
   /**
      *  variable for linesData
   */
  linesData: number[][];
}
/**
 *  OrdersChartData
 */
export abstract class OrdersChartData {
   /**
 *  abstract method for getOrdersChartData
 */
  abstract getOrdersChartData(period: string): OrdersChart;
}
