<!--/*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd 
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Dashboard Component
///  ascription: Dashboard for the project
///  Owner      : Sumeet Goel
///  Author     : Aman Kumar
///  Created On : 22-02-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            ascription
///
///------------------------------------------------------------------->
<div  *ngIf="!searchloader" class="containerClass" [ngClass]=" isLoader? 'flow' : ''" style=" margin: 16px 26px 0px !important;">
  <!------------------------ html for dashboad 2.0 start here------------------------ -->
  <!--------------------------skeleton loading componanats start  ------------------------->
  <div *ngIf="isLoader">
    <ngx-loader> </ngx-loader>
  </div>
  <!---------------------------------skeleton loading componanats End  ------------------------------->



  <!-------------------- html for parent which contains whole structure start here ----------------------->

  <div class="parent col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 suggestedConditionalbox">
    <!------------------------------ html for first row started from here ------------------------------>
    <div class="row first-child">



      <div
        class="right-child_1 left_padding_1  col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 customPaddingRight1">

        <nb-card class="avtar-card commonClass">
          <nb-card-body>
            <div class="d-flex flex-row" style="justify-content: space-between;padding: 16px;">
              <div class="user-content">
                <div style="height: 24px; width: 125px;" class="">
                  <h6 class="mb-1">Users</h6>
                </div>
                <span class="avtar-color"> </span>
                <h4 class="">{{usercount}}</h4>
              </div>
              <div class="avtar">
                <nb-icon icon="person" class="avtar-icon"></nb-icon>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div
        class="right-child_2 right-child_4 left_padding pl-0 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 customPaddingRight">
        <nb-card class="docs-card commonClass">
          <nb-card-body>
            <div class="d-flex flex-row" style="justify-content: space-between;padding: 16px;">
              <div class="total-docs-content">
                <div style="height: 24px; width: 125px;">
                  <h6 class="mb-1">Total docs</h6>
                </div>
                <span class="total-docs-color"> </span>
                <h4 class="">{{totaldocs}}</h4>
              </div>
              <div class="docs">
                <nb-icon icon="layers" class="docs-icon"></nb-icon>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div
        class="right-child_1 left_padding_1  pl-0 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 customPaddingRight1">
        <nb-card class="recentlyAdded-card commonClass">
          <nb-card-body>
            <div class="d-flex flex-row " style="justify-content: space-between;padding: 16px;">
              <div class="recentlyAdded-content">
                <div style="height: 24px; width: 125px;">
                  <h6 class="mb-1">Recently added</h6>
                </div>
                <span class="recentlyAdded-color"> </span>
                <h4 class="">{{timeTrackerData}}</h4>
              </div>
              <div class="d-flex flex-row">
                <div class="timeTracker d-flex flex-column align-items-right justify-content-right">
                  <div class="track">
                    <p [ngClass]="timeTrackerFlag === 'daily' ? 'active' : 'non-active'"
                      (click)="timeTrackerHandler('daily')"><span class="cursor-pointer">Today</span> </p>
                  </div>
                  <div class="track">
                    <p [ngClass]="timeTrackerFlag === 'weekly' ? 'active' : 'non-active'"
                      (click)="timeTrackerHandler('weekly')"><span class="cursor-pointer">7 days</span></p>
                  </div>
                  <div class="track">
                    <p [ngClass]="timeTrackerFlag === 'monthly' ? 'active' : 'non-active'"
                      (click)="timeTrackerHandler('monthly')" style="width:40px;"><span class="cursor-pointer">30
                        days</span> </p>
                  </div>
                </div>
                <!-- <div class="recentlyAdded">
                 <nb-icon icon="file-add" class="recentlyAdded-icon"></nb-icon>
               </div> -->
              </div>
            </div>
          </nb-card-body>
        </nb-card>


      </div>

      <div
        class="right-child_2 left_padding pl-0 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 customPaddingRight onlyForLarge">
        <nb-card class="d-page-card commonClass">
          <nb-card-body>
            <div class="d-flex flex-row" style="justify-content: space-between;padding: 16px;">
              <div class="d-pages-content">
                <div style="height: 24px; width: 125px;">
                  <h6 class="mb-1">My docs</h6>
                </div>
                <span class="d-pages-color"> </span>
                <h4 class="">{{dpages}}</h4>
              </div>
              <!-- <div class="d-page">
                <nb-icon icon="book-open" class="d-page-icon"></nb-icon>
              </div> -->
              <div style="display: flex;
              flex-direction: column;
              text-align: right;" class="pt-3">
                <p class="mb-0 cursor-pointer" [ngClass]="countData === 'Owned' ? 'activeOwn' : 'non-activeOwn'" (click)="getDocCount('Owned')">Owned by me</p>
                <p class="mb-0 cursor-pointer" [ngClass]="countData === 'Shared' ? 'activeOwn' : 'non-activeOwn'" (click)="getDocCount('Shared')">Shared with me</p>
              </div>

            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <!-- new value -->
    <!-------------------------- html for first row ended from here ------------------------->
    <!-------------------------------------- html for second row started from here ----------------------->
    <div class="row second-child">
      <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9 pl-0 customPaddingRight"
        [ngClass]="[passwordboxhidden || isDismiss || !setActiveOwn? 'totalDocClass_1' : '']">
        <nb-card class="table-card commonClass">
          <nb-card-body>
            <nb-tabset fullWidth class="tabsetParent" (changeTab)="tabActive($event)">
              <nb-tab tabTitle="Last accessed" class="p-0 tabParent" active="{{setActiveLast}}"
                (click)="ownByMeEnterKeyActive()">
                <div class="table-responsive p-0">
                  <table style="width:100%">

                    <tr style="height: 63px;" class="tableHeading">
                      <th class="table_head_format"> </th>
                      <th class="tableName" [appSort]="getLastAccess" data-order="asc" data-name="docRename"
                        (click)="showIconShare('name')">Name
                        <nb-icon *ngIf="!showAscendingsShare && showNameShare" icon="arrow-upward-outline"></nb-icon>
                        <nb-icon *ngIf="showAscendingsShare && showNameShare" icon="arrow-downward-outline"></nb-icon>
                      </th>
                      <th class="table_head"><span >Size </span></th> 
                      <th class="tableClass"  data-order="asc" data-name="changedate"
                        data-type="date" (click)="showIconShare('date')">Added on
                        <nb-icon *ngIf="!showAscendingsShare && !showNameShare&& !disableSorting" icon="arrow-upward-outline"></nb-icon>
                        <nb-icon *ngIf="showAscendingsShare && !showNameShare && !disableSorting" icon="arrow-downward-outline"></nb-icon>
                      </th>
                      <th class="tableClass">Owner</th>
                      <th class="table_head">
                        <nb-icon icon="refresh-outline" [ngClass]="{'icon-disable' : isDisabledButton}"
                          class="icon-style cursor-pointer" (click)="onrefreshClick()"></nb-icon>
                      </th>
                    </tr>
                    <tr id="filterclickicon" (dblclick)="onViewClickThree(lastaccessed)" class="table-row-style" [ngClass]="isRefreshed ? 'hideData':''"
                      *ngFor="let lastaccessed Of getLastAccess; index as i"
                      [ngClass]="{'testgrey':lastaccessed?.shareStatus !== 'Access'}"
                                            (click)="ClickedRow(i, lastaccessed);filterdata('clickinsidefilter')"
                      [class.active]="i == HighlightRow" (keyup.enter)="onEnter(lastaccessed)">
                      <td id="filterclickicon" class="table_head_format"><img [src]="getImageUrl(lastaccessed,lastaccessed?.originalFileName)"
                          alt="" class="img-style"
                          [nbTooltip]="extensionFormat(lastaccessed, lastaccessed?.originalFileName)"
                          nbTooltipPlacement="right" nbTooltipStatus="info"></td>
                      <td id="filterclickicon" class=" tableName text-primary"
                        *ngIf="lastaccessed?.docRename?.length >= 20">
                        <a class="pt-2">{{lastaccessed?.docRename?.substring(0,20)}} </a> <span
                          class="tripple-dot pt-2" nbTooltip="{{lastaccessed.docRename}}" nbTooltipPlacement="right"
                          nbTooltipStatus="info"> ...</span>
                      </td>
                      <td id="filterclickicon" class="tableName text-primary"
                        *ngIf="lastaccessed?.docRename?.length < 20">
                        <a class="pt-2">
                          {{lastaccessed?.docRename}}</a>
                      </td>
                      <td id="filterclickicon" class="pt-3 table_head"><span [nbTooltip]="getTooltip(lastaccessed)"
                          nbTooltipPlacement="right" nbTooltipStatus="info"
                          class="">{{lastaccessed?.size?.split('|')[1]}}</span> </td>
                      <td id="filterclickicon" class="pt-3 tableClass">{{lastaccessed?.createdDtm}}</td>
                      <td id="filterclickicon" class="text-primary pt-3 tableClass"><span (click)="onCellClicked(lastaccessed)"><a
                            class="cursor-pointer">{{lastaccessed.status?.split('|')[1]}}</a> </span></td>
                      <td *ngIf="items?.length !== 0" id="filterclickicon" class="table_head"><button outline nbButton [nbContextMenu]="items"
                          nbContextMenuTag="quick-action-dashboard" class="moreIcons" #threeDot>
                          <nb-icon icon="more-vertical-outline"></nb-icon>
                        </button></td>
                    </tr>

                    <tr class="table-row-style" *ngIf="isRefreshed">
                      <td scope="row"></td>
                      <td scope="row  text-primary">
                      
                        <span class="ml-3 text-primary">
                          <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></span>
                        </td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                    
                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed " >
                        <td scope="row"></td>
                        <td scope="row  text-primary">
                        
                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></span></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                      
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
                          
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></span>
                            </td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                        
                          </tr>
                          <tr class="table-row-style"*ngIf="isRefreshed " >
                            <td scope="row"></td>
                            <td scope="row  text-primary">
                            
                              <span class="ml-3 text-primary">
                                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></span></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                          
                            </tr>
                            <tr class="table-row-style"*ngIf="isRefreshed " >
                              <td scope="row"></td>
                              <td scope="row  text-primary">
                              
                                <span class="ml-3 text-primary">
                                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></span>
                                </td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                            
                              </tr>
                              <tr class="table-row-style"*ngIf="isRefreshed " >
                                <td scope="row"></td>
                                <td scope="row  text-primary">
                                
                                  <span class="ml-3 text-primary">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                    </ngx-skeleton-loader></span></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                              
                                </tr>
                  </table>
                </div>
                <div class="lastAccess-conditional">
                  <div class="conditional-lastAccess d-flex align-items-center justify-content-center "
                    *ngIf="!getLastAccess || getLastAccess.length === 0">
                    <div class="access-zero-color">
                      <div class="d-flex align-items-center justify-content-center flex-row" style="height: 100%;">
                        <p class="access-zero-para">0</p>
                        <p class="access-zero-para">0</p>
                      </div>
                    </div>
                    <div class="assess-para-div">
                      <p class="assess-para">Documents last accessed by you will appear here</p>
                    </div>
                  </div>
                </div>

              </nb-tab>
              <nb-tab tabTitle="Owned by me" class="p-0" active="{{setActiveOwn}}" (click)="sharedWithMeActive()">
                <div class="table-responsive p-0">
                    <!------------------------------------------- html for the table loading end here -------------------------------------------------->
                  <table style="width:100%" >


                    <tr style="height: 63px;"    class="tableHeading">
                      <th class="table_head_format  "></th>
                      <th class="tableName  fontcolor" [appSort]="owneddatatab" (click)="showIconOwn('name')" data-order="asc"
                      data-name="documentName">Name
                      <nb-icon *ngIf="!showAscendingsOwn && showNameOwn" icon="arrow-upward-outline"
                     ></nb-icon>
                    <nb-icon *ngIf="showAscendingsOwn && showNameOwn" icon="arrow-downward-outline"
                     ></nb-icon>
                    </th>
                      <th class="tableThirdData"> <span
                        class=""> Size </span></th>
                      <th class="tableThirdData" [appSort]="owneddatatab" (click)="showIconOwn('date')" data-order="asc" data-type="date"
                      data-name="changedate">Added on
                      <nb-icon *ngIf="!showAscendingsOwn && !showNameOwn" icon="arrow-upward-outline"
                     ></nb-icon>
                    <nb-icon *ngIf="showAscendingsOwn && !showNameOwn" icon="arrow-downward-outline"
                      ></nb-icon>
                    </th>
                      <th class="table_head">
                        <nb-icon icon="refresh-outline" [ngClass]="{'icon-disable' : isDisabledButton}"
                        class="icon-style cursor-pointer" (click)="onrefreshClick()"></nb-icon>
                      </th>
                    </tr>
                    
                      <tr id="filterclickicon" [ngClass]="isRefreshed ? 'hideData':''"  class="table-row-style"
                      *ngFor="let ownedByMe Of owneddatatab;  let i = index" (dblclick)="onViewClickThree(ownedByMe)"
                      (click)="ClickedRow(i, ownedByMe);filterdata('clickinsidefilter')"
                      [class.active]="i == HighlightRow">
                        <td class="table_head_format" id="filterclickicon">
                          <img [src]="getImageUrl(ownedByMe, ownedByMe?.documentName)" alt=""
                              class="img-style" [nbTooltip]="extensionFormat(ownedByMe, ownedByMe?.documentName)"
                              nbTooltipPlacement="right" nbTooltipStatus="info">
                        </td>
                        <td class="tableName text-primary" id="filterclickicon" *ngIf="ownedByMe?.documentName?.length >= 20"> <a
                          class="  pt-4 cursor-pointer">{{ownedByMe?.documentName?.substring(0,20)}}</a>
                        <span class="tripple-dot pt-4" nbTooltip="{{ownedByMe.documentName}}"
                          nbTooltipPlacement="right" nbTooltipStatus="info"> ...</span></td>
                        <td class="tableName text-primary" id="filterclickicon" *ngIf="ownedByMe?.documentName?.length < 20"> <a
                          class="  pt-4 cursor-pointer">{{ownedByMe?.documentName}}</a></td>
                        <td class="tableThirdData pt-3" id="filterclickicon"><span [nbTooltip]="getTooltip(ownedByMe)"
                          nbTooltipPlacement="right" nbTooltipStatus="info"
                          class="">{{ownedByMe?.size?.split('|')[1]}}</span> </td>
                        <td class="tableThirdData pt-3" id="filterclickicon">{{ownedByMe?.date}}</td>
                        <td *ngIf="items?.length !== 0" class="table_head" id="filterclickicon"><button outline nbButton
                          [nbContextMenu]="items" nbContextMenuTag="quick-action-dashboard" class="moreIcons"
                          #threeDot>
                          <nb-icon icon="more-vertical-outline"></nb-icon>
                        </button></td>
                      </tr>
                    
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed ">
                        <td scope="row"></td>
                        <td scope="row  text-primary">

                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </span>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>
                        <td>
                          <ngx-skeleton-loader appearance="circle"
                            [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader>
                        </td>

                      </tr>
                  </table>
                 
                  <div class="view-more" *ngIf="OwnerByMeLength != 0">
                    <p class="view-more-para">
                      <a href="" class="text-decoration-none" [routerLink]="['/pages/documents/owned-by-me']">View
                        more</a>
                    </p>
                  </div>
                </div>
                <div class="ownedByMe-conditional" *ngIf="OwnerByMeLength == 0">

                  <div class="conditional-lastAccess d-flex align-items-center justify-content-center ">
                    <div class="ownedByMe-zero-color">
                      <div class="d-flex align-items-center justify-content-center flex-row" style="height: 100%;">
                        <p class="ownedByMe-zero-para">0</p>
                        <p class="ownedByMe-zero-para">0</p>
                      </div>
                    </div>
                    <div class="assess-para-div">
                      <p class="assess-para">Documents owned by you will appear here</p>

                    </div>
                  </div>
                </div>

              </nb-tab>
              <nb-tab tabTitle="Shared with me" class="p-0" active="{{setActiveShared}}" (click)="DocsInUploadActive()">
                <div class="table-responsive p-0">
                  <table style="width:100%">
                    <tr style="height: 63px;" class="tableHeading">
                      <th class="table_head_format"> </th>
                      <th class="tableName" [appSort]="sharedWithMe" data-order="asc" data-name="documentName"
                        (click)="showIconShare('name')">Name
                        <nb-icon *ngIf="!showAscendingsShare && showNameShare" icon="arrow-upward-outline" class="">
                        </nb-icon>
                        <nb-icon *ngIf="showAscendingsShare && showNameShare" icon="arrow-downward-outline" class="">
                        </nb-icon>
                      </th>
                      <th class="table_head"><span >Size </span></th>
                      <th class="tableClass" [appSort]="sharedWithMe" data-order="asc" data-name="changedate"
                        data-type="date" (click)="showIconShare('date')">Added on
                        <nb-icon *ngIf="!showAscendingsShare && !showNameShare" icon="arrow-upward-outline" class="">
                        </nb-icon>
                        <nb-icon *ngIf="showAscendingsShare && !showNameShare" icon="arrow-downward-outline" class="">
                        </nb-icon>
                      </th>

                      <th class="tableClass">Owner</th>
                      <th class="table_head">
                        <nb-icon icon="refresh-outline" [ngClass]="{'icon-disable' : isDisabledButton}"
                          class="icon-style cursor-pointer" (click)="onrefreshClick()"></nb-icon>
                      </th>
                    </tr>
                    <tr id="filterclickicon" [ngClass]="isRefreshed ? 'hideData':''" class="table-row-style" *ngFor="let data Of sharedWithMe; let i = index"
                      (click)="ClickedRow(i, data);filterdata('clickinsidefilter')" [class.active]="i == HighlightRow" (dblclick)="onViewClickThree(data)">
                      <td id="filterclickicon" class="table_head_format"><img [src]="getImageUrl(data, data?.documentName)" alt=""
                          class="img-style" [nbTooltip]="extensionFormat(data, data?.documentName)"
                          nbTooltipPlacement="right" nbTooltipStatus="info"></td>
                      <td id="filterclickicon" class="tableName text-primary" *ngIf="data?.documentName?.length >= 20">
                        <a class="pt-2 cursor-pointer">
                          {{data?.documentName?.substring(0,20)}} </a> <span class="tripple-dot pt-2"
                          nbTooltip="{{data.documentName}}" nbTooltipPlacement="right" nbTooltipStatus="info">
                          ...</span>
                      </td>
                      <td id="filterclickicon" class="tableName text-primary" *ngIf="data?.documentName?.length < 20">
                        <a
                          class="pt-2 cursor-pointer">{{data?.documentName}} </a>
                      </td>
                      <td class="pt-3 table_head"><span [nbTooltip]="getTooltip(data)" nbTooltipPlacement="right"
                          nbTooltipStatus="info" class="pt-3">{{data?.size?.split('|')[1]}}</span> </td>
                      <td id="filterclickicon" class="pt-3 tableClass"><span class="">{{data?.date}} </span></td>
                      <td id="filterclickicon" class="text-primary tableClass pt-3"><span (click)="onCellClicked(data)"> <a
                            class="cursor-pointer text-primary ">{{data.status?.split('|')[1]}}</a></span></td>
                      <td *ngIf="items?.length !== 0" id="filterclickicon" class="table_head"><button outline nbButton [nbContextMenu]="items"
                          nbContextMenuTag="quick-action-dashboard" class="moreIcons" #threeDot>
                          <nb-icon icon="more-vertical-outline"></nb-icon>
                        </button></td>
                    </tr>
    
                    <tr class="table-row-style" *ngIf="isRefreshed ">
                      <td scope="row"></td>
                      <td scope="row  text-primary">
                      
                        <span class="ml-3 text-primary">
                          <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></span>
                        </td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                      <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                      </ngx-skeleton-loader></td>
                    
                      </tr>
                      <tr class="table-row-style" *ngIf="isRefreshed " >
                        <td scope="row"></td>
                        <td scope="row  text-primary">
                        
                          <span class="ml-3 text-primary">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></span></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                        <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                        </ngx-skeleton-loader></td>
                      
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
                          
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></span>
                            </td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                          <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                          </ngx-skeleton-loader></td>
                        
                          </tr>
                          <tr class="table-row-style"*ngIf="isRefreshed " >
                            <td scope="row"></td>
                            <td scope="row  text-primary">
                            
                              <span class="ml-3 text-primary">
                                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></span></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                          
                            </tr>
                            <tr class="table-row-style"*ngIf="isRefreshed " >
                              <td scope="row"></td>
                              <td scope="row  text-primary">
                              
                                <span class="ml-3 text-primary">
                                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></span>
                                </td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                            
                              </tr>
                              <tr class="table-row-style"*ngIf="isRefreshed " >
                                <td scope="row"></td>
                                <td scope="row  text-primary">
                                
                                  <span class="ml-3 text-primary">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                    </ngx-skeleton-loader></span></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                                <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></td>
                              
                                </tr>


                  </table>

                  <div class="view-more" *ngIf="ShareByMeLength != 0">
                    <p class="view-more-para">
                      <a href="" class="text-decoration-none" [routerLink]="['/pages/documents/shared-with-me']">View
                        more</a>
                    </p>
                  </div>
                </div>

                <div class="sharedWithme-conditional">


                  <div class="conditional-lastAccess d-flex align-items-center justify-content-center "
                    *ngIf="ShareByMeLength == 0">
                    <div class="sharedWithme-zero-color">
                      <div class="d-flex align-items-center justify-content-center flex-row" style="height: 100%;">
                        <p class="sharedWithme-zero-para">0</p>
                        <p class="sharedWithme-zero-para">0</p>
                      </div>
                    </div>
                    <div class="assess-para-div">
                      <p class="assess-para">Documents shared with you will appear here</p>

                    </div>
                  </div>
                </div>
              </nb-tab>
              <nb-tab tabTitle="My Queue" class="p-0" active="{{setActiveUpload}}" (click)="LastAccessActive()">
                <div class="table-responsive p-0">
                    <table style="width:100%">
                      <tr style="height: 63px;" class="tableHeading">
                        <th class="table_head_format"></th>
                        <th class="tableName" [appSort]="docsInUpload" data-order="asc" data-name="originalFileName"
                        (click)="showIconUpload('name')">Name<nb-icon *ngIf="showAscendingsUpload && showNameUpload" icon="arrow-upward-outline"
                        ></nb-icon>
                      <nb-icon *ngIf="!showAscendingsUpload && showNameUpload" icon="arrow-downward-outline"
                       ></nb-icon>
                      </th>
                        <th class="tableThirdData"> <span> Size </span></th>
                        <th class="tableThirdData" [appSort]="docsInUpload" data-order="asc" data-name="changedate"
                        (click)="showIconUpload('date')" data-type="date">Uploaded on
                        <nb-icon *ngIf="!showAscendingsUpload && !showNameUpload" icon="arrow-upward-outline"
                        ></nb-icon>
                      <nb-icon *ngIf="showAscendingsUpload && !showNameUpload" icon="arrow-downward-outline"
                       ></nb-icon>
                      </th>
                        <th class="table_head">
                          <nb-icon icon="refresh-outline" [ngClass]="{'icon-disable' : isDisabledButton}"
                            class="icon-style cursor-pointer" (click)="onrefreshClick()"></nb-icon>
                        </th>
                      </tr>

                      <!-- this condition is for below tr -->
                      <!-- [ngClass]="{isRefreshed:'hideData',testgrey:docs?.isReadable ? false:true}" -->

                      <tr id="filterclickicon" class="table-row-style"  *ngFor="let docs of docsInUpload; let i = index"
                      (click)="ClickedRow(i, docs);filterdata('clickinsidefilter')" (dblclick)="onVerifyDocument(docs)"
                      [ngClass]="isRefreshed ? 'hideData':''"
                      [class.active]="i == HighlightRow">
                        <td  id="filterclickicon" class="table_head_format">
                          <img [src]="getImageUrl(docs, docs?.originalFileName)" alt=""
                            class="img-style " [nbTooltip]="extensionFormat(docs, docs?.originalFileName)"
                            nbTooltipPlacement="right" nbTooltipStatus="info">
                        </td>
                        <td  id="filterclickicon" class="tableName text-primary" *ngIf="docs?.originalFileName?.length >= 20"> <a class="pt-4  cursor-pointer">
                          {{docs?.originalFileName?.substring(0,20)}}</a> <span class="tripple-dot pt-4"
                          nbTooltip="{{docs.originalFileName}}" nbTooltipPlacement="right" nbTooltipStatus="info">
                          ...</span></td>
                        <td  id="filterclickicon" class="tableName text-primary" *ngIf="docs?.originalFileName?.length < 20"><a class="pt-4  cursor-pointer">
                          {{docs?.originalFileName}}</a></td>
                        <td  id="filterclickicon" class="tableThirdData pt-3"><span [nbTooltip]="getTooltip(docs)"
                          nbTooltipPlacement="right" nbTooltipStatus="info"
                          class="">{{docs?.size?.split('|')[1]}}</span></td>
                        <td  id="filterclickicon" class="tableThirdData pt-3"> {{docs.date}}</td>
                        <td  *ngIf="items?.length !== 0" id="filterclickicon" class="table_head"><button outline nbButton
                          [nbContextMenu]="items" nbContextMenuTag="quick-action-dashboard" class="moreIcons"
                          #threeDot>
                          <nb-icon icon="more-vertical-outline"></nb-icon>
                        </button></td>
                      </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                        <tr class="table-row-style" *ngIf="isRefreshed ">
                          <td scope="row"></td>
                          <td scope="row  text-primary">
  
                            <span class="ml-3 text-primary">
                              <ngx-skeleton-loader appearance="circle"
                                [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader>
                            </span>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
                          <td>
                            <ngx-skeleton-loader appearance="circle"
                              [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader>
                          </td>
  
                        </tr>
                    </table>
                  
                  <div class="view-more" *ngIf="DocUploadedLength != 0">
                    <p class="view-more-para">
                      <a href="" class="text-decoration-none" [routerLink]="['/pages/documents/my-queue']">View more</a>
                    </p>
                  </div>
                </div>
                <div class="sharedWithme-conditional">


                  <div class="conditional-lastAccess d-flex align-items-center justify-content-center "
                    *ngIf="DocUploadedLength == 0">
                    <div class="docsInUpload-zero-color">
                      <div class="d-flex align-items-center justify-content-center flex-row" style="height: 100%;">
                        <p class="docsInUpload-zero-para">0</p>
                        <p class="docsInUpload-zero-para">0</p>
                      </div>
                    </div>
                    <div class="assess-para-div">
                      <p class="assess-para">Documents assigned to you for verification will appear here</p>

                    </div>
                  </div>
                </div>
              </nb-tab>
            </nb-tabset>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="uploadParentCardBox col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 pr-0">
        <nb-reveal-card class="m-15">
          <nb-card-front>
            <nb-card class="upload-card d-flex flex-column justify-content-center align-items-center commonClass"
              accent="danger">

              <nb-card-body class="d-flex flex-column align-items-center justify-content-center">
                <div class="upload-parent">
                  <div class="parent-circle-box d-flex flex-column justify-content-center align-items-center">
                    <div class="first-circle"></div>
                    <div class="child-circle-box d-flex flex-row justify-content-center align-items-center">
                      <div class="second-circle mr-1"></div>
                      <div class="third-circle"></div>
                    </div>
                  </div>
                  <div class="upload-btn d-flex align-items-center justify-content-center">
                    <button *ngIf="isDocumentUpload" nbButton class="upload-button" [disabled]="!isDocumentUpload"
                    appDebounceClick (debounceClick)="upload()" [debounceTime]="300">
                      <nb-icon icon="upload-outline"></nb-icon>
                      upload
                    </button>

                    <button *ngIf="!isDocumentUpload" nbButton
                      nbTooltip="You are not authorized to upload documents. Contact owner to get access"
                      nbTooltipPlacement="right" nbTooltipStatus="info" class="uploadisabled-button">
                      <nb-icon icon="upload-outline"></nb-icon>
                      upload
                    </button>

                  </div>
                  <div class="para">
                    <p class="para-content pl-3 pr-3">Upload a wide range of media file types to your workspace </p>
                  </div>

                </div>
              </nb-card-body>
            </nb-card>
          </nb-card-front>
          <nb-card-back>
            <nb-card class="upload-card d-flex flex-column justify-content-center align-items-center commonClass"
              accent="info">
              <nb-card-body class="d-flex flex-column align-items-center justify-content-center">
                <div class="upload-parent">
                  <div class="parent-circle-box d-flex flex-column justify-content-center align-items-center">
                    <div class="first-circle"></div>
                    <div class="child-circle-box d-flex flex-row justify-content-center align-items-center">
                      <div class="second-circle mr-1"></div>
                      <div class="third-circle"></div>
                    </div>
                  </div>
                  <div class="upload-btn-1 d-flex align-items-center justify-content-center flex-column">
                    <p class="document_para mb-0">Documents</p>
                    <div class="extention_1">
                      <p class="extention_1_para mb-0">{{format?.split(':')[0]?.split(',').join(', ')}} </p>
                    </div>
                    <div class=" mb-2 document_para-1">
                      <p class="mb-0 document_para-1" style="color: var(--color-primary-500);">Images </p>
                    </div>
                    <div class="extention_2">
                      <p class="extention_2_para mb-0">{{format?.split(':')[1]?.split(',').join(', ')}} </p>
                    </div>
                    <div class="maxParaBox">
                      <p class="text-center mb-0">Maximum file size : {{maxFileSizeUpload?.split('.')[0]}}
                        {{maxFileSizeUploadUnit}}</p>
                    </div>

                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-card-back>
        </nb-reveal-card>


      </div>
    </div>
    <!------------------------------------------ html for second row ended from here ------------------------------------>

    <div class="pb-3" style="position: relative; left:-15px">
      <p class="suggested-para m-0"
        [ngClass]="[passwordboxhidden || isDismiss || !setActiveOwn? 'suggestedConditionalbox': '']">
        Suggested for you
      </p>
    </div>
    <!--------------------------------------- html for third row started from here ------------------------------------------->
    <div class="row third-child"
      [ngClass]="[passwordboxhidden|| isDismiss || !setActiveOwn ? 'suggestedConditionalbox': '']">

      <nb-card class="first-card commonClass mb-2" *ngIf="rolename == 'Owner'">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Invite multiple users at once</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Invite users to your dox2U Workspace and explore the power of digital transformation.
              </p>
            </div>
            <div class="invite-link">
              <p class="blue-para"><a class="suggestedStyle" [routerLink]="['/pages/settings/users']">Invite users</a>
              </p>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <nb-card class="second-card commonClass mb-2" *ngIf="rolename == 'Owner'">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Customize the roles for your users</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Create custom roles to select the rights you would like each user to have. We have
                set some default roles for you. </p>
            </div>
            <div class="invite-link">
              <p class="blue-para"><a class="suggestedStyle" [routerLink]="['/pages/settings/roles']"> Go to Role
                  Management</a></p>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <nb-card class="third-card commonClass mb-2" *ngIf="rolename == 'Owner'">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Adjust your workspace settings</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Modify your time zone, date and time format, audit log duration and more.</p>
            </div>
            <div class="invite-link">
              <p class="blue-para"><a class="suggestedStyle" [routerLink]="['/pages/workspace-admin/ws-management']"> Go
                  to General Settings</a></p>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <nb-card class="fourth-card commonClass mb-2">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Uploading documents</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Get started on your digital journey by uploading documents to your dox2U Workspace
                and taking the first step towards smarter document management
              </p>
            </div>
            <div class="invite-link">
              <p *ngIf="isDocumentUpload" class="blue-para cursor-pointer"><a class="suggestedStyle" appDebounceClick
                  (debounceClick)="upload()" [debounceTime]="300"> Upload Document</a></p>
              <!-- <p *ngIf="!isDocumentUpload" class="blue-para"><a class="suggestedStyle"  nbTooltip= "You are not authorized to upload documents. Contact owner to get access" nbTooltipPlacement="right" nbTooltipStatus="info"   > Upload Document</a></p> -->
            </div>
          </div>
        </nb-card-body>
      </nb-card>


      <nb-card class="fifth-card commonClass mb-2">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Search for & retrieve your documents</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Search for your documents by name, tags, and content as well as advanced search
                parameters such as the owner of the document and the document type.</p>
            </div>

          </div>
        </nb-card-body>
      </nb-card>


      <nb-card class="sixth-card commonClass mb-2">
        <nb-card-body>
          <div class="invite-parent">
            <div class="invite-text-div">
              <p class="invite-para">Trash management</p>
            </div>
            <div class="invite-color">

            </div>
            <div class="invite-para-body">
              <p class="para-body">Manage the space occupied by your trash bin and recover any documents you want to
                retain.</p>
            </div>
            <div class="invite-link">
              <p class="blue-para"><a class="suggestedStyle" [routerLink]="['/pages/documents/trash']">Go to Trash</a>
              </p>
            </div>
          </div>
        </nb-card-body>
      </nb-card>

    </div>


  </div>
</div>
<div *ngIf="searchloader">
  <ngx-second-loader></ngx-second-loader>
</div>
<ngx-guest-user-modal></ngx-guest-user-modal>
<ngx-shared-access #shareChild [ownerEmailID]="ownerEmailID" [allUserLength]="allUserLength"
  [verifierEmailID]="verifierEmailID" [sharedWithUsers]="sharedWithUsers" [isAllUserShare]="isAllUserShare"
  (isSelectedAllUser)="isSelectedAllUser($event)" (sharedGroupCount)="sharedGroupCount($event)"
  (isCheckedAllUser)="isCheckedAllUser($event)" (sharedGroup)="sharedGroup($event)" (isUserGrp)="isUserGrp($event)">
</ngx-shared-access>
<!--------------------- Download Invoke ------------------->
<ngx-download [selectedFileNames]="selectedFileNames" [numberofDocsSelected]="numberofDocsSelected" [doclist]="doclist"
  [selectedDocs]="selectedDocs"></ngx-download>
<!----------------- Delete modal invoke------------------------------>
<ngx-delete-docs [rightUserList]="rightUserList" [selectedFileNames]="selectedFileNames"
  [numberofDocsSelected]="numberofDocsSelected" [doclist]="doclistDelete" [selectedDocs]="selectedDocs"
  (isDeleted)='resetSelected($event)'>
</ngx-delete-docs>
<!------------------------ </nb-layout-column> --------------------------->


<router-outlet></router-outlet>

<!------------------------- modal for the mini profile start  --------------------------->
<div>
  <ng-template #miniprofile let-data let-ref="miniprofile">
    <div class="d-flex justify-content-between float-right cursor-pointer headProfile"
      *ngIf="ownerEmailID === createdBy">
      <p class="headline-6 text-primary mb-0 text-right cursor-pointer" (click)="cancleEdit()" style="z-index: 7;">
        Edit Profile</p>
      <!-- <p class="headline-6 text-primary mb-0 text-right cursor-pointer " (click)="cancleEdit()"  *ngIf="!changeText">Edit Profile</p> -->
    </div>
    <ngx-mini-profile [showdata]="showdata"></ngx-mini-profile>
  </ng-template>
</div>
<!---------------------------------- modal for the mini profile End --------------------------- -->

<!--*******************************  unsaved changes modal starts here**************************************-->
<ng-template #Landingmodal let-data let-ref="dialogRef">
  <nb-card class="invitemodal width-40vw" (click)="Modaldata('clickinside')">
    <nb-card-header class="main-modal-body">
      <div class="landingHeader d-flex align-items-left justify-content-start">
        <p class="landingHeaderPara">
          Almost there!
        </p>
      </div>
    </nb-card-header>
    <nb-card-body>

      <div class="d-flex align-items-center justify-content-center">
        <div class="mainBodyBox">
          <p class="mainBodyPara">
            We are currently pre-commercial, so some of our functionalities may not be up to the mark yet. We would love
            to hear about and learn from your experience
          </p>
        </div>
        <div class="d-flex justify-content-start align-middle">
          <button nbButton status="primary" size="small">OK</button>
        </div>

      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!--*******************************  undsaved changes modal ends here**************************************-->
<ng-template #wsOutSync let-data let-ref="dialogRef">
  <nb-card (click)="Modaldata('clickinside')">
    <nb-card-header>Workspace out of sync
    </nb-card-header>
    <nb-card-body>
      <p class="mt-2 ml-2 mr-2">Your workspace went out of sync. There are some changes made which are not synchronized.
        Please reload this page before you continue.</p>
      <p class="mt-2 ml-2">This action cannot be completed as the document has been
        Deleted.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="textbutton" (click)="reloadScreen();dialogRef.close()"
        size="small">RELOAD</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<ng-template #wsOutSync let-data let-ref="dialogRef">
  <nb-card style="max-width: 40em;">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-primary mb-0"> Workspace out of sync </h6>
      </div>
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic ml-2">Your workspace went out of sync. There are some changes made which are not
        synchronized.
        Please reload this page before you continue.</p>
      <p class="mt-2 paragraph text-basic ml-2">This action cannot be completed as the document has been
        Deleted.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="textbutton" size="small"
        (click)="reloadScreen();dialogRef.close()">RELOAD</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- Transfer Document Modal Start-->
<ng-template #transferOwnership let-data let-ref="dialogRef">
  <nb-card class="invitemodalOwner" (click)="Modaldata('clickinside')">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic"> Transfer document ownership
        </h6>

      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row m-0">
        <div class="col-12 pl-4 pt-2">
          <p class="paragraph text-basic">Choose another user to be the owner of the document.</p>
        </div>
        <div class="col-8 pl-4 m-0">
          <ng-select [items]="ownerTransferUsers" placeholder="Select workspace member" bindLabel="displayName"
            appendTo="body" [closeOnSelect]="true" bindValue="displayName" (change)="onDocumentOwner($event)"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedDocumentOwner">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p class="subtitle text-basic mb-0" title={{item.emailId}}>{{item.displayName}}</p>
            </ng-template>
          </ng-select>
        </div>

      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" [disabled]="selectedDocumentOwner === null" class="invite mr-3"
        (click)="confirmOwnerShipTransfer()" size="small">TRANSFER OWNERSHIP</button>
      <button type="button" class=" button-2 addrolebtn" (click)="closeModal()" size="small">Cancel</button>

    </nb-card-footer>
  </nb-card>
</ng-template>
<!------------------------- Transfer Document Modal Start------------------------->
<!---------------------------------- Delete Document Modal Start-------------------------------->
<ng-template #deleteDocs let-data let-ref="dialogRef">
  <nb-card class="modalnbdelete" (click)="Modaldata('clickinside')">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Delete document(s)
        </h6>


      </div>
    </nb-card-header>
    <nb-card-body class="padding-25">
      <p class="paragraph text-basic">Choose a reason for deleting selected document(s) to keep other users in your
        workspace updated.</p>
      <p class="text-primary paragraph">{{selectedDocs.length}} document(s) selected</p>
      <nb-radio-group [(ngModel)]="selectedOption ">
        <nb-radio value="Poor scan quality" (click)="valueChange('Duplicate document')">
          <p class="deleteradiobtn P1 paragraph mb-0">Poor scan quality</p>
        </nb-radio>
        <nb-radio value="Incorrect document" (click)="valueChange('Duplicate document')">
          <p class="deleteradiobtn P1 paragraph mb-0">Incorrect document</p>
        </nb-radio>
        <nb-radio value="Duplicate document" (click)="valueChange('Duplicate document')">
          <p class="deleteradiobtn P1 paragraph mb-0">Duplicate document</p>
        </nb-radio>
        <nb-radio value="Other" (click)="valueChange('Other')">
          <p class="deleteradiobtn P1 paragraph mb-0">Other</p>
        </nb-radio>

      </nb-radio-group>
      <input nbInput id="OtherReason" #OtherReason maxlength="75" placeholder="Reason for deleting document (optional)"
        class="d-block" style="width: 60%;" *ngIf="Otherreason">
    </nb-card-body>
    <nb-card-footer>

      <button id="deleteModal" class="mr-3" status="primary" nbButton
        (click)="DeleteDocsModalResion(); ref.close();isdeleteSelectedOnce=false;HideViewDetailresponseForDelete=true;"
        size="small">DELETE</button>
      <button nbButton (click)="ref.close();HideViewDetailresponseForDelete=true" status="control"
        size="small">CANCEL</button>

    </nb-card-footer>

  </nb-card>
</ng-template>
<!----------------------------------------- Delete Document Modal end--------------------------------------->

<!--------------------------------------Change Docs verifier start here----------------------------------->

<ng-template #chngVerifier chng-verify let-data let-ref="dialogRef">
  <nb-card class="chng-verify" (click)="Modaldata('clickinside')">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-primary">
          Change verifier
        </h6>


      </div>
    </nb-card-header>

    <nb-card-body>
      <p class="paragraph text-basic ml-3 mr-5 mt-2">Please select a user to make them the verifier of selected
        document(s).</p>

      <div class="row ml-3 pb-3">
        <div class="col-8 pl-0">
          <ng-select [items]="filteredRightUserList" placeholder="Select user..." bindLabel="displayName"
            [closeOnSelect]="true" appendTo="body" bindValue="displayName" (change)="onChangeVerifier($event)"
            [ngModelOptions]="{standalone: true}" [ngModel]="selectedOwner?.displayName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p class="subtitle text-basic mb-0" title={{item.emailId}}>{{item.displayName}}</p>
            </ng-template>
          </ng-select>
        </div>
      </div>


    </nb-card-body>
    <nb-card-footer>
      <button class="mr-3" status="primary" nbButton
        (click)="onDocumentVerifierUpdate(); ref.close();isSelectedOnce = false" size="small">CHANGE VERIFIER</button>
      <button nbButton (click)="ref.close();isSelectedOnce = false; filteredRightUserList = rightUserList;" size="small" outline status="primary"
        size="small">CANCEL</button>
    </nb-card-footer>
  </nb-card>

</ng-template>

<!------------------------------Change Docs verifier end here------------------------------------->


<!---------------------------------------- print Invoke ------------------------->
<ngx-print-document [selecteddDocument]="selectedDocs" [numberofDocsSelected]="numberofDocsSelected"
  [rightUserList]="rightUserList" [selectedFileNames]="selectedFileNames" [doclist]="doclist"></ngx-print-document>