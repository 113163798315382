import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ApiServiceService } from '../api-service.service';

import 'rxjs/add/observable/of';
import 'rxjs/Rx';
@Injectable({
  providedIn: 'root'
})
export class NormalSearchService {

    constructor(private http: HttpClient,private apiService:ApiServiceService) {
  } 
  public elasticdbaseurl = environment.elasticdbaseurl;
  public baseOrigin = environment.baseOrigin;
  public baseURL = environment.baseUrl;
 

  SearchNormalFilter(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
    });
  
    headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')
  
    return this.http.post(this.elasticdbaseurl + "/api/Elastic/AllDocumentSearching", data,{headers: headers});
   
  }


  withContains(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
    });
  
    headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')
  
    return this.http.post(this.elasticdbaseurl + "/api/Elastic/DocumentSearchingDoc_Count_With_Contain", data,{headers: headers});
   
  }

  withoutContains(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
    });
  
    headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')
  
    return this.http.post(this.elasticdbaseurl + "/api/Elastic/DocumentSearchingDoc_Count_With_out_Contain", data,{headers: headers});
   
  }

  normalSearchData(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
    });
  
    headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')
  
    return this.http.post(this.elasticdbaseurl + "/api/Elastic/NormalSearchingDocCount", data,{headers: headers});
   
  }

  advanceSearchData(data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'APIKey': '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic'
    });
  
    headers = headers.set('APIKey', '20uyuiwqedqdnkcziiu878236863103,d90dsaldjalcjm9_sadak!dlsadjc9ic')
  
    return this.http.post(this.elasticdbaseurl + "/api/Elastic/AdvanceSearchingDocCount", data,{headers: headers});
   
  }
  
}
