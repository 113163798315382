
<ng-template #template >
  <nb-card class="myclass"  [nbSpinner]="loading">
    <nb-card-header>
      <div >
        <p  class="subtitle text-basic mb-0">Template Editor  <button (click)="createUnhandledModal(unhandleChanges)" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <nb-icon icon="close-outline"  class="cursor-pointer"></nb-icon>
          </span>
        </button>
      </p>
      </div>
    </nb-card-header>
    <nb-card-body class="pr-0 overflow-hidden">
      <div class="row m-0">
        <div class="col-3 templateleft pl-0">
          <button nbButton status="primary" class="createBtn" size="small"  (click)="openUnhandledModal(unhandleChanges)" style="width: 100% !important;">
            <nb-icon icon="plus-circle-outline"></nb-icon>Create New Template</button>
            <!-- <p *ngIf="_tagTempList.length !== 1" class="subtitle text-hint mt-4 mb-2"> TEMPLATES</p>
          <div *ngIf="_tagTempList.length == 1" class="notempfound pl-2 pr-2 mt-5">There are currently no templates in
            your workspace.</div> -->
            <!-- Template.length is used for template editor to invoke from bulk tag  -->
            <p *ngIf="_tagTempList.length !== 1||template.length !==1" class="subtitle text-hint mt-4 mb-2"> TEMPLATES</p>
          <div *ngIf="_tagTempList.length == 1 ||template.length ==1" class="notempfound pl-2 pr-2 mt-5">There are currently no templates in
            your workspace.</div>


          <ul class="list-unstyled pt-2 mb-0">
            <div class="customScroll">
            <li class="btn-group d-flex justify-content-between cursor-pointer"
             [ngClass]="((selectedTemplate?.tempName === tagTemplate?.tempName && selectedTemplate?.tempName !== 'No Template') || (selectedTemplates?.tempName === tagTemplate?.tempName &&  selectedTemplate?.tempName === 'No Template'))?'textColor':''"
             *ngFor="let tagTemplate of _tagTempList" dropdown >
              <div *ngIf="tagTemplate.tempName != 'No Template'" title="{{tagTemplate.tempName}}" class="visible w-100">
              <p (click)="handleUnsavedChanges(tagTemplate,unhandleChanges)" class="mb-0">
                <label class="template-label">
                  <span  class="template-name subtitle text-hint cursor-pointer"  [ngClass]="((selectedTemplate?.tempName === tagTemplate?.tempName && selectedTemplate?.tempName !== 'No Template') || (selectedTemplates?.tempName === tagTemplate?.tempName &&  selectedTemplate?.tempName === 'No Template'))?'textColor':''">{{tagTemplate.tempName}}</span>                 </label>
              </p>
              </div>
                  <div *ngIf="tagTemplate.tempName != 'No Template'">
                <nb-icon  icon="more-vertical-outline" id="button-basic" dropdownToggle type="button"
                  aria-controls="dropdown-basic" class="dropdown-toggle" (click)="closeOtherMenus(tagTemplate)"></nb-icon>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" [ngClass]="{'hide-style': !tagTemplate?.showMenu}">
                  <li role="menuitem"><a class="dropdown-item" id="openDeleteModalNew"
                      (click)="openDeleteModal(deleteTemplate,tagTemplate)">
                      <nb-icon  icon="trash-2-outline"></nb-icon > Delete
                    </a></li>

                </ul>
              </div>
            </li>
            </div>
          </ul>
        </div>
        <div class="col-9 TempEditRight pr-0" >

          <form [formGroup]="TemplateEditor">
            <div class="title w-75">
              <input  nbInput type="text" fieldSize="small" placeholder="Template title" required formControlName="title" name="title"
                (keyup)="onInputChange()" maxlength="25" class="tpt-width" fullWidth>
              <div class="row pb-2">
                <div class="text-left col-8 errorMsg caption text-danger"
                  *ngIf="TemplateEditor?.controls.title?.errors?.required && isSubmitPressed">Title cannot be blank</div>
              </div>
              <div class="row pb-2">
                <div class="text-left col-8 errorMsg caption text-danger" *ngIf="showErrorMessage">{{errorMessage}} </div>
              </div>
            </div>
            <div class="description w-75">
              <input  nbInput type="text" placeholder="Description" fullWidth fieldSize="small" formControlName="description" name="description"
                maxlength="500"  class="tpt-width">
            </div>
            <div class="AddTag btn-group" dropdown>
              <p  class="caption-2 text-primary align-middle mb-0" id="button-basic" dropdownToggle type="button" aria-controls="dropdown-basic">
                <nb-icon icon="pricetags-outline"></nb-icon>ADD TAG<nb-icon icon="arrow-ios-downward-outline" class="align-middle"></nb-icon>
              </p>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem" class="dropdown-item cursor-pointer" (click)="addEditTemplateControl(1)">
                  <nb-icon icon="file-outline" class="mr-2"></nb-icon> Text box
                </li>
                <li role="menuitem" type="date" class="dropdown-item cursor-pointer" (click)="addEditTemplateControl(2)">
                  <nb-icon icon="calendar-outline" class="mr-2"></nb-icon>Date picker
                </li>
                <li role="menuitem" class="dropdown-item cursor-pointer" (click)="addEditTemplateControl(3)">
                  <nb-icon icon="download-outline" class="mr-2"></nb-icon>Drop-down
                </li>

              </ul>

            </div>

            <hr class="mt-0 mrHr">


            <div class="CustomForm" id="TemplateEditorCustomForm">
              <ng-container *ngFor="let inputBox of editTemplateInput; let i = index;" class="d-flex">
               <div class="d-flex">
                <div class="col-11 p-0">
                  <div class="col-12 mb-3 input pl-0 pr-0" *ngIf="inputBox.inputType == 'text'">
                    <div class="form-group d-flex  m-0" >
                      <div class="col-5 TempEditLabel pl-0 pr-0">
                        <input  nbInput class="form-dox paragraph text-hint" fullWidth type="text" fieldSize="small" maxlength="100" required placeholder="Text"  (input)="clickInputData($event)"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="inputBox.tagTempLabel"
                          id="{{inputBox.tagTempLabel}}" (ngModelChange)="editTemplateInputChange($event)" />

                        <p class="errorMsg pull-right caption text-danger" *ngIf="!inputBox.tagTempLabel && isSubmitPressed">Field cannot be blank</p>

                      </div>
                      <span class="align-middle ml-3 mr-3"> : </span>
                      <div class="col-6 input-tagtemp TempEditValue pl-0 pr-0">
                        <input nbInput  class="form-dox paragraph text-hint" fullWidth fieldSize="small" type="{{inputBox.inputType}}" maxlength="25"
                          placeholder="Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="inputBox.tagTempValue"
                          id="{{inputBox.tagTempLabel}}" class="disabled" ng-model />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3 input pl-0 pr-0" *ngIf="inputBox.inputType == 'date'">
                    <div class="form-group d-flex  m-0" >
                      <div class="col-5 TempEditLabel pl-0 pr-0">
                        <input  nbInput class="form-dox paragraph text-hint" fullWidth type="text" fieldSize="small" maxlength="100" required placeholder="Text"  (input)="clickInputData($event)"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="inputBox.tagTempLabel"
                          id="{{inputBox.tagTempLabel}}" (ngModelChange)="editTemplateInputChange($event)" />

                        <p class="errorMsg pull-right caption text-danger" *ngIf="!inputBox.tagTempLabel && isSubmitPressed">Field cannot be blank</p>

                      </div>
                      <span class="align-middle ml-3 mr-3"> : </span>
                      <div class="col-6 input-tagtemp TempEditValue pl-0 pr-0">

                          <input nbInput readonly fieldSize="small" fullWidth class="form-dox paragraph text-hint" size="small"
                          [ngModelOptions]="{standalone: true}" placeholder="Answer"
                          [(ngModel)]="inputBox.tagTempValue"
                          id="{{inputBox.tagTempLabel}}" [nbDatepicker]="dateTimePicker" />
                        <nb-datepicker #dateTimePicker>
                        </nb-datepicker>
                      </div>
                    </div>
                  </div>


                  <div class="col-12 mb-3 input pl-0 pr-0" *ngIf="inputBox.inputType == 'dropdown'">
                    <div class="form-group d-flex m-0  labelform">
                      <div class="col-5 TempEditLabel pl-0 pr-0">
                        <input nbInput  class="form-dox w-100 paragraph text-hint" type="text" fieldSize="small" maxlength="100" required placeholder="Drop-down"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="inputBox.tagTempLabel"  (ngModelChange)="editTemplateInputChange($event)"
                          id="{{inputBox.tagTempLabel}}" />
                        <p class="errorMsg pull-right caption text-danger" *ngIf="!inputBox.tagTempLabel  && isSubmitPressed">Field cannot be blank</p>
                      </div>
                      <span class="align-middle ml-3 mr-3"> : </span>
                      <div class="col-6 TempEditValue pr-0">

                        <div *ngFor="let in of inputBox.options; let j = index;trackBy:trackByIdx"
                          class="mb-3 position-relative d-flex justify-content-between">
                          <span class=" position-absolute pt-1 countOption">{{j+1}}</span>
                           <input nbInput class="position-relative form-dox w-100" fieldSize="small"  (ngModelChange)="editTemplateInputChange(inputBox.options[j])"  (keydown)="clickInputData($event)"
                            type="text" maxlength="100" placeholder="Option {{j+1}}" required
                            [(ngModel)]="inputBox.options[j]" [ngModelOptions]="{standalone: true}"
                            [value]="inputBox.options[j]" />
                            <nb-icon icon="close-outline" class="delete position-absolute cursor-pointer mt-1 d-none"  (click)="deleteOption(i, j)"></nb-icon>
                        </div>

                        <button nbButton id="adoption" class="add-option pl-0 pt-0" (click)="AddOption(i)" [ngClass]="AddOptionDisabled ? 'deleteOption' : '' ">
                          <nb-icon icon="plus-circle-outline"></nb-icon> Add Option
                        </button>

                      </div>
                    </div>

                  </div>


                  <!-- <div class="col-1 custom-control custom-switch mt-1">
                      <input nbInput type="checkbox" [checked]="inputBox.isRequired"
                      (change)="updateRequiredflag(inputBox)" class="custom-control-input"
                      id="customSwitch_{{i}}" name="IsRequired">
                    <label class="custom-control-label inbox-label" for="customSwitch_{{i}}"></label>
                  </div> -->

                </div>
                <div class="pl-0 delete-tag ml-0 cursor-pointer  pr-0 col-1 d-flex justify-content-center">
                  <nb-icon icon="close-outline" (click)="removeEditTemplateInput(i,unhandleChanges)" class="ml-0 mb-1 mt-1 mr-2"></nb-icon>
                  <!-- <div class="border-right h-20"></div>
                  <span class="detele-form ml-2 caption-2 text-primary pt-2">Required</span> -->
                </div>
               </div>
              </ng-container>
           </div>

          </form>

        </div>

      </div>
    </nb-card-body>
    <nb-card-footer class="d-flex">

      <button nbButton status="primary" class="mr-2"  (click)="saveTemplate(false,true);clickValue();" size="small"  [disabled]="!TemplateEditor.valid || isNotValid() || getValue" >SAVE TEMPLATE</button>
      <button  class="controlbtndelt"  (click)=" createUnhandledModal(unhandleChanges)" >EXIT EDITOR</button>
      <p *ngIf="isSavedPressed" class="mb-0 subtitle-2 text-primary d-flex justify-content-center pt-1 ml-4">
        <nb-icon icon="checkmark-circle-2-outline"></nb-icon> Template Saved.
      </p>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- ----------------------------delete modal-------------- -->
<ng-template #deleteTemplate>
  <nb-card>
  <nb-card-header>
  <div>
    <h6 class="headline-5 text-basic ">
     Delete template
    <button type="button" class="close pull-right pt-2 d-flex justify-content-end" aria-label="Close">
        <nb-icon icon="close-outline"  (click)="modalDeleteRef.close()"></nb-icon>
    </button>
  </h6>
  </div>
</nb-card-header>
<nb-card-body>
    <p class="mb-0">Do you want to delete this template? </p>
    <p class="mb-0">The template will no longer be available to any user of your workspace.</p>
</nb-card-body>
<nb-card-footer>
    <button nbButton status="primary" class="textbutton mr-2" (click)="verifyDeleteTemplate(); modalDeleteRef.close()" size="small">Delete</button>
    <button nbButton (click)="modalDeleteRef.close()" class="controlbtndelt">Cancel</button>
</nb-card-footer>
</nb-card>
</ng-template>
<!-------------------------------------------unhandle changes model-------------------------------------------------------------- -->
<ng-template #unhandleChanges>
  <nb-card>
    <nb-card-header>
  <div>
    <h6 class="headline-5 text-basic ">
     Discard changes
    <button type="button" class="close pull-right" aria-label="Close">
        <!-- <nb-icon icon="close-outline" (click)="modalunhandleRef.close()"></nb-icon> -->
      </button>
    </h6>
  </div>
</nb-card-header>
<nb-card-body>
    <p class="mb-0">Do you want to save the changes made to your document?</p>
    <p class="mb-0"> Your changes will be discarded if you leave this page without saving them.</p>
</nb-card-body>
<nb-card-footer>
  <button nbButton status="primary" (click)="discardTempChanges()" class="mr-2" size="small">discard</button>
  <button nbButton status="primary"  (click)="saveTemplate(true,false);modalunhandleRef.close()"  class="mr-2 controlbtndelt">save</button>
  <button nbButton (click)="modalunhandleRef.close()" class="controlbtndelt">Cancel</button>
</nb-card-footer>
</nb-card>
</ng-template>
<!---------------------------------------------------------------------------------------------------------------- -->
