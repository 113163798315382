import { Observable } from 'rxjs';
/**
 *  UserActive 
 */
export interface UserActive {
    /**
      *  variable for date
   */
  date: string;
    /**
      *  variable for pagesVisitCount
   */
  pagesVisitCount: number;
    /**
      *  variable for deltaUp
   */
  deltaUp: boolean;
    /**
      *  variable for newVisits
   */
  newVisits: number;
}
   /**
      *  export for UserActivityData
   */
export abstract class UserActivityData {
     /**
 *  abstract method for getUserActivityData
 */
  abstract getUserActivityData(period: string): Observable<UserActive[]>;
}
