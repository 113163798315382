import { Observable } from 'rxjs';
/**
 *  profit chart
 */
export abstract class ProfitBarAnimationChartData {
  /**
 *  abstract method for get chart data
 */
  abstract getChartData(): Observable<{ firstLine: number[]; secondLine: number[]; }>;
}
