import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../@core/utils/shared.service';

@Component({
  selector: 'ngx-share-guets-rendered',
  templateUrl: './share-guets-rendered.component.html',
  styleUrls: ['./share-guets-rendered.component.scss']
})
export class ShareGuetsRenderedComponent implements OnInit {

  params: any;
  dpagesValue: any;
  data: any;
  shareDoc: any;
  shareDocCount: any;
  
  subscriptionmini: any;
  sharedData: unknown;
  guestAccess: string;
  GuestFlag: string;

  constructor(private sharedService : SharedService) {
    this.GuestFlag = localStorage.getItem('GuestFlag')

   }

  ngOnInit(): void {
    this.subscriptionmini = this.sharedService.sharedWithGuestIcon.subscribe((data) => {
      this.sharedData = data;
      if(this.data.docGuid === this.sharedData[0]){
        this.shareDocCount = this.sharedData[1];
        this.shareDoc = this.sharedData[1];

      }
    });
  } 
    /*
    *Adding document size icon in the lists
   */

    agInit(params:any){
      this.data=params?.data;
      this.shareDoc = this?.data?.shareDocCount;

      this.shareDocCount = this?.data?.shareDocCount;

    }

}
