import { Component,  } from '@angular/core';
import { SharedService } from '../../../@core/utils/shared.service'

@Component({
  selector: 'ngx-text-status',
  templateUrl: './text-status.component.html',
  styleUrls: ['./text-status.component.scss']
})
export class TextStatusComponent  {

  params: any;
  data : any;
  status : any;
  parserstatus : any;

  constructor(private sharedService : SharedService) { }
  textparser;
  textparserws;
  ngOnInit(): void {

    this.textparser = this.sharedService.parserreal.subscribe((data:any) => {

      if(this.guid == data.docGuid){
        this.parserrealchange(data);
      }
    });

  }
guid;
notExtractedStatus = '';
  runningmodule;
  agInit(params:any){
    this.params=params; 
    this.guid = params?.data.docGuid
     
    this.data = this.params?.rowData
    this.status=params?.value;
    this.notExtractedStatus=params?.data.parserapirequest;
    this.runningmodule = params?.data.running_module_code;

    if(this.status == 'doc_08'){
      this.parserstatus = 'Success';
    }
    else if(this.status=='doc_10'||this.status=='doc_11'||this.status=='doc_12'||this.status=='doc_13'||this.status=='doc_14'||this.status=='doc_15'||this.status=='doc_00'){
      this.parserstatus='Failure'
    }
    else{
      this.parserstatus = 'In queue'
    }
  }

  parserrealchange(params:any){
    this.status = params?.parseStatus;
    this.runningmodule = params?.running_module_code;
    if(this.status == 'doc_08'){
      this.parserstatus = 'Success';
    }
    else if(this.status=='doc_10'||this.status=='doc_11'||this.status=='doc_12'||this.status=='doc_13'||this.status=='doc_14'||this.status=='doc_15'||this.status=='doc_00'){
      this.parserstatus='Failure'
    }
    else{
      this.parserstatus = 'In queue'
    }

  
  }



}
