import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { StatsBarData } from '../data/stats-bar';

@Injectable()
export class StatsBarService extends StatsBarData {

 

  getStatsBarData(statsBarDatavalue): Observable<string[]> {
    return observableOf(statsBarDatavalue);
  }
}
