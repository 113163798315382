import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { VerifyDocumentComponent } from './verification/verify-document/verify-document.component';
import { AuthGuard } from './@core/Guard/auth.guard';
import { LoginRegisterComponent } from './onboarding/login-register/login-register.component';
import { ResponseComponent } from './verification/response/response.component';


export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: 'onboarding',
  loadChildren: () => import('./onboarding/onboarding.module')
  .then(m => m.OnboardingModule)
  },
  { path: 'verification', loadChildren: () =>
   import('./verification/verification.module')
   .then(m => m.VerificationModule) },

   { path: 'view', loadChildren: () =>
   import('./verification/verification.module')
   .then(m => m.VerificationModule) },
   { path: 'pages/documents/dockets', loadChildren: () =>
   import('./verification/verification.module')
   .then(m => m.VerificationModule) },
   { path: 'edit', loadChildren: () =>
   import('./verification/verification.module')
   .then(m => m.VerificationModule) },
   { path: 'my-trash', loadChildren: () =>
   import('./verification/verification.module')
   .then(m => m.VerificationModule) },
   { path: 'error', loadChildren: () =>
   import('./error-page/error-page.module')
   .then(m => m.ErrorPageModule) },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: '/onboarding/sign-in', pathMatch: 'full' },
  { path: '**', redirectTo: '/onboarding/sign-in' },
  { path: 'verification/verify-document?docId=value1&docGuid=value2', component: VerifyDocumentComponent , canActivate: [AuthGuard]},
  { path: 'view/view-document?docId=value1&docGuid=value2', component: VerifyDocumentComponent , canActivate: [AuthGuard]},
  { path: 'pages/documents/dockets/:abc/NEW', component: ResponseComponent , canActivate: [AuthGuard]},
  { path: 'pages/documents/dockets/:abc/UID/:uid', component: ResponseComponent , canActivate: [AuthGuard]},
  { path: 'onboarding/create-workspace?email=value1&name=value2&org=value3', component: LoginRegisterComponent },
  { path: 'edit/edit-document?docId=value1&docGuid=value2', component: VerifyDocumentComponent , canActivate: [AuthGuard]},
  { path: 'my-trash/my-trash-document?docId=value1&docGuid=value2', component: VerifyDocumentComponent , canActivate: [AuthGuard]},

  { path: 'all-workspace', loadChildren: () => import('./pages/all-workspace/all-workspace.module').then(m => m.AllWorkspaceModule) },
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}


