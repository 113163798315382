import { Component, OnInit } from '@angular/core';
/**
 * The  skeleton Loading Components for Admin section
 */
@Component({
  selector: 'ngx-second-loader',
  templateUrl: './second-loader.component.html',
  styleUrls: ['./second-loader.component.scss']
})
/**
 * loading export
 */
export class SecondLoaderComponent implements OnInit {
/**
 * git ignore  onInit
 */
  constructor() { }
/**
 * git ignore  onInit
 */
  ngOnInit(): void {
  }

}
