<!-- Support and help section -->
<app-support></app-support>
<div class="row">

  <div *ngIf="!hideDiv" class="col-3 pr-0 leftHead">
    <div class="header-container d-md-flex d-xl-flex d-lg-flex d-sm-flex">
      <div class="logo-container pl-0">
        <span [hidden]="href == '/error/error-page' || verifyurl">
          <a (click)="toggleSidebar()" href="#" class="sidebar-toggle pr-0 d-md-flex d-xl-flex d-lg-flex d-sm-flex">
            <nb-icon class="menu2outline1" icon="menu-2-outline"></nb-icon>
          </a>
        </span>
        <a class="logo pl-md-3 pl-lg-3 pl-xl-3 pl-0 pt-1 pt-md-0 pt-lg-0 pt-xl-0" (click)="navigateHome();getSelectedItem();"><img
            src="../../../../assets/dox2u_logo.svg" alt="" /></a>

      </div>

      <!-- <div class="breadcrumb-section d-none d-xl-flex d-xl-block cursor-pointer " *ngIf="href === '/error/error-page'">
        <nb-icon class="icon-color mb-0 homeoutline" icon="home-outline" (click)="loctiondirect()"> </nb-icon>
        <nb-icon class="breadcrumb-color" icon="arrow-ios-forward-outline"></nb-icon>
        <p class="paragraph text-hint  mb-0">Error Page</p>
      </div> -->
      <!-- For BreadCrumbs -->


    </div>
  </div>
  <div class="col-9 d-flex belldata pl-0">
    <div class="header-container justify-content-between w-100">

      <!-- TRial/grace period -->
      <!-- <p class="caption-2 text-basic mb-0 pr-3 grace-period d-none d-xl-flex d-xl-block">{{wsSubscriptionRemainDaysCount}}  days in trial</p> -->

      <!-- Global Search -->
      <!-- <span [hidden]="verifyurl"> -->
      <nb-form-field *ngIf="!hideDiv" class="max-width-20 global-search-bar mr-auto ml-auto  w-25-md w-100 position-relative">
        <nb-icon class="position-relative" nbPrefix icon="search-outline" pack="eva" [hidden]="verifyurl"></nb-icon>
        <input #headerSearch class="pr-5  max-width-Input headerSearch" (mouseover)="HandleFlags()" type="text" nbInput small shape="round"  autocomplete="off"
          name="searchingTempdatatemp" autocomplete="off"
          placeholder="Search by name, tags, notes, content" (focus)="onFocus()" (blur)="onBlur()" (change)="searchInputChangeAddNormal('ngOnInit');"
          [(ngModel)]="NormalSearchValue"  (keypress)="removeLanguage($event);alphacheck($event);" (keyup)="alphacheck($event);"
          (keyup.enter)="searchInputChange('ngOnInit')"  (paste)="onPasteName($event)" [hidden]="verifyurl" >
        <div class="position-absolute btnSuffixIcon d-flex justify-content-between">
          <div class="" [hidden]="verifyurl">
            <button  nbSuffix nbButton ghost class=" border-0 shadow-none cursor-pointer closeSearch p-0 ghostbtn"
              *ngIf="NormalSearchValue?.length > 0" (click)="emptyNormalSearch();ResetClickAdv();">
              <nb-icon icon="close-outline" class="crossIcon"></nb-icon>
            </button>
          </div>
          <div>
            <button class="position-relative cursor-pointer pl-0 filterGlobal text-hint" [hidden]="verifyurl">
              <nb-icon icon="options-2-outline" (click)="filterToggle('toggleclick')" id="searchDeep" pack="eva"
                [ngClass]="{'iconblue':advFilterDropdown}">
              </nb-icon>
            </button>
          </div>
        </div>
      </nb-form-field>
      <!-- </span> -->

      <!-- For Notifications -->

      <div class="d-flex align-items-center">


       <div *ngIf="!hideDiv" style="width:105px;" [hidden]="!checkListCompleted">
        <div class="d-flex " *ngIf="menuRights == 'Owner'" (click)="inviteTour()" style="cursor: pointer !important;">
          <img src="./../../../../assets/images/helicopter.svg" style="width: 24px; height:24px;cursor: pointer !important; " >
          <p class="Text Font/S1 Subtitle mb-0 ml-1" style="cursor: pointer !important;" >Get Started</p>
        </div>
        <div style="position: relative !important;
        max-width: unset;
        box-shadow: none !important;
        right: 115px !important;
        top: 32px !important;" #val [hidden]= "show_getstarted" > </div>

       </div>
        <!-- For support/help forms -->

        <nb-actions *ngIf="!hideDiv" size="medium" class="d-none d-md-block d-lg-block d-xl-block">
          <nb-action *ngIf="!hideDiv" class=" text-basic pl-2 pr-0 cursor-pointer" (click)="supportHelp();">
            <div class="menuListParent">
              <!-- <p class="helpsupport" id="itemValueId" (click)="itemValue()">Help & Support</p> -->
              <!-- <nb-icon class="icon-color helpsupporticon"  icon="question-mark-circle-outline" id="itemValueId" (click)="itemValue()"> </nb-icon> -->
              <!-- [ngClass]="isMenu ? 'colorblue': ' '" -->

              <button class="viewclass1 pr-0" [ngClass]="isMenu ? 'colorblue': 'colorGrey'"  id="itemValueId" (click)="itemValue()">
                <nb-icon class=" btnhelpsupporticon p-0 text-basic" icon="question-mark-circle-outline" > </nb-icon>
              </button>
              <div class="menuList d-flex flex-column" *ngIf="isMenu"  id="closeMe" (click)="clickMehere('closeMe')">

                <div class="d-flex help_supportIcon
             " style="width: 100%;" (click)="openHelp()">
                  <nb-icon  icon="file-text-outline"   class="p-0 mr-3 iconWhite" style="height: 20px; width:24px;"></nb-icon>
                  <p class="mb-0 py-1">Help & support</p>
                </div>

                <div class="d-flex help_supportIcon
             " style="width: 100%;" (click)="openSupport()">
                  <nb-icon icon="question-mark-circle-outline" class=" mr-3 p-0 iconWhite" style="height: 20px; width:24px;"></nb-icon>
                  <p class="mb-0 py-1">Support ticket</p>
                </div>
              </div>
            </div>

            <!-- s -->
          </nb-action>

        </nb-actions>

        <button *ngIf="!hideDiv" ghost nbButton type="button"
          class="d-none d-md-block d-lg-block d-xl-block size-large notificationBtn viewclass" id="notificationid"
          (click)="opennotification()">
          <nb-action class="notification-section " [ngClass]="{'icon-active' : !hidenotify}" *ngIf="budgecount"
            icon="bell-outline" badgeDot badgePosition="top right" badgeStatus="primary"> </nb-action>
          <nb-action class="notification-section" [ngClass]="{'icon-active' : !hidenotify}" *ngIf="!budgecount"
            icon="bell-outline" badgePosition="top right" badgeStatus="primary"> </nb-action>
        </button>
            <!-- home -->
            <button *ngIf="!hideDiv" ghost nbButton class="viewclass mr-2" (click)="loctiondirect();getSelectedItem();">
              <nb-icon class="icon-color mb-0 rightIcon " icon="home-outline"> </nb-icon>
            </button>
            <!-- home -->

        <!-- Profile card section -->
        <nb-card class="p-2 mb-0 cursor-pointer " [ngClass]="verifyurl ? 'mr-3' : ''" id="toggleid" (click)="toggle();">
          <div class="d-flex">
            <nb-card *ngIf="showIconCrad" class="mb-0 border-0 shadow-none mr-2 d-none d-lg-block d-xl-block">
              <a >
                <img id="showIcon" [src]="imageUrl" class="image"  height="32" alt="" *ngIf="imageUrl">
              </a>
            </nb-card>

            <nb-card class="mb-0 rounded-circle p-0 cursor-pointer">
              <nb-action class="avatarIcon" *ngIf="profileImagePath !== null && profileImagePath !== undefined"> <img
                  id="showAvatar" [src]="loginProfilImage" class="image" width="50px" height="30" alt=""
                  style="border: 1px solid var(--color-primary-500); border-radius: 65px;"></nb-action>
              <nb-action class="avatarIcon" *ngIf="profileImagePath == null || profileImagePath == undefined"> <img
                  id="showAvatar" src="../../../../assets/images/Default_Profile_Photo.svg" class="image" width="50px"
                  height="30" alt="" style="border: 1px solid var(--color-primary-500); border-radius: 65px;">
              </nb-action>
            </nb-card>
          </div>
        </nb-card>

      </div>

    </div>
  </div>

</div>
<!-- notification modal start from here -->

<div [hidden]="hidenotify" class="notify" (click)="noteclick('notifyClose')" ngxClickOutside>
  <nb-card class="NotificationParent">
    <nb-card-header>

      <div class="row">
        <div class="col-8 headline-6 text-basic notifyheader mt-2">
          Notifications ({{totalnotification}})
        </div>
        <div class="col-2 pr-3">
          <button ghost nbButton type="button" style="box-shadow: none; outline: none; border: none;" (click)="notifyActions('MarkASREAD')">
            <nb-icon icon="checkmark-square-outline" nbTooltip="Mark all as read" nbTooltipPlacement="bottom"
              nbTooltipStatus="info">
            </nb-icon>
          </button>


        </div>

        <div class="col-2 pl-0">
          <button ghost nbButton type="button" style="box-shadow: none; outline: none; border:none ;" (click)="notifyActions('DELETE')">
            <nb-icon icon="trash-2-outline" nbTooltip="Delete all" nbTooltipPlacement="bottom" nbTooltipStatus="info"
              >
            </nb-icon>
          </button>

        </div>

        <div>
        </div>
      </div>

    </nb-card-header>

    <nb-card-body class="p-0">
      <!-- skeleton Loader in Notification start here -->
      <div class="mt-3 parentSkeletonLoader" *ngIf="showSpinnernotification">
        <div class="mt-3 d-flex flex-column border-bottom">
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
           'border-radius': '98px',
           height: '17px',
           width: '133px',
           'margin-bottom': '20px',
           'margin-left': '24px',
           border: '1px solid white'
         }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
          'border-radius': '98px',
          height: '17px',
          width: '303px',
          'margin-bottom': '5px',
          'margin-left': '24px',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
          'border-radius': '98px',
          height: '17px',
          width: '262px',
          'margin-bottom': '5px',
          'margin-left': '24px',

          border: '1px solid white'
        }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
          'border-radius': '98px',
          height: '17px',
          width: '133px',
          'margin-bottom': '5px',
          'margin-left': '24px',
          border: '1px solid white'
        }"></ngx-skeleton-loader>

        </div>
        <div class="mt-3 d-flex flex-column border-bottom">
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
          'border-radius': '98px',
          height: '17px',
          width: '133px',
          'margin-bottom': '20px',
          'margin-left': '24px',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '303px',
         'margin-bottom': '5px',
         'margin-left': '24px',
         border: '1px solid white'
       }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '262px',
         'margin-bottom': '5px',
         'margin-left': '24px',

         border: '1px solid white'
       }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '133px',
         'margin-bottom': '5px',
         'margin-left': '24px',
         border: '1px solid white'
       }"></ngx-skeleton-loader>

        </div>

        <div class="mt-3 d-flex flex-column">
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
          'border-radius': '98px',
          height: '17px',
          width: '133px',
          'margin-bottom': '20px',
          'margin-left': '24px',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '303px',
         'margin-bottom': '5px',
         'margin-left': '24px',
         border: '1px solid white'
       }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '262px',
         'margin-bottom': '5px',
         'margin-left': '24px',

         border: '1px solid white'
       }"></ngx-skeleton-loader>
          <ngx-skeleton-loader class="emailFirstRow" [theme]="{
         'border-radius': '98px',
         height: '17px',
         width: '133px',
         'margin-bottom': '36px',
         'margin-left': '24px',
         border: '1px solid white'
       }"></ngx-skeleton-loader>

        </div>
      </div>

      <!-- skeleton Loader in Notification end here -->
      <div class="ml-auto mr-auto mt-3 mb-3 undostyle" *ngIf="checkundobtn && !showSpinnernotification ">
        <span class="undobtn pr-0">
          Notifications deleted.
        </span>
        <button nbbutton class="pl-0 cursor-pointer shadow-none undo-btn"> <span
            class="ml-1 subtitle text-primary cursor-pointer" (click)="undodata()"> Undo</span></button>

      </div>
      <div class="ml-auto mr-auto mt-2 mb-2 undostyle" [hidden]="newnotifybtn">
        <button nbButton status="primary" (click)="getallbudgescount();getallnotification()">
          <nb-icon icon="arrow-upward-outline"></nb-icon>New notifications
        </button>

      </div>

      <div class="mr-auto mt-2 mb-2 d-flex align-items-center justify-content-center"
        [ngClass]="[checkundobtn ? 'noNotification': 'noNotificationborder']" *ngIf="msgnotification">
        <span class="text-center"> {{msgnotification}}</span>
      </div>

      <nb-list *ngIf="getallArray && !showSpinnernotification" class="notifyheight" [hidden]="msgnotification"
        infinite-scroll [scrollWindow]="false" (scrolled)="onScrollDown($event)">


        <nb-list-item class="d-flex flex-column pr-4 pl-4" *ngFor="let allnotification Of listArray;index as i"
          (click)="readmessage(allnotification)" oncopy="return false"
          [ngClass]="[allnotification.isRead ? 'isread-color': 'bgClass']" id="{{i + 'selectbody'}}">
          <div class="d-block w-100 mb-2">
            <span class="pointer-none"
              [ngClass]="[allnotification.isRead ? 'isreadheading-color': 'isunreadheading-color']">{{allnotification.notificationHeading}}</span>
            <nb-icon icon="close-outline" class="cursor-pointer"
              [ngClass]="[allnotification.isRead ? 'isreadicon-color': 'isunreadicon-color']"
              (click)="onebyonedelete(allnotification)"></nb-icon>
          </div>
          <p class="mb-2 prenotify"
            [ngClass]="[allnotification.isRead ? 'isreadmsgbody-color': 'isunreadmsgbody-color']"
            [innerHtml]="allnotification.message"></p>
          <p class="d-block w-100 mb-0 pointer-none"
            [ngClass]="[allnotification.isRead ? 'isreaddate-color': 'isunreaddate-color']">
            {{allnotification.createdDtm}}
          </p>
        </nb-list-item>
      </nb-list>

    </nb-card-body>
  </nb-card>

</div>
<!-- profile modal start from here -->
<nb-card style ="z-index: 99;" class="profileCard" id="profCard" *ngIf="showProfile" (click)="profilediv('profileclk')" #userProfile
  ngxClickOutside>
  <nb-card-header>
    <div class="profileCarddiv">
      <p class="subtitle text-basic mb-0 text-center displyname">{{Full_name}}</p>
      <span class="label text-hint spandisplay">{{this.createdBy}}</span>
      <p class="subtitle mb-0 text-basic displayplan">Plan : <span class="displayplanspan">{{wsSubscriptionPlan}}</span>
      </p>
    </div>
    <div class="dcoins-div">
      <div class="row padding-8">
        <div class="col-md-6 left-side-rect">
           <p class="dcoins-subtitle">My dCoins</p>
           <a *ngIf="dcoinsOn" class="purchase-dcoins-subtitle" href="mailto:billing@dox2u.com">+ Add dCoins</a>
           <a *ngIf="!dcoinsOn" class="purchase-dcoins-subtitle" (click)="openModal(upgradeModalDcoins)">+ Add dCoins</a>
        </div>
        <div class="col-md-6">
          <h1 class="dcoins-title">{{totalDCoins}}</h1>
        </div>
      </div>
      
    </div>
  </nb-card-header>
  <nb-card-body class="border-bottom profilebox ">

    <p class="mb-0 subtitle-2 text-basic text-left cursor-pointer onhover " (click)="onCellClicked(data)">
      <nb-icon icon="person-outline" class="mr-3 profilebox onhover "></nb-icon>My profile
    </p>

  </nb-card-body>
  <nb-card-body class="">
    <p class="mb-0 subtitle-2 text-basic text-left cursor-pointer onhover" (click)="Setting()">
      <nb-icon icon="pie-chart-outline" class="mr-3 profilebox onhover"></nb-icon>User settings
    </p>

  </nb-card-body>
  <nb-card-footer class="d-flex justify-content-center">
    <button nbButton size="small" class="subtitle-2 text-basic cursor-pointer  profilebtn" (click)="logout()">SIGN OUT
    </button>
  </nb-card-footer>
</nb-card>


<!-- Edit profile Modal   ----->


<ng-template #UserSetingModal let-data let-ref="dialogRef">
  <nb-card class="user-seting-modal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-primary">
          Edit Profile
        </h6>
        <nb-icon class="cursor-pointer" (click)="closedialog(ref)" icon="close-outline"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body #hideMenu (wheel)="myFunction($event)">
      <form [formGroup]="userProfileForm">
        <div class="row">
          <div class="col-lg-8">
            <div class="form-group d-flex flex-column">
              <label for="full_name" class="label text-hint">Full Name:</label>
              <input type="text" fullWidth nbInput id="full_name" class="paragraph text-hint" minlength="3" #fullName
                maxlength="30" placeholder={{Full_name}} value={{Full_name}} formControlName="fullName"
                (click)="validateValue(fullName)">
              <small class="text-danger paragraph-2"
                *ngIf="!userProfileForm.get('fullName').valid && userProfileForm.get('fullName').touched ">Please enter
                valid name</small>
            </div>

            <div class="form-group d-flex flex-column">
              <label for="Dis_name" class="label text-hint">Display Name:</label>
              <input type="text" nbInput id="Dis_name" class="paragraph text-hint" fullWidth
                placeholder={{display_name}} value={{display_name}} formControlName="displayName">
              <small class="text-danger paragraph-2"
                *ngIf="!userProfileForm.get('displayName').valid && userProfileForm.get('displayName').touched ">Please
                enter valid name</small>
              <div class="display-text text-hint mt-1">
                <p>{{displayNameGuidline || suggestedGuideline}}</p>
              </div>
            </div>

            <div class="form-group d-flex flex-column">
              <label for="what_i_do" class="label text-hint">What I do:</label>

              <input nbInput class="paragraph text-hint" fullWidth maxlength="99" formControlName="whatIDo"
                placeholder="What keeps you busy at {{WorkSpaceNamePlaceholder}}" value="{{whatIDo}}">

            </div>

            <div class="form-group d-flex flex-column">
              <label for="contact_no" class="label text-hint">Contact Number:</label>

              <input type="number" formControlName="contactNumber" class="paragraph text-hint" fullWidth nbInput
                id="contact_no" placeholder="(+1) 123 456" onkeydown="javascript:  event.keyCode == 69 ? false : true">
              <small class="text-danger paragraph-2"
                *ngIf="!userProfileForm.get('contactNumber').valid && userProfileForm.get('contactNumber').touched ">Please
                enter valid contact number</small>
            </div>

            <div class="form-group d-flex flex-column">
              <label for="where_i_am" class="label text-hint">Where I Am:</label>

              <input type="text" class="paragraph text-hint" formControlName="whereIAm" maxlength="99" fullWidth
                nbInput id="where_i_am" value="{{whereIam}}" placeholder="Where you are currently">


            </div>

            <div class="form-group d-flex flex-column">
              <label class="label text-hint">Timezone:</label>

              <ng-select appendTo="body" [items]="zoneTime" placeholder="Select time zone" bindLabel="timeZone" (onClick)="multiselect()"
                [closeOnSelect]="true" bindValue="timeZone" formControlName="timeZone">
              </ng-select>


            </div>

          </div>
          <div class="col-lg-4 profile-card">
            <div class="user-img d-flex flex-column">
              <img [src]="ediimageUrl" alt="" class="profileimg" #profileimg>
              <p class="text-danger paragraph-2"><span id="fileSizeError" *ngIf="uploadfilesize">Profile Picture cannot
                  be larger than 500
                  KB.</span></p>

              <input type="file" #file formControlName="iconImage" id="iconImage" class="d-none"
                (change)="replaceimg($event)" accept="image/gif, image/jpeg, image/png">
              <p class="addphoto" nbButton outline status="primary" (click)="file.click()">ADD PHOTO</p>
              <button status="control" [disabled]="imageUrlExists == true" nbButton
                (click)="Remove(profileimg)" class="label mt-2 text-primary">Remove</button>
            </div>
            <div class="user-img-content">
              <div>
                <p class="subtitle-2 text-basic">Tips on choosing a good photo:</p>
              </div>
              <div>
                <p class="caption-2 text-basic">File type: <span class="caption text-basic">JPEG or PNG</span></p>
              </div>

              <div>
                <p class="caption-2 text-basic">Max size: <span class="caption text-basic">500 KB</span></p>
              </div>

              <div>
                <p class="caption-2 text-basic">Dimensions: <span class="caption text-basic">If you upload an image file
                    with a different aspect ratio your logo may appear distorted.</span></p>

              </div>
              <div>
                <p class="caption text-hint mb-0">Email</p>
                <p class="caption text-basic">{{editproEmail}}</p>
              </div>

              <div>
                <p class="caption text-hint mb-0">Role</p>
                <p class="caption text-basic">{{userRole}}</p>
              </div>
              <div>
                <p class="caption text-hint mb-0">Group</p>
                <p class="caption text-basic">{{userGroup}}</p>
              </div>

            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer class="d-flex">

      <button nbButton [disabled]="!(userProfileForm.valid && userProfileForm.dirty)"
        (click)="updateUserProfile(file.files,ref);" status="primary" class="mr-2" size="small"
        [nbSpinner]="showSpinner" nbSpinnerStatus="primary" nbSpinnerSize="tiny">Save changes</button>
      <button nbButton (click)="closedialog(ref); closeEditProfile()" size="small" status="primary" outline>Close
      </button>
      <p *ngIf="isSavedPressedEditor" class="mb-0 subtitle-3 text-primary d-flex justify-content-center pt-2 ml-4">
        <nb-icon icon="checkmark-circle-2-outline" class="mr-1"></nb-icon> Profile has been saved
      </p>
    </nb-card-footer>
  </nb-card>
</ng-template>



<!--------------------------Change Password------------------->



<ng-template #Password let-data let-ref="dialogRef">

  <nb-card class="settingModal">
    <nb-card-header class="d-flex justify-content-between">
      <h2 class="subtitle"> User Settings</h2>
      <nb-icon icon="close-outline" class="mr-2 cursor-pointer"
        (click)="dialogRef.close(); cancleUserProfile(ref);  ref.close();"></nb-icon>
    </nb-card-header>

    <nb-card-body class="pt-0 nbcardprofile"  #hideMenu (wheel)="myFunction()">
      <div class="row marginchip">
        <div class="col-3 p-0 m-0 userSettingSideBar">
          <ul class="list-unstyled" style="padding-top: 16px;">
            <li [ngClass]="{'backTextStyle' : loginProfile}" class="cursor-pointer ml-3 pr-3 pt-2 pb-2"
              (click)="userEditProfile()"><span class="userSettinghover">
                <nb-icon icon="person-outline" class="ml-3 mr-3 cursor-pointer"></nb-icon>Edit Profile
              </span> </li>
            <li [ngClass]="{'backTextStyle' : changePassword}" class="cursor-pointer ml-3 pr-3 pt-2 pb-2"
              (click)="userchangePassword()"><span class="userSettinghover">
                <nb-icon icon="lock-outline" class="mr-3 ml-3 cursor-pointer"></nb-icon>Login & Security
              </span></li>

          </ul>
        </div>
        <div class="col-9 pt-3 pl-0 formdata">
          <div *ngIf="changePassword" class="pl-4">
            <app-change-password (isUnsaved)="isUnsaved($event)" (close)="close()"></app-change-password>
          </div>
          <div *ngIf="notification">
            <app-notifications></app-notifications>
          </div>
          <div *ngIf="loginProfile">
            <form [formGroup]="userProfileForm">
              <div class="parentClass">
                <div class="row profileRow">
                  <div class="col-lg-12 profile-card">

                    <div class="d-flex flex-row align-items-center">
                      <div class="user-img d-flex flex-row justify-content-center align-items-center">
                        <img [src]="ediimageUrl" alt="" class="profileimg" #profileimg style=" height: 5.125rem; width: 5.125rem;
                border: 1px solid var(--color-primary-500); border-radius:68px">


                        <input type="file" #file formControlName="iconImage" id="iconImage" class="d-none" (click)="clearData($event)"
                          (change)="replaceimg($event)" accept="image/jpeg, image/png">


                      </div>
                      <div class="d-flex flex-column">
                        <div class="buttonstyling d-flex flex-row ml-2 mt-2">
                          <p (click)="file.click();clearData($event)" class="uploadBtn mb-0">UPLOAD</p>
                          <p class="removeBtn ml-3 mb-0"
                            [ngClass]="{'pointer-none' : imageUrlExists == true}"
                            (click)="Remove(profileimg)">REMOVE</p>
                        </div>
                        <div class="suggestedText d-flex flex-row">
                          <div class="ml-2 mt-2 mb-0">
                            <p class="caption-2 text-hint mb-1">Max size: <span class="caption-2 text-hint">500
                                KB</span></p>
                          </div>
                          <div class="ml-2 mt-2 mb-0">
                            <p class="caption-2 text-hint mb-1">File type: <span
                                class="caption-2 text-hint">JPEG,PNG</span></p>
                          </div>
                          <div class="ml-2 mt-2 mb-0">
                            <p class="caption-2 text-hint mb-1">Recommended dimension: <span
                                class="caption-2 text-hint">512*512</span></p>

                          </div>
                        </div>
                        <div class="ml-2" *ngIf=uploadfilesize>
                          <p class="text-danger paragraph-2 "><span id="fileSizeError">Profile Picture cannot be larger
                              than 500
                              KB.</span></p>
                        </div>
                        <div class="ml-2" *ngIf=formateError>
                          <p class="text-danger paragraph-2 "><span id="fileSizeError">File type not supported
                            </span></p>
                        </div>
                      </div>
                    </div>



                  </div>

                </div>
                <div class="row mt-3 m-0" style="height: 29rem; width: 100%;">
                  <div class="col-lg-12 pl-4 pr-4">
                    <div class="form-group d-flex flex-column">
                      <label for="full_name" class="label text-hint labelClass">Full Name:</label>
                      <input type="text" fullWidth nbInput id="full_name" class="paragraph text-hint" maxlength="99"
                        #fullName minlength="3" placeholder={{Full_name}} value={{Full_name}} formControlName="fullName"
                        (input)="validateValue(fullName)" onkeydown.enter="return false" (keypress)="removeLanguageModal($event);removeSpecialCharacters($event)">
                      <small class="text-danger paragraph-2 mt-1"
                        *ngIf="(!userProfileForm.get('fullName').valid && userProfileForm.get('fullName').touched) || correctvalue ">Please
                        enter valid name</small>
                    </div>

                    <div class="form-group d-flex flex-column mt-3">
                      <label for="Dis_name" class="label text-hint labelClass">Display Name:</label>
                      <input type="text" nbInput id="Dis_name" class="paragraph text-hint" maxlength="99" #disName
                        fullWidth (input)="displayValidateValue(disName)" placeholder={{display_name}} (keypress)="removeLanguageModal($event);removeSpecialCharacters($event)"
                        value={{display_name}} formControlName="displayName">
                      <small class="text-danger paragraph-2 mt-1"
                        *ngIf="(!userProfileForm.get('displayName').valid && userProfileForm.get('displayName').touched) || correctvalue1 ">Please
                        enter valid name</small>
                      <div class="display-text text-hint mt-2">
                        <p class="m-0 font-guide">{{displayNameGuidline || suggestedGuideline}}</p>
                      </div>

                    </div>
                    <div class="col-lg-12 form-group d-flex flex-row p-0 mt-3">
                      <div class=" col-lg-6 form-group d-flex flex-column pl-0">

                        <label for="contact_no" class="label text-hint labelClass">Contact Number:</label>

                        <input type="text" formControlName="contactNumber" class="paragraph text-hint" maxlength="15"  (keypress)="removeLanguageModal1($event)"
                          fullWidth nbInput id="contact_no" placeholder="(+1) 123 456" (keypress)="stopDot($event)"
                          (input)="changeValue()" onkeydown="javascript:  event.keyCode == 69 ? false : true"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                        <small class="text-danger paragraph-2 mt-1"
                          *ngIf="!userProfileForm.get('contactNumber').valid && userProfileForm.get('contactNumber').touched ">Please
                          enter valid contact number</small>
                      </div>

                      <div class="col-lg-6 form-group d-flex flex-column pr-0">
                        <label for="where_i_am" class="label text-hint labelClass">Where I Am:</label>

                        <input type="text" class="paragraph text-hint" formControlName="whereIAm" maxlength="99"
                          (input)="changeValue()" fullWidth nbInput id="where_i_am" value="{{whereIam}}"
                          placeholder="Where you are currently">

                      </div>
                    </div>
                    <div class="form-group d-flex flex-column mt-3">
                      <label for="what_i_do" class="label text-hint labelClass">What I do:</label>

                      <input nbInput class="paragraph text-hint" fullWidth maxlength="99" formControlName="whatIDo"
                        (input)="changeValue()" placeholder="What keeps you busy at {{WorkSpaceNamePlaceholder}}"
                        value="{{whatIDo}}">

                    </div>

                    <div class="form-group d-flex flex-column mt-3">
                      <label class="label text-hint labelClass">Timezone:</label>


                      <ng-select [items]="zoneTime" placeholder="Select time" bindLabel="timeZone" (ngModelChange)="changeValue()"
                        [closeOnSelect]="true" bindValue="timeZone" formControlName="timeZone"
                        (change)="onTimeZoneSelectionChange($event);" (onClick)="multiselect()">
                      </ng-select>



                    </div>

                  </div>

                </div>
              </div>
              <nb-card-footer class="footerBox">
                <button nbButton
                  [disabled]="!(userProfileForm.valid && userProfileForm.dirty)|| !enableSave || isSavedPressedEditor || uploadfilesize || formateError"
                  (click)="updateUserProfile(file.files,ref)" status="primary" class="mt-3 ml-4" size="small"
                  [nbSpinner]="showSpinner" nbSpinnerStatus="primary" nbSpinnerSize="tiny" style="width: 59px;
          height: 32px; margin-bottom: 16px;">Save</button>
                <p class="prev box-outline" (click)="cancleUserProfile1()">RESET</p>
              </nb-card-footer>

            </form>
          </div>
        </div>
      </div>
    </nb-card-body>


  </nb-card>
</ng-template>


<!-- Advance search modal -->

<div class="card-roww" [hidden]="!advFilterDropdown" (click)="advclick('cardclickadv')">
  <nb-card class="position-absolute card-row col-md-5 col-lg-5 col-xl-5 col-5  top-4 p-0 advancesearch">
    <nb-card-header>
      <div class="row d-flex">
        <div class="col-11">
          <h6 class="headline-6"> Advanced Search</h6>

        </div>
        <div class="col-1">
          <nb-icon icon="close-outline" class="cursor-pointer advacrossicon" (click)="advcross('crossclick')"></nb-icon>
        </div>
      </div>

    </nb-card-header>

    <nb-card-body class="p-0 searchcardbody" style="scrollbar-color: #c5cee0 #edf1f7 !important;
    scrollbar-width: thin !important;">
      <div class="row m-0 pb-3 pt-3 ownedbyfilter">
        <div class="col-4">
          <p class="Basic P2 pt-2 mb-0 subtitle">Doc type</p>

        </div>
        <div class="col-8 pl-0 ">
          <div class="col-12 pl-0 pr-0">
            <ng-select class="formattype" [items]="docTypeList" placeholder="Any" [closeOnSelect]="true" (click)="fetchId1('a069e2438ecd')"
              bindLabel="name" bindValue="name" appendTo="body" (change)="onTypeSelect($event)" (keyup)="alphacheckDocType($event);"
              [ngModelOptions]="{standalone: true}" groupBy="format" [(ngModel)]="isTypeSelected">
            </ng-select>
          </div>
        </div>


      </div>
      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class="Basic P2 subtitle mb-0 pt-2 ">Owned by</p>
        </div>
        <div class=" col-8 pl-0">
          <ng-select class="" [items]="searchUserData" bindLabel="displayName" [closeOnSelect]="true" appendTo="body" (keyup)="alphacheckDocOwner($event);"
            placeholder="Anyone" (change)="onOwnerName($event)" [(ngModel)]="selectedOwnerDisplayName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p class="subtitle templateText text-basic mb-0" *ngIf="createdBy != item.emailId">{{item.displayName}}
              </p>
              <p class="subtitle templateText text-basic mb-0" *ngIf="createdBy === item.emailId">{{item.displayName}}
                (You)
              </p>
            </ng-template>

          </ng-select>
        </div>


      </div>
      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class="Basic P2 subtitle pt-2 mb-0">Shared with</p>
        </div>
        <div class="col-8 pl-0">
          <ng-select class="" [items]="searchUserData" bindLabel="displayName" [closeOnSelect]="true" appendTo="body" (keyup)="alphacheckDocShared($event);"
            placeholder="Anyone" (change)="onShareName($event)" [(ngModel)]="selectedSharedDisplayName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p class="subtitle templateText text-basic mb-0" *ngIf="createdBy != item.emailId">{{item.displayName}}
              </p>
              <p class="subtitle templateText text-basic mb-0" *ngIf="createdBy === item.emailId">{{item.displayName}}
                (You)
              </p>
            </ng-template>

          </ng-select>
        </div>


      </div>
      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class="Basic P2 subtitle mb-0 pt-2">Added Date</p>

        </div>
        <div class="col-8 pl-0">
          <ng-select class=" placeholdercolor" [items]="dateSelectList" placeholder="Anytime" [closeOnSelect]="true"
            (change)="onDateSelect($event)" appendTo="body" [ngModelOptions]="{standalone: true}"  (keyup)="alphacheckAddedDate($event);"
            [(ngModel)]="DateInput">
          </ng-select>
        </div>


      </div>

      <div class="row  m-0  pb-3 coustomDatePicker" *ngIf="DateInput == 'Custom..'" (click)="advclick('cardclickadv')">
        <div style="display: flex;
       align-items: flex-end;
       justify-content: end;    width: 62.5%;
margin-right: 23px;">
          <div style="width: 13%;">
            <p class=" Basic P2 subtitle mt-1 paddingleft6b ">Custom</p>
          </div>
          <div class="min-width-16_4  margin-left2_5 " style="position: relative; width:87% ;">
            <nb-icon [nbDatepicker]="formpicker" [(ngModel)]="rangeDate" icon="calendar-outline"
              class="text-hint cursor-pointer" style="    position: absolute;
            right: 10px;
            top: 10px;" onclick="document.getElementById('datepick').click()">
            </nb-icon>
            <input id="datepick" nbInput placeholder="Pick Date Range" class="data-picker" fullWidth
              [(ngModel)]="rangeDate" [ngModelOptions]="{standalone: true}" [nbDatepicker]="formpicker" (change)="dateChangeCustom($event,'text')" (click)="fetchId('cdk-overlay-0')"
              fieldSize="medium">

            <nb-rangepicker #formpicker (rangeChange)="dateChangeCustom($event)" ></nb-rangepicker>
          </div>

        </div>
      </div>
      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class=" Basic P2 subtitle mb-0 pt-2">Doc name</p>
        </div>
        <div class="col-8 pl-0">
          <div class="">
            <input type="text" class="pl-3" nbInput fullWidth placeholder="Enter a term that matches part of the name" autocomplete="off"
              maxlength="50" minlength="3" (keypress)="removeLanguage($event);"  (keyup)="alphacheckDocName($event);"  (paste)="onPasteName($event)"
              (blur)="onDocName($event);" [(ngModel)]="AdvSearchValue" [ngModelOptions]="{standalone: true}">
            <p class="text-danger"
              *ngIf="AdvSearchValue?.length < 3 && validSearchLength && AdvSearchValue !== undefined">Minimum Length
              should be 3</p>
          </div>
        </div>


      </div>

      <div *ngIf ="deepSearch"  class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class=" Basic P2 subtitle mb-0 pt-2">Doc contains</p>
        </div>
        <div class="col-8 pl-0">

          <div class="">
            <input type="text" class="pl-3" nbInput fullWidth placeholder="Enter text" maxlength="200" minlength="3" autocomplete="off"
              (keypress)="removeLanguage($event);alphacheckDocContains($event)" (keyup)="alphacheckDocContains($event);" (paste)="onPasteName($event)" (blur)="onDocContains($event)"
              [(ngModel)]="AdvDocContains" [ngModelOptions]="{standalone: true}">

          </div>
        </div>
      </div>

      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4 ">
          <p class=" Basic P2 subtitle mb-0 pt-2 ">Tags</p>
        </div>
        <div class="col-8 pl-0 d-flex flex-column">
          <div class="d-flex flex-column">
            <div class="row d-flex pb-3 " style="position:relative;left:13px">
              <div class="d-flex pr-3">
                <nb-radio class="position radiobtnmargin" type="radio" [checked]="checked1" name="shareWithUserRadio"
                  value="1" id="flexRadioDefault1" (valueChange)="onCustomUserGroupChange($event)">
                  <p style="text-transform: capitalize; " class="subtitle pb-0 mb-0">Text/Notes</p>
                </nb-radio>

              </div>
              <div class="d-flex">
                <nb-radio class="position radiobtnmargin " type="radio" [checked]="checked2" name="shareWithUserRadio"
                  value="2" id="flexRadioDefault2" (valueChange)="onCustomUserGroupChange($event)">
                  <p style="text-transform: capitalize;  " class="subtitle pb-0 mb-0">Date</p>
                </nb-radio>

              </div>
            </div>
            <div *ngIf="checked2" >
            <input id="calender"  readonly nbInput placeholder="Enter Date"
              class="data-picker cursor-pointer" fullWidth [nbDatepicker]="dateTimePicker" [(ngModel)]="rangeDateTags" (click)="fetchId('cdk-overlay-0')"
              [ngModelOptions]="{standalone: true}" fieldSize="medium">
            <!-- <nb-icon *ngIf="checked2" class="cursor-pointer calenderoutline text-hint" icon="calendar-outline"
              onclick="document.getElementById('calender').click()"></nb-icon> -->
            <nb-datepicker (dateChange)="dateChange($event,'cdk-overlay-0')" #dateTimePicker></nb-datepicker>
          </div>
            <div *ngIf="!checked2"  >
            <input type="text" class="pl-3" nbInput fullWidth placeholder="Enter text" maxlength="50" autocomplete="off"
              minlength="3" (keypress)="removeLanguage($event)" (paste)="onPasteName($event)"  (keypress)="alphacheckDocTags($event);"
              (blur)="onDocTagsNotes($event)" [(ngModel)]="docTagsNotes" [ngModelOptions]="{standalone: true}">
            <p class="text-danger"
              *ngIf="docTagsNotes?.length < 3 && validSearchLengthTags && docTagsNotes !== undefined && checked1">
              Minimum Length should be 3</p>
            </div>
          </div>
        </div>


      </div>
      <div class="row m-0 pb-3 ownedbyfilter">
        <div class="col-4">
          <p class=" Basic P2 subtitle mb-0 pt-2">Includes the words</p>
        </div>
        <div class="col-8 pl-0">
          <div class="">
            <input type="text" class="pl-3" nbInput fullWidth placeholder="Search by name, tags, notes, content" autocomplete="off"
              maxlength="200" (keypress)="removeLanguage($event)" (paste)="onPasteName($event)" (keyup)="alphacheckDocIncludeWord($event);"
              (blur)="includeWord($event)" [(ngModel)]="IncludetheValue" [ngModelOptions]="{standalone: true}"
              id="searchField">
          </div>
        </div>


      </div>
    </nb-card-body>
    <nb-card-footer class="padding-left0_5 ">
      <div>
        <button nbButton class="textbutton mr-2" status="primary" size="small"
          (click)=" AdvSearchClick();headerAdvSearch($event,'','searchclickoninit')">SEARCH</button>

        <button class="button-invite text-basic" status="Basic" ghost size="small" (click)="ResetClickAdv()"
          nbButton>RESET</button>
      </div>
    </nb-card-footer>


  </nb-card>
</div>

<!-- Advance search modal end here -->

<!-- Unsaved Change Modal start here -->
<ng-template #unsavedChanges let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Unsaved changes
        </h6>
        <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
      </div>
    </nb-card-header>

    <nb-card-body>
      <p class="paragraph text-basic">Are you sure you want to leave this page? The changes you made will be<br>
        discarded.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" size="small" class="mr-3" (click)="dialogRef.close();discardChanges(ref)">Yes,
        leave</button>
      <button nbButton size="small" (click)=dialogRef.close() outline status="primary">No, stay</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Unsaved Change Modal start here -->

<!-- Unsaved Change Modal start here -->
<ng-template #unsavedChangesOnCancle let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Unsaved changes
        </h6>
        <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
      </div>
    </nb-card-header>

    <nb-card-body>
      <p class="paragraph text-basic">Are you sure you want to leave this page? The changes you made will be<br>
        discarded.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" size="small" class="mr-3"
        (click)="dialogRef.close();cancleUserProfile(ref)">Yes, leave</button>
      <button nbButton size="small" (click)=dialogRef.close() outline status="primary">No, stay</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Unsaved Change Modal start here -->
<!-- Cropper Modal start here -->
<ng-template #cropperModal let-data let-ref="dialogRef">
  <nb-card class="invitemodal borderClass">


    <nb-card-body class="p-0 borderClass">

      <div class="p-0 parentCropper borderClass">
        <image-cropper class="borderImageleft p-0" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          width="496" [containWithinAspectRatio]="false" [canvasRotation]="canvasRotation" height="314"
          cropperMinWidth="32" cropperMinHeight="32" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()" roundCropper="true" (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" size="small" class="float-left"
        (click)="dialogRef.close(); uploadImage()">Confirm</button>
      <p class="prev box-outline float-left" (click)="dialogRef.close();uploadImageCancle($event)">CANCEL</p>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Cropper Modal start here -->
<!-- Url Redirection Modal start here -->
<ng-template #urlRedModal let-data let-ref="dialogRef">
  <nb-card class="invitemodal borderClass">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          URL change restricted
        </h6>
      </div>
    </nb-card-header>

    <nb-card-body class="p-0 borderClass">

      <div class="p-0 parentCropper borderClass">
        Your Workspace URL is currently under redirection due to a recent change by you.
        You may change your Workspace URL again after 4 days.
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" size="small" class="float-left" (click)="dialogRef.close(); ">OKAY, I UNDERSTAND</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Url Redirection start here -->

<!-- modal for the mini profile start  -->
<div>
  <ng-template #miniprofile let-data let-ref="miniprofile">
    <div>
      <div class="d-flex justify-content-between float-right cursor-pointer headProfile">
        <p class="headline-6 text-primary mb-0 text-right cursor-pointer" (click)="cancleEdit()" style="z-index: 7;">
          Edit Profile</p>
        <!-- <p class="headline-6 text-primary mb-0 text-right cursor-pointer " (click)="cancleEdit()"  *ngIf="!changeText">Edit Profile</p> -->
      </div>
      <div class="miniprofileParentDiv">
        <ngx-mini-profile [showdata]="showdata" class="miniprofileParentDiv"></ngx-mini-profile>
      </div>
    </div>
  </ng-template>
</div>
<!-- modal for the mini profile End  -->

<!-- modal for the upgrade dcoins Start  -->
<ng-template #upgradeModalDcoins>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Add dCoins </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic mb-0">This functionality is not supported in your current subscription plan.<br> Please upgrade to an eligible plan and try again</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">UPGRADE NOW</button>
      <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- modal for the upgrade dcoins End  -->