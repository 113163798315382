import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ApiServiceService } from '../api-service.service';
import 'rxjs/add/observable/of';
import 'rxjs/Rx';
import { environment } from '../.../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WorkspaceTrashService {
  private data: any;
  private error: any;
  createdBy: any;
  workSpaceId: any;


    constructor(private http: HttpClient,private apiService:ApiServiceService) {
      this.workSpaceId = localStorage.getItem('localwsId')
      this.createdBy = localStorage.getItem('localloginId')
  } 
  public baseURL=environment.baseUrl;
  public baseOrigin = environment.baseOrigin;
 

  SizeTrashStatusService(data) {
    return this.apiService.post( '/api/Trash/TrashSizeCount', data);
   }

   UpdateEmptyTrashStatusService(data) {
    return this.apiService.post( '/api/Trash/UpdateEmptyTrashStatus', data);
   }

   validateTrashBinSize(data) {
    return this.apiService.post('/api/GeneralSettings/ValidateTrashBinSize', data);
   }

   saveTrashDurationAndSize(data) {
    return this.apiService.post('/api/GeneralSettings/TrashDurationAndSize', data);
   }


}
