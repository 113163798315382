import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiServiceService } from '../@core/api-service.service';
@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  private data: any;
  private error: any;
  createdBy: any;
  workSpaceId: any;


  constructor(private http: HttpClient,private apiService:ApiServiceService) {
   }
  public elasticdbaseurl = environment.elasticdbaseurl;
  public baseOrigin = environment.baseOrigin;
  public baseURL = environment.baseUrl;

  verifyDailyDaownlaodLimit(data) {
    return this.apiService.post('/api/OwnedByMe/ValidateDownloadLimit', data);
  }

  downloadDocument(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFiles1',  data, {responseType:'blob'}).pipe(
      retry(0)
    );
  }

  downloadDocumentzipdata(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFiles1', data,{responseType:'blob', reportProgress: true,
    }).pipe(
      retry(0)
    );
  }
  downloadDocumentzip(data) {
    return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFiles1', data,{responseType:'blob', reportProgress: true,
    observe: 'events'}).pipe(
      retry(0)
    );
  }
}
