<div class="changPass-section">
  <p class="subtitle text-basic mb-2"> Create a new password </p>
  <p class="paragraph text-basic mb-3">You can change your password by entering your old password and selecting a new
    one.
  </p>

  <!-- PASSWORD STRENGTH -->
  <form [formGroup]="passwordForm">
    <div class="form-group mb-3">
      <label class="label text-hint labelClass">Current Password:</label>
      <nb-form-field>
        <input type="password" nbInput status="basic" [type]="ExgetInputType()"
           placeholder="Existing Password"
          maxlength="20" autocomplete="off"
          onkeypress="return event.charCode != 32" [(ngModel)]="oldPasswordField" [ngModelOptions]="{standalone:true}" (input)="hidePasswordError($event.target.value)">
        <button nbSuffix nbButton ghost (click)="ExtoggleShowPassword()" class="creentshadow"  [ngClass]="{'checkmarkcolor' : ExshowPassword}">
          <nb-icon [icon]="ExshowPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva"
            [attr.aria-label]="ExshowPassword ? 'hide password' : 'show password'">
          </nb-icon>
        </button>
      </nb-form-field>

      <div class="row">
        <div class="col-8">
          <p *ngIf="passwordNotMatch1"
              class="errorMeassage paragraph-2 mt-1 mb-0">
             Password is incorrect
              </p>
          <p *ngIf="fieldError"
              class="errorMeassage paragraph-2 mt-1 mb-0">
             Cannot be Empty
              </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="">
        <label class="label text-hint col-md-12 pl-0 labelClass">New Password:</label>
        <nb-form-field>
          <input type="password" nbInput  status="basic" [type]="getInputType()"
            (keyup)="changeButtonState($event)" id="exampleFormControlInput1" placeholder="New Password"
            maxlength="20"  (keypress)="removeLanguage($event)"
            (keyup)="checkPasswordStrength($event)" (keyup.enter)="onEnterPassword($event)"  autocomplete="off" onkeypress="return event.charCode != 32" class=""
            id="password-signin" aria-describedby="passwordHelp" (keydown)="onKeydownMain($event)"
            formControlName="repassword">
          <button nbSuffix nbButton ghost (click)="toggleShowPassword()" class="creentshadow">
            <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva"  [ngClass]="{'checkmarkcolor' : showPassword}"
              [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
            </nb-icon>
          </button>
        </nb-form-field>
      </div>
      <div class="row">
        <div class="text-right col-9 error-position">
          <p *ngIf="(f1?.repassword?.errors || !passwordLength || !containLowerCase || !containSpecialCharacter || !containNumber || !containUpperCase) && submitted && !incorrectPassword"
              class="errorMeassage paragraph-2 mt-1 mb-0">
           Incomplete password
              </p>
                 <p *ngIf="incorrectPassword"
              class="errorMeassage paragraph-2 mt-1 mb-0">
             New password cannot be the same as old password
              </p>
        </div>

      </div>
    </div>
    <div class=" pt-0 sign-in-para d-flex flex-row justify-content-left align-item-left pb-5">
      <ul class="text-basic password-validation-list-1 ml-0 pl-4">
        <li class="pass-validation-list d-flex align-items-center"
          [ngClass]="{'blue' : passwordLength, 'normal' : !passwordLength, 'red' : (!passwordLength && submitted), 'errorpassword' : (!passwordLength && submitted)}">
          At least 6 characters</li>
        <li class="pass-validation-list  d-flex align-items-center"
          [ngClass]="{'blue' : containLowerCase, 'normal' : !containLowerCase, 'red' : (!containLowerCase && submitted), 'errorpassword' : (!containLowerCase && submitted)}">
          One lower case a-z</li>
        <li class="pass-validation-list  d-flex align-items-center"
          [ngClass]="{'blue' : containSpecialCharacter, 'normal' : !containSpecialCharacter, 'red' : (!containSpecialCharacter && submitted), 'errorpassword' : (!containSpecialCharacter && submitted)}">
          One special character</li>
      </ul>
      <ul class="pl-3 password-validation-list-2 text-basic">
        <li class="pass-validation-list  d-flex align-items-center"
          [ngClass]="{'blue' : containNumber, 'normal' : !containNumber, 'red' : (!containNumber && submitted), 'errorpassword' : (!containNumber && submitted)}">
          One number 0-9</li>
        <li class="pass-validation-list  d-flex align-items-center" id="list-2"
          [ngClass]="{'blue' : containUpperCase, 'normal' : !containUpperCase,'red' : (!containUpperCase && submitted), 'errorpassword' : (!containUpperCase && submitted)}">
          One upper case A-Z</li>
      </ul>
    </div>
    <div>
    <div class="bottombtn">
      <button class="button-active min-height-3 mr-2" nbButton status="primary" (click)=resetPasswordConfirm();
        size="small" class="creentshadow"> Save </button>
        <p class="prev box-outline"  (click)="resetPassword();" >RESET</p>
      <!-- <button nbButton (click)="passwordclose();" size="small" outline status="primary" class="creentshadow">close</button> -->
    </div>
  </div>
  </form>



</div>
 <ng-template #unsavedChanges let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-basic">
             Unsaved changes
            </h6>
                <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
        </div>
       </nb-card-header>

      <nb-card-body> <p class="paragraph text-basic">Are you sure you want to leave this page? The changes you made will be<br> discarded.
</p> </nb-card-body>
      <nb-card-footer class="d-flex">
      <button nbButton status="primary"  size="small" style="margin-right:16px;"  (click)=resetPasswordConfirm();dialogRef.close() class="">SAVE CHANGES</button>
       <button nbButton  size="small" (click)="dialogRef.close();discardChanges(ref)" outline status = "primary" class="">DISCARD</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
