import { Injectable } from '@angular/core';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
  } from '@angular/common/http';
  import { AuthService } from './auth.service';
  import { Observable  } from 'rxjs/Observable';
  import { retry, catchError } from 'rxjs/operators';
  import { throwError } from 'rxjs';
  import { SharedService } from '../utils/shared.service';
  import { Router} from '@angular/router';
  import { SignInService } from '../sign-in.service';

  @Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private sharedService : SharedService,private router: Router, private signInService: SignInService) {}
  errorMessage;
  refreshtokenval;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
   if(request.url !== "https://api.ipify.org/?format=json"){
     authReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.auth.getToken()}`)
    });
   }


      

      if(authReq.urlWithParams){
         /**
               *check refresh api hit
         */
        this.refreshtokenval = authReq.urlWithParams.search('/api/SignIn/refresh')
      }
      if (!window.navigator.onLine) {
          /**
               *if there is no internet, throw a HttpErrorResponse error
         */
          /**
               *since an error is thrown, the function will terminate here
         */
     
        return Observable.throw(new HttpErrorResponse({ error: 'Internet is required.' }));

    } else{
      return next.handle(authReq)
      .pipe(retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            this.errorMessage = error.status;
        
          } else {
            this.errorMessage = error.status;
       
            if(error.status == 401){
              this.refreshtoken();
              this.sharedService.refreshtoken.next();
            }

              /** 
               *when refresh token expire
            */
             if(this.refreshtokenval != -1){
                 if(error.status == 500){
                  localStorage.clear();
                  sessionStorage.clear();
                  this.router.navigate(['/onboarding/sign-in']);
              }
             }  
          }
          return throwError(errorMessage);
        })
      )
  }
}

refreshtoken() {
  let currentURL = window.location.href.toString();
  if(!currentURL.includes('ID=')){
      let token = {
          jwtToken: localStorage.getItem('token'),
          refreshToken: localStorage.getItem('refreshtoken'),
          WSOID: Number(localStorage.getItem('localwsId')) || Number(localStorage.getItem('tallyWSID')),
          EmailID: localStorage.getItem('localloginId') || localStorage.getItem('tallyEmail')
      }
      this.signInService.refreshtoken(token).subscribe((response: any) => {
          if (response) {
              localStorage.setItem('token', response.jwtToken);
              localStorage.setItem('refreshtoken', response.refreshToken);
          }
      })
  }
}
}