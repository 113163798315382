import { Component, OnInit,Input } from '@angular/core';
import { appProperties } from '../app.messages';

/**
 * @ignore
 */
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  /**
 * @ignore
 */
  @Input() message;
  @Input() feature;

  ngOnInit(): void {

 /**
 * In there we define the variable for message
 */

    this.message = this.feature ? appProperties[this.feature][this.message] : appProperties[this.message];
    
  }

}
