<div class="ShareModalPopup">
    <ng-template #shareuse let-data let-ref="dialogRef">
        <nb-card class="invitemodal " [nbSpinner]='showSpinner' nbSpinnerSize="medium" nbSpinnerStatus="primary">
            <nb-card-header>
                <div class="justify-content-between d-flex">
                    <h6 class="subtitle text-basic">
                        Share within workspace
                    </h6>
                    <nb-icon  (click)="close();ref.close(); oncancel()" class="cursor-pointer" icon="close-outline"></nb-icon>
                </div>

            </nb-card-header>
            <nb-card-body class="pt-3">
                <!-- <div><p class="">This document is shared with:</p></div>  -->

                <form [formGroup]="shereTemplateForm">
                    <div>

                        <div class="aligntmentcenter">

                            <nb-radio class="position" type="radio" [checked]="checked2" name="shareWithUserRadio"
                                value="2" id="flexRadioDefault2" (valueChange)="onAllUserChange($event);checkedButton($event)" (click)="allValueSelected($event)"> </nb-radio>


                            <span for="Addusergroup"
                                class="marginleft3 d-flex paragraph text-basic marginTop2 mb-0">Share with everyone<p
                                    class="paragraph-2 templateText text-basic ml-1 mb-0 "
                                    style="display: flex;align-items: center;">[{{allUserLength}} user(s)]</p></span>

                        </div>
                        <div class="pt-3">
                            <div style="display: flex; align-items: center;">
                                <nb-radio  class="position" type="radio" name="shareWithUserRadio" [checked]="checked3"
                                    value="3" id="flexRadioDefault1" (valueChange)="onNoUserChange($event);checkedButton($event)">

                                </nb-radio>
                                <p class="marginleft3 paragraph text-basic marginTop2 mb-0">Share with no one</p>
                            </div>
                        </div>
                    </div>
                    <div class="pt-3">
                        <div class=" form-group mb-0">



                            <div class=" pt-0 shared-access-select chipsharedaccess">
                                <div style="display: flex; align-items: center;">
                                    <nb-radio class="position" type="radio" name="shareWithUserRadio"
                                        [checked]="checked1" value="1" id="flexRadioDefault1"
                                        (valueChange)="onCustomUserGroupChange($event);checkedButton($event);getownerChip();">

                                    </nb-radio>
                                    <p class="marginleft3 paragraph text-basic marginTop2 mb-0">Share with users and
                                        groups</p>
                                </div>
                                <div class="mt-3">

                                    <ng-select appendTo="body" [ngClass]="showSpinner?'showplaceHolder':'hideplaceHolder'" class="minWidth30 containerflexShared  " id="chipdesign"
                                        [items]="options" [disabled]="shareAcessDisable" [multiple]="true"
                                        [selectableGroup]="false" [closeOnSelect]="true"
                                        placeholder="Add users & groups"
                                        [bindLabel]="'categroy' === 'GROUP' ? 'emailID' : 'nameAndGroup'"
                                        [bindValue]="'categroy' === 'GROUP' ? 'emailID' : 'nameAndGroup'"
                                        (change)="emailval($event)" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="namechips">
                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                            (click)="emailval(option)">
                                            <p class="subtitle templateText text-basic mb-0 d-flex align-items-center " *ngIf="item.categroy === 'GROUP'">{{item.nameAndGroup}} <br><p class="paragraph-2 templateText text-basic mb-0 ml-1">[{{item.usercount}}user(s)] </p></p>
                                            <p class="subtitle templateText text-basic mb-0 d-flex align-items-center " *ngIf="item.categroy !== 'GROUP'">{{item.nameAndGroup}}<br><p class="paragraph-2 templateText text-basic mb-0 ml-1 ">[{{item.emailID}}] </p></p>

                                        </ng-template>
                                    </ng-select>


                                    <div class="mt-2" *ngIf="!shareAcessDisable">
                                        <p class="Text Font/S1 Subtitle mb-1" style="font-weight:700;">Shared to:</p>
                                        <div class="input169 " [ngClass]="{'pointer-none' : shareAcessDisable}" >
                                            <div [hidden]="namechips.length == 0" class="mb-2 ml-1">
                                                <nb-tag-list (tagRemove)="onTagRemove($event)" class="chipShare mt-2">
                                                    <nb-tag appearance="filled" *ngFor="let tree of showChip"
                                                        [text]="tree.nameAndGroup"
                                                        [ngClass]="!shareAcessDisable && tree.categroy === 'GROUP' ? 'primary' : 'hideChip' "
                                                        status="primary" appearance="outline"[size]="tiny" removable> <nb-icon icon="people-outline" class="mr-1"></nb-icon>{{tree.nameAndGroup}}</nb-tag>
                                                        <nb-tag appearance="filled" *ngFor="let tree of showChip"
                                                        [text]="tree.nameAndGroup"
                                                        [ngClass]="!shareAcessDisable && tree.categroy !== 'GROUP' ? 'primary' : 'hideChip' "
                                                        status="primary" [size]="tiny" removable><nb-icon icon="people-outline" class="mr-1"></nb-icon>{{tree.nameAndGroup}}</nb-tag>
                                                </nb-tag-list>
                                                <br [hidden]="namechips.length == 0">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="NoOneSelected && ownerEmailID !== createdBy">
                                        <p class="noOneSelected pt-2 pr-3 pb-2 pl-3">
                                            If you continue with this action then your sharing rights for the selected documents will be revoked
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </form>

            </nb-card-body>
            <nb-card-footer class="" [ngClass]="!shareAcessDisable1 && seletedData == 'multi' ? 'showCheckBox' : 'hideCheckBox' ">
                <nb-checkbox (checkedChange)="toggle($event)" style="text-transform: initial;" [ngClass]= "!shareAcessDisable1 && seletedData == 'multi' ? 'showData' : 'hideItems'"><p class="mb-0 Text Font/S2 subtitle text-basic" style="text-transform: initial;">Replace existing shared access</p></nb-checkbox>
                <div>
                    <button (click)="close();ref.close(); oncancel()"
                    class="sharedcancelbutton text-basic mr-2">CANCEL</button>

                    <button nbButton status="primary" class="invite " size="small"
                    (click)="saveTemplate();ref.close();"[disabled]="getSelectedChange()">SHARE</button>

                </div>

            </nb-card-footer>
        </nb-card>
    </ng-template>

</div>

<!-----------------------------------share access modal start----------------------------------->
<div class="ShareAccessModal">
    <ng-template #shareAccess let-data let-ref="dialogRef">
        <nb-card class="invitemodal alluserwidth " style="min-height: unset !important;">
            <nb-card-header>
                <div class="justify-content-between d-flex">
                    <div class="subtitle text-basic">
                        Shared with users & groups
                    </div>

                </div>

            </nb-card-header>
            <nb-card-body class="nbusermodalbottom pb-0">
                <div>
                    <p>This document is shared with:</p>
                </div>
                <ng-container *ngFor="let user of sharedWithUsers">

                    <div *ngIf="sharedWithUsers.length>0" class="user-name">

                        <p class="paragraph text-basic mb-0" *ngIf="createdBy !== user.emailID"><span>

                                <nb-icon *ngIf="user.categroy=='GROUP'" class="groupColorIcon text-basic"
                                    icon="people-outline"></nb-icon>
                                <nb-icon *ngIf="user.categroy !='GROUP'" class="groupColorIcon text-basic"
                                    icon="person-outline"></nb-icon>

                            </span> &nbsp; &nbsp;{{user.name}}</p>
                        <p class="paragraph text-basic mb-0" *ngIf="createdBy === user.emailID">
                            <span>
                                <nb-icon *ngIf="user.categroy=='GROUP'" class="groupColorIcon text-basic"
                                    icon="people-outline"></nb-icon>
                                <nb-icon *ngIf="user.categroy !='GROUP'" class="groupColorIcon text-basic"
                                    icon="person-outline"></nb-icon>

                            </span> &nbsp; &nbsp;{{user.name}} (you)
                        </p>
                        <p class="groupColor paddingleft2 caption text-basic" *ngIf="user.categroy=='GROUP'">
                            {{user?.usercount}} user(s)</p>
                        <p class="groupColor paddingleft2 caption text-basic " *ngIf="user.categroy !=='GROUP'">
                            {{user?.emailID}}</p>
                    </div>


                </ng-container>
                <ng-container *ngIf="isAllUserShare && sharedWithUsers.length == 0 ">
                    <div class="user-name">
                        <p class="paragraph text-basic mb-0"><span>
                                <nb-icon class="groupColorIcon text-basic" icon="people-outline"></nb-icon>

                            </span> &nbsp; &nbsp;All Users</p>
                        <p class="groupColor groupallusershare">{{allUserLength}} User(s)</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="(!isAllUserShare) && (sharedWithUsers.length == 0)">
                    <div class="user-name">
                        <p class="paragraph text-basic mb-0"><span>
                                <nb-icon class="groupColorIcon text-basic" icon="people-outline"></nb-icon>

                            </span> &nbsp; &nbsp;Not shared with any users & groups</p>
                        <p class="groupColor"></p>
                    </div>
                </ng-container>

            </nb-card-body>
            <nb-card-footer>
                <button nbButton status="primary" class="invite mr-2" size="small" (click)="ref.close()">
                    Done
                </button>



            </nb-card-footer>

        </nb-card>
    </ng-template>

</div>



<!---------------------------------share access modal end------------------------->
