<div class="parentSkeleton">
     <div class="item mt-3 " style="display: flex;  width: 100%;">

        <div class="" style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
          <ngx-skeleton-loader class="nameFirstRow " [theme]="{ 
            'border-radius': '98px',
            height: '22px',
            width: '271px',
            
                   border: '1px solid white'
          }"></ngx-skeleton-loader>
        </div>
        <div class="" style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow " [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '135px',
        
        border: '1px solid white'
      }"></ngx-skeleton-loader>
        </div>
        <div class="" style="width: 25%;display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow "  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',
        
        border: '1px solid white'
      }"></ngx-skeleton-loader>
        </div>
        <div class="" style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow " [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '135px',
        
        border: '1px solid white'
      }"></ngx-skeleton-loader>
        </div>
     
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '270px',
        
               border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
       
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
       
        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '270px',
        
               border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
       
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>
    <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
       
        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '238px',
                  border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
              border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',
              border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
              border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '280px',
                   border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div> 
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '192px',
      
             border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '100px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '100px',
      
        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
   

     <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '278px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '238px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '272px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '194px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
     
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '267px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
     </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '276px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
     
      </div>
    </div>
    <div class="item mt-3" style="display: flex; width: 100%;">
      <div style="width: 35%; display: flex; justify-content: left; padding-left: 24px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '270px',

       border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="emailFirstRow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 25%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="jDateFirstRow"  [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
      <div style="width: 20%; display: flex; justify-content: left;">
      <ngx-skeleton-loader class="roleFirstrow" [theme]="{ 
        'border-radius': '98px',
        height: '22px',
        width: '124px',

        border: '1px solid white'
      }"></ngx-skeleton-loader>
      </div>
     
    </div> 
</div>