import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { subscribeOn } from 'rxjs-compat/operator/subscribeOn';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public showView: boolean;
  data: any;
  wsNameChanged = '';
  clickTriggered = false;

  constructor(private router: Router) { }
  Template=new Subject();
  searchloader = new Subject();
  searchString = new Subject();
  validateIP = new Subject();
  upgradeIt = new Subject();
  unSavedIp = new Subject();
  responseUploadProgress = new Subject();
  responseUploadProgressStatus = new Subject();
  redalert = new Subject();
  countClick = new Subject();
  cellClickedData1 = new Subject();
  responseDocDetails = new Subject();
  responseSelect = new Subject();
  DocketFileUploadError = new Subject();
  getOldTagsList = new Subject();
  roleClicked = new Subject();
  crumbvisible = new Subject();
  docItid = new Subject();
  docPreview = new Subject();
  ownerEnable = new Subject();
  isClose = new Subject();
  myqueueTagVerify = new Subject();
  myqueuePreview = new Subject();
  cellClickedData = new Subject();
  onhover_parser = new Subject();
  dashboardClick = new Subject();
  getTagId = new Subject();
  getDashboardUpdate = new Subject();
  accessRight = new Subject();
  closeDetailBar = new Subject();
  closeDetailBar1 = new Subject();
  notes = new Subject();
  ownedByMeTag = new Subject();
  responseTag = new Subject();
  onUploadClick = new Subject();
  minicrossval = new Subject();
  isDeleted = new Subject();
  IsSidebarClickedNotify = new Subject();
  sendRoles = new Subject();
  isSearchFocused = new Subject();
  changeLeftToggle = new Subject();
  countUpdate = new Subject();
  IsPrintDone = new Subject();
  bindMenu = new Subject();
  transferOwnerMenuAllWs = new Subject();
  IsSidebarClicked = new Subject();
  transferOwnerMenuOwnedBy = new Subject();
  deleteMenuOwnedBy = new Subject();
  deleteMenuAllWs = new Subject();
  searchData = new Subject();
  loaderProgress = new Subject();
  headerSearchName =new Subject();
  callSignin = new Subject();
  braedCrumbFirstLevel = new Subject();
  wsDisplayName = new Subject();
  doughnutval = new Subject();
  barChartHeader = new Subject();
  doughnutvalrole = new Subject();
  statsbarval = new Subject();
  selectedAccessEditor = new Subject();
  searchfirstcard = new Subject();
  searchRemoveChipOwn = new Subject();
  menuRights = new Subject();
  menuRights2 = new Subject();
  searchRemoveChipShared = new Subject();
  subscriptionPlan = new Subject();
  searchRemoveDocFromart = new Subject();
  searchRemoveDocDate = new Subject();
  wsDisplayNameForDash = new Subject();
  commonCabinetBreadcum = new Subject();
  commonCabinetBreadcumWeek= new Subject();
  refreshtoken = new Subject();
  tagsort = new Subject();
  valuetagsort = new Subject();
  tagsortvalue = new Subject();
  sortvaluenumber = new Subject();
  tagsortlabel = new Subject();
  commonCabinetBreadcumYear = new Subject();
  monthClick = new Subject();
  labelztoa = new Subject();
  numlabellasttofirst = new Subject();
  valueztoa = new Subject();
  sorttagdoc = new Subject();
  docztoa = new Subject();
  sorttagdocdate = new Subject();
  doclasttofirst = new Subject();
  doctagtoggle = new Subject();
  docordertoggle = new Subject();
  valuetagtoggle = new Subject();
  labeltagtoggle = new Subject();
  tagbreadcrumb = new Subject();

  labelbreadcrumb = new Subject();
  ownedbreadcrumb = new Subject();
  valuebreadcrumb = new Subject();
  labelClick = new Subject();
  ownedClick = new Subject ();
  ownedClickValue = new Subject();
  addeddatebreadcrumb = new Subject();
  tagdocfirstcard = new Subject();
  ownedtoggle = new Subject();
  alphaownedfirst = new Subject();
  wsName = new Subject();
  ownnameztoa = new Subject();
  owneddoc = new Subject();
  owneddoccard = new Subject();
  secondtab = new Subject();
  ownedtoggledocs = new Subject();
  alphaownedsecond = new Subject();
  ownnameztoasecond = new Subject();
  dateownedsecond = new Subject();
  numberownedfirst = new Subject();
  ownshowcard = new Subject();
  tagstatefirst = new Subject();
  valreststate = new Subject();
  searchcountdata = new Subject();
  pagedata = new Subject();
  pagedataval = new Subject();
  pageitemval = new Subject();
  changepage = new Subject();
  searchchips = new Subject();
  togglecross = new Subject();
  closehelpcenter = new Subject();
  OnLeftItemClick = new Subject();
  alphaownfirstown = new Subject();
  ownnameztoaarrow = new Subject();
  numberownarrow = new Subject();
  alphaownsecondarrow = new Subject();
  noUserSelect = new Subject();
  ownztoasecondarrow = new Subject();
  alphaownedsecondarrow = new Subject();
  ownztoasecarrow = new Subject();
  dateownedsecondarrow = new Subject();
  ownnameztoasecondarrow = new Subject();
  tagsortarrow = new Subject();
  labelztoaarrow = new Subject();
  tagsortlabelarrow = new Subject();
  numlabellasttofirstarrow = new Subject();
  tagsortvaluearrow = new Subject();
  valueztoaarrow= new Subject();
  sortvaluenumberarrow = new Subject();
  isShare = new Subject();
  verifierChange = new Subject();
  selectedDocsData = new Subject();
  sorttagdocarrow = new Subject();
  docztoaarrow = new Subject();
  sorttagdocdatearrow = new Subject();
  doclasttofirstarrow = new Subject();
  isAllUserSharevalue = new Subject();
  getuserradio = new Subject();
  isNewFileuploaded= new Subject();
  isDigiBlueBar = new Subject();
  myqueueparse = new Subject();
  wsqueueparse = new Subject();
  parserreal = new Subject();
  parserrealws = new Subject();
  doughnutvalselect = new Subject();
  refershrole = new Subject();
  doughnutvalselectref = new Subject();
  showSpinnerForDocumentStatus = new Subject();
  roleloader = new Subject();
  tdpagesspinerr = new Subject();
  tdpagesspinerref = new Subject();
  nodatadoughnutval = new Subject();
  workSpaceEnable = new Subject();
  totaldpagesnodata = new Subject();
  shareduserfullname = new Subject();
  docuserfullname = new Subject();
  uploadOpen = new Subject();
  printService = new Subject();
  myqueueThreeDot=new Subject();
  onGuestClick = new Subject();
  detailscheck = new Subject();
  detailsparserapi = new Subject();
  oneColumnSubscriptionDetails=new Subject();
  wsStatusOneColumn=new Subject();
  globalLoader=new Subject();
  sharedWithGuestIcon = new Subject();
  isLoaderDashboard = new Subject();
  uploaddata = new Subject();
  totaldpages = new Subject();
  trashdpages = new Subject();
  myQueueEnableRow = new Subject();
  myDlistEnableRow = new Subject();
  tabclickdetailact = new Subject();
  dashboardskelton = new Subject();
  myqueuedpages = new Subject();
  fordpages = new Subject();
  ownedview = new Subject();
  userSettingEmitter =new Subject();
  docSelectionStatus = new Subject();
  selectedDocId = new Subject();
  templateEditorEmitter = new Subject();
  myqueueShare = new Subject();
  ownerShipRight = new Subject();
  selectedDocumentList = new Subject();
  modalClose = new Subject();
  contentBaseData = new Subject();
  threeDotValue = new Subject();
  viewClickThreeDot = new Subject();
  searchDataPagingChange = new Subject();
  searchValueData = new Subject();
  nameBaseData = new Subject();
  dashboardReload = new Subject();
  secondarySidebarClicked = new Subject();
  miniProfileClicked = new Subject();
  sharedAccessData = new Subject();
  leftMenuLoader = new Subject();
  dashboardValueTarget = new Subject();
  menuValueRights = new Subject();
  legendcard = new Subject();
  isResponse = new Subject();
  verificationDoc=new Subject();
  IsPrintModalOpen = new Subject();
  documentId!: any;
  documentIds: any[] = [];
  docStatus = '';
  base64string = new Subject();
  transferData = new Subject();
  docRenameVal = new Subject();
  notifySilentSignIn = new Subject();
  docketNetworkError = new Subject();
  docketDataChanged = new Subject();
  documentTypeNotExist = new Subject();
}
