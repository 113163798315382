

import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, Output, TemplateRef, ViewChild, Optional, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { UserSettingService } from '../../../../@core/utils/user-setting.service';
import { SharedService } from '../../../../@core/utils/shared.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  isMyDocuments: any;
  
  isWatchDocument: any;
  isCommented: any;
  isRealTimeNotification: any;
  isAdminMail= true;
  isDocEmail= false;
  workSpaceId: any;
  createdBy: any;
  btnSave: boolean = true;
  @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>; 
  isUnsaved: boolean;
  subscrip: any;
  sideBardVal: unknown;

  constructor(private sharedService  : SharedService,@Optional() private dialogRef: NbDialogRef<any>, private dialogService: NbDialogService, private cookieService: CookieService, private fb: FormBuilder, private toastrService: NbToastrService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private data: UserSettingService){
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    if (!this.createdBy) {
      this.router.navigateByUrl('/sign-in');
    }
   }

   ngOnInit(): void {
     this.subscrip = this.sharedService.IsSidebarClickedNotify.subscribe((data) => {
       this.sideBardVal = data;
       if (this.isUnsaved) {
         this.openModal(this.unsavedChanges, false);
       }

     });
    var st = window.location.toString();
    var arr = st?.split("/");
    this.getUserNotification(); 
     this.isUnsaved = false;
  }
ngOnDestroy(){
  this?.subscrip?.unsubscribe();
}
  showToast(){
    this.toastrService.primary('Invite Sent','',);
  }

  
  //function use for get user notification start here
  isMyDocument:boolean=false;
  documentsVerifie:boolean=false;
  realTimeNotification:boolean=false
  getUserNotification() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "Wslink": arr[2],
      "CreatedBy": this.createdBy,
      
    }
    this.data.getNotificationService(postData).subscribe((response: any) => {
 
      if (response.isSuccess) {
         this.isMyDocument=response.data[0].isMyDocuments;
    
        this.documentsVerifie=response.data[0].isDocumentsVerified
        this.realTimeNotification=response.data[0].isRealTimeNotification
        localStorage.setItem('notifiychecked', response.data[0].isRealTimeNotification)

       
      } else {
  
        this.toastrService.primary(response.returnMessage);
  
      }
    })
  }
  isChanged(){
    this.isUnsaved = true;
    this.isUnsavedNotify.emit('stay');
  }
  myDocuments:boolean=false
  isDocumentsVerified:boolean=false;
  isWatchedDocument:boolean=false
  isrealtimenotification:boolean=false
  isdocumentsVerified:boolean=false

  isChecked(event:boolean, eventname){

if(eventname=='myDocuments'){
this.isMyDocument=event;
}
else if(eventname=='realTimenotify')
{this.realTimeNotification=event}

else if(eventname=='documentsVerified'){
this.documentsVerifie=event
}
this.updateNotification()
  }
  
 
    updateNotification() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "Wslink": arr[2],
      "IsMyDocuments":this.isMyDocument,
      "IsDocumentsVerified": this.documentsVerifie,
      "IsWatchDocument": false,
      "IsCommented": false,
      "isRealTimeNotification": this.realTimeNotification,
      "CreatedBy": this.createdBy,
      "guid" : localStorage.getItem('loginguid')  
    }
    this.data.updateNotificationService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.isUnsaved = false;
        this.toastrService.primary(response.returnMessage);
      } else {
        this.toastrService.primary(response.returnMessage);
      }
    })
  }
  
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.isUnsaved){
      $event.preventDefault();
      return false;
    }
    
  }
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });

  }
  @Output('isUnsaved') isUnsavedNotify = new EventEmitter()

  discardChanges(ref) {
    this.dialogRef.close();
    ref.close();
    this.isUnsavedNotify.emit('discard' + "|" + this.sideBardVal);
  }
}


