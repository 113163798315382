import { Injectable} from '@angular/core';
import {ApiIntegrationService} from '../../pages/api-integration.service'
import { NbToastrService } from '@nebular/theme';
import { SignInService } from '../sign-in.service';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { DownloadService } from '../download.service';
/**
 *  Injectable for print service 
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for print service method
 */
export class PrintService  {
  /**
 *  variable  for selected docs
 */
  private selectedDocs: any;
   /**
 *  variable  for send message
 */
  sendmessage: any;
  /**
 *  variable  for createdBy
 */
  createdBy: any;
   /**
 *  variable  for workspace id
 */
  workSpaceId: any;
   /**
 *  variable  for insert message
 */
  insertmessgae: string;
   /**
 *  variable  for user full name
 */
  userfullname: string;
    /**
 *  variable  for documentOwnerEmail
 */
  documentOwnerEmail: any;
    /**
 *  variable  for docModifiedEditedName
 */
  docModifiedEditedName: string;
  /**
 *  variable  for extension
 */
  extension: string;
  /**
 *  variable  for userEmailId
 */
  userEmailId: string;
  wsName: string;
  selectedRenamedFilesName: any[] = [];
  selectedDocId: any;
  selectedDocOriginalName: any[] = [];
  getguid: any;
  selectedDocGuidId: any[] = [];
  docownersearchablepdf: any;
/**
 *  method For selected docs
 */
  public setSelectedDocs(selectedDocs: any) {
    this.selectedDocs = selectedDocs;
  }
/**
 *  git ignore onInit
 */
  constructor(private signInService: SignInService,private readonly data: ApiIntegrationService,private toastrService: NbToastrService,private router:Router, private data1:DownloadService,
    private sharedService  :SharedService) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.userEmailId = localStorage.getItem('changepasemail');
    this.wsName = localStorage.getItem('WorkSpaceName');
    this.getguid = localStorage.getItem('loginguid') 

   }
 /**
 *  variable  for url Check
 */

   urlCheck;
/**
 *  method For validate And print Doc
 */
 format
  public validateAndprintDoc(): void {

    {
      this.urlCheck = this.router.url;
      let filePath
      if(this.urlCheck.includes('/pages/search')){
        filePath = this.selectedDocs[0].convertedpath;
      }
      else{
         filePath = this.selectedDocs[0].convertedPath;
      }
      if(!this.selectedRenamedFilesName.includes(this.selectedDocs[0].neweDocRename)){
        this.selectedRenamedFilesName.push(this.selectedDocs[0].neweDocRename);
      }
      this.selectedDocOriginalName.push(this.selectedDocs[0].originalFileName);
      this.selectedDocId =  this.selectedDocs[0].docId;
      if(!this.selectedDocGuidId.includes(this.selectedDocs[0].docGuid)){
        this.selectedDocGuidId.push(this.selectedDocs[0].docGuid)
      }
      this.selectedDocGuidId.push(this.selectedDocs[0].docGuid);
      this.docownersearchablepdf = this.selectedDocs[0].docOwnerEmailId
      filePath.replace('\\', '/');

      this.format  = this.selectedDocs[0]?.format || this.selectedDocs[0]?.formatExtension || this.selectedDocs[0]?.extension || this.selectedDocs[0].documentFormat;
        let postData: any
        let b  = []

        if (this.format != 'tiff' ||  this.format != 'tif' || this.format == 'xls' ||  this.format == 'xlsx' || this.format == 'xlsm' || this.format == 'fods' || this.format == 'ods' || this.format == 'slk') {
          b.push(this.workSpaceId + '_' + this.selectedDocs[0].docGuid + '_V0' + '.' + 'pdf');

          postData = {
            "wsoid": Number(this.workSpaceId),
            "wsName": this.wsName,
            "docCount": 1,
            "fileOrigin": 'pdf',
            "fileNames": b,
            "createdBy": this.createdBy,
            "DocIdList": this.selectedDocId.toString(),
            "OriginalFileNameList": this.selectedDocOriginalName.toString(),
            "Separator": ",",
            "renameFileNames": this.selectedRenamedFilesName,
            "guid" : this.getguid, 
            "DocGuid" : this.selectedDocGuidId,
            "DocOwner" : ''
          }
        } else {
          b.push(this.workSpaceId + '_' + this.selectedDocs[0].docGuid + '_V0' + '.' + this.format.toLowerCase());
       
          postData = {
            "wsoid": Number(this.workSpaceId),
            "wsName": this.wsName,
            "docCount": 1,
            "fileOrigin": 'original',
            "fileNames": b,
            "createdBy": this.createdBy,
            "DocIdList": this.selectedDocId.toString(),
            "OriginalFileNameList": this.selectedDocOriginalName.toString(),
            "Separator": ",",
            "renameFileNames": this.selectedRenamedFilesName,
            "guid" : this.getguid, 
            "DocGuid" : this.selectedDocGuidId,
            "DocOwner" : ''
          }
        }
  
          this.data1.downloadDocument(postData).subscribe((response) => {
            // console.log(response)
          this.updateAuditLog();
          setTimeout(() => {
            this.printDoc(response);
            let pribtObj = {fileName : this.selectedDocs[0].docRename || this.selectedDocs[0].documentName , loader : false};
            this.sharedService.IsPrintDone.next(pribtObj)
            this.selectedDocOriginalName = [];
            this.selectedRenamedFilesName = [];
            this.selectedDocGuidId = [];
          }, 2500);
       
        },
        );
    }
  }
  /**
 *  method For  print Doc
 */
  private printDoc(blobData): void {


    const blobUrl = URL.createObjectURL(blobData);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;


    iframe.id = 'print_doc';

    document.body.appendChild(iframe);

    const mediaQueryList = iframe.contentWindow.matchMedia('print');
    let pribtObj = {fileName : this.selectedDocs[0].docRename || this.selectedDocs.documentName , loader : false};
    this.sharedService.IsPrintDone.next(pribtObj)
    iframe.contentWindow.addEventListener('afterprint', (event) => {
    
    });
   
    window.onafterprint = (event) =>{
    
    
      let pribtObj = {fileName : this.selectedDocs[0].docRename || this.selectedDocs.documentName , loader : false};
      this.sharedService.IsPrintDone.next(pribtObj)
   }
  
    onafterprint = event => {
      this.updateAuditLog();
     };
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      },1000);
      setTimeout(() => {
        this.sharedService.IsPrintModalOpen.next(true);
      },1250); 
    };
    
  }

  //api integration audit log start //
  /**
 *  variable  for notification
 */
  notification = false
 /**
 *  method For  update Audit Log
 */
  public updateAuditLog() {
    let extension
    if(this.selectedDocs[0].formatExtension){
     extension = this.selectedDocs[0].formatExtension;
    }else if(this.selectedDocs[0].fileExtension){
    extension = this.selectedDocs[0].fileExtension;
    }
    else if(this.selectedDocs[0].format || this.selectedDocs[0].extension){{
      extension = this.selectedDocs[0].format || this.selectedDocs[0].extension;
    }

    }
    let documentName =  '';
    if(this.selectedDocs[0].docRename){
      documentName =  this.selectedDocs[0].docRename + '.' + extension;
    }else{
      documentName = this.selectedDocs[0].originalFileName
    }
    let currentIp = localStorage.getItem("ipAddress");
    const requestBody = {
      docId: this.selectedDocs[0].docId,
      wsoid: Number(this.workSpaceId),
      originalFileName: documentName,
      createdBy: this.createdBy,
      GUID : this.selectedDocs[0].docGuid,
      IPAddress: currentIp,
      IsIntegrationViewer: 0
    };
    this.data.UpdateAuditlogData(requestBody).subscribe((response) => {
      if(this.createdBy !== this.selectedDocs[0].docOwnerEmailId){
     
          this.notifyinsertPrint();
       
      }
    this.toastrService.primary('documents prepared for printing');
    
    
    });
  } 
/**
 *  variable  for extension val
 */
  extensionval;
  /**
 *  method For  notify insert Print
 */
  notifyinsertPrint() {
    var st = window?.location?.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'
     if(this.selectedDocs[0].formatExtension){
       this.extensionval = this.selectedDocs[0].formatExtension;
     }
     else if(this.selectedDocs[0].extension){
      this.extensionval = this.selectedDocs[0].extension;
     }
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document is printed",
      message: "<b>" + this.userfullname + "(" + this.createdBy + ")" + "</b>" + " " + "printed" + " " + this.selectedDocs[0].originalFileName +"  "+ "under your ownership. View" + " " + `<a href=${urlchange}>Owned By Me</a>`,
      notificationTo: this.selectedDocs[0].docOwnerEmailId,
      createdBy: this.userEmailId,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendPrint();
      }
      else {
        this.insertmessgae = '';
      }

    })

  };
  /**
 *  method For  notify send Print
 */
  notifysendPrint() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.selectedDocs[0].docOwnerEmailId,
      role: null,
      groupName: null,
      message: "Document is printed",
      databaseName: "",
      createdBy: this.userEmailId,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
}
