
<div [nbSpinner]="isShowSpinner" [ngClass]="hideDiv? 'verifyDiv': 'bulktagDiv'" nbSpinnerStatus="primary" style="height: calc(100vh - 232px);" id="clickedTag" (click)="getTagId('clickedTag')"
    (mouseenter)="leave($event)" (mouseleave)="leave($event)" tabindex="1" (blur)="checkBlur()" #saveTags>
    <nb-card [ngClass]="[isResponseTab ? 'docItTagOpen' : 'heightdetails']" *ngIf="openedFromViewer">
        <nb-card-header [hidden]="hideDiv" class="p-0">
            <div *ngIf="checkBulkTagHeader" class="d-flex justify-content-between align-items-center bulktagHeader">
                <div class="d-flex  align-items-center ">
                    <p class="Text Font/S1 subtitle mb-0 mr-2">Tag & Verify</p>
                    <div *ngIf="isSaving" class="d-flex  align-items-center">
                        <nb-icon icon="sync" class="text-hint"></nb-icon>
                        <p class="Text Font/P1 paragraph text-basic mx-1 mb-0">Saving</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <nb-icon icon="close-outline" class="cursor-pointer" (click)="crossdetails()"></nb-icon>
                    <button *ngIf="docid.length !== 0" (click)="verifyDocument(verifyModal)" nbButton status="primary"
                        class="ml-3">
                        Verify
                    </button>
                </div>
            </div>
            <div *ngIf="!checkBulkTagHeader" class="d-flex justify-content-between align-items-center bulktagHeader">
                <div class="d-flex  align-items-center ">
                    <p id="tags" class="Text Font/S1 subtitle mb-0 mr-2 text-basic">Tags</p>
                    <div *ngIf="isSaving" class="d-flex  align-items-center">
                        <nb-icon icon="sync" class="text-hint"></nb-icon>
                        <p class="Text Font/P1 paragraph text-basic mx-1 mb-0">Saving</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <nb-icon icon="close-outline" class="cursor-pointer" (click)="crossdetails()"></nb-icon>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body class="p-0 detailactivity">
            <div *ngIf="!checkEmptyPage() || docid.length === 0"  class="d-flex flex-column align-items-center justify-content-center blankState1">

               <div [hidden]="hideDiv" [nbSpinner]="viewspinner" style="height: 60px; width: 60px; background-color: #fff;" nbSpinnerStatus="primary" nbSpinnerSize="large" status="primary">
                <!-- <div [nbSpinner]="viewspinner" nbSpinnerStatus="primary" nbSpinnerSize="large" status="primary">
                    <img src="../../../assets/images/Folder-amico.png" />
                    
                </div>

                <p class="Text Font/P1 Paragraph mt-3">
                    Select a file to view the tags
                </p> -->
                </div>
            </div>
            <div *ngIf="checkEmptyPage() && docid.length !== 0" class="bulkTagBody" #bulkTagBody>
                <div [hidden]="hideDiv" *ngIf="multipleDocError" class="d-flex justify-content-center align-items-center multipleDocError">
                    <nb-icon icon="info-outline" class="mr-2 infoIcon"></nb-icon>
                    <p class="Text Font/P1 Paragraph mb-0">
                        You cannot see the existing tags when multiple documents are
                        selected.
                    </p>
                </div>
                <div>
                  <!-- first accordian with editing enable-->
            <div *ngIf="isViewScreen" class="accordianBox">
              <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                data-toggle="collapse" data-target="#collapseSingleValue" aria-expanded="true"
                aria-controls="collapseSingleValue">
                <p class="subtitle mb-0">Single value tags</p>
                <span class="material-symbols-outlined">
                  arrow_drop_up
                </span>
              </div>
              <div class="pr-3 cursor-pointer col-2 crossplace close-icon">
                <nb-icon icon="close-outline" (click)="crosstag()" class="cursor-pointer"></nb-icon>
            </div>
              <div class="collapse show " id="collapseSingleValue">
                <div class="" style="margin-top: 12px;">
                    <input id="singleTag" type="text" [(ngModel)]="getUserDataSingleTag" nbInput
                        (blur)="SaveSingleTagData()" (keyup)="saveUserSingleTag($event)" (paste)="onPaste($event)"
                        fullWidth (keypress)="checkNonEnglish($event); checkTagLength($event)" status="basic"
                        fieldSize="medium" placeholder="Add single-value tags seperated by space" class="mb-1" />
                </div>
                <div class="d-block divblock">
                  <div *ngIf="getUserDataSingleTag !== '' || singleTagsList?.length >= 1 || singleTagFlag ">
                      <div #singleTags class="w3-container chips" *ngFor="let tag of singleTagsList">
                          <p unselectable="on" maxlength="25" class="Text Font/S2 subtitle-2 mb-0 mr-1"
                              style="color: white; float: left">
                              {{ tag }}
                          </p>
                          <nb-icon icon="close-outline" class="cursor-pointer" (click)="removeChip(tag)"
                              style="position: relative; top: 2px"></nb-icon>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- first accordian with only viewing enable-->
            <div *ngIf="!isViewScreen" class="accordianBox">
                <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                  data-toggle="collapse" data-target="#collapseSingleValue" aria-expanded="true"
                  aria-controls="collapseSingleValue">
                  <p class="subtitle mb-0">Single value tags</p>
                  <span class="material-symbols-outlined">
                    arrow_drop_up
                  </span>
                </div>
                <div class="collapse show " id="collapseSingleValue">
                  <div class="d-block divblock">
                    <div *ngIf="getUserDataSingleTag !== '' || singleTagsList?.length >= 1 || singleTagFlag ">
                             <p>
                                {{singleTagsList?.join(', ')}}
                            </p>

                    </div>
                    <div *ngIf="singleTagsList?.length === 0 ">
                        <p>
                            There are no single-value tags attached to this document.
                       </p>
               </div>
                  </div>
                </div>
              </div>
                </div>

                <!-- horizenLine start-->
                <div id="animated-example" class="animated fadeInDown"></div>
                <div class="d-flex justify-content-center align-items-center card-row mt-3">
                    <div class="my-2 mb-3 horizenLine"></div>
                </div>
                <!-- horizenLine end-->

                <!-- selection accordian with editing enable -->
                <div *ngIf="isViewScreen" class="accordianBox">
                  <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                    data-toggle="collapse" data-target="#collapseTag" aria-controls="collapseTag">
                    <p class="subtitle mb-0">Tags with Label</p>
                    <div class="d-flex">
                        <div *ngIf="hideDiv" class="collapse show" id="collapseTag" style="color: blue;">
                            <span (click)="openModalTemplate($event)" (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation()">Template Editor</span>
                          </div>
                    <span class="material-symbols-outlined">
                      arrow_drop_up
                    </span>
                </div>
                  </div>
                  <div class="collapse show" id="collapseTag">
                    <div class="" style="margin-top: 12px;">
                      <div style="width: 100%" class="mb-2">
                        <nb-select placeholder="Choose template" appendTo="body" [selected]="verifiedtype"
                            (selectedChange)="getDataFromInput($event)" size="large" class="width100" style="width: 100%">
                            <nb-option [value]="option" class="newSelect" *ngFor="let option of selectedShowCase" #dropDown
                                id="dropDown" (mouseenter)="leave($event)" (mouseleave)="leave($event)" (click)="documentSelected($event)">
                                {{ option?.tempName }}
                            </nb-option>
                        </nb-select>
                    </div>
                    <div class="">
                        <button style="width: 100%" class="outlineColor" (click)="addTAgValue()" nbButton outline
                            status="primary">
                            Add tags with label
                        </button>
                    </div>
                    <div *ngIf="blankState" class="mt-3">
                        <!-- Custom field Array -->
                        <div style="width: 100%;" *ngFor="let items of customArray; index as i" class="d-flex mt-2">
                            <div *ngIf="(!ShowDateCustom[i] && isCustomBorderShow[i])" class="line-text" nbTooltip="Text"
                                nbTooltipStatus="info" nbTooltipPlacement="left">
                            </div>
                            <div *ngIf="(!ShowDateCustom[i] && !isCustomBorderShow[i])" class="linetextRemove"
                                nbTooltip="Text" nbTooltipStatus="info" nbTooltipPlacement="left">
                            </div>
                            <div *ngIf="(ShowDateCustom[i] && isCustomBorderShow[i])" class="line-date" style="width: 4px"
                                nbTooltip="Date" nbTooltipStatus="info" nbTooltipPlacement="left">
                            </div>
                            <div *ngIf="(ShowDateCustom[i] && !isCustomBorderShow[i])" class="linetextRemove"
                                style="width: 4px" nbTooltip="Date" nbTooltipStatus="info" nbTooltipPlacement="left">
                            </div>
                            <div style="column-gap: 8px ;width: 100%;" class="d-flex">
                                <div style="width: 39%">
                                    <input style="height: 30px; padding: 6px; width: 100%" class="paragraph-2 textWrap"
                                        [(ngModel)]="islabel[i]" (ngModelChange)="labelChanged($event)" type="text"
                                        status="basic" name="label_{{ i }}" placeholder="Label"
                                        (blur)="isfocusCustom(i, $event);saveCustomTags(i, $event, 'label', 'add');"
                                        (focus)="isfocusCustom(i, $event)" maxlength="15" id="customLabel_{{i}}_1"
                                        (input)="customArray[i].tagLabel = islabel[i]"
                                        [ngClass]="[!isCustomBorderShow[i] ? 'inputfieldtFont' : 'inputfieldtFont1']" />
                                </div>
                                <div style="width: 49%" class="d-flex flex-column">
                                    <div>
                                        <input *ngIf="ShowDateCustom[i]" (focus)="customValueFocused(i, 'date', $event)"
                                            id="customValue_{{i}}_1" class="paragraph-2 placeholderText" status="basic"
                                            type="date" (selectedChange)="valueChanged($event, 'value')"
                                            [(ngModel)]="isvalue[i]" name="value_{{ i }}"
                                            (blur)="customValueBlur(i, 'date', $event); saveCustomTags(i, $event, 'value', 'add');"
                                            style="height: 30px; padding: 6px; width: 100%"
                                            [ngClass]="[isCustomValueFocused[i] ? 'contentBox' : 'contentBoxOpacity']" />
                                    </div>
                                    <div *ngIf="!ShowDateCustom[i]" id="customValue_{{i}}" style="width: 100%;" contenteditable="true"
                                        data-placeholder="Value" type="text" rows="1" data-max-length="10"
                                        class="paragraph-2 checkBorder" #textArea status="basic"
                                        (selectedChange)="valueChanged($event, 'value')" [(ngModel)]="isvalue[i]"
                                        placeholder="Value" [textContent]="isvalue[i]" name="value_{{ i }}"
                                        (paste)="onPasteValue($event, 'custom', i)"
                                        (blur)="customValueBlur(i, 'text', $event); saveCustomTags(i, $event, 'value', 'add')"
                                        (keydown)="handleKeyboardEvent($event, i, 'custom')"
                                        (focus)="customValueFocused(i, 'text', $event)"
                                        (input)="isvalue[i] = tagValueInput(isvalue[i], textArea.innerText, $event, 'custom', i);"
                                        [ngClass]="[isCustomValueFocused[i] ? 'contentBox' : 'contentBoxOpacity']" onkeypress="return (this.innerText.length <= 40)">
                                    </div>
                                </div> 
                                <div class="d-flex justify-content-around" style="width: 12%; padding-top: 6px;"
                                    name="icon_{{ i }}">
                                    <nb-icon icon="calendar-outline" class="cursor-pointer" *ngIf="!ShowDateCustom[i]"
                                        nbTooltip="Convert to date tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                        (click)="changeCustomTextInputType(i)"></nb-icon>
                                    <nb-icon icon="text-outline" class="cursor-pointer" *ngIf="ShowDateCustom[i]"
                                        nbTooltip="Convert to text tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                        (click)="changeCustomDateInputType(i)"></nb-icon>
                                    <nb-icon icon="close-outline" class="cursor-pointer"
                                        (click)="removeTagValue(i)"></nb-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Text Field Array started from here-->
                    <div style="width: 100%;" *ngFor="let item of textFieldArray; index as i" class="d-flex  mt-2"
                        #textArray id="textArray" (mouseenter)="leave($event)" (mouseleave)="leave($event)">
                        <div style="position: absolute;">
                            <div *ngIf="!ShowDate[i]" class="line-text" style="width: 4px" nbTooltip="Text"
                                nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                            <div *ngIf="ShowDate[i]" class="line-date" style="width: 4px" nbTooltip="date"
                                nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                        </div>
                        <div style="column-gap: 8px ;width: 100%;" class="d-flex">
                            <div style="width: 39%">
                                <input id="textLabel_{{i}}_1" style="height: 30px; padding: 6px; width: 100%"
                                    (focus)="isTextfocus(i, $event)" class="paragraph-2 textWrap"
                                    (ngModelChange)="labelChanged($event, 'label')" status="basic"
                                    [(ngModel)]="item.tagLabel" type="text" (keyup)="checkLabelLength($event)"
                                    [value]="item.tagLabel" placeholder="Label" maxlength="15"
                                    (blur)="isTextfocus(i, $event); saveCustomTags(i, $event, 'label', 'text'); getTextBoxHide('tagLabel', $event, item)"
                                    [ngClass]="[!isTextBorderShow[i] ? 'inputfieldtFont1': 'inputfieldtFont']" />
                            </div>
                            <div style="width: 49%">
                                <input *ngIf="ShowDate[i]" type="date" maxlength="40"
                                    (focus)="customValueFocused(i, 'date', $event)" class="paragraph-2 placeholderText"
                                    (selectedChange)="valueChanged($event, 'value')" [(ngModel)]="item.tagValue"
                                    [value]="item.tagValue" status="basic" id="textValue_{{i}}_2"
                                    (blur)="TextValueBlur(i, 'date', $event); saveCustomTags(i, $event, 'value', 'text'); getTextBoxHide('tagvalue', $event, item)"
                                    (focus)="TextValueFocused(i, 'date', $event); getTextBoxShown()"
                                    [ngClass]="[isTextValueFocused[i] ? 'contentBox' : 'contentBoxOpacity']" />
                                <div *ngIf="!ShowDate[i]" id="textValue_{{i}}" data-placeholder="Value" style="width: 100%;"
                                    contenteditable="true" type="text" data-max-length="10" rows="1" #textArea
                                    class="paragraph-2 checkBorder" (selectedChange)="valueChanged($event, 'value')"
                                    [(ngModel)]="item.tagValue"
                                    (input)="item.tagValue = tagValueInput(item.tagValue, textArea.innerText, $event, 'text', i)"
                                    status="basic" placeholder="Value" [textContent]="item.tagValue"
                                    (paste)="onPasteValue($event, 'text', i)"
                                    (blur)="TextValueBlur(i, 'date', $event); saveCustomTags(i, $event, 'value', 'text'); getTextBoxHide('tagvalue', $event, item)"
                                    (keydown)="handleKeyboardEvent($event, i, 'text')"
                                    (focus)="TextValueFocused(i, 'date', $event); getTextBoxShown()"
                                    [ngClass]="[isTextValueFocused[i] ? 'contentBox' : 'contentBoxOpacity']" onkeypress="return (this.innerText.length <= 40)">
                                </div> 
                            </div>
                            <div class="d-flex justify-content-around" style="width: 12%;padding-top: 6px;"
                                name="icon_{{ i }}">
                                <nb-icon icon="calendar-outline" class="cursor-pointer" *ngIf="!ShowDate[i]"
                                    nbTooltip="Convert to date tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                    (click)="changeTextInputType(i)"></nb-icon>
                                <nb-icon icon="text-outline" class="cursor-pointer" *ngIf="ShowDate[i]"
                                    nbTooltip="Convert to text tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                    (click)="changeDateInputType(i)"></nb-icon>
                                <nb-icon icon="close-outline" class="cursor-pointer"
                                    (click)="removeTextTag(i, 'text')"></nb-icon>
                            </div>
                        </div>
                    </div>
                    <!-- Date Field Array Started from here -->
                    <div style="width: 100%;" *ngFor="let item of datefieldArray; index as i" class="d-flex  mt-2"
                        #dateArray>
                        <div style="position: absolute;">
                            <div *ngIf="ShowDate1[i]" class="line-date" style="width: 4px" nbTooltip="Date"
                                nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                            <div *ngIf="!ShowDate1[i]" class="line-text" style="width: 4px" nbTooltip="Text"
                                nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                        </div>
                        <div style="column-gap: 8px ;width: 100%;" class="d-flex">
                            <div style="width: 39%">
                                <input style="height: 30px; padding: 6px; width: 100%" class="paragraph-2 textWrap"
                                    status="basic" maxlength="15" [(ngModel)]="item.tagLabel" type="text"
                                    (keypress)="checkLabelLength($event)" [value]="item.tagLabel" placeholder="Label"
                                    (blur)="isDatefocus(i, $event); saveCustomTags(i, $event, 'label', 'date'); getTextBoxHide()"
                                    (focus)="isDatefocus(i, $event); getTextBoxShown('tagLabel', $event, item)"
                                    id="dateLabel_{{i}}_2"
                                    [ngClass]="(isDateBorderShow[i] && item.tagLabel !== '') ? 'inputfieldtFont1' : 'inputfieldtFont'" />
                            </div>
                            <div style="width: 49%" class="d-flex flex-column">
                                <input *ngIf="ShowDate1[i]" type="date" maxlength="40" contenteditable="true"
                                    style="height: 30px; padding: 6px; width: 100%" class="paragraph-2 placeholderText"
                                    [(ngModel)]="item.tagValue" [value]="item.tagValue" status="basic" placeholder="Value"
                                    (blur)="DateValueBlur(i, 'date', $event); saveCustomTags(i, $event, 'value', 'date'); getTextBoxHide()"
                                    (focus)="DateValueFocused(i, 'date', $event); getTextBoxShown('tagLabel', $event, item)"
                                    id="dateValue_{{i}}_1"
                                    [ngClass]="[isDateValueFocused[i] ? 'contentBox' :'contentBoxOpacity']" />
                                <div class="checkBorder">
                                    <div *ngIf="!ShowDate1[i]" id="dateValue_{{i}}" data-placeholder="Value" contenteditable="true" type="text"
                                        data-max-length="10" rows="1" #textArea [textContent]="item.tagValue"
                                        [(ngModel)]="item.tagValue" [value]="item.tagValue" status="basic"
                                        placeholder="Value" (paste)="onPasteValue($event, 'date', i)"
                                        (keydown)="handleKeyboardEvent($event, i, 'date')"
                                        (input)="item.tagValue = tagValueInput(item.tagValue, textArea.innerText, $event, 'date', i)"
                                        (blur)="DateValueBlur(i, 'date', $event); saveCustomTags(i, $event, 'value', 'date'); getTextBoxHide()"
                                        (focus)="DateValueFocused(i, 'date', $event); getTextBoxShown('tagLabel', $event, item)"
                                         [ngClass]="[isDateValueFocused[i] ? 'contentBox' : 'contentBoxOpacity']" onkeypress="return (this.innerText.length <= 40)">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-around" style="width: 12%;padding-top: 6px;">
                                <nb-icon icon="calendar-outline" class="cursor-pointer" *ngIf="!ShowDate1[i]"
                                    nbTooltip="Convert to date tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                    (click)="changeDate1InputType(i)"></nb-icon>
                                <nb-icon icon="text-outline" class="cursor-pointer" *ngIf="ShowDate1[i]"
                                    nbTooltip="Convert to text tag" nbTooltipPlacement="bottom" nbTooltipStatus="info"
                                    (click)="changeDate2InputType(i)"></nb-icon>
                                <nb-icon icon="close-outline" class="cursor-pointer"
                                    (click)="removeDateTag(i, 'date')"></nb-icon>
                            </div>
                        </div>
                    </div>

                    <!-- Dropdown dield Array started from here -->
                    <div style="width: 100%;" *ngFor="let item of dropdownArray; index as i"
                        [ngClass]="isDropDownBorderShow[i] && item.tagLabel !== '' ? 'borderLeftDropDown' : 'borderLeft1'"
                        class="d-flex  mt-2">
                        <div style="position: absolute;">
                            <div class="line-drodown" style="width: 4px" nbTooltip="Dropdown" nbTooltipStatus="info"
                                nbTooltipPlacement="left"></div>
                        </div>
                        <div style="column-gap: 8px ;width: 100%;" class="d-flex">
                            <div style="width: 39%">
                                <input style="height: 30px; padding: 6px; width: 100%" class="paragraph-2 textWrap"
                                    type="text" status="basic" maxlength="15" placeholder="Label"
                                    [(ngModel)]="item.tagLabel" (keypress)="checkLabelLength($event)"
                                    [value]="item.tagLabel"
                                    (blur)="isfocusDropDown(i, $event); saveCustomTags(i, $event, 'label', 'drop');getTextBoxHide()"
                                    (focus)="getTextBoxShown(); isfocusDropDown(i, $event)" id="dropdownLabel_{{i}}"
                                    [ngClass]="[isDropDownBorderShow[i] && item.tagLabel !== '' ? 'contentBoxOpacity' : 'contentBox']" />
                            </div>
                            <div style="width: 49%" class="d-flex">
                                <nb-select placeholder="item" appendTo="body" (selectedChange)="getSelectedData($event, i)"
                                    size="large" class=" height30" style="width: 100%;" [selected]="selected[i]"
                                    [ngClass]="[(selected[i] !== '' && isDropdownValueFocused[i]) ? 'dropdownOpacity' : 'dropdownBox']"
                                    (blur)="saveCustomTags(i, $event, 'value', 'drop')">
                                    <nb-option [value]="item" class="newSelect" *ngFor="let item of dropdownValue[i]"
                                        (focus)="dropdownFocus('focus', i)" (blur)="dropdownFocus('blur', i)"
                                        style="padding-left:6px">
                                        {{ item }}</nb-option>
                                </nb-select>
                            </div>
                            <div class="d-flex justify-content-end"
                                style="width: 12%; padding-top: 6px; padding-right: 3px;">
                                <nb-icon icon="close-outline" class="cursor-pointer"
                                    (click)="removeDropdownTag(i, 'dropdown')"></nb-icon>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-center align-items-center">
                        <div class="my-2 horizenLine"></div>
                    </div> -->



                    </div>
                  </div>
                </div>
                <!-- selection accordian with only view enable -->
                <div *ngIf="!isViewScreen" class="accordianBox">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                      data-toggle="collapse" data-target="#collapseTag" aria-controls="collapseTag">
                      <p class="subtitle mb-0">Tags with Label</p>
                      <span class="material-symbols-outlined">
                        arrow_drop_up
                      </span>
                    </div>
                    <div class="collapse show" id="collapseTag">

                      <!-- Text Field Array started from here-->
                      <div *ngIf = "textFieldArray.length >=1 || datefieldArray.length >=1 || dropdownArray.length >=1 " >
                      <div style="width: 100%;" *ngFor="let item of textFieldArray; index as i" class="d-flex  mt-2"
                          #textArray id="textArray" (mouseenter)="leave($event)" (mouseleave)="leave($event)">
                          <div style="position: absolute;">
                              <div *ngIf="!ShowDate[i]" class="line-text" style="width: 4px" nbTooltip="Text"
                                  nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                              <div *ngIf="ShowDate[i]" class="line-date" style="width: 4px" nbTooltip="date"
                                  nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                          </div>
                          <div style="column-gap: 8px ;width: 100%;" class="d-flex ml-2 mt-1">
                              <div style="width: 39%">
                                    {{item.tagLabel}}
                              </div>
                              <div style="width: 49%">
                                  {{item.tagValue}}
                              </div>
                          </div>
                      </div>

                      <!-- Date Field Array Started from here -->
                      <div style="width: 100%;" *ngFor="let item of datefieldArray; index as i" class="d-flex  mt-2"
                          #dateArray>
                          <div style="position: absolute;">
                              <div *ngIf="ShowDate1[i]" class="line-date" style="width: 4px" nbTooltip="Date"
                                  nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                              <div *ngIf="!ShowDate1[i]" class="line-text" style="width: 4px" nbTooltip="Text"
                                  nbTooltipStatus="info" nbTooltipPlacement="left"></div>
                          </div>
                          <div style="column-gap: 8px ;width: 100%;" class="d-flex ml-2 mt-1">
                              <div style="width: 39%">
                                      {{item.tagLabel}}
                              </div>
                              <div style="width: 49%" class="d-flex flex-column">
                                  {{item.tagValue}}
                              </div>
                          </div>
                      </div>

                      <!-- Dropdown dield Array started from here -->
                      <div style="width: 100%;" *ngFor="let item of dropdownArray; index as i"
                          [ngClass]="isDropDownBorderShow[i] && item.tagLabel !== '' ? 'borderLeftDropDown' : 'borderLeft1'"
                          class="d-flex  mt-2">
                          <div style="position: absolute;">
                              <div class="line-drodown" style="width: 4px" nbTooltip="Dropdown" nbTooltipStatus="info"
                                  nbTooltipPlacement="left"></div>
                          </div>
                          <div style="column-gap: 8px ;width: 100%;" class="d-flex ml-2 mt-1">
                              <div style="width: 39%">
                                      {{item.tagLabel}}
                              </div>
                              <div style="width: 49%" class="d-flex">
                                  {{dropdownValue[i]}}
                              </div>
                          </div>
                      </div>
                      </div>
                      <div *ngIf="textFieldArray.length === 0 && datefieldArray.length === 0 && dropdownArray.length === 0 ">
                        <p>
                            There are no label-value tags attached to this document.
                       </p>
                    </div>

                    </div>
                </div>
                 <!-- horizenLine start-->
                <div id="animated-example" class="animated fadeInDown"></div>
                <div class="d-flex justify-content-center align-items-center mt-3">
                    <div class="my-2 mb-3 horizenLine"></div>
                </div>
                <!-- horizenLine end-->

                <!-- third accordian with editing enable-->
                <!-- Text Area for Notes-->
            <div *ngIf="isViewScreen" class="accordianBox">
              <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                data-toggle="collapse" data-target="#collapseNotes" aria-expanded="true" aria-controls="collapseNotes">
                <p class="subtitle mb-0">Notes</p>
                <span class="material-symbols-outlined">
                  arrow_drop_up
                </span>
              </div>
              <div class="collapse show" id="collapseNotes">
                <div class="" style="margin-top: 12px;">
                  <textarea nbInput class="textAreaField" fullWidth placeholder="Add notes"
                        (keyup)="saveUserNote($event)" id="noteClick" (blur)="SaveData()"
                        [(ngModel)]="tagNote" maxlength="40"></textarea>
                </div>
              </div>
            </div>
            <!-- third accordian with only viewer enable-->
                <!-- Text Area for Notes-->
                <div *ngIf="!isViewScreen" class="accordianBox">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                      data-toggle="collapse" data-target="#collapseNotes" aria-expanded="true" aria-controls="collapseNotes">
                      <p class="subtitle mb-0">Notes</p>
                      <span class="material-symbols-outlined">
                        arrow_drop_up
                      </span>
                    </div>
                    <div class="collapse show" id="collapseNotes">
                      <div *ngIf="tagNote.length >=1 "  style="margin-top: 12px;">
                              {{tagNote}}
                      </div>
                      <div *ngIf="tagNote.length === 0">
                        <p>
                            There are no notes attached to this documents.
                       </p>
               </div>
                    </div>
                  </div>
                <!-- third accordian end -->
            </div>
        </nb-card-body>
    </nb-card>
</div>
<!-- modal for   Verify without tags-->
<ng-template #verifyModal chng-verify let-data let-ref="dialogRef">
    <nb-card class="verify_modal">
        <nb-card-header>
            <div class="justify-content-between d-flex">
                <h6 class="subtitle text-basic">Verify without tags</h6>
            </div>
        </nb-card-header>

        <nb-card-body>
            <div>
                <p class="mb-2 Text Font/P1 Paragraph">
                    You have not added tags to the documents. Are you sure you want to
                    continue?
                </p>
                <div class="d-flex">
                    <nb-checkbox status="basic" class="mr-3" (click)="hideModal()"></nb-checkbox>
                    <p class="Text Font/S2 Subtitle mb-0">Do not show me again</p>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button class="mr-2" status="primary" nbButton (click)="
          ref.close();
          verifyFlag = true;
          showSpinner = true;
          SaveTagVerifyData();
        " size="small">
                Confirm
            </button>
            <button (click)="ref.close(); verifyFlag = false" size="small" class="change-verfier-btn" size="small">
                <b class="text-basic">CANCEL</b>
            </button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<!-- template editor selector start here -->
<ngx-template-editor [tagTempList]="tagTempList" [templateInput]="templateInput" [selectedTemplate]="selectedTemplate"
  [selectedTemplateVerify]="selectedTemplate"(tagTemplate)="tagTemplateEdit($event)" ></ngx-template-editor>

<!-- template editor selector end here -->
