import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxClickOutside]'
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef) { }

  @Output()
  public clickOut = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickInside) {
      this.clickOut.emit(null);
    
    }
    
  }

}

