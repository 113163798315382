
import { NbIconComponent, NbMenuItem, NbSidebarComponent, NbSidebarService, NbToastrService } from '@nebular/theme';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { OwnerControlService } from '../../../@core/utils/owner-control.service';
import { DashboardService } from '../../../@core/utils/dashboard.service'
import {SharedServiceService} from '../../../shared-service.service';
import { SharedService } from '../../../@core/utils/shared.service';
import { WorkspaceAdminService } from '../../../@core/workspace-admin.service';
import { MENU_ITEMS } from "../../../../app/pages/pages-menu";
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import {

  NbMenuService,
} from "@nebular/theme";
import { SignInService } from '../../../@core/sign-in.service';
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: "./one-column.layout.html",
})
export class OneColumnLayoutComponent implements OnInit {
  @ViewChild("upgradeModalDcoins") upgradeModalDcoins: TemplateRef<any>;
  wsName: any;
  private workSpaceId;
  private createdBy;
  public totalspace;
  version;
  public sizeAllocated;
  firstLevel: string;
  wsCreatedDateCount: any;
  subscriptionDetailsData;
  wsStatusOneColumnData;
  isDigiActive: string;
  subscriptionPlan;
  menuRights;
  freeDpage: string;
  trialFree: string;
  isInfineTrial: boolean;
  isInfiniteDpage: boolean;
  subscriptionType: any;
  totalDpages: any;
  showDaysInsideBar: boolean;
  isLoader: boolean =true;
  guid: any;
  freePlan: boolean;
  roleName: string;
  subscriptionTypePaid = false;
  currentYear: number;
  customerID: string;
  innerwidth: number;
  removeClassFixed: boolean = true;
  sidebarCollapsed = false;
  totalDCoins: number = 0;
  /**
   * for hrefData
   */
  hrefData: string;
    /**
   * for workspaceDomain
   */
  workspaceDomain: string;
    /**
   * for wsSubDomain
   */
  wsSubDomain: string;
    /**
   * for emailId
   */
  emailId: string;
    /**
   * for wsId
   */
  wsId: string;
    /**
   * for dcoinsOn
   */
  dcoinsOn: boolean = true;
      /**
   * for getting latest information about features from cockpit
   */
  getallparseflag: any;


  @Input()
  public set isDocumentUploadEnabled(val: boolean) {
    this.isDocumentUpload = val;
  }
  globalwsStatusFlag: boolean = false;
  wsstatus: boolean = true;
  isDocumentUpload: boolean;
  constructor(private cd: ChangeDetectorRef, private router: Router,public sidebarService: NbSidebarService,
    private sharedservice:SharedServiceService, private menuService : NbMenuService, private data: DashboardService, private toastrService: NbToastrService,
    private ownerService: OwnerControlService, private cookieService: CookieService, private dialogService: NbDialogService, private sharedService: SharedService, private subdata: WorkspaceAdminService,private signInService: SignInService,) {
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.roleName = localStorage.getItem('roleName')
    this.createdBy = localStorage.getItem('localloginId')
    this.version = sessionStorage.getItem('buildVersion')
    this.wsSubscriptionRemainDaysCount = localStorage.getItem('subscriptionRemainingDaysCount') || '0';
    this.isDigiActive = localStorage.getItem('DigiActive');
    this.freeDpage = localStorage.getItem('freeDpage');
    this.trialFree = localStorage.getItem('trialFree');
    localStorage.getItem('planDisplayName')  ? this.subscriptionPlan = localStorage.getItem('planDisplayName') : '';
  }
  isWorkspaceEnabled: any = true;
  ngOnInit(): void {
    this.getBannerStatus();
    this.isDcoinEnabled();
    this.cd.detectChanges();
    this.wsStatusH();
    // this.GetInvoiceList();
    this.getYear();

     this.sharedService.transferData.subscribe((res) => {
       if(res=='refreshDcoinsData')
       {
        this.ngOnInit()
       }
     });

    this.sharedservice.hideSideBar.subscribe((data:any)=>{
      this.sidebarCollapsed = data;
    })
    // this.isDigiActive = localStorage.getItem('DigiActive');
    this.getguid = localStorage.getItem('loginguid');
    // this.isDigiActive = localStorage.getItem('DigiActive');
    // if (this.isDigiActive && this.isDigiActive == '0' || this.isDigiActive == null || this.isDigiActive == "") {
    //   this.globalwsStatusFlag = false;
    //   this.cd.detectChanges();
    // }
    // else {
    //   this.globalwsStatusFlag = true;
    //   this.cd.detectChanges();
    // }
    
    if(this.removeClassFixed) {
      let windowSize = window.innerWidth;
      if(windowSize <=1200) {
        let mySidebar; 
        mySidebar = <HTMLElement>document.getElementById("navbar");
        if(mySidebar.classList.contains('fixed')) {
          mySidebar.classList.remove('fixed');
        } 
      }
    }

    this.sharedService.oneColumnSubscriptionDetails.subscribe((data) => {
      this.subscriptionDetailsData = data;
      this.wsSubscriptionDetails(this.subscriptionDetailsData);
      this.cd.detectChanges();
    })
    this.sharedService.leftMenuLoader.subscribe((data) => {
      this.isLoader =false;
      this.cd.detectChanges();
    })

    this.subscriptionPlan.toUpperCase() === 'FREE' ? this.freePlan = true : this.freePlan = false;
    this.sharedService.subscriptionPlan.subscribe((data) => {
      this.subscriptionPlan =data;
    });
    this.sharedService.menuRights.subscribe((data) => {
      this.menuRights =data;
    });

    this.wsSubscriptionRemainDaysCount = localStorage.getItem('subscriptionRemainingDaysCount') || '0';
    this.sharedService.wsName.subscribe((data) => {
      this.wsName = localStorage.getItem('WorkSpaceName')
    });
    this.sharedService.workSpaceEnable.subscribe((data) => {
      this.isWorkspaceEnabled = data;
    })

    this.sharedService.wsStatusOneColumn.subscribe((data) => {
      this.wsStatusOneColumnData = data;
      this.workspacestatus(this.wsStatusOneColumnData);
    })
    this.sharedService.isNewFileuploaded.subscribe((data) => {
      if (data) {
        this.wsSubscriptionDetails(this.subscriptionDetailsData);
      }
    })
    this.sharedService.totaldpages.subscribe((data) => {
      this.totaldpages(data)
     })

     this.sharedService.fordpages.subscribe((data) => {

  })
  this.sharedService.trashdpages.subscribe((data) => {
    this.trashdpages(data)
})

this.sharedService.myqueuedpages.subscribe((data) => {
  this.myqueuedpages(data)
})

    this.findHighestZIndex("div");
    /*
    * getting value for digitization status
    */
    // let flag = '';
    // this.sharedService.isDigiBlueBar.subscribe((e: any) => {

    //   flag = e;
    //   if (flag && flag == '0' || flag == null || flag == "") {
    //     this.globalwsStatusFlag = false;
    //     this.cd.detectChanges();
    //   }
    //   else {
    //     this.globalwsStatusFlag = true;
    //     this.cd.detectChanges();
    //   }
    // })
    /*
    * Getting status for ws status
    */
    let wsStatDis = localStorage.getItem('WSstatusDisabled');
    if (wsStatDis == "true") {
      this.wsstatus = false;
    }
    else {
      this.wsstatus = true;
    }
  }

  wsStatusH() {
    let win = window.location.href;
    let wsLink = win.split("/")[2];
    let postData = {
      "WsLink": wsLink,
      "emailId": localStorage.getItem("localloginId")
    }
    this.signInService.wsStatus(postData).subscribe((response: any) => {
      if (response) {
      let isDigi = response?.data[0]?.isDigi;
        if (isDigi && isDigi == '0' || isDigi == null || isDigi == "") {
        this.globalwsStatusFlag = false;
        this.cd.detectChanges();
      }
      else {
        this.globalwsStatusFlag = true;
        this.cd.detectChanges();
      }
    }
      
    })
  }
  isDcoinEnabled() {
    this.workSpaceId = localStorage.getItem('localwsId')
  let parserdata = {
    wsoid: Number(this.workSpaceId),
    createdBy: ""
  }
  this.getallparseflag = '';
  this.signInService.getparserflag(parserdata).subscribe((response: any) => {
    if (response.isSuccess) {
      this.getallparseflag = response.data;
      for (let i = 0; i <= this.getallparseflag.length - 1; i++) {
        if (this.getallparseflag[i].uniqueID === 'FID-0063') {
          localStorage.setItem('dcoins', this.getallparseflag[i].isActive);
        }
        this.dcoinsOn = localStorage.getItem("dcoins").toLowerCase().toString()==='true'?true:false;
      }
      this.cd.detectChanges();
    }
  })
  }
  ngDoCheck() {
    this.sharedService.wsName.subscribe((data) => {
      this.wsName = localStorage.getItem('WorkSpaceName')
    });
    if(this.removeClassFixed) {
      let windowSize = window.innerWidth;
      if(windowSize <=1200) {
        let mySidebar; 
        mySidebar = <HTMLElement>document.getElementById("navbar");
        if(mySidebar.classList.contains('fixed')) {
          mySidebar.classList.remove('fixed');
        } 
      }
    }
  }
  HandleFlags(){
    localStorage.setItem('EnterActive' , "addgdg");
  }
  public dashboard(event) {
    this.router.navigate(['/pages/dashboard']);
    this.firstLevel = event;
    localStorage.setItem('breadCrumbFirstLvl', this.firstLevel);
    this.sharedService.braedCrumbFirstLevel.next();
    this.sharedService.dashboardClick.next();
    this.sharedService.OnLeftItemClick.next();
    this.sharedService.menuRights.next(this.menuRights);
  }
  @Output() newItemEvent = new EventEmitter<string>();
  DocumentUpload(data) {
    if (data) {
      this.isDocumentUpload = true;
    }
    localStorage.getItem('isDocumentUpload');
  }
  workspacestatus(data) {
    if (data.data[0].wsStatus === "Active") {
      this.sharedService.isDigiBlueBar.next(data.data[0].isDigi)
      localStorage.setItem('AllwsFlag', data.data[0].isDigiUser);
      localStorage.setItem('DigiActive', data.data[0].isDigi);
    }
    if (data.data[0].wsStatus === "Suspended") {
      this.router.navigate(['/pages/workspace-admin/ws-management']);
    }
  }

  
  private destroy$ = new Subject<void>();
  selectedItem: string;
  menuItems = MENU_ITEMS;
  wsSubscriptionRemainDaysCount = "0";
  dpagesCount: any;
  dpagesCountUsed :any;
  dpagesKB: any;
  formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes === 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }

  /**
   * To collapse the sidebar on menu item clicked for below 1200px resolution
   * @param event 
   */

  onMenuItemClick(event: MouseEvent): void{
    let windowSize = window.innerWidth;
      if(windowSize <=1200) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('menu-title')){
      const parentElement = target.parentElement;
      const hasExpandStateSibling = Array.from(parentElement.children).some(
        (child) => child !== target && child.classList.contains('expand-state')
      );
      if (!hasExpandStateSibling) {
    this.sidebarCollapsed = true;
    this.sidebarService.toggle(true, 'menu-sidebar');
  }}}}

  private wsSubscriptionDetails(data) {
   let current = data?.data[0].dpage?.split(" ")[0];
   let total = data?.data[0].dpage?.split(" ")[2].split('|')[0];
   if(data?.data[0].dpage?.split(" ")[2].split('|')[2] === '1'){
    total = total* Number(data?.data[0].memberCount)
    localStorage.setItem('BytesStorage',String(total+'|'+current+'|'+'user'+'|'+data?.data[0].memberCount) )
  }
   else{
    localStorage.setItem('BytesStorage',String(total+'|'+current+'|'+'workspace'+'|'+'1') )

   }
   this.isInfiniteDpage =  total === '1099511627776' || data?.data[0].subscriptionType === 'Paid' ? true : false;
   current=this.formatSizeUnits(Number(current))
   total=this.formatSizeUnits(Number(total))
    this.dpagesCountUsed= current
    this.dpagesCount = current+" of "+total
    this.isInfineTrial = (data?.data[0].duration === 0 || data?.data[0].duration >= 99999) ? false : true;
    this.subscriptionTypePaid = data?.data[0].subscriptionType === 'Paid' ? true : false;
    this.subscriptionPlan = data?.data[0].planDisplayName;
    this.totalDpages = total;
    localStorage.setItem('currentStorage' , current);
    localStorage.setItem('totalDpages' , this.totalDpages);
    localStorage.setItem('isInfiniteDpage', this.isInfiniteDpage.toString());
    this.wsSubscriptionRemainDaysCount = data?.data[0]?.subscriptionRemainingDaysCount;
    this.dpagesKB = data?.data[0].dpage?.split("|")[1];
    this.showDaysInsideBar = true;
    this.cd.detectChanges();
    localStorage.setItem('dpage', this.dpagesKB);
    let WSStatus = data?.data[0].wsStatus.toUpperCase();
    if (WSStatus == "DISABLED") {
      this.sharedService.workSpaceEnable.next(false);
      this.isWorkspaceEnabled = false;
    }
    else {
      this.sharedService.workSpaceEnable.next(true);
      this.isWorkspaceEnabled = true;
    }
  }

  myqueuedpages(data){
    this.dpagesCountUsed = JSON?.parse(this.dpagesCountUsed) - data;
    this.dpagesCount = JSON?.parse(this.dpagesCount) - data;
  }

  totaldpages(data){
    this.dpagesCountUsed = JSON?.parse(this.dpagesCountUsed) + data;
    this.dpagesCount = JSON?.parse(this.dpagesCount) + data;
  }

  trashdpages(data){
    this.dpagesCountUsed = JSON?.parse(this.dpagesCountUsed) - data;
    this.dpagesCount = JSON?.parse(this.dpagesCount) - data;
  }
  highestZindex: any;
  findHighestZIndex(elem) {
    var elems = document.getElementsByTagName(elem);
    var highest = Number.MIN_SAFE_INTEGER || -(Math.pow(2, 53) - 1);
    for (var i = 0; i < elems.length; i++) {
      var zindex = Number.parseInt(
        document.defaultView.getComputedStyle(elems[i], null).getPropertyValue("z-index"),
        10
      );
      if (zindex > highest) {
        highest = zindex;
      }
    }


    this.highestZindex = highest;
  }
  private dialogRef: NbDialogRef<any>;
  @ViewChild("DisableworkspaceModal") DisableworkspaceModal: TemplateRef<any>
  ownerControlRoute() {
    this.router.navigate(['/pages/workspace-admin/ws-management'], { queryParams: { 'enable': true } });
    this.sharedService.ownerEnable.next(true);
  }
  passwordErrorMessage = '';
  showPassword: any;
  passwordEmpty() {
    this.passwordErrorMessage = "";
  }
  hideModalPopup() {
    this.dialogRef.close();
    this.dialogRef = null;
  }
  public getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  //***********************************************************realtime prompt**************************************************/
  public toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  getguid: any;
  hdnEnableWorkspace: boolean = true;
  hdnDisableWorkspace: boolean = false;
  messageheading;
  messagebody;
  insertmessgae = '';
  //***********************************************************realtime-prompt**************************************************/
  ownerMsgInsert(checkdata) {
    if (checkdata == 'Workspace Deleted') {
      this.messageheading = "Workspace Deleted";
      this.messagebody =
        'Your Workspace has been deleted. Your ongoning sessions will be terminated Now.';
    }
    else if (checkdata == 'Workspace Disabled') {
      this.messageheading = "Workspace Disabled";
      this.messagebody =
        'Your Workspace has been Disabled by the Onwer. Your ongoning sessions will be terminated Now.';
    }
    else if (checkdata == 'Workspace Suspended') {
      this.messageheading = "Workspace Suspended";
      this.messagebody =
        'Your Workspace has been Suspended by the Owner. Your ongoning sessions will be terminated Now.';
    }
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: this.messageheading,
      message: this.messagebody,
      notificationTo: null,
      createdBy: this.createdBy,
    };
    this.insertmessgae = '';
    const formatedMsg = `${this.messagebody}|${this.messageheading}|${checkdata}`
    this.ownerService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.ownerdelivary(formatedMsg);
      } else {
        this.insertmessgae = '';
      }
    });
  }
  sendmessage;
  ownerdelivary(formatedMsg?: string) {
    let body = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: null,
      role: null,
      groupName: null,
      message: formatedMsg ? formatedMsg : this.messagebody,
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Modal",
      WsLink: ""
    }
    this.sendmessage = '';
    this.ownerService.notifysend(body).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }
    })
  }
  private rerendering() {
    this.bindUserList();
  }
  userList: any = [];
  private bindUserList() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;
    postData = {
      Wsoid: Number(this.workSpaceId),
      WsLink: arr[2],
    };
    this.ownerService.enabledUsersListService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.userList = response.data;
      } else {
        this.userList = [];
      }
    });
  }
getYear(){
  this.currentYear = new Date().getFullYear();

}
getBillingPage(){
  this.router.navigate(['/pages/settings/billing-subscriptions']);
}
  getSelectedItem() {
    localStorage.setItem('saved', 'false')
    this.menuService.getSelectedItem('menu')
      .pipe(takeUntil(this.destroy$))
      .subscribe((menuBag) => {

        if (menuBag?.item) {
          menuBag.item.selected = false;
          this.selectedItem = menuBag.item.title;
        }
      });
      this.cd.detectChanges();
  }
  trialDays ;
  subscriptionStatus ='';
  getBannerStatus(){
   let postData = {
      "WSLink": '',
      "Wsoid": Number(this.workSpaceId),
    }
    this.subdata.wsSubscriptionDetailsService(postData).subscribe((response:any)=>{
      if (response.data?.[0]?.timeFormat == "12 hour") {
        localStorage.setItem('TimeFormat', "hh:mm a")
      }
      if(response.data){
        localStorage.setItem('TotalDCoins',String(response.data?.[0]?.totalDcoins));
        this.totalDCoins = Number(localStorage.getItem('TotalDCoins'));
        this.cd.detectChanges();
      }
      else {
        localStorage.setItem('TimeFormat', "HH:mm")
      }
      this.wsCreatedDateCount = response?.data[0]?.subscriptionRemainingDaysCount;
      if(this.wsCreatedDateCount <= 15){
        this.trialDays = Number(this.wsCreatedDateCount)
        localStorage.setItem("trailDays",String(this.trialDays))

      }
      this.subscriptionStatus = response.data[0].subscriptionStatus.toUpperCase();


    })
  }
  workspaceStatus = '';
   GetInvoiceList(){

    let customer =localStorage.getItem("CustomerID")
    let request = {
      customerId:customer || 'AzyzgMT9OCiqZ2XFQ'
    };

    this.subdata.GetLastInvoiceDetail(request).toPromise()
setTimeout(() => {
  let postData = {
    "wsoid": Number(this.workSpaceId),
    "customerID": localStorage.getItem("param2Billing") || 'AzyzgMT9OCiqZ2XFQ',
    "subscription_id": ""
  }

  this.subdata.GetInvoiceList(postData).toPromise().then((response: any) => {
    this.subdata.WSSuspensationAndDeletion_Paid({}).subscribe()
   let data = response.data ? response.data[0] : null;
      if (response.data && data) {
        if(data.wsSatus == "Active"){
       this.workspaceStatus ='active'
        }
        else if(data.wsSatus === "Payment Grace Period"){
          this.workspaceStatus ='pgp'
        }
        else if(data.wsSatus === "Suspended Not Paid"){
          this.workspaceStatus ='notPaid'
        }
        else if(data.wsSatus === "To be Deleted"){
          this.workspaceStatus ='Deleted'
        }
        if(response.data.length == 0) {
          this.workspaceStatus ='Trial'
        }
      }
    });
}, 500);
   }

/**
* openModal function
*/
openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
  this.dialogRef = this.dialogService.open(dialogModal, {
    closeOnBackdropClick: false,
    closeOnEsc: false
  });
}

  /**
   * goto Billing for updation
   */
  gotobilling() {

    this.hrefData = this.router.url;
    var getDomainName = ".";
    var splitDot = new Array();
    var urlData = window.location.toString();
    var splitBackslash = urlData?.split("/")[2];
    if (splitBackslash == "localhost:4200") {
      this.workspaceDomain = ".localhost:4200";
    }
    else {
      splitDot = splitBackslash?.split(".");
      for (let i = 1; i < splitDot.length; i++) {

        if (i == 1)
          getDomainName = getDomainName + splitDot[i];
        if (i == 2)
          getDomainName = getDomainName + "." + splitDot[i];
      }
      this.workspaceDomain = getDomainName;
      this.wsSubDomain = urlData?.split("/")[2];
    }
    this.emailId = localStorage.getItem('localloginId');
    this.wsId = localStorage.getItem('localwsId');
    this.cookieService.set('emailId', this.emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('wsURL', this.wsSubDomain, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });

    const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + this.workspaceDomain + '/manage-billing/dashboard/'], {
      queryParams: {
        wsId: this.wsId,
        loginId: this.emailId,
        token:localStorage.getItem('token')
      }
    }))
    // this.router.navigate(['/onboarding/dashboard']);
    window.open(environment.httpUrl + url.substring(1), '_blank');
    if(localStorage.getItem('refreshKey') === 'true'){
      localStorage.removeItem('refreshKey')
     window.location.reload()
    }
  }


/**
* closeModal function
*/
close() {
  this.dialogRef.close();
}
}



