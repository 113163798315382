import { Injectable } from '@angular/core';
import { ApiServiceService } from '../@core/api-service.service';
import { ResetPassWordPayLoad } from '../onboarding/create-password/user-roles.model'
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';

/**
 *  Injectable for sign In  service 
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for sign in service
 */
export class SignInService {
  /**
 *  variable  for workspace id
 */
  workSpaceId: string;
  /**
 *  variable  for createdBy
 */
  createdBy: string;
/**
 *  git ignore onInit
 */
  constructor(private apiService:ApiServiceService, private http: HttpClient) { 
  }
 
  /**
 * method  for elastic base url
 */
  public elasticdbaseurl = environment.elasticdbaseurl;
 

 
/**
 *  method for signIn api
 */
  signIn(data) {
    localStorage.setItem("Request",JSON.stringify(data))
    return this.apiService.post('/api/SignIn/Validate', data);
  }

    /**
 * method  for ws icon updation
 */
    updateWSIcon(data) {
      return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFileStreamFromBlob', data, {responseType:'blob',observe: 'response'}).pipe(
        retry(0)
      );
    }
    
  /**
 *  method for silent signIn api
 */
  silentsignIn(data) {
    localStorage.setItem("Request",JSON.stringify(data))
    return this.apiService.post('/api/SignIn/SilentSingIn', data);
  }
/**
 *  method for signIn email api
 */
   signInemail(data) {
    return this.apiService.post('/api/SignIn/ValidateOnlyEmailID', data);
  }

  signOutService(data) {
    return this.apiService.post('/api/Workspace/SignOut', data);
  }
/**
 *  method for Get Owner Details  api
 */
  GetOwnerDetails(data) {
    return this.apiService.post('/api/WstOwner/Details', data);
  } 
  /**
 *  method for wsStatus  api
 */
  wsStatus(data) {
    return this.apiService.post('/api/Workspace/Status', data);
  }

/**
   * Updates workspace password
   * @param data 
   * @returns 
   */
  createPassword(data: ResetPassWordPayLoad){
    return this.apiService.post('/api/UserSettings/ChangePassword', data);
  }
     /**
 *  method for notify sign in
 */
   notifysignin(data) {
    return this.apiService.post('/api/NotificationKeyInsert/NotificationKeyInsert', data);
  }



  // ****************** notification Signup start here *******************
 /**
 *  method for role insert
 */
  roleinsert(data) {
    return this.apiService.post('/api/Notification/NotificationRoleInsert', data);
  }
  /**
 *  method for notify send
 */
  notifysend(data) {
    return this.apiService.post('/api/Notification/UserSendMessage', data);
  }
  /**
 *  method for notify insert
 */
  notifyinsert(data) {
    return this.apiService.post('/api/GetNotificationUser/NotificationInsert', data);
  }

  /**
 *  method for get data link
 */
  getdatalink(data) {
    return this.apiService.post('/api/GetDashboardCount/GetDetailsforWsLinks', data);
  }
  /**
 *  method for refresh token
 */
 
  refreshtoken(data) {
    return this.apiService.post('/api/SignIn/refresh', data);
  }
  /**
 *  method for Validate URL Rights
 */
    ValidateURLRights(data) {
      return this.apiService.post('/api/Workspace/ValidateURLRights', data);
    }

 /**
 *  method for fetch General SettingData
 */
  fetchGeneralSettingData(data) {
    return this.apiService.post('/api/GeneralSettings/Fetch', data);
  }
   /**
 *  method for findEmailVerify
 */
  findEmailVerify(data) {
    return this.apiService.post('/api/FindWorkSpace/Search', data)
  }
   // ****************** flag related api start here *******************
 /**
 *  method for fetch getflagevalue
 */
   getflagevalue() {
    return this.apiService.get('/api/GetFeatureFlaging/GetFeatureFlaging');
  }


   // ****************** parser flag value *******************
    /**
 *  method for fetch General SettingData
 */
   getparserflag(data) {
    return this.apiService.post('/api/Workspace/GetWSFeatureFlagging', data);
  }

    // ****************** view extract text api start here *******************
    viewextract(data) {
      return this.apiService.post('/api/ParserFileUpload/TextExtractionDocStatus', data);
    }
  zohoCreateProfile(data) {
    return this.apiService.Billingpost('/api/SignIn/createWsUser', data)
  }
  zohoCreateSubscription(data) {
    return this.apiService.Billingpost('/api/SignIn/createSubscription', data)
  }
  insertIntoSPDetails(data) {
    return this.apiService.Billingpost('/api/OrderSummary/insertAndUpdate', data)
  }
  insertCustomerId(data) {
    return this.apiService.Billingpost('/api/SignIn/sendCustomerID', data)
  }
  
  WSPLanAndUserDetails(data) {
    return this.apiService.post('/api/SignIn/CheckValidate', data);
  }
  
  getDecryptedValidationURLInfo(data) {
    return this.apiService.post('/api/Workspace/CheckIntegrationValidation', data);
  }
  
  getWSDetails(data) {
    return this.apiService.post('/api/Admin/WorkSpaceDetails', data);
  }

  getIPList(data) {
    return this.apiService.post('/api/Workspace/IPList', data);
  }
}
