// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: false,
  baseUrl: 'https://www.dox2udev.com:98',
  baseURL97: 'https://www.dox2udev.com:97',
  prepaid:"https://payments.dox2udev.com:8443",
  baseOrigin: "https://dox2udev.com",
  baseapp: "https://bugfix.dox2udev.com",
  httpUrl : "https://",
  domain : "dox2udev.com",
  logLevel: NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.DEBUG,
  enableSourceMaps: false,
  elasticdbaseurl : 'https://www.dox2udev.com:8800',
  paymentBaseUrl: 'https://www.dox2udev.com:8600',
  hostName: 'www.dox2udev.com',
  syncFusionHostURL: 'https://syncfusion_api.dox2udev.com/',
  port: '98'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
