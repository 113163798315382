
<button  nbButton [ngClass]="{'process' : params.data.pageCount === 0}"   class="bg-transparent border-0 deleteBtn  textextractionbtn" *ngIf="parserstatus === 'Failure'"
nbTooltip= "Not Extracted" nbTooltipPlacement="bottom" nbTooltipStatus="primary"  >
<nb-icon class="icon-fail" icon="file-remove"></nb-icon> 
</button>  

<button  nbButton [ngClass]="{'process' : params.data.pageCount === 0}"   class="bg-transparent border-0 deleteBtn  textextractionbtn" *ngIf="parserstatus === 'Not Extracted'"
nbTooltip= "Not Extracted" nbTooltipPlacement="bottom" nbTooltipStatus="primary"  >
<nb-icon class="icon-fail" icon="file-remove"></nb-icon> 
</button>  

<button nbButton [ngClass]="{'process' : params.data.pageCount === 0}"   class="bg-transparent border-0 pendingBtn  textextractionbtn" *ngIf="parserstatus === 'Success'"
nbTooltip= "Success" nbTooltipPlacement="bottom" nbTooltipStatus="primary" >
<nb-icon class="icon" icon="checkmark-circle-outline"></nb-icon>
</button>  

<button nbButton [ngClass]="{'process' : params.data.pageCount === 0}" class="bg-transparent border-0 verifyBtn textextractionbtn" *ngIf="parserstatus === 'Extracting'"
nbTooltip= "Extracting" nbTooltipPlacement="bottom" nbTooltipStatus="primary" >
<nb-icon class="icon-in textextractionbtn" icon="sync-outline"></nb-icon>
</button>

<button nbButton [ngClass]="{'process' : params.data.pageCount === 0}" class="bg-transparent border-0 verifyBtn textextractionbtn" *ngIf="parserstatus === 'In queue'"
nbTooltip= "In process" nbTooltipPlacement="bottom" nbTooltipStatus="primary" >
<nb-icon icon="loader-outline" class="loader-outline"></nb-icon>
</button>