import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class SharedAccessService {


  public showEdit:boolean;
  public showDownload:boolean;
  public showDelete:boolean;
  public showView: boolean;
  public showPrint: boolean;


    constructor( private apiService:ApiServiceService) {

  }

transferOwnershipService(data) {
  return this.apiService.post('/api/OwnedByMe/TransferDocOwner', data);
}
 /**
 *  method for notify send
 */
 notifysend(data) {
  return this.apiService.post('/api/Notification/UserSendMessage', data);
}
  roleinsert(data) {
    return this.apiService.post('/api/Notification/NotificationRoleInsert', data);
  }
  deleteAccessMultipleService(data) {
    return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
  }

  getFilterList(data) {
    return this.apiService.post('/api/Workspace/GetFilterList', data);
  }
  viewUserWiseRoleAccess(data) {
   return this.apiService.post('/api/GetUserRoleAccess/UserWiseRoleAccess', data)
  }
  documentFormatService(data) {
    return this.apiService.get('/api/RandomDocumentUpload/GetDocFormat ');
  }

  deleteMyDocService(data) {
     return this.apiService.post( '/api/DocumentVerification/DeleteDocVerifier', data)
  }
  UpdateDocumentVerifier(data) {
    return this.apiService.post('/api/DocumentVerification/UpdateDocVerifier', data)
  }
  UploadRightsUserListService(data) {
    return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data)
  }


  getaccessrightsService(data) {
    return this.apiService.post('/api/Workspace/MenuRights', data)
  }
  saveShareTemplateService(data) {
    return this.apiService.post('/api/DocumentVerification/SaveDocUsersAndGroup', data)
  }
  shereTemplateListService(data) {
    return this.apiService.post('/api/DocumentVerification/GetUsersAndGroupsList', data)
  }

  saveDocketUsersAndGroup(data){
    return this.apiService.post('/api/DocumentVerification/SaveDocketUsersAndgroup', data)
  }
  getDocketUserDetailWithGroup(data){
    return this.apiService.post('/api/GetUserDetailWithGroup/GetDocketUserDetailWithGroup',data)
  }
  getInvitedUsersLists(data)
  {
    return this.apiService.post('/api/AllUsers/Search',data)
  }
}
