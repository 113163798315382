import { Observable } from 'rxjs';
/**
      * export for statsBarData
 */
export abstract class TrafficChartData {
   /**
      * declaration for statsBarData
    */
  abstract getTrafficChartData(): Observable<number[]>;
}
