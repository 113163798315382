import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-format-tooltips',
  templateUrl: './format-tooltips.component.html',
  styleUrls: ['./format-tooltips.component.scss']
})
export class FormatTooltipsComponent implements OnInit {
  tooltipData: any;
  formatData: any;

  constructor(private router: Router) { }
  href; 
  ngOnInit(): void {
    this.href = this.router.url;
  }
  data: any;
  /*
    *Adding document format type in the my docs lists 
   */
   agInit(params:any){
    console.log("params",params)
     this.data=params?.data;
     this.tooltipData= this?.data?.formatExtension;
     console.log("toooltip data",this.tooltipData)
     if(this.data.pageCount >= 0 && this.data.iserror != 0 && this.data.iserror != 1){
      this.formatData =  this?.data?.documentFormat 
     }
     else if(this.data.pageCount === 0 && this.data.iserror === 0){
      this.formatData =  'This document is being processed.'
     }
     else if(this.data.iserror === 1){
      this.formatData =  'This document can’t be processed by dox2U.'
     }
   }


}
  