import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    
    const token = this.getToken();
    
    return tokenNotExpired(null, token);
  }

  constructor() { }
}
function tokenNotExpired(arg0: null, token: string): boolean {
  throw new Error('Function not implemented.');
}

