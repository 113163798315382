/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Random Upload Service
///  Description: Contains method for api used for uploading a document
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is deleting the /api/RandomDocumentUpload/ValidateUploadLimit as it no longer required
///
///------------------------------------------------------------------->
import { Injectable } from '@angular/core';
import { ApiServiceService } from '../@core/api-service.service';
/**
 *  Injectable for random upload service
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for random upload service  method
 */
export class RandomUploadService {
/**
 *  git ignore onInit
 */
  constructor(private apiService:ApiServiceService) { }
/**
 *  method for upload available storage
 */
  ValidateAvailableStorage(data){
    return this.apiService.post('/api/RandomDocumentUpload/ValidateAvailableStorage', data);
  }
/**
 *  method for remove Reserve File Space Storage Count
 */

  reserveFileSpaceStorageCount(data){
    return this.apiService.post('/api/RandomDocumentUpload/ReserveFileStorageCount', data);
  }
/**
 *  method for  Releasing Reserve Storage And Count
 */
  releasingReserveFileSpaceCount(data){
    return this.apiService.post('/api/RandomDocumentUpload/ReleaseStorageAndCountPerSession', data);
  }
  /**
 *  method for  random Upload
 */
  randomUploadAfter(data){
    return this.apiService.post('/api/RandomDocumentUpload/AfterUploadMethod1', data);
  }
 /**
 *  method for  Sending Upload Mailer and Owner And Verifier
 */
  sendDocEmail(data){
    return this.apiService.post('/api/RandomDocumentUpload/SendDocEmail', data);
  }
 /**
 *  method for  Random Document Languages
 */
  randomDocLanguageService(data){
    return this.apiService.post('/api/RandomDocumentUpload/Languages', data);
  }
 /**
 *  method for  checkbox
 */
    checkboxclick(data){
      return this.apiService.post('/api/Workspace/UpdateIsUplaodModule', data);
    }

 /**
 *  method for   Upload Rights User List
 */
  UploadRightsUserListService(data){
    return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }
 /**
 *  method for    Menu Rights List
 */
  menuRightsService(data){
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }
   /*
   *get feature flag value for the parser
   */

   featureflag(data){
    return this.apiService.post('/api/Common/WSBillingSubPlan', data);
  }

  docUpload(data){
    return this.apiService.post('/10.5.0.14:9009/docUpload/', data);
  }


}
