import { Observable } from 'rxjs';
/**
 *  ProgressInfo 
 */
export interface ProgressInfo {
   /**
      *  variable for title
   */
  title: string;
   /**
      *  variable for value
   */
  value: number;
   /**
      *  variable for activeProgress
   */
  activeProgress: number;
   /**
      *  variable for description
   */
  description: string;
}
  /**
      *  export for StatsProgressBarData
   */
export abstract class StatsProgressBarData {
     /**
 *  abstract method for getOrdersChartData
 */
  abstract getProgressInfoData(): Observable<ProgressInfo[]>;
}
