/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : pages modules
///  Description: It contains various sub components like dockets,menu,smart-cabinet and many more
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is of alignment of feature flags on basic of unqiueIDs
///
///------------------------------------------------------------------->
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { MENU_ITEMS } from "./pages-menu";
import { FeatureFlagsService } from '../@core/utils/feature-flag.service';
import { Router } from "@angular/router";
import * as mdl from "@aspnet/signalr";
import { SignInService } from "../@core/sign-in.service";
import { DashboardService } from "../@core/utils/dashboard.service";
import { environment } from '../../environments/environment';
import { WorkspaceAdminService } from '../@core/workspace-admin.service';
import { takeUntil } from 'rxjs/operators';
import { WorkspaceManagementService } from "../@core/utils/workspace-management.service";


import {
  NbToastrService,
  NbDialogService,
  NbDialogRef,
  NbMenuService,
} from "@nebular/theme";
import { SharedService } from "../@core/utils/shared.service";
import { ToastrService } from 'ngx-toastr';
import { CookieService } from "ngx-cookie-service";
import { MyQueueService } from '../@core/utils/my-queue.service';
import { Subject } from "rxjs";
declare global {
  interface Window {
    productFruitsUser: any;
    productFruits: any;
    pfDisableUrlChangeDetection: boolean
    Tawk_API: any
  }
}
@Component({
  selector: "ngx-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["pages.component.scss"],
})
export class PagesComponent implements OnInit, OnDestroy {
  @ViewChild("accountdisabled") accountdisabled: TemplateRef<any>;
  @ViewChild("purchaseDcoins") purchaseDcoins: TemplateRef<any>;
  @ViewChild("docSharedNotify") docSharedNotify: TemplateRef<any>;
  docSharedNotifyRef:any;

  private dialogRef: NbDialogRef<any>;
  public _hubConnecton?: mdl.HubConnection;
  public msg?: string;
  public menu = [];
  public result = [];
  public subscriptionTrialDaysCount: number;
  public wsSubscriptionRemainDaysCount: number;
  public menuRights: any = [];
  public roleName: string;
  isFirstTimeLogin: string;
  href;
  public showUploadDialog: boolean = false;
  public closeUploadDialog: boolean = false;
  removeitem: boolean = false;
  isManageUsersGroups: boolean;
  isAdminOwnerRights: boolean = false;
  isCreateModifyRoles: boolean;
  isWorkspaceManagement: boolean;
  public isDocumentUpload: boolean;
  private createdBy: any;
  private workSpaceId: any;
  private wsName: any;
  firstLevel: string;
  secondLevel: string;
  redirectToLocation: string;
  crumbVisible: void;
  flagvalueAllWs: string;
  wsCreatedDateCount: any;
  wsFlagvalue: string;
  dpageTotal: [];
  pages: any[];
  dpagesProgressCount: number;
  wsSubscriptionPlan: any;
  docGuid: any;
  abc: any;
  subMenu: any;
  parserflag: string;
  subscriptionfordpages: any;
  urlchecking: string;
  subscriptionType: string;
  roleId: any;
  wsManagement: any;
  isManageGroups: any;
  isCreatedRoles: any;
  AdminRightRole;
  subscription: any;
  selectedEvent: any;
  variable: boolean = true;
  variable1: boolean = true;
  refreshToInviteUser: boolean = false;
  saveurl: any;
  getallparseflag: any;
  isPlanChanged: boolean = false;
  otpVerification: boolean;
  userRights: boolean;
  docSharedRealTimeNotifyHeader:String;
  docSharedRealTimeNotifyBody:String;
  docSharedRealTimeNotifyFooter:String;

  constructor(
    private renderer: Renderer2,
    private generalSettings : WorkspaceManagementService,
    private data: DashboardService,
    private subdata: WorkspaceAdminService,
    private signInService: SignInService,
    private toastrService: NbToastrService,
    private cookieService: CookieService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dialogService: NbDialogService,
    private menuService: NbMenuService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private myqueueservice: MyQueueService,
    private featureFlag: FeatureFlagsService


  ) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.wsName = localStorage.getItem('WorkSpaceName')
  }
  ngOnDestroy(): void {

  }
  subscriptionupdate;
  subscriptionMenuRights;
  MenuTargetValue;
  ngOnInit(): void {
    let parserdata = {
      wsoid: Number(this.workSpaceId),
      createdBy: ""
    }
    this.signInService.getparserflag(parserdata).subscribe((response: any) => {
      this.getallparseflag = response.data;
      let url = window.location.href
      for (let i = 0; i <= this.getallparseflag.length - 1; i++) {
        if (this.getallparseflag[i].uniqueID == 'FID-0049') {
          localStorage.setItem('wsAnalytics', this.getallparseflag[i].isActive);
        }
        if (this.getallparseflag[i].uniqueID == 'FID-0051') {
          localStorage.setItem('dList', this.getallparseflag[i].isActive);
          localStorage.setItem('Response', this.getallparseflag[i].isActive);
        }
      }
      if (localStorage.getItem('wsAnalytics') != 'True') {

        if (url.includes("/pages/ws-analytics")) {
          this.router.navigate(['/error/error-page']);
        }

      }
      if ((localStorage.getItem('dList') && localStorage.getItem('dList') != 'True')
        && (localStorage.getItem('isDocket') && localStorage.getItem('isDocket') != 'true')) {

        if (url.includes("pages/documents/dockets")) {
          this.router.navigate(['/error/error-page']);
        }
        if (url.includes("dockets")) {
          this.router.navigate(['/error/error-page']);
        }

      }
    });
    localStorage.setItem('uploadTime', '0')
    this.MyDocListcount();
    this.getSelectedItem();
    this.menuService.onItemClick().subscribe((data) => {

      this.sharedService.OnLeftItemClick.next();
      if (data.item.link !== '/pages/dashboard') {

        const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
        if(s && s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0]){
          s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'none';
        }
      }
    });


    // this.featureFlag.loadConfig();


    this.subscriptionupdate = this.sharedService.uploaddata.subscribe((data) => {
      this.upload();
    });

    this.subscriptionMenuRights = this.sharedService.menuValueRights.subscribe((data) => {
      this.MenuTargetValue = data;
      if (this.MenuTargetValue) {
        this.data.setRights(this.MenuTargetValue);
        this.roleId = this.MenuTargetValue.roleId;
        localStorage.setItem('logincategory', this.MenuTargetValue.category);
        this.leftMenuConfig();
        this.sharedService.menuRights.next(this.MenuTargetValue);
        this.sharedService.menuRights2.next(this.MenuTargetValue);
        this.isDocumentUpload = this.MenuTargetValue.isDocumentUpload;
        this.rolename = this.MenuTargetValue.roleName;
        localStorage.setItem('roleName', this.MenuTargetValue.roleName);
        this.wsManagement = this.MenuTargetValue.isWorkspaceManagement;
        this.isManageGroups = this.MenuTargetValue.isManageUsersGroups;
        this.isCreatedRoles = this.MenuTargetValue.isCreateModifyRoles
        this.AdminRightRole = (this.wsManagement || this.isManageGroups || this.isCreatedRoles) ? true : false;
        localStorage.setItem('adminRightRole', this.AdminRightRole);
        localStorage.setItem('downloadandprint', this.MenuTargetValue.isDownloadPrintDocuments.toString());
        localStorage.setItem('editaccess', this.MenuTargetValue.isEditDocuments.toString());
        localStorage.setItem('deleteaccess', this.MenuTargetValue.isDeleteDocument.toString());
        localStorage.setItem('wsmanagementrights', this.MenuTargetValue.isWorkspaceManagement.toString());
        localStorage.setItem('isDocumentUpload', this.MenuTargetValue.isDocumentUpload.toString());
        localStorage.setItem('isDocket', this.MenuTargetValue.isDocket.toString());
        localStorage.setItem('isGuestWhatsappAccess',this.MenuTargetValue.isGuestWhatsAppAccess.toString());
        localStorage.setItem('isGuestAccess',this.MenuTargetValue.isGuestAccess.toString());

        this.roleId = this.MenuTargetValue.roleId

        this.menuRights = this.MenuTargetValue;
        this.roleName = this.menuRights.roleName;
        this.isManageUsersGroups = this.menuRights.isManageUsersGroups;
        this.isCreateModifyRoles = this.menuRights.isCreateModifyRoles;
        this.isWorkspaceManagement = this.menuRights.isWorkspaceManagement;
        this.urlchecking = this.router.url;
        if (!this.isWorkspaceManagement) {

          if (this.urlchecking == "/pages/workspace-admin/ws-management") {
            this.router.navigate(['/error/error-page']);
          }

        }
        if (localStorage.getItem('wsAnalytics') != 'True') {

          if (this.urlchecking == "/pages/ws-analytics") {
            this.router.navigate(['/error/error-page']);
          }

        }
        if (!this.isManageUsersGroups) {
          if (this.urlchecking == "/pages/settings/users" || this.urlchecking == "/pages/settings/users/invited"
            || this.urlchecking == "/pages/settings/users/deleted" || this.urlchecking == "/pages/settings/groups") {
            this.router.navigate(['/error/error-page']);
          }
        }

        if (!this.isCreateModifyRoles) {
          if (this.urlchecking == "/pages/settings/roles") {
            this.router.navigate(['/error/error-page']);
          }
        }

        if (localStorage.getItem('dList') != 'True' || localStorage.getItem('isDocket') != 'true') {
          let url = window.location.href
          if (url.includes("pages/documents/dockets")) {
            this.router.navigate(['/error/error-page']);
          }

        }
        this.isDocumentUpload = this.menuRights.isDocumentUpload;
        localStorage.setItem('wsmanagementrights', this.MenuTargetValue.isWorkspaceManagement.toString());
        this.MyDocListcount()
        this.sharedService.bindMenu.next();
        if (!this.isDocumentUpload) {
          this.uploadMenu();
        }
      }
      else {
        this.menuRights = [];
      }

    });
    this.subscription = this.sharedService.dashboardClick.subscribe((data) => {

      this.getSelectedItem();
    });

    this.subscriptionfordpages = this.sharedService.fordpages.subscribe((data) => {
      this.wsSubscriptionDetails();
    });
    this.sharedService.redalert.subscribe((response)=>{

      this.MyDocListcount()
    });
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2]
    }
    this.generalSettings.fetchGeneralSettingData(postData).subscribe((response: any) => {
      if (response.isSuccess == true) {
        this.otpVerification = response.data[0].isDisplayOTP;
        this.userRights = response.data[0].isDisplayDownloadPrint;
        localStorage.setItem('GeneralOTP',this.otpVerification.toString());
        localStorage.setItem('GeneralDownloadPrint',this.userRights.toString()); 
      }

    })
  }

  ngAfterViewInit() {
    // this.wsSubscriptionDetails();
    // this.connectiongenrate();
  }
  private destroy$ = new Subject<void>();
  selectedItem: string;
  getSelectedItem() {
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title) {
        this.selectedItem = event.item.title;
        this.selectedEvent = event.item;
        sessionStorage.setItem('lastActiveItem', JSON.stringify(event.item))
        this.cd.detectChanges();
      }
    });
    localStorage.setItem('saved', 'false')
    this.menuService.getSelectedItem('menu')
      .pipe(takeUntil(this.destroy$))
      .subscribe((menuBag) => {
        let value = menuBag;
        if (menuBag?.item) {
          menuBag.item.selected = false;
          this.selectedItem = menuBag.item.title;
        }
        if (this.selectedItem) {
          this.selectedEvent.selected = false;
          this.selectedItem = this.selectedEvent.title;
        }
        this.cd.detectChanges();
      });

  }

  navigateDashboard() {
    this.variable = false;
    this.router.navigate(["/pages/dashboard"]);
    this.sharedService.dashboardReload.next();
  }
  /**
   *
   * @returns left menu items feature flagging
   */
  menuConfig;
  leftMenuConfig() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData = {
      emailID: this.createdBy,
      wsoid: Number(this.workSpaceId),
      RoleId: Number(this.roleId),
      Owner: localStorage.getItem('logincategory')
    };
    return this.data.leftMenuListService(postData).subscribe((response: any) => {
      if (response.isSuccess == true) {
        this.menuConfig = response.data;
        let val = { 'mid': 8, 'mainMenu': 'Dockets', 'mainURL': '/pages/Dockets', 'mainIcon': 'shield-outline', 'subURL': "/pages/Dockets"
         , 'subIcon': "" ,  'subMenu': ""
      }
        this.menuConfig.splice(4, 0, val)
        if (localStorage.getItem('dList') != 'True' || localStorage.getItem('isDocket') != 'true') {
          this.menuConfig = this.menuConfig.filter(obj => {
            return obj.mainMenu != 'Dockets'
          })
        }
        this.wsSubscriptionDetails();
        this.connectiongenrate();
        this.sharedService.leftMenuLoader.next();
        if(localStorage.getItem('smartCabinet') == 'False'){
          this.menuConfig = this.menuConfig.filter((obj)=>{
             return obj.mainMenu != 'Smart Cabinet'
          })
        }


        MENU_ITEMS.forEach(element => {
          this.menuConfig.forEach(value => {

            const admin = MENU_ITEMS.find((obj) => obj?.title.toUpperCase() === value?.mainMenu?.toUpperCase());
            if (!this.isAlredyIncluded(value.mainMenu)) {
              this.menu.push(admin);
            }
          });
        });

        this.subMenu = this.menuConfig.filter(a => a.subMenu !== '');
        this.subMenu.forEach(element => {

          element.title = element.subMenu;
        });
        if (localStorage.getItem('wsAnalytics') == 'False' || !this.wsManagement) {
          this.subMenu = this.subMenu.filter((obj)=>{
            return obj.subMenu != 'WS Analytics'
          })
        }
        this.subMenu = this.subMenu.filter((obj)=>{
          return obj.mainMenu != 'Dockets'
        })
        const admin = this.menu.find((obj) => obj.title.toUpperCase() === "ADMIN");

        if (admin && admin.children) {
          admin.children = admin.children.filter(o1 => this.subMenu.some(o2 => o1.title.toUpperCase() === o2.title?.toUpperCase()));
          this.cd.detectChanges();
        }
        const Docs = this.menu.find((obj) => obj.title.toUpperCase() === "DOCS IN UPLOAD");
        if (Docs && Docs.children) {
          Docs.children = Docs.children.filter(o1 => this.subMenu.some(o2 => o1.title.toUpperCase() === o2.title?.toUpperCase()));
        }

      }
    });
  }
  /**
   *
   * @param data check for the alredy pushed items in araay nd remove duplicates
   * @returns
   */
  isAlredyIncluded(data) {
    let included = false
    this.menu.forEach(element => {
      if (element?.title?.toUpperCase() === data?.toUpperCase()) {
        included = true;
      }
    });
    return included
  }

  rolename;
  groupname;
  /**
   * connection generate code
   */
  async connectiongenrate() {
    try {
      this._hubConnecton = new mdl.HubConnectionBuilder().withUrl(environment.baseUrl + '/notificationhub').withAutomaticReconnect().configureLogging(mdl.LogLevel.Information).build();

      Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
      await this._hubConnecton.start();
      await this._hubConnecton.invoke("GetConnectionId");
      const connectionId = await this._hubConnecton.invoke("GetConnectionId");
      this._hubConnecton.onreconnected(connectionId => {
        const reconnectionid = connectionId;
        sessionStorage.setItem("KeyId", reconnectionid);
        this.workSpaceId = localStorage.getItem("localwsId");
        this.createdBy = localStorage.getItem("localloginId");
        this.rolename = localStorage.getItem("rolevalue");
        this.groupname = localStorage.getItem("groupnamevalue");
        if ((this.workSpaceId != '0') && (this.workSpaceId != null) && (this.workSpaceId != '')) {
          let notify = {
            wsoid: Number(this.workSpaceId),
            key: reconnectionid,
            userEmailID: this.createdBy,
            role: this.rolename,
            groupName: this.groupname,
            databaseName: "",
            createdBy: this.createdBy,
          };
          this.signInService.notifysignin(notify).subscribe((response: any) => {
            if (response.isSuccess) {
              if (response.statusCode == "C200") {
              }
            }
          });
        }
      });
      this._hubConnecton.on("tosterpvtnotification", (m) => {
        this.msg = m;
        console.log(m)
        this.showSuccess(this.msg);
        localStorage.setItem('newnotify', 'message')
      });
      this._hubConnecton.on("ParserApi", (m) => {
        this.parserflag = localStorage.getItem('parserflag');
        if (this.parserflag == 'True') {
          this.msg = m;
          let currentwindowurl = window.location.href;
          this.sharedService.detailsparserapi.next(this.msg);

          if (currentwindowurl.includes('documents/my-queue')) {

            this.sharedService.myqueueparse.next(this.msg);
          }
          if (currentwindowurl.includes('documents/workspace-queue')) {

            this.sharedService.wsqueueparse.next(this.msg);
          }
          if (currentwindowurl.includes('edit/edit-document') || currentwindowurl.includes('view/view-document') || currentwindowurl.includes('verification/verify-document')) {

            this.sharedService.onhover_parser.next(this.msg);
          }
        }


      });
      this._hubConnecton.on("ReParserApi", (m) => {
        this.parserflag = localStorage.getItem('parserflag');
        if (this.parserflag == 'True') {
          this.msg = m;
          let currentwindowurl = window.location.href;
          this.sharedService.detailscheck.next(this.msg);
          if (currentwindowurl.includes('documents/my-queue')) {
            this.sharedService.myqueueparse.next(this.msg);
          }
          if (currentwindowurl.includes('documents/workspace-queue')) {
            this.sharedService.wsqueueparse.next(this.msg);
          }
        }
      });
      this._hubConnecton.on("documentupdate", (m) => {
        this.msg = m;
        let currentwindowurl = window.location.href;
        if (currentwindowurl.includes('documents/my-queue')) {
          this.sharedService.myQueueEnableRow.next(this.msg);
        }
        if (currentwindowurl.includes('dockets')) {
          this.sharedService.myDlistEnableRow.next(this.msg);
        }

      });
      this._hubConnecton.on("modalpvtnotification", (m: string) => {
        console.log("message11",m)
        if(!(m.includes('documentDeletedRealTimeNotify') || m.includes('shareWithNoOne'))){
        if(m.includes('SendingDocSharedRealTimeNotification')){
          console.log("Real Time Notifications Document is shared with Guest", m);
          // this.sharedService.transferData.subscribe((res) => {
          //   console.log("Array testing", res);
          //   this.docSharedRealTimeNotifyBody=res[1]
          //   this.docSharedRealTimeNotifyFooter=""+new Date()+""
          // });
          this.docSharedRealTimeNotifyBody=m
          this.docSharedRealTimeNotifyBody=this.docSharedRealTimeNotifyBody.replace('SendingDocSharedRealTimeNotification','')
          this.docSharedRealTimeNotifyFooter=""+new Date()+""
          this.openRealTimeDocShareNotification();
        }
        if(m.includes('Insufficient dCoins to send OTP')){
          console.log("dcoins", m);
          this.openTemplate1();
        }
        
        if (m == 'Your WS Plan has been changed. Please refresh to access your updated WS.|WS plan changed') {
          this.refreshToInviteUser = true
          let url = window.location.href?.split('/')
          if (url[6] == undefined) {
            this.saveurl = '/' + url[3] + '/' + url[4] + '/' + url[5]
          } else {
            this.saveurl = '/' + url[3] + '/' + url[4] + '/' + url[5] + '/' + url[6]
          }
        }
        
        else {
          if (m.includes('Your plan has been modified') || m.includes('Role modified') || m.includes('Role changed') || m.includes('role deleted') || m.includes('Transfer Ownership')) {
            this.isPlanChanged = false;
          } else {
            this.isPlanChanged = true;
          }
        }
        localStorage.setItem('hubMessage', m?.toString());
        let directLogOutSignalR:string[]=[];
        let tempHubMessageArray =m?.split("|");
        for(let i =0;i<tempHubMessageArray.length;i++){
          if (
            tempHubMessageArray[i].toLowerCase() === "account disabled" ||
            tempHubMessageArray[i].toLowerCase() === "disabled" ||
            tempHubMessageArray[i].toLowerCase() === "account deleted" ||
            tempHubMessageArray[i].toLowerCase() === "deleted" ||
            tempHubMessageArray[i].toLowerCase() === "workspace deleted" ||
            tempHubMessageArray[i].toLowerCase() === "workspace disabled" ||
            tempHubMessageArray[i].toLowerCase() === "ws suspended"
          ) {
            directLogOutSignalR.push(tempHubMessageArray[i]);
          }
          else{
            this.result.push(tempHubMessageArray[i]);
          }
        }
        if (directLogOutSignalR.length>0){
          this.accoutClose(directLogOutSignalR[0]);
        }
        else{
          if(!m.includes('SendingDocSharedRealTimeNotification')) {
          this.openTemplate(this.accountdisabled);
        }
        }
      }
      });

      // this._hubConnecton.on("RolerealTimeDeletion", (m) => {
      //   this.result = m?.split("|");
      //   this.openTemplate(this.accountdisabled);
      // });
      this._hubConnecton.on('modalguestKeynotification', m => {
        this.result = m?.split('|');
        if (this.result) {
          this.sharedService.sharedWithGuestIcon.next(this.result);
        }

      });

      sessionStorage.setItem("KeyId", connectionId);
      this.workSpaceId = localStorage.getItem('localwsId')
      this.createdBy = localStorage.getItem('localloginId')
      this.rolename = localStorage.getItem('rolevalue')
      this.groupname = localStorage.getItem('groupnamevalue')
      let notify = {
        wsoid: Number(this.workSpaceId),
        key: connectionId,
        userEmailID: this.createdBy,
        role: this.rolename,
        groupName: this.groupname,
        databaseName: "",
        createdBy: this.createdBy,
      };
      this.signInService.notifysignin(notify).subscribe((response: any) => {
        if (response.isSuccess) {
          if (response.statusCode == "C200") {
          }
        }
      });
    } catch (err) {
      console.warn(err);
    }
  }

  rowDatatarget

  msgval(data) {
    this.showToastr(this.msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }

  showToastr(msg) {
    this.toastrService.primary(msg, '',);
  }
  public openTemplate(Accountdisabled: TemplateRef<any>) {

    const e = new Event("mouseenter");
    const element = document.querySelector("#accountdisabled");
    if (element && e) {
      element.dispatchEvent(e);
    }
    if(this.count === 0){
      this.count=1
      this.dialogRef = this.dialogService.open(this.accountdisabled, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      });
      setTimeout(() => {
        this.count=0
      }, 2000);
    }

  }

  public openTemplate1() {
    console.log("function working")
    const e = new Event("mouseenter");
    const element = document.querySelector("#purchaseDcoins");
    if (element && e) {
      element.dispatchEvent(e);
    }
    if(this.count === 0){
      this.count=1
      this.dialogRef = this.dialogService.open(this.purchaseDcoins, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      });
      setTimeout(() => {
        this.count=0
      }, 2000);
    }
  }

  public openRealTimeDocShareNotification() {
    this.docSharedNotifyRef = this.dialogService.open(this.docSharedNotify, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  public NavigateOwnedBy()
  {
    this.router.navigate(['/pages/documents/owned-by-me']);
  }

  closeRealNotify(){
    this.docSharedNotifyRef.close()
  }

  public openModal(dialogModal: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialogModal, { closeOnBackdropClick: false });
  }
 
  count = 0
  upload() {
    this.count = Number(localStorage.getItem('uploadTime'))
    if (this.count == 0) {
      this.showUploadDialog = true;
      this.sharedService.uploadOpen.next(true);
      this.count++
      localStorage.setItem('uploadTime', '1')
    }


  }
  public closediagoue() {
    this.showUploadDialog = false;
  }

  aldaysLeft: any;
  dPagescountLimit: any;
  dPagescountTotal: any;
  dpageCount
  public wsSubscriptionDetails() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }
    this.subdata.wsSubscriptionDetailsService(postData).subscribe((response: any) => {
      if(response.data[0].wsStatus.toUpperCase() == "DISABLED"){
        localStorage.setItem('workspace','disable')
      }
      if(response.data?.[0]?.totalDcoins){
        localStorage.setItem('TotalDCoins',String(response.data?.[0]?.totalDcoins));
      }
      this.wsSubscriptionRemainDaysCount = response?.data?.[0]?.subscriptionRemainingDaysCount;
      localStorage.setItem('subscriptionRemainingDaysCount', this.wsSubscriptionRemainDaysCount.toString())
      localStorage.setItem('subscriptionSpId', response?.data?.[0]?.spId)
      localStorage.setItem('currency', response.data[0].currency);
      this.wsSubscriptionPlan = response.data?.[0].subscriptionPlan;
      this.subscriptionType = response.data?.[0].subscriptionType;
      this.sharedService.subscriptionPlan.next(this.wsSubscriptionPlan);
      localStorage.setItem('wsSubscriptionPlan', this.wsSubscriptionPlan);
      localStorage.setItem('planDisplayName',response.data?.[0]?.planDisplayName);
      localStorage.setItem('subscriptionPlan', this.subscriptionType);
      this.sharedService.oneColumnSubscriptionDetails.next(response);
      this.wsCreatedDateCount = response?.data?.[0]?.subscriptionTrialDaysCount;
      this.cd.detectChanges();
      this.dpageCount = response?.data?.[0]?.dpage;
      localStorage.setItem('dpage', response?.data?.[0].dpage?.split("|")[1])

      let dpagesArr = this.dpageCount?.split(" ");
      this.dPagescountTotal = dpagesArr?.[0];
      this.dPagescountLimit = dpagesArr?.[2]?.split("|")[0];
      if(response?.data?.[0].dpage?.split(" ")[2].split('|')[2] === '1'){
        this.dPagescountLimit =  this.dPagescountLimit * Number(response?.data?.[0]?.memberCount)
       }
      this.dpagesProgressCount = (this.dPagescountTotal * 100) / this.dPagescountLimit;
      this.cd.detectChanges();
    }
    )
  }
  public accoutClose(str?, newUrl?) {
    this.dialogRef?.close();
    if(Array.isArray(str)){
      str = str[1];
    }
    str = str?.toString()?.toLowerCase();
        let docketRole: any;
    var st = window.location.toString();
    var arr = st?.split("/");
    let logoinIdCookie = 'localloginId' + localStorage.getItem('localwsId');
    let wsIdCookie = 'localwsId' + localStorage.getItem('localwsId');
    if(str == "ip change"){
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/onboarding/sign-in"]);
    }
    let postData1: any;
    postData1 = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }
    this.subdata.wsSubscriptionDetailsService(postData1).subscribe((response: any) => {
      if(response.data[0].wsStatus.toUpperCase() == "DISABLED"){
        localStorage.setItem('workspace','disable')
      }
      if(response.data?.[0]?.totalDcoins){
        localStorage.setItem('TotalDCoins',String(response.data?.[0]?.totalDcoins));
      }
    })
  let  postData = {
      Wsoid: parseInt(localStorage.getItem('localwsId')),
      WsLink: arr[2],
      EmailId: localStorage.getItem('localloginId'),
    };
    this.data.menuRightsService(postData).subscribe((response:any)=>{
       docketRole = response.data.isDocket
    })
      this.dialogRef?.close();
    if (newUrl) {
      window.location.href = newUrl;
    }
    else
    if (str === "transfer ownership" && localStorage.getItem("workspace") === "disable") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      this.dialogRef.close();
      sessionStorage.clear();
      this.router.navigate(["/onboarding/disabled"]);
    }
    else if (this.refreshToInviteUser == true) {
      this.router.navigate([this.saveurl]);
      this.dialogRef.close();
      window.location.href = this.saveurl;
          }
    else if (str == 'ws plan changed') {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/onboarding/sign-in"]);
    }
else if (str == "account disabled" || str == "disabled") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("signalRMsg",'userDisabledSR');
      this.router.navigate(["/onboarding/account-disable"]);
    }
    else if (str == 'account deleted' || str == "deleted") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("signalRMsg",'userDeletedSR');
      this.router.navigate(["/onboarding/account-deleted"]);
    }
    else if (str == "resetpassword" || str == "reset your password") {
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.router.navigate(["/onboarding/change-password"]);
    }
    else if (str == "workspace deleted") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/onboarding/deleted"]);
    }
    else if (str == "workspace disabled") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("signalRMsg",'workSpaceDisabledSR');
      this.router.navigate(["/onboarding/disabled"]);
    }
    else if (str === "ws suspended") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      let loginUser = localStorage.getItem('localloginId')
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem('localloginId',loginUser);
      localStorage.setItem("signalRMsg",'workSpaceSuspendedSR');
      this.router.navigate(["/onboarding/suspended"]);
    }
    else if (
      str == "modified" ||
      str == "role deleted" ||
      str == "transfer ownership" ||
      str == "role changed"
    ) {
      setTimeout(() => {
        if (str == "transfer ownership" && localStorage.getItem("workspace") == "disable") {
          this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          localStorage.clear();
          this.dialogRef.close();
          sessionStorage.clear();
          this.router.navigate(["/onboarding/disabled"]);
        }
        if(window.location.href.includes('/documents/dockets') &&  docketRole ){
          window.location.href = '/pages/documents/dockets';
        }
        else{
          window.location.href = '/pages/dashboard';

        }
      }, 500);

      this.dialogRef.close();
    }
    else {
      window.location.href = '/pages/dashboard';
      this.dialogRef?.close();
    }

  }
  flagvalue;
  flagvaluecabinet;

  MyDocListcount() {

    let postData = {
      "DocumentFormat": "",
      "DocumentOwner": "",
      "EndDate": "",
      "OriginalFileName": "",
      "StartDate": "",
      "UploadedBy": "",
      "Pagecount":1,
      "NumberofRow":50,
      "Verifier": this.createdBy,
      "Wsoid": Number(this.workSpaceId),

    }
    this.myqueueservice.myQueueListService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.rowDatatarget = (response.data);
        if(response?.data[0]?.totalcount){
          localStorage.setItem('redalert', response?.data[0]?.totalcount)
        }else{
          const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
          if (docinupload && docinupload.children) {
            docinupload.badge.dotMode = false;
            docinupload.badge.status = '';
            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            docinuploadchild.badge.text = '';
            docinuploadchild.badge.status = '';

          }      }

        let redalertval = JSON.parse(localStorage?.getItem('redalert'));
        if(!redalertval){
          redalertval=0
        }
        if(redalertval != 0){
          let docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");

          if (docinupload && docinupload.children) {
            docinupload.badge.dotMode = true;
            docinupload.badge.status = 'danger';

            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            if (redalertval > 99) {
              docinuploadchild.badge.text = "99+";
              docinuploadchild.badge.status = 'danger';
            }
            else {
              docinuploadchild.badge.text = redalertval;
              docinuploadchild.badge.status = 'danger';
            }
        }


        }
           else{
          const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
          if (docinupload && docinupload.children) {
            docinupload.badge.dotMode = false;
            docinupload.badge.status = '';
            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            docinuploadchild.badge.text = '';
            docinuploadchild.badge.status = '';

          }
      }
    }
    })
  }  private uploadMenu() {
    this.menu = this.menu.filter((obj) => obj.title !== "Upload");
  }
}
