<!--/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Menu Component
///  Description: Menu component in all users
///  Owner      : Sumeet Goel
///  Author     : Trisha pathak
///  Created On : 27-07-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->



    

      <button [hidden]="params.data.iserror === 1" (click)="triggerMenu()" outline nbButton [nbContextMenu]="items" nbContextMenuTrigger="noop" id="threedoticon" nbContextMenuTag="quick-action-menu" class="moreIcons" #threeDot
      [ngClass]="{ 'disabled-button': docSelectedCount }">
        <nb-icon icon="more-vertical-outline" ></nb-icon>
        </button>

        <button *ngIf="params.data.iserror === 1" outline nbButton class="moreIcons" (click)="triggerMenu()" nbContextMenuTrigger="noop" #threeDot
        [ngClass]="{ 'disabled-button': docSelectedCount }">
          <nb-icon icon="trash-2-outline" ></nb-icon>
          </button>
        
        
       
  
  