import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../@core/utils/shared.service';
import { ApiServiceService } from '../../@core/api-service.service';
import { MiniProfileService } from '../../@core/utils/mini-profile.service'
import { VerifyDocumentService } from '../../@core/verify-document.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import{NbDialogService, NbPopoverDirective} from '@nebular/theme';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'ngx-mini-profile',
  templateUrl: './mini-profile.component.html',
  styleUrls: ['./mini-profile.component.scss']
})
export class MiniProfileComponent implements OnInit {
  @ViewChild('upgradeModalDcoins') upgradeModalDcoins!:TemplateRef<any>
  /**
   * variable for  pendingRequest
   */
  pendingRequest: any;
    /**
   * variable for  workSpaceId
   */
  workSpaceId: string;
    /**
   * variable for  sanitizedUrl
   */
  sanitizedUrl: any;
   /**
   * variable for  showSpinner
   */
  showSpinner: boolean;
   /**
   * variable for  domainUrl
   */
  domainUrl: string;
    /**
   * variable for  dialogRef
   */
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  dialogRef: any;
   /**
   * variable for  changeText
   */
  changeText: boolean;
  /**
   * variable for  dateFormat
   */
  dateFormat: string;
  /**
   * variable for  dateValue
   */
  dateValue: any;
  /**
   * variable for  timeVal
   */
  timeVal: string;
  /**
   * variable for  currentDate
   */
  currentDate: any;
  /**
   * variable for  localtime1
   */
  localtime1: any;
  totalDCoins: number = 0;
  dcoinsOn: boolean = false;
  
  constructor(public cookieService: CookieService, public sanitizer: DomSanitizer,  private dialogService: NbDialogService,private cd: ChangeDetectorRef,private thumbnailService: VerifyDocumentService,private router: Router,private sharedService : SharedService,private apiService:ApiServiceService,private miniservice: MiniProfileService) { 
    this.workSpaceId = localStorage.getItem('localwsId');

  }

  IsModalopen = true;
  DisplayName:any;
  EmailId:any;
  chkcontactno;
  contactno;
  rolename;
  groupname;
  whatIDo;
  whereiam;
  localtime;
  urlshow: any = '../../../assets/images/Default_Profile_Photo.svg';
  name;
  takedata;
  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  @Input() showdata;

  ngOnInit(): void {
    this.getminiprofile(); 
    this.totalDCoins = Number(localStorage.getItem('TotalDCoins'));
    this.dcoinsOn = localStorage.getItem('dcoins').toString().toLowerCase() == 'true'? true: false;
    this.changeText = false;
    
    this.sharedService.transferData.subscribe((res) => {
      if(res=='refreshDcoinsData')
      {
       this.ngOnInit()
      }
    });

  }

  minicross(){
    this.sharedService.minicrossval.next();
  }

  splitname;
  minigetdata;
  lastcheckno;
  checkout;
  checkoutfirst;
  checkoutsecond;
  checkoutthird;
  checkoutfourth;
  userstatusval;
  status;
  groupnamearray = [];
  getminiprofile(){
    this.showSpinner = true;

     this.takedata = this.showdata;
      let minivalue = {
        "wsoid": this.takedata.wsoid,
        "emailId": this.takedata.emailId,
        "guid" : localStorage.getItem('loginguid')   
      }
      this.groupnamearray = [];
    
      this.miniservice.getminiprofile(minivalue).subscribe((response: any)=> {
        if (response.isSuccess) {
          // console.log(response);
          this.showSpinner = false;

            this.minigetdata = response.data[0];
           
            this.name = this.minigetdata.displayName
            this.DisplayName = this.minigetdata.fullName;   
            this.rolename = this.minigetdata.roleName;
            
            this.whatIDo = this.minigetdata.whatIdo;
            this.whereiam = this.minigetdata.whereIam; 
            this.localtime = this.minigetdata.localTime?.split(' ')[0];
            let regExp = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if(regExp.some(a => this.minigetdata.localTime?.split(' ')[0]?.replace('-', ' ').includes(a))){
             let correctValue =  this.localtime?.split('-');
             this.localtime = correctValue[0] + ' ' + correctValue[1] + ',' + ' ' + correctValue[2];
              // console.log(this.localtime);
            }
            if(this.minigetdata.localTime?.split(' ')[2] == undefined){
              this.localtime1 = this.minigetdata.localTime?.split(' ')[1]
            }else{

            this.localtime1 = this.minigetdata.localTime?.split(' ')[1] + '' + this.minigetdata.localTime?.split(' ')[2];
            }
           
            this.userstatusval = this.minigetdata.status

            for(let i=0;i<=response.data.length-1;i++){
              this.groupnamearray.push(response.data[i].groupName);
            }

            if(this.groupnamearray){
              this.groupname = this.groupnamearray.join(",   ");
             
            }
      

            if((this.userstatusval == 'Disabled') || (this.userstatusval == 'Deleted')){
               this.status = this.userstatusval;
            }
            else{
              this.status = '';
            }
          
            if(this.minigetdata.profileImagePath){
              this.getUserImage();
             
            }
            else{
              this.urlshow = '../../../assets/images/Default_Profile_Photo.svg';
            }
        
            if(this.minigetdata.isDisplayEmailId){
              this.EmailId = this.minigetdata.emailId;
            }
            else{
              this.EmailId = "";
            }
        
            if(this.minigetdata.isDisplayContactNo){
              this.checkout = this.minigetdata.contactNo;
              this.checkoutfirst = this.checkout.slice(0,1);
              this.checkoutsecond = this.checkout.slice(1,2);
              this.checkoutthird = this.checkout.slice(2,3);
              this.checkoutfourth = this.checkout.slice(-1);
              if(this.checkoutfirst == '+' && this.checkoutsecond == '+' && this.checkoutthird == '(' && this.checkoutfourth == ')'){
                this.chkcontactno = this.minigetdata.contactNo?.split("++(");
                this.lastcheckno = this.chkcontactno[1]?.split(")");
                if(this.lastcheckno[0]){
                  this.contactno = this.lastcheckno[0];
                }
                else{
                  this.contactno = '';
                }
              }
              else{
                this.contactno = this.minigetdata.contactNo;
              }
              if(this.status){
                document.getElementById("adaptiveheight").style.maxHeight = "19.438rem";
              }
              else{
                document.getElementById("adaptiveheight").style.maxHeight = "20.5rem";
              }
            }
            else{
              this.contactno = '';
            }
        }
      })
  }

  getUserImage() {
    let postData: any
    postData = {
      "wsoid": Number(this.workSpaceId),
      "fileName": this.minigetdata.profileImagePath,
      "folderName": "profileimage"
    }
    this.miniservice.pdfViewerFileConversion(postData).subscribe((response: any) => {
      if (response.body instanceof Blob && response.body.size > 0) {
        const blob = new Blob([response.body], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        this.urlshow = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      }
      // else {
      
      //   this.urlshow = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
      //   this.cd.detectChanges();
      // }
    })
  }
  getFormattedDate(date) {
    var year = date.getFullYear().toString();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    let monthchange = moment(month).format("MMM");
    return monthchange + ' ' + day + ',' + year;
  }
  pdfFileConversion(fileName){
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "fileName": fileName,
      "folderName": "thumbnail"
    }
    this.pendingRequest = this.thumbnailService.pdfViewerFileConversion(postData).subscribe((response: any) => {
      this.createImageFromBlob(response);
      this.cd.detectChanges();
    })
  }
imageToShow: any;
createImageFromBlob(image: Blob) {
   let reader = new FileReader();
   reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.imageToShow);
      this.cd.detectChanges();
    
   }, false);
   if (image) {
      reader.readAsDataURL(image);
   }
}

getDate(date){
  let newly = date;
  let DateValue = newly.toLocaleDateString();
  let time = newly.toLocaleTimeString()?.split(':');
  let dsf = time[2]?.split('')[3] + time[2]?.split('')[4];
  let timeVal = time[0] + ':' + time[1] + ' ' + dsf;
  return DateValue;
}
  copySignUp(val){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =this.takedata.emailId;
    document.body?.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
    this.popover?.hide();
  }, 2000);
  }
 
  onEditClick(){
   this.dialogRef.close();
  }
 
  gotobilling() {

    let hrefData = this.router.url;
    var getDomainName = ".";
    var splitDot = new Array();
    var urlData = window.location.toString();
    var splitBackslash = urlData?.split("/")[2];
    let workspaceDomain = '';
    let wsSubDomain = '';
    if (splitBackslash == "localhost:4200") {
       workspaceDomain = ".localhost:4200";
    }
    else {
      splitDot = splitBackslash?.split(".");
      for (let i = 1; i < splitDot.length; i++) {
  
        if (i == 1)
          getDomainName = getDomainName + splitDot[i];
        if (i == 2)
          getDomainName = getDomainName + "." + splitDot[i];
      }
      workspaceDomain = getDomainName;
      wsSubDomain = urlData?.split("/")[2];
    }
    let emailId = localStorage.getItem('localloginId');
    let wsId = localStorage.getItem('localwsId');
    this.cookieService.set('emailId', emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('wsURL', wsSubDomain, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
  
    const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + workspaceDomain + '/manage-billing/dashboard/'], {
      queryParams: {
        wsId: wsId,
        loginId: emailId,
        token:localStorage.getItem('token')
      }
    }))
    // this.router.navigate(['/onboarding/dashboard']);
    window.open(environment.httpUrl + url.substring(1), '_blank');
    if(localStorage.getItem('refreshKey') === 'true'){
      localStorage.removeItem('refreshKey')
     window.location.reload()
    }
  }
  /**
   * openModal function
   */
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });

  }
  close(){
    this.dialogRef.close();
  }
}
