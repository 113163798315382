import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class DetailAndActivityService {

  constructor(private apiService:ApiServiceService) { }

  
   getdetailsdata(data) {
    return this.apiService.post('/api/OwnedByMe/DocDetailsById', data);
  }

  getListofSharedUsersOfDoc(data){
    return this.apiService.post('/api/WhatsAppGuestAccess/GetGuestList',data)
  }

 
   getActivitydata(data) {
    return this.apiService.post('/api/OwnedByMe/DocActivityListByDocId', data);
  }


}
