import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public onlineStatus: BehaviorSubject<boolean>;
  constructor() {
    this.onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);
    window.addEventListener('online', () => this.onlineStatus.next(true));
    window.addEventListener('offline', () => this.onlineStatus.next(false));
  }
  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }
}