/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Download Component
///  Description: inside shared module
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Removal of download searchable pdf option from modal dialog box
///
///------------------------------------------------------------------->
import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output, Optional,} from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService,NbWindowService,NbWindowRef,NbWindowControlButtonsConfig,NbWindowState} from '@nebular/theme';
import { SharedServiceService } from '../../shared-service.service';
import { DownloadService } from '../../@core/download.service';
import { CookieService } from 'ngx-cookie-service';
import { SignInService } from '../../@core/sign-in.service';
import { HttpEventType } from '@angular/common/http';
import { VerifyDocumentService } from '../../@core/verify-document.service';
@Component({
  selector: 'ngx-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  selectedRenamedFilesName = [];
  pdfByteArrays = [];
  pdfFile: string;
  showdata: any;
  checkOriginal = true;
  checkPdf = false;
  workSpaceId: string;
  wsName: string;
  createdBy: string;
  subscription: Subscription;
  subscription12: Subscription;
  subscriptions: Subscription;
  selectedFileFormat = 'original';
  validateDownloadResponse: any;
  multiSelectionResp: any;
  singleSelectResp: any;
  selectedDocId=[];
  selectedDocOriginalName=[];
  @ViewChild('dailyLimitEcxeeded') dailyLimitEcxeeded: TemplateRef<any>;
  @ViewChild('downloadOptionPDForORG') downloadOptionPDForORG: TemplateRef<any>;
  @ViewChild('selectedLimitEcxeeded') selectedLimitEcxeeded: TemplateRef<any>;
  @ViewChild('maxSelectionExceed') maxSelectionExceed: TemplateRef<any>;
  @ViewChild('multipleSelectionConf') multipleSelectionConf: TemplateRef<any>;
  flagValue:false;
  href: any;
  selectedDocumentOwner: any;
  documentOwnerName: any;
  documentOwnerEmail: any;
  renamedFileName=[];
  getguid;
  insertmessgae: string;
  sendmessage: any;
  documentDownloadEmail: any;
  downloadModifiedNameFileName;
  nonOwnerDocuments: any;
  uniqueEmailId: unknown[];
  filesCount: any;
  documentOwnerEmailMulti: any;
  extension: any;
  parserflagdata;
  userfullname;
  downloadExtractedTextPdf;
  downloadCount: any;
  constructor(
    // private deviceInformationService: AngularDeviceInformationService,
    private cookieService: CookieService,@Optional() private dialogRef: NbDialogRef<any>, @Optional() private windowRef: NbWindowRef,private windowService: NbWindowService, private toastrService: NbToastrService, private signInService: SignInService,
  private dialogService: NbDialogService,private verifyDocumentService: VerifyDocumentService,
    private router: Router,private sharedservice: SharedServiceService, private data: DownloadService) {
    this.createdBy = localStorage.getItem('localloginId')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.getguid = localStorage.getItem('loginguid')
    this.parserflagdata = localStorage.getItem('parserflag');
    this.downloadExtractedTextPdf = localStorage.getItem('downloadExtractedText');

  }
  @Input() selectedDocs;

  _docLis: string = '';
  @Input()
  set doclist(doclist: string) {
    this._docLis = (doclist && doclist?.trim()) || '';
  }
  get doclist() { return this._docLis; }

  _numberofDocsSelected:number;
  @Input()
  set numberofDocsSelected(numberofDocsSelected: any) {
    this._numberofDocsSelected = (numberofDocsSelected) || '';
  }
  get numberofDocsSelected() { return this._numberofDocsSelected; }



  @Input() selectedFileNames;
  @Input() rightUserList;
  @Output() ownedByEvent = new EventEmitter<string>();

  downloadprint;
  editrights;

  ngOnInit(): void {
    this.href = this.router.url;

    this.subscription = this.sharedservice.downlaodButtonThreeDotOwnedBy.subscribe((data) => {

      this.validateDownloadDailyLimit();
    });
    this.subscriptions = this.sharedservice.downlaodButtonThreeDotSharedBy.subscribe((data) => {

      this.validateDownloadDailyLimit();
    });

    this.downloadprint = localStorage.getItem('downloadandprint') ? localStorage.getItem('downloadandprint') : '';
    this.editrights = localStorage.getItem('editaccess')  ? localStorage.getItem('editaccess') : '';

  }
  state;
  maximize() {
    this.state = NbWindowState.MAXIMIZED;
  }

  ngOnDestroy() {
    if(this.subscriptions || this.subscription ) {
      this.subscription.unsubscribe();

      this.subscriptions.unsubscribe();
    }

  }

  extensiondownload;
  firstrender = false;
  secondrender = false;
  extensionformatcheck;
  statuscode;
  fourthrender = false;
  downloaddocsize;
  notShowExtrated = '';
  hambugerdownload=''
  percentageDone: any;

  validateDownloadDailyLimit() {
    let postData: any;

    postData = {
      "wsoid": Number(this.workSpaceId),
      "downloadDocCount": this.selectedDocs.length,
      "createdBy": this.createdBy
    }
    this.extensiondownload = '';
    this.extensionformatcheck = '';
    this.statuscode = '';
    this.notShowExtrated = '';

    this.data.verifyDailyDaownlaodLimit(postData).subscribe((response: any) => {
      if (response.data != null || response.data != undefined) {
        this.validateDownloadResponse = response.data;
        if (response.isSuccess === true) {
          this.downloadCount = response.data.downloadDocCount;
          if(this.selectedDocs[0].size){

             if(this.href.includes('/pages/search')){
              this.downloaddocsize = this.selectedDocs[0].size + ' ' + this.selectedDocs[0].sizeunit;
            }
            else if((this.href.includes('/pages/documents/trash')) || (this.href.includes('/pages/documents/shared-with-me')) || (this.href.includes('/pages/cabinet'))){
              this.downloaddocsize = this.selectedDocs[0].size + ' ' + this.selectedDocs[0].sizeUnit;
            }
            else if((this.href.includes('/pages/dashboard'))){
              this.downloaddocsize = this.selectedDocs[0].size?.split('|')[1];
            }
            else{
              this.downloaddocsize = this.selectedDocs[0].size;
            }
          }
          if (response.data.downloadDocCount > response.data.availableDownloadLimit) {

            this.openModal(this.maxSelectionExceed, false);
          }
          else if (response.data.downloadDocCount === 1 && (this.selectedDocs[0].documentFormat.toUpperCase() === 'PDF')) {

            if(this.parserflagdata == 'True'){
              this.extensiondownload = this.selectedDocs[0].documentFormat.toLowerCase();
              this.statuscode = this.selectedDocs[0].parserstatuscode ;
                 /*
              *In the edittable document format always show language detected
              *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
              *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
              */
            this.secondrender = false;
            this.fourthrender = true;
            if(this.extensiondownload.toLowerCase() == 'pdf'){
              if(this.downloadExtractedTextPdf =='True'){
                if(this.selectedDocs[0].parserstatuscode !== 'doc_08'){
                  this.downlaodDocument(response.data.downloadDocCount, 'original', this.selectedDocs);
                  this.toastrService.primary("Document(s) being prepared & will be downloaded shortly");
                }
                else{
                  this.openModal(this.downloadOptionPDForORG, false);
                }
              }
              else{
                this.downlaodDocument(response.data.downloadDocCount, 'original', this.selectedDocs);
                this.toastrService.primary("Document(s) being prepared & will be downloaded shortly");
              }
            }
            else{

              this.openModal(this.downloadOptionPDForORG, false);
            }

            this.singleSelectResp = response;

            }
            else{
              this.downlaodDocument(response.data.downloadDocCount, 'original', this.selectedDocs);
              this.toastrService.primary("Document(s) being prepared & will be downloaded shortly");
            }

          }
          else if (response.data.downloadDocCount === 1 && this.selectedDocs[0].documentFormat?.toUpperCase() !== 'PDF') {
            if(this.selectedDocs[0].extension){
              this.extensiondownload = this.selectedDocs[0].extension.toLowerCase();
            }
            else if(this.selectedDocs[0].formatExtension){
              this.extensiondownload = this.selectedDocs[0].formatExtension.toLowerCase();
            }
            else if(this.selectedDocs[0].documentFormat){
              this.extensiondownload = this.selectedDocs[0].documentFormat.toLowerCase();
            }
            else if(this.selectedDocs[0].format){
              this.extensiondownload = this.selectedDocs[0].format.toLowerCase();
            }

            if((this.extensiondownload.toLowerCase() == 'txt') || (this.extensiondownload.toLowerCase() == 'doc')
            || (this.extensiondownload.toLowerCase() == 'docx') || (this.extensiondownload.toLowerCase() == 'rtf')
            || (this.extensiondownload.toLowerCase() == 'odt'))
            {
              this.notShowExtrated = 'Not_Show';
            }
            else{
            this.notShowExtrated = '';
           }
          //  if(this.parserflagdata == 'True'){
              this.extensionformatcheck = this.extensiondownload;
              this.statuscode = this.selectedDocs[0].parserstatuscode;
              /*
           *In the edittable document format always show language detected
           *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
           *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
           */
              this.firstrender = true;
              this.secondrender = true;
              this.fourthrender = true;
              this.openModal(this.downloadOptionPDForORG, false);
              this.singleSelectResp = response;
          //  }
          }
          else if (response.data.downloadDocCount > 1) {
            this.multiSelectionResp = response;
            this.openModal(this.multipleSelectionConf, false);
          }
        }
        else if (response.isSuccess === false) {
          if (response.data.availableDownloadLimit < 1) {
            // daily download limit exceeded
            this.openModal(this.dailyLimitEcxeeded, false);
          } else if (response.data.downloadDocCount > response.data.availableDownloadLimit) {
             // Number of files selected exceeded daily download limit
             this.openModal(this.selectedLimitEcxeeded, false);
          }
        }
      }
    })
  }

  selectedDocGuidId = [];
  selectedRenamedFilesNamesearch = [];
  docownersearchablepdf;
  downloadarrayname = [];
  hidewindow = false;
  downloadarraynameduplicate = [];
  downlaodDocument(docCount, docType, filename) {
    this.percentageDone = undefined;
    this.selectedFileFormat = 'original';
    this.selectedRenamedFilesName = [];
    this.selectedRenamedFilesNamesearch = [];
    this.selectedDocId=[];
    this.selectedDocOriginalName=[];
    this.renamedFileName=[];
    this.selectedDocGuidId = [];
    this.docownersearchablepdf = '';
    if(docType != 'txt'){
      let stdata = window.location.toString();
      if(!stdata.includes('pages/search')){
        filename.forEach(element => {
          this.selectedRenamedFilesName.push(docType === 'pdf' ? element?.convertedPath?.split('\\')[element?.convertedPath?.split('\\').length-1]  : element?.uploadPath?.split('\\')[element?.uploadPath?.split('\\').length-1] || element?.thumbnailPath?.split('\\')[element?.thumbnailPath?.split('\\').length-1]);
          this.selectedDocId.push(element.docId);
          this.selectedDocOriginalName.push(element.originalFileName);
          if(element.docRename){
            this.renamedFileName.push(element.docRename);
          }
          else{
            this.renamedFileName.push(element.documentName);
          }
          this.selectedDocGuidId.push(element.docGuid);
        });
      }
      else{
        filename.forEach(element => {
          this.selectedRenamedFilesName.push(docType === 'pdf' ? element?.convertedpath?.split('\\')[element?.convertedpath?.split('\\').length-1]  : element?.uploadpath?.split('\\')[element?.uploadpath?.split('\\').length-1] || element?.thumbnailpath?.split('\\')[element?.thumbnailpath?.split('\\').length-1]);
          this.selectedDocId.push(element.docId);
          this.selectedDocOriginalName.push(element.originalFileName);

          if(element.docRename){
            this.renamedFileName.push(element.docRename);
          }
          else{
            this.renamedFileName.push(element.documentName);
          }
          this.selectedDocGuidId.push(element.docGuid);
        });
      }
    }
    else if(docType == 'txt'){

      let stdata = window.location.toString();
      if(!stdata.includes('pages/search')){
        filename.forEach(element => {
          this.selectedRenamedFilesNamesearch.push(docType === 'pdf' ? element?.convertedPath?.split('\\')[element?.convertedPath?.split('\\').length-1]  : element?.uploadPath?.split('\\')[element?.uploadPath?.split('\\').length-1] || element?.thumbnailPath?.split('\\')[element?.thumbnailPath?.split('\\').length-1]);
          this.selectedDocId.push(element.docId);
          this.selectedDocOriginalName.push(element.originalFileName);
          if(element.docRename){
            this.renamedFileName.push(element.docRename);
          }
          else{
            this.renamedFileName.push(element.documentName);
          }
          this.selectedDocGuidId.push(element.docGuid);
          this.docownersearchablepdf = element.docOwnerName;
        });
        if(this.selectedRenamedFilesNamesearch){
          let getnameval = this.selectedRenamedFilesNamesearch[0]?.split('.');
          if(getnameval[0]){
            this.selectedRenamedFilesName.push(getnameval[0] + '.txt')
          }
        }
      }
      else{
        filename.forEach(element => {
          this.selectedRenamedFilesNamesearch.push(docType === 'pdf' ? element?.convertedpath?.split('\\')[element?.convertedpath?.split('\\').length-1]  : element?.uploadpath?.split('\\')[element?.uploadpath?.split('\\').length-1] || element?.thumbnailpath?.split('\\')[element?.thumbnailpath?.split('\\').length-1]);
          this.selectedDocId.push(element.docId);
          this.selectedDocOriginalName.push(element.originalFileName);
          if(element.docRename){
            this.renamedFileName.push(element.docRename);
          }
          else{
            this.renamedFileName.push(element.documentName);
          }
          this.selectedDocGuidId.push(element.docGuid);
          this.docownersearchablepdf = element.docOwnerName;
        });
        if(this.selectedRenamedFilesNamesearch){
          let getnameval = this.selectedRenamedFilesNamesearch[0]?.split('.');
          if(getnameval[0]){
            this.selectedRenamedFilesName.push(getnameval[0] + '.txt')
          }
        }
      }
    }


    let postData: any
    postData = {
      "wsoid": Number(this.workSpaceId),
      "wsName": this.wsName,
      "docCount": docCount,
      "fileOrigin": docType,
      "fileNames": this.selectedRenamedFilesName,
      "createdBy": this.createdBy,
      "DocIdList": this.selectedDocId.toString(),
      "OriginalFileNameList": this.selectedDocOriginalName.toString(),
      "Separator": ",",
      "renameFileNames": this.renamedFileName,
      "guid" : this.getguid,
      "DocGuid" : this.selectedDocGuidId,
      "DocOwner" : this.docownersearchablepdf
    }
    if(docCount > 1){
      let filenamedata = {
        name : this.wsName + '-' + docCount + ' ' + 'Documents',
        percent : 0,
        guid : filename[0].docGuid
       }
       this.downloadarrayname.unshift(filenamedata);

       this.data.downloadDocumentzip(postData).subscribe((response) => {

        if (docCount > 1) {
          if(!this.alreadyopenwindow){
            this.openWindow();

          }
          // this.downloadZip(response, docCount, this.wsName)
          if (response.type == HttpEventType.DownloadProgress) {
            // This is an download progress event. Compute and show the % done:
            if(response.loaded){
              if(!response.total){
                response.total = response.loaded
              }
              const percentDone = Math.round(100 * response.loaded / response.total);
              this.percentageDone = percentDone;

              let filenamedata = {
                name : this.wsName,
                percent : percentDone,
                guid : filename[0].docGuid
            }
            const changepercentvalue = this.downloadarrayname.findIndex(item=>item['guid']===filenamedata['guid'] && item['percent']!==100)


            // const arrayChange=changepercentvalue!==-1? this.downloadarrayname[changepercentvalue]['percent']=filenamedata['percent']:this.downloadarrayname.push(filenamedata);
            if (changepercentvalue!==-1) {
              this.downloadarrayname[changepercentvalue]['percent']=filenamedata['percent']
            }

              const checkPercentageValue=this.downloadarrayname.findIndex(al=>al['percent']!==100);
              const changeHideWindow=checkPercentageValue!==-1?this.hidewindow=false:this.hidewindow=true

              if(this.hidewindow){
                setTimeout(() => {
                  const p = document.querySelectorAll('.productfruits--container')[0].shadowRoot;
                  p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'flex';

                   this.windowRef.close();
                   this.alreadyopenwindow=false
                   this.downloadarrayname.length = 0;
                }, 5000);
              }
            }
          }
          if (response.type == HttpEventType.Response) {
            if(this.percentageDone == 100){
              // this.data.downloadDocumentzipdata(postData).subscribe((response) => {
                console.log("response of zip data", response);
                this.downloadZip(response.body, docCount, this.wsName)
                this.toastrService.primary("Multiple documents are downloaded");
              // })
  
            }
          }


        }
          if (docCount > 1) {

            this.nonOwnerDocuments = filename.filter(a => a.docOwnerEmailId !== this.createdBy);
            let output;
            output = Object.values(this.nonOwnerDocuments.reduce((obj, { docOwnerEmailId }) => {
              if (obj[docOwnerEmailId] === undefined){
                obj[docOwnerEmailId] = { docOwnerEmailId: docOwnerEmailId, occurrences: 1 };
              }
              else{
                obj[docOwnerEmailId].occurrences++;
              }
              return obj;
            }, {}));


              output.forEach(element => {
                if(element.occurrences === 1) {
                  this.nonOwnerDocuments.forEach(value => {
                  if(element.docOwnerEmailId === value.docOwnerEmailId){
                    if(value.docRename){
                      this.downloadModifiedNameFileName = value.docRename;
                    }
                    else{
                      this.downloadModifiedNameFileName = value.documentName;
                    }

                    this.documentOwnerEmail = value.docOwnerEmailId;
                    this.notifyInsertDownlaodSinfle();
                    // this.notifysendDownload();
                    // this.toastrService.primary("Document is downloaded");
                  }
                });
                } else if (element.occurrences > 1) {
                  this.documentOwnerEmailMulti = element.docOwnerEmailId;
                  this.filesCount = element.occurrences;
                   this.notifyInsertDownlaodMultifle();
                  //  this.notifysendDownloadMulti();
                  //  this.toastrService.primary("Multiple documents are downloaded");

                }
              });


          }
          else {
            if (filename[0].docOwnerEmailId !== this.createdBy) {

            if(filename[0].docRename){
              this.downloadModifiedNameFileName = filename[0].docRename;
            }
            else{
              this.downloadModifiedNameFileName = filename[0].documentName;
            }
            if(filename[0].formatExtension){
              this.extension = filename[0].formatExtension;
            }
            else{
              this.extension = filename[0].extension;
            }
            this.documentOwnerEmail = filename[0].docOwnerEmailId;
            this.notifyInsertDownlaodSinfle();
            // this.notifysendDownload();
            // this.toastrService.primary("Multiple documents are downloaded");

          }
        }
        //   if (docCount > 1) {
        //     this.nonOwnerDocuments = filename.filter(a => a.docOwnerEmailId !== this.createdBy);
        //     let output;
        //     output = Object.values(this.nonOwnerDocuments.reduce((obj, { docOwnerEmailId }) => {
        //       if (obj[docOwnerEmailId] === undefined){
        //         obj[docOwnerEmailId] = { docOwnerEmailId: docOwnerEmailId, occurrences: 1 };
        //       }
        //       else{
        //         obj[docOwnerEmailId].occurrences++;
        //       }
        //       return obj;
        //     }, {}));


        //       output.forEach(element => {
        //         if(element.occurrences === 1) {
        //           this.nonOwnerDocuments.forEach(value => {
        //           if(element.docOwnerEmailId === value.docOwnerEmailId){

        //             if(value.docRename){
        //               this.downloadModifiedNameFileName = value.docRename;
        //             }
        //             else{
        //               this.downloadModifiedNameFileName = value.documentName;
        //             }
        //             this.documentOwnerEmail = value.docOwnerEmailId;
        //             this.notifyInsertDownlaodSinfle();
        //             // this.notifysendDownload();
        //           }
        //         });
        //         } else if (element.occurrences > 1) {
        //           this.documentOwnerEmailMulti = element.docOwnerEmailId;
        //           this.filesCount = element.occurrences;
        //           this.notifyInsertDownlaodMultifle();
        //           //  this.notifysendDownloadMulti();

        //         }
        //       });


        //   } else {
        //     if (filename[0].docOwnerEmailId !== this.createdBy) {

        //     if(filename[0].docRename){
        //       this.downloadModifiedNameFileName = filename[0].docRename;
        //     }
        //     else{
        //       this.downloadModifiedNameFileName = filename[0].documentName;
        //     }
        //     if(filename[0].formatExtension){
        //       this.extension = filename[0].formatExtension;
        //     }
        //     else{
        //       this.extension = filename[0].extension;
        //     }
        //     this.documentOwnerEmail = filename[0].docOwnerEmailId;
        //    this.notifyInsertDownlaodSinfle();
        //   // this.notifysendDownload();

        //   }
        // }

      },
        (error) => {

        })
    }
    else{
      this.data.downloadDocument(postData).subscribe((response) => {
        if (docCount > 1) {
            this.downloadZip(response, docCount, this.wsName);
        } else if (docType === 'pdf') {

              this.downloadPdf(response, filename,docType);
              this.updateDocAuditLog(filename ,'downloaded as PDF by','AM-1541');
              this.toastrService.primary("Document is downloaded");
        }
        else if(docType === 'txt'){

              this.downloadtxt(response, filename)
              this.updateDocAuditLogext('Extracted Text for',filename ,'downloaded by','AM-1537');
              this.toastrService.primary("Document is downloaded");
        }
        else {
              this.downloadOriginal(response, filename);
              this.updateDocAuditLog(filename ,'downloaded by','AM-030');
              this.toastrService.primary("Document is downloaded");
        }

          if (docCount > 1) {
            this.nonOwnerDocuments = filename.filter(a => a.docOwnerEmailId !== this.createdBy);
            let output;
            output = Object.values(this.nonOwnerDocuments.reduce((obj, { docOwnerEmailId }) => {
              if (obj[docOwnerEmailId] === undefined){
                obj[docOwnerEmailId] = { docOwnerEmailId: docOwnerEmailId, occurrences: 1 };
              }
              else{
                obj[docOwnerEmailId].occurrences++;
              }
              return obj;
            }, {}));


              output.forEach(element => {
                if(element.occurrences === 1) {
                  this.nonOwnerDocuments.forEach(value => {
                  if(element.docOwnerEmailId === value.docOwnerEmailId){

                    if(value.docRename){
                      this.downloadModifiedNameFileName = value.docRename;
                    }
                    else{
                      this.downloadModifiedNameFileName = value.documentName;
                    }
                    this.documentOwnerEmail = value.docOwnerEmailId;
                    this.notifyInsertDownlaodSinfle();
                    // this.notifysendDownload();
                  }
                });
                } else if (element.occurrences > 1) {
                  this.documentOwnerEmailMulti = element.docOwnerEmailId;
                  this.filesCount = element.occurrences;
                  this.notifyInsertDownlaodMultifle();
                  // this.notifysendDownloadMulti();

                }
              });


          } else {
            if (filename[0].docOwnerEmailId !== this.createdBy) {

            if(filename[0].docRename){
              this.downloadModifiedNameFileName = filename[0].docRename;
            }
            else{
              this.downloadModifiedNameFileName = filename[0].documentName;
            }
            if(filename[0].formatExtension){
              this.extension = filename[0].formatExtension;
            }
            else if(filename[0].documentFormat){
              this.extension = filename[0].documentFormat;
            }
            else{
              this.extension = filename[0].extension;
            }

            this.documentOwnerEmail = filename[0].docOwnerEmailId;
            this.notifyInsertDownlaodSinfle();
            // this.notifysendDownload();

          }
        }


      },
        (error) => {

        })
    }

  }
  updateinauditlog;
  docId;
  filenamewithext;
  updateDocAuditLog(filename,operation, serial?) {
    let extensionval;
    if(filename[0].extension){
      extensionval = filename[0].extension;
   }else if(filename[0].formatExtension){
     extensionval = filename[0].formatExtension;
   }
   else if(filename[0].documentFormat){
    extensionval = filename[0].documentFormat;
   }
   else{
    extensionval = filename[0].format.toLowerCase();;
   }
    this.docId = filename[0].docId;
    if(filename[0].neweDocRename){
    this.filenamewithext = filename[0].neweDocRename + '.' + extensionval.toLowerCase();
    }
    else{
      let docrenameval
      if(filename[0].docxRename){
         docrenameval = filename[0].docxRename + '.' + extensionval.toLowerCase();
      }
     else if(filename[0].originalFileName){
        docrenameval = filename[0].originalFileName;
     }
      else{
        docrenameval = filename[0].docRename + '.' + extensionval.toLowerCase();
      }

     this.filenamewithext = docrenameval
    }
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document Activity",
      "logMessage": `<b>"${this.filenamewithext}"</b>` + " " + operation + " " + `<b>${this.userfullname}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      "serialNo" : serial,
      "docName": filename[0].neweDocRename,
      "userName": this.userfullname,
      "guid" : filename[0].docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
    }
    this.verifyDocumentService.viewDocsAuditLog(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.updateinauditlog = response;
      }
    })
  }


  checkZipFile(filename){
      for(let i=0;i<=filename.length-1;i++){
        this.updateDocAuditLogZip(filename[i] ,'downloaded by','AM-030');
      }
  }

  updateDocAuditLogZip(filename,operation, serial?) {
    this.docId = filename.docId;
    if(filename.neweDocRename){
      this.filenamewithext = filename.neweDocRename + '.' + filename.formatExtension;
    }
    else{
      let docrenameval
      if(filename.docxRename){
         docrenameval = filename.docxRename;
      }
      else{
        docrenameval = filename.docRename;
      }
      let extensionval;
      if(filename.extension){
        extensionval = filename.extension;
     }
     else{
      extensionval = filename.format.toLowerCase();;
     }
     this.filenamewithext = docrenameval + '.' + extensionval;
    }
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document Activity",
      "logMessage": `<b>"${this.filenamewithext}"</b>` + " " + operation + " " + `<b>${this.userfullname}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      "serialNo" : serial,
      "docName": filename.neweDocRename,
      "userName": this.userfullname,
      "guid" : filename.docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
      
    }
    this.verifyDocumentService.viewDocsAuditLog(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.updateinauditlog = response;
      }
    })
  }
  docname;
  updateDocAuditLogext(updatedoc,filename,operation, serial?) {
    this.docId = filename[0].docId;
    let extensionval;
    if(filename[0].extension){
      extensionval = filename[0].extension;
   }else if(filename[0].formatExtension){
    extensionval = filename[0].formatExtension;
   }
   else if(filename[0].documentFormat){
    extensionval = filename[0].documentFormat;
   }
   else{
    extensionval = filename[0].format.toLowerCase();
   }
    if(filename[0].neweDocRename){
      this.filenamewithext = filename[0].neweDocRename + '.' + extensionval.toLowerCase();
      this.docname = filename[0].neweDocRename;
    } else if(filename[0].originalFileName){
      this.filenamewithext = filename[0].originalFileName
      this.docname = filename[0].originalFileName;
   }
    else{
      this.filenamewithext = filename.neweDocRename + '.' + extensionval.toLowerCase();
      this.docname = filename[0].docxRename;
    }
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document Activity",
      "logMessage": updatedoc + ' ' +`<b>"${this.filenamewithext}"</b>` + " " + operation + " " + `<b>${this.userfullname}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      "serialNo" : serial,
      "docName": this.docname,
      "userName": this.userfullname,
      "guid" : filename[0].docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
    }
    this.verifyDocumentService.viewDocsAuditLog(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.updateinauditlog = response;
      }
    })
  }

  downloadOriginal(response, renameName) {
    let file = new Blob([response], { type: response.type.toString() });
    let newDocNameForDownload = this.replaceSpecialCharacters(renameName);

    if(renameName[0].formatExtension){
      newDocNameForDownload = newDocNameForDownload + "." + renameName[0].formatExtension;
      FileSaver.saveAs(file, newDocNameForDownload);
    }
    else if(renameName[0].format){
      newDocNameForDownload = newDocNameForDownload + '.' + renameName[0].format
      FileSaver.saveAs(file, newDocNameForDownload);
    }
    else{
      FileSaver.saveAs(file, newDocNameForDownload || renameName[0].documentName + '.' + renameName[0].documentFormat);
    }
  }
  replaceSpecialCharacters(renameName: any) {
    let newDocNameForDownload = renameName[0].docRename || renameName[0].documentName;

    if (newDocNameForDownload.includes("<")) {
      newDocNameForDownload = newDocNameForDownload?.split("<").join(" ");
    }
    if (newDocNameForDownload.includes(":")) {
      newDocNameForDownload = newDocNameForDownload?.split(":").join(" ");
    }
    if (newDocNameForDownload.includes('"')) {
      newDocNameForDownload = newDocNameForDownload?.split('"').join(" ");
    }
    if (newDocNameForDownload.includes("\\")) {
      newDocNameForDownload = newDocNameForDownload?.split("\\").join(" ");
    }
    if (newDocNameForDownload.includes("/")) {
      newDocNameForDownload = newDocNameForDownload?.split("/").join(" ");
    }
    if (newDocNameForDownload.includes("|")) {
      newDocNameForDownload = newDocNameForDownload?.split("|").join(" ");
    }
    if (newDocNameForDownload.includes("?")) {
      newDocNameForDownload = newDocNameForDownload?.split("?").join(" ");
    }
    if (newDocNameForDownload.includes(">")) {
      newDocNameForDownload = newDocNameForDownload?.split(">").join(" ");
    }
    if (newDocNameForDownload.includes("*")) {
      newDocNameForDownload = newDocNameForDownload?.split("*").join(" ");
    }
    newDocNameForDownload = newDocNameForDownload.trim();
    return newDocNameForDownload;
  }

  downloadPdf(response, renameName,docType) {
    let file = new Blob([response], { type: response.type.toString() });
    let newDocNameForDownload = this.replaceSpecialCharacters(renameName);

    if(newDocNameForDownload){
      FileSaver.saveAs(file, newDocNameForDownload  + '.pdf');
    }
    else{
      FileSaver.saveAs(file, renameName[0].documentName  + '.pdf');
    }

  }

  downloadtxt(response, renameName) {
    let file = new Blob([response], { type: response.type.toString() });
    let newDocNameForDownload = this.replaceSpecialCharacters(renameName);
    if(newDocNameForDownload){
      FileSaver.saveAs(file, newDocNameForDownload + '.txt');
    }
    else{
      FileSaver.saveAs(file, renameName[0].documentName + '.txt');
    }

  }
  downloadPdfOriginal(response) {
    let file = new Blob([response], { type: response.type.toString() });
    FileSaver.saveAs(file, this.selectedRenamedFilesName);
  }
  downloadZip(response, docCount, wsName) {
    let file = new Blob([response], { type: response.type.toString() });
    FileSaver.saveAs(file, wsName + '-' + docCount + ' ' + 'documents.zip');
  }

  /*download modal start */
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick:boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false});

  }
  closeModal() {
    this.dialogRef.close();
    this.sharedservice.downloadDisable.next(false)
  }
  fileFormatselected(value) {
    if (value === 'original') {
      this.selectedFileFormat = 'original';
      this.checkOriginal = true;
      this.checkPdf = false;
    }
    else if(value === 'txt'){
      this.selectedFileFormat = 'txt';
      this.checkOriginal = false;
      this.checkPdf = true;
    }
    else {
      this.selectedFileFormat = 'pdf';
      this.checkOriginal = false;
      this.checkPdf = true;
    }
  }

  downlaodSingleConfirmation() {
    this.downlaodDocument(this.singleSelectResp.data.downloadDocCount, this.selectedFileFormat, this.selectedDocs);
    this.toastrService.primary("Document(s) being prepared & will be downloaded shortly");
    this.sharedservice.downloadDisable.next(false)
  }
  downloadZipConfirmation() {
    this.downlaodDocument(this.multiSelectionResp.data.downloadDocCount, 'original', this.selectedDocs);
    this.toastrService.primary("Document(s) being prepared & will be downloaded shortly");

      this.checkZipFile(this.selectedDocs);
      this.sharedservice.downloadDisable.next(false)

  }
  dropdownconfig = {
    displayKey: "custom",
    search: true,
    placeholder: "Select user",
    searchPlaceholder:'Search user',
    height: "auto"
  };
  onDocumentOwner(event) {

    this.documentOwnerName= event.displayName;
    this.documentOwnerEmail= event.emailId;
  }
  notifyInsertDownlaodSinfle() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: 'Document is downloaded',
      message: "<b>" + localStorage.getItem('noteuserfullname') + " " + "(" + this.createdBy + ")" + "</b>" + " " + "downloaded" + " " + this.downloadModifiedNameFileName  + " " + "under your ownership. View" + " " + `<a href=${urlchange}>Owned by me</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      }
      else {
        this.insertmessgae = '';
      }
    })
  }

  notifysendDownload() {
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: 'Document is downloaded',
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""
    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }
    })
  }
  notifyInsertDownlaodMultifle() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: 'Multiple documents are downloaded',
      message: "<b>" + localStorage.getItem('noteuserfullname') + " " + "(" + this.createdBy + ")" + "</b>" + " " + "downloaded" + " " +  this.filesCount  + " " + "documents of your ownership. View" + " " + `<a href=${urlchange}>Owned by me</a>` ,
      notificationTo: this.documentOwnerEmailMulti,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      }
      else {
        this.insertmessgae = '';
      }
    })
  }

  /*
          *click on the image in download
  */
 getimagevalue(data){
    let fomateclick = data;
    if(fomateclick != 'pdf' && fomateclick != 'txt'){
      this.firstrender = true;
      this.secondrender = true;
      this.fourthrender = true;
    }
    else if(fomateclick == 'txt'){
      this.firstrender = false;
      this.secondrender = true;
      this.fourthrender = false;
    }
    else{
      this.firstrender = false;
      this.secondrender = false;
       this.fourthrender = true;
    }
 }
  notifysendDownloadMulti() {
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmailMulti,
      role: null,
      groupName: null,
      message: 'Multiple documents are downloaded',
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""
    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }
    })
  }
  @ViewChild('contentTemplate') contentTemplate: TemplateRef<any>;

  close = true;
  alreadyopenwindow = false;
  openWindow() {
    if(!this.alreadyopenwindow){


    const buttonsConfig: NbWindowControlButtonsConfig = {
      minimize: true,
      maximize: true,
      fullScreen: false,

    }

   this.windowRef = this.windowService.open(
      this.contentTemplate,
      { title: `Document(s) being fetched for downloading...`,
      closeOnBackdropClick: false,
        closeOnEsc: true,
        buttons: buttonsConfig,
        initialState:NbWindowState.MAXIMIZED
       },
    )
    this.alreadyopenwindow = true;

    const p = document.querySelectorAll('.productfruits--container')[0].shadowRoot;
    p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'none';
  }
}
}


