
import { Component, ChangeDetectorRef, OnInit, ViewChild,HostListener, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { NbContextMenuDirective, NbMenuService } from '@nebular/theme';
import { SharedService } from '../../../@core/utils/shared.service';
import { SharedServiceService } from '../../../shared-service.service';

@Component({
  selector: 'ngx-three-dot',
  templateUrl: './three-dot.component.html', 
  styleUrls: ['./three-dot.component.scss'] 
})
export class ThreeDotComponent implements OnInit,OnDestroy {
  data:any;
  params:any;
  showButtons: boolean;
  myQueueDeleteModalOpen:Boolean;
  docSelectedCount: boolean = false;
    selectedDocs: any;
  isMenuOpen = false;
  @ViewChild("nb-context-menu") threedot!:any
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  docSelectStatus: boolean;
  selectedDocCount: any = '';
  cdkOverlayPane: any;

  ngAfterViewInit(): void {
    
  }



  items = [
    {
      title: 'Open Document',
      icon: { icon: 'external-link-outline', pack: 'eva' },
    },{
      title: 'Preview',
      icon: { icon: 'eye-outline', pack: 'eva' },
    },
    {
      title: 'Tag & Verify',
      icon: { icon: 'checkmark-square-outline', pack: 'eva' },
    },
    {
      title: 'Share',
      icon: { icon: 'share-outline', pack: 'eva' },
    },
   
    {
      title: 'Change verifier',
      icon: { icon: 'person-outline', pack: 'eva' },
    },
    {
      title: 'View details',
      icon: { icon: 'file-text-outline', pack: 'eva' },
    },
    {
      title: 'Delete',
      icon: { icon: 'trash-2-outline', pack: 'eva' },
    },
  ];
  // thisitems.forEach((element,index)=>{
  //   if(element.title=="View details") delete items[index];
  constructor(public cd: ChangeDetectorRef, private sharedservice: SharedServiceService, private sharedserv:SharedService,private menuService: NbMenuService, private renderer: Renderer2, private el: ElementRef) {


   }

    
  ngOnDestroy(): void {
  this.subscriberThreeDot?.unsubscribe()
  }

  hidedetail:boolean=true
   subscriberThreeDot :any;

  ngOnInit(): void {
 
   this.getUpdatedValue();
    this.subscriberThreeDot = this.menuService.onItemClick().subscribe((event) => {
      if(event.item.title === 'Open Document'){ 
        this.sharedserv.ownedview.next('View');
     
            this.onVerifyClick();
      }
        else if (event.item.title === 'Change verifier') {
          this.sharedserv.ownedview.next('View');
          this.onChangeVerifierClicked();
        } 
        else if(event.item.title === 'View details'){
          this.sharedserv.ownedview.next('View');
         // this.sharedservice.myqueueViewDetails.next(this.hidedetail)
         this.onViewClickdocdetails();
        }
        else if(event.item.title === 'Tag & Verify'){
          this.sharedserv.ownedview.next('View');
          this.sharedserv.myqueueTagVerify.next(this.hidedetail)
        }else if(event.item.title === 'Preview'){
          this.sharedserv.ownedview.next('View');
          this.sharedserv.myqueuePreview.next(this.hidedetail)
        }
       
        else if(event.item.title === 'Delete'){
          this.sharedserv.ownedview.next('View');
          if(this.params){
            this.params.data.res = true;
            this.sharedservice.myqueueDelete.next(this.params?.data);
          }
    
        }
        else if(event.item.title === 'Share'){
          this.sharedserv.ownedview.next('View');
          this.openShareModal();
    
        }
      
     });
     this.sharedserv.threeDotValue.subscribe((res:any)=>{
      this.selectedDocCount = res;
      this.getUpdatedValue();
      
    })
  }

  triggerMenu() {
    this.contextMenu.show();
}
  

@HostListener('document:click', ['$event'])
onClick(event: MouseEvent) {
  this.handleClickOutside(event);
}

handleClickOutside(event: MouseEvent) {
  if (!this.el.nativeElement.contains(event.target)) {
    this.contextMenu.hide();
  }
}

  updateDocSelectedCount() {
    this.selectedDocs = localStorage.getItem('selectedDocs');

    if(this.selectedDocs > 1) {
      this.docSelectedCount = true;
    }
    else {
      this.docSelectedCount = false;
    }
    this.cd.detectChanges();
  }

  ngDoCheck() {
    this.updateDocSelectedCount()
  }
  
  getUpdatedValue(){
    if(this.selectedDocCount === 'multi'){
      this.items =  this.items.filter(obj =>{
        return obj.title !== 'Open Document' && obj.title !== 'View details' && obj.title !== 'Preview'
      })
      if (localStorage.getItem('shareRightValidation') !== 'true') {
        this.items = this.items.filter((obj) => obj.title !== "Share");
       }
       if (localStorage.getItem('sharetagsRightValidation') !== 'true') {
        this.items = this.items.filter((obj) => obj.title !== "Tag & Verify");
       }
       if (localStorage.getItem('shareRightValidation') !== 'true') {
        this.items = this.items.filter((obj) => obj.title !== "Share");
       }
  
    }
    else{
      this.items = [
        {
          title: 'Open Document',
          icon: { icon: 'external-link-outline', pack: 'eva' },
        },{
          title: 'Preview',
          icon: { icon: 'eye-outline', pack: 'eva' },
        },
        {
          title: 'Tag & Verify',
          icon: { icon: 'checkmark-square-outline', pack: 'eva' },
        },
        {
          title: 'Share',
          icon: { icon: 'share-outline', pack: 'eva' },
        },
      
        {
          title: 'Change verifier',
          icon: { icon: 'person-outline', pack: 'eva' },
        },
        {
          title: 'View details',
          icon: { icon: 'file-text-outline', pack: 'eva' },
        },
        {
          title: 'Delete',
          icon: { icon: 'trash-2-outline', pack: 'eva' },
        },
      ];
      if (localStorage.getItem('preview') !== 'True') {
        this.items = this.items.filter((obj) => obj.title !== "Preview");
       }
    }
    console.log("itemsCount", this.items)
   
  
  }

  hide() {
    this.cdkOverlayPane[0].style.display = 'none';
  }

  agInit(params:any){
    
    this.data=params?.value;
    this.params = params;
    if (!params?.data.isReadable || params?.data.isLocked || params?.data.isEncrypted || params?.data.isCorrupt) {
      this.showButtons = false;
  }

   else{
    this.showButtons = true;
  }
    }
      MyqueueDeleteDocs(){
        this.params.data.res = true;
        this.sharedservice.myqueueDeleteThreeDot.next(this.params?.data);
      }
      MyqueueChangeVerifier(){
        this.sharedservice.MyqueueChangeVerifier.next(this.params?.data);
      }  
      myQueueShare(){
        this.sharedserv.myqueueShare.next(this.params?.data);
      }
      openShareModal(){
        const params = {
          rowData: this.params?.node.data
       }
        this.params?.onShareClick(params);
      }
      onVerifyClick(){
      
        if (this.params?.onVerifyClick instanceof Function) {
        
          const params = {
            rowData: this.params?.node.data,
            documentId:this.params?.node.data.docId,
            docGuid:this.params?.node.data.docGuid,
    
          } 
          this.params?.onVerifyClickthree(params);  
    
        }  
      }

      public showDeleteUser(){
        if (this.params?.data.pageCount == 0) {       
          return;
        }  else{
          this.params.node.data.showMenu=!this.params?.node.data.showMenu;
        
          this.closeOtherMenus(this.params?.node.data.docId);
        }
      }

      private closeOtherMenus(docId) {
        if (this.params?.rowData && this.params?.rowData.length) {
          this.params?.rowData.forEach(x => {
            if (x.docId !== docId) {
              x['showMenu'] = false
            }
          })
        }
      }

      getClassIfDisabled(){
        if (this.params?.data.pageCount == 0) {       

            return 'colorIcon';
            
          }
  
      }
      onViewClickdocdetails(){
        const params = {
          rowData: this.params?.node.data
       }
        this.params?.onViewClickdocdetails(params);
          
      }
onTagVerifyClick(){
  const params = {
    rowData: this.params?.node.data
 }
  this.params?.onTagVerify(params);
    
}
      onChangeVerifierClicked(){
        const params = {
          rowData: this.params?.node.data
       }
        this.params?.onChangeVerifierClicked(params);
          
      }

      ondeleteClicked(){
        const params = {
          rowData: this.params?.node.data,
          res:true
       }
        this.params?.ondeleteClicked(params);
          
      }



}
