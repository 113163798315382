/**
 *  DocChart 
 */
export interface DocChart {
   /**
      *  variable for chartLabel
   */
  chartLabel: string[];
   /**
      *  variable for DocData
   */
  DocData: number[][];
}
/**
 *  DocChart 
 */
export abstract class DocChartData {
    /**
 *  abstract method for getDocChartData
 */
  abstract getDocChartData(period: string): DocChart;
}
