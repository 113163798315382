import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {  map } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedAccessService } from '../../@core/utils/shared-access.service';
import { NbDialogRef, NbDialogService,NbTagComponent, NbToastrService } from '@nebular/theme';
import { SharedService } from '../../@core/utils/shared.service';
import { SharedServiceService } from '../../shared-service.service';
import { OwnedDocsService } from '../../../app/@core/utils/owned-docs.service';
import { DetailAndActivityComponent } from '../../shared/detail-and-activity/detail-and-activity.component';
import { ResponseComponent } from '../../verification/response/response.component';
import { not } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'ngx-shared-access',
  templateUrl: './shared-access.component.html',
  styleUrls: ['./shared-access.component.scss']
})

export class SharedAccessComponent implements OnInit {
  @ViewChild('shareuse') shareExitModal;
  @ViewChild('shareAccess') shareAccess: TemplateRef<any>;
  @Output() sharedGroup = new EventEmitter<any>();
  @Output() sharedGroupCount = new EventEmitter<any>();
  @Output() isUserGrp = new EventEmitter<any>();
  @Output() isSelectedAllUser = new EventEmitter<any>();
  @Output() isCheckedAllUser = new EventEmitter<any>();
  @Output() isSelectedNoUser = new EventEmitter<any>();
  @ViewChild(DetailAndActivityComponent) rightnavchild: DetailAndActivityComponent;

  @Input() ownerEmailID;
  @Input() allUserLength;
  @Input() sharedWithUsers;
  @Input() verifierEmailID;
  @Input() isAllUserShare;
  @Input() selectedMultiDocument;
  @ViewChild('autoInput') input;
 /**
 *  variable for shareDetails
 * */
  shareDetails: any = [];
   /**
 *  variable for verifierName
 * */
  verifierName: string;
   /**
 *  variable for verifierName
 * */
  showSharemodalSpin: boolean = false;
   /**
 *  variable for ownByMeList
 * */
  shareAcessDisable: boolean = true;
   /**
 *  variable for ownByMeList
 * */

  VisibUnvisib: string = "visible";
   /**
 *  variable for isCheckedAllUsers
 * */
  isCheckedAllUsers: boolean;
   /**
 *  variable for showOverlaySpinner
 * */
  showOverlaySpinner = true;
   /**
 *  variable for ownByMeList
 * */
  @BlockUI() blockUI: NgBlockUI;
   /**
 *  variable for workSpaceId
 * */
  workSpaceId: string;
   /**
 *  variable for createdBy
 * */
  createdBy: string;
   /**
 *  variable for shareSpinner
 * */
  shareSpinner:boolean=true;
   /**
 *  variable for docId
 * */
  docId = '';
   /**
 *  variable for DocGuid
 * */
  DocGuid = '';
   /**
 *  variable for docGuid
 * */
  docGuid = '';
   /**
 *  variable for myDocs
 * */
  myDocs = false;
   /**
 *  variable for pendingRequest
 * */
  pendingRequest: any;
   /**
 *  variable for idsList
 * */
  idsList: any = [];
   /**
 *  variable for selectedShareAcess
 * */
  selectedShareAcess: any = [];
   /**
 *  variable for categroyList
 * */
  categroyList: any = [];
   /**
 *  variable for user EmailIDs And GroupList
 * */
  userEmailIDsAndGroupList: any = [];
   /**
 *  variable for ownByMeList
 * */
  userNameGroupList: any = [];
   /**
 *  variable for share user Count
 * */
  shareuserCount: number = 0;
   /**
 *  variable for allUserGroupList
 * */
  allUserGroupList: any = [];
   /**
 *  variable for setUserGroupArr
 * */
  setUserGroupArr: any = [];
   /**
 *  variable for isSelectedAllUsers
 * */
  isSelectedAllUsers: boolean = true;
   /**
 *  variable for isUsersGroup
 * */
  isUsersGroup: boolean = true;
   /**
 *  variable for items
 * */
  items=[];
   /**
 *  variable for addeduserdata
 * */
  addeduserdata;
   /**
 *  variable for trees
 * */
  trees=[];
   /**
 *  variable for options
 * */
  options=[];
   /**
 *  variable for allTreeData
 * */
  allTreeData=[];
  checked2=true;
  checked1: boolean;
  selectedSharedValue: any;
  subscription: any;
  setUserGroupArrEdit: any;
  insertmessgae: string;
  fullName: string;
  subscriptionshared: any;
  href: string;
  cancelClicked: boolean = false;
  selectedValue: any;
  selected: any;
  checked3: boolean;
  groupExist: boolean;
  groupdataValue: boolean;
  multipleDocIdList: [];
  seletedData: any;
  NoOneSelected: boolean = false;
  everyOneSelected: boolean = true;
  getSharedDiv: boolean;
  changeChipState: boolean = false;
  shareAcessDisable1: boolean = true;
  enableButton: boolean;
  flagValue:any = "1";
  changeUserAndGroup: boolean = false;
  disabledBtn: boolean = true;
  isSelectedNoUsers: boolean = false;
  enableShareButton: boolean;
  hideCheckedOption: boolean =false;
  selectedDocId: any;
  check: boolean;
  noshare: boolean;
  checked3button: boolean;
  showChip: any;
  shareDetailsNew: any;
  chipRemove: boolean=false;
  showSpinner: boolean;
  singleDocGuid='';
  constructor(private toastrService : NbToastrService , private ownDoc :OwnedDocsService, private responseComponent : ResponseComponent,
    private sharedservice : SharedServiceService ,private sharedService: SharedService,  private dialogService: NbDialogService, private activatedRoute: ActivatedRoute,  private data: SharedAccessService, private httpClient: HttpClient, private router: Router, private titleService: Title, private fb: FormBuilder, private rendrer: Renderer2, private e1: ElementRef, private http: HttpClient
    ,@Optional() private dialogRef: NbDialogRef<any>)
  {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.fullName = localStorage.getItem('noteuserfullname')
    this.createdBy = localStorage.getItem('localloginId')
    if (!this.createdBy) {
      this.router.navigateByUrl('/sign-in');
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.docId = params['docId'];
      this.docGuid = params['docGuid'];

    })
  }

  subscriptionradio;
  radiobtnselect;
  radioselectusers;
  getchipsdata:any;
  cities3 = [];
  namechips = [];

  getSelectedChange(){

    var flag = true;

 if(this.enableButton || this.enableShareButton || this.chipRemove
  ){      flag = false;
    }


    return flag;
  }
  oncancel(){
    this.sharedservice.shareButton.next();
  }
  ngOnInit(): void {
    
    var st = window.location.toString();
    var arr = st.split("/")[4];

  
    var wslink = st?.split("/")[2]
    let payload={
      "WsLink":wslink,
      "userEmailID":localStorage.getItem('ownerEmailId'),
      "FromDate":"",
      "ToDate":"",
      "InvitedBy":"",
      "Role":""
    }

    this.data.getInvitedUsersLists(payload)
    .subscribe((res:any)=>{
      console.log(res)
      let invitedUsersEmail=[]
      if(res.data && res.data.length>0)
      {
        for(let i=0; i<res.data.length; i++)
        {
          invitedUsersEmail.push(res.data[i].emailId)
        }
      }

      console.log("invitedUsersEmail",invitedUsersEmail)
      
    })

    if(arr == "socket"){
      this.allUserLength=localStorage.getItem('userList')
    }
    this.checked3button=false
    this.namechips = [];
    this.shareTemplateList();
    this.sharedService.selectedDocumentList.subscribe((res:any)=>{
      this.selectedMultiDocument = res;
    })
    this.sharedService.threeDotValue.subscribe((res:any)=>{
      this.seletedData = res;
    })
    this.sharedService.isShare.subscribe((flag:any)=>{
      this.isCheckedAllUsers = flag;
      // console.log(flag)
    })
    // console.log(this.selectedMultiDocument)
    this.subscription = this.sharedService.selectedAccessEditor.subscribe((data) => {
      this.setUserGroupArrEdit = data;

      /*
           *dropdown chips data bind
      */
      this.namechips = [];
      this.allTreeData = [];
      this.getchipsdata = data;
      this.allTreeData.push(...this.getchipsdata);

      for(let i=0;i<=this.allTreeData.length-1;i++){
        this.allTreeData[i].nameAndGroup =  this.allTreeData[i].name;
        this.namechips.push(this.allTreeData[i].name);

      }
      this.check =this.namechips.length == 0? false:true

      this.onAlUserGroupList(this.allTreeData);

    });

      /*
           *radio button selection according to share data
      */

    this.subscriptionradio = this.sharedService.isAllUserSharevalue.subscribe((data) => {
      this.radiobtnselect = data;
      this.checkradio()
    });

    this.subscriptionshared = this.sharedService.sharedAccessData.subscribe(data=>{
        if(this.seletedData == 'multi'){
          this.saveShareTemplateMultiple('withoutshareclick')
          localStorage.setItem('uploadTime','0');

        }else{
          this.saveShareTemplate('withoutshareclick',data)
          localStorage.setItem('uploadTime','0');
        }
    })

    this.subscriptionradio = this.sharedService.getuserradio.subscribe((data) => {
      this.radioselectusers = data;
      this.checkradio()
    });

  }
  ngOnDestroy(){
    this?.subscription?.unsubscribe();
  }
  disableDropdown(){
    let flag = false;
    if(this.shareAcessDisable){
      flag = true;
    }

    return flag
  }
  allusergroupconfig = {
    displayKey: "custom",
    search: true,
    placeholder: "Add users & groups",
    height: "150px"
  };
  onAlUserGroupList(event) {
    this.allUserGroupList=[];

    this.allUserGroupList.push(event);
    // console.log(this.allUserGroupList,"userList");

    this.showChip=this.allUserGroupList[0]
    // console.log(this.showChip,"userList");

    this.idsList = [];
    this.categroyList = [];
    this.userNameGroupList = [];
    this.setUserGroupArr = [];
    this.userEmailIDsAndGroupList = [];
    if(this.namechips.length > 0){
      this.isCheckedAllUsers = false;
    }
    for (let item of this.allTreeData) {

      this.idsList.push(item.id);
      // console.log(this.idsList)
      this.categroyList.push(item.categroy);
      this.userEmailIDsAndGroupList.push(item.emailID);
      this.userNameGroupList.push(item.nameAndGroup);
    }

    this.shareuserCount = this.userNameGroupList.length;

    for (let i = 0; i <= this.userNameGroupList.length - 1; i++) {
      if (i <= 5) {
        this.setUserGroupArr.push(this.userNameGroupList[i]);

      }
      else {

      }
    }



  }

  close(){
this.namechips=[]
    localStorage.setItem('uploadTime','0');

  }
  saveShareTemplateMultiple(val?) {
    localStorage.setItem('uploadTime','0');
    if (this.shareAcessDisable) {
      this.namechips = [];

    }
    if(this.everyOneSelected){
      this.idsList = [];
      this.categroyList = [];
      this.userEmailIDsAndGroupList = [];
    }
    this.sharedservice.shareButton.next();
    var st = window.location.toString();
    var arr = st?.split("/");
    this.idsList = this.idsList?.filter(item => !!item);
    this.userEmailIDsAndGroupList = this.userEmailIDsAndGroupList?.filter(item => !!item);
    if(this.shareAcessDisable){
      this.idsList= [];
    }
    let postData: any
    if(this.isCheckedAllUsers){
       this.idsList = [];
       this.categroyList = [];
       this.userEmailIDsAndGroupList = [];
    }
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "DocID": 1 ,
      "DocIdList":this.multipleDocIdList?.toString(),
      "IDsList": this.idsList.join('|'),
      "CategroyList": this.categroyList.join('|'),
      "UserEmailIDsAndGroupList": this.userEmailIDsAndGroupList.join('|'),
      "IsAll": this.isCheckedAllUsers,
      "Separator": "|",
      "CreatedBy": this.createdBy,
      "guid": this.fullName,
      "flag": this.flagValue,
      "IsShareWithSendDoc" : 0

    }
    this.blockUI.start('Loading...');
    this.pendingRequest = this.data.saveShareTemplateService(postData).subscribe((response: any) => {
      if (response) {
        this.notifyShared();
        if(!this.cancelClicked){
          if(!val){
            this.toastrService.primary('Shared access updated');
            this.rightnavchild.getSharedDetails()
          }
        }


        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
        if (!this.isCheckedAllUsers) {
          this.isSelectedAllUsers = true;
          this.isSelectedAllUser.emit(this.isSelectedAllUsers);
          this.isUsersGroup = false;
          this.isUserGrp.emit(this.isUsersGroup);
          this.sharedGroupCount.emit(this.shareuserCount);
          this.selectedShareAcess = this.allUserGroupList;
        }
        else if (this.isCheckedAllUsers) {
          this.isSelectedAllUsers = false;
          this.sharedGroupCount.emit(this.shareuserCount);
          this.isSelectedAllUser.emit(this.isSelectedAllUsers)
          this.isUsersGroup = true;
          this.isUserGrp.emit(this.isUsersGroup);

        }
        else {
          this.isSelectedNoUsers = true;
          this.isUsersGroup = false;
          this.isSelectedNoUser.emit(this.isSelectedNoUsers)
          this.isUserGrp.emit(this.isUsersGroup);
          this.sharedGroupCount.emit(this.shareuserCount);
        }
        this.sharedGroup.emit(this.setUserGroupArr);

      } else {
        this.sharedGroup.emit(this.setUserGroupArr);

      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000)
    })
  }
 async saveTemplate(){
  localStorage.setItem('uploadTime','0');
  // console.log(this.namechips,'name')
  if(this.seletedData == 'multi'){
    this.saveShareTemplateMultiple();
  }
  else{
    await this.saveShareTemplate();
  }
  setTimeout(() => {
    this.sharedService.cellClickedData.next(true);
  }, 500);
  this.sharedService.getDashboardUpdate.next(true);
 }

  async saveShareTemplate(val?,data?) {
    if (this.shareAcessDisable) {
      this.namechips = [];
      this.idsList = [];
      this.categroyList = [];
      this.userEmailIDsAndGroupList = [];
    }
    if(this.isCheckedAllUsers){
      this.idsList = [];
      this.categroyList = [];
      this.userEmailIDsAndGroupList = [];
    }
    if(this.everyOneSelected){
      this.idsList = [];
      this.categroyList = [];
      this.userEmailIDsAndGroupList = [];
    }
    if (this.namechips.length == 0) {
      this.checked3 = true;
    }

    this.sharedservice.shareButton.next();
    var st = window.location.toString();
    var arr = st?.split("/");
    this.idsList = this.idsList?.filter(item => !!item);
    this.userEmailIDsAndGroupList = this.userEmailIDsAndGroupList?.filter(item => !!item);

    let postData: any

    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "DocID": Number(this.docId),
      "IDsList": this.idsList.join('|'),
      "CategroyList": this.categroyList.join('|'),
      "UserEmailIDsAndGroupList": this.userEmailIDsAndGroupList.join('|'),
      "IsAll": this.isCheckedAllUsers,
      "Separator": "|",
      "CreatedBy": this.createdBy,
      "guid": this.fullName,
      "flag": this.flagValue

    }
    if(data == 'build'){
      postData.IsAll = true
    }
    this.blockUI.start('Loading...');
   await this.data.saveShareTemplateService(postData).toPromise().then(async (response: any) => {
      if (response) {
        console.log('inside save template, this.noshare,this.checked3',this.noshare,this.checked3)

        if(this.checked3)
        {
          // this.notifySharedCustom()
        }
        
        this.notifyShared();
        if(!this.cancelClicked){
          if(!val){
            this.toastrService.primary('Shared access updated');
            if(this.isDocketDoc){
              await this.responseComponent.allverifiedDocList("refresh");
            }else if(!this.isDocketDoc){
              setTimeout(() => {
                this.sharedService.cellClickedData.next(true);
                this.rightnavchild.getSharedDetails();
              }, 100);
            }
          }
        }


        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
        if (!this.isCheckedAllUsers) {
          this.isSelectedAllUsers = true;
          this.isSelectedAllUser.emit(this.isSelectedAllUsers);
          this.isUsersGroup = false;
          this.isUserGrp.emit(this.isUsersGroup);
          this.sharedGroupCount.emit(this.shareuserCount);
          this.selectedShareAcess = this.allUserGroupList;
        }
        else if (this.isCheckedAllUsers) {
          this.isSelectedAllUsers = false;
          this.sharedGroupCount.emit(this.shareuserCount);
          this.isSelectedAllUser.emit(this.isSelectedAllUsers)
          this.isUsersGroup = true;
          this.isUserGrp.emit(this.isUsersGroup);

        }
        else {
          this.isUsersGroup = false;
        }
        this.sharedGroup.emit(this.setUserGroupArr);

      } else {
        this.sharedGroup.emit(this.setUserGroupArr);

      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000)
    })
  }
  shereTemplateForm = new FormGroup({
    shareWithUserRadio: new FormControl(''),
  });
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.emailID.toLowerCase().includes(filterValue));
  }

  /**
   * Notify Custom Sent Shared 
   */
  notifySharedCustom()
  {
    console.log("this.userEmailIDsAndGroupList",this.userEmailIDsAndGroupList)
    console.log('notify shared called custom')
    console.log("localStorage.getItem('ownerEmailId')",localStorage.getItem('ownerEmailId'))

    var st = window.location.toString();
    var wslink = st?.split("/")[2]
    var urlchange = wslink[0] + "//" + wslink[2] + '/pages/documents/shared-with-me'

    let payload={
      // "WsLink":wslink,
      // "userEmailID":localStorage.getItem('ownerEmailId'),
      // "FromDate":"",
      // "ToDate":"",
      // "InvitedBy":"",
      // "Role":""

      "AuthorizeEmailId":localStorage.getItem('ownerEmailId'),
      "FromDate":"",
      "FullName":"",
      "GroupId":0,
      "RoleId":0,
      "Status":"",
      "ToDate":"",
      "WsLink":wslink,
      "Wsoid":Number(localStorage.getItem('localwsId'))
    }

    let invitedUsersEmail=[]

    this.data.getInvitedUsersLists(payload)
    .subscribe((res:any)=>{
      console.log(res)
      
      if(res.data && res.data.length>0)
      {
        for(let i=0; i<res.data.length; i++)
        {
          if(!invitedUsersEmail.includes(res.data[i].emailId) && res.data[i].category!='Owner')
          invitedUsersEmail.push(res.data[i].emailId)
        }
      }

      console.log("invitedUsersEmail",invitedUsersEmail)

      for(let i=0; i<invitedUsersEmail.length; i++)
      {
            let notifysend = {
              wsoid: Number(this.workSpaceId),
              key: null,
              userEmailID:invitedUsersEmail[i],
              //userEmailID:'shivamb@privetonline.com',
              role: null,
              groupName: null,
              message: "shareWithNoOne|docGuid,"+this.singleDocGuid+"|",
              databaseName: "",
              createdBy: this.createdBy,
              Flag: "Modal",
              WsLink: ""
            }

            
            console.log("localStorage.getItem('ownerEmailId')",localStorage.getItem('ownerEmailId'))
        
             if(localStorage.getItem('ownerEmailId')!=invitedUsersEmail[i])
             {
              this.data.notifysend(notifysend).subscribe((response: any) => {
                if (response.isSuccess) {
                  console.log('notifyShared Function called for shareWithNoOne')
                }
              })
            }

      }
      
    })


}


  notifyShared() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/shared-with-me'
    let roleinsert = {
      wsoid: Number(this.workSpaceId),
      createdby: this.createdBy,
      category: "Shareduser",
      value: "<b>" + localStorage.getItem('noteuserfullname') + " " + "(" + this.createdBy + ")" + "</b>" + " " + "shared a document with you. Go to" + " " + `<a href=${urlchange}>Shared with me</a>`,
      database: "",
      WsLink: this.userEmailIDsAndGroupList.join(','),
    }
    this.insertmessgae = "";
    this.data.roleinsert(roleinsert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      }
      else {
        this.insertmessgae = '';
      }
    })
  }
  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }
  onChange() {
    this.addeduserdata = this.getFilteredOptions(this.input.nativeElement.value);
  }
  tempArray = [];
shareUsers(val?,id?) {
  console.log("document guid ids here",val,id)
  this.singleDocGuid=val.docGuid;
  let myqueueFlag = id;
  this.disabledBtn = true;
  // this.enableShareButton = false;
  this.NoOneSelected = false;
  this.flagValue = "0";
  this.sharedService.isShare.subscribe((flag:any)=>{
    this.isCheckedAllUsers = flag;
  })

   this.sharedService.threeDotValue.subscribe((res:any)=>{
    this.seletedData = res;
  })


  this.shareAcessDisable1 = true;
  val ?this.docId = val : '';
this.tempArray = [];

// if(val?.bydefault != ''){
  if(val?.bydefault == 'bydefault'){
  }
  else{
    this.openSharePopModal();
  }
// }

    this.VisibUnvisib = "hidden"
    this.showOverlaySpinner = false;
    this.showSharemodalSpin = false;

    this.blockUI.start('Loading...');
    setTimeout(() => {
      /** spinner ends after 5 seconds */

      this.VisibUnvisib = "visible"
      this.showSharemodalSpin = true;
    }, 10);

    if(this.seletedData == 'multi'){
      this.checked1 = false;
      this.checked2 = false;
      this.checked3 = false;
      this.namechips= [];
      this.tempArray= [];
      this.allTreeData = [];
      this.showSpinner = false;

    }
    else{
      this.enableButton = true;
      this.href = this.router.url;
      this.getShareUserCount(this.docId);
      if (!this.isCheckedAllUsers) {
        if(this.namechips.length > 0){
          this.checked1 = true;
          this.checked2 = false;
          this.checked3 = false;
          this.onCustomUserGroupChange('1')
          this.hideCheckedOption = true;
        }
        else{
          if(this.namechips.length == 0){
            this.checked2 = false;
            this.checked1 = false;
            this.checked3 = true;
            this.onCustomUserGroupChange('3')
            this.hideCheckedOption = false;
          }else{
            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
          }

        }
      }
      else if (this.isCheckedAllUsers) {
        if(this.namechips.length > 0){
          this.checked1 = true;
          this.checked2 = false;
          this.checked3 = false;
          this.onCustomUserGroupChange('1')
          this.hideCheckedOption = true;

        }
        else{

            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
          }

      }

      if(val?.owner && val?.verifier){
        this.docId = val.docId;
        if(val?.owner !== val?.verifier){
          this.checked1 = false;
          this.checked2 = true;
          this.checked3 = false;
          this.onCustomUserGroupChange('1')
          if(this.checked2){
            this.shareAcessDisable = true;
          }

          this.hideCheckedOption = true;
          if (!this.isCheckedAllUsers) {
            if(this.namechips.length > 0){
              this.checked1 = true;
              this.checked2 = false;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              this.hideCheckedOption = true;
            }
            else{
              if(this.namechips.length == 0){
                this.checked2 = false;
                this.checked1 = false;
                this.checked3 = true;
                this.onCustomUserGroupChange('3')
                this.hideCheckedOption = false;
              }else{
                this.checked2 = true;
                this.checked1 = false;
                this.checked3 = false;
              }

            }
          }
          else if (this.isCheckedAllUsers) {
            if(this.namechips.length > 0){
              this.checked1 = true;
              this.checked2 = false;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              this.hideCheckedOption = true;

            }
            else{

                this.checked2 = true;
                this.checked1 = false;
                this.checked3 = false;
              }

          }

            if(this.options.length >= 0){
              for(let i=0;i<=this.options.length-1;i++){
                if(this.options[i].emailID == val.verifier){
                  this.tempArray.push(this.options[i])

                  this.href = this.router.url;
                  if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
                    if (!this.isCheckedAllUsers) {
                      this.allTreeData.push(...this.tempArray);
                      for(let i=0;i<=this.allTreeData.length-1;i++){
                        if (!this.namechips.includes(this.allTreeData[i].nameAndGroup)) {
                          // ✅ only runs if value not in array
                          this.namechips.push(this.allTreeData[i].nameAndGroup);
                        }

                      }
                    }
                  }
                  else{
                    if (!this.isCheckedAllUsers) {

                      for(let i=0;i<=this.sharedWithUsers.length-1;i++){
                        this.namechips.push(this.sharedWithUsers[i].nameAndGroup);
                      }
                    }
                  }

                  this.onAlUserGroupList(this.allTreeData);
                }
              }
            }



          else{
            this.onAlUserGroupList(this.allTreeData);

          }
        }
      }


    }



  }
  valueMyqueue:any
  shareUsersMqueue(val?,id?) {
    console.log("val,id",val,id)
    this.singleDocGuid=val.docGuid
    this.valueMyqueue=val
    let myqueueFlag = id;
    this.disabledBtn = true;
    this.enableShareButton = false;
    this.NoOneSelected = false;
    this.flagValue = "0";
    this.sharedService.isShare.subscribe((flag:any)=>{
      this.isCheckedAllUsers = flag;
    })

     this.sharedService.threeDotValue.subscribe((res:any)=>{
      this.seletedData = res;
    })

    this.shareAcessDisable1 = true;

    val ? this.docId = val.docId : '';
  this.tempArray = [];

  // if(val?.bydefault != ''){
    if(val?.bydefault == 'bydefault'){
    }
    else{
      this.openSharePopModal();
    }
  // }

      this.VisibUnvisib = "hidden"
      this.showOverlaySpinner = false;
      this.showSharemodalSpin = false;

      this.blockUI.start('Loading...');
      setTimeout(() => {
        /** spinner ends after 5 seconds */

        this.VisibUnvisib = "visible"
        this.showSharemodalSpin = true;
      }, 10);

      if(this.seletedData == 'multi'){
        this.checked1 = false;
        this.checked2 = false;
        this.checked3 = false;
        this.namechips= [];
        this.tempArray= [];
        this.allTreeData = [];
        this.showSpinner = false;

      }

      else{

        this.enableButton = true;
        this.getShareUserCount(this.docId);
        if (this.isCheckedAllUsers) {
          this.checked2 = true;
          this.checked1 = false;
          this.checked3 = false;
          }
          else if(!this.isCheckedAllUsers && this.namechips.length > 0){

            this.checked2 = false;
            this.checked1 = true;
            this.checked3 = false;
            this.onCustomUserGroupChange('1')
            }
         else if (!this.isCheckedAllUsers && this.namechips.length == 0) {
            this.checked2 = false;
            this.checked1 = false;
            this.checked3 = true;
          }




        // else{
        //   this.onAlUserGroupList(this.allTreeData);

        // }
        // if(val?.owner && val?.verifier){
        //   this.docId = val.docId;
        //   if(val?.owner == val?.verifier){
        //     if(!this.checked3button){
        //     this.getShareUserCount(this.docId);
        //     }
        //   }
        // }
        // if (!this.isCheckedAllUsers && this.namechips.length == 0) {
        //   this.checked2 = false;
        //   this.checked1 = false;
        //   this.checked3 = true;
        // }
        // else if (this.isCheckedAllUsers && this.namechips.length == 0) {
        // this.checked2 = true;
        // this.checked1 = false;
        // this.checked3 = false;
        // }
        // else if(!this.isCheckedAllUsers && this.namechips.length > 0){
        // this.checked2 = false;
        // this.checked1 = true;
        // this.checked3 = false;
        // this.onCustomUserGroupChange('1')
        // if(this.checked2){
        //   this.shareAcessDisable = true;
        // }
        // }



      }



    }
    isDocketDoc=false;
    shareUsersMqueueDocIt(val?,id?,key?) {
      this.isDocketDoc = key;
      this.valueMyqueue=val
      let myqueueFlag = id;
      this.disabledBtn = true;
      this.enableShareButton = false;
      this.NoOneSelected = false;
      this.flagValue = "0";
      this.sharedService.isShare.subscribe((flag:any)=>{
        this.isCheckedAllUsers = flag;
      })

       this.sharedService.threeDotValue.subscribe((res:any)=>{
        this.seletedData = res;
      })

      this.shareAcessDisable1 = true;

      val ? this.docId = val.docId : '';
    this.tempArray = [];

    // if(val?.bydefault != ''){
      if(val?.bydefault == 'bydefault'){
      }
      else{
        this.openSharePopModal();
      }
    // }

        this.VisibUnvisib = "hidden"
        this.showOverlaySpinner = false;
        this.showSharemodalSpin = false;

        this.blockUI.start('Loading...');
        setTimeout(() => {
          /** spinner ends after 5 seconds */

          this.VisibUnvisib = "visible"
          this.showSharemodalSpin = true;
        }, 10);

        if(this.seletedData == 'multi'){
          this.checked1 = false;
          this.checked2 = false;
          this.checked3 = false;
          this.namechips= [];
          this.tempArray= [];
          this.allTreeData = [];
          this.showSpinner = false;

        }

        else{

          this.enableButton = true;
          this.getShareUserCount(this.docId);
          if (this.isCheckedAllUsers) {
            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
            }
            else if(!this.isCheckedAllUsers && this.namechips.length > 0){

              this.checked2 = false;
              this.checked1 = true;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              }
           else if (!this.isCheckedAllUsers && this.namechips.length == 0) {
              this.checked2 = false;
              this.checked1 = false;
              this.checked3 = true;
            }
        }



      }
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    getownerChip(){
      this.namechips=[];
      this.allTreeData = [];
      this.tempArray = [];
      let val =  this.valueMyqueue
      if(this.seletedData !== 'multi'){
        if(val?.owner && val?.verifier){
          this.docId = val.docId;
          if(val?.owner !== val?.verifier){
            this.hideCheckedOption = true;
            if(!this.checked3button){
              if(this.options.length >= 0){
                for(let i=0;i<=this.options.length-1;i++){
                  if(this.options[i].emailID == val.verifier){
                    if (! this.tempArray.includes(this.options[i])) {
                      // ✅ only runs if value not in array
                      this.tempArray.push(this.options[i])
                    }
                    this.tempArray.push(this.options[i])

                    this.href = this.router.url;
                    if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
                      this.allTreeData = [...new Set(this.tempArray.map(item => item))];
                      for(let i=0;i<=this.allTreeData.length-1;i++){

                        this.namechips.push(this.allTreeData[i].nameAndGroup);

                    }
                    }
                    this.onAlUserGroupList(this.allTreeData);
                  }
                }
              }
            }

          }
        }
      }
      if(this.seletedData == 'multi'){
        this.checked1 = false;
        this.checked2 = false;
        this.checked3 = false;
        this.namechips= [];
        this.tempArray= [];
        this.allTreeData = [];
      }

    }
  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
    if(this.checked){
      this.flagValue = "0"
    }else{
      this.flagValue = "1"
    }

  }
  selectedGuid = []
  getShareUserCount(arr?){
    console.log("arr is", arr);
    let postData;
    if (window.location.href.includes("/view/view-document")) {
        postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(arr.docId),
        "database": "get2doxMaster",
      }
    } else {
        postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(arr),
        "database": "get2doxMaster",
      }
    }
    this.pendingRequest = this.ownDoc.getSharedWithModalDataForView(postData).subscribe((response: any) => {
      if (response) {
        let pass =this.isCheckedAllUsers
        this.sharedWithUsers = response.data;
        this.setUserGroupArr = this.sharedWithUsers;
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(true);
        this.sharedService.selectedAccessEditor.next(response?.data);
        this.href = this.router.url;
        this.isCheckedAllUsers =pass
        if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
          if (this.isCheckedAllUsers) {
            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
            this.getownerChip()
            }
            else if(!this.isCheckedAllUsers && this.sharedWithUsers.length > 0){

              this.checked2 = false;
              this.checked1 = true;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              }
           else if (!this.isCheckedAllUsers && this.sharedWithUsers.length == 0) {
              this.checked2 = false;
              this.checked1 = false;
              this.checked3 = true;
            }


        }
        else{
          if (!this.isCheckedAllUsers) {
            if(this.namechips.length > 0){
              this.checked1 = true;
              this.checked2 = false;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              this.hideCheckedOption = true;
            }
            else{
              if(this.namechips.length == 0){
                this.checked2 = false;
                this.checked1 = false;
                this.checked3 = true;
                this.onCustomUserGroupChange('3')
                this.hideCheckedOption = false;
              }else{
                this.checked2 = true;
                this.checked1 = false;
                this.checked3 = false;
              }

            }
          }
          else if (this.isCheckedAllUsers) {
            if(this.namechips.length > 0){
              this.checked1 = true;
              this.checked2 = false;
              this.checked3 = false;
              this.onCustomUserGroupChange('1')
              this.hideCheckedOption = true;

            }
            else{

                this.checked2 = true;
                this.checked1 = false;
                this.checked3 = false;
              }

          }
        }
        // this.showSpinner = false;
      }

      else {
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(false);
        // this.showSpinner = false;
      }
    })

  }
  shareWithMutipleUsers(val?,guid?,tempdata?) {
    console.log("val,guid,tempdata",val,guid,tempdata)
    this.singleDocGuid = guid[0];
    this.disabledBtn = true;
    this.selectedDocId = val[0];
    this.NoOneSelected = false;
    this.enableShareButton = false;
    this.enableButton = false;
    this.sharedService.threeDotValue.subscribe((res:any)=>{
      this.seletedData = res;
    })

    this.shareAcessDisable1 = true;
    this.selectedGuid = guid
    val ? this.multipleDocIdList = val : '';
    this.tempArray = [];

  // if(val?.bydefault != ''){
    if(val?.bydefault == 'bydefault'){
    }
    else{
      this.openSharePopModal();
    }
  // }

      this.VisibUnvisib = "hidden"
      this.showOverlaySpinner = false;
      this.showSharemodalSpin = false;

      this.blockUI.start('Loading...');
      setTimeout(() => {
        /** spinner ends after 5 seconds */

        this.VisibUnvisib = "visible"
        this.showSharemodalSpin = true;
      }, 10);
    if(this.seletedData == 'multi'){
      this.checked1 = false;
      this.checked2 = false;
      this.checked3 = false;
      this.namechips= [];
      this.tempArray= [];
      this.allTreeData = [];
      this.hideCheckedOption = false;
      val ? this.multipleDocIdList = val : '';
    }
    else{
      val ? this.docId = val[0] : '';
      this.getShareUserCount(this.selectedDocId);
      if (!this.isCheckedAllUsers) {
        if(this.namechips.length > 0){
          this.checked1 = true;
          this.checked2 = false;
          this.checked3 = false;
          this.onCustomUserGroupChange('1')
          this.hideCheckedOption = true;
        }
        else{
          if(this.namechips.length == 0){
            this.checked2 = false;
            this.checked1 = false;
            this.checked3 = true;
            this.onCustomUserGroupChange('3')
            this.hideCheckedOption = false;
          }else{
            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
          }

        }
      }
      else if (this.isCheckedAllUsers) {
        if(this.namechips.length > 0){
          this.checked1 = true;
          this.checked2 = false;
          this.checked3 = false;
          this.onCustomUserGroupChange('1')
          this.hideCheckedOption = true;

        }
        else{

            this.checked2 = true;
            this.checked1 = false;
            this.checked3 = false;
          }
      }
        if(tempdata?.owner && tempdata?.verifier){
          this.docId = tempdata.docId;
          if(tempdata?.owner !== tempdata?.verifier){
            this.checked1 = false;
            this.checked2 = true;
            this.checked3 = false;
            this.onCustomUserGroupChange('1')
            if(this.checked2){
              this.shareAcessDisable = true;
            }
            this.hideCheckedOption = true;
            if (!this.isCheckedAllUsers) {
              if(this.namechips.length > 0){
                if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
                  this.checked1 = false;
                  this.checked2 = true;
                  this.checked3 = false;
                  this.onCustomUserGroupChange('1')
                  if(this.checked2){
                    this.shareAcessDisable = true;
                  }
                }else{
                  this.checked3 = false;
                  this.checked1 = true;
                  this.checked2 = false;
                  this.onCustomUserGroupChange('1')
                  if(this.checked2 ){
                    this.shareAcessDisable = true;
                  }
                  this.hideCheckedOption = true;
                }

              }
              else {
                if(this.namechips.length == 0){
                  this.href = this.router.url;
                  if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
                    this.checked1 = false;
                    this.checked2 = true;
                    this.checked3 = false;
                  }else{
                    this.checked1 = false;
                    this.checked2 = false;
                    this.checked3 = true;
                  }

                }else{
                  this.checked1 = false;
                  this.checked2 = true;
                  this.checked3 = false;
                }


              }

            }
            else if (this.isCheckedAllUsers) {
              if(this.namechips.length > 0){
                this.checked1 = false;
                this.checked2 = true;
                this.checked3 = false;
                this.onCustomUserGroupChange('1')
                if(this.checked2){
                  this.shareAcessDisable = true;
                }
                this.hideCheckedOption = true;
              }
              else{

                  this.checked2 = true;
                  this.checked3 = false;
                  this.checked1 = false;
                  if(this.checked2){
                    this.shareAcessDisable = true;
                  }


                }
            }
            if(this.options.length >= 0){
              for(let i=0;i<=this.options.length-1;i++){
                if(this.options[i].emailID == tempdata.verifier){
                  this.tempArray.push(this.options[i])

                  this.href = this.router.url;
                  if(this.href.includes('/verification/verify-document') || this.href.includes('/pages/documents/my-queue')){
                    if (!this.isCheckedAllUsers) {
                      this.allTreeData.push(...this.tempArray);
                      for(let i=0;i<=this.allTreeData.length-1;i++){
                        if (!this.namechips.includes(this.allTreeData[i].nameAndGroup)) {
                          // ✅ only runs if value not in array
                          this.namechips.push(this.allTreeData[i].nameAndGroup);
                        }
                      }
                    }
                  }
                  else{
                    if (!this.isCheckedAllUsers) {

                      for(let i=0;i<=this.sharedWithUsers.length-1;i++){
                        this.namechips.push(this.sharedWithUsers[i].nameAndGroup);
                      }
                    }
                  }

                  this.onAlUserGroupList(this.allTreeData);
                }
              }
            }

            else{
              this.onAlUserGroupList(this.allTreeData);

            }
          }
        }

    }






     }
   selectedOption = 'All Users'
   onNoUserChange(event:any){
    this.checked3button=true
    this.noshare = true;
    // this.namechips=[]
    this.NoOneSelected = true;
    this.shareAcessDisable1 = true;
    this.shareAcessDisable = true;
    this.flagValue = "0"
    this.everyOneSelected = false;
    this.changeUserAndGroup = false;
    this.checked = false;
    var elementID = event;
    if (elementID == "2") {
      this.isCheckedAllUsers = true;
      this.shareAcessDisable = true;
      this.isCheckedAllUser.emit(this.isCheckedAllUsers);
    }
  else if(elementID == "3"){
    this.isCheckedAllUsers = false;
    this.isSelectedNoUsers = true;
    this.flagValue = "0"
  }
  else {
    if (elementID == "1") {
      this.isCheckedAllUsers = false;
      this.isCheckedAllUser.emit(this.isCheckedAllUsers);
    }
  }
}
  onAllUserChange(event: any) {
    this.checked3button=false
    this.namechips=[]
    this.everyOneSelected = true;
    this.shareAcessDisable1 = true;
    this.NoOneSelected = false;
    this.changeUserAndGroup = false;
    this.checked = false;

    var elementID = event;

      if (elementID == "2") {
        this.isCheckedAllUsers = true;
        this.shareAcessDisable = true;

        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
      }

    else {
      if (elementID == "1") {
        this.isCheckedAllUsers = false;
        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
      }
      else{
        this.isSelectedNoUsers = true;
        this.isSelectedNoUser.emit(this.isSelectedNoUsers);
      }
    }


  }
  allValueSelected(){
    this.everyOneSelected = false;
    // this.shareAcessDisable = false;
  }
  onCustomUserGroupChange(event: any) {
    this.checked3button=false

    this.everyOneSelected = false;
    this.NoOneSelected = false;
    this.checked = false;
    this.changeUserAndGroup = true;
    var elementID = event;
    if(this.seletedData !== "multi"){
      this.flagValue = "0"
    }else{
      this.flagValue = "1"
    }


      if (elementID == "1") {
        // this.isCheckedAllUsers = false;
        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
        this.shareAcessDisable = false;
        this.shareAcessDisable1 = false;
      }

    else {
      if (elementID == "2") {
        this.isCheckedAllUsers = true;
        this.shareAcessDisable = true;
        this.isCheckedAllUser.emit(this.isCheckedAllUsers);
      }
      else{
        this.isSelectedNoUsers = true;
        this.isSelectedNoUser.emit(this.isSelectedNoUsers);
      }
    }
  }


  shareTemplateList() {
    this.items=[];
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }
    this.blockUI.start('Loading...');
    this.pendingRequest = this.data.shereTemplateListService(postData).subscribe((response: any) => {

      if (response.isSuccess && this.trees.length <= 0) {
        let OwnerEmail =localStorage.getItem('docOwnerEmailId')
        this.shareDetailsNew = response.data.filter((loc)=>loc.emailID != OwnerEmail )

        this.shareDetails = response.data.filter(
          loc => loc.emailID != this.ownerEmailID);

          this.items.push(...this.shareDetailsNew);
          this.items.sort((one, two) => (one.emailID.toLowerCase() < two.emailID.toLowerCase() ? -1 : 1));
          this.addeduserdata = of(this.items);
          this.options = this.items;

        for (let user of this.shareDetails) {
          user.id;
          user.categroy;
          user.emailID;
          user['custom'] = user.id;
          user['custom'] = user.categroy;
          if(user.categroy === 'GROUP'){
            user['custom'] = user.emailID;

          }else {
            user['custom'] = user.nameAndGroup;

          }
        }
        if (this.ownerEmailID !== this.verifierEmailID) {

          this.selectedShareAcess = this.verifierName;

        }
        else {
          this.selectedShareAcess = [];
        }

      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000)
    })
  }

  openShareModal(){
    console.log("this.sharedWithUsers",this.sharedWithUsers)
    if(this.sharedWithUsers.length>0 || this.isAllUserShare){
      this.openShareAccess(this.shareAccess);

    }else{
      this.isAllUserShare = false;
      this.openShareAccess(this.shareAccess);
    }
  }
  openSharePopModal(){
    console.log("sharedWithUsers",this.sharedWithUsers)
      this.openShareModalPopup(this.shareExitModal);

  }
  addchipsvalue = [];

  emailval(data){
    // console.log(this.namechips,'name')
    // console.log(this.options)
    if(this.seletedData == 'multi'){
      this.getSharedDiv = true;
    }
    this.changeChipState = true;
    // console.log(this.selectedMultiDocument)
    this.allTreeData=[];
    this.enableShareButton = true;
    this.allTreeData.push(...data);
    this.onAlUserGroupList(this.allTreeData);
  }
  onTagRemove(tagToRemove: NbTagComponent): void {
    this.namechips = this.namechips.filter(t => t !== tagToRemove.text);
    this.allTreeData = this.allTreeData.filter(a => a.nameAndGroup !== tagToRemove.text);
    this.onAlUserGroupList(this.allTreeData);
    this.chipRemove=true

  }
  openShareModalPopup(shareuse) {
    console.log("sharedWithUsers",this.sharedWithUsers)
    this.showSpinner = true;
    setTimeout(() => {
      if(this.checked1){
        this.shareAcessDisable=false
      }
      else{
        this.shareAcessDisable=true
      }
      this.showSpinner = false;

    }, 500);
    this.dialogRef = this.dialogService.open(shareuse, {
      closeOnBackdropClick: false,
      closeOnEsc: false,

    });

    this.shareTemplateList();

  }
  openShareAccess(shareAccess) {
    console.log("sharedWithUsers",this.sharedWithUsers)
    setTimeout(() => {
      if(this.checked1){
        this.shareAcessDisable=false
      }
      else{
        this.shareAcessDisable=true
      }
    }, 800);
    this.dialogRef = this.dialogService.open(shareAccess, {
      closeOnBackdropClick: false,
      closeOnEsc: false,

    });
    this.shareTemplateList();

  }

    /*
           *radio button selection value checking
      */

  checkradio(){

    if(this.radiobtnselect == true && this.radioselectusers == false){
       this.isCheckedAllUsers = true;
    }
    else if(this.radiobtnselect == false && this.radioselectusers == true){
      this.isCheckedAllUsers = false;
      this.isCheckedAllUser.emit(this.isCheckedAllUsers);
      this.shareAcessDisable = false;
    }
    else if(this.radiobtnselect == false && this.radioselectusers == false){
      this.isCheckedAllUsers = true;
    }
  }
  checkedButton(event){
   this.enableButton =true
  }
}
