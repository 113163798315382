/**
 * In there we define the variable for error message
 */

export const appProperties = {
    signin_form : {
      errormessageforblank: 'Please enter your email',
      errormsgnotautorized : 'You are not authorized to access this workspace',
      errormessageinvalidemail: "That's an invalid email",
      errormessagenotregister: "Looks like you’re not registered for this workspace yet",
      erroraccountdisableuser  : "Your account seems to be temporarily disabled",
      errorpassChangeError : "You have been requested to change your password.",
      errorpasswordnotblank : "Cannot be blank",
      errorpasswordincharacters : "Must contain atleast 6",
      errorpasswordincorrect : "The password you entered is incorrect",
      errormessageforspecialcharacteroronenglishsignin: "The characters you entered are not allowed"  
    },
    signup_form : {
      errormessageformincharacters: 'Must contain atleast 2 characters',
      errormessageformincharacterswsname: 'Must contain atleast 3 alphabets',
      errormessageforinvalidemail: "That's an invalid email",
      errormessagefornotauthorizedemail: "You are not authorized to access this workspace",
      errormessageforalredyexistingemail: "Looks like you're already registered for this workspace",
      errormessagefornotregisteredmail: "Looks like you're not registered for this workspace yet",
      errormessageforwrongpassword: "The code you entered is incorrect",
      errormessagefornegativesubdomain: "This URL is either blocked or contains unapproved words. Please contact support@dox2u.com for any assistance",
      errormessageforpassword: "Incomplete password",
      errormessageforpasswords: "Incomplete password",
      errorpasswordincorrectz: "The password you entered is incorrect",
      errormessageforaccountdisable: "Your account seems to be temporarily disabled",
      errormessageforspecialcharacteroronenglish: "The characters you entered are not allowed",
      errormessagefornotavalaiblewsurl: "Oops!! This URL is not available",
      errormessagefornotmatching: "Passwords do not match"
    },
  };