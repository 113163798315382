import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { SearchingComponent } from './searching/searching.component';
import { SharedModule } from '../../shared/shared.module'
import { NbButtonModule, NbCardModule, NbContextMenuModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbOptionModule, NbPopoverModule, NbProgressBarModule, NbRadioModule, NbSelectModule, NbSpinnerModule, NbTagModule, NbThemeModule, NbToastrModule, NbToggleModule, NbTooltipModule, NbWindowModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormatTooltipComponent } from './searching/format-tooltip/format-tooltip.component';
import { ThreeDotComponent } from './three-dot/three-dot.component';
import { DpagestooltipComponent } from './searching/dpagestooltip/dpagestooltip.component';
import { DoubleClickDirectiveModule } from '../../@theme/directives/double-click-directive.module';



@NgModule({
  declarations: [
    SearchingComponent,
    FormatTooltipComponent,
    ThreeDotComponent,
    DpagestooltipComponent,
  
  ],
  imports: [
    CommonModule,
    SearchRoutingModule,
    SharedModule,
    NbIconModule,
    NbToastrModule.forRoot(),
    NbSelectModule,
    NbCardModule,
    FormsModule,
    NbProgressBarModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbSpinnerModule,

    NbTooltipModule,
    NbPopoverModule,
    NbTagModule,
    NbInputModule,
    NbRadioModule ,
    NbThemeModule.forRoot({ name: "default" }),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbContextMenuModule,
    NbToggleModule,
    RouterModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    BsDropdownModule.forRoot(),

    NbListModule,
    NbOptionModule,
    NbFormFieldModule,
    BrowserAnimationsModule,
    
    NgSelectModule,
    NbSpinnerModule,
    DoubleClickDirectiveModule
  ]
})
export class SearchModule { }
