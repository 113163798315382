import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/observable/of';
import 'rxjs/Rx';
import { environment } from '../../../src/environments/environment';
import { ApiServiceService } from '../@core/api-service.service';

@Injectable({
  providedIn: 'root'
})
export class ApiIntegrationService {

  imageURL: any;
  setImageURL(imageNewURL: any) {
    throw new Error('Method not implemented.');
  }




  private data: any;
  private error: any;
  baseOrigin;
  baseURL;
  constructor(private http: HttpClient,private apiService:ApiServiceService) {
    this.baseOrigin = environment.baseOrigin;
    this.baseURL= environment.baseUrl;
  }

  searchUsers(data) {
   
    return this.apiService.post('/api/InvitesUser/Search', data);
  }
  deleteInvitationService(data) {
   
    return this.apiService.post('/api/MemberInvitation/Delete', data);
  }
  resendInvitationService(data) {
   
    return this.apiService.post('/api/MemberInvitation/Push', data);
  }
  getInvitedUserListService(data) {
   
    return this.apiService.post('/api/Workspace/InvitedByListing', data);
  }
  AccessWorkspace(data) {
   
    return this.apiService.post('/api/Workspace/AdminAuthorization', data);
  }

  getaccessrights(data) {
  
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }



 


 
  // ****************** User Enabled start here ***********************

  userEnabledService(data) {
    
    return this.apiService.post('/api/WstMember/Enable', data);
  }
  updateRight(data) {
  
    return this.apiService.post('/api/Role/UpdateRights', data);
  }
  roleinsert(data) {

    return this.apiService.post('/api/Notification/NotificationRoleInsert', data);
  }
  notifysend(data) {

    return this.apiService.post('/api/Notification/UserSendMessage', data);
  }

  roleTransfer(data) {
   
    return this.apiService.post('/api/Role/ForUserTransfer', data);
  }
  roleRename(data) {
   
    return this.apiService.post('/api/Role/Rename', data);
  }
  roleAdd(data) {
  
    return this.apiService.post('/api/Role/Add', data);
  }
  roleDefault(data) {
 
    return this.apiService.post('/api/Role/Default', data);
  }
  roleDelete(data) {
 
    return this.apiService.post('/api/Role/Delete', data);
  }
  roleCopy(data) {
   
    return this.apiService.post('/api/Role/Copy', data);
  }

  // *******************************************api for role mgmt end***************************


  // ****************** User Reset Password start here ***********************

  userResetPasswordService(data) {
 
    return this.apiService.post('/api/WstMember/EncapsulateReset', data);
  }
  // ****************** Set Default Group start here ***********************

  setDefaultGroupService(data) {
 
    return this.apiService.post('/api/Group/Default', data);
  }  // ****************** Get roles list start here ***********************


  // ****************** User Group Change start here ***********************
  // ****************** Set Default Group start here ***********************

  renameGroupService(data) {
  
    return this.apiService.post('/api/Group/Rename', data);
  }

  // ****************** Delete Group start here ***********************

  deleteGroupService(data) {
  
    return this.apiService.post('/api/Group/Delete', data);
  }

  shereTemplateListService(data) {
   
    return this.apiService.post('/api/DocumentVerification/GetUsersAndGroupsList', data);
  }
  saveShareTemplateService(data) {
   
    return this.apiService.post('/api/DocumentVerification/SaveDocUsersAndGroup', data);
  }
  getRolesListService(data) {
   
    return this.apiService.post('/api/Role/Get', data);
  }
   // ****************** Get roles list start here ***********************




  userGroupChangeService(data) {
  
    
    return this.apiService.post('/api/GeneralSettings/WsNameAndUrl', data);
  }





  userRoleChangeService(data) {
    
    return this.apiService.post('/api/Role/Change', data);
  }
  // ****************** User Deleted start here ***********************

  userDeletedService(data) {
  
    return this.apiService.post('/api/WstMember/Delete', data);
  }
  //************************ API for add notify insert API Get End ************************

  notifyinsert(data) {

    return this.apiService.post('/api/GetNotificationUser/NotificationInsert', data);
  }

  // ****************** User Disabled start here ***********************

  userDisabledService(data) {
   
    return this.apiService.post('/api/WstMember/Disable', data);
  }
  // ****************** Users count start here ***********************
  // ****************** Users count start here ***********************

  usersCountService(data) {
  
    return this.apiService.post('/api/Workspace/GetUserCount', data);
  }
  groupsCountService(data) {
  
    return this.apiService.post('/api/Group/Get', data);
  }

  usersInviteService(data) {
   
    return this.apiService.post('/api/MemberInvitation/Push', data);
  }
  // ****************** Get roles list start here ***********************

  //   //************************ API for checking disabled user list show API Get End ************************

  getverifierdisable(data) {

   
    return this.apiService.post('/api/AllUsers/CheckDocuments', data);
  }
 
  // ****************** Status Dropdown bind start here ***********************

  public statusDropdown = [{ statusName: 'Active' }, { statusName: 'Disabled' }, { statusName: 'Reset Password' }];
  bindStatus() {
    return this.statusDropdown;
  }

  // ****************** Set Default Group start here ***********************

  addGroupService(data) {
    
    return this.apiService.post('/api/Group/Add', data);
  }
 

  updateTrashService(data) {
   
    return this.apiService.post('/api/Trash/UpdateDocTrashStatus', data);
  }
 
  public allDocService(data) {
    
    return this.apiService.post('/api/DocumentVerification/AllDocList', data);
  }


 
  getTrashDocListData(data) {

    return this.apiService.post('/api/Trash/TrashDocList', data);
  }

 

  // ****************** UploadRightsUserList end here ****************************



  getStatusService(data) {
  
    return this.apiService.get('/api/DocumentVerification/DocStatusList');
  }


 
 
 


  //********************************************transfer workspace ownership api start *******************
  transferWSOwnershipService(data) {
   
    return this.apiService.post('/api/WsManagement/OwnershipTransfer', data);
  }
  //********************************************transfer workspace ownership api end *******************
  //  *********************************************owned by me start***************************************
  getOwnedByMeDocListData(data) {

 
    return this.apiService.post('/api/OwnedByMe/OwnedByMeDocList', data);
  }

  transferOwnershipService(data) {
  
    return this.apiService.post('/api/OwnedByMe/TransferDocOwner', data);
  }

  deleteAccessMultipleService(data) {
  
    return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
  }
  //*************************************************owned by me end******************************** */

  
  templateDetailsService(data) {
   
    return this.apiService.post('/api/DocumentVerification/TemplateDetailsById', data);
  }
  public saveTemplateEditorService(data) {
  
    return this.apiService.post('/api/DocumentVerification/InsertTemplate', data);
  }
  //***********************************************template editor api end ********************************************* */
  // ****************** Enabled users list start here *******************

  enabledUsersListService(data) {
  
    return this.apiService.post('/api/Workspace/EnabledUserList', data);
  }
  // ****************** Enabled users list end here *******************
 
  getDocumentRawData(filePath) {
    const options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',

        'Content-Transfer-Encoding': 'Binary',
        'Content-disposition': 'inline',
      }),

      responseType: 'blob',
    };

    const url = this.baseURL + `/${filePath}`; 
    return this.http.get<Blob>(url, options);
  }
  UpdateAuditlogData(data) {
  
    return this.apiService.post('/api/OwnedByMe/DocPrintAuditLog', data);
  }
  allUsersService(data) {
  
    return this.apiService.post('/api/AllUsers/ForAuditLog', data);
  }
  auditActivityService(data) {
    
    return this.apiService.post('/api/Workspace/AuditLogActivity', data);
  }
  auditLogDownloadService(data) {
   
    return this.apiService.post('/api/Workspace/DownloadAuditLogs', data);
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['category', 'activity', 'logMessage', 'createdDtm']);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  validateOwnerCredentialService(data) {
  
    return this.apiService.post('/api/Workspace/AdminAuthorization', data);
  }
 
  disableWorkspaceService(data) {
 
    return this.apiService.post('/api/Workspace/DisableWorkspace', data);
  }

  enableWorkspaceService(data) {
   
    return this.apiService.post('/api/Workspace/EnableWorkspace', data);
  }

  getfeaturews() {
   
    return this.apiService.get('/api/GetFeatureFlaging/GetFeatureFlaging');
  }

 
}
