import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  UpdateAuditlogData(requestBody: { docId: any; wsoid: any; originalFileName: any; createdBy: any; }) {
    throw new Error('Method not implemented.');
  }
  getDocumentRawData(filePath: string) {
    throw new Error('Method not implemented.');
  }

  public showEdit:boolean;
  public showDownload:boolean;
  public showDelete:boolean=true;
  public showView: boolean;
  public showPrint: boolean;
  baseOrigin = environment.baseOrigin;
  baseUrl= environment.baseUrl;
  paymentBaseUrl = environment.paymentBaseUrl
  baseUrl97= environment.baseURL97;
  prepaid = environment.prepaid;
  BillingbaseUrl = environment.paymentBaseUrl;
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin}) };

  constructor(private http:HttpClient) { }
  
  post(url,body){
      return this.http.post(`${this.baseUrl}${url}`, body).pipe(
        retry(3)
      );
    
  }
  post85(url,body){
    return this.http.post(`${this.paymentBaseUrl}${url}`, body).pipe(
      retry(3)
    );
  }
  // post85(url,body){
  //   return this.http.post(`https://dox2uuat.com:85${url}`, body).pipe(
  //     retry(3)
  //   );
  // }

  post97(url,body){
    return this.http.post(`${this.baseUrl97}${url}`, body).pipe(
      retry(3)
    );
  }
  // zpost(url,body){
  //   return this.http.post(`${environment.zorperUrl}${url}`, body).pipe(
  //     retry(3)
  //   );
  // }
  postPlan(url ,body){
    return this.http.post(`${this.BillingbaseUrl}${url}`, body).pipe(
      retry(3)
    );
  }
  Billingpost(url, body) {
    return this.http.post(`${this.prepaid}${url}`, body).pipe(
      retry(3)
    );   
  }

  get(url){
    return this.http.get(`${this.baseUrl}${url}`).pipe(
      retry(3)
    );
  }

  postDataWithParams(url,body,params){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }),params:params };
    return this.http.post(`${this.baseUrl}${url}`, {},httpOptions).pipe(
      retry(3)
    );
  }




    // ****************** Owner Details end here *******************

}
