import { Observable } from 'rxjs';
/**
      * export for statsBarData
 */
export abstract class StatsBarData {
  /**
      * declaration for statsBarData
    */
  abstract getStatsBarData(statsBarDatavalue): Observable<string[]>;
}
