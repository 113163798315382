
<!--/*************************************************************************
 /*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2021] - [2022] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE: All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any. The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Searching
///  Description: Search in the workspace
///  Author     : Akansha Aggarwal
///  Created On : 23-05-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///----------------------------------------------------------------- -->

<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">


  <!---------------------------- Breadcrumbs and Activity Details invoke ---------------------------->

<div class="globalparent" style="    margin: 16px 26px 0px !important;">

<!------------------------ Heading Card Starts------------------------------>
<div>
     <nb-card class="mb-3 pt-3 pb-3 pr-3 pl-4 HeadCard">
      <nb-card-body class="p-0 HeadCardBody">
        <div class="row" >
         <div class="col-6 height48">
           <div>
            <h5 class="headline-5  mb-0 d-flex align-items-center docshead" ><span nbTooltipTrigger="hover"  nbTooltip= "View all the docs that are under your ownership and control in your workspace" nbTooltipPlacement="right" nbTooltipStatus="info">Search</span>

            </h5>
            <p class="caption text-hint mb-0 captext">  {{documentCount}} document(s)</p>
            </div>
            <div  class="searchFilter-section  background-basic-color-2 col-12" >
              <div class="row m-0">
                 <!------------------------------  SEARCH & Filter section  ------------------------- -->





          </div>
            </div>
          </div>

          <div class="col-6 d-flex justify-content-end align-items-center">
            <!----------------------------------- multiDocs menu when user click  --------------------->
            <div class="click-menu d-flex  multiDocsMenu mr-2" >

              <button nbButton ghost class=" changecolor viewclass " [ngClass]="previewPanelOpen? 'notConvertblue':'Convertblue'" *ngIf="previewValidate()"
              nbTooltip="Preview" nbTooltipStatus="info" nbTooltipPlacement="bottom" (click)="openPreviewPanel(previewChild)">
              <nb-icon icon="eye-outline" class="changecolor"  ></nb-icon>
            </button>

              <button nbButton ghost  *ngIf="selectedDocs.length > 0 && showDownload()" nbTooltip="Download"
                nbTooltipStatus="info" nbTooltipPlacement="bottom"  class="changecolor viewclass" [disabled]="isDownloadModalOperationInProgress" (click)="disabledownload();downloadAccessMultiDocs('downcolor')">
              <nb-icon  icon="download-outline" class="cursor-pointer changecolor" ></nb-icon></button>
              <button nbButton ghost class="mr-2 changecolor viewclass" *ngIf="shareAccessValidate()" (click)="openShareCommonModal(shareChild)"   nbTooltip="Share" nbTooltipStatus="info" nbTooltipPlacement="bottom">
                <nb-icon icon="share-outline" class="changecolor"> </nb-icon>
              </button>
              <button nbButton ghost class="mr-2 changecolor viewclass" *ngIf="selectedDocs.length >0 && GuestFlag =='True' && this.selectedDocs[0].docShareStatus == 'Access'&& isGuestAccessInRole" nbTooltip="Share with Guests"
              nbTooltipStatus="info" nbTooltipPlacement="bottom" [disabled]="isGuestModalOperationInProgress" (click)="disableGuest();GuestModalShare()">
                <nb-icon icon="people-outline" class="changecolor"> </nb-icon>
              </button>

              <button nbButton ghost *ngIf="selectedDocs.length > 0 && showTransferOwnerShip()" nbTooltip="Transfer Ownership"
                nbTooltipStatus="info" nbTooltipPlacement="bottom" class="mr-2  changecolor viewclass" (click)="openModal(transferOwnership,false)">
              <nb-icon  icon="swap-outline" class=" cursor-pointer changecolor" ></nb-icon> </button>

              <button nbButton ghost   *ngIf="selectedDocs.length > 0 && showDeleteUser()" nbTooltip="Delete" [disabled]="isDeleteModalOperationInProgress"
                nbTooltipStatus="info" nbTooltipPlacement="bottom" class=" mr-2 changecolor viewclass" (click)="disabledelete();openDeleteModalGlobal()">
              <nb-icon icon="trash-2-outline" class=" changecolor  cursor-pointer" ></nb-icon></button>



            </div>
               <!----------------------- multidocs menu when user click end set  ----------------------------->


            <div  class="activity-section d-flex  align-items-center ">
              <div *ngIf="selectedDocs.length > 0 && showDeleteUser()" class="line"></div>
              <span class="sortedtext ml-2">
                Sort By :
              </span>
              <nb-select size="tiny" class="sortingtype" [(selected)]="selectedSortItem" (click)="GetValue()">

                <nb-option value="Relevance, prioritized by Name" class="subtitle padding1216"  (click)="nameBase()">Relevance, prioritized by Name</nb-option>
                <nb-option *ngIf="relevanceByContent == 'True'" value="Relevance, prioritized by Content"  (click)="contentBase()" class="subtitle padding1216">Relevance, prioritized by Content</nb-option>
                <nb-option value="Added Date : Oldest to Newest" class="subtitle padding1216" (click)="dateAsc('asc')">Added Date : Oldest to Newest</nb-option>
                <nb-option value="Added Date : Newest to Oldest" class="subtitle padding1216" (click)="dateAsc('dsc')">Added Date : Newest to Oldest</nb-option>
                <nb-option value="Alphabetical : A-Z" class="subtitle padding1216" (click)="alphaAsc('asc')">Alphabetical : A-Z</nb-option>
                <nb-option value="Alphabetical : Z-A" class="subtitle padding1216" (click)="alphaAsc('dsc')">Alphabetical : Z-A</nb-option>
              </nb-select>
              <!-- <button nbButton ghost class="filterfunnel viewclass"  nbTooltip="Search & filter"
              nbTooltipStatus="info" nbTooltipPlacement="bottom">
              <nb-icon  icon="search-outline" class="cursor-pointer"></nb-icon>
            </button> -->

              <button nbButton ghost class="detailSection viewclass" (click)="detailSection()" nbTooltip="View details" nbTooltipStatus="info" nbTooltipPlacement="bottom" [ngClass]="{'disableCursorEventForProcessing' : selectedDocs.length>1}"> <nb-icon icon="file-text-outline" class="cursor-pointer "></nb-icon></button>
              <button *ngIf="shareAccessTags()" (click)= "tagVerify()"  nbButton ghost class="detailSection viewclass" nbTooltip="Tag" nbTooltipStatus="info"
              nbTooltipPlacement="bottom">
              <nb-icon icon="pricetags-outline" class="cursor-pointer  "></nb-icon>
            </button>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
<!-------------------------- Change request in the card column and width w.r.t UI ----------------------------->

    <div class="row m-0 searchBar ">
      <div class="searchBarfirstchild pl-0" [ngClass]="hideDetail && tagPanelOpen && previewPanelOpen  ? 'col-12 pr-0' : 'col-xxxl-8  col-xxl-7 col-xl-7 col-l-7 col-md-7 col-sm-7  pr-0'" >
    <nb-card  class="mb-0 allwsCard pl-0 overflowX-hidden searchBarfirstchildcard" >
      <nb-card-body class="p-0 overflow-hidden" [ngClass]="isDetailOpen ? 'max-width-1550' : ''">
        <div class="ownedClass" *ngIf="searchSpinnerNew">
          <ngx-second-loader></ngx-second-loader>
         </div>
        <div  *ngIf="listBg">
          <div class="row m-0" [ngClass]="searchSpinnerNew ? 'parentgrid' : ''">
        <div class="col-md-10 d-flex itemsperPage pt-3 pl-0 pr-0" *ngIf="!docSelectedByUser">
          <p class="mb-0 pr-2  paragraph-1 text-basic ">Items per page :</p>
          <nb-select size="tiny" class="pagesize" (selectedChange)="onPageSizeChanged($event)" [(selected)]="selectedItem">

          <nb-option value="50">50</nb-option>
          <nb-option value="100">100</nb-option>

          <nb-option value="200">200</nb-option>
          <nb-option value="500">500</nb-option>
        </nb-select>
        </div>

        <div class="col-md-10 d-flex itemsperPage docselected pt-3 pl-0 pr-0" *ngIf="docSelectedByUser">
          <p class="mb-0 pr-2  paragraph-1 text-basic ">{{docSelected}} documents selected <span *ngIf="docSharedTrue"> ({{docShared}} not accessible)</span></p>
          
        </div>
    </div>




      <!------------------------------------------------ag-grid start-------------------------------------------- -->



        <div style="margin-top: 19px;display: flex;justify-content: flex-end;padding-right: 30px;">

          <nb-icon class="cursor-pointer searchiconarrow"  (click)="onBtFirst()" icon="arrowhead-left-outline"></nb-icon>
          <nb-icon class="cursor-pointer searchiconarrow"  (click)="onBtPrevious()" icon="arrow-ios-back-outline"></nb-icon>
          <span class="value pr-1" id="lbCurrentPage"></span>
           of
          <span class="pl-1">
          {{totalPagesCount}}
          </span>
            <nb-icon class="cursor-pointer searchiconarrow" (click)="onBtNext()" icon="arrow-ios-forward-outline"></nb-icon>
          <nb-icon class="cursor-pointer searchiconarrow"   (click)="onBtLast()" icon="arrowhead-right-outline"></nb-icon>

      </div>

        <div hidden>
            <span class="label">Last Page Found:</span>
            <span class="value" id="lbLastPageFound"></span>
            <span class="label">Page Size:</span>
            <span class="value" id="lbPageSize"></span>
            <span class="label">Total Pages:</span>
            <span class="value" id="lbTotalPages"></span>
            <span class="label">Current Page:</span>
            <span class="value" id="lbCurrentPage">-</span>

        </div>

      <div class="ag-theme-balham " [ngClass]="searchSpinnerNew ? 'parentgrid' : ''">
        <div class="row" >
           <div class="pt-3" [ngClass]="hideDetail ? 'col-12' : 'col-12'">
            <ag-grid-angular #agGrid  class="ag-theme-balham "
            [rowData]="rowData" 
            [pagination]="false" paginationPageSize = "50"
              [columnDefs]="columnDefs"
              (gridReady)="onGridReady($event)"
              [suppressRowDeselection]="true"
              (selectionChanged)="onSelectionChanged($event)"
              (click)="getSelectedRowData()"
               [overlayNoRowsTemplate]="noRowsTemplate"
               [enableBrowserTooltips]="true"
               [tooltipShowDelay]="tooltipShowDelay"
               domLayout="autoHeight"
               [rowSelection]= "'multiple'" [rowMultiSelectWithClick]="false"
              (firstDataRendered)="onFirstDataRendered($event)"
              (cellClicked)="onCellClicked($event)"
               [getRowClass]="getRowClass"
               headerHeight="48"
               [suppressMenu] = true
               [suppressColumnVirtualisation]="true"
               (paginationChanged)="onPaginationChanged($event)"
               [rowHeight]="48"
               (rowDoubleClicked)="onRowDoubleClicked($event)" (bodyScroll)="hideThreeDotComp()" >
            </ag-grid-angular>
           </div>
        </div>


      </div>

        <!--------------------------------------------------------ag grid end----------------------------------------------- -->
        </div>

        <ngx-guest-user-modal></ngx-guest-user-modal>
        <ngx-shared-access #shareChild [ownerEmailID]="ownerEmailID" [allUserLength]="allUserLength"  (isSelectNoUser)="isSelectNoUser($event)"
          [verifierEmailID]="verifierEmailID" [sharedWithUsers]="sharedWithUsers" [isAllUserShare]="isAllUserShare"
          (isSelectedAllUser)="isSelectedAllUser($event)" (sharedGroupCount)="sharedGroupCount($event)"
          (isCheckedAllUser)="isCheckedAllUser($event)" (sharedGroup)="sharedGroup($event)" (isUserGrp)="isUserGrp($event)">
        </ngx-shared-access>

        <ngx-print-document [selecteddDocument]="selectedDocs" [numberofDocsSelected]="numberofDocsSelected"
        [rightUserList]="rightUserList" [selectedFileNames]="selectDocumentName" [doclist]="doclist" ></ngx-print-document>



  <!--SHARE MODAL fINISH-->
      </nb-card-body>

    </nb-card>
  </div>
  <div class="col-xxxl-4  col-xxl-5 col-xl-5 col-l-5 col-md-5 col-sm-5 pr-0" >
    <div [hidden]="hideDetail">
      <ngx-detail-and-activity></ngx-detail-and-activity>
    </div>
    <div [hidden]="tagPanelOpen">
      <ngx-bulk-tag></ngx-bulk-tag>
    </div>
    <div [hidden]="previewPanelOpen">
      <ngx-doc-preview></ngx-doc-preview>
    </div>
  </div>

  <!-- Download Invoke -->
  <ngx-download [rightUserList]="rightUserList" [selectedFileNames]="selectedFileNames"
  [numberofDocsSelected]="numberofDocsSelected" [doclist]="doclist" [selectedDocs]="selectedDocs"></ngx-download>
    <!-- Delete modal invoke-->
    <ngx-delete-docs [rightUserList]="rightUserList" [selectedFileNames]="selectedFileNames"
    [numberofDocsSelected]="numberofDocsSelected" [doclist]="doclistDelete" [selectedDocs]="selectedDocs"
    (isDeleted)='resetSelected($event)'
    >
    </ngx-delete-docs>



    </div>

  </div>

      <!-- Delete modal invoke-->
      <ngx-delete-docs [rightUserList]="rightUserList" [selectedFileNames]="selectedFileNames"
      [numberofDocsSelected]="numberofDocsSelected" [doclist]="doclistDelete" [selectedDocs]="selectedDocs"
      (isDeleted)='resetSelected($event)'
      >
      </ngx-delete-docs>





  <div>
    <ng-template #miniprofile let-data let-ref="miniprofile">
      <ngx-mini-profile [showdata]="showdata"></ngx-mini-profile>
    </ng-template>
  </div>
  <ng-template #wsOutSync let-data let-ref="dialogRef">
    <nb-card style="max-width: 40em;">
        <nb-card-header>  <div class="justify-content-between d-flex">
          <h6 class="subtitle text-primary mb-0"> Workspace out of sync </h6>
        </div>
        </nb-card-header>
        <nb-card-body>
            <p class="paragraph text-basic">Your workspace went out of sync. There are some changes made which are not synchronized.
              Please reload this page before you continue.</p>
            <p class="mt-2 paragraph text-basic">This action cannot be completed as the document has been
                Deleted.
            </p>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton status="primary" class="textbutton" size="small" (click)="reloadScreen();dialogRef.close()">RELOAD</button>
        </nb-card-footer>
    </nb-card>
  </ng-template>


  <ng-template #PermanentDeletedoc let-data let-ref="dialogRef">
    <nb-card class="invitemodal">
      <nb-card-header>
        <div class="justify-content-between d-flex">
          <h6 class="subtitle text-basic">
            Permanently delete documents
          </h6>
          <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
      </div>
      </nb-card-header>
      <nb-card-body>
        <p class="paragraph text-basic" >As There is no space in Trash, so Files will automatically get Permanent Deleted</p>
        <p class="mt-2 paragraph text-basic">{{selectedDocs?.length}} documents selected
        </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="invite mr-3" (click)="ref.close(); permanentDeleteDocs(); showSpinner = true;" size="small">CONFIRM</button>

      <button nbButton (click)="ref.close();" outline status="primary" size="small">CANCEL</button>

    </nb-card-footer>
 </nb-card>

  </ng-template>

  <ng-template #transferOwnership let-data let-ref="dialogRef">
    <nb-card class="invitemodal">
      <nb-card-header>
        <div class="justify-content-between d-flex">
          <h6 class="subtitle text-basic"> Transfer document ownership
          </h6>

          </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row m-0">
          <div class="col-12 pl-0">
          <p class="paragraph text-basic">Choose another user to be the owner of the document.</p>
          </div>
          <div class="col-8 pl-0 m-0">
            <ng-select [items]="ownerTransferUsers"
            placeholder="Select workspace member"
            bindLabel="displayName"
            appendTo="body"
            [closeOnSelect]="true"
            bindValue="displayName"
            (change)="onDocumentOwner($event)"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="selectedDocumentOwner">
             <ng-template ng-option-tmp let-item="item" let-index="index">
                  <p class="subtitle text-basic mb-0" title={{item.emailId}} >{{item.displayName}}</p>
              </ng-template>
            </ng-select>
          </div>

        </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" [disabled]="selectedDocumentOwner === null" class="invite mr-3"  (click)="confirmOwnerShipTransfer(); showSpinner = true;" size="small">TRANSFER OWNERSHIP</button>
      <button nbButton (click)="closeModal()"  status="control" size="small">CANCEL</button>

    </nb-card-footer>
   </nb-card>
  </ng-template>








