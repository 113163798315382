import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-format-tooltip',
  templateUrl: './format-tooltip.component.html',
  styleUrls: ['./format-tooltip.component.scss']
})
export class FormatTooltipComponent implements OnInit {

  constructor() {
    this.createdBy = localStorage.getItem('localloginId')
   }

/**
 * createdBy var
 */
  createdBy;

  /**
 * format tooltip oninit
 */
  ngOnInit(): void {
   
  }
  /**
 * data var
 */
  data: any;
  /**
 * tooltipData var
 */
  tooltipData: any;
  /**
 * formatData var
 */
  formatData: any;
 /**
 * shareddata var
 */
  shareddata = false;

   /**
 * aginit for format tooltip 
 */
   agInit(params:any){
     this.data=params?.data;
    // this.tooltipData=this?.data?.format.toLowerCase();
     let shareingdoc = this.data.docShareStatus;
     if(shareingdoc == 'Not Access' && this.createdBy !== this.data.documentowner){
      this.shareddata = false;
     }
     else{
      this.shareddata = true;
      this.tooltipData=this?.data?.format;
     console.log("tooltipShared", this.tooltipData);
     }
     if(this.data.format.toLowerCase() == 'txt' || this.data.format.toLowerCase() == 'odt' || this.data.format.toLowerCase() == 'rtf' || this.data.format.toLowerCase() == 'doc' || this.data.format.toLowerCase() == 'docx'){
       this.formatData = 'Document';
     }
     else if(this.data.format.toLowerCase() == 'pdf'){
       this.formatData = 'PDF';
     }
     else if(this.data.format.toLowerCase() == 'gif' || this.data.format.toLowerCase() == 'jpeg' || 
     this.data.format.toLowerCase() == 'jpg' || this.data.format.toLowerCase() == 'png'
      || this.data.format.toLowerCase() == 'bmp'   || this.data.format.toLowerCase() == 'tiff' || this.data.format.toLowerCase() == 'tif' ){
      this.formatData = 'Image';
    }
  
   }

}
