import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
    /**
    * injecting root
    */
@Injectable({
  providedIn: 'root'
}) 
   /**
    * exporting class dashboard
    */
export class DashboardService {
     /**
    * define variable
    */
  menuRights: any;
 
   /**
    *initialize constructor
    */
  constructor(private apiService:ApiServiceService) { }
     /**
    * setRights
    */
  public setRights(data){
    this.menuRights = data;
  }
     /**
    * getRights
    */
  public getRights() {
   return this.menuRights;
  }
   /**
    * getSharedWithModalDataForView
    */
  getSharedWithModalDataForView(data) {
    return this.apiService.post('/api/GetUserDetailWithGroup/GetUserDetailWithGroup', data);
  }
     /**
    * viewUserWiseRoleAccess
    */
  viewUserWiseRoleAccess(data) {
    return this.apiService.post('/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }
     /**
    * menuRightsService
    */
  menuRightsService(data) {
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }
     /**
    * getWsFeatureflagging
    */
  getWsFeatureflagging(data) {
    return this.apiService.post('/api/Workspace/GetWSFeatureFlagging', data);
  }
   /**
    * leftMenuListService
    */
  leftMenuListService(data) {
    return this.apiService.post('/api/Workspace/GetMenuList', data);
  }
   /**
    * DocsOwned
    */
 //Docs owned APi for Dashbo
 DocsOwned(data) {
  return this.apiService.post('/api/GetDashboardOwnerByMe/GetDashboardOwnerByMe', data);
}
   /**
    * DocsShared
    */

  //docs shared api
  DocsShared(data) {
   return this.apiService.post ('/api/GetDashboardShareByMe/GetDashboardShareByMe', data);
  }
   /**
    * DocsUpload
    */
   //Docs in upload api

   DocsUpload(data) {
   return this.apiService.post('/api/GetDashboardDocUploaded/GetDashboardDocUploaded', data);
  }
     /**
    * SizeTrashStatusService
    */
  SizeTrashStatusService(data) {
    return this.apiService.post('/api/Trash/TrashSizeCount', data);
  }
     /**
    * myDocListService
    */
  
  public myDocListService(data) {
    return this.apiService.post('/api/GetDashboardSlider/GetDashboardSlider ', data);
  }
   /**
    * dashboardcards
    */
  /**
   * 
   * @param data target method is used to get Dashboard Count
   * @returns  target method will return count values for user ,recently Uploaded ,d-pages and docs in Upload
   */
dashboardcards(data) {
   return this.apiService.post('/api/GetDashboardCount/GetDashboardCount', data);
  }
   /**
   * 
   * @param data target method is used to getLast Access Details
   * @returns  target method will return last Access details
   */
     /**
    * lastAccessTab
    */
  lastAccessTab(data){
    return this.apiService.post('/api/GetDashboardSlider/GetDashboardSlider',data);
  }
     /**
    * commontab
    */

   /**
   * 
   * @param data target method is used to  get response for OwnByMe , docs in Upload and shared with me
   * @returns  target method will return last Access details
   */
         /**
    * get_lastAcess_share_details
    */
          get_lastAcess_share_details(data){
            return this.apiService.post('/api/GetUserRoleAccess/UserWiseRoleAccess',data);
          }
  commontab(data){
    return this.apiService.post('/api/GetDashboardDocUploaded/GetallDashboardData',data);
  }


}
