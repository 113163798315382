import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Output,
  Input,
  TemplateRef,
  HostBinding,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Optional,
} from "@angular/core";
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import { SharedServiceService } from "../../shared-service.service";
import { Router } from "@angular/router";
import { SharedService } from "../../../app/@core/utils/shared.service";
import { Subscription } from "rxjs";
import { DeleteDocsService } from "../../@core/utils/delete-docs.service";
import { CookieService } from "ngx-cookie-service";
import { SignInService } from "../../@core/sign-in.service";

@Component({
  selector: "ngx-delete-docs",
  templateUrl: "./delete-docs.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./delete-docs.component.scss"],
})
export class DeleteDocsComponent implements OnInit {
  confirmDeleteRef: NbDialogRef<any>;
  wsOutOfSyncRef: NbDialogRef<any>;
  deleteNotauthRef: NbDialogRef<any>;
  sendmessage: any;
  documentOwnerEmailMulti: any;
  insertmessgae: string;
  documentOwnerEmail: any;
  downloadModifiedNameFileName: string;
  filesCount: string;
  nonOwnerDocuments: any;
  numberOfSelectedDocs: number;
  externalAssociationFound: boolean = false;
  externalAssociationFoundMul: boolean =false;
  associatedDocumentsName: string[] = [];

  checkAccess() {
    throw new Error("Method not implemented.");
  }
  @ViewChild("confirmDelete") confirmDelete: TemplateRef<any>;
  @ViewChild("wsOutOfSync") wsOutOfSync: TemplateRef<any>;

  WsdisplayName: string;
  workSpaceId: string;
  createdBy: any;
  createdName: any;
  href: any;
  wsName: string;
  docOwnerEmailId: string;
  docOwnerName: string;
  localTime: string;
  subscription: Subscription;
  subscriptions: Subscription;
  role: any;
  pendingRequest: Subscription;
  chkdownloadprint: string;
  chkeditrights: string;
  checkDeleteRights: string;
  downloadprint: any;
  editrights: any;
  deleteRights: any;
  isSomeOwned: any;
  isSomeShared: any;
  isNoneOwned: any;
  isNoneShared: any;
  defaultDelText: boolean = true;
  getguid;

  constructor(
    private cookieService: CookieService,
    private signInService: SignInService,
    @Optional() private dialogRef: NbDialogRef<any>,
    private dialogService: NbDialogService,
    private sharedservice: SharedServiceService,
    private toastrService: NbToastrService,
    private router: Router,
    private sharedSrvice: SharedService,
    private data: DeleteDocsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.href = this.router.url;
    this.workSpaceId = localStorage.getItem("localwsId");
    this.createdBy = localStorage.getItem("localloginId");
    this.createdName = localStorage.getItem("WsdisplayName")
      ? localStorage.getItem("WsdisplayName")
      : "";
    this.wsName = localStorage.getItem("WorkSpaceName");
    this.docOwnerEmailId = localStorage.getItem("docOwnerEmailId")
      ? localStorage.getItem("docOwnerEmailId")
      : "";
    this.WsdisplayName = localStorage.getItem("WsdisplayName")
      ? localStorage.getItem("WsdisplayName")
      : "";
    this.chkdownloadprint = localStorage.getItem("downloadandprint")
      ? localStorage.getItem("downloadandprint")
      : "";
    this.chkeditrights = localStorage.getItem("editaccess")
      ? localStorage.getItem("editaccess")
      : "";
    this.checkDeleteRights = localStorage.getItem("deleteaccess")
      ? localStorage.getItem("deleteaccess")
      : "";
    this.getguid = localStorage.getItem("loginguid");
    if (this.chkdownloadprint) {
      this.downloadprint = JSON.parse(this.chkdownloadprint);
    }
    if (this.chkeditrights) {
      this.editrights = JSON.parse(this.chkeditrights);
    }
    if (this.checkDeleteRights) {
      this.deleteRights = JSON.parse(this.checkDeleteRights);
    }

    this.subscription = this.sharedservice.deleteMenuOwnedBy.subscribe(
      (data) => {
        this.deleteOwnedDoc();
      }
    );
    this.subscriptions = this.sharedservice.deleteMenuAllWs.subscribe(
      (data) => {
        this.deleteOwnedDoc();
      }
    );
  }
    /**
     * Method to check EXternal Solution
     */
  checkExternalSol() {
    this.numberOfSelectedDocs = Number(
      localStorage.getItem("selectedDocsNumber")
    );
    if (this.numberOfSelectedDocs == 1) {
      this.selectedDocs.forEach((doc)=>{
        if((doc.externalSol !== '') && (doc.externalSol !== undefined)){
          this.externalAssociationFound = true;
          this.defaultDelText = false;
          this.externalAssociationFoundMul =false;
          this.cd.detectChanges();
        }
        else {
          this.externalAssociationFound = false; 
          this.externalAssociationFoundMul = false;
          this.defaultDelText = true;
          this.cd.detectChanges();
        }
      })
  }
  else if (this.numberOfSelectedDocs > 1) {
    let ifMulti = this.selectedDocs.some((data)=> data.externalSol!= '')
    if(ifMulti) {
      this.associatedDocumentsName = [];
      this.selectedDocs.forEach((doc)=>{
        if((doc.externalSol !== '') && (doc.externalSol !== undefined)) {
            this.associatedDocumentsName.push(doc.docRename);
        }
      })
          this.externalAssociationFoundMul = true;
          this.externalAssociationFound = false;
          this.defaultDelText = false;
          this.cd.detectChanges();
      }
      else {
        this.externalAssociationFoundMul = false; 
        this.externalAssociationFound = false;
        this.defaultDelText = true;
        this.cd.detectChanges();
      }
  }
  
}
  ngOnDestroy() {
    if (this.subscriptions || this.subscription) {
      this.subscription.unsubscribe();
      this.subscriptions.unsubscribe();
    }
  }

  @HostBinding("class")
  classes = "example-items-rows";
  showToast() {
    this.toastrService.primary("Invite Sent", "");
  }

  _selectedDocs;
  @Input()
  set selectedDocs(selectedDocs) {
    this._selectedDocs = selectedDocs;
  }
  get selectedDocs() {
    return this._selectedDocs;
  }

  _docList: string = "";
  _docguidList: string = "";
  @Input()
  set doclist(doclist: string) {
    this._docList = doclist?.toString() || "";
  }
  get doclist() {
    return this._docList;
  }

  _numberofDocsSelected: number;
  @Input()
  set numberofDocsSelected(numberofDocsSelected: any) {
    this._numberofDocsSelected = numberofDocsSelected || "";
  }
  get numberofDocsSelected() {
    return this._numberofDocsSelected;
  }

  @Input() selectedFileNames;
  @Input() rightUserList;
  @Output() ownedByEvent = new EventEmitter<string>();
  @Output() allWsEvent = new EventEmitter<string>();

  @ViewChild("deletedoc", { read: TemplateRef }) deletedoc: TemplateRef<any>;
  @ViewChild("notauth", { read: TemplateRef }) notauth: TemplateRef<any>;

  callAuthCheck() {
    this.confirmDeleteBox();
  }
  openModalDelete() {
    this.openModal(this.confirmDelete, false);
  }

  notauthDoc() {
    this.openNotauthDelete(this.notauth);
  }

  // modal for deleting documents

  openNotauthDelete(deleteNotauth: TemplateRef<any>) {
    this.deleteNotauthRef = this.dialogService.open(this.notauth, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  // delete checks starts

  deleteAuthCheck(
    confirmDelete: TemplateRef<any>,
    wsOutOfSync: TemplateRef<any>
  ) {
    if (this._selectedDocs.length > 0 && this._selectedDocs.length == 1) {
      let flag = false;
      let isSharedRights = localStorage.getItem("isSharedRights");
      let checkDeleteRights = localStorage.getItem("deleteaccess");

      if (
        this._selectedDocs[0].docOwnerEmailId == this.createdBy ||
        (isSharedRights == "true" && checkDeleteRights)
      ) {
        this.openModal(this.confirmDelete, false);
      } else if (
        this._selectedDocs[0].docOwnerEmailId == this.createdBy ||
        (isSharedRights == "true" && !checkDeleteRights)
      ) {
        this.wsOutOfSyncRef = this.dialogService.open(wsOutOfSync, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
        });
      }

      return flag;
    }

    if (this.numberOfSelectedDocs > 0 && this.numberOfSelectedDocs > 1) {
      let flag = false;
      let isSharedRights = localStorage.getItem("isSharedRights");
      let allSharedData = JSON.parse(localStorage.getItem("allSharedData"));
      let checkDeleteRights = localStorage.getItem("deleteaccess");

      let sharedArray = [];
      let none = (arr, callback) => !arr.some(callback);
      this.selectedDocs.forEach((e) => {
        let data = allSharedData.filter((e1) => {
          return e1.documentDetailsList[0].docId == e.docId;
        });
        if (!data[0]) {
          return;
        }
        let newData = data[0].documentShareUserList;
        let isSharedIndex = newData.findIndex((e) => {
          return e.userEmailID == this.createdBy;
        });
        let json: any;
        if (isSharedIndex > -1) {
          json = { docId: e.docId, isShared: true };
        } else {
          json = { docId: e.docId, isShared: false };
        }
        sharedArray.push(json);
      });

      let someSharedCount = 0;
      sharedArray.forEach((e) => {
        if (e.isShared == true) {
          someSharedCount++;
        }
      });
      let isSomeShared = false;
      if (someSharedCount > 0) {
        isSomeShared = true;
      }

      let isAllShared = sharedArray.every((e) => {
        return e.isShared == true;
      });
      let isAllOwned = this.selectedDocs.every((e) => {
        return e.docOwnerEmailId == this.createdBy;
      });
      let someOwnedCount = 0;
      let isSomeOwned = false;
      this.selectedDocs.forEach((e) => {
        let index = sharedArray.findIndex((e1) => {
          return e1.docId == e.docId;
        });
        if ((index > -1 && !sharedArray[index].isShared) || index == -1) {
          if (e.docOwnerEmailId == this.createdBy) {
            someOwnedCount++;
            return true;
          }
        }
      });
      if (someOwnedCount > 0) {
        isSomeOwned = true;
      }

      let cummilativeCount = someSharedCount + someOwnedCount;
    }
  }

  reloadScreen() {
    window.location.reload();
  }

  @Output() isDeleted = new EventEmitter();

  doclistdata = [];
  docOwnerList = [];
  deleteAccessMultiple() {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    this.doclistdata = [];
    this.docOwnerList = [];
    if (this._selectedDocs) {
      for (let i = 0; i <= this._selectedDocs.length - 1; i++) {
        this.doclistdata.push(this._selectedDocs[i].docGuid);
        this.docOwnerList.push(this.selectedDocs[i].docOwnerEmailId);
      }
    }
    let postData: any;
    postData = {
      wsoid: Number(this.workSpaceId),
      docIdList: this._docList,
      originalFileNameList: this.selectedFileNames,
      modifiedBy: this.createdBy,
      modifiedName: this.WsdisplayName,
      separator: "|",
      wsLink: arr[2],
      docOwnerEmailId: this.docOwnerList.join("|"),
      wsName: this.wsName,
      documentCount: this.numberOfSelectedDocs.toString(),
      guid: this.getguid,
      DocGuid: this.doclistdata.join("|"),
      ExternalSol: "dox2uapp"
    };
    this.showSpinnerDelete = true;
    this.pendingRequest = this.data
      .deleteOwnedService(postData)
      .subscribe((response: any) => {
        if (response.isSuccess) {
          this.dialogRef.close();
          this.nonOwnerDocuments = this._selectedDocs.filter(
            (a) => a.docOwnerEmailId !== this.createdBy
          );
          let output;
          output = Object.values(
            this.nonOwnerDocuments.reduce((obj, { docOwnerEmailId }) => {
              if (obj[docOwnerEmailId] === undefined) {
                obj[docOwnerEmailId] = {
                  docOwnerEmailId: docOwnerEmailId,
                  occurrences: 1,
                };
              } else {
                obj[docOwnerEmailId].occurrences++;
              }
              return obj;
            }, {})
          );

          output.forEach((element) => {
            if (element.occurrences === 1) {
              this.nonOwnerDocuments.forEach((value) => {
                if (element.docOwnerEmailId === value.docOwnerEmailId) {
                  this.downloadModifiedNameFileName = value.docRename;
                  this.documentOwnerEmail = value.docOwnerEmailId;
                  this.notifyInsertDownlaodSinfle();
                  this.notifysendDownload();
                }
              });
            } else if (element.occurrences > 1) {
              this.documentOwnerEmailMulti = element.docOwnerEmailId;
              this.filesCount = element.occurrences;
              this.notifyInsertDownlaodMultifle();
              this.notifysendDownloadMulti();
            }
          });
          if (this.href === "/pages/documents/owned-by-me") {
            this.sharedservice.ownedByMeEmitter.next("");
          } else if (this.href.includes("/pages/search")) {
            this.sharedservice.refreshSearch.next("");
            setTimeout(() => {
              this.sharedservice.globalSearchEmitter.next("");
            }, 3500);
          } else if (this.href === "/pages/documents/shared-with-me") {
            this.sharedservice.sharedWithMeEmitter.next("");
          } else if (this.href === "/pages/dashboard") {
            this.sharedservice.dashboardEmitter.next("");
          } else if (this.href === "/pages/all-workspace") {
            this.sharedservice.allWsEmitter.next("");
          } else if (this.href === "/pages/cabinet") {
            this.sharedservice.cabinetDelete.next("");
          }
          this.isDeleted.emit(true);
          this.toastrService.primary(
            this.numberOfSelectedDocs + " documents moved to Trash"
          );
          this.showSpinnerDelete = false;
          this.dialogRef.close();
          this.AfterDelete(postData);
          this.sharedSrvice.isDeleted.next();
        }
      });
  }

  showSpinnerDelete = false;
  deleteOwnedDoc() {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      emailId: this.createdBy,
      wsLink: arr[2],
      wsoid: JSON.parse(this.workSpaceId),
      guid: this.getguid,
    };

    let docGuid = localStorage.getItem('selectedDocGuid');
    this.data.getaccessrights(access).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sharedservice?.deleteFromList?.next(docGuid)
        localStorage.setItem(
          "downloadandprint",
          JSON.stringify(response.data.isDownloadPrintDocuments.toString())
        );
        localStorage.setItem(
          "editaccess",
          response.data.isEditDocuments.toString()
        );
        localStorage.setItem(
          "deleteaccess",
          response.data.isDeleteDocument.toString()
        );

        this.chkdownloadprint = localStorage.getItem("downloadandprint");
        this.chkeditrights = localStorage.getItem("editaccess");
        this.checkDeleteRights = localStorage.getItem("deleteaccess");
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.editrights = JSON.parse(this.chkeditrights);
        this.deleteRights = JSON.parse(this.checkDeleteRights);
        let isAllOwned = localStorage.getItem("isAllOwned")
          ? localStorage.getItem("isAllOwned")
          : "";

        if (isAllOwned || this.deleteRights) {
          this.proceedDelete();
        } else {
          this.wsOutOfSyncRef = this.dialogService.open(this.wsOutOfSync, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
          });
        }
      }
    });
    if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
      this.router.navigate(['/pages/dashboard']);
    }
  }

  deleteDoc() {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let postData: any;
    postData = {
      wsoid: Number(this.workSpaceId),
      docIdList: this._docList,
      originalFileNameList: this.selectedFileNames,
      modifiedBy: this.createdBy,
      modifiedName: this.WsdisplayName,
      separator: "|",
      wsLink: arr[2],
      docOwnerEmailId:
        this._selectedDocs[0].docOwnerEmailId ||
        this._selectedDocs[0].status?.split("|")[2],
      wsName: this.wsName,
      documentCount: this.numberOfSelectedDocs.toString(),
      guid: this.getguid,
      DocGuid: this.selectedDocs[0].docGuid.toString(),
      ExternalSol: "dox2uapp"
    };

    this.sharedservice.sendDetailData.next("");
    this.showSpinnerDelete = true;
    this.data.deleteOwnedService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.dialogRef.close();
        if (this._selectedDocs[0].docOwnerEmailId !== this.createdBy) {
          this.downloadModifiedNameFileName = this._selectedDocs[0].docRename;
          this.documentOwnerEmail = this._selectedDocs[0].docOwnerEmailId;
          this.notifyInsertDownlaodSinfle();
          this.notifysendDownload();
        }
        if (this.href === "/pages/documents/owned-by-me") {
          this.sharedservice.ownedByMeEmitter.next("");
        } else if (this.href.includes("/pages/search")) {
          this.sharedservice.refreshSearch.next("");
          setTimeout(() => {
            this.sharedservice.globalSearchEmitter.next("");
          }, 3500);
        } else if (this.href === "/pages/documents/shared-with-me") {
          this.sharedservice.sharedWithMeEmitter.next("");
        } else if (this.href === "/pages/dashboard") {
          this.sharedservice.dashboardEmitter.next("");
        } else if (this.href === "/pages/all-workspace") {
          this.sharedservice.allWsEmitter.next("");
        } else if (this.href === "/pages/cabinet") {
          this.sharedservice.cabinetDelete.next("");
        }
        this.toastrService.primary(
          this.numberOfSelectedDocs + " document moved to Trash"
        );
        this.showSpinnerDelete = false;
        this.dialogRef.close();
        this.isDeleted.emit(true);
        this.AfterDelete(postData);
        this.sharedSrvice.isDeleted.next();
      }
    });

         //Send Real Time Delete Notification
            var st = window.location.toString();
            var wslink = st?.split("/")[2]
            

            let payload={
              // "WsLink":wslink,
              // "userEmailID":localStorage.getItem('ownerEmailId'),
              // "FromDate":"",
              // "ToDate":"",
              // "InvitedBy":"",
              // "Role":""

              "AuthorizeEmailId":localStorage.getItem('ownerEmailId'),
              "FromDate":"",
              "FullName":"",
              "GroupId":0,
              "RoleId":0,
              "Status":"",
              "ToDate":"",
              "WsLink":wslink,
              "Wsoid":Number(localStorage.getItem('localwsId'))
            }

            let invitedUsersEmail=[]

            this.data.getInvitedUsersLists(payload)
            .subscribe((res:any)=>{
              
              if(res.data && res.data.length>0)
              {
                for(let i=0; i<res.data.length; i++)
                {
                  if(!invitedUsersEmail.includes(res.data[i].emailId) && res.data[i].category!='Owner')
                  invitedUsersEmail.push(res.data[i].emailId)
                }
              }

              console.log("invitedUsersEmail",invitedUsersEmail)

              var urlchange = arr[0] + "//" + arr[2] + "/pages/documents/trash";

        let notificationHeading="Document moved to trash";
        let message = '';
      

              for(let i=0; i<invitedUsersEmail.length; i++)
              {
                if (localStorage.getItem("docOwnerEmailId") == invitedUsersEmail[i]) {
                  message=
                  "This document has been trashed by" + " " +
                  this.createdBy +
                  ". " +
                  "The doc has been moved to your Trash" +
                  " "
                } else {
                   message = "This document may have been moved to trash or permanently deleted and therefore cannot be accessed. "
                }
                  let notifysend = {
                    wsoid: Number(this.workSpaceId),
                    key: null,
                    userEmailID:invitedUsersEmail[i],
                    role: null,
                    groupName: null,
                    message: "documentDeletedRealTimeNotify|docGuid,"+this.selectedDocs[0].docGuid.toString()+'|'+notificationHeading+'|'+message,
                    databaseName: "",
                    createdBy: this.createdBy,
                    Flag: "Modal",
                    WsLink: ""
                  }
              
                  //  if(localStorage.getItem('ownerEmailId')!=invitedUsersEmail[i])
                  //  {
                    this.data.notifysend(notifysend).subscribe((response: any) => {
                      if (response.isSuccess) {
                        console.log('notifyShared Function called for Delete ')
                      }
                    })
                  // }

              }
              
            })

  }

  AfterDelete(data) {
    this.data.afterDeleteOwnedService(data).subscribe((response: any) => {
      if (response.isSuccess) {
      } else {
      }
    });
  }

  confirmDeleteBox() {
    this.checkExternalSol();
    this.openModal(this.confirmDelete, false);
  }

  proceedDelete() {
    this.numberOfSelectedDocs = Number(
      localStorage.getItem("selectedDocsNumber")
    );
    if (this.numberOfSelectedDocs == 1) {
      this.checkExternalSol();
      this.deleteDoc();
    } else if (this.numberOfSelectedDocs > 1) {
      this.checkExternalSol();
      this.deleteAccessMultiple();
    }
  }
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      autoFocus: true,
      closeOnEsc: false,
    });
  }

  deleteOpen(confirmDelete: TemplateRef<any>) {
    this.openModal(this.confirmDelete, false);
    this.deleteOwnedDoc();
  }

  private getDocOwnerEmailIdList() {
    const list = [];
    this.selectedDocs.forEach((obj) => {
      list.push(obj.docOwnerEmailId);
    });
    return list.join("|");
  }
  notifyInsertDownlaodSinfle() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + "/pages/documents/trash";
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document moved to trash",
      message:
        "<b>" +
        localStorage.getItem("noteuserfullname") +
        "(" +
        this.createdBy +
        ")" +
        "</b>" +
        " " +
        "moved" +
        " " +
        this._selectedDocs[0].originalFileName +
        " " +
        "to your trash. View" +
        " " +
        `<a href=${urlchange}>Trash</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    };
    this.insertmessgae = "";
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      } else {
        this.insertmessgae = "";
      }
    });
  }

  notifysendDownload() {
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: "Document moved to trash",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: "",
    };
    this.sendmessage = "";
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      } else {
        this.sendmessage = "";
      }
    });
  }
  notifyInsertDownlaodMultifle() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + "/pages/documents/trash";
    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Documents moved to trash",
      message:
        "<b>" +
        localStorage.getItem("noteuserfullname") +
        " " +
        "(" +
        this.createdBy +
        ")" +
        "</b>" +
        " " +
        "moved your" +
        " " +
        this.filesCount +
        " " +
        "documents to your trash. View" +
        " " +
        `<a href=${urlchange}>Trash</a>`,
      notificationTo: this.documentOwnerEmailMulti,
      createdBy: this.createdBy,
    };
    this.insertmessgae = "";
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      } else {
        this.insertmessgae = "";
      }
    });
  }

  notifysendDownloadMulti() {
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmailMulti,
      role: null,
      groupName: null,
      message: "Documents moved to trash",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: "",
    };
    this.sendmessage = "";
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      } else {
        this.sendmessage = "";
      }
    });
  }
}
