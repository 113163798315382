import { ElementRef, HostListener, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, TemplateRef, OnInit, ChangeDetectorRef, } from '@angular/core';
import { Router } from "@angular/router";
import { NbContextMenuDirective, NbDialogRef, NbDialogService, NbIconConfig, NbMenuService, NbToastrService } from "@nebular/theme";
import { WorkspaceTrashService } from '../../../@core/utils/workspace-trash.service'
import { Title } from '@angular/platform-browser';
import { SharedDocsService } from '../../../@core/utils/shared-docs.service';
import { SharedService } from '../../../@core/utils/shared.service';
import { VerifyDocumentService } from '../../../@core/verify-document.service';
import { DashboardService } from '../../../@core/utils/dashboard.service';
import { ApiServiceService } from '../../../@core/api-service.service';
import { AllWorkspaceService } from '../../../@core/utils/all-workspace.service';
import { PrintService } from '../../../@core/utils/print.service';
import { DeleteDocsComponent } from '../../../shared/delete-docs/delete-docs.component';
import { MiniProfileService } from '../../../@core/utils/mini-profile.service';
import { UserSettingService } from '../../../@core/utils/user-setting.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedServiceService } from '../../../shared-service.service';
import { GuestUserModalComponent } from '../../../shared/guest-user-modal/guest-user-modal.component';
import { PrintDocumentComponent } from '../../../shared/print-document/print-document.component';
import { OwnedDocsService } from './../../../@core/utils/owned-docs.service';
import { MyQueueService } from '../../../@core/utils/my-queue.service';
import { SharedAccessComponent } from '../../../shared/shared-access/shared-access.component';
import { DateTransformPipe } from '../../date-transform.pipe';
import { WorkspaceAdminService } from '../../../../app/@core/workspace-admin.service';
import { environment } from '../../../../environments/environment';

declare global {
  interface Window {
    productFruitsUser: any;
    productFruits : any;
    pfDisableUrlChangeDetection : boolean
    Tawk_API : any
  }
}
@Component({
  selector: 'ngx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [NbMenuService]
})
export class DashboardComponent implements OnInit {
  [x: string]: any;
  modalGuestOpen: boolean = true;

  public showUploadDialog: boolean = false;

  @ViewChild("nb-context-menu") threeDot!:any
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  @ViewChild('miniprofile', { read: TemplateRef }) miniprofile:TemplateRef<any>;
  @ViewChild('Landingmodal', { read: TemplateRef }) Landingmodal: TemplateRef<any>;
  @ViewChild('miniprofileview', { read: TemplateRef }) miniprofileview:TemplateRef<any>;
  @ViewChild('transferOwnership', { read: TemplateRef }) transferOwnership: TemplateRef<any>;
  @ViewChild('wsOutSync', { read: TemplateRef }) wsOutSync: TemplateRef<any>;
  @ViewChild(DeleteDocsComponent) deleteChild : DeleteDocsComponent;
  @ViewChild('chngVerifier') chngVerifier!: TemplateRef<any>;
  @ViewChild('deleteDocs') deleteDocs!: TemplateRef<any>
  @ViewChild(PrintDocumentComponent) printChild: PrintDocumentComponent;
  @ViewChild(GuestUserModalComponent) guestChild: GuestUserModalComponent;

/**
 * for open modal function ends here
 * */
  HighlightRow;
  /**
 * for open lastAccess Length
 * */
  lastAccessLength;
  /**
 * for before Step
 * */
  beforeStep = false;
  /**
 * for password box hidden
 * */
  passwordboxhidden =false;
  /**
 * for icon Last Step Completed
 * */
  iconLastStepCompleted = false;
/**
 * for isLoader
 * */
  isLoader = true;
  /**
 * for number of DocsSelected
 * */
  numberofDocsSelected = 1;
    /**
 * for count
 * */
  Count:Number;
  /**
 * for items of list
 * */
  items = [];
/**
 * for icon Path
 * */
  iconPath: any;
  /**
 * for icon Step Completed
 * */
  iconStepCompleted =false;
/**
 * variable for icon CreativeDtm
 * */
  CreativeDtm;
  /**
 * variable for icon inner Width
 * */
  innerWidth = window.innerWidth;
/**
 * variable for icon user Card
 * */
  userCard = true;
  /**
 * variable for ws Owner Card
 * */
  wsOwnerCard = true;
    /**
 * variable for guest Access
 * */
  guestAccess: any;
/**
 *  variable for file Upload Prgress Btn
 * */
  fileUploadPrgressBtn: boolean;
  /**
 * variable for file uploadRef
 * */
  uploadRef: NbDialogRef<any>;
    /**
 * variable for file uploadRef
 * */
  getguid: any;
/**
 *  variable for file shift key
 * */
  shiftkey: string;
  /**
 * variable for file middot
 * */
  middot = ' | ';
 /**
 *  variable for file maximum FileSize
 * */
  maximumFileSize: any;
   /**
 *  variable for file shift key Code
 * */
  shiftkeyCode: number;
   /**
 * variable for file pending Request
 * */
  pendingRequest: any
/**
 * variable for file isDocumentUpload
 * */
  isDocumentUpload = "false";
  /**
 *  variable for file isDocumentUpload
 * */
  isRefreshed=false;
/**
 *  variable for file workspace name
 * */
  wsName :any;
  /**
 *  variable for file maxFileSizeUpload
 * */
  maxFileSizeUpload;
   /**
 *  variable for file lastAccessDocId
 * */
  lastAccessDocId:any;
/**
 *  variable for file alreadyOpenDoc
 * */
  alreadyOpenDoc:boolean = false;
  /**
 *  variable for file already Open View1Doc
 * */
  alreadyOpenView1Doc :boolean = false;
   /**
 *  variable for file alreadyOpenView2Doc
 * */
  alreadyOpenView2Doc :boolean = false;
    /**
 *  variable for file WsdisplayName
 * */
  WsdisplayName: string;
 /**
 *  variable for file isSelectedAllUsers
 * */
  isSelectedAllUsers: any;
  /**
 *  variable for file share user Count
 * */
  shareuserCount: any;
   /**
 *  variable for file set User Group Arr
 * */
  setUserGroupArr: any;
  /**
 *  variable for already Open View Doc
 * */
  alreadyOpenViewDoc:boolean = false;
   /**
 *  variable for isCheckedAllUsers
 * */
  isCheckedAllUsers: any;
     /**
 *  variable for showVerifier
 * */
  showVerifier: boolean;
  /**
 *  variable for isUsersGroup
 * */
  isUsersGroup: any;
 /**
 *  variable for reload tawk to
 * */
  reloadtawk= false;
   /**
 *  variable for shared With Users
 * */
  sharedWithUsers: any;
  /**
 *  variable for category
 * */
  category:any;
/**
 *  variable for subscription SpId
 * */
  subscriptionSpId;
  /**
 *  variable for TableDataId
 * */
  TableDataId:any;
  /**
 *  variable for allUserLength
 * */
  allUserLength;
/**
 *  variable for allUserLength
 * */
  dpageSize;
  /**
 *  variable for documentOwnerName
 * */
  documentOwnerName:any
   /**
 *  variable for format
 * */
  format;
 /**
 *  variable for formats
 * */
  formats;
/**
 *  variable for isAllUserShare
 * */
  isAllUserShare: boolean;
  /**
 *  variable for role name
 * */
  rolename:any;
  /**
 *  variable for disabling soring in last Accessed
 * */
  GuestFlag;
  isGuestAccessInRole: boolean = false;

  disableSorting: boolean = true;
  constructor(private httpClient: HttpClient,
    private renderer: Renderer2,
    private router: Router,
    private printservice:PrintService,
    private data: DashboardService,
    private apiService: ApiServiceService,
    private datamyqueue: MyQueueService,
    private data1: VerifyDocumentService,
    private trash: WorkspaceTrashService,
    private dialogService: NbDialogService,
    private cd: ChangeDetectorRef,
    private _eref : ElementRef,
    private data2: OwnedDocsService,
    private titleService: Title,
    private datePipeTransform :DateTransformPipe,
    private cookieService:CookieService,
    private sharedService: SharedService,
    private sharedservice: SharedServiceService,
    private toastrService: NbToastrService,
    private datanew: SharedDocsService,
    private allworkspaceservice:AllWorkspaceService,
    private UserSettingService:UserSettingService,
    private menuService: NbMenuService,
    private miniProfile:MiniProfileService,
    private wsService:WorkspaceAdminService,

    private ownedservice : OwnedDocsService) {
      this.workSpaceId = localStorage.getItem('localwsId')
      this.WsdisplayName = (localStorage.getItem('WsdisplayName') === null || localStorage.getItem('WsdisplayName') === undefined) ? localStorage.getItem('WsdisplayName') : localStorage.getItem('noteuserfullname');
      this.createdBy = localStorage.getItem('localloginId')
      this.timeFormat = localStorage.getItem('TimeFormat')
      this.DateFormat = localStorage.getItem('DateFormat')
      this.dpageSize = localStorage.getItem('dpage');
      this.guestAccess = localStorage.getItem('isGuestAccess');
      this.rolename = localStorage.getItem('logincategory')
      this.subscriptionSpId = localStorage.getItem('subscriptionSpId')
      this.checkDeleteRights = localStorage.getItem('deleteaccess')
      this.deleteRights = JSON.parse(this.checkDeleteRights);

      this.GuestFlag = localStorage.getItem('GuestFlag');
      this.isGuestAccessInRole = localStorage.getItem('isGuestAccess')?.toString().toLowerCase() =='true'?true:false;

      localStorage.getItem('maxFileSizeUpload') ? this.maxFileSizeUpload = localStorage.getItem('maxFileSizeUpload') : '';
      localStorage.getItem('isDocumentUpload') ?  this.isDocumentUpload = JSON.parse(localStorage.getItem('isDocumentUpload')):'';
      this.guid = localStorage.getItem('loginguid')
      this.wsName = localStorage.getItem('WorkSpaceName')

     this.titleService.setTitle( this.WsdisplayName+this.middot+this.wsName+this.middot+"dox2U");
      this.ClickedRow = function(index,data?){
        this.HighlightRow = index;
        this.rowDatas = data;
        console.log(this.rowDatas)
        localStorage.setItem('selectedDocGuid', data?.docGuid);
        this.TableDataId = this.rowDatas.originalFileName;
        this.ownerEmailID = this.rowDatas.status?.split('|')[2];
        if (this.activeTab === 'Last accessed') {
        if (this.rowDatas.status?.split('|')[2] === this.createdBy) {
          this.ownByMeThreeDot();
        } else {
          this.sharedWithMeThreeDot();
        }
      }
      if (this.activeTab === 'Shared with me') {
        this.showDeleteUser();
      }
    }
      let self = this;
      window.addEventListener("keydown", function (event) {

        self.lastAccessLength
          switch (event.key) {
            case "Down":
            if(self.activeTab === 'Last accessed'){
              self.lastAccessLength-1 === self.HighlightRow ? self.HighlightRow = self.lastAccessLength-1  :  self.HighlightRow++;
            }
            if(self.activeTab === 'My Queue'){
              self.DocUploadedLength-1 === self.HighlightRow ? self.HighlightRow = self.DocUploadedLength-1  :  self.HighlightRow++;
            }
            if(self.activeTab === 'Shared with me'){
              self.ShareByMeLength-1 === self.HighlightRow ? self.HighlightRow = self.ShareByMeLength-1  :  self.HighlightRow++;
            }
            if(self.activeTab === 'Owned by me'){
              self.OwnerByMeLength-1 === self.HighlightRow ? self.HighlightRow = self.OwnerByMeLength-1  :  self.HighlightRow++;
            }

            case "ArrowDown":
              if(self.activeTab === 'Last accessed'){
                self.lastAccessLength-1 === self.HighlightRow ? self.HighlightRow = self.lastAccessLength-1  :  self.HighlightRow++;
              }
              if(self.activeTab === 'My Queue'){
                self.DocUploadedLength-1 === self.HighlightRow ? self.HighlightRow = self.DocUploadedLength-1  :  self.HighlightRow++;
              }
              if(self.activeTab === 'Shared with me'){
                self.ShareByMeLength-1 === self.HighlightRow ? self.HighlightRow = self.ShareByMeLength-1  :  self.HighlightRow++;
              }
              if(self.activeTab === 'Owned by me'){
                self.OwnerByMeLength-1 === self.HighlightRow ? self.HighlightRow = self.OwnerByMeLength-1  :  self.HighlightRow++;
              }

              break;
            case "Up":
            self.HighlightRow === 0 ? self.HighlightRow = 0 : self.HighlightRow--;
            case "ArrowUp":

              self.HighlightRow === 0 ? self.HighlightRow = 0 : self.HighlightRow--;
              break;
            case " ":
              let value1 = localStorage.getItem('EnterActive');
              if(self.HighlightRow === null || self.HighlightRow === undefined ){

                value1 == "activeEnter" ?   self.HighlightRow = null : self.HighlightRow = 0;

              }

              if (self.isSearchFocused){
                self.HighlightRow = 0;
              }
              break;

            case "Enter":
              const value = localStorage.getItem('EnterActive');

              if(self.HighlightRow === null || self.HighlightRow === undefined ){

                value == "activeEnter" ?   self.HighlightRow = null : self.HighlightRow = 0;

              }
               else{

                if(self.activeTab === 'My Queue'){
                  if(!self.alreadyOpenDoc){
                    value !== 'activeEnter' ? self.onVerifyDocument(self.rowDatas): '';
                    self.alreadyOpenDoc = true;
                    self.HighlightRow;
                  }

                }
                else if (self.activeTab === 'Shared with me' && self.activeTab !== undefined){
                  if(!self.alreadyOpenViewDoc){
                    self.alreadyOpenViewDoc = true;
                    value !== 'activeEnter' ? self.onViewClickThree(self.rowDatas) : '';
                    self.HighlightRow;
                  }
                }
                else if(self.activeTab === 'Last accessed' && self.activeTab !== undefined){
                  if(!self.alreadyOpenView1Doc){
                    self.alreadyOpenView1Doc = true;
                    value !== 'activeEnter' ? self.onViewClickThree(self.rowDatas): '';
                    self.HighlightRow;
                  }

                }
                else if(self.activeTab === 'Owned by me' && self.activeTab !== undefined){
                  if(!self.alreadyOpenView2Doc){
                    self.alreadyOpenView2Doc = true;
                    value !== 'activeEnter' ?  self.onViewClickThree(self.rowDatas): '';
                    self.HighlightRow;
                  }

                }

            }
              break;
          }


        });



  }
  /**
 *  event handler for mouseleave event
 * */
  @HostListener('mouseleave', ['$event']) onLeave( e: MouseEvent ) {
    this.alreadyOpenDoc = true;
    this.alreadyOpenView1Doc = true;
    this.alreadyOpenViewDoc = true;
    this.alreadyOpenView2Doc = true;

  }
/**
 *  method for ownByMe Enter Key Active
 * */
  ownByMeEnterKeyActive(){
    this.alreadyOpenDoc = false;
    this.alreadyOpenViewDoc = false;
    this.alreadyOpenView1Doc = false;

  }
  /**
 *  method for shared With Me Active
 * */
  sharedWithMeActive(){
    this.alreadyOpenDoc = false;
    this.alreadyOpenView1Doc = false;
    this.alreadyOpenView2Doc = false;
  }
  /**
 *  method for Docs In Upload Active
 * */
  DocsInUploadActive(){

    this.alreadyOpenViewDoc = false;
    this.alreadyOpenView1Doc = false;
    this.alreadyOpenView2Doc = false;
  }
/**
 *  method for Last Access Active
 * */
  LastAccessActive(){
    this.alreadyOpenDoc = false;
    this.alreadyOpenViewDoc = false;
    this.alreadyOpenView2Doc = false;
  }
  /**
 *  variable for isDisabledButton
 * */
  isDisabledButton=false;
  /**
 *  variable for workSpace Id
 * */
  workSpaceId;
  /**
 *  variable for guid
 * */
  guid;
    /**
 *  variable for upload url
 * */
  uploadurl ;
  /**
 *  variable for createdBy
 * */
  createdBy;
   /**
 *  variable for ClickedRow
 * */
  ClickedRow;
   /**
 *  variable for subscriptionmini
 * */
  subscriptionmini;
   /**
 *  variable for menuRights
 * */
  menuRights;
   /**
 *  variable for fullNamelast
 * */
  fullNamelast;
     /**
 *  variable for subscriptionThreeDot
 * */
  subscriptionThreeDot;
/**
 *  variable for subscription
 * */
  subscription;
  /**
 *  variable for wsManagement
 * */
  wsManagement = false;
   /**
 *  variable for  Managing Groups
 * */
  isManageGroups= false;
  /**
 *  variable for CreatedRoles
 * */
  isCreatedRoles = false;
    /**
 *  variable for AdminRightRole
 * */
  AdminRightRole = false;
  /**
 *  variable for AdminRightRole
 * */
  visiblelifeRing = false;
/**
 *  variable for visible life Ring
 * */
  subscriptions;
  /**
 *  variable for isSearchFocused
 * */
  isSearchFocused;
  /**
 *  variable for search id
 * */
  searchidsss
  /**
 *  variable for subscriptionData1
 * */
  subscriptionData1;
    /**
 *  variable for newValueTarget1
 * */
  newValueTarget1;
  /**
 *  method for ownByMeList
 * */
  ownByMeList(){
  let postData = {
      "Wsoid": Number(this.workSpaceId),
      "OriginalFileName": "",
      "startDate": "",
      "endDate":  "",
      "documentFormat": "",
      "uploadedBy":"",
      "verifiedBy": "",
      "createdBy": this.createdBy,
      "NumberofRow":50,
      "Pagecount":1,
      // "RowNumber":Number(this.selectedItem),
      // (this.selectedItem *) *

    }
    this.data2.getOwnedByMeDocListData(postData).subscribe((response: any) => {
      if(response?.data[0]?.totalcount){
        this.totalDocCount = response.data[0].totalcount;
        localStorage.setItem("OwnListCount",  this.totalDocCount)
        this.getDocCount('Owned')
    }
    })

  }
  /**
 *  method for shared WithMeList
 * */
  sharedWithMeList(){
   let postData = {
      "Wsoid": Number(this.workSpaceId),
      "OriginalFileName": "",
      "StartDate": "",
      "EndDate": "",
      "documentFormat": "",
      "uploadedBy": "",
      "verifiedBy":"",
      "createdBy": this.createdBy,
      "OwnedBy": "" ,
      "NumberofRow" :50,
      "Pagecount":1,
    }
    this.datanew.getSharedByMeDocListData(postData).subscribe((response:any)=>{
      if(response?.data[0]?.totalcount){
        this.totalDocCount =response.data[0].totalcount
        localStorage.setItem("SharedListCount",  this.totalDocCount)
      }

    })
  }
/**
 *  method for search loader
 * */
  searchloader
  /**
 *  method for life-cycle hook
 * */
  ngOnInit(): void {
    // this.sharedService.transferData.subscribe((res) => {
    //   if(res=='reloadData')
    //   {
    //     this.ngOnInit()
    //   }
    // });
    
    this.sharedService.searchloader.subscribe((response)=>{
      this.searchloader =      response
    })
    // this.sharedService.notifySilentSignIn.subscribe((res: any) => {
    //   if (res) {
    //     if (this.cookieService.get('SSOURL')) {
    //       console.log("cookie is deleted");
    //       this.CookieService.delete('SSOURL');
    //     }
    //   }
    // })
    // this.searchloader = localStorage.getItem('searchloader')
    this.commonTab();
    this.ownByMeList();
    this.sharedWithMeList();
    this.getDocCount('Owned')
    this.cookieService.delete('isCreating', '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
    setTimeout(() => {
      this.lastAccessTab()
      this.isLoader = false;
    }, 1000);
    this.sharedService.dashboardReload.subscribe((res:any)=>{
      this.reloadDashboard();
      this.ownByMeList();
      this.sharedWithMeList();
      this.getDocCount('Owned')
      this.getDocCount('Shared')
    })
    this.href = this.router.url;
     this.sharedService.uploadOpen.subscribe((data) =>
    {
      this.uploadClose = data;
    })
    this.sharedservice?.deleteFromList?.subscribe(guid=>{
      let index
      for(let i=0;i<=this.getLastAccess?.length;i++){
        if(this.getLastAccess[i]?.docGuid == guid){
          index = i
        }
      }
      this.getLastAccess.splice(index,1)
    })
    this.getguid = localStorage.getItem('loginguid');
    this.tooltipData= "Pages are dox2U pages As per our 'Pay as you Go' policy we consider " + this.dpageSize+ ' KB  as 1 dPage'
    this.reloadtawk = false
    this.subscriptions = this.sharedService.menuRights.subscribe((data) => {
      this.menuRights = data;
      this.isDocumentUpload = this.menuRights.isDocumentUpload;
      this.rolename = this.menuRights.roleName;
      this.wsManagement = this.menuRights.isWorkspaceManagement;
      this.isManageGroups = this.menuRights.isManageUsersGroups;
      this.isCreatedRoles = this.menuRights.isCreateModifyRoles
      this.AdminRightRole = (this.wsManagement || this.isManageGroups || this.isCreatedRoles) ? true : false
    });
    this.subscriptions = this.sharedService.isSearchFocused.subscribe((data) => {
      this.isSearchFocused = data;
    });
    //this.lastAccessTab()

    this.sharedService?.uploadOpen?.subscribe((data) => {

      this.uploadurl = data

      if ((this.href === '/pages/dashboard' && this.uploadurl) || (this.href === '/pages/dashboard' && this.uploadurl !== undefined)) {
        const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
        if(s && s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0]){
          s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'none';
        }

      }
      if ((this.href === '/pages/dashboard' && !this.uploadurl) || (this.href === '/pages/dashboard' && this.uploadClose === 'true')) {

        const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
        if(s && s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0]){
          s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'flex';
        }

      }


    })
    this.subscriptions = this.sharedService.dashboardskelton.subscribe((data) => {
      this.loading = true;
    });

    this.subscriptions = this.sharedService.changeLeftToggle.subscribe((data) => {
      const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
      if(s && s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0]){
        s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.marginLeft = data ? '0rem' : '-14rem'
        s.querySelectorAll<HTMLElement>('.productfruits--checklist-panel')[0].style.marginLeft = data ? '0rem' : '-14rem'
      }

    });

    this.sharedservice.shareButton.subscribe((e) => {
      this.isSelectedOnceDetailsShare = false;
    })
    this.subscriptions = this.sharedService.bindMenu.subscribe((data) => {

    });

    this.subscription = this.sharedservice.dashboardEmitter.subscribe((data) => {
      this.commonTab();
    });
    if (localStorage.getItem('logincategory')) {
      this.category = localStorage.getItem('logincategory')
    }


    this.subscriptionData1 = this.sharedService.dashboardValueTarget.subscribe((data) => {
      this.newValueTarget1 = data;
      this.usercount = this.newValueTarget1[0].userCount;
      this.daily = this.newValueTarget1[0].daily;
      this.weekly = this.newValueTarget1[0].weekly;
      this.monthly = this.newValueTarget1[0].monthly;
      this.totaldocs = this.newValueTarget1[0].document;
      this.isDismiss = this.newValueTarget1[0].isDismiss === 1 ? true : false;

      this.timeTrackerData =  this.newValueTarget1[0].daily;
      this.isprofileComplete = this?.newValueTarget1[0]?.isprofile === 1 ? true : false;
      this.timeTrackerFlag ='daily';
      if(this.newValueTarget1[0].invitationMembercount == 1){
        if(window.productFruits.api != undefined){
          window.productFruits?.api?.checklists.markItemAsDone('invite_user', true)
        }
      }
      if( this.newValueTarget1[0].invitationMembercount >= 1 && this.newValueTarget1[0].ownerEmailID > 3 &&  this.newValueTarget1[0].generalSetting >= 1) localStorage.setItem('checklist','done')

      this.sharedservice.productFruitDataTemp.next()


    })




    this.subscriptionThreeDot = this.menuService.onItemClick().subscribe((event) => {

      if (event.item.title === 'Open Document' && this.activeTab == 'My Queue') {

        this.onVerifyClick();
      }
      else if (event.item.title === 'Open Document' && this.activeTab !== 'My Queue') {

        this.onViewClickThree();
      }
      else if (event.item.title === 'Change Verifier') {

        this.onChangeVerifierClicked();
      }
      else if (event.item.title === 'Edit') {
        this.onEditClick();
      }
      else if (event.item.title === 'Download') {

        this.openModeldownloadThree();
      }
      else if (event.item.title === 'Print') {

        this.openModelPrintThree();
      }
      else if (event.item.title === 'Share') {

        this.openShareModal(this.rowDatas);
      }

      else if (event.item.title === 'Delete') {
        if (this.activeTab !== 'My Queue') {
          this.openDeleteModalThree(this.rowDatas)
        } else {
          this.onDeleteDocs(this.rowDatas)
        }
      }
      else if (event.item.title === 'Share with guests') {
        if(this.modalGuestOpen == true) {
        this.openModalGuest(); 
      }
      }
      else if (event.item.title === 'Transfer ownership') {
        this.openModelOwnerShipTransferThreeDot();
      }

    });
    this.subscriptionmini = this.sharedService.minicrossval.subscribe((data) => {
      this.crossminidata();
    });

    this.sharedService.isLoaderDashboard.subscribe((data) => {

      this.cd.detectChanges();
    });
  }

  /**
 *  method for life-cycle hook
 * */
  ngAfterViewInit() {
    // this.lastAccessTab();
    this.sendProductFruitData()
    this.format = localStorage.getItem('FormatAvailable')
    this.format1 = this.format?.split(',').join(' ')

    this.formats = this.format?.split(':')[0] + ',' + this.format?.split(':')[1];
    this.maxFileSizeUpload = this.format?.split(':')[2]
    this.maxFileSizeUploadUnit = this.format?.split(':')[3];
    this.sharedService.menuRights.subscribe((data:any) => {
      this.isGuestAccessInRole = data.isGuestAccess;
    })
  }
/**
 *  method for send Product Fruit Data
 * */
 sendProductFruitData(){
  var st = window.location.toString();
  var arr = st?.split("/");
  let postData: any;
  postData = {
    Wsoid: parseInt(localStorage.getItem('localwsId')),
    WsLink: arr[2],
    EmailId: localStorage.getItem('localloginId'),
  };

let  postDatasp = {
    "WSLink": arr[2],
    "Wsoid": Number(this.workSpaceId)
  }
  this.wsService.wsSubscriptionDetailsService(postDatasp).subscribe((response:any)=>{
    localStorage.setItem('subscriptionSpId', response?.data?.[0]?.spId)
    if(response.data?.[0]?.totalDcoins){
      localStorage.setItem('TotalDCoins',String(response.data?.[0]?.totalDcoins));
    }
    localStorage.setItem('wsSubscriptionPlan',response.data?.[0]?.subscriptionPlan);
    localStorage.setItem('planDisplayName',response.data?.[0]?.planDisplayName);
    if (response.data?.[0]?.timeFormat == "12 hour") {
      localStorage.setItem('TimeFormat', "hh:mm a")
    }
    else {
      localStorage.setItem('TimeFormat', "HH:mm")
    }
  })
  this.data.menuRightsService(postData).subscribe((response:any)=>{
    // console.log(response.data)
    this.menuRights = response?.data;
    this.isDocumentUpload = this.menuRights.isDocumentUpload;
    this.rolename = this.menuRights.roleName;
    this.wsManagement = this.menuRights.isWorkspaceManagement;
    this.isManageGroups = this.menuRights.isManageUsersGroups;
    this.isCreatedRoles = this.menuRights.isCreateModifyRoles
    this.AdminRightRole = (this.wsManagement || this.isManageGroups || this.isCreatedRoles) ? true : false
    setTimeout(() => {
      this.productFruitData();
    }, 250);
    this.TawkTo();
  })
 }
 /**
 *  method for send Product Fruit Data
 * */
  productFruitData(){
    let link = window.location.href
    let key
  let enviroment
  if(link.includes('.dox2udev.com')|| link.includes('localhost:4200')|| link.includes('zorper.com')){
   enviroment='Development'
   key = 'XFgeZf6HYGl0yfP3';
  }
 else if(link.includes('.dox2uqa.com')){
       enviroment='QA'
       key = 'XFgeZf6HYGl0yfP3';
  }
  else if(link.includes('.dox2uuat.com')){
    enviroment='UAT'
    key = 'XFgeZf6HYGl0yfP3';

  } else if(link.includes('.dox2u.com')){
    enviroment='Production'
    key = 'jSceRnN5HKo903oF'

  }
    var promise = new Promise((resolve, reject) => {
      window.pfDisableUrlChangeDetection = undefined
      window.productFruitsUser = {
        username: this.workSpaceId + '|' + this.createdBy,
        email: this.createdBy,
        role: this.rolename,
        props: {
          admin_right: this.AdminRightRole,
          upload_right: JSON.parse(this.isDocumentUpload),
          WS_ID: Number(this.workSpaceId),
          plan: localStorage.getItem('wsSubscriptionPlan'),
          Environment:enviroment,
          first_login:localStorage.getItem('FirstLogin')?'yes':'no'


          // plan: Number(localStorage.getItem('subscriptionSpId')),
        }
      };



      (function (w: any, d, u, c, l) {
        w.productFruits = w.productFruits || {};
        w.productFruits.language = l; w.productFruits.code = c;
        var a = d.getElementsByTagName('head')[0];
        var r: any = d.createElement('script'); r.async = 1;
        r.src = u + '?c=' + c;
        a.appendChild(r);
      })(window, document, 'https://app.productfruits.com/static/script.js', key, 'en'); // Replace 'en' with a proper language code
      const s = document?.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
      if( s && s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0]){
        s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'flex';
      }
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      window.Tawk_API = window.Tawk_API || {};

      if (!this.reloadtawk) {

        window.Tawk_API.onChatMinimized = function () {
          window.productFruits.api.button.showButton();
          this.visiblelifeRing = true;
          const p = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
          if(p && p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0]){
            p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.right = '22px';
            p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.bottom = '19px';
            const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
            s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'flex'
           const ss = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
          ss.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'flex';
          }
        };
      }
      if (this.reloadtawk) {

        window.Tawk_API.onChatMaximized = function () {
          window.productFruits.api.button.hideButton();
          this.visiblelifeRing = false;
          const p = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
          if(p && p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0]){
            p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'none';
          }



        }
      }
      window.addEventListener('productfruits_button_ext_widget_init', () => {
        window.Tawk_API.maximize();

      });

      /**
       * TAWKTO JAVASCRIPT SNIPPET
       */

      resolve("Promise Resolved");

    })

    promise.then((success) => {
     setTimeout(() =>{
      this.dashboardcards();
      this.lastAccessTab();
     })

    })
  }
 /**
 *  method for TawkTo
 * */
  TawkTo(){
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/625419ef7b967b11798a11c9/1g0c8akna';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }
   /**
 *  method for ngOnDestroy
 * */
  ngOnDestroy(){
    this?.subscription?.unsubscribe();
    this?.subscriptions?.unsubscribe();
    this?.subscriptionThreeDot?.unsubscribe();
  }
   /**
 *  variable for count Data
 * */
  countData = 'Owned';
 /**
 *  method for get Doc Count
 * */
  getDocCount(id:any){
   if(id === "Owned"){
    this.countData = "Owned";
    this.dpages = localStorage.getItem('OwnListCount') || "0";
   }else if(id === "Shared"){
    this.countData = "Shared";
    this.dpages = localStorage.getItem('SharedListCount') || "0";
   }
  }
  /**
 *  method for step Completed
 * */
stepCompleted(){
  this.iconStepCompleted =true;
  this.beforeStep = true;
}
  /**
 *  variable for showdata
 * */
  showdata:any;
 /**
 *  method for CellClicked
 * */
  onCellClicked(data){
    let minidata = {
      "wsoid": Number(this.workSpaceId),
      "emailId":  data.status?.split('|')[2],
      "originalFileName" : data.thumbnailPath
     }

    this.showdata = minidata;

    this.dialogRef = this.dialogService.open(this.miniprofile);

  }
 /**
 *  method for crossminidata
 * */
  crossminidata(){
    this.dialogRef?.close();
}
 /**
 *  method for crossminidata
 * */
reloadDashboard(){
      this.isLoader = true;
      setTimeout(() => {
        this.isLoader = false;
      }, 800);
}

/**
   * ts code for dashboard card started here
   */
  getcountdata;
    /**
 *  variable for user count
 * */
  usercount;
  /**
 *  variable for daily
 * */
  daily;
/**
 *  variable for weekly
 * */
  weekly;
  /**
 *  variable for monthly
 * */
  monthly;
    /**
 *  variable for timeTrackerData
 * */
  timeTrackerData;
  /**
 *  variable for documentName
 * */
  documentName;
   /**
 *  variable for totaldocs
 * */
   totaldocs;
    /**
 *  variable for dpages
 * */
  dpages = "0";
  timeTrackerFlag;
  showView :boolean=true;
  isprofileComplete :any;
  isgeneralSettingComplete :any;
  invitationMembercountComplete :any;
  /**
 *  methods for totaldocs
 * */
    dashboardcards(){

    let usercard = {
      wsoid : Number(this.workSpaceId),
      documentOwner : this.createdBy,
      status: "",
      guid: this.guid,
      date: "",
      flag: "",
      isDismiss: this.passwordboxhidden ? 1 : 0,
      OwnerEmailID : localStorage.getItem('ownerEmailId')
    }
    this.data.dashboardcards(usercard).subscribe((response: any) => {

      if (response.isSuccess) {
        this.isLoader = false;
        this.getcountdata = response?.data;
        this.usercount = this.getcountdata[0].userCount;
        this.daily = this.getcountdata[0].daily;
        this.weekly = this.getcountdata[0].weekly;
        this.monthly = this.getcountdata[0].monthly;
        this.totaldocs = this.getcountdata[0].document;
        this.isDismiss = this.getcountdata[0].isDismiss === 1 ? true : false;
        // this.dpages = this.getcountdata[0].comparePercentage;
        this.timeTrackerData =  this.getcountdata[0].daily;
        this.isprofileComplete = this?.getcountdata[0]?.isprofile === 1 ? true : false;
        this.timeTrackerFlag ='daily';
        if(this.getcountdata[0].invitationMembercount == 1){
          if(window.productFruits.api != undefined){
            window.productFruits?.api?.checklists.markItemAsDone('invite_user', true)
          }
        }
        // this.getcountdata[0].invitationMembercount == 1? window.productFruits.api.checklists.markItemAsDone('invite_user', true):'';
        if( this.getcountdata[0].invitationMembercount >= 1 && this.getcountdata[0].ownerEmailID > 3 &&  this.getcountdata[0].generalSetting >= 1) localStorage.setItem('checklist','done')

        this.sharedservice.productFruitDataTemp.next()


      }
    })
  }

  /**
   * ts code for last Access started here
   */
   checkShareAccess:any[]=[]
  getLastAccess;
  docsImg;
  getDocument:boolean = false;
  getmyQueueDocsCount:boolean = false;
  getSharedWithMeDocsCount:boolean = false;
  fileSize;
  uploadDate;
  thumbnail
  CreatedBy=[];
  docRename=[];
  size=[];
  createdDtm=[];
    /**
 *  methods for lastAccessTab
 * */
  lastAccessTab(){
    let lastAccess ={
      wsoid: Number(this.workSpaceId),
      documentOwner: this.createdBy,
      status: "",
      guid:  this.guid
    }

    this.data.lastAccessTab(lastAccess).subscribe((response: any) => {
      if (response) {
        this.isRefreshed = true
        this.checkShareAccess=[]
        for(let i=0;i<=response.data.length-1;i++){
                    let postData1 = {
            "wsoid": Number(this.workSpaceId),
            "docID": response.data[i].docId,
             "role": "View",
              "database": "get2doxMaster",
              "createdby":this.createdBy,
               "Isdeleteby": false,
                "guid":this.guid,
                 "DocGuid": response.data[i].docGuid
          }
          this.data.get_lastAcess_share_details(postData1).subscribe((data:any)=>{
            response.data[i].shareStatus=data?.data[0].docShareStatus
          })
        }
        this.lastAccessLength = response?.data?.length;
        this.UploadRightsUserList();
        this.dashboardcards();
        for (let i = 0; i <= response.data.length - 1; i++) {
          response.data[i].documentFormat = response.data[i].format
        }
        if (this.lastAccessLength !== 0) {
          this.getDocument = true;

        }
        if (this.DocUploadedLength !== 0) {
          this.getmyQueueDocsCount = true;
        }
        if (this.ShareByMeLength !== 0) {
          this.getSharedWithMeDocsCount = true;
        }
        if(this.lastAccessLength !== 0){
          this.setActiveLast = true;
          this.setActiveOwn = false;
          this.setActiveShared = false;
          this.setActiveUpload = false;
          this.tabtitle = 'Last accessed';
          this.tabActive(this.tabtitle);
          this.cd.detectChanges();
        }
        else{
          if(this.OwnerByMeLength !== 0){
            this.setActiveLast = false;
            this.setActiveOwn = true;
            this.setActiveShared = false;
            this.setActiveUpload = false;
            this.tabtitle = 'Owned by me'
            this.tabActive(this.tabtitle);
            this.cd.detectChanges();
          }
          else{
            if(this.DocUploadedLength !== 0){
              this.setActiveLast = false;
              this.setActiveShared = false;
              this.setActiveOwn = false;
              this.setActiveUpload = true;
              this.tabtitle = 'My Queue'
              this.tabActive(this.tabtitle);
              this.cd.detectChanges();
            }
            else{
              if(this.ShareByMeLength !== 0){
                this.setActiveLast = false;
                this.setActiveOwn = false;
                this.setActiveShared = true;
                this.setActiveUpload = false;
                this.tabtitle = 'Shared with me'
                this.tabActive(this.tabtitle);
                this.cd.detectChanges();
              }
              else{
                this.setActiveLast = false;
                this.setActiveOwn = true;
                this.setActiveShared = false;
                this.setActiveUpload = false;
                this.tabtitle = 'Owned by me'
                this.tabActive(this.tabtitle);
                this.cd.detectChanges();
              }
            }
          }
        }
        this.fullNamelast = response.data.status?.split('|')[1]
        setTimeout(() => {
          this.getLastAccess = this.datePipeTransform?.transform(response).data;
          if( this.getLastAccess){
          this.isLoader = false;
          this.isRefreshed =false
          }
        }, 800);
        this.Count = response?.data?.length;

      }

      }
     ) }

/**
   * ts code for Owned by me , shared with me and My Queue started here
   */

 getCommonTabCard;
 owneddatatab;
 sharedWithMe;
 docsInUpload;
 OwnerByMeLength;
 ShareByMeLength;
 DocUploadedLength;
  /**
 *  methods for commonTab
 * */
 commonTab(){
  var promise = new Promise((resolve, reject) => {
     let commonTabcard = {
       wsoid: Number(this.workSpaceId),
       documentOwner: this.createdBy,
       status: "",
       guid: this.guid
     }

     this.data.commontab(commonTabcard).subscribe((response: any) => {

       if (response.isSuccess) {
         this.OwnerByMeLength = response?.data?.getDashboardOwnerByMe?.length;
         this.ShareByMeLength = response?.data?.getDashboardShareByMe?.length;
         this.DocUploadedLength = response?.data?.getDashboardDocUploaded?.length;
         this.fullNameShared = response.data.getDashboardShareByMe.status?.split('|')[1]
         resolve("Promise Resolved");
         this.owneddatatab    = this.datePipeTransform.transformDashboardDate(response.data.getDashboardOwnerByMe);
         this.sharedWithMe    = this.datePipeTransform.transformDashboardDate(response.data.getDashboardShareByMe);
         this.docsInUpload   = this.datePipeTransform.transformDashboardDate(response.data.getDashboardDocUploaded);
         this.getCommonTabCard = response?.data;


       }
     })
   })
 }
   /**
 *  methods for get Tool tip
 * */
getTooltip(data){
  let tooltipData = data?.size?.split("|")[1];
  return 'Size: '+ tooltipData
}

/**
 * getTransferupdate dynamically
 */

getTransferownershipUpdate(){
  let lastAccess ={
    wsoid: Number(this.workSpaceId),
    documentOwner: this.createdBy,
    status: "",
    guid:  this.guid
  }
  this.data.lastAccessTab(lastAccess).subscribe((response: any) => {
    this.getLastAccess = this.datePipeTransform.transform(response).data;

  })
}
/**
   * ts code for Owned by me , shared with me and My Queue end here
   */


/**
 *
 * @param params metohd is used in document verify
 */
onVerifyDocument(params){
  let postData={
    "wsoid": Number(this.workSpaceId),
    "docID": Number(params?.docId),
    "role": "PendingView",
    "database": "get2doxMaster",
    "createdby": this.createdBy,
    "Isdeleteby": false,
    "guid" : this.getguid ,
    "DocGuid" : params?.docGuid
  }
  this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {
    if (response.isSuccess) {
      if(response.data[0].docShareStatus === 'Not Access') {
        this.openModal(this?.wsOutSync, false);
      } else{
          localStorage.setItem('redirectedFrom',  'dashboard');

      const url = this.router.serializeUrl(
        this.router.createUrlTree(['verification/verify-document'], { queryParams: { docId: params?.docId, docGuid: params?.docGuid } })
      );
      window.open(url, '_blank');
    }
    } else{
      this.openModal(this?.wsOutSync, false);
    }
  }, error => {

  })

}
/**
 * getSelectedRowData
 */

getSelectedRowData(){
  let selectedNodes = this.gridApi.getSelectedNodes();
  let selectedData = selectedNodes.map(node => node.data);


  this.selectedDocs = selectedData;
  this.sizeConvert();
  this.Print.setSelectedDocs(this.selectedDocs)
  return selectedData;

}
/**
 * method for reloadScreen
 */
reloadScreen() {
  window.location.reload();
}
/**
 * method for onEnter
 */
  onEnter(params){
    let postData={
      "wsoid": Number(this.workSpaceId),
      "docID": Number(params?.docId),
      "role": "View",
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid" : this.guid,
      "DocGuid" : params?.docGuid
    }
    this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        if(response.data[0].docShareStatus === 'Not Access') {
          this.openModal(this?.wsOutSync, false);

        } else{
          localStorage.setItem('redirectedFrom', 'dashboard');
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: params?.docId, docGuid: params?.docGuid } })
          );
          window.open(url, '_blank');
        }
      } else{
        this.openModal(this?.wsOutSync, false);
      }

    }, error => {

    });
  }

/**
 * method for upload
 */
  public upload(){
    this.showUploadDialog = true;
    this.sharedService.uploaddata.next()
  }
  /**
 * method for closediagoue
 */
  public closediagoue(){
    this.showUploadDialog = false;
   }
   iconWorkSpaceStepCompleted = false;
   /**
 * method for workSpacepage
 */
   workSpacepage(){
    this.router.navigate(['/pages/workspace-admin/ws-management']);
    this.iconStepCompleted = true;
   }
  /**
 * method for userSetting
 */
   userSetting(){
    this.iconLastStepCompleted =true;
   }

   /**
    *
    * @param input method is used to get recently added document stated here
    */
   timeTrackerHandler(input){

    this.timeTrackerFlag = input;

     if(input == 'daily'){
       this.timeTrackerData = this.daily;
     }
     if(input == 'weekly'){
      this.timeTrackerData = this.weekly
    }
    if(input == 'monthly'){
      this.timeTrackerData = this.monthly
    }
   }
   /**
    *
    * @param input method is used to get recently added document ended here
    */
   invitUserPage(){
    this.router.navigate(['/pages/workspace-admin/users/invited-users']);
   }

   userSettingPage(){
    this.router.navigate(['/pages/workspace-admin/users/invited-users']);
   }

   onDismiss(){
     this.passwordboxhidden=true;
     this.dashboardcards();
   }
   showSlider = false;
   sliderButton(){

     this.showSlider = true;
   }

 /**
    *
    * @param input method is used to get refresh the table stated here
    */
counter;
onrefreshClick(){

    this.isRefreshed = true;
    this.isDisabledButton  = true;

  this.commonTab();
  this.counter = 5;

  const interval = setInterval(() => {

    this.counter--;
    this.isRefreshed = false;
    if (this.counter === 0) {
      clearInterval(interval);
      this.isDisabledButton = false;

    }
  }, 1000);

}




 /**
    *
    * @param input method is used to get refresh the table stated here
    */

   /**
    *
    * @param val this method is used to get image format started here
    * @returns
    */
    getImageUrl(val,docrename){
      let documentFormat = val.documentFormat
      let formats =(docrename)
      let format = formats?.split('.').pop();

      if(format === "Document" || format== 'docx' || format == 'doc' || format == 'txt' || documentFormat ==="Document" || documentFormat == 'docx' ||documentFormat =='doc'
      || documentFormat == 'txt'){
        return 'assets/images/Formatnew/DOC.svg'
      } else if(format === "png" || format === "PNG" || documentFormat == "png" || documentFormat == "PNG"){
        return 'assets/images/Formatnew/PNG.svg'
      } else if(format === "Document" || documentFormat === "Document"){
        return 'assets/images/Formatnew/DOC.svg'
      } else if(format === 'jpg' || format == 'jpeg' || documentFormat === 'jpg' || documentFormat == 'jpeg'){
        return 'assets/images/Formatnew/JPG.svg'
      } else if(format==='tiff' || format=== 'tif'|| documentFormat==='tiff' || documentFormat=== 'tif'){
        return 'assets/images/Formatnew/TIF.svg'
      } else if(format ==='gif' || documentFormat === 'gif'){
        return '/assets/images/Formatnew/GIF.svg'
      }
     else if(format =='pdf' || documentFormat == 'pdf' || documentFormat == 'PDF'){
      return '/assets/images/Formatnew/PDF.svg'
    }
    else if(format ==='rtf' || documentFormat === 'rtf'){
      return '/assets/images/Formatnew/RTF.svg'
    }
    else if(format ==='odt' || documentFormat === 'odt'){
      return '/assets/images/Formatnew/ODT.svg'
    }
    else if(format ==='bmp' || documentFormat === 'bmp'){
      return 'assets/images/Formatnew/BMP.svg'
    }
    else if(format ==='pipeg' || documentFormat === 'pipeg'){
      return 'assets/images/Formatnew/PJPG.svg'
    }
    else if(format ==='pjp' || documentFormat === 'pjp'){
      return 'assets/images/Formatnew/PJP.svg'
    }
    else if(format ==='dib' || documentFormat === 'dib'){
      return 'assets/images/Formatnew/DIB.svg'
    }
    else if(format ==='dot' || documentFormat === 'dot'){
      return 'assets/images/Formatnew/DOT.svg'
    }
    else if(format ==='text' || documentFormat === 'text'){
      return 'assets/images/Formatnew/TEXT.svg'
    }
    else if(format ==='jfif' || documentFormat === 'jfif'){
      return 'assets/images/Formatnew/JFIF.svg'
    }
    else if(format ==='xls' || documentFormat ==='xls'){
      return 'assets/images/Formatnew/XLS.svg'
    }
    else if(format ==='fods' || documentFormat ==='fods'){
      return 'assets/images/Formatnew/FODS.svg'
    }
    else if(format ==='xlsx'  || documentFormat ==='xlsx'){
      return 'assets/images/Formatnew/XLSX.svg'
    }
    else if(format ==='xlsm'  || documentFormat ==='xlsm'){
      return 'assets/images/Formatnew/XLSM.svg'
    }
    else if(format ==='ods'  || documentFormat ==='ods'){
      return 'assets/images/Formatnew/ODS.svg'
    }
    else if(format ==='slk'  || documentFormat ==='slk'){
      return 'assets/images/Formatnew/SLK.svg'
    }
     }
     extensionFormats;
     extensionFormat(val,documentName){

      this.extensionFormats = documentName?.split('.')[1] || val?.documentFormat;
      if(this.extensionFormats === 'pdf'){
        return 'PDF'
      }  else if(this.extensionFormats === 'bmp' || this.extensionFormats === 'tiff' || this.extensionFormats === 'tif' || this.extensionFormats === 'jpg' || this.extensionFormats == 'jpeg' ||
      this.extensionFormats === "png" || this.extensionFormats === "PNG" || this.extensionFormats ==='gif' || this.extensionFormats === 'jfif' || this.extensionFormats === 'pip' || this.extensionFormats === 'dib' || this.extensionFormats === 'pipeg'){
        return 'Image'
      } else {
        return 'Document'
      }
     }
    /**
    *
    * @param val this method is used to get image format started here
    * @returns
    */

     cancleEdit(){
      this.dialogRef.close();
      this.sharedservice.userSettingEmitter.next('');
    }
  sort() {
       this.getLastAccess.sort((a, b) => a.docRename > b.docRename ? 1 : a.docRename < b.docRename ? -1 : 0)
}

  showAscendings = false;
  showName = false;
  showIcon(val){
    if(val === 'name') {
     this.showName= true
    } else {
      this.showName = false
    }
    this.showAscendings = !this.showAscendings
    this.cd.detectChanges();
  }
  linkClick(event){
if(event === 'User settings'){
   this.sharedservice.userSettingEmitter.next('');

}
if(event === 'User profile'){
  this.sharedservice.userProfileEmitter.next('');

}
  }
  showAscendingsOwn = true;
  showNameOwn = false;
  showIconOwn(val){
    if(val === 'name') {
     this.showNameOwn= true
    } else {
      this.showNameOwn = false
    }
    this.showAscendingsOwn = !this.showAscendingsOwn
    this.cd.detectChanges();
  }
  showAscendingsShare = true;
  showNameShare = false;
  showIconShare(val){
    if(val === 'name') {
      this.showNameShare= true
    } else {
      this.showNameShare = false
    }
    this.showAscendingsShare = !this.showAscendingsShare
    this.cd.detectChanges();
  }
  showAscendingsUpload = true;
  showNameUpload = false;
  showIconUpload(val){
    if(val === 'name') {
      this.showNameUpload= true;
    } else {
      this.showNameUpload = false
    }

    this.showAscendingsUpload = !this.showAscendingsUpload
    this.cd.detectChanges();
  }
  isSelectedOnceDetailsEdit;
  onEditClick() {


      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(this.rowDatas.docId),
        "role": "Edit",
        "database": "get2doxMaster",
        "createdby": this.createdBy,
        "Isdeleteby": false,
        "guid": this.getguid,
        "DocGuid": this.rowDatas.docGuid
      }
      this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (response.data[0].docShareStatus === 'Not Access') {
            this.openModal(this.wsOutSync, false);
          } else {
            localStorage.setItem('redirectedFrom', 'dashboard')
            localStorage.setItem('isSave', 'false')
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['edit/edit-document'], { queryParams: { docId: this?.rowDatas?.docId, docGuid: this?.rowDatas?.docGuid } })
            );

            window.open(url, '_blank');
          }

        } else {

        }

      }, error => {

      })

  }
  onViewClickThree(rowdata?) {


      let postData = {
        "wsoid": Number(this?.workSpaceId ),
        "docID": Number(this?.rowDatas?.docId)|| Number(rowdata?.docId),
        "role": "View",
        "database": "get2doxMaster",
        "createdby": this?.createdBy,
        "Isdeleteby": false,
        "guid": this.getguid,
        "DocGuid": this?.rowDatas?.docGuid || rowdata?.docGuid
      }
      this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (response.data[0].docShareStatus === 'Not Access') {
            this.openModal(this.wsOutSync, false);
          } else {
            localStorage.setItem('redirectedFrom', 'dashboard')
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['view/view-document'], { queryParams: { docId: (this?.rowDatas?.docId || rowdata?.docId), docGuid: (this?.rowDatas?.docGuid ||  rowdata.docGuid) } })
            );
            window.open(url, '_blank');
          }
        } else {
          this.openModal(this.wsOutSync, false);
        }

      }, error => {

      });

  }
  doclist;
  openModeldownloadThree() {

      this.selectedDocs = [];
      this.selectedFileNames = this?.rowDatas?.originalFileName;
      this.doclist = this.rowDatas?.docId?.toString();
      this.selectedDocs.push(this.rowDatas);
      const body = this.getRoleAccessBody(this.rowDatas?.docId, this.rowDatas?.docGuid, 'download');
      this.isSelectedOnceDetailsDownload = true;
      this.ownedservice.viewUserWiseRoleAccess(body).subscribe((response: any) => {
        if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {
          this.openModal(this.wsOutSync, false);
        } else {
          if(this.activeTab === 'Last accessed'){

            this.sharedservice.downlaodButtonThreeDotOwnedBy.next();

          }
          else {
            this.sharedservice.downlaodButtonThreeDotOwnedBy.next();
          }
        }
      });
  }


  private getRoleAccessBody(docId, docguid, role) {
    return {
      wsoid: Number(this.workSpaceId),
      docID: Number(docId),
      role: role,
      database: 'get2doxmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      DocGuid: docguid
    };
  }
  openModelPrintThree() {
      const body = this.getRoleAccessBody(this.rowDatas?.docId, this.rowDatas?.docGuid, 'Print')
      this.isSelectedOnceDetailsPrint = true;
      this.ownedservice.viewUserWiseRoleAccess(body).subscribe((response: any) => {
        if (response.data && response.data[0].docShareStatus === 'Not Access') {
          this.openModal(this.wsOutSync, false);
        } else {
          this.selectedDocs = [];
          if(this.activeTab === 'Last accessed'){

           this.selectedFileNames = this?.rowDatas?.docRename
          }
          else if(this.activeTab === 'Owned by me'){
            this.selectedFileNames = this?.rowDatas?.documentName
          }
          else if(this.activeTab === 'Shared with me'){
            this.selectedFileNames = this?.rowDatas?.documentName
          }

          let pribtObj = {fileName : this.selectedFileNames , loader : true,docId : this?.rowDatas?.docId}
          this.selectedDocs?.push(this.rowDatas);
          this.printservice?.setSelectedDocs(this.selectedDocs);
          this.printChild.checking(pribtObj)
          this.printservice?.validateAndprintDoc();
          this.sharedService?.printService?.next(true);
        }
      })
  }
  openModalGuest() {
   if(window.location.href.includes("dashboard")) {
      this.isSelectedGuest = true;
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "docID": Number(this.rowDatas.docId),
        "role": "View",
        "database": "get2doxMaster",
        "createdby": this.createdBy,
        "Isdeleteby": false,
        "guid": this.getguid,
        "DocGuid": this.rowDatas.docGuid
      }
      let guestShareEventData= []
      guestShareEventData.push(this.rowDatas)
      this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

        if (response.isSuccess) {
          if (response.data[0].docShareStatus === 'Not Access' || this.rowDatas.docOwnerEmailId === this.createdBy) {
            if(this.modalGuestOpen == true) {
            if (localStorage.getItem('isGuestAccess') == 'true') {
              this.guestChild.open(guestShareEventData,"dashboard");
            } else {
              this.guestChild.openNonEdit(this.rowDatas);
            }
          }
          } else {
            if(this.modalGuestOpen == true) {
            if (localStorage.getItem('isGuestAccess') == 'true') {
              this.guestChild.open(guestShareEventData,"dashboard");
            } else {
              this.guestChild.openNonEdit(this.rowDatas);
            }
          }
          }
          this.modalGuestOpen = false;
          setTimeout(() => {
            this.modalGuestOpen = true;
          },3000)
        }

      }, error => {

      })
    }
  }
  openModelOwnerShipTransferThreeDot() {

      this.selectedFileNames = '';
      this.doclist = '';
      this.docguidlist = '';
      this.selectedFileNames = this.rowDatas.originalFileName;
      this.numberofDocsSelected = 1;
      this.doclist = this.rowDatas.docId.toString();
      this.docguidlist = this.rowDatas.docGuid.toString();

        this.isSelectedOnceDetails = true;

        const body = this.getRoleAccessBody(
          this.rowDatas.docId,
          this.rowDatas.docGuid,
          'OwnerShipTransfer'
        );
        this.ownedservice.viewUserWiseRoleAccess(body).subscribe((response: any) => {
          if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {
            this.openModal(this.wsOutSync, false);

          } else {
            this.openTemplate(this.transferOwnership);
          }
        });

  }
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.selectedDocs = [];
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });

  }
  public openTemplate(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }
  selectnewverifier;
  selectedOwnerEmail;
  selectedOwnername;
  selectedOwner;

  onChangeVerifier(event) {
    this.selectedOwnerEmail = event.emailId;
    this.selectedOwnername = event.displayName;
    this.selectnewverifier = event.fullName;
  }
  docguidlistdata = [];
  onDocumentVerifierUpdate(){

    this.docIdList=[];
    this.docguidlistdata = [];
    this.selectedDocs.push(this.rowDatas)
    this.selectedDocs.forEach(element => {
     this.docIdList.push(element.docId);
     this.docguidlistdata.push(element.docGuid);
   })

   if(this.selectedDocs?.length == 1){
     this.oldverifier = this.selectedDocs[0].verifiedByEmailId;
     this.docowner = this.selectedDocs[0].docOwnerEmailId;
   }

   var st = window.location.toString();
   var arr = st?.split("/");
   let postData;

       postData = {
         "Wsoid": Number(this.workSpaceId),
         "WsLink": arr[2],
         "DocIdList": this.docIdList.join('|'),
         "DocCount": this.docIdList?.length.toString(),
         "OldVerifierEmailId": this.createdBy,
         "OldVerifierName": this.fullName,
         "VerifierEmailId": this.selectedOwnerEmail,
         "OriginalFileNameList": this.rowDatas.documentName,
         "ModifiedBy": this.createdBy,
         "Separator": "|",
         "guid" : this.getguid,
         "DocGuid" : this.docguidlistdata.join('|')
       }
       this.doccountval = this.docIdList?.length;
       this.newverifier = this.selectedOwnerEmail;

       this.datamyqueue.UpdateDocumentVerifier(postData).subscribe((response: any) => {
         if (response.isSuccess ) {

           this.toastrService.primary('Verifier of the selected document(s) changed');
           this.commonTab();

           this.selectedOwnerEmail='';
         }
         else{
           const iconConfig: NbIconConfig = { icon: '' };
           this.toastrService.primary(response.returnMessage, iconConfig);

           this.MyDocList('ngOnInit');
           this.selectedOwnerEmail='';
           this.filteredRightUserList = this.rightUserList;
         }
       });

   }
  /*
   transfer ownership modal starts
*/
  selectedDocumentOwner = null;
  confirmOwnerShipTransfer() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;
    postData = {
      "wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "docIdList": this.doclist,
      "docCount": 1,
      "newOwnerEmailId": this.documentOwnerEmail,
      "originalFileNameList": this.rowDatas.documentName,
      "modifiedBy": this.createdBy,
      "separator": "|",
      "guid": this.getguid,
      "DocGuid": this.rowDatas.docGuid
    }
    this.senddetailsdata = '';
    this.isSelectedOnceDetails = false;
    this.ownedservice.transferOwnershipService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.dialogRef.close();
        this.selectedDocumentOwner = null;
        this.toastrService.primary("Document ownership transferred to " + this.documentOwnerName);
        this.getTransferownershipUpdate();
        this.onrefreshClick();
        this.commonTab();      }


    });
    this.dialogRef.close();
  }

  closeModal() {
    this.selectedDocumentOwner = null;
    this.dialogRef.close();
    this.isSelectedOnceDetails = false;

  }
  uploadRightUserList;
  filteredRightUserList =[];
  filteredRightUserLists =[];
  ownerTransferUsers;
  UploadRightsUserList() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }
    this.pendingRequest = this.ownedservice.searchAllUsersService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.rightUserList = response?.data;
        this.allUserLength = response.data.length;
        this.commonTab();
        this.ownerTransferUsers = response?.data?.filter(
        loc => (loc.emailId != this.createdBy && !loc.roleName.includes("Tally")));

        this.uploadRightUserList = response.data;

        for (let user of this.uploadRightUserList) {

          user['custom'] = user.fullName;
        }
        this.filteredRightUserList = [];
        this.filteredRightUserLists = [];

        this.rightUserList.forEach(value => {
          // if (value.category !== "Owner") {
            this.filteredRightUserList.push(value);
          // }
        });
        this.filteredRightUserLists = [...new Set(this.filteredRightUserList)];
      }
    }, error => {

    })
  }
  onDocumentOwner(event) {

    this.documentOwnerName = event.displayName;
    this.documentOwnerEmail = event.emailId;
  }
  onVerifyClick() {

        this.selectedDocs = []
        this.selectedDocs.push(this.rowDatas);
    let postData={
      "wsoid": Number(this.workSpaceId),
      "docID": Number(this.rowDatas.docId),
      "role": "PendingView",
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid" : this.getguid ,
      "DocGuid" : this.rowDatas.docGuid
    }
    this.pendingRequest = this.ownedservice.viewUserWiseRoleAccess(postData).subscribe((response: any) => {

      if (response.isSuccess) {
        if(response?.data[0].docShareStatus === 'Not Access') {
          this.openModal(this.wsOutSync, false);
        } else{
          localStorage.setItem('redirectedFrom', 'dashboard')

            const url = this.router.serializeUrl(
            this.router.createUrlTree(['verification/verify-document'],
            { queryParams: { docId: this?.rowDatas?.docId, docGuid: this?.rowDatas?.docGuid } }
            )
          );

          window.open(url, '_blank');
        }
      } else{
        this.openModal(this.wsOutSync, false);
      }

    }, error => {

    })

  }
  isSelectedOnceDetailsDelete = false;
  openDeleteModalThree(params) {
    localStorage.setItem("selectedDocsNumber", "1");
    if (!this.isSelectedOnceDetailsDelete && this?.rowDatas?.docId == params?.docId) {
      this.isSelectedOnceDetailsDelete = true;
      this.checkIfDocExists(this.rowDatas);

    }

  }
  getLastaccessNew(){
    let lastAccess ={
      wsoid: Number(this.workSpaceId),
      documentOwner: this.createdBy,
      status: "",
      guid:  this.guid
    }
    this.isRefreshed = true;
    this.data.lastAccessTab(lastAccess).subscribe((response: any) => {
      if (response) {
        this.getLastAccess = [];
        this.getLastAccess = response?.data;



    }
    })
  }
  /*
    *Document selected event check
  */
  resetSelected(event) {

    if (event) {
      this.selectedDocs = [];
    }
    this.isSelectedOnceDetailsDelete = false;
  }
checkIfDocExists(val) {
  let flag = false;

  let postData: any;
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "OriginalFileName": "",
    "startDate":  "",
    "endDate": "",
    "documentFormat": "",
    "uploadedBy": "",
    "verifiedBy": "",
    "createdBy": this.createdBy,

  }
  this.senddetailsdata = '';
  this.ownedservice.getOwnedByMeDocListData(postData).subscribe((response: any) => {


    if (response.isSuccess) {
      let docId = this?.rowDatas?.docId;
      let resp = [];
      if(response){
        resp = response?.data?.filter((e)=>{

          return e?.docId == this?.rowDatas?.docId;
        })
      }


      if(response){
          flag= true;
          this.selectedDocs = [];
          this.selectedDocs.push(this.rowDatas);
          this.selectedFileNames = this.selectedDocs[0].documentName;
          this.doclistDelete = this.selectedDocs[0].docId;
          this.numberofDocsSelected = this.selectedDocs?.length;
          this.deleteChild.callAuthCheck();
          this.deleteChild.notifyInsertDownlaodSinfle();

      }
      else{

        this.openModal(this.wsOutSync, false);
      }
      this.showSpinner = false;
    }
    else{
      this.showSpinner = false;
      this.senddetailsdata =  '';
    }
    if (!response.isSuccess) {
      this.showErrorMessage = true;
      this.errorMessage = 'There is no data available matching your search parameters.';
      this.errorMessagesm = 'Try another search or use filters to refine your search.';
      this.senddetailsdata =  '';
        this.sendetailvalue(this.senddetailsdata);
    }
  })
}
sizeConvert() {
  this.selectedDocSize = 0;
   this.selectedDocs.forEach(element => {
     if(element.size.includes('KB')) {
       this.selectedDocSize += Number(element.size?.split(" ")[0]);
     }
     else if(element.size.includes('MB')){
      this.selectedDocSize += Number(element.size?.split(" ")[0]) * 1024;
     }
     else if(element.size.includes('GB')){
      this.selectedDocSize += Number(element.size?.split(" ")[0] )* 1024 * 1024;
     }
   });
}
sizeApi(){
  let postData: any
  postData = {
  "Wsoid": Number(this.workSpaceId),
  "docStatus": this.deleted,
  "modifiedBy": this.createdBy,
  "guid" : localStorage.getItem('loginguid')
  }
  this.ownedservice.SizeTrashStatusService(postData).subscribe((response: any) => {
  if (response.isSuccess) {
    this.returnQuote = response.request;
    this.myvalue= this.returnQuote.trashFileSize;
    this.fileCount = this.returnQuote.trashCount;
    this.totalWSCount = this.returnQuote.binSizeValue;
    this.percentageValue=this.returnQuote.wsSizePercentage;
    this.availableTrashSize = 0;
  if(this.totalWSCount && this.totalWSCount.includes('KB')) {
    this.availableTrashSize = Number(this.totalWSCount?.split("K")[0]);
  }
  else if(this.totalWSCount && this.totalWSCount.includes('MB')){
   this.availableTrashSize = Number(this.totalWSCount?.split("M")[0]) * 1024;
  }
  else if(this.totalWSCount && this.totalWSCount.includes('GB')){
   this.availableTrashSize = Number(this.totalWSCount?.split("G")[0] )* 1024 * 1024;
  }
  if(this.myvalue){
    if(this.myvalue.includes('KB')) {
      this.occupiedTrashSize = Number(this.myvalue?.split(" ")[0]);
    }
    else if(this.myvalue.includes('MB')){
     this.occupiedTrashSize = Number(this.myvalue?.split(" ")[0]) * 1024;
    }
    else if(this.myvalue.includes('GB')){
     this.occupiedTrashSize = Number(this.myvalue?.split(" ")[0] )* 1024 * 1024;
    }
    else{
      this.occupiedTrashSize = Number(this.myvalue);
    }
  }

  }
  })

  }
  activeTab;
  chkdownloadprint;
  chkeditrights;
  checkDeleteRights;
  downloadprint;
  editrights;
  deleteRights;
  tabActive(id){
    this.activeTab = id?.tabTitle || id;
    if(localStorage.getItem('downloadandprint')){
      this.chkdownloadprint = localStorage.getItem('downloadandprint')
      this.downloadprint = JSON?.parse(this?.chkdownloadprint);
    }
    if(localStorage.getItem('editaccess')){
      this.chkeditrights = localStorage.getItem('editaccess')
      this.editrights = JSON.parse(this.chkeditrights);
    }
    this.GuestFlag = localStorage.getItem('GuestFlag');
    if(localStorage.getItem('deleteaccess')){
      this.checkDeleteRights = localStorage.getItem('deleteaccess')
      this.deleteRights = JSON.parse(this.checkDeleteRights);
    }
    if(id?.tabTitle === 'Last accessed' ){
      this.items=[{}]
      this.disableSorting = true;
    }
    if(id?.tabTitle === 'Owned by me' || id === 'Owned by me'){
      this.ownByMeThreeDot();
    }
    if(id?.tabTitle === 'Shared with me' || id === 'Shared with me'){
      this.sharedWithMeThreeDot()
  }
    if(id?.tabTitle === 'My Queue'){
      this.items=[]
     this.items.push( {
      title: 'Open Document',
      icon: { icon: 'external-link-outline', pack: 'eva' },
    },
    {
      title: 'Change Verifier',
      icon: { icon: 'person-outline', pack: 'eva' },
    },
    {
      title: 'Share',
      icon: { icon: 'share-outline', pack: 'eva' },
    },

    {
      title: 'Delete',
      icon: { icon: 'trash-2-outline', pack: 'eva' },
    })}

    //Remove Share With Guests when no guest access plan
    let GuestAccess=localStorage.getItem('GuestFlag');
    if(GuestAccess!='True' || !this.isGuestAccessInRole)
    {
      for(let i=0; i<this.items.length; i++)
      {
        if(this.items[i].title=='Share with guests')
        {
          this.items.splice(i,1)
        }
      }
    }

  }
  ownByMeThreeDot(){
    this.items = []
    this.items.push(
      {
        title: 'Open Document',
        icon: { icon: 'external-link-outline', pack: 'eva' },
      },
      {
        title: 'Download',
        icon: { icon: 'download-outline', pack: 'eva' },
      },
      {
        title: 'Print',
        icon: { icon: 'printer-outline', pack: 'eva' },
      },
      {
        title: 'Share',
        icon: { icon: 'share-outline', pack: 'eva' },
      },
      {
        title: 'Transfer ownership',
        icon: { icon: 'swap-outline', pack: 'eva' },
      },
      {
        title: 'Delete',
        icon: { icon: 'trash-2-outline', pack: 'eva' },
      },
      )
    if (!this.downloadprint) {
      this.items = this.items.filter((obj) => obj.title !== "Download");
    }

    if (this.GuestFlag === 'True') {
      this.items.push(
        {
          title: 'Share with guests',
          icon: { icon: 'people-outline', pack: 'eva' },
        })
    }
    if (!this.downloadprint) {
      this.items = this.items.filter((obj) => obj.title !== "Print");
    }


    //Remove Share With Guests when no guest access plan
    let GuestAccess=localStorage.getItem('GuestFlag');
    if(GuestAccess!='True' || !this.isGuestAccessInRole)
    {
      for(let i=0; i<this.items.length; i++)
      {
        if(this.items[i].title=='Share with guests')
        {
          this.items.splice(i,1)
        }
      }
    }

    
  }
  sharedWithMeThreeDot(){

    this.items = []
    this.items.push(
      {
        title: 'Open Document',
        icon: { icon: 'external-link-outline', pack: 'eva' },
      })
    if (this.downloadprint) {
      this.items.push({
        title: 'Download',
        icon: { icon: 'download-outline', pack: 'eva' },
      });
      this.items.push({
        title: 'Print',
        icon: { icon: 'printer-outline', pack: 'eva' },
      });
    }
    if (this.editrights) {
      this.items.push(
        {
          title: 'Share',
          icon: { icon: 'share-outline', pack: 'eva' },
        },
      );
    }
    if (this.deleteRights) {
      this.items.push({
        title: 'Delete',
        icon: { icon: 'trash-2-outline', pack: 'eva' },
      });

    }
    if (this.GuestFlag === 'True') {
      this.items.push(
        {
          title: 'Share with guests',
          icon: { icon: 'people-outline', pack: 'eva' },
        })
    }


    //Remove Share With Guests when no guest access plan
    let GuestAccess=localStorage.getItem('GuestFlag');
    if(GuestAccess!='True' || !this.isGuestAccessInRole)
    {
      for(let i=0; i<this.items.length; i++)
      {
        if(this.items[i].title=='Share with guests')
        {
          this.items.splice(i,1)
        }
      }
    }


  }
     /**
   * method for open show delete users
   */
  showDeleteUser() {

    var flag = false;
    this.apiService.showDelete = false;
    let isSharedRights = localStorage.getItem('isSharedRights');

    if ((this.rowDatas.status?.split('|')[2] == this.createdBy) || (this.deleteRights)) {
      flag = true;
      let isAllOwned = true;
      localStorage.setItem('isAllOwned', JSON.stringify(isAllOwned))
    }
    this.apiService.showDelete = flag;
    this.sharedWithMeThreeDot();

  }
     /**
   * method for open modal for change verifer.
   */
  onChangeVerifierClicked(){

        this.selectedDocs = []
        this.selectedDocs.push(this.rowDatas);
        this.documentOwnerName = this.rowDatas?.status?.split('|')[1];
        this.filteredRightUserList = this.filteredRightUserList?.filter((obj)=>{
          return obj?.displayName !==  this.documentOwnerName;
        })
        this.openModal(this.chngVerifier, false)


  }
  HideViewDetailresponseForDelete = true;
   /**
   * method for open delete modal
   */
  onDeleteDocs(data) {
    if (this.rowDatas && this.rowDatas.originalFileName == data.originalFileName && this.HideViewDetailresponseForDelete == true) {

      this.isdeleteSelectedOnce = true;
      this.selectedDocs = [];
      this.selectedDocs.push(this.rowDatas);
      this.open(this.deleteDocs);
      this.HideViewDetailresponseForDelete = false;

    }
  }
   /**
   * method for open modal
   */
  open(dialog: TemplateRef<any>) {

    this.  selectedOption='Poor scan quality';
    this.Otherreason=false;
    this.dialogService.open(dialog, { closeOnBackdropClick: false, autoFocus: true})
    this.element = document.getElementById('deleteModal');
    this.element.focus();
  }
   /**
   * method value Change
   */
valueChange(reason:any){
  if( reason=='Other')
  {this.Otherreason=true; }
else{this.Otherreason=false;}
}
Otherreason:boolean=false;
orignalFileNameList=[];
@ViewChild('OtherReason') OtherReason!:ElementRef
deletedoccount;
isAllStatus:any;
  /**
   * method Delete Docs Modal Resion
   */
DeleteDocsModalResion(){
  this.HideViewDetailresponseForDelete = true;
  this.docIdList=[];
  this.docguidlist = [];
   this.selectedDocs.forEach(element => {
    this.docIdList.push(element.docId);
    this.docguidlist.push(element.docGuid);
    this.orignalFileNameList.push(element.documentName);
  })

if(this.selectedDocs?.length >= 1){
  this.oldverifier = this.selectedDocs[0].verifiedByEmailId;
  this.docowner = this.selectedDocs[0].docOwnerEmailId;
  this.uploaderemail = this.selectedDocs[0].uploadedByEmailId;
  this.verifiernamedelete = this.selectedDocs[0].verifiedByName;

  var st = window.location.toString();
  var arr = st?.split("/");

  let postData;
if(this.selectedOption=='Other'){
this.selectedOption= this.OtherReason.nativeElement.value
}
  postData = {
    "Wsoid": Number(this.workSpaceId),
    "WsLink": arr[2],
    "DocIdList": this.docIdList.join('|'),
    "OriginalFileNameList": "|",
    "ModifiedBy": this.createdBy,
    "DeleteReason": this.selectedOption,
    "Separator": "|",
    "guid" : this.getguid,
    "DocGuid" : this.docguidlist.join('|'),
  }
 this.deletedoccount = this.docIdList?.length;
  this.pendingRequest = this.datamyqueue.deleteMyDocService(postData).subscribe((response: any) => {

    if (response.isSuccess) {

      const iconConfig: NbIconConfig = { icon: '' };
      this.toastrService.primary(this.selectedDocs?.length +' Document(s) deleted.', iconConfig);

      this.commonTab();

    }
    else{

      const iconConfig: NbIconConfig = { icon: '' };
      this.toastrService.primary(response.returnMessage, iconConfig);
       this.commonTab();
    }
  }, );

}
}
filtercardclick = "";
  /**
   * method filter data
   */
filterdata(data) {
  this.filtercardclick = data;
}
  /**
   * method Modal data
   */
Modaldata(data) {
  this.modalClick = data;
}
getfilterid = "";
getfilterids = "";
modalClick="";
outsideclick;
@HostListener('document:click', ['$event'])
  /**
   * method document Click
   */
documentClick(event) {

  if (this._eref.nativeElement.contains(event.target)) {

    if (!this.getfilterid) {
      if (this.filtercardclick != "clickinsidefilter" && event.pointerType !== '') {

        this.HighlightRow = null;
      }
      else {
        this.filtercardclick = '';
      }
    }
    else {
      this.getfilterid = '';
    }
    if (!this.getfilterids) {
      if (this.modalClick != "clickinside") {
        this.isSelectedOnceDetailsShare = false;
        this.HideViewDetailresponseForDelete = true
        this.isSelectedOnceDetailsDelete = false;
      }
      else {
        this.modalClick = '';
      }
    }
    else {
      this.getfilterids = '';
    }
  }
}
   /**
   * method Verify Doc Details
   */
onVerifyDocDetails(params){
  var st = window.location.toString();
  var arr = st?.split("/");
  let postdata={
    "DocGuid" :params?.docGuid,
    "DocID": params?.docId,
    // "WsLink": arr[2],
    "Wsoid": Number(localStorage.getItem('localwsId')),
    "currentUser":localStorage.getItem('localloginId'),
    }
       this.data1.verifierDocDetailsService(postdata).subscribe((customTags:any)=>{
        if (customTags.isSuccess) {
          this.isAllStatus = customTags?.data.docverificationdetailslists[0].isAllUserShare;
          this.sharedService.isShare.next(this.isAllStatus);
        }
       })
}
  @ViewChild('shareChild') shareuse: SharedAccessComponent;
  isSelectedOnceDetailsShare = false;
    /**
   * method open Share Modal
   */
  openShareModal(params) {

    if (this.rowDatas.docId == params?.docId) {
      this.getSharedWithData(this.rowDatas.docId)
      this.verifierEmailID = this.rowDatas?.status?.split('|')[3];
      this.ownerEmailID = this.rowDatas.status?.split('|')[2];
      this.isAllUserShare = false
      this.selectedDocs = [];
      this.selectedDocs.push(this.rowDatas);
      const body = this.getRoleAccessBody(this.rowDatas.docId, this.rowDatas.docGuid, 'share');
      this.isSelectedOnceDetailsShare = true;
      this.onVerifyDocDetails(params)
      setTimeout(() => {
        this.shareuse.shareUsers(params?.docId);
      }, 500);
      // this.data.viewUserWiseRoleAccess(body).subscribe((response: any) => {
      //   if (response.data[0] && response.data[0].docShareStatus === 'Not Access') {
      //     this.openModal(this.wsOutSync, false);
      //   } else {
      //     this.shareuse.shareUsers(params?.docId);
      //   }
      // });
    }
  }
    /**
   * method isSelectedAllUser
   */
  isSelectedAllUser($event) {
    this.isSelectedAllUsers = $event;
  }
    /**
   * method sharedGroupCount
   */
  sharedGroupCount($event) {
    this.shareuserCount = $event;
  }
    /**
   * method isCheckedAllUser
   */
  isCheckedAllUser($event) {
    this.isCheckedAllUsers = $event;
    this.isAllUserShare = $event;
  }
    /**
   * method sharedGroup
   */
  sharedGroup($event) {
    this.setUserGroupArr = $event;
    if (this.setUserGroupArr.length > 0 && !this.isCheckedAllUsers) {
      this.isAllUserShare = false;
    }
    if (this.setUserGroupArr.includes(this.verifierName)) {
      this.showVerifier = true;
    } else {
      this.showVerifier = false;
    }

  }
  /**
   * method isUserGrp
   */
  isUserGrp($event) {
    this.isUsersGroup = $event;
    this.cd.detectChanges();
  }
  /**
   * method getSharedWithData
   */
  getSharedWithData(docid) {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(docid),
      "database": "get2doxMaster",
    }
    this.pendingRequest = this.data.getSharedWithModalDataForView(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sharedWithUsers = response.data;
        this.setUserGroupArr = this.sharedWithUsers;
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(true);
        this.sharedService.selectedAccessEditor.next(response.data);
        this.cd.detectChanges();
      }

      else {
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(false);
      }
    })
  }
  format1
  maxFileSizeUploadUnit;

}



