import { Injectable } from '@angular/core';
import { of as observableOf,  Observable } from 'rxjs';
import { TrafficChartData } from '../data/traffic-chart';
/**
 * The injectable 
 */
@Injectable()
/**
 * traffic data class export
 */
export class TrafficChartService extends TrafficChartData {
/**
 * array for the traffic data
 */
  private data: number[] = [
    300, 520, 435, 530,
    730, 620, 660, 860,
  ];
/**
 * method for traffic Chart
 */
  getTrafficChartData(): Observable<number[]> {
    return observableOf(this.data);
  }
}
