 <!-------------------------download doc modal start-------------------->
 <ng-template #downloadOptionPDForORG let-data let-ref="dialogRef">
  <nb-card class="width-40vw">
      <nb-card-header>
          <div class="justify-content-between d-flex">
              <h6 class="subtitle text-basic">
                Download document(s)
          </h6>

          </div>
      </nb-card-header>
      <nb-card-body>
        <p class="paragraph text-basic mb-2">Choose a format to download selected document(s).</p>
        <p class="subtitle text-primary">
          Size of document : {{downloaddocsize}}
        </p>
        <div class="d-flex justify-content-center align-items-center">

          <div class="d-flex ml-3 mr-3 justify-content-center align-items-center">
              <div *ngIf="extensiondownload == 'png'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('png');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/PNG.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload=='jpg' || extensiondownload == 'jpeg'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('jpg');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/JPG.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div *ngIf="extensiondownload == 'jfif'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('jfif');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/JFIF.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="(extensiondownload == 'pipeg' || extensiondownload == 'pjpg')" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('pipeg');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/PJPG.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload == 'pjp'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('pjp');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/PJP.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload == 'dib'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('dib');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/DIB.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div *ngIf="extensiondownload=='tiff' || extensiondownload== 'tif'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('tiff');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/TIF.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div *ngIf="extensiondownload=='gif'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('gif');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/GIF.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div *ngIf="extensiondownload =='Document' || extensiondownload== 'docx' || extensiondownload == 'doc'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('Document');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/DOC.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2" style="text-transform: initial;">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='txt'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('txt');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/TXT.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='rtf'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('rtf');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/RTF.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='odt'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('odt');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/ODT.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='bmp'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('bmp');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/BMP.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='ods'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('ods');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/ODS.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='slk'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('slk');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/SLK.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='xlsx'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('xlsx');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/XLSX.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='xlsm'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('xlsm');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/XLSM.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='fods'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('fods');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/FODS.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload =='xls'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('xls');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/XLS.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div *ngIf="extensiondownload == 'dot'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('dot');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/DOT.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>
              <div *ngIf="extensiondownload == 'text'" [ngClass]="[firstrender ? 'selectbox': 'notselectbox']" (click)="getimagevalue('text');fileFormatselected('original')">
                <img src="../../../assets/images/Formatnew/TEXT.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Original format</p>
              </div>

              <div [ngClass]="[secondrender ? 'selectbox2': 'notselectbox2']" (click)="getimagevalue('pdf');fileFormatselected('pdf')">
                <img src="../../../assets/images/Formatnew/PDF.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">PDF</p>
              </div>
              <div [ngClass]="[fourthrender ? 'selectbox4': 'notselectbox4']" *ngIf="(downloadExtractedTextPdf == 'True') && (statuscode == 'doc_08') && (notShowExtrated == '')" (click)="getimagevalue('txt');fileFormatselected('txt')">
                <img src="../../../assets/images/Formatnew/TXT.svg" alt="" class="p-3 svgSize" />
                <hr class="m-0">
                <p class="originalcolor mt-2 mb-2">Extracted Text</p>
              </div>


           </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" class="textbutton mr-3"
        (click)="downlaodSingleConfirmation();ref.close();" size="small">DOWNLOAD</button>
        <button nbButton class="textbutton cancelbtn" size="small"
        (click)="closeModal();ref.close()">Cancel</button>
      </nb-card-footer>
  </nb-card>
</ng-template>
<!------------------------------download doc modal end-------------------------->

<!---------------------------daily limit exceeded modal start---------------------------->
<ng-template #dailyLimitEcxeeded let-data let-ref="dialogRef">
  <nb-card class="invitemodal width-40vw">
      <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-primary">
                Daily Download Limit Exceeded
              </h6>

                  <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>

          </div>
      </nb-card-header>
      <nb-card-body>
        <p class="paragraph text-basic">The daily download limit in your workspace has been exceeded.</p>
      </nb-card-body>
      <nb-card-footer>
          <button nbButton status="primary" class="textbutton" size="small"
          (click)="closeModal()">OK</button>

      </nb-card-footer>
  </nb-card>
</ng-template>
<!-------------------------daily limit exceeded modal end------------------------>

<!--------------------selected files exceeded daily limit modal start------------------------>
<ng-template #selectedLimitEcxeeded let-data let-ref="dialogRef">
  <nb-card class="invitemodal width-40vw">
      <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-basic">
                Download limit exceeded
            </h6>
                  <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>

          </div>
      </nb-card-header>
      <nb-card-body>
        <p class="mb-0 paragraph text-basic">You have exceeded your download limit, you can only download {{validateDownloadResponse?.availableDownloadLimit}} files in one go.</p>

      </nb-card-body>
      <nb-card-footer>
          <button nbButton status="primary" class="textbutton" size="small"
          (click)="closeModal()">OK</button>
      </nb-card-footer>
  </nb-card>
</ng-template>
<!--------------------------selected files exceeded daily limit modal end---------------------->


<!-------------------------maximum files exceeded modal start------------------------>
<ng-template #maxSelectionExceed let-data let-ref="dialogRef">
  <nb-card class="invitemodal width-40vw">
      <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-primary">
                Download Limit Exceeded
              </h6>
                  <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>

          </div>
      </nb-card-header>
      <nb-card-body>
        <p class="paragraph text-basic">Remove some files to stay within the download limit.</p><br>
        <p class="text-primary paragraph">Download Limit: {{validateDownloadResponse?.availableDownloadLimit}}</p>
      </nb-card-body>
      <nb-card-footer>
          <button nbButton status="primary" class="textbutton" size="small"
          (click)="closeModal()">Okay</button>
      </nb-card-footer>
  </nb-card>
</ng-template>
<!---------------------maximum files exceeded modal end----------------------->

<!----------------------downlaod multiple docs confirmation modal start----------------------->
<ng-template #multipleSelectionConf let-data let-ref="dialogRef">
  <nb-card class="invitemodal">
      <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-basic">
              Download document(s)
            </h6>


          </div>
      </nb-card-header>
      <nb-card-body>
        <div>
        <p class="paragraph text-basic mb-2">Are you sure you want to download these documents?</p>
        <p class="text-primary paragraph mb-0">{{selectedDocs?.length}} document(s) selected</p>
      </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" class="textbutton mr-3" size="small" (click)="downloadZipConfirmation();dialogRef.close();openWindow();">DOWNLOAD</button>
        <button nbButton class="textbutton cancelbtn" (click)="dialogRef.close();closeModal()" size="small">CANCEL</button>
      </nb-card-footer>
  </nb-card>
</ng-template>
<!-----------------------downlaod multiple docs confirmation modal end------------------------>

<ng-template #contentTemplate let-data let-ref="windowRef">
  <div class="col-12 d-flex p-0" *ngFor="let filename of downloadarrayname">
    <div class="col-11 p-0">
      <p class="paragraph-2 text-primary" >
        {{filename.name}}
      </p>
    </div>
    <div class="col-1 dataalign" *ngIf="filename.percent < 100">
      <progress class="pure-material-progress-circular"></progress>
    </div>
    <div class="col-1 dataalign checkiconcolor" *ngIf="filename.percent  == 100">
      <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
    </div>
  </div>

</ng-template>



