import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { ApiServiceService } from './api-service.service';
import { HttpClient } from '@angular/common/http';
/**
 *  Injectable for workspace admin service 
 */
@Injectable({
  providedIn: 'root',
})
/**
 *  export for workspace admin control service method
 */
export class WorkspaceAdminService {
  /**
 *  variable For  show Spinner
 */
  public showSpinner = false;
  /**
 *  variable For  roles
 */
  public roles: Subject<any> = new Subject<any>();
  /**
 *  variable For  users
 */
  public users: Subject<any> = new Subject<any>();
  /**
 *  variable For  selected Role
 */
  public selectedRole: Subject<any> = new Subject<any>();
  /**
 *  variable For  default Role
 */
  public defaultRole;
  /**
 *  variable For  selected user
 */
  public selecteduser: Subject<any> = new Subject<any>();
  /**
 *  method For  updateRoles
 */
  public updateRoles(data) {
    this.roles.next(data);
  }
    /**
 *  method For  updateGroup
 */
  public updateGroup(data) {
    this.users.next(data);
  }
  /**
 *  method For  update Role Filter
 */
  public updateRoleFilter(role) {
    this.defaultRole=role;
    this.selectedRole.next(role);
  }
    /**
 *  method For update Group Filter
 */
  public updateGroupFilter(role) {

    this.selecteduser.next(role);
  }
  /**
 *  git ignore onInit
 */
  constructor(private router: Router, private apiService:ApiServiceService, private http:HttpClient) {


  }

  /**
 *  method For ws Subscription Details Service
 */
  wsSubscriptionDetailsService(data) {
   return this.apiService.post('/api/Workspace/SubscriptionDetails', data);
  }
 /**
 *  method For new Role Get
 */
  newRoleGet(data) {
    return this.apiService.post('/api/Role/FetchRoleWithRights', data);
  }
   /**
 *  method For menu Rights Service
 */
  menuRightsService(data) {
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }
/**
 * /api/Dashboard/wsBillingSub
 */
wsBillingSub(data){
  return this.apiService.post('/api/Common/WSBillingSub',data);
}
/**
 * billing status
 */
GetInvoiceList(data) {
  return this.apiService.post('/api/InvoiceMaster/GetInvoiceList', data);
}
/**
 * GetLastInvoiceDetail
 */
GetLastInvoiceDetail(data) {
  return this.apiService.post85('/api/Dashboard/GetLastInvoiceDetail', data);
}
WSSuspensationAndDeletion_Paid(data){
  return this.apiService.post('/api/WSSuspensationAndDeletion/WSSuspensationAndDeletion_Paid', data);

}
}
