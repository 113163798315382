 <ng-template #upgradeModalOwner>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade your plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic mb-0">Your current plan does not include this feature. Do you want to upgrade 
your current plan?</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">UPGRADE NOW</button>
      <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #upgradeModalUser>
  <nb-card class="invitemodal_1">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade your plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body class="hidden">
      <p class="paragraph text-basic mb-0" >Your current plan does not include this feature.</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" size="small" (click)="reuesttoowner();close();">REQUEST OWNER TO UPGRADE</button>
      <button  type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
 <ng-template #upgradeModalOwnerInvite>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade Plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic mb-0">You can only have <b>{{totalUsers}} users</b> be a part of your workspace as per your current plan.</p>
      <p class="paragraph text-basic mb-0 mt-2"><b>Modify your subscription and buy more users before you can add them</b></p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">MODIFY SUBSCRIPTION</button>
      <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #upgradeModalUserInvite>
  <nb-card class="invitemodal_1">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade Plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body class="hidden">
        <p class="paragraph text-basic mb-0">You can only have {{totalUsers}} users be a part of your workspace as per your current plan.</p>
        <p class="paragraph text-basic mb-0 mt-2"><b>Please contact your Workspace Owner to add more users before inviting the rest of your team</b></p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" size="small" (click)="close();">OK</button>
      <button  type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
 <ng-template #upgradeModalOwnerUpload>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
       <p class="paragraph text-basic mb-0">You can only upload <b>{{totalDpages}}</b> to your workspace. Want to upload more documents?</p>
      <p class="subtitle text-basic mb-0 mt-1">Upgrade your plan to add more Storage.</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">UPGRADE NOW</button>
      <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card> 
</ng-template>

<ng-template #upgradeModalUserUpload>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Upgrade plan </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
       <p class="paragraph text-basic mb-0">You can only upload <b>{{totalDpages}} </b> to your workspace. Want to upload more documents?</p>
      <p class="subtitle text-basic mb-0 mt-1">Upgrade your plan to add more Storage.</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" size="small" (click)="reuesttoowner();close();">REQUEST OWNER TO UPGRADE</button>
      <button  type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>