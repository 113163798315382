import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { SignInService } from '../sign-in.service';
import { SharedService } from '../utils/shared.service';
import { WsSecurityComponent} from '../../../app/pages/workspace-admin/components/ws-security/ws-security.component'
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  wsId;
  @ViewChild(WsSecurityComponent) supportChild: WsSecurityComponent;
  constructor(public router: Router, public cookieService: CookieService,private http:HttpClient,public signInService: SignInService ) {
    this.wsId = localStorage.getItem('localwsId');
    console.log("auth guard 1", this.wsId);
  }

  userIdlocal;
  userIdsession;
  wspaceIdlocal;
  wspaceIdsession;
  domainurl;
  imageUrl ='';

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let logoinId = 'localloginId' + localStorage.getItem('localwsId');
    let wsId = 'localwsId' + localStorage.getItem('localwsId');
    this.userIdlocal = this.cookieService.get(logoinId);
    this.wspaceIdlocal = this.cookieService.get(wsId);
    console.log("auth guard 2", this.userIdlocal, this.wspaceIdlocal);

    if (sessionStorage.getItem('SSOURL')) {
    console.log("auth guard 2", this.userIdlocal, this.wspaceIdlocal);
      let postData = {
        "validateUID": sessionStorage.getItem('SSOURL')
      }
      try {
        const response: any = this.signInService.silentsignIn(postData).toPromise().then();
        if (response.statusCode == 'C200') {
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    
  } else {
    if (this.userIdlocal && this.wspaceIdlocal) {
      // if(localStorage.getItem('localloginId') !== localStorage.getItem('ownerEmailId')){
      //   this.getIPAddress()

      // }
      console.log("space url111111111", window.location.href);
      return true;
    } 
    else if (!this.userIdlocal || !this.wspaceIdlocal) {
      console.log("123edcv");
      this.router.navigate(['/onboarding/sign-in']);
    }
  }
  }
  getIPAddress(){
    
          this.http.get('https://api.ipify.org/?format=json',{}).subscribe((res: any) => {
        this.http.get('https://ipapi.co/' + res.ip + '/json/').subscribe((resp: any) => {
          if(localStorage.getItem('ipAddress') != resp.ip ){
            let body = JSON.parse(localStorage.getItem("Request"))
            body.ipCapture=resp.ip
            this.signInService.signIn(body).toPromise().then((response: any) => {
             if (response.statusCode == 'C201' || response.statusCode == 'C203' ) {
        
            
              let request ={"wsoid":Number(localStorage.getItem('localwsId')),"key":null,"userEmailID":localStorage.getItem('localloginId'),"role":"","groupName":null,"message":"Your network has been changed and your IP and Role is not Listed|IP Change","databaseName":"","createdBy":null,"Flag":"Modal","WsLink":""}
              this.signInService.notifysend(request).subscribe()
            }
          })
          
            localStorage.setItem('ipAddress', resp.ip);

          }
        });
      })
  }
}
