import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class MyQueueService {

  constructor(private apiService:ApiServiceService) { }

   //************************ My Doc List table data  API Get Start here ************************

   public myQueueListService(data) {
    return this.apiService.post('/api/DocumentVerification/MyDocList ', data);
  }

  viewUserWiseRoleAccess(data) {
    return this.apiService.post('/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }
  getFilterList(data) {
    return this.apiService.post('/api/Workspace/GetFilterList', data);
  }
  documentFormatService(data) {
    return this.apiService.get('/api/RandomDocumentUpload/GetDocFormat ');
  }

    //************************  API for delete my document  API Get Start here ************************

    deleteMyDocService(data) {
       return this.apiService.post('/api/DocumentVerification/DeleteDocVerifier', data);
    }
  
      // ****************** UploadRightsUserList start here ***********************
  UploadRightsUserListService(data) {
    return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }

    // ****************** Change verifiers end here ****************************

    UpdateDocumentVerifier(data) {
     return this.apiService.post('/api/DocumentVerification/UpdateDocVerifier', data);
    }

}
