<!--/*************************************************************************
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd 
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Dashboard Component
///  Description: Dashboard for the project
///  Owner      : Sumeet Goel
///  Author     : Aman Kumar
///  Created On : 22-02-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->

<!-- html for dashboad 2.0 start here -->

      <!-- html for parent which contains whole structure start here -->

   
      <div class="parent col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"  >
        <!------------------ html for first row started from here --------------------->
        <div class="row first-child">
         
         
          <div class="right-child_1 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
           
            <nb-card class="avtar-card">
              <nb-card-body>
               
                <div class="d-flex flex-row" style="justify-content: space-between;">
                 <div class="user-content">
                  <div style="height: 22px; width: 154px;" class="">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.375rem',
                    width: '9.625rem' ,'border-radius':'4px' }"></ngx-skeleton-loader>
                  
                </div>
                  
                   <h4 class="mt-1 pb-3">  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.5rem',
                    width: '1.5rem','margin-top': '12px' }"></ngx-skeleton-loader></h4>
                 </div>
                 <div class="avtar">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '5rem',
                  width: '5.3875rem', 
                  'margin-bottom': '8px',
                  'margin-top': '13px',
                  'margin-right': '7px',
                  'border-radius':'4px'
                   }">
                    <nb-icon icon="person" class="avtar-icon"></nb-icon>
                  </ngx-skeleton-loader>
                 </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="right-child_1 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <nb-card class="avtar-card">
              <nb-card-body>
               
                <div class="d-flex flex-row" style="justify-content: space-between;">
                 <div class="user-content">
                  <div style="height: 22px; width: 154px;" class="">
                    <ngx-skeleton-loader appearance="circle" [theme]="{height: '1.375rem',
                    width: '9.625rem','border-radius':'4px' }"></ngx-skeleton-loader>
                
                </div>
              
                   <h4 class="mt-1 pb-3">  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.5rem',
                    width: '1.5rem','margin-top': '12px' }"></ngx-skeleton-loader></h4>
                 </div>
                 <div class="avtar">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '5rem',
                  width: '5.3875rem', 
                  'margin-bottom': '8px',
                  'margin-top': '13px',
                  'margin-right': '7px',
                  'border-radius':'4px'
                   }">
                    <nb-icon icon="person" class="avtar-icon"></nb-icon>
                  </ngx-skeleton-loader>
                 </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
          
           <div class="right-child_2 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <nb-card class="avtar-card">
              <nb-card-body>
               
                <div class="d-flex flex-row" style="justify-content: space-between;">
                 <div class="user-content">
                  <div style="height: 22px; width: 154px;" class="">
                    <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.375rem',
                    width: '9.625rem','border-radius':'4px'  }"></ngx-skeleton-loader>
              
                </div>
                  
                   <h4 class="mt-1 pb-3">  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.5rem',
                    width: '1.5rem','margin-top': '12px' }"></ngx-skeleton-loader></h4>
                 </div>
                 <div class="avtar">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '5rem',
                    width: '5.3875rem', 
                    'margin-bottom': '8px',
                    'margin-top': '13px',
                    'margin-right': '7px',
                    'border-radius':'4px'
                   }">
                    <nb-icon icon="person" class="avtar-icon"></nb-icon>
                  </ngx-skeleton-loader>
                 </div>
                </div>
              </nb-card-body>
            </nb-card>
            </div>
            <div class="right-child_2 col-xxxl-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <nb-card class="avtar-card">
              <nb-card-body>
               
                <div class="d-flex flex-row" style="justify-content: space-between;">
                 <div class="user-content">
                  <div style="height: 22px; width: 154px;" class="">
                    <ngx-skeleton-loader appearance="circle" [theme]="{height: '1.375rem',
                    width: '9.625rem' ,'border-radius':'4px'}"></ngx-skeleton-loader>
                  
                </div>
                 
                   <h4 class="mt-1 pb-3">  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '1.5rem',
                    width: '1.5rem','margin-top': '12px' }"></ngx-skeleton-loader></h4>
                 </div>
                 <div class="avtar">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ height: '5rem',
                  width: '5.3875rem', 
                  'margin-bottom': '8px',
                  'margin-top': '13px',
                  'margin-right': '7px',
                  'border-radius':'4px'
                   }">
                    <nb-icon icon="person" class="avtar-icon"></nb-icon>
                  </ngx-skeleton-loader>
                 </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div> 
        </div>
        <!---------------------- html for first row ended from here --------------------------------->
        <!------------------------------------- html for second row started from here --------------------------------------------->
        <div class="row second-child">
          <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
            <nb-card class="table-card">
              <nb-card-body>
                
                <div>
              
                  <div class="p-0">
                 
                    <div class="container table-responsive p-0"> 
                      <table class="table">
                        <thead class="table-dark" style="height: 63px;">
                         
                        </thead>
                        <tbody class="table">
                          <tr class="table-row-style">
                            <td scope="row"></td>
                            <td scope="row  text-primary">
                            
                              <span class="ml-3 text-primary">
                                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></span>
                              </td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '77px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '65px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '70px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                          
                            </tr>
                            <tr class="table-row-style">
                              <td scope="row"></td>
                              <td scope="row  text-primary">
                              
                                <span class="ml-3 text-primary">
                                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></span></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                              <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                              </ngx-skeleton-loader></td>
                            
                              </tr>
                              <tr class="table-row-style">
                                <td scope="row"></td>
                            <td scope="row  text-primary">
                            
                              <span class="ml-3 text-primary">
                                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                </ngx-skeleton-loader></span></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                            <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                            </ngx-skeleton-loader></td>
                                </tr>
                                <tr class="table-row-style">
                                  <td scope="row"></td>
                                  <td scope="row  text-primary">
                                  
                                    <span class="ml-3 text-primary">
                                      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px','border-radius':'98px' }">
                                      </ngx-skeleton-loader></span></td>
                                  <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></td>
                                  <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></td>
                                  <td> <ngx-skeleton-loader appearance="circle" [theme]="{ width: '96px', height: '22px','border-radius':'98px' }">
                                  </ngx-skeleton-loader></td>
                                  </tr>
                         
                        </tbody>
                      </table>
                      </div>
                     
  
                  </div>
                 
                  
                 
                
                  </div>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <nb-card class="upload-card d-flex flex-column justify-content-center align-items-center">
              <nb-card-body class="d-flex flex-column align-items-center justify-content-center">
                <div class="upload-parent">
               <div class="parent-circle-box d-flex flex-column justify-content-center align-items-center">
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '117px', height: '106px' ,'border-radius':'4px'}">
                  <div class="first-circle"></div>
                  <div class="child-circle-box d-flex flex-row justify-content-center align-items-center">
                   <div class="second-circle"></div>
                   <div class="third-circle"></div>
                   
                  </div>
                </ngx-skeleton-loader>
               </div>
               <div class="upload-btn d-flex align-items-center justify-content-center">
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '117px', height: '40px' ,'border-radius':'98px'}">
                <button nbButton class="upload-button"><nb-icon icon="upload-outline"></nb-icon>
                  upload</button>
                </ngx-skeleton-loader>
               </div>
               <div class="para">
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '219px', height: '22px' ,'border-radius':'98px'}">
                <p class="para-content">Upload a wide range of media file types to your workspace </p>
              </ngx-skeleton-loader>
              </div>
         
              </div>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
        <!---------------------------------- html for second row ended from here --------------------------->
       
          <div class="suggested">
            <ngx-skeleton-loader appearance="circle" [theme]="{ width: '173px', height: '22px', 'border-radius':'98px' }">
              <p class="suggested-para">
                Suggested for you
              </p>
            </ngx-skeleton-loader>
            
          </div>
        <!------------------------------------- html for third row started from here ----------------------------->
        <div class="row third-child">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="first-card">
              <nb-card-body>
               <div class="invite-parent">
                 <div class="invite-text-div">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                    <p class="invite-para">Invite multiple users at once</p>
                  </ngx-skeleton-loader>
                 </div>
                
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                  <p class="invite-para">Invite multiple users at once</p>
                </ngx-skeleton-loader>
                <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                  <p class="invite-para">Invite multiple users at once</p>
                </ngx-skeleton-loader>
                 <div class="invite-link">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                  
                    <p class="blue-para">Go to General Settings</p>
                  </ngx-skeleton-loader>
                 </div>
               </div>
              </nb-card-body>
            </nb-card>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="second-card">
              <nb-card-body>
                <div class="invite-parent">
                  <div class="invite-text-div">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                     <p class="invite-para">Invite multiple users at once</p>
                   </ngx-skeleton-loader>
                  </div>
                 
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                  <div class="invite-link">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                   
                     <p class="blue-para">Go to General Settings</p>
                   </ngx-skeleton-loader>
                  </div>
                </div>
               </nb-card-body>
            </nb-card>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="third-card">
              <nb-card-body>
                <div class="invite-parent">
                  <div class="invite-text-div">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                     <p class="invite-para">Invite multiple users at once</p>
                   </ngx-skeleton-loader>
                  </div>
                 
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                  <div class="invite-link">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                   
                     <p class="blue-para">Go to General Settings</p>
                   </ngx-skeleton-loader>
                  </div>
                </div>
               </nb-card-body>
            </nb-card>
          </div>
        </div>
      <!---------------------------------------- html for third row ended from here --------------------------------->
      <!--------------------------------- html for fourth row started from here ----------------------------------------------------------->
        <div class="row fouth-child">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="fourth-card">
              <nb-card-body>
                <div class="invite-parent">
                  <div class="invite-text-div">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                     <p class="invite-para">Invite multiple users at once</p>
                   </ngx-skeleton-loader>
                  </div>
                 
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                  <div class="invite-link">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                   
                     <p class="blue-para">Go to General Settings</p>
                   </ngx-skeleton-loader>
                  </div>
                </div>
               </nb-card-body>
            </nb-card>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="fifth-card">
              <nb-card-body>
                <div class="invite-parent">
                  <div class="invite-text-div">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                     <p class="invite-para">Invite multiple users at once</p>
                   </ngx-skeleton-loader>
                  </div>
                 
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                  <div class="invite-link">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                   
                     <p class="blue-para">Go to General Settings</p>
                   </ngx-skeleton-loader>
                  </div>
                </div>
               </nb-card-body>
            </nb-card>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <nb-card class="sixth-card">
              <nb-card-body>
                <div class="invite-parent">
                  <div class="invite-text-div">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px'}">
                     <p class="invite-para">Invite multiple users at once</p>
                   </ngx-skeleton-loader>
                  </div>
                 
                  <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px','margin-top': '33px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                 <ngx-skeleton-loader appearance="circle" [theme]="{ width: '309px', height: '22px', 'border-radius': '98px', 'margin-bottom': '0px' }">
                   <p class="invite-para">Invite multiple users at once</p>
                 </ngx-skeleton-loader>
                  <div class="invite-link">
                   <ngx-skeleton-loader appearance="circle" [theme]="{ width: '176px', height: '22px', 'border-radius': '98px' }">
                   
                     <p class="blue-para">Go to General Settings</p>
                   </ngx-skeleton-loader>
                  </div>
                </div>
               </nb-card-body>
            </nb-card>
          </div>
        </div>
         <!----------------------------- html for fourth row end from here ----------------------------------->
        </div>
       <!-------------------------------- html for parent which contains whole structure end here --------------------->
  
  
  <!---------------------------------- html for dashboad 2.0 end here ----------------------->