<div class="notifications-section">
    <div class="SubtitleBox">
    <p class="subtitle text-basic mb-3">Notification settings</p>
  </div>
  <div class="parabox">

    <p class="para">Use the toggle to control which notifications you would like to receive</p>
  </div>
  <div class="d-flex flex-row notifiClass mb-1">
    <div class="SecondSubtitleBox">
    <p class="paragraph-2 SecondSubtitlePara  text-basic">Pop up notifications on dashboard </p>
    </div>
    <div class="d-flex">
    <nb-toggle  name="realTimenotify" type="checkbox" class="float-right text-basic" labelPosition="end" [checked]="realTimeNotification"
     (checkedChange)="isChanged()" >
    </nb-toggle>
  </div>
</div>

<div class="SubtitleBox">
  <p class="subtitle text-basic mb-2">Email settings</p>
</div>
<div class="parabox">

  <p class="para">Use the toggle to control which notifications you would like to receive</p>
</div>

<div class="d-flex flex-row notifiClass-1 mb-4 mt-4">
  <div class="SecondSubtitleBox-1">
  <p class="paragraph-2 SecondSubtitlePara  text-basic">Administrative emails (new user sign-up & password changes) </p>
  </div>
  <div class="d-flex">
  <nb-toggle  name="realTimenotify1" type="checkbox" class="float-right text-basic" labelPosition="end" [checked]="isAdminMail"
    (checkedChange)="isChanged()">
  </nb-toggle>
</div>
</div>
<div class="d-flex flex-row notifiClass-1">
<div class="SecondSubtitleBox-1">
  <p class="paragraph-2 SecondSubtitlePara  text-basic">Emails related to document handling, ownership & deletion</p>
  </div>
  <div class="d-flex">
  <nb-toggle  name="realTimenotify2" type="checkbox" class="float-right text-basic" labelPosition="end" [checked]="isDocEmail"
    (checkedChange)="isChanged()">
  </nb-toggle>
</div>
</div>
<div class="extraBox">

</div>
<div>

  <button nbButton status="primary" size="medium" class="savaButton" (click)="isChecked($event, 'realTimenotify')">Save</button>
</div>

  </div>
 <ng-template #unsavedChanges let-data let-ref="dialogRef">   
    <nb-card>
        <nb-card-header>
          <div class="justify-content-between d-flex">
            <h6 class="subtitle text-basic">
             Unsaved changes
            </h6> 
                <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
        </div>
       </nb-card-header>
     
      <nb-card-body> <p class="paragraph text-basic">Are you sure you want to leave this page? The changes you made will be<br> discarded.
</p> </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary"  size="small" class="mr-3"  (click)="isChecked($event, 'realTimenotify');dialogRef.close()" >SAVE CHANGES</button>
            <button nbButton  size="small" (click)="dialogRef.close();discardChanges(ref)" outline status = "primary">DISCARD</button>
      </nb-card-footer>
    </nb-card>
  </ng-template> 
