/**********************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Upload Component
///  Description: Upload component in random uplaod
///  Owner      : Sumeet Goel
///  Author     : Trisha pathak
///  Created On : 27-07-2021
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Himanshu Singh               Date:03/07/2023           Description: Last modificotion is of intergating new docUpload Api and minor impact changes due to new upload api
///
///------------------------------------------------------------------->


import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ChangeDetectorRef, Input, HostListener, ViewChild, Optional, AfterViewInit, Output, EventEmitter, ElementRef, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { RandomUploadService } from '../../../@core/random-upload.service';
import { map } from 'rxjs/operators';
import { MyQueauComponent } from '../../docs-in-upload/my-queau/my-queau.component';
import { SignInService } from "../../../@core/sign-in.service";
import { NbToastrService } from '@nebular/theme'
import { SharedService } from "../../../@core/utils/shared.service";

import { CookieService } from 'ngx-cookie-service';
import { UpgradeModalComponent } from '../../../shared/upgrade-modal/upgrade-modal.component';
import { DashboardService } from '../../../@core/utils/dashboard.service';
import { PagesComponent } from '../../pages.component';
import { MENU_ITEMS } from "../../../../app/pages/pages-menu";
import { MyQueueService } from '../../../../../src/app/@core/utils/my-queue.service';
import { NetworkService } from '../networks.service';
import { AllUsersService } from '../../../@core/utils/all-users.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'ngx-upload',
  templateUrl: './upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, AfterViewInit {
  @ViewChild(MyQueauComponent) myQueueChild : MyQueauComponent;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild('tabs', { read: TemplateRef }) templateTabs: TemplateRef<any>;

  @Input() selectedRoute;

  @ViewChild("uploadModal") uploadModal: TemplateRef<any>;
  @ViewChild(PagesComponent) PagesComponent: PagesComponent;

  @ViewChild("notshow") notshow: TemplateRef<any>;
  uploadLimitRef: any;
  workspaceStorageRef: any;
  canceluploadRef: any;
  notshowagain:any;
  removeDocRef: any;
  networkBrkRef: any;
  globalIndex: any;
  // @ViewChild("networkBrkRef") networkBrkRef: TemplateRef<any>;
  progress: number;
  event: any;
  parserfeature = false;
  uploadStorageExhaust: boolean;
  newtworkError=true;
  uploadFileDocId: any;
  uploadFileName: any;
  uploadFileModifiedDate: any;
  uploadFileCreatedDate: any;
  uploadFileFileSize: any;
  uploadFileOriginalFileName: any;
  componentList: any;
  pagesSizes: any;
  documentpagesSizes: any;
  availableStore: any;
  isnamechanged :boolean = true;
  pagesizesTotal: any;
  parserflagvalue: string;
  dpageOverError = true;
  disableChoose: boolean;
  uploadurl: boolean;
  subscription: any;
  public menu = MENU_ITEMS;

  format: string;
  formats: string;
  subscriptionId;
  maximumFileSizeUnit: any;
  formatsArray: string[];
  count: number = 0;
  countCall = 0;
  newtworkErrorDocket: boolean = false;
  CanDeactivate(): boolean {
    return !this.isDirty
  }
  workSpaceId: any;
  createdBy: any;
  menuRights: any = [];
  roleName: string;
  isProgressShow: boolean = false;
  isManageUsersGroups: boolean;
  isCreateModifyRoles: boolean;
  isWorkspaceSettings: boolean;
  languageRadioButton: boolean = false;
  errorMassageValidation: boolean = true;
  isProgress: boolean[] = [];
  isError: boolean[] = [];
 fileformatenotsupport : boolean[] = [];
  namenotmatch: boolean[] = [];
  protectedfile: boolean[] = [];
 isNetworkError: boolean[] = [];
  crossIconwDisable: boolean[] = [];
  checkIconwDisable: boolean[] = [];
  errorIconDisable: boolean[] = [];
  selectFilesButton: boolean = false;
  fileUploadPrgressBtn: boolean = false;
  uploadRightUserList = [];
  invalidFiles: boolean[] = [];
  apiFilesError: boolean[] = [];
  internetFaliures: boolean[] = [];
  networkOnline: any;

  isSessionPause: boolean = false;
  fileOnGoingSession=[];
  btnRemoveFile: boolean[] = [];
  btnRemoveAllFile: boolean = false;
  FileSuccessMsg: boolean = true;
  isFileUploadStart: boolean = false;
  frId: any = [];
  largeFileCount = 0;
  pageTitle: string;
  messages: any = [];
  isDocumentUpload: boolean;
  @BlockUI() blockUI: NgBlockUI;
  rightUserList: any;
  rightLanguageList: any;
  maximumFileSize: any;
  wsAvailableStorage: any;
  wsAvailableStorageInKB: any;
  uploadDocForm = new FormGroup({
    verifierName: new FormControl(''),
    documentOwnerName: new FormControl(''),
    selectLanguage: new FormControl('')

  });
  uploadRef: any;
  isuploadnotshowagain;
  formatAvailable: any;
  changedFormat = [];
  docketsNetworkError: boolean = false;

  constructor(private menuRight :  DashboardService, private toastrService: NbToastrService, private networkService: NetworkService,    private myqueueservice: MyQueueService,

    private cookieService: CookieService, private dialogService: NbDialogService,  private sharedService : SharedService,private signInService:SignInService, @Optional() private dialogRef: NbDialogRef<any>, private titleService: Title, private httpClient: HttpClient, private data: RandomUploadService, private http: HttpClient, private router: Router, private cd: ChangeDetectorRef, private eRef: ElementRef,private alluserlist : AllUsersService
  ) {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.subscriptionId =Number(localStorage.getItem('subscriptionSpId'))

    localStorage.setItem('activeTab', 'My Docs')
    if(localStorage.getItem('isuploadnotshowagain')){
      this.isuploadnotshowagain = localStorage.getItem('isuploadnotshowagain')
    }
    if (!this.createdBy) {
      this.router.navigateByUrl('/onboarding/sign-in');
    }

  }
  component: any;
  items = [];
  ngAfterViewInit(): void {
    this.items = [
      this.componentList,
    ]

  }


  dropdownconfig = {
    displayKey: "custom",
    search: true,
    placeholder: "Select User",
    searchPlaceholder: 'Search ',
    height: "150px"
  };

  tagdropdownconfig = {
    displayKey: "custom",
    search: true,
    placeholder: "Select Language",
    searchPlaceholder: 'Search Language',
    height: "150px"
  };

  fullName = '';
  userfullname;
  changingFormat = [];
  @Input() showUploadDialog: boolean = false
  @Output() closeUploadDialog = new EventEmitter<any>();

  ngOnInit(): void {
    localStorage.setItem('saved', 'false')
    if (this.menuRight.getRights() !== undefined) {
      this.bindMenuRights(this.menuRight.getRights());
    }
    this.subscription = this.sharedService.menuRights.subscribe((data) => {
      this.bindMenuRights(data);
    });
    this.rightUserList = of(this.options);
    this.randomDocumentLanguages();
    this.UploadRightsUserList();
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.parserflagvalue = localStorage.getItem('parserflag');
    if(this.parserflagvalue?.toLowerCase() === 'true'){
      this.parserfeature = true;
    }
    else{
      this.parserfeature = false;
    }
    this.wsStatus();
  }
  closeUploadmodal(){
    this.sharedService.uploadOpen.next('true');
    localStorage.setItem('uploadTime','0')
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
    // this.pendingRequest.unsubscribe();
  }

  wsStatus() {
      var st = window.location.toString();
      var arr = st?.split("/");
      let postData: any
      postData = {
          "Wslink": arr[2],
          "emailId": localStorage.getItem('localloginId'),
      }
      this.signInService.wsStatus(postData).subscribe((response: any) => {
        if (response) {
          let format = (response.data[0].format);
          localStorage.setItem('FormatAvailables', format);
          this.format =localStorage.getItem('FormatAvailables');
          let valueFormat = this.format?.split(',')
      
          for(let i=0;i<=valueFormat.length-1;i++){
            if(valueFormat[i].includes(':')){
              let FormatChange = valueFormat[i]?.split(':');
              for(let j=0;j<=FormatChange.length-1;j++){
      
                let changesFormateing = parseInt(FormatChange[j])
      
                if(isNaN(changesFormateing)){
                  this.changingFormat.push( ' ' + FormatChange[j])
                  this.changedFormat.push( ' .' + FormatChange[j])
                }
                else if(!isNaN(changesFormateing)){
                  break;
                }
      
              }
      
      
            }
            else{
              this.changingFormat.push(' ' + valueFormat[i])
              this.changedFormat.push(' .' + valueFormat[i]);
            }
      
          }
          this.format = this.changingFormat.join(',')
          this.formatAvailable = this.changedFormat.join(',').toLowerCase();
          this.formats = this.format;
          this.formatsArray = this.formats?.split(',');
          let changingFormat = []
          for(let i=0;i<=this.formatsArray.length-1;i++){
            changingFormat.push(this.formatsArray[i].trimStart())
          }
          this.formatsArray = changingFormat
          this.formatsArray = this.formatsArray.map(element => {
            return element.toUpperCase();
          });
      }
      });
  }
  // ******************  Bind User Permission Lists Start Here *******************
  bindMenuRights(response?) {
        this.menuRights = response
        this.roleName = this.menuRights.roleName;
        this.isManageUsersGroups = this.menuRights.isManageUsersGroups;
        this.isCreateModifyRoles = this.menuRights.isCreateModifyRoles;
        this.isWorkspaceSettings = this.menuRights.isWorkspaceSettings;
        this.isDocumentUpload = this.menuRights.isDocumentUpload;
        localStorage.setItem('logincategory', this.menuRights.category);
         setTimeout(()=>{
           this.uploadSubmit();

         },1500)

        this.cd.detectChanges();
  }

  // ****************** Bind User Permission Lists End  *******************


  closeOnBackdropClick: boolean = true;
  Upload(uploadModal: TemplateRef<any>) {

    this.fileUploadPrgressBtn = false;
    this.uploadRef = this.dialogService.open(uploadModal,
      { closeOnBackdropClick: false, closeOnEsc: false, }
    );

  }

  Uploadskip(uploadModal: TemplateRef<any>) {
    this.fileUploadPrgressBtn = true;
    this.uploadRef = this.dialogService.open(uploadModal,
      { closeOnBackdropClick: false, closeOnEsc: false, }
    );
  }


  // ****************** Check Daily Limit of Upload Document Start Here *******************
  languagecheck = '';
  dropdownverifier;
  dropdownowner;
  @ViewChild(UpgradeModalComponent) upgradeChild: UpgradeModalComponent;

  openUpgradeModal() {
    this.upgradeChild.openUpgradeUpload('upload additional dPages','EM-048');
  }
  hidechoosefile = false;
  uploadSubmit(value?) {

    this.languagecheck = value;
    if(value){
      localStorage.setItem('uploadTime','0')
      this.hidechoosefile = true;
    }
  this.sharedService.isNewFileuploaded.next(true);
    if (this.isDocumentUpload == true) {
      var st = window.location.toString();
      var arr = st?.split('/');
      let postData: any;
      postData = {
        Wsoid: Number(this.workSpaceId),
        WsLink: arr[2],
        guid : localStorage.getItem('loginguid')
      };
      let postDatastorage = {
        Wsoid: Number(this.workSpaceId),
        WsLink: arr[2],
        guid : localStorage.getItem('loginguid'),
        userEmailID : this.createdBy
      };
      this.blockUI.start('Loading...');

      this.data.ValidateAvailableStorage(postDatastorage).subscribe((response: any) => {
        if (response.isSuccess) {
          this.pagesizesTotal = response.data.pageSize;
          this.availableStore = response.data.applyAvailableStorage;
          // if(this.subscriptionId === 1||this.subscriptionId === 4){
          if (this.availableStore && (this.availableStore <= 0 || this.availableStore === null || this.availableStore === undefined)) {
            this.openUpgradeModal();
            return
          }
        // }
          this.documentpagesSizes = response.data.pageSize;
          if(!this.languagecheck){
            this.dropdownverifier = response.data.verifier.trim();
            this.dropdownowner = response.data.documentOwner.trim();
            if(this.rightUserList.length > 0){
              for (let user of Object.keys(this.rightUserList)) {
                var capital = this.rightUserList[user];
                if(this.dropdownverifier){
                if (capital.emailId == this.dropdownverifier) {
                  this.selectedVerifierName = capital;
                      this.selectedVerifierName.displayName = this.selectedVerifierName.displayName ;
                      this.verifierNameEmail = capital.emailId;
                      this.onVerifierName(this.selectedVerifierName)
                      if(this.selectedVerifierName.emailId == this.createdBy){
                        if(!this.selectedVerifierName.displayName.includes("(you)")){
                          this.selectedVerifierName.displayName = this.selectedVerifierName.displayName + " " + "(you)"
                          this.verifierNameEmail = capital.emailId;

                        }
                      }

                }
              }
              if(this.dropdownowner){
                if (capital.emailId == this.dropdownowner) {

                  this.selectedDocumentOwner = capital;
                      this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName ;
                      this.documentOwnerEmail = capital.emailId;
                      this.onDocumentOwner(this.selectedDocumentOwner);
                      if(this.selectedDocumentOwner.emailId == this.createdBy){
                        if(!this.selectedDocumentOwner.displayName.includes("(you)")){
                          this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName + " " + "(you)"
                          this.documentOwnerEmail = capital.emailId;
                        }
                      }

                }
              }

              }
            }
            if(!this.dropdownverifier){
              for (let user of Object.keys(this.rightUserList)) {
                var capital = this.rightUserList[user];
                      if(capital.emailId == this.createdBy){
                        this.selectedVerifierName = capital;
                        this.onVerifierName(this.selectedVerifierName)
                        if(!this.selectedVerifierName.displayName.includes("(you)")){
                          this.selectedVerifierName.displayName = this.selectedVerifierName.displayName + " " + "(you)"
                          this.verifierNameEmail = capital.emailId;


                        }
                      }

              }
            }
            if(!this.dropdownowner){
              for (let user of Object.keys(this.rightUserList)) {
                var capital = this.rightUserList[user];
                if(capital.emailId == this.createdBy){
                  this.selectedDocumentOwner = capital;
                  this.onDocumentOwner(this.selectedDocumentOwner);
                  if(!this.selectedDocumentOwner.displayName.includes("(you)")){
                    this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName + " " + "(you)"
                    this.documentOwnerEmail = capital.emailId;
                  }
                }
              }
            }
          }


          if(!this.languagecheck){
            for (let user of this.rightLanguageList) {
              if (user.languageName == response.data.language) {
                this.selectedlanguage = user.languageName;
                this.onSelectLanguage(this.selectedlanguage);
              }
            }
          }

                this.wsAvailableStorageInKB = Number(response.data.applyAvailableStorage);
                this.wsAvailableStorage = Number(response.data.applyAvailableStorage / 1024 / 1024);

          if (this.wsAvailableStorageInKB < 1024)
            this.wsAvailableStorage =
                    Number(this.wsAvailableStorageInKB) + ' KB';
          if (this.wsAvailableStorageInKB >= 1024)
            this.wsAvailableStorage =
                    Number(this.wsAvailableStorageInKB / 1024) +
              ' MB';
          if (this.wsAvailableStorageInKB / 1024 >= 1024)
            this.wsAvailableStorage =
                    Number(this.wsAvailableStorageInKB / 1024 / 1024)+ ' GB';
          localStorage.setItem('wsAvailableStorage', response.data.maximumFileSize?.split(':')[0])
          this.maximumFileSize = Number(response.data.maximumFileSize?.split(':')[0] || localStorage.getItem('wsAvailableStorage'));
          this.maximumFileSizeUnit = response.data.maximumFileSize?.split(':')[1];
          if (this.showUploadDialog && value===undefined) {
            this.Upload(this.uploadModal);
          }

        } else {
          this.wsAvailableStorage = Number(
            response.data.applyAvailableStorage
          );
          this.uploadStorageExhaust = true;
          // if(this.subscriptionId === 1 ||this.subscriptionId === 4 ){
          this.openUpgradeModal();}
          this.cd.detectChanges();
        // }
      });
    }

    this.closeUploadDialog.emit(false)
  }

  notifyupload(){
    window.productFruits?.api?.checklists.markItemAsDone( 'upload', true)
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange3 = arr[0]+ "//" + arr[2] + '/pages/documents/workspace-queue'
    let totalpages = this.totalPageUsed
    this.sharedService.totaldpages.next(totalpages)
    let uploadnotify = {
      "wsoid": Number(localStorage.getItem('localwsId')),
      "wsLink": arr[2],
      "createdby": localStorage.getItem('localloginId'),
      "category": "DocUpload",
      "value": this.numberofdoc + " documents have been successfully uploaded. These documents still need to be verified from your queue before they are actually added to your workspace. ",
      "database": ""
    }
    this.signInService.roleinsert(uploadnotify).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // if(localStorage.getItem("countToast") == "1"){
        //   this.notifysendupload();
        //   localStorage.removeItem("countToast");
        // }
        this.toastrService.primary('Documents uploaded');
       }
       else{
         this.insertmessgae = '';
       }
    })
  }
  notifysendupload(){

    var st = window.location.toString();
    var arr = st?.split("/");


    let notifysend = {
      wsoid: Number(localStorage.getItem('localwsId')),
      key : null,
      userEmailID: localStorage.getItem('localloginId'),
      role : null,
      groupName : null,
      message : "Documents uploaded",
      databaseName : "",
      createdBy: localStorage.getItem('localloginId'),
      Flag : "Toster",
      WsLink : arr[2]
    }
    this.sendmessage  = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else{
        this.sendmessage = '';
      }

    })
  }

  insertmessgae = '';
  messageheading;
  messagebody;

  notifyinsertverifierfirst(){
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange2 = arr[0]+ "//" + arr[2] + '/pages/documents/my-queue'

      let insert = {
        wsoid : Number(this.workSpaceId),
        notificationFrom : this.createdBy,
        notificationHeading : "Document assigned for verification & ownership",
        message : "<b>" + this.userfullname +" (" + this.createdBy + ")" + "</b>" + " " + "has made you the verifier and owner of " + this.numberofdoc + " documents. View " + `<a href=${urlchange2}>My Queue</a>`,
        notificationTo : this.verifierfirst,
        createdBy : this.createdBy,
      }
      this.insertmessgae = '';
      this.signInService.notifyinsert(insert).subscribe((response: any) => {
        if (response.isSuccess) {
         this.insertmessgae = response.data;
         this.toastrService.primary('Document assigned for verification & ownership');
        }
        else{
          this.insertmessgae = '';
        }

      })

  };

  sendmessage;
  notifysendverifierfirst(){

    let notifysend = {
      wsoid : Number(this.workSpaceId),
      key : null,
      userEmailID :  this.verifierfirst,
      role : null,
      groupName : null,
      message : "Document assigned for verification & ownership",
      databaseName : "",
      createdBy : this.createdBy,
      Flag : "Toster" ,
      WsLink : ""

    }
    this.sendmessage  = '';
    this.signInService.notifysend(notifysend).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else{
        this.sendmessage = '';
      }

    })
  }

  notifyinsertverifiersecond(){

    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange2 = arr[0]+ "//" + arr[2] + '/pages/documents/my-queue'

    let insert = {
      wsoid : Number(this.workSpaceId),
      notificationFrom : this.createdBy,
      notificationHeading : "Documents to be verified",
      message: "<b>" + this.userfullname + " (" + this.createdBy + ")" + "</b>" + " " + "has assigned you" + " " + this.numberofdoc + " " + "documents for verification. " + `<a href=${urlchange2}>My Queue</a>`,
      notificationTo : this.verifierfirst,
      createdBy : this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).subscribe((response: any) => {
      if (response.isSuccess) {
       this.insertmessgae = response.data;
       this.toastrService.primary('Documents to be verified');
      //  if(localStorage.getItem("countToast") == "1"){
      //   this.notifysendverifiersecond();
      //   setTimeout(() => {
      //     localStorage.removeItem("countToast");
      //   }, 2000);
      // }

      }
      else{
        this.insertmessgae = '';
      }

    })

};


notifysendverifiersecond(){

  let notifysend = {
    wsoid : Number(this.workSpaceId),
    key : null,
    userEmailID :  this.verifierfirst,
    role : null,
    groupName : null,
    message :  "Documents to be verified",
    databaseName : "",
    createdBy : this.createdBy,
    Flag : "Toster" ,
    WsLink : ""

  }
  this.sendmessage  = '';
  this.signInService.notifysend(notifysend).subscribe((response: any) => {
    if (response.isSuccess) {
      this.sendmessage = response.data;
    }
    else{
      this.sendmessage = '';
    }

  })
}

notifyinsertownersecond(){
  var st = window.location.toString();
  var arr = st?.split("/");
  var urlchange3 = arr[0]+ "//" + arr[2] + '/pages/documents/workspace-queue'

  let insert = {
    wsoid : Number(this.workSpaceId),
    notificationFrom : this.createdBy,
    notificationHeading : "Document ownership assigned",
    message : "<b>" + this.userfullname +" (" + this.createdBy + ")" + "</b>" + " " + "has made you the owner of " + this.numberofdoc + " documents. View " + `<a href=${urlchange3}>Workspace Queue</a>`,
    notificationTo : this.ownerfirst,
    createdBy : this.createdBy,
  }
  this.insertmessgae = '';
  this.signInService.notifyinsert(insert).subscribe((response: any) => {
    if (response.isSuccess) {
     this.insertmessgae = response.data;
    //  if(localStorage.getItem("countToast") == "1"){
    //   this.notifysendownersecond();
    //   localStorage.removeItem("countToast");
    // }
    this.toastrService.primary('Document ownership assigned');
    }
    else{
      this.insertmessgae = '';
    }

  })
}
notifysendownersecond(){

  let notifysend = {
    wsoid : Number(this.workSpaceId),
    key : null,
    userEmailID :  this.ownerfirst,
    role : null,
    groupName : null,
    message : "Document ownership assigned",
    databaseName : "",
    createdBy : this.createdBy,
    Flag : "Toster" ,
    WsLink : ""

  }
  this.sendmessage  = '';
  this.signInService.notifysend(notifysend).subscribe((response: any) => {
    if (response.isSuccess) {
      this.sendmessage = response.data;
    }
    else{
      this.sendmessage = '';
    }

  })
}

notifyinsertownerthird(){
  var st = window.location.toString();
  var arr = st?.split("/");
  var urlchangeall4 = arr[0]+ "//" + arr[2] + '/pages/documents/workspace-queue'

  let insert = {
    wsoid : Number(this.workSpaceId),
    notificationFrom : this.createdBy,
    notificationHeading : "Document ownership assigned",
    message : "<b>" + this.userfullname +" (" + this.createdBy + ")" + "</b>" + " " + "has made you the owner of " + this.numberofdoc + " documents. View " + `<a href=${urlchangeall4}>Workspace Queue</a>`,
    notificationTo : this.ownerfirst,
    createdBy : this.createdBy,
  }
  this.insertmessgae = '';
  this.signInService.notifyinsert(insert).subscribe((response: any) => {
    if (response.isSuccess) {
     this.insertmessgae = response.data;
     if(localStorage.getItem("countToast") == "1"){
      this.notifysendownerthird();
      localStorage.removeItem("countToast");
    }

    }
    else{
      this.insertmessgae = '';
    }

  })
}
notifysendownerthird(){

  let notifysend = {
    wsoid : Number(this.workSpaceId),
    key : null,
    userEmailID :  this.ownerfirst,
    role : null,
    groupName : null,
    message : "Document ownership assigned",
    databaseName : "",
    createdBy : this.createdBy,
    Flag : "Toster" ,
    WsLink : ""

  }
  this.sendmessage  = '';
  this.signInService.notifysend(notifysend).subscribe((response: any) => {
    if (response.isSuccess) {
      this.sendmessage = response.data;
    }
    else{
      this.sendmessage = '';
    }

  })
}

notifyinsertverifierfourth(){

  var st = window.location.toString();
  var arr = st?.split("/");
  var urlchange = arr[0]+ "//" + arr[2] + '/pages/documents/my-queue'

  let insert = {
    wsoid : Number(this.workSpaceId),
    notificationFrom : this.createdBy,
    notificationHeading : "Documents to be verified",
    message: "<b>" + this.userfullname + " (" + this.createdBy + ")" + "</b>" + " " + "has assigned you" + " " + this.numberofdoc + " " + "documents for verification. View " + `<a href=${urlchange}>My Queue</a>`,
    notificationTo : this.verifierfirst,
    createdBy : this.createdBy,
  }
  this.insertmessgae = '';
  this.signInService.notifyinsert(insert).subscribe((response: any) => {
    if (response.isSuccess) {
     this.insertmessgae = response.data;
    //  if(localStorage.getItem("countToast") == "1"){
    //   this.notifysendverifierfourth();
    //   localStorage.removeItem("countToast");
    this.toastrService.primary('Documents to be verified');
    // }


    }
    else{
      this.insertmessgae = '';
    }

  })
}
notifysendverifierfourth(){

  let notifysend = {
    wsoid : Number(this.workSpaceId),
    key : null,
    userEmailID :  this.verifierfirst,
    role : null,
    groupName : null,
    message : "Documents to be verified",
    databaseName : "",
    createdBy : this.createdBy,
    Flag : "Toster" ,
    WsLink : ""

  }
  this.sendmessage  = '';
  this.signInService.notifysend(notifysend).subscribe((response: any) => {
    if (response.isSuccess) {
      this.sendmessage = response.data;
    }
    else{
      this.sendmessage = '';
    }

  })
}

  closeUploadLimit() {
    this.uploadLimitRef.close();
  }
  @ViewChild('uploadLimit') uploadLimit;
  @ViewChild('workspaceStorage') workspaceStorage;


  isDirty = false;
  totalPageUsed: any = 0;
  totalSpaceUsed: any = 0;
  currentPageUsed: any = 0;
  currentSpaceUsed: any = 0;
  currentFileSize: any = 0;
  totalFileCount: number = 0;
  fileCount: number = 0;
  totalFileUpload: any = [];
  LargeFileUpload: any = [];
  btnUpload: boolean = true;

  isUploadStarted: boolean = false;

  dropDownOptionDisable: boolean = false;
  storageError: boolean = true;
  isUploadSuccess = false;
  selectedVerifierName: any;
  selectedDocumentOwner: any;
  selectedlanguage: any;
  documentOwnerName: any;
  verifierName: any;
  documentOwnerEmail: any;
  verifierNameEmail: any;
  documentLanguage: any;

  lists = [];
  verifierauto = [];
  ownerauto = [];
  languageauto = [];

  UploadRightsUserList() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }
    this.blockUI.start('Loading...');
    this.alluserlist.searchAllUsersService(postData).subscribe((result:any) =>{
      if (result.isSuccess) {
        let actualUsers = [];
        result.data.forEach(user=>{
        if(!user.roleName.includes("Tally") ){
        actualUsers.push(user);
      }
     })
     this.verifierauto = actualUsers;
     this.ownerauto = actualUsers;
    }
    })
    this.data.UploadRightsUserListService(postData).subscribe((response: any) => {
      if (response.isSuccess) {

        this.rightUserList = response.data;
        this.uploadRightUserList = response.data;
        for (let user of this.uploadRightUserList) {
          user.fullName;
          user['custom'] = user.fullName;
          user['custom'] = user.emailId;
        }
     for(let i=0;i<=this.rightUserList.length-1;i++){
       if(this.rightUserList[i].emailId == this.createdBy){
        if(!this.rightUserList[i].displayName.includes("(you)")){
          this.rightUserList[i].displayName = this.rightUserList[i].displayName + " " + "(you)"

        }
       }
     }
        for (let user of this.rightUserList) {
          if(this.dropdownverifier){
          if (user.emailId == this.dropdownverifier) {
            this.selectedVerifierName = user;
                this.selectedVerifierName.displayName = this.selectedVerifierName.displayName ;
                this.onVerifierName(this.selectedVerifierName);
                if(this.selectedVerifierName.emailId == this.createdBy){
                  if(!this.selectedVerifierName.displayName.includes("(you)")){
                    this.selectedVerifierName.displayName = this.selectedVerifierName.displayName + " " + "(you)"
                    this.onVerifierName(this.selectedVerifierName);
                  }
                }

          }
        }
        if(this.dropdownowner){
          if (user.emailId == this.dropdownowner) {

            this.selectedDocumentOwner = user;
                this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName ;
                this.onDocumentOwner(this.selectedDocumentOwner);
                if(this.selectedDocumentOwner.emailId == this.createdBy){
                  if(!this.selectedDocumentOwner.displayName.includes("(you)")){
                    this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName + " " + "(you)"
                    this.onDocumentOwner(this.selectedDocumentOwner);
                  }
                }

          }
        }
        }




        this.items = [];



        for (let i = 0; i <= this.rightUserList.length - 1; i++) {
          let itemvalue = {
            category: this.rightUserList[i].category,
            custom: this.rightUserList[i].custom,
            displayName: this.rightUserList[i].displayName,
            emailId: this.rightUserList[i].emailId,
            fullName: this.rightUserList[i].fullName,
            wsMid: this.rightUserList[i].wsMid,
            wsoid: this.rightUserList[i].wsoid
          }
          this.items.push(itemvalue);

        }
       

       

      }
    })
  }



  options: any = [];
  onVerifierName(event) {
    if (event?.fullName) {
      this.verifierName = event.fullName;
      this.verifierNameEmail = event.emailId;
      this.verifierfirst = event.emailId;
    }

  }
  verifierfirst;
  onSelectionChange($event) {
    this.selectedVerifierName = $event;
    if(this.selectedVerifierName.emailId == this.createdBy){
      if(!this.selectedVerifierName.displayName.includes("(you)")){
        this.selectedVerifierName.displayName = this.selectedVerifierName.displayName + "" + "(you)";
      }
    }
    this.verifierfirst = $event.emailId;
    this.onVerifierName($event);
  }

  ownerfirst;
  onOwnerChange($event) {
    this.selectedDocumentOwner = $event;
    this.ownerfirst = $event.emailId;
    this.onDocumentOwner($event);
  }
  onLanguageChange($event) {

    if(this.radiobtndata == 'ManualLanguage'){
      this.documentLanguage = $event.languageName;
    }
    else{
      this.documentLanguage = 'Autodetect';
    }
  }
  @ViewChild('autoInputOwner') inputOwner;
  @ViewChild('autoInput') input;
  @ViewChild('autoLanguage') lang;
  filterObject = (obj, filter, filterValue) =>
    Object.keys(obj).reduce((acc, val) =>
    (obj[val][filter] === filterValue ? acc : {
      ...acc,
      [val]: obj[val]
    }
    ), {});


  private filterLanguage(value: any): string[] {
    if (value) {
      let filterValue = '';
      if (typeof value == 'object') {
        filterValue = value.languageName.toLowerCase();
      }
      else {
        filterValue = value.toLowerCase();
      }
      return this.langList.filter(optionValue => {
        if (optionValue && optionValue.languageName)
          return optionValue.languageName.toLowerCase().includes(filterValue)
      });
    }
  }

  private filter(value: any): string[] {
    if (value) {
      let filterValue = '';
      if (typeof value == 'object') {
        filterValue = value?.displayName?.toLowerCase();
      }
      else {
        filterValue = value.toLowerCase();
      }
      return this.items.filter(optionValue => {
        if (optionValue && optionValue.displayName)
          return optionValue.displayName.toLowerCase().includes(filterValue)
      });
    } else if (value === "") {
      return this.items.filter(optionValue => {
        if (optionValue && optionValue.displayName)
          return optionValue.displayName.toLowerCase();
      });
    }
  }






  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.verifierauto = this.filter(this.input.nativeElement.value);
  }

  ownerSearch() {
    this.ownerauto = this.filter(this.input.nativeElement.value);

  }

  languageSearch() {
    if (!this.inputLang.nativeElement.value || this.inputLang.nativeElement.value.trim() == '') {
      this.languageauto = this.langList;
    }
    else {
      this.languageauto = this.filterLanguage(this.inputLang.nativeElement.value);

    }

  }

  onDocumentOwner(event) {
    if (event?.fullName) {
      this.documentOwnerName = event.fullName;
      this.documentOwnerEmail = event.emailId;
      this.ownerfirst = event.emailId;
    }
  }
  onSelectLanguage(event) {

    this.documentLanguage = event.languageName || event;
    this.getradiobtn('ManualLanguage')
  }

  langList: any;
  randomDocumentLanguages() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {

    }
    this.blockUI.start('Loading...');
    this.data.randomDocLanguageService(postData).subscribe((response: any) => {
      if (response.isSuccess) {

        this.rightLanguageList = response.data;
        this.languageauto = Object.values(this.rightLanguageList).slice();
        this.langList = Object.values(this.rightLanguageList).slice();

        this.lists = [];
        for (let i = 0; i <= this.rightUserList.length - 1; i++) {
          let listsvalue = {
            custom: this.rightUserList[i].custom,
            lId: this.rightUserList[i].lId,
            languageName: this.rightUserList[i].languageName
          }
          this.lists.push(listsvalue);
        }
      }
    })
  }

  /*
  RandomDocumentUpload/Languages  end
  */

  closeModal() {

    this.UploadRightsUserList();
    this.randomDocumentLanguages();
    this.files = [];
    this.documentLanguage = [];
    this.isRandomUploadModalOpen = false;
    let DropImage = document.getElementById("dropImage");
    let DropTarget = document.getElementById("dropTarget");
    this.LargeFileUpload = [];
    this.totalFileCount = this.totalFileUpload.length;
    if (this.totalFileCount < 1) {
      DropImage.style.display = "flex";
      DropTarget.style.backgroundColor = "#fcfcfc";
    }
    else {
      DropImage.style.display = "none";
    }
    this.largeFileCount = 0;
  }
  /*
   Close Modal Pop end
   */

  /*
  Cancel Random  Upload Pop start
  */
  @ViewChild('cancelUpload') cancelUpload;
  cancelRandomUpload() {

    if (this.totalFileCount > 0) {
      if (!this.FileSuccessMsg) {

        this.uploadRef?.close();
        this.canceluploadRef?.close();

        this.goToDashboardFunction('value');
        this.largeFileCount = 0;
      }
      else {

        this.canceluploadRef = this.dialogService.open(this.cancelUpload, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
        });
      }
    }

    else {

      this.uploadRef?.close();

      let verifierName = this.uploadDocForm.get('verifierName').value;
      let documentOwnerName = this.uploadDocForm.get('documentOwnerName').value;
      let selectLanguage = this.uploadDocForm.get('selectLanguage').value;

      if (verifierName.trim() == '' && documentOwnerName.trim() == '' && selectLanguage.trim() == '' && !this.isUploadSuccess) {
        this.closeUploadDialog.emit(false)
        return;
      }

      if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {


        this.uploadRef.open();
        this.isRandomUploadModalOpen = false;


      }
      else {
        return false;
      }
    }
    this.closeUploadDialog.emit(false)
  }



  /*
   Cancel Random  Upload Pop end
   */


  /*
   function for Remove File start
   */
  removeFile() {
    this.isRandomUploadModalOpen = false;
  }

  /*
   function for Go to Dashboard start
   */
  goToDashboardFunction(value?) {
    this.newtworkError = true;
    this.largeFileCount = 0;
    this.isRandomUploadModalOpen = false;
    this.selectFilesButton = false;
    this.languageRadioButton = false;
    this.btnUpload = true;
    this.isUploadStarted = false;
    this.totalFileCount = 0;
    this.totalPageUsed = 0;
    this.currentPageUsed = 0;
    this.totalSpaceUsed = 0;
    this.currentSpaceUsed = 0;
    this.successFileCount = 0;
    this.currentFileSize = 0;
    this.storageError = true;
    this.fileUploadPrgressBtn = false;
    this.files.length = 0;
    this.dropDownOptionDisable = false;
    this.UploadRightsUserList();
    this.randomDocumentLanguages();
    this.LargeFileUpload = [];
    this.isProgressShow = false;
    this.FileSuccessMsg = true;
    this.btnRemoveAllFile = false;
    this.files = [];
    this.uploadRightUserList = [];
    this.documentOwnerName = [];
    this.verifierName = [];
    this.documentLanguage = [];
    value ? '' : this.router.navigateByUrl('/pages/dashboard');
    this.canceluploadRef?.close();


    this.uploadRef?.close();
    this.sharedService.isNewFileuploaded.next(true);

    this.totalFileUpload = [];
    let DropImage = document.getElementById("dropImage");
    let DropTarget = document.getElementById("dropTarget");

  }

  restFileShow() {
    for (let i= this.globalIndex; i< this.files.length; i++) {
      this.checkIconwDisable[i] = true;
      this.crossIconwDisable[i] = true;
      this.invalidFiles[i] = false;
      this.internetFaliures[i] = true;
      this.errorIconDisable[i] = false;
      this.isNetworkError[i] = false;
      this.files[i].progress = 100;
      this.FileSuccessMsg = false;
      this.fileUploadPrgressBtn = false;
    }
  }


  /*
   Confirm Cancel Upload Modal Pop start
   */

  confirmCancelUploadModal() {
    this.largeFileCount = 0;
    this.isRandomUploadModalOpen = false;
    this.selectFilesButton = false;
    this.languageRadioButton = false;
    this.btnUpload = true;
    this.isUploadStarted = false;
    this.totalFileCount = 0;
    this.totalPageUsed = 0;
    this.totalSpaceUsed = 0;
    this.currentPageUsed = 0;
    this.currentSpaceUsed = 0;
    this.currentFileSize = 0;
    this.successFileCount = 0;
    this.storageError = true;
    this.files.length = 0;
    this.dropDownOptionDisable = false;
    this.UploadRightsUserList();
    this.randomDocumentLanguages();
    this.LargeFileUpload = [];
    this.isProgressShow = false;
    this.FileSuccessMsg = true;
    this.uploadRightUserList = [];
    this.documentOwnerName = [];
    this.verifierName = [];
    this.documentLanguage = [];


    this.canceluploadRef?.close();

    this.uploadRef?.close();
    this.totalFileUpload = [];

    let DropImage = document.getElementById("dropImage");
    let DropTarget = document.getElementById("dropTarget");
    if (this.totalFileCount < 1) {
    }
    else {
      DropImage.style.display = "none";
    }

  }

  /*
  Confirm Cancel Upload Modal Pop End
  */
  viewUploadedDocument() {
    this.largeFileCount = 0;
    if(this.router.url === '/pages/documents/my-queue'){
      window.location.reload();
    } else{
      this.router.navigateByUrl('/pages/documents/my-queue');
      let totalpages = this.totalPageUsed
      this.sharedService.fordpages.next(totalpages)
    }

    this.sharedService.isNewFileuploaded.next(true);
    this.uploadRightUserList = [];
    this.documentOwnerName = [];
    this.verifierName = [];
    this.documentLanguage = [];
    this.isRandomUploadModalOpen = false;
    this.confirmCancelUploadModal();
    this.fileUploadPrgressBtn = false;
  }
  /*
   Upload file for pop up false Start
  */
  uploadFiles() {
    this.isDirty = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  public doSomething($event) {
    if (this.isRandomUploadModalOpen) {

      $event.returnValue = 'Your data will be lost!';
    }
    else {
      return true;
    }
  }

  isRandomUploadModalOpen = false;
  openrandomUploadModal() {
    this.isRandomUploadModalOpen = true;
  }

  /**
   * function darg and drop end here
   */
  files: any[] = [];
  chekuploadcount;
  /*
   *All File Select Drop Area Function Start Here
    */
  onFileDropped($event) {


    if (this.isUploadStarted) {
      return;
    }

    this.prepareFilesList($event);
  }

  /*
   *handle file from browsing button Function Start Here
  */
   clearData(event){
    event.target.value = '';

   }
  fileBrowseHandler(files) {

    if (this.isUploadStarted) {
      return;
    }

    this.prepareFilesList(files);
    files = [];
  }


  /*
  *Delete File From Files List Function Start Here
  /*
   * Delete file from files list
   * @param index
   */
  @ViewChild('removeDoc') removeDoc;
  deletedIndexFile: any;

  clickOnCross(index: number) {

    this.btnRemoveFile[index] = false;
    if (this.fileOnGoingSession[index]) {
      this.deletedIndexFile = index;
      this.pendingRequest.unsubscribe();

      this.removeDocRef = this.dialogService.open(this.removeDoc, {
        closeOnBackdropClick: false,
        closeOnEsc: false,

      });
    }
    else if (this.fileOnGoingSession[index] == false) {
      this.deleteFile(index)
    }
  }

  /**
   *Toggle Uploading
   */
  ToggleUploading(type)
  {
      if(type=='pause')
      {
        this.pendingRequest.unsubscribe();
      }else if(type=='start'){
        let simIndex=0
        //this.deleteFile(1)
        
        for(let i=0; i<this.files.length; i++)
        {
          if(this.files[i].progress==100)
          {
            //this.deleteFile(i)
            simIndex=i+1
          }
        }

        this.uploadFilesSimulator(simIndex);
        this.reserveFileSpaceStorageCount()
        this.uploadSubmit('val');
        
      }
  }

  ToggleUploadingNew(index, type) {
    console.log("index and type is", index, type);
  }

  deleteFile(index: number) {
    let uploadNext = false;
    if((this.files[index].progress !== 0)){
      this.pendingRequest.unsubscribe();
      uploadNext = true;
    }
  
    let maximumFileSizeInKB = this.convertToKilobytes(this.maximumFileSize, this.maximumFileSizeUnit);
  
    for (let i = 0; i < this.files.length; i++) {
      if (i == index) {
        let availableFiles = this.totalFileUpload.filter((e) => e != this.files[i]);
        this.totalFileUpload = availableFiles;
        this.currentFileSize = (this.files[i].size / 1024);
  
        if (this.currentFileSize <= maximumFileSizeInKB) {
          this.currentSpaceUsed -= this.currentFileSize;
        }
  
        this.isRandomUploadModalOpen = false;
        this.isProgress.splice(index, 1);
        this.isError.splice(index, 1);
        this.files.splice(index, 1);
        this.largeFileCount = 0;
        this.LargeFileUpload = [];
  
        let DropImage = document.getElementById("dropImage");
        let DropTarget = document.getElementById("dropTarget");
  
        this.totalFileCount = this.totalFileUpload.length;
        DropImage.style.display = this.totalFileCount < 1 ? "flex" : "none";
        DropTarget.style.backgroundColor = this.totalFileCount < 1 ? "#fcfcfc" : "";
  
        if (this.currentSpaceUsed < 1024) this.totalSpaceUsed = this.currentSpaceUsed.toFixed(2) + " KB";
        else if (this.currentSpaceUsed >= 1024) this.totalSpaceUsed = (this.currentSpaceUsed / 1024).toFixed(2) + " MB";
        if ((this.currentSpaceUsed / 1024) >= 1024) this.totalSpaceUsed = (this.currentSpaceUsed / 1024 / 1024).toFixed(2) + " GB";
  
        this.currentPageUsed = Math.ceil(this.currentFileSize / this.documentpagesSizes);
        this.totalPageUsed -= this.itemSize;
        this.disableChoose = this.totalPageUsed >= this.availableStore;
  
        this.dpageOverError = this.totalPageUsed <= this.availableStore;
        this.storageError = this.currentPageUsed <= this.availableStore;
  
        if (this.totalFileCount === 0) {
          this.totalSpaceUsed = "0 KB";
          this.currentSpaceUsed = 0;
        }
  
        this.removeDocRef?.close();
  
        // Ensure the next file upload starts
        if (uploadNext) {
          console.log("file is uploaded");
          setTimeout(() => this.uploadFilesSimulator(index), 0);
        }
      }
    }
  
    let filesUploaded = this.files.every((res) => res.progress === 100);
    this.fileUploadPrgressBtn = filesUploaded && this.files.length > 0;
    this.cd.detectChanges();
  }
  


/*
*Send Email For verification  Api Function Start Here
*/

  sendDocEmail() {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "CreatedBy": this.createdBy,
      "DocVerifierEmailId": this.verifierNameEmail,
      "DocOwnerEmailId": this.documentOwnerEmail,
      "DocVerifierName": this.verifierName,
      "DocOwnerName": this.documentOwnerName,
      "DocCount": this.successFileCount.toString()
    }
    this.blockUI.start('Loading...');
    this.pendingRequest = this.data.sendDocEmail(postData).subscribe((response: any) => {
      if(response.isSuccess){

      }

    }, error => {
      setTimeout(() => {
        this.blockUI.stop();

      }, 1000)

    })

  }

/*
*Start Document Upload  Api Function Start Here
*/
  fileResponse: any = [];
  successFileCount: number = 0;
  StartDocumentUpload(saveUrl, file, index) {
    var st = window.location.toString();
    var arr = st?.split("/");
    var fileSize = Number(file.size / 1024).toFixed(2);
    let lastModifiedDate = new DatePipe('en-US').transform(file.lastModified, 'dd-MMM-yyyy hh:mm:ss');
    const formData = new FormData();

    formData.append("FileModifiedDate", lastModifiedDate);
    formData.append("Wsoid", (this.workSpaceId));
    formData.append("WsLink", arr[2]);
    formData.append("CreatedBy", this.createdBy);
    formData.append("files", file);
    formData.append("FRID", this.frId);
    formData.append("fileSize", fileSize.toString());
    formData.append("Verifier", this.verifierNameEmail);
    formData.append("DocumentOwner", this.documentOwnerEmail);
    formData.append("Language", this.documentLanguage);
    let headers = new HttpHeaders({

    });

    headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
    const uploadReq = new HttpRequest('POST', saveUrl, formData, {
      reportProgress: true,headers: headers
    });
    this.pendingRequest = this.http.request(uploadReq).subscribe(event => {

      if ('a'=='a') {
        this.fileResponse = event;
        if (this.fileResponse.body.isSuccess == true && this.fileResponse.body.statusCode == "C200") {
          this.checkIconwDisable[index] = false;
          this.crossIconwDisable[index] = true;
          this.invalidFiles[index] = false;
          this.btnRemoveFile[index] = true;
          this.successFileCount = this.successFileCount + 1;
          if ((this.files.length - 1) == index + this.largeFileCount) {

            this.isFileUploadStart = true;
            this.btnRemoveAllFile = true;
            this.FileSuccessMsg = false;
            this.largeFileCount = 0;

            this.fileUploadPrgressBtn = true;
            this.sendDocEmail();
          }
          else {
            this.btnRemoveAllFile = false;
          }

        }
        else if (this.fileResponse.body.isSuccess == false && this.fileResponse.body.statusCode == "C500") {
          this.checkIconwDisable[index] = true;
          this.crossIconwDisable[index] = true;
          this.invalidFiles[index] = false;
          this.errorIconDisable[index] = false;
          this.isNetworkError[index] = false;
          this.FileSuccessMsg = false;
          this.fileUploadPrgressBtn = true;

        }
      }
    }
      , error => {
        setTimeout(() => {
          this.blockUI.stop();
          this.networkBrkRef = this.dialogService.open(this.networkBrk, {
            closeOnBackdropClick: false,
            closeOnEsc: false,

          });
          this.checkIconwDisable[index] = true;
          this.crossIconwDisable[index] = true;
          this.invalidFiles[index] = false;
          this.errorIconDisable[index] = false;
          this.isNetworkError[index] = false;
          this.FileSuccessMsg = false;
          this.fileUploadPrgressBtn = true;

        }, 4000)
        this.pendingRequest.unsubscribe();
      }
    );
  }


  @ViewChild('networkBrk') networkBrk;
  pendingRequest: any;


  individualFileCancel(index: number) {
    this.btnRemoveFile[index] = false;

    this.removeDocRef?.close();

  }
 iserror : boolean;
 @ViewChild('fileDropRef') fileDropRef:any;
  onSelectFileClick(inEl) {
    document.getElementById('fileDropRef').click();

  }





  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      $event.returnValue = true; 
  }
  /**
   * this method is used to convert the file size into kilobytes and this help in validating the file size of the document.
   *
   * @param maximumFileSize
   * @param maximumFileSizeUnit
   * @returns Kilobytes
   */

  convertToKilobytes(maximumFileSize: number, maximumFileSizeUnit: string): number {
    const units = {
      KB: 1,
      MB: 1024,
      GB: 1024 * 1024,
      TB: 1024 * 1024 * 1024
    };
      return maximumFileSize * units[maximumFileSizeUnit];
  }

/*
 *  File Document Upload with progress Function Start Here
 */
  numberofdoc;
  indexdata;

  blankarray = [];
  uploadFilesSimulator(index: number) {
    let saveUrl = environment.baseUrl + '/api/newupload/docUpload';

    if (this.isProgressShow) {
      this.dropDownOptionDisable = true;
      this.btnUpload = true;
      this.selectFilesButton = true;
      this.languageRadioButton = true;
      this.isUploadStarted = true;
    }

    if (index === this.files.length) {
      return;
    } else {
      if (this.files[index]?.progress === 100) {
        this.checkIconwDisable[index] = true;
        if (this.isProgressShow) {
          this.isProgress[index] = true;

        }
        this.fileOnGoingSession[index] = false;
      }
      else {
        let maximumFileSizeInKB = this.convertToKilobytes(this.maximumFileSize,this.maximumFileSizeUnit);
        if (this.files[index]?.size / 1024 <= maximumFileSizeInKB && this.isSessionPause == false && index !== this.files.length) {
          var st = window.location.toString();
          var arr = st?.split("/");
          var fileSize = Number(this.files[index].size / 1024).toFixed(2);
          let lastModifiedDate = new DatePipe("en-US").transform(
            this.files[index].lastModified,
            "dd-MMM-yyyy hh:mm:ss"
          );
          let file = this.files[index];
          let filenamechk = file.name;
          var name = file.name;
         let docitdtid = 0
          file = new File([file], name, { type: file.type });
          let fileType = file.type?.split('/').at(-1);
          if ((this.formatsArray.includes(fileType.toUpperCase()) || this.formatsArray.includes(file.name?.split('.').at(-1).toUpperCase()) || fileType == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType == 'vnd.oasis.opendocument.text'))
          {
            let headers = new HttpHeaders({});
            let formDataNewObject = {
              "fileModifiedDate": lastModifiedDate,
              "wsoId": this.workSpaceId,
              "wsLink": arr[2],
              "createdBy": this.createdBy,
              "frId": this.frId,
              "fileSize": fileSize.toString(),
              "verifier": this.verifierNameEmail,
              "documentOwner": this.documentOwnerEmail,
              "language": this.documentLanguage,
              "flag": "dox2uapp",
              "docItDtId": this.frId,
              "docItId": this.frId,
              "originalFileName": filenamechk,
              "returnfilename": this.uploadFileName,
              "isParserEnable" : this.parserfeature,
            }
            const formData = new FormData();
            formData.append("fileData", file);
            formData.append("data",JSON.stringify(formDataNewObject));
            headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
            const uploadReq = new HttpRequest("POST", saveUrl, formData, {
              reportProgress: true,
              headers: headers,
              // responseType: 'json' // observe response
            });
            
            this.numberofdoc = this.files.length;
            this.pendingRequest = this.http
              .request(uploadReq)
              .subscribe((event) => {
                this.globalIndex = index;
                this.networkService.getOnlineStatus().subscribe(online => {
                  if (!online) {
                    console.log("tyruie");
                    
                     
                    if(this.newtworkError){
                        this.openModal(this.networkBrk, false);
                        this.newtworkError = false;
                        this.indexdata = index;
                    }
                     
                      console.log("cheques")
                      this.checkIconwDisable[this.globalIndex] = true;
                      this.crossIconwDisable[this.globalIndex] = true;
                      this.invalidFiles[this.globalIndex] = false;
                      this.errorIconDisable[this.globalIndex] = false;
                      this.isNetworkError[this.globalIndex] = true;
                      this.FileSuccessMsg = false;
                      // this.fileUploadPrgressBtn = true;
      
                    
                      // this.networkService.onlineStatus.next(true);
                  }
                });
                
              //   if(!navigator.onLine){
              //     console.log("tyruie");
              //     // setTimeout(() => {
              //       if(this.newtworkError){
              //         this.globalIndex = index;
              //         this.openModal(this.networkBrk, false);
              //         this.newtworkError = false;
              //         this.indexdata = index;
              //       }
              //       console.log("cheques")
              //       this.checkIconwDisable[index] = true;
              //       this.crossIconwDisable[index] = true;
              //       this.invalidFiles[index] = false;
              //       this.errorIconDisable[index] = false;
              //       this.isNetworkError[index] = true;
              //       this.FileSuccessMsg = false;
              //       this.fileUploadPrgressBtn = true;
    
              //     // }, 1000)
              //     // this.pendingRequest.unsubscribe();
              // } 
                this.event = event;
                console.log("event is", event);
                if (!this.event.ok && this.files[index].progress !== 100 && this.event.type !== 0 && navigator.onLine) {
                  // setInterval(()=> { 
                //     if(!navigator.onLine){
                //     console.log("tyruie");
                //     setTimeout(() => {
                //       if(this.newtworkError){
                //         this.globalIndex = index;
                //         this.openModal(this.networkBrk, false);
                //         this.newtworkError = false;
                //         this.indexdata = index;
                //       }
                //       console.log("cheques")
                //       this.checkIconwDisable[index] = true;
                //       this.crossIconwDisable[index] = true;
                //       this.invalidFiles[index] = false;
                //       this.errorIconDisable[index] = false;
                //       this.isNetworkError[index] = false;
                //       this.FileSuccessMsg = false;
                //       this.fileUploadPrgressBtn = true;

                //     }, 1000)
                //     this.pendingRequest.unsubscribe();
                // } 
              // }, 4000);

                  this.files[index].progress = Math.round((100 / this.event.total) * this.event.loaded);
                } else if (this.event.ok && this.event.type === HttpEventType.Response && navigator.onLine) {
                  this.isProgress[index] = true;
                  // this logic is used to tackle the handling of zero size file in upload section
                  if(this.event.body.statusCode === null) {
                    this.checkIconwDisable[index] = true;
                    this.crossIconwDisable[index] = true;
                    this.invalidFiles[index] = true;
                    this.btnRemoveFile[index] = true;
                    this.protectedfile[index] = true;
                    this.successFileCount = this.successFileCount + 1;
                    if (index === this.files.length) {
                      return;
                    } else {
                      this.uploadFilesSimulator(index + 1);
                    }
                    if ((this.files.length - 1) == index + this.largeFileCount) {

                      this.isFileUploadStart = true;
                      this.btnRemoveAllFile = true;
                      this.FileSuccessMsg = false;
                      this.largeFileCount = 0;

                      this.fileUploadPrgressBtn = true;
                      if(this.fileUploadPrgressBtn){
                        this.notifyupload();
                        if(this.verifierfirst && this.ownerfirst){
                          if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                              this.notifyinsertverifierfirst();
                        }
                        }
                        if(this.verifierfirst && this.ownerfirst){
                          if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertverifiersecond();
                              this.notifyinsertownersecond();
                        }
                        }
                        if(this.ownerfirst){
                          if(!this.verifierfirst){
                             this.verifierfirst = this.createdBy;
                             if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertownerthird();
                                }
                          }
                        }

                        if(this.verifierfirst){
                          if(!this.ownerfirst){
                            this.ownerfirst = this.createdBy;
                            if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertverifierfourth();
                             }
                          }

                        }
                      }
                      this.sendDocEmail();
                      this.cd.detectChanges();
                    }
                    else {
                      this.btnRemoveAllFile = false;
                    }
                  }
               if(this.event.body.statusCode !== "C501"){
                  if(this.event.body.statusCode !== "C500"){
                    this.uploadFileName = this.event.body.filename;
                  this.uploadFileDocId = this.event.body.DocID;
                   this.uploadFileCreatedDate = this.event.body.Request.fileCreatedDate;
                  this.uploadFileModifiedDate = this.event.body.Request.fileModifiedDate;
               this.uploadFileFileSize = this.event.body.Request.originalSize;
              this.uploadFileOriginalFileName = this.event.body.Request.originalFileName;
              if(filenamechk !== name){
                    this.namenotmatch[index] = true;
                  }
                  else{
                    this.namenotmatch[index] = false;
                  }
         if (this.event.ok) {
                     this.fileResponse = this.event;
                     if (this.fileResponse.ok && this.fileResponse.status.toString() == "200") {
               this.checkIconwDisable[index] = false;
                this.crossIconwDisable[index] = true;
                this.invalidFiles[index] = false;
                    this.btnRemoveFile[index] = true;
                this.successFileCount = this.successFileCount + 1;
                      if (index === this.files.length) {
                         return;
                    }  else {
                       this.uploadFilesSimulator(index + 1);
              }

                    if ((this.files.length - 1) == index + this.largeFileCount) {
                       this.isFileUploadStart = true;
                       this.btnRemoveAllFile = true;
                        this.FileSuccessMsg = false;
                       this.largeFileCount = 0;

                        this.fileUploadPrgressBtn = true;
                       if(this.fileUploadPrgressBtn){
                          this.notifyupload();
                          localStorage.setItem("countToast", "1")
                       if(this.isuploadnotshowagain == '0'){

                          }
                       if(this.verifierfirst && this.ownerfirst){
                            if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                            this.notifyinsertverifierfirst();
                            localStorage.setItem("countToast", "1")
         }
                         }

                         if(this.verifierfirst && this.ownerfirst){
                           if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                          this.notifyinsertverifiersecond();
                          this.notifyinsertownersecond();
                          localStorage.setItem("countToast", "1")

                           }
                        }
                    }


                         if(this.ownerfirst){
                          localStorage.setItem("countToast", "1")
                          if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertownerthird();

                                  }

                              }

                          if(this.verifierfirst){

                             if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertverifierfourth();

                                 }

                              }
                         }


                    this.cd.detectChanges();
                       }
                       else {
                       this.btnRemoveAllFile = false;
                     }

                    }
                      else if (this.fileResponse.ok == false && this.fileResponse.status.toString() == "500") {
                      this.checkIconwDisable[index] = true;
                      this.crossIconwDisable[index] = true;
                      this.invalidFiles[index] = false;
                      this.errorIconDisable[index] = false;
                      this.isNetworkError[index] = false;
                        this.FileSuccessMsg = false;
                        this.fileUploadPrgressBtn = true;
                        this.successFileCount = this.successFileCount + 1;
                        if (index === this.files.length) {
                          return;
                        } else {
                          this.uploadFilesSimulator(index + 1);
                        }

                      }

                    }
                    else if(this.event.body.statusCode === "C500"){
                      this.checkIconwDisable[index] = false;
                      this.crossIconwDisable[index] = true;
                      this.invalidFiles[index] = false;
                      this.btnRemoveFile[index] = true;
                      this.protectedfile[index] = true;

                      this.successFileCount = this.successFileCount + 1;
                      if (index === this.files.length) {
                        return;
                      } else {
                        this.uploadFilesSimulator(index + 1);
                      }
                      if ((this.files.length - 1) == index + this.largeFileCount) {
                        localStorage.setItem("countToast", "1")
                        this.isFileUploadStart = true;
                        this.btnRemoveAllFile = true;
                        this.FileSuccessMsg = false;
                        this.largeFileCount = 0;

                        this.fileUploadPrgressBtn = true;
                        if(this.fileUploadPrgressBtn){
                          this.notifyupload();
                          if(this.verifierfirst && this.ownerfirst){
                            if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                                this.notifyinsertverifierfirst();
                          }
                          }
                          if(this.verifierfirst && this.ownerfirst){
                            if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertverifiersecond();
                                this.notifyinsertownersecond();
                          }
                          }
                          if(this.ownerfirst){
                            if(!this.verifierfirst){
                               this.verifierfirst = this.createdBy;
                               if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertownerthird();
                                  }
                            }
                          }

                          if(this.verifierfirst){
                            if(!this.ownerfirst){
                              this.ownerfirst = this.createdBy;
                              if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertverifierfourth();
                               }
                            }

                          }
                        }
                      }
                      else {
                        this.btnRemoveAllFile = false;
                      }
                }
                  }
                  else if(this.event.body.statusCode === "C500"){
                    this.checkIconwDisable[index] = false;
                    this.crossIconwDisable[index] = true;
                    this.invalidFiles[index] = false;
                    this.btnRemoveFile[index] = true;
                    this.protectedfile[index] = true;

                    this.successFileCount = this.successFileCount + 1;
                    if (index === this.files.length) {
                      return;
                    } else {
                      this.uploadFilesSimulator(index + 1);
                    }
                    if ((this.files.length - 1) == index + this.largeFileCount) {

                      this.isFileUploadStart = true;
                      this.btnRemoveAllFile = true;
                      this.FileSuccessMsg = false;
                      this.largeFileCount = 0;

                      this.fileUploadPrgressBtn = true;
                      if(this.fileUploadPrgressBtn){
                        this.notifyupload();
                        if(this.verifierfirst && this.ownerfirst){
                          if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                              this.notifyinsertverifierfirst();
                        }
                        }
                        if(this.verifierfirst && this.ownerfirst){
                          if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertverifiersecond();
                              this.notifyinsertownersecond();
                        }
                        }
                        if(this.ownerfirst){
                          if(!this.verifierfirst){
                             this.verifierfirst = this.createdBy;
                             if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertownerthird();
                                }
                          }
                        }

                        if(this.verifierfirst){
                          if(!this.ownerfirst){
                            this.ownerfirst = this.createdBy;
                            if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                              this.notifyinsertverifierfourth();
                             }
                          }

                        }
                      }
                      this.sendDocEmail();
                      this.cd.detectChanges();
                    }
                    else {
                      this.btnRemoveAllFile = false;
                    }
              }


                else if(this.event.body.statusCode === "C501"){
                      this.checkIconwDisable[index] = false;
                      this.crossIconwDisable[index] = true;
                      this.invalidFiles[index] = false;
                      this.btnRemoveFile[index] = true;
                      this.protectedfile[index] = true;

                      this.successFileCount = this.successFileCount + 1;
                      if (index === this.files.length) {
                        return;
                      } else {
                        this.uploadFilesSimulator(index + 1);
                      }
                      if ((this.files.length - 1) == index + this.largeFileCount) {

                        this.isFileUploadStart = true;
                        this.btnRemoveAllFile = true;
                        this.FileSuccessMsg = false;
                        this.largeFileCount = 0;

                        this.fileUploadPrgressBtn = true;
                        if(this.fileUploadPrgressBtn){
                          this.notifyupload();
                          if(this.verifierfirst && this.ownerfirst){
                            if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                                this.notifyinsertverifierfirst();
                          }
                          }
                          if(this.verifierfirst && this.ownerfirst){
                            if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertverifiersecond();
                                this.notifyinsertownersecond();
                          }
                          }
                          if(this.ownerfirst){
                            if(!this.verifierfirst){
                               this.verifierfirst = this.createdBy;
                               if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertownerthird();
                                  }
                            }
                          }

                          if(this.verifierfirst){
                            if(!this.ownerfirst){
                              this.ownerfirst = this.createdBy;
                              if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                                this.notifyinsertverifierfourth();
                               }
                            }

                          }
                        }
                        this.sendDocEmail();
                        this.cd.detectChanges();
                      }
                      else {
                        this.btnRemoveAllFile = false;
                      }
                }
                else if(this.event.body.statusCode === "C500"){
                  this.checkIconwDisable[index] = false;
                  this.crossIconwDisable[index] = true;
                  this.invalidFiles[index] = false;
                  this.btnRemoveFile[index] = true;
                  this.protectedfile[index] = true;

                  this.successFileCount = this.successFileCount + 1;
                  if (index === this.files.length) {
                    return;
                  } else {
                    this.uploadFilesSimulator(index + 1);
                  }
                  if ((this.files.length - 1) == index + this.largeFileCount) {

                    this.isFileUploadStart = true;
                    this.btnRemoveAllFile = true;
                    this.FileSuccessMsg = false;
                    this.largeFileCount = 0;

                    this.fileUploadPrgressBtn = true;
                    if(this.fileUploadPrgressBtn){
                      this.notifyupload();
                      if(this.verifierfirst && this.ownerfirst){
                        if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                            this.notifyinsertverifierfirst();
                      }
                      }
                      if(this.verifierfirst && this.ownerfirst){
                        if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                            this.notifyinsertverifiersecond();
                            this.notifyinsertownersecond();
                      }
                      }
                      if(this.ownerfirst){
                        if(!this.verifierfirst){
                           this.verifierfirst = this.createdBy;
                           if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                            this.notifyinsertownerthird();
                              }
                        }
                      }

                      if(this.verifierfirst){
                        if(!this.ownerfirst){
                          this.ownerfirst = this.createdBy;
                          if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                            this.notifyinsertverifierfourth();
                           }
                        }

                      }
                    }
                    this.sendDocEmail();
                    this.cd.detectChanges();
                  }
                  else {
                    this.btnRemoveAllFile = false;
                  }
            }
            else if(this.event.body.statusCode === "C500"){
                this.checkIconwDisable[index] = false;
                this.crossIconwDisable[index] = true;
                this.invalidFiles[index] = false;
                this.btnRemoveFile[index] = true;
                this.protectedfile[index] = true;

                this.successFileCount = this.successFileCount + 1;
                if (index === this.files.length) {
                  return;
                } else {
                  this.uploadFilesSimulator(index + 1);
                }
                if ((this.files.length - 1) == index + this.largeFileCount) {

                  this.isFileUploadStart = true;
                  this.btnRemoveAllFile = true;
                  this.FileSuccessMsg = false;
                  this.largeFileCount = 0;

                  this.fileUploadPrgressBtn = true;
                  if(this.fileUploadPrgressBtn){
                    this.notifyupload();
                    if(this.verifierfirst && this.ownerfirst){
                      if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst == this.ownerfirst)){
                          this.notifyinsertverifierfirst();
                    }
                    }
                    if(this.verifierfirst && this.ownerfirst){
                      if((this.createdBy != this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                          this.notifyinsertverifiersecond();
                          this.notifyinsertownersecond();
                    }
                    }
                    if(this.ownerfirst){
                      if(!this.verifierfirst){
                         this.verifierfirst = this.createdBy;
                         if((this.createdBy == this.verifierfirst) && (this.createdBy != this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                          this.notifyinsertownerthird();
                            }
                      }
                    }

                    if(this.verifierfirst){
                      if(!this.ownerfirst){
                        this.ownerfirst = this.createdBy;
                        if((this.createdBy != this.verifierfirst) && (this.createdBy == this.ownerfirst) && (this.verifierfirst != this.ownerfirst)){
                          this.notifyinsertverifierfourth();
                         }
                      }

                    }
                  }
                  this.sendDocEmail();
                  this.cd.detectChanges();
                }
                else {
                  this.btnRemoveAllFile = false;
                }
          }


              if(index == this.numberofdoc-1){
                this.sendDocEmail();
              }

                }

          }, (error) => {
                // setTimeout(() => {
                //   console.log("connection down");
                //   this.blockUI.stop();
                //   this.networkBrkRef = this.dialogService.open(this.networkBrk, {
                //     closeOnBackdropClick: false,
                //     closeOnEsc: false,

                //   });
                  console.log("connection down");
                  this.checkIconwDisable[index] = true;
                  this.crossIconwDisable[index] = true;
                  this.invalidFiles[index] = false;
                  this.apiFilesError[index] = true;
                  this.errorIconDisable[index] = false;
                  this.isNetworkError[index] = false;
                  this.FileSuccessMsg = false;    

                // }, 2000)
                // this.pendingRequest.unsubscribe();
                this.uploadFilesSimulator(index + 1);
                // this.uploadFilesSimulator(index + 1);
              });
            // this.crossIconwDisable[index] = false;
            // this.errorIconDisable[index] = true;
            // this.invalidFiles[index] = false;
            // this.isError[index] = false;
            this.fileOnGoingSession[index] = true;
            // this.fileformatenotsupport[index]= false;
            // this.pendingRequest.unsubscribe();
            // this.uploadFilesSimulator(index + 1);
            this.cd.detectChanges();
          }
          else{
            this.fileformatenotsupport[index]= true;
            this.uploadFilesSimulator(index + 1);
          }

        }
        else {
          this.crossIconwDisable[index] = true;
          this.invalidFiles[index] = false;
          this.errorIconDisable[index] = false;
          if(this.totalFileCount === 0){
            this.btnUpload = true;
          }
          if (this.files.length - 1 == index) {
            this.sendDocEmail();
          }
          else {
            this.uploadFilesSimulator(index + 1);
          }
        }
      }
    }
  }

  uploadResponse(files,index,docitdtid,docItId){
    let saveUrl = environment.baseUrl + '/api/newupload/docUpload';
    let availableStorage =  Number(localStorage.getItem('availableStorage'))

      var st = window.location.toString();
      var arr = st.split("/");
      var fileSize = Number(files[index]?.size / 1024)?.toFixed(2);
      let newfileValidation = Number(fileSize)*1024
      availableStorage = availableStorage-Number(newfileValidation)
      localStorage.setItem('availableStorage',String(availableStorage))
      if (((newfileValidation) > availableStorage)){
        // to resolve code smell issue !(availableStorage > -9437184)
        if(availableStorage <= -9437184){
          this.sharedService.upgradeIt.next('upgradeIt');
          return;
        }
      }

      let lastModifiedDate = new DatePipe("en-US").transform(
        files[index]?.lastModified,
        "dd-MMM-yyyy hh:mm:ss"
      );
      let file = files[index];
      let filenamechk = file?.name;
      var name = file?.name;
      this.frId = Number(0);
      file = new File([file], name, { type: file?.type });
      let fileType = file?.type.split('/').at(-1);
      // if ((this.formatsArray?.includes(fileType.toUpperCase()) || this.formatsArray?.includes(file?.name?.split('.').at(-1).toUpperCase()) || fileType == 'vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType == 'vnd.oasis.opendocument.text'))
      // {
        let jobID = localStorage.getItem('JobID');
        let headers = new HttpHeaders({});
        // this.networkService.getOnlineStatus().subscribe((online) => {
        //   if(!online) {
        //     this.newtworkErrorDocket = true;
        //   } else {
        //     this.newtworkErrorDocket = false;
        //   }
        // })
        let formDataNewObject = {
          "fileModifiedDate": lastModifiedDate,
          "wsoId": this.workSpaceId,
          "wsLink": arr[2],
          "createdBy": this.createdBy,
          "frId": this.frId,
          "fileSize": fileSize.toString(),
          "verifier": this.createdBy,
          "documentOwner": this.createdBy,
          "language": "English",
          "flag": "dox2uapp",
          "docItDtId": docitdtid,
          "docItId": docItId,
          "uniqueId": jobID,
          "originalFileName": filenamechk,
          "returnfilename": this.uploadFileName,
          "isParserEnable" : this.parserfeature,
        }
        const formData = new FormData();
        formData.append("fileData", file);
        formData.append("data",JSON.stringify(formDataNewObject));
        headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
        const uploadReq = new HttpRequest("POST", saveUrl, formData, {
          reportProgress: true,headers: headers
        });
        this.numberofdoc = files.length;
        let uploadComplete = false;
        this.pendingRequest = this.http
          .request(uploadReq)
          .subscribe((event) => {
            this.event = event;
            console.log("himanshu", this.event);
            if (this.event.loaded !== undefined) {
              files[index].progress = Math.round((100 / this.event.total) * this.event.loaded);
            } else {
              files[index].progress = 100;
            }
            
            console.log("files are here", files[index]);
            this.isProgress[index] = true;
            this.sharedService.responseUploadProgressStatus.next(files[index].progress);
            this.networkService.getOnlineStatus().subscribe((online) => {
              if(!online) {
                this.newtworkErrorDocket = true;
                this.countCall++;
                this.fileResponse.validationError = "Network Error";
                this.sharedService.DocketFileUploadError.next([true, this.fileResponse.validationError]);
                this.pendingRequest.unsubscribe();
                setTimeout(() => {
                  this.countCall = 0;
                }, 500);
              } else {
                this.newtworkErrorDocket = false;
            if(files[index].progress === 100){
              uploadComplete = true;
              this.sharedService.responseUploadProgress.next(false);
            } else if (!isNaN(files[index].progress)){
              this.sharedService.responseUploadProgress.next(true);
            }
            if(uploadComplete && isNaN(files[index.progress])){
              this.sharedService.DocketFileUploadError.next([false]);
            }
            // this logic is used to tackle the handling of zero size file in the docket
            if(this.event?.body?.statusCode === null) {
              this.countCall++;
              this.fileResponse.validationError = "Invalid file";
              this.sharedService.DocketFileUploadError.next([true, this.fileResponse.validationError]);
              setTimeout(() => {
                this.countCall = 0;
              }, 500);
            }
            if((this.event?.body?.statusCode === 'C500' || this.event?.body?.statusCode === 'C501' || (this.event?.body?.statusCode && this.event?.body?.statusCode === 'C500')) && this.countCall === 0){
              this.countCall++;
              this.fileResponse.validationError = "File is locked/corrupt"
              this.sharedService.DocketFileUploadError.next([true, this.fileResponse.validationError]);
              setTimeout(() => {
                this.countCall = 0;
              }, 500);
            }
            
            if(this.count === 0 && uploadComplete && this.event?.body){
              if ((this.event?.status === 202)) {
                this.sharedService.documentTypeNotExist.next(true);
              }
              if((this.event?.status === 200)) {
                this.sharedService.responseDocDetails.next(this.event.body);
                this.uploadFileName = this.event?.body?.filename;
                this.uploadFileDocId = this.event?.body?.DocId;
                localStorage.setItem("docId",this.uploadFileDocId);
                this.uploadFileCreatedDate = this.event?.body?.Request.fileCreatedDate;
                this.uploadFileModifiedDate = this.event?.body?.Request.fileModifiedDate;
                this.uploadFileFileSize = this.event?.body?.Request.originalSize;
                this.uploadFileOriginalFileName = this.event?.body?.Request.originalFileName;
              }
              this.count++;
              setTimeout(() => {
                this.count = 0;
              }, 500);
            }
            if(uploadComplete && this.event?.body){
              localStorage.setItem("trigerUploadNext", "true");
            }
              }
            })
          }, (error) => {
            if (!this.newtworkErrorDocket) {
              this.countCall++;
              this.fileResponse.validationError = "Error from Server";
              this.sharedService.DocketFileUploadError.next([true, this.fileResponse.validationError]);
              setTimeout(() => {
                this.countCall = 0;
              }, 500);
            } 
          })
  }
 /*
  * Releasing Reserve Storage And Count Function For API Get start here
  */
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick:boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {closeOnBackdropClick});

  }
  donotshowmodal(dialogModal: TemplateRef<any>, closeOnBackdropClick:boolean){
    this.notshowagain = this.dialogService.open(dialogModal, {closeOnBackdropClick});
  }

  checkboxval: any;
  checkboxclick(event: any,elementID){
    this.checkboxval = event;
    let checkdata = {
      wsoid : Number(this.workSpaceId),
      emailId : this.createdBy
    }
    this.data.checkboxclick(checkdata).subscribe((response: any) => {
      if(response.isSuccess){
        if(this.checkboxval){
          localStorage.setItem('isuploadnotshowagain',"1",)
          this.isuploadnotshowagain = localStorage.getItem('isuploadnotshowagain')
        }
        else{
          localStorage.setItem('isuploadnotshowagain',"0",)
          this.isuploadnotshowagain = localStorage.getItem('isuploadnotshowagain')
        }

      }
    })
  }


  releasingReserveFileSpaceCount() {
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "CreatedBy": this.createdBy,
    }
    this.blockUI.start('Loading...');
    this.pendingRequest = this.data.releasingReserveFileSpaceCount(postData).subscribe((response: any) => {
      if (response.statusCode == "C200") {
        this.largeFileCount = 0;
      }
    }, error => {
      setTimeout(() => {
        this.blockUI.stop();

      }, 1000)

    })
  }

/*
* Reserve File Space Storage And File Count Function Start Here
*/
reseruploaddata;
  reserveFileSpaceStorageCount() {
    this.isProgressShow = true;

        this.frId = Number(0);
        this.uploadFilesSimulator(0);

  }


  // public formatName(name:String){
  //   const extension=name?.split('?')[0]?.split('.').pop();
  //   const fileName=name?.split('.').slice(0, -1).join('.')
  //  return fileName.replace(/[\./\\,"'/:*?|{}]/gi, " ")+'.'+extension;
  // }

  /*
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  // ******************  All File Select and drop panel Function Start Here *******************
  itemSize
  prepareFilesList(files: Array<any>) {
    this.isRandomUploadModalOpen = true;
    let maximumFileSizeInKB = this.convertToKilobytes(this.maximumFileSize,this.maximumFileSizeUnit);
    let flcount = 0;
    let counter = 0;
    let uploadFileCount = files.length;

    for (let item of files) {
      if(item.name.includes('.')){
        var extension = item.name.substr(item.name.lastIndexOf('.'));
        if (extension) {

          this.files.push(item);
          this.itemSize = item?.size
          if (((item.size) / 1024) <= maximumFileSizeInKB) {
            this.currentFileSize = ((item.size) / 1024);

            this.currentSpaceUsed = (this.currentSpaceUsed + this.currentFileSize);
            this.checkIconwDisable[flcount] = true;
            this.errorIconDisable[flcount] = true;
            this.crossIconwDisable[flcount] = false;
            this.invalidFiles[flcount] = false;
            this.totalFileUpload.push(item);
            item.progress = 0;
            this.errorMassageValidation = true;
          }

          else {
            this.errorIconDisable[flcount] = true;
            this.crossIconwDisable[flcount] = false;
            this.invalidFiles[flcount] = false;
            this.checkIconwDisable[flcount] = true;
            this.LargeFileUpload.push(item);
            this.errorMassageValidation = false;
          }

          let drop_area = document.getElementsByClassName("drop-Area")[0];
          let el = drop_area.childElementCount;
          let DropImage = document.getElementById("dropImage");
          let DropTarget = document.getElementById("dropTarget");
          if (el > 0) {
            DropImage.style.display = "none";
            DropTarget.style.backgroundColor = "white";
            DropTarget.style.border = "none";
          }

          flcount = 0;
          this.files = [];
          var ufileName = "";
          counter = counter + 1;

          for (let item of this.totalFileUpload) {
            this.files.push(item);
            if ((ufileName == "" || ufileName != item.name) && counter == uploadFileCount) {
              ufileName = item.name;
              if (((item.size) / 1024) <= maximumFileSizeInKB) {
                this.currentFileSize = ((item.size) / 1024);
                this.isProgress[flcount] = false;
                this.isError[flcount] = false;
                this.protectedfile[flcount] = false;
                this.isNetworkError[flcount] = true;
                this.errorIconDisable[flcount] = true;
                this.checkIconwDisable[flcount] = true;
                this.crossIconwDisable[flcount] = false;
                this.invalidFiles[flcount] = false;
              }
              else {
                this.isProgress[flcount] = true;
                this.isError[flcount] = true;
                this.errorIconDisable[flcount] = true;
                this.crossIconwDisable[flcount] = false;
                this.invalidFiles[flcount] = false;
                this.checkIconwDisable[flcount] = true;
              }

            }
            else {
              this.isNetworkError[flcount] = true;
              this.errorIconDisable[flcount] = true;
              this.crossIconwDisable[flcount] = false;
              this.invalidFiles[flcount] = false;
              this.checkIconwDisable[flcount] = true;
            }
            flcount = flcount + 1;
          }

          this.totalFileCount = this.files.length;
          for (let item of this.LargeFileUpload) {
            this.files.push(item);
            if ((ufileName == "" || ufileName != item.name) && counter == uploadFileCount) {
              ufileName = "item.name";
              if (((item.size) / 1024) <= maximumFileSizeInKB) {
                this.currentFileSize = ((item.size) / 1024);
                this.isProgress[flcount] = false;
                this.isError[flcount] = false;
                this.protectedfile[flcount] = false;
                this.isNetworkError[flcount] = true;
                this.crossIconwDisable[flcount] = false;
                this.invalidFiles[flcount] = false;
              }
              else {
                this.largeFileCount = this.largeFileCount + 1;
                this.isProgress[flcount] = true;
                this.isError[flcount] = true;
                this.protectedfile[flcount] = false;
                this.isNetworkError[flcount] = true;
                this.checkIconwDisable[flcount] = true;
                this.errorIconDisable[flcount] = true;
                this.crossIconwDisable[flcount] = false;
                this.invalidFiles[flcount] = false;
              }

              flcount = flcount + 1;
            }
            else {

            }
          }
          if (this.currentSpaceUsed < 1024)
            this.totalSpaceUsed = (this.currentSpaceUsed).toFixed(2) + " KB";
          if (this.currentSpaceUsed >= 1024)
            this.totalSpaceUsed = (this.currentSpaceUsed / 1024).toFixed(2) + " MB";
          if ((this.currentSpaceUsed / 1024) >= 1024)
            this.totalSpaceUsed = ((this.currentSpaceUsed / 1024) / 1024).toFixed(2) + " GB";
            const pagesCount = this.currentSpaceUsed/1024

            this.currentPageUsed = Math.ceil(this.currentFileSize/this.documentpagesSizes);
            this.totalPageUsed += this.itemSize;
          if ((this.totalPageUsed >= this.availableStore)) {
            this.disableChoose = true;
          } else {
            this.disableChoose = false;
          }
          if (((this.totalPageUsed) > this.availableStore)){
            this.dpageOverError = false;
            this.btnUpload = true;
            // if(this.subscriptionId === 1||this.subscriptionId === 4 ){

            this.upgradeChild.openUpgradeUpload('upload additional dPages', 'EM-048');
            let indexData1: number = 0;
            for(let i=0;i<=files.length-1;i++){
              this.blankarray.push(files)
            }
            for (let file of this.blankarray) {
              this.fileOnGoingSession[indexData1] = false;
              indexData1 = indexData1 + 1;
            }
            // }

            return;


          }
          else {
            this.dpageOverError = true;
          }
          if (this.currentPageUsed > this.availableStore) {
            this.btnUpload = true;
            this.storageError = false;
          }
          else {
            this.btnUpload = false;
            this.storageError = true;
          }
        }
      }


    }

    let index: number = 0;

    for(let i=0;i<=files.length-1;i++){
      this.blankarray.push(files)
    }
    for (let file of this.blankarray) {
      this.fileOnGoingSession[index] = false;
      index = index + 1;
    }
  }

  onProceedToUpload() {
    this.newtworkError = true;
    this.UploadRightsUserList();
    this.randomDocumentLanguages(); 
    this.networkBrkRef?.close();
    this.canceluploadRef?.close();
    this.pendingRequest.unsubscribe();
    // this.sharedService.isNewFileuploaded.next(true);
    this.restFileShow();
    let DropImage = document.getElementById("dropImage");
    let DropTarget = document.getElementById("dropTarget");
  }

  /*
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];


  }

  /*
  * Change Request adding dpages popover on file selections start
  */

  dpagesSize(sizes:number){
    let pagesCount= sizes/1024
    return "dPages : " + Math.ceil(pagesCount/this.documentpagesSizes);
  }

   /*
  * adding dpages popover on file selections ends
  */

  goToolTipFunction() {
    let DropImage = document.getElementById("tool-tip__info");
    DropImage.style.display = "flex";
  }


  hideMenuItems = true;
  toggleMenuItems() {
    this.hideMenuItems = !this.hideMenuItems;
  }

  onSubmit() {

    this.uploadRef?.close();
  }
  closeUpload() {

    if (this.files.length === 0) {
      this.router.navigateByUrl('/pages/dashboard');
      this.confirmCancelUploadModal();
      this.uploadRef?.close();
      this.fileUploadPrgressBtn = false;
      this.cd.detectChanges();
    }

    if (this.totalFileCount > 0) {
      if (this.FileSuccessMsg == false) {
        this.confirmCancelUploadModal();
        this.uploadRef?.close();
        this.fileUploadPrgressBtn = false;
        this.cd.detectChanges();
      }
      else {
        this.canceluploadRef = this.dialogService.open(this.cancelUpload, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
        });

      }
    }

  }

  /*
   Click outside for popover
  */

   public text: String;
   @HostListener('document:click', ['$event'])
   clickout(event) {
     if(this.eRef.nativeElement.contains(event.target)) {
       this.text = "clicked inside";

     } else {
       this.text = "clicked outside";
       this.showFormat = false;

     }

   }

   onClickedOutside(e: Event) {
    this.showFormat = false;
  }

  /*
   Show Format types
   */
   public showFormat:boolean = false;
   clickFormat() {
    this.showFormat = !this.showFormat;
    this.cd.detectChanges();
   }
  @ViewChild('autoInputLang') inputLang;

  radiobtndata;
  getradiobtn(data){
    this.radiobtndata = data;

    if(this.radiobtndata == 'Autodetect'){
      this.documentLanguage = 'Autodetect';
    }
  }


  MyDocListcount() {

    let postData = {
      "DocumentFormat": "",
      "DocumentOwner": "",
      "EndDate": "",
      "OriginalFileName": "",
      "StartDate": "",
      "UploadedBy": "",
      "Pagecount":1,
      "NumberofRow":50,
      "Verifier": this.createdBy,
      "Wsoid": Number(this.workSpaceId),

    }
    this.myqueueservice.myQueueListService(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        if(response?.data[0]?.totalcount){
          localStorage.setItem('redalert', response?.data[0]?.totalcount)
        }else{
          const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
          if (docinupload && docinupload.children) {
            docinupload.badge.dotMode = false;
            docinupload.badge.status = '';
            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            docinuploadchild.badge.text = '';
            docinuploadchild.badge.status = '';

          }      }
        let redalertval = JSON.parse(localStorage.getItem('redalert'));
        if(redalertval != 0){
          let docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");

          docinupload.badge.dotMode = true;
          docinupload.badge.status = 'danger';
          if (docinupload && docinupload.children) {

            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            if(redalertval == 67){
              docinuploadchild.badge.text = "99+";
              docinuploadchild.badge.status = 'danger';
            }
            if (redalertval > 99) {
              docinuploadchild.badge.text = "99+";
              docinuploadchild.badge.status = 'danger';
            }
            else {
              docinuploadchild.badge.text = redalertval;
              docinuploadchild.badge.status = 'danger';
            }
        }


        }
           else{
          const docinupload = this.menu.find((obj) => obj.title === "Docs in Upload");
          if (docinupload && docinupload.children) {
            docinupload.badge.dotMode = false;
            docinupload.badge.status = '';
            const docinuploadchild = docinupload.children.find((obj) => obj.title === "My Queue");
            docinuploadchild.badge.text = '';
            docinuploadchild.badge.status = '';

          }
      }
    }
    })
  }

}
