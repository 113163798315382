import { Observable } from 'rxjs';
import { DocChart } from './doc-chart';
import { OrdersChart } from './orders-chart';
import { ProfitChart  } from './profit-chart';
import { SharedChart } from './shared-chart';
/**
 * profit chart
*/
export interface OrderProfitChartSummary {
  /**
      *  variable for title of the profit chart value
   */
  title: string;
   /**
      *  variable for values of the profit chart value
   */
  value: number;
}
/**
      *  OrdersProfitChartData export
*/
export abstract class OrdersProfitChartData {
   /**
      *  method for getOrderProfitChartSummary
   */
  abstract getOrderProfitChartSummary(summary): Observable<OrderProfitChartSummary[]>;
   /**
      *  method for getOrdersChartData
   */
  abstract getOrdersChartData(period: string): Observable<OrdersChart>;
   /**
      *  method for getProfitChartData
   */
  abstract getProfitChartData(period: string): Observable<ProfitChart>;
   /**
      *  method for getDocChartData
   */
  abstract getDocChartData(period: string): Observable<DocChart>;
   /**
      *  method for getSharedChartData
   */
  abstract getSharedChartData(period: string): Observable<SharedChart>;

}
