import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteDocsService {

  constructor(private apiService:ApiServiceService) { }

  notifysend(data) {
    return this.apiService.post('/api/Notification/UserSendMessage', data);
  }

  getInvitedUsersLists(data)
  {
    return this.apiService.post('/api/AllUsers/Search',data)
  }
  
  deleteOwnedService(data) {
    return this.apiService.post('/api/OwnedByMe/DeleteOwnedByMeDoc', data);
  }

  afterDeleteOwnedService(data) {
    return this.apiService.post('/api/OwnedByMe/AfterDeleteOwnedByMeDoc', data);
  }


  getaccessrights(data) {
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }



}
 