/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Verify-Document Component
///  Description: It is inside verification module . It contains the view,editor,verifier document view screen code
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar
///  Created On : 10-07-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar
///  Date:10-07-2023
///  Description: Last Modification is of alignment of parser status code to show various screen.
///
///------------------------------------------------------------------->
import { ChangeDetectorRef, Component, ElementRef, HostListener, Optional, OnInit, TemplateRef, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import { Location } from '@angular/common';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { NbDateService, NbDialogRef, NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbSpinnerService, NbThemeService, NbToastrService } from '@nebular/theme';
import { VerifyDocumentService } from '../../@core/verify-document.service';
import { TextLayerRenderedEvent } from 'ngx-extended-pdf-viewer/lib/events/textlayer-rendered';
import { SignUpService } from '../../@core/sign-up.service';
import { LayoutService } from '../../@core/utils';
import { UserData } from '../../@core/data/users';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { TemplateEditorComponent } from '../../shared/template-editor/template-editor.component';
import { DownloadComponent } from '../../shared/download/download.component';
import { PrintDocumentComponent } from '../../shared/print-document/print-document.component'
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { MyQueauComponent } from '../../pages/docs-in-upload/my-queau/my-queau.component';
import { SharedAccessComponent } from '../../shared/shared-access/shared-access.component';
import { PrintService } from '../../@core/utils/print.service';
import { SharedService } from '../../@core/utils/shared.service';
import { SignInService } from "../../@core/sign-in.service";
import * as mdl from "@aspnet/signalr";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { RandomUploadService } from '../../@core/random-upload.service';
import { SharedServiceService } from '../../shared-service.service';
import { GuestUserModalComponent } from '../../shared/guest-user-modal/guest-user-modal.component';
import { UpgradeModalComponent } from '../../shared/upgrade-modal/upgrade-modal.component';
import { SupportComponent } from '../../@theme/components/header/support/support.component';
import { WorkspaceManagementService } from '../../@core/utils/workspace-management.service';
import { UserSettingService } from '../../@core/utils/user-setting.service';
import { FeatureFlagsService } from '../../@core/utils/feature-flag.service';
import { DetailAndActivityComponent } from '../../shared/detail-and-activity/detail-and-activity.component';
import { DeleteDocsComponent } from '../../shared/delete-docs/delete-docs.component';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { BulkTagComponent } from '../../shared/bulk-tag/bulk-tag.component';
import * as FileSaver from "file-saver";
import {Clipboard} from '@angular/cdk/clipboard';

declare global {
  interface Window {
    productFruitsUser: any;
    productFruits: any;
    pfDisableUrlChangeDetection: boolean
    Tawk_API: any
  }
}
@Component({
  selector: 'ngx-verify-document',
  templateUrl: './verify-document.component.html',
  styleUrls: ['./verify-document.component.scss']
})
export class VerifyDocumentComponent implements OnInit {
  @ViewChild(TemplateEditorComponent) templateEditorChild: TemplateEditorComponent;
  @ViewChild(DownloadComponent) downloadChild: DownloadComponent;
  @ViewChild(MyQueauComponent) myqueueparser: MyQueauComponent;
  @ViewChild(PrintDocumentComponent) printChild: PrintDocumentComponent;
  @ViewChild(GuestUserModalComponent) guestChild: GuestUserModalComponent;
  @ViewChild('verify', { read: TemplateRef }) verify: TemplateRef<any>;
  @ViewChild('wsOutSync', { read: TemplateRef }) wsOutSync: TemplateRef<any>;
  @ViewChild('multiple', { read: TemplateRef }) multiple: TemplateRef<any>;
  @ViewChild('saveRightsChange', { read: TemplateRef }) saveRightsChange: TemplateRef<any>;
  saveRightsChangeModalRef:any
  @ViewChild('verifierChange', { read: TemplateRef }) verifierChange: TemplateRef<any>;
  @ViewChild('restoreModal', { read: TemplateRef }) restoreModal: TemplateRef<any>;
  @ViewChild('discardChanges', { read: TemplateRef }) discardChanges: TemplateRef<any>;
  @ViewChild('viewextractedtext', { read: TemplateRef }) viewextractedtext: TemplateRef<any>;
  @ViewChild('reparsingsecond', { read: TemplateRef }) reparsingsecond: TemplateRef<any>;
  @ViewChild(DetailAndActivityComponent) rightnavchild : DetailAndActivityComponent;
  @ViewChild(SupportComponent) supportChild: SupportComponent;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('outsideNameBox') outsideNameBox: ElementRef;
  @ViewChild(DeleteDocsComponent) deleteChild: DeleteDocsComponent;
  @ViewChild(BulkTagComponent) bulkTagCom: BulkTagComponent;
  // @ViewChild("accountdisabled") accountdisabled: TemplateRef<any>;
  // @ViewChild("accountdisabledverify") accountdisabledverify: TemplateRef<any>;
  /*
  * route map creation
  */
  routeMap = new Map<string, boolean>();
  acv = 'low'
  sentForExtraction = true;
  extractionStarted = false;
  postProcessing = false;
  load = true;
  wait = false;
  tick1 = false;
  tick2 = false;
  tick3 = false;
  docId = '';
  DocGuid = '';
  sourceFile = "";
  sourceFilesearch = '';
  myDocs = false;
  pageTitle: string;
  messages: any = [];
  fileName = '';
  public isDocDetail: boolean = false;
  fileDate = '';
  docModifiedEditedName: string;
  originalFileNameDocs = '';
  renamedFileName = "";
  docDetails: any = [];
  enforceRenamingDocs: boolean;
  documentStatusCheck: boolean;
  fileNameVerification: string = '';
  spiltFileName: string;
  tagTempLabel: string;
  isGuestAvailable : boolean = false;
  tallyFlag = 'false';
  docState = '';
  disableVerifyDueToNameLength = false;
  inputNameFieldActive = false;
  VisibUnvisib: string = "visible";
  tagTempValue: string;
  tagTemptControlId: string;
  errorMessage = '';
  showErrorMessage = false;
  docGuid = '';
  isSubmitted = false;
  showText = false;
  shareAcessDisable: boolean = false;
  uploadRightUserList = [];
  ownerEmailID: string;
  verifierEmailID: string;
  verifierName: string;
  selectedDocumentOwner: any;
  selectedShareAcess: any = [];
  rightUserList: any;
  DeleteRow: string = "hidden"
  DeleteDropDown: string = "hidden"
  AddOptionDisabled: boolean = false
  tempeditsubmit: boolean = true;
  allowContextMenu = false;
  printIconDisabled = false;
  verificationDate: any;
  isPrintDownloadGuestEnableTally: boolean = true;
  @ViewChild('verify') verifyDoc;
  @ViewChild('shareChild') shareAccess: SharedAccessComponent;
  @ViewChild('shareChild') shareuse: SharedAccessComponent;
  /*
  * variable for delete rights  share rights
  */
  chkguestShareright: string;
  guestShare:boolean;
  chkIsDelete:string;
  isDelete: boolean;
  isGuestAccess:boolean=false;
  emptyPage:any;
  viewDelete: boolean= false;
  /**
  * profileImagePath var
  */
  profileImagePath: any;  
  showTextLayer: any;
  docTemplateId = '';
  shareuserCount: number = 0;
  ownByMe = false;
  trash: boolean;
  shareWithMe: boolean;
  href: string;
  isverification = false;
  isView = false;
  docDisplayName: any;
  myTrash = false;
  chkdownloadprint: string;
  chkeditrights: string;
  downloadprint: any;
  editrights: any;
  docFormat: any;
  showTextLayers = false;
  sidebarOpen = false;
  showSearch = false;
  showSearchablepdf = false;
  showautodetect = false;
  showtetectlangs = false;
  showenglish = false;
  dateFormat: string;
  calledBefore: any;
  docOwnerName: any;
  docTagList: any;
  hidenotify = true;
  isEdit = false;
  selectedDropdownValue: any;
  allWs = false;
  role = "View";
  docModifiedSystemName: any;
  showVerifier = true;
  isNameDuplicate = false;
  sharedWithUsers = [];
  dashboard = false;
  isAllUserShare = false;
  verifymode = false;
  isIE;
  isFirefox=false;  
  isDocEdited = false;
  disableSaveButton = true;
  isSavedClick = false;
  isSharedChanged: boolean;
  isTagEdited: boolean;
  isAllCheckPassed = false;
  defultlanguage: string;
  isSave: string;
  public _hubConnecton?: mdl.HubConnection;
  public msg?: string;
  public result = [];
  rolename;
  groupname;
  public langs = []
  allUserLength: any;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  angle = "270"
  items = [
    { title: 'Text', icon: "text-outline", id: "text" },
    { title: 'Date', icon: "calendar-outline", id: "date" },
  ];
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  documentsDockets = [];

  currentTheme = 'default';
  isNotActive = true;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  workSpaceId;
  createdBy;
  isDocDuplicateName: any;
  showData: boolean;
  downloadDoc = [];
  selectedDoc = [];
  saveChangesClick: boolean;
  WorkSpaceName: string;
  WsdisplayName: string;
  middot = ' � ';
  getguid;
  text: string;
  pendingStatus: string;
  createdByName;
  isVerifyClick = false;
  parserflagdata;
  btnDisable: boolean = true;
  radiobtndata;
  documentLanguage: any;
  languageauto = [];
  rightLanguageList: any;
  langList: any;
  showSpinner: Boolean = false;
  showSpinnertextext = false;
  spinnereparse = false;
  viewmode = false;
  trashmode = false;
  search = false;
  editmode = false;
  commoncabinet = false;
  languageviewext = [];
  selectedlanguagecheck: any;
  parserflag: string;
  docmodifiednameinputboxduplicate: string;
  extractedstatus: string;
  checkGuestAccess: string;
  subscriptionPlan: string;
  guestAccess: any;
  guestPlanRight: boolean;
  verifyData: any;
  shareDocCount = '0';
  showBanner: boolean;
  GuestFlag: string;
  ViewExtractedTextFeatureFlag;
  disableSave: boolean;
  searchcom = false;
  searchdocdis = true;
  selectDocumentName: any;
  toastrOpen: boolean;
  isOpen: boolean;
  showSpinnerVerify: boolean;
  unparserDocData: any;
  cabinetData1 = false;
  value: string;
  iseditableDocument: any;
  digiRights: string;
  editClicked: boolean;
  checkEscValue: boolean;
  disableVerify: boolean;
  docSharedStatus: string;
  isTooltipClicked = false;
  hoverOnShare = false;
  showTooltipAfterClicked = false;
  verifyParser: Subscription;
  ConfidenceValue: any;
  isCheckedCurrentAllUsers: any;
  isAllStatus: any;
  removeToolTip: boolean = true;
  viewerTally:boolean=false
  viewertag: boolean=true;
  viewerList:boolean=false;
  viewerDoc: boolean=false;
  getUserDataSingleTag: string;
  singleTagsList: string[];
  singleTagFlag: boolean;
  isInputEnterorSpace: boolean;
  userInputSingleTag: any;
  openVairable: boolean;
  blankState: boolean;
  profileData: any ='';
  subscriptionOwnedByDetail: Subscription;
  subscriptionOwnedBy: Subscription;
  isEditablevalue = false;
  upgradeMsg: boolean;
  extr=false;
  docIsDisplayed: boolean = true;
  docUnderProcessing: boolean = false;
  docFormatNotSupported: boolean = false;
  docIsLockedOrCorrupt: boolean = false;
  docParkingQueue: boolean = false;
  domainurl: any;
  changebg: boolean = false; /**To change background color of details&activity panel icon on click */
  tagLists = [];
  /**
   * store the document docId for permanently delete modal postdata
   */
  docIdList:string[]=[];
  /**
   * store the document guid for permanently delete modal postdata
   */
  docguidlist:string[]= [];
  /**
   * store the document originalName of the document for permanently delete modal postdata
   */
  orignalFileNameList:string[] = [];
  /**
   * variable to check the otherreason for permanently delete document
   */
  Otherreason: boolean = false;
  /**
   * variable to capture the selection for the permanently delete document modal
   */
  @ViewChild('OtherReason') OtherReason!: ElementRef
  /**
   * store the document deletion option selection of the document for permanently delete modal postdata
   */
  selectedOption: any = 'Poor scan quality';
  /**
   * store the document extraction text download rights
   */
  downloadExtractedTextPdf='';
  // docRenameVal: void;

  @ViewChild ('unlinkDocument', { read: TemplateRef }) unlinkDocumentHere: TemplateRef<any>;

  alphaville = '';
  tallyUserId = '';
  documentOwnerId= '';
  sharedIs = false;
  vouchersTags = [];
  masterTags = [];
  finalTallyTags =[];
  openAccordion: boolean[] = [];
  tagsHeight: number = 0;
  deleteDocModalHeading='';
  deleteDocModalMsg='';
  /**
   * for checking if the opened document is guest shared or not
   */
  isDocGuestShared: boolean = false;
    /**
   * for guest share modal
   */
  docSharedRealTimeNotifyHeader:String;
  docSharedRealTimeNotifyBody:String;
  docSharedRealTimeNotifyFooter:String;
  ownerTrashValue: boolean = false;
  urlchange = '';
      /**
   * for checking if the user is from tally or not
   */
  isFromTally: boolean = false;
  isDocketsPreview: boolean = false;
  openAccordionDockets: boolean[] = [];
  documentsForDockets = [];
  vouchers: any;
  uniqueId: any;
  docketsAccess: any;
  isDocketsPlan: boolean = false;
  docketsInPlan: string;
  isDocketReallyInPlan: boolean = false;
  constructor(private readonly printService: PrintService, private renderer: Renderer2, @Optional() private userSettingsRef: NbDialogRef<any>,
    private eRef: ElementRef,
    private http: HttpClient,
    private dataprofile: WorkspaceManagementService,
    private cookieService: CookieService,
    protected dateService: NbDateService<Date>, @Optional() private dialogRef: NbDialogRef<any>, private toastrService: NbToastrService,
    private location: Location, private verifyDocumentService: VerifyDocumentService, private activatedRoute: ActivatedRoute,
    private data: VerifyDocumentService,
    private clipboard: Clipboard,
    private langaugedata: RandomUploadService,
    private sharedServiceService: SharedServiceService,
    private router: Router, private spinner: NbSpinnerService,
    private titleService: Title, private fb: FormBuilder,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private dialogService: NbDialogService,
    private signInService: SignInService,
    private userService: UserData,
    private toastr: ToastrService,
    private featureFlagService: FeatureFlagsService,
    private datanew: UserSettingService,
    private layoutService: LayoutService, private signUpService: SignUpService,
    private breakpointService: NbMediaBreakpointsService, private cd: ChangeDetectorRef,
    private sharedService: SharedService,
    private logger: NGXLogger) {
    this.GuestFlag = localStorage.getItem('GuestFlag');
    this.tallyFlag = localStorage.getItem('TallyIntegration');
    this.parserflagdata = localStorage.getItem('parserflag');
    this.guestAccess = localStorage.getItem('isGuestAccess');
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.getguid = localStorage.getItem('loginguid');
    this.dateFormat = localStorage.getItem('DateFormat')
    this.subscriptionPlan = localStorage.getItem('wsSubscriptionPlan')
    this.editrights = JSON.parse(localStorage.getItem('editaccess'));
    this.digiRights = JSON.parse(localStorage.getItem('AllwsFlag'));
    this.ViewExtractedTextFeatureFlag = localStorage.getItem('ViewExtractedText');
    this.downloadExtractedTextPdf = localStorage.getItem('downloadExtractedText');
    this.tallyUserId = localStorage.getItem('localloginId');
    this.documentOwnerId = localStorage.getItem('eid');
    this.docketsAccess = localStorage.getItem('isDocket');
    this.domainurl = environment.domain;
    this.docketsInPlan = localStorage.getItem('dList');
    /**
     * Setting the cookies for the url containing (:)
     */
      console.log('string', window.location.toString());
      let st = this.domainurl?.split(':')[1];
      if (st !== '') {
        this.cookieService.set('localloginId' + this.workSpaceId.toString(), this.createdBy);
        this.cookieService.set('localwsId' + this.workSpaceId.toString(), JSON.stringify(this.workSpaceId));
      }
      // if (this.cookieService.get('SSOURL')) {
      //   this.cookieService.delete('SSOURL');
      //   this.sharedService.notifySilentSignIn.next(true);
      // }
      if (sessionStorage.getItem('SSOURL')) {
        sessionStorage.removeItem('SSOURL')
      }

    /*
    * Hamburger autoclose when user click outiside the hamabuger menu (Abhishek & Shubham)
    */
    this.renderer.listen('window', 'click',(e: any)=>{
     if(e.target.ownerSVGElement !== this.toggleButton.nativeElement?.firstElementChild?.firstElementChild && e.target!==this.menu.nativeElement){
        this.showViewerMenu=false;
     }
    });

    if (!this.editrights && this.documentOwnerEmail !== this.createdBy) {
      if (this.router.url.includes('edit')) {
        // this.router.navigate(['/error/error-page']);
      }
    }
    if (this.ViewExtractedTextFeatureFlag != 'True') {
      this.searchdocdis = false;
    }
    else {
      this.searchdocdis = true;
    }

    if (!this.createdBy) {
      this.router.navigateByUrl('/onboarding/sign-in');
      console.log("verify 1");
    }
    this.activatedRoute.queryParams.pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((params) => {
      console.log("params", params);
      this.docId = params['docId'];
      localStorage.setItem("documentId",this.docId);
      localStorage.setItem("documentIds",this.docId);
      
      this.docGuid = params['docGuid'];
      let component = localStorage.getItem('redirectedFrom')
      if (component !== null) {
        if (component == 'my-docs') {
          this.myDocs = true;
        }
        else if (component == 'own-by-me') {
          this.ownByMe = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        } else if (component == 'cabinet') {
          this.commoncabinet = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        }

        else if (component == 'shared-with-me') {
          this.shareWithMe = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        } else if (component == 'trash') {
          this.trash = true;
        } else if (component == 'all-workspace') {
          this.allWs = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        } else if (component == 'Search') {
          this.search = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        } else if (component == 'cabinet') {
          this.commoncabinet = true;
          if (this.router.url.includes('edit')) {
            this.editmode = true;
          }
          else if (this.router.url.includes('view')) {
            this.viewmode = true;

          }
        } else if (component == 'dashboard') {
          this.dashboard = true;
          if (this.router.url.includes('verification')) {
            this.verifymode = true;
            this.viewmode = false;
            this.trashmode = false;
            this.editmode = false;
          } else if (this.router.url.includes('view')) {
            this.viewmode = true;
            this.verifymode = false;
            this.trashmode = false;
            this.editmode = false;
          } else if (this.router.url.includes('my-trash')) {
            this.trashmode = true;
            this.viewmode = false;
            this.verifymode = false;
            this.editmode = false;
          }
          if (this.router.url.includes('edit')) {
            this.editmode = true;
            this.verifymode = false;
            this.viewmode = false;
            this.trashmode = false;
          }

        }
      } else {
        console.log("dashboard true")
        if (this.router.url.includes('verification')) {
          this.myDocs = true;
        } else if (this.router.url.includes('view')) {
          this.dashboard = true;
        } else if (this.router.url.includes('my-trash')) {
          this.trash = true;
        } else if (this.router.url.includes('edit')) {
          this.dashboard = true;
        }
      }

    })

    /*
    * route map initialzation
    */
    const routeData = {
      '/pages/documents/owned-by-me': this.ownByMe,
      '/pages/documents/my-queue': this.myDocs,
      '/pages/search': this.search,
      '/pages/documents/shared-with-me': this.shareWithMe,
      '/pages/documents/trash': this.trash,
      '/pages/all-workspace':this.allWs,
      '/pages/dashboard': this.dashboard,
      '/pages/cabinet':this.commoncabinet,
    };
    for (const key of Object.keys(routeData)) {
      this.routeMap.set(key, routeData[key]);
    }

    this.connectiongenrate();
    if (this.tallyUserId !== undefined && this.tallyUserId !== null && this.documentOwnerId !== undefined && this.documentOwnerId !== null) {
      if (this. tallyUserId != this.documentOwnerId) {
        this.sharedIs = true;
      } else {
        this.sharedIs = false;
      }
    }

  }
  showViewerMenu: boolean = false;


  shownewnotify;
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event:any){
    if(event.keyCode === 17){
      event.preventDefault();
      return false
    }
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  mouseenter() {
    if (localStorage.getItem('newnotify')) {
      this.getallbudgescount();
      this.shownewnotify = localStorage.getItem('newnotify')
      localStorage.setItem('shownotifynew', this.shownewnotify)

      localStorage.removeItem('newnotify');
      this.newnotification();
      this.cd.detectChanges();
    }
  }

  /*
          *outside click
  */
  outsideclick;
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (this.userclickinput !== 'docsName') {
        if (this.blankview1 != "blank") {
          if (!this.duplicateview1) {
            if (!this.verifyerrormorecharduplicate) {
              this.editverifydocname = false;
              this.valuecheckdulicate = false;
            }
          }
        }
        else {
          this.blankview1 = '';
        }

      }
      else {
        this.userclickinput = '';
      }
      if (this.displayownerid !== 'ownernameshow') {
        if (this.ownercard !== 'ownercardclick') {
          this.showowner = false;
        }

        else {
          this.ownercard = '';
        }
      }
      else {
        this.displayownerid = '';
      }

    }

    if (this.eRef.nativeElement.contains(event.target)) {

      if (!this.detailsview1) {
        if (this.detaiopenclk !== 'detailsclick') {
          this.showdetailspopoverview = false;
          this.showdetailspopover = false;
        }
        else {
          this.detaiopenclk = '';
        }

      }
      else {
        this.detailsview1 = '';
      }
    }

    if (this.eRef.nativeElement.contains(event.target)) {
      if (!this.duplicateview1) {

        this.isDocDuplicateName = false;
      }
      else {
        this.duplicateview1 = '';
      }

    }

    if (this.eRef.nativeElement.contains(event.target)) {
      if (!this.verifycheckexist) {

        this.fieldblankverify = '';

      }
      else {
        this.verifycheckexist = '';
      }
    }

    if (this.eRef.nativeElement.contains(event.target)) {
      if (!this.filtersaveedit) {

        this.fieldblankedit = '';

      }
      else {
        this.fieldblankedit = '';
      }
    }
  }
  /*
   * Help and Support hamburger code start here
   */
  isblue: boolean;
  openHelp() {
    window.open("https://help.dox2u.com/", "_blank");
    this.isblue = true;
  }

  showSupport: boolean = true;
  supportHelp() {
    this.showSupport = !this.showSupport;
  }

  openSupport() {
    this.supportChild.openSupport();
  }

  /*
   * Help and Support hamburger end here
   */

  /*
   * details and activity start here
   */

  rightnavdatadetail;
  sendetailvalue(data, isGuest?) {
    if (data) {
      this.rightnavdatadetail = data;
      this.rightnavchild.actdata(this.rightnavdatadetail, isGuest);
    } else {
      this.rightnavdatadetail = "";
      this.rightnavchild.actdata(this.rightnavdatadetail, isGuest);
    }
  }

  /*
  * details and activity main function here changes by Abhishek
  */
  hidedetail: boolean = true;
  tagPanelOpen: boolean = true;
  isIconClicked = true;
  detailsection() {
    this.changebg = true;
    this.viewertag = false;
    this.viewerTally=false;
    this.viewerDoc=false
    this.isDocketsPreview = false;
    this.isCloseClicked =  this.isIconClicked;
    localStorage.setItem("Invokepreview", String(false));
    this.tagPanelOpen = true;
    this.previewPanelOpen = true;

    this.hidedetail = false;
    localStorage.setItem("saved", "false");
    this.hideData();
    if (!this.hidedetail) {
      this.sendetailvalue(this.docDetails[0]);
    }
    this.hidedetail ? this.crossdata() : "";
    this.hidedetail ? this.showData1() : "";
  }

  /*
  * hideData() function
  */

 isDetailOpen = false;
 hideData() {
   this.previewPanelOpen = true;
   this.isDetailOpen = true;
  }

  /*
   * showData1() function
   */
  previewPanelOpen: boolean = true;
  showData1() {
    this.isDetailOpen = false;
    this.previewPanelOpen = true;
  }
  /*
   * crossdata() function
   */
  isSelectedOnceDetailsView = false;
  isCloseClicked = false;
  crossdata() {
    this.hidedetail = true;
    this.isSelectedOnceDetailsView = true;
    this.isIconClicked = this.isCloseClicked;
    this.isCloseClicked = !this.isCloseClicked;
  }
  /*
   * details and activity modal new end here changes by Abhishek and Shubham
   */

  /*
  * delete modal start here changes by Abhishek and Shubham
  */
  openDeleteModalGlobal(){
    const ids = []
    this.downloadDoc.forEach(obj => {
      ids.push(obj.docId)
    })
    const body = {
      wsoid: Number(this.workSpaceId),
      docID: ids.toString(),
      role: 'Delete',
      database: 'get2doxmaster',
      createdby: this.createdBy,
      isdeleteby: true,
      guid: this.getguid,
    };
    this.data.deleteAccessMultipleService(body).subscribe((Response: any) => {
      if (Response.data.filter(a => a.docShareStatus === 'Not Access').lenght > 0) {
        this.openModal(this.wsOutSync, false);

      } else {
        this.deleteChild.callAuthCheck();
      }



    }
    );
}

  open() {

    this.dialogRef = this.dialogService.open(this.dialog, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });


  }

  wsName: string;
  tagTemplate: any = [];
  tagTempList: any = [];
  tagTemplateId: string = "";
  pendingRequest: any;
  options: any = [];
  activeAccordion: number;

  ownerconfig = {
    displayKey: "ownerDropdown",
    search: true,
    placeholder: "Select User",
    multiple: false,
    height: "150px"
  };

  tagtemplateconfig = {
    displayKey: "tagTemplateDropdown",
    search: true,
    placeholder: "Default",
    multiple: false,
    height: "150px"
  };

  /*
  * navigate to the back pages
  */
  goBack(): void {
      this.routeMap.forEach((value: boolean, key: string) => {
        if(value === true){
              this.router.navigateByUrl(key);
              return;
            }

    });
  }

  

  toggleAccordion(index) {
    this.vouchersTags.forEach((res, i) => {
      if (index == i && this.openAccordion[i]) {
        this.openAccordion[i] = false;
      }
      else if (index == i && !this.openAccordion[i]) {
        this.openAccordion[i] = true;
      } else {
        this.openAccordion[i] = false;
      }
    })   
  }

  getShowData(index) : boolean {
    if (this.openAccordion[index] == true) {
      return true
    } else {
      return false;
    }
  }


  openAccordians(accordiansArr, index) {
    accordiansArr.forEach((res, i) => {
      if (index == i && res == false) {
        console.log("index is here", index, i);
        accordiansArr[i] = true;
      }
      else if(index == i && res == true) {
        accordiansArr[i] = false;
      } else {
        console.log("index is not here", index, i);
        accordiansArr[i] = false;
      }
    })
    console.log("accordians array is", accordiansArr);
  }


  fullName = '';
  userfullname;
  changeText: boolean;
  imageUrlStatic: any;
  imageNewUrl: any;
  ediimageUrl: any = '../../../assets/images/Default_Profile_Photo.svg';
  loginProfilImage: any;
  Full_name: any
  display_name: any;
  showIconCrad = true;
  isContentEditAble:any = 'false';
  @ViewChild('Password') Password!: TemplateRef<any>
  @ViewChild('UserSetingModal') UserSetingModal!: TemplateRef<any>
  onVerifyDocDetails(params?){
    var st = window.location.toString();
    var arr = st?.split("/");
    let postdata={
      "DocGuid" :params?.docGuid,
      "DocID": params?.docId,
      // "WsLink": arr[2],
      "Wsoid": Number(localStorage.getItem('localwsId')),
      "currentUser":localStorage.getItem('localloginId'),
      }
         this.data.verifierDocDetailsService(postdata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((customTags:any)=>{
          if (customTags.isSuccess) {
            this.isAllStatus = customTags?.data.docverificationdetailslists[0].isAllUserShare;
            this.sharedService.isShare.next(this.isAllStatus);
            let sum=(Number(customTags?.data.docshareuserlist.length + customTags?.data.docsharesgrouplist.length))
            if (Number(sum) > 0) {
              this.removeToolTip = false
            } else if (this.isAllStatus) {
              this.docSharedStatus = "All Users";
            } else {
              this.docSharedStatus = "No Users";
            }
          }
         })
  }

  crossTallyDMS() {
    this.viewerTally = false;
  }

  async ngOnInit(): Promise<void> {
    
  /**
   * For checking if browser is internet explorer
   */
  //@ts-ignore
  this.isIE = !!document.documentMode;

  /**
   * For checking if browser is firefox
   */

  //const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;

  
    const userAgent = navigator.userAgent.toLowerCase();
    this.isFirefox = userAgent.includes('firefox');
    if(this.isFirefox) {
      event.preventDefault();
    }
  
    this.sharedService.transferData.subscribe((res) => {
      if(res=='refreshDcoinsData')
      {
        this.getGuestList();
      }

      // if(res=='reloadData')
      // {
      //   this.ngOnInit()
      // }
      
    });

    if (this.docketsAccess == "true") {
      this.isDocketsPlan = true;
    } else {
      this.isDocketsPlan = false;
    }
    if (this.docketsInPlan == "True") {
      this.isDocketReallyInPlan = true;
    } else {
      this.isDocketReallyInPlan = false;
    }
    this.getGuestList();
    await this.rightsFunction();
    this.onGetTemplateDetails();

    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
      }

  });
  /*
  * for bulk tag by Shubham and Abhishek
  */
 this.sharedService.verificationDoc.subscribe(res => {
   this.tagVerify();
  })

  this.sharedService.templateEditorEmitter.subscribe(res=>{
    this.tagTempList[0];
  })


  /*
  * for crossing the details and activity modal by Abhishek
  */
  this.subscriptionOwnedByDetail = this.sharedService.closeDetailBar.pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((data) => {
    this.crossdata();
    this.showData1();
  });
  this.connectiongenrate();
  this.subscriptionOwnedBy = this.sharedServiceService.verificationDoc.subscribe((data) => {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     this.router.onSameUrlNavigation = 'reload';
     this.router.navigate(['./'],{ relativeTo: this.activatedRoute });
  });
    this.gettingLanguage();
    this.href = this.router.url;
    if (this.GuestFlag === 'True') {
      this.guestPlanRight = true;
    }
    else {
      this.guestPlanRight = false;
    }
    let template = { tagTempLabel: "", inputType: "text", tagTempValue: "", id: undefined }
    this.editTemplateInput.push(template);
    await this.verifierDocDetails(this.docId);
    await this.getDocketDetails(this.docId);
    this.spinner.load();
    this.showSpinner = true;
    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu")
      )
      .pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((option: any) => {
        this.groupActions(option.item["id"]);
      });
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe(themeName => this.currentTheme = themeName);
    if (this.href.includes('verification')) {
      this.isverification = true;
      this.role = "PendingView";
      this.cd.detectChanges();
      this.WorkSpaceName = localStorage.getItem('WorkSpaceName')
      this.WsdisplayName = localStorage.getItem('WsdisplayName') ? localStorage.getItem('WsdisplayName') : '';

      if(this.renamedFileName != ''){
        this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
      }
      else{
        this.titleService.setTitle(this.originalFileNameDocs + this.middot + this.wsName + this.middot + 'dox2U')
      }

    } else if (this.href.includes('view')) {
      this.isView = true;
      this.role = "View";
      this.cd.detectChanges();
      this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
    } else if (this.href.includes('my-trash')) {
      this.myTrash = true;
      this.isView = false;
      this.role = "TrashView";
      this.cd.detectChanges();
      this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
    } else if (this.href.includes('edit')) {
      this.isEdit = true;
      this.role = "Edit";
      this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
      this.cd.detectChanges();
    }
    this.getSharedWithData();
    this.wsSubscriptionDetails();
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.getallbudgescount();

    this.changeText = false;
    this.imageUrl = localStorage.getItem('imageUrl') ? localStorage.getItem('imageUrl') : '';
    this.sharedServiceService.userProfileEmitter.pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((data) => {
      this.GetTimeZone(this.UserSetingModal);
      this.userSettingsRef = this.dialogService.open(this.UserSetingModal, { closeOnBackdropClick: false });

    });
    this.sharedServiceService.userSettingEmitter.pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((data) => {
      this.dialogRef = this.dialogService.open(this.Password, { closeOnBackdropClick: false });

    });
    if (this.imageUrl == 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D') {
      this.showIconCrad = false;
      this.cd.detectChanges();
    }

    if (this.imageUrl == "" || this.imageUrl == null || this.imageUrl == undefined) {
      this.imageUrlStatic = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
    }

    this.GetProfileDetails(null);

    if((this.isView) && (this.editrights  || this.ownerEmailID === this.createdBy)){
      this.isEditablevalue = true;
    }else if (this.isverification){
      this.isEditablevalue = true;
    }

    if ((this.isView) && (this.isDelete|| this.ownerEmailID === this.createdBy)){
        this.viewDelete= true;
      }
    else if (this.isverification){
      this.viewDelete= true;
    }
    let tallyCheck = this.tagLists.some((obj) => obj.docSource.toLowerCase().includes('tally'));
    if(tallyCheck && this.tallyFlag?.toLowerCase().includes('true')){
          this.isDocSourceFlagTally= true;
        }
        if (localStorage.getItem("isTallyEnable") === "true") {
          this.isFromTally = true;
          if (this.verificationDate == null) {
            this.isPrintDownloadGuestEnableTally = false;
          }
        }
        if (localStorage.getItem("isTallyEnable") === "true" && tallyCheck) {
          this.viewerTally = true
          this.viewertag = false
        } else {
          this.viewertag = true
          this.viewerTally = false
        }
        if(localStorage.getItem("isTallyEnable") != undefined) {
          localStorage.removeItem("isTallyEnable");
        }
      if (this.docState === 'doc_21') {
        this.docUnderProcessing = true;
      } else if (this.docState === 'doc_22') {
        this.docIsLockedOrCorrupt = true;
      } else if (this.docState === 'doc_23') {
        this.docFormatNotSupported = true;
      } else if (this.docState === 'doc_15' || this.docState === '') {
        this.docParkingQueue = true;
      }
      if(this.docUnderProcessing || this.docFormatNotSupported || this.docIsLockedOrCorrupt || this.docParkingQueue){
        this.docIsDisplayed = false;
      }
    this.sharedService.IsPrintModalOpen.subscribe((res) => {
      this.printIconDisabled = false;
    })
  }

  getGuestList () {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docGuid": this.docGuid
    }
    this.data.getListofSharedUsersOfDoc(postData).subscribe((response:any)=>{
      if(response.isSuccess){
        if(response.data && response.data.length>0){
          this.isDocGuestShared = true;
        }}
        this.cd.detectChanges();
    })
    
  }
  ngAfterViewInit() {
    const s = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
    s ? s.querySelectorAll<HTMLElement>('.productfruits--checklist-launcher')[0].style.display = 'none' : '';
    const p = document.querySelectorAll('.productfruits--container')[0]?.shadowRoot;
    p ? p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'none' : '';
  }

  dropDownOptionDisable = false;
  gettingLanguage() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {

    }
    let notemp =
    {
      "lId": "",
      "languageName": "Autodetect",

    }
    this.langaugedata.randomDocLanguageService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

        this.rightLanguageList = response.data;
        this.languageauto = Object.values(this.rightLanguageList).slice();
        this.langList = Object.values(this.rightLanguageList).slice();
        /*
           *this is use to hide the option of autodetect in the dropdown
       */
        this.languageauto.unshift(notemp)
        for (let i = 0; i <= this.languageauto.length - 1; i++) {
          if (this.languageauto[i].languageName == 'Autodetect') {
            this.languageauto[i].disabled = true;
          }
          else {
            this.languageauto[i].disabled = false;
          }
        }
      }
    })
  }

  /*
  * share modal showing according to rights functions
  */

  viewShare(){
    if(this.isverification){
      this.shareUsersEvent();
    }
    else if((this.isView) && ((this.editrights) || (this.ownerEmailID === this.createdBy))){
      this.shareUsersEvent();
    }
    else{
      this.shareAccessModal();
    }
  }

  /*Proceed Anyways Btn Click*/
  ProceedAnywaysClick() {
   
   const myElement1 = document.getElementById("mainAppBox");
   myElement1.style.display = "block";

   const myElement2 = document.getElementById("msgBox");
   myElement2.style.display = "none";
    
  }



  /*
  * rights checking function at the time of ngoninit
  */
  async rightsFunction(){
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      "emailId": this.createdBy,
      "wsLink": arr[2],
      "wsoid": JSON.parse(this.workSpaceId)
    }
  await this.data.getaccessrights(access).toPromise().then((response:any)=>{
      if(response.isSuccess){
        localStorage.setItem('downloadandprint', response.data.isDownloadPrintDocuments.toString())
        localStorage.setItem('editaccess', response.data.isEditDocuments.toString())
        localStorage.setItem('deleteright',response.data.isDeleteDocument.toString())
        localStorage.setItem('guestShareright',response.data.isGuestAccess.toString())
        this.chkIsDelete = response.data.isDeleteDocument.toString();
        this.chkguestShareright =localStorage.getItem('guestShareright');
        this.chkdownloadprint = localStorage.getItem('downloadandprint')
        this.chkeditrights = localStorage.getItem('editaccess')
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.guestShare = JSON.parse(this.chkguestShareright);
        this.isDelete=JSON.parse(this.chkIsDelete);
        this.editrights = JSON.parse(this.chkeditrights);
      }
    })
  }

 /*
 * auto same save new code
 */
showError = false;
  onInput(event: any) {
    this.inputNameFieldActive= true;
    let name = event.target.innerText
    this.docmodifiednameinputbox= name;
    if (name.trim().length<3 ) {
      this.showError = true;
      this.disableVerifyDueToNameLength = true;
    }
    else{
      this.showError= false;
      this.disableVerifyDueToNameLength = false;
    }
  }


  @HostListener("window:beforeunload", ["$event"])
  /**
   * Unload Notification
   */
  unloadNotification($event: any) {
    if (this.inputNameFieldActive) {
      $event.returnValue = true;
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      // preventing the additional enter hits
      event.preventDefault();
      // Call the function you want to execute on Enter key press
      this.autoNameSave();
    }
  }

  nameAdjust(){
    if(this.renamedFileName === ""){
      this.renamedFileName = this.docmodifiednameinputbox.trim();
    }
  }


 async autoNameSave(val?){
   this.inputNameFieldActive= false;
   this.docmodifiednameinputbox = this.docmodifiednameinputbox.trim();
  if((this.docmodifiednameinputbox.length >=3) && (this.docmodifiednameinputbox.length<=100) && (this.renamedFileName !== this.docmodifiednameinputbox)){
    await this.checkDuplicateDocNamethree();
    this.showAutoSave = true;
    this.savebtnchk(val);
    this.editverifytemdocname();
    await this.editDocument('saveDoc');
    this.isNotActive = true;
  }
 }

  displayNameGuidline: any;
  whatIDo: any;
  whereIam: any
  editproEmail: any
  userRole: any;
  userGroup: any;
  groupnames: any;
  WorkSpaceNamePlaceholder;
  fullNamePattern = "^[a-zA-Z0-9. \s-]*$";

  numberPattern = "^[()0-9 +-]*$";
  inputPattern = "^[()a-zA-Z0-9 .,&]*$";
  displayNameGuidlineStatic: any;
  userProfileForm = new FormGroup({
    iconImage: new FormControl(),
    fullName: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern(this.fullNamePattern)]),
    displayName: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.fullNamePattern)]),
    whatIDo: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.inputPattern)]),
    whereIAm: new FormControl('', [Validators.maxLength(100), Validators.pattern(this.inputPattern)]),
    timeZone: new FormControl(),
    contactNumber: new FormControl('', [Validators.maxLength(13), Validators.pattern(this.numberPattern)]),

  });
  GetProfileDetails(dialog) {
    var st = window.location.toString();
    var arr = st?.split("/");

    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "Wslink": arr[2],
      "EmailId": this.createdBy
    }

    this.datanew.getUserProfileDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.displayNameGuidline = response.data[0].displayNameGuidline;


        this.WorkSpaceNamePlaceholder = response.data[0].wsName;
        if (response.data[0].profileImagePath == "" || response.data[0].profileImagePath == null || response.data[0].profileImagePath == undefined) {

          this.ediimageUrl = "../../../assets/images/Default_Profile_Photo.svg"
          this.loginProfilImage = this.ediimageUrl;
          localStorage.setItem('ediimageUrl', this.ediimageUrl)
          this.cd.detectChanges();
        }
        else {
          // this.ediimageUrl = environment.baseUrl + "/" + response.data[0].profileImagePath;
          this.profileImagePath = response.data[0].profileImagePath;
         this.getUserImage();
          // this.loginProfilImage = this.ediimageUrl;
          // localStorage.setItem('ediimageUrl', this.ediimageUrl)
          

        }
        this.userProfileForm.patchValue({
          fullName: response.data[0].fullName,
          displayName: response.data[0].displayName,
          whatIDo: response.data[0].whatIdo,
          whereIAm: response.data[0].whereIam,
          timeZone: response.data[0].timeZone,
          contactNumber: response.data[0].contactNo,

        })



        this.displayNameGuidlineStatic = response.data[0].displayNameGuidline
        this.Full_name = response.data[0].fullName;
        this.display_name = response.data[0].displayName;
        this.whatIDo = response.data[0].whatIdo;
        this.whereIam = response.data[0].whereIam;
        this.editproEmail = response.data[0].emailID;
        this.userRole = response.data[0].roleName;
        this.userGroup = response.data.map(obj => obj.groupName);
        this.groupnames = this.userGroup.join(',  ')
        localStorage.setItem('WsdisplayName', response.data[0].displayName)
        this.sharedService.wsDisplayName.next(response.data[0].displayName);
      }
      if (dialog) {
        this.dialogService.open(dialog, { closeOnBackdropClick: false });

      }
    })
  }
   /**
   * method to get user image as blob
   */
   getUserImage() {
    let postData: any
    postData = {
      "wsoid": 346221,
      "fileName": this.profileImagePath,
      "folderName": "profileimage"
    }
    this.datanew.pdfViewerFileConversion(postData).subscribe((response: any) => {
      if(response.isSuccess) {
        console.log("userResponse", response);
        this.ediimageUrl = response;
        this.loginProfilImage = this.ediimageUrl;
        this.cd.detectChanges();
        // localStorage.setItem('ediimageUrl', this.ediimageUrl)
      }
    })
  }

  //function used for bind time zone start here
  selectTime: any;
  zoneTime: any;
  timeZoneID: any;
  GetTimeZone(dialog) {

    this.dataprofile.getTimezone({}).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess == true) {

        this.zoneTime = response.data;
        for (let user of this.zoneTime) {
          user.timeZone;
          user['custom'] = user.timeZone;
        }
        this.GetProfileDetails(dialog);

      }
    }
    )
  }

  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick:false,
      closeOnEsc: false
    });

  }
  groupActions(id) {
    if (id == "text") {
      this.addtext();
    } else if (id == "date") {
      this.addDate();
    }
  }
  @ViewChild(UpgradeModalComponent) upgradeChild: UpgradeModalComponent;

  openGuestModal() {
    let guestShareEventData= []
    guestShareEventData.push(this.verifyData)
    if (!this.guestPlanRight) {
      this.upgradeChild.openUpgrade('use Guest Access', 'EM-051');
    } else {
      if (this.guestAccess === 'true') {
        if(this.isFromTally){
        this.guestChild.open(guestShareEventData,'dashboard');
          }
          else{
        this.guestChild.open(guestShareEventData);

          }
      }
      if (this.guestAccess !== 'true') {
        this.guestChild.openNonEdit(this.verifyData);
      }
    }

  }
  onTagTemplateId(event) {
    this.isSubmitted = false;
    this.tagTemplateId = event.tdId;

    let data = { "title": "", "description": "" };


    data['title'] = event.tempName;
    data['description'] = event.tempDescription;

    this.TemplateEditor.patchValue(data);
    this.onGetTemplateDetails();

    this.docsVerification.markAsDirty()
  }
  selectedchange;
  changeintemplateselection(event) {
    this.selectedTemplate = event;

  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
  reloadScreen() {
    window.location.reload();
  }
  logout() {
    let postData: any
    postData = {
      "EmailId": this.createdBy,
      "Wsoid": Number(this.workSpaceId)
    }
    this.signUpService.signOutService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.statusCode == 'C200') {
          let logoinIdCookie = 'localloginId' + localStorage.getItem('localwsId')
          let wsIdCookie = 'localwsId' + localStorage.getItem('localwsId')
          this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
          localStorage.clear();
          sessionStorage.clear();

          this.cookieService.delete('selectedDays')
          this.cookieService.delete('selectedHours')


          this.cookieService.delete('cabinetfirsttag')
          this.cookieService.delete('duplicatcabinetlabel')
          this.cookieService.delete('duplicatcabinetavl')


          this.cookieService.delete('valuetag')

          this.router.navigateByUrl('/onboarding/sign-in');
          this.notifylogout();
        }
      }
    })
  }

  notifylogout() {
    let notify = {
      "wsoid": Number(this.workSpaceId),
      "key": "",
      "userEmailID": this.createdBy
    }
    this.signUpService.notifylogout(notify).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.statusCode == 'C200') {


        }
      }
    })
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  templateInput = [];
  editTemplateInput = [];


  openDocDetails() {
    this.isDocDetail = !this.isDocDetail;
  }
  duplicateview1 = "";
  showduplicatepopover = false;
  openDuplicateCard() {
    this.isDocDuplicateName = !this.isDocDuplicateName;
    this.showduplicatepopover = true;
    this.duplicateview1 = document.getElementById("duplicatviewoutside").id
  }
  showToastr(msg) {
    this.toastrService.primary(msg, '',);
  }
  onGetTemplateDetails() {
    if (this.tagTemplateId) {
      this.templateInput = [];
      var st = window.location.toString();
      var arr = st?.split("/");
      let postData: any
      postData = {
        "Wsoid": Number(this.workSpaceId),
        "WsLink": arr[2],
        "TempId": this.tagTemplateId
      }
      this.pendingRequest = this.data.templateDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

        if (response.isSuccess) {
          let id = 0;
          response.data.forEach(e => {
            let template = {
              'tagTempLabel': ''
            }

            if (e.tagControlId == 1) {
              template['inputType'] = 'text'
            }
            else if (e.tagControlId == 2) {
              template['inputType'] = 'date';
            }
            else if (e.tagControlId == 3) {
              template['inputType'] = 'dropdown';
            }
            template.tagTempLabel = e.tagLabel;
            if (typeof e.tagValue == undefined || e.tagValue == null || e.tagValue.trim() == "") {
              template['tagTempValue'] = '';
            }
            else if (e.tagControlId == 3) {
              let tagValue = e.tagValue?.split('||');
              tagValue.unshift('Select');
              template['tagTempValue'] = tagValue;
              template['selectedValue'] = null;
            }
            else {
              template['tagTempValue'] = e.tagValue;
            }
            template['id'] = id;
            template['required'] = e.isRequired;
            this.templateInput.push(template);
            id++;

          });
          this.templateInput.forEach((e) => {
            if (e.required) {

              return;
            }
          })


          this.editTemplateInput = this.templateInput.slice()
          for (let i = 0; i <= this.templateInput.length - 1; i++) {
            this.templateInput[i].checktagdrop = false;
            if (this.templateInput[i].inputType == 'date') {
              if (this.templateInput[i].tagTempValue === "Invalid date NaN,NaN") {
                this.templateInput[i].tagTempValue = ""
              }
              if (this.templateInput[i].tagTempValue && this.templateInput[i].tagTempValue !== "Invalid date NaN,NaN") {
                this.templateInput[i].tagTempValue = new Date(this.templateInput[i].tagTempValue);
              }

            }
            if (this.templateInput[i].inputType == 'text') {
              this.templateInput[i].errormsg = "";
              this.templatechange(this.templateInput[i]);
            }
          }

        }

      }, error => {
        this.errormsg();

      })
    }

  }
  tagvaldata;
  arrayUniqueByKey;
  templatechange(data) {

    let templatedata = {
      wsoid: JSON.parse(this.workSpaceId),
      tagLabel: data.tagTempLabel
    }
    this.pendingRequest = this.data.templatechange(templatedata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        if (response.data.length > 0) {

          let temdata = {
            createdDtm: null,
            tagValue: data.tagTempValue
          }
          response.data.push(temdata);


          const key = 'tagValue';

          this.arrayUniqueByKey = [...new Map(response.data.map(item =>
            [item[key], item])).values()];

          for (let i = 0; i <= this.templateInput.length - 1; i++) {
            if (this.templateInput[i].id == data.id) {
              this.templateInput[i].tagvaldata = temdata.tagValue;
              this.templateInput[i].existingtagvalue = this.arrayUniqueByKey;
              this.templateInput[i].checktagdrop = true;
              this.templateInput[i]['errormsg'] = "";
            }
          }
        }
      }
    })
  }

  templatechangeedit(data) {

    let templatedata = {
      wsoid: JSON.parse(this.workSpaceId),
      tagLabel: data.tagLabel
    }

    this.pendingRequest = this.data.templatechange(templatedata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        if (response.data.length > 0) {

          let temdata = {
            createdDtm: null,
            tagValue: data.tagValue
          }
          response.data.push(temdata);
          const key = 'tagValue';

          this.arrayUniqueByKey = [...new Map(response.data.map(item =>
            [item[key], item])).values()];

          for (let i = 0; i <= this.docTagList.length - 1; i++) {
            if (this.docTagList[i].id == data.id) {
              this.docTagList[i].tagvaldata = temdata.tagValue;
              this.docTagList[i].existingtagvalue = this.arrayUniqueByKey;
              this.docTagList[i].checktagdrop = true;
            }
          }
        }
      }
    })
  }


  validayeDownloadChild() {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      "emailId": this.createdBy,
      "wsLink": arr[2],
      "wsoid": JSON.parse(this.workSpaceId)
    }
    this.data.getaccessrights(access).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        localStorage.setItem('downloadandprint', response.data.isDownloadPrintDocuments.toString())
        localStorage.setItem('editaccess', response.data.isEditDocuments.toString())
        this.chkdownloadprint = localStorage.getItem('downloadandprint')
        this.chkeditrights = localStorage.getItem('editaccess')
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.editrights = JSON.parse(this.chkeditrights)

        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(this.docId),
          "role": this.role,
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": localStorage.getItem('loginguid'),
          "DocGuid": this.docGuid,

        }
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === 'Not Access') {
              this.openModal(this.saveRightsChange, false);
            } else {
              if (!this.downloadprint && this.ownerEmailID !== this.createdBy) {
                this.openModal(this.wsOutSync, false);
              } else {
                this.downloadChild.validateDownloadDailyLimit();
              }
            }
          }
        })
      }
    })
  }
  public printClick(): void {
    this.printIconDisabled = true;
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      "emailId": this.createdBy,
      "wsLink": arr[2],
      "wsoid": JSON.parse(this.workSpaceId)
    }
    this.data.getaccessrights(access).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

        localStorage.setItem('downloadandprint', response.data.isDownloadPrintDocuments.toString())
        localStorage.setItem('editaccess', response.data.isEditDocuments.toString())

        this.chkdownloadprint = localStorage.getItem('downloadandprint')
        this.chkeditrights = localStorage.getItem('editaccess')
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.editrights = JSON.parse(this.chkeditrights);
        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(this.docId),
          "role": this.role,
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": localStorage.getItem('loginguid'),
          "DocGuid": this.docGuid,
        }
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

          if (response.isSuccess) {
            if (response.data[0].docShareStatus === 'Not Access' && !this.allWs) {
              this.openModal(this.saveRightsChange, false);
            } else {
              if (!this.downloadprint && this.ownerEmailID !== this.createdBy) {
                this.openModal(this.wsOutSync, false);
              } else {
                this.isOpen = true;
                setTimeout(() => {
                  this.isOpen = false;
                }, 10000)
                this.selectedDoc = [];
                this.selectDocumentName = this.docDetails[0].docRename;
                let pribtObj = { fileName: this.selectDocumentName, loader: true, docId: this.docDetails[0].docId }
                this.selectedDoc.push(this.docDetails[0]);
                this.printService.setSelectedDocs(this.selectedDoc);
                this.printChild.checking(pribtObj);
                this.printService.validateAndprintDoc();

                setTimeout(() => {
                this.toastrService.primary('documents prepared for printing')
                }
                , 3000)

              }
            }
          }


        })

      }
    })

  }

  filtersaveedit = '';
  savebtnchk(event) {
    this.filtersaveedit = event;
  }
  fieldblankedit;
  customdatatag = [];
  flagAutoChange = false
  getaccessrights(value) {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      "emailId": this.createdBy,
      "wsLink": arr[2],
      "wsoid": JSON.parse(this.workSpaceId)

    }
    this.fieldblankedit = '';
    this.data.getaccessrights(access).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

        localStorage.setItem('downloadandprint', response.data.isDownloadPrintDocuments.toString())
        localStorage.setItem('editaccess', response.data.isEditDocuments.toString())

        this.chkdownloadprint = localStorage.getItem('downloadandprint')
        this.chkeditrights = localStorage.getItem('editaccess')
        this.checkGuestAccess = localStorage.getItem('isGuestAccess')
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.editrights = JSON.parse(this.chkeditrights);
        this.isGuestAccess = JSON.parse(this.checkGuestAccess);
        this.cd.detectChanges();
        if (this.isEdit) {
          for (let i = 0; i <= this.docTagList.length - 1; i++) {
            this.docTagList[i].checktagdrop = false;
            this.docTagList[i].id = i + 1;
            if (this.docTagList[i].tagControlId == '2') {
              this.docTagList[i].tagValue = new Date(this.docTagList[i].tagValue)
            }
            if (this.docTagList[i].tagControlId == '3') {

              let tageditvalue = this.docTagList[i].optionValue.replace("Select||", "")
              this.docTagList[i].optionValue = tageditvalue;
            }

            if (this.docTagList[i].tagControlId == 1) {
              this.templatechangeedit(this.docTagList[i]);
            }
          }

          let filteredArrayedit = this.docTagList.filter((e) => {
            if (e.tagValue == '') {
              setTimeout(() => {
                this.fieldblankedit = 'The fields cannot be blank';
              }, 2000);


            }
          })

          this.customdatatag = [];
          for (let i = 0; i <= this.docsVerification.get('customform')['controls'].length - 1; i++) {
            this.flagAutoChange = true
            const getLabel = this.docsVerification.get('customform')['controls'][i]['controls']['label'];
            const getValue = this.docsVerification.get('customform')['controls'][i]['controls']['value'];
            if (getLabel.value === "" || getLabel.value === undefined) {
              this.docsVerification.get('customform')['controls'][i]['controls']['label']['label_'] = "0"
            } else {
              this.docsVerification.get('customform')['controls'][i]['controls']['label']['label_'] = "1"

            }

            if (getValue.value === "" || getValue.value === undefined) {
              this.docsVerification.get('customform')['controls'][i]['controls']['value']['value_'] = "0"
            } else {
              this.docsVerification.get('customform')['controls'][i]['controls']['value']['value_'] = "1"

            }
            this.customdatatag.push(this.docsVerification.get('customform').value[i])
          }
          setTimeout(() => {
            this.flagAutoChange = false
            return false
          }, 500)
          if (this.docsVerification.get('customform')['controls']) {

            let verifyval = this.customdatatag.filter(item => {
              if (item.value === '' || item.label === '' || item.value === undefined || item.label === undefined) {
                return item
              }
            })

            if (verifyval.length > 0) {
              setTimeout(() => {
                this.fieldblankedit = 'The fields cannot be blank';
              }, 2000);

            }
          }


          if (!this.editrights && value === 'ngOnInIt' && this.ownerEmailID !== this.createdBy) {
            // this.router.navigate(['/error/error-page']);
          } else if (!this.editrights && value !== 'ngOnInIt' && this.ownerEmailID !== this.createdBy) {
            this.editrights = false;
            this.openModal(this.saveRightsChange, false);
          }
        }
      }

    })

  }

  labelcustomcheck(data, index, valuechange, textname) {

    if (valuechange != undefined && this.flagAutoChange === false) {
      const getLabel = this.docsVerification.get('customform')['controls'][index]['controls']['label'];
      const getValue = this.docsVerification.get('customform')['controls'][index]['controls']['value'];
      const textchange = textname === 'label' ? this.docsVerification.get('customform')['controls'][index]['controls']['label']['label_'] = "1" : ''
      const textchangevalue = textname === 'value' ? this.docsVerification.get('customform')['controls'][index]['controls']['value']['value_'] = "1" : '';
      this.isDocEdited = true;
      this.disableSave = false;
    }

  }

  ownernameshow;
  ownernameactual;
  itemsowner;
  userownerauto = [];
  UploadRightsUserList() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }
    this.data.searchAllUsersService(postData).subscribe((response : any)=>{
      if (response.isSuccess) {
        let actualUsers = [];
        response.data.forEach(user=>{
        if(!user.roleName.includes("Tally") && user.emailId !== this.createdBy ){
        actualUsers.push(user.roleName);
      }
     })
        this.allUserLength = actualUsers.length;
    }
    })
    
    this.ownernameshow = '';
    this.ownernameactual = '';
    this.itemsowner = [];
    this.userownerauto = [];
    this.pendingRequest = this.data.UploadRightsUserListService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess == true) {

        this.rightUserList = response.data;
        // this.allUserLength = response.data.length;
        this.uploadRightUserList = response.data;

        for (let i = 0; i <= this.rightUserList.length - 1; i++) {
          this.rightUserList[i].diplaynamewithoutyou = this.rightUserList[i].displayName;

        }
        this.itemsowner = [];
        for (let i = 0; i <= this.rightUserList.length - 1; i++) {
          let itemvalue = {
            category: this.rightUserList[i].category,

            displayName: this.rightUserList[i].displayName,
            emailId: this.rightUserList[i].emailId,
            fullName: this.rightUserList[i].fullName,
            wsMid: this.rightUserList[i].wsMid,
            wsoid: this.rightUserList[i].wsoid,
            diplaynamewithoutyou: this.rightUserList[i].diplaynamewithoutyou
          }
          this.itemsowner.push(itemvalue);

        }
        this.userownerauto = this.itemsowner.slice();

        for (let i = 0; i <= this.userownerauto.length - 1; i++) {
          if (this.userownerauto[i].emailId == this.createdBy) {
            this.userownerauto[i].displayName = this.userownerauto[i].displayName + " (you)"
          }
        }

        for (let user of this.rightUserList) {

          user.displayName;
          user['ownerDropdown'] = user.displayName;
          if (this.ownerEmailID === user.emailId) {

            this.selectedDocumentOwner = user;

            if (this.selectedDocumentOwner.emailId == this.createdBy) {
              this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName + " (you)"
            }
            this.ownernameshow = user.diplaynamewithoutyou;
            this.ownernameactual = user.diplaynamewithoutyou;
            this.onDocumentOwner(this.selectedDocumentOwner, false);
          }
        }
        this.shareAccess.shareTemplateList();

      }

    }, error => {
      this.errormsg();
    })
  }

  documentOwnerName: any;
  documentOwnerEmail: any
  backgroundforowner = false;
  onDocumentOwner(event, formModified) {
    this.documentOwnerName = event.fullName;
    this.documentOwnerEmail = event.emailId;
    this.ownernameshow = event.diplaynamewithoutyou;
    this.selectedDocumentOwner = event;
    if (this.selectedDocumentOwner.emailId == this.createdBy) {
      if (this.selectedDocumentOwner.displayName.includes('(you)')) {
        let nodata = 'not apply'
      }
      else {
        this.selectedDocumentOwner.displayName = this.selectedDocumentOwner.displayName + " (you)"

      }
    }


    if (this.ownernameactual == this.ownernameshow) {
      this.backgroundforowner = false;
    }
    else {
      this.backgroundforowner = true;
    }

    this.showowner = false;
    if (formModified) {

      this.docsVerification.markAsDirty()
    }
  }
  selectedTemplate: any;
  templateArray = [];
  defaultTemplate;
  tagTemplateList(isRefresh, value?) {

    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {

      "wsoid": Number(this.workSpaceId),
      "guid": this.createdBy
    }
    this.pendingRequest = this.data.tagTemplateService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess == true) {
        this.sharedService.Template.next(response.data)
        let notemp =
        {
          "tdId": "",
          "tempName": "No Template",
          "tempDescription": ""
        }
        this.tagTemplate = response.data;
        const filterOutTemp = this.tagTemplate.filter(al => al['tempName'] === 'No Template')
        const filterOutNoTemp = this.tagTemplate.filter(al => al['tempName'] !== 'No Template')
        filterOutNoTemp.sort((a, b) => a.tempName.localeCompare(b.tempName))
        this.tagTemplate = [...filterOutNoTemp, ...filterOutTemp]

        this.templateArray = response.data;
        this.defaultTemplate = response.data.find(obj => obj.isdefault);
        this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];

        if (value) {
          for (let i = 0; i <= response.data.length - 1; i++) {
            if (value.tdId == response.data[i].tdId) {
              this.defaultTemplate = response.data[i];
              if (this.selectedTemplate.tdId !== value.tdId) {
                this.selectedTemplate = response.data[i];
              }
            }
          }
        }
        else {
          this.defaultTemplate = response.data.find(obj => obj.isdefault);

          this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];

        }




        this.tagTempList = this.templateArray.slice();
        if (!isRefresh) {
          this.tagTempList.sort((a, b) => a.tempName.localeCompare(b.tempName))

          let inx = 0;
          let inxGeneral;
          let inxNoTemplate;
          for (let user of this.tagTemplate) {
            user.tempName;
            user['tagTemplateDropdown'] = user.tempName;
            if (user.tdId == this.docTemplateId) {
              if (!isRefresh && !this.defaultTemplate) {
                this.selectedTemplate = user;

                this.onTagTemplateId(this.selectedTemplate);
              }
            }
            if (user.tempName == 'General') {
              inxGeneral = inx;
            }
            if (user.tempName == 'No Template') {
              inxNoTemplate = inx;
            }
            inx = inx + 1;
          }
          if (this.defaultTemplate) {
            this.onTagTemplateId(this.selectedTemplate);
          }

          else {

            this.onTagTemplateId(this.selectedTemplate);
          }
        }
        else {
          this.selectedTemplate = value;

          this.onTagTemplateId(this.selectedTemplate);
        }
      }


    }, error => {
      this.errormsg();
    })
  }
  public verifyDeleteTemplate(template: TemplateRef<any>) {
    this.showOverlaySpinner = false;

    this.showText = false;
    var st = window.location.toString();
    var arr = st?.split("/");

    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "TempId": Number(this.tagTemplate.tdId),
      "ModifiedBy": this.createdBy
    }

    setTimeout(() => {

      this.showText = true;
    }, 1000);
    this.pendingRequest = this.data.deleteTemplateService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        let selected;
        let hasDeletedAll: boolean = false;
        const length = this.tagTempList.length;

        this.tagTempList.forEach(function (item, i) {
          if (item.tdId === postData.TempId) {
            if (i > 0) {
              selected = i - 1;
            } else if (i == 0 && length === 1) {
              hasDeletedAll = true;
            } else {
              selected = i + 1;
            }
          }
        });
        this.tagTemplate.emit(false);
        if (hasDeletedAll) {

          this.TemplateEditor.markAsPristine();
        }
      }

    }, error => {
      setTimeout(() => {

      }, 1000)
    })
  }
  showTempEditor: boolean = false;
  isSubmitPressed = false;

  toggleTooltip() {
    this.isTooltipClicked = true;
  }

  hideTooltip() {
    if (this.isTooltipClicked && this.hoverOnShare) {
      this.hoverOnShare = false;
      this.showTooltipAfterClicked = false;
    }
  }

  showTooltip() {
    if (this.isTooltipClicked && !this.hoverOnShare) {
      this.hoverOnShare = true;
      this.showTooltipAfterClicked = true;
    }
  }

  shareModalinProgress = false;
  disableShare=()=>{
    this.shareModalinProgress=true;
    setTimeout(() => {
      this.shareModalinProgress=false;
    }, 700);
  }

  shareUsersEvent() {
    this.removeToolTip=false
    let guid =window.location.href?.split('=')
    let tempdata = {
      docGuid:guid[2],
      owner: this.ownerEmailID,
      verifier: this.verifierEmailID,
      docId: this.docIdData,
      bydefault: ''
    }
    this.sharedService.isShare.next(this.isAllStatus);

    if(this.href?.includes('/verification/verify-document')){
      this.onVerifyDocDetails(tempdata)
      setTimeout(()=>{
        this.sharedService.isShare.next(this.isAllStatus);
        this.shareuse.shareUsersMqueue(tempdata)
      },500)

    }else{
      this.onVerifyDocDetails(tempdata)
      setTimeout(()=>{
        this.sharedService.isShare.next(this.isAllStatus);
         this.shareuse.shareUsers(tempdata);
      },500)

    }
  }
  verifyTempShare = true;
  shareUsersEventbydefault() {
    let guid =window.location.href?.split('=')
    let tempdata = {
      docGuid:guid[2],
      owner: this.ownerEmailID,
      verifier: this.verifierEmailID,
      docId: this.docIdData,
      bydefault: 'bydefault'
    }
    setTimeout(() => {
      this.sharedService.isShare.next(this.isAllStatus);
      if(this.href?.includes('/verification/verify-document')){
        this.onVerifyDocDetails(tempdata)
        setTimeout(()=>{
          this.sharedService.isShare.next(this.isAllStatus);
          this.shareuse.shareUsersMqueue(tempdata)
        },500)
     }else{

      this.onVerifyDocDetails(tempdata)
      setTimeout(()=>{
        this.sharedService.isShare.next(this.isAllStatus);
        this.shareuse.shareUsers(tempdata);
      },500)



     }
      this.verifyTempShare = false;
    }, 5000);
  }
  shareAccessModal() {
    this.shareAccess.openShareModal();
  }
  showowner;
  displayownerid = '';
  ownercheck(event) {
    this.showowner = !this.showowner;
    this.displayownerid = event;
    this.cd.detectChanges();
  }
  ownercard;
  ownercheckcard(data) {

    this.ownercard = data;
  }
  detaiopenclk;
  detailsclk(data) {
    this.detaiopenclk = data;
  }

  //Notes text area words count
  WordsCount: boolean = false;
  clickcount: any;
  docmodifiednameinputbox;
  WordCount(e: any) {
    this.isDocEdited = true;
    this.disableSave = false;
    this.WordsCount = true;
    this.clickcount = 500 - e.target.value.length;
  }
  getDate(date) {
    let tagDate;
    tagDate = new Date(date.tagValue);
    return tagDate;
  }
  showdetectlang: boolean = false
  docNote: string;
  selectedlanguage: any;
  verifydocid;
  manuallanguage;
  parserautolanguage;
  parserstatus;
  language;
  fileExtensionview;
  parserautodetect
  mergelanguage
  extensionformat;
  labellanguge;
  viewextractdata;
  parserautodetecterror;
  uploadpath;
  viewautodetect;
  doccardlanguage;
  docSource = '';
  isDocSourceFlagTally= false;
  isDocketSourceFlag= false;
  originalnamefile;
  searchabledocverify = true;
  withoutsearchdoc = true;
  withsearchdoc = false;
  withoutsearchdocboth = '';
  withoutsearchdoceditable = true;
  searchabledoccheck = true;
  verifyerrormorechar = false;
  verifyerrormorecharbtn = false;
  verifyerrormorecharduplicate = false;
  notShowValue = '';
  docIdData = '';
  tallyTags = [];
  singleValueTallyTags = [];
  labelValueTallyTags =[];
  async verifierDocDetails(id) {
    this.verifydocid = id;
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      // "WsLink": arr[2],
      "DocID": Number(id),
      "DocGuid": this.docGuid,
      "currentUser": this.createdBy
    }
    this.docmodifiednameinputbox = '';
    this.language = '';
    this.extensionformat = '';
    this.labellanguge = '';
    this.viewextractdata = '';
    this.mergelanguage = '';
    this.parserautodetecterror = '';
    this.uploadpath = '';
    this.viewautodetect = '';
    this.doccardlanguage = '';
    this.originalnamefile = '';
    this.downloadDoc = [];
    this.searchabledocverify = true;
    this.withoutsearchdoc = true;
    this.withsearchdoc = false;
    this.withoutsearchdoceditable = true;
    this.searchabledoccheck = true;
    this.showSpinner = true;
    this.languageviewext = [];
    this.extractedstatus = '';
    this.notShowValue = '';
    this.tallyTags = [];
    this.singleValueTallyTags =[];
    this.labelValueTallyTags =[];
    this.vouchersTags = [];
    this.masterTags = [];
    this.pendingRequest = await this.data.verifierDocDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).toPromise().then((response: any) => {
      if (response.isSuccess) {
        this.showData = true;
        this.isAllStatus = response?.data.docverificationdetailslists[0].isAllUserShare
        this.sharedService.isShare.next(this.isAllStatus);
        this.alphaville = response.data.docverificationdetailslists[0].originalFileName;

        this.isCheckedAllUsers = response?.data.docverificationdetailslists[0].isAllUserShare;

        this.verifyData = response?.data.docverificationdetailslists[0];
        this.shareDocCount = response.data.docverificationdetailslists[0].shareDocCount;
        let sum=(Number(response?.data.docshareuserlist.length + response?.data.docsharesgrouplist.length))
        if (Number(sum) > 0) {
          this.removeToolTip=false
        } else if (this.isAllStatus) {
          this.docSharedStatus = "All Users";
        } else {
          this.docSharedStatus = "No Users";
        }
        this.iseditableDocument = response.data.docverificationdetailslists[0].iseditable;
        this.docNote = response.data.docverificationdetailslists[0].notes;
        this.docIdData = response.data.docverificationdetailslists[0].docId;
        this.clickcount = 500 - this?.docNote?.length || 500;
        this.ownerEmailID = response.data.docverificationdetailslists[0].docOwnerEmailId;
        this.verifierEmailID = response.data.docverificationdetailslists[0].verifiedByEmailId;
        this.verifierName = response.data.docverificationdetailslists[0].verifiedByDisplayName;
        this.docTemplateId = response.data.docverificationdetailslists[0].tempID;
        this.enforceRenamingDocs = response.data.docverificationdetailslists[0].enforceRenaming;
        this.extensionformat = response.data.docverificationdetailslists[0].extension.toLowerCase();
        this.uploadpath = response.data.docverificationdetailslists[0].uploadPath;
        this.ConfidenceValue = response.data.docverificationdetailslists[0].confidenceValue;
        this.tagLists = response.data.doctagsdetailslists;
        this.docSource = response.data.docverificationdetailslists[0].docsource;
        this.docState = response.data.docverificationdetailslists[0].parserstatuscode;
        this.verificationDate = response.data.docverificationdetailslists[0].docVerficationDtm;
        if (response.data.docverificationdetailslists[0].docRenameChip1 != null && response.data.docverificationdetailslists[0].docRenameChip1 != undefined) {
          if (response.data.docverificationdetailslists[0].docRenameChip1.indexOf('Date') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].updatedDateTime?.split(' ')[0].replaceAll('/', "-");
          }
          else if (response.data.docverificationdetailslists[0].docRenameChip1.indexOf('Document Type') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + 'General'
          }
          else if (response.data.docverificationdetailslists[0].docRenameChip1.indexOf('Original File Name') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].originalFileName.substring(0, response.data.docverificationdetailslists[0].originalFileName.lastIndexOf(".")).substring(0, 20);

          }
          else {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].docRenameChip1;
          }
        }
        if (response.data.docverificationdetailslists[0].docRenameChip2 != null && response.data.docverificationdetailslists[0].docRenameChip2 != undefined) {

          if (response.data.docverificationdetailslists[0].docRenameChip2.indexOf('Date') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].updatedDateTime?.split(' ')[0].replaceAll('/', "-");
          }
          else if (response.data.docverificationdetailslists[0].docRenameChip2.indexOf('Document Type') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + 'General'
          }
          else if (response.data.docverificationdetailslists[0].docRenameChip2.indexOf('Original File Name') >= 0) {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].originalFileName.substring(0, response.data.docverificationdetailslists[0].originalFileName.lastIndexOf(".")).substring(0, 20);
          }
          else {
            this.fileNameVerification = this.fileNameVerification + '_' + response.data.docverificationdetailslists[0].docRenameChip2;
          }
        }

        if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
          || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')

          || (this.extensionformat.toLowerCase() == 'odt')

        ) {
          this.notShowValue = 'Not_Show';
        }
        else {
          if (this.iseditableDocument === 1) {
            this.notShowValue = 'Not_Show';
          }
          else {
            this.notShowValue = '';
          }

        }
        if (this.parserflagdata == 'True') {
          this.defultlanguage = response.data.docverificationdetailslists[0].language;
          this.language = response.data.docverificationdetailslists[0].language;
          if (this.language != 'Autodetect') {
            /*
            *In the edittable document format always show language detected
            *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
            *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
            */
            if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
              || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
              || (this.extensionformat.toLowerCase() == 'odt')
            ) {
              this.parserautolanguage = response.data.docverificationdetailslists[0].parserlanguage;

              this.labellanguge = 'Language(s)';

              let getparserlanguage = response.data.docverificationdetailslists[0].parserlanguage?.split(',');
              if (getparserlanguage[0]) {
                this.mergelanguage = getparserlanguage[0];
                this.languageviewext.push(getparserlanguage[0])
              }
              if (getparserlanguage[0] && getparserlanguage[1]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];
                this.languageviewext.push(getparserlanguage[1])
              }
              if (getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];
                this.languageviewext.push(getparserlanguage[3])
              }
              this.viewextractdata = 'View Extracted Text';
              this.parserautodetect = this.mergelanguage;
              this.doccardlanguage = this.mergelanguage;
              this.manuallanguage = '';
              this.showdetectlang = true;
              this.showtetectlangs = true;
            }



            else {
              this.parserautolanguage = "";
              this.manuallanguage = response.data.docverificationdetailslists[0].language;
              this.labellanguge = 'Language';
              this.languageviewext.push(response.data.docverificationdetailslists[0].language)
              this.mergelanguage = response.data.docverificationdetailslists[0].language;
              this.viewextractdata = 'View Extracted Text / Change Language';
              this.parserautodetect = 'Processing';
              this.doccardlanguage = response.data.docverificationdetailslists[0].language;
              this.showtetectlangs = false;
            }

          }
          else {
            /*
               *parser status related changes
           */
            this.parserautolanguage = response.data.docverificationdetailslists[0].parserlanguage;
            this.manuallanguage = '';
            this.labellanguge = 'Language(s)';
            if (this.parserautolanguage) {

              let getparserlanguage = response.data.docverificationdetailslists[0].parserlanguage?.split(',');
              if (getparserlanguage[0]) {
                this.mergelanguage = getparserlanguage[0];
                this.languageviewext.push(getparserlanguage[0])
              }
              if (getparserlanguage[0] && getparserlanguage[1]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];
                this.languageviewext.push(getparserlanguage[1])
              }
              if (getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];
                this.languageviewext.push(getparserlanguage[3])
              }

              this.parserautodetect = this.mergelanguage
              this.manuallanguage = '';
              this.doccardlanguage = this.mergelanguage;
            }
            else {
              this.parserautodetect = 'Processing';
              this.manuallanguage = '';
              this.doccardlanguage = 'Processing';
            }



            /*
              *In the edittable document format always show language detected
              *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
              *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
              */
              if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
              || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
              || (this.extensionformat.toLowerCase() == 'odt')
            ) {

              this.viewextractdata = 'View Extracted Text';
            }
            else {

              this.viewextractdata = 'View Extracted Text / Change Language';
            }

          }
          this.check_parser_status(`abc|en|${response.data.docverificationdetailslists[0].parserstatuscode}|m12`)
          if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
            this.parserstatus = 'Success';
            this.parserautodetect = this.mergelanguage
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_07')
            || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_15') || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_14')) {
              this.parserstatus = 'Not Extracted'
              this.extractedstatus = 'Extraction In Progress';
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_00') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_03') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_18') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_24') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_25') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_27')) {
            this.parserstatus = 'Failure';

          }
          else if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_23') {
            this.parserstatus = 'Failure';
            this.parserautodetecterror = 'Can not parse';
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_04')|| (response.data.docverificationdetailslists[0].parserstatuscode =='doc_01')) {
            this.parserstatus = 'in process'
          }
          else if (response.data.docverificationdetailslists[0].parserstatuscode == '') {
            if (this.unparserDocData == '1') {
              this.parserstatus = 'In queue'
            }
            else {
              this.parserstatus = 'Not Extracted'
            }

            this.extractedstatus = 'Extraction Initiated';
          }

        }
        else {
          if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
            || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
            || (this.extensionformat.toLowerCase() == 'odt')
          ) {
            if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
              this.parserstatus = 'Success';
              this.parserautodetect = this.mergelanguage
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_07')
              || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_15') || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_14')) {
              this.parserstatus = 'Not Extracted'
              this.extractedstatus = 'Extraction In Progress';
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_00') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_03') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_18') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_24') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_25') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_27')) {
              this.parserstatus = 'Failure';
            }
            else if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_23') {
              this.parserstatus = 'Failure';
              this.parserautodetecterror = 'Can not parse';
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_04')|| (response.data.docverificationdetailslists[0].parserstatuscode =='doc_01')) {
              this.parserstatus = 'in process'
            }
            else if (response.data.docverificationdetailslists[0].parserstatuscode == '') {

              if (this.unparserDocData == '1') {
                this.parserstatus = 'In queue'
              }
              else {
                this.parserstatus = 'Not Extracted'
              }
              this.extractedstatus = 'Extraction Initiated';
            }


          }

        }

        this.spiltFileName = response.data.docverificationdetailslists[0].originalFileName.substring(0, response.data.docverificationdetailslists[0].originalFileName.lastIndexOf("."));
        this.docDetails = response.data.docverificationdetailslists;
        this.downloadDoc.push(response.data.docverificationdetailslists[0]);
        this.tagVerify();
        this.isAllUserShare = response.data.docverificationdetailslists[0].isAllUserShare;

        /*
        * select the default lanuage
         */
        if (this.rightLanguageList) {
          for (let user of this.rightLanguageList) {
            if (user.languageName == response.data.docverificationdetailslists[0].language) {
              this.selectedlanguage = user.languageName;
              this.selectedlanguagecheck = user.languageName;
              this.onSelectLanguage(this.selectedlanguage);
            } else {
              this.selectedlanguage = response.data.docverificationdetailslists[0].language
              this.selectedlanguagecheck = response.data.docverificationdetailslists[0].language;
              this.onSelectLanguage(this.selectedlanguage);
            }
          }
        }

        if (response.data.docverificationdetailslists[0].language == "Autodetect") {
          this.langs = response.data.docverificationdetailslists[0].parserlanguage?.split(',')

          this.selectedlanguage = response.data.docverificationdetailslists[0].parserlanguage
          this.selectedlanguage = 'Autodetect'
          this.onSelectLanguage(this.selectedlanguage)
          this.showautodetect = true
          this.showtetectlangs = true
          this.showenglish = false;
          this.showdetectlang = true;

          /*
             *this is for the view page in the autodected
          */
          if (this.langs.length > 1) {
            this.viewautodetect = true;
          }
          else {
            this.viewautodetect = false;
          }

        } if (response.data.docverificationdetailslists[0].parserlanguage == "english" ||
          response.data.docverificationdetailslists[0].parserlanguage !== "english" ||
          response.data.docverificationdetailslists[0].parserlanguage == " "
        ) {
          this.showautodetect = false;
          this.showenglish = true;
          this.showdetectlang = false

        }

        /*
              *this is for the view page in the autodected
           */
        if (this.langs.length > 1) {
          this.viewautodetect = true;
        }
        else {
          this.viewautodetect = false;
        }

        /*
            *this is for the view page in the manual language
       */
        if (response.data.docverificationdetailslists[0].language != "Autodetect") {

          this.viewautodetect = false;

        }
        /*
          *radio button selection value checking
       */
        this.sharedService.isAllUserSharevalue.next(this.isAllUserShare);
        this.getaccessrights('ngOnInIt');
        if (!this.allWs) {
          this.checkUserWiseRoleAccess();
        }
        this.docModifiedSystemName = response.data.docverificationdetailslists[0].originalFileName;
        if (this.docModifiedSystemName.includes(".")) {
          let splitDocName = this.docModifiedSystemName?.split(".");
          splitDocName = splitDocName.reverse();
          if (splitDocName[0] === response.data.docverificationdetailslists[0].extension) {
            splitDocName = splitDocName.slice(1);
            splitDocName = splitDocName.reverse();
            this.docModifiedSystemName = splitDocName.join(".");
          } else {
            splitDocName = splitDocName.reverse();
            this.docModifiedSystemName = splitDocName.join(".");
          }
        }

        if (response.data.docverificationdetailslists[0].docRename || response.data.docverificationdetailslists[0].docRename !== "") {
          this.docModifiedEditedName = response.data.docverificationdetailslists[0].docRename;
          if (this.docModifiedEditedName.includes(".")) {
            let splitDocName = this.docModifiedEditedName?.split(".");
            splitDocName = splitDocName.reverse();
            if (splitDocName[0] === response.data.docverificationdetailslists[0].extension) {
              splitDocName = splitDocName.slice(1);
              splitDocName = splitDocName.reverse();
              this.docModifiedEditedName = splitDocName.join(".");
            } else {
              splitDocName = splitDocName.reverse();
              this.docModifiedEditedName = splitDocName.join(".");
            }
          }
        } else {
          this.docModifiedEditedName = response.data.docverificationdetailslists[0].originalFileName;
          if (this.docModifiedEditedName.includes(".")) {
            let splitDocName = this.docModifiedEditedName?.split(".");
            splitDocName = splitDocName.reverse().slice(1);
            if (splitDocName[0] === response.data.docverificationdetailslists[0].extension) {
              splitDocName = splitDocName.slice(1);
              splitDocName = splitDocName.reverse();
              this.docModifiedEditedName = splitDocName.join(".");
            } else {
              splitDocName = splitDocName.reverse();
              this.docModifiedEditedName = splitDocName.join(".");
            }
          }
        }
        this.docmodifiednameinputbox = this.docModifiedEditedName;
        this.docmodifiednameinputboxduplicate = this.docModifiedEditedName;
        this.docsVerification.value.docsName = this.docModifiedEditedName;

        if (this.docModifiedEditedName.length > 100) {
          this.editverifydocname = true;
          this.verifyerrormorechar = true;
          this.verifyerrormorecharduplicate = true;
          this.verifyerrormorecharbtn = true;
        }
        else {
          this.verifyerrormorechar = false;
          this.verifyerrormorecharduplicate = false;
          this.verifyerrormorecharbtn = false;
        }
        this.docsVerification = this.fb.group({
          docsName: [this.docModifiedEditedName, [Validators.maxLength(100), Validators.required, Validators.minLength(1)]],
          customform: this.fb.array([]),
          textNote: new FormControl(''),
        })

        this.docFormat = this.docDetails[0].extension;
        if (this.docFormat.toUpperCase() === 'DOC' ||
          this.docFormat.toUpperCase() === "DOCX" || this.docFormat.toUpperCase() === 'ODT' ||
          this.docFormat.toUpperCase() === 'TXT' || this.docFormat.toUpperCase() === 'RTF') {
          this.showTextLayers = true;
          this.showSearch = true;
          if (this.parserflagdata == 'True') {
            if (this.iseditableDocument == 1) {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
            else {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
          }
          else {
            this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
            if (this.docdatafirsttab) {
              this.withoutsearchdoc = false;
              this.withsearchdoc = false;
              this.withoutsearchdoceditable = true;
              this.searchabledoccheck = false;
              this.activetabfirst = true;
              this.activetabsecond = false;
            }
            this.cd.detectChanges();
          }



        } else {

          if (this.parserflagdata == 'True') {
            if (this.iseditableDocument == 1) {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
            else {
              this.showTextLayers = false;
              this.showSearch = true;
              this.docdatafirsttab = "ORIGINAL DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = true;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = false;
                this.searchabledoccheck = true;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
          }
          else {
            this.showTextLayers = false;
            this.showSearch = true;
            this.docdatafirsttab = "ORIGINAL DOC";
            if (this.docdatafirsttab) {
              this.withoutsearchdoc = true;
              this.withsearchdoc = false;
              this.withoutsearchdoceditable = false;
              this.searchabledoccheck = true;
              this.activetabfirst = true;
              this.activetabsecond = false;
            }
            this.cd.detectChanges();
          }

        }
        if (this.searchabledoccheck && this.parserflagdata == 'True') {
          this.showSearchablepdf = true;
        }
        else {
          this.showSearchablepdf = false;
        }


        if (this.docDetails[0].pageCount > 1) {
          this.sidebarOpen = true;
        } else {
          this.sidebarOpen = false;
        }
        this.fileNameVerification = this.fileNameVerification.substring(1, this.fileNameVerification.length);
        this.originalFileNameDocs = response.data.docverificationdetailslists[0].originalFileName;
        this.renamedFileName = response.data.docverificationdetailslists[0].docRename;
        this.docDisplayName = response.data.docverificationdetailslists[0].docOwnerDisplayName;
        if (this.ownerEmailID === this.createdBy) {
          this.docDisplayName = this.docDisplayName + ' (you)';
        }
        // this.docOwnerName = response.data.docverificationdetailslists[0].docOwnerName;
        this.docOwnerName =  localStorage.getItem('noteuserfullname');

        if (this.isEdit) {
          for (let i = 0; i <= response.data.doctagsdetailslists.length - 1; i++) {
            if (response.data.doctagsdetailslists[i].tagControlId == '3') {

              let tageditvalue = response.data.doctagsdetailslists[i].optionValue.replace(/Select,/g, '')
              response.data.doctagsdetailslists[i].optionValue = tageditvalue;
            }
          }
          this.docTagList = response.data.doctagsdetailslists;
          for (let i = 0; i <= this.docTagList.length - 1; i++) {
            if (this.docTagList[i].tagControlId == "2") {
              this.docTagList[i].tagValue = new Date(this.docTagList[i].tagValue)
            }
          }

        }
        else {
          this.docTagList = response.data.doctagsdetailslists;

        }
        let index = 0;
       let k =0;
       let j=0;
       if(this.docTagList?.length !== 0){
         for(let i=0;i<this.docTagList.length;i++){
          if(this.docTagList[i]?.docSource?.toLowerCase().includes('tally')){
            this.tallyTags[index++] = this.docTagList[i];
          }
         }
         for(let i=0;i<this.tallyTags.length;i++){
          if(this.tallyTags[i].tagValue === ""){
            this.singleValueTallyTags[k] = this.tallyTags[i].tagLabel;
            k=k+1;
          }
          else{
            this.labelValueTallyTags[j++] = this.tallyTags[i];
          }
         }
         let recentTallyTags = [...this.labelValueTallyTags];
        const groupByCategory = recentTallyTags.reduce((group, product) => {
          const { externalId } = product;
          group[externalId] = group[externalId] ?? [];
          group[externalId].push(product);
          return group;
        }, {});
        //  const ste = new Set();
        //  let vochersId = this.labelValueTallyTags.map((res) => res.externalId);
        //  vochersId.forEach((res) => {
        //   ste.add(res);
        //  })
        //  const iterableSet = [...ste];
         let array = [];
         let masterTags = [];
         let voucherTags = [];
        for (let [key, value] of Object.entries(groupByCategory)) {
          let obj = {
            key : key,
            value: groupByCategory[key]
          }
          array.push(obj);
          if (this.openAccordion.length == 0) {
            this.openAccordion.push(true);
          } else {
            this.openAccordion.push(false);
          }
      }
      array.forEach((res) => {
        if (res.key.includes("Ledger") || res.key.includes("Stock Item") || res.key.includes("Budget") || 
            res.key.includes("Cost Centre") || res.key.includes("Employee")) {
          masterTags.push(res);
        } else if (res.key.includes("Voucher")) {
          voucherTags.push(res);
        }
      });
      this.vouchersTags = [...voucherTags,...masterTags];
       }
        let splitConvertedPath = response.data.docverificationdetailslists[0].convertedPath?.split('\\').length;
        if (!this.isverification) {
          this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
        } else {
          this.titleService.setTitle(this.originalFileNameDocs + this.middot + this.wsName + this.middot + 'dox2U');
        }
        let originalFileNameDocsConverted = splitConvertedPath === 3 ? response.data.docverificationdetailslists[0].convertedPath?.split('\\')[2] : response.data.docverificationdetailslists[0].convertedPath?.split('\\')[3];
        this.originalnamefile = originalFileNameDocsConverted;
        if (this.parserflagdata == 'True') {
          if (response.data.docverificationdetailslists[0].running_module_code == 'm_14' ||
            response.data.docverificationdetailslists[0].running_module_code == 'm_15') {
            this.pdfFileConversion(originalFileNameDocsConverted, this.docFormat);


          }
          else {
            this.pdfFileConversion(originalFileNameDocsConverted, this.docFormat);
            if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
              this.searchablepdfFileConversion(originalFileNameDocsConverted);
              this.searchabledocverify = false;
            }
          }
        }
        else {
          this.pdfFileConversion(originalFileNameDocsConverted, this.docFormat);
        }

        this.UploadRightsUserList();
        this.tagTemplateList(false);
        if (this.isverification) {
          for (let etags of response.data.doctagsdetailslists) {
            this.addSavedCustomTag(etags.tagLabel, etags.tagValue);
          }
        }
        this.showSpinner = false;
        // if(this.ownerEmailID != this.verifierEmailID){
        this.shareUsersEventbydefault()
        // }

      }
    }, error => {

      this.errormsg();
      // this.router.navigate(['/error/error-page']);

      this.showSpinner = false;
    })
  }

  docNamePattern = /^[^,'"().|?:<>*\\//]*$/;
  docsVerification = this.fb.group({
    // docsName: ['', [Validators.maxLength(40), Validators.required, Validators.minLength(1)]],
    docsName: ['', [Validators.maxLength(100), Validators.required, Validators.minLength(1)]],
    customform: this.fb.array([]),
    textNote: new FormControl(''),
  })
  get customform(): FormArray {
    return this.docsVerification.get("customform") as FormArray
  }

  getDynamicStyle() {
    if (this.vouchersTags.length == 1) {
      return '100vh';
    } else if (this.vouchersTags.length == 2) {
      return 'calc(100vh - 132px)';
    } else if (this.vouchersTags.length > 2) {
      return 'calc(100vh - 264px)';
    }
  }

  onSelectLanguage(event) {
    this.documentLanguage = event.languageName || event;
    this.getradiobtn('ManualLanguage')
  }
  getradiobtn(data) {
    this.radiobtndata = data;
    if (this.radiobtndata == 'Autodetect') {
      this.documentLanguage = 'Autodetect';
    }
  }
  sourceFileeditable;
  addeddatamode;
  pdfshowdata = false;
  pdfFileConversion(fileName, docFormat) {

    if (docFormat.toUpperCase() !== 'PDF') {
      this.addeddatamode = {
        "wsoid": Number(this.workSpaceId),
        "fileName": fileName,
        "folderName": "pdf"
      }
    }
    else {
      this.addeddatamode = {
        "wsoid": Number(this.workSpaceId),
        "fileName": fileName,
        "folderName": "raw"
      }
    }
    this.pdfshowdata = true;
    this.pendingRequest = this.data.verificationpdfViewerFileConversion(this.addeddatamode).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
        this.sourceFile = response;
        this.sourceFileeditable = response;
        this.pdfshowdata = false;

    })
  }
  docdatafirsttab = "";
  titletabdata = '';
  activetabfirst = true;
  activetabsecond = false;
  onChangeTab(data) {
    this.titletabdata = data.tabTitle;
    if (this.titletabdata == 'ORIGINAL DOC') {
      this.withoutsearchdoc = true;
      this.withsearchdoc = false;
      this.activetabfirst = true;
      this.activetabsecond = false;

    }

    if (this.titletabdata == 'ORIGINAL (SEARCHABLE) DOC') {
      this.withoutsearchdoc = false;
      this.withsearchdoc = false;
      this.activetabfirst = true;
      this.activetabsecond = false;

    }
    else if (this.titletabdata == 'SEARCHABLE DOC') {
      this.withoutsearchdoc = false;
      this.withsearchdoc = true;
      this.activetabfirst = false;
      this.activetabsecond = true;

    }
  }
  filenamesearch;
  searchablepdfFileConversion(file) {
    this.filenamesearch = this.originalnamefile;
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "fileName": file,
      "folderName": "parsertransform"
    }
    this.pendingRequest = this.data.verificationpdfViewerFileConversion(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      this.sourceFilesearch = response;
    })
  }

  newformGet(lbl, val, id): FormGroup {
    return this.fb.group({
      label: [lbl, Validators.required],
      value: [val, Validators.required],
      tagcontrolid: id,
    })
  }
  suggestedNameClick() {
    this.docModifiedEditedName = this.docModifiedSystemName;
    this.isDocDuplicateName = false;
  }
  checkAnyChangeInEdit() {
    if (!(this.docsVerification.controls.docsName.pristine || this.docModifiedEditedName === this.docModifiedSystemName) || (!this.docsVerification.controls.textNote.pristine && this.isDocEdited) ||
      (!this.docsVerification.controls.customform.pristine && this.isDocEdited) || !this.disableSave) {
      return true;
    }
    return false;
  }

  verifyselectoption = false;
  isTagsEdited($event, data) {
    this.isDocEdited = true;
    this.disableSave = true;
    this.checkAnyChangeInEdit();
    if ($event) {
      if (this.templateInput.length > 0) {
        for (let i in this.templateInput) {
          if (this.templateInput[i].inputType == "date") {
            // if (data) {
            //   if (this.templateInput[i].id == data.id) {
            //     this.templateInput[i].datetypetag = "datechange";
            //     this.disableSave = false;
            //   }
            // }
                this.templateInput[i].datetypetag = "datechange";
            this.disableSave = false;

          }

          if (this.templateInput[i].inputType == "text") {
            if (this.templateInput[i].id == data?.id) {
              if (this.templateInput[i].tagTempValue == '') {
                this.templateInput[i].textcheck = "";
                this.disableSave = false;
                this.cd.detectChanges();
              }
              else {
                this.cd.detectChanges();
                this.templateInput[i].textcheck = "dropdownchange";
                this.disableSave = false;
                this.cd.detectChanges();
              }

            }
          }

        }
      }

    }
  }

  selectdropdown(data, $event, value) {
    if ($event) {
      if (this.templateInput.length > 0) {
        for (let i in this.templateInput) {
          if (this.templateInput[i].inputType == "dropdown") {
            if (this.templateInput[i].id == data.id) {
              if (this.templateInput[i].selectedValue == 'Select') {
                this.templateInput[i].dropdowncheck = "dropdownchange";
                this.cd.detectChanges();
              }
              else {
                this.templateInput[i].dropdowncheck = "dropdownchange";
                this.cd.detectChanges();
              }

            }
          }
        }
      }

    }
  }

  selecttext(data, $event) {
    if ($event) {

      if (this.templateInput.length > 0) {
        for (let i in this.templateInput) {
          if (this.templateInput[i].inputType == "text") {
            if (this.templateInput[i].id == data.id) {
              if (this.templateInput[i].tagTempValue == '') {
                this.templateInput[i].textcheck = "";
                this.cd.detectChanges();
              }
              else {
                this.cd.detectChanges();
                this.templateInput[i].textcheck = "dropdownchange";
                this.cd.detectChanges();
              }

            }
          }
        }
      }

    }
  }
  getSharedWithData() {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(this.docId),
      "database": "get2doxMaster",
    }
    this.pendingRequest = this.data.getSharedWithModalDataForView(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sharedWithUsers = response.data;
        this.setUserGroupArr = this.sharedWithUsers;
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(true);
        this.sharedService.selectedAccessEditor.next(response.data);
        this.cd.detectChanges();
      }

      else {
        /*
          *radio button selection value checking
       */
        this.sharedService.getuserradio.next(false);
      }
    })
  }
  checkUserWiseRoleAccess() {
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(this.docId),
      "role": this.role,
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid": localStorage.getItem('loginguid'),
      "DocGuid": this.docGuid,
    }
    this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        if (response.data[0].docShareStatus === 'Not Access') {
          // this.router.navigate(['/error/error-page']);
        }
      } else {
        // this.router.navigate(['/error/error-page']);
      }
    }, error => {

    })
  }
  onEvent(value, event) {

    if (this.isView || this.myTrash || this.isEdit) {
      if (this.calledBefore) {
        return;
      }
      if (this.isView || this.myTrash) {
        this.updateDocAuditLog('opened for viewing by', 'AM-028');
      } else if (this.isEdit && localStorage.getItem('isSave') !== 'true') {
        this.updateDocAuditLog('opened for editing by', 'AM-029');
      }

      this.calledBefore = true;
      this.cd.detectChanges();
    }
  }
  originalSplitName: string;

  updateDocAuditLog(operation, serial?) {
    this.originalSplitName = this.originalFileNameDocs.substring(0, this.originalFileNameDocs.lastIndexOf('.'));
    // let originalSplitName = this.originalFileNameDocs?.split(".");
    let name= localStorage.getItem('WsdisplayName')
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document View",
      "logMessage": `<b>${this.renamedFileName || this.originalSplitName}.${this.docFormat.toLowerCase()}</b>` + " " + operation + " " + `<b>${name}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      "serialNo": serial,
      "docName": this.renamedFileName || this.originalSplitName,
      "userName": localStorage.getItem('noteuserfullname'),
      "guid": this.docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
    }
    this.pendingRequest = this.data.viewDocsAuditLog(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

      }
    })
  }
  updateDocActivityLogAfterEditing() {

    let msg = [];
    let serial;
   if (!this.docsVerification.controls.customform.pristine || this.isTagEdited) {
      msg.push('Tags for' + " " + `<b>${this.renamedFileName || this.originalSplitName}.${this.docFormat.toLowerCase()}</b>` + " " + 'changed by' + " " + `<b>${this.docOwnerName}</b>`);
    }
    if (!this.docsVerification.controls.docsName.pristine) {
      msg.push(`<b>${this.renamedFileName || this.originalSplitName}</b>` + " " + 'renamed to' + " " + `<b>${this.docModifiedEditedName}</b>` + " " + 'by' + " " + `<b>${this.docOwnerName}</b>`);
    }
    if (!this.docsVerification.controls.textNote.pristine) {
      msg.push('Notes for' + " " + `<b>${this.renamedFileName || this.originalSplitName}.${this.docFormat.toLowerCase()}</b>` + " " + 'updated by' + " " + `<b>${localStorage}</b>`);
    }
    if (this.isSharedChanged) {
      msg.push('Sharing for' + " " + `<b>${this.renamedFileName || this.originalSplitName}</b>` + " " + 'changed by' + " " + `<b>${this.docOwnerName}</b>`);
      serial = 'AM-035'
    }
    let currentIp = localStorage.getItem("ipAddress");
    for (let i = 0; i <= msg.length; i++) {
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "category": "Document",
        "activity": "Document Edit",
        "logMessage": msg[i].toString(),
        "createdBy": this.createdBy,
        "docId": Number(this.docId),
        "database": "get2doxMaster",
        "serialNo": serial,
        "docName": this.renamedFileName || this.originalSplitName,
        "userName": this.docOwnerName,
        "guid": this.docGuid,
        "IpAddress": currentIp,
        "IsIntegrationViewer": 0
      }
      this.pendingRequest = this.data.viewDocsAuditLog(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
        if (response.isSuccess) {

          if (i === msg.length - 1) {
            localStorage.setItem('isSave', 'true')
            this.isSavedClick = true;
            this.isTagEdited = false;
            this.docsVerification.reset();
            this.isDocEdited = false;
            this.isSharedChanged = false;
            window.location.reload();
          }
        }
      })
    }

  }
  updateDocAuditLogAfterEditing() {
    if (!this.docsVerification.controls.customform.pristine || this.isTagEdited || !this.docsVerification.controls.textNote.pristine) {
      let msg;
      msg = `<b>${this.renamedFileName || this.originalSplitName}.${this.docFormat.toLowerCase()}</b>` + " " + 'edited by' + " " + `<b>${this.docOwnerName}</b>`
      let currentIp = localStorage.getItem("ipAddress");
      let postData = {
        "wsoid": Number(this.workSpaceId),
        "category": "Document",
        "activity": "Document Edit",
        "logMessage": msg,
        "createdBy": this.createdBy,
        "docId": Number(this.docId),
        "database": "get2doxMaster",
        "serialNo": "AM-027",
        "docName": this.renamedFileName || this.originalSplitName,
        "userName": this.docOwnerName,
        "guid": this.docGuid,
        "IpAddress": currentIp,
        "IsIntegrationViewer": 0
                
      }
      this.pendingRequest = this.data.viewDocsAuditLog(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
        if (response.isSuccess) {
          window.location.reload();
        }
      })
    }
  }
  /*
      *check access right validity
  */
  validitycheckother() {
    var stval = window.location.toString();
    var arr = stval?.split("/");
    let access = {
      "emailId": this.createdBy,
      "wsLink": arr[2],
      "wsoid": JSON.parse(this.workSpaceId)
    }
    this.data.getaccessrights(access).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.editClicked = true;
        localStorage.setItem('downloadandprint', response.data.isDownloadPrintDocuments.toString())
        localStorage.setItem('editaccess', response.data.isEditDocuments.toString())
        this.chkdownloadprint = localStorage.getItem('downloadandprint')
        this.chkeditrights = localStorage.getItem('editaccess')
        this.downloadprint = JSON.parse(this.chkdownloadprint);
        this.editrights = JSON.parse(this.chkeditrights)

        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(this.docId),
          "role": this.role,
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": localStorage.getItem('loginguid'),
          "DocGuid": this.docGuid,

        }
        this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

          if (response.isSuccess) {
            if ((response.data[0].docShareStatus === 'Not Access' && !this.allWs)) {
              this.openModal(this.saveRightsChange, false);
            } else {

              this.editDocument('editClick', undefined);

            }
          }
        })
      }
    })
  }

  showAutoSave:boolean =false;
  saveeditedvalue = false;
  editsave = '';
  async editDocument(value, isModal?, redirectTo?): Promise<any> {

    this.editsave = value;

    if (isModal === 'modal') {
      this.dialogRef.close();
      this.isSubmitted = true;
      if (!this.docsVerification.valid) {
        return false;
      }
      let filteredArrayEdit = this.docTagList.filter((e) => {

        return e.tagValue == ''
      })
      if (filteredArrayEdit.length > 0) {
        return;
      }
      if (this.commoncabinet) {
        this.router.navigate(['/pages/cabinet']);
      }
      else {
        this.router.navigate(['/pages/documents/owned-by-me']);
      }

      this.isEdit = false;
    }
    if (value === 'editClick') {
      this.trash = false;
      this.myTrash = false;
      this.isView = false;
      this.isEdit = true;

      this.role = "Edit";
      this.cd.detectChanges();
    }

    if (!this.allWs) {
      this.getaccessrights(value);
    }
    if (value == 'saveDoc') {
      this.saveeditedvalue = true;
      this.exitButtonDisable = true;
    }
    return new Promise(resolve => {
      setTimeout( async() => {
        let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(this.docId),
          "role": this.role,
          "database": "get2doxMaster",
          "createdby": this.createdBy,
          "Isdeleteby": false,
          "guid": this.getguid,
          "DocGuid": this.docGuid,
        }

        this.pendingRequest = await this.data.viewUserWiseRoleAccess(postData).pipe(
          catchError((error) => {
            this.logger.error(error);
          return of(`Error: ${error}`);
        })
        ).toPromise().then((response: any) => {
          if (response.isSuccess) {
            if (response.data[0].docShareStatus === 'Not Access' && !this.allWs) {
              this.saveeditedvalue = false;
              this.editrights = false;
              this.openModal(this.saveRightsChange, false);
            } else if (value === 'saveDoc') {
              this.saveeditedvalue = true;
              this.exitButtonDisable = true;
              this.saveDocDetails('save');
            if(response.data[0].docShareStatus !== 'Not Access'){
              this.updateDocAuditLog('edited by', 'AM-027');
            }
            } else if (value === 'editClick') {
              this.saveeditedvalue = false;
              this.updateDocAuditLog('opened for editing by', 'AM-029');
              this.trash = false;
              this.myTrash = false;
              this.isView = false;
              this.isEdit = true;
              if (localStorage.getItem('redirectedFrom') == 'own-by-me') {
                this.ownByMe = true;
                this.editmode = true;
                this.viewmode = false;
              }
              if (localStorage.getItem('redirectedFrom') == 'shared-with-me') {
                this.shareWithMe = true;
                this.editmode = true;
                this.viewmode = false;
              }
              if (localStorage.getItem('redirectedFrom') == 'all-workspace') {
                this.allWs = true;
                this.editmode = true;
                this.viewmode = false;
              }
              if (localStorage.getItem('redirectedFrom') == 'Search') {
                this.search = true;
                this.editmode = true;
                this.viewmode = false;
              }
              if (localStorage.getItem('redirectedFrom') == 'cabinet') {
                this.commoncabinet = true;
                this.editmode = true;
                this.viewmode = false;
              }
              if (localStorage.getItem('redirectedFrom') == 'dashboard') {
                this.dashboard = true;
                this.editmode = true;
                this.viewmode = false;
              }

              this.role = "Edit";
              this.cd.detectChanges();
              this.titleService.setTitle(this.renamedFileName + this.middot + this.wsName + this.middot + 'dox2U');
              const url = this.router.serializeUrl(
                this.router.createUrlTree(['edit/edit-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })

              );
              this.location.replaceState(url);
              if (!this.editrights && this.documentOwnerEmail !== this.createdBy) {
                // if (url.includes('edit')) {
                  //   this.router.navigate(['/error/error-page']);
                  // }
                }
            }
          } else {
            this.saveeditedvalue = false;
            this.editrights = false;
            this.openModal(this.saveRightsChange, false);
          }
        }, error => {

        })
        resolve(of(0));
      }, 1000);
    })
  }
  addSavedCustomTag(lbl, val) {
    let id;
    if (val.includes('-')) {
      id = '2';
    } else {
      id = '1';
    }
    this.customform.push(this.newformGet(lbl, val, id))
    this.disableSave = false;
  }

  isSelectedAllUser($event) {
    this.isSelectedAllUsers = $event;
  }

  sharedGroupCount($event) {
    this.shareuserCount = $event;
  }
  sharedGroup($event) {
    this.setUserGroupArr = $event;
    if (this.setUserGroupArr.length > 0 && !this.isCheckedAllUsers) {
      this.isAllUserShare = false;
    }
    if (this.setUserGroupArr.includes(this.verifierName)) {
      this.showVerifier = true;
    } else {
      this.showVerifier = false;
    }
    this.isDocEdited = true;
    this.disableSave = false;
    this.isSharedChanged = true;
    this.cd.detectChanges();
    this.docsVerification.markAsDirty();
  }
  isUserGrp($event) {
    this.isUsersGroup = $event;
    this.cd.detectChanges();
  }
  tagTemplateEdit($event) {
    this.isSubmitted = false;
    this.tagTemplateList($event.flag, $event);
  }
  openModalTemplate() {
    this.templateEditorChild.openTemplateModal();
  }

  documentRestore() {
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "docIdList": this.docDetails[0].docId.toString(),
      "originalFileNameList": this.originalFileNameDocs,
      "docStatus": "VERIFIED",
      "modifiedBy": this.createdBy,
      "userName": this.docDisplayName,
      "guid": this.getguid,
      "DocGuid": this.docDetails[0].docGuid,
      "ExternalSol": "dox2uapp"
    }
    this.pendingRequest = this.data.updateTrashService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.trash = false;
        this.ownByMe = true;
        this.myTrash = false;
        this.isView = true;
        this.role = "View";
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
        );
        this.location.replaceState(url);
        this.showToastr('Document Restored.');
        this.updateDocAuditLog('Restored By');
      }
      else {

      }
    }, error => {
      this.errormsg();
    })
  }
  checkDuplicateDocName() {
    this.docsVerification.markAsDirty();
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "docName": this.docmodifiednameinputbox
    }
    this.pendingRequest = this.data.checkNameAlreadyExistsInWS(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.data[0].duplicateName === 0) {
          this.isNameDuplicate = false;
        } else if (response.data[0].duplicateName === 1) {
          this.isNameDuplicate = true;
        }
      }
      else {

      }
    })
  }
  valuecheckdulicate = false;
  async checkDuplicateDocNamethree() {
    return new Promise (resolve =>{
      setTimeout(async () => {
        this.docsVerification.markAsDirty();
        let postData: any
        postData = {
          "Wsoid": Number(this.workSpaceId),
          "docName": this.docmodifiednameinputbox
        }
        this.cd.detectChanges()
        this.pendingRequest = await this.data.checkNameAlreadyExistsInWS(postData).pipe(
          catchError((error) => {
            this.logger.error(error);
          return of(`Error: ${error}`);
        })
        ).toPromise().then((response: any) => {
          if (response.isSuccess) {
            if (response.data[0].duplicateName === 0) {
              this.isNameDuplicate = false;
              this.valuecheckdulicate = false;
              this.cd.detectChanges()
            } else if (response.data[0].duplicateName === 1) {
              this.isNameDuplicate = true;
              this.valuecheckdulicate = true;
              this.cd.detectChanges()
            }
          }
          else {

          }
        })
        resolve(of(0));
      }, 0);
    })
  }

  outduplicate = false;
  checkDuplicateDocNameout() {

    if (this.docModifiedEditedName == this.docmodifiednameinputbox) {
      this.outduplicate = false;
    }
    else {
      this.outduplicate = true;
    }

    this.docsVerification.markAsDirty();
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "docName": this.docModifiedEditedName
    }

    this.cd.detectChanges()
    this.pendingRequest = this.data.checkNameAlreadyExistsInWS(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.data[0].duplicateName === 0) {
          this.isNameDuplicate = false;
          this.valuecheckdulicate = false;
          this.cd.detectChanges()
        } else if (response.data[0].duplicateName === 1) {
          this.isNameDuplicate = true;
          this.valuecheckdulicate = true;
          this.cd.detectChanges()
        }
      }
      else {

      }
    })

  }
  onBlur() {
    if (this.docModifiedEditedName.length > 0) {
      this.checkDuplicateDocName();
    }
  }
  newform(id): FormGroup {
    return this.fb.group({
      label: ['', Validators.required],
      value: ['', Validators.required],
      tagcontrolid: id,
    })
  }

  addtext() {
    this.customform.push(this.newform('1'))
    this.docsVerification.markAsDirty()
    this.isVerifyClick = false;
    this.labeldropdown();
    this.disableSave = false;

  }

  lebelarray = [];
  selectedlebel;
  labeldropdown() {
    let labeldata = {
      wsoid: Number(this.workSpaceId)
    }
    this.pendingRequest = this.data.labeldropdown(labeldata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        this.isDocEdited = true;
        if (response.data.length > 0) {
          this.lebelarray = response.data;
          for (let i = 0; i <= response.data.length - 1; i++) {
            this.lebelarray[i].idlabel = i + 1;
          }

        }
      }
    })
  }

  valuearray = [];
  selectefvaluearray = [];
  getselectdata;
  linewiseindex;
  labelchoose(data, index) {
    this.getselectdata = data;
    this.linewiseindex = index;
    let templatedata = {
      wsoid: JSON.parse(this.workSpaceId),
      tagLabel: data.tagLabel
    }

    this.pendingRequest = this.data.templatechange(templatedata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.data.length > 0) {
          this.valuearray = response.data;
          for (let i = 0; i <= this.valuearray.length - 1; i++) {
            this.valuearray[i]['id'] = this.getselectdata.idlabel;

          }
          for (let i = 0; i <= this.docsVerification.get('customform')['controls'].length - 1; i++) {

            if (i + 1 == this.linewiseindex) {

              if (!this.docsVerification.get('customform')['controls'][i]['array']) {
                this.docsVerification.get('customform')['controls'][i]['array'] = this.valuearray;
                this.docsVerification.get('customform')['controls'][i]['idlevelvalue'] = this.getselectdata.idlabel
                this.docsVerification.get('customform')['controls'][i]['lineindex'] = this.linewiseindex;
              }

              if (this.docsVerification.get('customform')['controls'][i]['idlevelvalue']) {
                if (this.docsVerification.get('customform')['controls'][i]['lineindex'] == this.linewiseindex) {
                  this.docsVerification.get('customform')['controls'][i]['array'] = this.valuearray;
                  this.docsVerification.get('customform')['controls'][i]['idlevelvalue'] = this.getselectdata.idlabel
                  this.docsVerification.get('customform')['controls'][i]['lineindex'] = this.linewiseindex

                }
              }
            }
          }
        }
        else {
          this.docsVerification.get('customform')['controls']
        }
      }
    })
  }

  labelchooseedit(data, index) {
    this.getselectdata = index;
    this.linewiseindex = index;
    let templatedata = {
      wsoid: JSON.parse(this.workSpaceId),
      tagLabel: data
    }
    this.pendingRequest = this.data.templatechange(templatedata).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.data.length > 0) {
          this.valuearray = response.data;
          for (let i = 0; i <= this.valuearray.length - 1; i++) {
            this.valuearray[i]['id'] = this.getselectdata;

          }
          for (let i = 0; i <= this.docsVerification.get('customform')['controls'].length - 1; i++) {

            if (i + 1 == this.linewiseindex) {

              if (!this.docsVerification.get('customform')['controls'][i]['array']) {
                this.docsVerification.get('customform')['controls'][i]['array'] = this.valuearray;
                this.docsVerification.get('customform')['controls'][i]['idlevelvalue'] = this.getselectdata
                this.docsVerification.get('customform')['controls'][i]['lineindex'] = this.linewiseindex;
              }

              if (this.docsVerification.get('customform')['controls'][i]['idlevelvalue']) {
                if (this.docsVerification.get('customform')['controls'][i]['lineindex'] == this.linewiseindex) {
                  this.docsVerification.get('customform')['controls'][i]['array'] = this.valuearray;
                  this.docsVerification.get('customform')['controls'][i]['idlevelvalue'] = this.getselectdata
                  this.docsVerification.get('customform')['controls'][i]['lineindex'] = this.linewiseindex

                }
              }
            }

          }
        }
        else {
          for (let i = 0; i <= this.docsVerification.get('customform')['controls'].length - 1; i++) {

            if (i + 1 == this.linewiseindex) {
              if (this.docsVerification.get('customform')['controls'][i]['array']) {
                this.docsVerification.get('customform')['controls'][i]['array'] = []
              }

            }


          }
        }

      }
    })
  }

  valuedata;
  valuechoose(data, index) {
    this.valuedata = data;
  }
  valuechooseedit(data, index) {
    this.valuedata = data;
  }
  addDate() {
    this.customform.push(this.newform('2'))
    this.docsVerification.markAsDirty();
    this.isVerifyClick = false;
    this.labeldropdown();
    this.disableSave = false;

  }
  removeinput(i: number) {
    this.customform.removeAt(i)
  }
  isCheckedAllUser($event) {
    this.isCheckedAllUsers = $event;
    this.isAllUserShare = $event;
  }
  redirectedToOrigin() {
    this.router.navigate(['/pages/documents/my-queue']);
  }
  selectedValue: any;
  loctiondirect() {
    window.location.href = '/pages/dashboard';
    console.log("dashboard true4102")
  }
  verifydata = false;
  verifyandexit = false;
  showSpinnerSave = false;
  exitButtonDisable = false;
  saveDocDetails(flagValue) {
    this.isSubmitted = true;
    // if (!this.docsVerification.valid) {
    //   this.saveeditedvalue = false;
    //   return false;
    // }
    if (this.isEdit || this.docsVerification.valid) {
      let filteredArrayEdit = this.docTagList.filter((e) => {
        return e.tagValue == ''
      })
      if (filteredArrayEdit.length > 0) {
        this.saveeditedvalue = false;
        // return filteredArrayEdit;
      }
    } else {
      let filteredArray = this.templateInput.filter((e) => {
        return e.required && e.tagTempValue == ''
      })
      if (filteredArray.length > 0) {

        return;
      }
    }
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any;

    let doctagArray = [];
   
    if (flagValue === 'verify') {

      let filteredData = this.templateInput.filter((e) => {
        return e.tagTempValue != '';
      });
      filteredData.forEach((e) => {
        let template = {
          "docId": Number(this.docId),
          "tempId": this.tagTemplateId,
          "tagLabel": "",
          "tagValue": "",
          "createdBy": this.createdBy,
          "tagcontrolid": "",
          "optionvalue": ""
        }
        if (e.inputType === "text") {
          template.tagLabel = e.tagTempLabel;
          template.tagValue = e.tagTempValue;
          template.tagcontrolid = '1';
          doctagArray.push(template);
        } else if (e.inputType === "dropdown") {
          if (e.selectedValue != '' || e.selectedValue != null) {
            template.tagLabel = e.tagTempLabel;
            template.tagValue = e.selectedValue;
            template.tagcontrolid = '3';
            if (e.tagTempLabel && e.selectedValue) {
              if (e.selectedValue != 'Select') {
                template.optionvalue = e.tagTempValue.join('||');
                doctagArray.push(template);
              }
            }
          }
        } else if (e.inputType === "date") {
          if (e.tagTempValue != "Invalid date NaN,NaN") {
            template.tagLabel = e.tagTempLabel;
            let changedateformat = moment(e.tagTempValue).format('YYYY-MM-DD');
            template.tagValue = changedateformat;
            template.tagcontrolid = '2';
            doctagArray.push(template);
          }

        }
      })
      for (let e of this.customform.controls) {
        let a = e as FormGroup;
        let template = {
          "docId": Number(this.docId),
          "tagLabel": "",
          "tagValue": "",
          "tagcontrolid": "",
          "createdBy": this.createdBy,
        }
        template.tagLabel = a.value.label;
        if (a.value.tagcontrolid == 2) {
          let changedateformat = moment(a.value.value).format('YYYY-MM-DD');
          template.tagValue = changedateformat;
        }
        else {
          template.tagValue = a.value.value;
        }

        template.tagcontrolid = a.value.tagcontrolid;

        doctagArray.push(template);
      }
    }
    if (flagValue === 'save') {
      this.saveeditedvalue = true;
      this.exitButtonDisable = true;
      let filteredData = this.docTagList.filter((e) => {
        return e.tagTempValue != '';
      });

      filteredData.forEach((e) => {
        let template = {
          "docId": Number(this.docId),
          "tempId": e.tempID,
          "tagLabel": "",
          "tagValue": "",
          "createdBy": this.createdBy,
          "tagcontrolid": "",
          "optionvalue": ""
        }
        if (e.tagControlId === "1") {
          template.tagLabel = e.tagLabel;
          template.tagValue = e.tagValue;
          template.tagcontrolid = '1';
          doctagArray.push(template);
        } else if (e.tagControlId === "3") {
          template.tagLabel = e.tagLabel;
          template.tagValue = e.tagValue;
          template.tagcontrolid = '3';
          template.optionvalue = e.optionValue;
          doctagArray.push(template);
        } else if (e.tagControlId === "2") {
          template.tagLabel = e.tagLabel;

          let changedateformat = moment(e.tagValue).format('YYYY-MM-DD');
          template.tagValue = changedateformat;

          template.tagcontrolid = '2';
          doctagArray.push(template);
        }
      })

      for (let e of this.customform.controls) {
        let a = e as FormGroup;
        let template = {
          "docId": Number(this.docId),
          "tagLabel": "",
          "tagValue": "",
          "tagcontrolid": "",
          "createdBy": this.createdBy,

        }
        template.tagLabel = a.value.label;
        if (a.value.tagcontrolid == 2) {
          let changedateformat = moment(a.value.value).format('YYYY-MM-DD');
          template.tagValue = changedateformat;
        }
        else {
          template.tagValue = a.value.value;
        }

        template.tagcontrolid = a.value.tagcontrolid;

        doctagArray.push(template);
      }
    }
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "DocID": Number(this.docId),
      "TempID": Number(this.tagTemplateId),
      "DocRename": this.docModifiedEditedName,
      "DocOwner": this.documentOwnerEmail,
      "IsAllUserShare": this.isCheckedAllUsers,
      "Flag": flagValue,
      "ModifiedBy": this.createdBy,
      "docTags": doctagArray,
      "ownerEmailId": this.documentOwnerEmail,
      "ownerName": this.documentOwnerName,
      "oldOwnerEmailId": this.ownerEmailID,
      "docGuidList": this.docDetails[0].docGuid,
      /*
      *adding modified by display name in the key of oldOwnerName
      */
      "oldOwnerName": this.WsdisplayName ?? '',
      "Notes": localStorage.getItem('notesValue'),
      "WsName": this.wsName,
      "guid": this.getguid,
      "DocGuid": this.docDetails[0].docGuid,
      "iDsList": String(this.docId),
      "Source": "dox2uapp"
    }
    this.showSpinnerVerify = true;
    this.saveeditedvalue = true;
    this.pendingRequest = this.data.saveDocsDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {

      if (response.isSuccess) {
        this.showAutoSave = false;
        this.renamedFileName = response?.request?.docRename;
        this.sharedService.docRenameVal.next(this.renamedFileName);
        // localStorage.setItem("docRenameVal",this.renamedFileName);
        this.docModifiedSystemName = this.docModifiedEditedName;
        this.isDocEdited = false;
        this.showSpinnerVerify = false;
        if (flagValue === 'verify') {
          this.showSpinnerVerify = false;
          this.verifyandexit = true;
          localStorage.setItem("saved","true");
          this.dialogRef.close();
        }
        this.isAllCheckPassed = true;
        if (this.ownerEmailID !== this.documentOwnerEmail) {

          this.notifyinsert();
          if (this.createdBy !== this.ownerEmailID) {
            this.notifyinsertold();
          }
          this.updateDocAuditLogChangeOwner('made owner for');
          if (!this.docsVerification.controls.docsName.pristine) {
            this.notifyinsertNameModified();
          }
        }
        if (this.createdBy !== this.documentOwnerEmail) {
          if (!this.docsVerification.controls.docsName.pristine) {
            this.notifyinsertNameModified();
          }
        }
        if (flagValue === 'verify') {
          this.toastrService.primary('Document verification complete');
          if (this.createdBy !== this.documentOwnerEmail) {
            this.notifyinsertVerifierChange();
          }
          this.elasticUpdate(postData);
          this.isDocEdited = false;
          this.dialogRef.close();
          this.docsVerification.reset();
          this.verifydata = true;
          setTimeout(() => {
            this.router.navigateByUrl('/pages/documents/my-queue');
          }, 2000);

        }
        if (flagValue === 'save') {
          this.isDocEdited = false;
          this.disableSave = true;
          this.saveeditedvalue = false;
          this.exitButtonDisable = false;
          this.showToastr('Document renamed.');

          this.elasticUpdate(postData);
          this.isSubmitted = false;
          this.isAllCheckPassed = false;

          if (!this.docsVerification.controls.customform.pristine || this.isTagEdited || !this.docsVerification.controls.textNote.pristine) {
            this.notifyinsertNotesTags();

           // this.updateDocAuditLogAfterEditing();
            // this.updateDocActivityLogAfterEditing();

          }
          // window.location.reload()
        }
      }

    }, error => {
      this.errormsg();
      setTimeout(() => {
        this.showAutoSave = false;
        // this.toastr.error('Details not saved');
      }, 2000);
   })

  }


  documentElastic;
  elasticUpdate(data) {

    this.pendingRequest = this.data.elasticUpdate(data).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.documentElastic = response;
      }
    })
  }

  insertmessgae = '';
  messageheading;
  messagebody;
  calender(id) {
    var ids = document.getElementById(id);
    ids.click();

  }
  notifyinsertNotesTags() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document tags or notes updated",
      message: "<b>" + this.userfullname + " " + "(" + this.createdBy + ")" + "</b>" + " " + "updated tags or notes of" + " " + "View" + " " + `<a href=${urlchange}>Owned By Me</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // this.notifysendNotesTags();
        this.toastrService.primary('Document tags or notes updated');
      }
      else {
        this.insertmessgae = '';
      }

    })

  };
  notifysendNotesTags() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: "Document tags or notes updated",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
  notifyinsert() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document ownership assigned",
      message: "<b>" + this.userfullname + " " + "(" + this.createdBy + ")" + "</b>" + " " + "has made you the owner of" + " " + this.docModifiedEditedName  + " " + "View" + " " + `<a href=${urlchange}>Owned By Me</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // this.notifysend();
        this.toastrService.primary('Document ownership assigned');
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  sendmessage;
  notifysend() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: "Document ownership assigned",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }

  notifyinsertNameModified() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document name is updated",
      message: "<b>" + this.userfullname + " " + "(" + this.createdBy + ")" + "</b>" + " " + "renamed" + " " + this.renamedFileName + " " + "to" + " " + this.docModifiedEditedName + " " + "View" + " " + `<a href=${urlchange}>Owned By Me</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // this.notifysendNameModified();
        this.toastrService.primary('Document Name is updated');
      }
      else {
        this.insertmessgae = '';
      }

    })

  };

  notifysendNameModified() {
    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: "Document Name is updated",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
  notifyinsertVerifierChange() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange = arr[0] + "//" + arr[2] + '/pages/documents/owned-by-me'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document verification complete",
      message: "<b>" + this.userfullname + " " + "(" + this.createdBy + ")" + "</b>" + " " + "has completed the verification" + " " + this.docModifiedEditedName + " " + "View" + " " + `<a href=${urlchange}>Owned By Me</a>`,
      notificationTo: this.documentOwnerEmail,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // this.notifysendVerifierChange();
        // this.toastrService.primary('Document verification complete');
      }
      else {
        this.insertmessgae = '';
      }

    })

  };
  notifysendVerifierChange() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.documentOwnerEmail,
      role: null,
      groupName: null,
      message: "Document verification complete",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
  notifyinsertold() {
    var st = window.location.toString();
    var arr = st?.split("/");
    var urlchange2 = arr[0] + "//" + arr[2] + '/pages/documents/workspace-queue'

    let insert = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: this.createdBy,
      notificationHeading: "Document ownership changed",
      message: "<b>" + this.userfullname + " " + "(" + this.createdBy + ")" + "</b>" + " " + "has transferred the ownership of" + " " + this.docModifiedEditedName + " " + "from you to " + this.documentOwnerName + " (" + this.documentOwnerEmail + ")" + " " + "View " + `<a href=${urlchange2}>Workspace Queue</a>`,
      notificationTo: this.ownerEmailID,
      createdBy: this.createdBy,
    }
    this.insertmessgae = '';
    this.signInService.notifyinsert(insert).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        // this.notifysendold();
        this.toastrService.primary('Document ownership changed');
      }
      else {
        this.insertmessgae = '';
      }

    })

  };


  notifysendold() {

    let notifysend = {
      wsoid: Number(this.workSpaceId),
      key: null,
      userEmailID: this.ownerEmailID,
      role: null,
      groupName: null,
      message: "Document ownership changed",
      databaseName: "",
      createdBy: this.createdBy,
      Flag: "Toster",
      WsLink: ""

    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysend).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }


  updateDocAuditLogChangeOwner(operation) {
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document Verified",
      "logMessage": `<b>${this.documentOwnerName}</b>` + " " + operation + " " + `<b>${this.originalFileNameDocs}</b>` + " " +
        'by' + " " + `<b>${this.docOwnerName}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      "serialNo": "AM-021",
      "docName": this.originalFileNameDocs,
      "userName": this.docOwnerName,
      "guid": this.docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
      
    }
    this.pendingRequest = this.data.viewDocsAuditLog(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

      }
    })
  }
  splitDescription(theString: string) {
    if (theString) {
      return theString?.split('||');
    }
  }
  docsStatusList() {
    this.isSubmitted = true;
    this.disableVerify = true;
    this.docsVerification.value.docsName.length;
    // if (!this.docsVerification.valid) {
    //   return false;
    // }

    if (this.isEdit || !this.docsVerification.valid) {
      let filteredArrayEdit = this.docTagList.filter((e) => {
        return e.tagValue == ''
      })
      if (filteredArrayEdit.length > 0) {
        return;
      }
    } else {
      let filteredArray = this.templateInput.filter((e) => {
        return e.required && e.tagTempValue == ''
      })
      if (filteredArray.length > 0) {
        return;
      }
    }
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "docID": Number(this.docId),
      "role": this.role,
      "database": "get2doxMaster",
      "createdby": this.createdBy,
      "Isdeleteby": false,
      "guid": localStorage.getItem('loginguid'),
      "DocGuid": this.docGuid,
    }
    this.pendingRequest = this.data.viewUserWiseRoleAccess(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        if (response.data[0].docShareStatus === 'Not Access') {
          this.disableVerify = false;
          this.openModal(this.verifierChange, false);
        } else {
          this.disableVerify = false;
          this.openModal(this.verify, false);
        }
      } else {
        this.disableVerify = false;
        // this.router.navigate(['/error/error-page']);
      }
    }, error => {

    })
  }

  showOverlaySpinner = true;
  fieldblankverify = '';
  verifyexitbtn = '';
  verifycheckexist = '';
  docsStatusCheck(event) {
    this.isSubmitted = true;
    this.verifycheckexist = event;
    this.isVerifyClick = true
    this.fieldblankverify = '';
    this.templateInput.forEach((e) => {
      let flag = false;
      if (e.required && e.inputType !== 'date' && (e.tagTempValue === '' || e.selectedValue === null || e.selectedValue === '')) {
        setTimeout(() => {
          const element = this.renderer.selectRootElement('#' + e.tagTempLabel);
          element.focus()
        }, 10);
        flag = true;
      }

      if (flag) {
        return true;
      }
    })
    if (this.isEdit) {
      let filteredArrayEdit = this.docTagList.filter((e) => {
        return e.tagValue == ''
      })
      if (filteredArrayEdit.length > 0) {
        return;
      }
    } else {
      let filteredArray = this.templateInput.filter((e) => {

        if (e.inputType == 'date') {

          var timestamp = Date.parse(e.tagTempValue);

          if (isNaN(timestamp) == true) {
            e.tagTempValue == ''

            var d = new Date(timestamp);
            if (Object.prototype.toString.call(d) === '[object Date]') {
              if (isNaN(d.getTime())) {
                d.valueOf()
                e.datetypetag = '';
                e.tagTempValue = '';
              } else {

                e.datetypetag = 'datecheck';
                e.tagTempValue = '';
              }
            } else {

              e.datetypetag = '';
              e.tagTempValue = '';
            }
          }
          else {
            e.datetypetag = 'datecheck';

          }
        }
        if (e.inputType == 'dropdown') {
          if (e.selectedValue === 'Select') {
            e.dropdowncheck = ''
          }
          else {
            e.dropdowncheck = 'dropdata'
          }
        }
        if (e.inputType == 'text') {
          if (e.tagTempValue === '') {
            e.textcheck = ''
          }
          else {
            e.textcheck = 'dropdata'
          }
        }
        if (e.required && (e.textcheck == '' || e.datetypetag == '' || e.selectedValue === null || e.dropdowncheck === '' || e.selectedValue === '')) {
          this.fieldblankverify = 'The fields cannot be blank';
        }
        else if (this.verifyselectoption) {
          this.fieldblankverify = 'The fields cannot be blank';
        }
        return (e.required && (e.textcheck == '' || e.tagTempValue == 'Invalid date NaN,NaN' || e.tagTempValue == 'Invalid Date' || e.selectedValue === null || e.selectedValue === '' || e.dropdowncheck === ''))
      })

      this.customdatatag = [];
      for (let i = 0; i <= this.docsVerification.get('customform')['controls'].length - 1; i++) {
        this.flagAutoChange = true

        const getLabel = this.docsVerification.get('customform')['controls'][i]['controls']['label'];
        const getValue = this.docsVerification.get('customform')['controls'][i]['controls']['value'];
        if (getLabel.value === "" || getLabel.value === undefined) {
          this.docsVerification.get('customform')['controls'][i]['controls']['label']['label_'] = "0"
        } else {
          this.docsVerification.get('customform')['controls'][i]['controls']['label']['label_'] = "1"

        }

        if (getValue.value === "" || getValue.value === undefined) {
          this.docsVerification.get('customform')['controls'][i]['controls']['value']['value_'] = "0"
        } else {
          this.docsVerification.get('customform')['controls'][i]['controls']['value']['value_'] = "1"

        }

        this.customdatatag.push(this.docsVerification.get('customform').value[i])

      }
      setTimeout(() => {
        this.flagAutoChange = false
        return false
      }, 500)

      if (this.docsVerification.get('customform')['controls']) {

        let verifyval = this.customdatatag.filter(item => {
          if (item.value === '' || item.label === '' || item.value === undefined || item.label === undefined) {
            return item
          }
        })

        if (verifyval.length > 0) {
          this.fieldblankverify = 'The fields cannot be blank';
        }
      }

      if (filteredArray.length > 0) {
        return;
      }
    }


    this.showOverlaySpinner = false;
    this.showText = false;
    this.disableVerify = true;
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
      "DocID": Number(this.docId)
    }

    // if(this.ownerEmailID != this.verifierEmailID){
    // this.sharedService.sharedAccessData.next();
    // }
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.showText = true;
    }, 1000);
    this.pendingRequest = this.data.docsStatusCheckService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.documentStatusCheck = response.data.docStatus;
        if (response.data.docStatus == 'Pending' && !this.verifyselectoption) {
          this.disableVerify = true;
          this.docsStatusList();
        }
        else if ((response.data.docStatus == 'Verified') || (response.data.docStatus == 'Deleted')) {
          this.disableVerify = false;
          this.openModal(this.multiple, false);


        }
      }
    }, error => {
      this.errormsg();
    })

  }

  private errormsg() {

  }
  @ViewChild('dialog', { read: TemplateRef }) dialog: TemplateRef<any>;
  openmodal() {
    this.sharedService.isShare.next(this.isAllStatus);
    this.shareAccess.openShareModal();

  }
  openmodalPopover() {
    this.sharedService.isShare.next(this.isAllStatus);
    this.shareuse.openSharePopModal();

  }

  labelcustom(data) {

  }

  public multipleInput: any[] = [{
    id: 1,
    inputBox: '',
  }];
  addInput() {
    this.multipleInput.push({
      id: this.multipleInput.length + 1,
      inputBox: '',
    });
  }
  removeInput(i: number) {
    this.multipleInput.splice(i, 1);
  }

  removeTemplateInput(id) {
    let index = this.docTagList.findIndex(e => {
      return e.id == id;
    });
    this.docTagList.splice(index, 1);
    this.isDocEdited = true;
    this.isTagEdited = true;
    this.disableSave = false;
  }

  public multipledate: any[] = [{
    id: 1,
    inputBox: '',
    type: 'text'
  }];
  z: string = "";

  canleave(nextState) {
    this.z = nextState.url
    if ((this.isverification  && this.isEdit && this.editrights)) {
      this.saveChangesClick = false;
    } else {
      return true;
    }
  }


  discardchOwn() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/documents/owned-by-me']);
    this.dialogRef.close();
  }
  discardchMyDoc() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/documents/my-queue']);
    this.dialogRef.close();
  }
  discardchSearch() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/search']);
    this.dialogRef.close();
  }
  discardchShared() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/documents/shared-with-me']);
    this.dialogRef.close();
  }
  discardchTrash() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/my-trash']);
    this.dialogRef.close();
  }
  discardchAllWs() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/all-workspace']);
    this.dialogRef.close();
  }
  discardchDashboard() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/dashboard']);
    console.log("dashboard true 5094")
    this.dialogRef.close();
  }

  discardchcommoncabinet() {
    this.docsVerification.reset();
    this.isDocEdited = false;
    this.router.navigate(['/pages/cabinet']);
    this.dialogRef.close();
  }

  public redirected() {
    this.router.navigate(['/pages/documents/my-queue']);
  }



  /* --------------------------------------------Template Editor Type Script code-------------------------------------------------*/
  TemplateEditor = this.fb.group({
    title: ["", Validators.required],
    description: [""],
  })



  public visible: boolean = false;
  public IsDisabled: boolean = false


  @ViewChild('templateEditor') temeditir!: ElementRef<any>;


  editTempId: number;
  id: number;
  todos = ['abc', 'xyz'];

  ChangeRequiredValue(temp) {
    let index = this.editTemplateInput.findIndex((e) => {
      return temp.id == e.id
    })
    this.editTemplateInput[index].isRequired = !this.editTemplateInput[index].isRequired;
  }


  idsList: any = [];
  categroyList: any = [];
  userEmailIDsAndGroupList: any = [];
  userNameGroupList: any = [];

  allUserGroupList: any = [];
  setUserGroupArr: any = [];

  isCheckedAllUsers: boolean = true;

  isSelectedAllUsers: boolean = true;
  isUsersGroup: boolean = false;
  _showBoxes = false;

  public get showBoxes(): boolean {
    return this._showBoxes;
  }

  public set showBoxes(show: boolean) {
    if (show) {
      this.alreadyRendered.forEach((span) => {
        span.classList.add('box');
      });
    } else {
      this.alreadyRendered.forEach((span) => {
        span.classList.remove('box');
      });
    }
    this.cd.detectChanges();
  }

  alreadyRendered: Array<HTMLSpanElement> = [];

  public highlightWords(event: TextLayerRenderedEvent): void {
    event.source.textDivs.forEach((span) => {
      this.alreadyRendered.push(span);
    });
    if (this.showTextLayer) {
      event.source.textDivs.forEach((span) => {
        span.classList.add('box');
      });
    }
    this.cd.detectChanges();
  }
  public showProfile: boolean = false;
  profilediv(data:any){
   this.profileData = data;
  }
  opennotification() {
    this.showProfile = false;
    localStorage.removeItem('newnotify');
    this.newnotifybtn = true;
    if (this.hidenotify) {
      this.hidenotify = false;

      if (localStorage.getItem('deleteddbnid')) {
        this.undobtnshow = localStorage.getItem('deleteddbnid')
      }
      else {
        this.undobtnshow = '';
      }
      this.getallnotification();
    }
    else {
      this.hidenotify = true;
    }
  }

  newnotifybtn = true;
  newnotification() {
    this.newnotifybtn = false;
  }

  notifyActions(id) {
    if (id == "MarkASREAD") {
      this.allreadmessage();
    } else if (id == "DELETE") {
      this.deleteallnotification();
    }
  }

  dbniddelete = [];
  deleteallnotification() {

    this.dbniddelete = [];

    for (let i = 0; i <= this.getallArray.length - 1; i++) {
      this.dbniddelete.push(this.getallArray[i].dbnid)
    }

    let onedelete = {
      dbnid: this.dbniddelete.join(),
      wsoid: Number(this.workSpaceId),
      modifiedBy: this.createdBy,
      flag: "delete"
    }
    localStorage.setItem('deleteddbnid', this.dbniddelete.join())
    this.checkundobtn = true;
    this.signUpService.onebyonedelete(onedelete).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.undobtnshow = localStorage.getItem('deleteddbnid')
        this.getallbudgescount();
        this.getallnotification();

      }
    })
  }

  budgecount: any;
  budgecountval: any;
  getallbudgescount() {

    let notification = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: "",
      notificationTo: this.createdBy,
      databaseName: "",
      createdBy: this.createdBy
    }
    this.budgecount = '';
    this.budgecountval = [];
    this.signUpService.getallnotification(notification).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.budgecount = '';
        this.budgecountval = [];
        for (let i = 0; i <= response.data.length - 1; i++) {
          if (!(response.data[i].isRead)) {
            this.budgecountval.push(response.data[i]);
          }
        }
        if (localStorage.getItem('deleteddbnid')) {
          this.undobtnshow = localStorage.getItem('deleteddbnid')
        }
        else {
          this.undobtnshow = '';
        }
        if (this.budgecountval.length) {
          if (this.budgecountval.length > 99) {
            this.budgecount = "99+"
          }
          else {
            this.budgecount = this.budgecountval.length;
          }

        }

        this.cd.detectChanges();
      }
    })
  }

  undodata() {
    let onedelete = {
      dbnid: this.undobtnshow,
      wsoid: Number(this.workSpaceId),
      modifiedBy: this.createdBy,
      flag: "undo"
    }
    this.signUpService.onebyonedelete(onedelete).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        localStorage.removeItem('deleteddbnid');
        this.getallbudgescount();
        this.getallnotification();
      }
    })
  }

  getallArray: any;
  totalnotification: any;
  msgnotification;

  getallnotification() {

    let notification = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: "",
      notificationTo: this.createdBy,
      databaseName: "",
      createdBy: this.createdBy
    }
    this.getallArray = [];

    this.totalnotification = '0';

    this.signUpService.getallnotification(notification).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

        for (let i = 0; i <= response.data.length - 1; i++) {
          response.data[i].datetime = new Date(response.data[i].date)
        }
        this.getallArray = response.data;
        const readAry = this.getallArray.filter(el => el['isRead'] === false).sort((a, b) => {
          return b['datetime'] - a['datetime']
        })

        const unReadAry = this.getallArray.filter(el => el['isRead'] === true).sort((a, b) => {
          return b['datetime'] - a['datetime']
        })
        this.getallArray = [...readAry, ...unReadAry]
        this.msgnotification = '';
        this.newnotifybtn = true;
        this.totalnotification = this.getallArray.length;
        this.getallbudgescount();
        this.addItems("push");
        this.cd.detectChanges();
      }
      else {
        this.getallArray = [];
        this.msgnotification = 'No notifications';
      }
    })
  }

  readmessage(data) {

    let readmsg = {
      dbnid: JSON.stringify(data.dbnid),
      wsoid: Number(this.workSpaceId),
      modifiedBy: this.createdBy
    }
    this.signUpService.readmessage(readmsg).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.getallbudgescount();
        this.getallafterread();
      }
    })
  }
  getallafterread() {
    let notification = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: "",
      notificationTo: this.createdBy,
      databaseName: "",
      createdBy: this.createdBy
    }
    this.totalnotification = '0';
    this.signUpService.getallnotification(notification).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {

        for (let i = 0; i <= response.data.length - 1; i++) {
          response.data[i].datetime = new Date(response.data[i].date)
        }
        this.getallArray = response.data;
        const readAry = this.getallArray.filter(el => el['isRead'] === false).sort((a, b) => {
          return b['datetime'] - a['datetime']
        })

        const unReadAry = this.getallArray.filter(el => el['isRead'] === true).sort((a, b) => {
          return b['datetime'] - a['datetime']
        })
        this.getallArray = [...readAry, ...unReadAry]
        this.addItems("push");
        this.newnotifybtn = true;
        this.totalnotification = this.getallArray.length;
        this.cd.detectChanges();
      }
    })
  }

  appendItems() {
    this.addItems("push");
  }

  prependItems() {
    this.addItems("unshift");
  }

  listArray = [];
  sum = 50;
  direction = "";
  addItems(_method: string) {
    let pushValue = this.getallArray.slice(0, this.listArray.length + this.sum)
    this.listArray = pushValue
  }

  dbnidread = [];
  allreadmessage() {

    this.dbnidread = [];

    for (let i = 0; i <= this.getallArray.length - 1; i++) {
      if (!this.getallArray[i].isRead) {
        this.dbnidread.push(this.getallArray[i].dbnid)
      }
    }

    let readmsg = {
      dbnid: this.dbnidread.join(),
      wsoid: Number(this.workSpaceId),
      modifiedBy: this.createdBy
    }

    this.signUpService.readmessage(readmsg).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.getallbudgescount();
        this.getallnotification();
      }
    })
  }
  wsSubscriptionRemainDaysCount: number;
  private wsSubscriptionDetails() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "WSLink": arr[2],
      "Wsoid": Number(this.workSpaceId)
    }
    this.data.wsSubscriptionDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      this.wsSubscriptionRemainDaysCount = response.data[0].subscriptionRemainingDaysCount;
      localStorage.setItem('currency', response.data[0].currency)
    }
    )
  }



  undobtnshow: any;
  checkundobtn = false;
  onebyonedelete(data) {

    let onedelete = {
      dbnid: JSON.stringify(data.dbnid),
      wsoid: Number(this.workSpaceId),
      modifiedBy: this.createdBy,
      flag: "delete"
    }
    localStorage.setItem('deleteddbnid', JSON.stringify(data.dbnid))
    this.checkundobtn = true;
    this.signUpService.onebyonedelete(onedelete).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.undobtnshow = localStorage.getItem('deleteddbnid')
        this.getallbudgescount();
        this.getallnotification();
        setTimeout(() => {
          this.checkundobtn = false;
        }, 15000);
      }
    })
  }
  imageUrl: any;

  notifyFuncations: any = [
    { title: "Mark as read", icon: "done-all-outline", id: "MarkASREAD" },
    { title: "Delete", icon: "trash-2-outline", id: "DELETE" },
  ];
  selectall() {

    for (let i = 0; i <= this.getallArray.length - 1; i++) {
      let selectbodydata = i + 'selectbody';
      document.getElementById(`${selectbodydata}`).style.backgroundColor = "#D5E5F0";
    }
  }

  async connectiongenrate() {
    console.log("connection generate called")
    console.log("this.docGuid",this.docGuid)
    try {
      this._hubConnecton = new mdl.HubConnectionBuilder().withUrl(environment.baseUrl + '/notificationhub')
        .withAutomaticReconnect().configureLogging(mdl.LogLevel.Information).build();
      Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
      await this._hubConnecton.start();
      await this._hubConnecton.invoke("GetConnectionId");
      const connectionId = await this._hubConnecton.invoke("GetConnectionId");
      this._hubConnecton.onreconnected(connectionId => {
        const reconnectionid = connectionId;
        sessionStorage.setItem("KeyId", reconnectionid);
        this.workSpaceId = localStorage.getItem("localwsId");
        this.createdBy = localStorage.getItem("localloginId");
        this.rolename = localStorage.getItem("rolevalue");
        this.groupname = localStorage.getItem("groupnamevalue");
        if ((this.workSpaceId != '0') && (this.workSpaceId != null) && (this.workSpaceId != '')) {
          let notify = {
            wsoid: Number(this.workSpaceId),
            key: reconnectionid,
            userEmailID: this.createdBy,
            role: this.rolename,
            groupName: this.groupname,
            databaseName: "",
            createdBy: this.createdBy,
          };
          this.signInService.notifysignin(notify).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
            if (response.isSuccess) {
              if (response.statusCode == "C200") {
              }
            }
          });
        }
      });
      this._hubConnecton.on("tosterpvtnotification", (m) => {
        console.log(m)
        this.msg = m;
        this.showSuccess(this.msg);
        localStorage.setItem('newnotify', 'message');
      });
      this._hubConnecton.on("RealTimeDeletionDocument", (m) => {
        console.log(m)
        this.result = m?.split("|");
        let getdocDeletion = this.result[2];

        if (this.docGuid == getdocDeletion) {
          this.openTemplateverify(this.accountdisabledverify);
        }
      });
      this._hubConnecton.on("modalpvtnotification", (m) => {
        console.log(m)
        if(m.includes('SendingDocSharedRealTimeNotification')){
          console.log("Real Time Notifications Document is shared with Guest", m);
          this.docSharedRealTimeNotifyBody=m
          this.docSharedRealTimeNotifyBody=this.docSharedRealTimeNotifyBody.replace('SendingDocSharedRealTimeNotification','')
          this.docSharedRealTimeNotifyFooter=""+new Date()+""
          this.openRealTimeDocShareNotification();
        }
        else if(m.includes('shareWithNoOne') || m.includes('documentDeletedRealTimeNotify'))
        {
            

            if(m.includes('shareWithNoOne'))
            {
              let splitStr=m.split('|')
              let docGuid=splitStr[1].split(',')[1]
              console.log("this.docGuid",this.docGuid)

                if (this.docGuid == docGuid) {
                  
                  //this.router.navigate(['/pages/documents/shared-with-me']);
                  console.log('opening modal for access revoked')
                  this.openModal(this.saveRightsChange, false);
                }
            }
            

            if(m.includes('documentDeletedRealTimeNotify'))
            {
              let stval = window.location.toString();
              let arr = stval?.split("/");
              this.urlchange = arr[0] + "//" + arr[2] + "/pages/documents/trash";
              if (m.includes('doc has been moved to your Trash')) {
                this.ownerTrashValue = true;
              }
                let splitStr=m.split('|')
                let docGuid=splitStr[1].split(',')[1]
                console.log("this.docGuid",this.docGuid)
                if (this.docGuid == docGuid)
                {
                  console.log('Document Deleted Notify Called')
                  this.deleteDocModalHeading=splitStr[2];
                  this.deleteDocModalMsg=splitStr[3];
                  this.openTemplateverify(this.accountdisabledverify);
                }

                
                
            }

        }else{

              let directLogOutSignalR:string[]=[];
              let tempHubMessageArray =m?.split("|");

              for(let i =0;i<tempHubMessageArray.length;i++){
                if (
                  tempHubMessageArray[i].toLowerCase() === "account disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "account deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "ws suspended"
                ) {
                  directLogOutSignalR.push(tempHubMessageArray[i]);
                }
                else{
                  this.result.push(tempHubMessageArray[i]);
                }
              }
              if (directLogOutSignalR.length>0){
                this.accoutClose(directLogOutSignalR[0]);
              }
              else{
                this.openTemplate(this.accountdisabled);
              }


              for(let i =0;i<tempHubMessageArray.length;i++){
                if (
                  tempHubMessageArray[i].toLowerCase() === "account disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "account deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "ws suspended"
                ) {
                  directLogOutSignalR.push(tempHubMessageArray[i]);
                }
                else{
                  this.result.push(tempHubMessageArray[i]);
                }
              }
              if (directLogOutSignalR.length>0){
                this.accoutClose(directLogOutSignalR[0]);
              }
              else{
                this.openTemplate(this.accountdisabled);
              }




        }

       
      });

      this._hubConnecton.on('modalguestKeynotification', m => {
        console.log(m)
        this.result = m?.split('|');
        this.sharedService.sharedWithGuestIcon.next(this.result);
        if (this.docGuid === this.result[0]) {
          if (this.result[1] > 0) {
            this.showBanner = true;
          } else {
            this.showBanner = false;
          }
        }


      });

      this._hubConnecton.on("ParserApi", (m) => {
        this.msg = m;
        let getdocparserid = this.msg?.split('|');
        if (this.docGuid == getdocparserid[0]) {
          this.show_status=true
          this.check_parser_status(this.msg)
          this.onlystatuschange(this.verifydocid)
        }
      });
      this._hubConnecton.on("ReParserApi", (m) => {
        this.parserflag = localStorage.getItem('parserflag');
        if (this.parserflag == 'True') {
          this.msg = m;


          if (this.docGuid == this.msg) {

            this.onlystatuschange(this.verifydocid);
          }
        }


      });
      sessionStorage.setItem("KeyId", connectionId);
      this.workSpaceId = localStorage.getItem('localwsId')
      this.createdBy = localStorage.getItem('localloginId')
      this.rolename = localStorage.getItem('rolevalue')
      this.groupname = localStorage.getItem('groupnamevalue')

      let notify = {
        wsoid: Number(this.workSpaceId),
        key: connectionId,
        userEmailID: this.createdBy,
        role: this.rolename,
        groupName: this.groupname,
        databaseName: "",
        createdBy: this.createdBy,
      };
      this.signInService.notifysignin(notify).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
        if (response.isSuccess) {
          if (response.statusCode == "C200") {
          }
        }
      });
    } catch (err) {
      console.warn(err);
    }
  }

  @ViewChild("accountdisabled") accountdisabled: TemplateRef<any>;
  @ViewChild("accountdisabledverify") accountdisabledverify: TemplateRef<any>;
  @ViewChild("docSharedNotify") docSharedNotify: TemplateRef<any>;
  docSharedNotifyRef:any;
  public openTemplate(Accountdisabled: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(this.accountdisabled, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }
  public openTemplateverify(accountdisabledverify: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(this.accountdisabledverify, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  public accoutClose(str, newUrl?) {
    this.dialogRef.close();
    str = str?.toString()?.toLowerCase();
    let logoinIdCookie = 'localloginId' + localStorage.getItem('localwsId')
    let wsIdCookie = 'localwsId' + localStorage.getItem('localwsId')
    if (newUrl) {
      window.location.href = newUrl;
    }else{
    if (str == 'ws plan changed') {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(["/sign-in"]);
    }
    else if (str == "account disabled" || str == "disabled") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("signalRMsg",'userDisabledSR');
      this.router.navigate(["/onboarding/account-disable"]);
    }
    else if (str == 'account deleted' || str == "deleted") {
      this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("signalRMsg",'userDeletedSR');
      this.router.navigate(["/onboarding/account-deleted"]);
    }
      else if (str == "resetpassword") {
        this.router.navigate(["/onboarding/change-password"]);
      }
      else if (str == "workspace deleted") {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/onboarding/deleted"]);
      }
      else if (str == "workspace disabled") {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("signalRMsg",'workSpaceDisabledSR');
        this.router.navigate(["/onboarding/disabled"]);
      }
      else if (str == "ws suspended") {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        let loginUser = localStorage.getItem('localloginId')
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('localloginId',loginUser);
        localStorage.setItem("signalRMsg",'workSpaceSuspendedSR');
        this.router.navigate(["/onboarding/suspended"]);
      }
      else if (
        str == "modified" ||
        str == "role deleted" ||
        str == "transfer ownership" ||
        str == "role changed" ||
        str == "view-document" ||
        str == "verify-document" ||
        str == "edit-document"
      ) {
        window.location.href = '/pages/dashboard';
        console.log("dashboard true 5767")
        this.dialogRef.close();
      }
      else {
        window.location.href = '/pages/dashboard';
        console.log("dashboard true 5772")
        this.dialogRef.close();
      }

    }
  }

  public accoutCloserealdelete(str, newUrl) {

    window.location.href = '/pages/dashboard';
    console.log("dashboard true 5782")
    this.dialogRef.close();

  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }

  onRedirectTrash(url) {
    this.router.navigate(['/pages/documents/trash']);
  }

  onlystatuschange(id) {
    this.verifydocid = id;
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wsoid": Number(this.workSpaceId),
      "DocID": Number(id),
      "DocGuid": this.docGuid,
      "currentUser": this.createdBy
    }

    this.language = '';
    this.extensionformat = '';
    this.labellanguge = '';
    this.viewextractdata = '';
    this.mergelanguage = '';
    this.parserautodetecterror = '';
    this.uploadpath = '';
    this.viewautodetect = '';
    this.doccardlanguage = '';
    this.downloadDoc = [];
    this.searchabledocverify = true;
    this.withoutsearchdoc = true;
    this.withsearchdoc = false;
    this.withoutsearchdoceditable = true;
    this.searchabledoccheck = true;
    this.languageviewext = [];
    this.extractedstatus = '';
    this.notShowValue = '';
    this.onChangeTab('ORIGINAL DOC');

    this.pendingRequest = this.data.verifierDocDetailsService(postData).pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    ).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sharedService.isShare.next(response?.data.docverificationdetailslists[0].isAllUserShare);

        this.isCheckedAllUsers = response?.data.docverificationdetailslists[0].isAllUserShare;
        this.extensionformat = response.data.docverificationdetailslists[0].extension.toLowerCase();
        this.uploadpath = response.data.docverificationdetailslists[0].uploadPath;
        this.iseditableDocument = response.data.docverificationdetailslists[0].iseditable;
        this.docIdData = response.data.docverificationdetailslists[0].docId;
        this.unparserDocData = response.data.docverificationdetailslists[0].parserapirequest;
        this.ConfidenceValue = response.data.docverificationdetailslists[0].confidenceValue;
        if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
          || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
          || (this.extensionformat.toLowerCase() == 'odt')
        ) {
          this.notShowValue = 'Not_Show';
        }
        else {
          if (this.iseditableDocument === 1) {
            this.notShowValue = 'Not_Show';
          }
          else {
            this.notShowValue = '';
          }
        }
        if (this.parserflagdata == 'True') {
          this.defultlanguage = response.data.docverificationdetailslists[0].language;
          this.language = response.data.docverificationdetailslists[0].language;
          if (this.language != 'Autodetect') {
            /*
            *In the edittable document format always show language detected
            *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
            *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
            */
            if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
              || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
              || (this.extensionformat.toLowerCase() == 'odt')
            ) {
              this.parserautolanguage = response.data.docverificationdetailslists[0].parserlanguage;

              this.labellanguge = 'Language(s)';


              let getparserlanguage = response.data.docverificationdetailslists[0].parserlanguage?.split(',');
              if (getparserlanguage[0]) {
                this.mergelanguage = getparserlanguage[0];
                this.languageviewext.push(getparserlanguage[0])
              }
              if (getparserlanguage[0] && getparserlanguage[1]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];
                this.languageviewext.push(getparserlanguage[1])
              }
              if (getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];
                this.languageviewext.push(getparserlanguage[3])
              }
              this.viewextractdata = 'View Extracted Text';
              this.parserautodetect = this.mergelanguage;
              this.doccardlanguage = this.mergelanguage;
              this.manuallanguage = '';
              this.showdetectlang = true;
              this.showtetectlangs = true;
            }



            else {
              this.parserautolanguage = "";
              this.manuallanguage = response.data.docverificationdetailslists[0].language;
              this.labellanguge = 'Language';
              this.languageviewext.push(response.data.docverificationdetailslists[0].language)
              this.mergelanguage = response.data.docverificationdetailslists[0].language;
              this.viewextractdata = 'View Extracted Text / Change Language';
              this.parserautodetect = 'Processing';
              this.doccardlanguage = response.data.docverificationdetailslists[0].language;
              this.showtetectlangs = false;
            }

          }
          else {
            /*
               *parser status related changes
           */
            this.parserautolanguage = response.data.docverificationdetailslists[0].parserlanguage;
            this.manuallanguage = '';
            this.labellanguge = 'Language(s)';
            if (this.parserautolanguage) {

              let getparserlanguage = response.data.docverificationdetailslists[0].parserlanguage?.split(',');
              if (getparserlanguage[0]) {
                this.mergelanguage = getparserlanguage[0];
                this.languageviewext.push(getparserlanguage[0])
              }
              if (getparserlanguage[0] && getparserlanguage[1]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1];
                this.languageviewext.push(getparserlanguage[1])
              }
              if (getparserlanguage[0] && getparserlanguage[1] && getparserlanguage[2]) {
                this.mergelanguage = getparserlanguage[0] + ',' + getparserlanguage[1] + ',' + getparserlanguage[2];
                this.languageviewext.push(getparserlanguage[3])
              }

              this.parserautodetect = this.mergelanguage
              this.manuallanguage = '';
              this.doccardlanguage = this.mergelanguage;
            }
            else {
              this.parserautodetect = 'Processing';
              this.manuallanguage = '';
              this.doccardlanguage = 'Processing';
            }



            /*
              *In the edittable document format always show language detected
              *Edittable doc(TXT, DOC, DOCX, RTF, ODT)
              *Non-Edittable doc(PNG, JPEG, JPG, BMP, GIF, PDF, TIF, TIFF)
              */
            if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
              || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
              || (this.extensionformat.toLowerCase() == 'odt')
            ) {

              this.viewextractdata = 'View Extracted Text';
            }
            else {

              this.viewextractdata = 'View Extracted Text / Change Language';
            }

          }
          if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
            this.parserstatus = 'Success';
            this.parserautodetect = this.mergelanguage
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_07')
            || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_15') || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_14')) {
              this.parserstatus = 'Failure';
              this.parserstatus = 'Not Extracted'
              this.extractedstatus = 'Extraction In Progress';
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_00') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_03') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_18') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_24') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_25') ||
            (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_27')) {
            this.parserstatus = 'Failure';
          }
          else if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_23') {
            this.parserstatus = 'Failure';
            this.parserautodetecterror = 'Can not parse';
          }
          else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_04')|| (response.data.docverificationdetailslists[0].parserstatuscode =='doc_01')) {
            this.parserstatus = 'in process'
          }
          else if (response.data.docverificationdetailslists[0].parserstatuscode == '') {
            if (this.unparserDocData == '1') {
              this.parserstatus = 'In queue'
            }
            else {
              this.parserstatus = 'Not Extracted'
            }
            this.extractedstatus = 'Extraction Initiated';
          }

        }
        else {
          if ((this.extensionformat.toLowerCase() == 'txt') || (this.extensionformat.toLowerCase() == 'doc')
            || (this.extensionformat.toLowerCase() == 'docx') || (this.extensionformat.toLowerCase() == 'rtf')
            || (this.extensionformat.toLowerCase() == 'odt')
          ) {
            if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
              this.parserstatus = 'Success';
              this.parserautodetect = this.mergelanguage
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_07')
              || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_15') || (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_14')) {
                this.parserstatus = 'Not Extracted'
                this.extractedstatus = 'Extraction In Progress';
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_00') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_03') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_18') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_24') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_25') ||
              (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_27')) {
              this.parserstatus = 'Failure';
            }
            else if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_23') {
              this.parserstatus = 'Failure';
              this.parserautodetecterror = 'Can not parse';
            }
            else if ((response.data.docverificationdetailslists[0].parserstatuscode == 'doc_04')|| (response.data.docverificationdetailslists[0].parserstatuscode =='doc_01')) {
              this.parserstatus = 'in process'
            }
            else if (response.data.docverificationdetailslists[0].parserstatuscode == '') {
              if (this.unparserDocData == '1') {
                this.parserstatus = 'In queue'
              }
              else {
                this.parserstatus = 'Not Extracted'
              }
              this.extractedstatus = 'Extraction Initiated';
            }

          }
        }

        this.docDetails = response.data.docverificationdetailslists;
        this.downloadDoc.push(response.data.docverificationdetailslists[0]);


        /*
        * select the default lanuage
         */
        for (let user of this.rightLanguageList) {
          if (user.languageName == response.data.docverificationdetailslists[0].language) {
            this.selectedlanguage = user.languageName;
            this.selectedlanguagecheck = user.languageName;
            this.onSelectLanguage(this.selectedlanguage);
          } else {
            this.selectedlanguage = response.data.docverificationdetailslists[0].language
            this.selectedlanguagecheck = response.data.docverificationdetailslists[0].language;
            this.onSelectLanguage(this.selectedlanguage);
          }
        }
        if (response.data.docverificationdetailslists[0].language == "Autodetect") {
          this.langs = response.data.docverificationdetailslists[0].parserlanguage?.split(',')

          this.selectedlanguage = response.data.docverificationdetailslists[0].parserlanguage
          this.selectedlanguage = 'Autodetect'
          this.onSelectLanguage(this.selectedlanguage)
          this.showautodetect = true
          this.showtetectlangs = true
          this.showenglish = false;
          this.showdetectlang = true;

          /*
             *this is for the view page in the autodected
          */
          if (this.langs.length > 1) {
            this.viewautodetect = true;
          }
          else {
            this.viewautodetect = false;
          }

        } if (response.data.docverificationdetailslists[0].parserlanguage == "english" ||
          response.data.docverificationdetailslists[0].parserlanguage !== "english" ||
          response.data.docverificationdetailslists[0].parserlanguage == " "
        ) {
          this.showautodetect = false;
          this.showenglish = true;
          this.showdetectlang = false

        }

        /*
              *this is for the view page in the autodected
           */
        if (this.langs.length > 1) {
          this.viewautodetect = true;
        }
        else {
          this.viewautodetect = false;
        }

        /*
            *this is for the view page in the manual language
       */
        if (response.data.docverificationdetailslists[0].language != "Autodetect") {

          this.viewautodetect = false;

        }

        this.docFormat = this.docDetails[0].extension;
        if (this.docFormat.toUpperCase() === 'DOC' ||
          this.docFormat.toUpperCase() === "DOCX" || this.docFormat.toUpperCase() === 'ODT' ||
          this.docFormat.toUpperCase() === 'TXT' || this.docFormat.toUpperCase() === 'RTF') {
          this.showTextLayers = true;
          this.showSearch = true;
          if (this.parserflagdata == 'True') {
            if (this.iseditableDocument == 1) {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
            else {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
          }
          else {
            this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
            if (this.docdatafirsttab) {
              this.withoutsearchdoc = false;
              this.withsearchdoc = false;
              this.withoutsearchdoceditable = true;
              this.searchabledoccheck = false;
              this.activetabfirst = true;
              this.activetabsecond = false;
            }
            this.cd.detectChanges();
          }
        } else {
          if (this.parserflagdata == 'True') {
            if (this.iseditableDocument == 1) {
              this.docdatafirsttab = "ORIGINAL (SEARCHABLE) DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = false;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = true;
                this.searchabledoccheck = false;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
            else {
              this.showTextLayers = false;
              this.showSearch = false;
              this.docdatafirsttab = "ORIGINAL DOC";
              if (this.docdatafirsttab) {
                this.withoutsearchdoc = true;
                this.withsearchdoc = false;
                this.withoutsearchdoceditable = false;
                this.searchabledoccheck = true;
                this.activetabfirst = true;
                this.activetabsecond = false;
              }
              this.cd.detectChanges();
            }
          }
          else {
            this.showTextLayers = false;
            this.showSearch = false;
            this.docdatafirsttab = "ORIGINAL DOC";
            if (this.docdatafirsttab) {
              this.withoutsearchdoc = true;
              this.withsearchdoc = false;
              this.withoutsearchdoceditable = false;
              this.searchabledoccheck = true;
              this.activetabfirst = true;
              this.activetabsecond = false;
            }
            this.cd.detectChanges();
          }
        }

        if (this.searchabledoccheck && this.parserflagdata == 'True') {
          this.showSearchablepdf = true;
        }
        else {
          this.showSearchablepdf = false;
        }


        if (this.docDetails[0].pageCount > 1) {
          this.sidebarOpen = true;
        } else {
          this.sidebarOpen = false;
        }
        let splitConvertedPath = response.data.docverificationdetailslists[0].convertedPath?.split('\\').length;

        let originalFileNameDocsConverted = splitConvertedPath === 3 ? response.data.docverificationdetailslists[0].convertedPath?.split('\\')[2] : response.data.docverificationdetailslists[0].convertedPath?.split('\\')[3];

        if (this.parserflagdata == 'True') {
          if (response.data.docverificationdetailslists[0].running_module_code == 'm_14' ||
            response.data.docverificationdetailslists[0].running_module_code == 'm_15') {

          }
          else {

            if (response.data.docverificationdetailslists[0].parserstatuscode == 'doc_08') {
              this.searchablepdfFileConversion(originalFileNameDocsConverted);
              this.searchabledocverify = false;
            }
          }
        }
        else {

        }

        // if(this.ownerEmailID != this.verifierEmailID){
        this.shareUsersEventbydefault()
        // }

      }
    }, error => {

      this.errormsg();
      // this.router.navigate(['/error/error-page']);

      this.showSpinner = false;
    })
  }
  /*
            *verification doc name edit icon
  */
  editverifydocname = false;
  editverificationdocname() {
    this.editverifydocname = true;
    this.showError = false;
    this.showowner = false;
    this.errormessageblank = false;
    this.docmodifiednameinputbox = this.docModifiedEditedName;
    this.isDocDuplicateName = false;
    this.valuecheckdulicate = false;
    this.disableVerifyDueToNameLength = false;
  }

  isDownloadModalOperationInProgress=false;
  disabledownload=()=>{
    this.isDownloadModalOperationInProgress=true;
    setTimeout(() => {
      this.isDownloadModalOperationInProgress=false;
    }, 1500);
  }
  isGuestModalOperationInProgress=false;
  disableGuest=()=>{
    this.isGuestModalOperationInProgress=true;
    setTimeout(() => {
      this.isGuestModalOperationInProgress=false;
    }, 300);
  }
  isShareModalOperationInProgress=false;
  disableShare=()=>{
    this.isShareModalOperationInProgress=true;
    setTimeout(() => {
      this.isShareModalOperationInProgress=false;
    }, 700);
  }

  /*
  *verification doc name check icon
  */
  editmodevalidation = false;
  errormessageblank = false;
  blankview1 = ""
  editverifytemdocname() {
    if (this.docmodifiednameinputbox) {
      this.disableSave = false;
      // this.invalidCharacters = false;
      this.editverifydocname = false;
      this.inputverifyname = false;
      this.errormessageblank = false;
      this.isDocDuplicateName = false;
      this.docModifiedEditedName = this.docmodifiednameinputbox;
      this.blankview1 = document.getElementById("blankviewoutside")?.id
      this.docsVerification.value.docsName = this.docModifiedEditedName;
      if (this.docModifiedEditedName != this.docModifiedSystemName) {
        if (this.docmodifiednameinputboxduplicate != this.docmodifiednameinputbox) {
          this.editmodevalidation = true;
        }
      }
      let valchk = this.docsVerification.controls.docsName.value
      if (valchk.length < 40) {
        this.verifyerrormorecharbtn = false;
      }
    }


    if (this.docmodifiednameinputbox == "") {
      this.blankview1 = "blank";
      this.errormessageblank = true;
      this.docsVerification.value.docsName = this.docmodifiednameinputbox;
      this.docsVerification = this.fb.group({
        docsName: [this.docmodifiednameinputbox, [Validators.maxLength(100), Validators.required, Validators.minLength(1)]],
        customform: this.fb.array([]),
        textNote: new FormControl(''),
      })

      if (!this.docsVerification.valid) {
        this.errormessageblank = true;
      }
    }
  }



  /*
                   *verification doc name input box
  */
  inputverifyname = false;
  userclickinput = '';
  docrenamevalue(event) {
    this.inputverifyname = true;
    this.userclickinput = event;
  }

  getinputownerclick = '';
  docownercheck(event) {

    this.getinputownerclick = event;
  }
  showdetailspopover = false;
  showdetailspopoverview = false;
  detailsview1 = '';
  detailsdata() {
    this.showdetailspopover = !this.showdetailspopover;
    this.showdetailspopoverview = !this.showdetailspopoverview;
    this.detailsview1 = document.getElementById("detaisviewoutside").id
  }






    /*
    *for real time updation
    */



    getviewdata = [];
    docExtractedData = "";
    dialogReftextextract;
    upgradplanview;
    tryagainbtn = true;
    isExtractClick = false;
    size=0;
    isExtractAvailable:boolean=false;
    viewextract(value?) {
      this.isExtractClick = value;

      if (this.ViewExtractedTextFeatureFlag != 'True') {

        this.isExtractAvailable=true;
       // this.openUpgradeModal();
      }
      else {
        if(this.isExtractClick){
          this.dialogReftextextract = this.dialogService.open(this.viewextractedtext,{closeOnBackdropClick:false,closeOnEsc:false});
        }

        let viewchange = {
          wsoid: Number(this.workSpaceId),
          docGUID: this.docGuid,
          emailID: this.createdBy
        }
        this.getviewdata = [];
        this.tryagainbtn = true;
        this.showSpinnertextext = true;
        this.pendingRequest = this.data.viewchangetext(viewchange).pipe(
        catchError((error) => {
          this.logger.error(error);
          return of(`Error: ${error}`);
        })
      ).subscribe((response: any) => {
          if (response.isSuccess) {
            this.getviewdata = response.data
            this.getviewdata = this.getviewdata.slice(0, -1)

            // const ary = [{ "work_NO": "1" }, { "work_NO": "2" }, { "work_NO": "2" }]
            const sortArray = this.getviewdata.sort((a, b) => {
              return Number(a['pagE_NUMBER']) - Number(b['pagE_NUMBER'])
            })

            this.getviewdata = sortArray;
            this.size = this.getviewdata.length - 1;
            for (let i = 0; i <= this.size; i++) {
              this.getviewdata[i].pageno = i + 1
              // this.docExtractedData += 'PageNo: ' + `${this.getviewdata[i].pageno}` +"\n";
              this.docExtractedData += `PageNo: ${this.getviewdata[i].pageno} \n`;
              this.docExtractedData += this.getviewdata[i].extracteD_TEXT;

              if (this.getviewdata[i].extracteD_TEXT == "doc_12" || this.getviewdata[i].extracteD_TEXT == "doc_00" || this.getviewdata[i].extracteD_TEXT == " \n\f") {
                this.getviewdata[i].extracteD_TEXT = "Text from this page either could not be extracted or the page was found blank."
              }

            }
            this.showSpinnertextext = false;
          }
          else {
            this.showSpinnertextext = false;
          }
        })
      }

    }

    copyText(){
      this.clipboard.copy(this.docExtractedData);
    }

    disabledDownloadText= false;
    downloadExtractedtext(){
      this.disabledDownloadText = true;
      let file = new Blob([this.docExtractedData], { type: "text/plain" });
          FileSaver.saveAs(
            file,
            this.renamedFileName.split(".")[0] + ".txt");
            this.disabledDownloadText = false;
    }


    /*getting all la
    */

    documentLanguagereparse;

    onLanguageChange($event) {
      if (this.radiobtndata == 'ManualLanguage') {
        this.documentLanguagereparse = $event.languageName;
        this.btnDisable = false;
      }
      this.islanuagesCheck()

      let value = this.languageviewext.indexOf(this.selectedlanguage)
      if (value !== -1) {
        this.tryagainbtn = true;
      }
      else {
        this.tryagainbtn = false;
      }

    }





    islanuagesCheck() {
      let isstringcontent: boolean = false;
      for (var i = 0; i <= this.langs.length; i++) {

        isstringcontent = this.selectedlanguage.toLowerCase().includes(this.langs[i])
        if (isstringcontent == true) {
          i = this.langs.length + 1
        }

      }

      if (isstringcontent == true) {
        this.btnDisable = true;
      } else {
        this.btnDisable = false;
      }

    }


    reparsing() {
      this.dialogRef = this.dialogService.open(this.reparsingsecond);

    }

    confirmreparse() {

      let getpathupload = this.uploadpath?.split('RandomDocUpload');
      let getuploadsec = getpathupload[1]?.split('.');
      let reparsedata = {
        "wsoid": Number(this.workSpaceId),
        "createdBy": this.createdBy,
        "language": this.documentLanguagereparse,
        "docId": Number(this.verifydocid),
        "documentOwner": localStorage.getItem('noteuserfullname'),
        "docRename": this.docModifiedEditedName + '.' + getuploadsec[1],
        "DocGUID": this.docGuid,
        "FileExtension": getuploadsec[1],
      }
      this.dialogReftextextract.close();
      this.dialogRef.close();
      this.spinnereparse = true;
      this.pendingRequest = this.data.confirmreparse(reparsedata).pipe(
        catchError((error) => {
          this.logger.error(error);
          return of(`Error: ${error}`);
        })
      ).subscribe((response: any) => {
        if (response.isSuccess) {
          this.toastrService.primary("Text extraction restarted.");
          this.spinnereparse = false;
        }
      })

    }

    msgdata;
    e: any;



    eventHandler(data) {
      this.disableSave = false;
      this.errormessageblank = false;
      var k;
      k = data;
      // return ((k > 64 && k < 91)  || (k > 96 && k < 123) || k == 8 || k == 32 || k == 40 || k == 41  || k == 95 || k == 45 || (k >= 48 && k <= 57));
      return true;
    }

    customedropdowntag(data, id) {
      for (let i = 0; i <= this.templateInput.length - 1; i++) {
        if (this.templateInput[i].id == id) {
          this.templateInput[i].tagTempValue = data;
        }
      }
      for (let i in this.templateInput) {
        if (this.templateInput[i]['tagvaldata'] == "") {
          this.templateInput[i]['errormsg'] = "Error";
        }
        else {
          this.templateInput[i]['errormsg'] = "";
        }
      }

    }

    dropdownchange() {
      this.isDocEdited = true;
      this.isTagEdited = true;
      this.disableSave = false;
    }

    editvalue(data, id) {

      for (let i = 0; i <= this.docTagList.length - 1; i++) {
        if (this.docTagList[i].id == id) {
          this.docTagList[i].tagValue = data;
        }
      }
    }
    /**
     * method for redirecting to dashboard
     */
    public redirctToView4() {
      if (((this.checkAnyChangeInEdit() || this.isDocEdited) && this.isEdit && this.editrights)) {
        // this.openModal(this.discardChanges, false);
        this.dialogRef = this.dialogService.open(this.discardChanges, {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        });
      }
      else {
        if (this.editClicked) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
          );
          window.open(url, "_self");
        }
        else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/pages/dashboard'])
          );
          console.log("dashboard true 6592")
          window.open(url, "_self");
        }

      }


    }
    public redirctToView() {
      if (((this.checkAnyChangeInEdit() || this.isDocEdited) && this.isEdit && this.editrights)) {
        // this.openModal(this.discardChanges, false);
        this.dialogRef = this.dialogService.open(this.discardChanges, {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        });
      }
      else {
        if (this.editClicked) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
          );
          window.open(url, "_self");
        }
        else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/pages/documents/owned-by-me'])
          );
          window.open(url, "_self");
        }

      }


    }
    public redirctToView3() {
      if (((this.checkAnyChangeInEdit() || this.isDocEdited) && this.isEdit && this.editrights)) {
        // this.openModal(this.discardChanges, false);
        this.dialogRef = this.dialogService.open(this.discardChanges, {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        });

      }
      else {
        if (this.editClicked) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
          );
          window.open(url, "_self");
        }
        else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['pages/search?q='])
          );
          window.open(url, "_self");
        }

      }


    }
    public redirctToView1() {
      if (((this.checkAnyChangeInEdit() || this.isDocEdited) && this.isEdit && this.editrights)) {
        this.openModal(this.discardChanges, false);
        this.dialogRef = this.dialogService.open(this.discardChanges, {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        });
      }
      else {
        if (this.editClicked) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
          );
          window.open(url, "_self");
        }
        else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/pages/documents/shared-with-me'])
          );
          window.open(url, "_self");
        }

      }


    }
    public redirctToView2() {
      if (((this.checkAnyChangeInEdit() || this.isDocEdited) && this.isEdit && this.editrights)) {
        // this.openModal(this.discardChanges, false);
        this.dialogRef = this.dialogService.open(this.discardChanges, {
          closeOnBackdropClick: false,
          closeOnEsc: false,

        });
      }
      else {
        if (this.editClicked) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['view/view-document'], { queryParams: { docId: this.docDetails[0].docId, docGuid: this.docDetails[0].docGuid } })
          );
          window.open(url, "_self");
        }
        else {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/pages/cabinet'])
          );
          window.open(url, "_self");
        }

      }


    }



    userSettings() {


      this.GetTimeZone(this.UserSetingModal);
      this.userSettingsRef = this.dialogService.open(this.UserSetingModal, { closeOnBackdropClick: false });

    }


    //*******************************change password Ts****************** */

    Setting() {

      this.dialogRef = this.dialogService.open(this.Password, { closeOnBackdropClick: false });
    }



    // toggle() {
    //   this.showProfile = !this.showProfile;
    //   this.hidenotify = true;

    // }



    onScrollDown(ev: any) {


      this.sum += 50;
      this.appendItems();

      this.direction = "scroll down";
    }





    showdata: { wsoid: number; emailId: any; };
    @ViewChild('miniprofile', { read: TemplateRef }) miniprofile: TemplateRef<any>;
    onCellClicked(data) {
      let minidata = {
        "wsoid": Number(this.workSpaceId),
        "emailId": this.createdBy,

      }

      this.showdata = minidata;

      this.dialogRef = this.dialogService.open(this.miniprofile);
    }
    wsSubscriptionPlan: any;
    loginProfile = true;
    isUnsavedChangePW: boolean;
    isUnsavedChangeNotify: boolean;
    croppedImage: string;
    notification: boolean = false;
    changePassword: boolean;
    userEditProfile(val?) {
      if (!this.isUnsavedChangePW && !this.isUnsavedChangeNotify) {
        this.notification = false;
        this.changePassword = false;
        this.loginProfile = true;
      }
      val === undefined ? this.sharedService.IsSidebarClicked.next('profile') : '';
      val === undefined ? this.sharedService.IsSidebarClickedNotify.next('profile') : '';
    }

    isUnsaved(event) {
      if (event === 'stay') {
        this.isUnsavedChangePW = true;
      }
      if (event === 'discard|notify' || event === 'discard|profile') {
        this.isUnsavedChangePW = false;
        event?.split('|')[1] === 'notify' ? this.userNotification('val') : this.userEditProfile('val');
      }
    }

    userNotification(val?) {
      if (!this.isUnsavedChangePW) {
        this.changePassword = false;
        this.notification = true;
        this.loginProfile = false;
      }
      val === undefined ? this.sharedService.IsSidebarClicked.next('notify') : '';
    }

    isUnsavedNotify(event) {
      if (event === 'stay') {
        this.isUnsavedChangeNotify = true;
      }
      if (event === 'discard|password' || event === 'discard|profile') {
        this.isUnsavedChangeNotify = false;
        event?.split('|')[1] === 'password' ? this.userchangePassword('val') : this.userEditProfile('val');
      }
    }
    @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>; // Note: TemplateRef
    userchangePassword(val?) {
      if (!this.userProfileForm.pristine) {
        this.openModal(this.unsavedChanges, false);
      }
      if (!this.isUnsavedChangeNotify && this.userProfileForm.pristine) {
        this.notification = false;
        this.changePassword = true;
        this.loginProfile = false;
      }
      val === undefined ? this.sharedService.IsSidebarClickedNotify.next('password') : '';
    }

    close() {
      this.isUnsavedChangePW = false;
      this.dialogRef.close();
    }

    openUpgradeModal() {
      this.upgradeChild.openUpgrade('use View Extracted Text & Searchable PDF.', 'EM-052');
    }

    iconImage: any;
    fileToUpload: any;
    iconImagesEnable: boolean = true;
    imageChangedEvent: any = '';
    removeIcon = true;
    imageNewURL: any;
    /**
      * image cropper code
      */
    imageStrem: any;
    fileErr = false;
    NoFile = false;
    fileExtError = false;
    showIcon = false;
    iconImagesDisabled = true;
    imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      let response = base64ToFile(event.base64);
      let file = new Blob([response], { type: 'application/octet-stream' });
      this.imageStrem = file;


    }

    fileSize: any
    uploadfilesize: boolean = false;
    handleFileInput(files) {
      if (files.currentTarget.files.length === 0) {
        return
      }
      this.imageChangedEvent = files;
      this.fileErr = false;
      this.iconImagesEnable = false;
      this.fileExtError = false
      this.removeIcon = false;
      if (files != null && files != "") {
        this.fileSize = files.target.files[0].size;
        this.fileSize = Math.round((this.fileSize / 1024));
        this.NoFile = false;
        var extension = files.target.files[0].name?.split('.')[1];
        if (this.fileSize > 100) {

          document.getElementById("fileExtError").style.display = 'none';
          document.getElementById("fileErr").style.display = 'block';
          this.NoFile = true;
          this.fileErr = true;
          this.showIcon = true;
          this.imageUrl = this.imageNewURL;
          return;
        }
        else if (extension.toLowerCase() != "png" && extension.toLowerCase() != "jpg" && extension.toLowerCase() != "jpeg") {

          document.getElementById("fileErr").style.display = 'none';
          this.NoFile = false;
          this.fileErr = false;


          document.getElementById("fileExtError").style.display = 'block';

          this.fileExtError = true;


          this.imageUrl = this.imageNewURL;
          return;
        }
        else {

          document.getElementById("fileErr").style.display = 'none';

          this.fileErr = false;
          this.NoFile = false;
          document.getElementById("fileExtError").style.display = 'none';

          this.iconImage = files.target.files[0].name;
          this.iconImagesEnable = false;
          this.fileToUpload = files.target.files.item(0);

          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
          };
          reader.readAsDataURL(this.fileToUpload);
          this.showIcon = true;
          this.iconImagesDisabled = false;

          const file = (files.target as HTMLInputElement).files[0];

          let image = files.target.files[0];
        }

      }
    }

    selectedItemNgModel: any;
    isSavedPressedEditor: boolean = false;
    removePressed: boolean;
    updateUserProfile(files, ref) {

      this.getguid = localStorage.getItem('loginguid')
      var st = window.location.toString();
      var arr = st?.split("/");

      let formData = new FormData();
      if ((this.userProfileForm.value.fullName == undefined) || (this.userProfileForm.value.fullName == null)) {
        this.userProfileForm.value.fullName = ""
      }

      if ((this.userProfileForm.value.displayName == undefined) || (this.userProfileForm.value.displayName == null)) {
        this.userProfileForm.value.displayName = ""
      }

      if ((this.userProfileForm.value.selectDisplayName == undefined) || (this.userProfileForm.value.selectDisplayName == null)) {
        this.userProfileForm.value.selectDisplayName = ""
      }

      if ((this.userProfileForm.value.whatIDo == undefined) || (this.userProfileForm.value.whatIDo == null)) {
        this.userProfileForm.value.whatIDo = ""
      }

      if ((this.userProfileForm.value.whereIAm == undefined) || (this.userProfileForm.value.whereIAm == null)) {
        this.userProfileForm.value.whereIAm = ""
      }

      if ((this.selectTime == undefined) || (this.selectTime == null)) {
        this.selectTime = ""
      }

      if ((this.userProfileForm.value.contactNumber == undefined) || (this.userProfileForm.value.contactNumber == null)) {
        this.userProfileForm.value.contactNumber = ""
      }

      if ((this.userProfileForm.value.timeZone == undefined) || (this.userProfileForm.value.timeZone == null)) {
        this.userProfileForm.value.timeZone = ""
      }
      localStorage.setItem('noteuserfullname', this.userProfileForm.value.fullName)

      formData.append("Wsoid", (this.workSpaceId));
      formData.append("WsLink", arr[2]);
      formData.append("ProfileImagePath", this.userProfileForm.value.iconImage);
      formData.append("FullName", this.userProfileForm.value.fullName);
      formData.append("DisplayName", this.userProfileForm.value.displayName);
      formData.append("WhatIdo", this.userProfileForm.value.whatIDo);
      formData.append("WhereIam", this.userProfileForm.value.whereIAm);
      formData.append("TimeZone", this.userProfileForm.value.timeZone);
      formData.append("ContactNo", this.userProfileForm.value.contactNumber);
      formData.append("ModifiedBy", this.createdBy);
      formData.append("guid", this.getguid);
      formData.append("fileIcon", this.imageStrem)
      formData.append("reset", !this.removePressed ? '1' : 'Reset');
      let headers = new HttpHeaders({
      });

      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      for (let file of files)
        formData.append(file.name, file);


      this.showSpinner = true;

      const uploadReq = new HttpRequest('POST', environment.baseUrl + `/api/UserSettings/UpdateUserProfileDetails`, formData, {
        reportProgress: true, headers: headers
      });
      this.http.request(uploadReq).pipe(
        catchError((error) => {
          this.logger.error(error);
          return of(`Error: ${error}`);
        })
      ).subscribe((event: any) => {
        if (event.type === HttpEventType.Response) {
          this.toastrService.primary('Profile updated', '',);
          setTimeout(() => {
            this.showSpinner = false;
          }, 1000);

          this.userProfileForm.reset();
          this.loginProfilImage = this.ediimageUrl
          this.GetProfileDetails(null);
          this.isSavedPressedEditor = true;
          setTimeout(() => {
            this.isSavedPressedEditor = false;
          }, 4000);
        }

      });
    }
       /**
    * variable error_msg
    */
    error_msg = false
       /**
    * variable show_status
    */
    show_status = false
     /**
    * check_parser_status
    */
    check_parser_status(message) {
      this.show_status=true
       /**
    * split and get code
    */
      let getrealdocdata = message?.split('|');
      let val= getrealdocdata[2]
       /**
    * check parser code  and impliment
    */
        if (val == ''||val == 'doc_01') {
          this.sentForExtraction = true
          this.load = true
        }
        else if (val == 'doc_02') {
          this.tick1 = true
          this.sentForExtraction = false
          this.extractionStarted = true
        }
        else if (val == 'doc_03'||val == 'doc_04'||val == 'doc_05') {
          this.tick1 = true
          this.tick2 = true
          this.sentForExtraction = false
          this.extractionStarted = false
          this.postProcessing = true
        }
        else if (val == 'doc_08') {
          this.tick1 = true
          this.tick2 = true
          this.tick3 = true
          this.sentForExtraction = false
          this.extractionStarted = false
          this.postProcessing = false
          this.show_status=false
        }
        else{
           /**
    * call error function
    */
          this.check_error(message)
          this.show_status=false
        }

    }
   show_msg
   /**
    * function check error for parsing error handler
    */
    check_error(message) {
       /**
    * initialize value
    */
      this.error_msg = true
      let getrealdocdata = message?.split('|');
      let msg= getrealdocdata[2]
       /**
    * check msg code
    */
      if (msg == 'doc_10') {
        this.show_msg = "Uh-oh! We ran into a problem and are retrying text extraction for this document"
      }
      else if (msg == 'doc_11') {
        this.show_msg = "Unsupported media type"
      }
      else if (msg == 'doc_12') {
        this.show_msg = "This document could not be extracted because the file size is too large. For additional assistance, contact support@dox2u.com"
      } else if (msg == 'doc_13') {
        this.show_msg = "This document could not be extracted because the dimensions of the file are too large. For additional assistance, contact support@dox2u.com"
      } else if (msg == 'doc_14') {
        this.show_msg = "There is no text to be extracted from this document"
      }
      else if (msg == 'doc_15') {
        this.show_msg = "Sorry, this is taking us longer than expected. We will get back to you soon."
      }
      else if (msg == 'doc_00') {
        this.show_msg = "Sorry, the text from this document could not be extracted"
      }
      else{
        this.sentForExtraction = true
        this.load = true
        this.show_status=true

      }
    }

  // new code
  viewerMenu(){
    this.showViewerMenu=!this.showViewerMenu
    }

    viewerTallyDMS(){
      this.viewertag=false
      this.viewerList=false
      this.viewerDoc=false
      this.viewerTally=true
      this.changebg = false;
      this.isDocketsPreview = false;
    }
    viewerTagPanel(){
  this.viewertag=true
  this.viewerDoc=false
  this.viewerList=false
  this.viewerTally=false
  this.changebg = false;
  this.isDocketsPreview = false;
    }
    viewerDocAiPanel(){
      this.viewerDoc=true
      this.viewertag=false
      this.viewerList=false
      this.viewerTally=false
      this.changebg = false;
      this.isDocketsPreview = false;
    }
    crosstagIDP() {
      this.viewerDoc = false;
    }
    viewerdList(){
      this.viewerList=true
      this.viewertag=false
      this.viewerDoc=false
      this.viewerTally=false
      this.isDocketsPreview = false;
    }

    viewDocketsList() {
      this.viewertag=false
      this.viewerList=false
      this.viewerDoc=false
      this.viewerTally=false;
      this.changebg = false;
      this.isDocketsPreview = true;
    }

    SaveSingleTagData() {
      this.openVairable= true
      if (this.getUserDataSingleTag || this.getUserDataSingleTag !== "") {
        let checkSpace = this.getUserDataSingleTag.split(" ");
        if (checkSpace.length > 1) {
          for (let i = 0; i < checkSpace.length; i++) {
            let spaceSeparatedTag = [checkSpace[i].trim()];
            this.singleTagsList = spaceSeparatedTag.concat(this.singleTagsList);
          }
        } else {
          let tagWithoutSpaces = [this.getUserDataSingleTag.trim()];
          this.singleTagsList = tagWithoutSpaces.concat(this.singleTagsList);
        }
        this.singleTagsList = [...new Set(this.singleTagsList)];
        // this.SaveTagVerifyData();
      }
      this.getUserDataSingleTag = "";
    }


    saveUserSingleTag(event?) {
      if (event && event?.target.value.trim() !== "") {
        this.singleTagFlag = true;
      }
      this.isInputEnterorSpace = event?.keyCode === 13 || event?.keyCode === 32;
      this.userInputSingleTag = event?.target.value.trim();
      let tagsInPastedText = this.getUserDataSingleTag?.split(" ");
      if (this.userInputSingleTag.length <= 15) {
        if (this.isInputEnterorSpace) {
          this.getUserDataSingleTag =
            this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
          // this.SaveSingleTagData();
        } else {
          this.getUserDataSingleTag =
            this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
        }
      } else if (this.isInputEnterorSpace) {
        // this.SaveSingleTagData();
      } else {
        event.preventDefault();
      }
    }
    removeChip(tag) {
      let index = this.singleTagsList.indexOf(tag);
      if (index !== -1) {
        this.singleTagsList.splice(index, 1);
        this.getUserDataSingleTag = "";
      }
      // this.SaveTagVerifyData();
    }

    addTAgValue(){
      this.blankState= true
    }
   /**
   * showoutprofile var
   */
    // showoutprofile = '';
    profiletogglefield='';
    /* toggle function
    */
     toggle() {
      this.showProfile = !this.showProfile;
      // this.hidenotify=true;
      // this.isMenu=false;
      //  this.showoutprofile = 'showoutdataprofile';
       this.profiletogglefield =  document.getElementById('toggleid').id
     }
     isMenu :boolean = false;
     itemValue(){
     this.isMenu = !this.isMenu;
    //  this.itemVAlueIdVariable= document.getElementById("itemValueId").id

     }

     //bulk tag code
     tagVerify(params?){
      this.tagPanelOpen = false;
      this.hidedetail = true;
      this.getIds();

    }

     getIds(){
      const ids=[]
      const docVerifierEmailId = [];
      const docOwnerEmailId = [];
      const selectedGuid =[];
      const uploadedByDisplayName =[];
      const extension = [];
      const docRenameList = [];
      let loggedInUser = localStorage.getItem('WsdisplayName');
      this.downloadDoc.forEach(obj=>{
        ids.push(obj.docId);
        docVerifierEmailId.push(obj.verifiedByEmailId);
        docOwnerEmailId.push(obj.docOwnerEmailId);
        selectedGuid.push(obj.docGuid);
        uploadedByDisplayName.push(loggedInUser);
        extension.push(obj.extension ?? obj.format);
        docRenameList.push(obj.neweDocRename ?? obj.docrename ?? obj.originalFileName?.split('.').slice(0, -1).join('.'));
    })
    localStorage.setItem('docId', ids.at(-1));
    this.bulkTagCom.getmethodValue(ids,docVerifierEmailId,docOwnerEmailId,selectedGuid , uploadedByDisplayName,"",extension, docRenameList)
    }

  /**
   * this method check the deletion reason selecetd by user in permanent download modal
   */

  valueChange = (reason: string):void => {
    if (reason === 'Other') {
      this.Otherreason = true;
    }
    else {
      this.Otherreason = false;
    }
  }
  /**
    * This method calls when user clicks on delete button inside the hambuger menubar to open permamently delete modal for verifier
    */
  openDelete = (dialog: TemplateRef<any>):void => {
      this.selectedOption = 'Poor scan quality';
      this.Otherreason = false;
      this.dialogService.open(dialog, { closeOnBackdropClick: false, autoFocus: false })
    }

    /**
    * This method calls when user clicks on delete button inside the permanently delete modal dialog box
    *
    * Calls API </api/DocumentVerification/DeleteDocVerifier>
    */
    deleteDocsModalWithReason =():void=> {
    this.docIdList = [];
    this.docguidlist = [];
    this.downloadDoc.forEach(element => {
      this.docIdList.push(element.docId);
      this.docguidlist.push(element.docGuid);
      this.orignalFileNameList.push(element.originalFileName);
    })
      let st:string = window.location.toString();
      let arr:string[] = st?.split("/");
      if (this.selectedOption == 'Other') {
        this.selectedOption = this.OtherReason.nativeElement.value
      }
      let postData= {
        "Wsoid": Number(this.workSpaceId),
        "WsLink": arr[2],
        "DocIdList": this.docIdList.join('|'),
        "OriginalFileNameList": this.orignalFileNameList.join('|'),
        "ModifiedBy": this.createdBy,
        "DeleteReason": this.selectedOption,
        "Separator": "|",
        "guid": this.getguid,
        "DocGuid": this.docguidlist.join('|'),
        "IsParserEnable": 1
      }
      this.pendingRequest = this.data.deleteMyDocService(postData).pipe(
        catchError((error) => {
          this.logger.error(error);
          return of(`Error: ${error}`);
        })
      ).subscribe((response: any) => {
        if (response.isSuccess) {
          this.toastrService.primary('Document Deletion Successful');
          this.router.navigateByUrl('/pages/documents/my-queue');
        }
        else {
          this.toastrService.danger("Document could not be deleted. Please try again!");
        }
      },);

    }
    public openRealTimeDocShareNotification() {
      this.docSharedNotifyRef = this.dialogService.open(this.docSharedNotify, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      });
    }
    
    closeRealNotify(){
      this.docSharedNotifyRef.close()
    }

    toggleAccordionDockets(index) {
      this.documentsDockets.forEach((res, i) => {
        if (index == i && this.openAccordionDockets[i]) {
          this.openAccordionDockets[i] = false;
        }
        else if (index == i && !this.openAccordionDockets[i]) {
          this.openAccordionDockets[i] = true;
        } else {
          this.openAccordionDockets[i] = false;
        }
      })   
    }
  
    getShowDataDockets(index) : boolean {
      if (this.openAccordionDockets[index] == true) {
        return true
      } else {
        return false;
      }
    }
  
  
    openAccordiansDockets(accordiansArr, index) {
      accordiansArr.forEach((res, i) => {
        if (index == i && res == false) {
          accordiansArr[i] = true;
        }
        else if(index == i && res == true) {
          accordiansArr[i] = false;
        } else {
          accordiansArr[i] = false;
        }
      })
    }

    getDynamicStyleDockets() {
      if (this.documentsDockets.length == 1) {
        return '100vh';
      } else if (this.documentsDockets.length == 2) {
        return 'calc(100vh - 132px)';
      } else if (this.documentsDockets.length > 2) {
        return 'calc(100vh - 264px)';
      }
    }

    openModalUnlink(voucher) {
      this.uniqueId = voucher.docketname;
      this.openModal(this.unlinkDocumentHere, false);
      this.vouchers = voucher;
    }

    confirmUnlink() {
      this.unlinkDocument(this.vouchers);
    }

    unlinkDocument(item) {
      let postData = {
        wsoid: Number(this.workSpaceId),
        docId: this.docId,
        docitdtid: Number(item.docitdtid),
        docItId: Number(item.docItid),
        unlink: 0,
        flag: "unlink",
        createdby: this.createdBy,
        uniqueID: item.docketname,
      }
      this.data.linkDocument(postData).subscribe((response: any) => {
        if (response.isSuccess) {
          this.getDocketDetails(this.docId);
        }
      })
    }

    getDocketDetails(docId) {
      let postData = {
          "wsoid": Number(this.workSpaceId),
          "docID": Number(docId)
      }
      this.data.getDocketDetails(postData).subscribe((response: any) => {
        if (response.isSuccess) {
          this.isDocketSourceFlag = true;
          console.log("response is here", response);
          this.documentsDockets = response.data[0].docketViewarData;
          this.documentsDockets.forEach((res)=>{
          if (this.openAccordionDockets.length == 0) {
              this.openAccordionDockets.push(true);
            } else {
              this.openAccordionDockets.push(false);
            }
          })
          this.documentsForDockets = [...this.documentsDockets];
        }
      },
    (error)=> {
      console.log("docket error", error);
    })
    }
  }
