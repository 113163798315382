<!---------------------------------------- This template is used for content of the queue window started ----------------------->
<ng-template #contentTemplate let-data let-ref="windowRef">
    <div class="col-12 d-flex p-0" *ngFor="let filename of selectedDocs">
      <div class="col-11 p-0">
        <p class="paragraph-2 text-primary ellipeses" [ngClass]="printLoading ? 'text-primary' : 'final'">
        {{filename.fileName}}
        </p>
      </div>
     <div class="col-1 dataalign  ml-1 pl-1"   *ngIf="filename.loader">
      <div  class="" [nbSpinner]="filename.loader" nbSpinnerStatus="primary"  nbSpinnerSize="tiny"  style="height: 21px; width:21px;
      " ></div>
    
      </div>
      <div class="col-1 dataalign checkiconcolor">
        <nb-icon icon="checkmark-circle-2-outline" class="text-success" *ngIf="!filename.loader" style="height: 20px; width:20px;"></nb-icon>
      </div>
      <div class="col-1 dataalign"  *ngIf="errorCase">
        <img src="assets/images/url.svg" alt=""  style="height: 20px; width:20px;">
      </div>
    </div>
    
  </ng-template>
<!------------------------ This template is used for content of the queue window Ends -------------------->

<!----------------------- Unsaved Change Modal start here --------------------------------->
<ng-template #unsavedchanges let-data let-ref="dialogRef">   
  <nb-card>
      <nb-card-header>
        <div class="justify-content-between d-flex">
          <h6 class="subtitle text-basic">
           Unsaved changes
          </h6> 
              <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
      </div>
     </nb-card-header>
   
    <nb-card-body> <p class="paragraph text-basic">Are you sure you want to leave this page? The changes you made will be<br> discarded.
</p> </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary"  size="small" class="mr-3" (click)="dialogRef.close();discardChanges(ref)" >Yes, leave</button>
          <button nbButton  size="small"  (click)=dialogRef.close()  outline status = "primary">No, stay</button>
    </nb-card-footer>
  </nb-card>
</ng-template> 

<!------------------------- Unsaved Change Modal start here -------------------------->