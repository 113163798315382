import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  public showView: boolean;


  constructor() { }
  docPreview = new Subject();
  deleteFromList = new Subject();
  closeDetailBar = new Subject();
  openDetailRight = new Subject();
  redirectToSignIn = new Subject();
  selectedGroup = new Subject();
  userGroupId = new Subject();
  allDocsSpinner = new Subject();
  myDocsSpinner = new Subject();
  hideSideBar = new Subject();
  isMyDocsClicked = new Subject();
  downlaodButtonThreeDotOwnedBy = new Subject();
  downlaodButtonThreeDotLastAccess = new Subject();
  downlaodButtonThreeDotSharedBy = new Subject();
  transferOwnerMenuAllWs = new Subject();
  transferOwnerMenuOwnedBy = new Subject();
  deleteMenuOwnedBy = new Subject();
  deleteMenuAllWs = new Subject();
  ownedByMeEmitter = new Subject();
  ownedByMeEmitterGuest = new Subject();
  globalSearchEmitterGuest = new Subject();
  commonCabinetEmitterGuest = new Subject();
  allWsEmitter = new Subject();
  shareButton = new Subject();
  sendDetailData = new Subject();
  sharedviewdetailsopen = new Subject();
  ownedviewdetailsopen = new Subject();
  mydocviewdetailsopen = new Subject();
  permanentDeletebutton = new Subject();
  restoredocbutton = new Subject();
  disableuserbutton = new Subject();
  enableuserbutton = new Subject();
  resetpassworduser = new Subject();
  showaccess = new Subject();
  deleteinvite=new Subject();
  resendinvite=new Subject();
  deleteuser = new Subject();
  invitemodal = new Subject();
  selectedGrouprename = new Subject();
  Grouprename = new Subject();
  selectedGroupremovemulti = new Subject();
  Groupreremovemulti = new Subject();
  selectedGroupremoveone = new Subject();
  Groupreremoveone = new Subject();
  selectedGroupadd = new Subject();
  Groupreadd = new Subject();
  MyqueueDeleteDocs = new Subject();
  MyqueueChangeVerifier =new Subject();
  sharedWithMeEmitter = new Subject();
  sharedWithMeEmitterGuest = new Subject();
  globalSearchEmitter = new Subject();
  dashboardEmitter = new Subject();
  userSettingEmitter =new Subject();
  templateEditorEmitter = new Subject();
  modalClose = new Subject();
  userProfileEmitter =new Subject();
  myqueueViewDetails=new Subject();
  ownedByMeViewDetails=new Subject();
  myqueueDeleteThreeDot = new Subject();
  myqueueDelete=new Subject();
  trashrestore = new Subject();
  reparsedocguid = new Subject();
  downloadicondisable = new Subject();
  detailandactivitydelete = new Subject();
  cabinetDelete = new Subject();
  paginationChanging = new Subject();
  forSearchTableSpinner = new Subject();
  forSearchTableSpinnerstop = new Subject();
  totalCountValue = new Subject();
  searchData = new Subject();
  productFruitDataTemp = new Subject();
  docRights = new Subject();
  searchPaginationChanged = new Subject();
  verificationDoc=new Subject();
  headerToggle = new Subject();
  refreshSearch = new Subject();
  downloadDisable=new Subject();
  closeDocketDetails = new Subject();
  openSharedModal = new Subject();
}
