import { Observable } from 'rxjs';
/**
 * LiveUpdateChart export
 */
export interface LiveUpdateChart {
   /**
 * variable for live chart object array
 */
  liveChart: { value: [string, number] }[];
   /**
 * variable for delta object
 */
  delta: {
    up: boolean;
    value: number;
  };
   /**
 * variable for daily Income
 */
  dailyIncome: number;
}
/**
 * PieChart export
 */
export interface PieChart {
/**
 * variable for value
 */
  value: number;
   /**
 * variable for name
 */
  name: string;
}
/**
 * EarningData export
 */
export abstract class EarningData {
   /**
 * method for getEarningLiveUpdateCardData
 */
  abstract getEarningLiveUpdateCardData(currency: string): Observable<any[]>;
   /**
 * method for getEarningCardData
 */
  abstract getEarningCardData(currency: string): Observable<LiveUpdateChart>;
   /**
 * method for getEarningPieChartData
 */
  abstract getEarningPieChartData(pieChartData): Observable<PieChart[]>;
}
