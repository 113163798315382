<nb-layout windowMode> 
  <nb-layout-header relative>
      <ngx-header></ngx-header>
  </nb-layout-header> 
  <nb-sidebar class="menu-sidebar relative" (click)="onMenuItemClick($event)" id="navbar" tag="menu-sidebar" *ngIf="removeClassFixed" responsive  [state]="sidebarCollapsed ? 'compacted' : 'expanded'">
    <div [ngClass]="isLoader ? 'skeletonParent' : ''">
    <div class="skeletonParent" *ngIf="isLoader">
      <div style="display: flex; align-items: center; justify-content: center;  margin-top: 34px;">
        <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
          'border-radius': '98px',
          height: '21px',
          width: '151px',
         
          'background-color' : '#CCD5E33D'
        }"></ngx-skeleton-loader>  
  </div>

  <div style="display: flex; align-items: center; justify-content: center;  margin-top: 31px;">
    <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
      'border-radius': '98px',
      height: '40px',
      width: '117px',
   
      'background-color' : '#CCD5E33D'
    }"></ngx-skeleton-loader>  
</div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
 <div style=" margin-right: 11px;">
  <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
    'border-radius': '100%',
    height: '21px',
    width: '21px',
 
    'background-color' : '#CCD5E33D'
  }"></ngx-skeleton-loader></div>
  <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
    'border-radius': '98px',
    height: '21px',
    width: '103px',
 
    'background-color' : '#CCD5E33D'
  }"></ngx-skeleton-loader>  
</div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <div style=" margin-right: 11px;">
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '100%',
     height: '21px',
     width: '21px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader></div>
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '98px',
     height: '21px',
     width: '103px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader>  
 </div>
 <div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <div style=" margin-right: 11px;">
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '100%',
     height: '21px',
     width: '21px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader></div>
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '98px',
     height: '21px',
     width: '103px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader>  
 </div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <div style=" margin-right: 11px;">
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '100%',
     height: '21px',
     width: '21px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader></div>
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '98px',
     height: '21px',
     width: '103px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader>  
 </div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <div style=" margin-right: 11px;">
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '100%',
     height: '21px',
     width: '21px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader></div>
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '98px',
     height: '21px',
     width: '103px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader>  
 </div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <div style=" margin-right: 11px;">
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '100%',
     height: '21px',
     width: '21px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader></div>
   <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
     'border-radius': '98px',
     height: '21px',
     width: '103px',
  
     'background-color' : '#CCD5E33D'
   }"></ngx-skeleton-loader>  
 </div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
    'border-radius': '98px',
    height: '21px',
    width: '122px',
  
    'background-color' : '#CCD5E33D'
  }"></ngx-skeleton-loader>  
</div>
<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px;">
  <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
    'border-radius': '98px',
    height: '21px',
    width: '103px',
  
    'background-color' : '#CCD5E33D'
  }"></ngx-skeleton-loader>  
</div>

<div style="display: flex; align-items: center; justify-content: center;  margin-top: 15px; position: absolute; bottom: 1rem; left: 4rem;">
  <ngx-skeleton-loader class="nameFirstRow" [theme]="{ 
    'border-radius': '98px',
    height: '21px',
    width: '122px',
  
    'background-color' : '#CCD5E33D'
  }"></ngx-skeleton-loader>  
</div>


    </div>
 <div [ngClass]="isLoader ? 'new': ''">
      <div class="uploadSectionSticky ">
          <h6 class="dashboardButton  headline-6 text-alternate cursor-pointer text-center truncate" (click)="dashboard('WS_Localhost');getSelectedItem()">{{wsName}}</h6>
        <div class="header-button">
          <ng-content select="button" class="btnUpload"></ng-content>
        </div>
      </div>
      <div class="" [ngClass]="isDocumentUpload ? 'leftMenu': 'leftnav' ">
        <div class="nbMenu">
          <ng-content select="nb-menu"></ng-content>
        </div>

        <!-- <div class="bar-buttom" *ngIf="showDaysInsideBar">
          <div class="trial-exp mb-3">
            <p class="text-white">{{wsSubscriptionRemainDaysCount}} day(s)
              left in trial</p>
          </div>
          <p *ngIf="isInfineTrial && trialDays != 0" class="bar-text paragraph-2 text-alternate mb-2 d-flex justify-content-between"><span class="storage-text">Storage</span> <button *ngIf="!isInfiniteDpage" nbButton class="bg-transparent border-0 shadow-none p-0 payGoDpages mr-4">
          {{dpagesCount}} used 
          </button></p>
          <p *ngIf="isInfiniteDpage" class="bar-text paragraph-2 text-alternate mb-2">{{dpagesCountUsed}}  used </p>
          <p *ngIf="isInfineTrial && trialDays != 0" class="bar-text paragraph-2 text-alternate mb-2"><span class="storage-text">Storage</span>{{wsSubscriptionRemainDaysCount}} day(s)
            left in trial </p>
            <p *ngIf="trialDays == 0" class="bar-text paragraph-2 text-alternate mb-2">Trial expires today</p>
          <ng-content *ngIf="!isInfiniteDpage" select="nb-progress-bar"></ng-content>
          <div class="row dcoins-div mt-3" >
            <div class="col-md-6  m-auto">
              <div class="my-dcoin-text"><span nbTooltip="dCoins are dox2U Coins that can be used to access transaction-based features like WhatsApp Messaging etc." nbTooltipPlacement="right"  nbTooltipStatus="primary">My dCoins</span></div>
              <a *ngIf="dcoinsOn" class="purchase-dcoin-text" href="mailto:billing@dox2u.com">+Add more dCoins</a>
              <a *ngIf="!dcoinsOn" class="purchase-dcoin-text" (click)="openModal(upgradeModalDcoins)">+Add more dCoins</a>
            </div>
            <div class="col-md-6 m-auto">
              <h1 class="dcoins-title">{{totalDCoins}}</h1>
            </div>
          </div>
          
      
        </div> -->
       <!-- <div  class="bar-buttom">
          <p *ngIf="!isInfineTrial" class="bar-text paragraph-2 text-alternate mb-2" >{{wsSubscriptionRemainDaysCount}} days left in trial </p>
       </div> -->
      </div>
</div>
</div>

<div class="bar-buttom" *ngIf="showDaysInsideBar">
  <div style="padding-left:20px;">

      <div class="trial-exp mb-3"  *ngIf="isInfineTrial && trialDays != 0">
        <p class="text-white">{{wsSubscriptionRemainDaysCount}} day(s)
          left in trial</p>
      </div>
      <p *ngIf="!isInfiniteDpage" class="bar-text paragraph-2 text-alternate mb-2 d-flex justify-content-between">
        <span class="storage-text">Storage</span> <button *ngIf="!isInfiniteDpage" nbButton class="bg-transparent border-0 shadow-none p-0 payGoDpages mr-4">
      {{dpagesCount}} used 
      </button></p>
      <p *ngIf="isInfiniteDpage" class="bar-text paragraph-2 text-alternate mb-2 d-flex justify-content-between">
        <span class="storage-text">Storage</span> <button *ngIf="isInfiniteDpage" nbButton class="bg-transparent border-0 shadow-none p-0 payGoDpages mr-4">
      {{dpagesCountUsed}} used 
      </button></p>
      <!-- <p *ngIf="isInfiniteDpage" class="bar-text paragraph-2 text-alternate mb-2">{{dpagesCountUsed}}  used </p> -->
      <!-- <p *ngIf="isInfineTrial && trialDays != 0" class="bar-text paragraph-2 text-alternate mb-2"><span class="storage-text">Storage</span>{{wsSubscriptionRemainDaysCount}} day(s)
        left in trial </p> -->
        <p *ngIf="trialDays == 0" class="bar-text paragraph-2 text-alternate mb-2">Trial expires today</p>
      <ng-content *ngIf="!isInfiniteDpage" select="nb-progress-bar"></ng-content>

  </div>
  <div class="d-flex dcoins-div" >
    <div >
      <div class="my-dcoin-text">
      <span nbTooltip="dCoins are dox2U Coins that can be used to access transaction-based features like WhatsApp Messaging etc." nbTooltipPlacement="right"  nbTooltipStatus="primary">
        My dCoins
      </span>
      </div>
      <a *ngIf="dcoinsOn" class="purchase-dcoin-text" href="mailto:billing@dox2u.com">+Add more dCoins</a>
      <a *ngIf="!dcoinsOn" class="purchase-dcoin-text" (click)="openModal(upgradeModalDcoins)">+Add more dCoins</a>
    </div>
    <div style="margin-left:auto;">
      <div class="dcoins-title">{{totalDCoins}}</div>
    </div>
  </div>
  

</div>
</nb-sidebar>

  

  <nb-layout-column [ngClass]="!isWorkspaceEnabled || globalwsStatusFlag ? '' : ''" class=" " (mouseout)="HandleFlags()" id="dashboardSection">
   
    <!-- For Digitization occurance -->
<div    class="commonCabinetBar" *ngIf = "globalwsStatusFlag">
  <nb-card class="mb-0">
    <nb-card-body>
      <p class="paragraph text-primary mb-0 text-center">This Workspace is under digitization by <b> cabinet2cloud </b></p>
    </nb-card-body>
  </nb-card>
</div>
    <!-- For Payment Grace Period -->
    <div    class="commonCabinetBar_payment_grace" *ngIf="workspaceStatus === 'pgp'">
      <nb-card class="mb-0">
        <nb-card-body>
          <p class="paragraph text-warning mb-0 text-center">Your WS is in the Payment Grace Period. Please ask your WS Owner to clear all dues to avoid service interruption.</p>
        </nb-card-body>
      </nb-card>
    </div>
        <!-- For Trial Period -->
        <div    class="commonCabinetBar_payment_failure_Three" *ngIf="trialDays == 0">
          <nb-card class="mb-0">
            <nb-card-body>
              <p class="paragraph text-danger mb-0 text-center">Trial expires today. <button class="payment_failure_btn_Three ml-3 p-2" (click)="getBillingPage()">SUBSCRIBE NOW</button> </p>
            </nb-card-body>
          </nb-card>
        </div>
        <div    class="commonCabinetBar_payment_failure_Three" *ngIf="trialDays <= 3 && trialDays >= 1">
          <nb-card class="mb-0">
            <nb-card-body>
              <p class="paragraph text-danger mb-0 text-center">Your Trial will expire in <strong>{{trialDays}} days</strong>. <button class="payment_failure_btn_Three ml-3 p-2" (click)="getBillingPage()">SUBSCRIBE NOW</button> </p>
            </nb-card-body>
          </nb-card>
        </div>
        <div    class="commonCabinetBar_payment_failure_Seven" *ngIf="trialDays === 5 || trialDays === 4">
          <nb-card class="mb-0">
            <nb-card-body>
              <p class="paragraph text-warning mb-0 text-center">Your Trial will expire in <strong>{{trialDays}} days</strong>. <button class="payment_failure_btn_Seven ml-3 p-2" (click)="getBillingPage()">SUBSCRIBE NOW</button> </p>
            </nb-card-body>
          </nb-card>
        </div>
        <div    class="commonCabinetBar_payment_failure_Ten" *ngIf="trialDays > 5 && trialDays <= 9">
          <nb-card class="mb-0">
            <nb-card-body>
              <p class="paragraph text-info mb-0 text-center">Your Trial will expire in <strong>{{trialDays}} days</strong>. <button class="payment_failure_btn_Ten ml-3 p-2" (click)="getBillingPage()">SUBSCRIBE NOW</button> </p>
            </nb-card-body>
          </nb-card>
        </div>
<!-- For Disabling ws -->
<div class="disableWssection" *ngIf="!isWorkspaceEnabled" >
  <nb-card class="mb-0">
    <nb-card-body>
      <p class="paragraph  mb-0 text-center">Your Workspace is disabled.
        <button nbButton ghost class="bg-transparent border-0 shadow-none p-0 text-capitalize cursor-pointer"   (click)="ownerControlRoute()">Enable</button>
        </p>
        </nb-card-body>
        </nb-card>
  </div>

      <ng-content select="router-outlet" ></ng-content>
  </nb-layout-column> 

     

    <nb-layout-footer fixed class="p-0 bg-transparent copyright" style="z-index: 10; position: fixed;
    bottom: 0px;
    width: -webkit-fill-available;">
      <div class="nbFooter" >
        <p class="caption-2 text-basic mt-0 mb-0 text-center">Plan :</p>
        <p class="caption-2 text-primary mt-0 mb-0 text-center"> &nbsp; {{subscriptionPlan}}  </p>

      <p class="caption-2 text-basic mt-0 mb-0 text-center"> &nbsp;&nbsp; &copy;2020-<span >{{currentYear}}</span> Privet Ventures Pvt Ltd. All rights reserved.</p>
   <!-- <p class="caption-2 mb-0 mt-0 text-center ml-1"> BETA Version :  {{version}}</p> -->
  </div>
    </nb-layout-footer>
    

</nb-layout>

<ng-template #upgradeModalDcoins>
  <nb-card class="invitemodal">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic mb-0"> Add dCoins </h6>
       </div> 
    </nb-card-header>
    <nb-card-body>
      <p class="paragraph text-basic mb-0">This functionality is not supported in your current subscription plan.<br> Please upgrade to an eligible plan and try again</p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-3" type="button" (click)="gotobilling()" size="small">UPGRADE NOW</button>
      <button type="button"  (click)="close()" size="small" class="upgrademodalbtn">CANCEL</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
 
