<!--/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Upload Component
///  Description: Upload component in random uplaod
///  Owner      : Sumeet Goel
///  Author     : Abhishek Kumar , Shubham Pandey
///  Created On : 05-05-2023
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar             Date: 07/07/2023            Description: Alignment of few flags for IDP section
///
///------------------------------------------------------------------->

<div id="mainAppBox" >

<!-- Raise a ticket modal html invoking  -->
<app-support></app-support>
<!-- <div *ngIf="true">
  <nb-layout >
    <nb-layout-header class="padding0">
      <div class="viewerHeaderHeight d-flex justify-content-between align-items-center">
        <div class="viewerHeaderLeft">
          <div class="d-flex" style="width: 100%;">
            <div class=" d-flex align-items-center">
              
              {{docmodifiednameinputbox || originalFileNameDocs}}
              
              </div>
            </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <div class="ml-3">
                <ngx-header></ngx-header>
              </div>
            </div>
            </div>
    </nb-layout-header>
  </nb-layout>
</div> -->
<!-- HTML start for verification document -->
<div>
  <nb-layout *ngIf="(!isFirefox && !isIE)">
    <nb-layout-header  *ngIf="sharedIs" class="padding0">
      <div class="viewerHeaderHeight d-flex justify-content-between align-items-center col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
        <div class="viewerHeaderLeft">
          <div class="d-flex" style="width: 100%;">
            <div class=" d-flex align-items-center" >
              <div>
                {{alphaville}}
              </div>
              
             
              
              </div>
            </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <div class="ml-3">
                <ngx-header></ngx-header>
              </div>
            </div>
            </div>
    </nb-layout-header>
    <!-- header end -->
    <nb-layout-header *ngIf="!sharedIs" class="padding0">
      <!-- viewer header -->
      <div class="viewerHeaderHeight d-flex justify-content-between align-items-center">
        <!-- viewer left section -->
        <div class="viewerHeaderLeft">
          <div class="d-flex" style="width: 100%;">
            <div class="d-flex align-items-center">
              <div style="position: relative;">
                <div class="pr-4" #toggleButton (click)="viewerMenu()"
                  [ngClass]="showViewerMenu? 'menuBlue':'MenuGrey'">
                  <nb-icon icon="menu-outline"  class="menu-icon"></nb-icon>
                </div>
                <div [hidden]=!showViewerMenu #menu class="menuBox">
                  <div class="sidebar-menu">
                    <div class="d-flex height36 align-items-center cursor-pointer" (click)="tagVerify()">
                      <!-- <nb-icon icon="info-outline" class="mr-2 cursor-pointer"></nb-icon> -->
                      <span class="material-symbols-outlined mr-2 cursor-pointer description-menu-icon">
                        label
                        </span>
                      <p class="subtitle mb-0 cursor-pointer" (click)="viewerTagPanel()">Tags</p>
                    </div>
                    <div class="d-flex height36 align-items-center cursor-pointer">
                      <!-- <nb-icon icon="info-outline" class="mr-2 cursor-pointer"></nb-icon> -->
                      <span class="material-symbols-outlined mr-2 cursor-pointer description-menu-icon">
                        grading
                        </span>
                      <p class="subtitle mb-0 cursor-pointer" (click)="viewerDocAiPanel(); viewextract()">IDP</p>
                    </div>
                    <div class="d-flex height36 align-items-center cursor-pointer">
                      <!-- <nb-icon icon="info-outline" class="mr-2 cursor-pointer"></nb-icon> -->
                      <span class="material-symbols-outlined mr-2 cursor-pointer description-menu-icon">
                        extension
                        </span>
                      <p class="subtitle mb-0 cursor-pointer" (click)="viewerTallyDMS()">Tally DMS</p>
                    </div>
                    <div class="d-flex height36 align-items-center cursor-pointer">
                      <!-- <nb-icon icon="info-outline" class="mr-2 cursor-pointer"></nb-icon> -->
                      <span class="material-symbols-outlined mr-2 cursor-pointer description-menu-icon">
                        description
                        </span>
                      <p class="subtitle mb-0 cursor-pointer" (click)="detailsection()">Document Details</p>
                    </div>
                  </div>
                  
                  <div class="d-flex height36 align-items-center cursor-pointer displayChange">
                    <!-- <nb-icon icon="info-outline" class="mr-2 cursor-pointer"></nb-icon> -->
                    <span class="material-symbols-outlined mr-2 cursor-pointer description-menu-icon">
                      description
                      </span>
                    <p class="subtitle mb-0 cursor-pointer" (click)="detailsection()">Document Details</p>
                  </div>
                    <hr class="m-0">
                 
                  <div [class.disabled-div]="docUnderProcessing || docFormatNotSupported || docParkingQueue" *ngIf="(downloadprint && (isView) && isPrintDownloadGuestEnableTally)" style="position: relative;width: 100%;"
                    class="downloadSection d-flex  height36 align-items-center cursor-pointer">
                    <div class="d-flex  align-items-center cursor-pointer " style="position: relative; width: 100%;">
                      <nb-icon icon="download-outline" class="mr-2 cursor-pointer"></nb-icon>
                      <p class="subtitle mb-0 cursor-pointer" (click)="validayeDownloadChild()">Download</p>
                    </div>
                  </div>
        
                  <!-- Abhishek changes in share function -->
                  <div [class.disabled-div]="docUnderProcessing" class="d-flex height36  align-items-center cursor-pointer">
                    <nb-icon icon="share-outline" class="mr-2 cursor-pointer"></nb-icon>
                    <p class="subtitle mb-0 cursor-pointer" (click)="viewShare()">Share </p>
                  </div>
                  <!-- Abhishek changes in print function -->
                  <div [class.disabled-div]="docUnderProcessing || docIsLockedOrCorrupt || docFormatNotSupported || docParkingQueue" *ngIf="(downloadprint && (isView) && (!docFormatNotSupported) &&(!docIsLockedOrCorrupt) && (isPrintDownloadGuestEnableTally) )" class="d-flex height36  align-items-center cursor-pointer">
                    <nb-icon icon="printer-outline" class="mr-2 cursor-pointer"></nb-icon>
                    <p class="subtitle mb-0 cursor-pointer" (click)="printClick()">Print</p>
                  </div>
                  <div style="position: relative; width: 100%;"
                    class="downloadSection height36  align-items-center cursor-pointer d-flex">
                    <div class="d-flex  align-items-center cursor-pointer" style="position: relative; width: 100%;">
                      <nb-icon icon="question-mark-circle-outline" class="mr-2 cursor-pointer"></nb-icon>
                      <p class="subtitle mb-0 cursor-pointer">Help & Support</p>
                      <div style="position: absolute;right: 0px;">
                        <nb-icon icon="arrow-right" class="cursor-pointer" style="width:12px"></nb-icon>
                      </div>
                    </div>
                    <div class="downloadSectionChild">
                      <p class="subtitle height36 mb-0 d-flex align-items-center mb-0cursor-pointer"
                        (click)="openSupport()">Raise a ticket</p>
                      <p class="subtitle height36  mb-0 d-flex align-items-center mb-0 cursor-pointer"
                        (click)="openHelp()">Help & Support
                      </p>
                    </div>
                  </div>
                  <hr class="m-0">
                  <div class="d-flex height36  align-items-center cursor-pointer">
                    <nb-icon icon="trash-outline" class="mr-2 cursor-pointer text-danger"></nb-icon>
                    <p *ngIf="viewDelete && isView" class="subtitle mb-0 cursor-pointer text-danger DeletePara" (click)="openDeleteModalGlobal()">
                      Delete</p>
                      <p *ngIf="viewDelete && isverification" class="subtitle mb-0 cursor-pointer text-danger DeletePara" (click)="openDelete(permanentDeleteDocModal)">
                      Delete</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- block for name of the document -->
            <!-- binding this html with ts -->
            <div class=" d-flex align-items-center headerInput">
              <div *ngIf = "isNotActive" (click)=" isNotActive = false ; nameAdjust()">
                {{docmodifiednameinputbox || originalFileNameDocs}}
              </div>
              <div [ngStyle]="!showError? {'pointer-events': 'none'} : {'pointer-events': 'auto'} " *ngIf = "!isNotActive" [attr.contenteditable]="isEditablevalue" [textContent]="docmodifiednameinputbox"
                class="subtitle  px-2 py-1 autoInput" (click)="editverificationdocname()" (blur)="autoNameSave($event.target.name)"
                 #outsideNameBox (keyup)="onInput($event)" (keydown)="onKeyDown($event)" nbTooltipStatus="danger" nbTooltipPlacement="bottom" [nbTooltip]="showError && 'Doc Name must contain at least 3 characters'" [ngClass]="showError? 'inpputbox_new':'inpputbox'" onkeypress="return (this.innerText.length <= 99)">
                 {{docmodifiednameinputbox}}</div>
                        </div>
            <div *ngIf = "isNameDuplicate" class=" d-flex align-items-center alertIconDiv">
              <nb-icon icon="alert-triangle-outline" status="warning" nbTooltipStatus="warning" nbTooltipPlacement="bottom" [nbTooltip]="isNameDuplicate? 'This doc name is already taken. You can still use this name, but we recommend avoiding duplicate names to prevent confusion.' : null"></nb-icon>
            </div>
            <div class="verifyPageLine mx-2"></div>
            <div class="d-flex align-items-center savingBox" *ngIf="showAutoSave">
              <nb-icon icon="sync" class="text-hint mr-1"></nb-icon>
              <p class="mb-0 paragraph">Saving</p>
            </div>
          </div>
        </div>
        <!-- viewer left section end -->
        <!-- viewer right section -->
        <!-- chnages by abhishek  -->
        <div class="d-flex align-items-center justify-content-end viewerHeaderRight">
          <div [class.disabled-div]="docUnderProcessing || docIsLockedOrCorrupt || printIconDisabled || docFormatNotSupported || docParkingQueue" *ngIf="(downloadprint && (isView) && (!docFormatNotSupported) &&(!docIsLockedOrCorrupt) && (isPrintDownloadGuestEnableTally))" class="pr-0 pl-2 clickicons pt-1 mr-3 cursor-pointer" nbTooltip="Print" nbTooltipStatus="info" nbTooltipPlacement="bottom">
            <span class="multiUser-group" tooltip="Delete" data-toggle="tooltip"
              (click)="printClick()">
              <nb-icon icon="printer-outline" class="chng-grp icon"></nb-icon>
              <!-- [ngStyle]="{'pointer-events':isOpen ? 'none' : 'auto' }" -->
            </span>
          </div>

          <!-- download edit by abhishek  -->
          <div [class.disabled-div]="docUnderProcessing || docFormatNotSupported || docParkingQueue" [class.disabled-div1]="isDownloadModalOperationInProgress" *ngIf="(downloadprint && (isView) && (isPrintDownloadGuestEnableTally))" class="pr-0 pl-2 clickicons pt-1 mr-3 cursor-pointer" nbTooltip="Downlaod" nbTooltipStatus="info" nbTooltipPlacement="bottom" (click)="disabledownload();validayeDownloadChild()" >
            <span class="multiUser-group" tooltip="Delete" data-toggle="tooltip" >
              <nb-icon icon="download-outline" class="chng-grp icon"></nb-icon>
            </span>
          </div>
          <!-- </div> -->
          <!-- <div *ngIf="!isverification && !myTrash && !isView"> -->
            <div [class.disabled-div]="docUnderProcessing" [class.disabled-div1]="isGuestModalOperationInProgress" *ngIf="isView && (isPrintDownloadGuestEnableTally)" class="pr-0 pl-2 clickicons pt-1 mr-2 cursor-pointer" nbTooltip="Share with Guests"
              nbTooltipStatus="info" nbTooltipPlacement="bottom" (click)= "disableGuest();openGuestModal()" >
              <span class="multiUser-group" tooltip="Share with Guests" data-toggle="tooltip">
                <!-- <img *ngIf="GuestFlag !='True'" class="cursor-pointer guesticonupgrad1"
                  src="../../../assets/images/rolegift.svg"> -->
                <nb-icon icon="people-outline" class="chng-grp"></nb-icon>
              </span>
            </div>
          <!-- </div> -->
          <button *ngIf="isverification" [disabled]="isShareModalOperationInProgress || docUnderProcessing" nbButton ghost class="cursor-pointer  viewclass panelHide " nbTooltip="Share" nbTooltipStatus="info"
            nbTooltipPlacement="bottom" (click)="disableShare();viewShare()">
            <nb-icon icon="share-outline"></nb-icon>
          </button>
          <!-- <div _ngcontent-txe-c381="" class="verifyPageLine1  mx-2"></div> -->
          <!-- <button *ngIf="isView" class="ml-2 mr-3 verifyExitBtn respButton" [disabled]="isShareModalOperationInProgress || docUnderProcessing" nbButton status="primary" (click)="disableShare();viewShare()">Share
          </button> -->
          <span *ngIf="isView" class="ml-2 mr-3 verifyExitBtn" [disabled]="isShareModalOperationInProgress || docUnderProcessing" (click)="disableShare();viewShare()"><img src="../../../assets/images/share.svg">
          </span>
          <div class=" verifyPageLine panelHide hide768"></div>
          <div *ngIf="isverification" class=" verifyExitBtn panelHide" >
            <button nbButton class="respButton" status="primary" [nbSpinner]="disableVerify" nbSpinnerStatus="primary"
            nbSpinnerSize="tiny" nbButton status="primary"
            [disabled]="verifyerrormorecharbtn || verifyandexit || disableVerify || verifyTempShare || disableVerifyDueToNameLength "
            name="verifyandexist" (click)="docsStatusCheck($event.target.name);">Verify & exit </button>
            <span class="respIcon"
            [disabled]="verifyerrormorecharbtn || verifyandexit || disableVerify || verifyTempShare || disableVerifyDueToNameLength "
            name="verifyandexist" (click)="docsStatusCheck($event.target.name);"><img src="../../../assets/images/VerifyExitIcon.svg"></span>
              </div>
          <div class="ml-3 user-image">
            <ngx-header></ngx-header>
          </div>
        </div>
        <!-- viewer right section end -->
      </div>
    </nb-layout-header>
    <!-- header end -->
    <!-- viewer body -->
    <nb-layout-column class="classCheck p-0">
      <!--Document Shared Text Banner Start-->
      <div *ngIf="isDocGuestShared" style="padding:8px; background: var(--Info-200, #C7E2FF); color: var(--Info-500---Default, #0095FF);">
        <div style="font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; text-align:center;">
          This document has been shared outside your workspace.
        </div>
      </div>
      <div *ngIf="sharedIs" class="viewerBody">
        <div class="alternative-content1">
          <div>
            <img class="cursor-pointer doc-not-loading"
            src="../../../assets/images/SVG/Group.svg"></div>
            <div class="sharingDoc">Document is not shared</div>
            <div class="copyLoadingScreenText1">You cannot access this document since it is not shared with you.</div>
          </div>
      </div>
      <div *ngIf="!sharedIs" class="d-flex justify-content-between viewerBody">
        <div class="d-flex panelHide sidebar-container col-xl-4 col-l-3 col-md-5 p-0">
          <!-- sidebar -->
          <div class="d-flex flex-column  ">
            <div [ngClass]="isDocGuestShared? 'd-flex flex-column justify-content-between sidebar72-banner':'d-flex flex-column justify-content-between sidebar72'">
              <div (click)="tagVerify()">
                <div class="d-flex flex-column justify-content-center align-items-center "  (click)="viewerTagPanel()"
                  [ngClass]="viewertag? 'sidebarIconBlue':'sidebarIcon'" style="padding: 12px 24px;">
                  <span class="material-symbols-outlined ">label</span>
                  <p class="mb-0  subtitle-2" >Tags</p>
                </div>
                <div *ngIf="ViewExtractedTextFeatureFlag ==='True' && iseditableDocument === 0" [class.disabled-div]="docUnderProcessing || docIsLockedOrCorrupt || docFormatNotSupported || docParkingQueue" class="d-flex flex-column justify-content-center align-items-center borderRadius"
                  (click)="viewerDocAiPanel(); viewextract()" [ngClass]="viewerDoc? 'sidebarIconBlueRadius':'sidebarIcon'"
                  style="padding: 12px 24px;">
                  <span class="material-symbols-outlined ">grading</span>
                  <p class="mb-0  subtitle-2">IDP</p>
                </div>
                <div *ngIf="isDocSourceFlagTally" class="d-flex flex-column justify-content-center align-items-center borderRadius"
               (click)="viewerTallyDMS()"  [ngClass]="viewerTally? 'sidebarIconBlueRadius':'sidebarIcon'"
                style="padding: 12px 5px;">
                <span style="font-size: 26px !important" class="material-symbols-outlined">
                  extension
                  </span>
                <p class="mb-0  subtitle-3">Tally DMS</p>
              </div>
              <div *ngIf="isDocketSourceFlag && isDocketReallyInPlan" class="d-flex flex-column justify-content-center align-items-center borderRadius"
               (click)="viewDocketsList()"  [ngClass]="isDocketsPreview? 'sidebarIconBlue':'sidebarIcon'"
                style="padding: 12px 5px;">
                    <span style="font-size: 26px !important" class="material-symbols-outlined">
                      fact_check
                    </span>
                <p class="mb-0  subtitle-3">Dockets</p>
              </div>
              </div>
            <div>
              <!-- Details and Activity panel icon starts -->
              <div *ngIf="isIconClicked" class="d-flex flex-column justify-content-center align-items-center cursor-pointer"
              style="padding: 12px 24px;" (click)="detailsection()" [ngClass]="!hidedetail ? 'sidebarIconBlue':'sidebarIcon'">

                <span class="material-symbols-outlined details-symbol">
                  description
                </span>
                <p class="mb-0 mb-0 subtitle-2 details-symbol">Details</p>
              </div>
              <!-- Details and Activity panel icon ends -->


              <div (click)="goBack()" class="d-flex flex-column justify-content-center align-items-center cursor-pointer "
                style="padding: 12px 24px;">
                <span class="material-symbols-outlined">
                  exit_to_app
                </span>
                <p class="mb-0 mb-0 subtitle-2">Exit</p>
              </div>
            </div>
            </div>

          </div>

          <!-- sidebar end -->
          <!-- tag section -->
          <div *ngIf="viewertag" style="width:100%; background-color: rgb(255, 255, 255); height: 100%;">
            <ngx-bulk-tag></ngx-bulk-tag>
          </div>
          <!-- tag section end -->
          <!-- doc Ai section start-->
          <div *ngIf="viewerDoc" class="docAiSection">
            <!-- docAi Accordian -->
            <div *ngIf="!isExtractAvailable" style="padding: 16px 12px;" class="docAiSection-div">
              <div class="pr-3 cursor-pointer col-2 crossplace idp-close-icon">
                <nb-icon icon="close-outline" (click)="crosstagIDP()" class="cursor-pointer"></nb-icon>
            </div>
              <div>
                <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate"
                style="margin-top: 20px;"
                  data-toggle="collapse" data-target="#collapseSingleValue" aria-expanded="true"
                  aria-controls="collapseSingleValue">
                  <p class="subtitle mb-0">Extracted text</p>
                  <span class="material-symbols-outlined">
                    arrow_drop_up
                  </span>
                </div>
                <div class="d-flex align-items-center accordianIconsDiv" *ngIf="!show_status && !error_msg">
                  <span class="material-symbols-outlined iconvisible cursor-pointer" (click)="viewextract('true')" nbTooltip="full Width  " nbTooltipStatus="primary" nbTooltipPlacement="bottom">
                    zoom_out_map
                  </span>
                  <span *ngIf="(downloadExtractedTextPdf == 'True')" [ngClass]="{'disabled-div':disabledDownloadText}" (click)="downloadExtractedtext()" class="material-symbols-outlined cursor-pointer " nbTooltip="Download" nbTooltipStatus="primary" nbTooltipPlacement="bottom">
                    download
                  </span>
                  <span class="material-symbols-outlined cursor-pointer"  nbTooltip="Copy" nbTooltipStatus="primary" nbTooltipPlacement="bottom" (click)="copyText()">
                    content_copy
                  </span>
                </div>

              </div>
              <div class="collapse show " id="collapseSingleValue">

                <div class="" style="margin-top: 12px;">
                  <!-- check  -->
                  <div *ngIf="notShowValue != 'Not_Show'" this.notShowValue='Not_Show' class="">
                    <div *ngIf="show_status" class="d-flex" style="position: relative; ">
                      <div class="loaderImage" style="position: relative; right:10px;">
                        <!-- <nb-card > -->
                        <nb-card-body nbSpinnerSize="small" [nbSpinner]="true" nbSpinnerStatus="primary" class="">
                        </nb-card-body>
                        <!-- </nb-card> -->
                      </div>
                      <div class="processingText" style="position: relative; right:10px;">
                        <p class="Text Font/S1 subtitle mb-0 dpagesTooltip">Processing
                          <span class="dpagesTooltipPara d-flex flex-column">
                            <div class="mb-3 d-flex">
                              <nb-card-body [nbSpinner]="true" nbSpinnerSize="medium" nbSpinnerStatus="primary"
                                *ngIf='sentForExtraction && load'></nb-card-body>
                              <!-- <nb-icon icon="loader-outline"*ngIf='sentForExtraction && wait' class="WaitingIcon"></nb-icon> -->
                              <nb-icon icon="done-all-outline" *ngIf='tick1' class="WaitingIcon checkIconColor">
                              </nb-icon>
                              <p class="Text Font/S1 subtitle mb-0   d-flex align-items-center" style="width: 100%;">
                                Sent for extraction
                              </p>
                            </div>
                            <div class="mb-3 d-flex">
                              <nb-card-body [nbSpinner]="true" nbSpinnerSize="medium" nbSpinnerStatus="primary"
                                *ngIf='extractionStarted && load'></nb-card-body>
                              <nb-icon icon="loader-outline" *ngIf='sentForExtraction' class="WaitingIcon"></nb-icon>
                              <nb-icon icon="done-all-outline" *ngIf='tick2' class="WaitingIcon checkIconColor">
                              </nb-icon>
                              <p class="Text Font/S1 subtitle mb-0   d-flex align-items-center" style="width: 100%;">
                                Extraction started
                              </p>
                            </div>
                            <div class="mb-3 d-flex">
                              <nb-card-body [nbSpinner]="true" nbSpinnerSize="medium" nbSpinnerStatus="primary"
                                *ngIf='postProcessing && load'></nb-card-body>
                              <nb-icon icon="loader-outline" *ngIf='extractionStarted || sentForExtraction'
                                class="WaitingIcon"></nb-icon>
                              <nb-icon icon="done-all-outline" *ngIf='tick3' class="WaitingIcon checkIconColor">
                              </nb-icon>
                              <p class="Text Font/S1 subtitle mb-0  d-flex align-items-center" style="width: 100%; ">
                                Post processing started
                              </p>
                            </div>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="textcolorextration subtitle  cursor-pointer" style="color:#FF3D71 !important ;"
                      *ngIf=" !show_status && error_msg">
                      {{show_msg}}
                      <img *ngIf="ViewExtractedTextFeatureFlag !='True'" class="cursor-pointer "
                        src="../../../assets/images/rolegift.svg">
                    </div>

                    <!-- new code -->
                    <div *ngIf="!show_status && !error_msg" class="d-flex flex-column">
                      <div>
                        <p *ngIf="ConfidenceValue <= 69.999 && ConfidenceValue >= 0" class="Text Font/S1 subtitle concise2">Average confidence value:<span  class="Text Font/S1 subtitle ml-1" style="color: #FF3D71;">Low</span></p>
                        <p *ngIf="ConfidenceValue <= 84.999 && ConfidenceValue >= 70" class="Text Font/S1 subtitle concise2">Average confidence value:<span class="Text Font/S1 subtitle ml-1" style="color: #FFAA00;">Medium</span></p>
                        <p *ngIf="ConfidenceValue <= 100 && ConfidenceValue >= 85" class="Text Font/S1 subtitle concise2">Average confidence value:<span class="Text Font/S1 subtitle ml-1"  style="color: #00D68F;">{{ConfidenceValue}}%</span></p>
                      </div>
                      <div class="textextractionBox">
                        <p class="mb-0 paragraph" *ngFor = " let items of getviewdata">
                          {{items.extracteD_TEXT}}
                        </p>
                      </div>
                    </div>
                    <!-- new code end -->
                  </div>
                  <!-- code check -->

                </div>
              </div>
            </div>
            <!-- doc Ai Accordian -->
          </div>
          <!-- doc Ai section end -->


          <!-- Tally DMS section Start -->
          <div *ngIf="viewerTally" class="docAiSection scrollbar1" id="accordionFlushExample">

           
            <div *ngFor="let voucher of vouchersTags; index as i">
              <div class="accordianBox">
                <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate" [class.collapsed]="!getShowData(i)"
                (click)="toggleAccordion(i)"
                  [attr.data-target]="'#collapseTag' + i" aria-controls="collapseTag" [aria-expanded]="getShowData(i)">
                <p class="subtitle mb-0">{{voucher.key | tallyVoucherName: vouchersTags}}</p>
                <span class="material-symbols-outlined">
                  arrow_drop_up
                </span>
              </div>
              <div class="accordion-collapse collapse scroll-sense panel" [ngStyle]="{'max-height': getDynamicStyle()}" [attr.id]="'collapseTag' + i" [class.show]="getShowData(i)">
                <div *ngIf="voucher?.value?.length >= 1">
                  <div style="width: 100%;" *ngFor="let item of voucher?.value" class="d-flex  mt-2">
                      <div style="position: relative;">
                          <div class="line-text" style="width: 4px"></div>
                            
                      </div>
                      <div style="column-gap: 0.5rem ;width: 100%;" class="d-flex ml-2 mt-1">
                          <div style="width: 50%; word-break: break-word;">
                                {{item.tagLabel}}
                          </div>
                          <div style="width: 50%; word-break: break-word;">
                              {{item.tagValue}}
                          </div>
                      </div>
                  </div>
              </div>
              <div *ngIf="voucher?.value?.length === 0 ">
                <p >
                  There are no Tags with label attached to this document.
               </p>
       </div>
              </div>
              </div>
                  <div *ngIf="i != vouchersTags?.length-1" class="hrline"></div>
                </div>
                
          </div>
          <!-- Tally DMS Section end -->

          <!-- Dockets section begins-->
                    
                    <div *ngIf="isDocketsPreview" class="docAiSection scrollbar1" id="accordionFlushExample">

                      <div *ngIf="documentsForDockets?.length === 0 ">
                        <p class="no-docket-here ml-3 mt-3">
                          This document is not associated with any Docket.
                       </p>
               </div>
               <div *ngIf="documentsForDockets?.length !== 0 ">
                      <div *ngFor="let voucher of documentsForDockets; index as i">
                        <div class="accordianBox">
                          <div class="d-flex justify-content-between align-items-center cursor-pointer iconRotate" [class.collapsed]="!getShowDataDockets(i)"
                          (click)="toggleAccordionDockets(i)"
                            [attr.data-target]="'#collapseTag' + i" aria-controls="collapseTag" [aria-expanded]="getShowDataDockets(i)">
                          <p class="subtitle mb-0 docket-name-label">{{voucher.docketname}}</p>
                          <span class="material-symbols-outlined">
                            arrow_drop_up
                          </span>
                        </div>
                        <div class="accordion-collapse collapse scroll-sense panel" [ngStyle]="{'max-height': getDynamicStyleDockets()}" [attr.id]="'collapseTag' + i" [class.show]="getShowDataDockets(i)">
                          <div *ngIf="voucher?.dockettype">
                            <div class="docket-type-display" style="margin-top: 0.75rem;">
                              Docket Type
                            </div>
                            <div class="docket-type-value">
                              {{voucher.dockettype}}
                            </div>
                            </div>
                          <div *ngIf="voucher?.typeofdocument">
                              <div class="docket-type-display" style="margin-top: 0.75rem;">
                                Type of document
                              </div>
                              <div class="docket-type-value">
                                {{voucher.typeofdocument}}
                              </div>
                            </div>
                            <div *ngIf="voucher?.tagswithoutlabel.length > 0">
                              <div class="docket-type-display" style="margin-top: 0.75rem;">
                                Tags (without label)
                              </div>
                              <div class="docket-type-value">
                                {{voucher.tagswithoutlabel.toString()}}
                              </div>
                            </div>

                            <div *ngIf="voucher?.tagwithLabel?.length >= 1">
                              <div class="docket-type-display" style="margin-top: 0.75rem;">
                                Tags (with label)
                              </div>
                              <div style="width: 100%;" *ngFor="let item of voucher?.tagwithLabel" class="d-flex  mt-2">
                                <div style="width: 100%; display: flex">
                                  <div style="position: relative;">
                                    <div class="line-text" style="width: 4px"></div>
                                      
                                </div>
                                <div style="column-gap: 0.5rem ;width: 100%; font-size: 13px;" class="d-flex ml-2 mt-1">
                                    <div style="width: 50%; word-break: break-word;">
                                          {{item.tagLabel}}
                                    </div>
                                    <div style="width: 50%; word-break: break-word; font-size: 13px;">
                                        {{item.tagValue}}
                                    </div>
                                </div>
                                </div>
                                 
                              </div>
                          </div>
                          <div *ngIf="isDocketsPlan" style="margin-top: 0.75rem;">
                            <button class="red-button" (click)="openModalUnlink(voucher)">
                              Unlink document from Docket
                            </button>
                          </div>

                        
                      
                        </div>
                        </div>
                            <div *ngIf="i != documentsForDockets?.length-1" class="hrline"></div>
                          </div>
                          </div>
                          
                    </div>
          <!--Dockets section ends-->

          <!-- Details and Activity Start -->
          <div  [hidden]="hidedetail"  class="detailsActivityShow" style="width: 100%; background-color: rgb(255, 255, 255);">
            <div>           
              <ngx-detail-and-activity ></ngx-detail-and-activity>
            </div>
          </div>
          <!-- Details and Activity end -->

        </div>
        <!-- pdf viewer section -->
         <!-- showSearch variable is available in the ts file  -->
         <div class="pdf-viewer-container col-xl-8 col-l-9 col-md-7 col-sm-12 col-xs-12 col-12 pl-0" *ngIf="docIsDisplayed"
         style="width: 100%;   height: calc(100vh - 77px); border-left: 1px solid #E4E9F2;border-bottom: 1px solid black;">
            <ngx-extended-pdf-viewer #pdfViewer [src]="sourceFile" [useBrowserLocale]="true" zoom="page-width"
              [showHandToolButton]="false" [sidebarVisible]="sidebarOpen" [textLayer]="true" [height]="'80vh'"
              [handTool]="false" [enableDragAndDrop]="false" [enablePinchOnMobile]="true" [minZoom]="0.1" [maxZoom]="10"
              (textLayerRendered)="highlightWords($event)" [ignoreKeyboard]="false"
              [acceptKeys]="['Ctrl+WHEEL', 'Ctrl+P','Ctrl++','Ctrl+-','+','-']" [ignoreKeys]="false"
              [showFindButton]="true" [showPresentationModeButton]="true" [showRotateButton]="true"
              (pageRendered)="onEvent('pageRendered', $event)">
            </ngx-extended-pdf-viewer>
          </div>
          <!-- pdf viewer section end -->
          <div class="alternative-content" *ngIf="docUnderProcessing">
          <div class="spinner-border text-primary" role="status">
          </div>
          <div class="copyLoadingScreenText">We are still preparing your document for viewing. Please check again in some time</div>
        </div>
        <div class="alternative-content" *ngIf="docFormatNotSupported">
          <div>
            <img class="cursor-pointer doc-not-loading"
            src="../../../assets/images/document_not_supported.svg"></div>
            <div class="copyLoadingScreenText">We’re sorry! The format for this document is not supported for viewing on dox2U as yet</div>
          </div>
          <div class="alternative-content" *ngIf="docParkingQueue">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/SVG/hourclock.svg"></div>
              <div class="copyLoadingScreenText">Sorry, this is taking us longer than expected. We will get back to you soon.</div>
            </div>
          <div class="alternative-content" *ngIf="docIsLockedOrCorrupt">
            <div>
              <img class="cursor-pointer doc-not-loading"
              src="../../../assets/images/Padlock.svg"></div>
              <div class="copyLoadingScreenText">This document is either locked or corrupt and cannot be viewed on dox2U</div>
            </div>
        <!-- <div *ngIf="isCloseClicked" class="informationIcon1">
          <nb-icon icon="info-outline" (click)="detailsection()"></nb-icon>
        </div> -->
        <!-- <div *ngIf="isIconClicked" class="informationIcon">
          <nb-icon icon="info-outline" (click)="detailsection()"></nb-icon>
        </div>
        <div [hidden]="hidedetail" class="col-xxxl-3  col-xxl-3 col-xl-3 col-l-3 col-md-3 pr-0 detailsandactivity">
          <div>
            <ngx-detail-and-activity ></ngx-detail-and-activity>
          </div>
        </div> -->
      </div>
    </nb-layout-column>
    <!-- viewer body end -->
  </nb-layout>
</div>
<ng-template #viewextractedtext let-data let-ref="viewextractedtext">
  <nb-card class="textextract">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h4 class="subtitle text-basic">
          Extracted Text
        </h4>
        <div>
          <nb-icon class="cursor-pointer" (click)="dialogReftextextract.close()" icon="close-outline"></nb-icon>
        </div>
      </div>
    </nb-card-header>

    <nb-card-body style="padding:12px 16px">
      <p *ngIf="ConfidenceValue <= 69.999 && ConfidenceValue >= 0" class="Text Font/S1 subtitle">Average confidence
        value:<span class="Text Font/S1 subtitle ml-1" style="color: #FF3D71;">Low</span></p>
      <p *ngIf="ConfidenceValue <= 84.999 && ConfidenceValue >= 70" class="Text Font/S1 subtitle">Average confidence
        value:<span class="Text Font/S1 subtitle ml-1" style="color: #FFAA00;">Medium</span></p>
      <p *ngIf="ConfidenceValue <= 100 && ConfidenceValue >= 85" class="Text Font/S1 subtitle">Average confidence
        value:<span class="Text Font/S1 subtitle ml-1" style="color: #00D68F;">{{ConfidenceValue}}%</span></p>

      <div class="textextbackcoclor pl-3 pr-3 pt-2 pb-2"
        [nbSpinner]="showSpinnertextext" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
        <div *ngFor="let gettextdata of getviewdata" class="pagecolortext">
          <p class="text-center mb-1 pagecolortext">
            Page {{gettextdata.pageno}}
          </p>
          <pre class="pagecolortext">
            {{gettextdata.extracteD_TEXT}}
          </pre>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!---------------------- printing a document -------------------------------------->
<ngx-print-document [selecteddDocument]="selectedDoc" [selectedFileNames]="selectDocumentName"></ngx-print-document>

<!---------------------- download a document -------------------------------------->
<ngx-download [selectedDocs]="downloadDoc"></ngx-download>


<!-- Share modal start here-->
<ngx-shared-access #shareChild [ownerEmailID]="ownerEmailID" [allUserLength]="allUserLength"
  [verifierEmailID]="verifierEmailID" [sharedWithUsers]="sharedWithUsers" [isAllUserShare]="isAllUserShare"
  (isSelectedAllUser)="isSelectedAllUser($event)" (sharedGroupCount)="sharedGroupCount($event)"
  (isCheckedAllUser)="isCheckedAllUser($event)" (sharedGroup)="sharedGroup($event)" (isUserGrp)="isUserGrp($event)">
</ngx-shared-access>
<!-- Share modal end here-->

<!-- Guest share modal start here -->
<ngx-guest-user-modal></ngx-guest-user-modal>
<!-- Guest share modal end here -->

<!---------------------------- Delete modal invoke start here------------------------------------->
<ngx-delete-docs [rightUserList]="rightUserList" [selectedDocs]="downloadDoc"></ngx-delete-docs>
<!---------------------------- Delete modal invoke end here------------------------------------->

<!-- Verification document modal start here -->
<ng-template #verify let-data let-ref="dialogRef">
  <nb-card class="invitemodal_2">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h4 class="subtitle text-basic">
          Verify document
        </h4>
      </div>
    </nb-card-header>
    <nb-card-body>
      <p>Do you want to verify your document?</p>
      <p> Your changes will be saved, your document will be added to the workspace and will be
        accessible to the users who have been given shared access.</p>
    </nb-card-body>
    <nb-card-footer>
      <button [nbSpinner]="showSpinnerVerify" nbSpinnerStatus="primary" nbSpinnerSize="tiny" nbButton status="primary"
        class="textbutton mr-2" size="small" (click)="saveDocDetails('verify')">CONFIRM</button>
      <button (click)="dialogRef.close()" size="small" class="cancelbtn">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- Verification document modal end here -->
<ngx-upgrade-modal></ngx-upgrade-modal>

<!----------------------Permanent Delete Document Modal Start----------------------------->
<ng-template #permanentDeleteDocModal let-data let-ref="dialogRef">
  <nb-card class="modalnbdelete">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h6 class="subtitle text-basic">
          Delete document(s)
        </h6>


      </div>
    </nb-card-header>
    <nb-card-body class="padding-25">
      <p class="paragraph text-basic">Choose a reason for deleting selected document(s) to keep other users in your
        workspace updated.</p>
      <nb-radio-group [(ngModel)]="selectedOption "  >
        <nb-radio value="Poor scan quality" (click)="valueChange('Duplicate document')"  ><p class="deleteradiobtn P1 paragraph mb-0">Poor scan quality</p></nb-radio>
        <nb-radio value="Incorrect document" (click)="valueChange('Duplicate document')"><p class="deleteradiobtn P1 paragraph mb-0">Incorrect document</p></nb-radio>
        <nb-radio value="Duplicate document" (click)="valueChange('Duplicate document')"><p class="deleteradiobtn P1 paragraph mb-0" >Duplicate document</p></nb-radio>
        <nb-radio value="Other" (click)="valueChange('Other')" ><p class="deleteradiobtn P1 paragraph mb-0">Other</p></nb-radio>

      </nb-radio-group>
      <input nbInput id="OtherReason" #OtherReason maxlength="75" placeholder="Reason for deleting document (optional)"
        class="d-block" style="width: 60%;" *ngIf="Otherreason">
    </nb-card-body>
    <nb-card-footer>

      <button id="deleteModal" class="mr-3" status="primary" nbButton
        (click)="deleteDocsModalWithReason(); ref.close();isdeleteSelectedOnce=false;HideViewDetailresponseForDelete=true; showSpinner = true;"
        size="small">DELETE</button>
      <button  (click)="ref.close();HideViewDetailresponseForDelete=true" status="control" nbButton
        class="controlbtndelt text-basic"><b>CANCEL</b></button>
    </nb-card-footer>

  </nb-card>
</ng-template>

<!---------------------- Permanent Delete Document Modal End Here----------------------------->

<!---------------------- Signal Refresh Modals Start Here----------------------------->
<ng-template #accountdisabled>
  <nb-card class="deleteModalClass">
    <nb-card-header>
      <div>
        <h5 class="text-basic">{{result[1]}}</h5>
      </div>
    </nb-card-header>
    <nb-card-body class="p-0 pl-4 prompt-body">
      <div>
        <p class="mb-3 mt-3 mr-4">
          {{result[0]}}
        </p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div>
        <button nbButton status="primary" class="textbutton" (click)="accoutClose(result[2],result[3])">Refresh</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

    <!--Doc Shared Real Time Notify-->
    <ng-template #docSharedNotify let-data let-ref="docSharedNotifyRef" class="purchaseDcoinsModal">
      <nb-card class="purchaseDcoinsModal">
          <nb-card-header>
              <div class="justify-content-between d-flex">
                  <h6 class="subtitle text-basic">
                    Documents shared with Guest
                  </h6>
                  <nb-icon  (click)="docSharedNotifyRef.close()" class="cursor-pointer" icon="close-outline"></nb-icon>
              </div>
      
          </nb-card-header>
          <nb-card-body>
         
                  <div >
                     
                              <div>
                                 <div>
                                      
                                          <span>
                                            {{docSharedRealTimeNotifyBody}}
                                          </span>
                                          <span>
                                            View <u (click)="NavigateOwnedBy(); docSharedNotifyRef.close();" style="color: blue; cursor: pointer;">Docs Owned</u>
                                          </span>
  
                                          <br>
                                          
                                          
                                  </div>
                              </div>

                              <br>
                      <p>
                          {{docSharedRealTimeNotifyFooter}}
                      </p>
      
                      </div>
                      


                      
      
                  
      
          </nb-card-body>
          <!-- <nb-card-footer>
              <div style="padding:16px 12px;">
                
              </div>
             
          </nb-card-footer> -->
      </nb-card>
      </ng-template>


<ng-template #accountdisabledverify>
  <nb-card class="deleteModalClass">
    <nb-card-header>
      <div>
        <h5 *ngIf="deleteDocModalMsg=='' " class="text-basic">{{result[1]}}</h5>
        <h5 *ngIf="deleteDocModalMsg!='' " class="text-basic">{{deleteDocModalHeading}}</h5>
      </div>
    </nb-card-header>
    <nb-card-body class="p-0 pl-4 prompt-body">
      <div>
        <p *ngIf="deleteDocModalMsg=='' " class="mb-3 mt-3 mr-4">
          {{result[0]}}</p>

        <div  *ngIf="deleteDocModalMsg!='' "  class="mb-3 mt-3 mr-4">
          <div [innerHTML]="deleteDocModalMsg"></div>
        </div>

      </div>
    </nb-card-body>
    <nb-card-footer>
      <div>
        <button nbButton status="primary" class="textbutton btn"
          (click)="accoutCloserealdelete(result[2],result[3])">Take me to dashboard</button>
          <button *ngIf="ownerTrashValue" nbButton status="primary" class="textbutton btn custom-di"
            (click)="onRedirectTrash(urlchange)">
            View Trash
          </button>

      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!---------------------- Signal Refresh Modals Ends Here----------------------------->


<!---------------------- Workspace out of sync start here----------------------------->

<ng-template #wsOutSync let-data let-ref="dialogRef">
  <nb-card class="invitemodal_2">
    <nb-card-header>Workspace out of sync
    </nb-card-header>
    <nb-card-body>
      <p class="mb-0">Your workspace went out of sync. There are some changes made which are not synchronized.
        Please reload this page before you continue.</p>
      <p class="mt-2 mb-0">This action cannot be completed as the document rights have been changed.
      </p>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="textbutton" (click)="reloadScreen();dialogRef.close()"
        size="small">RELOAD</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!---------------------- Workspace out of sync end here----------------------------->

<!---------------------- document delete modal start here----------------------------->

<ng-template #multiple let-data let-ref="dialogRef">
  <nb-card class="invitemodal_2">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <h4 *ngIf="this.documentStatusCheck == 'Deleted'" class="subtitle text-basic">
          Document not available
        </h4>
        <h4 *ngIf="this.documentStatusCheck == 'Verified'" class="subtitle text-basic">
          Cannot be verified
        </h4>
        <div>
          <nb-icon class="cursor-pointer" (click)="dialogRef.close()" icon="close-outline"></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <p *ngIf="this.documentStatusCheck == 'Verified'" class="mt-3 mb-3">This action cannot be completed as the
        document has
        already been
        verified.</p>
        <p *ngIf="this.documentStatusCheck == 'Deleted'" class="mt-3 mb-3">This document may have been moved to trash or
          permanently deleted
          & therefore cannot be accessed.
        </p>
      </nb-card-body>
      <nb-card-footer>
        <button *ngIf="this.documentStatusCheck == 'Verified'" nbButton status="primary" class="textbutton"
        (click)="dialogRef.close()" size="small" [routerLink]="['/pages/documents/my-queue']">OK</button>
        <button *ngIf="this.documentStatusCheck == 'Deleted'" nbButton status="primary" class="textbutton"
        (click)="dialogRef.close()" size="small" [routerLink]="['/pages/dashboard']">Take me to dashboard</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

  <!---------------------- document delete modal end here----------------------------->

  <!---------------------- document shared access revoked modal start here----------------------------->
  <ng-template #saveRightsChange let-data let-ref="dialogRef">
    <nb-card class="changeinvitemodal width-40vw">
      <nb-card-header>
        <h4 class="subtitle text-basic">Document Shared Access Revoked</h4>
      </nb-card-header>
      <nb-card-body>
        <p class="mb-0">You can not perform this action as the document is no longer shared with you.
        </p>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" class="textbutton" (click)="goBack()" size="small"
          >EXIT</button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <!---------------------- document shared access revoked modal end here----------------------------->

  <ng-template #unlinkDocument let-data let-ref="dialogRef">
    <nb-card style="width: 360px;">
        <nb-card-header>
            <div class="justify-content-between d-flex">
                <h6 class="subtitle mb-0"> Unlink document form Docket </h6>
            </div>
        </nb-card-header>
        <nb-card-body>
            <p class="paragraph text-basic">Are you sure you want to unlink this document from Docket-{{uniqueId}} ?</p>
        </nb-card-body>
        <nb-card-footer class="deleteModal-footer" style="text-align: end;">
            <button nbButton class="textbutton1 btn-padd"
                (click)="dialogRef.close()">CANCEL</button>
                <button nbButton status="primary" class="copybutton btn-padd" size="small"
                (click)="dialogRef.close();confirmUnlink();">UNLINK</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

</div>

  <!--Message Box to display msg for screen less then 768px-->
<!-- <div id="msgBox">
  <div class="d-flex flex-column align-items-center text-center" style="width:437px;">




  <div class="shadowBoxParent">
    <div 
    class="d-flex justify-content-center align-items-center iconbox">
    <nb-icon icon="monitor-outline" class="text-hint shadowboxicon"></nb-icon>
  </div>
  <div class="shadowBox"  >

  </div>
  </div>



    <p class="Text Font/P1 Paragraph  mt-3" style="padding: 0 20px ;">dox2U currently supports screen resolutions greater than 768px. Your current screen size is not supported and may prevent dox2U from functioning properly.
    </p>
    <button  class="btn_navigate" (click)="ProceedAnywaysClick()">PROCEED ANYWAYS</button>
  </div>
</div> -->

<!-- Message to display if browser is firefox or internet explorer-->

<div class="url" *ngIf="isFirefox">
  <div class="d-block">
    <div class="d-flex align-item-center justify-content-center">
      <img src="../../../assets/images/url.svg">
    </div>
    <div>
      <h4 class="headline d-flex align-item-center justify-content-center">Oops, we're sorry :(</h4> <br>
      <div style="width:510px">
        <p class="urlData"> dox2U is currently not supported on Mozilla Firefox. Our team is working hard to bring in compatibility for Firefox! </p> <br>
        <p class="urlData"> In the meantime, you can access your dox2U Workspace using Chrome, Safari or Edge</p>
      </div>

    </div>
  </div>

</div>
<div class="url" *ngIf="isIE">
  <div class="d-block">
    <div class="d-flex align-item-center justify-content-center">
      <img src="../../../assets/images/url.svg">
    </div>
    <div>
      <h4 class="headline d-flex align-item-center justify-content-center">Oops, we're sorry :(</h4> <br>
      <div style="width:510px">
        <p class="urlData">dox2U is currently not supported on Internet Explorer. Our team is working hard to bring in this compatibility!</p> <br>
        <p class="urlData"> In the meantime, you can access your dox2U Workspace using Chrome, Safari or Edge</p>
      </div>

    </div>
  </div>

</div>

