<!--/
*
* PRIVET VENTURES - CONFIDENTIAL
* __________________
*
* [2020] - [2021] Privet Ventures Pvt Ltd, India
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Privet Ventures Pvt Ltd and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Privet Ventures Pvt Ltd
* and its suppliers and may be covered by Indian and Foreign Patents,
* patents in process, Indian Patents Act 1970, and are protected by secret
* trade or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Privet Ventures Pvt Ltd.
*/
///-----------------------------------------------------------------
///  Class      : Dashboard Component
///  Description: Dashboard for the project
///  Owner      : Sumeet Goel
///  Author     : Aman Kumar
///  Created On : 10-02-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->


<div [hidden]="(loginFailed || SignupFailed) || (isFirefox || isIE) " class="parent" id="loginBox" >
  <nb-layout class="layout-class">
    <nb-layout-column class="layout-class">
      <router-outlet></router-outlet>
      <!------------------------------ parent div start here ---------------------------- -->
      <div class="cont">

        <div class="form sign-in" [ngClass]="[conditionalSignUp ? 'conditionalSubCont' : '']">
          <div class="col-md-12 col-lg-6 loginwidth col-xxxl-6 p-0" id="signUp-parent">
            <div class="signIn-container">
              <a href="https://dox2u.com/" target="blank" class="dox2u-logo" style="box-shadow: none !important;
           border: none !important;
           outline: none !important;"> <img class="signIn-img" src="/assets/images/dox2u_logo.png" alt=""></a>
            </div>
            <!-------------------------- card body for signIn page started Here  ----------------------->

            <nb-card class="nb-card-class p-0 m-0">
              <nb-card-body class="d-flex justify-content-center align-items-center heightParent"
                style="height: 100%; width: 100%;">
                <div class=" img-child-head">

                  <div class="img-logo" *ngIf="iconPathname !==null" img condition>
                    <img [src]="iconPath" alt="" class="img-style">
                  </div>

                  <p class="logo-style">{{wsName}}</p>
                </div>
                <div>
                  <h4 class="main-head-sign-In">Sign in</h4>
                  <p class="sign-in-first-para text-center ">It’s great to see you again!</p>
                  <div>
                    <form [formGroup]="signinForm" autocomplete="off">
                      <div class="d-flex flex-column">
                        <div class="email-input-parent">
                          <div class="right d-flex flex-row">
                            <!------------------------- this div is used to wrap upmthe right icon --------------------------->
                            <div class="label-float">
                              <input type="text" id="email" fullWidth #signininput (paste)="onPasteEmailsignin($event)"
                                formControlName="email" class="signIn-input-1 custom"
                                (keypress)="removeemail('email',$event);removeLanguage($event)"
                                (keyup)="changeButtonStatesignin('email',$event)" (keydown)="datasign('email',$event)"
                                [ngClass]="{'invalid-input': (signinf.email.invalid && changeEmailSignin) || (signinf.email.invalid && emailblank == 'not blank') || unauthorizedError || notRegisteredError || accountDisabledError || passChangeError}"
                                trim>

                              <label
                                [ngClass]="{'invalid-input-label': (signinf.email.invalid && changeEmailSignin) || (signinf.email.invalid && emailblank == 'not blank') || unauthorizedError || notRegisteredError || accountDisabledError || passChangeError || disableworkspace}">Email</label>
                            </div>
                            <div>
                              <nb-icon
                                [ngClass]="[((signinf.email.valid && (!unauthorizedError)) && (signinf.email.valid && !disableworkspace) && (signinf.email.valid && !notRegisteredError) && (signinf.email.valid && !accountDisabledError) && (signinf.email.valid && !passChangeError)) ? 'checkmarkcolor': '']"
                                class="checkmark-icon" icon="checkmark-circle-outline">
                              </nb-icon>
                            </div>
                            <div class=" right-icon" (keypress)="removeemail('arrow',$event);" #signininputarrow
                              (click)="Submitsigninonlyemail('arrowclick')">
                              <button id="signinarrow" class="box" nbButton size="small" status="primary">
                                <nb-icon class="button__text" icon="chevron-right-outline" style="height: 40px; width: 40px; border: none !important;
                              outline: none !important;"></nb-icon>

                              </button>
                            </div>
                          </div>

                          <p class="para-sign-in"
                            [hidden]="(unauthorizedError || disableworkspace || accountDisabledError || passChangeError || notRegisteredError || changeEmailSignin)">
                            Enter your Email
                          </p>
                          <!---------------------------- global error message -------------------------------------->
                          <p *ngIf="unauthorizedError || disableworkspace" class="errorMeassage para-sign-in">
                            <app-message message="errormsgnotautorized" feature="signin_form"></app-message>
                          </p>
                          <p *ngIf="accountDisabledError" class="errorMeassage para-sign-in">
                            <app-message message="erroraccountdisableuser" feature="signin_form"></app-message>
                          </p>
                          <p *ngIf="passChangeError" class="para-sign-in">
                            <span class="errorMeassage">
                              <app-message message="errorpassChangeError" feature="signin_form"></app-message>
                            </span>
                            <span class="errorMeassagesignuplink ml-1">
                              <p class="cursor-pointer text-primary mb-0 caption" (click)="gotoChangePW()">Change
                                Password</p>
                            </span>
                          </p>
                          <p *ngIf="notRegisteredError" class="para-sign-in">
                            <span class="errorMeassage">
                              <app-message message="errormessagenotregister" feature="signin_form"></app-message>

                            </span>
                            <span class="errorMeassagesignuplink ml-1 ">
                              <p class="cursor-pointer text-primary mb-0 caption" (click)="signintogglechange()">SignUp
                              </p>

                            </span>
                          </p>
                          <p *ngIf="containNonenglishSpecialCharacterEmailsignin && changeEmailSignin"
                            class="errorMeassage para-sign-in">
                            <app-message message="errormessageforspecialcharacteroronenglishsignin"
                              feature="signin_form">
                            </app-message>
                          </p>

                          <p *ngIf="signinf.email.invalid && changeEmailSignin" class="errorMeassage para-sign-in"
                            [hidden]="containNonenglishSpecialCharacterEmailsignin">
                            <app-message message="errormessageinvalidemail" feature="signin_form"></app-message>
                          </p>
                        </div>
                        <div [ngClass]="[passwordboxhidden ? 'hidepasswordfield': '']">
                          <div class="label-float">
                            <input type="password" [type]="getInputTypesignin()" #signininputpassword
                              (keypress)="removepassword('password',$event);removeLanguage($event)" fullWidth
                              formControlName="password" maxlength="60" placeholder=" " class="signIn-input-2 custom"
                              autocomplete="off" (keyup)="changepasswordsignin($event)"
                              [ngClass]="{'invalid-input': (signinf.password.invalid && passwordnotblank) || (passcharacter || isSignInFailed) || passwordnotblank || (signinf.password.invalid && (signinf?.password?.value?.length === 0 || signinf?.password?.value === ''))}">
                            <label
                              [ngClass]="{'invalid-input-label':  (signinf.password.invalid && passwordnotblank) || (passcharacter || isSignInFailed) || passwordnotblank ||(signinf.password.invalid && (signinf?.password?.value?.length === 0 || signinf?.password?.value === ''))}">
                              Password
                            </label>
                          </div>

                          <p class="para-sign-in"
                            *ngIf="((((!isSignInFailed)) && !passcharacter ) || passwordnotblank )">
                            Enter the password
                          </p>


                          <!-- <p class="errorMeassage para-sign-in" *ngIf="">
                        <app-message message="errorpasswordincharacters" feature="signin_form"></app-message><span class="ml-1">characters</span>
                      </p> -->

                          <p class="errorMeassage para-sign-in"
                            *ngIf="(isSignInFailed && !passwordnotblank)|| signinf.password.invalid && passcharacter">
                            <app-message message="errorpasswordincorrect" feature="signin_form"></app-message>
                          </p>

                        </div>
                        <div [ngClass]="[passwordboxhidden ? 'hidepasswordfield': '']">
                          <nb-icon class="checkmark-icon-1 cursor-pointer"
                            [icon]="showpasswordsignin ? 'eye-outline' : 'eye-off-outline'" pack="eva"
                            (click)="toggleShowPasswordsignin();blueIconFunction();"
                            [attr.aria-label]="showpasswordsignin ? 'hide password' : 'show password'"
                            [ngClass]="passwordIconColor ? 'checkmarkcolor':'' ">
                          </nb-icon>
                        </div>
                      </div>
                      <div class="stripe-1 d-flex flex-row text-primary mr-2">
                        <p class="forgot-paaword text-primary"><span class="cursor-pointer" (click)="forgot_password()">
                            Forgot password?</span></p>
                        <span [ngClass]="[passwordboxhidden ? 'hidepasswordfield': '']"
                          (keypress)="removeemail('Next',$event);" (click)="Submitsigninnext()" #signininputnext
                          [ngStyle]="{'pointer-events':eventPointer ? 'none' : 'auto' }" id="inputValue" style="border: none !important;
                    outline: none !important;">
                          <button id="signin1" nbButton size="small" status="primary"><span
                              class="button__text">NEXT</span></button>
                        </span>
                      </div>
                    </form>
                  </div>

                  <div class="conditional-signUp">
                    <p class="conditional-signUp-para">New to dox2U? <span class="blue-text">
                        <a class="ml-1 cursor-pointer" (click)="signIn();onContidinalSignIn()">Sign up</a></span></p>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>

            <!--------------------------------------  card body for signIn page Ends Here------------------------->
          </div>
        </div>

        <!---------------------This div is the child div of main div (class="cont") starting form here -------------->

        <div class="sub-cont" [ngClass]="[conditionalSignIn ? 'conditionalSubCont' : '']">

          <!--------------- div for image which is floating started here ------------------->



          <div class="img">
            <div class="img__text m--up">
              <div class="innerBox" [ngClass]=" wsStatus == 'Disabled' ? 'innerBoxCondition' : '' ">
                <div class="innerBox-content">
                  <h6>Join the new wave of document management!</h6>
                  <p [ngClass]=" wsStatus == 'Disabled' ? 'conditional-signUp' : 'BlockDisplay' ">New to this workspace?
                  </p>
                </div>
              </div>

            </div>
            <div class="img__text m--in">
              <div class="innerBox" [ngClass]=" wsStatus == 'Disabled' ? 'innerBoxCondition' : '' ">
                <div class="innerBox-content">
                  <h6>Join the new wave of document management!</h6>
                  <p>Already a member of this workspace?</p>
                </div>
              </div>
            </div>
            <div class="img__btn" [ngClass]=" wsStatus == 'Disabled' ? 'conditional-signUp' : 'BlockDisplay' ">
              <span class="m--up">Sign Up</span>
              <span class="m--in">Sign In</span>
            </div>
          </div>

          <!---------------- div for image which is floating ends here ----------------------------------->

          <div class="form sign-up" [ngClass]="[conditionalSignIn ? 'conditionalSubCont' : 'conditionalSubCont1']">
            <div class="steppers-container row m-0">
              <div class="SignUp-parent col-md-12 col-lg-6 loginwidth col-xxxl-6 p-0">
                <div class="signIn-container">
                  <a href="https://dox2u.com/" target="blank"> <img class="signup-img" id="signup-img"
                      src="/assets/images/dox2u_logo.png" alt=""></a>
                </div>
                <nb-card class="nb-card-class">
                  <!------------------- card body for signUp page started Here ------------------------------------------------->
                  <nb-card-body  class="heightPage" style="height: 100%; width: 100%;">

                    <div *ngIf="showSignupPage" class=" img-child-head">

                      <div class="img-logo" *ngIf="iconPathname !==null" img condition>
                        <img [src]="iconPath" alt="" class="img-style">
                      </div>

                      <p class="logo-style">{{wsName}}</p>
                    </div>

                    <div *ngIf="showSignupPage" class="text-center">
                      <h4 class="main-head">Sign up</h4>
                    </div>
                    <nb-stepper #stepper class="header p-0" [(selectedIndex)]="stepperIndex">
                      <nb-step [stepControl]="signUpForm" [hidden]="true" label="First step">
                        <form [formGroup]="signUpForm" class="step-container">
                          <p *ngIf="showSignupPage" class="sign-up-para text-center">We’re glad you’re here!</p>
                          <div *ngIf="showSignupPage" class="input-group">
                            <div class="d-flex flex-row justify-content-center">
                              <div class="signUp-label-float">
                                <input #signupinput id="input0" type="text" maxlength="99" placeholder="Full Name"
                                  (paste)="onPasteName($event)" class="form-control custom-input"
                                  (keypress)="removeSpecialCharacters($event)" (keyup.enter)="onEnter('name',$event)"
                                  (keyup)="changeButtonStateName($event)" (click)="datachange()" formControlName="name"
                                  autocomplete="off" [ngClass]="{'invalid-input': f.name.invalid && changeNameSignup}">
                                <label [ngClass]="{'invalid-input-label': f.name.invalid && changeNameSignup}">Full
                                  Name</label>
                                <p *ngIf="f.name.valid || !changeNameSignup || !minnamesignup || f?.name?.value?.length === 0 || f?.name?.value === ''"
                                  [hidden]="minnamesignup && f?.name?.value?.length > 0 && !containNonenglishSpecialCharacterName && (f?.name?.value !== '')"
                                  class="upper-para">Your name will help
                                  others identify you</p>
                                <p *ngIf="minnamesignup && f?.name?.value?.length > 0 && !containNonenglishSpecialCharacterName && (f?.name?.value !== '')"
                                  class="errorMeassage upper-para">
                                  <app-message message="errormessageformincharacters" feature="signup_form">
                                  </app-message>
                                </p>
                                <p *ngIf="containNonenglishSpecialCharacterName && changeNameSignup && minnamesignup && f?.name?.value?.length > 0"
                                  class="errorMeassage upper-para">
                                  <app-message message="errormessageforspecialcharacteroronenglish"
                                    feature="signup_form">
                                  </app-message>
                                </p>
                              </div>
                              <div>
                                <nb-icon [ngClass]="{'checkmarkcolor' : f.name.valid}" class="checkmark-icon-signup-1"
                                  icon="checkmark-circle-outline"></nb-icon>
                              </div>
                            </div>
                            <div class="d-flex flex-row justify-content-center">
                              <div class="signUp-label-float">
                                <input type="text" placeholder=" " class="form-control custom-input"
                                  (paste)="onPasteEmail($event)" (keypress)="removeLanguage($event)"
                                  (keydown)="onEnter('email',$event)" (keyup)="changeButtonStateEmail($event)"
                                  formControlName="email"
                                  [ngClass]="{'invalid-input': (f.email.invalid && changeEmailSignup) || alreadyRegistered || notAuthorized}"
                                  autocomplete="off" trim>
                                <label
                                  [ngClass]="{'invalid-input-label': (f.email.invalid && changeEmailSignup) || alreadyRegistered || notAuthorized}">Email</label>
                                <p *ngIf="(f.email.valid || !minEmailSignUp|| !changeEmailSignup || f?.email?.value?.length === 0 || f?.email?.value === '') && (!alreadyRegistered && !notAuthorized)"
                                  class="upper-para">Your email is used to log in to your workspace</p>
                                <p *ngIf="f.email.invalid && changeEmailSignup && minEmailSignUp && f?.email?.value?.length > 0 && !containNonenglishSpecialCharacterEmail && !notAuthorized && !alreadyRegistered"
                                  class="errorMeassage upper-para">
                                  <app-message message="errormessageforinvalidemail" feature="signup_form">
                                  </app-message>
                                </p>
                                <p *ngIf="alreadyRegistered" class="errorMeassage upper-para">
                                  <app-message message="errormessageforalredyexistingemail" feature="signup_form">
                                  </app-message><a class="errrorsignin cursor-pointer" (click)="toggleSignin()">Sign
                                    In</a>
                                </p>
                                <p *ngIf="notAuthorized" class="errorMeassage upper-para">
                                  <app-message message="errormessagefornotauthorizedemail" feature="signup_form">
                                  </app-message>
                                </p>
                                <p *ngIf="containNonenglishSpecialCharacterEmail && changeEmailSignup && f?.email?.value?.length > 0"
                                  class="errorMeassage upper-para">
                                  <app-message message="errormessageforspecialcharacteroronenglish"
                                    feature="signup_form">
                                  </app-message>
                                </p>

                              </div>
                              <div>
                                <nb-icon
                                  [ngClass]="{'checkmarkcolor' : f.email.valid && !alreadyRegistered && !notAuthorized}"
                                  class="checkmark-icon-signup-2" icon="checkmark-circle-outline"></nb-icon>
                              </div>
                            </div>

                          </div>
                          <div *ngIf="showSignupPage" class="stripe">
                            <button  id="next1" nbButton size="small" status="primary" class="float-right cursor-pointer"
                              (click)="onSubmitFirst()" [ngClass]="eventPointer3 ? 'pointerLess' : 'pointerFul'"><span
                                class="button__text">NEXT</span></button>
                          </div>
                          <nb-card-body *ngIf="signUp1" class="heightPage" style="height: 100%; width: 100%;">

                            <div class=" img-child-head">

                              <div class="img-logo" *ngIf="iconPathname !==null" img condition>
                                <img [src]="iconPath" alt="" class="img-style">
                              </div>

                              <p class="logo-style">{{wsName}}</p>
                            </div>

                            <div class="text-center">
                              <h4 class="main-head">Account not created</h4>
                            </div>
                             <div class="text-center">
                              <p class="text-center" style="size: 15px;">Your account could not be created since your <br> Workspace does not have any more user licenses <br> available. Please contact your Workspace Owner to buy<br> more users.</p>
                            </div>

                          </nb-card-body>

                          <div class="conditional-signUp">
                            <p class="conditional-signUp-para text-center">Already have an account? <span
                                class="blue-text">
                                <a class="ml-1 cursor-pointer" (click)="signUp();onContidinalSignUp()">Sign
                                  in</a></span></p>
                          </div>


                        </form>
                      </nb-step>
                      <nb-step [stepControl]="form" [hidden]="true" label="Second step">
                        <form [formGroup]="form" class="step-container">
                          <div class="input-group">
                            <div class="para-parent d-flex flex-column">
                              <p class="sign-in-para-otp">We’ve sent a 5 character code to <span class="blue-text"><a
                                    (click)="changeEmail()" class="cursor-pointer">
                                    {{ email }}</a></span>
                                <nb-icon class="edit-icon cursor-pointer" icon="edit-outline" (click)="changeEmail()">
                                </nb-icon>
                              </p>
                              <p class="sign-in-para-otp">The code expires shortly, so please enter it soon. <span
                                  class="blue-text">
                                  <a class="subtitle text-primary cursor-pointer"
                                    [ngClass]="{'inactive-resend' : disableResendButton}"
                                    nbPopover="We’ve sent you a new code" nbPopoverPlacement="bottom"
                                    (click)="resendnewOtp()">Resend Code</a>
                                </span></p>
                            </div>
                            <p class="sign-up-para-verification text-center text-basic ">VERIFICATION CODE :</p>
                            <div
                              class="d-flex d-flex flex-row justify-content-center align-items-center input-parent-div pb-3">
                              <div class="inputs  pt-1 " *ngFor="let input of formInput;  index as i">
                                <input class=" mr-4 text-center form-control rounded" #formRow type="text"
                                  formControlName="{{input}}" id="input{{i}}" maxlength="1"
                                  (keyup)="keyUpEvent($event, i)" (keypress)="removeSpecialCharactersCode($event)"
                                  (paste)="onPaste($event)">
                              </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                              <p [ngClass]="{'hide-error' : !showErrorMessage}"
                                class="errorMeassage upper-para input-parent-div p-1">
                                <app-message message="errormessageforwrongpassword" feature="signup_form"></app-message>
                              </p>
                            </div>
                            <div class="sign-in-para icons text-basic  d-flex flex-row justify-content-center">
                              <a href="https://mail.google.com/" class="blue-text text-decoration-none mr-1"
                                target="_blank">
                                <img class="social text-decoration-none"
                                  src="https://img.icons8.com/color/48/000000/gmail-new.png"
                                  style="height: 25px; width: 25px;" />Open Gmail</a>
                              <a href="https://outlook.live.com/" class="blue-text text-decoration-none"
                                target="_blank"> <img class="social text-decoration-none ml-4"
                                  src="/assets/images/outlook.png" style="height: 25px; width: 25px;" />Open
                                Outlook</a>
                            </div>
                            <p class="sign-in-para-bold">Can’t find your code? Check your spam folder!</p>
                          </div>
                          <!---------------------------------- loader in the verification page------------------------------------------>
                          <div [ngClass]="{'hide-error' : showErrorMessage || !verifySpinner}"
                            class="d-flex justify-content-center align-items-center verification-loader button--loading">
                          </div>

                        </form>

                      </nb-step>
                      <nb-step [stepControl]="passwordForm" [hidden]="true" label="Third step">
                        <form [formGroup]="passwordForm" class="step-container">
                          <p class="sign-up-header text-center">Secure your accesss with a password</p>
                          <div class="input-group">
                            <div class="d-flex flex-row justify-content-center m-2 ">
                              <div class="signUp-pass-label-float">
                                <input #passwordInput [type]="getInputType()" placeholder="password" maxlength="20"
                                  minlenght="6" (keypress)="removeLanguage($event)"
                                  (keyup)="checkPasswordStrength($event)" (keyup.enter)="onEnterPassword($event)"
                                  class="form-control custom-input mt-2" formControlName="password"
                                  onpaste="return false;" ondrop="return false;" autocomplete="off"
                                  onkeypress="return event.charCode != 32"
                                  [ngClass]="{'invalid-input': submitted && (!passwordLength || !containLowerCase || !containSpecialCharacter || !containNumber || !containUpperCase)}">
                                <label
                                  [ngClass]="{'invalid-input-label': submitted && (!passwordLength || !containLowerCase || !containSpecialCharacter || !containNumber || !containUpperCase)}">Password</label>
                                <p *ngIf="(passwordLength && containLowerCase && containSpecialCharacter && containNumber && containUpperCase) || !submitted || f1?.password?.value?.length === 0 || f1?.password?.value === ''"
                                  class="upper-para">A strong password keeps your data safe</p>
                                <p *ngIf="f1?.password?.value?.length > 0 && submitted && (!passwordLength || !containLowerCase || !containSpecialCharacter || !containNumber || !containUpperCase)"
                                  class="errorMeassage upper-para pt-2 mb-3">
                                  <app-message message="errormessageforpassword" feature="signup_form"></app-message>
                                </p>
                              </div>
                              <div>
                                <nb-icon [ngClass]="passwordIconColor ? 'checkmarkcolor':'' "
                                  class="checkmark-icon-signup-passward cursor-pointer"
                                  [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva"
                                  (click)="toggleShowPassword() ;blueIconFunction();"
                                  [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                                </nb-icon>
                              </div>
                            </div>

                            <div class="pass-list sign-in-para d-flex flex-row justify-content-center mt-3">
                              <ul class="text-basic password-validation-list-1 ml-0">
                                <li class="mb-2 d-flex align-item-center pass-validation-list"
                                  [ngClass]="{'blue' : passwordLength, 'normal' : !passwordLength, 'red' : (!passwordLength && submitted), 'errorpassword' : (!passwordLength && submitted)}">
                                  At least 6 characters</li>
                                <li class="mb-2 d-flex align-item-center pass-validation-list"
                                  [ngClass]="{'blue' : containLowerCase, 'normal' : !containLowerCase, 'red' : (!containLowerCase && submitted), 'errorpassword' : (!containLowerCase && submitted)}">
                                  One lower case a-z</li>
                                <li class="mb-2 d-flex align-item-center pass-validation-list"
                                  [ngClass]="{'blue' : containSpecialCharacter, 'normal' : !containSpecialCharacter, 'red' : (!containSpecialCharacter && submitted), 'errorpassword' : (!containSpecialCharacter && submitted)}">
                                  One special character</li>
                              </ul>
                              <ul class="password-validation-list-2 text-basic">
                                <li class="mb-2 d-flex align-item-center pass-validation-list"
                                  [ngClass]="{'blue' : containNumber, 'normal' : !containNumber, 'red' : (!containNumber && submitted), 'errorpassword' : (!containNumber && submitted)}">
                                  One number 0-9</li>
                                <li class="mb-2 d-flex align-item-center pass-validation-list" id="list-2"
                                  [ngClass]="{'blue' : containUpperCase, 'normal' : !containUpperCase,'red' : (!containUpperCase && submitted), 'errorpassword' : (!containUpperCase && submitted)}">
                                  One upper case A-Z</li>
                              </ul>
                            </div>
                            <!-- <p class="mt-2 pl-2 password-terms sign-in-para">By clicking next, you agree to the <span
                                (click)="terms()" class="blue-text cursor-pointer">
                                Terms & Conditions </span>
                              and the <span class="blue-text cursor-pointer" (click)="privacy()">
                                Privacy Policy</span></p> -->
                                <div>
                                <nb-checkbox status="basic" formControlName="agreeCheckbox" id="checkbox" class="agreeCheckbox" type="checkbox" name="defaultAccess">
                                  I agree to the <a href="https://dox2u.com/legal/users-terms" target="_blank" class="terms">&nbsp;Terms & Conditions</a>&nbsp;and the <a href="https://dox2u.com/legal/privacy-policy" target="_blank" class="terms">&nbsp;Privacy Policy</a> 
                                </nb-checkbox>
                              </div>
                          </div>
                          <div class="stripe-2">
                            <div class="prev box-outline d-flex justify-content-center align-item-center pt-1">
                              <p class="prev box-outline" (click)="goToFirst()">PREVIOUS</p>
                            </div>

                            <button id="next3" nbButton size="small" status="primary"
                              [ngStyle]="{'pointer-events':eventPointer2 ? 'none' : 'auto' }"
                              [disabled]="!passwordForm.get('agreeCheckbox').value"
                              (click)="submitOwnerProfile()"><span class="button__text">FINISH</span></button>
                          </div>
                        </form>
                      </nb-step>

                    </nb-stepper>
                  </nb-card-body>
                  <!------------------------------ card body for signUp page Ends Here--------------------------->
                </nb-card>
              </div>

            </div>
          </div>
        </div>


        <!---------------------------------This div is the child div of main div (class="cont") ending form here---------------------->
      </div>
    </nb-layout-column>
  </nb-layout>
</div>

<div class="url" *ngIf="isFirefox">
<div class="d-block">
  <div class="d-flex align-item-center justify-content-center">
    <img src="../../../assets/images/url.svg">
  </div>
  <div>
    <h4 class="headline d-flex align-item-center justify-content-center">Oops, we're sorry :(</h4> <br>
    <div style="width:510px">
      <p class="urlData"> dox2U is currently not supported on Mozilla Firefox. Our team is working hard to bring in compatibility for Firefox! </p> <br>
      <p class="urlData"> In the meantime, you can access your dox2U Workspace using Chrome, Safari or Edge</p>
    </div>

  </div>
</div>

</div>
<div class="url" *ngIf="isIE">
<div class="d-block">
  <div class="d-flex align-item-center justify-content-center">
    <img src="../../../assets/images/url.svg">
  </div>
  <div>
    <h4 class="headline d-flex align-item-center justify-content-center">Oops, we're sorry :(</h4> <br>
    <div style="width:510px">
      <p class="urlData">dox2U is currently not supported on Internet Explorer. Our team is working hard to bring in this compatibility!</p> <br>
      <p class="urlData"> In the meantime, you can access your dox2U Workspace using Chrome, Safari or Edge</p>
    </div>

  </div>
</div>

</div>
<!------------------------ parent div Ends here ---------------------------------->






<!---------tally successfull div and error also------------>
<div class="width100" *ngIf="loginFailed || SignupFailed">
<!-- left side container -->
<div class="width50 d-flex flex-column align-items-center justify-content-center" style="position: relative;">
  <div class="logoPosition">
    <img class="logo-img" src="/assets/images/dox2u_logo.png" alt="">
  </div>
  <div class=" img-child-head">

    <div class="img-logo">
      <img class="img-logo" src="../../../assets/images/delete-ws-side-image.png">
    </div>

    <p class="logo-style mb-0">workspacename</p>
  </div>
  <!-- conditional operation perform ngIf-->
  <div *ngIf="SignupFailed" class="text-center">
    <h5 class="Headline mb-3">Sign up successful</h5>
    <p class="subtitle">Your account has been successfully created. You can <br> now use dox2U's Tally Add-On.</p> <br><br>
    <p class="subsubtitle">However, since you were invited to this Workspace as a <br> Tally Viewer, you will not be able to directly access this <br> Workspace. </p>
  </div>
  <!-- conditional operation perform -->
  <div *ngIf="loginFailed" class="text-center" style="width: 405px;">
    <h5 class="Headline mb-3">Login Failed</h5>
    <p class="subtitle mb-4">You cannot access this Workspace since you have been assigned the Tally Viewer role.</p>
    <p class="subtitle mb-4">Before you can log in, please contact the administrator of your Workspace to change your
      role.</p>
    <p class="createWSSpace text-primary">Create a workspace</p>
  </div>
</div>
<!-- right side container -->
<div class="width50 rightimg d-flex flex-column align-items-center justify-content-center">
  <div class="rightsidesmalldiv d-flex align-items-center">
    <h4>Join the new wave of document management!</h4>
  </div>
</div>
</div>

<!------------------------  session termination modal starts here --------------------------->
<ng-template #sessionmanagement let-data let-ref="dialogRef">
<nb-card class="invitemodal width-40vw">
  <nb-card-body>
    <div class="main-modal-body">
      <div class="icon-div">
        <img src="assets/images/SESSION-TIMEOUT.svg" class="modal-img">
      </div>
    </div>
    <div>
      <h6 class="modal-heading">Session expired</h6>
      <p class="modal-paragraph mb-0">Since you were inactive for a while, the process for signing up </p>
      <p class="modal-paragraph mt-0">was terminated.</p>
      <p class="modal-paragraph-2 cursor-pointer" (click)="startAgainafterTimeout()">Start again</p>
    </div>
  </nb-card-body>
</nb-card>
</ng-template>
<!---------------------- session termination modal ends here---------------------------->

<!-------------------------- unsaved changes modal starts here ---------------------------------->
<ng-template #unsavedchanges let-data let-ref="dialogRef">
<nb-card class="invitemodal " style="width: 623px; height:319px;">

  <div class="d-flex flex-column justify-content-center align-items-center ">
    <div class="main-modal-body">
      <div class="icon-div">
        <img src="assets/images/unsaved_chnage.svg" class="modal-img">
      </div>
    </div>
    <div style="width: 65%; " class="d-flex justify-content-center align-items-center flex-column">
      <h6 class="modal-heading mb-0">Unsaved Changes</h6>
      <p class="modal-paragraph mb-0" style="margin-bottom: 12px !important;">Are you sure you want to leave this
        page? The details you entered here will be lost.</p>

      <div class="d-flex justify-content-center align-middle">
        <p class="modal-paragraph-2 cursor-pointer mr-4 mt-0" (click)="unsavedLeave()">Yes, leave</p>
        <p class="modal-paragraph-2 cursor-pointer mt-0" (click)="unsavedStay()">No, stay here</p>
      </div>

    </div>
  </div>

</nb-card>
</ng-template>
<!----------------------------  undsaved changes modal ends here ------------------------------>

<!--Message Box to display msg for screen less then 768px-->
<div  id="msgBox">
  <div class="d-flex flex-column align-items-center text-center" style="width:437px;">




  <div class="shadowBoxParent">
    <div 
    class="d-flex justify-content-center align-items-center iconbox">
    <nb-icon icon="monitor-outline" class="text-hint shadowboxicon"></nb-icon>
  </div>
  <div class="shadowBox"  >

  </div>
  </div>





    <p class="Text Font/P1 Paragraph  mt-3" style="padding: 0 20px ;">dox2U currently supports screen resolutions greater than 768px. Your current screen size is not supported and may prevent dox2U from functioning properly.
    </p>
    <button  class="btn_navigate" (click)="ProceedAnywaysClick()">PROCEED ANYWAYS</button>
  </div>
</div>