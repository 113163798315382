import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';


import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WorkspaceManagementService {

  createdBy: any;
  workSpaceId: any;
  baseURL: any;



  constructor(private apiService:ApiServiceService) {

    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')

   }

   public imageURL = '';
   bSubject = new BehaviorSubject("");

   setImageURL(val){
    this.bSubject.next(val);
   }

  //////// -------------------GENERAL SETTINGS------------------//////////////


   saveDisplayGuideline(data) {
     window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
    return this.apiService.post('/api/GeneralSettings/DisplayNameGuideline', data);
   }

  //    //   //************************ API for session Termination Duration API Get End ************************

    savesessionmanagement(data) {
      window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
      return this.apiService.post('/api/GeneralSettings/SessionIdealTime', data);

    }

    // Save General Settings Date And Time Format

    saveDateAndTime(data) {
      window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
      return this.apiService.post('/api/GeneralSettings/DateAndTimeFormat', data);

    }

    logochange(data) {
      window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
      return this.apiService.post(`/api/GeneralSettings/WorkspaceIcon`, data);

    }


     // Save General Settings DisplayNameGuideline

     saveDisplayEmail(data) {
      window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
      return this.apiService.post('/api/GeneralSettings/DisplayEmailAndContact', data);

    }

    //Save Guest Share Settings
    guestUserRights(data){
      return this.apiService.post('/api/Mock/GetUserRights',data)
    }

    // general settings Timezone start

    saveGeneralSettingsTimezone(data){
      window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
      return this.apiService.post('/api/GeneralSettings/Timezone', data);
    }

    getTimezone(data) {
        return this.apiService.get('/api/TimeZone');
    }

     // general settings validate sub domain access

     validateSubDomain(data){
      return this.apiService.post('/api/SubDomain/Validate', data);
    }

     // generalSettings Ws Name AndUrl start here
      saveWsNameAndUrl(data) {
        window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
        return this.apiService.post('/api/GeneralSettings/WsNameAndUrl', data);
      }
     // send data to IO
     GetWSGuestDetailsList(data) {
        return this.apiService.post('/api/GuestAccess/GetWSGuestDetailsList', data);
      }

    //  Save domain access start here

      saveDomain(data) {
        window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
        return this.apiService.post('/api/GeneralSettings/DomainInvitation', data);
      }


      // ValidateTrashBinSize  start here

      validateTrashBinSize(data) {
        return this.apiService.post('/api/GeneralSettings/ValidateTrashBinSize', data);
      }

      // Trash Duration And Size start here

      saveTrashDurationAndSize(data){
        window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
        return this.apiService.post('/api/GeneralSettings/TrashDurationAndSize', data);
      }


      //  Audit Log Duration
      saveAuditLogDuration(data) {
        window?.productFruits?.api?.checklists.markItemAsDone( 'ws_management', true);
        return this.apiService.post('/api/GeneralSettings/AuditLogDuration', data);
      }

       // General Settings data Fetch
      fetchGeneralSettingData(data) {
        return this.apiService.post('/api/GeneralSettings/Fetch', data);
      }
       // General Settings data Fetch
      GetAuditActivityList() {
        return this.apiService.get('/api/Workspace/GetAuditActivityList');
      }

      // validate Common Domain
      validateCommDomainService(data){
        return this.apiService.post('/api/GeneralSettings/ValidateCommDomain', data);
      }
      

      // remove logo from workspace Icon
      iconImage: any;
      removeIconService(data){
        let params = new HttpParams();
        params = params?.append('Wsoid', data.Wsoid);
        params = params = params?.append('WsLink', 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D');
        params = params?.append('OriginalName', data.OriginalName);
        params = params?.append('ModifiedBy', data.ModifiedBy);
        params = params?.append('guid', data.guid);
          
        return this.apiService.postDataWithParams('/api/GeneralSettings/RemoveWorkspaceIcon', data,params);
      } 



 //////// ------------------- DOCUMENT SETTINGS API'S-----------------------////////////////////////

   // Get Documents Details start here 

   getDocumentsDetailsService(data) {
    return this.apiService.post('/api/DocumentSettings/Fetch', data);
  }

  
  // Get Global Documents Details start here 

  getGlobalDocumentsDetailsService(data) {
    return this.apiService.post('/api/DocumentSettings/GetWSGlobalDocumentSettings', data);
  }

 // update maximum document size start here 

 updateMaximumDocumentSizeService(data) {
  return this.apiService.post( '/api/DocumentSettings/UploadMaxSize', data);
}

  // update maximum document upload settings start here

  updateMaxDocumentUploadService(data) {
    return this.apiService.post('/api/DocumentSettings/MaxFileUpload', data);
  }

 // update maximum document download settings start here 

 updateMaxDocumentDownload(data) {
   return this.apiService.post('/api/DocumentSettings/MaxFileDownload', data);
}

public getfeaturews(): Observable<any>  {
 return this.apiService.get('${this.baseURL}/api/GetFeatureFlaging/GetFeatureFlaging');
}

  // Menu Rights List

  menuRightsService(data){
    return this.apiService.post('/api/Workspace/MenuRights', data);
  }





}



