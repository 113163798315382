import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrdersChart, OrdersChartData } from '../data/orders-chart';
import { OrderProfitChartSummary, OrdersProfitChartData } from '../data/orders-profit-chart';
import { ProfitChart, ProfitChartData } from '../data/profit-chart';
import { DocChart, DocChartData } from '../data/doc-chart';
import {SharedChart, SharedChartData } from '../data/shared-chart';

@Injectable()
export class OrdersProfitChartService extends OrdersProfitChartData {

  constructor(private ordersChartService: OrdersChartData,
              private profitChartService: ProfitChartData,
              private DocChartService :DocChartData,
              private SharedChartService :SharedChartData) {
    super();
  }

  getOrderProfitChartSummary(summary): Observable<OrderProfitChartSummary[]> {
    return observableOf(summary);
  }

  getOrdersChartData(period: string): Observable<OrdersChart> {
    return observableOf(this.ordersChartService.getOrdersChartData(period));
  }

  getProfitChartData(period: string): Observable<ProfitChart> {
    return observableOf(this.profitChartService.getProfitChartData(period));
  }
  getDocChartData(period: string): Observable<DocChart> {
    return observableOf(this.DocChartService.getDocChartData(period));
  }
  getSharedChartData(period: string): Observable<SharedChart> {
    return observableOf(this.SharedChartService.getSharedChartData(period));
  }
}
