
import { Component, HostListener, Inject, Input, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { NbWindowControlButtonsConfig,NbWindowState, NbWindowRef, NbWindowService, NbDialogRef, NbDialogService} from '@nebular/theme';
import { DownloadService } from '../../@core/download.service';
import { DownloadComponent } from '../download/download.component';
import { SharedService } from '../../@core/utils/shared.service'

import {PrintService} from '../../@core/utils/print.service'
import { ApiIntegrationService } from '../../../app/pages/api-integration.service';
@Component({
  selector: 'ngx-print-document',
  templateUrl: './print-document.component.html',
  styleUrls: ['./print-document.component.scss']
})
export class PrintDocumentComponent implements OnInit {

   /**
   * variable selected document array
   */
   selectedDocs = [];
     /**
   * view child decorator for close on ESC
   */
  @ViewChild('escClose', { read: TemplateRef }) escCloseTemplate: TemplateRef<HTMLElement>;
    /**
   * view child decorator for disable ESC
   */
  @ViewChild('disabledEsc', { read: TemplateRef }) disabledEscTemplate: TemplateRef<HTMLElement>;
    /**
   *view child decorator for content template
    */
  @ViewChild('contentTemplate') contentTemplate: TemplateRef<any>;
  /**
   * view child decorator for unsaved change
   */
  @ViewChild('unsavedchanges', { read: TemplateRef }) unsavedchanges: TemplateRef<any>;
    /**
   * view child decorator for download Child
   */
  @ViewChild(DownloadComponent) downloadChild : DownloadComponent;

    /**
   * Input decorator for selected document
   */
  @Input() selecteddDocument;
   /**
   * Input decorator for Number of selected document
   */
  @Input() numberofDocsSelected;
   /**
   * Input decorator for user rights lists
   */
  @Input() rightUserList;
   /**
   * Input decorator for list of document
   */
  @Input() doclist;
   /**
   * Input decorator for selected document Name
   */
  @Input() selectedFileNames;
   /**
   *variable for workspace owner who is created the workspace
   */
   createdBy: string;
  /**
   * variable for workspace Id
   */
  workSpaceId: string;
  /**
   * variable for workspace Name
   */
  wsName: string;
   /**
   * variable for User FullName
   */
  userfullname: string;
   /**
   * variable for Guid
   */
  getguid: string;
   /**
   * variable for parser flag data
   */
  parserflagdata: string;
   /**
   * variable for selected Renamed file Name
   */
  selectedRenamedFilesName: any
    /**
   * variable for selected Document ID
   */
  selectedDocId: any;
  /**
   * variable for selected Original File Name
   */
  selectedDocOriginalName: [];
  /**
   * variable for  Renamed file Name
   */
  renamedFileName: any;
  /**
   * variable for selected Guid
   */

  selectedDocGuidId: any[];
  /**
   * variable for document owner searchable pdf
   */
  docownersearchablepdf: any;
  /**
   * variable for document Array
   */
  printDownloadArray:any[];
   /**
   * variable for document Array
   */
  downloadArrayName: any[];
   /**
   * variable for hiding queue window
   */
  hidewindow= false;
   /**
   * variable for printed document Name
   */
  printedDocumentName: string;
   /**
   * variable for dialogue ref to close modal
   */
  dialogRef: any;
   /**
   * variable for row data of the document
   */
  rowData: any;
   /**
   * variable for error state
   */
  errorState: boolean;
   /**
   * variable for error case
   */
  changeText:boolean = false;
  errorCase: boolean;
  leaveMouse: boolean;
  userEmailName: string;
  constructor(private printService:PrintService, @Inject(ApiIntegrationService) private readonly apiService: ApiIntegrationService,
     private windowService: NbWindowService,private dialogService: NbDialogService, @Optional() private windowRef: NbWindowRef ,@Inject(DownloadService) private downloadData: DownloadService, @Optional() private userSettingsRef: NbDialogRef<any>, private sharedService : SharedService ) {
    this.createdBy = localStorage.getItem('localloginId')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.getguid = localStorage.getItem('loginguid')
    this.parserflagdata = localStorage.getItem('parserflag');
    this.userEmailName=localStorage.getItem('changepasemail');
   }

  ngOnInit(): void {
    this.sharedService.IsPrintDone.subscribe((res:any)=>{
      this.selectedDocs.forEach(element => {
        if(element.fileName === res.fileName){
          element.loader = false;
          if(element.loader === res.loader){
           setTimeout(() => {
             const p = document.querySelectorAll('.productfruits--container')[0].shadowRoot;
             p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'flex';
             this.windowRef.close();
             this.alreadyopenwindow=false;
          }, 7000);
         }
        }
      });
    },(err:any) => {

     this.errorCase = true;
   })
  }
/**
 *
 * @param $event for window reload , prevent the unsaved changes
 */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if(this.alreadyopenwindow){
      $event.returnValue = true;
    }
      // this.openModal(this.unsavedchanges, false);

  }
  printLoading:boolean;
  alreadyopenwindow = false;
  close = true;
  /**
   * method for queue window started here
   */
  openWindow(data){
    const p = document.querySelectorAll('.productfruits--container')[0].shadowRoot;
    p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'none';
    // this.printLoading =true;
    if(!this.alreadyopenwindow){

    const buttonsConfig: NbWindowControlButtonsConfig = {
      minimize: true,
      maximize: true,
      fullScreen: false,
    }
     this.sharedService.IsPrintDone.subscribe((res:any)=>{
       this.selectedDocs.forEach(element => {
         if(element.fileName === res.fileName){
           element.loader = false;
           if(element.loader === res.loader){
            setTimeout(() => {
              const p = document.querySelectorAll('.productfruits--container')[0].shadowRoot;
              p.querySelectorAll<HTMLElement>('.productfruits--lifering')[0].style.display = 'flex';
              this.windowRef.close();
              this.alreadyopenwindow=false;
           }, 7000);
          }
         }
       });
     },(err:any) => {

      this.errorCase = true;
    })

     this.windowRef=  this.windowService.open(
      this.contentTemplate,

      {
        title: `Document(s) being fetched for printing...`,
        closeOnBackdropClick: false,
        closeOnEsc: true,
        buttons: buttonsConfig,
        initialState:NbWindowState.MAXIMIZED},
    );
  this.windowRef.onClose.subscribe((res) => {
    this.alreadyopenwindow = false;

  });
    this.alreadyopenwindow = true;
      }



  }

  popDocument(data){
  for(let i=0;i<=this.selectedDocs.length-1;i++){
    if(this.selectedDocs[i].docId == data.docId){
      this.selectedDocs.splice(i, 1);
    }
  }
  if(this.selectedDocs.length === 0){
    this.changeText = false;
   setTimeout(() =>{
    this.windowRef.close();
    this.alreadyopenwindow=false;
   },5000)

  }

  }


  /**
   * method for queue window Ends here
   */
  printArrayName: any[];

   /**
   * method for fetching the selected documents details from particular components started here
   */
  checking(data){

     this.selectedDocs.unshift(data);
     if(!this.alreadyopenwindow){
      this.openWindow(data);

     }
  }

  /**
   * method for fetching the selected documents details from particular components Ends here
   */

  /**
   * method for opening / closing modal started here
   */
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false
    });

  }

/**
   * method for opening closing modal Ends here
   */

}

