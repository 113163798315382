<!--/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Upload Component
///  Description: Upload component in random uplaod
///  Owner      : Sumeet Goel
///  Author     : Trisha pathak
///  Created On : 27-07-2021
///  Notes      : <Notes>
///  Revision History: Yes
///  Name: Abhishek Kumar             Date: 07/07/2023            Description: Removal of upload limit no of documents
///
///------------------------------------------------------------------->
<ngx-upgrade-modal></ngx-upgrade-modal>

<button nbButton  [disabled]="!isDocumentUpload || uploadStorageExhaust" (click)="Upload(uploadModal) " >Upload Documents</button>
  <!--modal popup for daily upload limit  start-->
  <ng-template #uploadLimit let-data let-ref="uploadLimitRef">
    <nb-card class="uploadModal">
      <nb-card-header>
        <div class="justify-content-between d-flex">
          <div class="subtitle text-primary">
            Daily Upload Limit Exceeded
          </div>
          <div>
              <nb-icon class="cursor-pointer" (click)="uploadLimitRef.close()" icon="close-outline"></nb-icon>
          </div>
      </div>
      </nb-card-header>
      <nb-card-body>
        <div class="col-10 pl-0">
          <p >The daily upload limit in your workspace has been exceeded.
            Upgrade to upload more documents and get the most out of dox2U.</p>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="primary" (click)="uploadLimitRef.close()" size="small">Ok</button>

      </nb-card-footer>
    </nb-card>
  </ng-template>

<!--modal popup for daily upload limit  end-->

<!-- WS STORAGE MODAL  starts-->

<ng-template #workspaceStorage let-data let-ref="workspaceStorageRef">
  <nb-card>
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <div class="subtitle text-primary">
         Workspace Storage Full
        </div>
        <div>
            <nb-icon class="cursor-pointer" (click)="workspaceStorageRefclose()" icon="close-outline"></nb-icon>
        </div>
    </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-10 pl-0">
        <p class="paragraph text-basic">Your workspace storage is full. Upgrade to upload more documents
          and get the most out of dox2U.</p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" (click)="workspaceStorageRef.close()" size="small">Ok</button>

    </nb-card-footer>
  </nb-card>
</ng-template>
<!-- WS STORAGE MODAL ends-->
<!-- Upload Main modal -->
<ng-template #uploadModal let-data let-ref="uploadRef">
  <nb-card class="uploadModal">
    <nb-card-header class="d-flex justify-content-between paddingCustom pl-4">
      <p class="text-basic headline-5 mb-0 uploadHeading">Upload Document(s) </p>
      <button nbButton class="bg-transparent border-0 p-0 shadow-none cursor-pointer" (click)="cancelRandomUpload();closeUploadmodal(); ToggleUploading('pause');">
        <nb-icon class="cursor-pointer" icon="close-outline"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="p-0 uploadParentClass">
      <!-- verifier and owner select -->
       <div class="parentdiv">
         <div class="row no-gutters">
            <div class="col-4 rightline" >
              <div class="col-12 pl-4 pt-3 pb-2 pr-4">
                <label for="verifier" class="mb-0 pr-2 underlinedata fontsizedata"
                nbTooltip= "Someone designated to cross-check, classify, add details to documents being and save them to your workspace. This
                could be you or any other user." nbTooltipPlacement="right"  nbTooltipStatus="info"
                >VERIFIER</label>

              </div>
              <div class="col-12 pl-4 pr-4">
                <div>
                  <ng-select [items]="verifierauto" class="focusStyle"
                  placeholder="Select Verifier"
                  bindLabel="displayName"
                  [closeOnSelect]="true"
                  bindValue="displayName"
                  (change)="onSelectionChange($event)"
                  [disabled]="dropDownOptionDisable"
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="selectedVerifierName?.displayName">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <p class="subtitle text-basic mb-0" title={{item.emailId}} >{{item.displayName}}</p>
                </ng-template>
                  </ng-select>

                </div>

              </div>

              <div class="col-12 pl-4 pt-3 pb-2 pr-4">
                <label for="owner" class="fontsizedata mb-0 pr-2 underlinedata"
                nbTooltip= "Someone from your team to have complete control over these documents.
                 This could be you or any other user." nbTooltipPlacement="right"  nbTooltipStatus="info">OWNER</label>

              </div>
              <div class="col-12 pl-4 pr-4">
                <ng-select [items]="ownerauto" class="focusStyle"
                placeholder="Select Owner"
                bindLabel="displayName"
                [disabled]="dropDownOptionDisable"
                [closeOnSelect]="true"
                bindValue="displayName"
                (change)="onOwnerChange($event)"
                [ngModelOptions]="{standalone: true}"
                [ngModel]="selectedDocumentOwner?.displayName">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <p class="subtitle text-basic mb-0" title={{item.emailId}} >{{item.displayName}}</p>
              </ng-template>
                </ng-select>
              </div>


              <!-- <div class="col-12 pl-4 pt-3 pr-4" *ngIf="parserflagvalue == 'True'">
                    <label for="language" class="fontsizedata  pr-2 underlinedata"
                    nbTooltip= "For the purpose of extracting text from scanned images being uploaded, an appropriate language selection helps with better results. Or you can
                    choose to leave it to us." nbTooltipPlacement="right"  nbTooltipStatus="info"
                    >LANGUAGE</label>
      <div class="d-flex">
                <nb-radio-group style="width: 8%;" >
                  <nb-radio [disabled]="languageRadioButton" (click)="getradiobtn('ManualLanguage')"  class="txtchk ml-0">  </nb-radio>

                 </nb-radio-group>
                 <p class="ml-2 mb-2 langClass">Use this language:</p>
                </div>

                  <div class="col-12 p-0">
                      <ng-select [items]="languageauto" class="focusStyle"
                      placeholder="Select Language"
                      bindLabel="languageName"
                      [closeOnSelect]="true"
                      bindValue="languageName"
                      (change)="onLanguageChange($event)"
                      [disabled]="dropDownOptionDisable"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="selectedlanguage">
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <p class="subtitle text-basic mb-0">{{item.languageName}}</p>
                    </ng-template>
                      </ng-select>
                  </div>
      <div class="pt-3">
        <div class="d-flex">
                <nb-radio-group style="width: 8%;">

                //  this is use for disable the Autodetect mode
                  <nb-radio  disabled (click)="getradiobtn('Autodetect')" checked class=" txtchk ml-0"></nb-radio>

                  </nb-radio-group>
                  <div class="d-flex flex-column">
                  <p class="langClass-1 text-disabled ml-2 mb-1">Auto detect:</p>
                  <p class="paragraph-1 text-disabled ml-2 mb-1"> (This may take a bit longer)</p>
                </div>
                </div>
                </div>



              </div> -->
              <hr >
              <div class="uploadAndLink">
              <div class="col-12 pl-4 pb-2 pr-4">
                <button nbButton class="bg-transparent w-100 backcolor"
                nbTooltip= "Map your physical file storage information (Coming soon)" nbTooltipPlacement="bottom"  nbTooltipStatus="info">
                  Link filing cabinet
                </button>
              </div>

              <div class="col-12 uploadbtn pl-4 pr-4" [hidden]="fileUploadPrgressBtn">
                <button type="submit" nbButton status="primary" size="medium" [ngClass]="(btnUpload==true)?'btn-secondary':'prime-btn'" class="mr-2 w-100"
                [disabled]="btnUpload"  (click)="uploadSubmit('val');reserveFileSpaceStorageCount()">Upload</button>
              </div>
            </div>
              <div class="col-12 d-flex pl-0 pr-0 uploadbtn pl-3" >
                <div class="p-0" [hidden]="!fileUploadPrgressBtn">
                <div *ngIf="this.selectedVerifierName.displayName.includes('(you)')">
                  <div class="toottip">
                    <div class="polybox"></div>
                    <p class="tooltipContent">Documents must be Verified before they can be added to your WS</p>
                  </div>
                  <button type="submit" status="primary" class="mr-3 gotomyqueue" nbButton (click)="viewUploadedDocument(); closeUploadmodal()" size="medium">Go to my queue</button>
               </div>
               <div *ngIf="!this.selectedVerifierName.displayName.includes('(you)')">
                <div class="toottip1">
                  <div class="polybox1"></div>
                  <p class="tooltipContent">Documents must be Verified by assigned verifier before they can be added to your WS</p>
                </div>
                <button type="submit" status="primary" class="mr-3 gotomyqueue" nbButton (click)="uploadRef.close(); closeUploadmodal();" size="medium" routerLink="/pages/dashboard">GO TO DASHBOARD</button>
             </div>
                </div>
                <div class="p-0" [hidden]="!fileUploadPrgressBtn">
                  <!-- <button status="primary" class="mr-3" nbButton  >Close</button> -->
                  <button type="submit"  nbButton (click)="uploadRef.close(); closeUploadmodal()" class="gotomyqueueclose" size="medium">CLOSE</button>

                </div>

                </div>
            </div>
            <div class="col-8 paddingchanges" >
               <div class="col-12 d-flex justify-content-between mt-0 mb-2 ml-0 mr-0 p-0">
                 <div class="supportdata"  nbTooltip={{formats}} nbTooltipPlacement="right"   nbTooltipStatus="info">
                  Formats supported
                 </div>

               <div>
                 <span class="maximTransform">
                Maximum file size:  </span>{{maximumFileSize}} {{maximumFileSizeUnit}}
               </div>
               </div>

               <div class="col-12 p-0"  [ngClass]="[files.length == 0 ? 'bordervalue': 'withoutborder']">
                <div class="drop-Area max-10" id="dropTarget" ngxFileUpload (fileDropped)="onFileDropped($event)">
                  <input type="file" #fileDropRef id="fileDropRef" multiple
                      (change)="fileBrowseHandler($event.target.files);" (click)="clearData($event)"
                      accept={{formatAvailable}} />
                      <div class="files-list">

                      <div class="col-12 pl-0 pr-0" *ngFor="let file of files; let i = index">
                        <div class="single-file col-12 d-flex mb-0 ml-0 pl-0 pr-0" style="width:100%;">
                          <div style="width:100%" class="col-11 pl-0">
                          <div class="info col-11 mb-0">
                            <div style="width:95%">
                            <span class="name" title="{{ file?.name || null  }}" >
                              <!-- <span class="name" title="{{ file?.name ?formatName(file.name):null  }}" > -->
                                <!-- {{ file?.name ? formatName(file.name):null }} -->
                                {{ file?.name || null }}
                            </span>
                          </div>
                          <div>
                            <span class="size mr-0">
                                ({{ formatBytes(file?.size) }})
                            </span>
                          </div>
                          </div>
                      </div>
                        <div class="col-1 pr-0 pl-0">
                          <p class="info-icon mb-0 cursor-pointer d-flex justify-content-end">
                              <nb-icon icon="close-outline"
                                [hidden]="crossIconwDisable[i] || fileformatenotsupport[i] || namenotmatch[i]" (click)="clickOnCross(i); ToggleUploadingNew('pause', i)"></nb-icon>
                              <nb-icon icon="checkmark-circle-outline" status="primary" [hidden]="checkIconwDisable[i] || namenotmatch[i] || protectedfile[i]"></nb-icon>

                              <span [hidden]="!isError[i]">
                                <nb-icon icon="alert-triangle-outline" status="danger" [hidden]="errorIconDisable[i]" nbTooltip="File size is too large" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>

                              </span>
                              <span [hidden]="checkIconwDisable[i] || namenotmatch[i]">
                                <nb-icon icon="alert-triangle-outline" *ngIf="protectedfile[i]"  status="danger" nbTooltip="File is locked/corrupt" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>

                              </span>
                              <span *ngIf="fileformatenotsupport[i]">
                                <nb-icon icon="alert-triangle-outline" status="danger" nbTooltip="File type not supported" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>
                              </span>
                              <span *ngIf="invalidFiles[i]">
                                <nb-icon icon="alert-triangle-outline"  status="danger" nbTooltip="Invalid file" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>
                         
                              </span>
                              <span *ngIf="internetFaliures[i]">
                                <nb-icon icon="alert-triangle-outline"  status="danger" nbTooltip="No internet found" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>
                         
                              </span>
                              <span *ngIf="apiFilesError[i]">
                                <nb-icon icon="alert-triangle-outline"  status="danger" nbTooltip="Error from server" nbTooltipStatus="danger" nbTooltipPlacement="right"></nb-icon>
                         
                              </span>
                              <span *ngIf="namenotmatch[i]">
                                <nb-icon icon="checkmark-circle-outline" status="warning" nbTooltip="File name changed" nbTooltipStatus="warning" nbTooltipPlacement="right"></nb-icon>
                          <!-- <span class="error-massage" [hidden]="!protectedfile[i]" >
                            File is corrupt
                        </span> -->

                              </span>

                          </p>
                        </div>
                        </div>
                        <div class="col-12 pl-0">
                          <span *ngIf="isProgressShow"  [hidden]="(isError[i]) || protectedfile[i] || (files[i].progress == 100) || (fileformatenotsupport[i])">
                            <progress id="files[i]['count']" value={{files[i].progress}} min="0" max="100"> {{files[i].progress}} </progress>

                        </span>
                        </div>


                      </div>
                  </div>
                  <div class="text-center drop-image mt-3 cursor-pointer" id="dropImage" [hidden]="files.length > 0" (click)="onSelectFileClick()">
                      <img draggable="false" src="../../../../assets/Drop-Area-Image.svg" alt="" >
                      <p  class="mb-0">Drop your files here</p>
                  </div>

                  <div class="col-12 Success-massage-box ">
                </div>
              </div>
               </div>
               <div class="col-12 d-flex justify-content-between mt-2 mb-0 ml-0 mr-0 p-0">
                <div [hidden]="!dpageOverError">
                 <b>Number of files : {{totalFileCount}}</b>
                </div>
                 <div [hidden]="dpageOverError">
                  <p [ngClass]="(dpageOverError==false)?'subtitle text-danger':'subtitle text-danger'"
                 class="m-0" >Remove files to stay within workspace Storage limit</p>
                 </div>
                <div [hidden]="fileUploadPrgressBtn || hidechoosefile">
                  <div [ngClass]="[selectFilesButton==false ? 'btn-File' : 'btn-Select-file', disableChoose ?  'inactive-resend' : '']"
                  (click)="onSelectFileClick()" class="filedata" [disabled]="selectFilesButton || disableChoose">
                  Choose files
                </div>
                </div>

               </div>
            </div>
         </div>
       </div>
       <!-- </div> -->
    </nb-card-body>
  </nb-card>
</ng-template>

<!--modal popup for Cancel random Upload Document start-->

<ng-template #cancelUpload let-data let-ref="canceluploadRef">
  <nb-card class="uploadModalcancel">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <div class="subtitle text-basic">
          Cancel Upload
        </div>

    </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-10">
        <p class="text-paragraph-font-size mb-4">Are you sure you want to cancel uploading your files? </p>
        <p class="text-paragraph-font-size mb-2">Files which have not been uploaded or have only been partially uploaded will be lost</p>
        <p class="caption text-basic text-danger" [hidden]="!btnRemoveAllFile">
          The upload is complete and cannot be canceled.
        </p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-2" [disabled]="btnRemoveAllFile"  (click)="confirmCancelUploadModal()" size="small">Yes, cancel</button>
      <button  nbButton (click)="canceluploadRef.close()" class="noreturndata" size="small" (click)="ToggleUploading('start')">NO, RETURN</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<!--modal popup for Cancel random Upload Document end-->


<!-- <ng-template #notshow let-data let-ref="notshowagain">
  <nb-card class="uploadModalcancel">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <div class="subtitle text-primary">
          Verify documents
        </div>

    </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-12">
        <p>
          Uploaded  {{totalFileCount}} of {{reseruploaddata}} files
        </p>
        <p class="text-paragraph-font-size mb-4">
          Your files have been successfully uploaded. These documents must be verified before they can be accessed by others.
        </p>
        <p>
          Verify your documents from My Queue to add them to your workspace.
        </p>

          <nb-checkbox status="basic" id="checkbox" type="checkbox" name="defaultAccess"  (checkedChange)="checkboxclick($event,'checkbox')" [(ngModel)]="checkboxval">
            Do not show this to me again
          </nb-checkbox>

      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary" class="mr-2" size="small" (click)="viewUploadedDocument();notshowagain.close();">GO TO MY QUEUE</button>
      <button  nbButton (click)="notshowagain.close();" class="noreturndata" size="small">SKIP</button>
    </nb-card-footer>
  </nb-card>
</ng-template> -->

<!--Modal Popup For Removing Individual Document Cancel  Start-->
<ng-template #removeDoc let-data let-ref="removeDocRef">
  <nb-card class="uploadModalcancel">
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <div class="subtitle text-basic">
         Cancel Upload
        </div>
        <!-- <div>
            <nb-icon class="cursor-pointer" (click)="removeDocRef.close()" icon="close-outline"></nb-icon>
        </div> -->
    </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-10">
        <p class="text-paragraph-font-size mb-0">Are you sure you want to remove this file? </p>
        <p class="text-paragraph-font-size text-danger" [hidden]="!btnRemoveFile[deletedIndexFile]">
          The upload is complete and cannot be canceled.
        </p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton class="mr-2" status="primary" [ngClass]="(btnRemoveFile[deletedIndexFile]==false)?'prime-btn':'btn-secondary'"
      [disabled]="btnRemoveFile[deletedIndexFile]" (click)="deleteFile(deletedIndexFile)" size="small">Confirm</button>
      <button nbButton   (click)="individualFileCancel(deletedIndexFile); ToggleUploading('start')" class=" controlbtndelt">Cancel</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!--Modal Popup For Removing Individual Document Cancel  End-->

<!--modal popup for network breaks during upload start-->

<ng-template #networkBrk let-data let-ref="networkBrkRef">
  <nb-card>
    <nb-card-header>
      <div class="justify-content-between d-flex">
        <div class="subtitle text-primary">
          Network Error
        </div>
    </div>
    </nb-card-header>
    <nb-card-body>
      <div class="col-10 pl-0">
        <p>A network error occurred during the upload of your files. Please
          try again in some time. </p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="primary"  (click)="onProceedToUpload();dialogRef.close()" size="small">Ok</button>
    </nb-card-footer>
  </nb-card>
</ng-template>





