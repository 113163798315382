import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
 
  {
    
    title:'Docs in Upload',
    icon: 'file-outline',
    
 
    badge: {
      dotMode: true,
      status: 'danger',
     
     
    },
    children: [
      {
        title: 'My Queue',
        link: '/pages/documents/my-queue',
          badge: {
            text: String(localStorage.getItem('redalert')) != 'null' ? String(localStorage.getItem('redalert')) : '0',
            status: 'danger',
           
          },
      },
      {
        title: 'Workspace Queue',
        link: '/pages/documents/workspace-queue',
      },
    ],
  },
  {
    title: 'Owned by me',
    icon: 'folder-outline',
    link: '/pages/documents/owned-by-me',
   
  },
  {
    title: 'Shared with me',
    icon: 'share-outline',
    link: '/pages/documents/shared-with-me',
  },
  {
    title: 'Dockets',
    icon: 'layers-outline',
    link: '/pages/documents/dockets',
  },
  {
    title: 'Smart Cabinet',
    icon: 'hard-drive-outline',
    link: '/pages/cabinet',
  },
 
 


  {
    title: 'Trash',
    icon: 'trash-2-outline',
    link: '/pages/documents/trash',
  },
  {
    title: 'WS Analytics',
    icon: 'bar-chart-outline',
    link: '/pages/ws-analytics',
  },
  {
    title: 'Admin',
    icon: 'shield-outline',
    link: '/pages/settings',
    badge: {
      dotMode: false,
      status: '',
     
     
    },
    
    children: [
      {
        title: 'Users',
        link: '/pages/settings/users', 
      },
      {
        title: 'Groups',
        link: '/pages/settings/groups',
      },
      {
        title: 'Roles',
        link: '/pages/settings/roles',
      },
      {
        title: 'Workspace Management',
        link: '/pages/workspace-admin/ws-management',
      },
      {
        title: 'WS Analytics',
        link: '/pages/ws-analytics',
      },
      {
        title: 'Billing & Subscriptions',
        link: '/pages/settings/billing-subscriptions',
      },
    ],
  },
  {
    title: 'All WS Docs',
    icon: 'shake-outline',
    link: '/pages/all-workspace',
  },



];










