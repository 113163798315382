import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import 'rxjs/add/observable/of';
import 'rxjs/Rx';
import { environment } from '../.../../../../../src/environments/environment';
import { ApiServiceService } from '../api-service.service';
@Injectable({
  providedIn: 'root'
})
export class AllUsersService {
  private data: any;
  private error: any;
  createdBy: any;
  workSpaceId: any;


    constructor(private http: HttpClient,private apiService:ApiServiceService) {
      this.workSpaceId = localStorage.getItem('localwsId')
      this.createdBy = localStorage.getItem('localloginId')
  } 
  public baseURL=environment.baseUrl;
  public baseOrigin = environment.baseOrigin;

  searchAllUsersService(data) {
    return this.apiService.post('/api/AllUsers/Search', data);
  }
  UploadRightsUserListService(data) {
    return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }
  getverifierdisable(data) {

    return this.apiService.post('/api/AllUsers/CheckDocuments', data);
  }
  getRolesListService(data) {
    return this.apiService.post('/api/Role/Get', data);
  }
  usersInviteService(data) {
  
    return this.apiService.post('/api/MemberInvitation/Push', data);
  }
  groupsCountService(data) {
    return this.apiService.post( '/api/Group/Get', data);
  }
  userDisabledService(data) {
    return this.apiService.post('/api/WstMember/Disable', data);
  }
  notifyinsert(data) {
  return this.apiService.post('/api/GetNotificationUser/NotificationInsert', data);
}
notifysend(data) {

  return this.apiService.post('/api/Notification/UserSendMessage', data);
  }
  userEnabledService(data) {
 
    return this.apiService.post('/api/WstMember/Enable', data);
  }
  userResetPasswordService(data) {
    return this.apiService.post('/api/WstMember/EncapsulateReset', data);
  }
  userDeletedService(data) {
    return this.apiService.post('/api/WstMember/Delete', data);
  }
  userDeletedServiceNew(data) {
    return this.apiService.post('/api/CheckWSSubscriptionStatus/UserDeleteFromWS', data);
  }
  AfterUserDeletedService(data) {
    return this.apiService.post97('/api/CheckWSSubscriptionStatus/AfterUserDeleteFromWS', data);
  }
  userRoleChangeService(data) {
    return this.apiService.post('/api/Role/Change', data);
  }
  usersCountService(data) {
    return this.apiService.post('/api/Workspace/GetUserCount', data);
  }
  setDefaultGroupService(data) {
    
    return this.apiService.post('/api/Group/Default', data);
  }  
  userGroupChangeService(data) {

    return this.apiService.post('/api/Group/Change', data);
  }
  deleteGroupService(data) {
  
    return this.apiService.post('/api/Group/Delete', data);
  }
  
 addGroupService(data) {
 
  return this.apiService.post('/api/Group/Add', data);
}
public statusDropdown = [{ statusName: 'Active' }, { statusName: 'Disabled' }, { statusName: 'Reset Password' }];
bindStatus() {
  return this.statusDropdown;
}

renameGroupService(data) {
  return this.apiService.post('/api/Group/Rename', data);
}
/**
 * Insert  IP  into the database
 */
InsertIP(data){
  return this.apiService.post('/api/WsManagement/InsertIPAddressWhitelistingDetails',data)
}
/**
 * get  IP and Roles data from database
 */
getIPAndRole(data){
  return this.apiService.post('/api/WsManagement/GetIPAddressWhitelistingDetails',data)
}
/**
 * delete  IP and Roles data from database
 */
deleteIPAndRole(data){
  return this.apiService.post('/api/WsManagement/DeleteIPAddressWhitelistingDetails',data)
}
}
