import { Injectable } from '@angular/core';
import { PeriodsService } from './periods.service';
import { DocChart, DocChartData } from '../data/doc-chart';

@Injectable()
export class DocChartService extends DocChartData {

  
  private data = { };

  constructor(private period: PeriodsService) {
    super();
    this.data = {
    
    };
  }

  getDocChartData(period: string): DocChart {
    return this.data[period];
  }
}
