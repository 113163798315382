import { Component } from '@angular/core';

@Component({
  selector: 'ngx-template-renderer',
  templateUrl: './template-renderer.component.html',
  styleUrls: ['./template-renderer.component.scss']
})
export class TemplateRendererComponent  {

  params: any;
  dpagesValue: any;
  tooltipData: string;

  constructor() { }
 

  ngOnInit(): void {
    this.dpagesValue = localStorage.getItem('dpage')
    this.tooltipData= "Pages are dox2U pages As per our 'Pay as you Go' policy we consider " + this.dpagesValue + ' KB  as 1 dPage'

  }
   /*
    *Adding document size icon in the lists
   */

  agInit(params:any){
    this.params=params; 

  }

}
