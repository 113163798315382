import { Directive, Output,EventEmitter, HostBinding, HostListener } from '@angular/core';
/**
 * Directive for file upload  service
*/
@Directive({
  selector: '[ngxFileUpload]'
})
 /**
 * export  for file upload service class
 */
export class FileUploadDirective {
/**
 * git ignore onInit
 */
  constructor() { }
/**
 * hostBinding event on fileOver
 */
  @HostBinding('class.fileover') fileOver: boolean;
/**
 * file dropper variable for data transfer
 */
  @Output() fileDropped = new EventEmitter<any>();
/**
 * Dragover listener
 */
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }
/**
 * DragLeave listener
 */
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }
/**
 * Drop listener
 */
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}


