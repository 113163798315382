import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { SharedService } from '../@core/utils/shared.service';
import { parse } from "path";

@Pipe({
  name: "dateTransform",
})
export class DateTransformPipe implements PipeTransform{
  getDateTimeFormat: string;
  timeFormat: string;
  DateFormat: string;
  timeZone: string;
  getDTFormatLastActive: any;
  wsDateTimeFormat: string;
  wsDateTimeFormatForViewDetails = [];
  splitResponse: any;
  offset: string;
  changedate: any;
  wsDateTimeFormatForViewDetailsDocket = [];

  constructor( public sharedService : SharedService, private datePipe: DatePipe) {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    this.sharedService.isDeleted.subscribe((data)=>{
      this.getValueOnce = true;
    })
  }
  
  transform1(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.data?.length - 1; i++) {
      if (response?.data[i].joiningDate) {
        this.changedate =response?.data[i].joiningDate;
        this.wsDateTimeFormat = response?.data[i].joiningDate;
      } else if (response?.data[i]?.verifiedDateTime) {
        this.wsDateTimeFormat = response?.data[i].verifiedDateTime;
      } else if (response?.data[i].addedDateTime) {
        this.wsDateTimeFormat = response?.data[i].addedDateTime;
      } else if (response?.data[i].updatedDateTime) {
        this.wsDateTimeFormat = response?.data[i].updatedDateTime;
      } else if (response?.data[i].docTrashDate) {
        this.wsDateTimeFormat = response?.data[i].docTrashDate;
      } else if (response?.data[i].deletedDate) {
        this.wsDateTimeFormat = response?.data[i].deletedDate;
      } else if (response?.data[i].createdDtm) {
        this.wsDateTimeFormat = response?.data[i].createdDtm;
      } else if (response?.data[i].invitedDate) {
        this.wsDateTimeFormat = response?.data[i].invitedDate;
      } else if (response?.data[i].createddtm) {
        this.wsDateTimeFormat = response?.data[i].createddtm;;
      }
      else {
        return;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      console.log("wsDateTimeFormat", this.wsDateTimeFormat);
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "UTC+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;
        console.log("date time", dateTimeAtUTC, dateTimeAtUTCChange);
      // let timeZoneChange = this.timeZone?.split(" ")[0].replace('UTC', 'GMT');
      let timeZoneChange = this.timeZone?.split(" ")[0];
      timeZoneChange = timeZoneChange?.replace("(", "");
      timeZoneChange = timeZoneChange?.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      }
     
  

     // updatedDateTimeUTC: string;
      console.log("timezonechange", timeZoneChange, this.offset);
      // let datePipe = new DatePipe("en-US").transform(dateTimeAtUTC, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let datePipe = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      response.data[i].changedate = datePipe.toString();
      this.splitResponse = response.data[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.wsDateTimeFormat,
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        
        if (verifieddate === changetoday) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.wsDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.wsDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.wsDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.wsDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }

       



      if(dateTimeAtUTC.includes(":")) {
        let NewdateTimeparts;
        let dateTimeParts1 = dateTimeAtUTC.split(' ');
        let dateTimeparts = dateTimeParts1[0].split(':');
        let dateTimeUTCParts = dateTimeParts1[1];
        let UsertimeZone = localStorage.getItem("User_time_zone");
        if (UsertimeZone.includes('+')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('+')[1];
          let hoursToBeAdded = Number(hoursAndMins.split(':')[0]);
          let MinsToBeAdded = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours + hoursToBeAdded;
          let dateMin  = dateMinutes + MinsToBeAdded;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin>59) {
            let minVar = dateMin - 60;
            dateMin = minVar;
            dateChange = dateChange + 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange >= 24) {
              dateChange = dateChange-24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange < 12) {
                AmOrPm = 'PM';
              }
              else if (dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'PM'
                } else if (dateChange == 12) {
                  AmOrPm = 'PM'
                }
                }  else {
             AmOrPm = 'AM'
             }
            } 
            else {
              if(dateChange < 12) {
                 AmOrPm = 'AM';
               }
              else if (dateChange > 12) {
                 if (dateChange > 12) {
                 dateChange = dateChange - 12;
                 AmOrPm = 'PM'
                 if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                }else if (dateChange == 12) {
                  AmOrPm = 'AM'
                }
                 } else {
                AmOrPm = 'PM'
                }
            } 
           
        }
        
       
          if (dateMin < 10) {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }

        }
      
      dateTimeAtUTC = NewdateTimeparts;
        }
        else if (UsertimeZone.includes('-')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('-')[1];
          let hoursToBeSubtracted = Number(hoursAndMins.split(':')[0]);
          let MinsToBeSubtracted = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours - hoursToBeSubtracted;
          let dateMin  = dateMinutes - MinsToBeSubtracted;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin < 0) {
            let minVar = dateMin + 60;
            dateMin = minVar;
            dateChange = dateChange - 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange < 0) {
              dateChange = dateChange + 24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'AM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'PM';
                }
                
              } else {
                AmOrPm = 'PM';
              }
          }
            else {
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'PM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'AM';
                }
                
              } else {
                AmOrPm = 'AM';
              }
           
        }
        
       
        if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

        }
      
      dateTimeAtUTC = NewdateTimeparts;
        } else {
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours;
          let dateMin  = dateMinutes;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
        }
        if(timeFormat === "hh:mm a") {        
          if(dateTimeUTCParts === 'PM') {
            AmOrPm = 'PM';
         } else {
            AmOrPm = 'AM';
         }
         if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

      }
    
    dateTimeAtUTC = NewdateTimeparts;
        }
      }
    
      if (response.data[i].joiningDate) {
       response.data[i].changedate =  this.changedate ;
        response.data[i].joiningDate = dateTimeAtUTC;
      }
      if (response.data[i].verifiedDateTime) {
        response.data[i].verifiedDateTime = dateTimeAtUTC;
      } else if (response.data[i].addedDateTime) {
        response.data[i].addedDateTime = dateTimeAtUTC;
      } else if (response.data[i].updatedDateTime) {
        response.data[i].updatedDateTime = dateTimeAtUTC;
      } else if (response.data[i].docTrashDate) {
        response.data[i].docTrashDate = dateTimeAtUTC;
      } else if (response.data[i].deletedDate) {
        response.data[i].deletedDate = dateTimeAtUTC;
      } else if (response.data[i].invitedDate) {
        response.data[i].invitedDate = dateTimeAtUTC;
      } else if (response?.data[i].docTrashDate) {
        response.data[i].docTrashDate = dateTimeAtUTC;
      } else if (response.data[i].createdDtm) {
        response.data[i].createdDtm = dateTimeAtUTC;
      }
      else if (response?.data[i].createddtm) {
        response.data[i].createddtm = dateTimeAtUTC;
      }
      response.rowData = response.data;
    }
    return response;
}
  transform(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.data?.length - 1; i++) {
      if (response?.data[i].joiningDate) {
        this.changedate =response?.data[i].joiningDate;
        this.wsDateTimeFormat = response?.data[i].joiningDate;
      } else if (response?.data[i]?.verifiedDateTime) {
        this.wsDateTimeFormat = response?.data[i].verifiedDateTime;
      } else if (response?.data[i].addedDateTime) {
        this.wsDateTimeFormat = response?.data[i].addedDateTime;
      } else if (response?.data[i].updatedDateTime) {
        this.wsDateTimeFormat = response?.data[i].updatedDateTime;
      } else if (response?.data[i].docTrashDate) {
        this.wsDateTimeFormat = response?.data[i].docTrashDate;
      } else if (response?.data[i].deletedDate) {
        this.wsDateTimeFormat = response?.data[i].deletedDate;
      } else if (response?.data[i].createdDtm) {
        this.wsDateTimeFormat = response?.data[i].createdDtm;
      } else if (response?.data[i].invitedDate) {
        this.wsDateTimeFormat = response?.data[i].invitedDate;
      } else if (response?.data[i].createddtm) {
        this.wsDateTimeFormat = response?.data[i].createddtm;;
      }
      else {
        return;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "UTC+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;
      // let timeZoneChange = this.timeZone?.split(" ")[0].replace('UTC', 'GMT');
      let timeZoneChange = this.timeZone?.split(" ")[0];
      timeZoneChange = timeZoneChange?.replace("(", "");
      timeZoneChange = timeZoneChange?.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          if (Number(addedOffset[1]) >= 6) {
            addedOffset[1] = String(Number(addedOffset[1]) - 6);
            if (Number(addedOffset[1]) <= 9) {
              this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
            } else {
              this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
            }
          } else {
            addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
            this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
          }
        }
      }
      // let datePipe = new DatePipe("en-US").transform(dateTimeAtUTC, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let datePipe = new DatePipe("en-US").transform(
        dateTimeAtUTC,
        "MMM-dd-yyyy hh:mm a zzzz",
        timeZoneChange
      );
      response.data[i].changedate = datePipe.toString();
      this.splitResponse = response.data[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.splitResponse[0],
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate === changetoday) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }
      if (response.data[i].joiningDate) {
       response.data[i].changedate =  this.changedate ;
        response.data[i].joiningDate = dateTimeAtUTC;
      }
      if (response.data[i].verifiedDateTime) {
        response.data[i].verifiedDateTime = dateTimeAtUTC;
      } else if (response.data[i].addedDateTime) {
        response.data[i].addedDateTime = dateTimeAtUTC;
      } else if (response.data[i].updatedDateTime) {
        response.data[i].updatedDateTime = dateTimeAtUTC;
      } else if (response.data[i].docTrashDate) {
        response.data[i].docTrashDate = dateTimeAtUTC;
      } else if (response.data[i].deletedDate) {
        response.data[i].deletedDate = dateTimeAtUTC;
      } else if (response.data[i].invitedDate) {
        response.data[i].invitedDate = dateTimeAtUTC;
      } else if (response?.data[i].docTrashDate) {
        response.data[i].docTrashDate = dateTimeAtUTC;
      } else if (response.data[i].createdDtm) {
        response.data[i].createdDtm = dateTimeAtUTC;
      }
      else if (response?.data[i].createddtm) {
        response.data[i].createddtm = dateTimeAtUTC;
      }
      response.rowData = response.data;
    }
    return response;
  }
  transform_lastActive1(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.data?.length - 1; i++) {
      let value = "check";
      if (response?.data[i].lastActive) {
        this.changedate=response?.data[i].changedate
        this.getDateTimeFormat = response?.data[i].lastActive;
        if (this.getDateTimeFormat === "0001-01-01T00:00:00") value = "";
      } else {
        return;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "UTC+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;
      let timeZoneChange = this.timeZone?.split(" ")[0].replace("UTC", "UTC");
      timeZoneChange = timeZoneChange.replace("(", "");
      timeZoneChange = timeZoneChange.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
      // let datePipe = new DatePipe("en-US").transform(this.wsDateTimeFormat, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      dateTimeAtUTC = dateTimeAtUTC.replace(" ", "T");
      let datePipe = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz",
        timeZoneChange
      );
      // timeZoneChange = timeZoneChange.replace("(" || ")","");
      // timeZoneChange = timeZoneChange.replace(")","");
      response.data[i].changedate = datePipe.toString();
      this.splitResponse = response.data[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.getDateTimeFormat,
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      // let changetoday = new DatePipe("en-US").transform(currentDate, "dd/MM/YYYY", this.offset);
      // let currentDates = new DatePipe("en-US").transform(currentDate, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            onlytime =
              String(
                Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24
                  ? "0" +
                      String(
                        Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24
                      )
                  : Number(this.splitResponse[1]?.split(":")[0])
              ) +
              ":" +
              this.splitResponse[1]?.split(":")[1];
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }
      if (value == "") {
        dateTimeAtUTC = "";
      }

      if(dateTimeAtUTC.includes(":")) {
        let NewdateTimeparts;
        let dateTimeParts1 = dateTimeAtUTC.split(' ');
        let dateTimeparts = dateTimeParts1[0].split(':');
        let dateTimeUTCParts = dateTimeParts1[1];
        let UsertimeZone = localStorage.getItem("User_time_zone");
        if (UsertimeZone.includes('+')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('+')[1];
          let hoursToBeAdded = Number(hoursAndMins.split(':')[0]);
          let MinsToBeAdded = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours + hoursToBeAdded;
          let dateMin  = dateMinutes + MinsToBeAdded;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin>59) {
            let minVar = dateMin - 60;
            dateMin = minVar;
            dateChange = dateChange + 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange >= 24) {
              dateChange = dateChange-24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
              }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange < 12) {
                AmOrPm = 'PM';
              }
              else if (dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'PM'
                } else if (dateChange == 12) {
                  AmOrPm = 'PM'
                }
                }  else {
             AmOrPm = 'AM'
             }
            } 
            else {
              if(dateChange < 12) {
                 AmOrPm = 'AM';
               }
              else if (dateChange > 12) {
                 if (dateChange > 12) {
                 dateChange = dateChange - 12;
                 AmOrPm = 'PM'
                 if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                }else if (dateChange == 12) {
                  AmOrPm = 'AM'
                }
                 } else {
                AmOrPm = 'PM'
                }
            } 
           
        }
        
       
        if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

        }
      
      dateTimeAtUTC = NewdateTimeparts;
        }
        else if (UsertimeZone.includes('-')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('-')[1];
          let hoursToBeSubtracted = Number(hoursAndMins.split(':')[0]);
          let MinsToBeSubtracted = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours - hoursToBeSubtracted;
          let dateMin  = dateMinutes - MinsToBeSubtracted;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin < 0) {
            let minVar = dateMin + 60;
            dateMin = minVar;
            dateChange = dateChange - 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange < 0) {
              dateChange = dateChange + 24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }  
            }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'AM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'PM';
                }
                
              } else {
                AmOrPm = 'PM';
              }
          }
            else {
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'PM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'AM';
                }
                
              } else {
                AmOrPm = 'AM';
              }
           
        }
        
       
        if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

        }
      
      dateTimeAtUTC = NewdateTimeparts;
        } else {
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours;
          let dateMin  = dateMinutes;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }    
            }
        }
        if(timeFormat === "hh:mm a") {        
          if(dateTimeUTCParts === 'PM') {
            AmOrPm = 'PM';
         } else {
            AmOrPm = 'AM';
         }
         if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

      }
    
    dateTimeAtUTC = NewdateTimeparts;
        }
      }

      if (response.data[i].lastActive) {
        response.data[i].changedate = this.changedate
        response.data[i].lastActive = dateTimeAtUTC;
      }
      response.rowData = response.data;
    }
    return response;
  }

  transform_lastActive(response?: any): any {

    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.data?.length - 1; i++) {
      let value = "check";
      if (response?.data[i].lastActive) {
        this.changedate=response?.data[i].changedate
        this.getDateTimeFormat = response?.data[i].lastActive;
        if (this.getDateTimeFormat === "0001-01-01T00:00:00") value = "";
      } else {
        return;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "GMT+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;

      let timeZoneChange = this.timeZone?.split(" ")[0].replace("UTC", "UTC");
      timeZoneChange = timeZoneChange.replace("(", "");
      timeZoneChange = timeZoneChange.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
      // let datePipe = new DatePipe("en-US").transform(this.wsDateTimeFormat, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let datePipe = new DatePipe("en-US").transform(
        dateTimeAtUTC,
        "MMM-dd-yyyy hh:mm a zzzz",
        timeZoneChange
      );
      // timeZoneChange = timeZoneChange.replace("(" || ")","");
      // timeZoneChange = timeZoneChange.replace(")","");
      response.data[i].changedate = datePipe.toString();
      this.splitResponse = response.data[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.splitResponse[0],
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      // let changetoday = new DatePipe("en-US").transform(currentDate, "dd/MM/YYYY", this.offset);
      // let currentDates = new DatePipe("en-US").transform(currentDate, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            onlytime =
              String(
                Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24
                  ? "0" +
                      String(
                        Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24
                      )
                  : Number(this.splitResponse[1]?.split(":")[0])
              ) +
              ":" +
              this.splitResponse[1]?.split(":")[1];
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          // this.wsDateTimeFormat = new DatePipe("en-US").transform(response.data[i].changedate, this.DateFormat);
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response.data[i].changedate,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }
      if (value == "") {
        dateTimeAtUTC = "";
      }
      if (response.data[i].lastActive) {
        response.data[i].changedate = this.changedate

        response.data[i].lastActive = dateTimeAtUTC;

      }
      response.rowData = response.data;
    }
    return response;
  }
  transformDashboardDate1(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.length - 1; i++) {
      if (response[i]?.date) {
        this.getDateTimeFormat = response[i]?.date;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "UTC+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;
      let timeZoneChange = this.timeZone?.split(" ")[0].replace("UTC", "UTC");
      timeZoneChange = timeZoneChange.replace("(", "");
      timeZoneChange = timeZoneChange.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
      // let datePipe = new DatePipe("en-US").transform(this.getDateTimeFormat, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let datePipe = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz",
        //timeZoneChange
      );
      // timeZoneChange = timeZoneChange.replace("(" || ")","");
      // timeZoneChange = timeZoneChange.replace(")","");
      response[i].changedate = datePipe.toString();
      this.splitResponse = response[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.getDateTimeFormat,
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      // let changetoday = new DatePipe("en-US").transform(currentDate, "dd/MM/YYYY", this.offset);
      // let currentDates = new DatePipe("en-US").transform(currentDate, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            onlytime =
              String(
                Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24
                  ? "0" +
                      String(
                        Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24
                      )
                  : Number(this.splitResponse[1]?.split(":")[0])
              ) +
              ":" +
              this.splitResponse[1]?.split(":")[1];
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            this.getDateTimeFormat,
            this.DateFormat,
            timeZoneChange
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }
      console.log("qoowowowowowowo", dateTimeAtUTC);
      if(dateTimeAtUTC.includes(":")) {
        let NewdateTimeparts;
        let dateTimeParts1 = dateTimeAtUTC.split(' ');
        let dateTimeparts = dateTimeParts1[0].split(':');
        let dateTimeUTCParts = dateTimeParts1[1];
        let UsertimeZone = localStorage.getItem("User_time_zone");
        if (UsertimeZone.includes('+')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('+')[1];
          let hoursToBeAdded = Number(hoursAndMins.split(':')[0]);
          let MinsToBeAdded = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours + hoursToBeAdded;
          let dateMin  = dateMinutes + MinsToBeAdded;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin>59) {
            let minVar = dateMin - 60;
            dateMin = minVar;
            dateChange = dateChange + 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange >= 24) {
              dateChange = dateChange-24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange < 12) {
                AmOrPm = 'PM';
              }
              else if (dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'PM'
                } else if (dateChange == 12) {
                  AmOrPm = 'PM'
                }
                }  else {
             AmOrPm = 'AM'
             }
            } 
            else {
              if(dateChange < 12) {
                 AmOrPm = 'AM';
               }
              else if (dateChange > 12) {
                 if (dateChange > 12) {
                 dateChange = dateChange - 12;
                 AmOrPm = 'PM'
                 if(dateChange > 12) {
                  dateChange = dateChange - 12;
                  AmOrPm = 'AM'
                }else if (dateChange == 12) {
                  AmOrPm = 'AM'
                }
                 } else {
                AmOrPm = 'PM'
                }
            } 
           
        }
        
       
        if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

        }
      
      dateTimeAtUTC = NewdateTimeparts;
        }
        else if (UsertimeZone.includes('-')) {
          let splitTimeZone = UsertimeZone.split(')');
          let timeZoneArray = splitTimeZone[0].toString();
          let hoursAndMins = timeZoneArray.split('-')[1];
          let hoursToBeSubtracted = Number(hoursAndMins.split(':')[0]);
          let MinsToBeSubtracted = Number(hoursAndMins.split(':')[1]);
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours - hoursToBeSubtracted;
          let dateMin  = dateMinutes - MinsToBeSubtracted;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
        
          if(dateMin < 0) {
            let minVar = dateMin + 60;
            dateMin = minVar;
            dateChange = dateChange - 1;

          }
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if(dateChange < 0) {
              dateChange = dateChange + 24;
            }
            
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {  
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'AM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'PM';
                }
                
              } else {
                AmOrPm = 'PM';
              }
          }
            else {
              if(dateChange <= 0 ) {
                if(dateChange < 0){
                  dateChange = dateChange + 12;
                  AmOrPm = 'PM';
                } else {
                  dateChange = 12;
                  AmOrPm = 'AM';
                }
                
              } else {
                AmOrPm = 'AM';
              }
           
        }
        
       
        if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }
        }
      
      dateTimeAtUTC = NewdateTimeparts;
        } else {
          let dateHours = Number(dateTimeparts[0]);
          let dateMinutes = Number(dateTimeparts[1]);

          let dateChange = dateHours;
          let dateMin  = dateMinutes;
          console.log("hours data", dateChange);
          console.log("minutes data", dateMin);
          let timeFormat = localStorage.getItem("TimeFormat");
          let AmOrPm;
          if(timeFormat === "HH:mm") {
            if (dateMin < 10) {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + "0" + dateMin;
              }
            } else {
              if (dateChange < 10) {
                NewdateTimeparts = "0" + dateChange + ':' + dateMin;
              } else {
                NewdateTimeparts = dateChange + ':' + dateMin;
              }
            }
        }
        if(timeFormat === "hh:mm a") {        
          if(dateTimeUTCParts === 'PM') {
            AmOrPm = 'PM';
         } else {
            AmOrPm = 'AM';
         }
         if (dateMin < 10){
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          } else {
            NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
          }
        } else {
          if (dateChange < 10) {
            NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
          } else {
            NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
          }
        }

      }
    
    dateTimeAtUTC = NewdateTimeparts;
        }
      }
      if (response[i]?.date) {
        response[i].date = dateTimeAtUTC;
      }
      response.rowData = response.data;
    }
    return response;
  }
  transformDashboardDate(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");
    for (let i = 0; i <= response?.length - 1; i++) {
      if (response[i]?.date) {
        this.getDateTimeFormat = response[i]?.date;
      }
      let dateTimeAtUTC = new DatePipe("en-US").transform(
        this.getDateTimeFormat,
        "MMM-dd-yyyy hh:mm a zzzz"
      );
      let dateTimeAtUTCChange = dateTimeAtUTC
        ?.split(" ")[2]
        .replace("", "GMT+00:00");
      dateTimeAtUTC =
        dateTimeAtUTC?.split(" ")[0] +
        " " +
        dateTimeAtUTC?.split(" ")[1] +
        " " +
        dateTimeAtUTCChange;

      let timeZoneChange = this.timeZone?.split(" ")[0].replace("UTC", "UTC");
      timeZoneChange = timeZoneChange.replace("(", "");
      timeZoneChange = timeZoneChange.replace(")", "");
      const contains30 = timeZoneChange?.split(":")[1] === "30";
      let timeNumber = timeZoneChange?.split(":");
      const isTimeZoneNegative =
        timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
      if (contains30) {
        timeNumber[1] = timeNumber[1].replace("30", "00");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else if (timeZoneChange?.split(":")[1] === "00") {
        timeNumber[1] = timeNumber[1].replace("00", "30");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 5);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      } else {
        timeNumber[1] = timeNumber[1].replace("45", "15");
        if (isTimeZoneNegative) {
          let addedOffset = timeNumber[0]?.split("UTC+");
          addedOffset[1] = String(Number(addedOffset[1]) + 6);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        } else {
          let addedOffset = timeNumber[0]?.split("UTC-");
          addedOffset[1] = String(Number(addedOffset[1]) - 7);
          this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
      // let datePipe = new DatePipe("en-US").transform(this.getDateTimeFormat, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let datePipe = new DatePipe("en-US").transform(
        dateTimeAtUTC,
        "MMM-dd-yyyy hh:mm a zzzz",
        timeZoneChange
      );
      // timeZoneChange = timeZoneChange.replace("(" || ")","");
      // timeZoneChange = timeZoneChange.replace(")","");
      response[i].changedate = datePipe.toString();
      this.splitResponse = response[i].changedate?.split(" ");
      let verifieddate = new DatePipe("en-US").transform(
        this.splitResponse[0],
        this.DateFormat
      );
      if (this.DateFormat !== "dd-MM-yyyy") {
        verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          "dd/MM/YYYY"
        );
      }
      verifieddate = verifieddate.replace("-", "/").replace("-", "/");
      let today = moment().toDate();
      let currentDate = moment(today).format("DD/MM/YYYY");
      let currentMonth = currentDate?.split("/")[1];
      let verifiedMonth = verifieddate?.split("/")[1];
      let changetoday;
      let currentDateFirstItem = currentDate?.split("/")[0];
      let currentDateSecondItem = currentDate?.split("/")[1];
      let currentDates =
        currentDateSecondItem +
        "/" +
        currentDateFirstItem +
        "/" +
        currentDate?.split("/")[2];
      changetoday = new DatePipe("en-US").transform(
        currentDates,
        "dd/MM/YYYY",
        this.offset
      );
      // let changetoday = new DatePipe("en-US").transform(currentDate, "dd/MM/YYYY", this.offset);
      // let currentDates = new DatePipe("en-US").transform(currentDate, "MMM-dd-yyyy hh:mm a zzzz", this.offset);
      let currentYear = moment(today).format("YYYY");
      let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
      if (verifieddate === "Invalid date") {
        verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            onlytime =
              String(
                Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24
                  ? "0" +
                      String(
                        Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24
                      )
                  : Number(this.splitResponse[1]?.split(":")[0])
              ) +
              ":" +
              this.splitResponse[1]?.split(":")[1];
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response[i].changedate,
            this.DateFormat
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response[i].changedate,
            this.DateFormat
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      } else {
        let regExp = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
          let onlytime: string;
          if (this.timeFormat === "hh:mm a") {
            onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
          } else if (this.splitResponse[2] === "AM") {
            // onlytime = String(
            //   ((Number(this.splitResponse[1]?.split(":")[0]) + 12) >= 24)
            //     ? "0" + String((Number(this.splitResponse[1]?.split(":")[0]) + 12) - 24)
            //     : "0" + (Number(this.splitResponse[1]?.split(":")[0]))
            // ) + ":"+ this.splitResponse[1]?.split(":")[1];
            if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
              onlytime =
                "0" +
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
              onlytime =
                this.splitResponse[1]?.split(":")[0] +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            } else {
              onlytime =
                "0" +
                Number(this.splitResponse[1]?.split(":")[0]) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          } else {
            if (this.splitResponse[1]?.split(":")[0] === "12") {
              onlytime = this.splitResponse[1];
            } else {
              onlytime =
                String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
                ":" +
                this.splitResponse[1]?.split(":")[1];
            }
          }
          dateTimeAtUTC = onlytime.toString();
        } else if (currentYear === fetchedYear) {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response[i].changedate,
            this.DateFormat
          );
          const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
          if (isYearFirst) {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[1] +
              "-" +
              this.wsDateTimeFormat?.split("-")[2];
          } else {
            this.wsDateTimeFormat =
              this.wsDateTimeFormat?.split("-")[0] +
              "-" +
              this.wsDateTimeFormat?.split("-")[1];
          }
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            dateTimeAtUTC = onlytime;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        } else {
          this.wsDateTimeFormat = new DatePipe("en-US").transform(
            response[i].changedate,
            this.DateFormat
          );
          if (
            regExp.some((a) =>
              this.wsDateTimeFormat.replace("-", " ").includes(a)
            )
          ) {
            let onlytime = this.wsDateTimeFormat.replace("-", " ");
            onlytime = onlytime.replace("-", ",");
            let onlytimes =
              onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
            dateTimeAtUTC = onlytimes;
          } else {
            dateTimeAtUTC = this.wsDateTimeFormat;
          }
        }
      }

      if (response[i]?.date) {
        response[i].date = dateTimeAtUTC;
      }
      response.rowData = response.data;
    }
    return response;
  }
  getValueOnce1 = true
  transformSearch1(response?: any): any {
    if(this.getValueOnce1){
      this.timeZone = localStorage.getItem("User_time_zone");
      this.timeFormat = localStorage.getItem("TimeFormat");
      this.DateFormat = localStorage.getItem("DateFormat");
      for (let i = 0; i < response?.length; i++) {
        if (response[i].createddtm) {
          this.wsDateTimeFormat = response[i].createddtm;
        } else {
          return;
        }
        let dateTimeAtUTC = new DatePipe("en-US").transform(
          this.wsDateTimeFormat,
          "MMM-dd-yyyy hh:mm a zzzz"
        );
        let dateTimeAtUTCChange = dateTimeAtUTC
          ?.split(" ")[2]
          .replace("", "UTC+00:00");
        dateTimeAtUTC =
          dateTimeAtUTC?.split(" ")[0] +
          " " +
          dateTimeAtUTC?.split(" ")[1] +
          " " +
          dateTimeAtUTCChange;
        let timeZoneChange = this.timeZone?.split(" ")[0];
        timeZoneChange = timeZoneChange.replace("(", "");
        timeZoneChange = timeZoneChange.replace(")", "");
        const contains30 = timeZoneChange?.split(":")[1] === "30";
        let timeNumber = timeZoneChange?.split(":");
        const isTimeZoneNegative =
          timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
        this.setOffsetForSearch(
          contains30,
          timeNumber,
          isTimeZoneNegative,
          timeZoneChange
        );
        let datePipe = new DatePipe("en-US").transform(
          this.wsDateTimeFormat,
          "MMM-dd-yyyy hh:mm a zzzz",
          timeZoneChange
        );
        response[i].changedate = datePipe.toString();
        this.splitResponse = response[i].changedate?.split(" ");
        let verifieddate = new DatePipe("en-US").transform(
          this.wsDateTimeFormat,
          this.DateFormat
        );
        if (this.DateFormat !== "dd-MM-yyyy") {
          verifieddate = new DatePipe("en-US").transform(
            this.wsDateTimeFormat,
            "dd/MM/YYYY"
          );
        }
        verifieddate = verifieddate.replace("-", "/").replace("-", "/");
        let today = moment().toDate();
        let currentDate = moment(today).format("DD/MM/YYYY");
        let currentMonth = currentDate?.split("/")[1];
        let verifiedMonth = verifieddate?.split("/")[1];
        let changetoday;
        let currentDateFirstItem = currentDate?.split("/")[0];
        let currentDateSecondItem = currentDate?.split("/")[1];
        let currentDates =
          currentDateSecondItem +
          "/" +
          currentDateFirstItem +
          "/" +
          currentDate?.split("/")[2];
        changetoday = new DatePipe("en-US").transform(
          currentDates,
          "dd/MM/YYYY",
          this.offset
        );
        let currentYear = moment(today).format("YYYY");
        let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
        if (verifieddate === "Invalid date") {
          ({ verifieddate, dateTimeAtUTC } =
            this.setDateFormatForInvalidDateInSearch1(
              verifieddate,
              dateTimeAtUTC,
              changetoday,
              currentYear,
              fetchedYear,
              response,
              i,
              timeZoneChange
            ));
        } else {
          dateTimeAtUTC = this.setDateFormatForValidDateInSearch1(
            verifieddate,
            changetoday,
            verifiedMonth,
            currentMonth,
            dateTimeAtUTC,
            currentYear,
            fetchedYear,
            response,
            i,
            timeZoneChange
          );
        }
        if(dateTimeAtUTC.includes(":")) {
          let NewdateTimeparts;
          let dateTimeParts1 = dateTimeAtUTC.split(' ');
          let dateTimeparts = dateTimeParts1[0].split(':');
          let dateTimeUTCParts = dateTimeParts1[1];
          let UsertimeZone = localStorage.getItem("User_time_zone");
          if (UsertimeZone.includes('+')) {
            let splitTimeZone = UsertimeZone.split(')');
            let timeZoneArray = splitTimeZone[0].toString();
            let hoursAndMins = timeZoneArray.split('+')[1];
            let hoursToBeAdded = Number(hoursAndMins.split(':')[0]);
            let MinsToBeAdded = Number(hoursAndMins.split(':')[1]);
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours + hoursToBeAdded;
            let dateMin  = dateMinutes + MinsToBeAdded;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
          
            if(dateMin>59) {
              let minVar = dateMin - 60;
              dateMin = minVar;
              dateChange = dateChange + 1;
  
            }
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if(dateChange >= 24) {
                dateChange = dateChange-24;
              }
              
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
            }
            if(timeFormat === "hh:mm a") {        
              if(dateTimeUTCParts === 'PM') {  
                if(dateChange < 12) {
                  AmOrPm = 'PM';
                }
                else if (dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'AM'
                  if(dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'PM'
                  } else if (dateChange == 12) {
                    AmOrPm = 'PM'
                  }
                  }  else {
               AmOrPm = 'AM'
               }
              } 
              else {
                if(dateChange < 12) {
                   AmOrPm = 'AM';
                 }
                else if (dateChange > 12) {
                   if (dateChange > 12) {
                   dateChange = dateChange - 12;
                   AmOrPm = 'PM'
                   if(dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'AM'
                  }else if (dateChange == 12) {
                    AmOrPm = 'AM'
                  }
                   } else {
                  AmOrPm = 'PM'
                  }
              } 
             
          }
          
         
          if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
          }
        
        dateTimeAtUTC = NewdateTimeparts;
          }
          else if (UsertimeZone.includes('-')) {
            let splitTimeZone = UsertimeZone.split(')');
            let timeZoneArray = splitTimeZone[0].toString();
            let hoursAndMins = timeZoneArray.split('-')[1];
            let hoursToBeSubtracted = Number(hoursAndMins.split(':')[0]);
            let MinsToBeSubtracted = Number(hoursAndMins.split(':')[1]);
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours - hoursToBeSubtracted;
            let dateMin  = dateMinutes - MinsToBeSubtracted;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
          
            if(dateMin < 0) {
              let minVar = dateMin + 60;
              dateMin = minVar;
              dateChange = dateChange - 1;
  
            }
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if(dateChange < 0) {
                dateChange = dateChange + 24;
              }
              
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
            }
            if(timeFormat === "hh:mm a") {        
              if(dateTimeUTCParts === 'PM') {  
                if(dateChange <= 0 ) {
                  if(dateChange < 0){
                    dateChange = dateChange + 12;
                    AmOrPm = 'AM';
                  } else {
                    dateChange = 12;
                    AmOrPm = 'PM';
                  }
                  
                } else {
                  AmOrPm = 'PM';
                }
            }
              else {
                if(dateChange <= 0 ) {
                  if(dateChange < 0){
                    dateChange = dateChange + 12;
                    AmOrPm = 'PM';
                  } else {
                    dateChange = 12;
                    AmOrPm = 'AM';
                  }
                  
                } else {
                  AmOrPm = 'AM';
                }
             
          }
          
         
          if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
          }
        
        dateTimeAtUTC = NewdateTimeparts;
          } else {
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours;
            let dateMin  = dateMinutes;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {
              AmOrPm = 'PM';
           } else {
              AmOrPm = 'AM';
           }
           if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
        }
      
      dateTimeAtUTC = NewdateTimeparts;
          }
        }

        if (response[i].createddtm) {
          response[i].createddtm = dateTimeAtUTC;
        }
        response.rowData = response;
      }
      this.getValueOnce1 = false;
    }
    setTimeout(() => {
      this.getValueOnce1 = true;
    }, 100);
    return response;
  }

  private setDateFormatForInvalidDateInSearch1(
    verifieddate: string,
    dateTimeAtUTC: string,
    changetoday: any,
    currentYear: string,
    fetchedYear: string,
    response: any,
    i: number,
    timeZoneChange: string
  ) {
    verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate === changetoday) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[1] +
          "-" +
          this.wsDateTimeFormat?.split("-")[2];
      } else {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[0] +
          "-" +
          this.wsDateTimeFormat?.split("-")[1];
      }
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    } else {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    }
    return { verifieddate, dateTimeAtUTC };
  }

  private setDateFormatForValidDateInSearch1(
    verifieddate: string,
    changetoday: any,
    verifiedMonth: string,
    currentMonth: string,
    dateTimeAtUTC: string,
    currentYear: string,
    fetchedYear: string,
    response: any,
    i: number,
    timeZoneChange: string
  ) {
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[1] +
          "-" +
          this.wsDateTimeFormat?.split("-")[2];
      } else {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[0] +
          "-" +
          this.wsDateTimeFormat?.split("-")[1];
      }
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    } else {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        this.wsDateTimeFormat,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    }
    return dateTimeAtUTC;
  }

  getValueOnce = true
  transformSearch(response?: any): any {
    if(this.getValueOnce){
      this.timeZone = localStorage.getItem("User_time_zone");
      this.timeFormat = localStorage.getItem("TimeFormat");
      this.DateFormat = localStorage.getItem("DateFormat");
      for (let i = 0; i < response?.length; i++) {
        if (response[i].createddtm) {
          this.wsDateTimeFormat = response[i].createddtm;
        } else {
          return;
        }
        let dateTimeAtUTC = new DatePipe("en-US").transform(
          this.wsDateTimeFormat,
          "MMM-dd-yyyy hh:mm a zzzz"
        );
        let dateTimeAtUTCChange = dateTimeAtUTC
          ?.split(" ")[2]
          .replace("", "UTC+00:00");
        dateTimeAtUTC =
          dateTimeAtUTC?.split(" ")[0] +
          " " +
          dateTimeAtUTC?.split(" ")[1] +
          " " +
          dateTimeAtUTCChange;
        let timeZoneChange = this.timeZone?.split(" ")[0];
        timeZoneChange = timeZoneChange.replace("(", "");
        timeZoneChange = timeZoneChange.replace(")", "");
        const contains30 = timeZoneChange?.split(":")[1] === "30";
        let timeNumber = timeZoneChange?.split(":");
        const isTimeZoneNegative =
          timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
        this.setOffsetForSearch(
          contains30,
          timeNumber,
          isTimeZoneNegative,
          timeZoneChange
        );
        let datePipe = new DatePipe("en-US").transform(
          dateTimeAtUTC,
          "MMM-dd-yyyy hh:mm a zzzz",
          timeZoneChange
        );
        response[i].changedate = datePipe.toString();
        this.splitResponse = response[i].changedate?.split(" ");
        let verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          this.DateFormat
        );
        if (this.DateFormat !== "dd-MM-yyyy") {
          verifieddate = new DatePipe("en-US").transform(
            this.splitResponse[0],
            "dd/MM/YYYY"
          );
        }
        verifieddate = verifieddate.replace("-", "/").replace("-", "/");
        let today = moment().toDate();
        let currentDate = moment(today).format("DD/MM/YYYY");
        let currentMonth = currentDate?.split("/")[1];
        let verifiedMonth = verifieddate?.split("/")[1];
        let changetoday;
        let currentDateFirstItem = currentDate?.split("/")[0];
        let currentDateSecondItem = currentDate?.split("/")[1];
        let currentDates =
          currentDateSecondItem +
          "/" +
          currentDateFirstItem +
          "/" +
          currentDate?.split("/")[2];
        changetoday = new DatePipe("en-US").transform(
          currentDates,
          "dd/MM/YYYY",
          this.offset
        );
        let currentYear = moment(today).format("YYYY");
        let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
        if (verifieddate === "Invalid date") {
          ({ verifieddate, dateTimeAtUTC } =
            this.setDateFormatForInvalidDateInSearch(
              verifieddate,
              dateTimeAtUTC,
              changetoday,
              currentYear,
              fetchedYear,
              response,
              i,
              timeZoneChange
            ));
        } else {
          dateTimeAtUTC = this.setDateFormatForValidDateInSearch(
            verifieddate,
            changetoday,
            verifiedMonth,
            currentMonth,
            dateTimeAtUTC,
            currentYear,
            fetchedYear,
            response,
            i,
            timeZoneChange
          );
        }
        if (response[i].createddtm) {
          response[i].createddtm = dateTimeAtUTC;
        }
        response.rowData = response;
      }
      this.getValueOnce = false;
    }
    setTimeout(() => {
      this.getValueOnce = true;
    }, 100);
    return response;
  }
  private setDateFormatForValidDateInSearch(
    verifieddate: string,
    changetoday: any,
    verifiedMonth: string,
    currentMonth: string,
    dateTimeAtUTC: string,
    currentYear: string,
    fetchedYear: string,
    response: any,
    i: number,
    timeZoneChange: string
  ) {
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        response[i].changedate,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[1] +
          "-" +
          this.wsDateTimeFormat?.split("-")[2];
      } else {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[0] +
          "-" +
          this.wsDateTimeFormat?.split("-")[1];
      }
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    } else {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        response[i].changedate,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    }
    return dateTimeAtUTC;
  }

  private setDateFormatForInvalidDateInSearch(
    verifieddate: string,
    dateTimeAtUTC: string,
    changetoday: any,
    currentYear: string,
    fetchedYear: string,
    response: any,
    i: number,
    timeZoneChange: string
  ) {
    verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate === changetoday) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        response[i].changedate,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst = this.wsDateTimeFormat?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[1] +
          "-" +
          this.wsDateTimeFormat?.split("-")[2];
      } else {
        this.wsDateTimeFormat =
          this.wsDateTimeFormat?.split("-")[0] +
          "-" +
          this.wsDateTimeFormat?.split("-")[1];
      }
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    } else {
      this.wsDateTimeFormat = new DatePipe("en-US").transform(
        response[i].changedate,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) => this.wsDateTimeFormat.replace("-", " ").includes(a))
      ) {
        let onlytime = this.wsDateTimeFormat.replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormat;
      }
    }
    return { verifieddate, dateTimeAtUTC };
  }

  private setOffsetForSearch(
    contains30: boolean,
    timeNumber: string[],
    isTimeZoneNegative: boolean,
    timeZoneChange: string
  ) {
    if (contains30) {
      timeNumber[1] = timeNumber[1].replace("30", "00");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 6);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    } else if (timeZoneChange?.split(":")[1] === "00") {
      timeNumber[1] = timeNumber[1].replace("00", "30");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 5);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    } else {
      timeNumber[1] = timeNumber[1].replace("45", "15");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 6);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    }
  }

  transformView1(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");

    if (response[0].fileCreatedDate) {
      this.wsDateTimeFormatForViewDetails[0] = response[0].fileCreatedDate;
    }
    if (response[0].fileModifiedDate) {
      this.wsDateTimeFormatForViewDetails[1] = response[0].fileModifiedDate;
    }
    if (response[0].uploadedDateTime) {
      this.wsDateTimeFormatForViewDetails[2] = response[0].uploadedDateTime;
    }
    if (response[0].verifiedDateTime) {
      this.wsDateTimeFormatForViewDetails[3] = response[0].verifiedDateTime;
    }

    if (this.wsDateTimeFormatForViewDetails.length > 0) {
      for (let x = 0; x < this.wsDateTimeFormatForViewDetails.length; x++) {
        let dateTimeAtUTC = new DatePipe("en-US").transform(
          this.wsDateTimeFormatForViewDetails[x],
          "MMM-dd-yyyy hh:mm a zzzz"
        );
        let dateTimeAtUTCChange = dateTimeAtUTC
          ?.split(" ")[2]
          .replace("", "UTC+00:00");
        dateTimeAtUTC =
          dateTimeAtUTC?.split(" ")[0] +
          " " +
          dateTimeAtUTC?.split(" ")[1] +
          " " +
          dateTimeAtUTCChange;
        let timeZoneChange = this.timeZone?.split(" ")[0];
        timeZoneChange = timeZoneChange.replace("(", "");
        timeZoneChange = timeZoneChange.replace(")", "");
        const contains30 = timeZoneChange?.split(":")[1] === "30";
        let timeNumber = timeZoneChange?.split(":");
        const isTimeZoneNegative =
          timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
        this.setOffset(
          contains30,
          timeNumber,
          isTimeZoneNegative,
          timeZoneChange
        );
        let datePipe = new DatePipe("en-US").transform(
          this.wsDateTimeFormatForViewDetails[x],
          "MMM-dd-yyyy hh:mm a zzzz",
          timeZoneChange
        );
        response[0].changedate = datePipe.toString();
        this.splitResponse = response[0].changedate?.split(" ");
        let verifieddate = new DatePipe("en-US").transform(
          this.wsDateTimeFormatForViewDetails[x],
          this.DateFormat
        );
        if (this.DateFormat !== "dd-MM-yyyy") {
          verifieddate = new DatePipe("en-US").transform(
            this.wsDateTimeFormatForViewDetails[x],
            "dd/MM/YYYY"
          );
        }
        verifieddate = verifieddate.replace("-", "/").replace("-", "/");
        let today = moment().toDate();
        let currentDate = moment(today).format("DD/MM/YYYY");
        let currentMonth = currentDate?.split("/")[1];
        let verifiedMonth = verifieddate?.split("/")[1];
        let changetoday;
        let currentDateFirstItem = currentDate?.split("/")[0];
        let currentDateSecondItem = currentDate?.split("/")[1];
        let currentDates =
          currentDateSecondItem +
          "/" +
          currentDateFirstItem +
          "/" +
          currentDate?.split("/")[2];
        changetoday = new DatePipe("en-US").transform(
          currentDates,
          "dd/MM/YYYY",
          this.offset
        );
        let currentYear = moment(today).format("YYYY");
        let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
        if (verifieddate === "Invalid date") {
          ({ verifieddate, dateTimeAtUTC } =
            this.setDateFormatForInvalidDateinView1(
              verifieddate,
              dateTimeAtUTC,
              changetoday,
              currentYear,
              fetchedYear,
              x,
              response,
              timeZoneChange
            ));
        } else {
          dateTimeAtUTC = this.setDateFormatForValidDateinViewDetails1(
            verifieddate,
            changetoday,
            verifiedMonth,
            currentMonth,
            dateTimeAtUTC,
            currentYear,
            fetchedYear,
            x,
            response,
            timeZoneChange
          );
        }
        console.log("qoowowowowowowo", dateTimeAtUTC);
        
        if(dateTimeAtUTC.includes(":")) {
          let NewdateTimeparts;
          let dateTimeParts1 = dateTimeAtUTC.split(' ');
          let dateTimeparts = dateTimeParts1[0].split(':');
          let dateTimeUTCParts = dateTimeParts1[1];
          let UsertimeZone = localStorage.getItem("User_time_zone");
          if (UsertimeZone.includes('+')) {
            let splitTimeZone = UsertimeZone.split(')');
            let timeZoneArray = splitTimeZone[0].toString();
            let hoursAndMins = timeZoneArray.split('+')[1];
            let hoursToBeAdded = Number(hoursAndMins.split(':')[0]);
            let MinsToBeAdded = Number(hoursAndMins.split(':')[1]);
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours + hoursToBeAdded;
            let dateMin  = dateMinutes + MinsToBeAdded;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
          
            if(dateMin>59) {
              let minVar = dateMin - 60;
              dateMin = minVar;
              dateChange = dateChange + 1;
  
            }
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if(dateChange >= 24) {
                dateChange = dateChange-24;
              }
              
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
            }
            if(timeFormat === "hh:mm a") {        
              if(dateTimeUTCParts === 'PM') {  
                if(dateChange < 12) {
                  AmOrPm = 'PM';
                }
                else if (dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'AM'
                  if(dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'PM'
                  } else if (dateChange == 12) {
                    AmOrPm = 'PM'
                  }
                  }  else {
               AmOrPm = 'AM'
               }
              } 
              else {
                if(dateChange < 12) {
                   AmOrPm = 'AM';
                 }
                else if (dateChange > 12) {
                   if (dateChange > 12) {
                   dateChange = dateChange - 12;
                   AmOrPm = 'PM'
                   if(dateChange > 12) {
                    dateChange = dateChange - 12;
                    AmOrPm = 'AM'
                  }else if (dateChange == 12) {
                    AmOrPm = 'AM'
                  }
                   } else {
                  AmOrPm = 'PM'
                  }
              } 
             
          }
          
         
          if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
          }
        
        dateTimeAtUTC = NewdateTimeparts;
          }
          else if (UsertimeZone.includes('-')) {
            let splitTimeZone = UsertimeZone.split(')');
            let timeZoneArray = splitTimeZone[0].toString();
            let hoursAndMins = timeZoneArray.split('-')[1];
            let hoursToBeSubtracted = Number(hoursAndMins.split(':')[0]);
            let MinsToBeSubtracted = Number(hoursAndMins.split(':')[1]);
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours - hoursToBeSubtracted;
            let dateMin  = dateMinutes - MinsToBeSubtracted;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
          
            if(dateMin < 0) {
              let minVar = dateMin + 60;
              dateMin = minVar;
              dateChange = dateChange - 1;
  
            }
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if(dateChange < 0) {
                dateChange = dateChange + 24;
              }
              
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
            }
            if(timeFormat === "hh:mm a") {        
              if(dateTimeUTCParts === 'PM') {  
                if(dateChange <= 0 ) {
                  if(dateChange < 0){
                    dateChange = dateChange + 12;
                    AmOrPm = 'AM';
                  } else {
                    dateChange = 12;
                    AmOrPm = 'PM';
                  }
                  
                } else {
                  AmOrPm = 'PM';
                }
            }
              else {
                if(dateChange <= 0 ) {
                  if(dateChange < 0){
                    dateChange = dateChange + 12;
                    AmOrPm = 'PM';
                  } else {
                    dateChange = 12;
                    AmOrPm = 'AM';
                  }
                  
                } else {
                  AmOrPm = 'AM';
                }
             
          }
          
         
          if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
          }
        
        dateTimeAtUTC = NewdateTimeparts;
          } else {
            let dateHours = Number(dateTimeparts[0]);
            let dateMinutes = Number(dateTimeparts[1]);
  
            let dateChange = dateHours;
            let dateMin  = dateMinutes;
            console.log("hours data", dateChange);
            console.log("minutes data", dateMin);
            let timeFormat = localStorage.getItem("TimeFormat");
            let AmOrPm;
            if(timeFormat === "HH:mm") {
              if (dateMin < 10) {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + "0" + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + "0" + dateMin;
                }
              } else {
                if (dateChange < 10) {
                  NewdateTimeparts = "0" + dateChange + ':' + dateMin;
                } else {
                  NewdateTimeparts = dateChange + ':' + dateMin;
                }
              }
          }
          if(timeFormat === "hh:mm a") {        
            if(dateTimeUTCParts === 'PM') {
              AmOrPm = 'PM';
           } else {
              AmOrPm = 'AM';
           }
           if (dateMin < 10){
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            } else {
              NewdateTimeparts = dateChange + ":" + "0" + dateMin + " " + AmOrPm;
            }
          } else {
            if (dateChange < 10) {
              NewdateTimeparts = "0" + dateChange + ":" + dateMin + " " + AmOrPm; 
            } else {
              NewdateTimeparts = dateChange + ":" + dateMin + " " + AmOrPm;
            }
          }
  
        }
      
      dateTimeAtUTC = NewdateTimeparts;
          }
        }
        console.log("dateTimeUTC", dateTimeAtUTC);
       this.wsDateTimeFormatForViewDetails[x] = dateTimeAtUTC;
       
      }
      response[0].fileCreatedDate = this.wsDateTimeFormatForViewDetails[0];
      response[0].fileModifiedDate = this.wsDateTimeFormatForViewDetails[1];
      response[0].uploadedDateTime = this.wsDateTimeFormatForViewDetails[2];
      response[0].verifiedDateTime = this.wsDateTimeFormatForViewDetails[3];
    }
    return response;
  }
  private setDateFormatForValidDateinViewDetails1(
    verifieddate: string,
    changetoday: any,
    verifiedMonth: string,
    currentMonth: string,
    dateTimeAtUTC: string,
    currentYear: string,
    fetchedYear: string,
    x: number,
    response: any,
    timeZoneChange: string
  ) {
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        this.wsDateTimeFormatForViewDetails[x],
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst =
        this.wsDateTimeFormatForViewDetails[x]?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[2];
      } else {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[0] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1];
      }
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    } else {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        this.wsDateTimeFormatForViewDetails[x],
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    }
    return dateTimeAtUTC;
  }

  private setDateFormatForInvalidDateinView1(
    verifieddate: string,
    dateTimeAtUTC: string,
    changetoday: any,
    currentYear: string,
    fetchedYear: string,
    x: number,
    response: any,
    timeZoneChange: string
  ) {
    verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate === changetoday) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        this.wsDateTimeFormatForViewDetails[x],
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst =
        this.wsDateTimeFormatForViewDetails[x]?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[2];
      } else {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[0] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1];
      }
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    } else {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        this.wsDateTimeFormatForViewDetails[x],
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    }
    return { verifieddate, dateTimeAtUTC };
  }

  transformView(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");

    if (response[0].fileCreatedDate) {
      this.wsDateTimeFormatForViewDetails[0] = response[0].fileCreatedDate;
    }
    if (response[0].fileModifiedDate) {
      this.wsDateTimeFormatForViewDetails[1] = response[0].fileModifiedDate;
    }
    if (response[0].uploadedDateTime) {
      this.wsDateTimeFormatForViewDetails[2] = response[0].uploadedDateTime;
    }
    if (response[0].verifiedDateTime) {
      this.wsDateTimeFormatForViewDetails[3] = response[0].verifiedDateTime;
    }

    if (this.wsDateTimeFormatForViewDetails.length > 0) {
      for (let x = 0; x < this.wsDateTimeFormatForViewDetails.length; x++) {
        let dateTimeAtUTC = new DatePipe("en-US").transform(
          this.wsDateTimeFormatForViewDetails[x],
          "MMM-dd-yyyy hh:mm a zzzz"
        );
        let dateTimeAtUTCChange = dateTimeAtUTC
          ?.split(" ")[2]
          .replace("", "UTC+00:00");
        dateTimeAtUTC =
          dateTimeAtUTC?.split(" ")[0] +
          " " +
          dateTimeAtUTC?.split(" ")[1] +
          " " +
          dateTimeAtUTCChange;
        let timeZoneChange = this.timeZone?.split(" ")[0];
        timeZoneChange = timeZoneChange.replace("(", "");
        timeZoneChange = timeZoneChange.replace(")", "");
        const contains30 = timeZoneChange?.split(":")[1] === "30";
        let timeNumber = timeZoneChange?.split(":");
        const isTimeZoneNegative =
          timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
        this.setOffset(
          contains30,
          timeNumber,
          isTimeZoneNegative,
          timeZoneChange
        );
        let datePipe = new DatePipe("en-US").transform(
          dateTimeAtUTC,
          "MMM-dd-yyyy hh:mm a zzzz",
          timeZoneChange
        );
        response[0].changedate = datePipe.toString();
        this.splitResponse = response[0].changedate?.split(" ");
        let verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          this.DateFormat
        );
        if (this.DateFormat !== "dd-MM-yyyy") {
          verifieddate = new DatePipe("en-US").transform(
            this.splitResponse[0],
            "dd/MM/YYYY"
          );
        }
        verifieddate = verifieddate.replace("-", "/").replace("-", "/");
        let today = moment().toDate();
        let currentDate = moment(today).format("DD/MM/YYYY");
        let currentMonth = currentDate?.split("/")[1];
        let verifiedMonth = verifieddate?.split("/")[1];
        let changetoday;
        let currentDateFirstItem = currentDate?.split("/")[0];
        let currentDateSecondItem = currentDate?.split("/")[1];
        let currentDates =
          currentDateSecondItem +
          "/" +
          currentDateFirstItem +
          "/" +
          currentDate?.split("/")[2];
        changetoday = new DatePipe("en-US").transform(
          currentDates,
          "dd/MM/YYYY",
          this.offset
        );
        let currentYear = moment(today).format("YYYY");
        let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
        if (verifieddate === "Invalid date") {
          ({ verifieddate, dateTimeAtUTC } =
            this.setDateFormatForInvalidDateinView(
              verifieddate,
              dateTimeAtUTC,
              changetoday,
              currentYear,
              fetchedYear,
              x,
              response,
              timeZoneChange
            ));
        } else {
          dateTimeAtUTC = this.setDateFormatForValidDateinViewDetails(
            verifieddate,
            changetoday,
            verifiedMonth,
            currentMonth,
            dateTimeAtUTC,
            currentYear,
            fetchedYear,
            x,
            response,
            timeZoneChange
          );
        }
        this.wsDateTimeFormatForViewDetails[x] = dateTimeAtUTC;
      }
      response[0].fileCreatedDate = this.wsDateTimeFormatForViewDetails[0];
      response[0].fileModifiedDate = this.wsDateTimeFormatForViewDetails[1];
      response[0].uploadedDateTime = this.wsDateTimeFormatForViewDetails[2];
      response[0].verifiedDateTime = this.wsDateTimeFormatForViewDetails[3];
    }
    return response;
  }


  transformViewDockets(response?: any): any {
    this.timeZone = localStorage.getItem("User_time_zone");
    this.timeFormat = localStorage.getItem("TimeFormat");
    this.DateFormat = localStorage.getItem("DateFormat");

    if (response[0].createddtm) {
      this.wsDateTimeFormatForViewDetailsDocket[0] = response[0].createddtm;
    }
    if (response[0].modifieddtm) {
      this.wsDateTimeFormatForViewDetailsDocket[1] = response[0].modifieddtm;
    }

    if (this.wsDateTimeFormatForViewDetailsDocket.length > 0) {
      for (let x = 0; x < this.wsDateTimeFormatForViewDetailsDocket.length; x++) {
        let dateTimeAtUTC = new DatePipe("en-US").transform(
          this.wsDateTimeFormatForViewDetailsDocket[x],
          "MMM-dd-yyyy hh:mm a zzzz"
        );
        let dateTimeAtUTCChange = dateTimeAtUTC
          ?.split(" ")[2]
          .replace("", "UTC+00:00");
        dateTimeAtUTC =
          dateTimeAtUTC?.split(" ")[0] +
          " " +
          dateTimeAtUTC?.split(" ")[1] +
          " " +
          dateTimeAtUTCChange;
        let timeZoneChange = this.timeZone?.split(" ")[0];
        timeZoneChange = timeZoneChange.replace("(", "");
        timeZoneChange = timeZoneChange.replace(")", "");
        const contains30 = timeZoneChange?.split(":")[1] === "30";
        let timeNumber = timeZoneChange?.split(":");
        const isTimeZoneNegative =
          timeNumber[0]?.split("UTC")[1].charAt(0) === "+";
        this.setOffset(
          contains30,
          timeNumber,
          isTimeZoneNegative,
          timeZoneChange
        );
        let datePipe = new DatePipe("en-US").transform(
          dateTimeAtUTC,
          "MMM-dd-yyyy hh:mm a zzzz",
          timeZoneChange
        );
        response[0].changedate = datePipe.toString();
        this.splitResponse = response[0].changedate?.split(" ");
        let verifieddate = new DatePipe("en-US").transform(
          this.splitResponse[0],
          this.DateFormat
        );
        if (this.DateFormat !== "dd-MM-yyyy") {
          verifieddate = new DatePipe("en-US").transform(
            this.splitResponse[0],
            "dd/MM/YYYY"
          );
        }
        verifieddate = verifieddate.replace("-", "/").replace("-", "/");
        let today = moment().toDate();
        let currentDate = moment(today).format("DD/MM/YYYY");
        let currentMonth = currentDate?.split("/")[1];
        let verifiedMonth = verifieddate?.split("/")[1];
        let changetoday;
        let currentDateFirstItem = currentDate?.split("/")[0];
        let currentDateSecondItem = currentDate?.split("/")[1];
        let currentDates =
          currentDateSecondItem +
          "/" +
          currentDateFirstItem +
          "/" +
          currentDate?.split("/")[2];
        changetoday = new DatePipe("en-US").transform(
          currentDates,
          "dd/MM/YYYY",
          this.offset
        );
        let currentYear = moment(today).format("YYYY");
        let fetchedYear = datePipe?.split(" ")[0]?.split("-")[2];
        if (verifieddate === "Invalid date") {
          ({ verifieddate, dateTimeAtUTC } =
            this.setDateFormatForInvalidDateinView(
              verifieddate,
              dateTimeAtUTC,
              changetoday,
              currentYear,
              fetchedYear,
              x,
              response,
              timeZoneChange
            ));
        } else {
          dateTimeAtUTC = this.setDateFormatForValidDateinViewDetails(
            verifieddate,
            changetoday,
            verifiedMonth,
            currentMonth,
            dateTimeAtUTC,
            currentYear,
            fetchedYear,
            x,
            response,
            timeZoneChange
          );
        }
        this.wsDateTimeFormatForViewDetailsDocket[x] = dateTimeAtUTC;
      }
      response[0].createddtm = this.wsDateTimeFormatForViewDetailsDocket[0];
      response[0].modifieddtm = this.wsDateTimeFormatForViewDetailsDocket[1];
    }
    return response;
  }

  private setDateFormatForValidDateinViewDetails(
    verifieddate: string,
    changetoday: any,
    verifiedMonth: string,
    currentMonth: string,
    dateTimeAtUTC: string,
    currentYear: string,
    fetchedYear: string,
    x: number,
    response: any,
    timeZoneChange: string
  ) {
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate >= changetoday && verifiedMonth === currentMonth) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        response[0].changedate,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst =
        this.wsDateTimeFormatForViewDetails[x]?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[2];
      } else {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[0] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1];
      }
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    } else {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        response[0].changedate,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    }
    return dateTimeAtUTC;
  }

  private setDateFormatForInvalidDateinView(
    verifieddate: string,
    dateTimeAtUTC: string,
    changetoday: any,
    currentYear: string,
    fetchedYear: string,
    x: number,
    response: any,
    timeZoneChange: string
  ) {
    verifieddate = dateTimeAtUTC?.split(" ")[0]?.replace(/-/g, "/");
    let regExp = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (verifieddate === changetoday) {
      let onlytime: string;
      if (this.timeFormat === "hh:mm a") {
        onlytime = this.splitResponse[1] + " " + this.splitResponse[2];
      } else if (this.splitResponse[2] === "AM") {
        if (Number(this.splitResponse[1]?.split(":")[0]) + 12 >= 24) {
          onlytime =
            "0" +
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12 - 24) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else if (Number(this.splitResponse[1]?.split(":")[0]) >= 10) {
          onlytime =
            this.splitResponse[1]?.split(":")[0] +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        } else {
          onlytime =
            "0" +
            Number(this.splitResponse[1]?.split(":")[0]) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      } else {
        if (this.splitResponse[1]?.split(":")[0] === "12") {
          onlytime = this.splitResponse[1];
        } else {
          onlytime =
            String(Number(this.splitResponse[1]?.split(":")[0]) + 12) +
            ":" +
            this.splitResponse[1]?.split(":")[1];
        }
      }
      dateTimeAtUTC = onlytime.toString();
    } else if (currentYear === fetchedYear) {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        response[0].changedate,
        this.DateFormat,
        timeZoneChange
      );
      const isYearFirst =
        this.wsDateTimeFormatForViewDetails[x]?.split("-")[0].length === 4;
      if (isYearFirst) {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[2];
      } else {
        this.wsDateTimeFormatForViewDetails[x] =
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[0] +
          "-" +
          this.wsDateTimeFormatForViewDetails[x]?.split("-")[1];
      }
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        dateTimeAtUTC = onlytime;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    } else {
      this.wsDateTimeFormatForViewDetails[x] = new DatePipe("en-US").transform(
        response[0].changedate,
        this.DateFormat,
        timeZoneChange
      );
      if (
        regExp.some((a) =>
          this.wsDateTimeFormatForViewDetails[x].replace("-", " ").includes(a)
        )
      ) {
        let onlytime = this.wsDateTimeFormatForViewDetails[x].replace("-", " ");
        onlytime = onlytime.replace("-", ",");
        let onlytimes = onlytime?.split(",")[0] + ", " + onlytime?.split(",")[1];
        dateTimeAtUTC = onlytimes;
      } else {
        dateTimeAtUTC = this.wsDateTimeFormatForViewDetails[x];
      }
    }
    return { verifieddate, dateTimeAtUTC };
  }

  private setOffset(
    contains30: boolean,
    timeNumber: string[],
    isTimeZoneNegative: boolean,
    timeZoneChange: string
  ) {
    if (contains30) {
      timeNumber[1] = timeNumber[1].replace("30", "00");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 6);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    } else if (timeZoneChange?.split(":")[1] === "00") {
      timeNumber[1] = timeNumber[1].replace("00", "30");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 5);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    } else {
      timeNumber[1] = timeNumber[1].replace("45", "15");
      if (isTimeZoneNegative) {
        let addedOffset = timeNumber[0]?.split("UTC+");
        addedOffset[1] = String(Number(addedOffset[1]) + 6);
        this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
      } else {
        let addedOffset = timeNumber[0]?.split("UTC-");
        if (Number(addedOffset[1]) >= 6) {
          addedOffset[1] = String(Number(addedOffset[1]) - 6);
          if (Number(addedOffset[1]) <= 9) {
            this.offset = "-0" + addedOffset[1] + ":" + timeNumber[1];
          } else {
            this.offset = "-" + addedOffset[1] + ":" + timeNumber[1];
          }
        } else {
          addedOffset[1] = String(6 - Number(addedOffset[1]) - 1);
          this.offset = "+" + addedOffset[1] + ":" + timeNumber[1];
        }
      }
    }
  }
}
