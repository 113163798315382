import { Component,ChangeDetectorRef, ViewChild,OnInit } from "@angular/core";
import { NbContextMenuDirective, NbMenuService } from "@nebular/theme";
import { Router } from "@angular/router";
import { SharedService } from '../../../@core/utils/shared.service';
import { SharedServiceService } from "../../../shared-service.service";
 /**
  * Component three dot
  */
@Component({
  selector: 'ngx-three-dot',
  templateUrl: './three-dot.component.html',
  styleUrls: ['./three-dot.component.scss']
})
export class ThreeDotComponent implements OnInit {
 /**
   * data var
   */ 
  data: any;
  /**
   * workSpaceId var
   */ 
  workSpaceId: string;
  cdkOverlayPane: any;
  /**
   * docList var
   */ 
   doclist: string = '';
  /**
   * selectedDocumentOwner var
   */ 
  selectedDocumentOwner: any;
   /**
   * selectedFileNames var
   */ 
  selectedFileNames: any;
    /**
   * createdBy var
   */
  createdBy: any;
     /**
   * sendDetailsData var
   */
  sendDetailsData: string;
     /**
   * params var
   */
  params: any;
    /**
   * checkDeleteRights var
   */
  checkDeleteRights: string;
    /**
   * deleteRights var
   */
  deleteRights: any;
    /**
   * isEditClick var
   */
  isEditClick = true;
    /**
   * chkdownloadprint var
   */
  chkdownloadprint: string;
    /**
   * chkEditRights var
   */
  chkEditRights: string;
    /**
   * downloadPrint var
   */
  downloadPrint: any;
    /**
   * editrights var
   */
  editrights: any;
    /**
   * guestAccess var
   */
  guestAccess:any;
    /**
   * subscriptionPlan var
   */
  subscriptionPlan:string;
    /**
   * GuestFlag var
   */
  GuestFlag: any;

  docStatus = '';

  itemsArray = [];
 
  /**
 * SearchingComponent three-dot  oninit
 */
  @ViewChild("nb-context-menu") threedot!:any
  /**
   * NbContextMenuDirective Componet
   */
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  selectedDocCount: any;
  ownerShipRight: any;
  getDocstatus: string;
  /**
   * for checking whether guest access is available in role or not
   */
  isGuestAccessInRole: boolean = false;
   /**
   * Public Constructor
   */
  constructor(private cd: ChangeDetectorRef, private router: Router, private sharedservice: SharedService, private sharedserv: SharedServiceService, private menuService: NbMenuService) { 
    this.workSpaceId = localStorage.getItem('localwsId')
    this.subscriptionPlan = localStorage.getItem('wsSubscriptionPlan')
    this.guestAccess = localStorage.getItem('isGuestAccess')
    this.createdBy = localStorage.getItem('localloginId')
    this.chkdownloadprint = localStorage.getItem('downloadandprint')
    this.chkEditRights = localStorage.getItem('editaccess')
    this.checkDeleteRights = localStorage.getItem('deleteaccess');
    this.GuestFlag = localStorage.getItem('GuestFlag');
    this.isGuestAccessInRole = localStorage.getItem('isGuestAccess').toLowerCase() =='true'?true:false; 

    this.downloadPrint = localStorage.getItem("downloadRight");
    this.editrights = JSON.parse(this.chkEditRights);
    this.checkDeleteRights = localStorage.getItem('deleteaccess')
    this.deleteRights = JSON.parse(this.checkDeleteRights);
    this.getDocstatus = localStorage.getItem('sharedDoc');
  }



 /**
 * subscriptionThreeDot  oninit
 */
  subscriptionThreeDot;
 /**
 * subscriptionSearch  oninit
 */
  subscriptionSearch
   /**
 * searching three-dot  oninit
 */

  ngOnInit(): void {
    this.guestAccess = localStorage.getItem('isGuestAccess')
    let docOwner = localStorage.getItem('selectedDocOwnedBy');
    this.getUpdatedValue();
    if(this.GuestFlag ==='False' && !this.isGuestAccessInRole){
     this.items = this.items.filter((obj) => obj.title !== "Share with guests");
   }
    if (localStorage.getItem('downloadRight') !== 'true') {
      this.items = this.items.filter((obj) => obj.title !== "Download");
    }
    if (localStorage.getItem('downloadRight') !== 'true') {
      this.items = this.items.filter((obj) => obj.title !== "Print");
    }
    if (!this.ownerShipRight) {
      this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
    }
  
    this.checkDeleteRights = localStorage.getItem('deleteaccess')
    this.deleteRights = JSON.parse(this.checkDeleteRights);
     
    this.subscriptionSearch = this.sharedservice.threeDotValue.subscribe((data) => {
   
    });
    this.subscriptionThreeDot = this.menuService.onItemClick().subscribe((event) => {
       if(event.item.title === 'Open Document'){
        this.sharedservice.ownedview.next('View');
          this.onViewClick();
        }
        else if (event.item.title === 'Preview') {
          this.sharedservice.ownedview.next('View');
          this.onPreviewClick();
        } 
        else if(event.item.title === 'Transfer ownership'){
          this.sharedservice.ownedview.next('View');
         this.onTransferClick();
        }
        else if(event.item.title === 'Download'){
          this.sharedservice.ownedview.next('View');
         this.onViewClickDownload();
        }
        else if(event.item.title === 'Print'){
          this.sharedservice.ownedview.next('View');
         this.onViewClickPrint();
        }
        else if(event.item.title === 'View details'){
          this.sharedservice.ownedview.next('View');
         this.onViewClickDocDetails();
        this.sharedserv.ownedByMeViewDetails.next(true);
        }
       
        else if(event.item.title === 'Delete'){
          this.sharedservice.ownedview.next('View');
             this.onDeleteClick();
        }
        else if (event.item.title === 'Share with guests'){
          this.sharedservice.ownedview.next('View');
          this.onGuestClick();
        }
       
        else if (event.item.title === 'Share'){
          this.sharedservice.ownedview.next('View');
          this.onShareClick();
        }
        else if (event.item.title === 'Tag'){
          this.sharedservice.ownedview.next('View');
          this.onTagVerifyClick();
        }
     });
     this.sharedservice.ownerShipRight.subscribe((res:any) =>{
      this.ownerShipRight = res;
     })
     this.sharedservice.threeDotValue.subscribe((res:any)=>{
      this.selectedDocCount = res;
      this.getUpdatedValue();
      
    })
    this.sharedservice.docPreview.subscribe((res: any) => {
      this.docStatus = res[0]?.parserstatuscode;
      this.itemsArray = res;
    })
    
  }
  getUpdatedValue(){
    let docOwner = localStorage.getItem('selectedDocOwnedBy');
    if (localStorage.getItem('shareRightValidation') !== 'true') {
      this.items = this.items.filter((obj) => obj.title !== "Share");
      this.items = this.items.filter((obj) => obj.title !== "Tag");
     }
     if (localStorage.getItem('TransferOwnerShipValidation') !== 'true'){
      this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
     }
    if(this.selectedDocCount == 'multi'){
      let multiState = this.itemsArray.filter((obj) => (obj.parserstatuscode == 'doc_21' || obj.parserstatuscode == 'doc_23' || obj.parserstatuscode == 'doc_15'));
      if (multiState.length > 0) {
        if (this.docStatus == 'doc_21' || this.docStatus == 'doc_23' || this.docStatus == 'doc_15') {
          this.items = this.items.filter((obj) => obj.title !== "Open Document" &&  obj.title !== "View details" && obj.title !== "Download" && obj.title !== "Print" && obj.title !== "Share with guests"  &&  obj.title !== "Preview");
          if (localStorage.getItem('shareRightValidation') !== 'true') {
            this.items = this.items.filter((obj) => obj.title !== "Share");
           }
           if (localStorage.getItem('sharetagsRightValidation') !== 'true') {
            this.items = this.items.filter((obj) => obj.title !== "Tag");
           }
           if(localStorage.getItem('deleteRights') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Delete");
        }      
        if(this.items.length === 0){
          this.items[0] =  {
            title: "View details",
            icon: { icon: "file-text-outline", pack: "eva" },
          }
        }
        } else if (this.docStatus == 'doc_22') {
          this.items = this.items.filter((obj) => obj.title !== "Open Document" &&  obj.title !== "View details" && obj.title !== "Print" && obj.title !== "Share with guests"  &&  obj.title !== "Preview");
        if (localStorage.getItem('downloadRight') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Download");
        }
        if (localStorage.getItem('shareRightValidation') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Share");
         }
         if (localStorage.getItem('sharetagsRightValidation') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Tag");
         }
         if(localStorage.getItem('deleteRights') !== 'true'){
          this.items = this.items.filter((obj) => obj.title !== "Delete");
      }      
      if(this.items.length === 0){
        this.items[0] =  {
          title: "View details",
          icon: { icon: "file-text-outline", pack: "eva" },
        }
      }
        }
      } else {
        this.items = this.items.filter((obj) => obj.title !== "Open Document" &&  obj.title !== "View details" && obj.title !== "Print" && obj.title !== "Share with guests"  &&  obj.title !== "Preview");
        if (localStorage.getItem('downloadRight') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Download");
        }
        if (localStorage.getItem('shareRightValidation') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Share");
         }
         if (localStorage.getItem('sharetagsRightValidation') !== 'true') {
          this.items = this.items.filter((obj) => obj.title !== "Tag");
         }
         if(localStorage.getItem('deleteRights') !== 'true'){
          this.items = this.items.filter((obj) => obj.title !== "Delete");
      }      
      if(this.items.length === 0){
        this.items[0] =  {
          title: "View details",
          icon: { icon: "file-text-outline", pack: "eva" },
        }
      }
      }
    }
    else if(this.selectedDocCount == 'single'){
      if (this.docStatus == 'doc_21' || this.docStatus == 'doc_23' || this.docStatus == 'doc_15') {
        if(localStorage.getItem('sharedDoc') == 'Not Access' && this.createdBy !== localStorage.getItem('selectedDocOwnedBy')){
          this.items = this.items.filter((obj) => obj.title !== "Open Document");
          this.items = this.items.filter((obj) => obj.title !== "Preview");
          this.items = this.items.filter((obj) => obj.title !== "Tag");
          this.items = this.items.filter((obj) => obj.title !== "Download");
          this.items = this.items.filter((obj) => obj.title !== "Print");
          this.items = this.items.filter((obj) => obj.title !== "Share");
          this.items = this.items.filter((obj) => obj.title !== "Share with guests");
          this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
          this.items = this.items.filter((obj) => obj.title !== "Delete");
        }
        else{
          this.items =[
            {
              title: 'Open Document',
              icon: { icon: 'external-link-outline', pack: 'eva' },
            },
            {
              title: 'Preview',
              icon: { icon: 'eye-outline', pack: 'eva' },
            },
            // {
            //   title: 'Download',
            //   icon: { icon: 'download-outline', pack: 'eva' },
            // },
            // {
            //   title: 'Print',
            //   icon: { icon: 'printer-outline', pack: 'eva' },
            // },
            {
              title: 'Tag',
              icon: { icon: 'pricetags-outline', pack: 'eva' },
            },
            {
              title: 'View details',
              icon: { icon: 'file-text-outline', pack: 'eva' },
            },
            {
              title: 'Share',
              icon: { icon: 'share-outline', pack: 'eva' },
            },
            {
              title: 'Share with guests',
              icon: { icon: 'people-outline', pack: 'eva' },
            },
            {
              title: 'Transfer ownership',
              icon: { icon: 'swap-outline', pack: 'eva' },
            },
            {
              title: 'Delete',
              icon: { icon: 'trash-2-outline', pack: 'eva' },
            },
           ];
           if(localStorage.getItem('deleteRights') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Delete");
           }
           if (localStorage.getItem('preview') !== 'True') {
            this.items = this.items.filter((obj) => obj.title !== "Preview");
           }
           if (!this.editrights && docOwner !== this.createdBy) {
            this.items = this.items.filter((obj) => obj.title !== "Edit");
           }
           if (this.GuestFlag === 'False' && !this.isGuestAccessInRole) {
             this.items = this.items.filter((obj) => obj.title !== "Share with guests");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Download");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Print");
           }
           if (localStorage.getItem('TransferOwnerShipValidation') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
           }
           if (localStorage.getItem('shareRightValidation') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Share");
             this.items = this.items.filter((obj) => obj.title !== "Tag");
            }
        }
      } else if(this.docStatus == 'doc_22') {
        if(localStorage.getItem('sharedDoc') == 'Not Access' && this.createdBy !== localStorage.getItem('selectedDocOwnedBy')){
          this.items = this.items.filter((obj) => obj.title !== "Open Document");
          this.items = this.items.filter((obj) => obj.title !== "Preview");
          this.items = this.items.filter((obj) => obj.title !== "Tag");
          this.items = this.items.filter((obj) => obj.title !== "Download");
          this.items = this.items.filter((obj) => obj.title !== "Print");
          this.items = this.items.filter((obj) => obj.title !== "Share");
          this.items = this.items.filter((obj) => obj.title !== "Share with guests");
          this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
          this.items = this.items.filter((obj) => obj.title !== "Delete");
        }
        else{
          this.items =[
            {
              title: 'Open Document',
              icon: { icon: 'external-link-outline', pack: 'eva' },
            },
            {
              title: 'Preview',
              icon: { icon: 'eye-outline', pack: 'eva' },
            },
            {
              title: 'Download',
              icon: { icon: 'download-outline', pack: 'eva' },
            },
            // {
            //   title: 'Print',
            //   icon: { icon: 'printer-outline', pack: 'eva' },
            // },
            {
              title: 'Tag',
              icon: { icon: 'pricetags-outline', pack: 'eva' },
            },
            {
              title: 'View details',
              icon: { icon: 'file-text-outline', pack: 'eva' },
            },
            {
              title: 'Share',
              icon: { icon: 'share-outline', pack: 'eva' },
            },
            {
              title: 'Share with guests',
              icon: { icon: 'people-outline', pack: 'eva' },
            },
            {
              title: 'Transfer ownership',
              icon: { icon: 'swap-outline', pack: 'eva' },
            },
            {
              title: 'Delete',
              icon: { icon: 'trash-2-outline', pack: 'eva' },
            },
           ];
           if(localStorage.getItem('deleteRights') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Delete");
           }
           if (localStorage.getItem('preview') !== 'True') {
            this.items = this.items.filter((obj) => obj.title !== "Preview");
           }
           if (!this.editrights && docOwner !== this.createdBy) {
            this.items = this.items.filter((obj) => obj.title !== "Edit");
           }
           if (this.GuestFlag === 'False' && !this.isGuestAccessInRole) {
             this.items = this.items.filter((obj) => obj.title !== "Share with guests");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Download");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Print");
           }
           if (localStorage.getItem('TransferOwnerShipValidation') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
           }
           if (localStorage.getItem('shareRightValidation') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Share");
             this.items = this.items.filter((obj) => obj.title !== "Tag");
            }
        }
      } else {
        if(localStorage.getItem('sharedDoc') == 'Not Access' && this.createdBy !== localStorage.getItem('selectedDocOwnedBy')){
          this.items = this.items.filter((obj) => obj.title !== "Open Document");
          this.items = this.items.filter((obj) => obj.title !== "Preview");
          this.items = this.items.filter((obj) => obj.title !== "Tag");
          this.items = this.items.filter((obj) => obj.title !== "Download");
          this.items = this.items.filter((obj) => obj.title !== "Print");
          this.items = this.items.filter((obj) => obj.title !== "Share");
          this.items = this.items.filter((obj) => obj.title !== "Share with guests");
          this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
          this.items = this.items.filter((obj) => obj.title !== "Delete");
        }
        else{
          this.items =[
            {
              title: 'Open Document',
              icon: { icon: 'external-link-outline', pack: 'eva' },
            },
            {
              title: 'Preview',
              icon: { icon: 'eye-outline', pack: 'eva' },
            },
            {
              title: 'Download',
              icon: { icon: 'download-outline', pack: 'eva' },
            },
            {
              title: 'Print',
              icon: { icon: 'printer-outline', pack: 'eva' },
            },
            {
              title: 'Tag',
              icon: { icon: 'pricetags-outline', pack: 'eva' },
            },
            {
              title: 'View details',
              icon: { icon: 'file-text-outline', pack: 'eva' },
            },
            {
              title: 'Share',
              icon: { icon: 'share-outline', pack: 'eva' },
            },
            {
              title: 'Share with guests',
              icon: { icon: 'people-outline', pack: 'eva' },
            },
            {
              title: 'Transfer ownership',
              icon: { icon: 'swap-outline', pack: 'eva' },
            },
            {
              title: 'Delete',
              icon: { icon: 'trash-2-outline', pack: 'eva' },
            },
           ];
           if(localStorage.getItem('deleteRights') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Delete");
           }
           if (localStorage.getItem('preview') !== 'True') {
            this.items = this.items.filter((obj) => obj.title !== "Preview");
           }
           if (!this.editrights && docOwner !== this.createdBy) {
            this.items = this.items.filter((obj) => obj.title !== "Edit");
           }
           if (this.GuestFlag === 'False' && !this.isGuestAccessInRole) {
             this.items = this.items.filter((obj) => obj.title !== "Share with guests");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Download");
           }
           if (localStorage.getItem('downloadRight') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Print");
           }
           if (localStorage.getItem('TransferOwnerShipValidation') !== 'true'){
            this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
           }
           if (localStorage.getItem('shareRightValidation') !== 'true') {
             this.items = this.items.filter((obj) => obj.title !== "Share");
             this.items = this.items.filter((obj) => obj.title !== "Tag");
            }
        }  
      } 
    }

    //Remove Share With Guests when no guest access plan
    let GuestAccess=localStorage.getItem('GuestFlag');
    if(GuestAccess!='True' || !this.isGuestAccessInRole)
    {
      for(let i=0; i<this.items.length; i++)
      {
        if(this.items[i].title=='Share with guests')
        {
          this.items.splice(i,1)
        }
      }
    }


  }
 
   /**
 * searching three-dot items  oninit
 */
 items =[
      {
        title: 'Open Document',
        icon: { icon: 'external-link-outline', pack: 'eva' },
      },
      {
        title: 'Preview',
        icon: { icon: 'eye-outline', pack: 'eva' },
      },
     
      {
        title: 'Download',
        icon: { icon: 'download-outline', pack: 'eva' },
      },
      {
        title: 'Print',
        icon: { icon: 'printer-outline', pack: 'eva' },
      },
      {
        title: 'Tag',
        icon: { icon: 'pricetags-outline', pack: 'eva' },
      },
      {
        title: 'View details',
        icon: { icon: 'file-text-outline', pack: 'eva' },
      },
      {
        title: 'Share',
        icon: { icon: 'share-outline', pack: 'eva' },
      },
      {
        title: 'Share with guests',
        icon: { icon: 'people-outline', pack: 'eva' },
      },
      {
        title: 'Transfer ownership',
        icon: { icon: 'swap-outline', pack: 'eva' },
      },
    
      {
        title: 'Delete',
        icon: { icon: 'trash-2-outline', pack: 'eva' },
      },
     ];

   /**
  * method to hide the threedot component on scroll
  */  
   hide() {
    this.cdkOverlayPane[0].style.display = 'none';
  }

  /**
 * openNewTab  
 */
  openNewTab() {
    window.open('', '_blank');
  }
  /**
 * onGuestClick  
 */
  onGuestClick(){
    const params = {
      rowData: this.params?.node.data
    }
    this.params?.onGuestClick(params);
    
  }
  /**
 * onViewClickPrint  
 */
  onViewClickPrint() {
    /**
     * params
     */
    const params = {
    
      rowData: this.params?.node.data
    }
    this.params?.onViewClickPrint(params);
  }
    /**
 * onShareClick  
 */
   onShareClick() {
      /**
     * params
     */
    const params = {
   
      rowData: this.params?.node.data
    }
    this.params?.onShareClick(params);
  }
  onTagVerifyClick(){
    const params = {
   
      rowData: this.params?.node.data
    }
    this.params?.onTagVerify(params);
  }
  /**
 * onViewClickDownload  
 */
  onViewClickDownload() {
     /**
     * params
     */
    const params = {
      rowData: this.params?.node.data
    }
    this.params?.onViewClickDownload(params);
  }
    /**
 * onViewClickDocDetails  
 */
  onViewClickDocDetails() {
     /**
     * params
     */
    const params = {
  
      rowData: this.params?.node.data
    }
    this.params?.onViewClickDocDetails(params);

  }
onPreviewClick(){
  /**
     * params
     */
   const params = {
  
    rowData: this.params?.node.data
  }
  this.params?.onPreviewClick(params);
}
    /**
 * onTransferClick  
 */
  onTransferClick() {
    if (this.params?.onTransferClick instanceof Function) {
    /**
     * params
     */
      const params = {
      
        rowData: this.params?.node.data,
      
      };
      this.params?.onTransferClick(params);
    }
  }

     /**
 * onViewClick  
 */
   onViewClick() {
    if (this.params?.onViewClick instanceof Function) {
 /**
     * params
     */
    

      const params = {
     
        rowData: this.params?.node.data,
        documentId: this.params?.node.data.docId,
        docGuid: this.params?.node.data.docGuid,

      }
    
      this.params?.onViewClick(params);

    }
  }
      /**
 * onEditClick  
 */
  onEditClick() {
    if (this.params?.onEditClick instanceof Function) {
       /**
     * params
     */
      const params = {
    
        rowData: this.params?.node.data,
        documentId: this.params?.node.data.docId,
        docGuid: this.params?.node.data.docGuid,
      }
      this.params?.onEditClick(params);
    }
  }

  /**
 * onDeleteClick  
 */
  onDeleteClick() {

   
    const params = {
      
      rowData: this.params?.node.data
     
    }
    this.params?.onDeleteClick(params);
  }

/**
 * shareduser  var
 */
  shareduser;
 
   /**
 * agInit  
 */
    paramsdata;
 


   agInit(params: any) {
    this.data = params?.rowData;
    this.params = params;
    if(params?.data.docShareStatus == 'Not Access' && this.createdBy !== params?.data.documentowner){
      this.items = this.items.filter((obj) => obj.title !== "Open Document");
      this.items = this.items.filter((obj) => obj.title !== "Preview");
      this.items = this.items.filter((obj) => obj.title !== "Download");
      this.items = this.items.filter((obj) => obj.title !== "Print");
      this.items = this.items.filter((obj) => obj.title !== "Share");
      this.items = this.items.filter((obj) => obj.title !== "Share with guests");
      this.items = this.items.filter((obj) => obj.title !== "Transfer ownership");
      this.items = this.items.filter((obj) => obj.title !== "Delete");
    }
    else if(params?.data.docShareStatus == 'Access' && this.createdBy !== params?.data.documentowner){
      this.items = this.items.filter((obj) => obj.title !== "Transfer ownership")
      this.items = this.items.filter((obj) => obj.title !== "Share");
      if(localStorage.getItem('downloadRight') !== 'true'){
        this.items = this.items.filter((obj) => obj.title !== "Download")
        this.items = this.items.filter((obj) => obj.title !== "Print")
  
      }

      if(localStorage.getItem('deleteRights') !== 'true'){
        this.items = this.items.filter((obj) => obj.title !== "Delete")
       
      }
      if(!this.editrights){
        this.items = this.items.filter((obj) => obj.title !== "Edit")
       
      }
      if(this.GuestFlag ==='False' && !this.isGuestAccessInRole){
        this.items = this.items.filter((obj) => obj.title !== "Share with guests")
      }
      else{
        if(this.guestAccess == "false"){
          this.items = this.items.filter((obj) => obj.title !== "Share with guests")
        }
      }
    }
   

  }

}
