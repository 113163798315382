import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/observable/of';
import 'rxjs/Rx';
import { environment } from '../.../../../../../src/environments/environment';
import { ApiServiceService } from '../../@core/api-service.service';
import { retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EChartsService {
  createdBy: any;
  workSpaceId: any;

    constructor(private http: HttpClient,private apiService:ApiServiceService) {
      this.workSpaceId = localStorage.getItem('localwsId')
      this.createdBy = localStorage.getItem('localloginId')
  } 
  public baseURL=environment.baseUrl;
  public baseOrigin = environment.baseOrigin;

   //   //************************ API for Progress Section in E-Charts show API Get End ************************

   progresssection(data) {

    return this.apiService.post('/api/GetDashboardCount/GetDashboardAnalyticsCount', data);
   
  }

     //   //************************ API for line chart in E-Charts show API Get End ************************
  
     getlinechartapi(data) {
      return this.apiService.post('/api/GetDashboardCount/GetDashboardDocCountAnalytics', data);
     
    }


    getDocchartapi(data) {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Access-Control-Allow-Origin', this.baseURL + '');
      headers.append('Access-Control-Allow-Credentials', 'true');
      headers.append('GET', 'POST');
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }) };
      return this.http.post(this.baseURL + '/api/GetDashboardCount/GetDashboardDocTypeCountAnalytics', data, httpOptions).pipe(
        retry(3)
      );
    }


    getsharedchartapi(data) {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Access-Control-Allow-Origin', this.baseURL + '');
      headers.append('Access-Control-Allow-Credentials', 'true');
      headers.append('GET', 'POST');
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }) };
      return this.http.post(this.baseURL + '/api/GetDashboardCount/GetDashboardUsedSpaceCountAnalytics', data, httpOptions).pipe(
        retry(3)
      );
    }
    getPeriodChartApi(data) {
      return this.apiService.post('/api/GetDashboardCount/GetDashboardVerifiedCountAnalytics', data);
   
    }

        //   //************************ API for doughnut chart in E-Charts show API Get End ************************
    doughnutchart(data) {
      return this.apiService.post('/api/GetDashboardCount/GetDashboardDocStatusCountAnalytics', data);
       
    }

          //   //************************ API for doughnut chart in E-Charts show API Get End ************************
    
        rolechart(data) {
              return this.apiService.post('/api/GetDashboardCount/GetDashboardUserRoleCountAnalytics', data);      
        }

          //   //************************ API for stats chart in E-Charts show API Get End ************************
          // api​/GetDashboardCount​/GetDashboardDocMonthwiseCountAnalytics
      getstatscardApi(data) {
            return this.apiService.post('/api/GetDashboardCount/GetDashboardDocMonthwiseCountAnalytics', data);      
      }
}
