import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
@Injectable({
  providedIn: 'root'
})
 
export class InviteUsersService {

    constructor(private apiService:ApiServiceService) { } 
  guestListService(data){
    return this.apiService.post( '/api/GuestAccess/GetDocGuestAccess', data);

  }
  guestConfirm(data){
    return this.apiService.post( '/api/GuestAccess/SaveDocGuestShare', data);
  }
  emailWiseGuestShare(data){
    return this.apiService.post97('/api/GuestAccess/SaveDocGuestShare',data)
  }
  RemoveGuestShareAccess(data){
    return this.apiService.post97('/api/GuestAccess/RemoveGuestAccess',data)
  }
  ResendDocumentGuestShare(data){
    return this.apiService.post97('/api/WhatsAppGuestAccess/ResandGuestshareDocList',data)
  }
  getListofSharedUsersOfDoc(data){
    return this.apiService.post('/api/WhatsAppGuestAccess/GetGuestList',data)
  }
  checkIfDcoinsAvailable(data){
    return this.apiService.post97('/api/WhatsAppGuestAccess/DeductDCoins',data)
  }

  DocDetailsEmailandnumberList(data) {
    return this.apiService.post('/api/EmailAndNumber/DocDetailsEmailandnumberList', data)
  }
  phoneNumberWiseGuestShare(data){
    return this.apiService.post97('/api/WhatsAppGuestAccess/SaveDocWhatsAppGuestShare',data)
  }
  guestUserRights(data){
    return this.apiService.post('/api/Mock/GetUserRights',data)
  }
  subscriptionDetailsApiCall(data){
    return this.apiService.post('/api/Workspace/SubscriptionDetails',data)
  }
  DcoinsRelatedSettings(){
    return this.apiService.get('/api/DCoinesSettings/DCoinesSettingsList')
  }

  validateCommDomainService(data) {
    return this.apiService.post('/api/GeneralSettings/ValidateCommDomain', data);
  }
  saveDomain(data) {
    return this.apiService.post('/api/GeneralSettings/DomainInvitation', data);
  }
  documentFormatService(data) {
   return this.apiService.get('/api/RandomDocumentUpload/GetDocFormat');
  }
  
  getaccessrights(data) {
    return this.apiService.post( '/api/Workspace/MenuRights', data);
  }
  UploadRightsUserListService(data) {
   return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }
  viewUserWiseRoleAccess(data) {
   return this.apiService.post( '/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }
  searchUsers(data) { 
    return this.apiService.post('/api/InvitesUser/Search', data);
  }
  deleteInvitationService(data) {
    return this.apiService.post('/api/MemberInvitation/Delete', data);
  }
  resendInvitationService(data) {
    return this.apiService.post('/api/MemberInvitation/Push', data);
  }
  getRolesListService(data) {
  return this.apiService.post('/api/Role/Get', data);
  }

  searchAllUsersService(data) {
    return this.apiService.post('/api/AllUsers/Search', data);
  } 

  usersInviteService(data) {
       return this.apiService.post( '/api/MemberInvitation/Push', data);
  }
    /**
   *
   * @param data
   * @returns api method 'post()' for api '/api/DCoinesSettings/DCoinesSettingsList'
   */
     getDCoinsMapping() {
      return this.apiService.get("/api/DCoinesSettings/DCoinesSettingsList");
    }





}
