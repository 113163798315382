import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
/**
 *  Injectable for all workspace service 
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for all workspace service 
 */
export class AllWorkspaceService {
/**
 *  git ignore onInit
 */
  constructor(private apiService:ApiServiceService) { }
  public showEdit:boolean;
  public showDownload:boolean;
  public showDelete:boolean;
  public showView: boolean;
  public showPrint: boolean; 

/**
 *  method For list of all shared docs 
 */
allWsDocListService(data) {
  return this.apiService.post97('/api/AllWorkspaceDocument/AllWSDocit', data);
}
allWsData(data){
  return this.apiService.post('/api/AllWorkspaceDocument/AllWSDoc', data);
}
/**
 *  method For size trash status 
 */
SizeTrashStatusService(data) {
  return this.apiService.post('/api/Trash/TrashSizeCount', data);
}
/**
 *  method For Access view, edit etc on the basis of roles
 */
viewUserWiseRoleAccess(data){
  return this.apiService.post( '/api/GetUserRoleAccess/UserWiseRoleAccess', data);
}
/**
 *  method For Document format service
 */
documentFormatService(data) {
  return this.apiService.get('/api/RandomDocumentUpload/GetDocFormat');
}
/**
 *  method For delete Access Multiple Service
 */
deleteAccessMultipleService(data) {
  return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
}

}
 