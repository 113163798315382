<ngx-one-column-layout [isDocumentUploadEnabled]="isDocumentUpload" [ngClass]="variable ? 'mainAppBox':'msgBox' ">

  <button *ngIf="isDocumentUpload" nbButton shape="round" class="buttonUpload mb-3 opacity-40" appDebounceClick
  (debounceClick)="upload()" [debounceTime]="0">
    <nb-icon icon="upload-outline"></nb-icon><span>UPLOAD</span>
  </button>
  <ngx-upload *ngIf="showUploadDialog" [showUploadDialog]="showUploadDialog" (closeUploadDialog)="closediagoue();">
  </ngx-upload>

  <nb-menu tag="menu" [items]="menu" class="nav-dox2u menuborder abc"></nb-menu>

  <nb-progress-bar [value]="dpagesProgressCount" class="bar-width mb-1 mt-1" status="control"></nb-progress-bar>
  <router-outlet></router-outlet>
</ngx-one-column-layout>
<div  [ngClass]="variable1 ? 'msgBox':'mainAppBox' ">
  <div class="d-flex flex-column align-items-center text-center" style="width:437px;">




  <div class="shadowBoxParent">
    <div 
    class="d-flex justify-content-center align-items-center iconbox">
    <nb-icon icon="monitor-outline" class="text-hint shadowboxicon"></nb-icon>
  </div>
  <div class="shadowBox"  >

  </div>
  </div>





    <p class="Text Font/P1 Paragraph  mt-3" style="padding: 0 20px ;">dox2U currently supports screen resolutions greater than 768px. Your current screen size is not supported and may prevent dox2U from functioning properly.
    </p>
    <button  class="btn_navigate" (click)="navigateDashboard()">PROCEED ANYWAYS</button>
  </div>
</div>

<ng-template #accountdisabled>
  <nb-card class="promptBody">
    <nb-card-header>
      <div>
        <h6 class="text-basic">{{result[1]}}</h6>
      </div>
    </nb-card-header>
    <nb-card-body class="prompt-body">
      <div>
        <p>
          {{result[0]}}</p>

      </div>


    </nb-card-body>
    <nb-card-footer>
      <div
        *ngIf="(result[0] != 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.') && !isPlanChanged">
        <button nbButton status="primary" class="textbutton" (click)="accoutClose(result[2] || result[1],result[3])">REFRESH</button>
      </div>
      <div
        *ngIf="(result[0] != 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.') && isPlanChanged">
        <button nbButton status="primary" class="textbutton" (click)="accoutClose(result[1],result[3])">Log out</button>
      </div>
      <div
        *ngIf="result[0] == 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.'">
        <button nbButton status="primary" class="textbutton" (click)="accoutClose(result[2],result[3])">Take me to
          dashboard</button>

      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

 <ng-template #purchaseDcoins let-data let-ref="dialogRef" class="purchaseDcoinsModal">
    <nb-card class="purchaseDcoinsModal">
        <nb-card-header>
            <div class="justify-content-between d-flex">
                <h6 class="subtitle text-basic">
                    Insufficient dCoins
                </h6>
                <nb-icon  (click)="ref.close();" class="cursor-pointer" icon="close-outline"></nb-icon>
            </div>
    
        </nb-card-header>
        <nb-card-body>
    
         
                <div>
            <div class=" form-group mb-0">
                <div class="pt-0 shared-access-select">
                   
                            <div>
                               <div class="mt-2">
                                    
                                        <p>
                                            Your current dCoins balance is not enough to share these documents through WhatsApp.
                                        </p>
                                        <p>Please purchase additional dCoins and try again</p>
    
                                    
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                </div>
    
        </nb-card-body>
        <nb-card-footer class="">
            <div>
              <button nbButton status="primary" class="footer-button purchaseBTN mr-4" size="small"
                (click)="ref.close();purchaseClicked()">PURCHASE</button>

                <button (click)="ref.close();"
                class="footer-button cancelBTN">CANCEL</button>
            
                
            </div>
           
        </nb-card-footer>
    </nb-card>
 </ng-template>

    <!--Doc Shared Real Time Notify-->
    <ng-template #docSharedNotify let-data let-ref="docSharedNotifyRef" class="purchaseDcoinsModal">
      <nb-card class="purchaseDcoinsModal">
          <nb-card-header>
              <div class="justify-content-between d-flex">
                  <h6 class="subtitle text-basic">
                    Documents shared with Guest
                  </h6>
                  <nb-icon  (click)="docSharedNotifyRef.close()" class="cursor-pointer" icon="close-outline"></nb-icon>
              </div>
      
          </nb-card-header>
          <nb-card-body>
         
                  <div >
                     
                              <div>
                                 <div>
                                      
                                          <span>
                                            {{docSharedRealTimeNotifyBody}}
                                          </span>
                                          <span>
                                            View <u (click)="NavigateOwnedBy(); docSharedNotifyRef.close();" style="color: blue; cursor: pointer;">Docs Owned</u>
                                          </span>
  
                                          <br>
                                          
                                          
                                  </div>
                              </div>

                              <br>
                      <p>
                          {{docSharedRealTimeNotifyFooter}}
                      </p>
      
                      </div>
                      


                      
      
                  
      
          </nb-card-body>
          <!-- <nb-card-footer>
              <div style="padding:16px 12px;">
                
              </div>
             
          </nb-card-footer> -->
      </nb-card>
      </ng-template>