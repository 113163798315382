import { Observable } from 'rxjs';
/**
 *  TrafficList 
 */
export interface TrafficList {
    /**
      *  variable for date
   */
  date: string;
    /**
      *  variable for value
   */
  value: number;
    /**
      *  variable for delta object
   */
  delta: {
    up: boolean;
    value: number;
  };
    /**
      *  variable for comparison object
   */
  comparison: {
    prevDate: string;
    prevValue: number;
    nextDate: string;
    nextValue: number;
  };
}
/**
 *  TrafficListData 
 */
export abstract class TrafficListData {
     /**
 *  abstract method for getOrdersChartData
 */
  abstract getTrafficListData(period: string): Observable<TrafficList>;
}
