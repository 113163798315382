
import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
/**
 *  Injectable for owner control service 
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  export for owner control service 
 */
export class OwnerControlService {
/**
 *  git ignore onInit
 */
  constructor(private apiService:ApiServiceService) { }
   
/**
 *  method For transfer workspace ownership api start 
 */
   transferWSOwnershipService(data) {
    return this.apiService.post('/api/WsManagement/OwnershipTransfer', data);
  }
/**
 *  method For  Enabled users list start here
 */
   enabledUsersListService(data) {
    return this.apiService.post('/api/Workspace/EnabledUserList', data);
  }
  /**
 *  method For  GetNonEditableORcancel
 */
  GetNonEditableORcancel(data) {
    return this.apiService.post('/api/DocumentVerification/GetNonEditableORcancel', data);
  }
 /**
 *  method For  randomDocLanguageService
 */
  randomDocLanguageService(data) {
    return this.apiService.post('/api/RandomDocumentUpload/Languages', data);
  }
   /**
 *  method For  UnParser Document Process
 */
  UnparserDocumentProcess(data) {
    const headers = { 'content-type': 'application/json'}  
    return this.apiService.post('/api/ElasticDynamicCreateDocument/UnparserDocumentProcess' + '?WSOIDs=' + data.wsoid + '&languages=' + data.languages ,{'headers':headers});
  }
  /**
 *  method For  Document Skip for Parser
 */
  DocumentSkipforParser(data) {
    const headers = { 'content-type': 'application/json'}  
    return this.apiService.post('/api/ElasticDynamicCreateDocument/DocumentSkipforParser' + '?WSOID=' + data.wsoid,{'headers':headers});
  }
   /**
 *  method For  wsSubscriptionDetailsService
 */
  wsSubscriptionDetailsService(data) {
    return this.apiService.post('/api/Workspace/SubscriptionDetails', data);
   }

     /**
 *  method For  disableWorkspaceService
 */
   disableWorkspaceService(data) {
    return this.apiService.post('/api/Workspace/DisableWorkspace', data);
  }

  /**
 *  method For  disableWorkspaceService
 */
  enableWorkspaceService(data) {
   return this.apiService.post('/api/Workspace/EnableWorkspace', data);
  }
 /**
 *  method For API for add notify insert API Get End
 */
    notifyinsert(data) {
      return this.apiService.post('/api/GetNotificationUser/NotificationInsert', data);
    }
 /**
 *  method For API for add notify insert API Get End
 */
    notifysend(data) {
     return this.apiService.post('/api/Notification/UserSendMessage', data);
    }


    
}
