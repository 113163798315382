import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
@Injectable({
  providedIn: 'root'
})
export class SharedDocsService {

  constructor(private apiService:ApiServiceService) { }

  public showEdit:boolean;
  public showDownload:boolean;
  public showDelete:boolean;
  public showView: boolean;
  public showPrint: boolean; 

// For list of all shared docs 

  getSharedByMeDocListData(data) {
    return this.apiService.post('/api/SharedWithMe/SharedDocList', data);
  }
  SizeTrashStatusService(data) {
    return this.apiService.post('/api/Trash/TrashSizeCount', data);
  }
  // Access view, edit etc on the basis of roles
  viewUserWiseRoleAccess(data){
    return this.apiService.post( '/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }

  // Document format service

  documentFormatService(data) {
    return this.apiService.get('/api/RandomDocumentUpload/GetDocFormat');
  }

  deleteAccessMultipleService(data) {
    return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
  }

  updateTrashService(data) {
    return this.apiService.post('/api/Trash/UpdateDocTrashStatus', data);
  }


}


