import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  Renderer2,
  HostListener,
  Input,
  Injectable,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ApiIntegrationService } from "../../pages/api-integration.service";
import { DetailAndActivityService } from "../../../app/@core/utils/detail-and-activity.service";
import { SignInService } from "../../../app/@core/sign-in.service";
import { SharedService } from "../../../app/@core/utils/shared.service";
import { VerifyDocumentService } from "../../@core/verify-document.service";
import { NbDialogRef, NbDialogService, NbToastrService } from "@nebular/theme";
import { DatePipe } from "@angular/common";
import { timeout } from "rxjs-compat/operator/timeout";
import { Router } from "@angular/router";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TemplateEditorComponent } from "../../../app/shared/template-editor/template-editor.component";
import { FORMERR } from "dns";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "ngx-bulk-tag",
  templateUrl: "./bulk-tag.component.html",
  styleUrls: ["./bulk-tag.component.scss"],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     multi:true,
  //     useExisting: BulkTagComponent
  //   }
  // ]
})
export class BulkTagComponent implements OnDestroy {
  showdiv: boolean;
  workSpaceId: any;
  documentOwnerEmail: string;
  docModifiedEditedName: any;
  createdBy: any;
  GuestFlag: string;
  parserflagdata: string;
  guestAccess: string;
  wsName: string;
  getguid: string;
  dateFormat: string;
  subscriptionPlan: string;
  editrights: any;
  digiRights: any;
  isCheckedAllUsers: boolean;
  documentOwnerName: any;
  ownerEmailID: any;
  isAllUserShare: boolean;
  WsdisplayName: any;
  docsVerification: any;
  docDetails: any;
  templateInput: any;
  openedFromViewer: boolean = true;
  customform: any;
  tagTemplateId: any;
  selectedShowCase = [];
  docid = [];
  viewspinner = false;
  verifierEmailid = [];
  ownerEmailId = [];
  docGuid = [];
  pendingRequest: any;
  editTemplateInput: any;
  arrayUniqueByKey: unknown[];
  emptyPage: any;
  tagNote: any = "";
  showBox: boolean;
  deleteField: boolean;
  userInputSingleTag: string;
  getUserDataSingleTag: string;
  singleTagsList = [];
  tagLabels = [];
  tagValues = [];
  isText: boolean = false;
  isDate: boolean = false;
  isOption: boolean = false;
  textFieldArray: any = [];
  datefieldArray: any = [];
  dropdownArray: any = [];
  showBox1: boolean;
  blankState: boolean = false;
  multipleDocError: boolean;
  ShowDate = [];
  showValue: boolean = false;
  verifyFlag = false;
  getSelectedDocTagData: any;
  tagDetails = [];
  tagList: string;
  singleTagFlag: boolean;
  selectedPagesIncrease: any;
  checkBulkTagHeader: boolean;
  isInputEnterorSpace: boolean;
  selectedDocuments = [];
  checklabel: boolean[] = [];
  checkvalues: boolean[] = [];
  dialogRef: NbDialogRef<any>;
  isModalHidden = false;
  islabel: string[] = [];
  isvalue: string[] = [];
  singleValueTallyTags= [];
  labelTallyTagsArray= [];
  customTagArray=[];
  dropdownValue = [];
  selected = [];
  userInput: any;
  getUserData: any;
  selectedTemplate: any;
  tagLabel: string;
  tagvalue: string;
  flagValue = "";
  customArray = [];
  items: {
    tagControlId: any;
    docId?: any;
    tempTagId?: number;
    tagLabel?: any;
    tagValue?: any;
    createdBy?: string;
    optionValue?: string;
  };
  uploadedByDisplayName: string[] = [];
  copyLabel: string[] = [];
  copyValue: string[] = [];
  isLabelChanged: boolean;
  isValueChanged: boolean;
  isTextBorderShow: boolean[] = [];
  ShowDateCustom: boolean[] = [];
  ShowDate1 = [];
  isCustomBorderShow: boolean[] = [];
  isCustomValueFocused: boolean[] = [];
  isDateBorderShow: boolean[];
  isDropDownBorderShow: boolean[];
  isTextValueFocused: boolean[] = [];
  isDateValueFocused: boolean[] = [];
  isDropdownValueFocused: boolean[] = [];
  currentTab: string;
  formatExtension: string[] = [];
  docRenameList: string[] = [];
  isTemplateText: boolean[] = [];
  isTemplateDate: boolean[] = [];
  canTextBorderShow: boolean[] = [];
  canDateBorderShow: boolean[] = [];
  isTagSaving = false;
  @Input() verifiedtype;
  isSaving = false;
  saveCurrentPostData: {
    Wsoid: number;
    WsLink: string;
    DocID: number;
    TempID: number;
    DocRename: any;
    DocOwner: any;
    IsAllUserShare: boolean;
    Flag: string;
    ModifiedBy: any;
    docTags: any;
    //  "docTags": this.tagDetails,
    ownerEmailId: any;
    ownerName: any;
    iDsList: string;
    docGuidList: string;
    oldOwnerEmailId: any;
    /*
     *adding modified by display name in the key of oldOwnerName
     */
    oldOwnerName: any;
    Notes: any;
    WsName: string;
    guid: string;
    DocGuid: string;
  };
  count: number = 0;
  isResponseTab: boolean = false;
  countTagList: number = 0;
  userfullname: string;
  isRepeatLog: boolean[] = [];
  previousdocID = [];
  previousLogMessage = [];
  countCall: number = 0;
  checkURLChange = false;
  countCallRouterEvent = 0;
  countUpdateCall = 0;
  hideDiv= false;
  isViewScreen = true;
  variableClass = "heightdetails";
  isShowSpinner = false;
  selectedDocs = [];
  atlee: any;
  docRenamedValue: string = '';

  constructor(
    public datepipe: DatePipe,
    public renderer: Renderer2,
    public dialogService: NbDialogService,
    public data: VerifyDocumentService,
    public sharedService: SharedService,
    public signInService: SignInService,
    public details: DetailAndActivityService,
    public router: Router,
    public cd: ChangeDetectorRef
  ) {
    router.events.subscribe((val) => {
      if (
        localStorage.getItem("saved") === "true" &&
        this.countCallRouterEvent === 0
      ) {
        this.SaveTagVerifyData();
        this.countCallRouterEvent++;
      }
    });
    this.userfullname = localStorage.getItem("noteuserfullname");
    this.GuestFlag = localStorage.getItem("GuestFlag");
    this.parserflagdata = localStorage.getItem("parserflag");
    this.guestAccess = localStorage.getItem("isGuestAccess");
    this.wsName = localStorage.getItem("WorkSpaceName");
    this.workSpaceId = localStorage.getItem("localwsId");
    this.createdBy = localStorage.getItem("localloginId");
    // this.documentOwnerEmail = localStorage.getItem("docOwnerEmailId");
    this.getguid = localStorage.getItem("loginguid");
    this.dateFormat = localStorage.getItem("DateFormat");
    this.subscriptionPlan = localStorage.getItem("wsSubscriptionPlan");
    this.guestAccess = localStorage.getItem("isGuestAccess");
    this.editrights = JSON.parse(localStorage.getItem("editaccess"));
    this.digiRights = JSON.parse(localStorage.getItem("AllwsFlag"));
    this.WsdisplayName = localStorage.getItem("WsdisplayName")
      ? localStorage.getItem("WsdisplayName")
      : "";
    this.isModalHidden = localStorage.getItem("isModalHidden") === "true";
  }

  @ViewChild("saveTags") saveTags: PointerEvent;
  @ViewChild("bulkTagBody") bulkTagBody: ElementRef;
  @ViewChild("textArea") textArea: ElementRef;
  @ViewChild("singleTags") singleTags: ElementRef;
  @ViewChild("textArray") textArray: ElementRef;
  @ViewChild("dateArray") dateArray: ElementRef;
  @ViewChild("dropDown") dropDown: ElementRef;
  @ViewChild(TemplateEditorComponent) templateEditorChild: TemplateEditorComponent;

  ngOnInit(): void {
    localStorage.setItem("saved", "false");
    // localStorage.setItem("documentId", this.sharedService.documentId);
    let arr = window.location.href;
    if (arr.includes("dockets")) {
      this.viewspinner = true;
    }

    this.onGetTemplateDetails();
    this.getDataFromInput();
    this.currentTab = window.location.href.toString();
    this.sharedService.isResponse.next(this);

    /*
    * logic for hiding the head of the bulk tags in verify document components
    */
    if ((this.router.url.includes('/view/view-document')) || (this.router.url.includes('/verification/verify-document'))){
      this.hideDiv = true;
      if(this.hideDiv){
        this.variableClass = "verifyDiv";
      }
    }
    if (this.router.url.includes('/pages/documents/dockets/')){
        this.variableClass = "docItTagOpen";
    }

  }

  // ngAfterViewInit() {
  //   // Correct approach
  //   let currentTab = window.location.href.toString();
  //   setTimeout(() => {
  //     if (currentTab.includes("dockets")) {
  //       this.isResponseTab = true;
  //       this.cd.detectChanges();
  //     }
  //   });
  // }

  crosstag(){
    this.openedFromViewer = false;
  }

  ngDoCheck() {
    if (window.innerWidth > 768) {
      this.openedFromViewer = true;
    }
    this.sharedService.docRenameVal.subscribe((data: string) => {
      this.docRenamedValue = data;
   });
  }



  saved = true;
  crossdetails() {
    if (localStorage.getItem("saved") === "true") {
      this.SaveTagVerifyData();
    }
    this.sharedService.closeDetailBar1.next();
    this.islabel = [];
    this.isvalue = [];
    this.customArray = [];
    this.checklabel = [];
    this.checkvalues = [];
    this.isCustomBorderShow = [];
    this.isCustomValueFocused = [];
    this.saved = false;
    localStorage.setItem("saved", "false");
  }

  // documentSelected(val) {
  //   localStorage.setItem("documentId", this.sharedService.documentId);
  // }

  getDataFromInput(val?) {
    this.onGetTemplateDetails(val);
    let postData = {
      wsoid: Number(this.workSpaceId),
      guid: this.getguid,
    };
    this.data.tagTemplateService(postData).subscribe((res: any) => {
      if (res) {
        this.selectedShowCase = res.data;
        this.sharedService.Template.next(res.data)
      }
      this.selectedTemplate = this.selectedTemplate + "," + val?.tempName;
      const e = new Event("mouseenter");
      const element = document.querySelector("#dropDown");
      if (element && e) {
        localStorage.setItem("documentId", this.sharedService.documentId);
        element.dispatchEvent(e);
      }
      const ele = new Event("mouseleave");
      const element2 = document.querySelector("#dropDown");
      if (element2 && ele) {
        localStorage.setItem("documentId", this.sharedService.documentId);
        element2.dispatchEvent(ele);
      }
      const ele4 = new Event("mouseenter");
      const element4 = document.querySelector("#clickedTag");
      if (element4 && ele4) {
        element4.dispatchEvent(ele4);
      }
      const ele3 = new Event("mouseleave");
      const element3 = document.querySelector("#clickedTag");
      if (element3 && ele3) {
        element3.dispatchEvent(ele3);
      }
      const ele5 = new Event("mouseenter");
      const element5 = document.querySelector("#textArray");
      if (element5 && ele5) {
        element5.dispatchEvent(ele5);
      }
      const ele6 = new Event("mouseleave");
      const element6 = document.querySelector("#textArray");
      if (element6 && ele6) {
        element6.dispatchEvent(ele6);
      }
      if (!this.currentTab?.includes("dockets")) {
        // this.SaveTagVerifyData();
      }
    });
    const e = new Event("mouseenter");
    const element = document.querySelector("#dropDown");
    if (element && e) {
      localStorage.setItem("documentId", this.sharedService.documentId);
      element.dispatchEvent(e);
    }
    const ele = new Event("mouseleave");
    const element2 = document.querySelector("#dropDown");
    if (element2 && ele) {
      localStorage.setItem("documentId", this.sharedService.documentId);
      element2.dispatchEvent(ele);
    }
    const ele4 = new Event("mouseenter");
    const element4 = document.querySelector("#clickedTag");
    if (element4 && ele4) {
      element4.dispatchEvent(ele4);
    }
    const ele3 = new Event("mouseleave");
    const element3 = document.querySelector("#clickedTag");
    if (element3 && ele3) {
      element3.dispatchEvent(ele3);
    }
    const ele5 = new Event("mouseenter");
    const element5 = document.querySelector("#textArray");
    if (element5 && ele5) {
      element5.dispatchEvent(ele5);
    }
    const ele6 = new Event("mouseleave");
    const element6 = document.querySelector("#textArray");
    if (element6 && ele6) {
      element6.dispatchEvent(ele6);
    }
  }
  getTextBoxHide(check, event, x) {
    this.showBox = true;
    this.showBox1 = true;
    if (check == "tagLabel") {
      this.tagLabel = event?.target?.value || event?.target?.textContent;
    }
    if (check == "tagvalue") {
      this.tagvalue = event?.target?.value || event?.target?.textContent;
    }
    if (this.tagLabel && this.tagvalue) {
    }
  }

  getTextBoxShown() {
    this.showBox = false;
    this.showBox1 = false;
  }

  saveUserNote(event) {
    localStorage.setItem("saved", "true");
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    this.userInput = event?.target?.value || event?.target?.textContent;
    this.getUserData = this.userInput;
    localStorage.setItem("notesValue",this.getUserData)
  }

  saveUserSingleTag(event?) {
    localStorage.setItem("saved", "true");
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    if (event && event?.target.value?.trim() !== "") {
      this.singleTagFlag = true;
    }
    this.isInputEnterorSpace = event?.keyCode === 13 || event?.keyCode === 32;
    this.userInputSingleTag = event?.target.value?.trim() || "";
    if (this.userInputSingleTag?.length <= 15) {
      if (this.isInputEnterorSpace) {
        this.getUserDataSingleTag =
          this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
        this.SaveSingleTagData();
      } else {
        this.getUserDataSingleTag =
          this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
      }
    } else if (this.isInputEnterorSpace) {
      this.SaveSingleTagData();
    } else {
      event.preventDefault();
    }
  }

  changeCustomTextInputType(i) {
    this.ShowDateCustom[i] = true;
    this.isvalue[i] = "";
    this.isCustomBorderShow[i] = false;
    this.isCustomValueFocused[i] = true;
    this.customArray[i].tagControlId = "1";
  }

  changeTextInputType(i) {
    this.ShowDate[i] = true;
    this.textFieldArray[i].tagValue = "";
    this.isTextBorderShow[i] = false;
    this.isTextValueFocused[i] = true;
  }

  changeDate1InputType(i) {
    this.ShowDate1[i] = true;
    this.datefieldArray[i].tagValue = "";
    this.isDateBorderShow[i] = false;
    this.isDateValueFocused[i] = false;
  }

  changeCustomDateInputType(i) {
    this.ShowDateCustom[i] = false;
    this.isvalue[i] = "";
    this.isCustomBorderShow[i] = false;
    this.isCustomValueFocused[i] = true;
    this.customArray[i].tagControlId = "2";
  }

  changeDateInputType(i) {
    this.ShowDate[i] = false;
    this.textFieldArray[i].tagValue = "";
    this.isTextBorderShow[i] = false;
    this.isTextValueFocused[i] = true;
  }

  changeDate2InputType(i) {
    this.ShowDate1[i] = false;
    this.datefieldArray[i].tagValue = "";
    this.isDateValueFocused[i] = true;
  }

  isTextfocus(i, event) {
    if (event?.target?.value?.trim() !== "" && event.type === "blur") {
      this.isTextBorderShow[i] = false;
    } else {
      this.isTextBorderShow[i] = true;
    }
    console.log("event is",event);
  }

  isDatefocus(i, event) {
    if (event?.target?.value?.trim() !== "" && event.type === "blur") {
      this.isDateBorderShow[i] = true;
    } else {
      this.isDateBorderShow[i] = false;
    }
  }

  isfocusDropDown(i, event) {
    if (event?.target?.value.trim() !== "" && event.type === "blur") {
      this.isDropDownBorderShow[i] = true;
    } else {
      this.isDropDownBorderShow[i] = false;
    }
  }

  isfocusCustom(i, event) {
    if (event?.target?.value?.trim() !== "" && event.type === "blur") {
      this.isCustomBorderShow[i] = true;
    } else {
      this.isCustomBorderShow[i] = false;
    }
  }


  labelChanged(event?, tag?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    this.isLabelChanged = true;
  }

  valueChanged(event?, tag?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    this.isValueChanged = true;
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text").toString();
    let regex = new RegExp("^[a-zA-Z0-9. s-]*$");
    let regPattern = /[\u0000-\u007A]+/g;
    this.getUserDataSingleTag = "";
    if (regex.test(pastedText) && regPattern.test(pastedText)) {
      let checkWhiteSpace = pastedText?.split(" ")[1];
      if (checkWhiteSpace) {
        let tagsInPastedText = pastedText?.split(" ");
        for (let i = 0; i < tagsInPastedText?.length; i++) {
          if (
            !this.singleTagsList.includes(tagsInPastedText[i]?.trim()) &&
            tagsInPastedText[i].length <= 15
          ) {
            this.singleTagsList = [tagsInPastedText[i]]?.concat(
              this.singleTagsList
            );
          } else {
            event.preventDefault();
          }
        }
        localStorage.setItem("saved", "true");
        //this.SaveTagVerifyData()
      } else {
        if (
          !this.singleTagsList.includes(pastedText) &&
          pastedText?.length <= 15
        ) {
          this.singleTagsList = [pastedText?.trim()]?.concat(
            this.singleTagsList
          );
          localStorage.setItem("saved", "true");
          //this.SaveTagVerifyData()
        } else {
          event.preventDefault();
        }
      }
      if(this.docid?.length <= 1){
        localStorage.setItem(
          "singleTagList",
          this.singleTagsList.toString()
        );
      } else {
        localStorage.setItem(
          "singleTagList",
          ""
        );
      }
      const e = new Event("click");
      const element = document?.querySelector("#tags");
      if (element && e) {
        element.dispatchEvent(e);
      }
    } else {
      event.preventDefault();
    }
  }

// this function is used to save the custom tags check the validations of it's individual key value pair object and then save it


  saveCustomTags(i, event, value, key?) {
    if (
      value == "label" &&
      ((event?.target?.value && event?.target?.value != "") ||
        (event?.target?.textContent && event?.target?.textContent !== ""))
    ) {
      if (
        this.copyLabel.length > 0 &&
        (this.copyLabel[i] !== event?.target?.value ||
          this.copyLabel[i] !== event?.target?.textContent)
      ) {
        this.checklabel[i] = true;
      } else if (this.isLabelChanged) {
        this.checklabel[i] = true;
        this.copyLabel[i + 1] =
          event?.target?.value || event?.target?.textContent;
      } else {
        this.checklabel[i] = false;
        this.copyLabel[i + 1] =
          event?.target?.value || event?.target?.textContent;
      }
      this.isLabelChanged = false;
    }

    if (
      value == "value" &&
      ((event?.target?.value && event?.target?.value != "") ||
        (event?.target?.textContent && event?.target?.textContent != ""))
    ) {
      this.checkvalues[i] = true;
      this.copyValue[i + 1] =
        event?.target?.value || event?.target?.textContent;
      // this.isValueChanged = false;
    }

    if (
      this.textFieldArray &&
      this.textFieldArray.length > 0 &&
      key === "text"
    ) {
      if (
        this.textFieldArray[i]?.tagValue &&
        this.checklabel[i] &&
        this.textFieldArray[i]?.tagValue?.trim() !== "" &&
        (this.textFieldArray[i]?.tagLabel === event?.target?.value ||
          this.textFieldArray[i]?.tagLabel === event?.target?.textContent)
      ) {
        this.checkvalues[i] = true;
      }
      if (
        this.textFieldArray[i]?.tagLabel &&
        this.checkvalues[i] &&
        this.textFieldArray[i]?.tagLabel?.trim() !== "" &&
        (this.textFieldArray[i]?.tagValue === event?.target?.value ||
          this.textFieldArray[i]?.tagValue === event?.target?.textContent)
      ) {
        this.checklabel[i] = true;
      }
    }

    if (
      this.datefieldArray &&
      this.datefieldArray.length > 0 &&
      key === "date"
    ) {
      if (
        this.datefieldArray[i]?.tagValue &&
        this.checklabel[i] &&
        this.datefieldArray[i]?.tagValue?.trim() !== "" &&
        (this.datefieldArray[i]?.tagLabel === event?.target?.value ||
          this.datefieldArray[i]?.tagLabel === event?.target?.textContent)
      ) {
        this.checkvalues[i] = true;
      }
      if (
        this.datefieldArray[i]?.tagLabel &&
        this.checkvalues[i] &&
        this.datefieldArray[i]?.tagLabel?.trim() !== "" &&
        (this.datefieldArray[i]?.tagValue === event?.target?.value ||
          this.datefieldArray[i]?.tagValue === event?.target?.textContent)
      ) {
        this.checklabel[i] = true;
      }
    }

    if (this.dropdownArray && this.dropdownArray.length > 0 && key === "drop") {
      if (
        this.dropdownArray[i]?.tagValue &&
        this.checklabel[i] &&
        this.dropdownArray[i]?.tagValue?.trim() !== "" &&
        (this.dropdownArray[i]?.tagLabel === event?.target?.value ||
          this.dropdownArray[i]?.tagLabel === event?.target?.textContent)
      ) {
        this.checkvalues[i] = true;
      }
      if (
        this.dropdownArray[i]?.tagLabel &&
        this.checkvalues[i] &&
        this.dropdownArray[i]?.tagLabel?.trim() !== "" &&
        (this.dropdownArray[i]?.tagValue === event?.target?.value ||
          this.dropdownArray[i]?.tagValue === event?.target?.textContent)
      ) {
        this.checklabel[i] = true;
      }
    }

    if (this.islabel[i] && this.checkvalues[i]) {
      if (this.islabel[i]?.trim() !== "") {
        this.checklabel[i] = true;
      }
    }

    if (this.isvalue[i] && this.checklabel[i]) {
      if (this.isvalue[i]?.trim() !== "") {
        this.checkvalues[i] = true;
      }
    }

    if (this.checklabel[i] && this.checkvalues[i]) {
      localStorage.setItem("saved", "false");
      this.items = {
        docId: this.docid[0],
        tempTagId: 0,
        tagLabel: this.islabel[i] || this.textFieldArray[i].tagLabel,
        tagValue: this.isvalue[i] || this.textFieldArray[i].tagValue,
        createdBy: localStorage.getItem("localloginId"),
        tagControlId: "1",
        optionValue: "",
      };
      this.checklabel[i] = false;
      this.checkvalues[i] = false;
      this.islabel[i] = "";
      this.isvalue[i] = "";
      this.isCustomBorderShow[i] = false;
      this.isCustomValueFocused[i] = true;
      if (key == "add") {
        let addArrayofItems = [this.items];
        if (this.ShowDateCustom[i]) {
          this.ShowDate1 = [true].concat(this.ShowDate1);
          this.isDateBorderShow = [true].concat(this.isDateBorderShow);
          this.isDateValueFocused = [false].concat(this.isDateValueFocused);
          this.isDropdownValueFocused = [true].concat(
            this.isDropdownValueFocused
          );
          this.datefieldArray = addArrayofItems.concat(this.datefieldArray);
          this.items.tagControlId = "2";
          this.getUserData = [...this.textFieldArray,
                              ...this.datefieldArray,
                              ...this.dropdownArray];
        } else if (!this.ShowDateCustom[i]) {
          this.ShowDate = [false].concat(this.ShowDate);
          this.isTextBorderShow = [false].concat(this.isTextBorderShow);
          this.isTextValueFocused = [false].concat(this.isTextValueFocused);
          this.textFieldArray = addArrayofItems.concat(this.textFieldArray);
          this.getUserData = [...this.textFieldArray,
                              ...this.datefieldArray,
                              ...this.dropdownArray];
        }
        this.ShowDateCustom = [false].concat(this.ShowDateCustom);
        let item = {
          tagControlId: "1",
          tagLabel: "",
          tagValue: "",
        };
        this.customArray[i] = item;
        // this.customArray = [item].concat(this.customArray);
      } else {
        this.textFieldArray[i] = this.items;
        this.getUserData = [...this.textFieldArray,
          ...this.datefieldArray,
          ...this.dropdownArray];
      }
      localStorage.setItem("saved", "true");
      //this.SaveTagVerifyData();
    } else {
      localStorage.setItem("saved", "true");
    }
  }

  onPasteValue(event?, type?, i?) {
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (localStorage.getItem("saved") == "true") {
      $event.returnValue = true;
      this.isSaving = true;
      this.SaveTagVerifyData();
      setTimeout(() => {
        if(!this.isSaving){
          this.isSaving = false;
          localStorage.setItem("saved", "false");
        }
      }, 300);
    }
  }
  /**
   * get Template Details
   */
  onGetTemplateDetails(val?) {
    //tagLabel   tagValue  tagControlId
    if (val) {
      this.templateInput = [];
      let st = window.location.toString();
      let arr = st?.split("/");
      let postData: any;
      postData = {
        Wsoid: Number(this.workSpaceId),
        WsLink: arr[2],
        TempId: val.tdId,
      };
      let text: any = [],
        date = [],
        dropdown = [],
        labelSet = [];
      let textValueFocused = [],
        dateValueFocused = [];
      this.pendingRequest = this.data
        .templateDetailsService(postData)
        .subscribe((res: any) => {
          res.data.forEach((e) => {
            if (e.tagControlId == 1) {
              this.isText = true;
              if (!e.tagValue || e.tagValue === null || e.tagValue === "") {
                e.tagValue = "";
              } else {
                e.tagValue = e.tagValue.slice(0, 40);
              }
              text.push(e);
              this.ShowDate = [false].concat(this.ShowDate);
              this.isTemplateText = [true].concat(this.isTemplateText);
              this.isTextBorderShow = [false].concat(this.isTextBorderShow);
              if (e.tagValue === "") {
                textValueFocused.push(true);
              } else {
                textValueFocused.push(false);
              }
              // this.isTextValueFocused = [true].concat(this.isTextValueFocused);
            } else if (e.tagControlId == 2) {
              this.isDate = true;
              if (
                e.tagValue.includes("null") ||
                e.tagValue === "Invalid date NaN,NaN" ||
                e.tagValue === null
              ) {
                e.tagValue = "";
              }
              if (e.tagValue !== "") {
                if (!e.tagValue.includes("-")) {
                  e.tagValue =
                    e.tagValue.slice(0, 4) +
                    "-" +
                    e.tagValue.slice(4, 6) +
                    "-" +
                    e.tagValue.slice(6);
                }
                e.tagValue = String(
                  new DatePipe("en-US").transform(e.tagValue, "YYYY-MM-dd")
                );
              }
              date.push(e);
              this.ShowDate1 = [true].concat(this.ShowDate1);
              this.isTemplateDate = [true].concat(this.isTemplateDate);
              this.isDateBorderShow = [true].concat(this.isDateBorderShow);
              if (e.tagValue === "") {
                dateValueFocused.push(true);
              } else {
                dateValueFocused.push(false);
              }
              // this.isDateValueFocused = [true].concat(this.isDateValueFocused);
            } else if (e.tagControlId == 3) {
              this.isOption = true;
              dropdown.push(e);
              this.isDropDownBorderShow = [true].concat(
                this.isDropDownBorderShow
              );
            }
          });

          this.isTextValueFocused = textValueFocused?.concat(
            this.isTextValueFocused
          );
          this.isDateValueFocused = dateValueFocused?.concat(
            this.isDateValueFocused
          );
          this.textFieldArray = text?.concat(this.textFieldArray);
          this.datefieldArray = date?.concat(this.datefieldArray);
          this.dropdownArray = dropdown?.concat(this.dropdownArray);
          this.tagLabel = this.textFieldArray.tagLabel;
          this.tagvalue = this.textFieldArray.tagLabel;
          for (let i = 0; i < this.dropdownArray.length; i++) {
            if (this.dropdownArray[i].tagValue.includes("||")) {
              this.dropdownValue[i] =
                this.dropdownArray[i].tagValue?.split("||");
              this.isDropdownValueFocused[i] = false;
            } else {
              this.dropdownValue[i] = [this.dropdownArray[i].tagValue];
              this.selected[i] = this.dropdownArray[i].tagValue;
              this.isDropdownValueFocused[i] = true;
            }
          }
          const e = new Event("mouseenter");
          const element = document.querySelector("#dropDown");
          if (element && e) {
            element.dispatchEvent(e);
          }
          const ele = new Event("mouseleave");
          const element2 = document.querySelector("#dropDown");
          if (element2 && ele) {
            element2.dispatchEvent(ele);
          }
          const ele4 = new Event("mouseenter");
          const element4 = document.querySelector("#clickedTag");
          if (element4 && ele4) {
            element4.dispatchEvent(ele4);
          }
          const ele3 = new Event("mouseleave");
          const element3 = document.querySelector("#clickedTag");
          if (element3 && ele3) {
            element3.dispatchEvent(ele3);
          }
          const ele5 = new Event("mouseenter");
          const element5 = document.querySelector("#textArray");
          if (element5 && ele5) {
            element5.dispatchEvent(ele5);
          }
          const ele6 = new Event("mouseleave");
          const element6 = document.querySelector("#textArray");
          if (element6 && ele6) {
            element6.dispatchEvent(ele6);
          }
          //this.SaveTagVerifyData()
        });
      const e = new Event("mouseenter");
      const element = document.querySelector("#dropDown");
      if (element && e) {
        element.dispatchEvent(e);
      }
      const ele = new Event("mouseleave");
      const element2 = document.querySelector("#dropDown");
      if (element2 && ele) {
        element2.dispatchEvent(ele);
      }
      const ele4 = new Event("mouseenter");
      const element4 = document.querySelector("#clickedTag");
      if (element4 && ele4) {
        element4.dispatchEvent(ele4);
      }
      const ele3 = new Event("mouseleave");
      const element3 = document.querySelector("#clickedTag");
      if (element3 && ele3) {
        element3.dispatchEvent(ele3);
      }
      const ele5 = new Event("mouseenter");
      const element5 = document.querySelector("#textArray");
      if (element5 && ele5) {
        element5.dispatchEvent(ele5);
      }
      const ele6 = new Event("mouseleave");
      const element6 = document.querySelector("#textArray");
      if (element6 && ele6) {
        element6.dispatchEvent(ele6);
      }
    }
  }

  getSelectedData(event, i) {
    this.selected[i] = event;
    this.dropdownArray[i].tagValue = this.selected[i];
    // localStorage.setItem("saved", 'true');
    //this.SaveTagVerifyData()
  }

  getTagId(event) {
    this.sharedService.getTagId.next(event);
  }

  /*
  * method to open the template editor inside the bulk tag
  */
  templateColor:any;
  openModalTemplate() {
    this.templateColor = !this.templateColor;
    this.templateEditorChild.openTemplateModal1();
  }

  checkEmptyPage() {
    let flag = false;
    this.selectedPagesIncrease = this.emptyPage;
    if(this.hideDiv){
      this.emptyPage = 1;
    }else{
      this.emptyPage = localStorage.getItem("selectedDocs");
    }
    let singleTagListSavedOnLocal = localStorage.getItem("singleTagList");
    let url = window.location.href;
    if (!url.includes('dockets') && singleTagListSavedOnLocal && singleTagListSavedOnLocal !== "") {
      this.singleTagsList = singleTagListSavedOnLocal?.split(",");
      localStorage.removeItem('singleTagList');
      const e = new Event("mouseenter");
      const element = document.querySelector("#dropDown");
      if (element && e) {
        element.dispatchEvent(e);
      }
      const ele = new Event("mouseleave");
      const element2 = document.querySelector("#dropDown");
      if (element2 && ele) {
        element2.dispatchEvent(ele);
      }
      const ele4 = new Event("mouseenter");
      const element4 = document.querySelector("#clickedTag");
      if (element4 && ele4) {
        element4.dispatchEvent(ele4);
      }
      const ele3 = new Event("mouseleave");
      const element3 = document.querySelector("#clickedTag");
      if (element3 && ele3) {
        element3.dispatchEvent(ele3);
      }
      const ele5 = new Event("mouseenter");
      const element5 = document.querySelector("#textArray");
      if (element5 && ele5) {
        element5.dispatchEvent(ele5);
      }
      const ele6 = new Event("mouseleave");
      const element6 = document.querySelector("#textArray");
      if (element6 && ele6) {
        element6.dispatchEvent(ele6);
      }
    }
    if (this.isResponseTab) {
      if (singleTagListSavedOnLocal !== "") {
        this.singleTagsList = singleTagListSavedOnLocal?.split(",");
      } else {
        this.singleTagsList = [];
      }
      if (
        (!this.textFieldArray || this.textFieldArray?.length === 0) &&
        (!this.datefieldArray || this.datefieldArray?.length === 0) &&
        (!this.dropdownArray || this.dropdownArray?.length === 0)
      ) {
        this.sharedService.getOldTagsList.subscribe((response) => {
          if (this.countTagList === 0 && this.isResponseTab) {
            this.textFieldArray = response[0];
            this.datefieldArray = response[1];
            this.dropdownArray = response[2];
            this.ShowDate = response[3];
            this.ShowDate1 = response[4];
            this.isTextBorderShow = response[5];
            this.isTextValueFocused = response[6];
            this.isDateBorderShow = response[7];
            this.isDateValueFocused = response[8];
            this.dropdownValue = response[9];
            this.selected = response[10];
            this.isDropDownBorderShow = response[11];
            this.singleTagsList = response[12];
            this.tagNote = response[13];
            [
              this.docid,
              this.verifierEmailid,
              this.ownerEmailID,
              this.docGuid,
              this.formatExtension,
              this.uploadedByDisplayName,
              this.docRenameList,
            ] = [[], [], [], [], [], [], []];
            this.docid[0] = localStorage.getItem("docID");
            this.verifierEmailid[0] = localStorage.getItem("verifierEmailId");
            this.ownerEmailId[0] = localStorage.getItem("ownerEmailID");
            this.docGuid[0] = localStorage.getItem("guid");
            this.formatExtension[0] = localStorage.getItem("extension");
            this.uploadedByDisplayName[0] = localStorage.getItem(
              "uploadedByDisplayName"
            );
            this.docRenameList[0] = localStorage.getItem("docRenameList");
            this.countTagList++;
            setTimeout(() => {
              this.countTagList = 0;
            }, 1000);
          }
        });
      }
    }

    for (let i = 0; i < this.docid?.length; i++) {
      if (this.selectedDocuments) {
        if (this.selectedDocuments[i] !== this.docid[i]) {
          this.verifyFlag = false;
          this.selectedPagesIncrease = 1;
          this.singleTagsList = [];
          this.getUserDataSingleTag = "";
        }
      }
      this.selectedDocuments[i] = this.docid[i];
    }
    if (this.selectedPagesIncrease !== this.emptyPage) {
      this.verifyFlag = false;
      this.selectedPagesIncrease = 1;
      this.singleTagsList = [];
      this.getUserDataSingleTag = "";
    }
    if (this.emptyPage > 0) {
      flag = true;
    }
    if (this.currentTab?.includes("dockets")) {
      this.isResponseTab = true;
    } else {
      this.isResponseTab = false;
    }
    return flag;
  }

  addTAgValue() {
    this.customArray = [];
    this.blankState = true;
    let item = {
      tagControlId: "1",
      tagLabel: "",
      tagValue: "",
    };
    this.customArray = [item].concat(this.customArray);
    this.ShowDateCustom = [false].concat(this.ShowDateCustom);
    this.isCustomBorderShow = [false].concat(this.isCustomBorderShow);
    this.isCustomValueFocused = [true].concat(this.isCustomValueFocused);
    this.islabel = [""].concat(this.islabel);
    this.isvalue = [""].concat(this.isvalue);
  }

  /**
   * Template change
   */
  templatechange(data?) {
    let templatedata = {
      wsoid: JSON.parse(this.workSpaceId),
      tagLabel: data.tagTempLabel,
    };
    this.pendingRequest = this.data
      .templatechange(templatedata)
      .subscribe((response: any) => {
        if (response.isSuccess) {
          if (response.data.length > 0) {
            let temdata = {
              createdDtm: null,
              tagValue: data.tagTempValue,
            };
            response.data.push(temdata);
            const key = "tagValue";
            this.arrayUniqueByKey = [
              ...new Map(
                response.data.map((item) => [item[key], item])
              ).values(),
            ];
            for (let i = 0; i <= this.templateInput.length - 1; i++) {
              if (this.templateInput[i].id == data.id) {
                this.templateInput[i].tagvaldata = temdata.tagValue;
                this.templateInput[i].existingtagvalue = this.arrayUniqueByKey;
                this.templateInput[i].checktagdrop = true;
                this.templateInput[i]["errormsg"] = "";
              }
            }
          }
        }
      });
  }

  // ? Method to send tagdata to api
  SaveTagVerifyData(array?, type?, tag1?) {
    console.log("bulk tag");
    let tagsAndValue = array;
    if (localStorage.getItem("documentIds")) {
      this.selectedDocs = localStorage.getItem("documentIds").split(',');
    }
    let postDatas = [];
    // if (type) {
    //   if (type === "text") {
    //     this.textFieldArray.splice(tag, 1);
    //     this.deleteField = true;
    //   } else if (type === "date") {
    //     this.datefieldArray.splice(tag, 1);
    //     this.deleteField = true;
    //   } else if (type === "dropdown") {
    //     this.dropdownArray.splice(tag, 1);
    //     this.deleteField = true;
    //   }
    // }
    for (let i = 0; i < this.datefieldArray?.length; i++) {
      if (
        this.datefieldArray[i].tagValue !== "" &&
        this.datefieldArray[i].tagValue.includes("-") &&
        !this.datefieldArray[i].tagValue.includes("null") &&
        !this.datefieldArray[i].tagValue === null
      ) {
        this.datefieldArray[i].tagValue = String(
          new DatePipe("en-US").transform(
            this.datefieldArray[i].tagValue,
            "YYYY-MM-dd"
          )
        );
      }
    }
    tagsAndValue = [
      ...this.textFieldArray,
      ...this.datefieldArray,
      ...this.dropdownArray,
    ];
    if (this.selectedDocs && this.selectedDocs.length != 0) {
      for (let x = 0; x < this.selectedDocs.length; x++) {
        for (let tag of tagsAndValue) {
          if (
            tag.tagValue?.trim() !== ""
          ) {
            postDatas.push({
              docId: Number(this.selectedDocs[x]),
              tempId: tag.tempTagId,
              tagLabel: tag.tagLabel,
              tagValue: tag.tagValue,
              createdBy: localStorage.getItem("localloginId"),
              tagControlId: tag.tagControlId.toString(),
              optionValue: "",
            });
          }
        }
      }
    } else {
      for (let x = 0; x < this.docid.length; x++) {
        for (let tag of tagsAndValue) {
          if (
            tag.tagValue?.trim() !== ""
          ) {
            postDatas.push({
              docId: this.docid.length === 1? Number(localStorage.getItem("documentId")) :Number(this.docid[x]),
              tempId: tag.tempTagId,
              tagLabel: tag.tagLabel,
              tagValue: tag.tagValue,
              createdBy: localStorage.getItem("localloginId"),
              tagControlId: tag.tagControlId.toString(),
              optionValue: "",
            });
          }
        }
      }
    }
    
    tagsAndValue = postDatas;
    tagsAndValue = [...tagsAndValue,
      ...this.singleValueTallyTags,
      ...this.labelTallyTagsArray,
    ];
    let tagsOnly = tagsAndValue.filter((res) => res.docSource == undefined);
    var st = window.location.toString();
    var arr = st?.split("/");
    let doctagArray = [];
    this.tagDetails = [];
    doctagArray.push(array);
    let fileRename = localStorage.getItem("fileRename")?.split(".")[0];
    let postData = {
      Wsoid: Number(this.workSpaceId),
      WsLink: arr[2],
      DocID: Number(localStorage.getItem("documentId")),
      TempID: 1,
      DocRename: this.docRenamedValue,
      DocOwner: null,
      IsAllUserShare: true,
      Flag: "tag",
      ModifiedBy: this.createdBy,
      docTags: tagsOnly,
      //  "docTags": this.tagDetails,
      ownerEmailId: null,
      ownerName: localStorage.getItem("noteuserfullname"),
      iDsList: this.docid.toString(),
      docGuidList: this.docGuid.toString(),
      oldOwnerEmailId: null,
      oldOwnerName: this.WsdisplayName,
      Notes: this.tagNote,
      WsName: this.wsName,
      guid: this.getguid,
      DocGuid: this.getguid,
      source: "dox2uapp"
    };
    if (this.singleTagsList?.length > 0) {
      postData.TempID = 0;
      this.flagValue = "verify";
      this.tagList = JSON.parse(localStorage.getItem("tagList"));
      if (this.selectedDocs && this.selectedDocs.length != 0) {
        for (let x = 0; x < this.selectedDocs.length; x++) {
          for (let i = 0; i < this.singleTagsList.length; i++) {
            let docTagList = {
              docId: Number(this.selectedDocs[x]),
              tempId: 0,
              tagLabel: this.singleTagsList[i],
              tagValue: "",
              createdBy: this.createdBy,
              tagControlId: "1",
              optionValue: "",
            };
            postData.docTags.push(docTagList);
          }
        }
      } else {
        for (let x = 0; x < this.docid.length; x++) {
          for (let i = 0; i < this.singleTagsList.length; i++) {
            let docTagList = {
              docId: this.docid.length === 1? Number(localStorage.getItem("documentId")) :Number(this.docid[x]),
              tempId: 0,
              tagLabel: this.singleTagsList[i],
              tagValue: "",
              createdBy: this.createdBy,
              tagControlId: "1",
              optionValue: "",
            };
            postData.docTags.push(docTagList);
          }
        }
      }
    }

    if (postData.Flag === "verify") {
      let filteredData = this.templateInput?.filter((e) => {
        return e.tagTempValue != "";
      });
      filteredData?.forEach((e) => {
        let template = {
          tempId: this.tagTemplateId,
          tagLabel: "",
          tagValue: "",
          createdBy: this.createdBy,
          tagcontrolid: "",
          optionvalue: "",
        };
        if (e.inputType === "text") {
          template.tagLabel = e.tagTempLabel;
          template.tagValue = e.tagTempValue;
          template.tagcontrolid = "1";
          doctagArray.push(template);
        } else if (e.inputType === "dropdown") {
          if (e.selectedValue != "" || e.selectedValue != null) {
            template.tagLabel = e.tagTempLabel;
            template.tagValue = e.selectedValue;
            template.tagcontrolid = "3";
            if (e.tagTempLabel && e.selectedValue) {
              if (e.selectedValue != "Select") {
                template.optionvalue = e.tagTempValue.join("||");
                doctagArray.push(template);
              }
            }
          }
        } else if (e.inputType === "date") {
          if (e.tagTempValue != "Invalid date NaN,NaN") {
            template.tagLabel = e.tagTempLabel;
            // let changedateformat = moment(e.tagTempValue).format("YYYY-MM-DD");
            // template.tagValue = changedateformat;
            template.tagValue = e.tagTempValue;
            template.tagcontrolid = "2";
            doctagArray.push(template);
          }
        }
      });
      if (this.customform) {
        for (let e of this.customform?.controls) {
          let a = e as FormGroup;
          let template = {
            tagLabel: "",
            tagValue: "",
            tagcontrolid: "",
            createdBy: this.createdBy,
          };
          template.tagLabel = a.value.label;
          if (a.value.tagcontrolid == 2) {
            // let changedateformat = moment(a.value.value).format("YYYY-MM-DD");
            // template.tagValue = changedateformat;
            template.tagValue = e.tagTempValue;
          } else {
            template.tagValue = a.value.value;
          }
          template.tagcontrolid = a.value.tagcontrolid;
          doctagArray.push(template);
        }
      }
    }
    if (
      this.docid?.length > 0 &&
      ((localStorage.getItem("saved") === "true" || this.verifyFlag) &&
      this.countUpdateCall === 0)
    ) {
      this.countUpdateCall++;
      setTimeout(() => {
        this.countUpdateCall = 0;
      }, 300);
      if (this.verifyFlag ) {
        if(window.location.href.includes('documents/dockets/') && localStorage.getItem('link')){
          postData.Flag = "tag";
            localStorage.setItem("saved", "false");
            if (
              this.count === 0 ||
              window.location.href?.includes("dockets")
            ) {
              this.saveCurrentPostData = JSON.parse(JSON.stringify(postData));
              localStorage.setItem("isFileUploaded", "true");
            }
            this.data.elasticUpdate(postData).toPromise();
            setTimeout(() => {
              this.isSaving = false;
            }, 500);
            if (!window.location.href?.includes("dockets")) {
              window.location.reload();
            }

        }
        else{
          postData.Flag = "verify";
          this.data
            .saveDocsDetailsService(postData)
            .toPromise()
            .then((response: any) => {
              if (response.isSuccess) {
                localStorage.setItem("saved", "false");
                if (
                  this.count === 0 ||
                  window.location.href?.includes("dockets")
                ) {
                  this.saveCurrentPostData = JSON.parse(JSON.stringify(postData));
                  localStorage.setItem("isFileUploaded", "true");
                  localStorage.removeItem('currentlyUploadingFile');
                }
                this.data.elasticUpdate(postData).toPromise();
                setTimeout(() => {
                  this.isSaving = false;
                }, 300);
                if (!window.location.href?.includes("dockets")) {
                  window.location.reload();
                }
              } else {
                localStorage.setItem("saved", "true");
              }
            });
        }

      } else {
        this.data
          .saveDocsDetailsService(postData)
          .toPromise()
          .then((response: any) => {
            if (response.isSuccess) {
              if (!window.location.href.includes("verify")) {
                localStorage.setItem("saved", "false");
              }
              for (let i = 0; i < this.docid.length; i++) {
                if (this.isRepeatLog[i] !== true) {
                  this.isRepeatLog[i] = false;
                }
              }
              this.updateDocAuditLog("edited by");
              if (this.count === 0) {
                this.saveCurrentPostData = JSON.parse(JSON.stringify(postData));
              }
              this.data
                .elasticUpdate(this.saveCurrentPostData)
                .toPromise()
                .then((res: any) => {
                  if (res.isSuccess) {
                    this.count = 0;
                  } else {
                    this.count = 1;
                    setTimeout(() => {
                      this.count = 0;
                    }, 10000);
                  }
                });
                if (!window.location.href.includes("verify")) {
                  localStorage.setItem("saved", "false");
                }
              setTimeout(() => {
                this.isSaving = false;
              }, 300);
              this.getNewTagNotify();
            } else {
              localStorage.setItem("saved", "true");
            }
          });
      }
    }
  }

  setTemplateIDMethod(singleTag) {
    return Math.floor(Math.random() * (999999 - 100000)) + 100000;
  }

  SaveData() {
    this.getUserData = this.userInput;
    localStorage.setItem("saved", "true");
    //this.SaveTagVerifyData()
  }

  SaveSingleTagData() {
    if (this.getUserDataSingleTag || this.getUserDataSingleTag !== "") {
      let checkSpace = this.getUserDataSingleTag?.split(" ");
      if (checkSpace.length > 1) {
        for (let i = 0; i < checkSpace.length; i++) {
          let spaceSeparatedTag = [checkSpace[i]?.trim()];
          this.singleTagsList = spaceSeparatedTag.concat(this.singleTagsList);
        }
        this.getUserData = this.singleTagsList;
      } else {
        let tagWithoutSpaces = [this.getUserDataSingleTag?.trim()];
        this.singleTagsList = tagWithoutSpaces.concat(this.singleTagsList);
        this.getUserData = this.singleTagsList;
      }
      this.singleTagsList = Array.from(new Set(this.singleTagsList));
      if(this.docid?.length <= 1){
        localStorage.setItem(
          "singleTagList",
          this.singleTagsList.toString()
        );
      } else {
        localStorage.setItem(
          "singleTagList",
          ""
        );
      }
      localStorage.setItem("saved", "true");
      this.cd.detectChanges();
      //this.SaveTagVerifyData()
    }
    this.getUserDataSingleTag = "";
  }

async  getmethodValue(
    id?,
    verifierEmailId?,
    ownerEmailID?,
    guid?,
    uploadedByDisplayName?,
    check?,
    extension?,
    docRenameList?,
    key?
  ) {
    this.viewspinner = true;
    this.docid = id;
    if(this.docid.length > 0) {
      this.isShowSpinner = true;
    }else{
      this.isShowSpinner = false;
    }
    if (localStorage.getItem("saved") === "true") {
      this.SaveTagVerifyData();
    }
    this.currentTab = window.location.href.toString();
    // if (this.currentTab?.includes("dockets")) {
    //   this.isResponseTab = true;
    // } else {
    //   this.isResponseTab = false;
    // }
    this.getDataFromInput();

    setTimeout(() => {
      if (
        (this.countCall === 0 || this.currentTab?.includes("dockets")) &&
        id &&
        id.length !== 0
      ) {
        var st = window.location.toString();
        var arr = st?.split("/");
        if (this.currentTab?.includes("dockets")) {
          localStorage.setItem("docID", String(id[0]));
          localStorage.setItem("verifierEmailId", verifierEmailId[0]);
          localStorage.setItem("ownerEmailID", ownerEmailID[0]);
          localStorage.setItem("guid", guid[0]);
          localStorage.setItem(
            "uploadedByDisplayName",
            uploadedByDisplayName[0]
          );
          localStorage.setItem("docRenameList", docRenameList[0]);
        }
        let bulkTagRights = localStorage.getItem("bulkTagging");

        if (id?.length > 1 && bulkTagRights === "False") {
          this.crossdetails();
          return;
        }

        this.docid = id;
        console.log("the ids are", id, this.docid);
        this.multipleDocError = this.docid.length > 1 ? true : false;
        this.verifierEmailid = verifierEmailId;
        this.ownerEmailId = ownerEmailID;
        this.docGuid = guid;
        this.formatExtension = extension;
        this.uploadedByDisplayName = uploadedByDisplayName;
        this.docRenameList = docRenameList;
        this.documentOwnerEmail = ownerEmailID[0];
        if ((this.router.url.includes('/view/view-document'))){
          if(!this.editrights && this.createdBy !== this.documentOwnerEmail){
            this.isViewScreen= false;
          }
        }
        // this.SaveTagVerifyData();
        this.count = 0;
        setTimeout(() => {
          if (this.currentTab?.includes("dockets")) {
            this.isResponseTab = true;
            this.viewspinner = false;
            // this.cd.detectChanges();
            // if(this.isResponseTab){
            //   this.variableClass = "docItTagOpen";
            //   console.log(this.variableClass)
            // }
            this.verifyFlag = true;
            if (this.count === 0 && key) {
              this.SaveTagVerifyData();
            }
            this.count++;
            setTimeout(() => {
              this.count = 0;
            }, 2000);
          } else {
            this.isResponseTab = false;
          }

          this.getTagId("clickedTag");
          check == "myQueue"
            ? (this.checkBulkTagHeader = true)
            : (this.checkBulkTagHeader = false);
          this.textFieldArray = [];
          this.datefieldArray = [];
          this.dropdownArray = [];
          this.customArray = [];
          this.tagNote = "";
          for (let i = 0; i < this.uploadedByDisplayName.length; i++) {
            if (this.uploadedByDisplayName[i]?.includes("undefined")) {
              let OriginalDisplayName =
                this.uploadedByDisplayName[i]?.split("undefined")[1];
              if (
                OriginalDisplayName?.includes("(") &&
                OriginalDisplayName?.includes(")")
              ) {
                OriginalDisplayName = OriginalDisplayName?.replace(
                  "(",
                  ""
                ).replace(")", "");
              }
              if (OriginalDisplayName) {
                this.uploadedByDisplayName[i] = OriginalDisplayName?.trim();
              }
            }
          }

          this.islabel = [];
          this.isvalue = [];
          this.isTextBorderShow = [];
          this.isTextValueFocused = [];
          this.isDateBorderShow = [];
          this.isDateValueFocused = [];
          this.isDropdownValueFocused = [];
          this.isDropDownBorderShow = [];
          this.isCustomBorderShow = [];
          this.isCustomValueFocused = [];
          this.ShowDate = [];
          this.ShowDate1 = [];
          this.selected = [];
          this.ShowDateCustom = [];
          this.checklabel = [];
          this.checkvalues = [];
          this.labelTallyTagsArray= [];
          this.customTagArray=[];

          localStorage.setItem("selectedDocsNumber", String(this.docid.length));

          if (!this.multipleDocError) {
            let postdata = {
              DocGuid: guid[0],
              DocID: Number(id[0])!= null? Number(id[0]):0,
              // WsLink: arr[2],
              Wsoid: Number(localStorage.getItem("localwsId")),
              currentUser: localStorage.getItem("localloginId"),
            };
            this.data
              .verifierDocDetailsService(postdata)
              .subscribe(async (customTags: any) => {
                this.sharedService.documentId = customTags?.data?.docverificationdetailslists[0].docId;
                console.log("this", this.sharedService.documentId);
                let customTagData = customTags?.data?.doctagsdetailslists;
                let index = 0;
                let index1 =0;
                for(let i=0;i<customTagData.length;i++) {
                  if(customTagData[i]?.docSource?.toLowerCase().includes('tally') && customTagData[i].tagValue !== ""){
                    this.labelTallyTagsArray[index1++] = customTagData[i];
                  }else {
                    this.customTagArray[index++] = customTagData[i];
                  }
                }
                let customVerificationList = customTags?.data?.docverificationdetailslists[0];
                this.tagNote =
                  customVerificationList?.notes;
                let originalFileName =
                  customVerificationList
                    .originalFileName;
                let fileRename =
                  customVerificationList.docRename;
                localStorage.setItem(
                  "fileRename",
                  fileRename || originalFileName
                );
                if(customTagData?.length !== 0){
                this.singleTagsList = [];
                let k=0;
                  this.getSelectedDocTagData = customTagData;
                if (this.getSelectedDocTagData?.length > 0) {
                  for (let i = 0; i < this.getSelectedDocTagData?.length; i++) {
                    if (this.getSelectedDocTagData[i]?.tagValue === "" && this.getSelectedDocTagData[i]?.docSource === "") {
                      this.singleTagsList[i] =
                        this.getSelectedDocTagData[i].tagLabel;
                        this.singleTagsList = Array.from(new Set(this.singleTagsList));
                  }
                  else if(this.getSelectedDocTagData[i]?.tagValue === "" && this.getSelectedDocTagData[i]?.docSource?.toLowerCase().includes('tally')){
                    this.singleValueTallyTags[k++]= this.getSelectedDocTagData[i];
                  }
                }
              }
              if (this.singleTagsList?.includes(undefined)) {
                let index = this.singleTagsList?.indexOf(undefined);
                if (index !== -1) {
                  this.singleTagsList?.splice(index, 1);
                }
              }
                  if(this.docid?.length <= 1){
              localStorage.setItem(
                "singleTagList",
                      this.singleTagsList.toString()
              );
                  } else {
                    localStorage.setItem(
                      "singleTagList",
                      ""
                    );
                  }
          } else {
            this.singleTagsList = [];
          }
                this.cd.detectChanges();
                await this.getOldTags(customTagData);
              });
          } else {
            localStorage.setItem(
              "singleTagList",
              ""
              );
            this.isShowSpinner = false;
          }
          this.cd.detectChanges();
        }, 100);
        this.countCall = 1;
        setTimeout(() => {
          this.countCall = 0;
    }, 300);
      }
    }, 200);
  }

  async getOldTags(val) {
    let text: any = [],
      date = [],
      dropdown = [];

    let textValueFocused = [],
      dateValueFocused = [];

    if (val) {
      val.forEach((e) => {
        if (
          e.tagControlId == 1 &&
          (e.tagValue && e.tagValue !== "")
        ) {
          this.isText = true;
          e.tagValue = e.tagValue?.slice(0, 40);
          if(!e?.docSource?.toLowerCase().includes('tally') && e.tagValue !== ""){
            text.push(e);
          }
          this.ShowDate.push(false);
          this.isTemplateText = [true].concat(this.isTemplateText);
          this.canDateBorderShow = [true].concat(this.canDateBorderShow);
          this.isTextBorderShow = [false].concat(this.isTextBorderShow);
          if (e.tagValue === "") {
            textValueFocused.push(true);
          } else {
            textValueFocused.push(false);
          }
        } else if (
          e.tagControlId == 2 &&
          (e.tagValue && e.tagValue !== "")
        ) {
          this.isDate = true;
          if (
            e.tagValue.includes("null") ||
            e.tagValue === "Invalid date NaN,NaN" ||
            e.tagValue === null
          ) {
            e.tagValue = "";
          }
          if (e.tagValue && e.tagValue !== "") {
            e.tagValue = String(
              new DatePipe("en-US").transform(e.tagValue, "YYYY-MM-dd")
            );
          }
          this.ShowDate1.push(true);
          if(!e?.docSource?.toLowerCase().includes('tally') && e.tagValue !== ""){
            date.push(e);
          }
          this.isTemplateDate = [true].concat(this.isTemplateDate);
          this.canDateBorderShow = [true].concat(this.canDateBorderShow);
          this.isDateBorderShow = [true].concat(this.isDateBorderShow);
          if (e.tagValue === "") {
            dateValueFocused.push(true);
          } else {
            dateValueFocused.push(false);
          }
        } else if (
          e.tagControlId == 3 &&
          e.tagValue &&
          e.tagValue != "" &&
          !e.tagValue?.includes("||")
        ) {
          this.isOption = true;
          dropdown.push(e);
          this.isDropDownBorderShow.push(true);
          this.isDropdownValueFocused.push(true);
        }
      });
    }

    this.isTextValueFocused = textValueFocused?.concat(this.isTextValueFocused);
    this.isDateValueFocused = dateValueFocused?.concat(this.isDateValueFocused);
    this.textFieldArray = this.textFieldArray.concat(text);
    this.datefieldArray = this.datefieldArray.concat(date);
    this.dropdownArray = this.dropdownArray.concat(dropdown);
    for (let i = 0; i < this.dropdownArray.length; i++) {
      if (this.dropdownArray[i].tagValue.includes("||")) {
        this.dropdownValue[i] = this.dropdownArray[i].tagValue.split("||");
      } else {
        this.dropdownValue[i] = [this.dropdownArray[i].tagValue];
        this.selected[i] = this.dropdownArray[i].tagValue;
      }
    }
    this.sharedService.getOldTagsList.next([
      this.textFieldArray,
      this.datefieldArray,
      this.dropdownArray,
      this.ShowDate,
      this.ShowDate1,
      this.isTextBorderShow,
      this.isTextValueFocused,
      this.isDateBorderShow,
      this.isDateValueFocused,
      this.dropdownValue,
      this.selected,
      this.isDropDownBorderShow,
      this.singleTagsList,
      this.tagNote,
    ]);
    if(this.docid?.length <= 1){
    localStorage.setItem(
      "singleTagList",
        this.singleTagsList.toString()
    );
    } else {
      localStorage.setItem(
        "singleTagList",
        ""
      );
    }
    this.isShowSpinner = false;
    this.cd.detectChanges();
    const e = new Event("mouseenter");
    const element = document.querySelector("#clickedTag");
    if (element && e) {
      element.dispatchEvent(e);
    }
  }

  getAllIndexes(arr, val) {
    let indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
    return indexes.slice(0, -1);
  }

  getNoteData(data?) {
    this.tagNote = "";
    let detail = {
      wsoid:
        Number(this.workSpaceId) || parseInt(localStorage.getItem("localwsId")),
      docId: data.docId,
      CreatedBy: this.createdBy,
      guid: this.getguid,
      DocGuid: data.docGuid,
    };
    this.details.getdetailsdata(detail).subscribe((response: any) => {
      this.tagNote = response.data?.documentDetailsList[0]?.notes;
    });
  }

  removeTagValue(i) {
    if (this.islabel?.at(i) && this.islabel?.at(i) !== "") {
      this.islabel.splice(i, 1);
    } else if (this.islabel.length > i + 1) {
      let remaningLabels = this.islabel.slice(i);
      remaningLabels.shift();
      this.islabel = this.islabel.slice(0, i).concat(remaningLabels);
    }

    if (this.isvalue.at(i) && this.isvalue.at(i) !== "") {
      this.isvalue.splice(i, 1);
    } else if (this.isvalue.length > i + 1) {
      let remainingValues = this.isvalue.slice(i);
      remainingValues.shift();
      this.isvalue = this.isvalue.slice(0, i).concat(remainingValues);
    }

    this.customArray.splice(i, 1);
    this.ShowDateCustom.splice(i, 1);
    this.isCustomBorderShow.splice(i, 1);
    this.isCustomValueFocused.splice(i, 1);
  }

  removeChip(tag) {
    let index = this.singleTagsList.indexOf(tag);
    if (index !== -1) {
      this.singleTagsList.splice(index, 1);
      this.getUserDataSingleTag = "";
      this.getUserData = this.singleTagsList;
    }
    if(this.docid?.length <= 1){
      localStorage.setItem(
        "singleTagList",
        this.singleTagsList.toString()
      );
    } else {
      localStorage.setItem(
        "singleTagList",
        ""
      );
    }
    localStorage.setItem("saved", "true");
    //this.SaveTagVerifyData();
  }

  removeTextTag(tag, type) {
    let template = {
      tempId: this.textFieldArray.tempId,
      tagLabel: "",
      tagValue: "",
      createdBy: this.createdBy,
      tagcontrolid: "",
      optionvalue: "",
    };
    this.ShowDate.splice(tag, 1);
    this.textFieldArray.splice(tag, 1);
    this.getUserData = this.textFieldArray;
    this.deleteField = true;
    localStorage.setItem("saved", "true");
    // this.SaveTagVerifyData(template, type, tag);
  }

  removeDateTag(tag, type) {
    let template = {
      tempId: this.datefieldArray.tempId,
      tagLabel: "",
      tagValue: "",
      createdBy: this.createdBy,
      tagcontrolid: "",
      optionvalue: "",
    };
    this.ShowDate1.splice(tag, 1);
    this.datefieldArray.splice(tag, 1);
    this.getUserData = this.datefieldArray;
    this.deleteField = true;
    localStorage.setItem("saved", "true");
    // this.SaveTagVerifyData(template, type, tag);
  }

  removeDropdownTag(tag, type) {
    let template = {
      tempId: this.dropdownArray.tempId,
      tagLabel: "",
      tagValue: "",
      createdBy: this.createdBy,
      tagcontrolid: "",
      optionvalue: "",
    };
    this.dropdownArray.splice(tag, 1);
    this.getUserData = this.dropdownArray;
    this.deleteField = true;
    localStorage.setItem("saved", "true");
    // this.SaveTagVerifyData(template, type, tag);
  }

  verifyDocument(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    let  tagsAndValue = [
      ...this.textFieldArray,
      ...this.datefieldArray,
      ...this.dropdownArray,
      ...this.singleTagsList,
      ...this.customArray,
    ];
    localStorage.setItem("saved", "false");
    if ((tagsAndValue == undefined || tagsAndValue.length == 0) && !this.isModalHidden) {
      this.dialogRef = this.dialogService.open(dialogModal, {
        closeOnBackdropClick: false,
        autoFocus: true,
        closeOnEsc: false,
      });
    } else {
      this.verifyFlag = true;
      this.isSaving = true;
      localStorage.setItem("saved", "true");
      this.SaveTagVerifyData();
      setTimeout(() => {
        if(!this.isSaving){
          this.isSaving = false;
          localStorage.setItem("saved", "false");
        }
      }, 3000);
    }
    this.getNotified();
  }
  insertmessgae: any;
  getNewTagNotify() {
    let postdata = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: "",
      notificationHeading: "Document tags or notes are updated",
      message:
        "<b>" +
        this.userfullname +
        "(" +
        this.createdBy +
        ")" +
        "</b>" +
        " " +
        " updated tags or notes of  <b> ##docname</b>",
      notificationTo: "",
      createdBy: this.createdBy,
      docID: this.docid.toString(),
    };
    this.insertmessgae = "";
    this.signInService.notifyinsert(postdata).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      } else {
        this.insertmessgae = "";
      }
    });
  }
  getNotified() {
    // var st = window?.location?.toString();
    // var arr = st?.split("/");
    // var urlchange = arr[0] + "//" + arr[2] + '/view/view-document?docId='+ this.docid + '&docGuid=' + this.docGuid
    let postdata = {
      wsoid: Number(this.workSpaceId),
      notificationFrom: "",
      notificationHeading: "Document verification complete",
      message:
        "<b>" +
        this.userfullname +
        "(" +
        this.createdBy +
        ")" +
        "</b>" +
        " " +
        "has completed the verification of  <b> ##docname</b>",
      notificationTo: "",
      createdBy: this.createdBy,
      docID: this.docid.toString(),
    };
    this.insertmessgae = "";
    this.signInService.notifyinsert(postdata).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
      } else {
        this.insertmessgae = "";
      }
    });
  }
  checkNonEnglish(event) {
    if (event.charCode > 255) {
      event.preventDefault();
    }
  }

  checkTagLength(event) {
    let singleTagValueOnKeyPress =
      event?.target?.value?.trim() || event?.target?.textContent?.trim();
    if (
      (event.keyCode !== 13 || event.keyCode !== 32) &&
      singleTagValueOnKeyPress.length >= 15
    ) {
      event.preventDefault();
    }
  }

  checkLabelLength(event) {
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    let LabelDataOnKeyPress =
      event?.target?.value?.trim() || event?.target?.textContent?.trim();
    if (LabelDataOnKeyPress.length >= 15) {
      event.preventDefault();
    }
  }

  onDocumentVerifierUpdate() {
    localStorage.setItem("saved", "true");
    //this.SaveTagVerifyData();
  }

  hideModal() {
    let canVerifyWithoutTags = localStorage.getItem("isModalHidden");
    if (canVerifyWithoutTags) {
      if (canVerifyWithoutTags === "true") {
        this.isModalHidden = false;
      } else if (canVerifyWithoutTags === "false") {
        this.isModalHidden = true;
      }
    } else {
      this.isModalHidden = !this.isModalHidden;
    }
    localStorage.setItem("isModalHidden", String(this.isModalHidden));
  }

  leave(event?) {
    const element = document.getElementById("clickedTag");
    let isMenuOpen = false;
    this.renderer.listen("window", "click", (e) => {
      if (
        (window.innerWidth >= 1920 &&
          e.x < window.innerWidth - 500 &&
          e.y < 200) ||
        (window.innerWidth < 1920 && e.x < window.innerWidth - 450 && e.y < 180)
      ) {
        isMenuOpen = true;
        // this.isSaving = true;
        //this.SaveTagVerifyData()
      }
    });
  }

  tagValueInput(tagValue: string, textarea: string, event, type?, i?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
    localStorage.setItem("documentIds", this.sharedService.documentIds.toString());
    let regex = new RegExp("^[a-zA-Z0-9. s-]*$");
    let regPattern = /[\u0000-\u007A]+/g;
    let textContent: string;
    textContent = event.target.textContent?.toString();
    if (regex.test(textContent) && regPattern.test(textContent)) {
      if (textContent.length > 40) {
        tagValue = textContent.slice(0, 40);
      } else {
        tagValue = textContent;
      }
    }
    if (type) {
      let id: string;
      if (type === "custom") {
        id = "customValue_" + i;
      } else if (type === "text") {
        id = "textValue_" + i;
      } else if (type === "date") {
        id = "dateValue_" + i;
      }
      let el = document.getElementById(id);
      let range = document.createRange();
      let sel = window.getSelection();
      sel.removeAllRanges();
      range.selectNodeContents(el);
      range.collapse(false);
      sel.addRange(range);
      el.focus();
    }
    return tagValue;
  }

  // @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event, i, type): void {
    if(!this.hideDiv){
      const eventTarget = event.target;
      let alphakeys = Array.from(new Array(26), (x, i) => i + 65);
      alphakeys = alphakeys.concat(Array.from(new Array(26), (x, i) => i + 97));
      if (
        (eventTarget.value?.length > 39 ||
          eventTarget.textContent?.length > 39 ||
          eventTarget.innerHTML?.length > 39) &&
        event.which !== 13 &&
        event.which !== 32 &&
        event.which !== 8
      ) {
        event.preventDefault();
      } else if (
        alphakeys.includes(event.which) &&
        event.which !== 17 &&
        !(event.ctrlKey && event.keyCode === 86)
      ) {
        if (type === "text") {
          this.textFieldArray[i].tagValue =
            eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
        } else if (type === "date") {
          this.datefieldArray[i].tagValue =
            eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
        } else if (type === "custom") {
          this.isvalue[i] =
            eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
        }
      }
    }
  }

  updateDocAuditLog(operation?, serial?) {
    let postData = [];
    let currentIp = localStorage.getItem("ipAddress");
    for (let i = 0; i < this.docid.length; i++) {
      postData[i] = {
        wsoid: Number(this.workSpaceId),
        category: "Document",
        activity: "Document View",
        logMessage:
          `<b>"${this.docRenameList[i]}.${this.formatExtension[
            i
          ]?.toLowerCase()}"</b>` +
          " " +
          operation +
          " " +
          `<b>${this.uploadedByDisplayName[i]}</b>`,
        createdBy: this.createdBy,
        docId: Number(this.docid[i]),
        database: "get2doxMaster",
        // "serialNo": serial,
        docName: this.docRenameList[i],
        userName: localStorage.getItem("noteuserfullname"),
        guid: this.docGuid[i],
        IpAddress: currentIp,
        IsIntegrationViewer: 0
      };
    }
    let checkRepeatPostData = [];
    let checkRepeatDocID = [];
    for (let i = 0; i < postData.length; i++) {
      checkRepeatPostData[i] = false;
      checkRepeatDocID[i] = false;
      if (this.previousLogMessage.includes(postData[i].logMessage)) {
        checkRepeatPostData[i] = true;
      }
      if (this.previousdocID.includes(postData[i].docId)) {
        checkRepeatDocID[i] = true;
      }
    }
    for (let i = 0; i < postData.length; i++) {
      if (!checkRepeatDocID[i] && !checkRepeatDocID[i]) {
        this.data.viewDocsAuditLog(postData[i]).subscribe((response: any) => {
          if (response.isSuccess) {
            this.isRepeatLog[i] = true;
            this.previousLogMessage[i] = postData[i].logMessage;
            this.previousdocID[i] = postData[i].docId;
            setTimeout(() => {
              this.isRepeatLog[i] = false;
              this.previousdocID[i] = 0;
              this.previousLogMessage[i] = "";
            }, 500000);
          }
        });
      }
    }
  }


  /*
  * for template editor additional methods by Abhishek and Shubham
  */
  isSubmitted: boolean;
  tagTemplate:any = [];
  templateArray = [];
  defaultTemplate: any;
  tagTempList = [];
  docTemplateId: any;
  TemplateEditor: any;
  tagTemplateEdit($event:any) {
    this.isSubmitted = false;
    this.tagTemplateList($event.flag, $event);
  }
   /**
   *
   * @param data
   * @param $event method  for tag Template List
   */
  tagTemplateList(isRefresh, value?) {
    var st = window.location.toString();
    var arr = st?.split("/");
     let  postData = {
      "wsoid": Number(this.workSpaceId),
      "guid": this.createdBy
    }
    this.pendingRequest = this.data.tagTemplateService(postData).subscribe((response: any) => {
      if (response.isSuccess == true) {
        this.tagTemplate = response.data;
        const filterOutTemp = this.tagTemplate.filter(al => al['tempName'] === 'No Template')
        const filterOutNoTemp = this.tagTemplate.filter(al => al['tempName'] !== 'No Template')
        filterOutNoTemp.sort((a, b) => a.tempName.localeCompare(b.tempName))
        this.tagTemplate = [...filterOutNoTemp, ...filterOutTemp]
        this.templateArray = response.data;
        this.defaultTemplate = response.data.find(obj => obj.isdefault);
        this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];
        if (value) {
          for (let i = 0; i <= response.data.length - 1; i++) {
            if (value.tdId == response.data[i].tdId) {
              this.defaultTemplate = response.data[i];
              if (this.selectedTemplate.tdId !== value.tdId) {
                this.selectedTemplate = response.data[i];
              }
            }
          }
        }
        else {
          this.defaultTemplate = response.data.find(obj => obj.isdefault);
          this.selectedTemplate = this.defaultTemplate ? this.defaultTemplate : response.data[0];
        }
        this.tagTempList = this.templateArray.slice();
        if (!isRefresh) {
          this.tagTempList.sort((a, b) => a.tempName.localeCompare(b.tempName))
          let inx = 0;
          let inxGeneral = 0;
          let inxNoTemplate = 0;
          for (let user of this.tagTemplate) {
            user.tempName;
            user['tagTemplateDropdown'] = user.tempName;
            if (user.tdId == this.docTemplateId) {
              if (!isRefresh && !this.defaultTemplate) {
                this.selectedTemplate = user;
                this.onTagTemplateId(this.selectedTemplate);
              }
            }
            if (user.tempName == 'General') {
              inxGeneral = inx;
            }
            if (user.tempName == 'No Template') {
              inxNoTemplate = inx;
            }
            inx = inx + 1;
          }
          if (this.defaultTemplate) {
            this.onTagTemplateId(this.selectedTemplate);
          }
          else {
            this.onTagTemplateId(this.selectedTemplate);
          }
        }
        else {
          this.selectedTemplate = value;
          this.onTagTemplateId(this.selectedTemplate);
        }
      }
    }, error => {
      this.errormsg();
    })
  }
  @BlockUI() blockUI: NgBlockUI;
  private errormsg(){
    setTimeout(() => {
      this.blockUI.stop();
    }, 1000)
    }
  onTagTemplateId(event) {
    this.tagTemplateId = event.tdId;
    let data = { "title": "", "description": "" };
    data['title'] = event.tempName;
    data['description'] = event.tempDescription;
    this.TemplateEditor?.patchValue(data);
    this.onGetTemplateDetails();
    this.docsVerification?.markAsDirty();
  }

  /*
  * for template editor additional methods ends here by Abhishek and Shubham
  */

  dateChange(event, i) {
    let datePickedArray = event?.toDateString()?.split(" ");
    let datePickedString =
      datePickedArray[2] + " " + datePickedArray[1] + " " + datePickedArray[3];
    this.datefieldArray[i].tagValue = datePickedString;
  }

  @HostListener("document:blur", ["$event"])
  checkBlur() {
    this.isSaving = true;
    this.SaveTagVerifyData();
    setTimeout(() => {
      if(!this.isSaving){
        this.isSaving = false;
        localStorage.setItem("saved", "false");
      }
    }, 300);
  }

  customValueFocused(i, type, event?) {
    if (
      (event?.target?.value && event?.target?.value !== "") ||
      (event?.target?.textContent && event?.target?.textContent !== "")
    ) {
      this.isCustomValueFocused[i] = true;
    }
  }

  customValueBlur(i, type, event?) {
    if(this.blankState) {
      this.blankState = false;
    }
    if (
      (event?.target?.textContent && event?.target?.textContent !== "") ||
      (event?.target?.value && event?.target?.value !== "")
    ) {
      this.isCustomValueFocused[i] = false;
    }
  }

  TextValueFocused(i, type, event?) {
    if (
      (event?.target?.value && event?.target?.value !== "") ||
      (event?.target?.textContent && event?.target?.textContent !== "")
    ) {
      this.isTextValueFocused[i] = true;
    }
  }

  TextValueBlur(i, type, event?) {
    if (
      (event?.target?.textContent && event?.target?.textContent !== "") ||
      (event?.target?.value && event?.target?.value !== "")
    ) {
      this.isTextValueFocused[i] = false;
    }
  }

  DateValueFocused(i, type, event?) {
    if (
      (event?.target?.value && event?.target?.value !== "") ||
      (event?.target?.textContent && event?.target?.textContent !== "")
    ) {
      this.isDateValueFocused[i] = true;
    }
  }

  DateValueBlur(i, type, event?) {
    if (
      (event?.target?.textContent && event?.target?.textContent !== "") ||
      (event?.target?.value && event?.target?.value !== "")
    ) {
      this.isDateValueFocused[i] = false;
    }
  }

  dropdownFocus(type, i) {
    if (type === "focus") {
      this.isDropdownValueFocused[i] = false;
    } else {
      this.isDropdownValueFocused[i] = true;
    }
  }

  ngOnDestroy() {
    if (localStorage.getItem("saved") === "true") {
      this.SaveTagVerifyData();
    }
  }
}
