import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tallyVoucherName'
})
export class TallyVoucherNamePipe implements PipeTransform {

  transform(value: string, voucherTags: any): string {
    let keyString = '';
    let masterTags = [];
    let vouchersTags = [];
    voucherTags.forEach((res) => {
      if (res.key.includes("Voucher")) {
        vouchersTags.push(res);
      } else if (res.key.includes("Ledger") || res.key.includes("Stock Item") || res.key.includes("Budget") || 
          res.key.includes("Cost Centre") || res.key.includes("Employee")) {
        masterTags.push(res);
      }
    })
    if (value.includes("Voucher")) {
      vouchersTags.forEach((res,index) => {
        if (res.key == value) {
          keyString = "Voucher" + " " + (index+1);
        }
      })
    } else if (value.includes("Ledger") || value.includes("Stock Item")  || value.includes("Budget") || 
        value.includes("Cost Centre") || value.includes("Employee")) {
      masterTags.forEach((res, index) => {
        if (res.key == value) {
          keyString = "Master" + " " + (index+1);
        }
      })
    }
    return keyString;
  }

}
