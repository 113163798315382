import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild, ViewChildren, ElementRef, HostListener, TemplateRef, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';

import { NbDialogRef, NbDialogService, NbPopoverDirective, NbSpinnerService, NbStepperComponent } from '@nebular/theme';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../@core/utils/shared.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import {NGXLogger} from 'ngx-logger'
import { SignInService } from '../../@core/sign-in.service';
import { SignUpService } from '../../@core/sign-up.service';
import { environment } from '../../../environments/environment';
import { DashboardService } from '../../@core/utils/dashboard.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedServiceService } from '../../shared-service.service';
import { Session } from 'inspector';
import { NumberFilter } from 'ag-grid-community';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'ngx-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],

})
export class LoginRegisterComponent implements OnInit {
  conditionalSignIn = false;
  /**
   * variable for device information
   */
  deviceInfo:DeviceInfo;
  /**
   * first form  variable for first step form_group
   */
  firstForm: FormGroup;
  /**
  * second form  variable for second step form_group
  */
  secondForm: FormGroup;
  /**
  * third form  variable for third step form_group
  */
  thirdForm: FormGroup;
  /**
  *  variable for creator of workspace
  */
  createdBy: string;
  /**
   *  variable for stepper index
   */
  stepperIndex = 0
  /**
   * variable for workspace id
   */
  workspaceID: any;
  /**
   *  variable for href link
   */
  href: any;
  /**
  *  variable for sign-up form
  */
  signUpForm: any;
  /**
   *  variable for sign-in form
   */
  signinForm: any;
  /**
   *  variable for dot in title of the page
   */
  middot = ' | ';   /**
   *  variable for Name of workspace
   */
  WorkSpaceName: string;
  /**
  *  variable for id of workspace
  */
  wsoid: any;
  /**
  *  variable for name of workspace owner
  */
  wsName: any;
  /**
  *  variable for form of password
  */
  passwordForm: any;
  /**
   *  variable for disable sign-up page
   */
  disableSignUpPage: boolean;
  /**
  *  variable for email field in sign-in page
  */
  email = '';
  /**
   *  variable for spinner
   */
  showSpinner: boolean;
  /**
   *  variable for already registered
   */
  alreadyRegistered: boolean;
  /**
  *  variable for max-count
  */
  maxCountReached: boolean;
  /**
 *  variable for unauthorised access
 */
  notAuthorized: boolean;
  /**
   *  variable for email in sign up form
   */
  emailId: string;
  /**
  *  variable for vcid
  */
  vcid: any;
  /**
   *  variable for signIn hidden
   */
  signInHidden = true;
  /**
   *  variable for location of the page
   */
  currentPage = 1;
  /**
  *  variable for counter
  */
  counter: number;
  /**
  *  variable for resend button disable
  */
  disableResendButton = true;
  /**
 *  variable for sign-up email
 */
  signupemail: string;
  /**
 *  variable for sign-up workspace id
 */
  signupwsoid: string;
  /**
  *  variable for insert message
  */
  insertmessgae: string;
  /**
   *  variable for send message
   */
  sendmessage: string;
  /**
   *  variable for path of the image of workspace
   */
  iconPath: any = 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D';
  /**
   *  variable for loader in verification page of sign_up
   */
  verification_loader: any;
  /**
   *  variable for full Name of sign_up
   */
  fullNamePattern = "^[a-zA-Z0-9. \s-]*$";
  /**
  *  variable for change-name in signup
  */
  changeNameSignup = false;
  /**
   *  variable for change-password email
   */
  changeEmailSignup = false;
  /**
  *  variable for timer
  */
  timer: boolean;
  /**
   *  variable for inter-valid
   */
  intervalId: NodeJS.Timer;
  /**
   *  variable for input in verfication field
   */
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5'];
  /**
   *  variable for spinner loader in verfication field
   */
  verifySpinner: boolean;
  disableVerifyButton: boolean;
  otp: any;
  showPassword = false;
  showpasswordsignin = false;
  showPasswordError: boolean;
  containUpperCase: boolean;
  containLowerCase: boolean;
  containNumber: boolean;
  containSpecialCharacter: boolean;
  passwordLength: boolean;
  submitted: boolean;
  disableWSSignup: boolean;
  roundSpinner: boolean;
  userfullname: any;
  signUpEmailVerification: boolean;
  displayDate;
  str: string;
  fullName: any;
  targetInput = 'input0';
  startDate: number;
  minutes;
  minlocaldata: void;
  changesdata: number;
  sessionidle: number;
  enddate: any;
  Sessionduration: any;
  geturlval: string;
  localsearch: string[];
  innerwidth = window.innerWidth
  iconPathname: any;
  ownerEmailId: any;
  containNonenglishSpecialCharacterName: boolean;
  containNonenglishSpecialCharacterEmail: boolean;
  containNonenglishSpecialCharacterEmailsignin: boolean;
  subscriptionOwnedBy: any;
  emailID: unknown;
  otpSend: any;
  conditionalSignUp: boolean;
  loadertrue: boolean;
  eventPointer: boolean;
  eventPointer2: boolean;
  eventPointer3: boolean;
  termsAndCondition: string;
  signUpEmailFromCPW: string;
  @ViewChild('emailinput', { static: false }) inputEl: ElementRef;
  format: any;
  sharedEmailValueSignup: any;
  minEmailSignUp: boolean = false;
  workspaceDomain: string;
  wsSubDomain: string;
  login: string;
  wsid: string;
  iconImage: string;
  disableworkspaceData: any;
  wsStatus: any;
  getarrdata: string[];
  loginFailed= false;
  SignupFailed=false;
  defaultImage: string = 'https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D';
  constructor(@Optional() public dialogRef: NbDialogRef<any>, private sanitizer: DomSanitizer, public http:HttpClient, public logger:NGXLogger, public dialogService: NbDialogService, public formBuilder: FormBuilder, public signUpService: SignUpService, public cd: ChangeDetectorRef, public fb: FormBuilder, public spinner: NbSpinnerService, public router: Router, public location: Location, public sharedService: SharedService,private route: ActivatedRoute,
    public signInService: SignInService, private deviceDetectorService: DeviceDetectorService,
     public renderer: Renderer2, public cookieService: CookieService, private changingValue: DashboardService, private sharedservice: SharedServiceService,
    public titleService: Title) {
      if(cookieService.get('isCreate')){
        console.log("object");
        this.router.navigate(['/pages/dashboard']);
      }
      let getstdata = window.location.toString();
      let changereq = getstdata.search('https')
      if (changereq == -1) {
        let newStr = getstdata.replace('http', 'https')
        getstdata = newStr;
        this.getarrdata = getstdata?.split('/');
        if (this.getarrdata[2] != "localhost:4200") {
          if (environment.httpUrl == 'https://') {
            window.location.href = window.location.href.replace('http', 'https')
          }
        }
      }
     localStorage.setItem('urlredirect', getstdata);
    this.form = this.toFormGroup(this.formInput);
    this.signUpEmailFromCPW = localStorage.getItem('isChanegPWSignup');
    this.createdBy = localStorage.getItem('localloginId');
    this.workSpaceId = localStorage.getItem('localwsId')


    /**
    * when user is already sign-in
    */
    if (this.createdBy) {
      localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard();
    }
    /**
     * use for the password signup form validation
     */
    this.passwordForm = this.formBuilder.group({
      fname: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(3), Validators.pattern('^[a-zA-Z0-9 ._-]*$'),])],
      password: ['', [Validators.required,
      Validators.maxLength(20),
      Validators.minLength(6),
      Validators.pattern(/^\S*$/)]],
      agreeCheckbox: [false, Validators.requiredTrue],
      repassword: ['', [Validators.required]]
    }, {
      validator: this.passwordConfirming
    });


    /**
    * use for the signup form validation
   */
    this.signUpForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(2), Validators.pattern(this.fullNamePattern)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(/^(([^<>()[\]\\.',;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
    });

    /**
     * use for the signin form validation
    */
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(/^(([^<>()[\]\\.',;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      password: ['',
        [
          Validators.maxLength(60),
          Validators.minLength(6),
          Validators.pattern(/^\S*$/)]],
    });

  }

  /**
   * make viewchild for bydefault autofocus
   */
  @ViewChild("signininput") signinInputdata: ElementRef;
  @ViewChild("signupinput") signupInputdata: ElementRef;
  @ViewChild("passwordInput") passwordInputdata: ElementRef
  @ViewChild("stepper") stepper: NbStepperComponent;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChildren('formRow') rows: any;
  @ViewChild('sessionmanagement', { read: TemplateRef }) sessionmanagement: TemplateRef<any>;
  @ViewChild('unsavedchanges', { read: TemplateRef }) unsavedchanges: TemplateRef<any>;
  @ViewChild('unsavedchangessignin', { read: TemplateRef }) unsavedchangessignin: TemplateRef<any>;
  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key: any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  form: FormGroup;
  isIE;
  isFirefox=false;
  ngOnInit(): void {
    //console.log("InvitedUserEmailId",this.route.snapshot.paramMap.get('id'))
     this.getIPAddress();
    let invitedUserEmail=''
    if(localStorage.getItem('signupUserEmail') && localStorage.getItem('signupUserEmail')!='' && typeof(localStorage.getItem('signupUserEmail'))!='undefined')
    {
      invitedUserEmail=localStorage.getItem('signupUserEmail')   
    }
    console.log('invitedUserEmail',invitedUserEmail)
    if(typeof(invitedUserEmail)!='undefined' && invitedUserEmail!='')
    {
      this.f.email.value=invitedUserEmail
    }
    
    const userAgent = navigator.userAgent;
    console.log("user agent", userAgent);

    //@ts-ignore
    this.isIE = !!document.documentMode; 

    // if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
    //   // Internet Explorer detected
    //   this.isIE = true;
    // } else if (userAgent.indexOf("Edg") !== -1 || userAgent.indexOf("Edge") !== -1) {
    //   this.isIE = false;
    // }
    const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1; 

    if(isFirefox){
      this.isFirefox = isFirefox
      this.isIE = false;
    }else{
       this.isFirefox = false;
    }
    this.getIPAddress();
   if(localStorage.getItem('recheck') === 'signup'){
            this.SignupFailed = true
            this.loginFailed=false
    }
    localStorage.removeItem('recheck')
    var URLs = new URL(window.location.href);
    let getstdata = window.location.toString();
    let changereq = getstdata.search('https')
    if (changereq == -1) {
      let newStr = getstdata.replace('http', 'https')
      getstdata = newStr;
      this.getarrdata = getstdata?.split('/');
      if (this.getarrdata[2] != "localhost:4200") {
        if (environment.httpUrl == 'https://') {
          window.location.href = window.location.href.replace('http', 'https')
        }
      }
    }
   localStorage.setItem('urlredirect', getstdata);
    var email = URLs.searchParams.get("loginId");
    var login = URLs.searchParams.get("email");
    localStorage.setItem('signInEmail', email);
    localStorage.setItem('signUpInEmail', login);
    this.href = this.router.url;
    if (this.createdBy) {
      this.router.navigate(['/pages/dashboard']);
    }
    let self = this;
    if (localStorage.getItem('isChanegPW')) {
      this.signinForm.patchValue({
        email: localStorage.getItem('isChanegPW')
      })
      localStorage.removeItem('isChanegPW');
    }
    if (localStorage.getItem('signInEmail') !== null) {
      this.signinForm.patchValue({
        email: JSON.parse(localStorage.getItem('signInEmail'))
      })
      localStorage.getItem('signInEmail')
    }
    if (localStorage.getItem('isChanegPWSignup')) {
      this.signUpForm.patchValue({
        email: this.signUpEmailFromCPW

      })

      localStorage.removeItem('isChanegPWSignup');
    }
    if (localStorage.getItem('signUpInEmail') !== 'null') {
      this.signUpForm.patchValue({
        email: JSON.parse(localStorage.getItem('signUpInEmail'))
      })
      localStorage.removeItem('signUpInEmail');
    }
    if(this.cookieService.get('useremail')) {
      this.signinForm.patchValue({
        email: this.cookieService.get('useremail')
      })
    }

    /**
    * form slider code started here
    */


    document.querySelector('.img__btn').addEventListener('click', function () {
      this.eventPointer3 = false;
      this.eventPointer = false;
      if (document.querySelector('.cont').classList.value === 'cont') {

        let email = self.signinForm.controls.email.value;
        let password = self.signinForm.controls.password.value;
        if (email || password) {
          self.unsavedLeavesignin()

        }
        else {
          self.signIn();
          document.querySelector('.cont').classList.toggle('s--signup');
          this.eventPointer3 = false;
        }
      } else {
        if (self.currentPage === 1) {
          self.signUp();
          self.signUpForm.reset();
          self.signUpForm.patchValue({
            email: '',
            name: ''
          })
          self.changeEmailSignup = false;
          self.alreadyRegistered = false;
          self.notAuthorized = false;
          self.changeNameSignup = false;
          self.minnamesignup = false;
          self.minEmailSignUp = false;
          self.containNonenglishSpecialCharacterName = false;
          self.containNonenglishSpecialCharacterEmail = false;
          document.querySelector('.cont').classList.toggle('s--signup');
        }
        else {
          self.openModal(self.unsavedchanges, false);
        }
      }

    })
    /**
     * form slider code ends here
     */
    /**
     * This is use for the according to the url redirection
     * for sign-in and sign-up
     */
    if (this.href === "/onboarding/sign-in") {
      if (this.innerwidth <= 1200) {
        this.conditionalSignIn = true;
        this.conditionalSignUp = false;
      }
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['onboarding/sign-in'])
      );
    }
    else if (this.href === "/onboarding/sign-up" || this.href.includes('/onboarding/sign-up')) {
      if (this.innerwidth <= 1200) {
        this.conditionalSignIn = false;
        this.conditionalSignUp = true;
      }
      document.querySelector('.cont').classList.toggle('s--signup');
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['onboarding/sign-up'])
      );
    }
    this.displayDate = new Date();
    this.str = this.displayDate.toString();
    this.displayDate = (this.str.substring(this.str.indexOf("GMT"))).replace("GMT", "UTC");
    this.displayDate = this.displayDate.substring(0, 6) + ':' + this.displayDate.substring(6);
    //******************************************Sign Up Email validation Code ends here***************
    /**
     * workspace status api call function
     */
    this.workspacestatus();
    if (localStorage.getItem('emailSignup')) {
      this.signUpForm.patchValue({
        email: localStorage.getItem('emailSignup')
      })

      localStorage.removeItem('emailSignup')

    }

    if (localStorage.getItem('emailSignin')) {
      this.signinForm.patchValue({
        email: localStorage.getItem('emailSignin')
      })

      localStorage.removeItem('emailSignin')

    }
  }

  

  /**
   * Method for Getting IP Address
   */

  ipAddress
  selectedCountry
  getIPAddress(){
    if (localStorage.getItem('selectedCountry') === undefined || localStorage.getItem('selectedCountry') === null){
      this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
        this.http.get('https://ipapi.co/' + res.ip + '/json/').subscribe((resp: any) => {
          this.logger.log('Aman', resp)
          this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
          console.log(this.deviceInfo)
          localStorage.setItem("DeviceType",this.deviceInfo.deviceType)
          localStorage.setItem("BrowserName",this.deviceInfo.browser)
          localStorage.setItem("BrowserVersion",this.deviceInfo.browser_version)
          localStorage.setItem("OS",this.deviceInfo.os)
          let resolution = window.innerWidth + '*' + window.innerHeight
          console.log("Screen Resolution: " , resolution);
          console.log("Date and Time :-", new Date())
          this.ipAddress = resp.ip;
          this.selectedCountry = resp.country_name;
          localStorage.setItem('ipAddress', this.ipAddress);
          localStorage.setItem('selectedCountry', this.selectedCountry);

        }, error => {

          this.errormsg();
        });
      });
    } else {
      this.ipAddress = localStorage.getItem('ipAddress');
      this.selectedCountry = localStorage.getItem('selectedCountry');
      this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
      console.log(this.deviceInfo)
      localStorage.setItem("DeviceType",this.deviceInfo.deviceType)
      localStorage.setItem("BrowserName",this.deviceInfo.browser)
      localStorage.setItem("BrowserVersion",this.deviceInfo.browser_version)
      localStorage.setItem("OS",this.deviceInfo.os)
      let resolution = window.innerWidth + '*' + window.innerHeight
      console.log("Screen Resolution: " , resolution);
      console.log("Date and Time :-", new Date())
    }
  }
  errormsg() {
    throw new Error('Method not implemented.');
  }
  /**
   * password form confirm
   */
  passwordConfirming(c: any): any {
    if (c.get('password').value !== c.get('repassword').value) {
      return {
        invalid: true
      };
    }
  }

  @HostListener('window:load', ['$event'])
  OnLoad($event: any) {
    if (this.href === "/onboarding/sign-in") {
      this.signinInputdata.nativeElement.focus();
    } else if (this.href === "/onboarding/sign-up") {
      this.signupInputdata.nativeElement.focus();
    }

  }

  ngAfterViewInit() {
    /**
      * Bydefault autofocus when access according to the url
     */
    if (this.href === "/onboarding/sign-in") {
      this.signinInputdata.nativeElement.focus();
    } else if (this.href === "/onboarding/sign-up") {
      this.signupInputdata.nativeElement.focus();
    }
    this.setFocus();
  }
  openModalforsignin(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }
  /**
   * sigb-in toggle
   */
  toggleSignin() {
    document.querySelector('.cont').classList.toggle('s--signup');
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-in'])
    );
    this.signinForm.patchValue({
      email: this.f.email.value
    })
    this.location.replaceState(url);
    this.signUpForm.reset();
    this.signUpForm.patchValue({
      email: '',
      name: ''
    })
    this.changeEmailSignup = false;
    this.alreadyRegistered = false;
    this.notAuthorized = false;
    this.changeNameSignup = false;
    this.minnamesignup = false;
    this.minEmailSignUp = false;
    this.containNonenglishSpecialCharacterName = false;
    this.containNonenglishSpecialCharacterEmail = false;
    this.titleService.setTitle("Sign In" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }
  /**
   * set focus
   */
  private setFocus() {
    let targetElem = document.getElementById(this.targetInput);
    setTimeout(function waitTargetElem() {
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }
  /**
   * convenience getter for easy access to form fields
   */

  get f() {
    return this.signUpForm.controls;
  }
  get f1() {
    return this.passwordForm.controls;
  }

  /**
 * convenience getter for easy access to form fields
 */
  get signinf() {
    return this.signinForm.controls;
  }
  /**
   * change browser URL to sign in
   */
  signIn() {
    this.eventPointer3 = false;
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-up'])
    );

    /**
    * Bydefault autofocus when switch url using button
    */
    if (url === "/onboarding/sign-in") {
      this.signinInputdata.nativeElement.focus();
    } else {
      this.signupInputdata.nativeElement.focus();
    }
    this.location.replaceState(url);
    this.titleService.setTitle("Sign Up" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }
  /**
 * change browser URL to sign up
 */
  signUp() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-in'])
    );
    /**
    * Bydefault autofocus when switch url using button
    */
    if (url === "/onboarding/sign-in") {
      this.signinInputdata.nativeElement.focus();
    } else {
      this.signupInputdata.nativeElement.focus();
    }

    if (this.targetElemSignupFirst) {
      this.eventPointer3 = false;
    }

    this.location.replaceState(url);
    this.titleService.setTitle("Sign In" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }
  /**
 * on subimit first form of signup started here
 */
  @ViewChild('dailyLimitEcxeeded', { read: TemplateRef }) dailyLimitEcxeeded: TemplateRef<any>;
  minnamesignup = false;
  targetElemSignupFirst: any;
  signUp1:boolean=false;
  showSignupPage:boolean=true;
  onSubmitFirst() {
    this.eventPointer3 = true;
    this.targetElemSignupFirst = document.getElementById('next1');
    this.changeEmailSignup = true;
    this.changeNameSignup = true;
    this.showErrorMessage = false;
    this.disableResendButton = true;
    this.form.patchValue({
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: ''
    })
    if (this.signUpForm.invalid) {
      this.targetElemSignupFirst.classList.toggle('button--loading');
      this.eventPointer3 = false;
      setTimeout(() => {
        this.targetElemSignupFirst.classList.toggle('button--loading');
      }, 100);

      if (this.signUpForm.value.name.length > 0 && this.signUpForm.value.name.length < 2) {
        this.minnamesignup = true;

      }
      else if (this.signUpForm.value.email.length > 0) {
        this.minEmailSignUp = true;
      }
      else {
        this.minnamesignup = false;
        this.minEmailSignUp = false;
      }
      return
    }
    this.disableSignUpPage = true;
    let st = window.location.toString();
    let arr = st?.split("/");
    this.email = this.signUpForm.value.email;
    this.showSpinner = true;
    this.targetElemSignupFirst.classList.toggle('button--loading');

    let body = {
      'EmailId': this.signUpForm.value.email,
      "Wslink": arr[2],
      "FullName": this.signUpForm.value.name,
    };
    this.alreadyRegistered = false;
    this.notAuthorized = false;
    this.maxCountReached = false;
    this.signUpService.signUpEmail(body).subscribe((response: any) => {
      this.emailId = response.data.emailId;
      this.vcid = response.data.vcid;
      this.wsoid = response.data.wsoid
      this.cd.detectChanges();
      this.fullName = response.data.fullName;
      if (response.statusCode == 'C200') {
        this.eventPointer3 = true;
        this.showSpinner = false;
        this.currentPage = 2;
        setTimeout(() => {
          this.rows._results[0].nativeElement.focus();
        }, 0);
        this.stepper.next();
        this.startSession();
        this.resendTimeout();
        this.targetElemSignupFirst.classList.toggle('button--loading');
        this.cd.detectChanges();

      } else if (response.statusCode == 'C201') {
        this.alreadyRegistered = true;
        this.eventPointer3 = false;
        this.showSpinner = false;
        this.targetElemSignupFirst.classList.toggle('button--loading');
        this.cd.detectChanges();

      } else if (response.statusCode == 'C401') {
        this.eventPointer3 = false;
        this.notAuthorized = true;
        this.showSpinner = false;
        this.targetElemSignupFirst.classList.toggle('button--loading');
        this.cd.detectChanges();

      } else if (response.statusCode == 'C203') {
        this.signUp1  =true;
        this.showSignupPage =false;
        this.eventPointer3 = false;
        this.maxCountReached = true;
        this.eventPointer3 = false
        this.notifysignupmaxlimit();
        this.showSpinner = false;
        this.targetElemSignupFirst.classList.toggle('button--loading');
        this.cd.detectChanges();
        return;
      }
    })
  }
  /**
* on subimit first form of signup ends here
*/
  usingenter;
  @ViewChild("signininputarrow") signininputarrow: ElementRef;
  @ViewChild("signininputpassword") signininputpassword: ElementRef;
  /**
  * fuction for email remove started here
  */
  removeemail(value, event) {
    this.changeEmailSignin = false;
    this.unauthorizedError = false;
    this.notRegisteredError = false;
    this.accountDisabledError = false;
    this.passChangeError = false;
    this.passwordnotblank = false;
    this.passcharacter = false;
    this.disableworkspace = false;
    this.isSignInFailed = false;
    this.containNonenglishSpecialCharacterEmailsignin = false;
    this.emailblank = '';
    if (value == 'email') {
      if (event.charCode == '13') {
        this.usingenter = 'enteremail';
        setTimeout(() => {
          this.signinInputdata.nativeElement.blur();
          this.signininputarrow.nativeElement.blur();
          this.signininputpassword.nativeElement.focus();
        }, 500);
        this.signinInputdata.nativeElement.blur();
        this.signininputarrow.nativeElement.blur();
        this.signininputpassword.nativeElement.focus();
      }
    }
    if (value == 'arrow') {
      if (event.charCode == '13') {
        this.Submitsigninonlyemail('arrowclick')
        setTimeout(() => {
          this.signinInputdata.nativeElement.blur();
          this.signininputarrow.nativeElement.blur();
          this.signininputpassword.nativeElement.focus();
        }, 500);
        this.signinInputdata.nativeElement.blur();
        this.signininputarrow.nativeElement.blur();
        this.signininputpassword.nativeElement.focus();
      }
    }
    if (value == 'Next') {
      if (event.charCode == '13') {
        this.Submitsignin()
      }
    }
  }
  /**
  * fuction for email remove ends here
  */
  @ViewChild("signininputnext") signininputnext: ElementRef;
  /**
 * fuction for password remove started here
 */
  removepassword(value, event) {
    this.passwordnotblank = false;
    this.passcharacter = false;
    this.isSignInFailed = false;
    this.disableworkspace = false;
    this.eventPointer = false;
    if (event.keyCode == 32) {
      event.preventDefault();
    }
    if (value == 'password') {
      if (event.charCode == '13') {
        this.usingenter = 'password';
        this.signininputnext.nativeElement.focus();
        this.Submitsignin();
      }
    }
  }
  passwordIconColor = false;
  blueIconFunction() {
    this.passwordIconColor = !this.passwordIconColor
  }
  /**
   * fuction for password remove end here
   */
  /**
  * fuction for change password sign_in page started here
  */
  changepasswordsignin(event) {
    event.keyCode != 13 ? this.passcharacter = false : '';
    event.keyCode != 13 ? this.isSignInFailed = false : '';
    event.keyCode != 13 ? this.passwordnotblank = false : '';
    this.eventPointer = false;
  }
  /**
   * fuction for change password sign_in page end here
   */
  /**
  * fuction for email focus in sign-in page started here
  */

  changeButtonStatesignin(value, event) {

    this.unauthorizedError = false;
    this.notRegisteredError = false;
    this.accountDisabledError = false;
    this.passChangeError = false;
    this.disableworkspace = false;
    if (value == 'email') {

      if (this.emailischk !== this.signinForm.value.email) {
        if (!this.passwordboxhidden) {
          this.passwordboxhidden = true;

        }
      }

    }
  }
  /**
* fuction for change-password to sign-in page ends here
*/
  datasign(value, event) {
    if (value == 'email') {

      if (this.signinForm) {

        if (this.signinForm.value.email) {
          if (this.signinForm.invalid) {
            this.changeEmailSignin = true;
          }
          else {
            this.changeEmailSignin = false;
          }
        }
      }
      if (event.charCode == 0) {
        this.changeEmailSignin = false;
      }


    }
  }
  /**
    * fuction for toggle sign-in  started from here
    */
  signintogglechange() {
    document.querySelector('.cont').classList.toggle('s--signup');
    this.toggleIntoSignUp();
    this.signUpForm.patchValue({
      email: this.signinForm.value.email
    })
    this.signinForm.reset();
    this.changeEmailSignin = false;
    this.unauthorizedError = false;
    this.notRegisteredError = false;
    this.accountDisabledError = false;
    this.passChangeError = false;
    this.passwordnotblank = false;
    this.passcharacter = false;
    this.disableworkspace = false;
    this.isSignInFailed = false;
    this.passwordboxhidden = true;
  }

  /**
    * fuction for toggle sign-in  end from here
    */
  async Submitsigninnext() {
    this.getIPAddress();
    await this.Submitsignin().then();
    if (!this.signinForm?.control?.password?.valid) {
      this.eventPointer = false;
    }
    this.eventPointer = true;
  }
  loginId;
  wsId;
  domainurl;
  imageUrl;
  unauthorizedError = false;
  /**
       * this is for the sign-in
  */
  blankfield = false;
  changeEmailSignin = false;
  notRegisteredError = false;
  accountDisabledError = false;
  passChangeError = false;
  passwordboxhidden = true;
  passwordnotblank = false;
  passcharacter = false;
  isSignInFailed = false;
  disableworkspace = false;
  /**
   *  This method is called when user clicks on arrow button after putting the password while login
   *
   * This method validates the user from the databse and set localstorage and cookies
   *
   * CALLS Api </api/SignIn/Validate>
   */
  async Submitsignin(val?) {
    let targetElemsignin = document.getElementById('signin1');
    let targetElemsigninarrow = document.getElementById('signinarrow');
    this.unauthorizedError = false;
    let st = window.location.toString();
    let arr = st?.split("/");
    let body;
    if (!val) {
      if (this.usingenter == 'enteremail') {
        val = 'arrowclick'
        this.usingenter = '';
      }
    }

    if (val === 'signup') {
      body = {
        'LoginId': this.loginId,
        'Password': this.passwordForm.value.password,
        "Wslink": arr[2],
        "isEncrypt": 0,
      }
    }
    else {
      if (this.signinForm.invalid) {
        this.eventPointer = false;
        targetElemsignin.classList.toggle('button--loading');
        setTimeout(() => {
          targetElemsignin.classList.toggle('button--loading');
        }, 100);

        if (this.signinForm.value.password == '') {
          this.passwordnotblank = true;
          this.eventPointer = false;
          this.passcharacter = false;
        }
        else if (this.signinForm.value.password.length < 6) {
          this.passcharacter = true;
          this.eventPointer = false;
        }
        else {
          this.passwordnotblank = false;
          this.passcharacter = false;
          this.eventPointer = false;

        }
        return
      }
      if (this.signinForm.value.password == '') {
        targetElemsignin.classList.toggle('button--loading');
        setTimeout(() => {
          targetElemsignin.classList.toggle('button--loading');
        }, 100);

        this.passwordnotblank = true;
        this.eventPointer = false;
        this.passcharacter = false;
      }
      body = {
        'LoginId': this.signinForm.value.email,
        'Password': this.signinForm.value.password,
        "Wslink": arr[2],
        "isEncrypt": 0,
        "ipCapture": localStorage.getItem("ipAddress") ,
          "browserName": localStorage.getItem("BrowserName") + ' & Version: ' + (localStorage.getItem('BrowserVersion')),
          "operatingSystem": localStorage.getItem("OS"),
          "deviceType": localStorage.getItem("DeviceType"),
          "resolution":  window.screen.width + '*' + window.screen.height
      }
    }
    if (val == 'arrowclick') {
      targetElemsigninarrow.classList.toggle('button--loading');
    } else {
      targetElemsignin.classList.toggle('button--loading');
    }
    await this.signInService.signIn(body).toPromise().then((response: any) => {
      this.unauthorizedError = false;
      this.notRegisteredError = false;
      this.accountDisabledError = false;
      this.passChangeError = false;
      this.isSignInFailed = false;
      this.disableworkspace = false;
      this.eventPointer = true;
      /**
      *Parse response based on status code recieved
      */
      if (response.statusCode == 'C200') {
        this.passwordboxhidden = false;

        if (val != 'arrowclick') {
          this.loginId = response.request.loginId;
          this.wsId = response.data[0].wsoid;
          this.domainurl = environment.domain;
          //this.imageUrl = environment.baseUrl + response.data[0].wsIconPath;
          this.wsIconBlobGenerator();
          this.passwordboxhidden = false;
          /**
           * store value encrypted form
           * for get the value decrypt
         */
          localStorage.setItem('imageUrl', environment.baseUrl + '/' + response.data[0].wsIconPath)
          localStorage.setItem('token', response.data[0].jwtToken);
          /**
                *refresh token when sign-in
          */
          this.cookieService.delete('openwithCockpit');
          this.cookieService.set('istallyreg', response.data[0].istallyreg, 15);
          localStorage.setItem('refreshtoken', response.data[0].refreshToken);
          localStorage.setItem('noteuserfullname', response.data[0].fullName)

          localStorage.setItem('isuploadnotshowagain', response.data[0].isUplaodModule)
          localStorage.setItem('logincategory', response.data[0].category)
          localStorage.setItem('rolevalue', response.data[0].role)
          this.cookieService.set('loginguid', response.data[0].guid);
          localStorage.setItem('loginguid', response.data[0].guid)
          localStorage.setItem('CustomerID', response.data[0].customerID)

          localStorage.setItem('groupnamevalue', response.data[0].group)
          localStorage.setItem('localwsId', JSON.stringify(response.data[0].wsoid))

          localStorage.setItem('DateFormat', response.data[0].dateFormat)
          localStorage.setItem('TimeFormat', response.data[0].timeFormat)
          localStorage.setItem('TimeZone', response.data[0].timeZone)
          localStorage.setItem('WorkSpaceName', response.data[0].wsName)
          this.Sessionduration = response.data[0].sessionIdealTime;
          localStorage.setItem('sessionidle', this.Sessionduration);
          // this.cookieService.set("sessionidle", this.Sessionduration);
          localStorage.setItem('_expiredTime', JSON.stringify(Date.now()))
          localStorage.setItem('localloginId', response.request.loginId);
          let logoinIdCookie = 'localloginId' + localStorage.getItem('localwsId')
          let wsIdCookie = 'localwsId' + localStorage.getItem('localwsId')
          if(response.data[0]?.role === 'Tally With Rights' || response.data[0]?.role === 'Tally With Out Rights' || response.data[0]?.role === 'Tally Viewer' || response.data[0]?.role === 'Tally Viewer With Rights' || response.data[0]?.role === 'Tally Viewer With Out Rights'){
             this.SignupFailed = true
             this.loginFailed=false
             localStorage.clear()
             sessionStorage.clear()

             this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
             this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
             setTimeout(() => {
              this.SignupFailed = true
              this.loginFailed=false
             }, 1000);
             localStorage.setItem('recheck','signup')
             return
          }
          if (arr[2] !== "localhost:4200") {
            /**
             * this particular condition is to check for the url containing ':' and storing the cookie services for it
             */
            if (arr[2].includes(':')){
              this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId);
              this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid));
            }else {
              this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
              this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid), { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
            }
          }
          else {
            this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId);
            this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid));

          }


          /**https://localhost:4200/onboarding/sign-in
              *set expired time
          */
          //** this is commented to tackle the signin delay issue on 11-Nov-22 by Rohan */
          // if(localStorage.getItem('urlredirect')){
          let url = localStorage.getItem('urlredirect')?.split('/')[2]
          // if (url != "localhost:4200") {
          //   if (url?.includes(':')) {
          //     localStorage.removeItem('urlredirect')
          //   }
          // }
          if (localStorage.getItem('urlredirect')) {
            this.geturlval = localStorage.getItem('urlredirect')
            if (this.geturlval) {
              let searchdata = this.geturlval.search('localhost:4200');
              let chekrl = this.geturlval.search(this.domainurl);
              if (searchdata != -1) {
                this.localsearch = this.geturlval?.split('localhost:4200');
              }
              if (chekrl != -1) {
                this.localsearch = this.geturlval?.split(this.domainurl);
              }
              if (this.geturlval) {
                if (this.localsearch[1] != '/onboarding/sign-in' && this.localsearch[1] != '/onboarding/sign-up') {
                  let searquerydata1 = localStorage.getItem('param1');
                  let searquerydata2 = localStorage.getItem('param2');
                  if (searquerydata1 && searquerydata2) {
                    let urlidrelated = this.localsearch[1]?.split('?');
                    this.router.navigate([urlidrelated[0]], { queryParams: { docId: searquerydata1, docGuid: searquerydata2 } });
                  }
                  else {
                    if (this.localsearch[1] != '/onboarding/sign-out' && this.localsearch[1] != '/onboarding/disabled') {
                      this.router.navigate([this.localsearch[1]]);
                    }
                    else {
                      localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard();

                    }
                  }
                }
                else {
                  if(localStorage.getItem('enteredURL')?.includes('UID')|| localStorage.getItem('enteredURL')?.includes('NEW')){
                    window.location.href = localStorage.getItem('enteredURL')
                //  let responseURL = localStorage.getItem('enteredURL').split('/pages/documents/dockets');
                //  let goToURL = '/pages/documents/dockets' + responseURL[1];
                //  this.router.navigate([goToURL])
               }
                 else if(localStorage.getItem('enteredURL')?.includes('dockets')){
                    this.router.navigate(['/pages/documents/dockets']);
                  }
                  else if(localStorage.getItem('enteredURL')?.includes('/pages/settings/billing-subscriptions')){
                    this.redirectToBillingDashboard()
                    }
                  else if(localStorage.getItem('enteredURL')?.includes('/sign-in/billing')){
                    this.redirectToBillingDashboard()
                    }
                  else if (localStorage.getItem('enteredURL')?.includes('/my-queue')) {
                    // window.location.href = localStorage.getItem('enteredURL')
                    this.router.navigate(['/pages/documents/my-queue']);
                  }
                  else if (localStorage.getItem('enteredURL')?.includes('/trash')) {
                    // window.location.href = localStorage.getItem('enteredURL')
                    this.router.navigate(['/pages/documents/trash']);
                  }
                  else if (localStorage.getItem('enteredURL')?.includes('/owned-by-me')) {
                    // window.location.href = localStorage.getItem('enteredURL')
                    this.router.navigate(['/pages/documents/owned-by-me']);
                  }
                  else if (this.domainurl?.split(':')[1] !== '') {
                    this.router.navigate(['/pages/dashboard'])
                  } else {
                    localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard();
                  }
                }
              }
              else {
                localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard();
              }
            }
            else {
              localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard();
            }
          }
          else {

            localStorage.getItem('commonerror') !== 'suspended' ? this.router.navigate(['/pages/dashboard']) : this.redirectToBillingDashboard()
          }
        }
        else {
          targetElemsigninarrow.classList.toggle('button--loading');

        }
        setTimeout(() => {
          if((window.location.href.includes('/onboarding/sign-in')) )
          window.location.reload()
        }, 2000);

      }
      else if (response.statusCode == 'C201' || response.statusCode == 'C203') {
        this.unauthorizedError = true;
        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }


      }
      else if (response.statusCode == 'C202') {
        this.disableworkspace = true;

        this.passChangeError = true;
        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }
      }
      else if (response.statusCode == 'C204' || response.statusCode == 'C207') {
        this.notRegisteredError = true;

        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }
      }
      else if (response.statusCode == 'C205') {
        this.accountDisabledError = true;

        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }
      }
      else if (response.statusCode == 'C206') {

        if (val != 'arrowclick') {
          this.isSignInFailed = true;
          targetElemsignin.classList.toggle('button--loading');
        }
        else {
          this.passwordboxhidden = false;
          targetElemsigninarrow.classList.toggle('button--loading');
        }

      }
      else if (response.statusCode == 'C400') {

        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }
      }
      else if (response.statusCode?.includes('C500')) {
        if (val != 'arrowclick' && response.data) {
          this.loginId = response.request.loginId;
          this.wsId = response.data[0].wsoid;
          this.domainurl = environment.domain;
          //this.imageUrl = environment.baseUrl + response.data[0].wsIconPath;
          this.wsIconBlobGenerator();
          this.passwordboxhidden = false;
          localStorage.setItem('imageUrl', environment.baseUrl + '/' + response.data[0].wsIconPath)
          localStorage.setItem('token', response.data[0].jwtToken);
          this.cookieService.delete('openwithCockpit');
          localStorage.setItem('refreshtoken', response.data[0].refreshToken);
          localStorage.setItem('noteuserfullname', response.data[0].fullName)

          localStorage.setItem('isuploadnotshowagain', response.data[0].isUplaodModule)
          localStorage.setItem('logincategory', response.data[0].category)
          localStorage.setItem('rolevalue', response.data[0].role)
          this.cookieService.set('loginguid', response.data[0].guid);
          localStorage.setItem('loginguid', response.data[0].guid)
          localStorage.setItem('groupnamevalue', response.data[0].group)
          localStorage.setItem('localwsId', JSON.stringify(response.data[0].wsoid))

          localStorage.setItem('DateFormat', response.data[0].dateFormat)
          localStorage.setItem('TimeFormat', response.data[0].timeFormat)
          localStorage.setItem('TimeZone', response.data[0].timeZone)
          localStorage.setItem('WorkSpaceName', response.data[0].wsName)
          this.Sessionduration = response.data[0].sessionIdealTime;
          localStorage.setItem('sessionidle', this.Sessionduration);
          this.cookieService.set("sessionidle", this.Sessionduration);

          localStorage.setItem('_expiredTime', JSON.stringify(Date.now()))
          localStorage.setItem('localloginId', response.request.loginId);

          if (arr[2] !== "localhost:4200") {
            // this particular condition is to check for the url containing ':' and storing the cookie services for it
            if (arr[2].includes(':')){
              this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId);
              this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid));
            }else {
              this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
              this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid), { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
            }
          }
          else {
            this.cookieService.set('localloginId' + this.wsId.toString(), response.request.loginId);
            this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data[0].wsoid));

          }
        }
        this.isSignInFailed = true;

        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }

        this.router.navigate(['/onboarding/suspended']);
      }
      else {
        this.notRegisteredError = false;
        this.accountDisabledError = false;

        this.passChangeError = false;

        if (val == 'arrowclick') {
          targetElemsigninarrow.classList.toggle('button--loading');
        } else {
          targetElemsignin.classList.toggle('button--loading');
        }
      }
    })
  }
  /**
   * notify about new sign up
   */
  notifysignupmaxlimit() {
    let roleinsertdata = {
      wsoid: Number(this.wsoid),
      createdby: this.emailId,
      category: "Signup",
      value: '<b>' + this.emailId + '</b>' + " " + "could not sign up for your workspace as the maximum user limit has been reached. Allow more users to sign up." + ' ' + '<a href="http://dox2udev.com/">Upgrade</a>',
      database: "",
      WsLink: ""
    }
    this.insertmessgae = "";
    this.signInService.roleinsert(roleinsertdata).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysendmaxlimit();
      }
      else {
        this.insertmessgae = '';
      }
    })
  }
  getallparseflag;
  workSpaceId;

  /**
   * code for verification code input box
   * @param event key up change event
   * @param index to chnage varification input box
   */

   /**
 * remove Special Characters
 */
   removeSpecialCharactersCode(event:KeyboardEvent) {
    const regex = new RegExp("^[0-9]*$");
    const regPattern = /^[0-9]+$/;
    let key = event.key;

    // Check if the pressed key is a valid numeric digit
    if (!regex.test(key) || !regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  /**
  * code for verification code input box
  * @param event key up change event
  * @param index to chnage varification input box
  */

  keyUpEvent(event: KeyboardEvent, index?: any) {
    this.showErrorMessage = false;
    const regex = new RegExp("^[0-9]*$");

    // Get the pressed key from the event
    let key = event.key;
    let pos = index;

    if (key === "Backspace") {
      pos = index - 1;
    } else if (regex.test(key)) {
      pos = index + 1;
    }

    if (key === "ArrowRight") {
      pos = index + 1;
    } else if (key === "ArrowLeft") {
      pos = index - 1;
    }

    if (key === "Delete") {
      let input = index + 1;
      input = 'input' + input;
      this.form.get(input).patchValue('');
    }

    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }

    this.otp = [];
    this.otp.push(...this.form?.value?.input1, ...this.form?.value?.input2, ...this.form?.value?.input3, ...this.form?.value?.input4, ...this.form?.value?.input5);

    if (this.otp.length === 5 && !["ArrowLeft", "ArrowRight"].includes(key)) {
      if (this.otp.join('').toString() === this.otpSend) {
        this.showErrorMessage = true;
        return;
      } else {
        this.onSubmitOTP();
      }
    }
  }
  removeSpecialCharacters(event) {
    var regex = new RegExp("^[a-zA-Z0-9. \s-]*$");
    var regPattern = /[\u0000-\u007A]+/g;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key) || !regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
  }


  /**
   * remove non english Characters
   */
  removeLanguage(event) {
    var space = / /
    var regPattern = /[\u0000-\u007A]+/g;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regPattern.test(key)) {
      event.preventDefault();
      return false;
    }
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  toggleIntoSignUp() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-up'])
    );
    if (url === "/onboarding/sign-up") {
      this.signinInputdata.nativeElement.focus();
    } else {
      this.signupInputdata.nativeElement.focus();
    }
    this.location.replaceState(url);
    this.titleService.setTitle("Sign up" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }
  /**
   * onEnter event
   */
  onEnter(value, event) {
    if(event.key === 'Enter'){
      if (value === 'name') {
        if (this.f.name.valid) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        } else {
          this.changeNameSignup = true;
          if (this.f.name.value !== '') {

            this.minnamesignup = true;
          }
          this.cd.detectChanges();
        }
      }
      else{
        if (this.f.email.valid) {
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          form.elements[index + 1].focus();
        } else {
          this.changeEmailSignup = true;
          if (this.f.email.value !== '') {

            this.minEmailSignUp = true;
          }
          this.cd.detectChanges();
        }
      }
    }


  }


  datachange() {
    this.changeNameSignup = false;
    this.minnamesignup = false;
    this.minEmailSignUp = false;
  }
  abc() {

  }
  /**
 * 1200 signin toggle
 */
  onContidinalSignUp() {
    this.conditionalSignIn = true;
    this.conditionalSignUp = false;
    this.cd.detectChanges();

  }
  /**
   * 1200 signup toggle
   */
  onContidinalSignIn() {
    window.location.reload();
    this.conditionalSignUp = true;
    this.conditionalSignIn = false;
    this.cd.detectChanges();
  }


  /**
   * notification toater for sign up
   */
  notifysendmaxlimit() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let notifysenddata = {
      wsoid: Number(this.wsoid),
      key: null,
      userEmailID: this.ownerEmailId,
      role: null,
      groupName: null,
      message: "Upgrade to add members",
      databaseName: null,
      createdBy: null,
      Flag: "Toster",
      WsLink: ""
    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysenddata).subscribe((response: any) => {
      if (response.isSuccess) {
        this.sendmessage = response.data;
      }
      else {
        this.sendmessage = '';
      }

    })
  }
  /**
   * resend button timed out
   */
  resendTimeout() {
    this.counter = 15;

    const interval = setInterval(() => {
      this.counter--;

      if (this.counter === 0) {
        clearInterval(interval);

        this.disableResendButton = false;
        this.cd.detectChanges();
      }
    }, 1000);
    this.cd.detectChanges();
  }

  /**
   * ws status api
   */
  workstatus: any;
  digitization;
  workspacestatus() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let postData: any
    postData = {
      "Wslink": arr[2],
      "emailId": this.signinForm.value.email,
    }
    this.signInService.wsStatus(postData).subscribe((response: any) => {
      if (response) {
        this.format = (response.data[0].format)
        localStorage.setItem('FormatAvailable', this.format)
        this.sharedService.wsStatusOneColumn.next(response);
        this.wsName = response.data[0].wsname;
        this.wsStatus = response.data[0].wsStatus;
        this.ownerEmailId = response?.data[0]?.emailID;
        this.workspaceID = response?.data[0]?.wsoid;
        localStorage.setItem('ownerEmailId', this.ownerEmailId)
        localStorage.setItem('WsdisplayName', response.data[0].displayName);
        localStorage.setItem('rolevalue', response.data[0].roleName);
        localStorage.setItem('WorkspaceImg', response.data[0].iconPath);
        this.iconImage = response.data[0].iconPath;
        /**
          * store value encrypted form
          * for get the value decrypt
          */
        localStorage.setItem('WorkSpaceName', response.data[0].wsname)
        localStorage.setItem('maxFileSizeUpload', response.data[0].format?.split(':')[2])
        this.WorkSpaceName = localStorage.getItem('WorkSpaceName')
        let WsdisplayName = localStorage.getItem('WsdisplayName') ? localStorage.getItem('WsdisplayName') : '';
        /**
         * sign In Null title issue
         */

        if (this.href === "/onboarding/sign-in" && this.WorkSpaceName !== null) {
          this.titleService.setTitle("Sign In" + this.middot + this.WorkSpaceName + this.middot + "dox2U");

        }
        else if (this.href === "/onboarding/sign-up" && this.WorkSpaceName !== null) {
          this.titleService.setTitle("Sign Up" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
        }
        else if (this.href === "/pages/documents/trash" && this.WorkSpaceName !== null) {
          this.titleService.setTitle("Trash"+ this.middot + WsdisplayName +  this.middot + this.WorkSpaceName+ this.middot +"dox2U");
        }
        
         /**
   * Method to update ws icon
   */
         this.wsIconBlobGenerator();


        this.cd.detectChanges();
        /**
        title for signup and sign in on the basis of URL
        */
        let getstdata = window.location.toString();
        let getarrdata = getstdata?.split('/');
        if (getarrdata[4] == 'sign-in') {
          this.titleService.setTitle("Sign In" + this.middot + this.wsName + this.middot + "dox2U");
          this.cd.detectChanges();
        }
        else {
          return;
        }
        this.wsoid = response.data[0].wsoid;
        this.cd.detectChanges();
        localStorage.setItem('wsCreated', response.data[0].createdDtm)
        if (response.data[0].wsStatus === "Active") {

          this.sharedService.isDigiBlueBar.next(response.data[0].isDigi);
          localStorage.setItem('DigiActive', response.data[0].isDigi)
          localStorage.setItem('AllwsFlag', response.data[0].isDigiUser)
        }
        if (response.data[0].wsStatus === "Suspended") {
          this.router.navigate(['/onboarding/sign-in']);
        }
        if (response.data[0].wsStatus === "Disabled") {

          localStorage.setItem('WSstatusDisabled', "true")
          this.digitization = "0";

        }
      }

    })
  }

  /**
   * Method to update ws icon
   */
  wsIconBlobGenerator() {
    console.log("workspaceIDPP", this.workspaceID)
    if (!this.iconImage.includes('https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D')) {
    let postDataForIcon = {
      "wsoid": Number(this.workspaceID),
      "fileName": Number(this.workspaceID) + '.png',
      "folderName": "profileimage"
      
    }
    this.signInService.updateWSIcon(postDataForIcon).subscribe((response: any) => {

      if (response.body instanceof Blob && response.body.size > 0) {
        const blob = new Blob([response.body], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        this.iconPath = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      } else {
    
        this.iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
        this.imageUrl = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
      }
      this.cd.detectChanges();
      localStorage.setItem('iconPath', this.iconPath)
    })
  } else {
        this.iconPath = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
        this.imageUrl = "https://dox2uprod.blob.core.windows.net/websitedocs/DefaultWSIcon.png?sp=r&st=2024-05-01T09:37:15Z&se=2060-05-01T17:37:15Z&spr=https&sv=2022-11-02&sr=b&sig=l2Ebi3qU7Pas6krJ1ekSchoD0e4fBJVbxyQU5odQbOI%3D";
        this.cd.detectChanges();
        localStorage.setItem('iconPath', this.iconPath)
  }
  }

    public redirectToBillingDashboard() {
    this.workSpaceId = localStorage.getItem('localwsId')
    this.href = this.router.url;
    var getDomainName = ".";

    var splitDot = new Array();
    let st = window.location.toString();
    let arr = st?.split("/");
    var urls = window.location.toString();
    var splitBackslash = urls?.split("/")[2];
    if (splitBackslash === "localhost:4200") {
      this.workspaceDomain = ".localhost:4200";
    }
    else {
      this.login = this.cookieService.get('localloginId' + this.workSpaceId?.toString())
      this.wsid = this.cookieService.get('localwsId' + this.workSpaceId?.toString())
      splitDot = splitBackslash?.split(".");
      for (let i = 1; i < splitDot.length; i++) {

        if (i == 1)
          getDomainName = getDomainName + splitDot[i];
        if (i == 2)
          getDomainName = getDomainName + "." + splitDot[i];
      }
      this.workspaceDomain = getDomainName;
      this.wsSubDomain = urls?.split("/")[2];
    }
    this.wsSubDomain = urls?.split("/")[2];
    this.cookieService.set('emailId', this?.signinForm?.value?.email || this.createdBy, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('isBilling', 'true', { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
    this.cookieService.set('wsURL', arr[2], { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });

    const url = this.router.serializeUrl(this.router.createUrlTree(['payments' + this.workspaceDomain + '/manage-billing/dashboard/'], {
      queryParams: {
        wsId: this.wsId || this.workSpaceId,
        loginId: this?.signinForm?.value?.email || this.createdBy,
        token: localStorage.getItem('token')
      }
    }))
    if (localStorage.getItem("userRole") == "Owner") {
      window.open(environment.httpUrl + url.substring(1), '_self');
    }
    else {
      this.router.navigate(['/onboarding/dashboard']);
    }
  }
  /**
   * sign- in submit
   */

  /**
   * change button state name
   */
  changeButtonStateName(event) {

    event.keyCode != 13 ? this.changeNameSignup = false : '';
    event.keyCode != 13 ? this.minnamesignup = false : '';
    event.keyCode != 13 ? this.minEmailSignUp = false : '';
    this.containNonenglishSpecialCharacterName = false;
    if (event.keyCode == 13) {

      var regex = new RegExp("^[a-zA-Z0-9. \s-]*$");
      var regPattern = /[\u0000-\u007A]+/g;
      if (!regex.test(this.f.name.value.toString()) || !regPattern.test(this.f.name.value.toString())) {
        this.containNonenglishSpecialCharacterName = true;
        this.cd.detectChanges();
      }
    }
  }

  /**
 * change button state email
 */
  changeButtonStateEmail(event) {
    event.keyCode != 13 ? this.changeEmailSignup = false : '';
    event.keyCode != 13 ? this.alreadyRegistered = false : '';
    event.keyCode != 13 ? this.notAuthorized = false : '';
    this.containNonenglishSpecialCharacterEmail = false;
    var regPattern = /[\u0000-\u007A]+/g;
    if (!regPattern.test(this.f.email.value.toString())) {
      this.containNonenglishSpecialCharacterEmail = true;
      this.cd.detectChanges();
    }
  }
  /**
   * goto page 1 to change email
   */
  changeEmail() {
    this.stepper.previous();
    this.currentPage = 1;
    this.disableSignUpPage = false;
    this.cd.detectChanges();
    this.eventPointer3 = false;
  }
  @ViewChild(NbPopoverDirective) popoverData;
  /**
   * resend otp
   */
  resendnewOtp() {
    this.disableResendButton = true;
    this.counter = 15;
    let postData: any;
    postData = {
      EmailId: this.emailId,
      ReSend: "1",
      MailHeader: "Create a New Workspace",
    };
    this.signUpService.resendOTP(postData).subscribe((response: any) => {
      if (response.isSuccess) {
        this.popoverData.show();
        setTimeout(() => {
          this.popoverData.hide();
        }, 5000);
        this.cd.detectChanges();
        this.timer = true;

        this.intervalId = setInterval(() => {
          this.counter = this.counter - 1;
          if (this.counter === 0) {
            clearInterval(this.intervalId);

            this.cd.detectChanges();
            this.timer = false;
          }
        }, 1000);
        this.disableResendButton = true;
        this.cd.detectChanges();
        setTimeout(() => {
          this.disableResendButton = false;
          this.cd.detectChanges();
          this.timer = false;
        }, 15000);
      } else {
        this.timer = true;
        this.intervalId = setInterval(() => {
          this.counter = this.counter - 1;
          if (this.counter === 0) {
            clearInterval(this.intervalId);
            this.disableResendButton = true;
            this.cd.detectChanges();
            this.timer = false;
          }
        }, 1000);
        this.disableResendButton = true;
        this.cd.detectChanges();
        setTimeout(() => {
          this.disableResendButton = false;
          this.cd.detectChanges();
          this.timer = false;
        }, 15000);
      }
    });
  }
  /**
   * on submittion of verif code
   */
  showErrorMessage = false;
  errorMessage = '';
  otpBlankError = false;

  onSubmitOTP() {
    this.disableVerifyButton = true;
    this.otpBlankError = false;
    if (this.otp.length == 0) {
      this.otpBlankError = true;
      return;
    }
    let postData: any
    this.verifySpinner = true;
    postData = {
      "EmailId": this.signUpForm.value.email,
      "verificationCode": this.otp.join('').toString()
    }
    this.signUpService.verifyCode(postData).subscribe((response: any) => {
      this.showErrorMessage = false;
      this.passwordInputdata.nativeElement.focus();
      this.otpSend = postData.verificationCode

      if (response.isSuccess) {
        this.verifySpinner = false;
        this.stepper.next();
        this.containLowerCase = false;
        this.containNumber = false;
        this.containSpecialCharacter = false;
        this.containUpperCase = false;
        this.passwordLength = false
        this.passwordForm.reset();
        this.passwordForm.patchValue({
          password: ''
        })
        this.currentPage = 3;
        this.cd.detectChanges();

      } else if (response.statusCode == 'C202') {
        this.eventPointer2 = false;
        this.showErrorMessage = true;
        this.verifySpinner = false;
      } else if (response.statusCode == 'C203') {
        this.showErrorMessage = true;
        this.verifySpinner = false;
      }
      else {
        this.showErrorMessage = true;
        this.verifySpinner = false;
      }
    });

  }
  /**
   * paste copied verification code
   * @param event
   */
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text').replace(/ /g, "")?.split('');
    this.form.patchValue({
      input1: pastedText[0],
      input2: pastedText[1],
      input3: pastedText[2],
      input4: pastedText[3],
      input5: pastedText[4],
    })
  }

  /**
   * paste copied email
   * @param event
   */
  onPasteEmail(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text').replace(/ /g, "");
    const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
    // var regPattern = /[\u0000-\u007A]+/g;
    if (!pattern.test(pastedText.toString())) {
      this.containNonenglishSpecialCharacterEmailsignin = true;
      event.preventDefault();
      this.cd.detectChanges();
    }
  }

  /**
 * paste copied email
 * @param event
 */
  onPasteEmailsignin(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text').replace(/ /g, "");
    const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
    // var regPattern = /[\u0000-\u007A]+/g;
    if (!pattern.test(pastedText.toString())) {
      this.containNonenglishSpecialCharacterEmailsignin = true;
      event.preventDefault();
      this.cd.detectChanges();
    }
  }
  /**
   * paste copied name
   * @param event
   */
  onPasteName(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text').replace(/ /g, "");
    var regex = new RegExp("^[a-zA-Z0-9. \s-]*$");
    var regPattern = /[\u0000-\u007A]+/g;
    if (!regex.test(pastedText.toString()) || !regPattern.test(pastedText.toString())) {
      this.containNonenglishSpecialCharacterName = true;
      event.preventDefault();
      this.cd.detectChanges();

    }
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  /**
  *
  * @returns password input type
  */
  getInputTypesignin() {
    if (this.showpasswordsignin) {
      return 'text';
    }
    return 'password';
  }
  /**
   * toggle eye icon for password
   */
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  /**
 * toggle eye icon for password
 */
  toggleShowPasswordsignin() {
    this.showpasswordsignin = !this.showpasswordsignin;
  }
  /**
   * chnage button state for pw
   * @param e change passworkd event
   */
  changeButtonStatePassRe(e) {
    this.showPasswordError = false;
  }
  /**
   * password strength function
   * @param event
   */
  checkPasswordStrength(event) {
    this.submitted = false;
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    /[A-Z]/.test(this.f1.password.value) ? this.containUpperCase = true : this.containUpperCase = false;
    /[a-z]/.test(this.f1.password.value) ? this.containLowerCase = true : this.containLowerCase = false;
    /\d/.test(this.f1.password.value) ? this.containNumber = true : this.containNumber = false;
    format.test(this.f1.password.value) ? this.containSpecialCharacter = true : this.containSpecialCharacter = false;
    this.f1.password.valid ? this.passwordLength = true : this.passwordLength = false;
  }
  /**
   * fuction for submit password  started from here
   */
  submitOwnerProfile() {
    let targetElem = document.getElementById('next3');
    var st = window.location.toString();
    var arr = st?.split("/");
    this.submitted = true;
    this.disableWSSignup = true;
    if (!this.passwordLength || !this.containLowerCase || !this.containSpecialCharacter || !this.containNumber || !this.containUpperCase) {
      targetElem.classList.toggle('button--loading');
      setTimeout(() => {
        this.eventPointer2 = false;
        targetElem.classList.toggle('button--loading');
      }, 100);
      return
    } else {
      this.eventPointer2 = true;
      targetElem.classList.toggle('button--loading');
      let postData: any

      let url = this.router.serializeUrl(this.router.createUrlTree([environment.baseapp], {
        queryParams: {
          loginId: this.emailId
        }
      }))
      url = url?.split('?')[1]
      postData = {
        "Wsoid": this.wsoid,
        "EmailId": this.emailId,
        "FullName": this.signUpForm.value.name,
        "Password": this.passwordForm.value.password,
        "vcid": this.vcid,
        "Timezone": this.displayDate,
        "Wslink": arr[2],
        "queryString": '?' + url,
        "ipCapture": localStorage.getItem("ipAddress") ,
        "browserName": localStorage.getItem("BrowserName") + ' & Version: ' +  (localStorage.getItem('BrowserVersion')),
        "operatingSystem": localStorage.getItem("OS"),
        "deviceType": localStorage.getItem("DeviceType"),
        "resolution": window.innerWidth + '*' + window.innerHeight

      }
      this.userfullname = this.signUpForm.value.name;
      this.signUpService.signUpOwner(postData).subscribe((response: any) => {
        this.signinForm.patchValue({
          password: null,
        })
        if (response.isSuccess) {
          this.wsId = response.data.wsoid;
          this.loginId = response.data.emailId;
          this.eventPointer2 = true;
          this.notifysignup();
          localStorage.setItem('localloginId', response.data.emailId)
          localStorage.setItem('localwsId', JSON.stringify(response.data.wsoid));
          this.cookieService.set('localloginId' + this.wsId.toString(), response.data.emailId, { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
          this.cookieService.set('localwsId' + this.wsId.toString(), JSON.stringify(response.data.wsoid), { domain: environment.domain, secure: environment.httpUrl === 'http://' ? false : true, sameSite: 'Lax', path: '/' });
          this.signUpEmailVerification = true;
          this.Submitsignin('signup');
          this.cd.detectChanges();

        }
        else {
          this.roundSpinner = false;
          targetElem.classList.toggle('button--loading');
        }
      })
    }
  }
  /**
  * fuction for submit password  end from here
  */

  /**
   * browser prompt for unsaved changes
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if ((!this.signUpForm.controls.name.pristine || !this.signUpForm.controls.email.pristine) && !this.signUpEmailVerification && !this.signUp1) {
      $event.returnValue = true;
    }
  }
  @HostListener('window:popstate', ['$event'])
  popstate($event: any) {
    window.location.reload();
    if ((!this.signUpForm.controls.name.pristine || !this.signUpForm.controls.email.pristine) && !this.signUpEmailVerification) {
      $event.returnValue = true;
    }
  }
  /**
   * notify signup send
   */
  notifysignup() {
    let roleinsertdata = {
      wsoid: Number(this.wsoid),
      createdby: this.emailId,
      category: "Signup",
      value: "",
      database: "",
      WsLink: ""
    }
    this.insertmessgae = "";
    this.signInService.roleinsert(roleinsertdata).subscribe((response: any) => {
      if (response.isSuccess) {
        this.insertmessgae = response.data;
        this.notifysend();
      }
      else {
        this.insertmessgae = '';
      }
    })
  }
  /**
   * toaster send on signup
   */
  notifysend() {
    var st = window.location.toString();
    var arr = st?.split("/");
    let notifysenddata = {
      wsoid: Number(this.wsoid),
      key: null,
      userEmailID: this.ownerEmailId,
      role: null,
      groupName: null,
      message: "New User Sign Up",
      databaseName: null,
      createdBy: null,
      Flag: "Toster",
      WsLink: ""
    }
    this.sendmessage = '';
    this.signInService.notifysend(notifysenddata).subscribe((response: any) => {
      if (!response.isSuccess) {
        this.sendmessage = response.data;
        setTimeout(() => {
          window.location.reload()
        }, 1500);
      }
      else {
        this.sendmessage = '';
      }
    })
  }
  /**
 * capture mouse event
*/
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  /**
   * method for mouseenter
  */
  mouseenter() {
    let getmouseenter = JSON.parse(sessionStorage.getItem('mintuesSignup') || '{}');
    if (this.sessionidle) {
      if (getmouseenter >= this.sessionidle) {
        if (this.currentPage != 1 && this.href === '/onboarding/sign-up') {
          sessionStorage.clear();
          this.openModal(this.sessionmanagement, false);
        }
      } else {
        sessionStorage.setItem("_expiredTimeSignUp", JSON.stringify(Date.now()));
      }
    }
  }
  /**
   session management code for checking idle inactive time start here
    */
  startSession() {
    sessionStorage.setItem("_expiredTimeSignUp", JSON.stringify(Date.now()));

    let stdata = window.location.toString();
    let arrdata = stdata?.split('/');
    var splitURLdata = stdata?.split('/')[4];
    setInterval(() => {
      this.sessionidle = JSON.parse('15');
      this.enddate = JSON.parse(sessionStorage.getItem('_expiredTimeSignUp') || '');

      this.startDate = Date.now();
      const diff = (this.startDate - this.enddate)
      this.minutes = Math.floor(diff / 60000);
      this.minlocaldata = sessionStorage.setItem('mintuesSignup', this.minutes);
      this.changesdata = this.minutes;
      if (this.sessionidle) {
        if (this.changesdata >= this.sessionidle) {
          stdata = window.location.toString();
          arrdata = stdata?.split('/');
          splitURLdata = stdata?.split('/')[4];
          if (arrdata[4].trim() === 'sign-up' && this.currentPage != 1) {
            sessionStorage.clear();

            this.openModal(this.sessionmanagement, false);
          }
        }
      }

    }, 15000);
  }
  /**
  * session timeout start again
  */
  startAgainafterTimeout() {
    this.dialogRef.close();
    this.stepper.reset();
    this.changeNameSignup = false;
    this.minnamesignup = false;
    this.minEmailSignUp = false;
    this.changeEmailSignup = false;
    this.containNonenglishSpecialCharacterName = false;
    this.containNonenglishSpecialCharacterEmail = false;
    this.currentPage = 1;
    this.eventPointer = false;
    this.eventPointer3 = false;
  }
  /**
 * unsave changes on stay
 */
  unsavedStay() {
    this.dialogRef.close();
    this.eventPointer3 = false;
  }
  /**
   * unsave changes on leave
   */
  unsavedLeave() {
    this.dialogRef.close();
    this.stepper.reset();
    this.signUpForm.reset();
    this.changeNameSignup = false;
    this.eventPointer3 = false;
    this.minnamesignup = false;
    this.minEmailSignUp = false;
    this.changeEmailSignup = false;
    this.containNonenglishSpecialCharacterName = false;
    this.containNonenglishSpecialCharacterEmail = false
    this.currentPage = 1;
    document.querySelector('.cont').classList.toggle('s--signup');
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-in'])
    );
    this.location.replaceState(url);
    this.titleService.setTitle("Sign In" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }

  /**
   * unsave changes on leave for Sign-in
   */
  unsavedLeavesignin() {

    this.signinForm.reset();
    this.changeEmailSignin = false;
    this.unauthorizedError = false;
    this.notRegisteredError = false;
    this.accountDisabledError = false;
    this.passChangeError = false;
    this.passwordnotblank = false;
    this.passcharacter = false;
    this.disableworkspace = false;
    this.eventPointer3 = false;
    this.isSignInFailed = false;
    this.passwordboxhidden = true;
    document.querySelector('.cont').classList.toggle('s--signup');
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['onboarding/sign-up'])
    );
    this.location.replaceState(url);
    this.titleService.setTitle("Sign Up" + this.middot + this.WorkSpaceName + this.middot + "dox2U");
  }


  /**
 * only email validation check
 */
  emailblank = '';
  passwordBlank: boolean = false;
  emailischk = '';
  Submitsigninonlyemail(val?) {
    this.getIPAddress();
    if (this.usingenter !== 'password') {
      this.usingenter = '';

      let targetElemsigninarrow = document.getElementById('signinarrow');
      this.unauthorizedError = false;
      this.emailblank = '';
      let st = window.location.toString();
      let arr = st?.split("/");

      let body;

      if (val == 'arrowclick') {

        if (this.signinForm.invalid) {
          if (this.signinForm.value.email == '') {
            this.emailblank = 'not blank';
            this.changeEmailSignin = false;
          }
          else {
            this.emailblank = '';
            this.changeEmailSignin = true;
          }

          targetElemsigninarrow.classList.toggle('button--loading');
          setTimeout(() => {
            targetElemsigninarrow.classList.toggle('button--loading');
          }, 100);
          return
        }

        body = {
          'LoginId': this.signinForm.value.email,
          'Password': '',
          "Wslink": arr[2],
          "isEncrypt": 0,
          "ipCapture": localStorage.getItem("ipAddress") ,
          "browserName": localStorage.getItem("BrowserName") + ' & Version: ' +  (localStorage.getItem('BrowserVersion')),
          "operatingSystem": localStorage.getItem("OS"),
          "deviceType": localStorage.getItem("DeviceType"),
          "resolution": window.innerWidth + '*' + window.innerHeight
        }
      }

      this.emailischk = this.signinForm.value.email;
      if (val == 'arrowclick') {
        targetElemsigninarrow.classList.toggle('button--loading');
      }
      this.signInService.signInemail(body).subscribe((response: any) => {
        this.createdBy=this.signinForm.value.email
        localStorage.setItem('localloginId',this.signinForm.value.email);
        if(response.data[0]?.role === 'Tally With Rights' || response.data[0]?.role === 'Tally With Out Rights' || response.data[0]?.role === 'Tally Viewer' || response.data[0]?.role === 'Tally Viewer With Rights' || response.data[0]?.role === 'Tally Viewer With Out Rights'){
          this.loginFailed =true
          this.SignupFailed = false
        }
        localStorage.setItem('userRole', response.data[0].category);

        this.unauthorizedError = false;
        this.notRegisteredError = false;
        this.accountDisabledError = false;
        this.passChangeError = false;
        this.isSignInFailed = false;
        this.disableworkspace = false;
        this.signinForm.patchValue({
          password: ''
        })

        if (response.statusCode == 'C200') {
          this.passwordboxhidden = false;
          setTimeout(() => {
            this.signinInputdata.nativeElement.blur();
            this.signininputarrow.nativeElement.blur();
            this.signininputpassword.nativeElement.focus();
          }, 500);
          this.cd.detectChanges();
          targetElemsigninarrow.classList.toggle('button--loading');
          localStorage.setItem('changepasemail', this.signinForm.value.email)
        }
        else if (response.statusCode == 'C201' || response.statusCode == 'C203') {
          this.unauthorizedError = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
        else if (response.statusCode == 'C202') {
          this.passChangeError = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
        else if (response.statusCode == 'C206') {
          this.unauthorizedError = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
        else if (response.statusCode == 'C204' || response.statusCode == 'C207') {
          this.notRegisteredError = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }

        }
        else if (response.statusCode == 'C205') {
          this.accountDisabledError = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }

        else if (response.statusCode == 'C400') {
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
        else if (response.statusCode == 'C500') {
          this.disableworkspaceData = response.statusCode
          this.disableworkspace = true;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
        else {
          this.notRegisteredError = false;
          this.accountDisabledError = false;
          this.passChangeError = false;
          if (val == 'arrowclick') {
            targetElemsigninarrow.classList.toggle('button--loading');
          }
        }
      })
    }
    else {
      this.usingenter = ''
    }
    this.workspacestatus();
  }

  /**
   * goto step first on click of previous
   */
  goToFirst() {
    this.currentPage = 1;
    this.containLowerCase = false;
    this.submitted = false;
    this.containNumber = false;
    this.containSpecialCharacter = false;
    this.containUpperCase = false;
    this.eventPointer3 = false;
    this.passwordLength = false
    this.passwordForm.reset();
    this.passwordForm.patchValue({
      password: ''
    });
    this.stepperIndex = 0
    this.stepper.selectedIndex = 0

  }
  /**
 * onEnter event
 */
  onEnterPassword(event) {
    if (this.passwordLength && this.containLowerCase && this.containSpecialCharacter && this.containNumber && this.containUpperCase && this.passwordForm.get('agreeCheckbox').value) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    } else {
      this.submitted = true;
      this.cd.detectChanges();
    }
  }
  /**
  * function for terms and condition url start
  */
  terms() {
    window.open('https://www.dox2u.com/legal/users-terms', "_blank");
  }
  /**
 * function for terms and condition url end
 */
  /**
* function for privacy policy url start
*/
  privacy() {
    window.open('https://dox2u.com/legal/privacy-policy', "_blank");
  }
  /**
* function for privacy policy url end
*/
  /**
 * function for forget password url end
 */
  forgot_password() {
    this.router.navigate(['/onboarding/change-password']);
  }
  /**
* function for forget password url end
*/
  /**
*   function to redirect into  change password page started
*/
  gotoChangePW() {
    localStorage.setItem('changepasemail', this.signinForm.value.email)
    this.router.navigate(['/onboarding/change-password']);
  }
  /**
  *   function to redirect into  change password page end
  */

   /*Proceed Anyways Btn Click*/
   ProceedAnywaysClick() {
   
    const myElement1 = document.getElementById("loginBox");
    myElement1.style.display = "block";
 
    const myElement2 = document.getElementById("msgBox");
    myElement2.style.display = "none";
     
   }


}

