import { Component, OnInit,Injectable, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../@core/utils/shared.service';
import { VerifyDocumentService } from '../../@core/verify-document.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ngx-doc-preview',
  templateUrl: './doc-preview.component.html',
  styleUrls: ['./doc-preview.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class DocPreviewComponent implements OnInit {
  originalnamefile: any;
  sourceFile: string =  "";
  checkRights:any;
  currentTab: string;
  isResponseTab: boolean = false;
  createdBy: any;
  userfullname: any;
  workSpaceId: any;
  docId: any;
  docGuid: any;
  neweDocRename: any;
  fullDocRename: any;
  docrename: any;
  docIsDisplayed: boolean = false;
  docUnderProcessing: boolean = true;
  docFormatNotSupported: boolean = false;
  docIsLockedOrCorrupt: boolean = false;
  showProcessing: boolean = false;
  docState = '';
  docStateStatus = '';
  isLoading = false;
  docParkingQueue: boolean= false;
  scale: number = 1;
  sliderValue: number = 1;
  maxSliderValue: number;
  iframeTransform: string = `scale(${this.scale})`;
  riser: string = "page-width";

 
  constructor(private data: VerifyDocumentService,private sharedService: SharedService,public cd: ChangeDetectorRef
    ) { }
extension:any
  ngOnInit(): void {
    this.sourceFile = '';
    this.isLoading = false;
    //this.sliderValue = this.scale;
    this.currentTab = window.location.href.toString();
    if (this.currentTab?.includes("Dockets")) {
      this.isResponseTab = true;
    } else {
      this.isResponseTab = false;
    }
   this.sharedService.docPreview.subscribe((response:any)=>{
    console.log("response",response)
    this.riser = "page-width";
    this.docId= response[0]?.docId
    this.docGuid=response[0]?.docGuid
    this.docrename = response[0]?.docRename
    this.neweDocRename=response[0]?.neweDocRename
    this.fullDocRename=response[0]?.originalFileName
    if(this.currentTab?.includes("my-queue")){
      this.docState = response[0]?.parseStatus; 
        this.docrename = this.fullDocRename.split('.')[0];
    }
    else{
      this.docState = response[0].parserstatuscode;
      this.docrename = response[0]?.docRename
    }
      this.docUnderProcessing = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
      this.showProcessing = false;
    let checkRights = localStorage.getItem('accessRights')

    if(response?.length>1 || checkRights == 'false'){
      this.close()
    }
this.extension=response[0]?.formatExtension
if(this.extension == undefined){
  
  if(this.currentTab?.includes("cabinet")) {
    this.extension=response[0]?.documentFormat.toLowerCase();
  } else {
    this.extension=response[0]?.documentFormat;
  }
}

if (this.currentTab?.includes("cabinet")) {
  let splitConvertedPath1 = response[0]?.uploadPath?.split('\\').length;
  let onceUploaded1 = splitConvertedPath1 === 3 ? response[0]?.uploadPath?.split('\\')[2] : response[0]?.uploadPath?.split('\\')[3];
  this.originalnamefile = onceUploaded1.split(".")[0] + '.pdf'
  console.log("original path name1111111",this.originalnamefile);
  this.check1(this.originalnamefile)
} else {
  let splitConvertedPath = response[0]?.thumbnailPath?.split('\\').length;
  let onceUploaded = splitConvertedPath === 3 ? response[0]?.thumbnailPath?.split('\\')[2] : response[0]?.thumbnailPath?.split('\\')[3];
  this.originalnamefile = onceUploaded.split(".")[0] + '.pdf'
  console.log("original path name222222",this.originalnamefile);
  this.check1(this.originalnamefile)
}
   })

  }
  check1(fileName){
    let docFormat =this.extension
    let postData ={}
    if(fileName){
       postData = {
        "wsoid": Number(localStorage.getItem('localwsId')),
        "fileName": this.originalnamefile,
        "folderName": "thumbnail"
      
    }
  }
  this.data.verificationpdfViewerFileConversion(postData).subscribe((response: any) => {
    if (this.docState == 'doc_21') {
      this.docUnderProcessing = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
      this.showProcessing = true;
    } else if (this.docState == 'doc_15' || this.docState == ''){
      this.docParkingQueue = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docUnderProcessing = false;

    } else if (this.docState == 'doc_22') {
      this.docIsLockedOrCorrupt = true;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    } else if (this.docState == 'doc_23') {
      this.docFormatNotSupported = true;
      this.docUnderProcessing = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    }else{
      this.docIsLockedOrCorrupt = false;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = true;
      this.docParkingQueue = false;
    }
    this.riser = 'page-width';

      this.sourceFile = response;
      console.log("sourcefile11", this.sourceFile)
      this.sharedService.base64string.next(this.sourceFile);
      this.cd.detectChanges();
  
})
  } 

  check2(fileName){
    let docFormat =this.extension
    let postData ={}
    if(fileName){
       postData = {
        "wsoid": Number(localStorage.getItem('localwsId')),
        "fileName": this.originalnamefile,
        "folderName": "thumbnail"
      
    }
  }
  this.data.verificationpdfViewerFileConversion(postData).subscribe((response: any) => {
    if (this.docState == 'doc_21') {
      this.docUnderProcessing = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
      this.showProcessing = true;
    } else if (this.docState == 'doc_15' || this.docState == ''){
      this.docParkingQueue = true;
      this.docFormatNotSupported = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docUnderProcessing = false;

    } else if (this.docState == 'doc_22') {
      this.docIsLockedOrCorrupt = true;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    } else if (this.docState == 'doc_23') {
      this.docFormatNotSupported = true;
      this.docUnderProcessing = false;
      this.docIsLockedOrCorrupt = false;
      this.docIsDisplayed = false;
      this.docParkingQueue = false;
    }else{
      this.docIsLockedOrCorrupt = false;
      this.docFormatNotSupported = false;
      this.docUnderProcessing = false;
      this.docIsDisplayed = true;
      this.docParkingQueue = false;
    }

    this.riser = 'page-width';

      this.sourceFile = response;
      console.log("sourcefile11", this.sourceFile)
      this.sharedService.base64string.next(this.sourceFile);
      this.cd.detectChanges();
  
})
  } 

  check(fileName){
    let docFormat =this.extension
    let postData ={}
    if(fileName){
       postData = {
        "wsoid": Number(localStorage.getItem('localwsId')),
        "fileName": this.originalnamefile,
        "folderName": "thumbnail"
      
    }
  }
  this.data.verificationpdfViewerFileConversion(postData).subscribe((response: any) => {
    this.riser = 'page-width';

      this.sourceFile = response;
      console.log("sourcefile11", this.sourceFile)
      this.sharedService.base64string.next(this.sourceFile);
      this.cd.detectChanges();
  
})
  } 

  close(){
    this.sharedService.isClose.next(true);
    localStorage.setItem('Invokepreview','false')
  }
  share(response:any){
    console.log("response 123", response);
    this.riser = "page-width";
    this.docUnderProcessing = true;
        this.docFormatNotSupported = false;
        this.docIsLockedOrCorrupt = false;
        this.docIsDisplayed = false;
        this.docParkingQueue = false;
    this.docrename = response[0]?.docrename;
    // this.docState = '';
    this.showProcessing = false;
    this.docId= response[0]?.docId
    this.docGuid=response[0]?.docGuid
    this.neweDocRename=response[0]?.neweDocRename
    this.fullDocRename=response[0]?.originalFileName
    this.docState = response[0]?.parserstatuscode;
    let checkRights = localStorage.getItem('accessRights')
    if(response.length>1 || checkRights == 'false' ){
      this.close()
    }
    console.log(response)
    this.extension=response[0]?.documentFormat
    if(this.extension == undefined){
  this.extension=response[0]?.format
}
  let splitConvertedPath = response[0]?.convertedpath?.split('\\').length;
  this.originalnamefile = splitConvertedPath === 3 ? response[0]?.convertedpath?.split('\\')[2] : response[0]?.convertedpath?.split('\\')[3];
  console.log(this.originalnamefile)
  this.check2(this.originalnamefile)


  }
  dockets(convertedFile:any,extension,name,docId?,docGuid?){
    this.docId= docId
    this.docGuid=docGuid
    this.fullDocRename=name
     if(convertedFile?.includes('\\')){
      console.log(convertedFile,extension)
      let splitConvertedPath1 = convertedFile?.split('\\').length;
      this.originalnamefile = splitConvertedPath1 === 3 ? convertedFile?.split('\\')[2] : convertedFile?.split('\\')[3];
    let splitConvertedPath = name?.split('.')
    let length = name?.split('.').length
    this.extension=splitConvertedPath[length-1]
    this.neweDocRename=splitConvertedPath[length-2]
    console.log("originalnamefile", this.originalnamefile);
    this.check(this.originalnamefile)

    }
    else if(convertedFile){
    let splitConvertedPath = convertedFile?.split('.')
    let length = convertedFile?.split('.').length
    this.extension=splitConvertedPath[length-1]
    let file= convertedFile?.replace(splitConvertedPath[length-1],'pdf')
    console.log("originalnamefile2", file);
    this.originalnamefile = file;
    this.check(this.originalnamefile)
    }


  }
  updateDocAuditLog(neweDocRename?,docGuid?) {
    if(localStorage.getItem('Invokepreview')=='true'){
      this.riser = 'page-fit';
      console.log("riser", this.riser)
    this.workSpaceId = localStorage.getItem('localwsId')
    this.userfullname = localStorage.getItem('noteuserfullname')
    this.createdBy = localStorage.getItem('localloginId')
    let currentIp = localStorage.getItem("ipAddress");
    let postData = {
      "wsoid": Number(this.workSpaceId),
      "category": "Document",
      "activity": "Document Activity",
      "logMessage": `<b>"${this.fullDocRename}"</b>` + " "+ "Previewed By" + " " + `<b>${this.userfullname}</b>`,
      "createdBy": this.createdBy,
      "docId": Number(this.docId),
      "database": "get2doxMaster",
      // "serialNo" : serial,
      "docName":  this.neweDocRename,
      "userName": this.userfullname,
      "guid" : this.docGuid,
      "IpAddress": currentIp,
      "IsIntegrationViewer": 0
    }
    this.data.viewDocsAuditLog(postData).subscribe((response: any) => {
      this.riser = 'page-width';
      this.cd.detectChanges()
    })
  }
}

ngOnDestroy() {
  this.docState = '';
  this.sourceFile = "";
  this.docUnderProcessing = true;
  this.docFormatNotSupported = false;
  this.docIsLockedOrCorrupt = false;
  this.docIsDisplayed = false;
  this.docParkingQueue = false;
  this.showProcessing = false;
}
}
