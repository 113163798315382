import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { environment } from '../.../../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MiniProfileService {

  constructor(private apiService:ApiServiceService, private http: HttpClient) { }

  /**
 * method  for elastic base url
 */
  public elasticdbaseurl = environment.elasticdbaseurl;

   // ****************** detail start here *******************
   getminiprofile(data) {
    return this.apiService.post('/api/Common/UserMiniProfile', data);
  }

     /**
 * method  for getting user image in profile
 */
     pdfViewerFileConversion(data) {
      return this.http.post(this.elasticdbaseurl + '/api/OwnedByMe/DownloadFileStreamFromBlob', data, {responseType:'blob',observe: 'response'}).pipe(
        retry(0)
      );
    }




}
