import { Injectable } from '@angular/core';
import { ApiServiceService } from '../@core/api-service.service';
   /**
    *injecting provide in
    */
@Injectable({
  providedIn: 'root'
})
   /**
    * exporting class
    */
export class SignUpService {
   /**
    * initialize constructor
    */
  constructor(private apiService:ApiServiceService) { }
   /**
    * signUpEmail
    */
  signUpEmail(data) {
   return this.apiService.post('/api/WstMember/Validate', data);
  }
   /**
    * notifylogout
    */
  notifylogout(data) {
    return this.apiService.post('/api/NotificationKeyInsert/NotificationKeylogout', data);
   }
   /**
    * verifyCode
    */
  verifyCode(data) {
    return this.apiService.post('/api/EmailVerification/Validate', data);
  }
     /**
    * resendOTP
    */
  resendOTP(data) {
    return this.apiService.post('/api/EmailVerification/Send', data);
  }
     /**
    * signUpOwner
    */
  signUpOwner(data) {
    return this.apiService.post('/api/WstMember/Save', data);
  }
   /**
    *signOutService
    */
  signOutService(data) {
    return this.apiService.post('/api/Workspace/SignOut', data);
  }
   /**
    * getallnotification
    */
  getallnotification(data) {
    return this.apiService.post('/api/GetNotificationUser/GetNotificationUser', data);
  }
     /**
    * readmessage
    */
  readmessage(data) {
    return this.apiService.post('/api/GetNotificationUser/NotificationIsRead', data);
  }
   /**
    * onebyonedelete
    */
  onebyonedelete(data) {
    return this.apiService.post('/api/GetNotificationUser/NotificationDelete', data);
  }
}
