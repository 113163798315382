import { SharedService } from './../../../../@core/utils/shared.service';
import { MENU_ITEMS } from './../../../../pages/pages-menu';
import { Component, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import {  NbMenuService } from "@nebular/theme";
import { environment } from '../../../../../environments/environment';
import { HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSettingService } from '../../../../@core/utils/user-setting.service';
/**
 * component for Help and support
 */
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],

})
/**
 * export for Help and support
 */
export class SupportComponent implements OnInit {
    /**
 * variable for createdBy
 */
  createdBy: any;
    /**
 * variable for createdName
 */
  createdName: any;
    /**
 * variable for Full_name
 */
  Full_name:any;
    /**
 * variable for menu
 */
  public menu = MENU_ITEMS;
    /**
 * variable for featureMenu
 */
  featureMenu:any[];
    /**
 * variable for showSupport
 */
  showSupport: boolean;
    /**
 * variable for workSpaceId
 */
  workSpaceId: any;
  /**
 * variable for wsName
 */
  wsName: any;
/**
 * variable for support image Url
 */
  supportimageUrl: any;
/**
 * variable for menu2 object
 */
  menu2 = [{
    title: 'Docs in Upload'
  }, {
    title: 'Owned by Me'
  }, {
    title: 'Shared with Me'
  }, {
    title: 'WS Analytics'
  }, {
    title: 'Trash'
  }, {
    title: 'Upload'
  }, {
    title: 'View / Edit Document'
  }, {
    title: 'Document Verification'
  },
  {
    title: 'Delete Document'
  },
  {
    title: 'Download / Print Document'
  },
  {
    title: 'Share Document'
  },
  {
    title: 'Guest Sharing'
  }

  ]
/**
 * reference variable for help and support modal
 */
  @ViewChild('helpModal') helpModal: TemplateRef<any>; // Note: TemplateRef
  /**
 * variable for files
 */
  files: any;
  /**
 * variable for file ExtError
 */
  fileExtError: boolean;
 /**
 * git ignore on Init
 */
  constructor(@Optional() private dialogRef: NbDialogRef<any>, private dialogService: NbDialogService,private formBuilder: FormBuilder, private http:HttpClient ,private sharedservice : SharedService, private sanitizer: DomSanitizer,  private menuService : NbMenuService, private data:UserSettingService,  private toastrService: NbToastrService,) { 
  
  }
 /**
 * git ignore on Init
 */
  ngOnInit(): void {
    this.Full_name = localStorage.getItem('noteuserfullname')
    this.wsName = localStorage.getItem('WorkSpaceName')
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    this.supportForm.patchValue({
      name:this.Full_name,
      email:this.createdBy
    })
 
  }
/**
 * variable for states
 */
  state = "closed";
  /**
 * method for open support modal
 */
  openSupport(){
    this.openModal(this.helpModal, false);
  }
/**
 * method for change State
 */
  changeState(): void {
    (this.state == "closed") ? this.state = "open" : this.state = "closed";
  }
  /**
 * formGroup  for support form
 */
  supportForm=new FormGroup({
    name:new FormControl(),
    email:new FormControl(),
    selectedItem:new FormControl(),
    description: new FormControl(),
      file: new FormControl(),
  })
/**
 * method for open  modal
 */
  openModal(dialogModal: TemplateRef<any>, closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
      closeOnBackdropClick: false,
      autoFocus: true,
      closeOnEsc: false
    });

  }
  /**
 * method for closing support modal
 */
  onCloseSupport(){
    this.showSupport = false;
    this.fileSizeValidator = false;
    this.supportForm.value.selectedItem =  null;
    this.supportForm.value.description =  null;
    this.supportForm.value.file =  null;
    this.supportForm.patchValue({
      name: '',
      email:'',
      selectedItem: '',
      description:''
    })
    this.files = null;
  }
  /**
 * method for submit feedback
 */
  submit(){
    this.toastrService.primary('Feedback Submitted');
    this.dialogRef.close();
  }
/**
 * variable  for selected items
 */
  selectedItem = {};
  /**
 * method for on Change
 */
  onChange(e:any){
  
     this.selectedItem = e;
  }
/**
 * variable  for file size
 */
  fileSize:any;
/**
 * variable  for upload file size
 */
  uploadfilesize:boolean=false;
  /**
 * method for replace img
 */
  replaceimg(event:any){

    if(event.target.files.length>0){
     this.fileSize = event.target.files[0].size;
     this.fileSize = Math.round((this.fileSize / 1024));
     if (this.fileSize > 500) {
       this.uploadfilesize=true;
       this.supportForm.patchValue({iconImage:""});
     return false;
     }
     else{  this.uploadfilesize=false;}
      var src=URL.createObjectURL(event.target.files[0])
 
     this.supportimageUrl=this.sanitizer.bypassSecurityTrustUrl(src)
 
    }
   }
  /**
 * variable for file Selected
 */
   fileSelected:any;
  /**
 * variable for file validator
 */
   fileSizeValidator: boolean;
/**
 * method for upload File
 */
   uploadFile(event){
     this.fileExtError = false;
     this.fileSizeValidator = false;
     if(event.target.files.length>0){
       this.files = event.target.files[0].name
      this.fileSizeValidator = false;
      this.fileSize = event.target.files[0].size;
       var extension = event.target.files[0].name?.split('.')[1];
       if (extension.toLowerCase() != "png" && extension.toLowerCase() != "jpg" && extension.toLowerCase() != "jpeg") {
         this.fileExtError = true;
         return;
       } else{
         this.fileExtError = false;
       }
      this.fileSize = Math.round((this.fileSize / 1024));
      if (this.fileSize >= 25000) {
       
          this.fileSizeValidator = true;
       
      } else {
          
        this.fileSizeValidator = false;
        this.fileSelected = event.target.files[0];
        return null;
      }

  
     }
   }

  /**
 * method for Submit support button
 */
  supportSubmit(){
  

    if(!this.supportForm.valid || this.fileSizeValidator){
      return;
    }
    var st = window.location.toString();
    var arr = st?.split("/");
    let formData = new FormData();
    formData.append("Wsoid", this.workSpaceId);
    formData.append("WsLink", arr[2]);
    formData.append("FullName", this.Full_name);
    formData.append("DisplayName", localStorage.getItem('WsdisplayName'));
    formData.append("EmailID", this.createdBy);    formData.append("Features", this.supportForm.value.selectedItem);
    formData.append("Descriptions", this.supportForm.value.description);
    formData.append("CreatedBy", this.createdBy);
    formData.append("fileImagePath", this.supportForm.value.file);
    if(this.fileSelected)
      formData.append(this.fileSelected.name, this.fileSelected);

      let headers = new HttpHeaders({});
          
      headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
    const supportSys = new HttpRequest('POST', environment.baseUrl + '/api/UserSettings/InsertUserSupport',formData,{
      headers: headers
    });
    this.http.request(supportSys).subscribe(event =>{
      let resp:any = event;
      if (resp.ok) {
        this.showSupport = true;
        this.dialogRef.close();
        this.toastrService.primary('Thank you for your feedback.','',);
        this.supportForm.value.selectedItem =  null;
        this.supportForm.value.description =  null;
        this.supportForm.value.file =  null;
        this.supportForm.patchValue({
          name: '',
          email: '',
          selectedItem: '',
          description: ''
        })
        this.files = null;
      }
     
    })
  }
  /**
 * method for open modal ref
 */
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }
 
}
