<!-- <div class="supportSection"   [@openClose]="showSupport ? 'open' : 'closed'"> -->
<ng-template #helpModal let-data let-ref="dialogRef" >
  <nb-card class="invitemodal">

    <nb-card-header class="helptopbottom">
      <div class="d-flex align-items-center">
        <nb-icon icon='question-mark-circle' style="margin-right: 10px;"  class="text-primary"></nb-icon>
        <h6 class="headline-6  mb-0">Support ticket</h6>
      </div>
     
    </nb-card-header>
    
      <nb-card-body class="p-0">
        <form class="helpsupportbody" [formGroup]="supportForm">
        <div class="supportForm">
          <div class="row m-0 no-gutters">
            <div class="col-12 mb-4 pl-0">
              <nb-select (selectedChange)="onChange($event)" required placeholder="Select Showcase"
                [(selected)]="selectedItem" placeholder="Choose feature" fullWidth size="medium"
                formControlName="selectedItem">
                <nb-option *ngFor="let a of menu2" [value]="a.title">{{a.title}} </nb-option>
              </nb-select>

            </div>
            <div class="col-12 pl-0">

              <textarea nbInput fullWidth
                placeholder="Let us know how we can help you or give us your valuable feedback"
                formControlName="description" maxlength="500" class="helsupportinput mb-2 "></textarea>
            </div>
            <div class="col-12  pl-0">
              
                <div class="attachmentspace"> 
                <p class="cursor-pointer addattachmentpara" onclick="document.getElementById('iconImage').click()"> <nb-icon icon="attach-outline" class="attachclr text-primary" ></nb-icon>Add attachment<span *ngIf="files" class="subtitle-2 text-basic ml-2">{{ files}}</span></p><input type="file" #file formControlName="file" style="display:none;" id="iconImage" (change)="uploadFile($event)"
                accept="image/jpeg, image/png" >
              </div>
              <label *ngIf="fileSizeValidator" class="caption text-danger">Cannot upload more than 25 MB.</label>
              <label *ngIf="fileExtError" class="caption text-danger">File format not supported.</label>

              <p class="subtitle-2  mb-0">Contact us at: <a class="text-primary cursor-pointer maildiv ml-2"
                  href="mailto:support@dox2u.com" target="_blank">support@dox2u.com</a></p>
            </div>

          </div>

        </div>
      </form>
      </nb-card-body>
     

    
    <nb-card-footer class=" col-12 pl-0 helptopbottom">
      <button class="mr-3" nbButton size="small" status="primary" type="submit" (click)="supportSubmit()" [disabled]="!this.supportForm.valid || fileSizeValidator || fileExtError">
        SUBMIT
      </button>
      <button class="helpsupportcancelbtn text-basic" (click)="onCloseSupport();dialogRef.close()">
        CANCEL
      </button>

    </nb-card-footer>
  </nb-card>
</ng-template>